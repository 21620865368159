import { prepareUndeleteAlert, showErrorAlert, showSuccessAlert } from 'actions/alerts';
import { clearTestSentence } from 'actions/communication';
import {
  addAnnotationMatcherToRule,
  cloneRule,
  cloneRuleFailure,
  cloneRuleRequest,
  cloneRuleSuccess,
  createRuleConfig,
  deleteRule,
  deleteRuleFailure,
  deleteRuleRequest,
  deleteRuleSuccess,
  fetchAllRules,
  fetchAllRulesDebounced,
  fetchAllRulesFailure,
  fetchAllRulesRequest,
  fetchAllRulesSuccess,
  fetchAllRulesWithSummary,
  fetchFilterRules,
  fetchRelationshipsSuccess,
  fetchRuleConfigSuccess,
  fetchRulesForFilterPills,
  fetchRulesForFilterPillsFailure,
  fetchRulesForFilterPillsRequest,
  fetchRulesForFilterPillsSuccess,
  fetchSingleRule,
  fetchSingleRuleFailure,
  fetchSingleRuleRequest,
  fetchSingleRuleSuccess,
  fetchTestCaseSummaryRule,
  fetchTestCaseSummaryRuleFailure,
  fetchTestCaseSummaryRuleRequest,
  fetchTestCaseSummaryRuleSuccess,
  publishRuleVersion,
  publishRuleVersionFailure,
  publishRuleVersionRequest,
  publishRuleVersionSuccess,
  saveRelationshipsSuccess,
  saveRuleConfigSuccess,
  saveRuleFailure,
  saveRuleRequest,
  saveRuleSuccess,
  scheduleCompare,
  scheduleCompareFailure,
  scheduleCompareRequest,
  scheduleCompareSuccess,
  undoRuleVersion,
  undoRuleVersionFailure,
  undoRuleVersionRequest,
  undoRuleVersionSuccess,
  updateConfigGroups,
  updateRule,
} from 'actions/rule';
import { apiClient as LitLingoClient } from 'client';
import { push } from 'connected-react-router';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import type { GlobalState } from 'reducers';
import constructConfigTree from 'reducers/util/constructConfigTree';
import { normalizeRuleConfig } from 'reducers/util/flattenConfigData';
import type { Channel } from 'redux-saga';
import { buffers } from 'redux-saga';
import {
  actionChannel,
  all,
  call,
  debounce,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { getCustomerDomain } from 'selectors/auth';
import { getHasUnsavedChanges, getSelectedNode } from 'selectors/config';
import { getNavParamsByResource } from 'selectors/nav';
import { getConfigRuleAsArray } from 'selectors/rule';
import { getRule } from 'selectors/rules';
import { getSavedSearch } from 'selectors/savedSearches';
import type {
  API,
  MRule,
  MRuleConfigNode,
  NormalizedResource,
  Rule,
  RuleConfig,
  SagaReturn,
  SavedSearch,
  UUID,
} from 'types';
import { buildObjFromQSEntries, reverse } from 'utils/urls';
import { v4 as uuidv4 } from 'uuid';

function* updateRuleSaga(action: ReturnType<typeof updateRule>): SagaReturn {
  const { payload } = action;
  const { config, relationship } = (yield select()) as GlobalState;
  const { rule } = payload;
  const configData = constructConfigTree(rule.rootConfigId, config.items, relationship);
  const ruleData = {
    ...rule,
    ...{ config: configData },
  };
  yield put(saveRuleRequest());
  yield put(clearTestSentence());
  const response = (yield call([LitLingoClient.resources.rules, 'upsert'], {
    params: { relationships: ['annotators', 'annotators.annotator'] },
    data: ruleData,
  })) as API.Response<API.Rules.Upsert>;
  if (response.error != null) {
    yield put(saveRuleFailure(response.error));
  } else {
    const { config: removedConfig, ...dataWithoutConfig } = response.data;
    const newRule: NormalizedResource<MRule> = {
      [response.data.uuid]: {
        ...dataWithoutConfig,
        campaign_uuid: rule.campaign_uuid,
        ruleOutcome_uuid: rule.ruleOutcome_uuid,
        rootConfigId: response.data.config.uuid,
        config: {} as RuleConfig,
        outcomes: rule.outcomes,
      },
    };

    yield put(saveRuleSuccess(newRule));

    const newConfig = {
      ...removedConfig,
      rootConfigId: response.data.config.uuid as string,
    };

    const { ruleConfig, relationships } = normalizeRuleConfig({
      config: newConfig,
      rule: newRule[response.data.uuid],
    });

    yield put(saveRuleConfigSuccess(ruleConfig));
    yield put(saveRelationshipsSuccess(relationships));

    yield put(showSuccessAlert('Saved Model'));
  }
}

function* fetchAllRulesSaga({ payload }: ReturnType<typeof fetchAllRules>): SagaReturn {
  yield put(fetchAllRulesRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.rule)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = {
    ...payload,
    include_count: true,
    ...resourceParams,
    relationships: ['created_by', 'updated_by'],
  };

  const response = (yield call([LitLingoClient.resources.rules, 'list'], {
    params,
  })) as API.Response<API.Rules.List>;
  if (response.error != null) {
    yield put(fetchAllRulesFailure(response.error));
  } else {
    yield put(fetchAllRulesSuccess(response.data));
  }
}

function* fetchAllRulesWithSummarySaga({ payload }: ReturnType<typeof fetchAllRules>): SagaReturn {
  yield put(fetchAllRulesRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.rule)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = {
    ...payload,
    include_count: true,
    ...resourceParams,
    relationships: ['created_by', 'updated_by'],
  };

  const response = (yield call([LitLingoClient.resources.rules, 'list'], {
    params,
  })) as API.Response<API.Rules.List>;
  if (response.error != null) {
    yield put(fetchAllRulesFailure(response.error));
  } else {
    yield put(fetchAllRulesSuccess(response.data));
    yield all(
      response.data.records.map((rule) => put(fetchTestCaseSummaryRule({ rule_uuids: rule.uuid })))
    );
  }
}

function* fetchFiltersRulesSaga({ payload }: ReturnType<typeof fetchFilterRules>): SagaReturn {
  yield put(fetchAllRulesRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.rule)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = {
    ...payload,
    include_count: true,
    ...resourceParams,
    selectable_fields: ['name', 'uuid', 'rule_uuid'],
  };

  const response = (yield call([LitLingoClient.resources.rules, 'list'], {
    params,
  })) as API.Response<API.Rules.List>;
  if (response.error != null) {
    yield put(fetchAllRulesFailure(response.error));
  } else {
    yield put(fetchAllRulesSuccess(response.data));
  }
}

function* fetchRulesForFilterPillsSaga(
  action: ReturnType<typeof fetchRulesForFilterPills>
): SagaReturn {
  const { ruleIds } = action.payload;

  const params = { uuids: ruleIds, include_pii: true };
  yield put(fetchRulesForFilterPillsRequest());

  const response = (yield call([LitLingoClient.resources.rules, 'list'], {
    params,
  })) as API.Response<API.Rules.List>;
  if (response.error != null) {
    yield put(fetchRulesForFilterPillsFailure(response.error));
  } else {
    yield put(fetchRulesForFilterPillsSuccess(response.data));
  }
}

function* fetchSingleRuleSaga({ payload }: ReturnType<typeof fetchSingleRule>): SagaReturn {
  yield put(fetchSingleRuleRequest());

  const { ruleId } = payload;

  const rule = (yield select(getRule(ruleId))) as MRule;
  const unsavedChanges = (yield select(getHasUnsavedChanges)) as boolean;

  if (rule && unsavedChanges) {
    return;
  }

  const response = (yield call([LitLingoClient.resources.rules, 'retrieve'], ruleId, {
    params: {
      relationships: ['annotators', 'annotators.annotator', 'updated_by'],
      include_pii: true,
    },
  })) as API.Response<API.Rules.Retrieve>;
  if (response.error != null) {
    yield put(fetchSingleRuleFailure(response.error));
  } else {
    const rootConfigId = response.data.config.uuid || uuidv4();
    yield put(fetchSingleRuleSuccess({ ...response.data, rootConfigId }));
    const config = {
      ...response.data.config,
      rootConfigId,
    };
    const { ruleConfig, relationships } = normalizeRuleConfig({
      config,
      rule: response.data,
    });

    yield put(fetchRuleConfigSuccess(ruleConfig));
    yield put(fetchRelationshipsSuccess(relationships));
  }
}

function* addAnnotationMatcherToRuleSaga(
  action: ReturnType<typeof addAnnotationMatcherToRule>
): SagaReturn {
  const { payload } = action;
  const { annotator, rule, dropIndex } = payload;

  const nodeIdx: number = dropIndex ?? ((yield select(getSelectedNode)) as number);
  const arrayTree: MRuleConfigNode[] = (yield select(getConfigRuleAsArray)) as MRuleConfigNode[];

  if (nodeIdx && arrayTree[nodeIdx]) {
    const { name } = arrayTree[nodeIdx];
    if (name !== 'AND' && name !== 'OR' && name !== 'RELATIONSHIP_MATCH') {
      yield put(showErrorAlert("Can't add identifier to selected item"));
      return;
    }
  }

  const newId = uuidv4();
  const ruleConfig = {
    [newId]: {
      name: annotator.name,
      parent: nodeIdx ? arrayTree[nodeIdx].id : rule.rootConfigId,
      annotatorId: annotator.uuid,
      typeOfConfig: 'ANNOTATION_MATCH' as const,
      negated: undefined,
      id: newId,
    },
  };

  // BAD:  I don't like how I need to do step 1,2 before 3
  yield put(createRuleConfig(ruleConfig));
  yield put(
    updateConfigGroups({
      parentId: nodeIdx ? arrayTree[nodeIdx].id : (rule.rootConfigId as UUID),
      newConfigId: newId,
    })
  );
}

function* cloneRuleSaga({ payload }: ReturnType<typeof cloneRule>): SagaReturn {
  yield put(cloneRuleRequest());

  const { ruleOutcomeId, campaignId } = payload;

  const response = (yield call([LitLingoClient.resources.campaigns.extras, 'clone'], {
    urlParams: { ruleOutcomeId, campaignId },
  })) as API.Response<API.Campaigns.CloneRule>;
  if (response.error != null) {
    yield put(cloneRuleFailure(response.error));
  } else {
    yield put(showSuccessAlert('Model Duplicated'));
    yield put(cloneRuleSuccess());
  }
}

type CreateRulePayload = {
  rule: {
    name: string;
    description: string;
    config: {
      operator: 'OR';
      operands: [];
    };
    campaignId: UUID;
    outcomes: UUID[];
  };
};

export function* createRule(payload: CreateRulePayload): SagaReturn<Rule | boolean> {
  const { rule } = payload;
  const response = (yield call([LitLingoClient.resources.rules, 'upsert'], {
    params: { relationships: ['annotators', 'annotators.annotator'] },
    data: rule,
  })) as API.Response<API.Rules.Upsert>;

  if (response.error != null) {
    yield put(saveRuleFailure(response.error));
    return false;
  }

  const newId = response.data.uuid;

  const rootConfigId = uuidv4();
  const newRule = {
    [newId]: {
      ...response.data,
      campaign_uuid: rule.campaignId,
      rootConfigId,
      outcomes: rule.outcomes,
    },
  };

  const configData = {
    ...({} as RuleConfig),
    rootConfigId,
  };

  const { ruleConfig, relationships } = normalizeRuleConfig({
    config: configData,
    rule: newRule[newId],
  });

  yield put(saveRuleConfigSuccess(ruleConfig));
  yield put(saveRelationshipsSuccess(relationships));

  yield put(saveRuleSuccess(newRule));

  return newRule[newId];
}

function* deleteRuleSaga(action: ReturnType<typeof deleteRule>): SagaReturn {
  const { payload } = action;
  const { ruleId } = payload;
  yield put(deleteRuleRequest(ruleId));
  const response = (yield call(
    [LitLingoClient.resources.rules, 'delete'],
    ruleId
  )) as API.Response<API.Rules.Delete>;
  if (response.error != null) {
    yield put(deleteRuleFailure(response.error));
  } else {
    yield put(deleteRuleSuccess(ruleId));
    const customerDomain = (yield select(getCustomerDomain)) as string;
    const path = reverse({ routeName: 'rule-list', customerDomain });
    yield put(push(path));
    yield put(
      prepareUndeleteAlert({
        resource: 'rules',
        id: ruleId,
        fetchAction: fetchAllRules,
      })
    );
  }
}

function* scheduleCompareSaga(action: ReturnType<typeof scheduleCompare>): SagaReturn {
  const { payload } = action;
  const { ruleUuids, savedSearchId } = payload;
  yield put(scheduleCompareRequest());

  const savedSearch = (yield select(getSavedSearch(savedSearchId))) as SavedSearch;
  const savedSearchUrl = savedSearch.url.replace(/envelopes__/g, '').replace('?', '');
  const urlParams = new URLSearchParams(savedSearchUrl);
  const params = buildObjFromQSEntries(urlParams.entries());
  const response = (yield call([LitLingoClient.resources.rules.extras, 'scheduleCompare'], {
    params,
    data: {
      saved_search_name: savedSearch.name,
      rule_uuids: ruleUuids,
    },
  })) as API.Response<unknown>;

  if (response.error != null) {
    yield put(scheduleCompareFailure(response.error));
  } else {
    yield put(scheduleCompareSuccess());
    yield put(
      showSuccessAlert(
        'Success. When processing completes a data export will be sent to your inbox'
      )
    );
  }
}

function* fetchTestCasesSummaryRuleSaga({
  payload,
}: ReturnType<typeof fetchTestCaseSummaryRule>): SagaReturn {
  const { rule_uuids: ruleUuid } = payload;

  yield put(fetchTestCaseSummaryRuleRequest());

  const response = (yield call([LitLingoClient.resources.testCases.extras, 'summary'], {
    params: payload,
  })) as API.Response<API.Tests.Summary>;

  if (response.error != null) {
    yield put(fetchTestCaseSummaryRuleFailure(response.error));
  } else {
    const data = {
      ...response.data,
      ruleUuid,
    };
    yield put(fetchTestCaseSummaryRuleSuccess(data));
  }
}

function* publishRuleVersionSaga({ payload }: ReturnType<typeof publishRuleVersion>): SagaReturn {
  const { ruleId, version } = payload;

  yield put(publishRuleVersionRequest());

  const response = (yield call([LitLingoClient.resources.rules.extras, 'publishVersion'], {
    params: {
      relationships: ['annotators', 'annotators.annotator', 'updated_by'],
      include_pii: true,
    },
    urlParams: { ruleId, version },
  })) as API.Response<API.Rules.PublishVersion>;

  if (response.error != null) {
    yield put(publishRuleVersionFailure(response.error));
  } else {
    const rootConfigId = response.data.config.uuid || uuidv4();
    yield put(publishRuleVersionSuccess({ ...response.data, rootConfigId }));

    const config = {
      ...response.data.config,
      rootConfigId,
    };
    const { ruleConfig, relationships } = normalizeRuleConfig({
      config,
      rule: response.data,
    });

    yield put(fetchRuleConfigSuccess(ruleConfig));
    yield put(fetchRelationshipsSuccess(relationships));
    yield put(showSuccessAlert('Version published.'));
  }
}

function* undoRuleVersionSaga({ payload }: ReturnType<typeof undoRuleVersion>): SagaReturn {
  const { ruleId } = payload;

  yield put(undoRuleVersionRequest());

  const response = (yield call([LitLingoClient.resources.rules.extras, 'undoVersion'], {
    urlParams: { ruleId },
    params: {
      relationships: ['annotators', 'annotators.annotator', 'updated_by'],
      include_pii: true,
    },
  })) as API.Response<API.Rules.UndoVersion>;

  if (response.error != null) {
    yield put(undoRuleVersionFailure(response.error));
  } else {
    const rootConfigId = response.data.config.uuid || uuidv4();
    yield put(undoRuleVersionSuccess({ ...response.data, rootConfigId }));

    const config = {
      ...response.data.config,
      rootConfigId,
    };
    const { ruleConfig, relationships } = normalizeRuleConfig({
      config,
      rule: response.data,
    });

    yield put(fetchRuleConfigSuccess(ruleConfig));
    yield put(fetchRelationshipsSuccess(relationships));

    yield put(showSuccessAlert('Model restored to last version.'));
  }
}

function* channels(): SagaReturn {
  const fetchAllRulesChan = (yield actionChannel(
    fetchAllRulesDebounced.toString(),
    buffers.sliding(1)
  )) as Channel<ReturnType<typeof fetchAllRulesDebounced>>;

  yield debounce(400, fetchAllRulesChan, fetchAllRulesSaga);
}

function* rulesSaga(): SagaReturn {
  yield takeLatest(cloneRule, cloneRuleSaga);
  yield takeEvery(fetchSingleRule, fetchSingleRuleSaga);
  yield takeLatest(fetchAllRules.toString(), fetchAllRulesSaga);
  yield takeLatest(updateRule.toString(), updateRuleSaga);
  yield takeLatest(addAnnotationMatcherToRule.toString(), addAnnotationMatcherToRuleSaga);
  yield takeLatest(fetchRulesForFilterPills.toString(), fetchRulesForFilterPillsSaga);
  yield takeLatest(deleteRule.toString(), deleteRuleSaga);
  yield takeLatest(scheduleCompare.toString(), scheduleCompareSaga);
  yield takeEvery(fetchTestCaseSummaryRule.toString(), fetchTestCasesSummaryRuleSaga);
  yield takeLatest(publishRuleVersion.toString(), publishRuleVersionSaga);
  yield takeLatest(undoRuleVersion.toString(), undoRuleVersionSaga);
  yield takeLatest(fetchFilterRules.toString(), fetchFiltersRulesSaga);
  yield takeLatest(fetchAllRulesWithSummary.toString(), fetchAllRulesWithSummarySaga);
  // @ts-expect-error check types
  yield channels();
}

export default rulesSaga;
