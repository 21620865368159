import { fetchSingleAnnotator } from 'actions';
import Annotator from 'components/Annotator';
import AnnotatorList from 'components/AnnotatorList';
import AnnotatorName from 'components/AnnotatorName';
import AnnotatorType from 'components/AnnotatorType';
import { RouteSpec, SuperRouteSpec } from 'types';

const annotatorRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'annotator-list-new-matcher',
    path: '/:customerDomain/annotator/list/:campaignId?/:ruleId?/new/matcher/:annotatorId?',
    component: Annotator,
    private: true,
    permissions: 'annotators.list',
  },
  {
    name: 'annotator-list-new-type',
    path: '/:customerDomain/annotator/list/:campaignId?/:ruleId?/new/:type',
    component: AnnotatorName,
    private: true,
    permissions: 'annotators.create',
  },
  {
    name: 'annotator-choose-type',
    path: '/:customerDomain/annotator/choose-type/:ruleId?/:campaignId?',
    component: AnnotatorType,
    private: true,
    permissions: 'annotators.create',
  },
  {
    name: 'annotator-list',
    path: '/:customerDomain/annotator/list/:campaignId?/:ruleId?',
    component: AnnotatorList,
    private: true,
    permissions: 'annotators.list',
    data: {
      params: {
        include_count: {
          defaultValue: '1',
        },
        annotators: {
          nestedResource: true,
          params: {
            order_by: {
              defaultValue: 'updated_at',
              options: ['created_at', 'type', 'name'],
            },
            order_desc: {
              defaultValue: 'true',
              options: ['true', 'false'],
            },
            offset: {
              defaultValue: '0',
            },
            limit: {
              defaultValue: '25',
            },
            name: {},
            broad_search: {},
          },
        },
      },
    },
  },
  {
    name: 'annotator',
    path: '/:customerDomain/annotator/:annotatorId?/campaign/:campaignId?/rule/:ruleId?',
    component: Annotator,
    private: true,
    permissions: 'annotators.retrieve',
    data: {
      idFromPath: true,
      actions: [fetchSingleAnnotator],
      params: {
        body: {},
        include_count: {
          defaultValue: '1',
        },
        test_cases: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            broad_search: {},
            order_desc: {
              defaultValue: 'true',
            },
            order_by: {
              defaultValue: 'created_at',
              options: [
                'created_at',
                'created_by_uuid',
                'test_string',
                'should_match',
                'most_recent_run_passed',
              ],
            },
            match_criterias: {
              list: true,
            },
          },
        },
        audit_logs: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '10',
            },
            offset: {
              defaultValue: '0',
            },
            relationships: {
              defaultValue: ['updated_by'],
            },
            include_count: {
              defaultValue: '1',
            },
            order_by: {
              defaultValue: 'updated_at',
            },
            order_desc: {
              defaultValue: 'true',
            },
          },
        },
      },
    },
  },
  {
    name: 'annotator-detail',
    path: '/:customerDomain/annotator/:annotatorId?',
    component: Annotator,
    private: true,
    permissions: 'annotators.retrieve',
    data: {
      idFromPath: true,
      actions: [fetchSingleAnnotator],
      params: {
        body: {},
        include_count: {
          defaultValue: '1',
        },
        test_cases: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            broad_search: {},
            order_desc: {
              defaultValue: 'true',
            },
            order_by: {
              defaultValue: 'created_at',
              options: [
                'created_at',
                'created_by_uuid',
                'test_string',
                'should_match',
                'most_recent_run_passed',
              ],
            },
            match_criterias: {
              list: true,
            },
          },
        },
        audit_logs: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '10',
            },
            offset: {
              defaultValue: '0',
            },
            relationships: {
              defaultValue: ['updated_by'],
            },
            include_count: {
              defaultValue: '1',
            },
            order_by: {
              defaultValue: 'updated_at',
            },
            order_desc: {
              defaultValue: 'true',
            },
          },
        },
      },
    },
  },
  {
    path: '/annotator/list',
    component: (): null => null,
    private: true,
  },
];

export default annotatorRoutes;
