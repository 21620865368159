import { setURLParams } from 'actions';
import {
  PAGINATION_FIRST,
  PAGINATION_LAST,
  PAGINATION_NEXT,
  PAGINATION_PREV,
} from 'constants/commonIcons';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import type { API } from 'types';

type ComponentProps = {
  resourceName: string;
  resourceStateName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchHook: () => { data: API.WrappedAPIResponse<any> | null; isFetching: boolean };
  firstLastPage?: boolean;
};

const ListFooterHooksBased: React.FC<ComponentProps> = ({
  resourceStateName,
  resourceName,
  fetchHook,
  firstLastPage = false,
}) => {
  const dispatch = useDispatch();

  const { data, isFetching: loading } = fetchHook();

  const totalCount = data?.count || 0;

  const navParams = useSelector(getNavParamsByResource(resourceStateName));
  const offset = parseInt(navParams.offset as string, 10);
  const limit = parseInt(navParams.limit as string, 10);

  const hasPrevious = offset !== 0 && !loading;
  const hasNext = offset + limit < totalCount && !loading;

  const handlePreviousClick = (): void => {
    dispatch(
      setURLParams({
        [`${resourceStateName}__offset`]: (offset - limit) as unknown as string,
      })
    );
  };

  const handleNextClick = (): void => {
    dispatch(
      setURLParams({ [`${resourceStateName}__offset`]: (offset + limit) as unknown as string })
    );
  };

  const handleFirstClick = (): void => {
    dispatch(setURLParams({ [`${resourceStateName}__offset`]: '0' }));
  };

  const handleLastClick = (): void => {
    dispatch(
      setURLParams({
        [`${resourceStateName}__offset`]: (totalCount - (totalCount % limit)) as unknown as string,
      })
    );
  };

  return (
    <nav className="flex justify-center items-center h-10 bg-white border-llitlingo-gray-1">
      <div className="flex items-center justify-center gap-2">
        {firstLastPage && (
          <button
            className={`flex justify-center items-center h-5 w-5 focus:outline-none ${
              !hasPrevious ? 'text-litlingo-gray-2' : 'text-litlingo-gray-5'
            }`}
            type="button"
            disabled={!hasPrevious}
            onClick={handleFirstClick}
          >
            {PAGINATION_FIRST}
          </button>
        )}
        <button
          className={`flex justify-center items-center h-5 w-5 focus:outline-none ${
            !hasPrevious ? 'text-litlingo-gray-2' : 'text-litlingo-gray-5'
          }`}
          type="button"
          disabled={!hasPrevious}
          onClick={handlePreviousClick}
        >
          {PAGINATION_PREV}
        </button>
        <span className="text-small">
          {loading ? (
            'Loading...'
          ) : (
            <>
              <span className="font-bold">
                {` ${data?.records.length === 0 ? 0 : offset + 1}-${
                  data ? offset + data.records.length : offset
                }`}
              </span>
              {` of ${
                totalCount === 10000
                  ? '+10,000'
                  : pluralize(resourceName.split(' ').map(capitalize).join(' '), totalCount, true)
              }`}
            </>
          )}
        </span>
        <button
          className={`flex justify-center items-center h-5 w-5 focus:outline-none ${
            !hasNext ? 'text-litlingo-gray-2' : 'text-litlingo-gray-5'
          }`}
          type="button"
          disabled={!hasNext}
          onClick={handleNextClick}
        >
          {PAGINATION_NEXT}
        </button>

        {firstLastPage && (
          <button
            className={`flex justify-center items-center h-5 w-5 focus:outline-none ${
              !hasNext ? 'text-litlingo-gray-2' : 'text-litlingo-gray-5'
            }`}
            type="button"
            disabled={!hasNext}
            onClick={handleLastClick}
          >
            {PAGINATION_LAST}
          </button>
        )}
      </div>
    </nav>
  );
};

export default ListFooterHooksBased;
