import React, { useState } from 'react';
import ReactMarkdownWithHtml from 'react-markdown/with-html';
import { EXCLAMATION_LOGO } from '../constants';
import type { ChromeOutcome } from '../types';
import ChevronButton from './ChevronButton';
import HighlightedSentence from './HighlightedSentence';

interface OutcomeCardProps {
  outcomes: ChromeOutcome[];
  sentencesTitle?: string;
  onArrowClick?: (arrow: string) => void;
}

const OutcomeCardPaginated: React.VFC<OutcomeCardProps> = ({
  outcomes,
  sentencesTitle = 'Review the following highlighted text',
  onArrowClick,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const currentOutcome = outcomes[currentPage - 1];
  const leftButtonDisabled = currentPage === 1;
  const rightButtonDisabled = outcomes.length === currentPage;

  return (
    <div className="bg-white w-full">
      <div className="p-2 litlingo__color_grey">
        <div
          className="font-bold mt-1 mb-1 text-left text-base"
          data-testid="sentenceTitle"
        >
          {sentencesTitle}
        </div>

        <div className="w-full text-base leading-5 text-left overflow-auto">
          {currentOutcome.lines.map((line, idx) => (
            <div key={line.index} className={idx !== 0 ? 'mt-2' : ''}>
              <HighlightedSentence
                nodes={line.nodes}
                useHover={false}
                protectedFields={{
                  entity: 'FIELD_IS_UNPROTECTED',
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="litlingo__color_grey text-left text-base whitespace-normal rounded-t border border-litlingo-light-blue border-solid mt-2">
        <div className="flex items-center justify-between bg-litlingo-light-blue font-bold p-1 pt-2 pl-2 pr-2">
          <div className="flex items-center">
            <div className="mr-2">{EXCLAMATION_LOGO}</div>
            <span className="capitalize">{currentOutcome.rule}</span>
          </div>
          {outcomes.length > 1 && (
            <div className="flex items-center">
              <ChevronButton
                pointToLeft
                clickHandler={(): void => {
                  setCurrentPage((prevPage) => prevPage - 1);
                  if (onArrowClick) {
                    onArrowClick('left');
                  }
                }}
                disabled={leftButtonDisabled}
              />
              <div className="text-sm leading-3 mx-1">
                {currentPage} of {outcomes.length}{' '}
                <span className="font-normal">notices</span>
              </div>
              <ChevronButton
                clickHandler={(): void => {
                  setCurrentPage((prevPage) => prevPage + 1);
                  if (onArrowClick) {
                    onArrowClick('right');
                  }
                }}
                disabled={rightButtonDisabled}
              />
            </div>
          )}
        </div>
        <div className="markdown-body litlingo-video-wrapper p-2 leading-5">
          <ReactMarkdownWithHtml allowDangerousHtml>
            {currentOutcome.outcome}
          </ReactMarkdownWithHtml>
        </div>
      </div>
    </div>
  );
};

export default OutcomeCardPaginated;
