import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useAnnotators from 'hooks/annotators/useAnnotators';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';
import React from 'react';
import { useSelector } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';

const AnnotatorListCount: React.FC = () => {
  const { data, isLoading } = useAnnotators();

  const navParams = useSelector(getNavParamsByResource(resourceQueryParamName.annotator));
  const offset = parseInt(navParams.offset as string, 10);

  if (!data) return null;

  return (
    <div>
      <div className={`${isLoading ? 'text-litlingo-gray-2' : ''}`}>
        <span className="font-bold">{` ${data.records.length === 0 ? 0 : offset + 1}-${
          offset + data.records.length
        }`}</span>
        {` of ${
          data && data.count >= 10000
            ? '+10,000'
            : pluralize('identifier'.split(' ').map(capitalize).join(' '), data.count, true)
        }`}
      </div>
    </div>
  );
};

export default AnnotatorListCount;
