import {
  addModifiedItem,
  copyItem,
  fetchRelationshipsSuccess,
  linkParentWithChild,
  pasteItem,
  showErrorAlert,
  showSuccessAlert,
} from 'actions';
import type { GlobalState } from 'reducers';
import constructConfigTree from 'reducers/util/constructConfigTree';
import constructConfigWithNewIds from 'reducers/util/constructConfigWithNewIds';
import flattenConfigData from 'reducers/util/flattenConfigData';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { SagaReturn } from 'types';

function* copyItemSaga(action: ReturnType<typeof copyItem>): SagaReturn {
  const { payload } = action;
  const { idToCopy } = payload;

  const { config, relationship } = (yield select()) as GlobalState;
  const configData = yield call(constructConfigTree, idToCopy, config.items, relationship);
  try {
    yield call([navigator.clipboard, 'writeText'], JSON.stringify(configData));
    yield put(showSuccessAlert('Node copied to your clipboard'));
  } catch (error) {
    yield put(showErrorAlert('There was an error copying the node to your clipboard'));
  }
}

function* pasteItemSaga(action: ReturnType<typeof pasteItem>): SagaReturn {
  const { payload } = action;
  const { targetNodeId, useIdentifierId = false } = payload;

  try {
    const copiedData = (yield call([navigator.clipboard, 'readText'])) as string;
    const configFromClipboard = JSON.parse(copiedData);
    if (configFromClipboard.operator == null) {
      yield put(showErrorAlert('The config in the clipboard is not valid'));
      return;
    }
    const [ruleConfig, relationships] = (yield call(
      flattenConfigData,
      { ...configFromClipboard, rootConfigId: targetNodeId },
      [{}, {}],
      undefined,
      useIdentifierId
    )) as ReturnType<typeof flattenConfigData>;

    const [newRuleConfig, newRelationships] = (yield call(
      constructConfigWithNewIds,
      ruleConfig,
      relationships
    )) as ReturnType<typeof constructConfigWithNewIds>;
    const localParentNode = Object.values(newRuleConfig).find((node) => node.parent == null);

    yield put(fetchRelationshipsSuccess(newRelationships));
    if (localParentNode != null) {
      yield put(addModifiedItem(newRuleConfig));
      yield put(linkParentWithChild({ parentId: targetNodeId, childId: localParentNode.id }));
    }
  } catch (error) {
    yield put(showErrorAlert('There was an error pasting the config from your clipboard'));
  }
}

function* configSaga(): SagaReturn {
  yield takeLatest(copyItem.toString(), copyItemSaga);
  yield takeLatest(pasteItem.toString(), pasteItemSaga);
}

export default configSaga;
