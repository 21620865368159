import type { AnnotatorRelationship, MRuleConfigNode, NormalizedResource } from 'types';
import { v4 as uuidv4 } from 'uuid';

type RelationshipsType = NormalizedResource<
  Pick<AnnotatorRelationship, 'type' | 'id' | 'annotation_a' | 'annotation_b' | 'annotation_c'>
>;

type DenormalizeRelationshipType = Pick<
  AnnotatorRelationship,
  'type' | 'id' | 'annotation_a' | 'annotation_b' | 'annotation_c'
>;

const constructConfigWithNewIds = (
  ruleConfig: NormalizedResource<MRuleConfigNode>,
  relationships: RelationshipsType
): [NormalizedResource<MRuleConfigNode>, RelationshipsType] => {
  const mapNewIds: { [key: string]: string } = {};
  // Iterate rule config
  Object.keys(ruleConfig).forEach((node) => {
    const currentNode = ruleConfig[node];
    // Check if the node already have a new generated id
    if (mapNewIds[node] != null) {
      ruleConfig[mapNewIds[node]] = { ...currentNode, id: mapNewIds[node] };
    } else {
      // If node doesn't have a new id yet, we create a new one
      const newNodeId = uuidv4();
      ruleConfig[newNodeId] = { ...currentNode, id: newNodeId };
      // Store the new id in mapNewIds
      mapNewIds[node] = newNodeId;
    }

    // Check if the node has groups, because we need to change
    // the ids to the groups array too
    const newGroupsIds: string[] = [];
    if ('groups' in currentNode && currentNode.groups != null) {
      currentNode.groups.forEach((currentGroupId) => {
        // Check if the node already have a new generated id
        if (mapNewIds[currentGroupId] != null) {
          newGroupsIds.push(mapNewIds[currentGroupId]);
        } else {
          // If node doesn't have a new id yet, we create a new one
          const newGroupId = uuidv4();
          newGroupsIds.push(newGroupId);
          // Store the new id in mapNewIds
          mapNewIds[currentGroupId] = newGroupId;
        }
      });
      // @ts-ignore
      ruleConfig[mapNewIds[node]] = { ...ruleConfig[mapNewIds[node]], groups: newGroupsIds };
    }

    // Check if the node has relationship, because we need to change
    // the ids to the relationship array too
    const newRelationshipIds: string[] = [];
    if ('relationship' in currentNode && currentNode.relationship != null) {
      currentNode.relationship.forEach((relationshipId) => {
        // Check if the node already have a new generated id
        if (mapNewIds[relationshipId] != null) {
          newRelationshipIds.push(mapNewIds[relationshipId]);
        } else {
          // If node doesn't have a new id yet, we create a new one
          const newGroupId = uuidv4();
          newRelationshipIds.push(newGroupId);
          // Store the new id in mapNewIds
          mapNewIds[relationshipId] = newGroupId;
        }
      });
      ruleConfig[mapNewIds[node]] = {
        ...ruleConfig[mapNewIds[node]],
        // @ts-ignore
        relationship: newRelationshipIds,
      };
    }

    // Check if the node has a parent, because we need to change the id to the parent
    if ('parent' in currentNode && currentNode.parent != null) {
      let newParentId;
      // Check if the node already have a new generated id
      if (mapNewIds[currentNode.parent] != null) {
        newParentId = mapNewIds[currentNode.parent];
      } else {
        // If node doesn't have a new id yet, we create a new one
        const newId = uuidv4();
        newGroupsIds.push(newId);
        // Store the new id in mapNewIds
        newParentId = newId;
      }
      ruleConfig[mapNewIds[node]] = { ...ruleConfig[mapNewIds[node]], parent: newParentId };
    }
    delete ruleConfig[node];
  });

  const newRelationships: RelationshipsType = {};
  // Iterate over relationships object
  Object.keys(relationships).forEach((relationshipId) => {
    const relationshipWithNewAnnotations: DenormalizeRelationshipType =
      relationships[relationshipId];

    const annotationA = relationships[relationshipId].annotation_a.id;
    const annotationB = relationships[relationshipId].annotation_b.id;
    const annotationC = relationships[relationshipId].annotation_c?.id;

    // Check if the annotationA already have a new generated id
    if (mapNewIds[annotationA] != null) {
      relationshipWithNewAnnotations.annotation_a.id = mapNewIds[annotationA];
    } else {
      // If node doesn't have a new id yet, we create a new one
      const newAnnotationId = uuidv4();
      relationshipWithNewAnnotations.annotation_a.id = newAnnotationId;
      // Store the new id in mapNewIds
      mapNewIds[annotationA] = newAnnotationId;
    }

    // Check if the annotationB already have a new generated id
    if (mapNewIds[annotationB] != null) {
      relationshipWithNewAnnotations.annotation_b.id = mapNewIds[annotationB];
    } else {
      // If node doesn't have a new id yet, we create a new one
      const newAnnotationId = uuidv4();
      relationshipWithNewAnnotations.annotation_b.id = newAnnotationId;
      // Store the new id in mapNewIds
      mapNewIds[annotationB] = newAnnotationId;
    }

    // Check if the relationship has an annotationC
    if (annotationC != null && relationshipWithNewAnnotations.annotation_c != null) {
      // Check if the annotationC already have a new generated id
      if (mapNewIds[annotationC] != null) {
        relationshipWithNewAnnotations.annotation_c.id = mapNewIds[annotationC];
      } else {
        // If node doesn't have a new id yet, we create a new one
        const newAnnotationId = uuidv4();
        relationshipWithNewAnnotations.annotation_c.id = newAnnotationId;
        // Store the new id in mapNewIds
        mapNewIds[annotationC] = newAnnotationId;
      }
    }

    // Check if the relationship node already have a new generated id
    if (mapNewIds[relationshipId] != null) {
      newRelationships[mapNewIds[relationshipId]] = {
        ...relationshipWithNewAnnotations,
        id: mapNewIds[relationshipId],
      };
    } else {
      // If node doesn't have a new id yet, we create a new one
      const newRelationshipId = uuidv4();
      // Store the new id in mapNewIds
      mapNewIds[relationshipId] = newRelationshipId;
      newRelationships[newRelationshipId] = {
        ...relationshipWithNewAnnotations,
        id: mapNewIds[relationshipId],
      };
    }
  });

  return [ruleConfig, newRelationships];
};

export default constructConfigWithNewIds;
