/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-return-assign */
/* eslint-disable max-lines */
/* import { AxisTickProps } from '@nivo/axes';
import { useTheme } from '@nivo/core'; */
import { ResponsiveSwarmPlot } from '@nivo/swarmplot';
import Navbar from 'components/Navbar';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

type SwarmPlotData = {
  rule_node_description: string;
  created_at: string;
  count: number;
};

type ProcessedData = {
  id: string;
  timestamp: string;
  volume: number;
  group: string;
};

const SwarmPlot: React.FC = () => {
  const [swarmData, setSwarmData] = useState<SwarmPlotData[]>([] as SwarmPlotData[]);

  /* const sumData = 24; */
  /* const nodeGroups = [...new Set(swarmData.map((el) => el.rule_node_description))]; */
  /* const nodesDescription = [
    'Company Competing',
    'Excessive Fees',
    'Exclusions',
    'Lower Supply',
    'Plant Shutdown',
    'Dividing Concept + Business',
  ]; */

  // Initial data per day
  let processedData = swarmData;

  const nodesDescription = [...new Set(processedData.map((el) => el.rule_node_description))];

  processedData = processedData.filter((el) => nodesDescription.includes(el.rule_node_description));

  const changedKeys: ProcessedData[] = processedData.reduce(
    (acc, curr) =>
      [
        ...acc,
        {
          id: uuidv4(),
          timestamp: curr.created_at.substring(0, 10).toString(),
          volume: curr.count,
          group: curr.rule_node_description,
        },
      ] as never[],
    []
  );

  // Getting the total volume per day for every rule_node_description
  const finalData: ProcessedData[] = Object.values(
    changedKeys.reduce(
      (acc, { volume, id, ...r }: ProcessedData) => {
        const key = Object.entries(r).join('-');
        acc[key] = acc[key] || { ...r, volume: 0 };
        acc[key].volume += volume;
        acc[key].id = id;
        return acc;
      },
      {} as {
        [key: string]: ProcessedData;
      }
    )
  );

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const fileReader = new FileReader();
    if (e.target.files != null) {
      fileReader.readAsText(e.target.files[0], 'UTF-8');
      fileReader.onload = (event): void => {
        if (event.target != null && event.target.result != null)
          setSwarmData(JSON.parse(event.target.result as string));
      };
    }
  };

  /* const CustomTick = (tick: AxisTickProps<string>): JSX.Element => {
    const theme = useTheme();

    return (
      <g transform={`translate(${tick.x},${tick.y + 22})`}>
        <rect x={-14} y={-6} rx={3} ry={3} width={28} height={24} fill="rgba(0, 0, 0, .05)" />
        <rect x={-12} y={-12} rx={2} ry={2} width={24} height={24} fill="rgb(232, 193, 160)" />
        <line stroke="rgb(232, 193, 160)" strokeWidth={1.5} y1={-22} y2={-12} />
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            ...theme.axis.ticks.text,
            fill: '#333',
            fontSize: 10,
          }}
        >
          {tick.value}
        </text>
      </g>
    );
  }; */

  /* const getLabelRange = (sum: number, date: string | number | Date): string => {
    const totalDays = sum / 24;
    const curDate = moment(date);
    if (totalDays < 1) {
      return curDate.add(30, 'minutes').startOf('hour').format('hh:mm A');
    }
    if (totalDays >= 1 && totalDays < 30) {
      return curDate.format('ddd DD');
    }
    if (totalDays >= 30) {
      return curDate.format('MMM DD');
    }
    return curDate.format('MMM DD');
  }; *

  /* // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const localeTimeFormat = (value: number | Date) => value.toLocaleString(); */

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />
      <label htmlFor="sankey-file" className=" litlingo-annotator-label-name flex flex-col mt-6">
        <span>Please upload a JSON file with the data for Swarm plot</span>

        <input type="file" name="sankey-file" id="sankey-file" onChange={handleChangeFile} />
      </label>

      {swarmData && nodesDescription.length && (
        <div className="hw-swarm-plot m-auto">
          <ResponsiveSwarmPlot
            data={finalData}
            margin={{
              top: 50,
              right: 100,
              bottom: 100,
              left: 50,
            }}
            groups={nodesDescription}
            groupBy="group"
            value="timestamp"
            // valueFormat={localeTimeFormat}
            valueScale={{
              format: '%Y-%m-%d',
              type: 'time',
              nice: true,
              precision: 'day',
            }}
            // identity="id"
            // size={6}
            size={{ key: 'volume', values: [2, 500], sizes: [6, 30] }}
            colors={['#008000', '#FF9B00']}
            /* borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }} */
            forceStrength={0.2}
            axisTop={null}
            axisLeft={null}
            axisRight={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              legend: '',
              legendPosition: 'start',
              format: (v): string =>
                v.toString().length > 15 ? `${v.toString().slice(0, 12)}...` : v.toString(),
            }}
            axisBottom={{
              /* format: (value): string => getLabelRange(sumData, value), */
              format: 'Week: %m-%d',
              tickSize: 0,
              tickPadding: -10,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 46,
              // important
              tickValues: 'every 1 week',
              /* ticksPosition: 'after', */
              /* renderTick: CustomTick as never, */
            }}
            /* onClick={(el): void => console.log(el)} */
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            /* onMouseEnter={(e): void => {
              e.currentTarget.style.cursor = 'pointer';
            }} */
            tooltip={(data): JSX.Element => (
              <div className="bg-litlingo-off-white p-1 border-radius flex items-center shadow-md ">
                <div
                  style={{
                    background: data.color,
                  }}
                  className="h-3 w-3 mr-2"
                />
                <span className="text">{`${data.data.volume},${data.data.group}`}</span>
              </div>
            )}
            layout="horizontal"
            isInteractive
            animate
            enableGridX
            gridXValues="every 7 days"
            enableGridY={false}
            gap={-50}
            spacing={0}
          />
        </div>
      )}
    </div>
  );
};

export default SwarmPlot;
