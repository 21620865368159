/* eslint-disable max-lines */
/* eslint-disable no-unused-expressions */
import {
  clearTestSentence,
  createTestCase,
  postTestSentence,
  replaceURLParams,
  saveTestCase,
  testAnnotator,
} from 'actions';
import Permissions from 'components/Permissions';
import SentencesViolatedSection from 'components/SentencesViolatedSection';
import ToggleButton from 'components/ToggleButton';
import keyMap from 'constants/configHotKeys';
import { platformNames } from 'constants/platform';
import React, { useRef, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getAnnotatedTestSentenceResult,
  getAnnotationMatchedLanguage,
  getRulesViolated,
} from 'selectors/communications';
import { getNavParams } from 'selectors/nav';
import { getCurrentTestRuleId, getRule, getToAnnotator } from 'selectors/rule';
import { getGeneratedTestCase } from 'selectors/testCases';
import type { Platform } from 'types';
import logEvent from 'utils/analytics';

type RouterParams = {
  ruleId: string;
  campaignId: string;
  annotatorId: string;
};

type ComponentProps = {
  width?: string;
  testPhrase?: string;
};

const TestSentenceVisualizer: React.FC<ComponentProps> = ({ width, testPhrase }) => {
  const { useEffect } = React;
  const dispatch = useDispatch();
  const { ruleId, campaignId, annotatorId } = useParams<RouterParams>();
  const rule = useSelector(getRule(ruleId)) || {};
  const resultLines = useSelector(getAnnotatedTestSentenceResult);
  const { rulesViolated } = useSelector(getRulesViolated);
  const annotationsMatched = useSelector(getAnnotationMatchedLanguage);
  const bodyParam = useSelector(getNavParams).body || '';
  const currentTestRuleId = useSelector(getCurrentTestRuleId);
  const toAnnotator = useSelector(getToAnnotator);
  const generatedTest = useSelector(getGeneratedTestCase);

  const [shouldTestFire, setShouldTestFire] = useState(false);
  const [platform, setPlatform] = useState<Platform | 'all'>('all');
  const [body, setBody] = useState<string>(bodyParam as string);
  const [debouncedBody, setDebouncedBody] = useState('');

  const RunButtonRef = useRef<HTMLButtonElement>(null);
  const SaveButtonRef = useRef<HTMLButtonElement>(null);
  const TextAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedBody(body), 500);
    return (): void => {
      clearTimeout(timeoutId);
    };
  }, [body]);

  // works as a componentWillUnmount to avoid having data when returning to this view
  // @ts-ignore
  useEffect(() => {
    if (!toAnnotator || !currentTestRuleId) {
      dispatch(clearTestSentence());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTestRuleId, toAnnotator]);

  useEffect(() => {
    dispatch(replaceURLParams({ body: debouncedBody }));
  }, [debouncedBody, dispatch]);

  useEffect(() => {
    if (testPhrase) {
      setBody(testPhrase || '');
    }
  }, [testPhrase]);

  useEffect(() => {
    if (!bodyParam) return;
    setBody(bodyParam as string);
  }, [bodyParam]);

  useEffect(() => {
    if (generatedTest) {
      setBody(generatedTest.trim());
    }
  }, [generatedTest]);

  const handlePlatformChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    logEvent('tests-platform-change');
    setPlatform(event.target.value as Platform);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    logEvent('tests-run-test');

    const rules = rule.uuid ? [rule.uuid] : undefined;
    const campaigns = campaignId ? [campaignId] : undefined;
    if (annotatorId) {
      dispatch(testAnnotator({ body, annotator: annotatorId }));
    } else {
      let platforms: Platform[] = [];
      platforms = [platform];

      dispatch(postTestSentence({ body, rules, campaigns, platforms }));
    }
  };

  const handleSaveTestCase = (): void => {
    const entities = {
      annotator_uuid: annotatorId,
      rule_uuid: annotatorId ? undefined : rule.uuid, // if there's an annotator, not rule
      campaign_uuid: rule.uuid || annotatorId ? undefined : campaignId, // if theres a rule, save rule not campaign
    };
    dispatch(saveTestCase({ test_string: body, should_match: shouldTestFire, ...entities }));
  };

  const handleGenerateTest = (): void => {
    dispatch(createTestCase({ prompt: body }));
  };

  const handlers = {
    RUN_TEST_PHRASE: (): void => {
      if (document.activeElement === TextAreaRef?.current) {
        RunButtonRef?.current?.click();
      }
    },
    RUN_TEST_PHRASE_ALT: (): void => {
      if (document.activeElement === TextAreaRef?.current) {
        RunButtonRef?.current?.click();
      }
    },
    SAVE_TO_SUITE: (event: KeyboardEvent | undefined): void => {
      if (event) {
        event.preventDefault();
        if (document.activeElement === TextAreaRef?.current) {
          SaveButtonRef?.current?.click();
        }
      }
    },
  };
  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <div>
        <div>
          <div className="flex">
            <div className={`${width || 'w-2/3'} p-4`}>
              <form onSubmit={handleSubmit} data-testid="test-viz-phrase-form">
                <div className="text-left">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                  >
                    <div className="mt-1 sm:mt-0 ">
                      <div className="flex rounded-md shadow-sm">
                        <textarea
                          ref={TextAreaRef}
                          id="about"
                          data-testid="test-viz-phrase-input"
                          rows={6}
                          className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          onChange={(event): void => {
                            setBody(event.target.value);
                          }}
                          value={body}
                        />
                      </div>
                    </div>
                  </label>
                </div>
                <div>
                  <p className="mt-2 text-xs text-gray-600 font-normal">
                    <strong>Ctrl + Enter </strong>
                    runs test.
                    <strong> Ctrl + S </strong>
                    saves test.
                  </p>
                </div>
                <div className="flex flex-row gap-2 justify-between">
                  <div className="flex items-center">
                    <Permissions action="test_cases.askAiToGenerate">
                      <button
                        type="button"
                        className="whitespace-no-wrap button button--secondary h-8"
                        onClick={handleGenerateTest}
                      >
                        Ask AI to generate
                      </button>
                    </Permissions>
                  </div>
                  <div className="mt-2 flex justify-end">
                    <div className="flex justify-end">
                      <div className="flex justify-end items-center">
                        {!campaignId && !ruleId && !annotatorId && (
                          <div className="mr-8 flex">
                            <span className="mr-2 self-center text text--medium">Platform</span>
                            <select
                              aria-label="Selected tab"
                              className="form-select block pl-3 pr-10 py-px text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150 text text--large"
                              onChange={handlePlatformChange}
                              value={platform}
                              data-testid="platform-select"
                            >
                              {(
                                Object.keys(platformNames) as Array<keyof typeof platformNames>
                              ).map((key) => (
                                <option className="text" key={key} value={key}>
                                  {platformNames[key]}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        <Permissions action="test_cases.createFromTestPage">
                          <span className="mr-4 flex items-center">
                            <p className="text text--medium mr-4">Should Match</p>
                            <ToggleButton
                              isOn={shouldTestFire}
                              handleToggleClick={(): void => setShouldTestFire(!shouldTestFire)}
                            />
                          </span>
                          <span className="mr-4 inline-flex rounded-md shadow-sm">
                            <button
                              ref={SaveButtonRef}
                              data-testid="test-viz-save-button"
                              type="button"
                              className={`button button--secondary ${
                                body === '' ? 'bg-opacity-25' : ''
                              }`}
                              disabled={body === ''}
                              onClick={handleSaveTestCase}
                            >
                              Add to training set
                            </button>
                          </span>
                        </Permissions>
                        <span className="inline-flex rounded-md shadow-sm">
                          <button
                            ref={RunButtonRef}
                            type="submit"
                            data-testid="run-test-button"
                            className={`button button--primary ${
                              body === '' ? 'bg-opacity-25' : ''
                            }`}
                            disabled={body === ''}
                          >
                            Run Test
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {resultLines && (
              <SentencesViolatedSection
                annotatorId={annotatorId}
                annotationsMatched={annotationsMatched}
                rulesViolated={rulesViolated}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TestSentenceVisualizer;
