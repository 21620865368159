import { ResponsiveWaffle } from '@nivo/waffle';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import React from 'react';
import { DefaultWidgetComponentProps, MetricsDataV2 } from 'types';
import logEvent from 'utils/analytics';

const WaffleWidget: React.FC<DefaultWidgetComponentProps & { widgetData: MetricsDataV2 }> = (
  props
) => {
  const { widgetData, nivoProps } = props;

  if (widgetData == null) {
    return <WidgetLoading />;
  }
  // @ts-ignore
  const data = widgetData.results[0].y_axis.map((item) => ({ ...item, value: item.values[0][1] }));

  const nameMap = data.reduce((acc, current) => ({ ...acc, [current.id]: current.label }), {});

  const totalCompleted = widgetData.results[0].y_axis.reduce(
    // @ts-ignore
    (acc, item) => acc + item.values[0][1],
    0
  );
  // const total = widgetData.results[0].y_axis.reduce(
  //   // @ts-ignore
  //   (acc, item) => acc + item.values[0][0],
  //   0
  // );

  if (data.length === 0) {
    return <WidgetEmptyState />;
  }

  return (
    <ResponsiveWaffle
      data={data}
      total={totalCompleted * 4}
      rows={18}
      columns={14}
      margin={{ top: 10, right: 10, bottom: 10, left: 120 }}
      // @ts-ignore
      legendLabel={(id: string): string => nameMap[id]}
      colors={[
        // greens dark to light
        '#65A782',
        '#93C6A8',
        '#B6DDC6',
        // yellows dark to light
        '#F4A21F',
        '#FFCB48',
        '#FFEDA1',
      ]}
      borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
      animate
      motionStiffness={90}
      motionDamping={11}
      onMouseEnter={(): Promise<void> => logEvent('Dashboard-hover-waffle-widget')}
      legends={[
        {
          anchor: 'top-left',
          direction: 'column',
          justify: false,
          translateX: -100,
          translateY: 0,
          itemsSpacing: 4,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          itemTextColor: '#777',
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
                itemBackground: '#f7fafb',
              },
            },
          ],
        },
      ]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...nivoProps}
    />
  );
};

export default withWidget({ fetchOnePeriod: true })(
  WaffleWidget as React.FC<DefaultWidgetComponentProps>
);
