// import { SentimentOverTime } from 'types';
import { ResponsiveTreeMapHtml } from '@nivo/treemap';
import { setURLParams } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import Select from 'components/Select';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { GlobalState } from 'reducers';
import { getCommonWordEntities, getCommonWords, getCommonWordsLoading } from 'selectors/analytics';
import DashboardWidget from './DashboardWidget';

const DashboardCommonWords: React.FC = () => {
  const dispatch = useDispatch();
  const commonWordEntities = useSelector(getCommonWordEntities);
  const loading = useSelector(getCommonWordsLoading);
  const [entityChoice, setEntityChoice] = useState('nouns');
  const commonWords = useSelector((state: GlobalState) => getCommonWords(state, entityChoice));

  const colors = {
    0: '#3690C0',
  };
  const getColors = (): string => colors[0];

  // @ts-ignore
  const handleClick = (node): void => {
    const { name } = node.data;

    dispatch(
      setURLParams({
        [`${resourceQueryParamName.analytics}__broad_search`]: name,
      })
    );
  };

  const spinner = (
    <div className="flex items-center justify-center h-12">
      <LoadingIndicator className="ml-2" size="8" />
    </div>
  );
  if ((!commonWordEntities || commonWords?.children.length === 0) && !loading)
    return (
      <div className="flex justify-center mt-2">
        <span>There is no data to show for common words</span>
      </div>
    );

  const body = (
    <div className="h-72 mt-4 overflow-hidden font-bold">
      {commonWordEntities && (
        <div className="w-1/4">
          <Select
            onChange={setEntityChoice}
            options={commonWordEntities}
            value={entityChoice as string}
          />
        </div>
      )}

      <ResponsiveTreeMapHtml
        data={commonWords}
        identity="name"
        value="total"
        valueFormat=".02s"
        margin={{ top: 0, right: 5, bottom: 50, left: 0 }}
        label="id"
        labelSkipSize={10}
        enableParentLabel={false}
        leavesOnly
        innerPadding={4}
        borderWidth={0}
        labelTextColor="#325e7a"
        colors={getColors}
        onClick={(data): void => {
          handleClick(data);
        }}
      />
    </div>
  );

  return (
    <DashboardWidget
      title="Common Words"
      className="h-dashboard-campaign-performance"
      body={loading ? spinner : body}
    />
  );
};

export default DashboardCommonWords;
