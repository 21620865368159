const daysAgo = (date: string | number | Date): string => {
  const today = new Date();
  const createdOn = new Date(date);
  const msInDay = 24 * 60 * 60 * 1000;

  createdOn.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  const diff = Number(((+today - +createdOn) / msInDay).toFixed());

  if (diff === 0) return 'Today';
  if (diff === 1) return 'Yesterday';
  return `${diff} Days Ago`;
};
export default daysAgo;
