/* eslint-disable max-lines */
import React from 'react';
import RuleManagerTable from './RuleManagerTable';

const RulesCompareRightPanel: React.FC = () => (
  //   const dispatch = useDispatch();
  // const loading = useSelector(getUsersLoading);

  <div className="w-full py-4">
    <RuleManagerTable
      side="right"
      setIdToMove={(): void => {
        // moot
      }}
      idToMove=""
      collapseLevel={10}
      setLevelIsModified={(): void => {
        // moot
      }}
      setCollapseLevel={(): void => {
        // moot
      }}
    />
  </div>
);
export default RulesCompareRightPanel;
