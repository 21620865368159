import React from 'react';

type ComponentProps = {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  or?: boolean;
  disabled?: boolean;
};

const AndOrBadge: React.FC<ComponentProps> = ({ or, onClick, disabled }) => (
  <div
    data-testid="container"
    className={`inline-block rounded ${
      !disabled ? 'bg-litlingo-info-light' : 'bg-litlingo-gray-1'
    }`}
  >
    <span data-testid="text" className="text-small flex py-0.5 px-1 items-center">
      <button
        data-testid=""
        onClick={onClick}
        className={`flex text-xss focus:outline-none outline-none font-bold ${
          !disabled ? 'cursor-pointer' : 'cursor-default'
        }`}
        type="button"
      >
        <span className="align-center">{or ? 'OR' : 'AND'}</span>
      </button>
    </span>
  </div>
);

export default AndOrBadge;
