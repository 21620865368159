import { FAIL_UPLOAD, SUCCESS_UPLOADED } from 'constants/customerIcons';
import React from 'react';

type ComponentProps = {
  ok: boolean | null;
  message: string;
};

const VerifyCredentialsMsg: React.FC<ComponentProps> = ({ ok, message }) => {
  const okMessage = (
    <div className="flex flex-row items-center">
      {SUCCESS_UPLOADED}
      <span className="litlingo-green-color text-xs font-bold pl-4">{message}</span>
    </div>
  );
  const failMessage = (
    <div className="flex flex-row items-center">
      {FAIL_UPLOAD}
      <span className="litlingo-red-color text-xs font-bold pl-2">{message}</span>
    </div>
  );
  return <>{ok ? okMessage : failMessage}</>;
};

export default VerifyCredentialsMsg;
