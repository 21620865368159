import { deleteCampaign, upsertCampaign } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import Modal from 'components/Modal';
import platform, {
  allPlatforms,
  areasToMonitor,
  notImplementedPlatforms,
} from 'constants/platform';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { MCampaign } from 'types';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
  campaignToEdit?: MCampaign;
  isCreating?: boolean;
};

type PlatformsType = {
  [K in keyof typeof platform]?: boolean;
};

type AreasToMonitorType = {
  [K in keyof typeof areasToMonitor]?: boolean;
};

const EditCampaignModal: React.FC<ComponentProps> = ({
  isModalOpen,
  toggleModalOpen,
  campaignToEdit,
  isCreating,
}) => {
  const dispatch = useDispatch();
  const campaign = campaignToEdit;

  const [isDeletingCampaign, setIsDeletingCampaign] = useState(false);

  const platformDefaults: PlatformsType = {};
  (Object.keys(platform) as Array<keyof typeof platform>).forEach((p) => {
    platformDefaults[p] = campaign ? campaign[p] : false;
  });

  const areasDefaults: AreasToMonitorType = {};
  (Object.keys(areasToMonitor) as Array<keyof typeof areasToMonitor>).forEach((p) => {
    areasDefaults[p] = campaign ? campaign[p] : false;
  });

  const { register, getValues, handleSubmit } = useForm<
    PlatformsType &
      AreasToMonitorType & {
        name: string;
        description: string;
        active: string;
      }
  >({
    defaultValues: {
      name: campaign?.name,
      description: campaign?.description || '',
      active: campaign?.active.toString(),
      ...platformDefaults,
      ...areasDefaults,
    },
  });

  const deleteButtonStyle = 'text-litlingo-alert underline self-end focus:outline-none';

  const onSubmit = (): void => {
    const { name, description, active, ...rest } = getValues();

    dispatch(
      upsertCampaign({
        ...campaign,
        name,
        description,
        active: active === 'true',
        redirect: isCreating,
        ...rest,
      })
    );
    toggleModalOpen();
  };

  const handleSaveButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const toggleConfirmModal = (): void => setIsDeletingCampaign(!isDeletingCampaign);

  const handleDelete = (): void => {
    if (campaignToEdit) {
      dispatch(deleteCampaign({ id: campaignToEdit.uuid }));
      setIsDeletingCampaign(false);
      toggleConfirmModal();
    }
  };

  const renderPlatforms = (): JSX.Element[] =>
    Object.entries(allPlatforms).map(([key, p]) => (
      <label
        htmlFor={`${key}-checkbox`}
        className="flex flex-row gap-2 text-body items-center"
        key={key}
      >
        <input
          disabled={Object.keys(notImplementedPlatforms).includes(key)}
          id={`${key}-checkbox`}
          ref={register()}
          type="checkbox"
          name={key}
          className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out"
        />
        <div className="flex flex-row gap-1 text-body">{p}</div>
      </label>
    ));

  const renderAreas = (): JSX.Element[] =>
    Object.entries(areasToMonitor).map(([key, p]) => (
      <label
        htmlFor={`${key}-checkbox`}
        className="flex flex-row gap-2 text-body items-center"
        key={key}
      >
        <input
          disabled={Object.keys(notImplementedPlatforms).includes(key)}
          id={`${key}-checkbox`}
          ref={register()}
          type="checkbox"
          name={key}
          className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out"
        />
        <div className="flex flex-row gap-1 text-body">{p}</div>
      </label>
    ));

  const renderModelForm = (
    <div className="pt-4 pb-4">
      <form className="flex flex-col items-start">
        <div className="flex flex-col items-start gap-4 w-full">
          <label htmlFor="name" className="flex flex-col gap-1 mt-2 text-body">
            <div className="flex flex-row gap-1 text-small">
              <span>Name</span>
              <span className="litlingo-red-color">*</span>
            </div>

            <input
              id="name"
              ref={register({ required: true, pattern: /\S/i })}
              name="name"
              placeholder="Use case name"
              className="form-input h-9 w-66 p-2 text-body rounded placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>

          <label htmlFor="description" className="flex flex-col gap-1 mt-2 text-body">
            <div className="flex flex-row gap-1 text-small">
              <span>Description</span>
            </div>

            <textarea
              id="description"
              ref={register()}
              name="description"
              rows={3}
              placeholder="Use this description to help others understand the purpose of this use case."
              className="form-input w-112 p-2 text-body rounded resize-none placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>
          <div className="flex flex-col gap-1">
            <div className="text-body">Set Use Case as:</div>
            <div className="flex flex-row gap-5">
              <label htmlFor="radio-no" className="flex flex-row gap-2 text-body">
                <input
                  type="radio"
                  id="radio-no"
                  ref={register()}
                  name="active"
                  value="false"
                  className="input-radio"
                  defaultChecked
                />
                <div className="flex flex-row gap-1">Inactive</div>
              </label>
              <label htmlFor="radio-yes" className="flex flex-row gap-2 text-body">
                <input
                  type="radio"
                  id="radio-yes"
                  ref={register()}
                  name="active"
                  value="true"
                  className="input-radio"
                  aria-hidden
                />
                <div className="flex flex-row gap-1">Active</div>
              </label>
            </div>
          </div>

          <div className="w-full border-bottom" />

          <div className="w-full">
            <span className="text-heading-3">Settings</span>
            <div className="flex flex-row pt-2">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-body">Platforms</span>
                <div className="flex flex-col gap-2 pl-4">{renderPlatforms()}</div>
              </div>
              <div className="flex flex-col w-full">
                <span className="text-body">Areas to monitor</span>
                <div className="flex flex-col gap-2 pl-4">{renderAreas()}</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderModelForm}
          title={isCreating ? 'Create new Use Case' : 'Edit Use Case'}
          okButton
          okButtonText="Save"
          okButtonOnClick={handleSaveButton}
          toggleShowModal={toggleModalOpen}
          secondaryButton={campaignToEdit !== undefined}
          secondaryButtonOnclick={toggleConfirmModal}
          secondaryButtonStyle={deleteButtonStyle}
          secondaryButtonText="Delete Use Case"
        />
      )}
      {isDeletingCampaign && (
        <ConfirmModal
          text="Are you sure you want to delete this Use Case?"
          okButtonText="Yes"
          cancelButtonText="No"
          data-testid="delete-modal"
          okButtonOnClick={handleDelete}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </>
  );
};

export default EditCampaignModal;
