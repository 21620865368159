/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { addSelectedFilter, removeSelectedFilter } from 'actions';
import { TAG_REMOVE_ICON } from 'constants/filterIcons';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getSelectedFilters } from 'selectors/envelopes';
import { useSelector } from 'store';
import { ValueNode } from 'utils/parserTree';

type ComponentProps = {
  title: string;
  deleteAction?: () => void;
  customLeftIcon?: JSX.Element | false;
  negated?: boolean;
  node?: ValueNode;
  disabled?: boolean;
};

const FilterBadge: React.FC<ComponentProps> = ({
  negated = false,
  title,
  deleteAction,
  customLeftIcon,
  disabled,
  node,
}) => {
  const selectedFilters = useSelector(getSelectedFilters);
  const dispatch = useDispatch();

  const handleClick = (): void => {
    if (!node) return;
    if (selectedFilters.some((filter) => filter.id === node.id)) {
      dispatch(removeSelectedFilter({ node }));
      return;
    }
    dispatch(addSelectedFilter({ node }));
  };
  const bgColor = useMemo(() => {
    if (node && selectedFilters.find((filter) => filter.id === node.id)) {
      return 'bg-litlingo-primary-60';
    }
    if (negated) {
      return 'bg-litlingo-alert-light';
    }
    return 'bg-litlingo-success-light';
  }, [negated, node, selectedFilters]);

  return (
    <div data-testid={`container-badge-${title}`} className={`inline-block rounded ${bgColor}`}>
      <span data-testid="text" className="text-small flex my-0 px-1 py-0.5 items-center gap-1">
        {customLeftIcon && <span className="mr-2">{customLeftIcon}</span>}
        <button
          data-testid="filter-badge"
          type="button"
          onClick={(e): void => {
            e.stopPropagation();
            if (!disabled) handleClick();
          }}
          role={node?.id ?? 'button'}
          className="focus:outline-none"
        >
          {title}
        </button>
        {deleteAction && !disabled && (
          <button
            data-testid="delete-button"
            onClick={(e): void => {
              e.stopPropagation();
              deleteAction();
            }}
            className="focus:outline-none outline-none"
            type="button"
          >
            {TAG_REMOVE_ICON}
          </button>
        )}
      </span>
    </div>
  );
};

export default FilterBadge;
