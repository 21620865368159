/* eslint-disable max-lines */
/* eslint-disable camelcase */
/* eslint-disable react/require-default-props */
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { setURLParams } from 'actions/nav';
import LoadingIndicator from 'components/LoadingIndicator';
import useClickOutside from 'components/utils/useClickOutside';
import { SEARCH_ICON } from 'constants/filterIcons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { Selector } from 'types';

type MinimumResource = {
  uuid: string;
  color?: string | null;
  envelopes_count?: number;
};

type ResourceFilterProps<T extends MinimumResource, A> = {
  resource: string;
  className?: string;
  filterKey: string;
  fetchAction?: A;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchParams?: Record<string, any>;
  searchKey?: string;
  getResourceList: Selector<T[]>;
  getLoading: Selector<boolean>;
  title?: string;
  nameKey: keyof T;
  itemField?: keyof T;
  notFoundText?: string;
  dataTestid?: string;
};

const DropdownFilter = <
  T extends MinimumResource,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  A extends ActionCreatorWithOptionalPayload<any, string>
>(
  props: ResourceFilterProps<T, A>
): ReturnType<React.FC> => {
  const {
    className,
    resource,
    fetchAction,
    fetchParams,
    filterKey,
    searchKey,
    itemField = 'uuid',
    getResourceList,
    getLoading,
    title = '',
    nameKey,
    notFoundText,
    dataTestid,
  } = props;

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

  const params = useSelector(getNavParamsByResource(resource));
  const allData = useSelector(getResourceList);
  const loading = useSelector(getLoading);

  const filterData = params[filterKey] ?? [];

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  useEffect(() => {
    if (isOpen && fetchAction != null) {
      dispatch(
        fetchAction({
          limit: 25,
          include_envelopes_count: false,
          [searchKey || 'searchValue']: debouncedSearchValue,
          ...fetchParams,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen, fetchAction, debouncedSearchValue]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedSearchValue(searchValue), 300);
    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  const handleButtonClick = (): void => {
    if (!isOpen) {
      setDebouncedSearchValue('');
      setSearchValue('');
    }

    setIsOpen(true);
  };

  const handleDelete = (labelId: string): void => {
    dispatch(
      setURLParams({
        [`${resource}__${filterKey}`]: [...filterData].filter((s) => s !== labelId),
        [`${resource}__offset`]: '0',
      })
    );
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, item: T): void => {
    const id = item.uuid;
    const value = item[itemField];
    const tagName = typeof value === 'string' ? value : id;

    let tagsNames = [...filterData];

    if (event.target.checked) {
      tagsNames.push(tagName);
      dispatch(
        setURLParams({
          [`${resource}__${filterKey}`]: tagsNames,
          [`${resource}__offset`]: '0',
        })
      );
    } else {
      tagsNames = tagsNames.filter((s) => s !== tagName);
      handleDelete(tagName);
    }

    event.stopPropagation();
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e?.target.value.toLowerCase();
    setSearchValue(value);
  };

  const renderOptions = (): JSX.Element => {
    if (loading)
      return (
        <div className="w-full min-h-select-dropdown flex justify-center items-center">
          <LoadingIndicator />
        </div>
      );

    return (
      <fieldset className="m-2 flex flex-col gap-2" data-testid="users-list">
        {allData.length > 0 ? (
          allData.sort().map((item, idx) => (
            <div key={item.uuid} data-testid={`option-${idx}`} className="cursor-pointer">
              <label htmlFor={`option-${item.uuid}`} className="flex flex-row items-center gap-1">
                <input
                  type="checkbox"
                  id={`option-${item.uuid}`}
                  data-testid={`user-select-button-${idx}`}
                  onChange={(e): void => handleInputChange(e, item)}
                  checked={filterData.includes(item.uuid)}
                  className="form-checkbox litlingo-checkbox"
                />

                <div className="text-small select-none break-all">{item[nameKey]}</div>
              </label>
            </div>
          ))
        ) : (
          <div className="text-small">{notFoundText || 'No items found'}</div>
        )}
      </fieldset>
    );
  };

  return (
    <div ref={wrapperRef} className={`relative inline-block text-left w-full ${className || ''}"`}>
      <div className="rounded-md shadow-sm">
        <div className="flex flex-row items-center w-full h-8 bg-white">
          <div className="absolute left-0 ml-1.5 w-5">{SEARCH_ICON()}</div>
          <input
            id="search"
            name="search"
            data-testid={dataTestid}
            className="form-input h-full w-full text-litlingo-gray-6 pl-8 text-body placeholder-italic placeholder-litlingo-gray-6"
            placeholder={title}
            onChange={handleSearchChange}
            onFocus={handleButtonClick}
            autoComplete="off"
          />
        </div>
      </div>

      {isOpen && (
        <div className="origin-top-left absolute left-0 mt-1 w-full rounded-md shadow-lg z-40">
          <div className="rounded-md bg-white shadow-xs">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="max-h-select-dropdown overflow-auto custom-scrollbar-select-dropdown">
                {renderOptions()}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownFilter;
