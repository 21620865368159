import { clearAlert } from 'actions';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import Transition from 'styles/Transition';

const AdvanceQueryAlert: React.FC = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alerts.alert);

  if (alert == null) {
    return null;
  }

  const handleCloseAlert = (): void => {
    dispatch(clearAlert());
  };

  return (
    <div className="z-alert fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
      <Transition
        show
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="max-w-332 w-full bg-white shadow-lg rounded-lg pointer-events-auto">
          <div className="rounded-md bg-litlingo-white p-4">
            <div className="flex gap-2 items-start justify-between">
              <div className="w-5 flex-shrink-0">
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 20 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0.948242C7.34784 0.948242 4.8043 2.06424 2.92894 4.05074C1.05358 6.03724 0 8.7315 0 11.5408C0 14.3502 1.05358 17.0444 2.92894 19.0309C4.8043 21.0174 7.34784 22.1334 10 22.1334C12.652 22.1329 15.1953 21.0168 17.0706 19.0304C18.9458 17.044 19.9995 14.35 20 11.5408C20 8.7315 18.9464 6.03724 17.0711 4.05074C15.1957 2.06424 12.6522 0.948242 10 0.948242ZM13.9678 14.9543C14.0661 15.0586 14.1213 15.1999 14.1213 15.3472C14.1213 15.4945 14.0661 15.6358 13.9678 15.7401C13.8695 15.8444 13.7361 15.903 13.5969 15.9031C13.4578 15.903 13.3244 15.8444 13.226 15.7401L10.007 12.3303L6.78622 15.7401C6.68789 15.8444 6.55448 15.903 6.41533 15.9031C6.27618 15.903 6.14278 15.8444 6.04444 15.7401C5.94616 15.6358 5.89095 15.4945 5.89095 15.3472C5.89095 15.1999 5.94616 15.0586 6.04444 14.9543L9.26522 11.5445L6.04444 8.1329C5.94616 8.02866 5.89095 7.88736 5.89095 7.74003C5.89095 7.5927 5.94616 7.4514 6.04444 7.34716C6.14285 7.24305 6.27625 7.18458 6.41533 7.18458C6.55442 7.18458 6.68781 7.24305 6.78622 7.34716L10.007 10.7588L13.226 7.34716C13.3244 7.24305 13.4578 7.18458 13.5969 7.18458C13.736 7.18458 13.8694 7.24305 13.9678 7.34716C14.0661 7.4514 14.1213 7.5927 14.1213 7.74003C14.1213 7.88736 14.0661 8.02866 13.9678 8.1329L10.7488 11.5445L13.9678 14.9543Z"
                    fill="#F8351A"
                  />
                </svg>
              </div>

              <div className="text-base text-black">
                The search string could not be processed, double check your string. Common issues
                are ending quotes and parenthesis. Refer to{' '}
                <a
                  className="text-base underline text-litlingo-primary cursor-pointer font-normal"
                  href="https://lit-public-static-content.s3.us-east-2.amazonaws.com/LitLingo+Search+Syntax+(1).pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  types of queries
                </a>
                for more help.
              </div>
              <button
                className="cursor-pointer h-5 focus:outline-none"
                type="button"
                onClick={handleCloseAlert}
              >
                <svg
                  width="20"
                  height="23"
                  viewBox="0 0 20 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.80005 3.91406L17.36 19.339"
                    stroke="#333333"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.36 3.91406L2.80005 19.339"
                    stroke="#333333"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default AdvanceQueryAlert;
