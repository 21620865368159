import type { ErrorObject, Selector } from 'types';

export const getFileUploadsError: Selector<ErrorObject | null> = (state) => state.fileUploads.error;

export const getFileUploadsProgress: Selector<number | null> = (state) =>
  state.fileUploads.progress;

export const getFileUploadsSuccess: Selector<boolean> = (state) => state.fileUploads.success;

export const getFileUploadsUploading: Selector<boolean> = (state) => state.fileUploads.uploading;

export const getFileUploadsActualProvider: Selector<string | null> = (state) =>
  state.fileUploads.actualProvider;
