import type { ErrorData } from '@litlingo/client';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { API, AuditLog, NormalizedResource, UUID } from 'types';

type Loading = 'audit-logs' | 'changelog';

export type AuditLogsState = {
  error: ErrorData | null;
  loading: Loading[];
  items: NormalizedResource<AuditLog>;
  list: UUID[] | null;
};

type AuditLogsReducer<P = void> = (state: AuditLogsState, action: PayloadAction<P>) => void;

const initialState: AuditLogsState = {
  error: null,
  loading: [],
  items: {},
  list: null,
};

const handleFetchAuditLogs: AuditLogsReducer = (state) => {
  state.loading.push('audit-logs');
  state.error = null;
};

type FetchAuditLogsSuccessPayload = API.WrappedAPIResponse<AuditLog>;
const handleFetchAuditLogsSuccess: AuditLogsReducer<FetchAuditLogsSuccessPayload> = (
  state,
  action
) => {
  state.items = action.payload.records.reduce(
    (curr, log) => ({ ...curr, [log.uuid]: log }),
    state.items
  );
  state.list = action.payload.records.map((log) => log.uuid);
};

type FetchAuditLogsFailurePayload = ErrorData;
const handleFetchAuditLogsFailure: AuditLogsReducer<FetchAuditLogsFailurePayload> = (
  state,
  action
) => {
  state.error = action.payload;
};

const handleFetchAuditLogsFulfill: AuditLogsReducer = (state) => {
  state.loading = state.loading.filter((l) => l !== 'audit-logs');
};

const handleFetchChangelog: AuditLogsReducer = (state) => {
  state.loading.push('changelog');
  state.error = null;
};

export type FetchChangelogSuccessType = {
  auditlogUuid: UUID;
  changelog: string[];
};

const handleFetchChangelogSuccess: AuditLogsReducer<FetchChangelogSuccessType> = (
  state,
  action
) => {
  const { auditlogUuid, changelog } = action.payload;

  state.items[auditlogUuid] = { ...state.items[auditlogUuid], changelog };
};

type FetchChangelogFailurePayload = ErrorData & { message: string; auditlogUuid: UUID };
const handleFetchChangelogFailure: AuditLogsReducer<FetchChangelogFailurePayload> = (
  state,
  action
) => {
  const { message, auditlogUuid } = action.payload;
  state.error = action.payload;
  state.items[auditlogUuid] = { ...state.items[auditlogUuid], changelog: [message] };
};

const handleFetchChangelogFulfill: AuditLogsReducer = (state) => {
  state.loading = state.loading.filter((l) => l !== 'changelog');
};

const auditLogsSlice = createSlice({
  name: 'auditLogs',
  initialState,
  reducers: {
    fetchAuditLogs: handleFetchAuditLogs,
    fetchAuditLogsSuccess: handleFetchAuditLogsSuccess,
    fetchAuditLogsFailure: handleFetchAuditLogsFailure,
    fetchAuditLogsFulfill: handleFetchAuditLogsFulfill,
    fetchChangelogRequest: handleFetchChangelog,
    fetchChangelogSuccess: handleFetchChangelogSuccess,
    fetchChangelogFailure: handleFetchChangelogFailure,
    fetchChangelogFulfill: handleFetchChangelogFulfill,
  },
});

const { actions, reducer } = auditLogsSlice;

export const {
  fetchAuditLogs,
  fetchAuditLogsSuccess,
  fetchAuditLogsFailure,
  fetchAuditLogsFulfill,
  fetchChangelogRequest,
  fetchChangelogSuccess,
  fetchChangelogFailure,
  fetchChangelogFulfill,
} = actions;

export default reducer;
