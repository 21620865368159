import { fetchSingleCategory } from 'actions';
import CategoriesList from 'components/CategoriesList';
import Category from 'components/Category';
import { RouteSpec, SuperRouteSpec } from 'types';

const categoriesRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'categories-list',
    path: '/:customerDomain/categories',
    component: CategoriesList,
    private: true,
    permissions: 'categories.list',
    data: {
      params: {
        include_count: {
          defaultValue: '1',
        },
        customer_owned: { defaultValue: 'true' },
        categories: {
          nestedResource: true,
          params: {
            order_by: {
              defaultValue: 'updated_at',
              options: ['created_at', 'type', 'name'],
            },
            order_desc: {
              defaultValue: 'true',
              options: ['true', 'false'],
            },
            offset: {
              defaultValue: '0',
            },
            limit: {
              defaultValue: '25',
            },
            name: {},
            broad_search: {},
          },
        },
      },
    },
  },
  {
    name: 'category-manager',
    path: '/:customerDomain/category/:categoryId',
    component: Category,
    private: true,
    permissions: 'categories.retrieve',
    data: {
      idFromPath: true,
      actions: [fetchSingleCategory],
      params: {
        body: {},
        include_count: {
          defaultValue: '1',
        },
        customer_owned: { defaultValue: 'true' },
        test_cases: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            broad_search: {},
            order_desc: {
              defaultValue: 'true',
            },
            order_by: {
              defaultValue: 'created_at',
              options: [
                'created_at',
                'created_by_uuid',
                'test_string',
                'should_match',
                'most_recent_run_passed',
              ],
            },
            match_criterias: {
              list: true,
            },
          },
        },
        audit_logs: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '10',
            },
            offset: {
              defaultValue: '0',
            },
            relationships: {
              defaultValue: ['updated_by'],
            },
            include_count: {
              defaultValue: '1',
            },
            order_by: {
              defaultValue: 'updated_at',
            },
            order_desc: {
              defaultValue: 'true',
            },
          },
        },
      },
    },
  },
];
export default categoriesRoutes;
