/* eslint-disable camelcase */
import { createAction } from '@reduxjs/toolkit';

const changeThreadHighlightMode = createAction<boolean>('ENVELOPE_VIEW/CHANGE_THREAD_HIGHLIGHT');
export const changeTranslationMode = createAction<boolean>('ENVELOPE_VIEW/CHANGE_TRANSLATION');
export const openAttachment = createAction<boolean>('ENVELOPE_VIEW/OPEN_ATTACHMENT');
export const collapseLeftPanel = createAction<boolean>('ENVELOPE_VIEW/COLLAPSE_LEFT_PANEL');
export const showMoreLikeThis = createAction<boolean>('ENVELOPE_VIEW/SHOW_MORE_LIKE_THIS');

export default changeThreadHighlightMode;
