import {
  deletePrompt,
  deletePromptFailure,
  deletePromptRequest,
  deletePromptSuccess,
  fetchEntities,
  fetchEntitiesFailure,
  fetchEntitiesFulfill,
  fetchEntitiesRequest,
  fetchEntitiesSuccess,
  fetchPrompts,
  fetchPromptsFailure,
  fetchPromptsFulfill,
  fetchPromptsRequest,
  fetchPromptsSuccess,
  fetchSinglePrompt,
  fetchSinglePromptFailure,
  fetchSinglePromptRequest,
  fetchSinglePromptSuccess,
  mergeEntities,
  mergeEntitiesFailure,
  mergeEntitiesRequest,
  mergeEntitiesSuccess,
  upsertPrompt,
  upsertPromptFailure,
  upsertPromptRequest,
  upsertPromptSuccess,
} from 'actions';
import { apiClient as LitLingoClient } from 'client';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getNavParamsByResource } from 'selectors/nav';
import { API, SagaReturn } from 'types';

function* fetchSinglePromptsaga(action: ReturnType<typeof fetchSinglePrompt>): SagaReturn {
  const { payload } = action;
  yield put(fetchSinglePromptRequest());
  const response = (yield call(
    [LitLingoClient.resources.prompts, 'retrieve'],
    payload.promptId
  )) as API.Response<API.Prompts.Retrieve>;
  if (response.error != null) {
    yield put(fetchSinglePromptFailure(response.error));
  } else {
    yield put(fetchSinglePromptSuccess(response.data));
  }
}

function* fetchEntitiesSaga(): SagaReturn {
  yield put(fetchEntitiesRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.entities)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const response = (yield call([LitLingoClient.resources.entities, 'list'], {
    params: {
      relationships: ['entity_impressions', 'entity_impressions.divisions'],
      ...resourceParams,
      include_count: true,
    },
  })) as API.Response<API.Entities.List>;

  if (response.error != null) {
    yield put(fetchEntitiesFailure(response.error));
  } else {
    yield put(fetchEntitiesSuccess(response.data));
  }

  yield put(fetchEntitiesFulfill());
}
function* mergeEntitiesSaga({ payload }: ReturnType<typeof mergeEntities>): SagaReturn {
  const { source, destination } = payload;

  yield put(mergeEntitiesRequest());

  const response = (yield call([LitLingoClient.resources.entities.extras, 'merge'], {
    data: {
      entity_uuid: source,
      destination_entity_uuid: destination,
    },
  })) as API.Response<API.Entities.Merge>;

  if (response.error != null) {
    yield put(mergeEntitiesFailure(response.error));
  } else {
    yield put(mergeEntitiesSuccess(source));
  }
}

function* fetchPromptsSaga(action: ReturnType<typeof fetchPrompts>): SagaReturn {
  const { kind } = action?.payload || {};

  yield put(fetchPromptsRequest());

  const params: { include_count: boolean; kind?: string } = {
    include_count: true,
  };
  if (kind) {
    params.kind = kind;
  }

  const response = (yield call([LitLingoClient.resources.prompts, 'list'], {
    params,
  })) as API.Response<API.Prompts.List>;

  if (response.error != null) {
    yield put(fetchPromptsFailure(response.error));
  } else {
    yield put(fetchPromptsSuccess(response.data));
  }

  yield put(fetchPromptsFulfill());
}

function* upsertPromptSaga(action: ReturnType<typeof upsertPrompt>): SagaReturn {
  const { payload } = action;

  yield put(upsertPromptRequest());

  const response = (yield call([LitLingoClient.resources.prompts, 'upsert'], {
    data: payload,
  })) as API.Response<API.Prompts.Upsert>;

  if (response.error != null) {
    yield put(upsertPromptFailure(response.error));
  } else {
    yield put(upsertPromptSuccess(response.data));
  }
}

function* deletePromptSaga(action: ReturnType<typeof deletePrompt>): SagaReturn {
  const { uuid } = action.payload;

  yield put(deletePromptRequest());

  const response = (yield call(
    [LitLingoClient.resources.prompts, 'delete'],
    uuid
  )) as API.Response<API.Prompts.Upsert>;

  if (response.error != null) {
    yield put(deletePromptFailure(response.error));
  } else {
    yield put(deletePromptSuccess({ uuid }));
  }
}

function* entitiesSaga(): SagaReturn {
  yield takeLatest(fetchSinglePrompt.toString(), fetchSinglePromptsaga);
  yield takeLatest(fetchEntities.toString(), fetchEntitiesSaga);
  yield takeLatest(mergeEntities.toString(), mergeEntitiesSaga);
  yield takeLatest(fetchPrompts.toString(), fetchPromptsSaga);
  yield takeLatest(upsertPrompt.toString(), upsertPromptSaga);
  yield takeLatest(deletePrompt.toString(), deletePromptSaga);
}

export default entitiesSaga;
