import {
  APIClient,
  V1APISpec as APISpec,
  V2APISpec as APISpecV2,
  AccountsSpec,
  AuthClient,
} from '@litlingo/client';
import { merge } from '@litlingo/client/dist/utils';
import config from 'config';

// Auth client
const authClient = new AuthClient(config.litlingo.clientId);

// Api Client
const apiSpec = merge(APISpec, {
  base: config.backend.base,
  logs: config.node.env !== 'production',
});
const apiClient = new APIClient(apiSpec, authClient);

// Api Client V2
const apiSpecV2 = merge(APISpecV2, {
  logs: config.node.env !== 'production',
});
const apiClientV2 = new APIClient(apiSpecV2, authClient);

// Account client
const accountsSpec = merge(AccountsSpec, {
  logs: config.node.env !== 'production',
});

const accountsClient = new APIClient(accountsSpec, authClient);

// // Base client.
const baseSpec = {
  base: config.backend.base,
  logs: apiSpec.logs,

  headers: apiSpec.headers,

  resources: {
    logMetrics: {
      path: '/queue-stats',
      include: [],
      extras: {
        logMetrics: {
          path: '',
          method: 'GET',
        },
      },
    },
  },
} as const;

const baseClient = new APIClient(baseSpec, authClient);

export { accountsClient, apiClient, apiClientV2, authClient, baseClient };
