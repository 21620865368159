import { apiClient as LitLingoClient } from 'client';
import {
  fetchAuditLogs,
  fetchAuditLogsFailure,
  fetchAuditLogsFulfill,
  fetchAuditLogsSuccess,
} from 'reducers/auditLogs';
import { call, put, takeLatest } from 'redux-saga/effects';
import type { API, ErrorData, SagaReturn } from 'types';

function* fetchAuditLogsSaga(): SagaReturn {
  try {
    const response = (yield call([
      LitLingoClient.resources.customers.extras,
      'auditLogs',
    ])) as API.Response<API.Customers.AuditLogs>;
    if (response.error != null) {
      yield put(fetchAuditLogsFailure(response.error));
    } else {
      yield put(fetchAuditLogsSuccess(response.data));
    }
  } catch (error) {
    const errorData = error as ErrorData;
    yield put(fetchAuditLogsFailure(errorData));
  } finally {
    yield put(fetchAuditLogsFulfill());
  }
}

function* auditLogsSaga(): SagaReturn {
  yield takeLatest(fetchAuditLogs, fetchAuditLogsSaga);
}

export default auditLogsSaga;
