/* eslint-disable react/jsx-props-no-spreading */
import {
  cloneIdentifier,
  deleteIdentifier,
  fetchIdentifierTypes,
  saveIdentifierRequest,
  setCurrentTestIdentifierRevisionId,
  setShowEditIdentifier,
  setShowUtilization,
  upsertIdentifier,
} from 'actions/identifier';
import ConfirmModal from 'components/ConfirmModal';
import CustomerSimpleList from 'components/CustomerSimpleList';
import IdentifierAuditLogsTable from 'components/Identifier/IdentifierAuditLogsTable';
import RenderOptionsConfig from 'components/Identifier/RenderOptionsConfig';
import LanguageMatcherRedesign from 'components/LanguageMatcherRedesign';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import GlobalNavbar from 'components/Navbar/GlobalNavbar';
import keyMap from 'constants/configHotKeys';
import { fieldTypes } from 'constants/fieldTypes';
import React, { useEffect, useState } from 'react';

import { FormContext, useForm } from 'react-hook-form';
import { GlobalHotKeys } from 'react-hotkeys';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getIdentifierRequestLoading,
  getIdentifierTypes,
  getSelectedIdentifier,
  getSelectedIdentifierRevision,
  getShowEditIdentifier,
} from 'selectors/identifier';
import { useSelector } from 'store';
import type { Identifier as IdentifierT, UUID } from 'types';
import EditIdentifierModal from './EditIdentifierModal';
import IdentifierCategoriesTable from './IdentifierCategoriesTable';
import IdentifierDetailModal from './IdentifierDetailModal';
import IdentifierSidebar from './Sidebar';

type RouterParams = {
  identifierId: UUID;
  identifierRevisionId: UUID;
};

const Identifier: React.FC = () => {
  const { identifierId, identifierRevisionId } = useParams<RouterParams>();
  const dispatch = useDispatch();
  const identifier = useSelector(getSelectedIdentifier);
  const identifierRevision = useSelector(getSelectedIdentifierRevision);
  const types = useSelector(getIdentifierTypes);
  const loading = useSelector(getIdentifierRequestLoading);
  const editIdentifierModal = useSelector(getShowEditIdentifier);

  const methods = useForm<IdentifierT>();

  const [isDeletingIdentifier, setIsDeletingIdentifier] = useState(false);
  const [isCloneIdentifierOpen, setIsCloneIdentifierOpen] = useState(false);
  const [showEditIdentifierForModel, setShowEditIdentifierForModel] = useState(false);

  const toggleEditIdentifierForModelModal = (): void =>
    setShowEditIdentifierForModel(!showEditIdentifierForModel);
  const toggleEditModal = (): void => {
    dispatch(setShowEditIdentifier(!editIdentifierModal));
  };

  useEffect(() => {
    const fromModel = window.localStorage.getItem('create-identifier-from-model');
    if (fromModel) {
      window.localStorage.removeItem('create-identifier-from-model');
      setShowEditIdentifierForModel(true);
    }
  }, []);

  useEffect(() => {
    if (identifierId || identifierRevisionId) {
      dispatch(fetchIdentifierTypes());
    }
  }, [identifierId, identifierRevisionId, dispatch]);

  useEffect(() => {
    dispatch(setCurrentTestIdentifierRevisionId(''));
    dispatch(setShowUtilization(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifierId]);

  useEffect(() => {
    if (
      identifierRevision &&
      identifierRevision.options_config &&
      Object.keys(identifierRevision.options_config) &&
      types &&
      Object.keys(types).length > 0
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const defaultData: { [key: string]: any } = {};
      Object.entries(types[identifierRevision.kind].options).forEach(([key, value]) => {
        let inputValue;
        if (identifierRevision.options_config[key]) {
          inputValue = identifierRevision.options_config[key];
        } else if (value?.type === fieldTypes.bool) {
          inputValue = false;
        } else {
          inputValue = '';
        }

        defaultData[key] = Array.isArray(inputValue) ? inputValue : [inputValue];
      });
      methods.reset(defaultData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifierRevision?.options_config, types]);

  const toggleConfirmModal = (): void => setIsDeletingIdentifier(false);

  const handleDeleteClick = async (): Promise<void> => {
    if (identifierRevision) {
      dispatch(deleteIdentifier({ identifierId: identifierRevision.uuid, redirect: true }));
      toggleConfirmModal();
    }
  };

  const toggleIsCloneIdentifierOpen = (): void => {
    setIsCloneIdentifierOpen(!isCloneIdentifierOpen);
  };

  const handlers = {
    SAVE_IDENTIFIER: (event: KeyboardEvent | undefined): void => {
      if (event) {
        event.preventDefault();
        const button = document.querySelector('#save-identifier-setup-button') as HTMLButtonElement;
        if (button) button.click();
      }
    },
  };

  const renderIdentifierScreen = (
    <CustomerSimpleList
      toggleShowCloneResourceModalOpen={toggleIsCloneIdentifierOpen}
      resourceId={identifierId}
      cloneResource={cloneIdentifier}
    />
  );

  const onSubmit = async (values?: IdentifierT): Promise<void> => {
    if (!identifierRevision) return;
    if (!identifierRevision.name && !values) return;

    const options = {};
    const typeObj = types[identifierRevision.kind];
    if (typeObj && typeObj.options && Object.keys(typeObj.options) && values != null) {
      (Object.keys(typeObj.options) as Array<keyof typeof typeObj.options>).forEach((key) => {
        // @ts-ignores
        let val = values[key];
        if (typeObj.options[key]?.type === 'float') {
          val = parseFloat(val);
        }

        if (Array.isArray(val)) {
          const arrVal: string[] = [];
          val.forEach((el) => {
            const elements = el.trim().split('\n');
            if (elements.length > 1) {
              arrVal.push(...elements.map((e: string) => e.trim()));
            } else {
              arrVal.push(el.trim());
            }
          });
          val = arrVal.filter((el) => !/^\s*$/.test(el));
        }
        // @ts-ignore
        options[key] = val;
      });
    }
    dispatch(saveIdentifierRequest());

    const identifierToSend = identifierRevision;

    dispatch(
      upsertIdentifier({
        ...identifierToSend,
        options_config: values != null ? options : identifierRevision.options_config,
      })
    );
  };

  if (!identifier || !identifierRevision) {
    return <LoadingIndicator className="m-auto mt-20" size="20" />;
  }

  if (
    loading &&
    identifier.uuid !== identifierId &&
    identifierRevision.uuid !== identifierRevisionId
  ) {
    return <LoadingIndicator className="m-auto mt-20" size="20" />;
  }

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <div
        className="flex flex-col bg-white min-w-screen"
        style={{
          height: `calc(100vh - ${warningBarOffset}px)`,
        }}
      >
        <GlobalNavbar />

        <div className="flex flex-row h-full overflow-hidden">
          <div className="test-right-sidebar border-r border-litlingo-gray-1 flex flex-col h-full overflow-auto no-scrollbar">
            <IdentifierSidebar identifier={identifierRevision} />
          </div>

          <div className="flex flex-col gap-4 h-full w-full overflow-hidden">
            <main className="flex flex-col justify-between h-full overflow-auto no-scrollbar min-w-full">
              <FormContext {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  {identifierRevision && identifierRevision.kind === 'custom' && (
                    <LanguageMatcherRedesign identifierData={identifierRevision} />
                  )}
                  {identifierRevision &&
                    identifierRevision.options_config &&
                    Object.keys(identifierRevision.options_config).length > 0 && (
                      <RenderOptionsConfig identifier={identifierRevision} types={types} />
                    )}
                  {/* starship <RenderTestSuite entity="identifier" entityId={identifierId} /> */}
                </form>
                <div>
                  <IdentifierCategoriesTable identifierId={identifierRevision.uuid} />
                  <IdentifierAuditLogsTable identifierId={identifierId} />
                </div>
              </FormContext>
            </main>
          </div>
        </div>

        {isCloneIdentifierOpen && (
          <Modal
            body={renderIdentifierScreen}
            title="Choose a customer"
            okButton
            okButtonText="Done"
            okButtonOnClick={toggleIsCloneIdentifierOpen}
            toggleShowModal={toggleIsCloneIdentifierOpen}
          />
        )}
        {isDeletingIdentifier && (
          <ConfirmModal
            data-testid="delete-modal"
            text="Are you sure you want to delete this identifier?"
            okButtonText="Yes"
            cancelButtonText="No"
            okButtonOnClick={handleDeleteClick}
            toggleShowModal={toggleConfirmModal}
          />
        )}
        {showEditIdentifierForModel && (
          <IdentifierDetailModal
            isModalOpen={showEditIdentifierForModel}
            toggleModalOpen={toggleEditIdentifierForModelModal}
            identifier={identifierRevision}
          />
        )}
        {editIdentifierModal && (
          <EditIdentifierModal
            isModalOpen={editIdentifierModal}
            toggleModalOpen={toggleEditModal}
            identifier={identifierRevision}
            title="Identifier Details"
          />
        )}
      </div>
    </>
  );
};

export default Identifier;
