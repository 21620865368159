import { changeLanguageMatcherType } from 'actions';
import AddLanguageMatcherInput from 'components/LanguageMatcher/AddLanguageMatcherInput';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageMatcherTypes } from 'selectors/annotator';
import type { LanguageMatcher, LanguageMatchersTypes, MLanguageMatcher } from 'types';

type ChooseCombinationTypeProps = {
  activeLanguageMatcher: MLanguageMatcher;
};

const ChooseCombinationType: React.FC<ChooseCombinationTypeProps> = (props) => {
  const combinations = useSelector(getLanguageMatcherTypes);
  const { activeLanguageMatcher: languageMatcher } = props;
  const dispatch = useDispatch();

  const [combinationSelection, setCombinationSelection] = useState<string>('');

  useEffect(() => {
    if (!languageMatcher) return;
    if (languageMatcher.type) {
      setCombinationSelection(languageMatcher.type);
    }
  }, [languageMatcher]);

  const handleChangeType = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setCombinationSelection(e.target.value);
    const defaultValues: { [key: string]: string | number } = {};
    Object.entries(combinations[e.target.value as keyof typeof combinations]).forEach(
      ([key, field]) => {
        if (field != null && field.default) {
          defaultValues[key] = field.default;
        }
      }
    );
    dispatch(
      changeLanguageMatcherType({
        languageMatcherId: languageMatcher.uuid,
        type: e.target.value,
        ...defaultValues,
      })
    );
  };

  const isLanguageMatcherNew = !languageMatcher || (languageMatcher && languageMatcher.isNew);
  return (
    <div className="grid gap-x-4 grid-cols-2">
      <label
        htmlFor="keyword"
        className={`mt-6 block litlingo-annotator-label-name ${
          isLanguageMatcherNew ? '' : 'opacity-50'
        }`}
      >
        Type
        <div>
          <select
            value={combinationSelection}
            onChange={handleChangeType}
            id="Mode Of Speech"
            data-testid="select-language-matcher-type"
            className="block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            disabled={!isLanguageMatcherNew}
          >
            {Object.keys(combinations).map((combination) => (
              <option key={combination} value={combination}>
                {combination}
              </option>
            ))}
          </select>
          {combinationSelection === 'keyword' ? (
            <span className="text-xs">WARNING: Please Do Not use!!</span>
          ) : (
            ''
          )}
          <p className="mt-2 text-sm text-gray-500">
            {combinations[combinationSelection as keyof typeof combinations] &&
              combinations[combinationSelection as keyof typeof combinations].description}
          </p>
        </div>
      </label>
      {combinationSelection &&
        combinations[combinationSelection as keyof typeof combinations] &&
        Object.keys(combinations[combinationSelection as keyof typeof combinations]).map((key) => (
          <AddLanguageMatcherInput
            key={key}
            form={key as keyof LanguageMatcher}
            languageMatcherId={languageMatcher?.uuid}
            type={combinationSelection as keyof LanguageMatchersTypes}
          />
        ))}
    </div>
  );
};

export default ChooseCombinationType;
