import { fetchEventSummary } from 'actions';
import EventView from 'components/EventView';
import { RouteSpec, SuperRouteSpec } from 'types';

const eventsRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'event-detail',
    path: '/:customerDomain/event/:eventId',
    component: EventView,
    private: true,
    data: {
      idFromPath: true,
      actions: [fetchEventSummary],
    },
  },
  {
    path: '/events',
    component: (): null => null,
    private: true,
  },
];

export default eventsRoutes;
