import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RouteParams } from 'src/types';

export type NavState = {
  params: RouteParams;
};

type NavReducer<P = void> = (state: NavState, action: PayloadAction<P>) => NavState;

const defaultState: NavState = {
  params: {
    time_range: '-30days',
  },
};

type SetURLParamsPayload = {
  [param: string]: string | string[];
};
export const handleSetURLParams: NavReducer<SetURLParamsPayload> = (state) => state;

export const handleReplaceURLParams: NavReducer<SetURLParamsPayload> = (state) => state;

export const handleDeleteURLParams: NavReducer<string[]> = (state) => state;

export const handleClearURLParams: NavReducer = (state) => state;

type addFilterToURLPayload = {
  resource: string;
  key: string;
  value: string;
};
export const handleAddFilterToURL: NavReducer<addFilterToURLPayload> = (state) => state;

const handleSetParamsFromURL: NavReducer<RouteParams> = (state, { payload }) => ({
  ...state,
  params: payload,
});

const navSlice = createSlice({
  name: 'nav',
  initialState: defaultState,
  reducers: {
    setURLParams: handleSetURLParams,
    replaceURLParams: handleReplaceURLParams,
    deleteURLParams: handleDeleteURLParams,
    clearURLParams: handleClearURLParams,
    setParamsFromURL: handleSetParamsFromURL,
    addFilterToURL: handleAddFilterToURL,
  },
});

const { actions, reducer } = navSlice;

export const {
  setURLParams,
  replaceURLParams,
  deleteURLParams,
  clearURLParams,
  setParamsFromURL,
  addFilterToURL,
} = actions;

export default reducer;
