/* eslint-disable jsx-a11y/control-has-associated-label */
import { fetchOwnSavedSearchesViewers, selectReviewSet } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDisplayInAssignmentsSavedSearches,
  getSavedSearchesLoading,
  getSortedOwnActiveSavedSearchesList,
  getSortedOwnInactiveSavedSearchesList,
} from 'selectors/savedSearches';
import EmptyReviewList from './EmptyReviewList';
import ReviewSetItemCard from './ReviewSetItemCard';
import SavedSearchItemCard from './SavedSearchItemCard';

const ReviewSetsList: React.FC = () => {
  const dispatch = useDispatch();

  const loading = useSelector(getSavedSearchesLoading);
  const reviewSets = useSelector(getSortedOwnActiveSavedSearchesList);
  const innactiveReviewSets = useSelector(getSortedOwnInactiveSavedSearchesList);
  const notAssignableSavedSearches = useSelector(getDisplayInAssignmentsSavedSearches);

  useEffect(() => {
    if (!reviewSets[0] || loading) {
      dispatch(selectReviewSet({ reviewSet: null }));
      return;
    }

    dispatch(selectReviewSet({ reviewSet: reviewSets[0] }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, reviewSets]);

  useEffect(() => {
    dispatch(
      fetchOwnSavedSearchesViewers({
        is_assignable: false,
      })
    );
  }, [dispatch]);

  const renderReviewSetList = (): JSX.Element | null => {
    if (loading) {
      return (
        <div className="flex justify-center w-full" data-testid="spinner-container">
          <LoadingIndicator size="10" />
        </div>
      );
    }

    if (!loading && reviewSets.length === 0 && notAssignableSavedSearches.length === 0) {
      return <EmptyReviewList />;
    }

    return (
      <>
        {reviewSets.map((reviewSet) => (
          <ReviewSetItemCard reviewSet={reviewSet} key={reviewSet.uuid} />
        ))}
        {notAssignableSavedSearches.map((savedSearch) => (
          <SavedSearchItemCard savedSearch={savedSearch} key={savedSearch.uuid} />
        ))}
        {innactiveReviewSets.map((reviewSet) => (
          <ReviewSetItemCard reviewSet={reviewSet} key={reviewSet.uuid} />
        ))}
      </>
    );
  };

  return (
    <div className="flex flex-col items-start gap-4 h-full w-full pl-6 pr-5 py-8 no-scrollbar overflow-auto">
      {renderReviewSetList()}
    </div>
  );
};

export default ReviewSetsList;
