import { createAction } from '@reduxjs/toolkit';
import type {
  Annotator,
  AnnotatorTypes,
  API,
  ErrorObject,
  LanguageMatcher,
  LanguageMatchersTypes,
  LanguageMatcherType,
  MLanguageMatcher,
  NormalizedAnnotator,
  RelationshipType,
  UUID,
} from 'types';

type FetchAnnotatorsForFilterPayload = {
  searchValue: string;
  limit?: number;
  offset?: number;
};
export const fetchAnnotatorsForFilter =
  createAction<FetchAnnotatorsForFilterPayload>('ANNOTATORS_FILTER/FETCH');

export const fetchAllAnnotators = createAction<{
  name?: string;
  limit?: string;
  searchValue?: string;
}>('ANNOTATORS/FETCH');
export const fetchAllAnnotatorsRequest = createAction('ANNOTATORS/FETCH_REQUEST');
export const fetchAllAnnotatorsSuccess = createAction<API.WrappedAPIResponse<Annotator>>(
  'ANNOTATORS/FETCH_SUCCESS'
);
export const fetchAllAnnotatorsSuccessAppend = createAction<API.WrappedAPIResponse<Annotator>>(
  'ANNOTATORS/FETCH_SUCCESS_APPEND'
);
export const fetchAllAnnotatorsFailure = createAction<ErrorObject>('ANNOTATORS/FETCH_FAILURE');

type FetchAnnotatorsByIds = { annotatorIds: string[] };

export const fetchAnnotatorsByIds = createAction<FetchAnnotatorsByIds>('ANNOTATORS/FETCH_BY_IDS');
export const fetchAnnotatorsByIdsRequest = createAction('ANNOTATORS/FETCH_BY_IDS_REQUEST');
export const fetchAnnotatorsByIdsSuccess = createAction<API.WrappedAPIResponse<Annotator>>(
  'ANNOTATORS/FETCH_BY_IDS_SUCCESS'
);
export const fetchAnnotatorsByIdsFailure = createAction<ErrorObject>(
  'ANNOTATORS/FETCH_BY_IDS_FAILURE'
);

export const fetchSingleAnnotator = createAction<{ annotatorId: UUID }>('ANNOTATORS/FETCH_SINGLE');
export const fetchSingleAnnotatorRequest = createAction('ANNOTATORS/FETCH_SINGLE_REQUEST');
export const fetchSingleAnnotatorSuccess = createAction<Annotator>(
  'ANNOTATORS/FETCH_SINGLE_SUCCESS'
);
export const fetchSingleAnnotatorFailure = createAction<ErrorObject>(
  'ANNOTATORS/FETCH_SINGLE_FAILURE'
);

type DeleteAnnotatorPayload = {
  annotatorId: UUID;
  redirect?: boolean;
};
export const deleteAnnotator = createAction<DeleteAnnotatorPayload>('ANNOTATORS/DELETE');
export const deleteAnnotatorRequest = createAction('ANNOTATORS/DELETE_REQUEST');
export const deleteAnnotatorSuccess = createAction<string>('ANNOTATORS/DELETE_SUCCESS');
export const deleteAnnotatorFailure = createAction<ErrorObject>('ANNOTATORS/DELETE_FAILURE');

export const saveAnnotatorRequest = createAction('ANNOTATORS/SAVE_REQUEST');
export const saveAnnotatorSuccess = createAction<Annotator>('ANNOTATORS/SAVE_SUCCESS');
export const saveAnnotatorFailure = createAction<ErrorObject>('ANNOTATORS/SAVE_FAILURE');
export const saveAnnotatorFulfill = createAction('ANNOTATORS/SAVE_FULFILL');
export const saveAnnotatorLocalFailure = createAction<UpsertAnnotatorPayload>(
  'ANNOTATORS/SAVE_LOCAL_FAILURE'
);

export const fetchAnnotatorTypes = createAction('ANNOTATORS/FETCH_TYPES');
export const fetchAnnotatorTypesRequest = createAction('ANNOTATORS/FETCH_TYPES_REQUEST');
export const fetchAnnotatorTypesSuccess = createAction<AnnotatorTypes>(
  'ANNOTATORS/FETCH_TYPES_SUCCESS'
);
export const fetchAnnotatorTypesFailure = createAction<ErrorObject>(
  'ANNOTATORS/FETCH_TYPES_FAILURE'
);

export const fetchLanguageMatcherTypes = createAction('LANGUAGE_MATCHERS/FETCH');
export const fetchLanguageMatcherTypesRequest = createAction('LANGUAGE_MATCHERS/FETCH_REQUEST');
export const fetchLanguageMatcherTypesSuccess = createAction<LanguageMatchersTypes>(
  'LANGUAGE_MATCHERS/FETCH_SUCCESS'
);
export const fetchLanguageMatcherTypesFailure = createAction<ErrorObject>(
  'LANGUAGE_MATCHERS/FETCH_FAILURE'
);

export const fetchRelationshipTypes = createAction('RELATIONSHP_TYPES/FETCH');
export const fetchRelationshipTypesRequest = createAction('RELATIONSHIP_TYPES/FETCH_REQUEST');
export const fetchRelationshipTypesSuccess = createAction<RelationshipType[]>(
  'RELATIONSHIP_TYPES/FETCH_SUCCESS'
);
export const fetchRelationshipTypesFailure = createAction<ErrorObject>(
  'RELATIONSHIP_TYPES/FETCH_FAILURE'
);

export const cloneAnnotator =
  createAction<{ resourceId: UUID; customerId: UUID }>('ANNOTATOR/CLONE');
export const cloneAnnotatorRequest = createAction('ANNOTATOR/CLONE_REQUEST');
export const cloneAnnotatorFailure = createAction<ErrorObject>('ANNOTATOR/CLONE_FAILURE');
export const cloneAnnotatorSuccess = createAction<Annotator>('ANNOTATOR/CLONE_SUCCESS');

export type AddNewLanguageMatcherPayload = {
  matcherBody: Pick<MLanguageMatcher, 'name' | 'saved'>;
  annotatorId: UUID;
  languageMatcherId: UUID;
};
export const addNewLanguageMatcher =
  createAction<AddNewLanguageMatcherPayload>('ADD_LANGUAGE_MATCHER');

export type ReceiveNewKeywordPayload = {
  languageMatcherId: UUID;
  matcherBody?: LanguageMatcher;
  parentType: LanguageMatcherType;
  data: {
    key: keyof MLanguageMatcher;
    value?: string | number | boolean | (string | number | boolean | undefined)[];
  };
};
export const receiveNewKeyword = createAction<ReceiveNewKeywordPayload>('RECEIVE_NEW_KEYWORD');

export type ChangeLanguageMatcherTypePayload = {
  languageMatcherId: UUID;
  type: string;
};
export const changeLanguageMatcherType = createAction<ChangeLanguageMatcherTypePayload>(
  'CHANGE_LANGUAGE_MATCHER_TYPE'
);

export const setActiveLanguageMatcherId = createAction<string>('SET_ACTIVE_LANGUAGE_MATCHER');

export type DeleteLanguageMatcherPayload = {
  annotatorId: UUID;
  languageMatcherId: UUID;
};
export const deleteLanguageMatcher =
  createAction<DeleteLanguageMatcherPayload>('DELETE_LANGUAGE_MATCHER');

export type EditLanguageMatcherPayload = {
  languageMatcherId: UUID;
  data: Partial<LanguageMatcher>;
};

export const editLanguageMatcher =
  createAction<EditLanguageMatcherPayload>('EDIT_LANGUAGE_MATCHER');

export type RemoveKeywordPayload = {
  languageMatcherId: UUID;
  keyword: string | boolean | undefined;
  // FIXME: use the correct type for this
  partOfSpeech: keyof LanguageMatcher;
};
export const removeKeyword = createAction<RemoveKeywordPayload>('REMOVE_KEYWORD');

export type EditAnnotatorPayload = {
  id: UUID;
  data: Pick<NormalizedAnnotator, 'name'> | Pick<NormalizedAnnotator, 'always_save'>;
};
export const editAnnotator = createAction<EditAnnotatorPayload>('ANNOTATOR/EDIT');

export type UpsertAnnotatorPayload = {
  annotatorId?: UUID;
  // eslint-disable-next-line camelcase
  options_config: AnnotatorTypes[string]['options'];
  showSuccess?: boolean;
  name?: string;
  description?: string | null;
  // eslint-disable-next-line camelcase
  always_save?: boolean;
  redirect?: boolean;
  type?: string;
  ruleId?: string;
  campaignId?: string;
};

export const upsertAnnotator = createAction<UpsertAnnotatorPayload>('ANNOTATOR/UPSERT');
export const upsertAnnotatorDebounced = createAction<UpsertAnnotatorPayload>(
  'ANNOTATOR/UPSERT_DEBOUNCED'
);

export type FetchAnnotatorChangelogType = {
  annotatorId: UUID;
  auditlogUuid: UUID;
  version: string;
};

export const fetchAnnotatorChangelog = createAction<FetchAnnotatorChangelogType>(
  'ANNOTATOR/FETCH_CHANGELOG'
);

export const setHasChanges = createAction<boolean>('ANNOTATOR/SET_HAS_CHANGES');

export type ReplaceAnnotatorType = {
  source: UUID;
  targets: UUID[];
};

export const replaceAnnotator = createAction<ReplaceAnnotatorType>('ANNOTATOR/REPLACE_ANNOTATOR');
export const replaceAnnotatorRequest = createAction('ANNOTATOR/REPLACE_REQUEST');
export const replaceAnnotatorFailure = createAction<ErrorObject>('ANNOTATOR/REPLACE_FAILURE');
export const replaceAnnotatorSuccess = createAction('ANNOTATOR/REPLACE_SUCCESS');

type GenerateTermsType = {
  includedTerms: string[];
};

export const generateTerms = createAction<GenerateTermsType>('ANNOTATOR/GENERATE_TERMS');
export const generateTermsRequest = createAction('ANNOTATOR/GENERATE_TERMS_REQUEST');
export const generateTermsFailure = createAction<ErrorObject>('ANNOTATOR/GENERATE_TERMS_FAILURE');
export const generateTermsSuccess = createAction<string[]>('ANNOTATOR/GENERATE_TERMS_SUCCESS');

export const setDropPosition = createAction<number>('ANNOTATOR/SET_DROP_POSITION');
