import { fetchUsersForFilter, setURLParams } from 'actions';
import useClickOutside from 'components/utils/useClickOutside';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { useSelector } from 'store';
import type { UUID } from 'types';

type ComponentProps = {
  className?: string;
  resource: string;
  title?: string;
  resourceId?: UUID;
};

const UsersFilter: React.FC<ComponentProps> = (props) => {
  const { className, resource, title, resourceId } = props;

  const dispatch = useDispatch();
  const filters = useSelector(getNavParamsByResource(resource))[resourceId || 'user_uuids'] || [];
  const users = useSelector((state) => state.users.items);

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  // click outside functionality
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleButtonClick = (): void => setIsOpen(!isOpen);

  useEffect(() => {
    dispatch(fetchUsersForFilter({ searchValue }));
  }, [dispatch, searchValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { id: state } = event.target;

    let userIds = [...filters];
    if (event.target.checked) {
      userIds.push(state);
    } else {
      userIds = userIds.filter((s) => s !== state);
    }

    dispatch(
      setURLParams({
        [`${resource}__${resourceId || 'user_uuids'}`]: userIds,
        [`${resource}__offset`]: '0',
      })
    );
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  };

  return (
    <div ref={wrapperRef} className={`relative inline-block text-left ${className || ''}`}>
      <div className="rounded-md shadow-sm">
        <button
          type="button"
          className={`inline-flex justify-center w-full rounded-md border px-4 py-2 bg-white text text--lighter-4 hover:text-gray-500 focus:outline-none focus:border-litlingo-success focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 ${
            filters.length !== 0 ? 'border-gray-700' : 'border-gray-300'
          }`}
          id="options-menu"
          data-testid="user-filter-button"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={handleButtonClick}
        >
          {title || 'Users'}
          <svg className="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-left absolute left-0 mt-2 w-64 rounded-md shadow-lg z-20">
          <div className="rounded-md bg-white shadow-xs">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="m-2 relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg className="h-4 w-4 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M12.9 14.32a8 8 0 111.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 108 2a6 6 0 000 12z" />
                  </svg>
                </div>
                <input
                  data-testid="user-filter-search"
                  id="search"
                  name="search"
                  className="form-input block w-full pl-10 sm:text-sm sm:leading-5 shadow-sm"
                  onChange={handleSearchChange}
                  autoComplete="off"
                />
              </div>
              <div className="m-2 max-h-filter overflow-auto">
                <fieldset data-testid="users-filter-result-filtered">
                  {Object.keys(users)
                    .sort((a, b) => {
                      const userA = users[a];
                      const userB = users[b];

                      if (userA.name == null || userB.name == null) {
                        return 0;
                      }
                      if (filters.includes(a)) {
                        return -1;
                      }
                      if (filters.includes(b)) {
                        return 1;
                      }

                      return userA.name.toLowerCase() < userB.name.toLowerCase() ? -1 : 1;
                    })
                    .map((key, idx) => (
                      <div key={key} className={idx !== 0 ? 'mt-4' : ''}>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5 mr-2">
                            <input
                              data-testid={`user-${key}`}
                              id={key}
                              type="checkbox"
                              className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out"
                              onChange={handleInputChange}
                              checked={filters.includes(key)}
                            />
                          </div>
                          <div className="text-sm leading-5">
                            <label htmlFor={key} className="text-gray-700">
                              {users[key].name}
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersFilter;
