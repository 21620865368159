import { fetchInvitations } from 'actions';
import InvitationsList from 'components/InvitationsList';
import { RouteSpec, SuperRouteSpec } from 'types';

const invitationsRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'invitations',
    path: '/:customerDomain/invitations',
    component: InvitationsList,
    private: true,
    permissions: 'invitations.list',
    data: {
      actions: [fetchInvitations],
      params: {
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        invitations: {
          nestedResource: true,
          params: {
            broad_search: {},
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
          },
        },
      },
    },
  },
];
export default invitationsRoutes;
