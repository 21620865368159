import { fetchTemplates } from 'actions';
import TemplatesManager from 'components/TemplatesManager';
import { RouteSpec, SuperRouteSpec } from 'types';

const templatesRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'templates-manager',
    path: '/:customerDomain/templates',
    component: TemplatesManager,
    private: true,
    permissions: 'templates.list',
    data: {
      actions: [fetchTemplates],
      params: {
        order_by: {
          defaultValue: 'created_at',
        },
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        templates: {
          nestedResource: true,
          params: {
            broad_search: {},
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
          },
        },
      },
    },
  },
];

export default templatesRoutes;
