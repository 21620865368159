import { createAction } from '@reduxjs/toolkit';
import type { API, ErrorObject, Tag, TagGroup, UUID } from 'types';

type FetchAllTagGroupsPayload =
  | {
      limit?: number;
      broad_search?: string;
    }
  | undefined;

export const fetchAllTagGroups = createAction<FetchAllTagGroupsPayload>('TAG_GROUPS/FETCH_ALL');
export const fetchAllTagGroupsRequest = createAction('TAG_GROUPS/FETCH_ALL_REQUEST');
export const fetchAllTagGroupsFailure = createAction<ErrorObject>('TAG_GROUPS/FETCH_ALL_FAILURE');
export const fetchAllTagGroupsSuccess = createAction<API.WrappedAPIResponse<TagGroup>>(
  'TAG_GROUPS/FETCH_ALL_SUCCESS'
);

type FetchTagGroupsPayload =
  | {
      uuids?: UUID[];
      limit?: number;
      broad_search?: string;
    }
  | undefined;

export const fetchTagGroups = createAction<FetchTagGroupsPayload>('TAG_GROUPS/FETCH');
export const fetchTagGroupsRequest = createAction('TAG_GROUPS/FETCH_REQUEST');
export const fetchTagGroupsFailure = createAction<ErrorObject>('TAG_GROUPS/FETCH_FAILURE');
export const fetchTagGroupsSuccess = createAction<API.WrappedAPIResponse<TagGroup>>(
  'TAG_GROUPS/FETCH_SUCCESS'
);

export type FetchSingleTagGroupPayload = { tagGroupId: UUID };

export const fetchSingleTagGroup =
  createAction<FetchSingleTagGroupPayload>('TAG_GROUPS/FETCH_SINGLE');
export const fetchSingleTagGroupRequest = createAction('TAG_GROUPS/FETCH_SINGLE_REQUEST');
export const fetchSingleTagGroupSuccess = createAction<TagGroup>('TAG_GROUPS/FETCH_SINGLE_SUCCESS');
export const fetchSingleTagGroupFailure = createAction<ErrorObject>(
  'TAG_GROUPS/FETCH_SINGLE_FAILURE'
);

export const upsertTagGroup = createAction<
  Pick<TagGroup, 'name' | 'description' | 'color'> & { uuid?: TagGroup['uuid'] }
>('TAG_GROUPS/UPSERT');
export const upsertTagGroupRequest = createAction('TAG_GROUPS/UPSERT_REQUEST');
export const upsertTagGroupFailure = createAction<ErrorObject>('TAG_GROUPS/UPSERT_FAILURE');
export const upsertTagGroupSuccess = createAction<TagGroup>('TAG_GROUPS/UPSERT_SUCCESS');

export const deleteTagGroup = createAction<{ id: UUID }>('TAG_GROUPS/DELETE');
export const deleteTagGroupRequest = createAction('TAG_GROUPS/DELETE_REQUEST');
export const deleteTagGroupSuccess = createAction<string>('TAG_GROUPS/DELETE_SUCCESS');
export const deleteTagGroupFailure = createAction<ErrorObject>('TAG_GROUPS/DELETE_FAILURE');

export const addTagsToTagGroup =
  createAction<{ tags: string[]; uuid: string }>('TAG_GROUPS/ADD_TAGS');
export const addTagsToTagGroupRequest = createAction('TAG_GROUPS/ADD_TAGS_REQUEST');
export const addTagsToTagGroupSuccess = createAction<{ tagGroupId: string; tags: Tag[] }>(
  'TAG_GROUPS/ADD_TAGS_SUCCESS'
);
export const addTagsToTagGroupFailure = createAction<ErrorObject>('TAG_GROUPS/ADD_TAGS_FAILURE');

export const removeTagsFromTagGroup =
  createAction<{ tags: string[]; uuid: string }>('TAG_GROUPS/REMOVE_TAGS');
export const removeTagsFromTagGroupRequest = createAction('TAG_GROUPS/REMOVE_TAGS_REQUEST');
export const removeTagsFromTagGroupSuccess = createAction<{ tagGroupId: string; tags: Tag[] }>(
  'TAG_GROUPS/REMOVE_TAGS_SUCCESS'
);
export const removeTagsFromTagGroupFailure = createAction<ErrorObject>(
  'TAG_GROUPS/REMOVE_TAGS_FAILURE'
);
