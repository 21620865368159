/* eslint-disable jsx-a11y/control-has-associated-label */
import EmptyTableState from 'components/EmptyTableState';
import ListFooter from 'components/ListFooter';
import LoadingIndicator from 'components/LoadingIndicator';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useSelector } from 'react-redux';
import {
  getAssignments,
  getAssignmentsLoading,
  getAssignmentsTotalCount,
} from 'selectors/assignments';
import AssignmentListItem from './AssignmentListItem';

const AssignmentsList: React.FC = () => {
  const loading = useSelector(getAssignmentsLoading);
  const assignments = useSelector(getAssignments);

  return (
    <div className="table-wrapper relative">
      <LoadingOverlay
        active={loading}
        spinner={<LoadingIndicator size="10" />}
        fadeSpeed={0}
        styles={{
          content: (base) => ({
            ...base,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }),
          wrapper: () => ({}),
          spinner: () => ({}),
          overlay: (base: Record<string, unknown>): { [key: string]: number; zIndex: number } => ({
            ...base,
            zIndex: 10,
          }),
        }}
      >
        <table className="table-wrapper__table">
          <thead>
            <tr>
              <th className="table-wrapper__th">Assignee</th>
              <th className="table-wrapper__th">Last Reviewed</th>
              <th className="table-wrapper__th">Count Pending</th>
              <th className="table-wrapper__th">Status</th>
            </tr>
          </thead>

          <tbody className="table-wrapper__tbody">
            {!loading && assignments.length === 0 ? (
              <EmptyTableState message="There are no review streams to display" colCount="5" />
            ) : (
              assignments &&
              assignments.map((assignment) => (
                <AssignmentListItem assignment={assignment} key={assignment.uuid} />
              ))
            )}
          </tbody>
        </table>
      </LoadingOverlay>
      <ListFooter
        resourceStateName={resourceQueryParamName.assignments}
        resourceName="assignment"
        getResourceList={getAssignments}
        getResourceTotalCount={getAssignmentsTotalCount}
        getResourceLoading={getAssignmentsLoading}
      />
    </div>
  );
};

export default AssignmentsList;
