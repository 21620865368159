/* eslint-disable react/no-array-index-key */
import { Customer } from '@litlingo/client';
import React from 'react';

type SearchCustomerItemProps = {
  customer: Customer;
  search: string;
};

const SearchHighlight = ({
  text,
  searchTerm,
}: {
  text: string;
  searchTerm: string;
}): JSX.Element => {
  const regex = new RegExp(searchTerm, 'gi');
  const textParts = text.split(regex);

  const highlightedText = textParts.map((part, index) => {
    if (index > 0) {
      return (
        <span key={index}>
          <strong>{searchTerm}</strong>
          {part}
        </span>
      );
    }
    return part;
  });

  return <div>{highlightedText}</div>;
};

const SearchCustomerItem: React.FC<SearchCustomerItemProps> = ({ customer, search }) => (
  <button
    type="button"
    key={customer.uuid}
    className={`w-full text-litlingo-gray-6 flex flex-row justify-between items-center pl-10 pr-4 py-2.5 border-b border-litlingo-gray-1 hover:bg-litlingo-gray-1 hover:bg-opacity-50 transition ease-in-out duration-150 focus:outline-none `}
  >
    <SearchHighlight text={customer.name ?? ''} searchTerm={search} />
    <div>{`P `}</div>
  </button>
);

export default SearchCustomerItem;
