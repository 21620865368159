/* eslint-disable camelcase */
import { createAction } from '@reduxjs/toolkit';
import type { CampaignRuleOutcome, ErrorObject, NormalizedResource, UUID } from 'types';

type FetchAllCampaignRuleOutcomesPayload = {
  rule_uuid?: UUID;
  campaign_uuid?: UUID;
  annotator_uuids?: UUID;
  outcome_uuids?: UUID;
  limit?: number;
};

export const fetchFilterCampaignRuleOutcomes = createAction<FetchAllCampaignRuleOutcomesPayload>(
  'CAMPAIGN_RULE_OUTCOMES/FILTER'
);

export const fetchAllCampaignRuleOutcomes = createAction<FetchAllCampaignRuleOutcomesPayload>(
  'CAMPAIGN_RULE_OUTCOMES/FETCH'
);

export const fetchAllCampaignRuleOutcomesRequest = createAction(
  'CAMPAIGN_RULE_OUTCOMES/FETCH_REQUEST'
);

export type FetchAllCampaignRuleOutcomesSuccessPayload = NormalizedResource<CampaignRuleOutcome>;

export const fetchAllCampaignRuleOutcomesSuccess = createAction<
  NormalizedResource<CampaignRuleOutcome>
>('CAMPAIGN_RULE_OUTCOMES/FETCH_SUCCESS');

export const fetchAllCampaignRuleOutcomesFailure = createAction<ErrorObject>(
  'CAMPAIGN_RULE_OUTCOMES/FETCH_FAILURE'
);

export const updateCampaignRuleOutcomeConfig = createAction<
  Pick<CampaignRuleOutcome, 'uuid' | 'config'>
>('CAMPAIGN_RULE_OUTCOME_CONFIG');
export const updateCampaignRuleOutcomeConfigRequest = createAction<
  Pick<CampaignRuleOutcome, 'uuid' | 'config'>
>('CAMPAIGN_RULE_OUTCOME_CONFIG/UPDATE_REQUEST');
export const updateCampaignRuleOutcomeConfigFailure = createAction<ErrorObject>(
  'CAMPAIGN_RULE_OUTCOME_CONFIG/UPDATE_FAILURE'
);
export const updateCampaignRuleOutcomeConfigSuccess = createAction(
  'CAMPAIGN_RULE_OUTCOME_CONFIG/UPDATE_SUCCESS'
);

export type UpdateCampaignRuleOutcomePayload = {
  ruleId: UUID;
  campaignId: UUID;
};
export const updateCampaignRuleOutcome = createAction<UpdateCampaignRuleOutcomePayload>(
  'CAMPAIGN/UPDATE_CAMPAING_RULE_OUTCOME'
);

export type DeleteCampaignRuleOutcomePayload = {
  ruleId: UUID;
  campaignId: UUID;
  outcomeId: UUID;
};
export const deleteCampaignRuleOutcome = createAction<DeleteCampaignRuleOutcomePayload>(
  'CAMPAIGN/DELETE_CAMPAIGN_RULE_OUTCOME'
);

export const receiveRuleOutcomes = createAction<CampaignRuleOutcome[]>(
  'CAMPAIGN_RULE_OUTCOME/RECEIVE_RULE_OUTCOMES'
);

type RemoveRuleOutcomePayload = {
  ruleId: UUID;
  outcomeId: UUID;
  campaignId: UUID;
};

export const removeRuleOutcome = createAction<RemoveRuleOutcomePayload>(
  'CAMPAIGN_RULE_OUTCOME/REMOVE_RULE_OUTCOME'
);

type CreateRuleAndOutcomePayload = {
  campaignId: UUID;
  name: string;
  description: string;
};

export const createRuleAndOutcome = createAction<CreateRuleAndOutcomePayload>(
  'CAMPAIGN_RULE_OUTCOME/CREATE_RULE_AND_OUTCOME'
);
