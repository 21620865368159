import { showSuccessAlert } from 'actions';
import {
  getEmailTemplate,
  getEmailTemplateFailure,
  getEmailTemplateFulfill,
  getEmailTemplateSuccess,
  notifyEventAuthor,
  notifyEventAuthorFailure,
  notifyEventAuthorFulfill,
  notifyEventAuthorSuccess,
  renderEmailTemplate,
  renderEmailTemplateFailure,
  renderEmailTemplateFulfill,
  renderEmailTemplateSuccess,
} from 'actions/notifications';
import { apiClient as LitLingoClient } from 'client';
import Mustache from 'mustache';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getEnvelope } from 'selectors/envelopes';
import { API, CommunicationEnvelope, SagaReturn } from 'types';
import { getEventsDataFromEnvelopeRules } from '../selectors/notifications';
import { getRuleUuidsFromEnvelope } from '../selectors/rules';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getEmailTemplateSaga(action: ReturnType<typeof getEmailTemplate>): SagaReturn<void, any> {
  const { id, envelopeId } = action.payload;

  const response = (yield call(
    [LitLingoClient.resources.notifications.extras, 'getEmailTemplate'],
    {
      urlParams: { templateId: id },
    }
  )) as API.Response<API.Notifications.GetEmailTemplate>;

  if (response.error != null) {
    yield put(getEmailTemplateFailure(response.error));
    yield put(getEmailTemplateFulfill());
    return;
  }
  // This is how it should work when backend handle the template
  if (id !== 'violation_notice') {
    yield put(getEmailTemplateSuccess({ ...response.data, id }));
    yield put(getEmailTemplateFulfill());
    return;
  }

  // FIXME: find a good way to do this, probably in the backend
  const templates = (yield call([
    LitLingoClient.resources.templates,
    'list',
  ])) as API.Response<API.Templates.List>;

  let template: string;
  if (templates.error != null || templates.data.records.length === 0) {
    // use template body from the request by id
    template = response.data.fields.body.default as string;
  } else {
    // if there was success at the response, get the first template and
    // its value to pass it to Mustache
    template = templates.data.records[0].value;
  }

  const envelope: CommunicationEnvelope = yield select(getEnvelope, envelopeId);

  if (envelope.events) {
    // get all rules uuids for the selected envelope
    const rulesUuids = yield select(getRuleUuidsFromEnvelope(envelopeId));
    // get the rules data by their uuids
    const rulesResponse = (yield call([LitLingoClient.resources.rules, 'list'], {
      params: {
        uuids: rulesUuids,
      },
    })) as API.Response<API.Rules.List>;

    if (rulesResponse.error != null) {
      yield put(getEmailTemplateFailure(rulesResponse.error));
      return;
    }
    // set the data that will be passed to mustache
    const events = yield select(
      getEventsDataFromEnvelopeRules(envelope, rulesResponse.data.records)
    );

    // save email template at redux store
    yield put(
      getEmailTemplateSuccess({
        ...response.data,
        fields: {
          ...response.data.fields,
          body: {
            ...response.data.fields.body,
            type: 'long_str',
            default: Mustache.render(template, {
              events,
            }),
          },
        },
        id,
      })
    );
  }
  yield put(getEmailTemplateFulfill());
}

function* renderEmailTemplateSaga(action: ReturnType<typeof renderEmailTemplate>): SagaReturn {
  const { id, values } = action.payload;

  const response = (yield call(
    [LitLingoClient.resources.notifications.extras, 'renderEmailTemplate'],
    {
      urlParams: { templateId: id },
      data: { values },
    }
  )) as API.Response<API.Notifications.RenderEmailTemplate>;

  if (response.error != null) {
    yield put(renderEmailTemplateFailure(response.error));
  } else {
    yield put(renderEmailTemplateSuccess({ id, data: response.data }));
  }

  yield put(renderEmailTemplateFulfill());
}

function* notifyEventAuthorSaga(action: ReturnType<typeof notifyEventAuthor>): SagaReturn {
  const { uuid, context } = action.payload;

  const response = (yield call([LitLingoClient.resources.events.extras, 'notifyAuthor'], {
    urlParams: { eventId: uuid },
    data: {
      subject: context.subject,
      body: context.body,
    },
  })) as API.Response<API.Events.NotifyAuthor>;

  if (response.error != null) {
    yield put(notifyEventAuthorFailure(response.error));
  } else {
    yield put(notifyEventAuthorSuccess());
    yield put(showSuccessAlert('E-mail notification sent to the user.'));
  }

  yield put(notifyEventAuthorFulfill());
}

function* notificationsSaga(): SagaReturn {
  yield takeLatest(getEmailTemplate.toString(), getEmailTemplateSaga);
  yield takeLatest(renderEmailTemplate.toString(), renderEmailTemplateSaga);
  yield takeLatest(notifyEventAuthor.toString(), notifyEventAuthorSaga);
}

export default notificationsSaga;
