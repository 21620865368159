/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
import { AnyScale, Axes } from '@nivo/axes';
import { BarCustomLayer, BarExtendedDatum } from '@nivo/bar';
import { computeXYScalesForSeries } from '@nivo/scales';
import { curveCatmullRom, line } from 'd3-shape';
import React from 'react';

type LayerProps = {
  bars: {
    x: number;
    y: number;
    width: number;
    height: number;
    key: string;
    data: BarExtendedDatum;
  }[];
  xScale: AnyScale;
  width: number;
  height: number;
};

const composeLineChart = (data: number[]): BarCustomLayer => {
  const LineComponent: BarCustomLayer = (props) => {
    const lineColor = '#F8A01A';
    const { bars, xScale, width, height } = props as LayerProps;

    const scale = computeXYScalesForSeries(
      [
        {
          id: 'only',
          // @ts-ignore
          data: [
            ...data.map((it) => ({
              x: 0,
              y: it,
            })),
          ],
        },
      ],
      { type: 'linear' },
      { type: 'linear', max: Math.max(Math.max(...data) + 3, 5) },
      width,
      height
    );

    const lineGenerator = line()
      // @ts-ignore
      .x((bar) => bar.x + bar.width / 2)
      // @ts-ignore
      .y((bar, idx) => scale.yScale(data[idx] || 0))
      .curve(curveCatmullRom.alpha(0.7));

    return (
      <>
        <Axes
          yScale={scale.yScale}
          xScale={xScale}
          width={width}
          height={height}
          right={{
            ticksPosition: 'before',
            legendPosition: 'start',
            tickValues: 5,
          }}
        />
        <path
          // @ts-ignore
          d={lineGenerator(bars)}
          fill="none"
          stroke={lineColor}
          strokeWidth={2}
          style={{ pointerEvents: 'none' }}
        />
        {bars.map((bar, idx) => (
          <circle
            key={bar.key}
            cx={bar.x + bar.width / 2}
            // @ts-ignore
            cy={scale.yScale(data[idx] || 0)}
            r={3}
            fill={lineColor}
            stroke={lineColor}
            style={{ pointerEvents: 'none' }}
          />
        ))}
      </>
    );
  };
  return LineComponent;
};

export default composeLineChart;
