import { fetchInvitationsRequest } from 'actions';
import type { Invitation, NormalizedResource, Selector } from 'types';

export const getInvitationsLoading: Selector<boolean> = (state) =>
  state.invitations.loading.includes(fetchInvitationsRequest.toString());

export const getInvitations: Selector<NormalizedResource<Invitation>> = (state) =>
  state.invitations.items;

export const getInvitationsTotalCount: Selector<number> = (state) => state.invitations.count;

export const getInvitationsList: Selector<Invitation[]> = (state) => {
  const { items } = state.invitations;

  return Object.keys(items).map((uuid) => items[uuid]);
};
