import { clearURLParams } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import GlobalNavbar from 'components/Navbar/GlobalNavbar';
import RuleGroupsTable from 'components/RuleGroupsTable';
import SearchInput from 'components/SearchInput';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCreateRuleGroupLoading } from 'selectors/ruleGroup';
import { getFetchAllRuleGroupsLoading, getRuleGroupsList } from 'selectors/ruleGroups';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';
import CreateRuleGroupModal from './CreateRuleGroupModal';
import RuleGroupListCount from './RuleGroupListCount';

const RuleGroupsList: React.FC = () => {
  const [isCreateRuleModalOpen, setIsCreateRuleModalOpen] = useState(false);

  const loading = useSelector(getFetchAllRuleGroupsLoading);
  const createRuleLoading = useSelector(getCreateRuleGroupLoading);
  const rules = useSelector(getRuleGroupsList);
  const dispatch = useDispatch();
  const toggleCreateRuleModal = (): void => {
    setIsCreateRuleModalOpen(!isCreateRuleModalOpen);
  };

  const handleResetFiltersClick = (): void => {
    logEvent('rule-groups-list-reset-filters');
    dispatch(clearURLParams());
  };

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <GlobalNavbar />
      <div className="pt-8">
        <header>
          <div className="max-w-7xl mx-auto px-6">
            <div className="flex items-center justify-between">
              <div className="flex flex-row items-center">
                <h2 className="title">Models</h2>
                {loading && <LoadingIndicator className="ml-2" size="5" />}
              </div>
              <button
                type="button"
                className="flex flex-row justify-center button button--primary h-8 w-30 ml-2 shadow-sm rounded"
                data-testid="create-rule-group-button"
                onClick={toggleCreateRuleModal}
              >
                <span>{createRuleLoading ? <LoadingIndicator size="5" /> : 'Add New'}</span>
              </button>
            </div>

            <div className="flex flex-row justify-between mt-4 flex-shrink-0">
              <div className="flex flex-row gap-2">
                <SearchInput
                  resource={resourceQueryParamName.ruleGroups}
                  field="name"
                  className="h-8"
                />
                <button
                  type="button"
                  className="bg-white border-0 focus:outline-none inline-flex items-end leading-4 text text-body text-litlingo-gray-6 hover:text-litlingo-gray-4 underline"
                  data-testid="reset-filters-button"
                  onClick={handleResetFiltersClick}
                >
                  Clear Filters
                </button>
              </div>
              <div className="self-end">
                <RuleGroupListCount />
              </div>
            </div>
          </div>
        </header>
        <main>
          <div className="mt-2 mb-8 max-w-7xl mx-auto px-6 text-left">
            <RuleGroupsTable rules={rules} loading={loading} />
          </div>
        </main>
      </div>
      {isCreateRuleModalOpen && (
        <CreateRuleGroupModal
          isModalOpen={isCreateRuleModalOpen}
          toggleModalOpen={toggleCreateRuleModal}
        />
      )}
    </div>
  );
};

export default RuleGroupsList;
