import CollapsableSection from 'components/CollapsableSection';
import LinkLookup from 'components/LinkLookup';
import useIdentifierCategories from 'hooks/identifiers/useIdentifierCategories';
import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import { getSelectedIdentifierRevision } from 'selectors/identifier';
import { useSelector } from 'store';

type UseCasesModels = {
  name: string;
  uuid: string;
  group: string;
  rules: {
    name: string;
    uuid: string;
    updated: string;
  }[];
};

const IdentifierAssociatedModels: React.FC = () => {
  const identifierRevision = useSelector(getSelectedIdentifierRevision);
  const { data } = useIdentifierCategories(identifierRevision?.uuid || '');

  const useCases = data?.reduce<Record<string, UseCasesModels>>((acc, val) => {
    if (acc[val.model_uuid]) {
      if (!acc[val.model_uuid].rules.find((r) => r.uuid === val.rule?.rule_group_uuid)) {
        acc[val.model_uuid].rules.push({
          name: val.rule?.name || '',
          uuid: val.rule?.rule_group_uuid || '',
          updated: val.rule?.updated_at || '',
        });
      }
    } else {
      acc[val.model_uuid] = {
        name: val.model?.name || '',
        uuid: val.model_uuid || '',
        // @ts-ignore
        group: val.model.kind || '',
        rules: [
          {
            name: val.rule?.name || '',
            uuid: val.rule?.rule_group_uuid || '',
            updated: val.rule?.updated_at || '',
          },
        ],
      };
    }
    return acc;
  }, {});

  const useCaseModels = data?.reduce((acc, val) => {
    if (!acc.find((i) => i.uuid === val.model_uuid && i.ruleUuid === val.rule?.rule_group_uuid)) {
      acc.push({
        uuid: val.model_uuid,
        ruleUuid: val.rule?.rule_group_uuid || '',
      });
    }
    return acc;
  }, [] as { uuid: string; ruleUuid: string }[]);

  return (
    <CollapsableSection
      headerTitle={`Associated Models (${(useCaseModels ?? [])?.length})`}
      headerClasses="w-full h-7.5 px-4 flex flex-row justify-between items-center bg-litlingo-gray-1"
      contentClasses="relative p-4 bg-white"
      titleClasses="text-body"
      isDefaultClosed={(useCaseModels ?? [])?.length === 0}
      useArrowIcon
    >
      <ul className="flex flex-col gap-1 list-disc list-outside ml-4 bg-white">
        {useCases && Object.values(useCases).length > 0 ? (
          Object.values(useCases).map((u) => (
            <li key={u.uuid} className="text-small">
              <LinkLookup
                routeName="global-model-manager"
                routeParams={{ modelId: u.uuid }}
                className="text-litlingo-primary-120 underline"
              >
                {u.name}
              </LinkLookup>
              <span className="text-litlingo-gray-5"> {capitalize(u.group)}</span>
              <div className="flex flex-col gap-1 pl-2 pt-1">
                {u.rules.map((r) => (
                  <div key={r.uuid} className="flex flex-row gap-1">
                    <svg
                      width="11"
                      height="11"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1 0V6C1 8.20914 2.79086 10 5 10H11" stroke="#5C5C5C" />
                    </svg>
                    <LinkLookup
                      routeName="global-rule-group-manager"
                      type="button"
                      className={`self-end  focus:outline-none text-litlingo-primary-120 underline `}
                      routeParams={{ ruleId: r.uuid }}
                    >
                      <span>{r.name}</span>
                    </LinkLookup>
                    <span className="whitespace-no-wrap text-litlingo-gray-5">
                      {' '}
                      ({moment(r.updated).fromNow()})
                    </span>
                  </div>
                ))}
              </div>
            </li>
          ))
        ) : (
          <span>No associated models</span>
        )}
      </ul>
    </CollapsableSection>
  );
};

export default IdentifierAssociatedModels;
