import PermissionsLink from 'components/PermissionsLink';
import React from 'react';
import type { RuleResults, UUID } from 'types';

type ComponentProps = {
  annotatorId?: UUID;
  annotationsMatched: UUID[];
  rulesViolated: RuleResults[];
  classes?: string;
};

const SentencesViolatedSection: React.FC<ComponentProps> = ({
  annotatorId,
  annotationsMatched,
  rulesViolated,
  classes,
}) => {
  if (annotatorId && annotationsMatched && annotationsMatched.length !== 0) {
    return (
      <>
        <div className={`w-1/3 mt-6 litlingo-pink-color mr-4 mb-4 rounded ${classes}`}>
          <div className="flex inline-flex self-center p-4">
            <svg
              className="h-5 w-5 mr-2 text font-bold"
              fill="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zm12.73-1.41A8 8 0 104.34 4.34a8 8 0 0011.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z" />
            </svg>
            <span className="text-xl text font-bold">{`Matched ${annotationsMatched.length}`}</span>
          </div>
          <div className="h-32 overflow-auto">
            <ol className="list-decimal ml-10 pl-4 text-sm text whitespace-no-wrap">
              {annotationsMatched.map((match) => (
                <li key={match} className="w-full">
                  <span className="text text--primary">{match}</span>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </>
    );
  }
  if (!annotatorId && rulesViolated && rulesViolated.length !== 0) {
    return (
      <>
        <div className={`w-1/3 ${classes} mt-6 litlingo-pink-color mr-4 mb-4 rounded `}>
          <div className="flex inline-flex self-center p-4">
            <svg
              className="h-5 w-5 mr-2 text font-bold"
              fill="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zm12.73-1.41A8 8 0 104.34 4.34a8 8 0 0011.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z" />
            </svg>
            <span className="text-xl text font-bold" data-testid="matched-title">
              {`Matched ${rulesViolated.length} ${rulesViolated.length > 1 ? 'models' : 'model'}`}
            </span>
          </div>
          <div className="h-32 overflow-auto">
            <ol className="list-decimal ml-10 pl-4 text-sm text whitespace-no-wrap">
              {rulesViolated.map((ruleItem) => (
                <li key={ruleItem.rule.uuid} className="w-full">
                  {ruleItem.campaigns_with_rule &&
                    ruleItem.campaigns_with_rule.map((campaign, idx) => (
                      <span key={campaign.uuid}>
                        {idx > 0 ? ' | ' : ''}
                        <PermissionsLink
                          routeName="campaign-detail"
                          routeParams={{ campaignId: campaign.uuid }}
                          className="text text--primary hover:underline"
                          action="campaigns.retrieve"
                        >
                          {campaign.name}
                        </PermissionsLink>
                        {' - '}
                        <PermissionsLink
                          routeName="rule-manager-campaign"
                          routeParams={{ ruleId: ruleItem.rule.uuid, campaignId: campaign.uuid }}
                          className="text text--primary hover:underline"
                          action="rules.retrieve"
                        >
                          {ruleItem.rule.name}
                        </PermissionsLink>
                        {`${
                          idx + 1 === ruleItem.campaigns_with_rule.length ? '\u00A0'.repeat(10) : ''
                        }`}
                      </span>
                    ))}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={`w-1/3 mt-6 ${classes}`}>
      <div className="litlingo-gray-bg-color-3 mr-4 rounded">
        <div className="flex inline-flex p-4 litlingo-gray-bg-color-3 rounded">
          <svg
            className="h-5 w-5 mr-2 text text--bold"
            fill="#FCFCFC"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            viewBox="-0.5 0 22 20"
            stroke="#FCFCFC"
          >
            <path d="M0 10a10 10 0 1120 0 10 10 0 01-20 0zm16.32-4.9L5.09 16.31A8 8 0 0016.32 5.09zm-1.41-1.42A8 8 0 003.68 14.91L14.91 3.68z" />
          </svg>
          <span className="text-xl text text--bold text--white">
            {`No ${annotatorId ? 'language' : 'model'} matched`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SentencesViolatedSection;
