import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  applyPermissionsToSelectedUsersAndSaveFullFil,
  applyPermissionsToSingleSelectedUserAndSaveFullfil,
  saveUsersSuccess,
} from 'actions';
import {
  InvitationStates,
  addExcludedInsight,
  addExcludedPrevent,
  addExcludedRoles,
  addExcludedTeams,
  addIncludedInsight,
  addIncludedPrevent,
  addIncludedRoles,
  addIncludedTeams,
  addUsers,
  applyPermissions,
  applyPermissionsSingleUser,
  changeEmail,
  changeName,
  changePrivacyLevel,
  changeSendInvitation,
  clearPermissions,
  clearState,
  fetchUserInvitationStateSuccess,
  removeExcludedInsight,
  removeExcludedPrevent,
  removeExcludedRoles,
  removeExcludedTeams,
  removeIncludedInsight,
  removeIncludedPrevent,
  removeIncludedRoles,
  removeIncludedTeams,
  removeUsers,
  selectAllUsers,
  selectUsers,
  setNeedToApplyChanges,
} from 'actions/userPermissionsPage';
import { userTypesMapping } from 'constants/userRoles';
import type { ErrorObject, Role, Team, User } from 'types';
import { userRolesToTypes, userTypesToRoles } from 'utils/userRoles';

type ModifiedUser = User & { modified?: boolean };

type UserState = {
  loading: string[];
  error: ErrorObject | null;
  name: string;
  email: string;
  selectedUsers: User[];
  users: ModifiedUser[];
  includedTeams: Team[];
  excludedTeams: Team[];
  includedRoles: string[];
  excludedRoles: string[];
  includedInsight: string[];
  excludedInsight: string[];
  includedPrevent: string[];
  excludedPrevent: string[];
  sendInvitation: boolean;
  invitationState: InvitationStates['invitation'];
  privacyLevel: User['privacy_level'];
  unsavedChanges: boolean;
  selectAllUsers: boolean;
};

type UserReducer<P = void> = (state: UserState, payload: PayloadAction<P>) => UserState;

const defaultState: UserState = {
  loading: [],
  error: null,
  name: '',
  email: '',
  selectedUsers: [],
  users: [],
  includedTeams: [],
  excludedTeams: [],
  includedRoles: [],
  excludedRoles: [],
  includedInsight: [],
  excludedInsight: [],
  includedPrevent: [],
  excludedPrevent: [],
  sendInvitation: true,
  invitationState: null,
  privacyLevel: 'standard',
  unsavedChanges: false,
  selectAllUsers: false,
};

const handleAddUsers: UserReducer<User[]> = (state, { payload }) => ({
  ...state,
  users: [...state.users, ...payload],
});

const handleRemoveUsers: UserReducer<User[]> = (state, { payload }) => ({
  ...state,
  users: state.users.filter((u) => !payload.some((user) => user.uuid === u.uuid)),
});

const handleChangeName: UserReducer<string> = (state, { payload }) => ({
  ...state,
  name: payload,
});

const handleChangeEmail: UserReducer<string> = (state, { payload }) => ({
  ...state,
  email: payload,
});

const handleSelectUsers: UserReducer<User[]> = (state, { payload }) => {
  if (payload.length === 1) {
    const user = payload[0];

    const insight = [];

    if (user.insight_active_email) {
      insight.push('email');
    }
    if (user.insight_active_chat) {
      insight.push('chat');
    }

    const prevent = [];

    if (user.prevent_active_email) {
      prevent.push('email');
    }
    if (user.prevent_active_chat) {
      prevent.push('chat');
    }

    const userTypes = userRolesToTypes(user?.roles || []).map((t) => t.label);

    return {
      ...state,
      name: user.name || '',
      email: user.email || '',
      selectedUsers: payload,
      includedTeams: user.teams || [],
      includedRoles: userTypes,
      includedInsight: insight,
      includedPrevent: prevent,
      privacyLevel: user.privacy_level,
      excludedTeams: [],
      excludedRoles: [],
      excludedInsight: [],
      excludedPrevent: [],
    };
  }

  return {
    ...state,
    name: '',
    email: '',
    selectedUsers: payload,
    includedTeams: [],
    includedRoles: [],
    includedInsight: [],
    includedPrevent: [],
    privacyLevel: 'standard',
  };
};

const handleSelectAllUsers: UserReducer<boolean> = (state, { payload }) => ({
  ...state,
  selectAllUsers: payload,
});

const handleAddIncludedTeams: UserReducer<Team[]> = (state, { payload }) => ({
  ...state,
  includedTeams: [...state.includedTeams, ...payload],
});

const handleRemoveIncludedTeams: UserReducer<Team[]> = (state, { payload }) => ({
  ...state,
  includedTeams: state.includedTeams.filter((t) => payload.some((team) => team.uuid !== t.uuid)),
});

const handleAddExcludedTeams: UserReducer<Team[]> = (state, { payload }) => ({
  ...state,
  excludedTeams: [...state.excludedTeams, ...payload],
});

const handleRemoveExcludedTeams: UserReducer<Team[]> = (state, { payload }) => ({
  ...state,
  excludedTeams: state.excludedTeams.filter((t) => payload.some((team) => team.uuid !== t.uuid)),
});

const handleAddIncludedRoles: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  includedRoles: [...state.includedRoles, ...payload],
});

const handleRemoveIncludedRoles: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  includedRoles: state.includedRoles.filter((r) => payload.every((role) => role !== r)),
});

const handleAddExcludedRoles: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  excludedRoles: [...state.excludedRoles, ...payload],
});

const handleRemoveExcludedRoles: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  excludedRoles: state.excludedRoles.filter((r) => payload.some((role) => role !== r)),
});

const handleAddIncludedInsight: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  includedInsight: [...state.includedInsight, ...payload],
});

const handleRemoveIncludedInsight: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  includedInsight: state.includedInsight.filter((i) => payload.some((insight) => insight !== i)),
});

const handleAddExcludedInsight: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  excludedInsight: [...state.excludedInsight, ...payload],
});

const handleRemoveExcludedInsight: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  excludedInsight: state.excludedInsight.filter((i) => payload.some((insight) => insight !== i)),
});

const handleAddIncludedPrevent: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  includedPrevent: [...state.includedPrevent, ...payload],
});

const handleRemoveIncludedPrevent: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  includedPrevent: state.includedPrevent.filter((p) => payload.some((prevent) => prevent !== p)),
});

const handleAddExcludedPrevent: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  excludedPrevent: [...state.excludedPrevent, ...payload],
});

const handleRemoveExcludedPrevent: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  excludedPrevent: state.excludedPrevent.filter((p) => payload.some((prevent) => prevent !== p)),
});

const handleChangePrivacyLevel: UserReducer<User['privacy_level']> = (state, { payload }) => ({
  ...state,
  privacyLevel: payload,
});

const handleChangeSendInvitation: UserReducer<boolean> = (state, { payload }) => ({
  ...state,
  sendInvitation: payload,
});

const handleClearPermissions: UserReducer = (state) => ({
  ...defaultState,
  users: state.users,
  selectedUsers: state.selectedUsers,
  selectAllUsers: state.selectAllUsers,
});

const handleClearState: UserReducer = () => ({
  ...defaultState,
});

const handleApplyPermissions: UserReducer = (state) => {
  const { selectedUsers } = state;

  const teamsToAdd = state.includedTeams;
  const teamsToRemove = state.excludedTeams;

  const rolesToAdd = state.includedRoles;
  const rolesToRemove = state.excludedRoles;

  const insightToAdd = state.includedInsight;
  const insightToRemove = state.excludedInsight;

  const preventToAdd = state.includedPrevent;
  const preventToRemove = state.excludedPrevent;

  const { privacyLevel } = state;

  const modifiedUsers = state.users
    .filter((user) => selectedUsers.some((u) => u.uuid === user.uuid))
    .map((user) => JSON.parse(JSON.stringify(user)) as ModifiedUser);

  modifiedUsers.forEach((user) => {
    user.modified = true;

    // add teams
    teamsToAdd.forEach((team) => {
      if (!user.teams?.find((t) => t.uuid === team.uuid)) {
        if (user.teams) {
          user.teams?.push(team);
        } else {
          user.teams = [team];
        }
      }
    });

    // remove teams
    const newTeams = user.teams?.filter((t) => !teamsToRemove.some((team) => team.uuid === t.uuid));
    user.teams = newTeams;

    const userTypesRoles = userRolesToTypes(user.roles as Role[]);

    // add roles
    rolesToAdd.forEach((role) => {
      if (!userTypesRoles?.find((r) => r.label === role)) {
        const userType = Object.values(userTypesMapping).find((uT) => uT.label === role);
        if (userType) {
          userTypesRoles.push(userType);
        }
      }
    });

    // remove roles
    const newRoles = userTypesRoles?.filter((r) => !rolesToRemove.some((role) => role === r.label));
    user.roles = userTypesToRoles(newRoles.map((r) => r.label));

    // add insight
    insightToAdd.forEach((insight) => {
      if (insight === 'email') {
        user.insight_active_email = true;
      }
      if (insight === 'chat') {
        user.insight_active_chat = true;
      }
    });

    // remove insight
    insightToRemove.forEach((insight) => {
      if (insight === 'email') {
        user.insight_active_email = false;
      }
      if (insight === 'chat') {
        user.insight_active_chat = false;
      }
    });

    // add prevent
    preventToAdd.forEach((prevent) => {
      if (prevent === 'email') {
        user.prevent_active_email = true;
      }
      if (prevent === 'chat') {
        user.prevent_active_chat = true;
      }
    });

    // remove prevent
    preventToRemove.forEach((prevent) => {
      if (prevent === 'email') {
        user.prevent_active_email = false;
      }
      if (prevent === 'chat') {
        user.prevent_active_chat = false;
      }
    });

    user.privacy_level = privacyLevel || 'standard';
  });

  return {
    ...state,
    users: state.users.map((user) => {
      const modified = modifiedUsers.find((u) => u.uuid === user.uuid);

      if (modified) return modified;
      return user;
    }),

    selectedUsers: state.selectedUsers.map((user) => {
      const modified = modifiedUsers.find((u) => u.uuid === user.uuid);

      if (modified) return modified;
      return user;
    }),
  };
};

const handleApplyPermissionsToSelectedUsersAndSave: UserReducer<User[]> = (state, { payload }) => ({
  ...state,
  selectedUsers: payload,
});

const handleApplyPermissionsSingleUser: UserReducer = (state) => {
  const selectedUser = state.selectedUsers[0];

  const { name, email } = state;
  const teamsToAdd = state.includedTeams;
  const rolesToAdd = state.includedRoles;
  const insightToAdd = state.includedInsight;
  const preventToAdd = state.includedPrevent;

  const { privacyLevel } = state;

  const modifiedUser = JSON.parse(JSON.stringify(selectedUser)) as ModifiedUser;

  modifiedUser.name = name;
  modifiedUser.email = email;
  modifiedUser.modified = true;
  modifiedUser.teams = teamsToAdd;
  modifiedUser.roles = userTypesToRoles(rolesToAdd);

  insightToAdd.forEach((insight) => {
    if (insight === 'email') {
      modifiedUser.insight_active_email = true;
    }
    if (insight === 'chat') {
      modifiedUser.insight_active_chat = true;
    }
  });

  preventToAdd.forEach((prevent) => {
    if (prevent === 'email') {
      modifiedUser.prevent_active_email = true;
    }
    if (prevent === 'chat') {
      modifiedUser.prevent_active_chat = true;
    }
  });

  modifiedUser.privacy_level = privacyLevel || 'standard';

  return {
    ...state,
    users: state.users.map((user) => {
      if (user.uuid === modifiedUser.uuid) return modifiedUser;
      return user;
    }),
  };
};

const handleApplyPermissionsToSingleSelectedUserAndSave: UserReducer<User[]> = (
  state,
  { payload }
) => ({
  ...state,
  selectedUsers: payload,
});

const handleFetchUserInvitationStateSuccess: UserReducer<InvitationStates> = (
  state,
  { payload }
) => ({
  ...state,
  invitationState: payload.invitation,
});

const handleSetNeedToApplyChanges: UserReducer<boolean> = (state, { payload }) => ({
  ...state,
  unsavedChanges: payload,
});

const handleSaveUserSuccess: UserReducer<User[]> = (state, action) => {
  if (action.payload.length === 1) {
    return handleSelectUsers(state, action);
  }
  return {
    ...state,
  };
};

const handlers = {
  [addUsers.toString()]: handleAddUsers,
  [removeUsers.toString()]: handleRemoveUsers,

  [changeName.toString()]: handleChangeName,
  [changeEmail.toString()]: handleChangeEmail,

  [selectUsers.toString()]: handleSelectUsers,
  [selectAllUsers.toString()]: handleSelectAllUsers,

  [addIncludedTeams.toString()]: handleAddIncludedTeams,
  [removeIncludedTeams.toString()]: handleRemoveIncludedTeams,
  [addExcludedTeams.toString()]: handleAddExcludedTeams,
  [removeExcludedTeams.toString()]: handleRemoveExcludedTeams,

  [addIncludedRoles.toString()]: handleAddIncludedRoles,
  [removeIncludedRoles.toString()]: handleRemoveIncludedRoles,
  [addExcludedRoles.toString()]: handleAddExcludedRoles,
  [removeExcludedRoles.toString()]: handleRemoveExcludedRoles,

  [addIncludedInsight.toString()]: handleAddIncludedInsight,
  [removeIncludedInsight.toString()]: handleRemoveIncludedInsight,
  [addExcludedInsight.toString()]: handleAddExcludedInsight,
  [removeExcludedInsight.toString()]: handleRemoveExcludedInsight,

  [addIncludedPrevent.toString()]: handleAddIncludedPrevent,
  [removeIncludedPrevent.toString()]: handleRemoveIncludedPrevent,
  [addExcludedPrevent.toString()]: handleAddExcludedPrevent,
  [removeExcludedPrevent.toString()]: handleRemoveExcludedPrevent,

  [changePrivacyLevel.toString()]: handleChangePrivacyLevel,

  [changeSendInvitation.toString()]: handleChangeSendInvitation,

  [clearState.toString()]: handleClearState,
  [clearPermissions.toString()]: handleClearPermissions,

  [applyPermissions.toString()]: handleApplyPermissions,
  [applyPermissionsSingleUser.toString()]: handleApplyPermissionsSingleUser,

  [applyPermissionsToSelectedUsersAndSaveFullFil.toString()]:
    handleApplyPermissionsToSelectedUsersAndSave,
  [applyPermissionsToSingleSelectedUserAndSaveFullfil.toString()]:
    handleApplyPermissionsToSingleSelectedUserAndSave,
  [fetchUserInvitationStateSuccess.toString()]: handleFetchUserInvitationStateSuccess,
  [setNeedToApplyChanges.toString()]: handleSetNeedToApplyChanges,
  [saveUsersSuccess.toString()]: handleSaveUserSuccess,
};

const usersPageReducer = createReducer(defaultState, handlers);

export default usersPageReducer;
