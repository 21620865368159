import {
  fetchAnnotatorChangelog,
  fetchCustomerChangelog,
  fetchRuleChangelog,
  publishRuleVersion,
} from 'actions';
import { fetchRuleRevisionChangelog } from 'actions/ruleGroup';
import { ARROW_CLOSE_ENVELOPE, ARROW_OPEN_ENVELOPE } from 'constants/envelopeIcons';
import moment from 'moment';
import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { getFetchChangelogLoading } from 'selectors/auditLogs';
import { useSelector } from 'store';
import type { AuditLog, UUID } from 'types';

type AuditLogsTableItemProps = {
  log: AuditLog;
};

type AuditEntity = {
  type: 'rule' | 'annotator' | 'customer' | 'model' | '';
  id: UUID;
};

const AuditLogsTableItem: React.FC<AuditLogsTableItemProps> = (props) => {
  const { log } = props;

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const loading = useSelector(getFetchChangelogLoading);

  let entity: AuditEntity = {
    type: '',
    id: '',
  };

  if ('annotator_uuid' in log) {
    entity = {
      type: 'annotator',
      id: log.annotator_uuid,
    };
  } else if ('rule_uuid' in log) {
    entity = {
      type: 'rule',
      id: log.rule_uuid,
    };
  } else if ('rule_group_uuid' in log) {
    entity = {
      type: 'model',
      id: log.uuid,
    };
  } else {
    entity = {
      type: 'customer',
      id: log.uuid,
    };
  }

  const renderAction = (): string => {
    switch (entity.type) {
      case 'annotator':
        return `Edited the identifier '${log?.name}'`;

      case 'rule':
        return `Edited the model '${log?.name}'`;

      case 'model':
        return `Edited the model '${log?.name}'`;

      case 'customer':
        return `Edited the customer '${log?.name}'`;

      default:
        return '';
    }
  };

  const handleClick = (): void => {
    if (!log.changelog) {
      switch (entity.type) {
        case 'customer':
          if ('version' in log) {
            dispatch(
              fetchCustomerChangelog({ auditlogUuid: log.uuid, version: log.version.toString() })
            );
          }
          break;
        case 'rule':
          if ('version' in log) {
            dispatch(
              fetchRuleChangelog({
                ruleId: entity.id,
                auditlogUuid: log.uuid,
                version: log.version.toString(),
              })
            );
          }

          break;

        case 'model':
          dispatch(
            fetchRuleRevisionChangelog({
              revisionId: entity.id,
              auditlogUuid: log.uuid,
            })
          );
          break;

        case 'annotator':
          if ('version' in log) {
            dispatch(
              fetchAnnotatorChangelog({
                annotatorId: entity.id,
                auditlogUuid: log.uuid,
                version: log.version.toString(),
              })
            );
          }
          break;
        default:
          break;
      }
    }
  };

  const handlePublishVersion = (): void => {
    switch (entity.type) {
      case 'customer':
        break;
      case 'rule':
        if ('version' in log) {
          dispatch(publishRuleVersion({ ruleId: entity.id, version: log.version.toString() }));
        }
        break;
      case 'annotator':
        break;
      default:
        break;
    }
  };

  const renderChangelog = (): JSX.Element | null => {
    if (!open) return null;

    if (loading && !log.changelog) {
      return (
        <tr className="bg-litlingo-gray-0.5" data-testid="loader-container">
          <td className="table-wrapper__new-td" colSpan={5}>
            <div className="pl-8 pr-6 pt-3 pb-4">
              <Skeleton />
            </div>
          </td>
        </tr>
      );
    }

    return (
      <tr className="bg-litlingo-gray-0.5">
        <td className="table-wrapper__new-td" colSpan={5}>
          <div className="flex flex-col gap-2 pl-8 pr-6 pt-3 pb-4">
            {entity.type === 'rule' && (
              <div className="flex flex-row justify-end items-center text-body">
                <button
                  type="button"
                  className="button button--secondary button--green-text font-bold h-8"
                  onClick={handlePublishVersion}
                >
                  Publish Version
                </button>
              </div>
            )}
            <ul className="text-body px-6" data-testid="changelog-list">
              {log.changelog?.map((c) => (
                <li key={c} className="list-disc my-1 break-all">
                  {c}
                </li>
              ))}
            </ul>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <tr
        key={log.uuid}
        className="border-t border-gray-200 hover:bg-gray-100 table-row"
        data-testid="audit-logs-row"
      >
        <td />
        <td className="table-wrapper__new-td">
          <span className="text">{renderAction()}</span>
        </td>

        <td className="table-wrapper__new-td">
          <span className="text">{log.updated_by?.name}</span>
        </td>

        <td className="table-wrapper__new-td">
          <span className="text">
            {log.updated_at != null && moment(new Date(log.updated_at)).format('L, LTS')}
          </span>
        </td>

        <td className="table-wrapper__new-td h-9">
          <button
            className="flex items-center justify-center focus:outline-none w-full"
            type="button"
            data-testid="open-button"
            onClick={(): void => {
              setOpen(!open);
              handleClick();
            }}
          >
            <div className="flex justify-center items-center rounded-full hover:bg-litlingo-line w-6 h-6 cursor-pointer">
              {open ? ARROW_OPEN_ENVELOPE() : ARROW_CLOSE_ENVELOPE()}
            </div>
          </button>
        </td>
      </tr>
      {renderChangelog()}
    </>
  );
};

export default AuditLogsTableItem;
