import {
  fetchTeamUsersCount,
  fetchUser,
  fetchUserIntegrationsCount,
  fetchUserRoles,
  fetchUserRolesCount,
  fetchUsers,
} from 'actions';
import User from 'components/User';
import UsersList from 'components/UsersList';
import UsersPermissions from 'components/UsersPermissions';
import { RouteSpec, SuperRouteSpec } from 'types';
import { DEFAULT_TIME, TIME_RANGE_FIELD } from 'utils/timeRanges';

const usersRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'user',
    path: '/:customerDomain/user/:userId',
    component: User,
    private: true,
    data: {
      actions: [fetchUser, fetchUserRoles],
      idFromPath: true,
      params: {
        relationships: {
          list: true,
          defaultValue: 'teams',
        },
      },
    },
  },
  {
    name: 'user-profile',
    path: '/:customerDomain/users/user/:userId',
    component: User,
    private: true,
    data: {
      actions: [fetchUser, fetchUserRoles],
      idFromPath: true,
      params: {
        relationships: {
          list: true,
          defaultValue: 'teams',
        },
      },
    },
  },
  {
    name: 'users',
    path: '/:customerDomain/users',
    component: UsersList,
    private: true,
    permissions: 'users.viewUsersPage',
    data: {
      actions: [
        fetchUsers,
        fetchUserRoles,
        fetchUserRolesCount,
        fetchUserIntegrationsCount,
        fetchTeamUsersCount,
      ],
      params: {
        include_pii: {
          defaultValue: 'true',
        },
        is_external: {
          defaultValue: 'false',
        },
        include_count: {
          defaultValue: '1',
        },
        users: {
          nestedResource: true,
          params: {
            broad_search: {},
            include_deleted: {},
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            relationships: {
              defaultValue: 'teams',
            },
            roles: {
              list: true,
            },
            group_roles: {
              list: true,
            },
            true_users: {
              options: ['true', 'false'],
            },
            team_uuids: {
              list: true,
            },
            sync_emails: {
              list: true,
            },
            order_by: {
              options: ['name', 'recent_email_communication'],
            },
            order_desc: {
              options: ['true', 'false'],
            },
            ms_graph_inactive: {
              options: ['true', 'false'],
            },
            ms_graph_active: {
              options: ['true', 'false'],
            },
            recent_email_communication: {
              list: false,
            },
            recent_chat_communication: {
              list: false,
            },
            recent_email_app_communication: {
              list: false,
            },
            insight_active_chat: {},
            insight_active_email: {},
            prevent_active_chat: {},
            prevent_active_email: {},
          },
        },
      },
    },
  },
  {
    name: 'users-permissions',
    path: '/:customerDomain/users-permissions',
    component: UsersPermissions,
    private: true,
    permissions: 'users.viewUsersPermissionsPage',
    data: {
      actions: [fetchUsers, fetchUserRoles],
      params: {
        [TIME_RANGE_FIELD]: {
          defaultValue: DEFAULT_TIME,
        },
        include_pii: {
          defaultValue: 'true',
        },
        is_external: {
          defaultValue: 'false',
        },
        include_count: {
          defaultValue: '1',
        },
        users: {
          nestedResource: true,
          params: {
            broad_search: {},
            include_deleted: {},
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            relationships: {
              defaultValue: 'teams',
            },
            roles: {
              list: true,
            },
            team_uuids: {
              list: true,
            },
            sync_emails: {
              list: true,
            },
          },
        },
      },
    },
  },

  {
    path: '/user',
    component: (): null => null,
    private: true,
  },
  {
    path: '/users/customers',
    component: (): null => null,
    private: true,
  },
  {
    path: '/users',
    component: (): null => null,
    private: true,
  },
];
export default usersRoutes;
