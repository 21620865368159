import React, { useState } from 'react';

export interface ItemType {
  label: string | null;
  content: JSX.Element[] | null;
}

type ComponentProps<I> = {
  item: I;
  handleOnClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: I) => void;
  component?: (
    item: I,
    level: number,
    expanded: boolean,
    handleExpandClick: () => void
  ) => JSX.Element | null;
  level: number;
  renderExpandIcon?: boolean;
  indentation?: boolean;
  elementsGap?: boolean;
};

const ListItem = <I extends ItemType>(props: ComponentProps<I>): ReturnType<React.FC> => {
  const {
    item,
    handleOnClick,
    component,
    level,
    renderExpandIcon = true,
    indentation = true,
    elementsGap,
  } = props;

  const [active, setActive] = useState(false);

  const expandClick = (): void => {
    setActive(!active);
  };

  const onTitleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (handleOnClick) handleOnClick(e, item);
  };

  const ARROW_CLOSE_ENVELOPE = (
    <svg
      data-testid="arrow-close-envelope"
      width="10"
      height="10"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 1.16797L7.33333 7.0013L1.5 12.8346"
        stroke="#828282"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const ARROW_OPEN_ENVELOPE = (
    <svg
      data-testid="arrow-open-envelope"
      width="10"
      height="10"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8337 1.5L7.00033 7.33333L1.16699 1.5"
        stroke="#828282"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const icon = (): JSX.Element | null => {
    if (item.content && item.content.length > 0) {
      if (active) {
        return ARROW_OPEN_ENVELOPE;
      }
      return ARROW_CLOSE_ENVELOPE;
    }
    return null;
  };

  return (
    <>
      <div className="flex gap-2 items-center cursor-pointer">
        {renderExpandIcon && (
          <div
            className="w-2"
            onClick={(): void => expandClick()}
            role="button"
            aria-hidden
            data-testid="expand-button-click"
          >
            {icon()}
          </div>
        )}
        {component && component(item, level, active, expandClick) ? (
          <div className="w-full">{component(item, level, active, expandClick)}</div>
        ) : (
          <button
            type="button"
            className="text-body focus:outline-none"
            onClick={(e): void => onTitleClick(e)}
          >
            {item.label}
          </button>
        )}
      </div>
      {item.content && (
        <div
          className={`flex flex-col ${elementsGap ? 'gap-2' : ''} ${indentation ? 'pl-4' : ''} ${
            active ? 'block' : 'hidden'
          }`}
        >
          {item.content}
        </div>
      )}
    </>
  );
};

ListItem.defaultProps = {
  handleOnClick: (): null => null,
  component: (): null => null,
};

export default ListItem;
