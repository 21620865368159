import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import MultiselectDropdownRedesign, {
  MultiSelectOption,
} from 'components/MultiSelectDropdown/MultiSelectDropdownRedesign';
import Labels from 'components/UsersPermissions/UserPermissionsForm/Labels';
import { startCase } from 'lodash';
import React from 'react';
import { Selector, useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'reducers';

type ComponentProps<T, A, S> = {
  className?: string;
  includedItemsSelector: S;
  excludedItemsSelector?: S;
  addIncludedAction: A;
  removeIncludedAction: A;
  addExcludedAction?: A;
  removeExcludedAction?: A;
  options: T[];
  title?: string;
  showLabels?: boolean;
};

const PermissionsSelect = <
  T extends string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  A extends ActionCreatorWithOptionalPayload<any, string>,
  S extends Selector<GlobalState, T[]>
>(
  props: ComponentProps<T, A, S>
): ReturnType<React.FC> => {
  const {
    className,
    addIncludedAction,
    removeIncludedAction,
    addExcludedAction,
    removeExcludedAction,
    excludedItemsSelector,
    includedItemsSelector,
    options,
    title,
    showLabels,
  } = props;

  const dispatch = useDispatch();
  const includedItems = useSelector(includedItemsSelector);
  const excludedItems = useSelector(excludedItemsSelector || ((): T[] => []));

  const handleChange = (option: MultiSelectOption, exclude = false): void => {
    const selected =
      excludedItems.find((r) => r === option.value) ||
      includedItems.find((r) => r === option.value);

    if (exclude) {
      if (selected) {
        if (removeExcludedAction) {
          dispatch(removeExcludedAction([selected]));
        }
      } else if (addExcludedAction) {
        dispatch(addExcludedAction([option.value]));
      }
    } else if (selected) {
      dispatch(removeIncludedAction([selected]));
    } else {
      dispatch(addIncludedAction([option.value]));
    }
  };

  const handleDelete = (option: MultiSelectOption): void => {
    const inExclude = excludedItems.find((r) => r === option.value);
    const inInclude = includedItems.find((r) => r === option.value);

    if (inInclude) {
      dispatch(removeIncludedAction([option.value]));
    }

    if (inExclude) {
      if (removeExcludedAction) {
        dispatch(removeExcludedAction([option.value]));
      }
    }
  };

  return (
    <div className="flex flex-row gap-2">
      <div className="min-w-42 w-42">
        <MultiselectDropdownRedesign
          className={className || ''}
          placeholder={title}
          onChange={handleChange}
          options={options.map((o) => ({ value: o, label: startCase(o) }))}
          selectedItems={includedItems.map((i) => ({ value: i, label: i }))}
          excludedSelectedItems={excludedItems.map((i) => ({ value: i, label: i }))}
          dataTestid="active"
        />
      </div>
      {showLabels && (
        <Labels
          includedLabels={includedItems.map((i) => ({ value: i, label: i }))}
          excludedLabels={excludedItems.map((i) => ({ value: i, label: i }))}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default PermissionsSelect;
