/* eslint-disable no-unused-expressions */
/* eslint-disable max-lines */

import {
  clearTestSentence,
  postTestSentence,
  replaceURLParams,
  saveTestCase,
  setCurrentTestRuleId,
  setShowHighlights,
} from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import SelectRedesign from 'components/Select/SelectRedesign';
import SentencesViolatedSidebarSection from 'components/SentencesViolatedSection/SentencesViolatedSidebarSection';
import keyMap from 'constants/configHotKeys';
import { EXPAND_ICON } from 'constants/dashboardIcons';
import { platformNames } from 'constants/platform';
import React, { useRef, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCustomerDomain } from 'selectors/auth';
import {
  getAnnotatedTestSentenceResult,
  getCampaignsRulesViolated,
  getCommunicationTest,
  getTestSentenceLoading,
} from 'selectors/communications';
import { getSelectedNode } from 'selectors/config';
import { getNavParams } from 'selectors/nav';
import { getConfigRuleAsArray, getCurrentTestRuleId, getRule } from 'selectors/rule';
import { getShowRuleHighlights } from 'selectors/rules';
import type { Platform } from 'types';
import logEvent from 'utils/analytics';

type RouterParams = {
  ruleId: string;
  campaignId: string;
};

type ComponentProps = {
  testPhrase?: string;
};

const TestSentenceVisualizerSidebar: React.FC<ComponentProps> = ({ testPhrase }) => {
  const { useEffect } = React;
  const dispatch = useDispatch();
  const { ruleId, campaignId } = useParams<RouterParams>();

  const domain = useSelector(getCustomerDomain);
  const rule = useSelector(getRule(ruleId)) || {};
  const campaignRulesViolated = useSelector(getCampaignsRulesViolated);
  const arrayTree = useSelector(getConfigRuleAsArray);
  const selectedNode = useSelector(getSelectedNode);
  const testSentenceLoading = useSelector(getTestSentenceLoading);
  const showHighlights = useSelector(getShowRuleHighlights);

  const resultLines = useSelector(getAnnotatedTestSentenceResult);
  const communication = useSelector(getCommunicationTest);
  const bodyParam = useSelector(getNavParams).body || '';
  const currentTestRuleId = useSelector(getCurrentTestRuleId);

  const [shouldTestFire, setShouldTestFire] = useState(false);
  const [platform, setPlatform] = useState<string>('this-model');
  const [body, setBody] = useState<string>(bodyParam as string);
  const [debouncedBody, setDebouncedBody] = useState('');

  const RunButtonRef = useRef<HTMLButtonElement>(null);
  const SaveButtonRef = useRef<HTMLButtonElement>(null);
  const TextAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const channel = new BroadcastChannel('test-visualizer-channel');
    if (communication) {
      channel.postMessage({ communication });
    }
  }, [communication]);

  useEffect(() => {
    const channel = new BroadcastChannel('test-visualizer-channel');

    const handleEvent = (event: MessageEvent<{ ready: boolean }>): void => {
      if (event.data.ready) {
        if (communication) {
          channel.postMessage({ communication });
        }
      }
    };

    channel.addEventListener('message', handleEvent);

    return () => {
      channel.removeEventListener('message', handleEvent);
    };
  }, [communication]);

  // works as a componentWillUnmount to avoid having data when returning to this view
  // @ts-ignore
  useEffect(() => {
    if (!currentTestRuleId) {
      dispatch(clearTestSentence());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTestRuleId]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedBody(body), 500);
    return (): void => {
      clearTimeout(timeoutId);
    };
  }, [body]);

  useEffect(() => {
    dispatch(replaceURLParams({ body: debouncedBody }));
  }, [debouncedBody, dispatch]);

  useEffect(() => {
    if (testPhrase) {
      setBody(testPhrase || '');
    }
  }, [testPhrase]);

  useEffect(() => {
    if (!bodyParam) return;
    setBody(bodyParam as string);
  }, [bodyParam]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    logEvent('tests-run-test');

    const rules = rule.uuid ? [rule.uuid] : undefined;
    const campaigns = campaignId ? [campaignId] : undefined;

    let platforms: string[] = [];
    platforms = [platform];

    if (platform === 'this-model') {
      dispatch(postTestSentence({ body, campaigns, rules, platforms: ['all'] }));
    } else if (platform === 'selected-branch') {
      dispatch(postTestSentence({ body, campaigns, rules, platforms: platforms as Platform[] }));
    } else {
      dispatch(postTestSentence({ body, platforms: platforms as Platform[] }));
    }
  };

  const handlers = {
    RUN_TEST_PHRASE: (): void => {
      if (document.activeElement === TextAreaRef?.current) {
        RunButtonRef?.current?.click();
      }
    },
    RUN_TEST_PHRASE_ALT: (): void => {
      if (document.activeElement === TextAreaRef?.current) {
        RunButtonRef?.current?.click();
      }
    },
    SAVE_TO_SUITE: (event: KeyboardEvent | undefined): void => {
      if (event) {
        event.preventDefault();
        if (document.activeElement === TextAreaRef?.current) {
          SaveButtonRef?.current?.click();
        }
      }
    },
  };

  const selectTargetOptions = [
    {
      label: 'This Model',
      value: 'this-model',
    },
    {
      label: 'Selected Branch',
      value: 'selected-branch',
    },
    ...(Object.keys(platformNames) as Array<keyof typeof platformNames>).map((key) => ({
      label: platformNames[key],
      value: key,
    })),
  ];

  const handlePlatformChange = (option: string): void => {
    logEvent('tests-platform-change');
    setPlatform(option as Platform);
  };

  const handleSaveTestCase = (): void => {
    const branchId = arrayTree[selectedNode as number].id;

    const entities = {
      rule_uuid: rule.uuid || '',
      branchId: branchId || '',
    };

    dispatch(saveTestCase({ test_string: body, should_match: shouldTestFire, ...entities }));
  };

  const handleShowHighlights = (): void => {
    dispatch(setShowHighlights(!showHighlights));
  };

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <div className="flex flex-col gap-6 pb-5">
        <div className="flex flex-col gap-3">
          <div className="flex flex-row items-center gap-2">
            <span className="text-body">on</span>
            <div className="w-52">
              <SelectRedesign
                onChange={handlePlatformChange}
                options={selectTargetOptions}
                value={platform}
                valueClassName="italic"
              />
            </div>
          </div>

          <form
            onSubmit={handleSubmit}
            data-testid="test-viz-phrase-form"
            className="flex flex-col gap-4"
          >
            <div className="flex flex-col gap-1">
              <textarea
                ref={TextAreaRef}
                id="body"
                data-testid="test-viz-phrase-input"
                rows={12}
                className="form-textarea text-body w-full transition duration-150 ease-in-out resize-none"
                onChange={(event): void => {
                  setBody(event.target.value);
                }}
                value={body}
              />

              <p className="text-small">
                <strong>Ctrl + Enter </strong>
                to test the phrase.
                <strong> Ctrl + S </strong>
                saves the test.
              </p>
            </div>

            <button
              ref={RunButtonRef}
              type="submit"
              data-testid="run-test-button"
              className={`h-8 w-37 text-body button button--primary flex justify-center self-end ${
                body === '' ? 'bg-opacity-25' : ''
              }`}
              disabled={body === ''}
              onClick={(): void => {
                dispatch(setCurrentTestRuleId(ruleId));
                dispatch(setShowHighlights(true));
              }}
            >
              <span className="text-white font-bold">
                {testSentenceLoading ? <LoadingIndicator size="5" /> : 'Run Test'}
              </span>
            </button>
          </form>
        </div>
        <div className="border-b border-litlingo-gray-4" />

        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <span className="text-heading-3">Results</span>

            {resultLines ? (
              <div className="flex flex-col gap-4">
                <SentencesViolatedSidebarSection
                  campaignRulesViolated={campaignRulesViolated}
                  resultLines={resultLines}
                />
                <div className="flex flex-col gap-2 pl-0.5">
                  <span>
                    This should:<span className="text-litlingo-alert">*</span>
                  </span>
                  <div className="flex flex-row gap-5 text-small">
                    <label htmlFor="match" className="flex flex-row items-center gap-1 w-full">
                      <input
                        type="radio"
                        id="match"
                        className="input-radio"
                        name="true"
                        value="true"
                        checked={shouldTestFire}
                        onChange={(): void => setShouldTestFire(true)}
                      />
                      <span>Match</span>
                    </label>
                    <label htmlFor="no-match" className="flex flex-row items-center gap-1 w-full">
                      <input
                        type="radio"
                        id="no-match"
                        className="input-radio"
                        name="false"
                        value="false"
                        checked={!shouldTestFire}
                        onChange={(): void => setShouldTestFire(false)}
                      />
                      <span>No Match</span>
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <span className="text-body italic text-litlingo-gray-4">
                Run your test to see the results here.
              </span>
            )}
          </div>
          {resultLines && (
            <>
              <div className="flex flex-row justify-between gap-2.5">
                <button
                  type="button"
                  className="button button--secondary flex justify-center text-body w-full h-8"
                  onClick={handleShowHighlights}
                >
                  <span className="font-bold">
                    {showHighlights ? 'Hide in Logic' : 'Show in Logic'}{' '}
                  </span>
                </button>
                <button
                  type="button"
                  className="button button--primary w-full flex justify-center h-8"
                  onClick={handleSaveTestCase}
                >
                  <span className="font-bold">Add to Test Suite</span>
                </button>
              </div>

              <a
                className="flex flex-row items-center gap-2 self-center text-body"
                target="_blank"
                rel="noopener noreferrer"
                href={`${window.location.origin}/${domain}/test-viz-result`}
              >
                <span>{EXPAND_ICON}</span>
                <span>Language Visualizer Pop-out</span>
              </a>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TestSentenceVisualizerSidebar;
