import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import { fetchEventSummaryRequest } from 'actions';
import {
  addBulkTestCaseTagsFailure,
  addBulkTestCaseTagsRequest,
  addBulkTestCaseTagsSuccess,
  addTestCaseTagFailure,
  addTestCaseTagRequest,
  addTestCaseTagSuccess,
  addTestCaseToBranchFailure,
  addTestCaseToBranchRequest,
  addTestCaseToBranchSuccess,
  addToLastTestCases,
  bulkDeleteTestCases,
  bulkDeleteTestCasesFailure,
  bulkDeleteTestCasesFulfill,
  bulkDeleteTestCasesSuccess,
  bulkDuplicateTestCases,
  bulkDuplicateTestCasesFailure,
  bulkDuplicateTestCasesSuccess,
  clearLastTestCases,
  createTestCaseSuccess,
  deleteTestCaseFailure,
  deleteTestCaseSuccess,
  evaluateAsyncFailure,
  evaluateAsyncRequest,
  evaluateAsyncResultsFailure,
  evaluateAsyncResultsRequest,
  evaluateAsyncResultsSuccess,
  EvaluateAsyncResultsSuccessPayload,
  evaluateAsyncSuccess,
  evaluateFailure,
  evaluateRequest,
  exportTestCases,
  exportTestCasesFailure,
  exportTestCasesFulfill,
  fetchSingleTestCaseFailure,
  fetchSingleTestCaseRequest,
  fetchSingleTestCaseSuccess,
  fetchTestCasesFailure,
  fetchTestCasesRequest,
  fetchTestCasesSuccess,
  fetchTestCaseSummaryFailure,
  fetchTestCaseSummaryRequest,
  fetchTestCaseSummarySuccess,
  fetchTestResultsFailure,
  fetchTestResultsRequest,
  fetchTestResultsSuccess,
  FetchTestResultsSuccessPayload,
  importTestCases,
  importTestCasesFailure,
  importTestCasesFulfill,
  nextPageRequest,
  previousPageRequest,
  removeTestCaseFromBranchFailure,
  removeTestCaseFromBranchRequest,
  removeTestCaseFromBranchSuccess,
  removeTestCaseTagFailure,
  removeTestCaseTagRequest,
  removeTestCaseTagSuccess,
  saveTestCaseFailure,
  saveTestCaseRequest,
  saveTestCaseSuccess,
  upsertTestCaseFailure,
  upsertTestCaseRequest,
  upsertTestCaseSuccess,
} from 'actions/testCases';
import type {
  API,
  ErrorObject,
  MTestCase,
  NormalizedResource,
  TestCase,
  TestCasesSummary,
  UUID,
} from 'types';

export type TestCasesState = {
  items: NormalizedResource<MTestCase>;
  loading: string[];
  error: ErrorObject | null;
  asyncLoading: string[];
  limit: number;
  offset: number;
  resultsKey: string | null;
  count: number;
  summary: TestCasesSummary;
  lastTestCases: string[];
  generatedTestCase: string;
};

type TestCasesReducer<P = void> = (
  state: TestCasesState,
  payload: PayloadAction<P>
) => TestCasesState;

const defaultState: TestCasesState = {
  items: {},
  loading: [],
  asyncLoading: [],
  error: null,
  resultsKey: null,
  limit: 25,
  offset: 0,
  count: 0,
  lastTestCases: [],
  summary: {
    failing: 0,
    passing: 0,
    false_negatives: 0,
    false_positives: 0,
    true_positives: 0,
    true_negatives: 0,
    not_run: 0,
  },
  generatedTestCase: '',
};

const handleSaveTestCaseRequest: TestCasesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, saveTestCaseRequest.toString()],
});

const handleSaveTestCaseFailure: TestCasesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== saveTestCaseRequest.toString()),
});

const handleSaveTestCaseSuccess: TestCasesReducer<MTestCase> = (state, { payload }) => {
  const normalizedTestCases: NormalizedResource<MTestCase> = {};
  const currentTest = payload.uuid in state.items ? state.items[payload.uuid] : {};
  normalizedTestCases[payload.uuid] = { ...currentTest, ...payload };
  return {
    ...state,
    items: { ...normalizedTestCases, ...state.items },
    loading: state.loading.filter((s) => s !== saveTestCaseRequest.toString()),
  };
};

const handleFetchSingleTestCaseRequest: TestCasesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchSingleTestCaseRequest.toString()],
});

const handleFetchSingleTestCaseFailure: TestCasesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchSingleTestCaseRequest.toString()),
});

const handleFetchSingleTestCaseSuccess: TestCasesReducer<TestCase> = (state, { payload }) => ({
  ...state,
  items: { ...state.items, [payload.uuid]: payload },
  loading: state.loading.filter((s) => s !== fetchSingleTestCaseRequest.toString()),
});

const handleTestResultsRequest: TestCasesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchTestResultsRequest.toString()],
});

const handleTestResultsFailure: TestCasesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchTestResultsRequest.toString()),
});

const handleTestResultsSuccess: TestCasesReducer<FetchTestResultsSuccessPayload> = (
  state,
  { payload }
) => {
  const { uuid, data } = payload;
  const { records } = data;
  return {
    ...state,
    items: { [uuid]: { ...state.items[uuid], testResults: records } },
    loading: state.loading.filter((s) => s !== fetchTestResultsRequest.toString()),
  };
};

const handleEvaluateRequest: TestCasesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, evaluateRequest.toString()],
});

const handleEvaluateFailure: TestCasesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== evaluateRequest.toString()),
});

const handleEvaluateAsyncRequest: TestCasesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, evaluateAsyncRequest.toString()],
  asyncLoading: [...state.asyncLoading, evaluateAsyncRequest.toString()],
});

const handleEvaluateAsyncFailure: TestCasesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== evaluateAsyncRequest.toString()),
});

const handleEvaluateAsyncSuccess: TestCasesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== evaluateAsyncRequest.toString()),
  // TODO: Remove this when the results_key is returned back from the backend
  asyncLoading: state.loading.filter((s) => s !== evaluateAsyncRequest.toString()),
});

const handleEvaluateAsyncResultsRequest: TestCasesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, evaluateAsyncResultsRequest.toString()],
});

const handleEvaluateAsyncResultsFailure: TestCasesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== evaluateAsyncResultsRequest.toString()),
});

const handleEvaluateAsyncResultsSuccess: TestCasesReducer<EvaluateAsyncResultsSuccessPayload> = (
  state,
  { payload }
) => {
  const normalizedTestCases: NormalizedResource<MTestCase> = {};
  if (payload.results) {
    payload.results.forEach((result) => {
      const testCaseId = result.test_case_uuid;

      if (state.items[testCaseId] != null) {
        normalizedTestCases[testCaseId] = {
          ...state.items[testCaseId],
          most_recent_run_passed: result.passed,
          most_recent_ran_at: new Date().toLocaleString(),
        };
      }
    });
  }

  return {
    ...state,
    items: { ...state.items, ...normalizedTestCases },
    resultsKey: payload.results ? null : state.resultsKey,
    loading: state.loading.filter(
      (s) => s !== evaluateAsyncResultsRequest.toString() && s !== evaluateAsyncRequest.toString()
    ),
    asyncLoading: payload.results
      ? state.loading.filter((s) => s !== evaluateAsyncRequest.toString())
      : [...state.asyncLoading],
  };
};

const handleDeleteTestCaseFailure: TestCasesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleDeleteTestCaseSuccess: TestCasesReducer<string> = (state, { payload: id }) => {
  const testCases = { ...state.items };
  delete testCases[id];

  return {
    ...state,
    items: testCases,
  };
};

const handleFetchTestCasesFailure: TestCasesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchTestCasesRequest.toString()),
});

const handleFetchTestCasesRequest: TestCasesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchTestCasesRequest.toString()],
});

const handleFetchTestCasesSuccess: TestCasesReducer<API.WrappedAPIResponse<MTestCase>> = (
  state,
  { payload }
) => {
  const normalizedTestCases: NormalizedResource<MTestCase> = {};
  const { count, records } = payload;
  records.forEach((test) => {
    const currentTest = test.uuid in state.items ? state.items[test.uuid] : {};
    normalizedTestCases[test.uuid] = { ...currentTest, ...test };
  });
  return {
    ...state,
    items: { ...normalizedTestCases },
    loading: state.loading.filter((s) => s !== fetchTestCasesRequest.toString()),
    count,
  };
};

const handleNextPageRequest: TestCasesReducer = (state) => ({
  ...state,
  offset: state.offset + state.limit,
});

const handlePreviousPageRequest: TestCasesReducer = (state) => ({
  ...state,
  offset: state.offset - state.limit,
});

const handleUpsertTestCaseRequest: TestCasesReducer = (state) => ({
  ...state,
  loading: [...state.loading, upsertTestCaseRequest.toString()],
});

const handleUpsertTestCaseSuccess: TestCasesReducer<MTestCase> = (state, { payload }) => ({
  ...state,
  items: {
    ...state.items,
    [payload.uuid]: payload,
  },
  loading: state.loading.filter((s) => s !== upsertTestCaseRequest.toString()),
});

const handleUpsertTestCaseFailure: TestCasesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== upsertTestCaseRequest.toString()),
});

const handleFetchTestCaseSummaryRequest: TestCasesReducer = (state) => ({
  ...state,
  loading: [...state.loading, fetchEventSummaryRequest.toString()],
});

const handleFetchTestCaseSummarySuccess: TestCasesReducer<TestCasesSummary> = (
  state,
  { payload }
) => {
  const data = {
    failing: payload.failing || 0,
    passing: payload.passing || 0,
    false_negatives: payload.false_negatives || 0,
    false_positives: payload.false_positives || 0,
    not_run: payload.not_run || 0,
    true_positives: payload.true_positives || 0,
    true_negatives: payload.true_negatives || 0,
  };

  return {
    ...state,
    summary: {
      ...state.summary,
      ...data,
    },
    loading: state.loading.filter((s) => s !== fetchEventSummaryRequest.toString()),
  };
};

const handleFetchTestCaseSummaryFailure: TestCasesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchEventSummaryRequest.toString()),
});

const handleExportTestCases: TestCasesReducer = (state) => ({
  ...state,
  loading: [...state.loading, exportTestCases.toString()],
});

const handleExportTestCasesFailure: TestCasesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleExportTestCasesFulfill: TestCasesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== exportTestCases.toString()),
});

const handleImportTestCases: TestCasesReducer = (state) => ({
  ...state,
  loading: [...state.loading, importTestCases.toString()],
});

const handleImportTestCasesFailure: TestCasesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleImportTestCasesFulfill: TestCasesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== importTestCases.toString()),
});

const handleBulkDeleteTestCases: TestCasesReducer = (state) => ({
  ...state,
  loading: [...state.loading, bulkDeleteTestCases.toString()],
});

const handleBulkDuplicateTestCases: TestCasesReducer = (state) => ({
  ...state,
  loading: [...state.loading, bulkDuplicateTestCases.toString()],
});

const handleBulkDuplicateTestCasesSuccess: TestCasesReducer = (state) => {
  const oldLoading = [...state.loading];
  const newLoading = oldLoading.filter((item) => item !== bulkDuplicateTestCases.toString());
  return { ...state, loading: newLoading };
};

const handleBulkDuplicateTestCasesFailure: TestCasesReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
});

const handleBulkDeleteTestCasesSuccess: TestCasesReducer<{ uuids: UUID[] }> = (
  state,
  { payload }
) => ({
  ...state,
  items: Object.keys(state.items).reduce((curr, uuid) => {
    if (payload.uuids.includes(uuid)) {
      return curr;
    }

    return { ...curr, [uuid]: state.items[uuid] };
  }, {} as NormalizedResource<MTestCase>),
});

const handleBulkDeleteTestCasesFailure: TestCasesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleBulkDeleteTestCasesFulfill: TestCasesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== bulkDeleteTestCases.toString()),
});

const handleAddTestCaseTagRequest: TestCasesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, addTestCaseTagRequest.toString()],
});

const handleAddTestCaseTagSuccess: TestCasesReducer<TestCase> = (state, { payload }) => {
  const item = state.items[payload.uuid] || {};
  const updatedTestCase = {
    [payload.uuid]: { ...item, tags: payload.tags },
  };

  return {
    ...state,
    items: { ...state.items, ...updatedTestCase },
    loading: state.loading.filter((s) => s !== addTestCaseTagRequest.toString()),
  };
};

const handleAddTestCaseTagFailure: TestCasesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== addTestCaseTagRequest.toString()),
});

const handleRemoveTestCaseTagRequest: TestCasesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, removeTestCaseTagRequest.toString()],
});

const handleRemoveTestCaseTagSuccess: TestCasesReducer<TestCase> = (state, { payload }) => {
  const item = state.items[payload.uuid] || {};
  const updatedTestCase = {
    [payload.uuid]: { ...item, tags: payload.tags },
  };

  return {
    ...state,
    items: { ...state.items, ...updatedTestCase },
    loading: state.loading.filter((s) => s !== removeTestCaseTagRequest.toString()),
  };
};

const handleRemoveTestCaseTagFailure: TestCasesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== removeTestCaseTagRequest.toString()),
});

const handleAddBulkTestCaseTagsRequest: TestCasesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, addBulkTestCaseTagsRequest.toString()],
});

const handleAddBulkTestCaseTagsFailure: TestCasesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== addBulkTestCaseTagsRequest.toString()),
});

const handleAddBulkTestCaseTagsSuccess: TestCasesReducer<TestCase[]> = (state, { payload }) => {
  const updatedTestCases: NormalizedResource<TestCase> = {};
  payload.forEach((testCase) => {
    updatedTestCases[testCase.uuid] = { ...state.items[testCase.uuid], ...testCase };
  });

  return {
    ...state,
    items: {
      ...state.items,
      ...updatedTestCases,
    },
    loading: state.loading.filter((s) => s !== addBulkTestCaseTagsRequest.toString()),
  };
};

const handleAddTestCasesToBranchesRequest: TestCasesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, addTestCaseToBranchRequest.toString()],
});

const handleAddTestCasesToBranchesSuccess: TestCasesReducer<TestCase[]> = (state, { payload }) => {
  const updatedTestCases: NormalizedResource<TestCase> = {};
  payload.forEach((testCase) => {
    updatedTestCases[testCase.uuid] = { ...state.items[testCase.uuid], ...testCase };
  });
  return {
    ...state,
    items: {
      ...state.items,
      ...updatedTestCases,
    },
    loading: state.loading.filter((s) => s !== addTestCaseToBranchRequest.toString()),
    lastTestCases: payload.map((testCase) => testCase.uuid),
  };
};

const handleAddTestCasesToBranchesFailure: TestCasesReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== addTestCaseToBranchRequest.toString()),
});

const handleRemoveTestCasesFromBranchesRequest: TestCasesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, removeTestCaseFromBranchRequest.toString()],
});

const handleRemoveTestCasesFromBranchesSuccess: TestCasesReducer<TestCase[]> = (
  state,
  { payload }
) => {
  const updatedTestCases: NormalizedResource<TestCase> = {};
  payload.forEach((testCase) => {
    updatedTestCases[testCase.uuid] = { ...state.items[testCase.uuid], ...testCase };
  });
  return {
    ...state,
    items: {
      ...state.items,
      ...updatedTestCases,
    },
    loading: state.loading.filter((s) => s !== removeTestCaseFromBranchRequest.toString()),
  };
};

const handleRemoveTestCasesFromBranchesFailure: TestCasesReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== removeTestCaseFromBranchRequest.toString()),
});

const handleAddToLastTestCases: TestCasesReducer<string[]> = (state, { payload }) => ({
  ...state,
  lastTestCases: payload,
});

const handleClearLastTestCases: TestCasesReducer = (state) => ({
  ...state,
  lastTestCases: [],
});

const handleCreateTestCaseSuccess: TestCasesReducer<API.Tests.GenerateTestCase> = (
  state,
  { payload }
) => {
  const { test_case: testCase } = payload;
  return {
    ...state,
    generatedTestCase: testCase,
  };
};

const handlers = {
  [fetchTestCasesSuccess.toString()]: handleFetchTestCasesSuccess,
  [deleteTestCaseSuccess.toString()]: handleDeleteTestCaseSuccess,
  [deleteTestCaseFailure.toString()]: handleDeleteTestCaseFailure,
  [saveTestCaseRequest.toString()]: handleSaveTestCaseRequest,
  [saveTestCaseFailure.toString()]: handleSaveTestCaseFailure,
  [saveTestCaseSuccess.toString()]: handleSaveTestCaseSuccess,
  [fetchTestCasesRequest.toString()]: handleFetchTestCasesRequest,
  [fetchTestCasesFailure.toString()]: handleFetchTestCasesFailure,
  [evaluateRequest.toString()]: handleEvaluateRequest,
  [evaluateFailure.toString()]: handleEvaluateFailure,
  [nextPageRequest.toString()]: handleNextPageRequest,
  [previousPageRequest.toString()]: handlePreviousPageRequest,
  [evaluateAsyncRequest.toString()]: handleEvaluateAsyncRequest,
  [evaluateAsyncSuccess.toString()]: handleEvaluateAsyncSuccess,
  [evaluateAsyncFailure.toString()]: handleEvaluateAsyncFailure,
  [evaluateAsyncResultsRequest.toString()]: handleEvaluateAsyncResultsRequest,
  [evaluateAsyncResultsSuccess.toString()]: handleEvaluateAsyncResultsSuccess,
  [evaluateAsyncResultsFailure.toString()]: handleEvaluateAsyncResultsFailure,
  [fetchSingleTestCaseRequest.toString()]: handleFetchSingleTestCaseRequest,
  [fetchSingleTestCaseSuccess.toString()]: handleFetchSingleTestCaseSuccess,
  [fetchSingleTestCaseFailure.toString()]: handleFetchSingleTestCaseFailure,
  [fetchTestResultsRequest.toString()]: handleTestResultsRequest,
  [fetchTestResultsSuccess.toString()]: handleTestResultsSuccess,
  [fetchTestResultsFailure.toString()]: handleTestResultsFailure,
  [upsertTestCaseRequest.toString()]: handleUpsertTestCaseRequest,
  [upsertTestCaseSuccess.toString()]: handleUpsertTestCaseSuccess,
  [upsertTestCaseFailure.toString()]: handleUpsertTestCaseFailure,
  [fetchTestCaseSummaryRequest.toString()]: handleFetchTestCaseSummaryRequest,
  [fetchTestCaseSummarySuccess.toString()]: handleFetchTestCaseSummarySuccess,
  [fetchTestCaseSummaryFailure.toString()]: handleFetchTestCaseSummaryFailure,
  [exportTestCases.toString()]: handleExportTestCases,
  [exportTestCasesFailure.toString()]: handleExportTestCasesFailure,
  [exportTestCasesFulfill.toString()]: handleExportTestCasesFulfill,
  [importTestCases.toString()]: handleImportTestCases,
  [importTestCasesFailure.toString()]: handleImportTestCasesFailure,
  [importTestCasesFulfill.toString()]: handleImportTestCasesFulfill,
  [bulkDeleteTestCases.toString()]: handleBulkDeleteTestCases,
  [bulkDuplicateTestCases.toString()]: handleBulkDuplicateTestCases,
  [bulkDuplicateTestCasesSuccess.toString()]: handleBulkDuplicateTestCasesSuccess,
  [bulkDuplicateTestCasesFailure.toString()]: handleBulkDuplicateTestCasesFailure,
  [bulkDeleteTestCasesSuccess.toString()]: handleBulkDeleteTestCasesSuccess,
  [bulkDeleteTestCasesFailure.toString()]: handleBulkDeleteTestCasesFailure,
  [bulkDeleteTestCasesFulfill.toString()]: handleBulkDeleteTestCasesFulfill,
  [addTestCaseTagRequest.toString()]: handleAddTestCaseTagRequest,
  [addTestCaseTagFailure.toString()]: handleAddTestCaseTagFailure,
  [addTestCaseTagSuccess.toString()]: handleAddTestCaseTagSuccess,
  [removeTestCaseTagRequest.toString()]: handleRemoveTestCaseTagRequest,
  [removeTestCaseTagFailure.toString()]: handleRemoveTestCaseTagFailure,
  [removeTestCaseTagSuccess.toString()]: handleRemoveTestCaseTagSuccess,
  [addBulkTestCaseTagsRequest.toString()]: handleAddBulkTestCaseTagsRequest,
  [addBulkTestCaseTagsFailure.toString()]: handleAddBulkTestCaseTagsFailure,
  [addBulkTestCaseTagsSuccess.toString()]: handleAddBulkTestCaseTagsSuccess,
  [addTestCaseToBranchRequest.toString()]: handleAddTestCasesToBranchesRequest,
  [addTestCaseToBranchSuccess.toString()]: handleAddTestCasesToBranchesSuccess,
  [addTestCaseToBranchFailure.toString()]: handleAddTestCasesToBranchesFailure,
  [removeTestCaseFromBranchRequest.toString()]: handleRemoveTestCasesFromBranchesRequest,
  [removeTestCaseFromBranchSuccess.toString()]: handleRemoveTestCasesFromBranchesSuccess,
  [removeTestCaseFromBranchFailure.toString()]: handleRemoveTestCasesFromBranchesFailure,
  [addToLastTestCases.toString()]: handleAddToLastTestCases,
  [clearLastTestCases.toString()]: handleClearLastTestCases,
  [createTestCaseSuccess.toString()]: handleCreateTestCaseSuccess,
};

const testCasesReducer = createReducer(defaultState, handlers);

export default testCasesReducer;
