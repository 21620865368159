/* eslint-disable camelcase */
import {
  ReviewSkippedEnvelopesSuccessPayload,
  addEnvelopeTag,
  closeAssignment,
  closeAssignmentFailure,
  closeAssignmentFulfill,
  closeAssignmentSuccess,
  continueAssignment,
  continueAssignmentFailure,
  continueAssignmentFulfill,
  continueAssignmentRequest,
  continueAssignmentSuccess,
  continueSkippedAssignment,
  continueSkippedAssignmentFailure,
  continueSkippedAssignmentFulfill,
  continueSkippedAssignmentRequest,
  deleteAssignment,
  deleteAssignmentFailure,
  deleteAssignmentFulfill,
  deleteAssignmentRequest,
  deleteAssignmentSuccess,
  fetchAssignments,
  fetchAssignmentsFailure,
  fetchAssignmentsFulfill,
  fetchAssignmentsRequest,
  fetchAssignmentsSuccess,
  fetchOwnAssignments,
  fetchOwnAssignmentsSuccess,
  nextSkippedAssignment,
  openAssignment,
  openAssignmentFailure,
  openAssignmentFulfill,
  openAssignmentSuccess,
  openSelectedSkippedAssigment,
  openSkippedAssignment,
  removeEnvelopeTag,
  reviewAndContinueAssignment,
  reviewAndContinueAssignmentFulfill,
  reviewAndContinueAssignmentRequest,
  reviewEnvelope,
  reviewSkippedEnvelopes,
  reviewSkippedEnvelopesFailure,
  reviewSkippedEnvelopesRequest,
  reviewSkippedEnvelopesSuccess,
  setLastAssignment,
  setModalAfterReview,
  showErrorAlert,
  showSuccessAlert,
  updateAssigmentCountsFailure,
  updateAssigmentCountsRequest,
  updateAssigmentCountsSuccess,
  updateAssignmentCounts,
  upsertAssignment,
  upsertAssignmentFailure,
  upsertAssignmentFulfill,
  upsertAssignmentRequest,
  upsertAssignmentSuccess,
} from 'actions';
import { apiClient as LitLingoClient } from 'client';
import { push } from 'connected-react-router';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { matchPath } from 'react-router-dom';
import { GlobalState } from 'reducers';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import routes from 'routes';
import { getAssignment, getLastAssignment } from 'selectors/assignments';
import { getCustomerDomain } from 'selectors/auth';
import getSelectedEnvelope, { getDeclaredStatus, getTags } from 'selectors/envelopeReview';
import { getLastReview } from 'selectors/envelopes';
import { getNavParamsByResource } from 'selectors/nav';
import type { API, Assignment, SagaReturn } from 'types';
import { getRoute, reverse } from 'utils/urls';

import {
  addEnvelopeTagSaga,
  fetchSingleEnvelopeSaga,
  removeEnvelopeTagSaga,
  reviewEnvelopeSaga,
} from './envelopes';

function* fetchAssignmentsSaga(action: ReturnType<typeof fetchAssignments>): SagaReturn {
  const { payload } = action;
  yield put(fetchAssignmentsRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.assignments)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = {
    include_count: true,
    include_private: true,
    include_pii: 'true',
    ...resourceParams,
    ...payload,
  };

  const response = (yield call([LitLingoClient.resources.assignments, 'list'], {
    params: { ...params, relationships: ['created_by', 'saved_search', 'user'] },
  })) as API.Response<API.Assignments.Mine>;
  if (response.error != null) {
    yield put(fetchAssignmentsFailure(response.error));
  } else {
    yield put(fetchAssignmentsSuccess(response.data));
  }
  yield put(fetchAssignmentsFulfill());
}

function* fetchOwnAssignmentsSaga(action: ReturnType<typeof fetchAssignments>): SagaReturn {
  const { payload } = action;
  yield put(fetchAssignmentsRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.assignments)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = {
    include_count: true,
    include_private: true,
    include_pii: 'true',
    ...resourceParams,
    ...payload,
  };

  const response = (yield call([LitLingoClient.resources.assignments.extras, 'mine'], {
    params: { ...params, relationships: ['created_by', 'saved_search', 'saved_search.skipped'] },
  })) as API.Response<API.Assignments.Mine>;
  if (response.error != null) {
    yield put(fetchAssignmentsFailure(response.error));
  } else {
    yield put(fetchOwnAssignmentsSuccess(response.data));
  }
  yield put(fetchAssignmentsFulfill());
}

function* upsertAssignmentSaga(action: ReturnType<typeof upsertAssignment>): SagaReturn {
  const { payload } = action;
  yield put(upsertAssignmentRequest());

  const response = (yield call([LitLingoClient.resources.assignments, 'upsert'], {
    data: payload,
    params: {
      relationships: ['saved_search'],
    },
  })) as API.Response<API.Assignments.Upsert>;

  if (response.error) {
    yield put(upsertAssignmentFailure(response.error));
  } else {
    yield put(upsertAssignmentSuccess(response.data));
    if (payload.create_assignment === true) {
      yield put(continueAssignment({ uuid: response.data.uuid }));
    } else {
      yield put(showSuccessAlert('Assignment Saved'));
    }
  }
  yield put(upsertAssignmentFulfill());
}

function* deleteAssignmentSaga(action: ReturnType<typeof deleteAssignment>): SagaReturn {
  const { payload } = action;
  yield put(deleteAssignmentRequest());

  const response = (yield call(
    [LitLingoClient.resources.assignments, 'delete'],
    payload.uuid
  )) as API.Response<API.Assignments.Delete>;
  if (response.error != null) {
    yield put(deleteAssignmentFailure(response.error));
  } else {
    yield put(deleteAssignmentSuccess(payload.uuid));
    yield put(showSuccessAlert('Assignment Deleted'));
  }
  yield put(deleteAssignmentFulfill());
}

function* continueAssignmentSaga(action: ReturnType<typeof continueAssignment>): SagaReturn {
  const { payload } = action;
  yield put(continueAssignmentRequest());

  const { router } = (yield select()) as GlobalState;
  const { pathname } = router.location;

  const { auth } = (yield select()) as GlobalState;
  const spec = routes.find((route) => matchPath(pathname, route));

  const routeSpec = getRoute(spec, auth.user.customer?.config);

  const assignment = (yield select(getAssignment(payload.uuid))) as ReturnType<
    ReturnType<typeof getAssignment>
  >;

  if (assignment && !assignment.is_open) {
    yield put(openAssignment({ uuid: payload.uuid }));
    yield take(openAssignmentFulfill.toString());
  }

  const response = (yield call([LitLingoClient.resources.assignments.extras, 'getNextEnvelope'], {
    urlParams: { assignmentId: payload.uuid },
    params: {
      ...payload,
      relationships: ['assignment.saved_search', 'assignment.saved_search.skipped'],
    },
  })) as API.Response<API.Assignments.GetNextEnvelope>;

  if (response.error != null) {
    yield put(showErrorAlert('Cannot Start your assignment'));
    yield put(continueAssignmentFailure(response.error));
  } else if (
    response.data.assignment.pending === 0 ||
    (response.data.assignment.pending === -1 &&
      response.data.assignment.saved_search?.record_count === 0)
  ) {
    // todo: all the logic here
    if (response.data.assignment.skipped < 0) {
      yield put(
        setModalAfterReview({
          modal: 'withSkips',
          reviewSet: response.data.assignment.saved_search,
        })
      );
      yield put(setLastAssignment(response.data.assignment));
    } else {
      yield put(
        setModalAfterReview({
          modal: 'withoutSkips',
          reviewSet: response.data.assignment.saved_search,
        })
      );
    }
    yield put(continueAssignmentSuccess(response.data));
  } else if (response.data.envelope != null) {
    const customerDomain = (yield select(getCustomerDomain)) as string;
    const queryParams = {
      envelopes__currentReview: assignment?.saved_search_uuid || '',
      threads__entry_point: response.data?.envelope.uuid,
    };
    const path = reverse({
      routeName: 'envelope-detail',
      routeParams: { envelopeId: response.data?.envelope.uuid as string },
      queryParams,
      customerDomain,
    });

    yield put(push(path, { from: routeSpec?.name }));
    yield put(continueAssignmentSuccess(response.data));
  } else {
    yield put(continueAssignmentSuccess(response.data));
    /* yield put(
      showSuccessAlert('No more communications in this assignment. Please check back later')
    ); */
  }

  yield put(continueAssignmentFulfill());
}

function* continueSkippedAssignmentSaga(
  action: ReturnType<typeof continueSkippedAssignment>
): SagaReturn {
  const { payload } = action;

  yield put(continueSkippedAssignmentRequest());

  const { router } = (yield select()) as GlobalState;
  const { pathname } = router.location;

  const { auth } = (yield select()) as GlobalState;
  const spec = routes.find((route) => matchPath(pathname, route));

  const routeSpec = getRoute(spec, auth.user.customer?.config);

  const assignment = (yield select(getAssignment(payload.uuid))) as ReturnType<
    ReturnType<typeof getAssignment>
  >;

  if (assignment && !assignment.is_open) {
    yield put(openAssignment({ uuid: payload.uuid }));
    yield take(openAssignmentFulfill.toString());
  }

  const response = (yield call(
    [LitLingoClient.resources.assignments.extras, 'getNextSkippedEnvelope'],
    {
      urlParams: { assignmentId: payload.uuid },
      params: {
        ...payload,
        relationships: ['assignment.saved_search', 'assignment.saved_search.skipped'],
      },
    }
  )) as API.Response<API.Assignments.GetNextSkippedEnvelope>;

  if (response.error != null) {
    yield put(showErrorAlert('Review skipped envelopes failed'));
    yield put(continueSkippedAssignmentFailure(response.error));
  } else if (response.data.envelope != null) {
    const customerDomain = (yield select(getCustomerDomain)) as string;
    const queryParams = {
      envelopes__currentReview: assignment?.saved_search_uuid || '',
      threads__entry_point: response.data?.envelope.uuid,
    };
    const path = reverse({
      routeName: 'envelope-detail',
      routeParams: { envelopeId: response.data?.envelope.uuid as string },
      queryParams,
      customerDomain,
    });
    yield put(push(path, { from: routeSpec?.name }));
    yield put(continueAssignmentSuccess(response.data));
  } else {
    yield put(continueAssignmentSuccess(response.data));
  }
  yield put(continueSkippedAssignmentFulfill());
}

function* openSkippedAssignmentSaga(action: ReturnType<typeof openSkippedAssignment>): SagaReturn {
  const { payload } = action;

  const { router } = (yield select()) as GlobalState;
  const { pathname } = router.location;

  const { auth } = (yield select()) as GlobalState;
  const spec = routes.find((route) => matchPath(pathname, route));

  const routeSpec = getRoute(spec, auth.user.customer?.config);

  const assignment = (yield select(getAssignment(payload.uuid))) as ReturnType<
    ReturnType<typeof getAssignment>
  >;

  const response = (yield call([LitLingoClient.resources.assignments.extras, 'openSkipped'], {
    urlParams: { assignmentId: payload.uuid },
  })) as API.Response<API.Assignments.SkipToPendingEnvelopes>;

  if (response.error != null) {
    yield put(openAssignmentFailure(response.error));
  } else if (response.data.envelope !== null) {
    const customerDomain = (yield select(getCustomerDomain)) as string;
    const queryParams = {
      envelopes__currentReview: assignment?.saved_search_uuid || '',
      threads__entry_point: response.data?.envelope.uuid,
    };
    const path = reverse({
      routeName: 'envelope-detail',
      routeParams: { envelopeId: response.data?.envelope.uuid as string },
      queryParams,
      customerDomain,
    });
    yield put(push(path, { from: routeSpec?.name }));
    yield put(continueAssignmentSuccess(response.data));
  }
}

export function* openSelectedSkippedAssignmentSaga(
  action: ReturnType<typeof openSelectedSkippedAssigment>
): SagaReturn {
  const { payload } = action;
  const { envelopeId, assignmentId, skip_if_unreviewed = false, navigate = true } = payload;

  const { router } = (yield select()) as GlobalState;
  const { pathname } = router.location;

  const { auth } = (yield select()) as GlobalState;
  const spec = routes.find((route) => matchPath(pathname, route));

  const routeSpec = getRoute(spec, auth.user.customer?.config);

  const assignment = (yield select(getAssignment(assignmentId))) as ReturnType<
    ReturnType<typeof getAssignment>
  >;

  const responseUnlockEnvelop = (yield call([
    LitLingoClient.resources.users.extras,
    'unlockEnvelope',
  ])) as API.Response<API.Users.UnlockEnvelope>;

  if (responseUnlockEnvelop.data?.ok) {
    const response = (yield call(
      [LitLingoClient.resources.assignments.extras, 'getSelectedSkipped'],
      {
        urlParams: { assignmentId, envelopeId },
        params: {
          skip_if_unreviewed,
        },
      }
    )) as API.Response<API.Assignments.SkipToPendingEnvelopes>;

    if (response.error != null) {
      yield put(openAssignmentFailure(response.error));
    } else if (response.data.envelope !== null && navigate) {
      const customerDomain = (yield select(getCustomerDomain)) as string;
      const queryParams = {
        envelopes__currentReview: assignment?.saved_search_uuid || '',
        threads__entry_point: response.data?.envelope.uuid,
      };
      const path = reverse({
        routeName: 'envelope-detail',
        routeParams: { envelopeId: response.data?.envelope.uuid as string },
        queryParams,
        customerDomain,
      });
      yield put(push(path, { from: routeSpec?.name }));
      yield put(continueAssignmentSuccess(response.data));
    }
  }
}

function* nextSkippedAssignmentSaga(
  action: ReturnType<typeof openSelectedSkippedAssigment>
): SagaReturn {
  const { payload } = action;
  const { envelopeId, assignmentId, skip_if_unreviewed = false } = payload;

  const { router } = (yield select()) as GlobalState;
  const { pathname } = router.location;

  const { auth } = (yield select()) as GlobalState;
  const spec = routes.find((route) => matchPath(pathname, route));

  const routeSpec = getRoute(spec, auth.user.customer?.config);

  const assignment = (yield select(getAssignment(assignmentId))) as ReturnType<
    ReturnType<typeof getAssignment>
  >;

  const response = (yield call(
    [LitLingoClient.resources.assignments.extras, 'getSelectedSkipped'],
    {
      urlParams: { assignmentId, envelopeId },
      params: {
        skip_if_unreviewed,
        relationships: ['assignment.saved_search', 'assignment.saved_search.skipped'],
      },
    }
  )) as API.Response<API.Assignments.SkipToPendingEnvelopes>;

  if (response.error != null) {
    yield put(openAssignmentFailure(response.error));
  } else if (response.data.envelope !== null) {
    const customerDomain = (yield select(getCustomerDomain)) as string;
    const queryParams = {
      envelopes__currentReview: assignment?.saved_search_uuid || '',
      threads__entry_point: response.data?.envelope.uuid,
    };
    const path = reverse({
      routeName: 'envelope-detail',
      routeParams: { envelopeId: response.data?.envelope.uuid as string },
      queryParams,
      customerDomain,
    });
    yield put(push(path, { from: routeSpec?.name }));
    yield put(continueAssignmentSuccess(response.data));
  }
}

function* reviewAndContinueSaga(
  action: ReturnType<typeof reviewAndContinueAssignment>
): SagaReturn {
  const { payload } = action;
  const { envelopeId, secondsSpent, assignmentId, value, meta_data, skip_if_unreviewed, navigate } =
    payload;

  yield put(reviewAndContinueAssignmentRequest());

  const finalTags = (yield select(getTags)) as ReturnType<typeof getTags>;
  const envelope = (yield select(getSelectedEnvelope)) as ReturnType<typeof getSelectedEnvelope>;
  const declaredStatus = (yield select(getDeclaredStatus)) as ReturnType<typeof getDeclaredStatus>;
  const lastReview = (yield select((state) => getLastReview(state, envelopeId))) as ReturnType<
    typeof getLastReview
  >;

  if (!envelope) return;

  const { tags } = envelope;

  // Add missing tags
  const addTags = finalTags?.filter((t) =>
    tags?.every((tag) => tag.tag_value_uuid !== t.tag_value_uuid)
  );
  if (addTags && addTags.length > 0) {
    yield all(
      addTags.map((tag) =>
        call(addEnvelopeTagSaga, {
          payload: {
            envelopeId,
            tag: tag?.tag_value?.value || '',
            skipSaveEnvelope: true,
          },
          type: addEnvelopeTag.toString(),
        })
      )
    );
  }

  // Remove tags
  const removeTags = tags?.filter((t) =>
    finalTags?.every((tag) => tag.tag_value_uuid !== t.tag_value_uuid)
  );
  if (removeTags && removeTags.length > 0) {
    yield all(
      removeTags.map((tag) =>
        call(removeEnvelopeTagSaga, {
          payload: {
            envelopeId,
            tag: tag?.tag_value?.value || '',
            skipSaveEnvelope: true,
          },
          type: removeEnvelopeTag.toString(),
        })
      )
    );
  }

  if (
    declaredStatus &&
    (declaredStatus !== envelope.review_value ||
      meta_data?.is_confirmed !== lastReview?.meta_data.is_confirmed ||
      meta_data?.reviewed_by !== lastReview?.meta_data.reviewed_by)
  ) {
    yield call(reviewEnvelopeSaga, {
      payload: { envelopeId, secondsSpent, assignmentId, value, meta_data },
      type: reviewEnvelope.toString(),
    });
  }

  yield call(fetchSingleEnvelopeSaga, {
    payload: { envelopeId },
    type: reviewEnvelope.toString(),
  });

  if (navigate && assignmentId) {
    if (navigate === 'next') {
      yield call(continueAssignmentSaga, {
        payload: { uuid: assignmentId, skip_if_unreviewed },
        type: continueAssignment.toString(),
      });
    } else if (navigate === 'skip') {
      yield call(continueSkippedAssignmentSaga, {
        payload: { uuid: assignmentId, skip_if_unreviewed },
        type: continueSkippedAssignment.toString(),
      });
    } else {
      window.history.back();
    }
  }

  yield put(reviewAndContinueAssignmentFulfill());
}

function* closeAssignmentSaga(action: ReturnType<typeof closeAssignment>): SagaReturn {
  const { payload } = action;

  const response = (yield call([LitLingoClient.resources.assignments.extras, 'close'], {
    urlParams: { assignmentId: payload.uuid },
  })) as API.Response<API.Assignments.Close>;

  if (response.error != null) {
    yield put(closeAssignmentFailure(response.error));
  } else {
    yield put(showSuccessAlert('Assignment Closed'));
    yield put(closeAssignmentSuccess(response.data));
  }

  yield put(closeAssignmentFulfill());
}

function* openAssignmentSaga(action: ReturnType<typeof openAssignment>): SagaReturn {
  const { payload } = action;

  const response = (yield call([LitLingoClient.resources.assignments.extras, 'open'], {
    urlParams: { assignmentId: payload.uuid },
  })) as API.Response<API.Assignments.Open>;

  if (response.error != null) {
    yield put(openAssignmentFailure(response.error));
  } else {
    yield put(openAssignmentSuccess(response.data));
  }

  yield put(openAssignmentFulfill());
}

function* reviewSkippedEnvelopesSaga(): SagaReturn {
  const lastAssignment = (yield select(getLastAssignment)) as Assignment;
  yield put(reviewSkippedEnvelopesRequest());
  const response = (yield call([LitLingoClient.resources.assignments.extras, 'skipToPending'], {
    urlParams: { assignmentId: lastAssignment.uuid },
  })) as API.Response<ReviewSkippedEnvelopesSuccessPayload>;

  if (response.error != null) {
    yield put(reviewSkippedEnvelopesFailure(response.error));
    yield put(showErrorAlert(response.error.message));
  } else if (response.data.error_message) {
    yield put(
      reviewSkippedEnvelopesFailure({
        message: response.data.error_message,
        code: 'skipped_envelopes_error',
      })
    );
    yield put(showErrorAlert(response.data.error_message));
  } else {
    const customerDomain = (yield select(getCustomerDomain)) as string;
    const queryParams = {
      envelopes__currentReview: lastAssignment.saved_search_uuid,
      threads__entry_point: response.data?.envelope.uuid,
    };
    const path = reverse({
      routeName: 'envelope-detail',
      routeParams: { envelopeId: response.data?.envelope.uuid as string },
      queryParams,
      customerDomain,
    });
    yield put(push(path));
    yield put(reviewSkippedEnvelopesSuccess(response.data));
  }
  yield put(setModalAfterReview(null));
}

export function* updateAssignmentCountsSaga(
  action: ReturnType<typeof updateAssignmentCounts>
): SagaReturn {
  const { assignmentId, envelopeIds } = action.payload;

  yield put(updateAssigmentCountsRequest());

  const response = (yield call([LitLingoClient.resources.assignments.extras, 'updateSkips'], {
    urlParams: { assignmentId },
    data: {
      uuids: envelopeIds,
    },
  })) as API.Response<API.Assignments.UpdateSkips>;

  if (response.error != null) {
    yield put(updateAssigmentCountsFailure(response.error));
  } else {
    yield put(updateAssigmentCountsSuccess(response.data));
  }
}

function* assignmentsSaga(): SagaReturn {
  yield takeLatest(upsertAssignment.toString(), upsertAssignmentSaga);
  yield takeLatest(deleteAssignment.toString(), deleteAssignmentSaga);
  yield takeLatest(fetchAssignments.toString(), fetchAssignmentsSaga);
  yield takeLatest(fetchOwnAssignments.toString(), fetchOwnAssignmentsSaga);
  yield takeLatest(continueAssignment.toString(), continueAssignmentSaga);
  yield takeLatest(continueSkippedAssignment.toString(), continueSkippedAssignmentSaga);
  yield takeLatest(openSkippedAssignment.toString(), openSkippedAssignmentSaga);
  yield takeLatest(closeAssignment.toString(), closeAssignmentSaga);
  yield takeLatest(openAssignment.toString(), openAssignmentSaga);
  yield takeLatest(reviewAndContinueAssignment.toString(), reviewAndContinueSaga);
  yield takeLatest(reviewSkippedEnvelopes.toString(), reviewSkippedEnvelopesSaga);
  yield takeLatest(openSelectedSkippedAssigment.toString(), openSelectedSkippedAssignmentSaga);
  yield takeLatest(nextSkippedAssignment.toString(), nextSkippedAssignmentSaga);
  yield takeLatest(updateAssignmentCounts.toString(), updateAssignmentCountsSaga);
}

export default assignmentsSaga;
