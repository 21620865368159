import { fetchRuleRevisions } from 'actions/ruleGroup';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getFetchRulesAuditLogsLoading,
  getRulesAuditLogsCount,
  getRulesAuditLogsList,
} from 'selectors/rulesAuditLogs';
import AuditLogsTable from '../AuditLogsTable';

type RuleAuditLogsTableProps = {
  ruleId: string;
};

const RuleAuditLogsTable: React.FC<RuleAuditLogsTableProps> = (props) => {
  const { ruleId } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRuleRevisions({ ruleId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleId]);

  return (
    <div className="p-4">
      <AuditLogsTable
        getList={getRulesAuditLogsList}
        getCount={getRulesAuditLogsCount}
        getLoading={getFetchRulesAuditLogsLoading}
      />
    </div>
  );
};

export default RuleAuditLogsTable;
