import type { ActionCreatorWithoutPayload, PayloadAction } from '@reduxjs/toolkit';
import { fetchCommunicationDocumentFailure, fetchEventSummaryFailure } from 'actions';
import {
  clearAlert,
  prepareUndeleteAlert,
  showActionAlert,
  showErrorAlert,
  showSuccessAlert,
  showUndeleteAlert,
  undeleteResource,
  undeleteResourceFailure,
  undeleteResourceSuccess,
} from 'actions/alerts';
import { verifyFailure } from 'actions/auth';
import { apiClient as LitLingoClient } from 'client';
import { replace } from 'connected-react-router';
import { capitalize } from 'lodash';
import { matchPath } from 'react-router-dom';
import { GlobalState } from 'reducers';
import { call, delay, put, select, take, takeLatest } from 'redux-saga/effects';
import routes from 'routes';
import type { API, ErrorObject, SagaReturn } from 'types';
import { captureException } from 'utils/reports';
import { getRoute } from 'utils/urls';

const DEAUTHORIZED_ACTIONS = [
  verifyFailure.toString(),
  fetchEventSummaryFailure.toString(),
  fetchCommunicationDocumentFailure.toString(),
];

const ALERTS = [
  showSuccessAlert.toString(),
  showErrorAlert.toString(),
  showUndeleteAlert.toString(),
  showActionAlert.toString(),
];

class LitLingoError extends Error {
  name = 'LitLingoError';

  // eslint-disable-next-line no-useless-constructor
  constructor(name: string, message: string) {
    super(message);
    this.name = name;
  }
}

function* prepareUndeleteAlertSaga(action: ReturnType<typeof prepareUndeleteAlert>): SagaReturn {
  const { payload } = action;
  const { resource, id, fetchAction, fetchActionArgs, timeout = 4000 } = payload;

  yield put(showUndeleteAlert(resource, id, timeout));

  const returnedAction = (yield take([
    undeleteResourceSuccess.toString(),
    undeleteResourceFailure.toString(),
  ])) as ActionCreatorWithoutPayload;

  if (returnedAction.type === undeleteResourceSuccess.toString()) {
    if (fetchAction != null) {
      if (fetchActionArgs != null) {
        yield put(fetchAction(fetchActionArgs));
      } else {
        yield put(fetchAction());
      }
    }
  }
}

function* undeleteResourceSaga(action: ReturnType<typeof undeleteResource>): SagaReturn {
  const { payload } = action;
  const { resource, id } = payload;

  const path = LitLingoClient.resources[resource].extras;

  const response = (yield call([path, 'undelete'], {
    urlParams: { uuid: id },
  })) as API.Response<unknown>;

  if (response.error != null) {
    yield put(undeleteResourceFailure());
  } else {
    yield put(undeleteResourceSuccess());
  }
}

function* dismissAlertSaga(action: PayloadAction<{ timeout: number }>): SagaReturn {
  const { payload } = action;

  const { timeout } = payload;
  yield delay(timeout);
  yield put(clearAlert());
}

function* showErrorAlertSaga(action: PayloadAction<ErrorObject>): SagaReturn {
  const { payload, type } = action;

  yield call(captureException, new LitLingoError(capitalize(payload.message), type), {
    extra: payload,
  });

  let message: string;

  if (typeof payload !== 'string') {
    if (payload.status === 403) {
      message = "You don't have valid user permissions to do that";
    } else {
      message = payload.message;
    }
  } else {
    message = payload;
  }
  yield put(showErrorAlert(message));
}

function* redirectOnErrorSaga(action: PayloadAction<ErrorObject>): SagaReturn {
  const { type, payload } = action;

  const { router } = (yield select()) as GlobalState;
  const { pathname } = router.location;

  const { auth } = (yield select()) as GlobalState;
  const spec = routes.find((route) => matchPath(pathname, route));

  const routeSpec = getRoute(spec, auth.user.customer?.config);

  let redirectActions: string[] = [];
  if (routeSpec && routeSpec.data && routeSpec.data.actions) {
    redirectActions = routeSpec.data.actions.map((a) => a.toString());
  }

  const strippedType = type.replace('_FAILURE', '');
  const redirect = redirectActions.some((a) => strippedType === a);

  if (redirect && payload.status !== 400) {
    yield put(replace('/not-found'));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function validateFailureAction(action: any): boolean {
  const { payload, type } = action;
  return /.*FAILURE$/.exec(type) != null && !DEAUTHORIZED_ACTIONS.includes(type) && payload != null;
}

function* alertsSaga(): SagaReturn {
  yield takeLatest(prepareUndeleteAlert.toString(), prepareUndeleteAlertSaga);
  yield takeLatest(undeleteResource.toString(), undeleteResourceSaga);
  yield takeLatest(ALERTS, dismissAlertSaga);
  yield takeLatest(validateFailureAction, showErrorAlertSaga);
  yield takeLatest(validateFailureAction, redirectOnErrorSaga);
}

export default alertsSaga;
