export type CampaignWithRules = {
  campaignName: string;
  campaignPriority: number;
  rules: {
    ruleName: string;
    ruleId: string;
    ruleGroupId?: string;
  }[];
};

const sortCampaigns = (
  [, a]: [string, CampaignWithRules],
  [, b]: [string, CampaignWithRules]
): number => {
  const aPrio = a.campaignPriority && a.campaignPriority !== 0 ? a.campaignPriority : 1000;
  const bPrio = b.campaignPriority && b.campaignPriority !== 0 ? b.campaignPriority : 1000;

  if (aPrio < bPrio) {
    return -1;
  }
  if (aPrio > bPrio) {
    return 1;
  }

  if (a.campaignName < b.campaignName) {
    return -1;
  }
  if (a.campaignName > b.campaignName) {
    return 1;
  }

  return 0;
};

export default sortCampaigns;
