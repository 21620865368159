import {
  createOutcome,
  createOutcomeFailure,
  createOutcomeRequest,
  createOutcomeSuccess,
  fetchOutcomes,
  fetchOutcomesFailure,
  fetchOutcomesRequest,
  fetchOutcomesSuccess,
  fetchOutcomeTypes,
  fetchOutcomeTypesFailure,
  fetchOutcomeTypesRequest,
  fetchOutcomeTypesSuccess,
  fetchSingleOutcome,
  fetchSingleOutcomeFailure,
  fetchSingleOutcomeRequest,
  fetchSingleOutcomeSuccess,
  showErrorAlert,
  showSuccessAlert,
  updateOutcome,
} from 'actions';
import { editRule } from 'actions/rule';
import { apiClient as LitLingoClient } from 'client';
import { push } from 'connected-react-router';
import type { GlobalState } from 'reducers';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { createRuleOutcome } from 'sagas/campaignRuleOutcomes';
import type {
  API,
  CampaignRuleOutcome,
  MOutcome,
  NormalizedResource,
  SagaReturn,
  SortField,
} from 'types';
import { NEW_OUTCOME_UUID } from '../components/Outcome/index';

function* fetchOutcomesSaga(action: ReturnType<typeof fetchOutcomes>): SagaReturn {
  const { payload } = action;
  yield put(fetchOutcomesRequest());

  let searchTermParam;
  if (payload.searchTerm != null) {
    const { searchTerm } = payload;
    searchTermParam = searchTerm;
  } else {
    searchTermParam = {};
  }

  const { sort: sortParams } = ((yield select()) as GlobalState).outcome;
  const sort: SortField = { order_by: sortParams.field };
  if (sortParams.desc) {
    sort.order_desc = sortParams.desc;
  }
  const params = { ...searchTermParam, ...sort };
  const response = (yield call([LitLingoClient.resources.outcomes, 'list'], {
    params,
  })) as API.Response<API.Outcomes.List>;

  if (response.error != null) {
    yield put(fetchOutcomesFailure(response.error));
  } else {
    yield put(fetchOutcomesSuccess(response.data));
  }
}

function* fetchOutcomeTypesSaga(): SagaReturn {
  yield put(fetchOutcomeTypesRequest());
  const response = (yield call([
    LitLingoClient.resources.outcomes.extras,
    'types',
  ])) as API.Response<API.Outcomes.Types>;

  if (response.error != null) {
    yield put(fetchOutcomeTypesFailure(response.error));
  } else {
    yield put(fetchOutcomeTypesSuccess(response.data));
  }
}

function* fetchSingleOutcomeSaga(action: ReturnType<typeof fetchSingleOutcome>): SagaReturn {
  const { payload } = action;
  const { outcomeId } = payload;

  if (outcomeId === NEW_OUTCOME_UUID) return;

  yield put(fetchSingleOutcomeRequest());
  const response = (yield call(
    [LitLingoClient.resources.outcomes, 'retrieve'],
    outcomeId
  )) as API.Response<API.Outcomes.Retrieve>;

  if (response.error != null) {
    yield put(fetchSingleOutcomeFailure(response.error));
  } else {
    yield put(fetchSingleOutcomeSuccess(response.data));
  }
}

function* updateOutcomeSaga(action: ReturnType<typeof updateOutcome>): SagaReturn {
  const { payload } = action;
  const { ruleId, campaignId, outcomeId } = payload;
  const { outcome } = (yield select()) as GlobalState;
  const newRuleOutcome = (yield call(createRuleOutcome, {
    ruleId,
    campaignId,
    outcomeId,
  })) as CampaignRuleOutcome | false;

  if (newRuleOutcome) {
    const outcomeToSwitch = outcome.outcomes[outcomeId];
    const normalizedOutcome: NormalizedResource<MOutcome> = {};
    normalizedOutcome[outcomeId] = {
      ...outcomeToSwitch,
      campaign_uuid: campaignId,
      ruleOutcome_uuid: newRuleOutcome.uuid,
    };
    yield put(createOutcomeSuccess(normalizedOutcome));
    yield put(
      editRule({
        id: ruleId,
        data: {
          outcomes: [
            ...Object.values(outcome.activeOutcomes).map((element: MOutcome) => element.uuid),
            outcomeId,
          ],
        },
      })
    );
  }
}

function* createOutcomeSaga(action: ReturnType<typeof createOutcome>): SagaReturn {
  const { payload } = action;
  const { formData } = payload;
  yield put(createOutcomeRequest());

  const response = (yield call([LitLingoClient.resources.outcomes, 'upsert'], {
    data: formData,
  })) as API.Response<API.Outcomes.Upsert>;

  if (response.error != null) {
    yield put(createOutcomeFailure(response.error));
    yield put(showErrorAlert('Error - Outcome Not Saved'));
  } else if (!response.data.uuid) {
    // TODO: add a better error message
    yield put(createOutcomeFailure({ message: 'No data' }));
    yield put(showErrorAlert('Error - Outcome Not Saved'));
  } else {
    yield put(createOutcomeSuccess({ [response.data.uuid]: response.data }));
    yield put(showSuccessAlert('Outcome Saved'));

    const { router } = (yield select()) as GlobalState;

    const outcomesManager = router.location.pathname.split('/');
    // redirect to outcomes manager view only if it's creating, not updating
    if (outcomesManager[outcomesManager.length - 1] === NEW_OUTCOME_UUID) {
      const redirectPath = outcomesManager.slice(0, -1).join('/');
      yield put(push(redirectPath));
    }
  }
}

export default function* outcomeSaga(): SagaReturn {
  yield takeLatest(fetchOutcomes.toString(), fetchOutcomesSaga);
  yield takeEvery(fetchSingleOutcome.toString(), fetchSingleOutcomeSaga);
  yield takeLatest(fetchOutcomeTypes.toString(), fetchOutcomeTypesSaga);
  yield takeLatest(updateOutcome.toString(), updateOutcomeSaga);
  yield takeLatest(createOutcome.toString(), createOutcomeSaga);
}
