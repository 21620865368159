import { EnvelopeAction, UUID } from '@litlingo/client';
import { createAction } from '@reduxjs/toolkit';

import { ErrorObject } from 'types';

type updateEnvelopeActionPayload = {
  value: string;
  uuid: UUID;
};

export const updateEnvelopeAction = createAction<updateEnvelopeActionPayload>('ACTION/UPDATE');
export const updateEnvelopeActionRequest = createAction('ACTION/UPDATE_REQUEST');
export const updateEnvelopeActionFailure = createAction<ErrorObject>('ACTION/UPDATE_FAILURE');
export const updateEnvelopeActionSuccess = createAction<EnvelopeAction>('ACTION/UPDATE_SUCCESS');

export type deleteEnvelopeActionPayload = {
  envelopeId: UUID;
  envActionUuid: UUID;
};

export const deleteEnvelopeAction = createAction<deleteEnvelopeActionPayload>('ACTION/DELETE');
export const deleteEnvelopeActionRequest = createAction('ACTION/DELETE_REQUEST');
export const deleteEnvelopeActionFailure = createAction<ErrorObject>('ACTION/DELETE_FAILURE');
export const deleteEnvelopeActionSuccess =
  createAction<deleteEnvelopeActionPayload>('ACTION/DELETE_SUCCESS');

export type fetchEnvelopeActionPayload = {
  envActionUuid: UUID;
};
export const fetchEnvelopeAction = createAction<fetchEnvelopeActionPayload>('ACTION/FETCH');
export const fetchEnvelopeActionRequest = createAction('ACTION/FETCH_REQUEST');
export const fetchEnvelopeActionFailure = createAction<ErrorObject>('ACTION/FETCH_FAILURE');
export const fetchEnvelopeActionSuccess = createAction<EnvelopeAction>('ACTION/FETCH_SUCCESS');
