/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  addFilterToURL,
  clearURLParams,
  deleteURLParams,
  manualLocationChange,
  replaceURLParams,
  setParamsFromURL,
  setURLParams,
  showErrorAlert,
  verifyAuth,
  verifySuccess,
} from 'actions';
import { authClient as LitlingoAuthClient } from 'client';
import { LOCATION_CHANGE, push, replace } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import type { GlobalState } from 'reducers';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import routes from 'routes';
import { getNavParamsByResource } from 'selectors/nav';
import type { RouteParams, SagaReturn } from 'types';
import { notAllowedDomains } from 'utils/auth';
import { buildQSFromQSObj, buildQSObjFromQS, getRoute } from 'utils/urls';

function* setURLQueryString(
  action: ReturnType<typeof setURLParams | typeof clearURLParams>
): SagaReturn {
  const { payload } = action;
  const { router } = (yield select()) as GlobalState;
  const { pathname, search } = router.location;

  if (payload == null) {
    yield put(push(pathname));
    return;
  }

  const qs = (yield call(buildQSFromQSObj, payload, search)) as ReturnType<typeof buildQSFromQSObj>;
  yield put(push(`${pathname}?${qs}`));
}

function* deleteURL(action: ReturnType<typeof deleteURLParams>): SagaReturn {
  const { payload } = action;
  const { router } = (yield select()) as GlobalState;

  const { pathname, search } = router.location;

  const current = new URLSearchParams(search);
  payload.forEach((param) => current.delete(param));

  const qs = current.toString();
  if (qs === '') {
    yield put(push(pathname));
  } else {
    yield put(push(`${pathname}?${qs}`));
  }
}

function* replaceURLQueryString(action: ReturnType<typeof replaceURLParams>): SagaReturn {
  const { payload } = action;
  const { requery, ...params } = action.payload;

  const { router } = (yield select()) as GlobalState;

  const { pathname, search } = router.location;

  if (payload == null) {
    yield put(replace(pathname));
    return;
  }
  // @ts-ignore
  const qs = yield call(buildQSFromQSObj, params, search);
  yield put(replace(`${pathname}?${qs}`, requery === 'false' ? 'no-requery' : 'requery'));
}

function* handleLocationChange(action: ReturnType<typeof manualLocationChange>): SagaReturn {
  const { payload } = action;
  const { pathname, search } = payload.location;
  const savedCustomer = window.localStorage.getItem('__customer__');

  let { auth } = (yield select()) as GlobalState;

  const spec = routes.find((route) => matchPath(pathname, route));

  const routeSpec = getRoute(spec, auth.user.customer?.config);

  if (Object.keys(auth.user).length === 0) {
    if (pathname === '/select-customer') return;

    if (pathname.includes('global')) {
      if (savedCustomer) {
        yield put(verifyAuth({ domain: savedCustomer }));
      }
    }

    if (pathname !== '/login') {
      const curDomain = pathname.split('/').filter(Boolean)[0];
      if (curDomain != null) {
        if (!routeSpec?.path) {
          yield put(push('/'));
          yield put(showErrorAlert(`Whoops, route was not found`));
        }
        if (!notAllowedDomains.some((d) => d === curDomain)) {
          yield put(verifyAuth({ domain: curDomain }));
          window.localStorage.setItem('__customer__', curDomain);
        }
      } else if (savedCustomer) {
        yield put(verifyAuth({ domain: savedCustomer }));
        yield take(verifySuccess.toString());
        yield put(push(`/${savedCustomer}`));
      } else {
        yield put(push('/select-customer'));
      }
    }

    if (pathname === '/login') {
      const token = (yield call([LitlingoAuthClient, 'getToken'])) as string | null;
      if (token != null) {
        yield put(push('/select-customer'));
      } else {
        return;
      }
    }
    yield take(verifySuccess.toString());
    ({ auth } = (yield select()) as GlobalState);
  }

  if (routeSpec == null || routeSpec.data == null) {
    yield put(setParamsFromURL({} as RouteParams));
  } else {
    let params = {};
    if (routeSpec.data.params != null) {
      params = (yield call(buildQSObjFromQS, search, routeSpec.data.params)) as string;
    }

    yield put(setParamsFromURL(params));

    if (payload.location.state === 'no-requery' && !payload.isFirstRendering) return;
    if (routeSpec.data.actions != null) {
      const targetDomain = pathname.split('/').filter(Boolean)[0];
      const curDomain = auth.user?.customer?.domain;

      if (curDomain !== targetDomain && targetDomain !== 'global') {
        yield take(verifySuccess.toString());
      }

      yield all(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        routeSpec.data.actions.map((actionCreator: any) => {
          let routeAction = actionCreator();
          if (routeSpec && routeSpec.data && routeSpec.data.idFromPath) {
            const match = matchPath(pathname, routeSpec);
            routeAction = actionCreator(match != null ? match.params : {});
          }

          return put(routeAction);
        })
      );
    }
  }
}

function* addFilterToURLSaga(action: ReturnType<typeof addFilterToURL>): SagaReturn {
  const { key, resource, value } = action.payload;

  const params: string | string[] =
    ((yield select(getNavParamsByResource(resource))) as RouteParams)[key] || [];

  const inURL = params && params.includes(value);

  if (Array.isArray(params)) {
    if (inURL) {
      yield put(
        setURLParams({
          [`${resource}__${key}`]: params.filter((p) => p !== value),
          [`${resource}__offset`]: '0',
        })
      );
    } else {
      yield put(
        setURLParams({
          [`${resource}__${key}`]: [...params, value],
          [`${resource}__offset`]: '0',
        })
      );
    }
  } else if (inURL) {
    yield put(
      setURLParams({
        [`${resource}__${key}`]: '',
        [`${resource}__offset`]: '0',
      })
    );
  } else {
    yield put(
      setURLParams({
        [`${resource}__${key}`]: value,
        [`${resource}__offset`]: '0',
      })
    );
  }
}

function* navSaga(): SagaReturn {
  yield takeEvery(LOCATION_CHANGE, handleLocationChange);
  yield takeEvery(manualLocationChange.toString(), handleLocationChange);
  yield takeEvery(setURLParams.toString(), setURLQueryString);
  yield takeEvery(clearURLParams.toString(), setURLQueryString);
  yield takeEvery(deleteURLParams.toString(), deleteURL);
  yield takeEvery(replaceURLParams.toString(), replaceURLQueryString);
  yield takeEvery(addFilterToURL.toString(), addFilterToURLSaga);
}

export default navSaga;
