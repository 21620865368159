import SearchCampaignRule from 'components/Campaign/SearchCampaignRule';
import CollapsableCard from 'components/CollapsableCard';
import LinkLookup from 'components/LinkLookup';
import Permissions from 'components/Permissions';
import RulesTable from 'components/RulesTable';
import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { getCampaignRules } from 'selectors/campaigns';
import { useSelector } from 'store';
import type { MCampaign, UUID } from 'types';

type RouteParams = {
  campaignId: UUID;
};

type ComponentProps = {
  item: MCampaign;
};

const RenderRules: React.FC<ComponentProps> = ({ item }) => {
  const { campaignId } = useParams<RouteParams>();
  const rulesIds = useSelector((state) => getCampaignRules(state, campaignId));
  const [filteredRules, setFilteredRules] = useState<UUID[] | null>(null);
  const rules = useSelector((state) => state.rule.rules);

  const handleSearchChange = (value: string): void => {
    if (rulesIds) {
      setFilteredRules(
        rulesIds.filter((ruleId) => rules[ruleId].name.toLowerCase().includes(value || ''))
      );
    }
  };

  const createRuleButton = (
    <Permissions action="rules.create">
      <span className="rounded-md">
        <LinkLookup
          onClick={(e): void => e.stopPropagation()}
          routeName="campaign-add-rule"
          routeParams={{ campaignId }}
          type="button"
          data-testid="create-rule-form"
          className="button button--secondary button--green-text font-bold h-8"
        >
          Create Model
        </LinkLookup>
      </span>
    </Permissions>
  );

  const skeletonLoading = (): JSX.Element => (
    <div className="px-12 py-5">
      <Skeleton count={5} />
    </div>
  );

  const renderContent = (): JSX.Element => {
    if (item.rules?.length === 0) {
      return (
        <div className="flex flex-col justify-center items-center gap-4 py-8">
          <span className="text-body">
            Models make up the conditions the use cases will monitor and match against
          </span>
          <LinkLookup
            onClick={(e): void => e.stopPropagation()}
            routeName="campaign-add-rule"
            routeParams={{ campaignId }}
            type="button"
            data-testid="create-rule-form"
            className="button button--primary h-8"
          >
            Create Model
          </LinkLookup>
        </div>
      );
    }

    return (
      <>
        <div className="w-56 mb-2">
          <SearchCampaignRule placeholder="Search Model" handleSearchChange={handleSearchChange} />
        </div>
        <div className="border rounded">
          {!item.rules ? (
            skeletonLoading()
          ) : (
            <RulesTable
              rules={
                (filteredRules != null && filteredRules) ||
                (rulesIds != null && rulesIds) ||
                ([] as UUID[])
              }
              campaignId={campaignId}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <CollapsableCard headerTitle="Models" customAction={createRuleButton}>
      <div className="p-4">{renderContent()}</div>
    </CollapsableCard>
  );
};

export default RenderRules;
