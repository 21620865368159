import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  deleteIdentityFailure,
  deleteIdentityFulfill,
  deleteIdentityRequest,
  deleteIdentitySuccess,
  fetchIdentitiesFailure,
  fetchIdentitiesFulfill,
  fetchIdentitiesRequest,
  fetchIdentitiesSuccess,
  upsertIdentityFailure,
  upsertIdentityFulfill,
  upsertIdentityRequest,
  upsertIdentitySuccess,
} from 'actions';
import type { API, ErrorObject, Identity, NormalizedResource } from 'types';

type IdentityState = {
  loading: string[];
  error: ErrorObject | null;
  items: NormalizedResource<Identity>;
  count: number;
};

type IdentityReducer<P = void> = (state: IdentityState, payload: PayloadAction<P>) => IdentityState;

const defaultState: IdentityState = {
  loading: [],
  error: null,
  items: {},
  count: 0,
};

const handleFetchIdentitiesRequest: IdentityReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchIdentitiesRequest.toString()],
});

const handleFetchIdentitiesFailure: IdentityReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleFetchIdentitiesSuccess: IdentityReducer<API.WrappedAPIResponse<Identity>> = (
  state,
  { payload }
) => {
  const normalizedIdentities: NormalizedResource<Identity> = {};
  payload.records.forEach((identity) => {
    normalizedIdentities[identity.uuid] = identity;
  });
  return {
    ...state,
    items: normalizedIdentities,
    count: payload.count,
  };
};

const handleFetchIdentitiesFulfill: IdentityReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchIdentitiesRequest.toString()),
});

const handleUpsertIdentityRequest: IdentityReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, upsertIdentityRequest.toString()],
});

const handleUpsertIdentityFailure: IdentityReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleUpsertIdentitySuccess: IdentityReducer<Identity> = (state, { payload }) => {
  const normalizedIdentities: NormalizedResource<Identity> = {
    [payload.uuid]: payload,
  };

  return {
    ...state,
    items: { ...state.items, ...normalizedIdentities },
  };
};

const handleUpsertIdentityFulfill: IdentityReducer = (state) => ({
  ...state,
  loading: [...state.loading, upsertIdentityRequest.toString()],
});

const handleDeleteIdentityRequest: IdentityReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, deleteIdentityRequest.toString()],
});

const handleDeleteIdentitySuccess: IdentityReducer<string> = (state, { payload }) => {
  const id = payload;
  const identities = { ...state.items };
  delete identities[id];

  return {
    ...state,
    items: identities,
  };
};

const handleDeleteIdentityFailure: IdentityReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleDeleteIdentityFulfill: IdentityReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== deleteIdentityRequest.toString()),
});

const handlers = {
  [fetchIdentitiesRequest.toString()]: handleFetchIdentitiesRequest,
  [fetchIdentitiesSuccess.toString()]: handleFetchIdentitiesSuccess,
  [fetchIdentitiesFailure.toString()]: handleFetchIdentitiesFailure,
  [fetchIdentitiesFulfill.toString()]: handleFetchIdentitiesFulfill,
  [upsertIdentityRequest.toString()]: handleUpsertIdentityRequest,
  [upsertIdentitySuccess.toString()]: handleUpsertIdentitySuccess,
  [upsertIdentityFailure.toString()]: handleUpsertIdentityFailure,
  [upsertIdentityFulfill.toString()]: handleUpsertIdentityFulfill,
  [deleteIdentityRequest.toString()]: handleDeleteIdentityRequest,
  [deleteIdentitySuccess.toString()]: handleDeleteIdentitySuccess,
  [deleteIdentityFailure.toString()]: handleDeleteIdentityFailure,
  [deleteIdentityFulfill.toString()]: handleDeleteIdentityFulfill,
};

const identitiesReducer = createReducer(defaultState, handlers);

export default identitiesReducer;
