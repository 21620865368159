import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  addNewLanguageMatcher,
  AddNewLanguageMatcherPayload,
  changeLanguageMatcherType,
  ChangeLanguageMatcherTypePayload,
  deleteAnnotatorFailure,
  deleteAnnotatorRequest,
  deleteAnnotatorSuccess,
  deleteLanguageMatcher,
  DeleteLanguageMatcherPayload,
  editAnnotator,
  EditAnnotatorPayload,
  editLanguageMatcher,
  EditLanguageMatcherPayload,
  fetchAllAnnotatorsFailure,
  fetchAllAnnotatorsRequest,
  fetchAllAnnotatorsSuccess,
  fetchAllAnnotatorsSuccessAppend,
  fetchAnnotatorsByIdsFailure,
  fetchAnnotatorsByIdsRequest,
  fetchAnnotatorsByIdsSuccess,
  fetchAnnotatorTypesFailure,
  fetchAnnotatorTypesRequest,
  fetchAnnotatorTypesSuccess,
  fetchLanguageMatcherTypesFailure,
  fetchLanguageMatcherTypesRequest,
  fetchLanguageMatcherTypesSuccess,
  fetchRelationshipTypesFailure,
  fetchRelationshipTypesRequest,
  fetchRelationshipTypesSuccess,
  fetchSingleAnnotatorFailure,
  fetchSingleAnnotatorRequest,
  fetchSingleAnnotatorSuccess,
  generateTermsRequest,
  generateTermsSuccess,
  receiveNewKeyword,
  ReceiveNewKeywordPayload,
  removeKeyword,
  RemoveKeywordPayload,
  saveAnnotatorFailure,
  saveAnnotatorFulfill,
  saveAnnotatorLocalFailure,
  saveAnnotatorRequest,
  saveAnnotatorSuccess,
  setActiveLanguageMatcherId,
  setDropPosition,
  setHasChanges,
} from 'actions/annotator';
import type {
  Annotator,
  AnnotatorTypes,
  API,
  ErrorObject,
  LanguageMatchersTypes,
  MLanguageMatcher,
  NormalizedAnnotator,
  NormalizedRelationshipType,
  NormalizedResource,
  RelationshipType,
} from 'types';
import { v4 as uuidv4 } from 'uuid';

export type AnnotatorState = {
  unsavedChanges: boolean;
  items: NormalizedResource<NormalizedAnnotator>;
  filterItems: NormalizedResource<NormalizedAnnotator>;
  types: AnnotatorTypes;
  languageMatchers: NormalizedResource<MLanguageMatcher>;
  activeLanguageMatcher: string;
  loading: string[];
  relationshipTypes: NormalizedRelationshipType[];
  languageMatcherTypes: LanguageMatchersTypes;
  generatedTerms: Record<string, string[]>;
  dropPosition: number;
  error: ErrorObject | null;
  count: number;
};

type AnnotatorReducer<P = void> = (
  state: AnnotatorState,
  action: PayloadAction<P>
) => AnnotatorState;

const defaultState: AnnotatorState = {
  unsavedChanges: false,
  items: {},
  filterItems: {},
  types: {} as AnnotatorTypes,
  languageMatchers: {},
  activeLanguageMatcher: '',
  loading: [],
  relationshipTypes: [],
  languageMatcherTypes: {} as LanguageMatchersTypes,
  generatedTerms: {},
  dropPosition: -1,
  error: null,
  count: 0,
};

const handleFetchAllAnnotatorsRequest: AnnotatorReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAllAnnotatorsRequest.toString()],
});

const handleFetchAllAnnotatorsSuccess: AnnotatorReducer<API.WrappedAPIResponse<Annotator>> = (
  state,
  { payload }
) => {
  const annotators: { [uuid: string]: NormalizedAnnotator } = {};
  const languageMatchers: { [uuid: string]: MLanguageMatcher } = {};
  payload.records.forEach((annotator) => {
    const { uuid, language_matchers: annotatorLanguageMatchers } = annotator;
    // @ts-ignore
    const normalizedAnnotator: NormalizedAnnotator = {
      ...annotator,
      language_matchers: annotatorLanguageMatchers.map((matcher) => {
        // @ts-ignore
        const matcherId = matcher.id || uuidv4();

        languageMatchers[matcherId] = {
          ...matcher,
          id: matcherId,
          annotator_uuid: uuid,
          saved: true,
        };

        return matcherId;
      }),
    };

    annotators[uuid] = normalizedAnnotator;
  });

  return {
    ...state,
    items: annotators,
    languageMatchers: { ...state.languageMatchers, ...languageMatchers },
    count: payload.count,
    loading: state.loading.filter((s) => s !== fetchAllAnnotatorsRequest.toString()),
  };
};

const handleFetchAllAnnotatorsFailure: AnnotatorReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchAllAnnotatorsRequest.toString()),
});

const handleFetchAnnotatorsByIdsRequest: AnnotatorReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAnnotatorsByIdsRequest.toString()],
});

const handleFetchAnnotatorsByIdsSuccess: AnnotatorReducer<API.WrappedAPIResponse<Annotator>> = (
  state,
  { payload }
) => {
  const annotators: { [uuid: string]: NormalizedAnnotator } = {};
  const languageMatchers: { [uuid: string]: MLanguageMatcher } = {};
  payload.records.forEach((annotator) => {
    const { uuid, language_matchers: annotatorLanguageMatchers } = annotator;
    // @ts-ignore
    const normalizedAnnotator: NormalizedAnnotator = {
      ...annotator,
      language_matchers: annotatorLanguageMatchers.map((matcher) => {
        // @ts-ignore
        const matcherId = matcher.id || uuidv4();

        languageMatchers[matcherId] = {
          ...matcher,
          id: matcherId,
          annotator_uuid: uuid,
          saved: true,
        };

        return matcherId;
      }),
    };

    annotators[uuid] = normalizedAnnotator;
  });

  return {
    ...state,
    filterItems: annotators,
    loading: state.loading.filter((s) => s !== fetchAnnotatorsByIdsRequest.toString()),
  };
};

const handleFetchAnnotatorsByIdsFailure: AnnotatorReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchAnnotatorsByIdsRequest.toString()),
});

const handleDeleteAnnotatorRequest: AnnotatorReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, deleteAnnotatorRequest.toString()],
});

const handleDeleteAnnotatorSuccess: AnnotatorReducer<string> = (state, { payload }) => {
  const id = payload;
  const annotators = { ...state.items };
  delete annotators[id];

  return {
    ...state,
    items: annotators,
    loading: state.loading.filter((s) => s !== deleteAnnotatorRequest.toString()),
  };
};

const handleFetchSingleAnnotatorRequest: AnnotatorReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchSingleAnnotatorRequest.toString()],
});

const handleFetchSingleAnnotatorSuccess: AnnotatorReducer<Annotator> = (state, { payload }) => {
  const { language_matchers: annotatorLanguageMatchers } = payload;
  const languageMatchers: { [uuid: string]: MLanguageMatcher } = {};
  // @ts-ignore
  const normalizedAnnotator: NormalizedAnnotator = {
    ...payload,
    language_matchers: annotatorLanguageMatchers.map((matcher) => {
      const matcherId = matcher.uuid;
      languageMatchers[matcherId] = {
        ...matcher,
        saved: true,
      };

      return matcherId;
    }),
  };

  return {
    ...state,
    items: {
      ...state.items,
      [payload.uuid]: normalizedAnnotator,
    },
    languageMatchers: {
      ...state.languageMatchers,
      ...languageMatchers,
    },
    loading: state.loading.filter((s) => s !== fetchSingleAnnotatorRequest.toString()),
    unsavedChanges: false,
  };
};

const handleFetchSingleAnnotatorFailure: AnnotatorReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchSingleAnnotatorRequest.toString()),
});

const handleDeleteAnnotatorFailure: AnnotatorReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== deleteAnnotatorRequest.toString()),
});

const handleSaveAnnotatorRequest: AnnotatorReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, saveAnnotatorRequest.toString()],
});

const handleSaveAnnotatorSuccess: AnnotatorReducer<Annotator> = (state, { payload }) => {
  const { language_matchers: annotatorLanguageMatchers } = payload;
  const languageMatchers: { [uuid: string]: MLanguageMatcher } = {};

  // @ts-ignore
  const normalizedAnnotator: NormalizedAnnotator = {
    ...payload,
    language_matchers: annotatorLanguageMatchers.map((matcher) => {
      const matcherId = matcher.uuid;
      languageMatchers[matcherId] = {
        ...matcher,
        saved: true,
      };

      return matcherId;
    }),
  };

  return {
    ...state,
    items: {
      ...state.items,
      [payload.uuid]: normalizedAnnotator,
    },
    languageMatchers: {
      ...state.languageMatchers,
      ...languageMatchers,
    },
    unsavedChanges: false,
  };
};

const handleSaveAnnotatorFailure: AnnotatorReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleSaveAnnotatorFulfill: AnnotatorReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== saveAnnotatorRequest.toString()),
});

const handleSaveAnnotatorLocalFailure: AnnotatorReducer<Annotator> = (state, { payload }) => {
  const normalizedLanguageMatchers: NormalizedResource<MLanguageMatcher> = {};
  payload.language_matchers.forEach((languageMatcher) => {
    normalizedLanguageMatchers[languageMatcher.uuid] = languageMatcher;
  });
  return {
    ...state,
    languageMatchers: {
      ...state.languageMatchers,
      ...normalizedLanguageMatchers,
    },
  };
};
const handleFetchAnnotatorTypesRequest: AnnotatorReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAnnotatorTypesRequest.toString()],
});

const handleFetchAnnotatorTypesSuccess: AnnotatorReducer<AnnotatorTypes> = (state, { payload }) => {
  const types = Object.entries(payload).reduce(
    (curr, [key, type]) => ({
      ...curr,
      [key]: {
        ...type,
        name: type.meta.name,
        description: type.meta.description,
        key,
      },
    }),
    {}
  );

  return {
    ...state,
    types: {
      ...state.types,
      ...types,
    },
    loading: state.loading.filter((s) => s !== fetchAnnotatorTypesRequest.toString()),
  };
};

const handleFetchAnnotatorTypesFailure: AnnotatorReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchAnnotatorTypesRequest.toString()),
});

const handleFetchLanguageMatcherTypesRequest: AnnotatorReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchLanguageMatcherTypesRequest.toString()],
});

const handleFetchLanguageMatcherTypesSuccess: AnnotatorReducer<LanguageMatchersTypes> = (
  state,
  { payload }
) => ({
  ...state,
  languageMatcherTypes: payload,
  loading: state.loading.filter((s) => s !== fetchLanguageMatcherTypesRequest.toString()),
});

const handleFetchLanguageMatcherTypesFailure: AnnotatorReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchLanguageMatcherTypesRequest.toString()),
});

const handleFetchRelationshipTypesRequest: AnnotatorReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchRelationshipTypesRequest.toString()],
});

const handleFetchRelationshipTypesSuccess: AnnotatorReducer<RelationshipType[]> = (
  state,
  { payload }
) => {
  const formattedData = payload.map((relationshipType) => ({
    id: relationshipType.type,
    name: relationshipType.name,
  }));
  return {
    ...state,
    relationshipTypes: formattedData,
    loading: state.loading.filter((s) => s !== fetchRelationshipTypesRequest.toString()),
  };
};

const handleFetchRelationshipTypesFailure: AnnotatorReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchRelationshipTypesRequest.toString()),
});

const handleAddNewLanguageMatcher: AnnotatorReducer<AddNewLanguageMatcherPayload> = (
  state,
  { payload }
) => {
  const { annotatorId, languageMatcherId, matcherBody } = payload;
  const languageMatcherItem = state.languageMatchers[languageMatcherId] || {};
  const annotatorItem = state.items[annotatorId];
  return {
    ...state,
    items: {
      ...state.items,
      [annotatorId]: {
        ...annotatorItem,
        language_matchers: [...annotatorItem.language_matchers, languageMatcherId],
      },
    },
    languageMatchers: {
      ...state.languageMatchers,
      [languageMatcherId]: {
        ...languageMatcherItem,
        ...matcherBody,
      },
    },
    unsavedChanges: true,
  };
};

// FIXME: need to find a way to use proper types for this
// @ts-ignore
const handleReceiveNewKeyword: AnnotatorReducer<ReceiveNewKeywordPayload> = (
  state,
  { payload }
) => {
  const { languageMatcherId, data, parentType } = payload;
  const languageMatcherItem: MLanguageMatcher = state.languageMatchers[languageMatcherId] || {};
  let newVal = data.value;

  if (Array.isArray(data.value)) {
    const existingKeywords = languageMatcherItem[data.key] || [];

    if (Array.isArray(existingKeywords)) {
      const tempVal = data.value
        .filter((v, index, arr) => index === arr.indexOf(v))
        .filter((val) => val)
        .map((val) => val?.toString().trim());
      newVal = [...new Set([...existingKeywords, ...tempVal])];
    }
  }

  return {
    ...state,
    languageMatchers: {
      ...state.languageMatchers,
      [languageMatcherId]: {
        ...languageMatcherItem,
        type: parentType,
        [data.key]: newVal,
        uuid: languageMatcherId,
      },
    },
    activeLanguageMatcher: languageMatcherId,
    unsavedChanges: true,
  };
};

const handleChangeLanguageMatcherType: AnnotatorReducer<ChangeLanguageMatcherTypePayload> = (
  state,
  { payload }
) => {
  const { languageMatcherId, type, ...args } = payload;

  const baseLanguageMatcher = state.languageMatchers[languageMatcherId];
  const newLanguageMatcher = {
    uuid: baseLanguageMatcher.uuid,
    name: baseLanguageMatcher.name,
    type,
  } as MLanguageMatcher;
  if ('isNew' in baseLanguageMatcher) {
    newLanguageMatcher.isNew = baseLanguageMatcher.isNew;
  }
  if ('saved' in baseLanguageMatcher) {
    newLanguageMatcher.saved = baseLanguageMatcher.saved;
  }
  Object.assign(newLanguageMatcher, args);

  return {
    ...state,
    languageMatchers: {
      ...state.languageMatchers,
      [languageMatcherId]: newLanguageMatcher,
    },
  };
};

const handleSetActiveLanguageMatcherId: AnnotatorReducer<string> = (state, { payload }) => ({
  ...state,
  activeLanguageMatcher: payload,
});

const handleDeleteLanguageMatcher: AnnotatorReducer<DeleteLanguageMatcherPayload> = (
  state,
  { payload }
) => {
  const { languageMatcherId, annotatorId } = payload;
  const languageMatchers = { ...state.languageMatchers };
  delete languageMatchers[languageMatcherId];
  const annotatorItem = state.items[annotatorId];
  const itemLanguageMatchers = state.items[annotatorId].language_matchers;
  const index = itemLanguageMatchers.indexOf(languageMatcherId);
  if (index === -1) return { ...state };
  return {
    ...state,
    items: {
      ...state.items,
      [annotatorId]: {
        ...annotatorItem,
        language_matchers: [
          ...itemLanguageMatchers.slice(0, index),
          ...itemLanguageMatchers.slice(index + 1),
        ],
      },
    },
    languageMatchers,
    unsavedChanges: true,
  };
};

// FIXME: need to find a way to use proper types for this
// @ts-ignore
const handleEditLanguageMatcherName: AnnotatorReducer<EditLanguageMatcherPayload> = (
  state,
  { payload }
) => {
  const { languageMatcherId, data } = payload;
  const { ...args } = data;
  const languageMatcherItem = state.languageMatchers[languageMatcherId] || {};
  return {
    ...state,
    languageMatchers: {
      ...state.languageMatchers,
      [languageMatcherId]: {
        ...languageMatcherItem,
        ...args,
      },
    },
    activeLanguageMatcher: languageMatcherId,
    unsavedChanges: true,
  };
};

const handleRemoveKeyword: AnnotatorReducer<RemoveKeywordPayload> = (state, { payload }) => {
  const { languageMatcherId, keyword, partOfSpeech } = payload;
  const languageMatcherItem = state.languageMatchers[languageMatcherId];
  // FIXME: Remove 'as unknown' when the payload type is fixed
  const list = state.languageMatchers[languageMatcherId][partOfSpeech] as unknown as string[];
  const index = list.indexOf(keyword as string);

  return {
    ...state,
    languageMatchers: {
      ...state.languageMatchers,
      [languageMatcherId]: {
        ...languageMatcherItem,
        [partOfSpeech]: [...list.slice(0, index), ...list.slice(index + 1)],
      },
    },
    unsavedChanges: true,
  };
};

const handleEditAnnotator: AnnotatorReducer<EditAnnotatorPayload> = (state, { payload }) => {
  const { id, data } = payload;
  const item = state.items[id];
  return {
    ...state,
    items: {
      ...state.items,
      [id]: {
        ...item,
        ...data,
      },
    },
  };
};

const handleSetHasChanges: AnnotatorReducer<boolean> = (state, { payload }) => ({
  ...state,
  unsavedChanges: payload,
});

const handleFetchAllAnnotatorsSuccessAppend: AnnotatorReducer<
  API.WrappedAPIResponse<NormalizedAnnotator>
> = (state, { payload }) => {
  const normalizedAnnotators: NormalizedResource<NormalizedAnnotator> = {};
  payload.records.forEach((annotator) => {
    normalizedAnnotators[annotator.uuid] = annotator;
  });
  return {
    ...state,
    items: { ...state.items, ...normalizedAnnotators },
    loading: state.loading.filter((s) => s !== fetchAllAnnotatorsRequest.toString()),
    count: payload.count,
  };
};

const handleGenerateTermsRequest: AnnotatorReducer<string[]> = (state) => ({
  ...state,
  loading: [...state.loading, generateTermsRequest.toString()],
});

const handleGenerateTermsSuccess: AnnotatorReducer<string[]> = (state, { payload }) => ({
  ...state,
  generatedTerms: { ...state.generatedTerms, [state.activeLanguageMatcher]: payload },
  loading: state.loading.filter((r) => r !== generateTermsRequest.toString()),
});

const handleDropPosition: AnnotatorReducer<number> = (state, { payload }) => ({
  ...state,
  dropPosition: payload,
});

const handlers = {
  [fetchAllAnnotatorsRequest.toString()]: handleFetchAllAnnotatorsRequest,
  [fetchAllAnnotatorsSuccess.toString()]: handleFetchAllAnnotatorsSuccess,
  [fetchAllAnnotatorsFailure.toString()]: handleFetchAllAnnotatorsFailure,
  [fetchAnnotatorsByIdsRequest.toString()]: handleFetchAnnotatorsByIdsRequest,
  [fetchAnnotatorsByIdsSuccess.toString()]: handleFetchAnnotatorsByIdsSuccess,
  [fetchAnnotatorsByIdsFailure.toString()]: handleFetchAnnotatorsByIdsFailure,
  [fetchSingleAnnotatorRequest.toString()]: handleFetchSingleAnnotatorRequest,
  [fetchSingleAnnotatorSuccess.toString()]: handleFetchSingleAnnotatorSuccess,
  [fetchSingleAnnotatorFailure.toString()]: handleFetchSingleAnnotatorFailure,
  [deleteAnnotatorRequest.toString()]: handleDeleteAnnotatorRequest,
  [deleteAnnotatorSuccess.toString()]: handleDeleteAnnotatorSuccess,
  [deleteAnnotatorFailure.toString()]: handleDeleteAnnotatorFailure,
  [saveAnnotatorRequest.toString()]: handleSaveAnnotatorRequest,
  [saveAnnotatorSuccess.toString()]: handleSaveAnnotatorSuccess,
  [saveAnnotatorFailure.toString()]: handleSaveAnnotatorFailure,
  [saveAnnotatorFulfill.toString()]: handleSaveAnnotatorFulfill,
  [saveAnnotatorLocalFailure.toString()]: handleSaveAnnotatorLocalFailure,
  [fetchAnnotatorTypesRequest.toString()]: handleFetchAnnotatorTypesRequest,
  [fetchAnnotatorTypesSuccess.toString()]: handleFetchAnnotatorTypesSuccess,
  [fetchAnnotatorTypesFailure.toString()]: handleFetchAnnotatorTypesFailure,
  [fetchLanguageMatcherTypesRequest.toString()]: handleFetchLanguageMatcherTypesRequest,
  [fetchLanguageMatcherTypesSuccess.toString()]: handleFetchLanguageMatcherTypesSuccess,
  [fetchLanguageMatcherTypesFailure.toString()]: handleFetchLanguageMatcherTypesFailure,
  [fetchRelationshipTypesRequest.toString()]: handleFetchRelationshipTypesRequest,
  [fetchRelationshipTypesSuccess.toString()]: handleFetchRelationshipTypesSuccess,
  [fetchRelationshipTypesFailure.toString()]: handleFetchRelationshipTypesFailure,
  [addNewLanguageMatcher.toString()]: handleAddNewLanguageMatcher,
  [receiveNewKeyword.toString()]: handleReceiveNewKeyword,
  [setActiveLanguageMatcherId.toString()]: handleSetActiveLanguageMatcherId,
  [deleteLanguageMatcher.toString()]: handleDeleteLanguageMatcher,
  [removeKeyword.toString()]: handleRemoveKeyword,
  [editAnnotator.toString()]: handleEditAnnotator,
  [editLanguageMatcher.toString()]: handleEditLanguageMatcherName,
  [changeLanguageMatcherType.toString()]: handleChangeLanguageMatcherType,
  [setHasChanges.toString()]: handleSetHasChanges,
  [fetchAllAnnotatorsSuccessAppend.toString()]: handleFetchAllAnnotatorsSuccessAppend,
  [generateTermsSuccess.toString()]: handleGenerateTermsSuccess,
  [generateTermsRequest.toString()]: handleGenerateTermsRequest,
  [setDropPosition.toString()]: handleDropPosition,
};

const annotatorReducer = createReducer(defaultState, handlers);

export default annotatorReducer;
