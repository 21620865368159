import { DashboardConfig } from '@litlingo/client';
import GeneralSidebar from 'components/Sidebar/GeneralSidebar';
import React from 'react';
import { resourceQueryParamName } from '../../constants/resourceQueryNames';
import DashboardFiltersSidebar from './DashboardFiltersSidebar';

type ComponentProps = {
  params: { [paramName: string]: string | undefined };
  dashboardConfig: DashboardConfig & { key: string };
};

const DashboardSidebar: React.FC<ComponentProps> = ({ params, dashboardConfig }) => (
  <GeneralSidebar
    baseSidebar={
      <DashboardFiltersSidebar
        resource={resourceQueryParamName.metrics}
        params={params}
        dashboardConfig={dashboardConfig}
      />
    }
    dashboardView
  />
);

export default DashboardSidebar;
