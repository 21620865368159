import { editCustomer, fetchSingleCustomer, saveCustomer } from 'actions';
import Card from 'components/Card';
import CustomerAllowedDomainsThreeDots from 'components/Customer/CustomerAllowedDomainsThreeDots';
import CustomerSaveButton from 'components/Customer/CustomerSaveButton';
import LinkLookup from 'components/LinkLookup';
import Modal from 'components/Modal';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import { LEFT_ARROW_ICON } from 'constants/envelopeIcons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCustomer, getUser } from 'selectors/auth';
import { useSelector } from 'store';
import CustomerLeaveConfirmModal from './CustomerLeaveConfirmModal';

const CustomerAllowedDomains: React.FC = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentIdx, setCurrentIdx] = useState(-1);
  const [arrDomains, setArrDomains] = useState<string[]>([]);

  const [isSaved, setIsSaved] = useState(false);
  const [unsaved, setUnsaved] = useState(false);
  const [isLeaveModalOpen, setLeaveModalOpen] = useState(false);

  const user = useSelector(getUser);
  const customer = useSelector(getCustomer);

  const handleClickOnSave = (): void => {
    dispatch(
      editCustomer({
        config: {
          ...customer.config,
          valid_email_domains: arrDomains,
        },
      })
    );
    setUnsaved(true);
    setModalOpen(false);
  };

  const handleRemoveDomain = (idx: number): void => {
    const newVal: string[] = [...arrDomains.slice(0, idx), ...arrDomains.slice(idx + 1)];
    dispatch(
      editCustomer({
        config: {
          ...customer.config,
          valid_email_domains: newVal,
        },
      })
    );
    setArrDomains(newVal);
    setUnsaved(true);
  };

  const handleSave = async (): Promise<void> => {
    dispatch(saveCustomer({}));
    setIsSaved(true);
    setUnsaved(false);
  };

  const handleFormChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    setUnsaved(true);
    const { value } = evt.target;
    const newVal: string[] = [
      ...arrDomains.slice(0, currentIdx),
      value,
      ...arrDomains.slice(currentIdx + 1),
    ];
    setArrDomains(newVal);
  };

  useEffect(() => {
    const domains = customer.config?.valid_email_domains;
    if (!domains) return;
    setArrDomains(domains);
  }, [setArrDomains, customer]);

  useEffect(() => {
    if (user && !customer.config) {
      dispatch(fetchSingleCustomer());
    }
  }, [dispatch, user, customer]);

  const header = (
    <div>
      <div className="flex justify-between">
        <div className="pt-5 pb-12 flex items-center">
          <LinkLookup
            key="goback"
            data-testid="customer-goback-button"
            routeName="customer"
            className="mx-6 focus:outline-none"
            type="button"
            onClick={(e): void => {
              if (unsaved) {
                e.preventDefault();
                setLeaveModalOpen(true);
              }
            }}
          >
            {LEFT_ARROW_ICON}
          </LinkLookup>
          <span className="text text--large text--bold">Allowed Domains</span>
        </div>
        <CustomerSaveButton
          handleSave={handleSave}
          unsaved={unsaved}
          isSaved={isSaved}
          className="litlingo-customer-save-button"
        />
      </div>
      <div className="flex justify-between items-center">
        <p className="text-sm font-bold mx-6">Domains</p>
        <button
          type="button"
          data-testid="add-domain-button"
          className="mb-3 mr-5 litlingo-test-case-button focus:outline-none litlingo-test-case-button-outline litlingo-test-button-rounded bg-litlingo-primary"
          onClick={(): void => {
            setCurrentIdx(arrDomains.length);
            setModalOpen(true);
          }}
        >
          Add
        </button>
      </div>
    </div>
  );

  const renderDomain = (name: string, idx: number): JSX.Element => (
    <div key={name} className="my-4 mx-5 flex justify-between">
      <span className="flex items-center text-sm">{name}</span>
      <CustomerAllowedDomainsThreeDots
        setModalOpen={setModalOpen}
        handleRemoveDomain={handleRemoveDomain}
        idx={idx}
        setCurrentIdx={setCurrentIdx}
      />
    </div>
  );

  const renderBody = (
    <input
      id="domain"
      data-testid="input-domain"
      onChange={(evt): void => handleFormChange(evt)}
      value={arrDomains[currentIdx] || ''}
      className="form-input litlingo-customer-card-input my-4 w-full"
    />
  );

  const renderDomains = arrDomains.map((name, idx) => renderDomain(name, idx));

  return (
    <div className="litlingo-gray-bg-color-4">
      <Navbar />

      <div
        className="flex flex-row overflow-auto"
        style={{
          minHeight: 'calc(100vh - 4rem)',
          height: 'calc(100vh - 4rem)',
        }}
      >
        <SettingSidebar />
        <div className="mt-0 mx-auto min-w-2xl w-8/12 max-w-6xl text-left">
          <Card items={[header, renderDomains, <div key="div" />]} />
          {isModalOpen && (
            <Modal
              body={renderBody}
              title="Add/edit domain"
              okButton
              okButtonText="Save"
              okButtonOnClick={handleClickOnSave}
              toggleShowModal={(): void => setModalOpen(!isModalOpen)}
            />
          )}
          {isLeaveModalOpen && (
            <CustomerLeaveConfirmModal
              toggleShowModal={(): void => setLeaveModalOpen(!isLeaveModalOpen)}
              setUnsaved={setUnsaved}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerAllowedDomains;
