import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  deleteInvitationFailure,
  deleteInvitationFulfill,
  deleteInvitationRequest,
  deleteInvitationSuccess,
  fetchInvitationsFailure,
  fetchInvitationsFulfill,
  fetchInvitationsRequest,
  fetchInvitationsSuccess,
  resendInvitation,
  resendInvitationFailure,
  resendInvitationFulfill,
  resendInvitationSuccess,
  sendInvitation,
  sendInvitationFailure,
  sendInvitationFulfill,
  upsertInvitationFailure,
  upsertInvitationFulfill,
  upsertInvitationRequest,
  upsertInvitationSuccess,
} from 'actions';
import type { API, ErrorObject, Invitation, NormalizedResource } from 'types';

type InvitationState = {
  loading: string[];
  error: ErrorObject | null;
  items: NormalizedResource<Invitation>;
  count: number;
};

type InvitationReducer<P = void> = (
  state: InvitationState,
  payload: PayloadAction<P>
) => InvitationState;

const defaultState: InvitationState = {
  loading: [],
  error: null,
  items: {},
  count: 0,
};

const handleFetchInvitationsRequest: InvitationReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchInvitationsRequest.toString()],
});

const handleFetchInvitationsFailure: InvitationReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleFetchInvitationsSuccess: InvitationReducer<API.WrappedAPIResponse<Invitation>> = (
  state,
  { payload }
) => {
  const normalizedInvitations: NormalizedResource<Invitation> = {};
  payload.records.forEach((invitation) => {
    normalizedInvitations[invitation.uuid] = invitation;
  });
  return {
    ...state,
    items: normalizedInvitations,
    count: payload.count,
  };
};

const handleFetchInvitationsFulfill: InvitationReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchInvitationsRequest.toString()),
});

const handleUpsertInvitationRequest: InvitationReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, upsertInvitationRequest.toString()],
});

const handleUpsertInvitationFailure: InvitationReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleUpsertInvitationSuccess: InvitationReducer<Invitation> = (state, { payload }) => {
  const normalizedInvitations: NormalizedResource<Invitation> = {
    [payload.uuid]: payload,
  };

  return {
    ...state,
    items: { ...state.items, ...normalizedInvitations },
  };
};

const handleUpsertInvitationFulfill: InvitationReducer = (state) => ({
  ...state,
  loading: [...state.loading, upsertInvitationRequest.toString()],
});

const handleDeleteInvitationRequest: InvitationReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, deleteInvitationRequest.toString()],
});

const handleDeleteInvitationSuccess: InvitationReducer<string> = (state, { payload }) => {
  const id = payload;
  const identities = { ...state.items };
  delete identities[id];

  return {
    ...state,
    items: identities,
  };
};

const handleDeleteInvitationFailure: InvitationReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleDeleteInvitationFulfill: InvitationReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== deleteInvitationRequest.toString()),
});

const handleResendInvitation: InvitationReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, resendInvitation.toString()],
});

const handleResendInvitationSuccess: InvitationReducer<string> = (state) => state;

const handleResendInvitationFailure: InvitationReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleResendInvitationFulfill: InvitationReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== resendInvitation.toString()),
});

const handleSendInvitation: InvitationReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: [...state.loading, sendInvitation.toString()],
});

const handleSendInvitationFailure: InvitationReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleSendInvitationFulfill: InvitationReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== sendInvitation.toString()),
});

const handlers = {
  [fetchInvitationsRequest.toString()]: handleFetchInvitationsRequest,
  [fetchInvitationsSuccess.toString()]: handleFetchInvitationsSuccess,
  [fetchInvitationsFailure.toString()]: handleFetchInvitationsFailure,
  [fetchInvitationsFulfill.toString()]: handleFetchInvitationsFulfill,
  [upsertInvitationRequest.toString()]: handleUpsertInvitationRequest,
  [upsertInvitationSuccess.toString()]: handleUpsertInvitationSuccess,
  [upsertInvitationFailure.toString()]: handleUpsertInvitationFailure,
  [upsertInvitationFulfill.toString()]: handleUpsertInvitationFulfill,
  [deleteInvitationRequest.toString()]: handleDeleteInvitationRequest,
  [deleteInvitationSuccess.toString()]: handleDeleteInvitationSuccess,
  [deleteInvitationFailure.toString()]: handleDeleteInvitationFailure,
  [deleteInvitationFulfill.toString()]: handleDeleteInvitationFulfill,
  [sendInvitation.toString()]: handleSendInvitation,
  [sendInvitationFailure.toString()]: handleSendInvitationFailure,
  [sendInvitationFulfill.toString()]: handleSendInvitationFulfill,
  [resendInvitation.toString()]: handleResendInvitation,
  [resendInvitationSuccess.toString()]: handleResendInvitationSuccess,
  [resendInvitationFailure.toString()]: handleResendInvitationFailure,
  [resendInvitationFulfill.toString()]: handleResendInvitationFulfill,
};

const identitiesReducer = createReducer(defaultState, handlers);

export default identitiesReducer;
