/* eslint-disable jsx-a11y/control-has-associated-label */
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import moment from 'moment';
import React, { Key, useMemo } from 'react';
import { DefaultWidgetComponentProps, MetricsDataV2 } from 'types';
import { buildTableDataFromMetrics } from 'utils/dashboard';

const TableWidget: React.FC<DefaultWidgetComponentProps & { widgetData: MetricsDataV2 }> = (
  props
) => {
  const { widgetData, queryData } = props;
  const currentMonth = moment().utc().format('MMMM');

  const processedData = useMemo(
    () => widgetData && queryData && buildTableDataFromMetrics(widgetData, queryData),
    [widgetData, queryData]
  );

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  if (processedData?.data.length === 0) {
    return <WidgetEmptyState />;
  }

  return (
    <div>
      <table className="table-fixed w-full h-full border-r">
        <thead className={`bg-litlingo-primary-60 `}>
          <tr className="h-10 border-b border-litlingo-gray-2 ">
            <th className="bg-litlingo-gray-0.5 px-2 w-32" />
            {Object.entries(processedData?.labelsMap ?? {}).map(([key, header]) => (
              <th key={key} className="border-r border-litlingo-gray-2">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={`text-left border-l `}>
          <>
            {processedData?.data.map((row) => (
              <tr
                key={`${row.date}` as string}
                className={` ${
                  row.date === currentMonth
                    ? 'bg-litlingo-warning-light  border-b border-litlingo-black'
                    : 'border-b border-litlingo-gray-2'
                }`}
              >
                {Object.values(row).map((data, index) => (
                  <td
                    key={`${row.date}-${index}` as Key}
                    className={` border-litlingo-gray-2 px-4  ${
                      index === 0
                        ? `h-8  px-4 text-left text font-bold text-base w-32 ${
                            row.date === currentMonth
                              ? 'bg-litlingo-warning-light'
                              : 'bg-litlingo-gray-0.5'
                          }`
                        : 'text-right border-r'
                    } `}
                  >
                    {typeof data === 'number' ? data.toLocaleString() : data}
                    {index === 0 && row.date === currentMonth && '*'}
                  </td>
                ))}
              </tr>
            ))}
            {processedData?.data && (
              <tr className="border-b border-litlingo-gray-2">
                <th className="bg-litlingo-gray-0.5 h-8  px-4">Total</th>
                {processedData?.total?.map((sum) => (
                  <td key={sum} className="px-4 text-right border-r border-litlingo-gray-2">
                    {sum.toLocaleString()}
                  </td>
                ))}
              </tr>
            )}
          </>
        </tbody>
      </table>
    </div>
  );
};

export default withWidget({})(TableWidget as unknown as React.FC<DefaultWidgetComponentProps>);
