type CommSort = {
  sent_at: string | null;
  version: number;
};

// if reversed = false, hightest version goes first
const sortCommunications = <T extends CommSort>(a: T, b: T, reversed = false): number => {
  const res = reversed ? 1 : -1;
  const oRes = reversed ? -1 : 1;

  if (a.version > b.version) return res;
  if (b.version > a.version) return oRes;
  if (a.sent_at && b.sent_at && a.sent_at <= b.sent_at) return oRes;
  return res;
};

export default sortCommunications;
