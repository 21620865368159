import Permissions from 'components/Permissions';
import PermissionsRowLink from 'components/PermissionsRowLink';
import daysAgo from 'components/utils/daysAgo';
import React from 'react';
import { getRuleGroup, getRuleGroupTestCaseSummary } from 'selectors/ruleGroups';
import { useSelector } from 'store';
import type { UUID } from 'types';

type ComponentProps = {
  ruleId: UUID;
};

const RuleGroupListItem: React.FC<ComponentProps> = (props) => {
  const { ruleId } = props;

  const rule = useSelector(getRuleGroup(ruleId)) || {};

  const summaryTestCases = useSelector((state) => getRuleGroupTestCaseSummary(state, ruleId));

  const { passing, failing, not_run: notRun } = summaryTestCases;

  const totalTests = passing + failing + notRun;

  const totalGood = Math.round((100 * passing) / totalTests);

  const routeName = 'global-rule-group-manager';
  const routeParams = { ruleId };

  return (
    <>
      <PermissionsRowLink
        className="table-row align-middle  border-t border-gray-200 hover:bg-gray-100"
        action="rules.retrieve"
        routeName={routeName}
        routeParams={routeParams}
        as="tr"
      >
        <td className="table-wrapper__new-td">
          <div className="flex items-center h-8 pl-8">
            <div className="overflow-hidden ">
              <div className="text-base font-bold">{rule.name}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center">
            <div className="text-body">{rule.updated_at != null && daysAgo(rule.updated_at)}</div>
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="flex items-center">
            <div className="text-body">{rule.updated_by?.name}</div>
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="flex items-center">
            <div className="text-body">{totalTests || '-'}</div>
          </div>
        </td>

        <Permissions action="test_cases.viewMetrics">
          <td className="table-wrapper__new-td">
            {totalGood ? (
              <div
                className={` text-base ${
                  totalGood >= 66 ? 'text-litlingo-primary-120' : 'text-litlingo-alert'
                }`}
              >{` ${totalGood}% `}</div>
            ) : (
              <div className="text-base">-</div>
            )}
          </td>
        </Permissions>
      </PermissionsRowLink>
    </>
  );
};

export default RuleGroupListItem;
