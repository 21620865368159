/* eslint-disable camelcase */
import { saveTestCase } from 'actions';
import Modal from 'components/Modal';
import ToggleButton from 'components/ToggleButton';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import type { CommunicationSummary, UUID } from 'types';
import SelectAndSearchByResource from '../SelectAndSearchByResource';

type ComponentProps = {
  toggleModal: () => void;
  communication?: CommunicationSummary;
};

const ModalAddTestSuiteFromComm: React.FC<ComponentProps> = ({ toggleModal, communication }) => {
  const dispatch = useDispatch();
  const [phrase, setPhrase] = useState<string | undefined>(communication?.body);
  const [selectedTestSuit, setSelectedTestSuit] = useState<
    'campaign_uuid' | 'rule_uuid' | 'annotator_uuid'
  >('campaign_uuid');
  const [shouldTestFire, setShouldTestFire] = useState(true);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const options = {
    campaign_uuid: 'Use Case',
    rule_uuid: 'Model',
    annotator_uuid: 'Identifier',
  };

  const handleClickOnAdd = (): void => {
    const rule_uuid: UUID | null = selectedTestSuit === 'rule_uuid' ? selectedId : null;
    const campaign_uuid: UUID | null = selectedTestSuit === 'campaign_uuid' ? selectedId : null;
    const annotator_uuid: UUID | null = selectedTestSuit === 'annotator_uuid' ? selectedId : null;

    dispatch(
      saveTestCase({
        rule_uuid: rule_uuid as UUID,
        campaign_uuid: campaign_uuid as UUID,
        annotator_uuid: annotator_uuid as UUID,
        platform: 'slack',
        should_match: shouldTestFire,
        test_string: phrase as string,
      })
    );
    toggleModal();
  };

  const handleChangeTestSuit = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { value } = event.target;
    (Object.keys(options) as Array<keyof typeof options>).forEach((key) => {
      if (key === value) {
        setSelectedTestSuit(key);
        setSelectedId(null);
      }
    });
  };

  const renderBody = (
    <div className="mt-4 text-left">
      <span className="text text--semibold">Test phrase</span>

      <textarea
        id="TestPhrase"
        name="test suit body"
        rows={3}
        onChange={(event): void => setPhrase(event.target.value)}
        className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 litlingo-gray-bg-color border-2 border-gray-200 h-32 overflow-auto p-2 text-sm"
        value={phrase}
      />

      <SelectAndSearchByResource
        title="Choose test suite"
        options={options}
        selectedEntity={selectedTestSuit}
        setSelectedId={setSelectedId}
        handleChangeEntity={handleChangeTestSuit}
        className="mt-8"
        cleanSearchValue
      />

      <span className="mr-4 flex flex-col items-start">
        <p className="mr-4 mt-8 text text--semibold">Expected result</p>
        <div className="flex">
          <ToggleButton
            isOn={shouldTestFire}
            handleToggleClick={(): void => setShouldTestFire(!shouldTestFire)}
          />
          <span className="ml-2">{shouldTestFire ? 'Match' : 'No match'}</span>
        </div>
      </span>
    </div>
  );

  return (
    <Modal
      body={renderBody}
      title="Add phrase to test suite"
      okButton
      okButtonText="Add"
      okButtonOnClick={handleClickOnAdd}
      toggleShowModal={toggleModal}
      style={{ maxWidth: '50%' }}
    />
  );
};

export default ModalAddTestSuiteFromComm;
