import React from 'react';

type WidgetErrorProps = {
  msg: string;
  intersected?: boolean;
};

const WidgetError: React.FC<WidgetErrorProps> = (props) => {
  const { msg, intersected } = props;

  return (
    <div
      className={`flex flex-col justify-evenly h-full w-full border rounded-md box-shadown overflow-hidden ${
        intersected ? 'px-5 border-dotted border-black' : ''
      }`}
    >
      <span className="text text--medium text--italic text--bold text--center">{msg}</span>
    </div>
  );
};

export default WidgetError;
