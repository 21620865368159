/* eslint-disable max-lines */
import { selectUsers } from 'actions/userPermissionsPage';
import Tooltip from 'components/Tooltip';
import React from 'react';
import { useDispatch } from 'react-redux';
import type { User } from 'types';
import EmptyUsersTable from './EmptyUsersTable';
import UserPermissionsTableRow from './UsersPermissionsTableRow';

type ComponentsProps = {
  allUsers: User[];
  users: User[];
  loading: boolean;
  selectedUsers: User[];
  EmptyTableStateComponent?: JSX.Element;
};

const UserPermissionsTable: React.FC<ComponentsProps> = ({
  allUsers,
  users,
  loading,
  selectedUsers,
  EmptyTableStateComponent,
}) => {
  const dispatch = useDispatch();

  const setSelectedUsers = (newUsers: User[]): void => {
    dispatch(selectUsers(newUsers));
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      setSelectedUsers(Object.values(allUsers));
    } else {
      setSelectedUsers([]);
    }
  };

  const handleSelectUser = (e: React.ChangeEvent<HTMLInputElement>, user: User): void => {
    e.stopPropagation();

    if (selectedUsers.find((u) => u.uuid === user.uuid)) {
      setSelectedUsers(selectedUsers.filter((u) => u.uuid !== user.uuid));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const renderTable = (): JSX.Element => {
    if (!loading && Object.keys(users).length === 0) {
      if (EmptyTableStateComponent) {
        return EmptyTableStateComponent;
      }
      return <EmptyUsersTable colCount="6" />;
    }

    return (
      <>
        {Object.values(users).map((user) => (
          <UserPermissionsTableRow
            key={user.uuid}
            user={user}
            selectedUsers={selectedUsers}
            handleSelectUser={handleSelectUser}
          />
        ))}
      </>
    );
  };

  return (
    <table className="table-wrapper__table table-wrapper_table--fixed">
      <thead className="sticky top-0 z-10">
        <tr className="table-wrapper__header-tr h-10">
          <th className="table-wrapper__new-th pt-3 pb-2 table-wrapper__th--w-3 bg-litlingo-secondary-80">
            <div className="px-2">
              <Tooltip tooltip="envelopesListActions.selectAll" redesign>
                <label
                  htmlFor="select-all"
                  className="flex justify-start items-center w-full cursor-pointer"
                >
                  <input
                    id="select-all"
                    data-testid="select-all"
                    className={`cursor-pointer form-checkbox litlingo-checkbox w-5 h-5 rounded-sm ${
                      selectedUsers.length > 0 &&
                      selectedUsers?.length < Object.keys(allUsers).length
                        ? 'checkbox-select-all-partial'
                        : ''
                    }`}
                    type="checkbox"
                    checked={
                      selectedUsers?.length === Object.keys(allUsers).length &&
                      selectedUsers?.length > 0
                    }
                    onChange={handleSelectAll}
                  />
                </label>
              </Tooltip>
            </div>
          </th>
          <th className="table-wrapper__new-th table-wrapper__th--w-22 bg-litlingo-secondary-80">
            Name
          </th>
          <th className="table-wrapper__new-th table-wrapper__th--w-25 bg-litlingo-secondary-80">
            Role(s)
          </th>
          <th className="table-wrapper__new-th table-wrapper__th--w-18 bg-litlingo-secondary-80">
            Division(s)
          </th>
          <th className="table-wrapper__new-th table-wrapper__th--w-16 bg-litlingo-secondary-80">
            Insight Integrations
          </th>
          <th className="table-wrapper__new-th table-wrapper__th--w-16 bg-litlingo-secondary-80">
            Prevent Integrations
          </th>
        </tr>
      </thead>
      <tbody className="table-wrapper__tbody" data-testid="users-table">
        {renderTable()}
      </tbody>
    </table>
  );
};

export default UserPermissionsTable;
