import { BarDatum } from '@nivo/bar';
import { FULL_DATE_FORMAT } from 'constants/formats';
import moment, { unitOfTime as moUnitOfTime } from 'moment';
import { useMemo } from 'react';
import { getActiveUuid, getCustomerDashboardConfig } from 'selectors/dashboard';
import { getNavParams } from 'selectors/nav';

import { useDeepCompareSelector } from 'store';
import { timeGroupingType } from 'types';

/* eslint-disable camelcase */
export type TimeRange = {
  created_after?: string;
  created_before?: string;
};
/* eslint-enable camelcase */

type MetricsTimeRange = {
  start: number;
  end: number;
  period: number;
  unitOfTime?: string;
};

export const TIME_RANGE_FIELD = 'time_range';
export const UNIT_OF_TIME = 'unit_of_time';

export const DEFAULT_TIME = '-30days';

export const NUMBER_OF_PERIODS = 3;

const YEARS = 'years';
const QUARTERS = 'quarters';
const MONTHS = 'months';
const WEEKS = 'weeks';
const DAYS = 'days';
const HOURS = 'hours';
const MINUTES = 'minutes';

export const THIS_YEAR = 'this_year';
export const THIS_QUARTER = 'this_quarter';
export const THIS_MONTH = 'this_month';
export const THIS_WEEK = 'this_week';

const UNITS = [YEARS, QUARTERS, MONTHS, WEEKS, DAYS, HOURS, MINUTES] as const;
const ISO_STRING_REGEX = '\\d{4}-[01]\\d-[0-3]\\dT[0-2]\\d:[0-5]\\d:[0-5]\\d\\.\\d+Z';
const RELATIVE_REGEX = new RegExp(`^(${ISO_STRING_REGEX})?(\\+\\d+|-\\d+)(${UNITS.join('|')})`);

const timePeriod = (period: number, units: number | string): string =>
  `${period >= 0 ? '+' : ''}${period}${units}`;

export const dateFormat = (
  amount?: moment.DurationInputArg1,
  unit?: moment.unitOfTime.DurationConstructor | undefined
): string => {
  switch (unit) {
    case 'days':
      return moment().subtract(amount, unit).startOf('days').utc().format(FULL_DATE_FORMAT);
    default:
      return moment().subtract(amount, unit).utc().format(FULL_DATE_FORMAT);
  }
};

export const years = (y: number): string => timePeriod(y, YEARS);
export const quarters = (q: number): string => timePeriod(q, QUARTERS);
export const months = (m: number): string => timePeriod(m, MONTHS);
export const weeks = (w: number): string => timePeriod(w, WEEKS);
export const days = (d: number): string => timePeriod(d, DAYS);
export const hours = (h: number): string => timePeriod(h, HOURS);
export const minutes = (m: number): string => timePeriod(m, MINUTES);

const todayAdjusted = moment().utc().add(1, 'day').startOf('day');

export const dateFormatYear = (): string => {
  const yearStart = moment().utc().startOf('year');
  const daysFromStart = todayAdjusted.diff(yearStart, 'days');
  return dateFormat(daysFromStart, 'days');
};

export const dateFormatHalfYear = (): string => {
  const monthStart = moment().utc().subtract(5, 'month').startOf('month');
  const daysFromStart = todayAdjusted.diff(monthStart, 'days');
  return dateFormat(daysFromStart, 'days');
};

export const yearToDate = (): string => {
  const yearStart = moment().utc().startOf('year');
  const daysFromStart = yearStart.diff(todayAdjusted, 'days');
  return timePeriod(daysFromStart, DAYS);
};

export const halfYearToDate = (): string => {
  const monthStart = moment().utc().subtract(5, 'month').startOf('month');
  const daysFromStart = monthStart.diff(todayAdjusted, 'days');
  return timePeriod(daysFromStart, DAYS);
};

export const DEFAULT_DASHBOARD_TIME_RANGE = '-90days';

const orderMoments = (moment1: moment.Moment, moment2: moment.Moment): moment.Moment[] =>
  moment1.isBefore(moment2) ? [moment1, moment2] : [moment2, moment1];

const thisPeriodRange = (range: string): moment.Moment[] => {
  const [, period] = range.split('_');

  const start = moment().startOf(period as moment.unitOfTime.StartOf);
  const end = moment();

  return [start, end];
};

const lastPeriodRange = (range: string): moment.Moment[] => {
  const [, period] = range.split('_');

  const start = moment()
    .subtract(1, period as moment.unitOfTime.DurationConstructor)
    .startOf(period as moment.unitOfTime.StartOf);

  const end = moment()
    .subtract(1, period as moment.unitOfTime.DurationConstructor)
    .endOf(period as moment.unitOfTime.StartOf);

  return [start, end];
};

const relativePeriodRange = (match: RegExpExecArray, isUsingRollup = false): moment.Moment[] => {
  const quantity = parseInt(match[2], 10);
  const units = match[3] as moment.unitOfTime.DurationConstructor;

  // if there is no iso string match[1] will be undefined and moment.now will be
  // used
  if (match[1] != null) {
    return orderMoments(moment(match[1]), moment(match[1]).add(quantity, units));
  }

  // if match[1] is null, it means the range is something like '-30days', in this
  // case we should push the starting moment to the end of the period if
  // quantity < 0 or to the start if quantity > 0

  if (!isUsingRollup) {
    let start = moment().add(1, units).startOf(units);
    if (quantity > 0) {
      start = moment().subtract(1, units).endOf(units);
    }
    return orderMoments(moment(), start.clone().add(quantity, units));
  }

  // TODO: remove this when all metrics are converted to rollup

  let start = units !== 'hours' ? moment().startOf(units) : moment();
  if (quantity > 0) {
    start = units !== 'hours' ? moment().endOf(units) : moment();
  }

  return orderMoments(moment(), start.clone().add(quantity, units));
};

export const toTimeRange = (range: string, isUsingRollup = false): moment.Moment[] => {
  if (range.startsWith('this_')) {
    return thisPeriodRange(range);
  }

  if (range.startsWith('last_')) {
    return lastPeriodRange(range);
  }

  const match = RELATIVE_REGEX.exec(range);
  if (match != null) {
    return relativePeriodRange(match, isUsingRollup);
  }

  // if nothing matches the range is startISO;endISO
  const [firstISO, secondISO] = range.split(';');

  const start = moment(firstISO).isValid() ? moment(firstISO) : moment(new Date(0));
  const end = moment(secondISO).isValid() ? moment(secondISO) : moment(new Date());

  return orderMoments(start, end);
};

// TODO: Remove when the backend stops throwing when iso string has ms
const toISOWithoutMS = (date: moment.Moment): string =>
  `${date.utc().toISOString().split('.')[0]}Z`;

export const useTimeRange = (field = TIME_RANGE_FIELD): TimeRange => {
  const activeUuid = useDeepCompareSelector(getActiveUuid);
  const dashboardConfig = useDeepCompareSelector(
    getCustomerDashboardConfig(activeUuid || undefined)
  );

  let range = useDeepCompareSelector((state) => getNavParams(state)[field]) as string;

  range = dashboardConfig?.override_time_range || range;

  const hasRollupWidgets = (dashboardConfig?.widgets || []).some((widget) =>
    ['sent_at', 'reviewed_at'].includes(widget?.type || '')
  );

  return useMemo(() => {
    if (range == null) {
      return {};
    }
    if (range === '-1month') {
      const startOfTheMonth = moment().startOf('month').format();
      const endOfTheMonth = moment().endOf('month').format();
      return {
        created_after: startOfTheMonth,
        created_before: endOfTheMonth,
      };
    }

    const [start, end] = toTimeRange(range as string, hasRollupWidgets);

    return {
      created_after: start.isValid() ? toISOWithoutMS(start) : '0',
      created_before: end.isValid() ? toISOWithoutMS(end) : undefined,
    };
  }, [range, hasRollupWidgets]);
};

// TODO: maybe this should take into account the original time range units
const diffToPeriod = (
  diff: moment.Duration
): {
  unitOfTime: string;
  period: number;
} => {
  const minutesDiff = diff.as('minutes');
  if (minutesDiff <= 60) {
    return {
      unitOfTime: 'minutes',
      period: 1,
    }; // 1 min
  }

  const hoursDiff = diff.as('hours');
  if (hoursDiff <= 24) {
    return {
      unitOfTime: 'hours',
      period: 1,
    }; // 1 hour
  }

  const daysDiff = diff.as('days');
  if (Math.floor(daysDiff) <= 30) {
    return {
      unitOfTime: 'days',
      period: 1,
    }; // 1 day
  }

  const weeksDiff = diff.as('weeks');
  if (weeksDiff <= 30) {
    return {
      unitOfTime: 'weeks',
      period: 1,
    }; // 1 week
  }

  const monthsDiff = diff.as('months');
  if (monthsDiff <= 12) {
    return {
      unitOfTime: 'months',
      period: 1,
    }; // 1 month
  }

  return {
    unitOfTime: 'years',
    period: 1,
  }; // 1 year
};

export const timeStringToPeriod = (time: timeGroupingType): number => {
  switch (time) {
    case 'hour':
      return moment.duration(1, 'hours').asSeconds();
    case 'day':
      return moment.duration(1, 'days').asSeconds();
    case 'week':
      return moment.duration(1, 'weeks').asSeconds();
    case 'month':
      return moment.duration(1, 'months').asSeconds();
    case 'year':
      return moment.duration(1, 'years').asSeconds();
    default:
      return moment.duration(1, 'days').asSeconds();
  }
};

export const timeRangeToMetricsRange = (
  range: string,
  fetchOnePeriod: boolean,
  fetchAvgPeriod: boolean,
  unitOfTime?: string,
  isUsingRollup = false
): MetricsTimeRange => {
  const [start, end] = toTimeRange(range, isUsingRollup);
  const diff = moment.duration(end.diff(start));

  if (fetchOnePeriod) {
    if (diffToPeriod(diff).unitOfTime === 'weeks') {
      return {
        start: start.valueOf(),
        end: end.valueOf(),
        period: Math.ceil(diff.as('days')),
        unitOfTime: 'days',
      };
    }

    return {
      start: start.valueOf(),
      end: end.valueOf(),
      period: Math.ceil(diff.as(unitOfTime ? (unitOfTime as moUnitOfTime.Base) : 'seconds')),
      unitOfTime: unitOfTime || diffToPeriod(diff).unitOfTime,
    };
  }

  if (fetchAvgPeriod) {
    const uot = unitOfTime || diffToPeriod(diff).unitOfTime;

    if (uot === 'weeks' || uot === 'months' || uot === 'years') {
      return {
        start: start
          .subtract(
            NUMBER_OF_PERIODS * Math.ceil(diff.as('days')),
            uot as moment.unitOfTime.DurationConstructor
          )
          .startOf(unitOfTime as moment.unitOfTime.StartOf)
          .valueOf(),
        end: end.valueOf(),
        period: Math.ceil(diff.as('days')),
        unitOfTime: 'days',
      };
    }

    return {
      // @ts-ignore
      start: start
        .subtract(
          NUMBER_OF_PERIODS * Math.ceil(diff.as(uot ? (uot as moUnitOfTime.Base) : 'seconds')),
          uot as moment.unitOfTime.DurationConstructor
        )
        .startOf(uot as moment.unitOfTime.StartOf)
        .valueOf(),
      end: end.valueOf(),
      period: Math.ceil(diff.as(uot ? (uot as moUnitOfTime.Base) : 'seconds')),
      unitOfTime: uot,
    };
  }

  const a = {
    start: start.valueOf(),
    end: end.valueOf(),
    period: unitOfTime && unitOfTime !== 'seconds' ? 1 : diffToPeriod(diff).period,
    unitOfTime: unitOfTime || diffToPeriod(diff).unitOfTime,
  };

  return a;
};

export const getLabelFormatFromPeriod = ({
  timestamp,
  period,
  unitOfTime,
  customFormat,
  utc,
}: {
  timestamp: number;
  period: number;
  unitOfTime?: string;
  customFormat?: string;
  utc?: boolean;
}): string => {
  if (unitOfTime) {
    const units: { [key: string]: string } = {
      hours: 'hh:mm A',
      days: 'ddd DD',
      weeks: 'MMM D',
      months: 'MMM',
      years: 'YYYY',
    };

    if (utc) {
      return moment.utc(timestamp * 1000).format(customFormat || units[unitOfTime]);
    }

    return moment(timestamp * 1000).format(customFormat || units[unitOfTime]);
  }

  if (period <= 3600) {
    return moment(timestamp * 1000).format('hh:mm A');
  }
  if (period > 3600 && period <= 86400) {
    return moment(timestamp * 1000)
      .add(moment(timestamp * 1000).isDST() ? 0 : 1, 'hours')
      .format('ddd DD');
  }
  if (period > 86400 && period <= 2628000) {
    return `${moment(timestamp * 1000).format('MMM DD')}`;
  }
  return moment(timestamp * 1000).format('MMM DD YYYY');
};

export const kFormatter = (num: number | undefined): string => {
  if (!num) return '0';
  if (Math.abs(num) < 999) {
    return `${num}`;
  }
  if (Math.abs(num) > 999 && Math.abs(num) < 999999) {
    return `${(num / 1000).toFixed(1)}K`;
  }

  return `${(num / 1000000).toFixed(1)}M`;
};

export const removeUTC = ({ date, unitOfTime }: { date: string; unitOfTime?: string }): string => {
  if (unitOfTime && unitOfTime === 'hours') {
    return date.charAt(date.length - 1) !== 'Z' ? date.concat('Z') : date;
  }
  return date;
};

export const fromTimeRangeToString = (timeRange: string): string =>
  timeRange.replace('-', '').replace(/[^a-zA-Z](?=[a-zA-Z])/g, '$& ');

export const sumObjectsByKey = (...objs: BarDatum[]): BarDatum => {
  const res = objs.reduce((a, b) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const k in b) {
      if (Object.prototype.hasOwnProperty.call(b, k)) {
        if (typeof b[k] === 'string') {
          a[k] = b[k];
        } else {
          a[k] = (Number(a[k]) || 0) + Number(b[k]);
        }
      }
    }
    return a;
  }, {});

  return res;
};
