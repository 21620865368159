import {
  applyPermissions,
  applyPermissionsSingleUser,
  clearPermissions,
  setNeedToApplyChanges,
} from 'actions/userPermissionsPage';
import Modal from 'components/Modal';
import { MODAL_BACKGROUND } from 'constants/common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDomain } from 'selectors/auth';
import { getSelectedUsers } from 'selectors/userPermissionsPage';
import { useHistory } from 'utils/urls';

type ComponentProps = {
  toggleModalOpen: () => void;
};

const SaveChangesModal: React.FC<ComponentProps> = ({ toggleModalOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const customerDomain = useSelector(getCustomerDomain);
  const selectedUsers = useSelector(getSelectedUsers);
  const singleUser = selectedUsers.length === 1;

  const handleSaveChanges = (): void => {
    if (singleUser) {
      dispatch(applyPermissionsSingleUser());
      dispatch(setNeedToApplyChanges(false));
      return;
    }

    dispatch(applyPermissions());
    dispatch(clearPermissions());
    dispatch(setNeedToApplyChanges(false));
  };

  const handleExit = (): void => {
    dispatch(setNeedToApplyChanges(false));
    history.pushLookup({
      customerDomain,
      routeName: 'users',
    });
  };

  const saveChangesBody = (
    <>
      <div className="absolute top-5 left-0 right-0 h-40 z-0">{MODAL_BACKGROUND}</div>

      <div className="flex flex-col mt-36 mb-8 gap-2 max-h-128 overflow-hidden transition-all duration-300">
        <h2 className="text-heading-1">Save changes?</h2>
        <span>
          There were selections made that haven’t been applied to the user. Would you like to save
          these selections?
        </span>
      </div>
    </>
  );

  return (
    <Modal
      body={saveChangesBody}
      title=" "
      toggleShowModal={toggleModalOpen}
      xButton={false}
      okButton
      okButtonText="Save changes"
      okButtonOnClick={handleSaveChanges}
      cancelButtonText="No, exit"
      cancelButtonOnclick={handleExit}
    />
  );
};

export default SaveChangesModal;
