/* eslint-disable max-lines */
import LoadingIndicator from 'components/LoadingIndicator';
import Permissions from 'components/Permissions';
import useClickOutside from 'components/utils/useClickOutside';
import React, { useCallback, useRef, useState } from 'react';

type ComponentProps = {
  primaryLabel: string;
  options: Option[];
  dropdownDirection?: 'down' | 'up';
  loading?: boolean;
};
type Option = {
  id: string;
  label: string;
  action: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  permissions?: string;
};

const ButtonMenuGeneral: React.FC<ComponentProps> = (props) => {
  const { primaryLabel, options, dropdownDirection = 'down', loading = false } = props;

  const [isOpen, setIsOpen] = useState(false);
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleOptionClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    action: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  ): void => {
    setIsOpen(false);
    e.stopPropagation();
    action(e);
  };

  const renderOption = (o: Option): JSX.Element => {
    if (o.permissions) {
      return (
        <Permissions action={o.permissions} key={o.id}>
          <button
            data-testid={`menu-option-${o.id}`}
            type="button"
            onClick={(e): void => handleOptionClick(e, o.action)}
            className="text-body w-full border-litlingo-gray-2 p-2 pr-6 focus:outline-none hover:bg-litlingo-gray-1"
          >
            <div className="flex">
              <span className="whitespace-no-wrap">{o.label}</span>
            </div>
          </button>
        </Permissions>
      );
    }
    return (
      <button
        key={o.label}
        type="button"
        onClick={(e): void => handleOptionClick(e, o.action)}
        className="text-body w-full border-litlingo-gray-2 p-2 pr-6 focus:outline-none hover:bg-litlingo-gray-1"
      >
        <div className="flex">
          <span className="whitespace-no-wrap">{o.label}</span>
        </div>
      </button>
    );
  };

  const renderButton = (): JSX.Element => (
    <div className="w-full flex flex-row items-center bg-litlingo-primary rounded">
      <button
        type="button"
        className="button button--primary h-full flex gap-1 justify-center py-0.5 px-2 text-body w-full border-0 focus:outline-none"
        onClick={(): void => setIsOpen(!isOpen)}
      >
        {loading ? (
          <LoadingIndicator size="5" />
        ) : (
          <span className="font-bold text-white whitespace-no-wrap">{primaryLabel}</span>
        )}
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.49026 8.44392L1.83398 3L0 4.91123L7.49035 12.0926L15 4.91123L13.166 3L7.49026 8.44392Z"
            fill="#ffffff"
          />
        </svg>
      </button>
    </div>
  );

  const renderMenu = (): JSX.Element | undefined => (
    <>
      {isOpen && options.length > 0 && (
        <div
          data-testid="dropdown-menu"
          className={`origin-bottom-right right-0 absolute rounded z-50 border border-litlingo-gray-2 ${
            dropdownDirection === 'down'
              ? 'top-full mt-1'
              : 'top-0 -mt-1 transform -translate-y-full'
          }`}
        >
          <div
            className="rounded bg-white shadow-xs overflow-hidden"
            style={{ boxShadow: '3px 1px 8px rgba(0, 0, 0, 0.35)' }}
          >
            <div
              role="menu"
              data-testid="options-container"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {options.map((o) => renderOption(o))}
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div ref={wrapperRef} className="relative w-full">
      {renderButton()}
      {renderMenu()}
    </div>
  );
};

export default ButtonMenuGeneral;
