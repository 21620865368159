import { fetchCommsByPlatform } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import withWidget from 'decorators/widget';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getCommsByPlatLoading,
  getEmailCommsCount,
  getSlackCommsCount,
  getZendeskCommsCount,
} from 'selectors/analytics';
import { useSelector } from 'store';
import { useTimeRange } from 'utils/timeRanges';

const EMAIL_ICON = (
  <svg
    className="h-6 w-6 text font-bold inline-flex"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
  </svg>
);
const SLACK_ICON = (
  <svg
    className="h-6 w-6 text font-bold inline-flex"
    fill="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    stroke="currentColor"
    focusable="false"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 1024 1024"
  >
    <path
      fill="#333333"
      d="M409.4 128c-42.4 0-76.7 34.4-76.7 76.8c0 20.3 8.1 39.9 22.4 54.3c14.4 14.4 33.9 22.5 54.3 22.5h76.7v-76.8c0-42.3-34.3-76.7-76.7-76.8zm0 204.8H204.7c-42.4 0-76.7 34.4-76.7 76.8s34.4 76.8 76.7 76.8h204.6c42.4 0 76.7-34.4 76.7-76.8c.1-42.4-34.3-76.8-76.6-76.8zM614 486.4c42.4 0 76.8-34.4 76.7-76.8V204.8c0-42.4-34.3-76.8-76.7-76.8c-42.4 0-76.7 34.4-76.7 76.8v204.8c0 42.5 34.3 76.8 76.7 76.8zm281.4-76.8c0-42.4-34.4-76.8-76.7-76.8S742 367.2 742 409.6v76.8h76.7c42.3 0 76.7-34.4 76.7-76.8zm-76.8 128H614c-42.4 0-76.7 34.4-76.7 76.8c0 20.3 8.1 39.9 22.4 54.3c14.4 14.4 33.9 22.5 54.3 22.5h204.6c42.4 0 76.7-34.4 76.7-76.8c.1-42.4-34.3-76.7-76.7-76.8zM614 742.4h-76.7v76.8c0 42.4 34.4 76.8 76.7 76.8c42.4 0 76.8-34.4 76.7-76.8c.1-42.4-34.3-76.7-76.7-76.8zM409.4 537.6c-42.4 0-76.7 34.4-76.7 76.8v204.8c0 42.4 34.4 76.8 76.7 76.8c42.4 0 76.8-34.4 76.7-76.8V614.4c0-20.3-8.1-39.9-22.4-54.3c-14.4-14.4-34-22.5-54.3-22.5zM128 614.4c0 20.3 8.1 39.9 22.4 54.3c14.4 14.4 33.9 22.5 54.3 22.5c42.4 0 76.8-34.4 76.7-76.8v-76.8h-76.7c-42.3 0-76.7 34.4-76.7 76.8z"
    />
  </svg>
);
const ZENDESK_ICON = (
  <svg
    className="h-4 w-6 text font-bold inline-flex"
    fill="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    stroke="currentColor"
    focusable="false"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 256 195"
  >
    <path
      fill="#333333"
      d="M118.25 51.233v142.773H0L118.25 51.233zm0-51.233c0 32.644-26.481 59.125-59.125 59.125S0 32.645 0 0h118.25zm19.5 194.006c0-32.677 26.449-59.125 59.125-59.125c32.677 0 59.125 26.48 59.125 59.125H137.75zm0-51.266V0H256L137.75 142.74z"
    />
  </svg>
);

const spinner = (
  <div className="h-6">
    <LoadingIndicator className="ml-2" size="5" />
  </div>
);

type PlatformProps = {
  icon: JSX.Element;
  text: string;
  count: string | number;
  loading: boolean;
};

const Platform: React.FC<PlatformProps> = (props) => {
  const { icon, text, count, loading } = props;

  return (
    <div className="flex flex-row justify-between text-xl text">
      <div>
        <span className="align-middle">{icon}</span>
        <span className="inline-flex align-middle ml-3">{text}</span>
      </div>

      <span>{loading ? spinner : count}</span>
    </div>
  );
};

const Platforms: React.FC = () => {
  const dispatch = useDispatch();

  const commsByPlatLoading = useSelector(getCommsByPlatLoading);
  const emailCount = useSelector(getEmailCommsCount);
  const slackCount = useSelector(getSlackCommsCount);
  const zendeskCount = useSelector(getZendeskCommsCount);

  const range = useTimeRange();

  useEffect(() => {
    dispatch(fetchCommsByPlatform(range));
  }, [dispatch, range]);

  return (
    <div className="h-full flex flex-col justify-around">
      <Platform icon={EMAIL_ICON} text="Email" count={emailCount} loading={commsByPlatLoading} />
      <Platform icon={SLACK_ICON} text="Slack" count={slackCount} loading={commsByPlatLoading} />
      <Platform
        icon={ZENDESK_ICON}
        text="Zendesk"
        count={zendeskCount}
        loading={commsByPlatLoading}
      />
    </div>
  );
};

export default withWidget({
  title: 'Platforms',
  subtitle: 'Messages monitored per platform',
  minWidth: 3,
  minHeight: 8,
})(Platforms);
