import { AxisTickProps } from '@nivo/axes';
import { BarDatum, BarExtendedDatum, BarMouseEventHandler, ResponsiveBar } from '@nivo/bar';
import { useTheme } from '@nivo/core';
import {
  allowedReadOnlyDimensions,
  metricsLinksMap,
  stackedHistogramFiltersQueryMap,
} from 'constants/dashboard';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getNavParamsByResource, getNavWidgetFilters } from 'selectors/nav';
import { getPermissionsPolicy } from 'selectors/permissions';
import { DefaultWidgetComponentProps, MetricsDataV2 } from 'types';
import logEvent from 'utils/analytics';
import { buildStackedHistogramDataFromMetrics } from 'utils/dashboard';
import { isActionAuthorized } from 'utils/permissions';
import { useHistory } from 'utils/urls';
// FIXME: Change DefaultWidgetComponentProps to use new metrics type
// once the migration to the new structure is done
const StackedHistogramWidget: React.FC<
  DefaultWidgetComponentProps & { widgetData: MetricsDataV2 }
> = (props) => {
  const { widgetId, widgetData, nivoProps, queryData, showLegend } = props;

  const history = useHistory();
  const customerDomain = useSelector(getCustomerDomain);
  const widgetFilters = useSelector(getNavWidgetFilters(widgetId));
  const user = useSelector(getUser);
  const filters = useSelector(getNavParamsByResource(resourceQueryParamName.metrics));
  const policy = useSelector(getPermissionsPolicy);

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  const processedData = buildStackedHistogramDataFromMetrics(widgetData);
  if (processedData.data.length === 0) {
    return <WidgetEmptyState />;
  }

  // TODO: Create an utility to make all these validations
  const handleClick = (datum: BarExtendedDatum): void => {
    // this widget only ever has a single metric config
    // so we pluck the first query config
    const query = queryData.queries[0];
    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info == null || !isActionAuthorized(info.action, user.roles, policy)) {
      return;
    }

    const series = widgetData.results[0];

    if (series == null) {
      return;
    }

    const start = moment.utc(queryData.start_time * 1000).format(FULL_DATE_FORMAT);
    const end = moment.utc(queryData.end_time * 1000).format(FULL_DATE_FORMAT);

    const dimensions: Record<string, string | string[]> = {};
    if (info.dimensions != null) {
      Object.entries(info.dimensions).forEach(([dimension, param]) => {
        const allowed = allowedReadOnlyDimensions[query.metric];

        // if allowedDimension is not defined or the dimension is not included,
        // just ignore it
        if (allowed == null || !allowed.includes(dimension)) {
          return;
        }

        let value: string | string[] = '';
        if (filters[dimension] != null) {
          const topValue = filters[dimension];
          value = topValue;
        }

        if (widgetFilters[dimension] != null) {
          const widgetValue = filters[dimension];
          value = widgetValue;
        }

        if (query.group_by === dimension) {
          value = datum.id as string;
        }

        dimensions[`${info.resource}__${param}`] = value;
      });
    }
    let groupBy: string;
    let isReviewedReviewValue = false;
    if (Array.isArray(query.group_by)) {
      // eslint-disable-next-line prefer-destructuring
      groupBy = query.group_by[0];
      isReviewedReviewValue = query.group_by.some((item) => item === 'is_reviewed_review_value');
    } else {
      groupBy = query.group_by ?? '';
      isReviewedReviewValue = groupBy === 'is_reviewed_review_value';
    }

    const filtersQuery =
      stackedHistogramFiltersQueryMap[query.metric as keyof typeof stackedHistogramFiltersQueryMap];

    const { x_axis: xAxis, y_axis: yAxis } =
      filtersQuery[groupBy as keyof typeof filtersQuery] ?? filtersQuery.default;

    const datumId = datum.id as string;
    const datumIdData: Record<string, string | string[]> = {};

    if (datumId === 'reviewed') {
      datumIdData[`${info.resource}__${yAxis}`] = ['in_policy', 'out_of_policy'];
    } else if (isReviewedReviewValue && datumId === 'pending') {
      datumIdData[`${info.resource}__${yAxis}`] = ['pending', 'skipped'];
    } else {
      datumIdData[`${info.resource}__${yAxis}`] = datumId;
    }

    if (query.metric === 'assignments') {
      history.push(`/${customerDomain}/review-set`);
    } else {
      history.pushLookup({
        customerDomain,
        routeName: info.route,
        queryParams: {
          ...dimensions,
          // @ts-expect-error Value type is not an string, we turn this
          // into an object in order get the id of the resource
          [`${info.resource}__${xAxis}`]: datum.data.category.value as string,
          ...datumIdData,
          [`${info.resource}__created_after`]: start,
          [`${info.resource}__created_before`]: end,
        },
      });
    }
  };

  const handleMouseEnter: BarMouseEventHandler<SVGElement> = (
    _datum: BarExtendedDatum,
    event: React.MouseEvent<SVGElement, MouseEvent>
  ): void => {
    const [id, type] = widgetId.split('--');
    logEvent(`Dashboard-hover-${id}-${type}`);

    const query = queryData.queries[0];
    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info != null) {
      event.currentTarget.style.cursor = 'pointer';
    }
  };

  const CustomTick = (tick: AxisTickProps<string>): JSX.Element => {
    const theme = useTheme();
    const { value, x, y } = tick;
    const words = value.split(' ');
    return (
      <g transform={`translate(${x},${y + 10})`}>
        <line stroke="rgb(119, 119, 119)" strokeWidth={1} y1={-5} y2={-10} />
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            ...theme.axis.ticks.text,
          }}
          transform={`translate(0,${y + 10})`}
        >
          {words[0].length > 7 ? `${words[0].substring(0, 4)}...` : words[0]}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveBar
      data={processedData.data}
      keys={processedData.keys}
      // @ts-expect-error Value type is not an string, we turn this
      // into an object in order get the id of the resource
      indexBy={(item): string => item.category.label}
      margin={{ top: 10, right: showLegend ? 220 : 10, bottom: 50, left: 50 }}
      enableLabel={false}
      colors={[
        // greens dark to light
        '#65A782',
        '#93C6A8',
        '#B6DDC6',
        // yellows dark to light
        '#F4A21F',
        '#FFCB48',
        '#FFEDA1',
      ]}
      enableGridY={false}
      layout="vertical"
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisBottom={{
        renderTick: CustomTick,
      }}
      legends={
        showLegend
          ? [
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                itemWidth: 100,
                itemHeight: 20,
                translateX: 130,
                toggleSerie: true,
              },
            ]
          : []
      }
      legendLabel={showLegend ? (datum: BarDatum): string => processedData.labelsMap[datum.id] : ''}
      theme={{
        tooltip: {
          container: {
            background: 'none',
            boxShadow: 'none',
          },
        },
        axis: {
          ticks: {
            text: { fontSize: processedData.data.length > 6 ? 9 : 11 },
          },
        },
      }}
      tooltip={(d): JSX.Element => (
        <div className="bg-litlingo-off-white p-1 rounded flex items-center shadow-md">
          <div
            style={{
              background: d.color,
            }}
            className="h-3 w-3 mr-2"
          />
          <span className="text">
            {processedData.labelsMap[d.id as number]} - {d.indexValue}: <b>{d.value}</b>
          </span>
        </div>
      )}
      animate
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...nivoProps}
    />
  );
};

export default withWidget({})(StackedHistogramWidget as React.FC<DefaultWidgetComponentProps>);
// FIXME: Remove this cast when the migration to the new structure is done
