import { addAnnotationMatcherToRule, setDropPosition } from 'actions';
import { ItemTypes } from 'components/RuleManagerTable/RuleManagerUtils';
import React from 'react';
import { useDrag } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { MRule, NormalizedAnnotator, UUID } from 'types';
import RenderAnnotator from './RenderAnnotator';

type ComponentProps = {
  Component: typeof RenderAnnotator;
  annotator: NormalizedAnnotator;
  rule: MRule;
  campaignId: UUID;
  searchValue: string;
};

const MakeAnnotatorDraggable: React.FC<ComponentProps> = ({
  Component,
  annotator,
  rule,
  campaignId,
  searchValue,
}) => {
  const dispatch = useDispatch();

  const type: keyof typeof ItemTypes = 'ANNOTATOR';

  const [, drag, preview] = useDrag({
    item: { type: ItemTypes[type], annotator, rule },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult) {
        const { collapsedFolder, handleUncollapse, id } = dropResult;

        dispatch(addAnnotationMatcherToRule({ rule, annotator, dropIndex: dropResult.index }));

        if (collapsedFolder.includes(id)) {
          handleUncollapse(id);
        }
      }
      dispatch(setDropPosition(-1));
    },
  });

  return (
    <Component
      drag={drag}
      preview={preview}
      annotator={annotator}
      rule={rule}
      campaignId={campaignId}
      searchValue={searchValue}
    />
  );
};

export default MakeAnnotatorDraggable;
