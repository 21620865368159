import { call, takeEvery } from 'redux-saga/effects';
import type { SagaReturn } from 'types';
import { createSession, getSession, updateSession } from 'utils/session';

function* handleSessionSaga(): SagaReturn {
  const session = yield call(getSession);
  if (session) {
    yield call(updateSession);
  } else {
    yield call(createSession);
  }
}

function* sessionSaga(): SagaReturn {
  yield takeEvery('*', handleSessionSaga);
}

export default sessionSaga;
