import type { PartialConfig } from './base';

const local: PartialConfig = {
  backend: {
    base: 'http://127.0.0.1:5000/api/v1',
  },
  google: {
    clientId: '135764768326-hdmvt17dopjgd266g7tpka6vaa75ha92.apps.googleusercontent.com',
  },
};

export default local;
