/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { patchSingleRuleConfig, showSuccessAlert } from 'actions';
import LinkLookup from 'components/LinkLookup';
import Tooltip from 'components/Tooltip';
import itemRowcolors from 'constants/rowItem';
import { NOTES_ICON_REDESIGN } from 'constants/testCaseListIcons';
import React, { useEffect, useRef, useState } from 'react';
import type { DragElementWrapper, DragPreviewOptions, DragSourceOptions } from 'react-dnd';
import { DragPreviewImage } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTriggeredNodes } from 'selectors/communications';
import {
  getAddedNodes,
  getLastChangedNode,
  getModifiedNodes,
  getSelectedNode,
} from 'selectors/config';
import { getSelectedAnnotators } from 'selectors/rule';
import { getShowRuleHighlights } from 'selectors/rules';
import {
  getAllChildNodes,
  getAllChildNodesAndRelationships,
  getDisabledNode,
  getNodeInCompare,
  getRuleInCompare,
} from 'selectors/rulesCompare';
import { useSelector } from 'store';
import type { RuleRowDataType } from 'types';
import {
  formattedName,
  indentation,
  modifiedIcon,
  operatorName,
  operatorNameNot,
  previewImage,
  relationshipIcon,
} from './RuleManagerUtils';

type ComponentProps = {
  data: RuleRowDataType;
  isDragging: boolean;
  active: boolean;
  drag: DragElementWrapper<DragSourceOptions>;
  drop: DragElementWrapper<unknown>;
  preview: DragElementWrapper<DragPreviewOptions>;
  collapsedItems: string[];
  collapsedFolder: string[];
  idToMove: string | null;
  // handleUncollapse: (id: string) => void;
  // handleCollapse: (id: string, isRoot?: boolean) => void;
  showTransition: boolean;
  setDropPosition: React.Dispatch<React.SetStateAction<number | undefined>>;
  dropPosition: number | undefined;
  side: 'left' | 'right';
};

type RouterParams = { ruleId?: string; campaignId?: string };

const RowItem: React.FC<ComponentProps> = ({
  data,
  isDragging,
  active,
  drag,
  drop,
  preview,
  collapsedItems,
  collapsedFolder,
  idToMove,
  // handleUncollapse,
  // handleCollapse,
  showTransition,
  setDropPosition,
  dropPosition,
  side,
}) => {
  const dispatch = useDispatch();

  const { campaignId, ruleId } = useParams<RouterParams>();

  let { name } = data;
  const { deleted } = data;
  const { id, actionItem, level, relationship, annotatorId, index, changed, change, description } =
    data;

  const triggeredNodes = useSelector(getTriggeredNodes);
  const modifiedNodes = useSelector(getModifiedNodes);
  const addedNodes = useSelector(getAddedNodes);
  const lastChangedNode = useSelector(getLastChangedNode);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const selectedNode = useSelector(getSelectedNode);
  const showHighlights = useSelector(getShowRuleHighlights);
  const selectedAnnotators = useSelector(getSelectedAnnotators);

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rule = useSelector((state) => getRuleInCompare(state, side));
  const node = useSelector((state) => getNodeInCompare(state, side, id));

  const disabled = useSelector((state) => getDisabledNode(state, data.id, side));

  const isRoot = data.parent == null;
  const allChildNodes = useSelector((state) => getAllChildNodesAndRelationships(state, side, id));
  const allChilds = useSelector((state) => getAllChildNodes(state, side, id));

  const [image, setImage] = useState('');
  const [isSetColorOpened, setIsSetColorOpened] = useState(false);

  const ref = useRef(null);

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const container = useRef<HTMLDivElement>(null);
  const containerP = useRef<HTMLDivElement>(null);

  const numberOfcollapsedItems = allChildNodes.reduce((acc, n) => {
    let count = 0;
    if (n) {
      if (collapsedItems.includes(n.id)) {
        count += 1;
      }

      if ('relationship' in n && collapsedFolder.includes(n.id)) {
        count += n.relationship.length;
      }
    }
    return acc + count;
  }, 0);

  useEffect(() => {
    const position = index + allChilds.length - numberOfcollapsedItems;
    if (active) {
      setDropPosition(position);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, id]);

  useEffect(() => {
    setImage(previewImage(name).src);
  }, [name]);

  drag(drop(ref));

  const getBackgroundColor = (): string => {
    if (changed === 'add') return 'bg-litlingo-success-light';
    if (changed === 'delete') return 'bg-litlingo-alert-light';
    if (changed === 'change') return 'bg-litlingo-success-light';

    if (idToMove === id) return 'bg-litlingo-gray-2';
    if (isDragging) return 'opacity-50 bg-litlingo-gray-1';
    if (active) return 'border-2 border-litlingo-primary-80';

    return 'bg-white';
  };

  const routeParams = {
    annotatorId: annotatorId || '',
    campaignId: campaignId || '',
    ruleId: ruleId || '',
  };

  if (data.modifiers?.NOT ?? false) {
    name = operatorNameNot[name as 'RELATIONSHIP_MATCH' | 'OR' | 'AND'];
  }
  if (data.negated) {
    name = `${name} (NEGATED)`;
  } else if (data.negated === false) {
    name = `${name} (NOT NEGATED)`;
  }

  if (data.mode_of_speech === 'subject') {
    name = `${name} (SUBJECT)`;
  } else if (data.mode_of_speech === 'object') {
    name = `${name} (OBJECT)`;
  }

  const modifyColor = (value: string): void => {
    dispatch(
      // @ts-ignore
      patchSingleRuleConfig({
        id: data.id,
        color: value,
      })
    );
  };

  const renderRelationship = (): JSX.Element[] =>
    relationship.map((item) => {
      let bgColor = 'bg-white';
      // @ts-ignore
      if (item.changed === 'add') bgColor = 'bg-litlingo-success-light';
      // @ts-ignore
      if (item.changed === 'delete') bgColor = 'bg-litlingo-alert-light';
      if (changed === 'add') bgColor = 'bg-litlingo-success-light';
      if (changed === 'delete') bgColor = 'bg-litlingo-alert-light';
      if (changed === 'change') bgColor = 'bg-litlingo-success-light';
      if (triggeredNodes.includes(item.id) && showHighlights) bgColor = 'bg-litlingo-gray-2';

      return (
        <Tooltip tooltip={disabled ? 'ruleConfig.disabledBranch' : ''} key={item.id}>
          <div
            className={`flex flex-row items-center justify-between w-full border-litlingo-gray-2 h-6
            ${disabled && 'text-litlingo-line'}
            ${bgColor}
            ${collapsedItems.includes(id) ? '' : ''}`}
          >
            <div className="whitespace-no-wrap text-body p-1  text-left flex flex-row">
              <div className="flex flex-row">
                <div className="text-litlingo-gray-4 mr-2 text-right w-6">{index}</div>
              </div>
              {indentation.repeat(level)}
              {'\u00A0\u00A0'}
              {relationshipIcon(disabled)}
              {item.deleted ? (
                <span className="text-gray-500">{formattedName(item.name, disabled)}</span>
              ) : (
                formattedName(item.name, disabled)
              )}
            </div>
            <span className="ml-2.5 border-b border-litlingo-gray-1 w-full" />
            <div className="pr-4" key={item.id}>
              {modifiedNodes && modifiedNodes.indexOf(item.id) !== -1 ? modifiedIcon : ''}
            </div>
          </div>
        </Tooltip>
      );
    });

  /* const handleClick = (): void => {
    if (collapsedFolder.includes(id)) {
      handleUncollapse(id);
    } else {
      handleCollapse(id);
    }
  }; */

  const renderName = (itemId: string): JSX.Element | string => {
    if (annotatorId) {
      if (deleted) {
        return (
          <Tooltip tooltip="ruleConfig.deletedAnnotator">
            <span className="text-gray-500">{formattedName(name, disabled)}</span>
          </Tooltip>
        );
      }
      return (
        <LinkLookup
          routeName="annotator"
          routeParams={routeParams}
          className={`relative mr-2.5 hover:underline ${
            node?.typeOfConfig === 'ANNOTATION_MATCH' &&
            node.annotatorId &&
            selectedAnnotators.includes(node?.annotatorId) &&
            'bg-litlingo-green-bg'
          } ${disabled ? 'text-litlingo-line' : 'text-black'}`}
        >
          {formattedName(name, disabled)}
        </LinkLookup>
      );
    }
    return (
      <div className="relative flex items-center">
        {!collapsedFolder.includes(itemId) && !isRoot && (
          <span
            className={`absolute border-l border-litlingo-gray-2 left-1.5 top-6 `}
            style={{
              height: `${(allChildNodes.length - numberOfcollapsedItems) * 16}px`,
            }}
          />
        )}
        {/* {operatorName[name as keyof typeof operatorName] && !isRoot && (
          <button
            data-testid={`collapse-${itemId}`}
            className="focus:outline-none mr-1"
            type="button"
            onClick={handleClick}
          >
            {collapsedFolder.includes(id)
              ? ARROW_CLOSE_ENVELOPE(disabled)
              : ARROW_OPEN_ENVELOPE(disabled)}
          </button>
        )} */}
        <span className={`${change === 'operator' ? 'bg-litlingo-primary-60' : ''}`}>
          {formattedName(name, disabled)}
        </span>
      </div>
    );
  };

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setColorIcon = (): JSX.Element => (
    <div className="relative flex flex-row items-center">
      {isSetColorOpened && (
        <div className="absolute right-full z-0 mr-4 h-full">
          <div className="flex items-center justify-center">
            {Object.entries(itemRowcolors).map(([key, value]) => (
              <div key={key} className="px-1">
                <button
                  type="button"
                  aria-label="Set color"
                  className={`w-5 h-5 inline-flex rounded-full cursor-pointer focus:outline-none focus:shadow-outline ${value}`}
                  onClick={(): void => {
                    setIsSetColorOpened(false);
                    modifyColor(value);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <button
        type="button"
        aria-label="Open color picker"
        className={`w-5 h-5 rounded-full focus:outline-none inline-flex ${
          data.color || 'bg-litlingo-gray-1'
        }`}
        onClick={(): void => setIsSetColorOpened(!isSetColorOpened)}
      />
    </div>
  );

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderModifiedIcon = (modifiedId: string): JSX.Element | null => {
    if (modifiedNodes && modifiedNodes.indexOf(modifiedId) !== -1) return modifiedIcon;
    if (addedNodes && addedNodes.indexOf(modifiedId) !== -1) return modifiedIcon;
    return null;
  };

  /* const renderLastModified = (): JSX.Element | null => {
    if (!hover) return null;

    return (
      <div className={`flex flex-row gap-1 text-litlingo-gray-4 ml-4 `}>
        <span>{rule?.updated_by?.name}</span>
        <span>({moment(rule?.updated_at).fromNow()})</span>
      </div>
    );
  }; */

  const renderDescription = (): JSX.Element | null => {
    if (data.description) {
      return (
        <div className="flex flex-row items-center">
          <span style={disabled ? { filter: 'grayscale(1)' } : {}}>{NOTES_ICON_REDESIGN}</span>
          {/* <input
            className={`italic ml-1 mr-2 text-sx pl-1 pt-0.5  border border-transparent border-solid box-border transition duration-150 ease-in-out sm:text-sm sm:leading-5  focus:border focus:border-litlingo-line focus:border-solid overflow-y-hidden truncate ${
              disabled ? 'text-litlingo-line' : 'text-litlingo-gray-300'
            } ${change === 'description' ? 'bg-litlingo-primary-60' : 'bg-transparent'}`}
            placeholder="Add a description..."
            data-testid="description-input"
            value={description || ''}
            onChange={(e): void => modifyDescription(e.target.value)}
            onClick={(e): void => handleInputFocus(e)}
            onBlur={(): void => setInputFocused(false)}
            style={{
              minWidth: '120px',
              width,
            }}
            ref={span}
          /> */}
          <span
            className={`italic ml-1 mr-2 text-sx px-1 pt-0.5  border border-transparent border-solid box-border transition duration-150 ease-in-out sm:text-sm sm:leading-5  focus:border focus:border-litlingo-line focus:border-solid overflow-y-hidden truncate ${
              disabled ? 'text-litlingo-line' : 'text-litlingo-gray-300'
            } ${change === 'description' ? 'bg-litlingo-primary-60' : 'bg-transparent'}`}
          >
            {description}
          </span>
        </div>
      );
    }
    return null;
  };

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleLinkClick = async (): Promise<void> => {
    const link = new URL(window.location.href);

    link.searchParams.delete('highlighted_rule');
    link.searchParams.append('highlighted_rule', id);

    await navigator.clipboard.writeText(link.href);
    dispatch(showSuccessAlert('Link copied to your clipboard'));
  };

  return (
    <>
      <DragPreviewImage connect={preview} src={image} />
      <Tooltip tooltip={disabled ? 'ruleConfig.disabledBranch' : ''}>
        <div
          ref={ref}
          data-testid={`rule-manager-table-tr-${id}`}
          className={`flex flex-row gap-2 items-center justify-between w-full h-6 py-1 
          ${getBackgroundColor()}
          ${disabled && 'text-litlingo-line'}
          ${lastChangedNode === id && showTransition ? 'bg-gray-transition' : ''}
          ${collapsedItems.includes(id) ? '' : ''}
          ${index === dropPosition && 'border-b-2 border-litlingo-success'}
        `}
        >
          <div
            className={`${
              actionItem === null ? 'text-gray-300' : ''
            } whitespace-no-wrap text-body text-left flex flex-row items-center w-full`}
          >
            <div className="flex flex-row">
              <div
                className={`text-litlingo-gray-4 text-right mr-2 ${
                  node?.typeOfConfig === 'ANNOTATION_MATCH' &&
                  node.annotatorId &&
                  selectedAnnotators.includes(node?.annotatorId) &&
                  'bg-litlingo-green-bg'
                }`}
                style={{ width: '24px' }}
              >
                {index}
              </div>
              {/* <div>{actionItem}</div> */}
            </div>
            {indentation.repeat(level)}

            {renderName(id)}
            <div className="flex flex-row w-full items-center" ref={containerP}>
              {operatorName[name as keyof typeof operatorName] && (
                <div
                  className="ml-4"
                  style={{
                    maxWidth: '400px',
                  }}
                >
                  {renderDescription()}
                </div>
              )}
              {/* <span className="border-b border-litlingo-gray-1 w-full" ref={container} /> */}
              {/* {renderLastModified()} */}
            </div>
          </div>

          {/* <div
            className={`flex flex-row space-x-1 ${!!modifiedNodes || !!addedNodes ? 'mr-1' : null}`}
          >
            {operatorName[name as keyof typeof operatorName] && (
              <div className="flex flex-row">{setColorIcon()}</div>
            )}
            <div className="flex flex-row ">{renderModifiedIcon(id)}</div>
            {selectedNode === index && (
              <button type="button" className="focus:outline-none" onClick={handleLinkClick}>
                {LINK_ICON}
              </button>
            )}
          </div> */}
        </div>
      </Tooltip>
      {renderRelationship()}
    </>
  );
};

export default RowItem;
