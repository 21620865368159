import Modal from 'components/Modal';
import React from 'react';

type ComponentProps = {
  countOutcomes: number | null;
  okButtonOnClick: () => void;
  toggleShowModal: () => void;
};

const OutcomeConfirmModal: React.FC<ComponentProps> = ({
  countOutcomes,
  okButtonOnClick,
  toggleShowModal,
}) => {
  const body = (
    <div className="text mt-5">
      <p>This outcome is shared by other models.</p>
      <p className="mt-5 mb-6 font-bold">{`${countOutcomes} instances will be updated.`}</p>
    </div>
  );

  return (
    <Modal
      body={body}
      title="Are you sure?"
      okButton
      okButtonText="Update All"
      cancelButtonText="Cancel"
      okButtonOnClick={okButtonOnClick}
      toggleShowModal={toggleShowModal}
    />
  );
};

export default OutcomeConfirmModal;
