/* eslint-disable no-unused-expressions */
import TestSentenceVisualizer from 'components/TestSentenceVisualizer';
import React from 'react';
import TestResultsVisualizer from '../TestResultsVisualizer';

type ComponentProps = {
  width?: string;
  testPhrase?: string;
};

const TestVisualizer: React.FC<ComponentProps> = ({ width, testPhrase }) => (
  <div>
    <TestSentenceVisualizer width={width} testPhrase={testPhrase} />
    <TestResultsVisualizer />
  </div>
);

export default TestVisualizer;
