import { Tag } from '@litlingo/client';
import React from 'react';

type TagItemProps = {
  tag: Tag;
  defaultChecked: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, tag: Tag) => void;
  type?: 'checkbox' | 'radio';
  name?: string;
  disabled?: boolean;
  checked: boolean;
};

const TagItem: React.FC<TagItemProps> = ({
  tag,
  defaultChecked,
  handleChange,
  type = 'checkbox',
  name = '',
  disabled = false,
  checked,
}) => (
  <div key={tag.uuid} className="flex flex-row gap-1 items-center">
    <input
      id={tag.uuid}
      checked={checked}
      type={type}
      name={name}
      className={`${
        type === 'checkbox' ? 'form-checkbox litlingo-checkbox h-4 w-4' : 'input-radio'
      } transition duration-150 ease-in-out`}
      onChange={(e): void => {
        handleChange(e, tag);
      }}
      disabled={disabled}
      defaultChecked={defaultChecked}
    />
    <label
      htmlFor={tag.uuid}
      className={`text-xss flex-1 pl-1 leading-4 ${
        checked ? 'bg-litlingo-primary-60 bg-opacity-25' : ''
      }`}
    >
      {tag.value.charAt(0).toUpperCase() + tag.value.slice(1)}
    </label>
  </div>
);

export default TagItem;
