import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  fetchRulesDiffForCompareRequest,
  fetchRulesDiffForCompareSuccess,
  fetchRulesForCompareConfigsSuccess,
  fetchRulesForCompareRequest,
  fetchRulesForCompareSuccess,
  flipReferenceRulesCompare,
} from 'actions/rulesCompare';
import type {
  AnnotatorRelationship,
  MRule,
  MRuleConfigNode,
  NormalizedResource,
  RuleChange,
  UUID,
} from 'types';

export type RulesCompareState = {
  ruleOne: MRule | null;
  ruleTwo: MRule | null;
  configItemsRuleOne: NormalizedResource<MRuleConfigNode>;
  configItemsRuleTwo: NormalizedResource<MRuleConfigNode>;
  relationshipsOne: NormalizedResource<AnnotatorRelationship>;
  relationshipsTwo: NormalizedResource<AnnotatorRelationship>;
  rulesDiff: RuleChange[];
  loading: string[];
};

export type RulesCompareReducer<P = void> = (
  state: RulesCompareState,
  payload: PayloadAction<P>
) => RulesCompareState;

export type PayloadPatchSingle = {
  id: UUID;
};

const defaultState: RulesCompareState = {
  ruleOne: null,
  ruleTwo: null,
  configItemsRuleOne: {},
  configItemsRuleTwo: {},
  relationshipsOne: {},
  relationshipsTwo: {},
  rulesDiff: [],
  loading: [],
};

const handleFetchRulesConfigSuccess: RulesCompareReducer<{
  ruleOne: NormalizedResource<MRuleConfigNode>;
  ruleTwo: NormalizedResource<MRuleConfigNode>;
  relationshipsOne: NormalizedResource<AnnotatorRelationship>;
  relationshipsTwo: NormalizedResource<AnnotatorRelationship>;
}> = (state, { payload }) => ({
  ...state,
  configItemsRuleOne: {
    ...state.configItemsRuleOne,
    ...payload.ruleOne,
  },
  configItemsRuleTwo: {
    ...state.configItemsRuleTwo,
    ...payload.ruleTwo,
  },
  relationshipsOne: payload.relationshipsOne,
  relationshipsTwo: payload.relationshipsTwo,
});

const handleFetchRulesRequest: RulesCompareReducer = (state) => ({
  ...state,
  loading: [...state.loading, fetchRulesForCompareRequest.toString()],
});

const handleFetchRulesSuccess: RulesCompareReducer<{
  ruleOne: MRule;
  ruleTwo: MRule;
}> = (state, { payload }) => ({
  ...state,
  ruleOne: payload.ruleOne,
  ruleTwo: payload.ruleTwo,
  loading: state.loading.filter((i) => i !== fetchRulesForCompareRequest.toString()),
});

const handleFetchRulesDiffForCompareRequest: RulesCompareReducer = (state) => ({
  ...state,
  loading: [...state.loading, fetchRulesDiffForCompareRequest.toString()],
});

const handleFetchRulesDiffForCompareSuccess: RulesCompareReducer<RuleChange[]> = (
  state,
  { payload }
) => ({
  ...state,
  rulesDiff: payload,
  loading: state.loading.filter((i) => i !== fetchRulesDiffForCompareRequest.toString()),
});

const handleFlipReference: RulesCompareReducer = (state) => {
  const tempRule = state.ruleOne;
  const tempConfig = state.configItemsRuleOne;

  return {
    ...state,
    ruleOne: state.ruleTwo,
    ruleTwo: tempRule,
    configItemsRuleOne: state.configItemsRuleTwo,
    configItemsRuleTwo: tempConfig,
    rulesDiff: [],
  };
};

const handlers = {
  [fetchRulesForCompareRequest.toString()]: handleFetchRulesRequest,
  [fetchRulesForCompareSuccess.toString()]: handleFetchRulesSuccess,
  [fetchRulesDiffForCompareRequest.toString()]: handleFetchRulesDiffForCompareRequest,
  [fetchRulesDiffForCompareSuccess.toString()]: handleFetchRulesDiffForCompareSuccess,
  [fetchRulesForCompareConfigsSuccess.toString()]: handleFetchRulesConfigSuccess,
  [flipReferenceRulesCompare.toString()]: handleFlipReference,
};

const rulesCompareReducer = createReducer(defaultState, handlers);

export default rulesCompareReducer;
