import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import RulesTable from 'components/RulesTable';
import SearchInput from 'components/SearchInput';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';
import { getFetchAllRulesLoading, getRulesList } from 'selectors/rules';
import { useSelector } from 'store';
import RuleListCount from './RuleListCount';

const RulesList: React.FC = () => {
  const loading = useSelector(getFetchAllRulesLoading);
  const rules = useSelector(getRulesList);

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />
      <div className="pt-8">
        <header>
          <div className="max-w-7xl mx-auto px-6">
            <div className="flex flex-row items-center">
              <h2 className="title">Models</h2>
              {loading && <LoadingIndicator className="ml-2" size="5" />}
            </div>

            <div className="flex flex-row justify-between mt-4 flex-shrink-0">
              <SearchInput resource={resourceQueryParamName.rule} field="name" className="h-8" />
              <div className="self-end">
                <RuleListCount />
              </div>
            </div>
          </div>
        </header>
        <main>
          <div className="mt-2 mb-8 max-w-7xl mx-auto px-6 text-left">
            <RulesTable rules={rules} loading={loading} />
          </div>
        </main>
      </div>
    </div>
  );
};

export default RulesList;
