import {
  cloneAnnotator,
  cloneAnnotatorFailure,
  cloneAnnotatorRequest,
  cloneAnnotatorSuccess,
  deleteAnnotator,
  deleteAnnotatorFailure,
  deleteAnnotatorRequest,
  deleteAnnotatorSuccess,
  fetchAllAnnotators,
  fetchAllAnnotatorsFailure,
  fetchAllAnnotatorsRequest,
  fetchAllAnnotatorsSuccess,
  fetchAnnotatorTypes,
  fetchAnnotatorTypesFailure,
  fetchAnnotatorTypesRequest,
  fetchAnnotatorTypesSuccess,
  fetchLanguageMatcherTypes,
  fetchLanguageMatcherTypesFailure,
  fetchLanguageMatcherTypesRequest,
  fetchLanguageMatcherTypesSuccess,
  fetchRelationshipTypes,
  fetchRelationshipTypesFailure,
  fetchRelationshipTypesRequest,
  fetchRelationshipTypesSuccess,
  fetchSingleAnnotator,
  fetchSingleAnnotatorFailure,
  fetchSingleAnnotatorRequest,
  fetchSingleAnnotatorSuccess,
  prepareUndeleteAlert,
  saveAnnotatorFailure,
  saveAnnotatorFulfill,
  saveAnnotatorLocalFailure,
  saveAnnotatorRequest,
  saveAnnotatorSuccess,
  showErrorAlert,
  showSuccessAlert,
  upsertAnnotator,
  upsertAnnotatorDebounced,
} from 'actions';
import {
  UpsertAnnotatorPayload,
  fetchAllAnnotatorsSuccessAppend,
  fetchAnnotatorsByIds,
  fetchAnnotatorsByIdsFailure,
  fetchAnnotatorsByIdsRequest,
  fetchAnnotatorsByIdsSuccess,
  fetchAnnotatorsForFilter,
  generateTerms,
  generateTermsFailure,
  generateTermsRequest,
  generateTermsSuccess,
  replaceAnnotator,
  replaceAnnotatorFailure,
  replaceAnnotatorRequest,
  replaceAnnotatorSuccess,
} from 'actions/annotator';
import { apiClient as LitLingoClient } from 'client';
import { push } from 'connected-react-router';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { GlobalState } from 'reducers';
import type { AnnotatorState } from 'reducers/annotator';
import type { Channel } from 'redux-saga';
import { buffers } from 'redux-saga';
import { actionChannel, call, debounce, delay, put, select, takeLatest } from 'redux-saga/effects';
import { getAnnotator } from 'selectors/annotator';
import { getCustomerDomain } from 'selectors/auth';
import { getNavParams, getNavParamsByResource } from 'selectors/nav';
import type {
  API,
  AnnotatorVersion,
  LanguageMatcher,
  MLanguageMatcher,
  NormalizedAnnotator,
  RouteParams,
  SagaReturn,
  UUID,
} from 'types';
import { reverse } from 'utils/urls';

const pruneDataForApi = (
  annotatorState: AnnotatorState,
  annotatorId: UUID,
  payload: UpsertAnnotatorPayload
): AnnotatorVersion => {
  const annotator = annotatorState.items[annotatorId];
  // strip out unwanted keys from object getting sent to API b/c they will save otherwise
  const { language_matchers: languageMatchers, ...annotatorNoLanguageMatcher } = annotator;

  const newLanguageMatchers: MLanguageMatcher[] = [];
  const cleanLanguageMatchers: LanguageMatcher[] = [];
  languageMatchers.forEach((matcherId) => {
    let localError = false;
    const errorFields: string[] = [];
    const item = annotatorState.languageMatchers[matcherId];
    const itemToSave = { ...item };
    const { name } = itemToSave;
    if (!name) {
      localError = true;
    }

    const combination = annotatorState.languageMatcherTypes[itemToSave.type];
    Object.keys(combination).forEach((field) => {
      // @ts-ignore
      if (!combination[field].required) return;
      if (!(field in itemToSave)) {
        localError = true;
        errorFields.push(field);
      }
    });
    if (localError) {
      delete itemToSave.saved;
      newLanguageMatchers.push({
        ...itemToSave,
        errorFields,
        localError: 'Missing some required fields',
      });
    } else {
      newLanguageMatchers.push({ ...itemToSave, localError: false, errorFields: [] });
    }
  });

  if (newLanguageMatchers.every((el) => !el.localError)) {
    newLanguageMatchers.forEach((languageMatcher) => {
      const itemToSave = { ...languageMatcher };
      delete itemToSave.localError;
      delete itemToSave.saved;
      delete itemToSave.errorFields;
      delete itemToSave.isNew;

      cleanLanguageMatchers.push(itemToSave);
    });
  } else {
    cleanLanguageMatchers.push(...newLanguageMatchers);
  }

  const data = {
    ...annotatorNoLanguageMatcher,
    name: payload.name || annotatorNoLanguageMatcher.name,
    description: payload.description || '',
    always_save:
      payload.always_save !== undefined
        ? payload.always_save
        : annotatorNoLanguageMatcher.always_save,
    language_matchers: cleanLanguageMatchers,
  };

  return data;
};

function* upsertAnnotatorSaga(action: ReturnType<typeof upsertAnnotator>): SagaReturn {
  const { payload } = action;
  const { annotator } = (yield select()) as GlobalState;
  const {
    annotatorId,
    options_config: optionsConfig,
    showSuccess = true,
    redirect = false,
    campaignId,
    ruleId,
  } = payload;
  const annotatorData = annotatorId
    ? { ...pruneDataForApi(annotator, annotatorId, payload), options_config: optionsConfig }
    : payload;

  if (
    'language_matchers' in annotatorData &&
    // @ts-ignore
    annotatorData.language_matchers.some((el) => el.localError)
  ) {
    yield put(saveAnnotatorLocalFailure(annotatorData));
    yield put(showErrorAlert('Error - Identifier Not Saved'));
    return;
  }

  yield put(saveAnnotatorRequest());

  const response = (yield call([LitLingoClient.resources.annotators, 'upsert'], {
    data: annotatorData,
  })) as API.Response<API.Annotators.Upsert>;
  if (response.error != null) {
    yield put(saveAnnotatorFailure(response.error));
    yield put(showErrorAlert('Error - Identifier Not Saved'));
    yield put(saveAnnotatorFulfill());
    return;
  }

  yield put(saveAnnotatorSuccess(response.data));
  if (showSuccess) {
    yield put(showSuccessAlert('Identifier Saved'));
  }

  if (action.type === upsertAnnotatorDebounced.toString()) yield delay(500);
  yield put(saveAnnotatorFulfill());

  if (redirect) {
    const customerDomain = (yield select(getCustomerDomain)) as string;
    const path = reverse({
      routeName: 'annotator',
      routeParams: {
        annotatorId: response.data.uuid,
        campaignId: campaignId || '',
        ruleId: ruleId || '',
      },
      customerDomain,
    });
    yield put(push(path));
  }
}

function* upsertAnnotatorChannelDebounced(): SagaReturn {
  const chan = (yield actionChannel(
    upsertAnnotatorDebounced.toString(),
    buffers.sliding(1)
  )) as Channel<ReturnType<typeof upsertAnnotatorDebounced>>;

  yield debounce(400, chan, upsertAnnotatorSaga);
}

function* fetchLanguageMatcherTypesSaga(): SagaReturn {
  yield put(fetchLanguageMatcherTypesRequest());
  const response = (yield call(
    [LitLingoClient.resources.annotators.extras, 'languageMatchers'],
    {}
  )) as API.Response<API.Annotators.LanguageMatchers>;
  if (response.error != null) {
    yield put(fetchLanguageMatcherTypesFailure(response.error));
  } else {
    yield put(fetchLanguageMatcherTypesSuccess(response.data));
  }
}

function* fetchRelationshipTypesSaga(): SagaReturn {
  yield put(fetchRelationshipTypesRequest());
  const response = (yield call(
    [LitLingoClient.resources.annotators.extras, 'relationshipTypes'],
    {}
  )) as API.Response<API.Annotators.RelationshipTypes>;
  if (response.error != null) {
    yield put(fetchRelationshipTypesFailure(response.error));
  } else {
    yield put(fetchRelationshipTypesSuccess(response.data));
  }
}

function* deleteAnnotatorSaga(action: ReturnType<typeof deleteAnnotator>): SagaReturn {
  const { payload } = action;
  const { annotatorId, redirect = false } = payload;
  yield put(deleteAnnotatorRequest());
  const response = (yield call(
    [LitLingoClient.resources.annotators, 'delete'],
    annotatorId
  )) as API.Response<API.Annotators.Delete>;
  if (response.error != null) {
    yield put(deleteAnnotatorFailure(response.error));
  } else {
    yield put(deleteAnnotatorSuccess(annotatorId));
    if (redirect) {
      const customerDomain = (yield select(getCustomerDomain)) as string;
      const path = reverse({ routeName: 'annotator-list', customerDomain });
      yield put(push(path));
    }
    yield put(
      prepareUndeleteAlert({
        resource: 'annotators',
        id: annotatorId,
        fetchAction: fetchAllAnnotators,
      })
    );
  }
}

function* cloneAnnotatorSaga(action: ReturnType<typeof cloneAnnotator>): SagaReturn {
  const { payload } = action;
  yield put(cloneAnnotatorRequest());
  const { resourceId: annotatorId, customerId } = payload;

  const response = (yield call([LitLingoClient.resources.annotators.extras, 'cloneAnnotator'], {
    urlParams: { annotatorId, customerId },
  })) as API.Response<API.Annotators.CloneAnnotator>;

  if (response.error != null) {
    yield put(cloneAnnotatorFailure(response.error));
  } else {
    yield put(showSuccessAlert('Indicator Duplicated'));
    yield put(cloneAnnotatorSuccess(response.data));
  }
}

function* fetchAnnotatorsByIdsSaga(action: ReturnType<typeof fetchAnnotatorsByIds>): SagaReturn {
  const { annotatorIds } = action.payload;

  const params = { uuids: annotatorIds, include_pii: true, limit: '-1' };
  yield put(fetchAnnotatorsByIdsRequest());

  const response = (yield call([LitLingoClient.resources.annotators, 'list'], {
    params,
  })) as API.Response<API.Annotators.List>;
  if (response.error != null) {
    yield put(fetchAnnotatorsByIdsFailure(response.error));
  } else {
    yield put(fetchAnnotatorsByIdsSuccess(response.data));
  }
}

function* fetchAllAnnotatorsSaga(action: ReturnType<typeof fetchAllAnnotators>): SagaReturn {
  const { payload = {} } = action;
  const { name = '' } = payload;
  const { limit = '' } = payload;
  const { searchValue = '' } = payload;

  yield put(fetchAllAnnotatorsRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.annotator)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const navParams = (yield select(getNavParams)) as ReturnType<typeof getNavParams>;

  const params: RouteParams = {
    ...navParams,
    ...resourceParams,
    ...payload,
    ...{ name: resourceParams.name ? resourceParams.name : name },
    ...{ limit: resourceParams.limit ? resourceParams.limit : limit },
    ...{ broad_search: resourceParams.broad_search ? resourceParams.broad_search : searchValue },
    relationships: [
      'rule_annotators.rule',
      'rule_annotators.rule.campaign_rule_outcomes',
      'rule_annotators.rule.campaign_rule_outcomes.campaign',
      'updated_by',
    ],
    include_pii: 'true',
  };

  if (params.order_desc !== 'true') {
    delete params.order_desc;
  }
  const response = (yield call([LitLingoClient.resources.annotators, 'list'], {
    params,
  })) as API.Response<API.Annotators.List>;
  if (response.error != null) {
    yield put(fetchAllAnnotatorsFailure(response.error));
  } else {
    yield put(fetchAllAnnotatorsSuccess(response.data));
  }
}

function* fetchSingleAnnotatorSaga(action: ReturnType<typeof fetchSingleAnnotator>): SagaReturn {
  const { payload } = action;
  const { annotatorId } = payload;

  const annotator = (yield select((state) =>
    getAnnotator(state, annotatorId)
  )) as NormalizedAnnotator;
  const annotatorState = (yield select((state) => state.annotator)) as AnnotatorState;

  if (annotator && annotatorState.unsavedChanges) {
    return;
  }

  yield put(fetchSingleAnnotatorRequest());
  const response = (yield call(
    [LitLingoClient.resources.annotators, 'retrieve'],
    annotatorId
  )) as API.Response<API.Annotators.Retrieve>;

  if (response.error != null) {
    yield put(fetchSingleAnnotatorFailure(response.error));
  } else {
    yield put(fetchSingleAnnotatorSuccess(response.data));
  }
}

function* fetchAnnotatorTypesSaga(): SagaReturn {
  yield put(fetchAnnotatorTypesRequest());
  const response = (yield call([
    LitLingoClient.resources.annotators.extras,
    'types',
  ])) as API.Response<API.Annotators.Types>;
  if (response.error != null) {
    yield put(fetchAnnotatorTypesFailure(response.error));
  } else {
    yield put(fetchAnnotatorTypesSuccess(response.data));
  }
}

function* replaceAnnotatorSaga(action: ReturnType<typeof replaceAnnotator>): SagaReturn {
  const { payload } = action;
  yield put(replaceAnnotatorRequest());
  const { source, targets } = payload;

  const data = {
    source_uuid: source,
    target_uuids: targets,
  };

  const response = (yield call([LitLingoClient.resources.annotators.extras, 'replaceAnnotator'], {
    data,
  })) as API.Response<API.Annotators.ReplaceAnnotator>;

  if (response.error != null) {
    yield put(replaceAnnotatorFailure(response.error));
  } else {
    const annotator = (yield select((state) => getAnnotator(state, source))) as ReturnType<
      typeof getAnnotator
    >;
    yield put(
      showSuccessAlert(
        `Successfully using ${annotator.name} (v${annotator.version}) as the main identifier.`
      )
    );
    yield put(replaceAnnotatorSuccess());
  }
}

function* fetchAnnotatorsForFilterSaga(
  action: ReturnType<typeof fetchAnnotatorsForFilter>
): SagaReturn {
  const { payload } = action;
  const { searchValue = '', limit = 25, offset = 0 } = payload;
  const params = {
    include_pii: 'true',
    broad_search: searchValue,
    limit,
    offset,
    include_count: true,
  };
  yield put(fetchAllAnnotatorsRequest());

  const response = (yield call([LitLingoClient.resources.annotators, 'list'], {
    params,
  })) as API.Response<API.Annotators.List>;
  if (response.error != null) {
    yield put(fetchAllAnnotatorsFailure(response.error));
  } else if (params.offset !== undefined && params.offset === 0) {
    yield put(fetchAllAnnotatorsSuccess(response.data));
  } else {
    yield put(fetchAllAnnotatorsSuccessAppend(response.data));
  }
}

function* generateTermsSaga(action: ReturnType<typeof generateTerms>): SagaReturn {
  const { includedTerms } = action.payload;

  const data = {
    existing_terms: includedTerms,
  };

  yield put(generateTermsRequest());

  const response = (yield call([LitLingoClient.resources.annotators.extras, 'generateTerms'], {
    data,
  })) as API.Response<API.Annotators.GenerateTerms>;

  if (response.error != null) {
    yield put(generateTermsFailure(response.error));
  } else {
    yield put(generateTermsSuccess(response.data.suggestions));
  }
}

function* annotatorSaga(): SagaReturn {
  yield takeLatest(fetchRelationshipTypes.toString(), fetchRelationshipTypesSaga);
  yield takeLatest(fetchLanguageMatcherTypes.toString(), fetchLanguageMatcherTypesSaga);
  yield takeLatest(upsertAnnotator.toString(), upsertAnnotatorSaga);
  yield takeLatest(deleteAnnotator.toString(), deleteAnnotatorSaga);
  yield takeLatest(fetchAllAnnotators.toString(), fetchAllAnnotatorsSaga);
  yield takeLatest(fetchAnnotatorsByIds.toString(), fetchAnnotatorsByIdsSaga);
  yield takeLatest(fetchSingleAnnotator.toString(), fetchSingleAnnotatorSaga);
  yield takeLatest(fetchAnnotatorTypes.toString(), fetchAnnotatorTypesSaga);
  yield takeLatest(cloneAnnotator.toString(), cloneAnnotatorSaga);
  yield takeLatest(replaceAnnotator.toString(), replaceAnnotatorSaga);
  yield takeLatest(fetchAnnotatorsForFilter.toString(), fetchAnnotatorsForFilterSaga);
  yield takeLatest(generateTerms.toString(), generateTermsSaga);

  // @ts-expect-error check types
  yield upsertAnnotatorChannelDebounced();
}

export default annotatorSaga;
