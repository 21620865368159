import React, { useEffect, useState } from 'react';
import type {
  AnnotatedGraph,
  Arrow,
  LineArrows,
  VisualizationType,
} from '../types';
import { buildWidths } from '../utils/visualizer';
import DocumentVizAnnotations from './DocumentVizAnnotations';
import DocumentVizRelationships from './DocumentVizRelationships';
import { IconLogo } from './Logo';

type DocumentVizProps = {
  annotationsBasePath?: string;
  lines: AnnotatedGraph[];
  arrows: LineArrows[];
};

type RowsObject = {
  texts: JSX.Element[];
  annotators: JSX.Element[];
  partsOfSpeech: JSX.Element[];
  lemmas: JSX.Element[];
  entities: JSX.Element[];
  tenses: JSX.Element[];
  negated: JSX.Element[];
  suggestions: JSX.Element[];
  modesOfSpeech: JSX.Element[];
};

const DocumentViz: React.FC<DocumentVizProps> = (props) => {
  const { annotationsBasePath, lines, arrows } = props;

  const [geekMode, setGeekMode] = useState<VisualizationType>('basic_mode');
  const [widths, setWidths] = useState(() => buildWidths(lines));
  const [topArrows, setTopArrows] = useState<
    (Arrow & { lineArrowIndex: number })[]
  >([]);
  const [bottomArrows, setBottomArrows] = useState<
    (Arrow & { lineArrowIndex: number })[]
  >([]);
  // if lines change the widths array needs to be cleaned in order to add all
  // the new widths
  useEffect(() => setWidths(buildWidths(lines)), [lines]);

  useEffect(() => {
    const pairs: number[][] = [];
    const tArrows: (Arrow & { lineArrowIndex: number })[] = [];
    const bArrows: (Arrow & { lineArrowIndex: number })[] = [];

    arrows.forEach((lineArrows) => {
      lineArrows.arrows
        .filter((arrow) => {
          if (geekMode === 'basic_mode') return arrow.type === 'relationship';
          if (geekMode === 'dependent_mode') return arrow.type === 'dependent';
          return arrow.type === 'edge';
        })
        .forEach((arrow) => {
          const min = Math.min(arrow.head, arrow.root);
          const max = Math.max(arrow.head, arrow.root);
          let crossing = false;
          pairs.forEach((pair) => {
            if (pair[0] < max && pair[0] > min) {
              if (pair[1] > max || pair[1] < min) {
                crossing = true;
              }
            }
            if (pair[1] < max && pair[1] > min) {
              if (pair[0] > max || pair[0] < min) {
                crossing = true;
              }
            }
          });
          if (crossing) {
            bArrows.push({ ...arrow, lineArrowIndex: lineArrows.index });
          } else {
            tArrows.push({ ...arrow, lineArrowIndex: lineArrows.index });
            pairs.push([arrow.head, arrow.root]);
          }
        });
    });

    setTopArrows(tArrows);
    setBottomArrows(bArrows);
  }, [arrows, geekMode]);

  const textsRef = (ref: HTMLTableDataCellElement | null): void => {
    if (ref == null) {
      return;
    }

    const [, line, , x] = ref.id.split('-');
    const lineIdx = parseInt(line, 10);
    const xIndex = parseInt(x, 10);

    // callback mode needs to be used here to always have an updated version of
    // the widths array
    setWidths((currentWidths) => {
      if (
        currentWidths[lineIdx] == null ||
        currentWidths[lineIdx][xIndex] !== 0
      ) {
        return currentWidths;
      }

      return {
        ...currentWidths,
        [lineIdx]: {
          ...currentWidths[lineIdx],
          [xIndex]: ref.getBoundingClientRect().width,
        },
      };
    });
  };

  const handleGeekModeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => setGeekMode(event.target.value as VisualizationType);

  const handleShowMoreClick = (): void => setWidths(buildWidths(lines));

  const trClasses = 'border-b border-gray-400 hover:cursor-default';
  const thClasses =
    'sticky bg-gray-50 p-4 pr-16 font-semibold left-0 text-left';
  const tdClasses = 'text-center p-4';

  const rows: RowsObject = {
    texts: [],
    annotators: [],
    partsOfSpeech: [],
    modesOfSpeech: [],
    lemmas: [],
    entities: [],
    tenses: [],
    negated: [],
    suggestions: [],
  };
  let xIndex = 0;
  lines.forEach((line) => {
    line.nodes.forEach((superNode) => {
      const { annotations, index, nodes } = superNode;

      rows.annotators.push(
        <td
          aria-hidden
          key={`annotations-${nodes[0].text}-${line.index}-${index}-${xIndex}`}
          colSpan={superNode.nodes.length}
          className={`align-top ${tdClasses}`}
        >
          <DocumentVizAnnotations
            annotationsBasePath={annotationsBasePath as string}
            annotations={annotations}
            index={index}
            onShowMoreClick={handleShowMoreClick}
          />
        </td>
      );
      superNode.nodes.forEach((node) => {
        rows.texts.push(
          <td
            key={`text-${line.index}-${node.index}-${xIndex}`}
            ref={textsRef}
            className={tdClasses}
            id={`node-${line.index}-${node.index}-${xIndex}`}
          >
            <span className="font-bold">{node.text}</span>
          </td>
        );
        rows.partsOfSpeech.push(
          <td
            key={`pos-${line.index}-${node.index}-${xIndex}`}
            className={tdClasses}
          >
            {node.pos}
          </td>
        );
        rows.modesOfSpeech.push(
          <td
            key={`pos-${line.index}-${node.index}-${xIndex}`}
            className={tdClasses}
          >
            {node.mos}
          </td>
        );
        rows.lemmas.push(
          <td
            key={`lemma-${line.index}-${node.index}-${xIndex}`}
            className={tdClasses}
          >
            {node.lemma}
          </td>
        );
        rows.entities.push(
          <td
            key={`entity-${line.index}-${node.index}-${xIndex}`}
            className={tdClasses}
          >
            <span className="capitalize">{node.entity}</span>
          </td>
        );
        rows.tenses.push(
          <td
            key={`tense-${line.index}-${node.index}-${xIndex}`}
            className={tdClasses}
          >
            {node.tense}
          </td>
        );
        rows.negated.push(
          <td
            key={`negated-${line.index}-${node.index}-${xIndex}`}
            className={tdClasses}
          >
            {node.negated ? 'true' : 'false'}
          </td>
        );
        rows.suggestions.push(
          <td
            key={`suggestions-${line.index}-${node.index}-${xIndex}`}
            className={tdClasses}
          >
            {node.suggestions && node.suggestions.join(', ')}
          </td>
        );
        xIndex += 1;
      });
    });
  });

  return (
    <div className="w-full">
      <div className="relative overflow-auto whitespace-no-wrap">
        <table className="w-full">
          <tbody>
            <tr className={trClasses}>
              <th className={thClasses}>
                <div>
                  <div>Relationships</div>
                  <label
                    htmlFor="geek_mode"
                    className="block text-sm leading-5 font-normal text-gray-600"
                  >
                    <div className="flex items-start flex-col justify-center">
                      <div className="flex justify-center items-center my-1">
                        <input
                          id="basic_mode"
                          type="radio"
                          name="mode"
                          value="basic_mode"
                          className="h-4 w-4 form-radio text-litlingo-success transition duration-150 ease-in-out"
                          onChange={handleGeekModeChange}
                          checked={geekMode === 'basic_mode'}
                        />
                        <span className="ml-2">Basic</span>
                      </div>
                      <div className="flex justify-center items-center my-1">
                        <input
                          id="dependent_mode"
                          type="radio"
                          name="mode"
                          value="dependent_mode"
                          className="h-4 w-4 form-radio text-litlingo-success transition duration-150 ease-in-out"
                          onChange={handleGeekModeChange}
                          checked={geekMode === 'dependent_mode'}
                        />
                        <span className="ml-2">Dependent clauses</span>
                      </div>
                      <div className="flex justify-center items-center my-1">
                        <input
                          id="advanced_mode"
                          type="radio"
                          name="mode"
                          value="advanced_mode"
                          className="h-4 w-4 form-radio text-litlingo-success transition duration-150 ease-in-out"
                          onChange={handleGeekModeChange}
                          checked={geekMode === 'advanced_mode'}
                        />
                        <span className="ml-2">Advanced NLP</span>
                      </div>
                    </div>
                  </label>
                </div>
              </th>
              <td colSpan={rows.texts.length} aria-hidden>
                <DocumentVizRelationships
                  geekMode={geekMode}
                  widths={widths}
                  arrows={topArrows}
                />
              </td>
            </tr>

            <tr className={trClasses}>
              <th className={thClasses} aria-label="Text" />
              {rows.texts}
            </tr>
            {bottomArrows.length > 0 && (
              <tr className={trClasses}>
                <th className={thClasses} aria-label="Text" />
                <td colSpan={rows.texts.length} aria-hidden>
                  <DocumentVizRelationships
                    geekMode={geekMode}
                    widths={widths}
                    arrows={bottomArrows}
                    flipped
                  />
                </td>
              </tr>
            )}
            <tr className={trClasses}>
              <th className={thClasses}>
                <div className="flex items-center">
                  <div className="h-6 mr-1">
                    <IconLogo />
                  </div>
                  <span>Litlingo Identifier</span>
                </div>
              </th>
              {rows.annotators}
            </tr>

            <tr className={trClasses}>
              <th className={thClasses}>Part of speech</th>
              {rows.partsOfSpeech}
            </tr>

            <tr className={trClasses}>
              <th className={thClasses}>Mode of speech</th>
              {rows.modesOfSpeech}
            </tr>

            <tr className={trClasses}>
              <th className={thClasses}>Lemma (root)</th>
              {rows.lemmas}
            </tr>

            <tr className={trClasses}>
              <th className={thClasses}>Entity</th>
              {rows.entities}
            </tr>

            <tr className={trClasses}>
              <th className={thClasses}>Tense</th>
              {rows.tenses}
            </tr>

            <tr className={trClasses}>
              <th className={thClasses}>Negated</th>
              {rows.negated}
            </tr>

            <tr className={trClasses}>
              <th className={thClasses}>Suggestions</th>
              {rows.suggestions}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
DocumentViz.defaultProps = {
  annotationsBasePath: '/annotator',
};

export default DocumentViz;
