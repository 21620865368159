import type { MRuleConfig, MRuleConfigNode } from 'types';

const isSameSentenceApplicable = (
  itemToMove: MRuleConfig | MRuleConfigNode,
  potentialTarget: MRuleConfig | MRuleConfigNode
): boolean => {
  if (potentialTarget.name !== 'RELATIONSHIP_MATCH') {
    return true;
  }
  if (itemToMove.name === 'AND' || itemToMove.name === 'OR') {
    return false;
  }
  return true;
};

export default isSameSentenceApplicable;
