/* eslint-disable camelcase */

import {
  fetchRulesDiffForCompare,
  fetchRulesDiffForCompareFailure,
  fetchRulesDiffForCompareRequest,
  fetchRulesDiffForCompareSuccess,
  fetchRulesForCompare,
  fetchRulesForCompareConfigsSuccess,
  fetchRulesForCompareFailure,
  fetchRulesForCompareRequest,
  fetchRulesForCompareSuccess,
} from 'actions/rulesCompare';
import { apiClient as LitLingoClient } from 'client';
import { normalizeRuleConfig } from 'reducers/util/flattenConfigData';
import { call, put, takeEvery } from 'redux-saga/effects';
import type { API, SagaReturn } from 'types';
import { v4 as uuidv4 } from 'uuid';

function* fetchRulesForCompareSaga({
  payload,
}: ReturnType<typeof fetchRulesForCompare>): SagaReturn {
  yield put(fetchRulesForCompareRequest());

  const { customerOneId, customerTwoId, ruleOneId, ruleTwoId } = payload;

  const ruleOneResponse = (yield call([LitLingoClient.resources.rules, 'list'], {
    params: {
      uuids: [ruleOneId],
      customer_uuid: customerOneId,
      relationships: ['annotators', 'annotators.annotator', 'updated_by', 'customer'],
      include_pii: true,
    },
  })) as API.Response<API.Rules.List>;

  const ruleTwoResponse = (yield call([LitLingoClient.resources.rules, 'list'], {
    params: {
      uuids: [ruleTwoId],
      customer_uuid: customerTwoId,
      relationships: ['annotators', 'annotators.annotator', 'updated_by', 'customer'],
      include_pii: true,
    },
  })) as API.Response<API.Rules.List>;

  if (ruleOneResponse.error != null) {
    yield put(fetchRulesForCompareFailure(ruleOneResponse.error));
  }
  if (ruleTwoResponse.error != null) {
    yield put(fetchRulesForCompareFailure(ruleTwoResponse.error));
  } else if (ruleOneResponse.error === null && ruleTwoResponse.error === null) {
    const ruleOne = ruleOneResponse.data.records[0];
    const ruleTwo = ruleTwoResponse.data.records[0];

    const rootConfigIdOne = ruleOne.config.uuid || uuidv4();
    const rootConfigIdTwo = ruleTwo.config.uuid || uuidv4();

    const configOne = {
      ...ruleOne.config,
      rootConfigId: rootConfigIdOne,
    };

    const configTwo = {
      ...ruleTwo.config,
      rootConfigId: rootConfigIdTwo,
    };

    yield put(
      fetchRulesForCompareSuccess({
        ruleOne: { ...ruleOne, rootConfigId: rootConfigIdOne },
        ruleTwo: { ...ruleTwo, rootConfigId: rootConfigIdTwo },
      })
    );

    const { ruleConfig: ruleConfigOne, relationships: relationshipsOne } = normalizeRuleConfig({
      config: configOne,
      rule: ruleOne,
    });

    const { ruleConfig: ruleConfigTwo, relationships: relationshipsTwo } = normalizeRuleConfig({
      config: configTwo,
      rule: ruleTwo,
    });

    yield put(
      fetchRulesForCompareConfigsSuccess({
        ruleOne: ruleConfigOne,
        ruleTwo: ruleConfigTwo,
        relationshipsOne,
        relationshipsTwo,
      })
    );

    yield put(
      fetchRulesDiffForCompare({
        ruleOneId,
        ruleTwoId,
        customerOneId,
        customerTwoId,
      })
    );
  }
}

function* fetchRulesDiffForCompareSaga({
  payload,
}: ReturnType<typeof fetchRulesDiffForCompare>): SagaReturn {
  yield put(fetchRulesDiffForCompareRequest());

  const {
    ruleOneId: rule_1_uuid,
    ruleTwoId: rule_2_uuid,
    customerOneId: customer_uuid_rule_1,
    customerTwoId: customer_uuid_rule_2,
  } = payload;

  const response = (yield call([LitLingoClient.resources.rules.extras, 'diffRules'], {
    data: { rule_1_uuid, rule_2_uuid, customer_uuid_rule_1, customer_uuid_rule_2 },
  })) as API.Response<API.Rules.DiffRules>;

  if (response.error != null) {
    yield put(fetchRulesDiffForCompareFailure(response.error));
  } else {
    yield put(fetchRulesDiffForCompareSuccess(response.data.changes));
  }
}

function* rulesCompareSaga(): SagaReturn {
  yield takeEvery(fetchRulesForCompare, fetchRulesForCompareSaga);
  yield takeEvery(fetchRulesDiffForCompare, fetchRulesDiffForCompareSaga);
}

export default rulesCompareSaga;
