/* eslint-disable camelcase */
import type { DefaultRawDatum } from '@nivo/pie';
import { ResponsivePie } from '@nivo/pie';
import React from 'react';
import type { TestCasesSummary } from 'types';

type ComponentProps = {
  summary: Omit<TestCasesSummary, 'false_positives'>;
  className?: string;
  size?: number;
  fontSize?: number;
  returnPlaceholder?: boolean;
};

const TestCasePieChart: React.FC<ComponentProps> = ({
  summary,
  className,
  size = 50,
  fontSize = 12,
  returnPlaceholder,
}) => {
  const { passing, failing, not_run } = summary;
  const total = passing + failing + not_run;
  if (total === 0) {
    if (returnPlaceholder) {
      return <div />;
    }
    return null;
  }
  const totalGood = ((100 * passing) / total).toFixed(0);
  const margin = { top: 0, right: 0, bottom: 0, left: 0 };
  const data = [
    {
      id: 'passing',
      value: passing,
    },
    {
      id: 'failing',
      value: failing,
    },
  ];
  const colors: { [name: string]: string } = { passing: '#16B126', failing: '#F8351A' };
  const getColor = (bar: DefaultRawDatum): string => colors[bar.id];

  return (
    <div
      className={`relative text-center ${className}`}
      style={{
        width: size,
        height: size,
      }}
    >
      <ResponsivePie
        margin={margin}
        data={data}
        innerRadius={0.7}
        colors={getColor}
        enableArcLabels={false}
      />
      <div
        className="absolute flex flex-col items-center justify-center text-center text-heading-3"
        style={{
          ...margin,
          fontSize: totalGood === '100' ? `${fontSize}px` : `${fontSize + 2}px`,
        }}
      >
        <span>{`${totalGood}%`}</span>
      </div>
    </div>
  );
};

export default TestCasePieChart;
