import { TestCase } from '@litlingo/client';
import {
  addTestCaseToBranch,
  removeTestCaseFromBranch,
  updateTestCasesMatch,
  upsertTestCaseAndAddToBranch,
} from 'actions';
import useFetchTestCases from 'hooks/testCases/useFetchTestCases';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getSelectedNode } from 'selectors/config';
import { getConfigRuleAsArray } from 'selectors/rule';
import {
  getAddToBranchLoading,
  getRemoveFromBranchLoading,
  getTestCases,
  getTestCasesTotalCount,
} from 'selectors/testCases';
import { useDeepCompareSelector, useSelector } from 'store';
import RuleManagerAddNewSidebar from './RuleManagerAddNewSideBar';
import RuleManagerTestSuiteSidebarList from './RuleManagerTestSuiteSidebarList';

type RuleManagerSidebarContentProps = {
  ruleId: string;
};

const RuleManagerSidebarContent: React.FC<RuleManagerSidebarContentProps> = (props) => {
  const { ruleId } = props;
  const dispatch = useDispatch();
  const testCases = useDeepCompareSelector(getTestCases);
  const testCasesCount = useSelector(getTestCasesTotalCount);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [addModal, setAddModal] = React.useState<boolean>(false);
  const [shouldMatch, setShouldMatch] = React.useState<boolean | null>(null);
  const [testString, setTestString] = React.useState<string>('');
  const [search, setSearch] = React.useState<string>('');
  const [errors, setErrors] = React.useState<{ [key: string]: { message: string } }>({});
  const [searchItem, setSearchItem] = React.useState<TestCase | null>(null);
  const selectedNode = useSelector(getSelectedNode);
  const addBranchesLoadingState = useSelector(getAddToBranchLoading);
  const removeBranchesLoadingState = useSelector(getRemoveFromBranchLoading);
  const arrayTree = useSelector(getConfigRuleAsArray);
  const { data, isLoading } = useFetchTestCases(search);

  const handleChangeSelections = (name: string): void => {
    if (selected.includes(name)) {
      setSelected(selected.filter((item) => item !== name));
    } else {
      setSelected([...selected, name]);
    }
  };

  const handleChangeShouldMatch = (value: boolean): void => {
    if (shouldMatch === value) {
      setShouldMatch(null);
      return;
    }
    setShouldMatch(value);
  };

  const handleChangeRowMatch = (value: boolean): void => {
    dispatch(updateTestCasesMatch({ ids: selected, shouldMatch: value }));
    setSelected([]);
  };

  const addNewTestCase = (): void => {
    if (addBranchesLoadingState) return;

    if (shouldMatch === null) {
      setErrors({ ...errors, shouldMatch: { message: 'This field is required' } });
      return;
    }

    if (!testString) {
      setErrors({ ...errors, testString: { message: 'This field is required' } });
      return;
    }

    dispatch(
      upsertTestCaseAndAddToBranch({
        test: {
          test_string: testString,
          should_match: shouldMatch,
          rule_uuid: ruleId,
          platform: 'all',
        },
        ruleId,
        branchId: selectedNode && selectedNode > 0 ? arrayTree[selectedNode as number].id : '',
        refetch: true,
      })
    );

    setTestString('');
    setShouldMatch(null);
    setErrors({});
    setSearchItem(null);
    setSearch('');
    setAddModal(false);
  };

  const handleAddFromSameModel = (selectedTests: string[]): void => {
    dispatch(
      addTestCaseToBranch({
        testCaseIds: selectedTests,
        refetch: true,
        ruleId,
        branchId:
          selectedNode && selectedNode > 0 ? arrayTree[selectedNode as number].id : arrayTree[0].id,
      })
    );
    setAddModal(false);
  };

  const handleUnlink = (): void => {
    if (!selectedNode || selectedNode === -1 || removeBranchesLoadingState) return;
    dispatch(
      removeTestCaseFromBranch({
        testCaseIds: selected,
        refetch: true,
        ruleId,
        branchId: arrayTree[selectedNode as number].id,
      })
    );
    setSelected([]);
  };

  const handleSelectSearchItem = (item: TestCase): void => {
    dispatch(
      upsertTestCaseAndAddToBranch({
        test: item,
        branchId: selectedNode && selectedNode > 0 ? arrayTree[selectedNode as number].id : '',
        ruleId,
      })
    );

    setTestString('');
    setShouldMatch(null);
    setErrors({});
    setSearchItem(null);
    setSearch('');
    setAddModal(false);
  };

  React.useEffect(() => {
    setSelected([]);
  }, [selectedNode]);

  return addModal ? (
    <RuleManagerAddNewSidebar
      addNewTestCase={addNewTestCase}
      testString={testString}
      setTestString={setTestString}
      shouldMatch={shouldMatch}
      errors={errors}
      search={search}
      setSearch={setSearch}
      handleSelectSearchItem={handleSelectSearchItem}
      searchItem={searchItem}
      data={data}
      isLoading={isLoading}
      handleChangeShouldMatch={handleChangeShouldMatch}
      setAddModal={setAddModal}
      setSearchItem={setSearchItem}
      nodeSelected={selectedNode}
      addBranchesLoadingState={addBranchesLoadingState}
      testCases={testCases}
      handleAddFromSameModel={handleAddFromSameModel}
    />
  ) : (
    <>
      <div
        className="flex items-center pl-4 pr-2.5 py-2.5 bg-litlingo-green-light min-h-13"
        role="button"
        aria-hidden="true"
        data-testid="collapse-button"
      >
        <div className="w-full flex flex-row justify-between items-center gap-3">
          <div className="text-heading-3 overflow-visible">Test Cases ({testCasesCount})</div>

          {selected.length === 0 && (
            <button
              type="button"
              onClick={(): void => setAddModal(true)}
              className="button button--secondary font-bold h-8"
            >
              Add new
            </button>
          )}
        </div>
      </div>
      <RuleManagerTestSuiteSidebarList
        handleChangeRowMatch={handleChangeRowMatch}
        handleChangeSelections={handleChangeSelections}
        handleUnlink={handleUnlink}
        selected={selected}
        selectedNode={selectedNode}
        setSelected={setSelected}
        testCases={testCases}
        removeBranchesLoadingState={removeBranchesLoadingState}
      />
    </>
  );
};

export default RuleManagerSidebarContent;
