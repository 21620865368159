import type { PartialConfig } from './base';

const API_BASE = 'http://127.0.0.1:5000';

const local: PartialConfig = {
  api: {
    base: API_BASE,
    v1: `${API_BASE}/api/v1`,
    v2: `${API_BASE}/api/v2`,
    integrations: `${API_BASE}/api/v1/integrations`,
    customers: `${API_BASE}/api/v2/users/me/customers`,
    user: `${API_BASE}/api/v2/users/me`,
  },
};

export default local;
