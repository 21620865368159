/* eslint-disable camelcase */
import * as Sentry from '@sentry/react';
import { saveRuleRequest } from 'actions/rule';
import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import type {
  AnnotatorRelationship,
  ModeOfSpeech,
  MRule,
  MRuleConfig,
  MRuleConfigNode,
  MRuleConfigRelationship,
  NormalizedResource,
  Rule,
  RuleAnnotator,
  Selector,
  UUID,
} from 'types';

export const getRule =
  (ruleId: UUID): Selector<MRule> =>
  (state): MRule =>
    state.rule.rules[ruleId];

export const getRuleAnnotators: Selector<RuleAnnotator[] | null> = (state) => {
  const { pathname } = state.router.location;

  const spec = matchPath<{ ruleId: string }>(pathname, {
    path: ['/:customerDomain/rule/:ruleId', '/:customerDomain/model/:ruleId'],
    exact: false,
  });

  if (spec && spec.params) {
    return state.rule.rules[spec.params.ruleId]?.annotators || null;
  }

  return null;
};

export const getRuleConfigId: Selector<string | null> = (state) => {
  const { pathname } = state.router.location;

  const spec = matchPath<{ ruleId: string }>(pathname, {
    path: ['/:customerDomain/rule/:ruleId', '/:customerDomain/model/:ruleId'],
    exact: false,
  });

  if (spec && spec.params) {
    return state.rule.rules[spec.params.ruleId]?.rootConfigId || null;
  }

  return null;
};

export const getConfigRuleAsArray: Selector<MRuleConfig[]> = createSelector(
  [
    (state): NormalizedResource<MRuleConfigNode> => state.config.items,
    (state): NormalizedResource<AnnotatorRelationship> => state.relationship,
    getRuleAnnotators,
    getRuleConfigId,
  ],
  (config, relationshipState, annotators, rootConfigId) => {
    const traverseTree = (curIdNode: UUID, level: number, parent: UUID[]): MRuleConfig[] => {
      const curNode = config[curIdNode];
      if (!curNode) {
        return [];
      }
      const { id, name, description, color } = curNode;

      let negated;
      let mode_of_speech: ModeOfSpeech | undefined;
      let annotatorId: UUID | undefined;
      let modifiers;
      let nodeGroups;

      if (curNode.typeOfConfig === 'ANNOTATION_MATCH') {
        mode_of_speech = curNode.mode_of_speech;
        negated = curNode.negated;
        annotatorId = curNode.annotatorId;
      } else {
        modifiers = curNode.modifiers;
        nodeGroups = curNode.groups;
      }
      const relationship: MRuleConfigRelationship[] = [];

      if (curNode.parent != null) {
        const parentNode = config[curNode.parent];
        const curRelationship = 'relationship' in parentNode ? parentNode.relationship : null;

        if (curRelationship != null) {
          curRelationship.forEach((key) => {
            const cur = relationshipState[key];

            if (cur && cur.annotation_a != null && cur.annotation_b != null) {
              const annotatorAId = cur.annotation_a.id;
              const annotatorBId = cur.annotation_b.id;

              if (annotatorAId === id && config[annotatorBId]) {
                const configB = config[annotatorBId];
                if (annotators != null && 'annotatorId' in configB) {
                  const actualAnnotator = annotators.find(
                    (element) => element.annotator_uuid === configB.annotatorId
                  );
                  relationship.push({
                    id: cur.id,
                    name: `${cur.type} ${actualAnnotator?.annotator?.name}`,
                    deleted: !!(actualAnnotator && actualAnnotator.annotator?.deleted_at != null),
                  });
                }
              }
            }
          });
        }
      }

      // FIXME: Create a function to process the annotator name
      let ruleName = '';
      let deleted = false;
      if (!annotators) {
        ruleName = name;
      } else if (annotators != null) {
        const actualAnnotator = annotators.find(
          (element) => element.annotator_uuid === annotatorId
        );
        if (actualAnnotator && annotatorId) {
          if (actualAnnotator && actualAnnotator.annotator == null) {
            ruleName = `Identifier doesn't exist ${annotatorId}`;
            deleted = true;
          } else if (actualAnnotator.annotator?.deleted_at == null) {
            ruleName = actualAnnotator.annotator?.name ?? '';
          } else {
            ruleName = actualAnnotator.annotator?.name ?? '';
            deleted = true;
            // @ts-ignore
            if (config.lastChangedNode === annotatorId) {
              Sentry.captureMessage(`${annotatorId} annotator was deleted`);
            }
          }
        } else {
          ruleName = name;
        }
      }

      const curItem: MRuleConfig[] = [
        {
          id,
          name: ruleName,
          ...(curNode.typeOfConfig !== 'ANNOTATION_MATCH' ? { description } : {}),
          ...(curNode.typeOfConfig !== 'ANNOTATION_MATCH' ? { color } : {}),
          level,
          parent,
          deleted,
          relationship,
          modifiers,
          nodeGroups,
          ...(curNode.typeOfConfig === 'ANNOTATION_MATCH'
            ? { negated, mode_of_speech, annotatorId }
            : {}),
        },
      ];

      const groups = 'groups' in curNode ? curNode.groups : [];
      return groups.reduce(
        (curArray, nextNodeId) =>
          traverseTree(nextNodeId, level + 1, [...parent, id]).concat(curArray),
        curItem
      );
    };
    return traverseTree(rootConfigId || '', 0, []).reverse();
  }
);

export const saveRuleRequestLoading: Selector<boolean> = (state) =>
  state.rule.loading.includes(saveRuleRequest.toString());

export const getAnnotatorsFromRules: Selector<RuleAnnotator[] | undefined, [UUID]> = (
  state,
  ruleId
) => state.rule.rules[ruleId].annotators;

export const getSelectedRule: Selector<Rule | null> = (state) => state.rule.selectedRule;

export const getSelectedAnnotators: Selector<string[]> = (state) => state.rule.selectedAnnotators;

export const getCurrentTestRuleId: Selector<string> = (state) => state.rule.testRuleId;

export const getRuleAnnotatorPosition: Selector<string | null> = (state) =>
  state.rule.annotatorPosition;

export const getToAnnotator: Selector<boolean> = (state) => state.rule.toAnnotator;
