import { BUCKET_ICON, REMOVE_ICON } from 'constants/envelopeIcons';
import React from 'react';
import { textContrastColor } from '../../utils/tagColorUtils';

type ComponentProps = {
  text: string;
  className?: string;
  showIcon?: boolean;
  allowedRemove?: boolean;
  handleRemove?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, tag: string) => void;
  backgroundColor?: string;
};

const EnvelopeLabel: React.FC<ComponentProps> = ({
  className,
  text,
  backgroundColor,
  showIcon,
  allowedRemove = false,
  handleRemove = (): Record<string, never> => ({}),
}) => {
  const textColor = backgroundColor ? textContrastColor(backgroundColor) : 'text-litlingo-body';
  return (
    <div
      className={`litlingo-envelope-label ${className || ''}`}
      style={{ backgroundColor: `${backgroundColor}` }}
    >
      {showIcon && BUCKET_ICON}
      <span className={`text-xs px-1 ${textColor}`}>{text}</span>
      {allowedRemove && (
        <div
          role="button"
          aria-hidden
          data-testid="remove-button"
          onClick={(e): void => {
            e.stopPropagation();
            handleRemove(e, text);
          }}
        >
          {REMOVE_ICON(textColor)}
        </div>
      )}
    </div>
  );
};

export default EnvelopeLabel;
