import { fetchIdentities } from 'actions';
import IdentitiesList from 'components/IdentitiesList';
import { RouteSpec, SuperRouteSpec } from 'types';

const identitiesRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'identities',
    path: '/:customerDomain/identities',
    component: IdentitiesList,
    private: true,
    permissions: 'identities.list',
    data: {
      actions: [fetchIdentities],
      params: {
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        identities: {
          nestedResource: true,
          params: {
            broad_search: {},
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
          },
        },
      },
    },
  },
];

export default identitiesRoutes;
