import Badge from 'components/Badge';
import React from 'react';
import { TagGroup } from 'types';
import { printStringArray } from 'utils/strings';

type ComponentProps = {
  tagGroups: TagGroup[];
  className?: string;
  handleDelete?: (group: TagGroup) => void;
  stringLike?: boolean;
};

const TagGroupLabels: React.FC<ComponentProps> = ({
  tagGroups,
  className,
  handleDelete,
  stringLike = false,
}) => {
  if (stringLike) {
    return (
      <div className={`flex flex-row items-center gap-1 ${className}`}>
        {tagGroups &&
          tagGroups.length > 0 &&
          printStringArray(tagGroups.map((group) => group.name))}
      </div>
    );
  }

  return (
    <div className={`flex flex-row items-center gap-1 ${className}`}>
      {tagGroups &&
        tagGroups.length > 0 &&
        tagGroups.map((group) => (
          <div key={group.uuid}>
            <Badge
              rounded={false}
              title={group.name || ''}
              colorClass="#FFC945"
              deleteAction={handleDelete ? (): void => handleDelete(group) : undefined}
            />
          </div>
        ))}
    </div>
  );
};

export default TagGroupLabels;
