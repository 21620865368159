import type { Attachment, Communication } from '@litlingo/client';
import {
  selectCampaignEnvelopeReview,
  selectCommunication,
  selectRuleEnvelopeReview,
  selectSection,
} from 'actions';
import { DOWNLOAD_ATTACHMENT_ICON } from 'constants/envelopeIcons';
import fileExtensionIconParser from 'constants/fileIcons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { EnvelopeReviewRule, SectionType } from 'reducers/envelopeReview';
import { getCustomerDomain } from 'selectors/auth';
import { getSelectedCommunication } from 'selectors/communications';
import {
  CampaignWithRules,
  getCampaignsRulesFromAttachment,
  getCommunicationFromAttachment,
  getCommunicationTranslationFromAttachment,
  getSelectedCampaign,
  getSelectedRule,
} from 'selectors/envelopeReview';
import { useSelector } from 'store';
import { reverse, useHistory } from 'utils/urls';
import CampaignRules from './CampaignRules';

const sortCampaigns = (
  [, a]: [string, CampaignWithRules],
  [, b]: [string, CampaignWithRules]
): number => {
  const aPrio = a.campaignPriority && a.campaignPriority !== 0 ? a.campaignPriority : 1000;
  const bPrio = b.campaignPriority && b.campaignPriority !== 0 ? b.campaignPriority : 1000;

  if (aPrio < bPrio) {
    return -1;
  }
  if (bPrio > aPrio) {
    return 1;
  }

  if (a.campaignName < b.campaignName) {
    return -1;
  }
  if (a.campaignName > b.campaignName) {
    return 1;
  }

  return 0;
};

type ComponentProps = {
  attachment: Attachment;
  onlyMatches: boolean;
};

const AttachmentItem: React.FC<ComponentProps> = (props) => {
  const { attachment, onlyMatches } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const [hover, setHover] = useState(false);

  const customerDomain = useSelector(getCustomerDomain);

  let communication: Communication | null;

  const section: SectionType = 'attachment';

  const selectedRule = useSelector(getSelectedRule);
  const selectedCampaign = useSelector(getSelectedCampaign);
  const campaignsRules = useSelector((state) =>
    getCampaignsRulesFromAttachment(state, attachment.filename)
  );

  const communicationBase = useSelector((state) =>
    getCommunicationFromAttachment(state, attachment.filename)
  );
  const communicationTranslationBase = useSelector((state) =>
    getCommunicationTranslationFromAttachment(state, attachment.filename)
  );

  const selectedCommunication = useSelector(getSelectedCommunication);

  if (communicationTranslationBase) {
    communication = communicationTranslationBase;
  } else {
    communication = communicationBase;
  }

  const downloadAttachment = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    url: string
  ): void => {
    e.stopPropagation();
    window.open(url);
  };

  const handleAttachmentClick = (): void => {
    if (communication) {
      dispatch(selectCommunication({ communication }));
      dispatch(selectSection(section));

      if (campaignsRules && Object.keys(campaignsRules).length > 0) {
        const campaigns = Object.entries(campaignsRules).sort(sortCampaigns);
        if (selectedCampaign) {
          if (!Object.keys(campaigns).includes(selectedCampaign) && campaigns && campaigns[0]) {
            dispatch(selectCampaignEnvelopeReview(campaigns[0][0]));
          }
        } else if (campaigns && campaigns[0]) {
          dispatch(selectCampaignEnvelopeReview(campaigns[0][0]));
        }
      } else {
        dispatch(selectCampaignEnvelopeReview(null));
        dispatch(selectRuleEnvelopeReview(null));
      }
    } else {
      dispatch(selectCampaignEnvelopeReview(null));
      dispatch(selectRuleEnvelopeReview(null));
    }
  };

  const selectCampaign = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string): void => {
    e.stopPropagation();

    if (!campaignsRules) return;
    dispatch(selectCampaignEnvelopeReview(id));
    dispatch(selectRuleEnvelopeReview(null));

    handleAttachmentClick();
  };

  const selectRuleCampaign = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rule: EnvelopeReviewRule,
    campaignId: string
  ): void => {
    e.stopPropagation();
    dispatch(selectCampaignEnvelopeReview(campaignId));
    dispatch(selectRuleEnvelopeReview(rule));
    handleAttachmentClick();

    let route = {};

    if ('rule_group_uuid' in rule && rule.rule_group_uuid) {
      route = {
        routeName: 'global-rule-group-manager',
        routeParams: { ruleId: rule.rule_group_uuid },
        customerDomain,
      };
    } else {
      route = {
        routeName: 'rule-manager-campaign',
        routeParams: { ruleId: rule.uuid, campaignId },
        customerDomain,
      };
    }

    const path = reverse(route);

    if ((e.ctrlKey || e.metaKey) && selectedRule?.uuid === rule.uuid) {
      window.open(path, '_blank');
      return;
    }

    const element = document.getElementById(rule.uuid);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    if (selectedRule?.uuid === rule.uuid) {
      history.pushLookup(route);
    }
  };

  const name = attachment.filename.split('.').reduce((acc, item, index, arr) => {
    if (index !== arr.length - 1) return acc + item;
    return acc;
  }, '');
  const extension = attachment.filename.split('.').pop();

  if (onlyMatches && (!campaignsRules || Object.keys(campaignsRules).length === 0)) return null;

  return (
    <>
      <button
        className={`relative h-6 px-3 w-full flex flex-row items-center justify-between gap-2 border-t border-litlingo-gray-0.5 overflow-hidden focus:outline-none hover:border hover:border-litlingo-secondary-100 ${
          communication?.uuid === selectedCommunication?.uuid ? 'bg-litlingo-highlight' : ''
        }`}
        type="button"
        onMouseEnter={(): void => setHover(true)}
        onMouseLeave={(): void => setHover(false)}
        onClick={handleAttachmentClick}
      >
        <div className="min-w-1 max-w-full flex flex-row items-center gap-2 flex-initial">
          <div className="flex flex-none h-3 w-3">{fileExtensionIconParser(extension || '')}</div>
          <div className="flex flex-row items-center overflow-hidden">
            <span className="truncate">{name}</span>
            <span className="flex flex-none">{`.${extension}`}</span>
          </div>
        </div>

        {hover && (
          <button
            className="w-4 h-4 flex bg-litlingo-gray-4 rounded hover:bg-litlingo-gray-5 focus:outline-none"
            type="button"
            onClick={(e): void => downloadAttachment(e, attachment.s3_url)}
          >
            {DOWNLOAD_ATTACHMENT_ICON}
          </button>
        )}
      </button>
      <div className="flex flex-col">
        {campaignsRules &&
          Object.entries(campaignsRules)
            .sort(sortCampaigns)
            .map(([key, value]) => (
              <CampaignRules
                key={key}
                campaignId={key}
                campaignRules={value}
                selectedCampaign={selectedCampaign}
                selectCampaign={selectCampaign}
                selectRuleCampaign={selectRuleCampaign}
                section={section}
              />
            ))}
      </div>
    </>
  );
};

export default AttachmentItem;
