import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logEvent from 'utils/analytics';
import { useHistory } from 'utils/urls';

export type UseResizeObservertype = (pageName?: string) => void;

const useLogUnmountEvent: UseResizeObservertype = (pageName) => {
  const history = useHistory();
  const location = useLocation();

  const [startTime, setStartTime] = useState(0);

  useEffect(() => {
    setStartTime(performance.now());
  }, []);

  const unblock = history.block((nav) => {
    const originPath = location.pathname;
    const destinationPath = nav.pathname;

    const origin = `${
      location.pathname.split('/')[2] ? location.pathname.split('/')[2] : 'dashboard'
    }${location.pathname.split('/')[3] ? '-details' : ''}`;

    const destination = `${nav.pathname.split('/')[2] ? nav.pathname.split('/')[2] : 'dashboard'}${
      nav.pathname.split('/')[3] ? '-details' : ''
    }`;

    if (originPath === destinationPath) return;

    logEvent(`${pageName || origin}-unmount`, {
      time: performance.now() - startTime,
      destination,
    });
    setStartTime(performance.now());
    unblock();
  });
};

export default useLogUnmountEvent;
