import { setURLParams } from 'actions';
import moment from 'moment'; // require
import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { useSelector } from 'store';
import { isValidDate } from 'utils/dateValidation';

type ComponentProps = {
  className?: string;
  resource: string;
};
const DateFilter: React.FC<ComponentProps> = (props) => {
  const { className, resource } = props;

  const createdAfterRef = useRef<DatePicker>(null);
  const createdBeforeRef = useRef<DatePicker>(null);

  const dispatch = useDispatch();
  const createdAfter = useSelector(getNavParamsByResource(resource)).created_after as string;
  const createdBefore = useSelector(getNavParamsByResource(resource)).created_before as string;

  const handleSetDate = (key: string, date: Date | [Date, Date] | null): void => {
    const urlDate = moment.utc(date as Date).format();
    dispatch(setURLParams({ [`${resource}__${key}`]: urlDate, [`${resource}__offset`]: '0' }));
  };

  const createAfterDate = isValidDate(createdAfter) ? new Date(createdAfter as string) : new Date();
  const createdBeforeDate = isValidDate(createdBefore)
    ? new Date(createdBefore as string)
    : new Date();
  return (
    <div className={`flex text-left rounded-md shadow-sm ${className || ''}`}>
      <button
        data-testid="date-after-filter-button"
        type="button"
        onClick={(): void => createdAfterRef?.current?.setOpen(true)}
        className="inline-flex items-center justify-center rounded-md border px-4 bg-white text text--lighter-4 hover:text-gray-500 focus:outline-none focus:border-litlingo-success focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 border-gray-300"
      >
        <div className="flex items-center py-2">
          <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="2 2 20 20"
            stroke="currentColor"
            className="w-5 h-5 pr-2"
          >
            <path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
          After
        </div>

        <div className="flex">
          <DatePicker
            ref={createdAfterRef}
            className={`ml-2 date-picker text-sm ${createdAfter ? 'text-gray-700' : 'text-white'}`}
            selected={createAfterDate}
            onChange={(e): void => handleSetDate('created_after', e)}
          />
        </div>
      </button>

      <button
        data-testid="date-before-filter-button"
        type="button"
        onClick={(): void => createdBeforeRef?.current?.setOpen(true)}
        className="inline-flex items-center justify-center rounded-md border px-4 bg-white text text--lighter-4 hover:text-gray-500 focus:outline-none focus:border-litlingo-success focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 border-gray-300"
      >
        <div className="flex items-center py-2">
          <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="2 2 20 20"
            stroke="currentColor"
            className="w-5 h-5 pr-2"
          >
            <path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
          Before
        </div>

        <div className="flex">
          <DatePicker
            ref={createdBeforeRef}
            className={`ml-2 date-picker text-sm ${createdBefore ? 'text-gray-700' : 'text-white'}`}
            selected={createdBeforeDate}
            onChange={(e): void => handleSetDate('created_before', e)}
          />
        </div>
      </button>
    </div>
  );
};

export default DateFilter;
