import { showErrorAlert, showSuccessAlert } from 'actions';
import {
  UpsertIdentifierPayload,
  bulkDeleteIdentifier,
  bulkDeleteIdentifierFailure,
  bulkDeleteIdentifierRequest,
  cloneIdentifier,
  cloneIdentifierFailure,
  cloneIdentifierRequest,
  cloneIdentifierSuccess,
  createIdentifier,
  deleteIdentifier,
  deleteIdentifierFailure,
  deleteIdentifierRequest,
  deleteIdentifierSuccess,
  fetchAllIdentifiers,
  fetchAllIdentifiersFailure,
  fetchAllIdentifiersRequest,
  fetchAllIdentifiersSuccess,
  fetchAllIdentifiersSuccessAppend,
  fetchIdentifierCustomers,
  fetchIdentifierCustomersFailure,
  fetchIdentifierCustomersFulfill,
  fetchIdentifierCustomersRequest,
  fetchIdentifierCustomersSuccess,
  fetchIdentifierRevision,
  fetchIdentifierRevisionFailure,
  fetchIdentifierRevisionRequest,
  fetchIdentifierRevisionSuccess,
  fetchIdentifierTypes,
  fetchIdentifierTypesFailure,
  fetchIdentifierTypesRequest,
  fetchIdentifierTypesSuccess,
  fetchIdentifiersByIds,
  fetchIdentifiersByIdsFailure,
  fetchIdentifiersByIdsRequest,
  fetchIdentifiersByIdsSuccess,
  fetchIdentifiersForFilter,
  fetchLanguageMatcherTypes,
  fetchLanguageMatcherTypesFailure,
  fetchLanguageMatcherTypesRequest,
  fetchLanguageMatcherTypesSuccess,
  fetchRelationshipTypes,
  fetchRelationshipTypesFailure,
  fetchRelationshipTypesRequest,
  fetchRelationshipTypesSuccess,
  fetchSingleIdentifier,
  fetchSingleIdentifierFailure,
  fetchSingleIdentifierRequest,
  fetchSingleIdentifierSuccess,
  generateTerms,
  generateTermsFailure,
  generateTermsRequest,
  generateTermsSuccess,
  replaceIdentifier,
  replaceIdentifierFailure,
  replaceIdentifierRequest,
  replaceIdentifierSuccess,
  saveIdentifierFailure,
  saveIdentifierFulfill,
  saveIdentifierLocalFailure,
  saveIdentifierRequest,
  saveIdentifierSuccess,
  upsertIdentifier,
  upsertIdentifierDebounced,
} from 'actions/identifier';
import { apiClient as LitLingoClient, apiClientV2 as LitLingoClientV2 } from 'client';
import { push } from 'connected-react-router';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { GlobalState } from 'reducers';
import type { IdentifierState } from 'reducers/identifier';
import type { Channel } from 'redux-saga';
import { buffers } from 'redux-saga';
import { actionChannel, call, debounce, delay, put, select, takeLatest } from 'redux-saga/effects';
import { getCustomerDomain } from 'selectors/auth';
import { getSelectedIdentifier } from 'selectors/identifier';
import { getNavParams, getNavParamsByResource } from 'selectors/nav';
import type {
  API,
  APIV2,
  IdentifierRevision,
  LanguageMatcher,
  MLanguageMatcher,
  NormalizedIdentifier,
  RouteParams,
  SagaReturn,
} from 'types';
import { reverse } from 'utils/urls';

const pruneDataForApi = (
  identifierState: IdentifierState,
  payload: UpsertIdentifierPayload
): IdentifierRevision => {
  const identifier = identifierState.selectedIdentifierRevision;
  if (!identifier) return {} as IdentifierRevision;

  // strip out unwanted keys from object getting sent to API b/c they will save otherwise
  const { language_matchers: languageMatchers, ...identifierNoLanguageMatcher } = identifier;

  const newLanguageMatchers: MLanguageMatcher[] = [];
  const cleanLanguageMatchers: LanguageMatcher[] = [];
  languageMatchers.forEach((matcherId) => {
    let localError = false;
    const errorFields: string[] = [];
    const item = identifierState.languageMatchers[matcherId];
    const itemToSave = { ...item };
    const { name } = itemToSave;
    if (!name) {
      localError = true;
    }

    const combination = identifierState.languageMatcherTypes[itemToSave.type];
    Object.keys(combination).forEach((field) => {
      // @ts-ignore
      if (!combination[field].required) return;
      if (!(field in itemToSave)) {
        localError = true;
        errorFields.push(field);
      }
    });
    if (localError) {
      delete itemToSave.saved;
      newLanguageMatchers.push({
        ...itemToSave,
        errorFields,
        localError: 'Missing some required fields',
      });
    } else {
      newLanguageMatchers.push({ ...itemToSave, localError: false, errorFields: [] });
    }
  });

  if (newLanguageMatchers.every((el) => !el.localError)) {
    newLanguageMatchers.forEach((languageMatcher) => {
      const itemToSave = { ...languageMatcher };
      delete itemToSave.localError;
      delete itemToSave.saved;
      delete itemToSave.errorFields;
      delete itemToSave.isNew;

      cleanLanguageMatchers.push(itemToSave);
    });
  } else {
    cleanLanguageMatchers.push(...newLanguageMatchers);
  }

  const data = {
    ...identifierNoLanguageMatcher,
    name: payload.name || identifierNoLanguageMatcher.name,
    description: payload.description || '',
    always_save:
      payload.always_save !== undefined
        ? payload.always_save
        : identifierNoLanguageMatcher.always_save,
    language_matchers: cleanLanguageMatchers,
  };

  return data;
};

function* createIdentifierSaga(action: ReturnType<typeof createIdentifier>): SagaReturn {
  const { redirect, ...rest } = action.payload;

  yield put(saveIdentifierRequest());

  const response = (yield call([LitLingoClientV2.resources.identifiers, 'upsert'], {
    data: { ...rest },
  })) as API.Response<APIV2.Identifiers.Upsert>;

  if (response.error != null) {
    yield put(saveIdentifierFailure(response.error));
    yield put(showErrorAlert('Error - Identifier Not Saved'));
    yield put(saveIdentifierFulfill());
    return;
  }
  const responseTwo = (yield call(
    [LitLingoClientV2.resources.identifiers.extras, 'upsertIdentifierRevision'],
    {
      urlParams: { identifierId: response.data.uuid },
      data: { ...rest },
    }
  )) as API.Response<APIV2.Identifiers.Upsert>;

  if (responseTwo.error != null) {
    yield put(saveIdentifierFailure(responseTwo.error));
    yield put(showErrorAlert('Error - Identifier Not Saved'));
    yield put(saveIdentifierFulfill());
    return;
  }

  yield put(saveIdentifierFulfill());

  if (redirect) {
    const customerDomain = (yield select(getCustomerDomain)) as string;
    const path = reverse({
      routeName: 'global-identifier-manager',
      routeParams: {
        identifierId: response.data.uuid,
      },
      customerDomain,
    });
    yield put(push(path));
  }
}

function* upsertIdentifierSaga(action: ReturnType<typeof upsertIdentifier>): SagaReturn {
  const { payload } = action;
  const { identifier } = (yield select()) as GlobalState;
  const { selectedIdentifier } = identifier;
  const {
    identifierId,
    options_config: optionsConfig,
    showSuccess = true,
    redirect = false,
    campaignId,
    ruleId,
  } = payload;

  const identifierData = identifierId
    ? { ...pruneDataForApi(identifier, payload), options_config: optionsConfig }
    : payload;

  if (
    'language_matchers' in identifierData &&
    // @ts-ignore
    identifierData.language_matchers.some((el) => el.localError)
  ) {
    yield put(saveIdentifierLocalFailure(identifierData));
    yield put(showErrorAlert('Error - Identifier Not Saved'));
    return;
  }

  yield put(saveIdentifierRequest());

  const response = (yield call(
    [LitLingoClientV2.resources.identifiers.extras, 'upsertIdentifierRevision'],
    {
      data: { ...identifierData, keep_lockstep: 'true' },
      urlParams: { identifierId: selectedIdentifier?.uuid || '' },
    }
  )) as API.Response<APIV2.Identifiers.Upsert>;
  if (response.error != null) {
    yield put(saveIdentifierFailure(response.error));
    yield put(showErrorAlert('Error - Identifier Not Saved'));
    yield put(saveIdentifierFulfill());
    return;
  }

  yield put(saveIdentifierSuccess(response.data));
  if (showSuccess) {
    yield put(showSuccessAlert('Identifier Saved'));
  }

  if (action.type === upsertIdentifierDebounced.toString()) yield delay(500);
  yield put(saveIdentifierFulfill());

  if (redirect) {
    const customerDomain = (yield select(getCustomerDomain)) as string;
    const path = reverse({
      routeName: 'identifier',
      routeParams: {
        identifierId: response.data.uuid,
        campaignId: campaignId || '',
        ruleId: ruleId || '',
      },
      customerDomain,
    });
    yield put(push(path));
  }
}

function* upsertIdentifierChannelDebounced(): SagaReturn {
  const chan = (yield actionChannel(
    upsertIdentifierDebounced.toString(),
    buffers.sliding(1)
  )) as Channel<ReturnType<typeof upsertIdentifierDebounced>>;

  yield debounce(400, chan, upsertIdentifierSaga);
}

function* fetchLanguageMatcherTypesSaga(): SagaReturn {
  yield put(fetchLanguageMatcherTypesRequest());
  const response = (yield call(
    [LitLingoClient.resources.annotators.extras, 'languageMatchers'],
    {}
  )) as API.Response<API.Annotators.LanguageMatchers>;
  if (response.error != null) {
    yield put(fetchLanguageMatcherTypesFailure(response.error));
  } else {
    yield put(fetchLanguageMatcherTypesSuccess(response.data));
  }
}

function* fetchRelationshipTypesSaga(): SagaReturn {
  yield put(fetchRelationshipTypesRequest());
  const response = (yield call(
    [LitLingoClient.resources.annotators.extras, 'relationshipTypes'],
    {}
  )) as API.Response<API.Annotators.RelationshipTypes>;
  if (response.error != null) {
    yield put(fetchRelationshipTypesFailure(response.error));
  } else {
    yield put(fetchRelationshipTypesSuccess(response.data));
  }
}

function* deleteIdentifierSaga(action: ReturnType<typeof deleteIdentifier>): SagaReturn {
  const { payload } = action;
  const { identifierId, redirect = false, fetchFn } = payload;
  yield put(deleteIdentifierRequest());
  const response = (yield call(
    [LitLingoClientV2.resources.identifiers, 'delete'],
    identifierId
  )) as API.Response<APIV2.Identifiers.Delete>;
  if (response.error != null) {
    yield put(deleteIdentifierFailure(response.error));
  } else {
    yield put(deleteIdentifierSuccess(identifierId));
    if (fetchFn) {
      yield call(fetchFn);
    }
    if (redirect) {
      const customerDomain = (yield select(getCustomerDomain)) as string;
      const path = reverse({ routeName: 'global-identifiers-list', customerDomain });
      yield put(push(path));
    }
    /* starship yield put(
      prepareUndeleteAlert({
        resource: 'identifiers',
        id: identifierId,
        fetchAction: fetchAllIdentifiers,
      })
    ); */
  }
}

function* bulkDeleteIdentifierSaga(action: ReturnType<typeof bulkDeleteIdentifier>): SagaReturn {
  const { payload } = action;
  const { identifierIds, fetchFn } = payload;
  yield put(bulkDeleteIdentifierRequest());
  const response = (yield call([LitLingoClientV2.resources.identifiers.extras, 'bulkDelete'], {
    data: { uuids: identifierIds },
  })) as API.Response<APIV2.Identifiers.BulkDelete>;
  if (response.error != null) {
    yield put(bulkDeleteIdentifierFailure(response.error));
  } else if (fetchFn) {
    yield call(fetchFn);
  }
}

function* fetchIdentifiersByIdsSaga(action: ReturnType<typeof fetchIdentifiersByIds>): SagaReturn {
  const { identifierIds } = action.payload;

  const params = { uuids: identifierIds, include_pii: true, limit: '-1' };
  yield put(fetchIdentifiersByIdsRequest());

  const response = (yield call([LitLingoClientV2.resources.identifiers, 'list'], {
    params,
  })) as API.Response<APIV2.Identifiers.List>;
  if (response.error != null) {
    yield put(fetchIdentifiersByIdsFailure(response.error));
  } else {
    yield put(fetchIdentifiersByIdsSuccess(response.data));
  }
}

function* fetchAllIdentifiersSaga(action: ReturnType<typeof fetchAllIdentifiers>): SagaReturn {
  const { payload = {} } = action;
  const { limit = '' } = payload;
  const { searchValue = '' } = payload;
  const { group = '' } = payload;

  yield put(fetchAllIdentifiersRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.identifier)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const navParams = (yield select(getNavParams)) as ReturnType<typeof getNavParams>;

  const params: RouteParams = {
    ...navParams,
    ...resourceParams,
    ...payload,
    ...{ name: resourceParams.name ? resourceParams.name : '' },
    ...{ limit: resourceParams.limit ? resourceParams.limit : limit },
    ...{ broad_search: resourceParams.broad_search ? resourceParams.broad_search : searchValue },
    group,
    ...{
      relationships: resourceParams.relationships
        ? resourceParams.relationships
        : [
            'rule_identifiers.rule',
            'rule_identifiers.rule.campaign_rule_outcomes',
            'rule_identifiers.rule.campaign_rule_outcomes.campaign',
            'updated_by',
          ],
    },
    include_pii: 'true',
    heads_only: 'true',
  };

  if (params.order_desc !== 'true') {
    delete params.order_desc;
  }
  const response = (yield call([LitLingoClientV2.resources.identifiers.extras, 'getRevisions'], {
    params,
  })) as API.Response<APIV2.Identifiers.List>;
  if (response.error != null) {
    yield put(fetchAllIdentifiersFailure(response.error));
  } else {
    yield put(fetchAllIdentifiersSuccess(response.data));
  }
}

function* fetchSingleIdentifierSaga(action: ReturnType<typeof fetchSingleIdentifier>): SagaReturn {
  const { payload } = action;
  const { identifierId } = payload;

  const identifier = (yield select(getSelectedIdentifier)) as NormalizedIdentifier;
  const identifierState = (yield select((state) => state.identifier)) as IdentifierState;

  if (identifier && identifierState.unsavedChanges) {
    return;
  }

  yield put(fetchSingleIdentifierRequest());
  const response = (yield call([LitLingoClientV2.resources.identifiers, 'retrieve'], identifierId, {
    params: {
      relationships: ['model_groups'],
    },
  })) as API.Response<APIV2.Identifiers.Retrieve>;

  if (response.error != null) {
    yield put(fetchSingleIdentifierFailure(response.error));
  } else {
    yield put(fetchSingleIdentifierRequest());
    const responseTwo = (yield call(
      [LitLingoClientV2.resources.identifiers.extras, 'getRevisions'],
      {
        params: {
          identifier_uuid: identifierId,
          order_by: 'updated_at',
          order_desc: 'true',
          heads_only: 'true',
        },
      }
    )) as API.Response<APIV2.Identifiers.GetRevisions>;

    if (responseTwo.error != null) {
      yield put(fetchSingleIdentifierFailure(responseTwo.error));
    } else {
      yield put(
        fetchSingleIdentifierSuccess({
          identifier: response.data,
          identifierRevision: responseTwo.data.records[0],
        })
      );
    }
  }
}

function* fetchIdentifierRevisionSaga(
  action: ReturnType<typeof fetchIdentifierRevision>
): SagaReturn {
  const { identifierRevisionId } = action.payload;

  yield put(fetchIdentifierRevisionRequest());

  const response = (yield call([LitLingoClientV2.resources.identifiers.extras, 'getRevision'], {
    urlParams: { revisionsId: identifierRevisionId },
  })) as API.Response<APIV2.Identifiers.GetRevision>;

  if (response.error != null) {
    yield put(fetchIdentifierRevisionFailure(response.error));
  } else {
    yield put(fetchIdentifierRevisionRequest());

    const identifierId = response.data.identifier_uuid;

    const responseTwo = (yield call(
      [LitLingoClientV2.resources.identifiers, 'retrieve'],
      identifierId
    )) as API.Response<APIV2.Identifiers.Retrieve>;

    if (responseTwo.error != null) {
      yield put(fetchIdentifierRevisionFailure(responseTwo.error));
    } else {
      yield put(
        fetchIdentifierRevisionSuccess({
          identifier: responseTwo.data,
          identifierRevision: response.data,
        })
      );
    }
  }
}

function* fetchIdentifierTypesSaga(): SagaReturn {
  yield put(fetchIdentifierTypesRequest());
  const response = (yield call([
    LitLingoClient.resources.annotators.extras,
    'types',
  ])) as API.Response<API.Annotators.Types>;
  if (response.error != null) {
    yield put(fetchIdentifierTypesFailure(response.error));
  } else {
    yield put(fetchIdentifierTypesSuccess(response.data));
  }
}

function* replaceIdentifierSaga(action: ReturnType<typeof replaceIdentifier>): SagaReturn {
  const { payload } = action;
  yield put(replaceIdentifierRequest());
  const { source, targets } = payload;

  const data = {
    source_uuid: source?.uuid,
    target_uuids: targets,
  };

  const response = (yield call([LitLingoClientV2.resources.identifiers.extras, 'merge'], {
    data,
  })) as API.Response<API.Annotators.ReplaceAnnotator>;

  if (response.error != null) {
    yield put(replaceIdentifierFailure(response.error));
  } else {
    yield put(showSuccessAlert(`Successfully using ${source?.name} as the main identifier.`));
    yield put(replaceIdentifierSuccess());
  }
}

function* fetchIdentifiersForFilterSaga(
  action: ReturnType<typeof fetchIdentifiersForFilter>
): SagaReturn {
  const { payload } = action;
  const { searchValue = '', limit = 25, offset = 0 } = payload;
  const params = {
    include_pii: 'true',
    broad_search: searchValue,
    limit,
    offset,
    include_count: true,
  };
  yield put(fetchAllIdentifiersRequest());

  const response = (yield call([LitLingoClientV2.resources.identifiers, 'list'], {
    params,
  })) as API.Response<APIV2.Identifiers.List>;
  if (response.error != null) {
    yield put(fetchAllIdentifiersFailure(response.error));
  } else if (params.offset !== undefined && params.offset === 0) {
    yield put(fetchAllIdentifiersSuccess(response.data));
  } else {
    yield put(fetchAllIdentifiersSuccessAppend(response.data));
  }
}

function* generateTermsSaga(action: ReturnType<typeof generateTerms>): SagaReturn {
  const { includedTerms } = action.payload;

  const data = {
    existing_terms: includedTerms,
  };

  yield put(generateTermsRequest());

  const response = (yield call([LitLingoClient.resources.annotators.extras, 'generateTerms'], {
    data,
  })) as API.Response<API.Annotators.GenerateTerms>;

  if (response.error != null) {
    yield put(generateTermsFailure(response.error));
  } else {
    yield put(generateTermsSuccess(response.data.suggestions));
  }
}

function* fetchIdentifierCustomersSaga({
  payload,
}: ReturnType<typeof fetchIdentifierCustomers>): SagaReturn {
  yield put(fetchIdentifierCustomersRequest());

  const response = (yield call([LitLingoClientV2.resources.identifiers.extras, 'getCustomers'], {
    urlParams: { revisionsId: payload },
  })) as API.Response<APIV2.Identifiers.GetCustomers>;

  if (response.error != null) {
    yield put(fetchIdentifierCustomersFailure(response.error));
  } else {
    yield put(fetchIdentifierCustomersSuccess(response.data));
  }
  yield put(fetchIdentifierCustomersFulfill());
}

function* cloneIdentifierSaga({ payload }: ReturnType<typeof cloneIdentifier>): SagaReturn {
  const { identifierId, redirect = false } = payload;

  yield put(cloneIdentifierRequest());

  const response = (yield call([LitLingoClientV2.resources.identifiers.extras, 'cloneIdentifier'], {
    urlParams: { identifierId },
  })) as API.Response<APIV2.Identifiers.CloneIdentifier>;

  if (response.error != null) {
    yield put(cloneIdentifierFailure(response.error));
  } else {
    yield put(cloneIdentifierSuccess(response.data));
    yield put(showSuccessAlert('Identifier copied successfully'));
    if (redirect) {
      const customerDomain = 'global';
      const path = reverse({
        routeName: 'global-identifier-manager',
        routeParams: { identifierId: response.data.uuid },
        customerDomain,
      });
      window.open(path, '_blank');
    }
  }
}

function* identifierSaga(): SagaReturn {
  yield takeLatest(fetchRelationshipTypes.toString(), fetchRelationshipTypesSaga);
  yield takeLatest(fetchLanguageMatcherTypes.toString(), fetchLanguageMatcherTypesSaga);
  yield takeLatest(createIdentifier.toString(), createIdentifierSaga);
  yield takeLatest(upsertIdentifier.toString(), upsertIdentifierSaga);
  yield takeLatest(deleteIdentifier.toString(), deleteIdentifierSaga);
  yield takeLatest(fetchAllIdentifiers.toString(), fetchAllIdentifiersSaga);
  yield takeLatest(fetchIdentifiersByIds.toString(), fetchIdentifiersByIdsSaga);
  yield takeLatest(fetchSingleIdentifier.toString(), fetchSingleIdentifierSaga);
  yield takeLatest(fetchIdentifierTypes.toString(), fetchIdentifierTypesSaga);
  yield takeLatest(replaceIdentifier.toString(), replaceIdentifierSaga);
  yield takeLatest(fetchIdentifiersForFilter.toString(), fetchIdentifiersForFilterSaga);
  yield takeLatest(generateTerms.toString(), generateTermsSaga);
  yield takeLatest(fetchIdentifierCustomers.toString(), fetchIdentifierCustomersSaga);
  yield takeLatest(fetchIdentifierRevision.toString(), fetchIdentifierRevisionSaga);
  yield takeLatest(bulkDeleteIdentifier.toString(), bulkDeleteIdentifierSaga);
  yield takeLatest(cloneIdentifier.toString(), cloneIdentifierSaga);

  // @ts-expect-error check types
  yield upsertIdentifierChannelDebounced();
}

export default identifierSaga;
