import { taggingEnvelope } from 'actions/envelopeListView';
import React, { ReactHTML } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { LinkProps } from 'react-router-dom';
import { Link, useHistory } from 'react-router-dom';
import { getCustomerDomain } from 'selectors/auth';
import { reverse } from 'utils/urls';

type LinkLookupProps = Omit<LinkProps, 'to'> & {
  routeName: string;
  routeParams?: { [key: string]: string };
  queryParams?: { [key: string]: string | null } | string;
  ref?: React.RefObject<HTMLAnchorElement>;
  as?: keyof ReactHTML;
  preventNavigation?: () => boolean;
  alternativeNavigation?: () => void;
  alternativeEvaluation?: string | undefined;
  skippedEnvelopes?: boolean;
  skippedEnvelopeNavigation?: () => void;
  isTaggingEnvelope?: boolean;
};

type AsLinkProps = {
  as: keyof ReactHTML;
  path: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler;
  preventNavigation?: () => boolean;
  alternativeNavigation?: () => void;
  alternativeEvaluation?: string | undefined;
  skippedEnvelopes?: boolean;
  skippedEnvelopeNavigation?: () => void;
  isTaggingEnvelope?: boolean;
};

const AsLink: React.FC<AsLinkProps> = (props) => {
  const {
    children,
    path,
    as,
    onClick,
    preventNavigation,
    alternativeNavigation,
    alternativeEvaluation,
    skippedEnvelopes,
    skippedEnvelopeNavigation,
    isTaggingEnvelope,
    ...rest
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const handleNavigation = (e: React.MouseEvent): void => {
    if (preventNavigation && preventNavigation()) {
      e.preventDefault();
      e.stopPropagation();

      return;
    }

    if (skippedEnvelopes && skippedEnvelopeNavigation) {
      skippedEnvelopeNavigation();
      return;
    }

    if (isTaggingEnvelope) {
      dispatch(taggingEnvelope(false));
      return;
    }

    if (alternativeEvaluation && alternativeNavigation) {
      alternativeNavigation();
      return;
    }

    if (onClick) {
      onClick(e);
    }

    if (e.ctrlKey || e.metaKey) {
      window.open(path, '_blank');
      return;
    }

    history.push(path);
  };

  return React.createElement(as, { onClick: handleNavigation, ...rest }, children);
};

const LinkLookup: React.FC<LinkLookupProps> = (props) => {
  const { children, routeName, routeParams, queryParams, as, ...rest } = props;
  const customerDomain = useSelector(getCustomerDomain);
  const path = reverse({
    routeName,
    routeParams,
    queryParams,
    customerDomain,
  });

  if (as) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <AsLink as={as} path={path} {...rest}>
        {children}
      </AsLink>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Link to={path} {...rest}>
      {children}
    </Link>
  );
};

export default LinkLookup;
