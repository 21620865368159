import { User } from '@litlingo/client';
import { upsertSavedSearch } from 'actions';
import Modal from 'components/Modal';
import UserLabels from 'components/User/UserLabels';
import UserSelect from 'components/User/UserSelect';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getAllNavParams } from 'selectors/nav';
import { useSelector } from 'store';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
};

const SaveFiltersModal: React.FC<ComponentProps> = ({ isModalOpen, toggleModalOpen }) => {
  const dispatch = useDispatch();
  const navParams = useSelector(getAllNavParams);
  const params = Object.entries(navParams).filter(
    ([key]) => !['envelopes__limit', 'envelopes__offset'].includes(key)
  );

  const [users, setUsers] = useState<User[]>([]);
  const [diplayToAllUsers, setDiplayToAllUsers] = useState(false);

  const { register, getValues, handleSubmit } = useForm<{
    name: string;
    /* FRO-1090 description: string; */
    display_in_assignments: boolean;
  }>();

  const onSubmit = (): void => {
    const { name, /* FRO-1090 description, */ display_in_assignments: displayInAssignments } =
      getValues();

    const urlParams = new URLSearchParams();
    let filteredParams = params;

    if (navParams.envelopes__filters_search) {
      filteredParams = filteredParams.filter(([key]) =>
        ['envelopes__filters_search'].includes(key)
      );
    }

    filteredParams.forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => urlParams.append(key, v));
      } else {
        urlParams.append(key, value);
      }
    });

    const urlQuery = `?${urlParams.toString()}`;
    const paramCount = Object.keys(params).length;

    dispatch(
      upsertSavedSearch({
        name,
        description: '',
        private: true,
        priority: null,
        url: urlQuery,
        is_starred: false,
        param_count: paramCount,
        is_assignable: false,
        config: {
          default_count: 100,
        },
        redirect: 'review-set',
        display_in_assignments: displayInAssignments,
        viewers: users,
      })
    );
    toggleModalOpen();
  };

  const handleSaveButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const handleDeleteUser = (user: User): void => {
    setUsers(users.filter((u) => u.uuid !== user.uuid));
  };

  const handleSelectUser = (user: User, checked: boolean): void => {
    if (checked) {
      setUsers((userList) => [...userList, user]);
    } else {
      handleDeleteUser(user);
    }
  };
  const handleChangeDisplayToAllUsers = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setDiplayToAllUsers(e.target.checked);

  const renderSaveFilters = (
    <div className="pt-4 pb-6">
      <form className="flex flex-col items-start">
        <div className="flex flex-col items-start gap-4 w-full">
          <label htmlFor="name" className="flex flex-col gap-1 text-small">
            <div className="flex flex-row gap-1">
              <span>Name</span>
              <span className="litlingo-red-color">*</span>
            </div>

            <input
              id="name"
              ref={register({ required: true, pattern: /\S/i })}
              name="name"
              placeholder="New save search"
              className="form-input h-9 w-64 p-2 text-body rounded placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>

          {/* FRO-1090 <label htmlFor="description" className="flex flex-col gap-1 text-small">
            <div className="flex flex-row gap-1">
              <span>Description</span>
            </div>

            <textarea
              id="description"
              ref={register()}
              name="description"
              rows={3}
              placeholder="A short description that can help others understand the purpose of this Save Search"
              className="form-input w-88 p-2 text-body rounded resize-none placeholder-italic placeholder-litlingo-gray-6"
            />
          </label> */}
          {!diplayToAllUsers && (
            <div className="flex flex-col gap-1 mt-2 w-full">
              <span className="text-body">Users that can view</span>
              <div className="flex flex-row items-start w-full gap-5">
                <div className="w-88">
                  <UserSelect
                    selectUser={handleSelectUser}
                    selectedUsers={users}
                    className="w-64"
                    roles={['reviewer', 'super-reviewer']}
                  />
                </div>
                <UserLabels users={users} handleDelete={handleDeleteUser} className="flex-wrap" />
              </div>
            </div>
          )}

          <label htmlFor="description" className="flex flex-row gap-1 items-center">
            <div className="flex flex-row gap-1">
              <span>Display to all users</span>
            </div>
            <input
              id="display_in_assignments"
              name="display_in_assignments"
              type="checkbox"
              ref={register()}
              className="form-checkbox litlingo-checkbox"
              onChange={handleChangeDisplayToAllUsers}
            />
          </label>
        </div>
      </form>
    </div>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderSaveFilters}
          title="Save Search"
          okButton
          okButtonText="Save"
          okButtonOnClick={handleSaveButton}
          toggleShowModal={toggleModalOpen}
        />
      )}
    </>
  );
};

export default SaveFiltersModal;
