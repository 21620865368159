/* eslint-disable camelcase */
import {
  annotateCommunicationGroups,
  annotateCommunicationGroupsLegacy,
  remapModesOfSpeech,
} from '@litlingo/react';
import * as Sentry from '@sentry/react';
import {
  createRuleGroupRequest,
  fetchRuleCustomersRequest,
  fetchSingleRuleGroupRequest,
  previewRuleGroupRequest,
  saveRuleRequest,
} from 'actions/ruleGroup';
import { GlobalState } from 'reducers';
import { createSelector } from 'reselect';
import type {
  AnnotatorRelationship,
  Category,
  Customer,
  MRuleConfig,
  MRuleConfigNode,
  MRuleConfigRelationship,
  MRuleGroup,
  MRuleRevision,
  ModeOfSpeech,
  NormalizedResource,
  OperandsValue,
  RuleIdentifier,
  RuleResults,
  Selector,
  TestCommunication,
  UUID,
} from 'types';
import { getUseNewGraphs } from './auth';

export const getRule =
  (ruleId: UUID): Selector<MRuleGroup> =>
  (state): MRuleGroup =>
    state.ruleGroups.rules[ruleId];

export const getRuleIdentifiers: Selector<RuleIdentifier[]> = (state) =>
  state.ruleGroups.selectedRule?.identifiers || [];

export const getSelectedRuleIdentifiers: Selector<RuleIdentifier[]> = (state) =>
  state.ruleGroups.selectedRule?.identifiers || [];

export const getRuleConfigId: Selector<string | null> = (state) =>
  state.ruleGroups.selectedRule?.rootConfigId || null;

export const getConfigRuleAsArray: Selector<MRuleConfig[]> = createSelector(
  [
    (state): NormalizedResource<MRuleConfigNode> => state.config.items,
    (state): NormalizedResource<AnnotatorRelationship> => state.relationship,
    getRuleIdentifiers,
    getRuleConfigId,
  ],
  (config, relationshipState, identifiers, rootConfigId) => {
    const traverseTree = (curIdNode: UUID, level: number, parent: UUID[]): MRuleConfig[] => {
      const curNode = config[curIdNode];
      if (!curNode) {
        return [];
      }
      const { id, name, description, color } = curNode;

      let negated;
      let mode_of_speech: ModeOfSpeech | undefined;
      let annotatorId: UUID | undefined;
      let identifierId: UUID | undefined;
      let modifiers;
      let nodeGroups;

      if (curNode.typeOfConfig === 'ANNOTATION_MATCH') {
        mode_of_speech = curNode.mode_of_speech;
        negated = curNode.negated;
        annotatorId = curNode.annotatorId;

        const identifier = identifiers.find((element) => element.identifier_uuid === annotatorId);
        identifierId = identifier?.identifier?.identifier_uuid;
      } else {
        modifiers = curNode.modifiers;
        nodeGroups = curNode.groups;
      }
      const relationship: MRuleConfigRelationship[] = [];

      if (curNode.parent != null) {
        const parentNode = config[curNode.parent];
        const curRelationship = 'relationship' in parentNode ? parentNode.relationship : null;

        if (curRelationship != null) {
          curRelationship.forEach((key) => {
            const cur = relationshipState[key];

            if (cur && cur.annotation_a != null && cur.annotation_b != null) {
              const annotatorAId = cur.annotation_a.id;
              const annotatorBId = cur.annotation_b.id;

              if (annotatorAId === id && config[annotatorBId]) {
                const configB = config[annotatorBId];
                if (identifiers != null && 'annotatorId' in configB) {
                  const actualIdentifier = identifiers.find(
                    (element) => element.identifier_uuid === configB.annotatorId
                  );
                  relationship.push({
                    id: cur.id,
                    name: `${cur.type} ${actualIdentifier?.identifier?.name}`,
                    deleted: !!(actualIdentifier && actualIdentifier.deleted_at != null),
                  });
                }
              }
            }
          });
        }
      }

      // FIXME: Create a function to process the identifier name
      let ruleName = '';
      let deleted = false;
      if (!identifiers) {
        ruleName = name;
      } else if (identifiers != null) {
        const actualIdentifier = identifiers.find(
          (element) => element.identifier_uuid === annotatorId
        );
        if (actualIdentifier && annotatorId) {
          if (actualIdentifier && actualIdentifier.identifier == null) {
            ruleName = `Identifier doesn't exist ${annotatorId}`;
            deleted = true;
          } else if (actualIdentifier.deleted_at == null) {
            ruleName = actualIdentifier.identifier?.name ?? '';
          } else {
            ruleName = actualIdentifier.identifier?.name ?? '';
            deleted = true;
            // @ts-ignore
            if (config.lastChangedNode === annotatorId) {
              Sentry.captureMessage(`${annotatorId} identifier was deleted`);
            }
          }
        } else {
          ruleName = name;
        }
      }

      const curItem: MRuleConfig[] = [
        {
          id,
          name: ruleName,
          ...(curNode.typeOfConfig !== 'ANNOTATION_MATCH' ? { description } : {}),
          ...(curNode.typeOfConfig !== 'ANNOTATION_MATCH' ? { color } : {}),
          level,
          parent,
          deleted,
          relationship,
          identifierId,
          modifiers,
          nodeGroups,
          ...(curNode.typeOfConfig === 'ANNOTATION_MATCH'
            ? { negated, mode_of_speech, annotatorId }
            : {}),
        },
      ];

      const groups = 'groups' in curNode ? curNode.groups : [];
      return groups.reduce(
        (curArray, nextNodeId) =>
          traverseTree(nextNodeId, level + 1, [...parent, id]).concat(curArray),
        curItem
      );
    };
    return traverseTree(rootConfigId || '', 0, []).reverse();
  }
);

export const getCreateRuleGroupLoading: Selector<boolean> = (state) =>
  state.ruleGroups.loading.includes(createRuleGroupRequest.toString());

export const saveRuleRequestLoading: Selector<boolean> = (state) =>
  state.ruleGroups.loading.includes(saveRuleRequest.toString());

export const getSelectedRule: Selector<MRuleRevision | null> = (state) =>
  state.ruleGroups.selectedRule;

export const getRuleGroupLoading: Selector<boolean> = (state) =>
  state.ruleGroups.loading.includes(fetchSingleRuleGroupRequest.toString());

export const getSelectedIdentifiers: Selector<string[]> = (state) =>
  state.ruleGroups.selectedIdentifiers;

export const getCurrentTestRuleId: Selector<string> = (state) => state.ruleGroups.testRuleId;

export const getRuleIdentifierPosition: Selector<string | null> = (state) =>
  state.ruleGroups.identifierPosition;

export const getToIdentifier: Selector<boolean> = (state) => state.ruleGroups.toIdentifier;

export const getPreviewRuleGroupLoading: Selector<boolean> = (state) =>
  state.ruleGroups.loading.includes(previewRuleGroupRequest.toString());

export const getRulesViolated: Selector<{
  rulesViolated: RuleResults[];
  rulesPassed: RuleResults[];
}> = (state) => {
  const { test } = state.ruleGroups;

  const rulesViolated: RuleResults[] = [];
  const rulesPassed: RuleResults[] = [];
  if (test == null) {
    return { rulesViolated, rulesPassed };
  }
  const resultRules = test.rule_results;
  if (!resultRules || resultRules.length === 0) {
    return { rulesViolated, rulesPassed };
  }
  resultRules.forEach((e) => {
    (e.value ? rulesViolated : rulesPassed).push(e);
  });
  return { rulesViolated, rulesPassed };
};

export const getRulesViolatedV2: Selector<{
  rulesViolated: RuleResults[];
  rulesPassed: RuleResults[];
}> = (state) => {
  const { test } = state.ruleGroups;

  const rulesViolated: RuleResults[] = [];
  const rulesPassed: RuleResults[] = [];
  if (test == null) {
    return { rulesViolated, rulesPassed };
  }
  const resultRules = test.graph_v2_rule_results;
  if (!resultRules || resultRules.length === 0) {
    return { rulesViolated, rulesPassed };
  }
  resultRules.forEach((e) => {
    (e.value ? rulesViolated : rulesPassed).push(e);
  });
  return { rulesViolated, rulesPassed };
};

export type CampaignRulesViolated = {
  label: string;
  id: string;
  rules: { label: string; id: string }[];
};

export const getCampaignsRulesViolatedRuleGroup: Selector<Record<string, CampaignRulesViolated>> = (
  state
) => {
  const { rulesViolated } = getRulesViolated(state);

  const rules: Record<string, CampaignRulesViolated> = {};

  rulesViolated.forEach((r) => {
    const { rule } = r;

    rules[rule.uuid] = {
      label: rule.name,
      id: rule.uuid,
      rules: [{ label: r.rule.name, id: r.rule.uuid }],
    };
  });

  return rules;
};

export const getCampaignsRulesViolatedRuleGroupV2: Selector<
  Record<string, CampaignRulesViolated>
> = (state) => {
  const { rulesViolated } = getRulesViolatedV2(state);

  const rules: Record<string, CampaignRulesViolated> = {};

  rulesViolated.forEach((r) => {
    const { rule } = r;

    rules[rule.uuid] = {
      label: rule.name,
      id: rule.uuid,
      rules: [{ label: r.rule.name, id: r.rule.uuid }],
    };
  });

  return rules;
};

export const getAnnotatedTestSentenceRuleGroupResult: Selector<
  ReturnType<typeof annotateCommunicationGroups>['lines'] | null
> = createSelector([(state): GlobalState => state, getUseNewGraphs], (state, useNewGraphs) => {
  const { test } = state.ruleGroups;
  if (test == null) {
    return null;
  }
  const { objects, subjects } = test;
  const normalizedMode = remapModesOfSpeech(subjects, objects);

  const annotations = test.annotations.map((a) => ({ ...a, highlight: true }));

  if (useNewGraphs) {
    return annotateCommunicationGroups(
      test.document.graphs,
      test.document.groups,
      annotations,
      null,
      normalizedMode
    ).lines;
  }

  return annotateCommunicationGroupsLegacy(
    // @ts-ignore
    test.document.groups,
    annotations,
    null,
    normalizedMode
  ).lines;
});

export const getAnnotatedTestSentenceRuleGroupResultV2: Selector<
  ReturnType<typeof annotateCommunicationGroups>['lines'] | null
> = createSelector([(state): GlobalState => state, getUseNewGraphs], (state, useNewGraphs) => {
  const { test } = state.ruleGroups;
  if (test == null) {
    return null;
  }

  const { v2_summary: summaryV2, v2_annotations: annotationsV2 } = test;

  if (!summaryV2 || !annotationsV2) {
    return null;
  }

  const summary = Array.isArray(summaryV2) ? summaryV2[0] : summaryV2;

  const { objects, subjects } = summary;
  const normalizedMode = remapModesOfSpeech(subjects, objects);

  const annotations = annotationsV2.map((a) => ({ ...a, highlight: true }));

  if (useNewGraphs) {
    return annotateCommunicationGroups(
      summary.document.graphs,
      summary.document.groups,
      annotations,
      null,
      normalizedMode
    ).lines;
  }

  return annotateCommunicationGroupsLegacy(
    summary.document.groups,
    annotations,
    null,
    normalizedMode
  ).lines;
});

export const getRuleGroupTest: Selector<TestCommunication | null> = (state) =>
  state.ruleGroups.test;

export const getTriggeredNodes: Selector<string[]> = createSelector(
  [
    (state): GlobalState['config'] => state.config,
    (state): GlobalState['ruleGroups'] => state.ruleGroups,
  ],
  (config, ruleGroup) => {
    const triggeredNodes: string[] = [];
    // if something has been changed and not saved, we don't want to trigger this
    if (config.added.length > 0 || config.modified.length > 0) {
      return triggeredNodes;
    }
    if (
      !ruleGroup ||
      !ruleGroup.test ||
      !ruleGroup.test.rule_results ||
      ruleGroup.test.rule_results.length === 0
    ) {
      return triggeredNodes;
    }

    const { context } = ruleGroup.test.rule_results[0];
    if (!context.operands) {
      return triggeredNodes;
    }

    const traverseTree = (arr: OperandsValue, node: string): void => {
      if (!arr) return;

      if (arr[0] === true) {
        triggeredNodes.push(node);
      }
      if (Object.keys(arr[1]).length === 0) {
        return;
      }
      Object.keys(arr[1]).forEach((key) => {
        traverseTree(arr[1][key], key);
      });
    };

    const rootNode = Object.keys(context.operands)[0];
    traverseTree(context.operands[rootNode], rootNode);

    return triggeredNodes;
  }
);

export const getTriggeredNodesV2: Selector<string[]> = createSelector(
  [
    (state): GlobalState['config'] => state.config,
    (state): GlobalState['ruleGroups'] => state.ruleGroups,
  ],
  (config, ruleGroup) => {
    const triggeredNodes: string[] = [];
    // if something has been changed and not saved, we don't want to trigger this
    if (config.added.length > 0 || config.modified.length > 0) {
      return triggeredNodes;
    }
    if (
      !ruleGroup ||
      !ruleGroup.test ||
      !ruleGroup.test.graph_v2_rule_results ||
      ruleGroup.test.graph_v2_rule_results.length === 0
    ) {
      return triggeredNodes;
    }

    const { context } = ruleGroup.test.graph_v2_rule_results[0];
    if (!context.operands) {
      return triggeredNodes;
    }

    const traverseTree = (arr: OperandsValue, node: string): void => {
      if (!arr) return;

      if (arr[0] === true) {
        triggeredNodes.push(node);
      }
      if (Object.keys(arr[1]).length === 0) {
        return;
      }
      Object.keys(arr[1]).forEach((key) => {
        traverseTree(arr[1][key], key);
      });
    };

    const rootNode = Object.keys(context.operands)[0];
    traverseTree(context.operands[rootNode], rootNode);

    return triggeredNodes;
  }
);

export const getFetchRuleCustomersLoading: Selector<boolean> = (state) =>
  state.ruleGroups.loading.includes(fetchRuleCustomersRequest.toString());

export const getRuleCustomers: Selector<Customer[]> = (state) => state.ruleGroups.ruleCustomers;

export const getRuleCategory: Selector<Category | null> = (state) => state.ruleGroups.ruleCategory;

export const getShowRuleUtilization: Selector<boolean> = (state) =>
  state.ruleGroups.showUtilization;

export const getShowV2Graph: Selector<boolean> = (state) => state.ruleGroups.showV2;
