import { removeKeyword } from 'actions';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import type { LanguageMatcher } from 'types';

type ComponentProps = {
  keyword: string;
  id: string;
  type: keyof LanguageMatcher;
  hidden?: boolean;
};

const LanguageMatcherList: React.FC<ComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { keyword, id, type, hidden } = props;
  const [ref, setRef] = useState<HTMLParagraphElement | null>(null);

  const onRefChange = useCallback((node) => {
    setRef(node); // e.g. change ref state to trigger re-render
  }, []);

  return (
    <div className={`${hidden && 'hidden'} flex flex-row items-center my-1 mx-1`}>
      <div className="litlingo-annotator-badge">
        {ref && ref.scrollWidth > ref.clientWidth && <Tooltip id={keyword} />}
        <p
          className="truncate litlingo-annotator-badge-text"
          data-tip={keyword}
          data-for={keyword}
          ref={onRefChange}
        >
          {keyword}
        </p>
        <button
          className="self-center focus:outline-none"
          type="button"
          onClick={(): void => {
            dispatch(
              removeKeyword({
                keyword,
                languageMatcherId: id,
                partOfSpeech: type,
              })
            );
          }}
        >
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L7 7M1 7L7 1L1 7Z"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default LanguageMatcherList;
