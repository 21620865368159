import {
  REVIEW_SET_INBOX_ICON,
  REVIEW_SET_SAMPLE_ICON,
  REVIEW_STARS_ICON,
} from 'constants/reviewSets';
import React from 'react';

type ReviewSetDefaultItemProps = {
  type: 'inbox' | 'sample';
  leftTitle: string;
  leftText: string;
  rightTitle: string;
  rightText: string;
};

const ReviewSetDefaultItem: React.FC<ReviewSetDefaultItemProps> = ({
  type,
  leftTitle,
  leftText,
  rightTitle,
  rightText,
}) => {
  const renderRightSection = (): JSX.Element => (
    <div className="flex flex-row items-center gap-6 w-1/2 h-full pl-6 pr-3">
      <div className="flex flex-col justify-center items-start gap-2 h-full w-full text-left">
        <div className="text-heading-3" data-testid="header">
          {rightTitle}
        </div>

        <div className="text-body">{rightText}</div>
      </div>
    </div>
  );

  const renderLeftSection = (): JSX.Element => (
    <div className="flex flex-row items-center gap-4 w-1/2 h-full pl-6 pr-4 py-6 bg-litlingo-gray-2 bg-opacity-20">
      <div className="flex w-12.5">
        {type === 'inbox' ? REVIEW_SET_INBOX_ICON : REVIEW_SET_SAMPLE_ICON}
      </div>
      <div className="flex flex-col justify-center gap-2 h-full">
        <div className="flex text-heading-3">{leftTitle}</div>
        <div className="flex text-body">{leftText}</div>
      </div>
    </div>
  );

  return (
    <div
      className="flex flex-row w-full min-h-26 bg-white border-l-16 border-litlingo-gray-3 rounded focus:outline-none"
      data-testid="review-set"
      style={{ filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))' }}
    >
      {renderLeftSection()}
      {renderRightSection()}
    </div>
  );
};

const EmptyReviewList: React.FC = () => (
  <div className="w-full flex flex-col gap-8">
    <div className="w-full flex flex-col py-8 px-14 border-8 border-litlingo-primary-100 rounded">
      <div className="h-full w-full flex flex-col gap-4">
        <div className="self-center">{REVIEW_STARS_ICON}</div>
        <div className="h-full w-full flex flex-col gap-1">
          <span className="text-heading-2 text-center">No Assigned Streams to Review</span>
          <div>
            <p>
              This section is where your assignments will live. You’ll see them populated here as
              soon as your Review Manager assigns them. The cards below are what you can expect when
              you have assignments.
            </p>
            <p className="mt-4">There are two types.</p>
            <p>
              1. <b>Shared Inbox-</b> where you and your peers review critical messages that enter
              the stream. The goal here is to keep this at 0.
            </p>
            <p>
              2. <b>Assignments-</b> the work here is high to low priority that comes at a regular
              cadence.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full flex flex-col gap-4">
      <ReviewSetDefaultItem
        type="inbox"
        leftTitle="Shared Inbox Example"
        leftText="Shared Inbox"
        rightTitle="No Actions Needed"
        rightText="Good for now"
      />
      <ReviewSetDefaultItem
        type="sample"
        leftTitle="Assignment Example"
        leftText="Assignment"
        rightTitle="No Actions Needed"
        rightText="Good for now"
      />
    </div>
  </div>
);

export default EmptyReviewList;
