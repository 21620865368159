import React, { useState } from 'react';

import { getLanguageMatcherByAnnotatorId } from 'selectors/annotator';
import { useSelector } from 'store';

type ComponentProps = {
  annotatorId: string;
  searchValue: string;
};

const TermsSerchMatch: React.FC<ComponentProps> = ({ annotatorId, searchValue }) => {
  const languageMatchers = useSelector((state) =>
    getLanguageMatcherByAnnotatorId(state, annotatorId, '')
  );

  const [showTerms, setShowTerms] = useState(false);

  const terms: string[] = languageMatchers
    .reduce((acc, curr) => [...acc, ...(curr.terms?.map((t) => t) ?? [])] as never, [])
    .filter((c, index, self) => self.findIndex((item) => item === c) === index);

  if (!terms.includes(searchValue)) return null;

  const filteredTerms = showTerms ? terms : terms.filter((t) => searchValue.includes(t));

  return (
    <div className="flex flex-row flex-wrap gap-1 mt-1 text-xss mb-6">
      <div>Term:</div>
      {filteredTerms.map((t, idx) => (
        <div key={t} className="flex flex-row">
          <span
            className={`inline-block align-text-bottom capitalize ${
              searchValue.includes(t) ? 'bg-litlingo-highlight' : ''
            }`}
          >
            {t}
          </span>
          {filteredTerms.length - 1 !== idx && <span>,</span>}
          {filteredTerms.length - 1 === idx && terms.length > 1 && (
            <>
              <span>,</span>
              <div className="flex flex-row justify-end ml-1">
                <button
                  type="button"
                  className=" underline text-litlingo-primary focus:outline-none text-xss"
                  onClick={(): void => setShowTerms(!showTerms)}
                >
                  {showTerms ? 'Show Less' : 'Show All Terms'}
                </button>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default TermsSerchMatch;
