import LinkLookup from 'components/LinkLookup';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';
import { useSelector } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';

type ComponentProps = {
  colCount?: string;
};

// If the table have table-layout: fixed, you must provide the colCount
// prop, which is the total number of <th> in the <thead>
// This component MUST be inside the <tbody> element
const EmptyUsersTable: React.FC<ComponentProps> = ({ colCount }) => {
  const value = useSelector(getNavParamsByResource(resourceQueryParamName.user)).broad_search || '';

  if (value) {
    return (
      <tr>
        {/* @ts-ignore */}
        <td colSpan={colCount || '100%'}>
          <div className="flex flex-col items-center gap-4 py-20 px-52">
            <div className="w-112 flex flex-col gap-1 text-center">
              <span className="text-heading-2">User not found</span>
              <div className="text-body">
                Suggestions: Make sure all words are spelled correctly or try a more general search.
              </div>
            </div>
            <LinkLookup
              routeName="users-permissions"
              className="w-52 h-8 flex flex-row justify-center button button--primary whitespace-no-wrap focus:outline-none"
              key="add"
            >
              <span className="text-base">Add a New User</span>
            </LinkLookup>
          </div>
        </td>
      </tr>
    );
  }
  return (
    <tr>
      {/* @ts-ignore */}
      <td colSpan={colCount || '100%'}>
        <div className="flex flex-col items-center gap-4 py-20 px-52">
          <div className="w-112 flex flex-col gap-1 text-center">
            <span className="text-heading-2">Get Started</span>
            <div className="text-body">
              Add a new user or users to get started. You’ll be able to define roles and permissions
              upon adding users.
            </div>
          </div>
          <LinkLookup
            routeName="users-permissions"
            className="w-52 h-8 flex flex-row justify-center button button--primary whitespace-no-wrap focus:outline-none"
            key="add"
          >
            <span className="text-base">Add a New User</span>
          </LinkLookup>
        </div>
      </td>
    </tr>
  );
};
export default EmptyUsersTable;
