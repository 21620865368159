import { deleteURLParams, setURLParams } from 'actions';
import { STAR_ICON } from 'constants/envelopeIcons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { useSelector } from 'store';

type ComponentProps = {
  resource: string;
};

const IsStarredFilter: React.FC<ComponentProps> = (props) => {
  const { resource } = props;

  const dispatch = useDispatch();
  const starred = useSelector<string | string[] | null>((state) => {
    const current = getNavParamsByResource(resource)(state).is_starred;
    if (current == null) {
      return null;
    }

    return current;
  });

  const handleChange = (): void => {
    if (starred == null) {
      dispatch(
        setURLParams({
          [`${resource}__is_starred`]: 'true',
          [`${resource}__offset`]: '0',
        })
      );
    } else {
      dispatch(deleteURLParams([`${resource}__is_starred`]));
      dispatch(setURLParams({ [`${resource}__offset`]: '0' }));
    }
  };

  return (
    <div className="relative inline-block text-left mr-1 mb-1">
      <div className="rounded-md shadow-sm">
        <button
          data-testid="starred-filter-button"
          className="inline-flex justify-center w-full rounded-md border px-4 py-2 bg-white text text--lighter-4 hover:text-gray-500 focus:outline-none focus:border-litlingo-success focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 border-gray-300"
          type="button"
          onClick={handleChange}
        >
          <div>{STAR_ICON(starred ? '#007656' : 'none', null)}</div>
        </button>
      </div>
    </div>
  );
};

export default IsStarredFilter;
