import { clearTestSentence, fetchTestResults, postTestSentence } from 'actions';
import { evaluateAsync, removeTestCaseTag } from 'actions/testCases';
import CollapsableCard from 'components/CollapsableCard';
import EnvelopeLabel from 'components/EnvelopeLabel';
import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import PageHeader from 'components/PageHeader';
import Permissions from 'components/Permissions';
import SentencesViolatedSection from 'components/SentencesViolatedSection';
import TestCaseForm from 'components/TestCase/TestCaseForm';
import TestResultsTable from 'components/TestCase/TestResultsTable';
import TestResultsVisualizer from 'components/TestResultsVisualizer';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getAnnotatedTestSentenceResult,
  getAnnotationMatchedLanguage,
  getRulesViolated,
} from 'selectors/communications';
import {
  evaluateAsyncTestCaseLoading,
  getTestCase,
  getTestCasesLoading,
} from 'selectors/testCases';
import { useSelector } from 'store';
import type { UUID } from 'types';
import { useHistory } from 'utils/urls';
import TestCaseTags from './TestCaseTags';

type RouteParams = {
  testCaseId: UUID;
};

const TestCase: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { testCaseId } = useParams<RouteParams>();

  const [fired, setFired] = useState(false);
  const testCase = useSelector((state) => getTestCase(state, testCaseId) || {});

  const loading = useSelector(getTestCasesLoading);
  const evaluateLoading = useSelector(evaluateAsyncTestCaseLoading);
  const resultLines = useSelector(getAnnotatedTestSentenceResult);
  const annotationsMatched = useSelector(getAnnotationMatchedLanguage);
  const { rulesViolated } = useSelector(getRulesViolated);

  useEffect(() => {
    if (!evaluateLoading) {
      dispatch(fetchTestResults({ test_case_uuids: testCaseId }));
    }
  }, [dispatch, evaluateLoading, testCaseId]);

  useEffect(() => {
    dispatch(fetchTestResults({ test_case_uuids: testCaseId }));
  }, [dispatch, testCaseId]);

  // @ts-ignore
  useEffect(() => (): void => dispatch(clearTestSentence()), [dispatch]);

  useEffect(() => {
    if (testCase.test_string && !fired) {
      dispatch(postTestSentence({ body: testCase.test_string, platforms: ['all'] }));
      setFired(true);
    }
  }, [dispatch, fired, testCase.test_string]);

  const handleEvaluateTest = (): void => {
    dispatch(evaluateAsync({ uuids: testCaseId }));
    dispatch(postTestSentence({ body: testCase.test_string, platforms: ['all'] }));
  };

  const renderEvualateTestCaseButton = (
    <span className="rounded-md shadow-sm ">
      <button
        data-testid="run-test-button"
        type="button"
        onClick={handleEvaluateTest}
        disabled={false}
        className="button button--primary"
      >
        Run Test
      </button>
    </span>
  );

  const handleRemoveTag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, tag: string): void => {
    e.preventDefault();
    dispatch(removeTestCaseTag({ testCaseId: testCase.uuid, tag }));
  };

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />
      <div className="py-10">
        <header>
          <div className="max-w-7xl mx-auto px-1 sm:px-12 lg:px-14">
            <PageHeader />
            <div className="mt-2 flex items-center justify-between gap-2">
              <div className="flex items-center">
                <h2 className="title">Test Case</h2>
                {loading && <LoadingIndicator className="ml-2" size="5" />}
              </div>

              <div className="mt-4 flex-shrink-0 flex items-center gap-3">
                <Permissions action="test_cases.tags">
                  <div className="w-44">
                    <TestCaseTags testCase={testCase} direction="row" renderLabels={false} />
                  </div>
                </Permissions>
                <span className="shadow-sm rounded-md ">
                  <button
                    data-testid="go-back-button"
                    type="button"
                    className="button button--secondary"
                    onClick={(): void => history.goBack()}
                  >
                    Back to List
                  </button>
                </span>
                {testCase.event_uuid != null && (
                  <span className="shadow-sm rounded-md ">
                    <LinkLookup
                      routeName="event-detail"
                      type="button"
                      routeParams={{ eventId: testCase.event_uuid }}
                      className="button button--primary"
                    >
                      Go to event
                    </LinkLookup>
                  </span>
                )}
                {renderEvualateTestCaseButton}
              </div>
            </div>
            <Permissions action="test_cases.tags">
              <div className="flex flex-row items-start gap-1">
                {testCase.tags &&
                  testCase.tags.map((t) => (
                    <EnvelopeLabel
                      key={t.uuid}
                      text={t.tag_value?.value || ''}
                      backgroundColor={t.tag_value?.color}
                      className="litlingo-tag-color"
                      allowedRemove
                      handleRemove={(e): void => handleRemoveTag(e, t.tag_value?.value || '')}
                    />
                  ))}
              </div>
            </Permissions>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto px-1 sm:px-12 lg:px-14 mt-12">
            <CollapsableCard headerTitle="Setup" containerClasses="mt-10">
              <div className="p-4">
                {!loading && testCase ? <TestCaseForm testCase={testCase} /> : <></>}
              </div>
            </CollapsableCard>

            <CollapsableCard
              headerTitle="Test Result Visualizer"
              containerClasses="mt-10"
              isDefaultClosed
            >
              <>
                {resultLines && (
                  <div className="px-4">
                    <SentencesViolatedSection
                      annotatorId=""
                      annotationsMatched={annotationsMatched}
                      rulesViolated={rulesViolated}
                      classes="w50"
                    />
                  </div>
                )}
                <TestResultsVisualizer />
              </>
            </CollapsableCard>

            <CollapsableCard headerTitle="Previous tests results" containerClasses="mt-10">
              {!loading && testCase ? (
                <div className="p-4">
                  <TestResultsTable testResults={testCase.testResults || []} />
                </div>
              ) : (
                <></>
              )}
            </CollapsableCard>
          </div>
        </main>
      </div>
    </div>
  );
};

export default TestCase;
