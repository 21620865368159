import { fetchMoreLikeThis } from 'actions';
import { showMoreLikeThis } from 'actions/envelopeView';
import LoadingIndicator from 'components/LoadingIndicator';
import { ATTACHMENT_ICON, CLOSE_ICON, RECIPIENTS_ICON } from 'constants/envelopeIcons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCustomerDomain } from 'selectors/auth';
import { getMoreLikeThis, getMoreLikeThisLoading } from 'selectors/envelopes';
import { useHistory } from 'utils/urls';
import ReviewValueLabel from './ReviewValueLabel';

type ComponentProps = {
  envelopeUuid: string;
};

const MoreLikeThis: React.FC<ComponentProps> = ({ envelopeUuid }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const customerDomain = useSelector(getCustomerDomain);

  const moreLikeThis = useSelector(getMoreLikeThis);
  const loading = useSelector(getMoreLikeThisLoading);
  const [size, setSize] = useState(5);

  useEffect(() => {
    dispatch(fetchMoreLikeThis({ envelopeUuid, size }));
  }, [dispatch, envelopeUuid, size]);

  const onMoreClick = (): void => {
    setSize(size + 5);
  };

  const handleClick = (uuid: string): void => {
    history.pushLookup({
      routeName: 'envelope-detail',
      routeParams: { envelopeId: uuid },
      queryParams: location.search,
      customerDomain,
    });
  };

  const handleClose = (): void => {
    dispatch(showMoreLikeThis(false));
  };

  return (
    <div className="h-80 overflow-hidden">
      <div className="h-12 px-5 bg-litlingo-gray-0.5 flex flex-row items-center justify-between">
        <span className="w-5" />
        <span className="text-heading-3">Viewing Similar Messages</span>
        <button type="button" className="focus:outline-none" onClick={(): void => handleClose()}>
          {CLOSE_ICON}
        </button>
      </div>
      <LoadingOverlayWrapper
        active={loading}
        spinner={<LoadingIndicator size="20" />}
        fadeSpeed={0}
      >
        <div className="table-wrapper__new overflow-auto h-full no-scrollbar min-h-78 max-h-78">
          {moreLikeThis.length > 0 || loading ? (
            <table className="table-wrapper__table table-wrapper_table--fixed">
              <thead>
                <tr>
                  <th aria-hidden className="p-0 table-wrapper__th--w-5" />
                  <th aria-hidden className="p-0 table-wrapper__th--w-15" />
                  <th aria-hidden className="p-0 table-wrapper__th--w-80" />
                </tr>
              </thead>

              <tbody className="overflow-auto">
                {(moreLikeThis || []).map((point, idx) => {
                  const envelope = point.payload.communication_envelope;
                  const { events } = envelope;
                  const communication = envelope.communications?.[0] || null;
                  const recipients =
                    communication?.recipients.filter((r) => r.role === 'to').length || 0;
                  const attachments = communication?.meta_data.attachment_list?.length || 0;

                  return (
                    <tr
                      key={point.id}
                      onClick={(): void => handleClick(point.payload.comm_env_uuid)}
                      className="h-20 border-b border-litlingo-gray-1 table-row"
                    >
                      <td className="h-20 table-wrapper__new-td">
                        <div className="h-full flex items-start justify-end px-2 py-2 table-wrapper__new-cell-content">
                          <span className="text-body break-all">{idx + 1}</span>
                        </div>
                      </td>
                      <td className="h-20 table-wrapper__new-td">
                        <div className="h-full flex flex-col items-start gap-1 pl-2 pr-3 py-2 table-wrapper__new-cell-content overflow-hidden">
                          <span className="text-body truncate">
                            {moment(
                              new Date(communication?.sent_at || envelope.created_at || '')
                            ).format('MMM DD, YYYY')}
                          </span>
                          <div className="flex flex-row gap-2">
                            {recipients > 0 && (
                              <div className="flex flex-row gap-1 items-end text-small">
                                <span className="w-3 h-4">{RECIPIENTS_ICON}</span>
                                <span>{recipients}</span>
                              </div>
                            )}
                            {attachments > 0 && (
                              <div className="flex flex-row gap-1 items-end">
                                <span className="w-3 h-4">{ATTACHMENT_ICON}</span>
                                <span>{attachments}</span>
                              </div>
                            )}
                          </div>

                          {point.payload.communication_envelope.review_value &&
                            point.payload.communication_envelope.review_value !== 'pending' && (
                              <ReviewValueLabel
                                reviewValue={point.payload.communication_envelope.review_value}
                                isConfirmed
                              />
                            )}
                        </div>
                      </td>
                      <td className="h-20 table-wrapper__new-td">
                        <div className="relative h-full flex items-start pl-2 pr-3 py-2 table-wrapper__new-cell-content">
                          <span className="text-body">{point.payload.text}</span>
                          {events && events?.length > 0 && (
                            <div className="absolute top-0 right-0">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M0 0H20V20L10 10L0 0Z" fill="#FFC945" />
                              </svg>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr className="h-20">
                  {(moreLikeThis || []).length > 0 && (
                    <td colSpan={5} className="align-top">
                      <button
                        onClick={onMoreClick}
                        type="button"
                        className="w-full py-1.5 my-2 bg-litlingo-gray-1 rounded text-base font-bold leading-5 focus:outline-none"
                      >
                        Load More
                      </button>
                    </td>
                  )}
                </tr>
              </tfoot>
            </table>
          ) : (
            <div className="flex items-center justify-center pt-30 w-full">
              <span className="text-heading-2">No similar messages found</span>
            </div>
          )}
        </div>
      </LoadingOverlayWrapper>
    </div>
  );
};

export default MoreLikeThis;
