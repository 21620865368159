import { setURLParams } from 'actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';

type ComponentProps = {
  className?: string;
  text: string;
  field: string;
  resource: string;
  redesign?: boolean;
  inverted?: boolean;
};

const FilterListHeader: React.FC<ComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { className, text, field, resource, redesign = false, inverted } = props;

  const { order_by: orderBy, order_desc: orderDesc } = useSelector(
    getNavParamsByResource(resource)
  );

  const handleClick = (): void => {
    dispatch(
      setURLParams({
        [`${resource}__order_by`]: field,
        [`${resource}__order_desc`]:
          field === orderBy ? (!(orderDesc === 'true') as unknown as string) : 'true',
      })
    );
  };

  const finalOrder = (): string | string[] => {
    if (inverted) {
      if (orderDesc === 'true') {
        return 'false';
      }
      return 'true';
    }
    return orderDesc;
  };

  let icon = (
    <div className="text-gray-400">
      <svg
        className="h-3 w-3 fill-current max-w-none"
        viewBox="0 0 24 24"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M10.707 7.05L10 6.343 4.343 12l1.414 1.414L10 9.172l4.243 4.242L15.657 12z" />
      </svg>
      <svg
        className="h-3 w-3 fill-current max-w-none"
        viewBox="0 0 24 24"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </div>
  );
  if (field === orderBy) {
    icon =
      finalOrder() === 'true' ? (
        <svg
          className="h-5 w-5 fill-current text-litlingo-primary max-w-none"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      ) : (
        <svg
          className="h-5 w-5 fill-current text-litlingo-primary max-w-none"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d="M10.707 7.05L10 6.343 4.343 12l1.414 1.414L10 9.172l4.243 4.242L15.657 12z" />
        </svg>
      );
  }

  return (
    <th
      onClick={handleClick}
      className={`${redesign ? 'table-wrapper__new-th' : 'table-wrapper__th'} ${className || ''}`}
    >
      <div className="mt-1 flex items-center sm:mt-0 sm:flex-row">
        <div className="truncate">{text}</div>
        <div className="ml-2">{icon}</div>
      </div>
    </th>
  );
};

export default FilterListHeader;
