import EmptyTableState from 'components/EmptyTableState';
import moment from 'moment';
import React from 'react';
import type { TestResult } from 'types';

type ComponentProps = {
  testResults: TestResult[];
};

const TestResultsTable: React.FC<ComponentProps> = ({ testResults }) => (
  <div className="table-wrapper">
    <table className="table-wrapper__table">
      <thead>
        <tr>
          <th className="table-wrapper__th">Test Phrase</th>
          <th className="table-wrapper__th">Expected</th>
          <th className="table-wrapper__th">Result</th>
        </tr>
      </thead>
      <tbody className="table-wrapper__tbody">
        {testResults.length === 0 ? (
          <EmptyTableState message="There are no test results to display" colCount="3" />
        ) : (
          testResults.map((data) => (
            <tr key={data.uuid}>
              <td className="table-wrapper__td">
                <div className="flex items-center">
                  <div className="overflow-hidden">
                    <div className="text litlingo-flex-break-words">{data.test_string}</div>
                  </div>
                </div>
              </td>
              <td className="table-wrapper__td">
                <div className="flex items-center">
                  <div>
                    <div className="text">{data.should_have_matched ? 'Match' : 'No match'}</div>
                  </div>
                </div>
              </td>
              <td className="table-wrapper__td">
                <div className="flex items-center">
                  {data.created_at && (
                    <div className="flex flex-col items-center">
                      <div className="text">
                        {data.passed === true ? (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            PASSED
                          </span>
                        ) : (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            FAILED
                          </span>
                        )}
                      </div>
                      <div className="text text-gray-600">{moment(data.created_at).fromNow()}</div>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  </div>
);

export default TestResultsTable;
