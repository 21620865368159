/* eslint-disable camelcase */
import { createAction } from '@reduxjs/toolkit';
import type { API, Assignment, ErrorObject, SavedSearch, UUID } from 'types';
import { reviewEnvelopeType } from './envelopes';

type FetchAssignmentsPayload = {
  limit?: number;
  is_starred?: boolean;
  is_open?: boolean;
  saved_search_uuid?: UUID;
};

export const fetchOwnAssignments = createAction<FetchAssignmentsPayload>('ASSIGNMENTS/FETCH_OWN');
export const fetchOwnAssignmentsSuccess = createAction<API.WrappedAPIResponse<Assignment>>(
  'ASSIGNMENTS/FETCH_OWN_SUCCESS'
);

export const fetchAssignments = createAction<FetchAssignmentsPayload>('ASSIGNMENTS/FETCH');
export const fetchAssignmentsRequest = createAction('ASSIGNMENTS/FETCH_REQUEST');
export const fetchAssignmentsSuccess = createAction<API.WrappedAPIResponse<Assignment>>(
  'ASSIGNMENTS/FETCH_SUCCESS'
);
export const fetchAssignmentsFailure = createAction<ErrorObject>('ASSIGNMENTS/FETCH_FAILURE');
export const fetchAssignmentsFulfill = createAction('ASSIGNMENTS/FETCH_FULFILL');

type CreateAssigmnmentType = {
  create_assignment?: boolean;
};

type UpsertAssignmentPayload = CreateAssigmnmentType &
  Pick<Assignment, 'user_uuid' | 'saved_search_uuid'>;

export const upsertAssignment = createAction<UpsertAssignmentPayload>('ASSIGNMENTS/UPSERT');
export const upsertAssignmentRequest = createAction('ASSIGNMENTS/UPSERT_REQUEST');
export const upsertAssignmentSuccess = createAction<Assignment>('ASSIGNMENTS/UPSERT_SUCCESS');
export const upsertAssignmentFailure = createAction<ErrorObject>('ASSIGNMENTS/UPSERT_FAILURE');
export const upsertAssignmentFulfill = createAction('ASSIGNMENTS/UPSERT_FULFILL');

export const deleteAssignment = createAction<{ uuid: UUID }>('ASSIGNMENTS/DELETE');
export const deleteAssignmentRequest = createAction('ASSIGNMENTS/DELETE_REQUEST');
export const deleteAssignmentSuccess = createAction<UUID>('ASSIGNMENTS/DELETE_SUCCESS');
export const deleteAssignmentFailure = createAction<ErrorObject>('ASSIGNMENTS/DELETE_FAILURE');
export const deleteAssignmentFulfill = createAction('ASSIGNMENTS/DELETE_FULFILL');

type StartAssignmentPayload = {
  uuid: UUID;
  skip_if_unreviewed?: boolean;
};
export const continueAssignment = createAction<StartAssignmentPayload>('ASSIGNMENTS/CONTINUE');
export const continueAssignmentRequest = createAction('ASSIGNMENTS/CONTINUE_REQUEST');
export const continueAssignmentSuccess = createAction<API.Assignments.GetNextEnvelope>(
  'ASSIGNMENTS/CONTINUE_SUCCESS'
);
export const continueAssignmentFailure = createAction<ErrorObject>('ASSIGNMENTS/CONTINUE_FAILURE');
export const continueAssignmentFulfill = createAction('ASSIGNMENTS/CONTINUE_FULFILL');

export const closeAssignment = createAction<{ uuid: UUID }>('ASSIGNMENTS/CLOSE');
export const closeAssignmentSuccess = createAction<Assignment>('ASSIGNMENTS/CLOSE_SUCCESS');
export const closeAssignmentFailure = createAction<ErrorObject>('ASSIGNMENTS/CLOSE_FAILURE');
export const closeAssignmentFulfill = createAction('ASSIGNMENTS/CLOSE_FULFILL');

export const openAssignment = createAction<{ uuid: UUID }>('ASSIGNMENTS/OPEN');
export const openAssignmentSuccess = createAction<Assignment>('ASSIGNMENTS/OPEN_SUCCESS');
export const openAssignmentFailure = createAction<ErrorObject>('ASSIGNMENTS/OPEN_FAILURE');
export const openAssignmentFulfill = createAction('ASSIGNMENTS/OPEN_FULFILL');

type ReviewAndContinueAssignmentPayload = reviewEnvelopeType & {
  skip_if_unreviewed?: boolean;
  navigate: 'next' | 'back' | 'skip' | null;
};
export const reviewAndContinueAssignment = createAction<ReviewAndContinueAssignmentPayload>(
  'ASSIGNMENTS/REVIEW_AND_CONTINUE'
);
export const reviewAndContinueAssignmentRequest = createAction(
  'ASSIGNMENTS/REVIEW_AND_CONTINUE_REQUEST'
);
export const reviewAndContinueAssignmentFulfill = createAction(
  'REVIEW_AND_ASSIGNMENTS/CONTINUE_FULFILL'
);

export type ReviewSkippedEnvelopesSuccessPayload = API.Assignments.SkipToPendingEnvelopes & {
  saved_search: SavedSearch;
};
export const reviewSkippedEnvelopes = createAction('ASSIGNMENTS/REVIEW_SKIPPED');
export const reviewSkippedEnvelopesRequest = createAction('ASSIGNMENTS/REVIEW_SKIPPED_REQUEST');
export const reviewSkippedEnvelopesSuccess = createAction<ReviewSkippedEnvelopesSuccessPayload>(
  'ASSIGNMENTS/REVIEW_SKIPPED_SUCCESS'
);
export const reviewSkippedEnvelopesFailure = createAction<ErrorObject>(
  'ASSIGNMENTS/REVIEW_SKIPPED_FAILURE'
);
export const setLastAssignment = createAction<Assignment>('ASSIGNMENTS/LAST_ASSIGNMENT');

type ContinueSkippedAssigment = {
  uuid: UUID;
  skip_if_unreviewed?: boolean;
};

export const continueSkippedAssignment = createAction<ContinueSkippedAssigment>(
  'ASSIGNMENTS/CONTINUE_SKIPPED'
);
export const continueSkippedAssignmentRequest = createAction(
  'ASSIGNMENTS/CONTINUE_SKIPPED_REQUEST'
);
export const continueSkippedAssignmentSuccess =
  createAction<API.Assignments.GetNextSkippedEnvelope>('ASSIGNMENTS/CONTINUE_SKIPPED_SUCCESS');
export const continueSkippedAssignmentFailure = createAction<ErrorObject>(
  'ASSIGNMENTS/CONTINUE_SKIPPED_FAILURE'
);
export const continueSkippedAssignmentFulfill = createAction(
  'ASSIGNMENTS/CONTINUE_SKIPPED_FULFILL'
);

export const setShowSkippedEnvelopes = createAction<boolean>('ASSIGNMENTS/SHOW_SKIPPED');

export const openSkippedAssignment = createAction<{ uuid: UUID }>('ASSIGNMENTS/OPEN_SKIPPED');

type OpenSelectedSkippedAssignmentPayload = {
  assignmentId: string;
  envelopeId: string;
  skip_if_unreviewed?: boolean;
  navigate?: boolean;
};

export const openSelectedSkippedAssigment = createAction<OpenSelectedSkippedAssignmentPayload>(
  'ASSIGNMENTS/OPEN_SELECTED_SKIPPED'
);

export const nextSkippedAssignment = createAction<OpenSelectedSkippedAssignmentPayload>(
  'ASSIGNMENTS/OPEN_NEXT_SKIPPED'
);

type UpdateAssignmentPayload = {
  envelopeIds: UUID[];
  assignmentId: UUID;
};

export const updateAssignmentCounts = createAction<UpdateAssignmentPayload>(
  'ASSIGNMENTS/UPDATE_COUNTS'
);

export const updateAssigmentCountsRequest = createAction('ASSIGNMENTS/UPDATE_COUNTS_REQUEST');
export const updateAssigmentCountsSuccess = createAction<API.Assignments.UpdateSkips>(
  'ASSIGNMENTS/UPDATE_COUNTS_SUCCESS'
);
export const updateAssigmentCountsFailure = createAction<ErrorObject>(
  'ASSIGNMENTS/UPDATE_COUNTS_FAILURE'
);
