/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import {
  addValueToTree,
  fetchFilterCampaignRuleOutcomes,
  fetchFilterCampaigns,
  fetchFilterRules,
  removeValueFromTree,
} from 'actions';
import ShowLabelsDashboard from 'components/Filters/BackendResourceFilter/ShowLabelsDashboard';
import LoadingIndicator from 'components/LoadingIndicator';
import useClickOutside from 'components/utils/useClickOutside';
import { SEARCH_ICON } from 'constants/filterIcons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import {
  getCampaignRuleOutcomesList,
  getFetchAllCampaignRuleOutcomesLoading,
} from 'selectors/campaignRuleOutcomes';
import {
  getCampaignsForFilterPills,
  getCampaignsList,
  getFetchAllCampaignsLoading,
} from 'selectors/campaigns';
import { getNavParamsByResource } from 'selectors/nav';
import { getFetchAllRulesLoading, getRulesForFilterPills, getRulesList } from 'selectors/rules';
import { useSelector } from 'store';
import { MCampaign, MRule } from 'types';
import logEvent from 'utils/analytics';

type ResourceFilterProps = {
  resource: string;
  className?: string;
  title?: string;
  dataTestid?: string;
  hasSelectedParams?: boolean;
  campaignsKey: string;
  rulesKey: string;
  isDashboardView?: boolean;
  isAdvanceFilterView?: boolean;
  isOpening?: boolean;
  addOrRemoveAction: ActionCreatorWithPayload<
    { key: string; resource: string; value: string; name?: string },
    string
  >;
};

const CategoriesFilterDashboard: React.FC<ResourceFilterProps> = (props) => {
  const {
    className,
    resource,
    title = '',
    hasSelectedParams,
    dataTestid,
    campaignsKey,
    rulesKey,
    isDashboardView,
    isAdvanceFilterView,
    isOpening,
    addOrRemoveAction,
  } = props;

  const campaignsKeys = campaignsKey;
  const campaignsKeysAnd = `${campaignsKey}_and`;
  const rulesKeys = rulesKey;
  const rulesKeysAnd = `${rulesKey}_and`;

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [mode, setMode] = useState<'AND' | 'OR'>('OR');

  const [firstLoad, setFirstLoad] = useState(true);

  const inputRef = useRef<HTMLInputElement>(null);

  const params = useSelector(getNavParamsByResource(resource));
  const allCampaigns = useSelector(getCampaignsList);
  let allRules = useSelector(getRulesList);
  if (isAdvanceFilterView) {
    allRules = [];
  }

  const outcomes = useSelector(getCampaignRuleOutcomesList);

  const loadingCampaigns = useSelector(getFetchAllCampaignsLoading);
  const loadingRules = useSelector(getFetchAllRulesLoading);
  const loadingCampaignRuleOutcomes = useSelector(getFetchAllCampaignRuleOutcomesLoading);
  const loading = loadingCampaigns || loadingRules || loadingCampaignRuleOutcomes;

  const filterCampaigns = params[campaignsKeys] ?? [];
  const filterCampaignsAnd = params[campaignsKeysAnd] ?? [];
  const notFilterCampaigns = params[`not_${campaignsKeys}`] ?? [];
  const notFilterCampaignsAnd = params[`not_${campaignsKeysAnd}`] ?? [];
  const filterRules = params[rulesKeys] ?? [];
  const filterRulesAnd = params[rulesKeysAnd] ?? [];
  const notFilterRules = params[`not_${rulesKeys}`] ?? [];
  const notFilterRulesAnd = params[`not_${rulesKeysAnd}`] ?? [];

  const filterCampaignsItems =
    typeof filterCampaigns === 'string' ? [filterCampaigns] : [...filterCampaigns];
  const filterCampaignsItemsAnd =
    typeof filterCampaignsAnd === 'string' ? [filterCampaignsAnd] : [...filterCampaignsAnd];
  const notFilterCampaignsItems =
    typeof notFilterCampaigns === 'string' ? [notFilterCampaigns] : [...notFilterCampaigns];
  const notFilterCampaignsItemsAnd =
    typeof notFilterCampaignsAnd === 'string'
      ? [notFilterCampaignsAnd]
      : [...notFilterCampaignsAnd];

  const filterRulesItems = typeof filterRules === 'string' ? [filterRules] : [...filterRules];
  const filterRulesItemsAnd =
    typeof filterRulesAnd === 'string' ? [filterRulesAnd] : [...filterRulesAnd];
  const notFilterRulesItems =
    typeof notFilterRules === 'string' ? [notFilterRules] : [...notFilterRules];
  const notFilterRulesItemsAnd =
    typeof notFilterRulesAnd === 'string' ? [notFilterRulesAnd] : [...notFilterRulesAnd];

  const allFilterItems = [
    ...filterCampaignsItems,
    ...filterCampaignsItemsAnd,
    ...filterRulesItems,
    ...filterRulesItemsAnd,
    ...notFilterCampaignsItems,
    ...notFilterCampaignsItemsAnd,
    ...notFilterRulesItems,
    ...notFilterRulesItemsAnd,
  ];

  const allCampaignsItems = [...filterCampaignsItems, ...filterCampaignsItemsAnd];

  const allNotCampaignsItems = [...notFilterCampaignsItems, ...notFilterCampaignsItemsAnd];

  const allRulesItems = [...filterRulesItems, ...filterRulesItemsAnd];

  const allNotRulesItems = [...notFilterRulesItems, ...notFilterRulesItemsAnd];

  const allAndItems = [
    ...filterCampaignsItemsAnd,
    ...notFilterCampaignsItemsAnd,
    ...filterRulesItemsAnd,
    ...notFilterCampaignsItemsAnd,
  ];

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  useEffect(() => {
    if ((isOpen && firstLoad) || (hasSelectedParams && firstLoad)) {
      setFirstLoad(false);
      dispatch(
        fetchFilterCampaigns({
          limit: '-1',
          name: searchValue,
        })
      );
      dispatch(
        fetchFilterRules({
          limit: '-1',
          name: searchValue,
        })
      );
      dispatch(fetchFilterCampaignRuleOutcomes({ limit: -1 }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen]);

  useEffect(() => {
    if (isOpening) {
      inputRef.current?.focus();
    }
  }, [inputRef, isOpening]);

  useEffect(() => {
    if (allAndItems.length > 0) {
      setMode('AND');
    } else {
      setMode('OR');
    }
  }, [allAndItems.length]);

  const handleButtonClick = (): void => {
    if (isOpen) return;
    setIsOpen(!isOpen);
  };

  const handleDelete = (labelId: string, exclude: boolean): void => {
    if (allRulesItems.includes(labelId) || allNotRulesItems.includes(labelId)) {
      if (exclude) {
        dispatch(
          addOrRemoveAction({
            key: `not_${mode === 'OR' ? rulesKeys : rulesKeysAnd}`,
            resource,
            value: labelId,
          })
        );
      } else {
        dispatch(
          addOrRemoveAction({
            key: mode === 'OR' ? rulesKeys : rulesKeysAnd,
            resource,
            value: labelId,
          })
        );
      }
      dispatch(removeValueFromTree({ value: labelId, field: rulesKeys.replace('_and', '') }));
    } else if (exclude) {
      dispatch(
        addOrRemoveAction({
          key: `not_${mode === 'OR' ? campaignsKeys : campaignsKeysAnd}`,
          resource,
          value: labelId,
        })
      );
      dispatch(removeValueFromTree({ value: labelId, field: campaignsKeys.replace('_and', '') }));
    } else {
      dispatch(removeValueFromTree({ value: labelId, field: campaignsKeys.replace('_and', '') }));
      dispatch(
        addOrRemoveAction({
          key: mode === 'OR' ? campaignsKeys : campaignsKeysAnd,
          resource,
          value: labelId,
        })
      );
    }
  };

  const handleInputChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    isRule: boolean
  ): void => {
    logEvent('envelopes-list-filter-models');

    const { shiftKey: exclude } = event.nativeEvent;
    const { id: state } = event.currentTarget;

    if (
      exclude &&
      ((isRule && allRulesItems.includes(state)) || (!isRule && allCampaignsItems.includes(state)))
    ) {
      return;
    }
    if (
      !exclude &&
      ((isRule && allNotRulesItems.includes(state)) ||
        (!isRule && allNotCampaignsItems.includes(state)))
    ) {
      return;
    }

    if (exclude && isDashboardView) return;

    event.currentTarget.dataset.checked =
      event.currentTarget.dataset.checked === 'true' ? 'false' : 'true';

    const item =
      allCampaigns.find((c) => c.uuid === state) || allRules.find((r) => r.uuid === state);

    if (event.currentTarget.dataset.checked === 'true') {
      let key = '';
      if (isRule) {
        key = mode === 'OR' ? rulesKeys : rulesKeysAnd;
      } else {
        key = mode === 'OR' ? campaignsKeys : campaignsKeysAnd;
      }

      if (isDashboardView) {
        dispatch(
          addOrRemoveAction({
            key: isRule ? rulesKeys : campaignsKeys,
            resource,
            value: state,
            name: item?.name,
          })
        );
      } else if (exclude) {
        dispatch(
          addOrRemoveAction({
            key: isRule ? `not_${key}` : `not_${key}`,
            resource,
            value: state,
            name: item?.name,
          })
        );
        dispatch(
          addValueToTree({
            value: state,
            field: `not_${key}`.replace('and_', ''),
            label: item?.name,
          })
        );
      } else {
        dispatch(
          addValueToTree({ value: state, field: key.replace('_and', ''), label: item?.name })
        );
        dispatch(
          addOrRemoveAction({
            key: isRule ? key : key,
            resource,
            value: state,
            name: item?.name,
          })
        );
      }

      if (isDashboardView) {
        setIsOpen(false);
      }
    } else {
      handleDelete(state, exclude);
    }

    event.stopPropagation();
  };

  const getData = (): (MCampaign | MRule)[] =>
    [...allCampaigns, ...allRules].filter(
      ({ name }) => name && name.toLowerCase().includes(searchValue.toLowerCase())
    ) || [];

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e?.target.value.toLowerCase();
    setSearchValue(value);
  };

  return (
    <>
      <div ref={wrapperRef} className={`flex flex-col ${className || ''}"`}>
        <div className="relative flex flex-row justify-start items-center w-full min-h-8 rounded p-1.5 bg-litlingo-success-light bg-opacity-10">
          <div className="w-5">{SEARCH_ICON('white')}</div>
          <input
            id="search"
            name="search"
            ref={inputRef}
            className="max-h-5 w-full text-white ml-1 font-normal text-base bg-transparent placeholder-white placeholder-italic"
            placeholder={`Search ${title}`}
            data-testid={dataTestid}
            type="text"
            onFocus={handleButtonClick}
            onChange={handleSearchChange}
            value={searchValue}
            autoComplete="off"
          />
        </div>

        {isOpen && (
          <>
            <LoadingOverlayWrapper active={loading} spinner={<LoadingIndicator />} fadeSpeed={0}>
              <div className="w-full max-h-filter z-10 overflow-auto bg-litlingo-gray-1 custom-scrollbar">
                <fieldset className="m-2 min-h-5">
                  {getData().map((item, idx) => {
                    const ruleOutcome = outcomes.find(
                      (outcome) => outcome.rule_uuid === item.uuid && !outcome.campaign?.deleted_at
                    );
                    const isRule = 'rule_uuid' in item;

                    if (isRule && !ruleOutcome) {
                      return null;
                    }

                    const negated =
                      notFilterCampaignsItems.includes(item.uuid) ||
                      notFilterRulesItems.includes(item.uuid) ||
                      notFilterCampaignsItemsAnd.includes(item.uuid) ||
                      notFilterRulesItemsAnd.includes(item.uuid);

                    return (
                      <div key={item.uuid} className={idx !== 0 ? 'mt-2' : ''}>
                        <div
                          id={item.uuid}
                          role="button"
                          aria-hidden
                          className="relative flex items-center cursor-pointer"
                          data-checked={allFilterItems.includes(item.uuid)}
                          onClick={(e): void => handleInputChange(e, isRule)}
                        >
                          <div className="text-sm leading-5 flex flex-col items-start justify-center cursor-pointer">
                            <div className="flex flex-row items-center cursor-pointer">
                              <div className="relative flex justify-start items-start">
                                <div className="flex items-center h-5 mr-1">
                                  <input
                                    id={item.uuid}
                                    data-testid={`${item.uuid}-checkbox`}
                                    type="checkbox"
                                    className={`form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out cursor-pointer ${
                                      negated ? 'litlingo-checkbox-negated' : 'litlingo-checkbox'
                                    }`}
                                    checked={allFilterItems.includes(item.uuid)}
                                  />
                                </div>

                                {!isRule && (
                                  <div className="h-5 flex items-center">
                                    <span className="mr-1">
                                      <svg
                                        width="3"
                                        height="16"
                                        viewBox="0 0 3 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect width="3" height="16" fill="#F8A01A" />
                                      </svg>
                                    </span>
                                  </div>
                                )}

                                <div className="flex flex-col text-sm leading-5">
                                  <label htmlFor={item.uuid} className="cursor-pointer">
                                    <span className="text-litlingo-gray-6 text-xxs font-normal select-none break-word">
                                      {item.name}
                                    </span>
                                  </label>
                                  {ruleOutcome && (
                                    <span className="text-litlingo-gray-5 text-xxs font-normal select-none break-word">
                                      {ruleOutcome.campaign?.name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </fieldset>
              </div>
            </LoadingOverlayWrapper>
            {!isDashboardView && (
              <div className="flex self-end mt-2 -mb-2">
                <span className="text-white font-normal text-xss">Shift + click to exclude</span>
              </div>
            )}
          </>
        )}

        {!isOpen && (
          <>
            <ShowLabelsDashboard
              handleDelete={handleDelete}
              filterData={[...filterCampaignsItems, ...filterCampaignsItemsAnd]}
              notFilterData={[...notFilterCampaignsItems, ...notFilterCampaignsItemsAnd]}
              getResourceList={isDashboardView ? getCampaignsList : getCampaignsForFilterPills}
              nameKey="name"
            />
            <ShowLabelsDashboard
              handleDelete={handleDelete}
              filterData={[...filterRulesItems, ...filterRulesItemsAnd]}
              notFilterData={[...notFilterRulesItems, ...notFilterRulesItemsAnd]}
              getResourceList={isDashboardView ? getRulesList : getRulesForFilterPills}
              nameKey="name"
            />
          </>
        )}
      </div>
    </>
  );
};

export default CategoriesFilterDashboard;
