import React from 'react';
import { CSSTransition } from 'react-transition-group';

type ComponentProps = {
  show: boolean;
  enter: string;
  enterFrom: string;
  enterTo: string;
  leave: string;
  leaveFrom: string;
  leaveTo: string;
  children: JSX.Element;
};

const Transition: React.FC<ComponentProps> = ({
  show,
  enter,
  enterFrom,
  enterTo,
  leave,
  leaveFrom,
  leaveTo,
  children,
}) => {
  const enterClasses = enter.split(' ');
  const enterFromClasses = enterFrom.split(' ');
  const enterToClasses = enterTo.split(' ');
  const leaveClasses = leave.split(' ');
  const leaveFromClasses = leaveFrom.split(' ');
  const leaveToClasses = leaveTo.split(' ');

  return (
    <CSSTransition
      unmountOnExit
      in={show}
      addEndListener={(node: HTMLElement, done: () => void): void => {
        node.addEventListener('transitionend', done, false);
      }}
      onEnter={(node: HTMLElement): void => {
        node.classList.add(...enterClasses, ...enterFromClasses);
      }}
      onEntering={(node: HTMLElement): void => {
        node.classList.remove(...enterFromClasses);
        node.classList.add(...enterToClasses);
      }}
      onEntered={(node: HTMLElement): void => {
        node.classList.remove(...enterToClasses, ...enterClasses);
      }}
      onExit={(node): void => {
        node.classList.add(...leaveClasses, ...leaveFromClasses);
      }}
      onExiting={(node): void => {
        node.classList.remove(...leaveFromClasses);
        node.classList.add(...leaveToClasses);
      }}
      onExited={(node): void => {
        node.classList.remove(...leaveToClasses, ...leaveClasses);
      }}
    >
      {children}
    </CSSTransition>
  );
};

export default Transition;
