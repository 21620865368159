/* eslint-disable camelcase */

import { setCurrentTestRuleId, setToAnnotator } from 'actions';
import CollapsableCard from 'components/CollapsableCard';
import LinkLookup from 'components/LinkLookup';
import OutcomeTable from 'components/OutcomeTable';
import RuleAuditLogsTable from 'components/RuleManager/RuleAuditLogsTable';
import RuleManagerSaveButton from 'components/RuleManager/RuleManagerSaveButton';
import RuleManagerTestSuite from 'components/RuleManager/RuleManagerTestSuite';
import RuleManagerTable from 'components/RuleManagerTable';
import SelectRedesign from 'components/Select/SelectRedesign';
import TestCasesActions from 'components/TestCasesActions';
import WarningMsg from 'components/WarningMsg';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { getUpdateCampaignRuleOutcomeConfigLoading } from 'selectors/campaignRuleOutcomes';
import { getAddedNodes, getModifiedNodes } from 'selectors/config';
import { getNavParams } from 'selectors/nav';
import { getConfigRuleAsArray, getCurrentTestRuleId, saveRuleRequestLoading } from 'selectors/rule';
import { evaluateAsyncTestCaseLoading, getTestCasesLoading } from 'selectors/testCases';
import { useSelector } from 'store';
import type { MRule, UUID } from 'types';
import { sectionNotficationMesgs } from 'utils/userNotificationMsgs';
import RuleManagerDiscardChangesButton from './RuleManagerDiscardChangesButton';

type ComponentProps = {
  campaignId?: UUID;
  ruleId: UUID;
  rule: MRule;
  toggleShowModal: () => void;
  toggleTestSuiteSidebar: () => void;
};

const RuleManagerContent: React.FC<ComponentProps> = ({
  campaignId,
  ruleId,
  rule,
  toggleShowModal,
  toggleTestSuiteSidebar,
}) => {
  const dispatch = useDispatch();

  const [idToMove, setIdToMove] = useState<string | null>(null);
  const [collapseLevel, setCollapseLevel] = useState<number>(-1);
  const [isLevelModified, setLevelIsModified] = useState(false);

  const modifiedNodes = useSelector(getModifiedNodes);
  const addedNodes = useSelector(getAddedNodes);
  const arrayTree = useSelector(getConfigRuleAsArray);

  const { highlighted_rule } = useSelector(getNavParams) as {
    highlighted_rule?: string;
  };

  const isLoadingSaveRule = useSelector(saveRuleRequestLoading);
  const upsertOutcomeConfigLoading = useSelector(getUpdateCampaignRuleOutcomeConfigLoading);
  const evaluateAsyncTestLoading = useSelector(evaluateAsyncTestCaseLoading);
  const testCasesLoading = useSelector(getTestCasesLoading);
  const testSuiteAction = <TestCasesActions entity="rule" entityId={ruleId} />;
  const currentTestRuleId = useSelector(getCurrentTestRuleId);

  let level = 0;
  arrayTree.forEach((n) => {
    if (n.level > level) {
      level = n.level;
    }
  });

  useEffect(() => {
    const node = arrayTree.find((n) => n.id === highlighted_rule);

    if (node) {
      setCollapseLevel(node?.level);
    }
  }, [highlighted_rule, arrayTree]);

  useEffect(() => {
    if (arrayTree && arrayTree[0]?.nodeGroups && arrayTree[0]?.nodeGroups?.length < 5) {
      setCollapseLevel(level);
    } else {
      setCollapseLevel(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentTestRuleId !== ruleId) {
      dispatch(setCurrentTestRuleId(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTestRuleId, ruleId]);

  useEffect(() => {
    dispatch(setToAnnotator(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderOutcomeButton = (
    <>
      {campaignId && (
        <LinkLookup
          routeName="rule-campaign-outcome"
          routeParams={{ ruleId, campaignId }}
          className="button button--secondary h-8"
        >
          <span className="font-bold">Manage Outcomes</span>
        </LinkLookup>
      )}
    </>
  );

  const levelOptions = (): {
    label: string;
    value: string;
    className?: string;
  }[] => {
    const options = [];

    for (let i = 1; i < level; i += 1) {
      options.push({
        label: `Level ${i}`,
        value: i.toString(),
      });
    }

    options.push({
      label: `Show All`,
      value: level.toString(),
    });

    if (isLevelModified) {
      options.unshift({
        label: '-',
        value: (-1).toString(),
      });
    }

    return options;
  };

  const handleChangeCollapseLevel = (value: string): void => {
    setCollapseLevel(Number(value));
  };

  const renderRuleManagerSecondaryActions = (
    <div className="ml-2 w-28 z-0">
      <SelectRedesign
        className="bg-white"
        options={levelOptions()}
        onChange={handleChangeCollapseLevel}
        value={collapseLevel.toString()}
      />
    </div>
  );

  const renderRuleManagerActions = (
    <div className="flex flex-row gap-2">
      <WarningMsg
        classes="self-end"
        message={sectionNotficationMesgs.unsavedChanges}
        showWarning={modifiedNodes.length > 0 || addedNodes.length > 0}
        showLoading={isLoadingSaveRule}
        showSavedMsg
      />
      {(modifiedNodes.length > 0 || addedNodes.length > 0) && <RuleManagerDiscardChangesButton />}
      <RuleManagerSaveButton rule={rule} />
    </div>
  );

  const loadingSkeleton = (): JSX.Element => (
    <div>
      <Skeleton count={3} />
    </div>
  );

  return (
    <>
      <CollapsableCard
        headerTitle="Logic Builder"
        customAction={renderRuleManagerActions}
        secondaryCustomAction={renderRuleManagerSecondaryActions}
        stickyHeader
      >
        <>
          {rule && rule.annotators ? (
            <RuleManagerTable
              setIdToMove={setIdToMove}
              toggleShowModal={toggleShowModal}
              idToMove={idToMove}
              collapseLevel={collapseLevel}
              setLevelIsModified={setLevelIsModified}
              setCollapseLevel={setCollapseLevel}
              ruleId={ruleId}
            />
          ) : (
            <div className="p-4">{loadingSkeleton()}</div>
          )}
        </>
      </CollapsableCard>
      {campaignId && (
        <CollapsableCard
          headerTitle="Outcome"
          customAction={renderOutcomeButton}
          isDefaultClosed
          customLoading={upsertOutcomeConfigLoading}
          dataTestid="outcome-card"
          contentContainerClasses="z-50"
        >
          <div className="p-4">
            <OutcomeTable ruleId={ruleId} />
          </div>
        </CollapsableCard>
      )}

      <CollapsableCard
        headerTitle="Test Suite"
        customAction={testSuiteAction}
        customLoading={evaluateAsyncTestLoading || testCasesLoading}
      >
        <RuleManagerTestSuite ruleId={ruleId} toggleTestSuiteSidebar={toggleTestSuiteSidebar} />
      </CollapsableCard>
      <CollapsableCard headerTitle="Audit Log" isDefaultClosed>
        <RuleAuditLogsTable rule={rule} />
      </CollapsableCard>
    </>
  );
};

export default RuleManagerContent;
