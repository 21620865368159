import { setURLParams } from 'actions';
import { orderByNewParam } from 'actions/envelopeListView';
import { TAG_REMOVE_ICON } from 'constants/filterIcons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';

type ComponentProps = {
  className?: string;
  text: string;
  field: string;
  resource: string;
  closeIcon?: boolean;
  inverted?: boolean;
};

const FilterListHeaderRedesign: React.FC<ComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { className, text, field, resource, closeIcon = false, inverted } = props;

  const { order_by: orderBy, order_desc: orderDesc } = useSelector(
    getNavParamsByResource(resource)
  );

  const handleClick = (e: React.MouseEvent<HTMLTableCellElement>): void => {
    dispatch(
      setURLParams({
        [`${resource}__order_by`]: field,
        [`${resource}__order_desc`]:
          field === orderBy ? (!(orderDesc === 'true') as unknown as string) : 'true',
      })
    );
    e.stopPropagation();
  };

  const finalOrder = (): string | string[] => {
    if (inverted) {
      if (orderDesc === 'true') {
        return 'false';
      }
      return 'true';
    }
    return orderDesc;
  };

  let icon = (
    <div className=" flex flex-col gap-1 text-gray-400">
      <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 0L7.4641 6L0.535898 6L4 0Z" fill="#5C5C5C" />
      </svg>

      <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 6L0.535898 0H7.4641L4 6Z" fill="#5C5C5C" />
      </svg>
    </div>
  );
  if (field === orderBy) {
    icon =
      finalOrder() === 'true' ? (
        <svg
          width="8"
          height="6"
          viewBox="0 0 8 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3 fill-current text-litlingo-primary max-w-none"
        >
          <path d="M4 6L0.535898 0H7.4641L4 6Z" fill="#5C5C5C" />
        </svg>
      ) : (
        <svg
          width="8"
          height="6"
          viewBox="0 0 8 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3 fill-current text-litlingo-primary max-w-none"
        >
          <path d="M4 0L7.4641 6L0.535898 6L4 0Z" fill="#5C5C5C" />
        </svg>
      );
  }

  const handleCloseFilterCol = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    dispatch(orderByNewParam(false));
    dispatch(
      setURLParams({
        [`${resource}__order_by`]: 'create_at',
        [`${resource}__order_desc`]: 'true',
        [`${resource}__offset`]: '0',
      })
    );
  };

  return (
    <th onClick={handleClick} className={`relative table-wrapper__new-th ${className || ''}`}>
      <div className="flex flex-row items-center ">
        <div className="">{icon}</div>
        <div className={`truncate ml-1 `}>{text}</div>
      </div>
      {closeIcon && (
        <button
          data-testid="delete-button"
          className="absolute right-1 top-1 focus:outline-none outline-none"
          type="button"
          onClick={handleCloseFilterCol}
        >
          {TAG_REMOVE_ICON}
        </button>
      )}
    </th>
  );
};

export default FilterListHeaderRedesign;
