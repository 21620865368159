/* eslint-disable camelcase */

import * as Sentry from '@sentry/react';
import {
  addBulkEnvelopeTagsSuccess,
  addCommentEvent,
  addEnvelopeTag,
  addEventLabel,
  createCampaignSuccess,
  reprocessComms,
  reprocessEventsSuccess,
  reviewEventRequest,
  saveAnnotatorSuccess,
  saveRuleSuccess,
  saveTestCaseSuccess,
  testSentenceRequest,
} from 'actions';
import {
  fetchAllEventsCount,
  fetchAllEventsFailure,
  fetchAllEventsRequest,
  fetchAllEventsSuccess,
  fetchAllPendingEvents,
  fetchAllPendingEventsFailure,
  fetchAllPendingEventsRequest,
  fetchAllPendingEventsSuccess,
  fetchCampaignsPerformance,
  fetchCampaignsPerformanceFailure,
  fetchCampaignsPerformanceRequest,
  fetchCampaignsPerformanceSuccess,
  fetchCommonWords,
  fetchCommonWordsFailure,
  fetchCommonWordsRequest,
  fetchCommonWordsSuccess,
  fetchCommsByPlatform,
  fetchCommsByPlatformFailure,
  fetchCommsByPlatformRequest,
  fetchCommsByPlatformSuccess,
  fetchEnvelopesBuckets,
  fetchEnvelopesBucketsFailure,
  fetchEnvelopesBucketsRequest,
  fetchEnvelopesBucketsSuccess,
  fetchEventBuckets,
  fetchEventBucketsFailure,
  fetchEventBucketsRequest,
  fetchEventBucketsSuccess,
  fetchMetricsData,
  fetchMetricsDataFailure,
  fetchMetricsDataFulfill,
  fetchMetricsDataRequest,
  fetchMetricsDataSuccess,
  fetchMetricsExportData,
  fetchSentimentOverTime,
  fetchSentimentOverTimeFailure,
  fetchSentimentOverTimeRequest,
  fetchSentimentOverTimeSuccess,
  fetchTagsAnalytics,
  fetchTagsAnalyticsFailure,
  fetchTagsAnalyticsFulfill,
  fetchTagsAnalyticsSuccess,
  fetchTopUsersByEvents,
  fetchTopUsersByEventsFailure,
  fetchTopUsersByEventsRequest,
  fetchTopUsersByEventsSuccess,
  fetchViolatedEventsCount,
  fetchViolatedEventsFailure,
  fetchViolatedEventsRequest,
  fetchViolatedEventsSuccess,
  fetchWorkforceRisk,
  fetchWorkforceRiskFailure,
  fetchWorkforceRiskRequest,
  fetchWorkforceRiskSuccess,
  saveWidgetQueryData,
} from 'actions/analytics';
import amplitude from 'amplitude-js';
import { apiClient as LitLingoClient } from 'client';
import { createMatchSelector } from 'connected-react-router';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { isEqual } from 'lodash';
import moment from 'moment';
import { GlobalState } from 'reducers';
import { Task } from 'redux-saga';
import {
  ForkEffectDescriptor,
  SimpleEffect,
  all,
  call,
  cancel,
  fork,
  put,
  retry,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { getQueryDataById, getWidgetDataLoading } from 'selectors/analytics';
import { getCustomerDomain } from 'selectors/auth';
import { getCustomerDashboardConfig } from 'selectors/dashboard';
import { getNavParams, getNavParamsByResource, getNavWidgetFilters } from 'selectors/nav';
import type {
  API,
  DashboardConfig,
  QueryMetricObject,
  RouteParams,
  SagaReturn,
  TopSender,
  UUID,
} from 'types';
import { buildQFromDashboardConfig } from 'utils/dashboard';
import { TIME_RANGE_FIELD } from 'utils/timeRanges';
import triggerDownload from 'utils/triggerDownload';

function* fetchAllPendingEventsSaga(action: ReturnType<typeof fetchAllPendingEvents>): SagaReturn {
  const params = { states: 'NEW', ...action.payload };

  yield put(fetchAllPendingEventsRequest());
  const response = (yield call([LitLingoClient.resources.events.extras, 'count'], {
    params,
  })) as API.Response<API.Events.Count>;
  if (response.error != null) {
    yield put(fetchAllPendingEventsFailure(response.error));
  } else {
    yield put(fetchAllPendingEventsSuccess(response.data));
  }
}

function* fetchCampaignsPerformanceSaga(
  action: ReturnType<typeof fetchCampaignsPerformance>
): SagaReturn {
  const { payload: params } = action;

  yield put(fetchCampaignsPerformanceRequest());

  const response = (yield call([LitLingoClient.resources.analytics.extras, 'eventByCampaign'], {
    params,
  })) as API.Response<API.Analytics.EventByCampaign>;
  if (response.error != null) {
    yield put(fetchCampaignsPerformanceFailure(response.error));
  } else {
    yield put(fetchCampaignsPerformanceSuccess(response.data));
  }
}

const mapRangeToLimit: Record<string, number> = {
  '-24hours': 24,
  '-7days': 7,
  '-30days': 30,
};

function* fetchEventBucketsSaga(action: ReturnType<typeof fetchEventBuckets>): SagaReturn {
  const { payload: params } = action;
  const timeRange = (yield select(
    (state) => getNavParams(state)[TIME_RANGE_FIELD] || ''
  )) as string;

  const nav = (yield select(getNavParamsByResource(resourceQueryParamName.metrics))) as RouteParams;
  const filters = {
    ...params,
    ...(nav.campaign != null ? { campaign_uuid: nav.campaign } : {}),
    ...(nav.state != null ? { state: nav.state } : {}),
    ...{ limit: mapRangeToLimit[timeRange] ?? '' },
  };

  yield put(fetchEventBucketsRequest());

  const response = (yield call([LitLingoClient.resources.analytics.extras, 'eventBuckets'], {
    params: filters,
  })) as API.Response<API.Analytics.EventBuckets>;
  if (response.error != null) {
    yield put(fetchEventBucketsFailure(response.error));
  } else {
    yield put(fetchEventBucketsSuccess(response.data));
  }
}

function* fetchEnvelopesBucketsSaga(action: ReturnType<typeof fetchEnvelopesBuckets>): SagaReturn {
  const { payload: params } = action;
  const timeRange = (yield select(
    (state) => getNavParams(state)[TIME_RANGE_FIELD] || ''
  )) as string;

  const nav = (yield select(getNavParamsByResource(resourceQueryParamName.metrics))) as RouteParams;
  const filters = {
    ...params,
    ...(nav.campaign != null ? { campaign_uuid: nav.campaign } : {}),
    ...(nav.state != null ? { state: nav.state } : {}),
    ...{ limit: mapRangeToLimit[timeRange] ?? '' },
  };

  yield put(fetchEnvelopesBucketsRequest());

  const response = (yield call([LitLingoClient.resources.analytics.extras, 'envelopeBuckets'], {
    params: filters,
  })) as API.Response<API.Analytics.EnvelopeBuckets>;
  if (response.error != null) {
    yield put(fetchEnvelopesBucketsFailure(response.error));
  } else {
    yield put(fetchEnvelopesBucketsSuccess(response.data));
  }
}

function* fetchAllEventsCountSaga(action: ReturnType<typeof fetchAllEventsCount>): SagaReturn {
  const { payload: params } = action;

  yield put(fetchAllEventsRequest());
  const response = (yield call([LitLingoClient.resources.analytics.extras, 'eventsByStte'], {
    params,
  })) as API.Response<API.Analytics.EventsByState>;
  if (response.error != null) {
    yield put(fetchAllEventsFailure(response.error));
  } else {
    yield put(fetchAllEventsSuccess(response.data));
  }
}

function* fetchViolatedEventsCountSaga(
  action: ReturnType<typeof fetchViolatedEventsCount>
): SagaReturn {
  const params = { states: 'contains_issues', ...action.payload };

  yield put(fetchViolatedEventsRequest());
  const response = (yield call([LitLingoClient.resources.events.extras, 'count'], {
    params,
  })) as API.Response<API.Events.Count>;
  if (response.error != null) {
    yield put(fetchViolatedEventsFailure(response.error));
  } else {
    yield put(fetchViolatedEventsSuccess(response.data));
  }
}

function* fetchCommsByPlatformSaga(action: ReturnType<typeof fetchCommsByPlatform>): SagaReturn {
  const { payload: params } = action;

  yield put(fetchCommsByPlatformRequest());
  const response = (yield call([LitLingoClient.resources.analytics.extras, 'commsByPlatforms'], {
    params,
  })) as API.Response<API.Analytics.CommsByPlatforms>;
  if (response.error != null) {
    yield put(fetchCommsByPlatformFailure(response.error));
  } else {
    yield put(fetchCommsByPlatformSuccess(response.data));
  }
}

function* fetchTopUsersByEventsSaga(action: ReturnType<typeof fetchTopUsersByEvents>): SagaReturn {
  const { payload: params } = action;

  yield put(fetchTopUsersByEventsRequest());

  const response = (yield call([LitLingoClient.resources.analytics.extras, 'topUsersByEvents'], {
    params,
  })) as API.Response<API.Analytics.TopUsersByEvents>;
  if (response.error) {
    yield put(fetchTopUsersByEventsFailure(response.error));
    return;
  }

  const navParams = (yield select(getNavParams)) as ReturnType<typeof getNavParams>;

  // TODO: API should return this info when asking for top senders
  const [campaigns, users] = (yield all([
    call([LitLingoClient.resources.campaigns, 'list'], {
      params: {
        uuids: Object.values(response.data.campaigns).reduce(
          (curr, cs) => [...curr, ...cs],
          [] as string[]
        ),
        ...navParams,
      },
    }),
    call([LitLingoClient.resources.users, 'list'], {
      params: {
        include_pii: 'true',
        uuids: Object.keys(response.data.campaigns),
        ...navParams,
      },
    }),
  ])) as [API.Response<API.Campaigns.List>, API.Response<API.Users.List>];

  if (campaigns.error != null) {
    yield put(fetchTopUsersByEventsFailure(campaigns.error));
    return;
  }
  if (users.error != null) {
    yield put(fetchTopUsersByEventsFailure(users.error));
    return;
  }

  const normalize = <T extends { uuid: UUID }>(list: T[]): { [uuid: string]: T } =>
    list.reduce((curr, item) => ({ ...curr, [item.uuid]: item }), {});

  const normCampaigns = campaigns ? normalize(campaigns.data.records) : {};
  const normUsers = users ? normalize(users.data.records) : {};

  const topSenders: TopSender[] = response.data.counts.map((count) => ({
    events: count.events,
    user: {
      uuid: count.user_uuid,
      name: normUsers[count.user_uuid] ? normUsers[count.user_uuid].name : '',
      email: normUsers[count.user_uuid] ? normUsers[count.user_uuid].email : '',
    },
    campaigns: response.data.campaigns[count.user_uuid].map((campaignId) => ({
      uuid: campaignId,
      name: normCampaigns[campaignId] ? normCampaigns[campaignId].name : '',
    })),
  }));

  yield put(fetchTopUsersByEventsSuccess(topSenders));
}

function* fetchWorkforceRiskSaga(action: ReturnType<typeof fetchWorkforceRisk>): SagaReturn {
  const { payload: params } = action;

  yield put(fetchWorkforceRiskRequest());
  const response = (yield call([LitLingoClient.resources.analytics.extras, 'workforceRisk'], {
    params,
  })) as API.Response<API.Analytics.WorkforceRisk>;
  if (response.error != null) {
    yield put(fetchWorkforceRiskFailure(response.error));
  } else {
    yield put(fetchWorkforceRiskSuccess(response.data));
  }
}

function* fetchSentimentOverTimeSaga(): SagaReturn {
  const dateMonthAgo = moment.utc(moment().subtract(1, 'M')).format(FULL_DATE_FORMAT);

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.analytics)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const fullParams = {
    ...{ sent_after: dateMonthAgo },
    ...resourceParams,
  };

  yield put(fetchSentimentOverTimeRequest());
  const response = (yield call([LitLingoClient.resources.analytics.extras, 'sentimentOverTime'], {
    params: fullParams,
  })) as API.Response<API.Analytics.SentimentOverTime>;
  if (response.error != null) {
    yield put(fetchSentimentOverTimeFailure(response.error));
  } else {
    yield put(fetchSentimentOverTimeSuccess(response.data));
  }
}

function* fetchCommonWordsSaga(): SagaReturn {
  yield put(fetchCommonWordsRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.analytics)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const response = (yield call([LitLingoClient.resources.analytics.extras, 'commonWords'], {
    params: resourceParams,
  })) as API.Response<API.Analytics.CommonWords>;
  if (response.error != null) {
    yield put(fetchCommonWordsFailure(response.error));
  } else {
    yield put(fetchCommonWordsSuccess(response.data));
  }
}

function* fetchTagsAnalyticsSaga(): SagaReturn {
  const response = (yield call([
    LitLingoClient.resources.analytics.extras,
    'tags',
  ])) as API.Response<API.Analytics.Tags>;
  if (response.error != null) {
    yield put(fetchTagsAnalyticsFailure(response.error));
  } else {
    yield put(fetchTagsAnalyticsSuccess(response.data));
  }

  yield put(fetchTagsAnalyticsFulfill());
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* metricsRequest(data: QueryMetricObject): Generator<any, any, any> {
  let response = null;

  if (data.customer_uuids && data.customer_uuids.length > 0) {
    response = (yield call([LitLingoClient.resources.analytics.extras, 'multiCustomerMetrics'], {
      data,
    })) as API.Response<API.Analytics.Metrics>;
  } else {
    response = (yield call([LitLingoClient.resources.analytics.extras, 'metrics'], {
      data,
    })) as API.Response<API.Analytics.Metrics>;
  }

  if (response.error != null) {
    throw new Error(response.error.message);
  }

  return response;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* metricsExportRequest(data: QueryMetricObject): Generator<any, any, any> {
  const response = (yield call([LitLingoClient.resources.analytics.extras, 'metricsExport'], {
    data,
  })) as API.Response<API.Analytics.Metrics>;

  if (response.error != null) {
    throw new Error(response.error.message);
  }

  return response;
}

type QueryState = {
  filters: RouteParams;
  widgetFilters: RouteParams;
  timeRange: string;
};

function* getQueryState(widgetId: string): SagaReturn<QueryState> {
  const filters = (yield select(
    getNavParamsByResource(resourceQueryParamName.metrics)
  )) as RouteParams;

  // TODO: dry this up
  const widgetFilters = (yield select(getNavWidgetFilters(widgetId))) as ReturnType<
    ReturnType<typeof getNavWidgetFilters>
  >;

  const matchSelector = (yield select(
    createMatchSelector({ path: '/:customerDomain/dashboards/:activeUuid' })
  )) as { params: { activeUuid: string } } | undefined;

  const dashboardConfig = (yield select(
    getCustomerDashboardConfig(matchSelector?.params?.activeUuid)
  )) as DashboardConfig;

  const _timeRange = (yield select(
    (state) => getNavParams(state)[TIME_RANGE_FIELD] || '-30days'
  )) as string;

  const timeRange = dashboardConfig.override_start_datetime
    ? `${dashboardConfig.override_start_datetime}${_timeRange}`
    : _timeRange;

  return {
    filters,
    widgetFilters,
    timeRange,
  };
}

function* fetchMetricsDataSaga(action: ReturnType<typeof fetchMetricsData>): SagaReturn {
  const { payload } = action;
  const {
    metrics,
    widgetId,
    fetchOnePeriod,
    fetchAvgPeriod,
    metricType,
    /* timeGrouping, */
    unitOfTime,
    timeRange: overrideTimeRange,
    use_cache,
    customer_uuids,
  } = payload;

  const { location } = ((yield select()) as GlobalState).router;
  const loading = (yield select((state) => getWidgetDataLoading(state, widgetId))) as ReturnType<
    typeof getWidgetDataLoading
  >;
  const queryData = (yield select((state) => getQueryDataById(state, widgetId))) as ReturnType<
    typeof getQueryDataById
  >;
  const { pathname } = location;

  // To not dispatch unnecesary actions - Selector Performance
  if (!loading) {
    yield put(fetchMetricsDataRequest({ widgetId }));
  }

  const queryState = (yield call(getQueryState, widgetId)) as QueryState;
  const { filters, widgetFilters, timeRange } = queryState;

  const matchSelector = (yield select(
    createMatchSelector({ path: '/:customerDomain/dashboards/:activeUuid' })
  )) as { params: { activeUuid: string } } | undefined;

  const dashboardConfig = (yield select(
    getCustomerDashboardConfig(matchSelector?.params?.activeUuid)
  )) as DashboardConfig & {
    key: string;
  };

  // query to send to the backend
  const queryObject = (yield call(
    buildQFromDashboardConfig,
    metrics,
    overrideTimeRange || timeRange,
    fetchOnePeriod,
    fetchAvgPeriod,
    metricType,
    filters,
    widgetFilters,
    unitOfTime,
    use_cache,
    dashboardConfig,
    customer_uuids
  )) as QueryMetricObject;

  // To not dispatch unnecesary actions - Selector Performance
  if (!isEqual(queryData, { id: widgetId, ...queryObject })) {
    yield put(saveWidgetQueryData({ id: widgetId, ...queryObject }));
  }

  try {
    const response = (yield retry(1, 0, metricsRequest, {
      id: widgetId,
      ...queryObject,
    })) as API.Response<API.Analytics.Metrics>;

    if (response.data == null) {
      yield put(fetchMetricsDataFailure({ message: 'Empty response data', widgetId }));
      Sentry.captureMessage('Empty response at analytics fetchMetricsDataSaga', {
        contexts: { data: response },
      });
    } else {
      if (response.data.need_to_refetch && response.data.need_to_refetch === widgetId) {
        if (payload.widgetId.includes(pathname)) {
          // To not dispatch unnecesary actions - Selector Performance
          yield call(fetchMetricsDataSaga, { payload, type: fetchMetricsData.toString() });
        }
      } else {
        yield put(fetchMetricsDataSuccess({ ...response.data, widgetId }));
        yield put(fetchMetricsDataFulfill({ widgetId }));
      }

      return;
    }
  } catch (error) {
    Sentry.captureMessage(
      'Error: failed request after retrying at analytics fetchMetricsDataSaga',
      {
        contexts: { error: { error } },
      }
    );
  }
}

function* fetchMetricsExportDataSaga(
  action: ReturnType<typeof fetchMetricsExportData>
): SagaReturn {
  const { payload } = action;
  const {
    metrics,
    widgetId,
    fetchOnePeriod,
    fetchAvgPeriod,
    metricType,
    timeGrouping,
    timeRange: overrideTimeRange,
    finalTitle,
  } = payload;
  const queryState = (yield call(getQueryState, widgetId)) as QueryState;
  const { filters, widgetFilters, timeRange } = queryState;

  const matchSelector = (yield select(
    createMatchSelector({ path: '/:customerDomain/dashboards/:activeUuid' })
  )) as { params: { activeUuid: string } } | undefined;

  const dashboardConfig = (yield select(
    getCustomerDashboardConfig(matchSelector?.params?.activeUuid)
  )) as DashboardConfig & {
    key: string;
  };

  // query to send to the backend
  const queryObject = (yield call(
    buildQFromDashboardConfig,
    metrics,
    overrideTimeRange || timeRange,
    fetchOnePeriod,
    fetchAvgPeriod,
    metricType,
    filters,
    widgetFilters,
    timeGrouping,
    false,
    dashboardConfig
  )) as QueryMetricObject;

  try {
    const response = (yield retry(
      5,
      5 * 1000,
      metricsExportRequest,
      queryObject
    )) as API.Response<Blob>;

    if (response.data == null) {
      yield put(fetchMetricsDataFailure({ message: 'Empty response data', widgetId }));
      Sentry.captureMessage('Empty response at analytics fetchMetricsExportDataSaga', {
        contexts: { data: response },
      });
    } else {
      triggerDownload(response.data as Blob, finalTitle ?? 'dasboard_widget');
    }
  } catch (error) {
    Sentry.captureMessage(
      'Error: failed request after retrying at analytics fetchMetricsExportDataSaga',
      {
        contexts: { error: { error } },
      }
    );
  }
}

const ANALYTICS_WHITELIST = [
  testSentenceRequest.toString(),
  reviewEventRequest.toString(),
  addEnvelopeTag.toString(),
  addCommentEvent.toString(),
  addEventLabel.toString(),
  saveTestCaseSuccess.toString(),
  saveAnnotatorSuccess.toString(),
  saveRuleSuccess.toString(),
  reprocessEventsSuccess.toString(),
  reprocessComms.toString(),
  createCampaignSuccess.toString(),
  addBulkEnvelopeTagsSuccess.toString(),
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* reportAnalytics(action: any): SagaReturn {
  const { payload, type } = action;
  const customerDomain = yield select(getCustomerDomain);

  let extraPayload = {};
  if (payload != null) {
    const keys = Object.keys(payload);
    let name;
    if (keys.length === 1) {
      name = payload[keys[0]].name;
    } else {
      name = payload.name;
    }
    extraPayload = { name };
  }

  amplitude.getInstance().logEvent(`ACTION/${type}`, { customerDomain, ...extraPayload });
}

const takeLatestForWidget = (
  pattern: string,
  saga: (action: ReturnType<typeof fetchMetricsData>, ...args: unknown[]) => SagaReturn,
  ...args: unknown[]
): SimpleEffect<'FORK', ForkEffectDescriptor<never>> =>
  fork(function* takeMetricsDataByWidgetId() {
    type WidgetTask = { widgetId: string; task: Task };
    let activeTasks: WidgetTask[] = [];

    while (true) {
      // Get new action for metrics
      const action = (yield take(pattern)) as ReturnType<typeof fetchMetricsData>;

      // Clean completed tasks
      activeTasks = activeTasks.reduce<WidgetTask[]>((acc, t) => {
        if (t.task.isRunning()) {
          return [...acc, t];
        }
        return acc;
      }, []);

      // Get active task with same widgetId as new action
      const repeatedTask: WidgetTask | undefined = activeTasks.find(
        (t) => t.widgetId === action.payload.widgetId
      );

      // Cancel repeated task
      if (repeatedTask) {
        yield cancel(repeatedTask.task);
      }

      // Fork task for new action
      const task = (yield fork<
        (action: ReturnType<typeof fetchMetricsData>, ...rest: unknown[]) => SagaReturn
      >(saga, ...([action, args] as const))) as Task;

      // Addd new task to activeTasks
      activeTasks.push({ widgetId: action.payload.widgetId, task });
    }
  });

function* analyticsSaga(): SagaReturn {
  // TODO: Remove unused saga
  yield takeLatest(fetchAllPendingEvents.toString(), fetchAllPendingEventsSaga);
  yield takeLatest(fetchEventBuckets.toString(), fetchEventBucketsSaga);
  yield takeLatest(fetchEnvelopesBuckets.toString(), fetchEnvelopesBucketsSaga);
  yield takeLatest(fetchCampaignsPerformance.toString(), fetchCampaignsPerformanceSaga);
  yield takeLatest(fetchTopUsersByEvents.toString(), fetchTopUsersByEventsSaga);
  yield takeLatest(fetchAllEventsCount.toString(), fetchAllEventsCountSaga);
  // TODO: Remove unused saga
  yield takeLatest(fetchViolatedEventsCount.toString(), fetchViolatedEventsCountSaga);
  yield takeLatest(fetchCommsByPlatform.toString(), fetchCommsByPlatformSaga);
  yield takeLatest(fetchWorkforceRisk.toString(), fetchWorkforceRiskSaga);
  yield takeLeading(fetchSentimentOverTime.toString(), fetchSentimentOverTimeSaga);
  yield takeLatest(fetchCommonWords.toString(), fetchCommonWordsSaga);
  yield takeLatest(fetchTagsAnalytics.toString(), fetchTagsAnalyticsSaga);
  // metrics endp
  yield takeLatestForWidget(fetchMetricsData.toString(), fetchMetricsDataSaga);
  yield takeEvery(fetchMetricsExportData.toString(), fetchMetricsExportDataSaga);

  yield takeEvery(ANALYTICS_WHITELIST, reportAnalytics);
}

export default analyticsSaga;
