import React from 'react';
import ListItem from './ListItem';

interface ListItemType<I> {
  label: string | null;
  subitems: I[] | null;
}

interface ComponentProps<I> {
  list: I[];
  handleOnClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: I) => void;
  component?: (
    item: I,
    level: number,
    expanded: boolean,
    handleExpandClick: () => void
  ) => JSX.Element;
  renderExpandIcon?: boolean;
  indentation?: boolean;
  className?: string;
  elementsGap?: boolean;
}

const CollapsableList = <I extends ListItemType<I>>(props: ComponentProps<I>): JSX.Element => {
  const {
    list,
    handleOnClick,
    component,
    renderExpandIcon,
    indentation,
    className,
    elementsGap = true,
  } = props;

  let renderItems: (items: I[] | null, level: number) => JSX.Element[] | null;

  const parseList = (item: I, level: number): JSX.Element[] | null =>
    renderItems(item.subitems, level + 1);

  renderItems = (items: I[] | null, level: number): JSX.Element[] | null => {
    if (!items) return null;
    // eslint-disable-next-line react/destructuring-assignment
    return items.map((i) => (
      <ListItem
        key={i.label}
        item={{ ...i, content: parseList(i, level) }}
        handleOnClick={handleOnClick}
        component={component}
        level={level}
        renderExpandIcon={renderExpandIcon}
        indentation={indentation}
        elementsGap={elementsGap}
      />
    ));
  };

  return (
    <div
      className={`flex flex-col w-full h-full overflow-auto ${elementsGap && 'gap-2'} ${
        className || ''
      } `}
    >
      {renderItems(list, 0)}
    </div>
  );
};

CollapsableList.defaultProps = {
  handleOnClick: (): null => null,
  component: (): null => null,
};

export default CollapsableList;
