import { UUID } from '@litlingo/client';
import { fetchSavedSearches } from 'actions';
import Select from 'components/Select';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSavedSearchesList } from 'selectors/savedSearches';

type ComponentProps = {
  setSelectedSavedSearch: React.Dispatch<React.SetStateAction<string | null>>;
  selectedSavedSearch: string | null;
};
const SelectSavedSearch: React.FC<ComponentProps> = ({
  setSelectedSavedSearch,
  selectedSavedSearch,
}) => {
  const dispatch = useDispatch();

  const savedSearches = useSelector(getSavedSearchesList);

  useEffect(() => {
    dispatch(fetchSavedSearches({ limit: -1 }));
  }, [dispatch]);

  const handleChange = (fieldValue: UUID): void => {
    setSelectedSavedSearch(fieldValue);
  };

  const options = [...savedSearches.map((item) => ({ label: item.name, value: item.uuid }))];

  return (
    <div className="relative inline-block text-left">
      <Select
        onChange={handleChange}
        options={options}
        className="max-h-72"
        value={selectedSavedSearch || ''}
      />
    </div>
  );
};

export default SelectSavedSearch;
