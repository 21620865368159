import { DashboardMetric } from '@litlingo/client';
import { fetchMetricsData } from 'actions';
import Permissions from 'components/Permissions';
import useClickOutside from 'components/utils/useClickOutside';
import { DOWNLOAD } from 'constants/customerIcons';
import { CLOSE_ICON } from 'constants/dashboardIcons';
import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { timeGroupingType } from 'types';
import WidgetTable, { Header, Row } from './WidgetTable';

type WidgetExpandedViewProps = {
  setIsExpandedView: (value: boolean) => void;
  headers: Header[];
  rows: Row[];
  title: string | undefined;
  onExportClick: () => void;
  allowDownloadCsv: boolean | undefined;
  type: string | undefined;
  dataToReturnDashboard:
    | {
        metrics: DashboardMetric[];
        fetchOnePeriod: boolean;
        fetchAvgPeriod: boolean;
        widgetId: string;
        timeRange?: string;
        metricType?: string;
        timeGrouping?: timeGroupingType;
        use_cache?: boolean;
      }
    | undefined;
  children: ReactNode;
  isTableView: boolean;
  reversed: boolean;
  setReversed: React.Dispatch<React.SetStateAction<boolean>>;
};

type SelectOptionProps = {
  option: {
    label: string;
    value: () => void;
    state: boolean | undefined;
  };
  dataTestid: string;
};

type SelectProps = {
  dataTestid?: string;
  options: SelectOptionProps['option'][];
  className?: string;
  value: string;
};

const SelectOption: React.FC<SelectOptionProps> = (props) => {
  const { option, dataTestid } = props;

  const handleClick = (): void => option.value();

  return (
    <button
      type="button"
      data-testid={dataTestid}
      className={`w-full border-litlingo-gray-2 p-2 focus:outline-none hover:bg-litlingo-gray-1 ${
        !option.state && 'text-litlingo-line'
      } `}
      onClick={handleClick}
      /* disabled={option.state && option.state} */
    >
      <div className="flex">
        <span>{option.label}</span>
      </div>
    </button>
  );
};

const Select: React.FC<SelectProps> = (props) => {
  const { options, value, dataTestid, className } = props;

  const [isOpen, setIsOpen] = useState(false);

  // click outside functionality
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleClick = (): void => setIsOpen(!isOpen);

  return (
    <div ref={wrapperRef} className="relative h-5 w-full text-body">
      <button
        type="button"
        className={`flex w-full items-center align-middle pr-2 text-litling0-gray-6 focus:outline-none disabled:opacity-50 ${className}`}
        id="options-menu"
        data-testid={dataTestid || 'select'}
        aria-haspopup="true"
        aria-expanded="true"
        onClick={handleClick}
      >
        <span className="truncate text-litlingo-white">{value}</span>

        <svg className="-mr-1  h-5 w-5" fill="#FFFFFF" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          data-testid="dropdown-menu"
          className="origin-bottom-left absolute mt-1 w-56 shadow-lg z-50 border border-litlingo-gray-2"
        >
          <div
            className="bg-white shadow-xs overflow-hidden"
            style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}
          >
            <div
              role="menu"
              data-testid="options-container"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {options.map((option) => (
                <SelectOption
                  key={`${option.label}-${option.value}`}
                  option={option}
                  dataTestid={`${dataTestid || 'select'}-${option.label}-option`}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const WidgetExpandedView: React.FC<WidgetExpandedViewProps> = (props) => {
  const {
    setIsExpandedView,
    headers,
    rows,
    title,
    onExportClick,
    allowDownloadCsv,
    type,
    dataToReturnDashboard,
    children,
    isTableView,
    reversed,
    setReversed,
  } = props;

  const dispatch = useDispatch();
  const [isChangedChart, setIsChangedChart] = useState(false);
  const [isExpandTableView, setIsExpandTableView] = useState(isTableView);

  const styles = {
    vertical: {
      minHeight: '1400px',
      left: '250px',
      right: '200px',
      top: '76px',
    },
    horizontal: {
      height: '1400px',
      left: '80px',
      width: '2000px',
      top: '82px',
    },
  };

  let options = [
    {
      id: 'flip',
      value: (): void => setReversed(!reversed),
      label: 'Flip row and column headers',
      state: isExpandTableView,
    },
    {
      id: 'expand',
      value: (): void => {
        setIsExpandTableView(!isExpandTableView);
        setIsChangedChart(false);
      },
      label: isExpandTableView ? 'Expanded chart' : 'Expanded table',
      state: true,
    },
    {
      id: 'view',
      value: (): void => setIsChangedChart(!isChangedChart),
      label: isChangedChart ? 'View as portrait' : 'View as landscape',
      state: true,
    },
  ];

  if (!isExpandTableView) {
    options = options.filter((option) => option.id !== 'view');
  }

  const handleCloseExpandedView = (): void => {
    setIsExpandedView(false);
    if (dataToReturnDashboard && type === 'histogram') {
      dispatch(fetchMetricsData(dataToReturnDashboard));
    }
  };

  return (
    <div
      style={{
        backgroundColor: 'rgba(28, 27, 27, 0.85)',
      }}
      className="fixed inset-0 flex items-center justify-center z-modal overflow-auto custom-scrollbar"
    >
      <div className="absolute inset-0 top-8 transition-opacity ">
        <div className="flex justify-between text-litlingo-white h-5">
          <div className="flex ml-10 items-center">
            <span className="whitespace-no-wrap">{title}</span>
            <div className="mx-2">|</div>
            <Select options={options} value="Options" />
          </div>
          <div className=" flex ml-2 mr-8 align-middle">
            {allowDownloadCsv && (
              <Permissions action="analytics.downloadCsv">
                <button
                  type="button"
                  className="focus:outline-none"
                  onClick={(): void => onExportClick()}
                >
                  {DOWNLOAD}
                </button>
              </Permissions>
            )}
            <button
              type="button"
              className="ml-4 pt-1 focus:outline-none "
              onClick={(): void => handleCloseExpandedView()}
            >
              {CLOSE_ICON}
            </button>
          </div>
        </div>
      </div>
      <div
        style={isChangedChart ? styles.horizontal : styles.vertical}
        className="absolute inset-0 bg-white  shadow-xl transform transition-all max-h-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        {isExpandTableView ? (
          <div className="max-h-full flex px-15 py-16">
            <WidgetTable
              headers={headers}
              rows={rows}
              reversed={reversed}
              setReversed={setReversed}
              isExpandedView
            />
          </div>
        ) : (
          <div className="flex flex-col justify-between h-full w-full bg-white px-15 py-16">
            <div className={`text text-2xl text--title text--bold line-height-100 `}>{title}</div>
            <div
              className={`h-full flex flex-col pt-4 
              }`}
            >
              {children}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WidgetExpandedView;
