import moment from 'moment-timezone';
import React from 'react';
import { useParams } from 'react-router-dom';
import { getCustomerDashboardConfig } from 'selectors/dashboard';
import { getNavParams } from 'selectors/nav';
import { useSelector } from 'store';
import { DefaultWidgetComponentProps } from 'types/dashboard';
import { TIME_RANGE_FIELD, useTimeRange } from 'utils/timeRanges';
import { OPTIONS } from '../Filters/TimeRangeFilterRedesign';

type RouterParams = {
  activeUuid?: string;
};

const StaticDateRange: React.FC<DefaultWidgetComponentProps> = ({ subtitle }) => {
  const { activeUuid } = useParams<RouterParams>();

  const dashboardConfig = useSelector(getCustomerDashboardConfig(activeUuid));

  const range = useTimeRange();
  const timeRange = useSelector((state) => getNavParams(state)[TIME_RANGE_FIELD] || '');

  const after =
    range.created_after != null
      ? // @ts-ignore
        moment(range.created_after).tz(dashboardConfig.primary_timezone)
      : null;
  const before =
    range.created_before != null
      ? // @ts-ignore
        moment(range.created_before).tz(dashboardConfig.primary_timezone)
      : null;

  let createdAfter = after?.format('MM/DD/yyyy');

  if (createdAfter && new Date(createdAfter).getTime() <= 0) {
    createdAfter = undefined;
  }

  const createdBefore = before?.format('MM/DD/yyyy');

  const currentRange = [...OPTIONS, { label: 'Past 6 Months', value: '-6months' }].find(
    ({ value }) => value === timeRange
  );

  return (
    <div className="flex flex-col">
      <div className="flex items-center mt-4">
        <h2 className="font-bold text-base mr-1">{currentRange?.label || 'Date Range'}</h2>
        <div className="text-base">
          {createdAfter} {createdAfter ? '-' : null} {createdBefore}
        </div>
      </div>
      {subtitle && <div className="mt-1">{subtitle}</div>}
    </div>
  );
};

export default StaticDateRange;
