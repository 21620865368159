import type { PartialConfig } from './base';

const development: PartialConfig = {
  backend: {
    base: 'https://dev.litlingo.com/api/v1',
  },
  google: {
    provider: 'google',
    clientId: '425754279135-k1932p6d3asssdrp6u3i93nftkq98123.apps.googleusercontent.com',
  },
};

export default development;
