/* eslint-disable max-lines */
/* eslint-disable camelcase */
/* eslint-disable react/require-default-props */
import { CommunicationEnvelope, EntityTag, Tag } from '@litlingo/client';
import {
  addBulkEnvelopeTags,
  fetchAllTagGroups,
  fetchTagsForFilter,
  removeBulkEnvelopeTags,
} from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import useClickOutside from 'components/utils/useClickOutside';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { useDispatch, useSelector } from 'react-redux';
import { getFetchAllTagsLoading, getTagsForFilters, getTagsTotalCount } from 'selectors/tags';
import logEvent from 'utils/analytics';

type ResourceFilterProps = {
  className?: string;
  dataTestid?: string;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  envelope: CommunicationEnvelope;
  envelopeTags: EntityTag[] | undefined;
};

const EnvelopeTagButton: React.FC<ResourceFilterProps> = (props) => {
  const { className, dataTestid, handleClose, envelope, envelopeTags } = props;

  const dispatch = useDispatch();

  const [offset, setOffset] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);
  const allTags = useSelector(getTagsForFilters);
  const totalCountTags = useSelector(getTagsTotalCount);

  const loadingTags = useSelector(getFetchAllTagsLoading);

  const loading = loadingTags;

  const handleClickOutside = useCallback(() => {
    handleClose(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useClickOutside(wrapperRef, handleClickOutside);

  useEffect(() => {
    dispatch(
      fetchAllTagGroups({
        limit: 25,
        broad_search: debouncedSearchValue,
      })
    );
    dispatch(
      fetchTagsForFilter({
        searchValue: debouncedSearchValue,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedSearchValue(searchValue), 300);
    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  useEffect(() => {
    const scrollHandler = (e: WheelEvent): void => {
      e.stopPropagation();
    };

    const ref = wrapperRef.current;

    ref?.addEventListener('wheel', scrollHandler, { passive: false });
    return () => ref?.removeEventListener('wheel', scrollHandler);
  }, []);

  const handleInputChange = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    logEvent('envelopes-list-filter-models');

    const { id: state } = event.currentTarget;

    const item =
      allTags.find((t) => t.uuid === state) ||
      envelopeTags?.map((tag) => tag.tag_value).find((t) => t && t.uuid === state);

    event.currentTarget.dataset.checked =
      event.currentTarget.dataset.checked === 'true' ? 'false' : 'true';

    if (item) {
      if (event.currentTarget.dataset.checked === 'true') {
        dispatch(
          addBulkEnvelopeTags({
            uuids: [envelope.uuid],
            value: item.value,
          })
        );
      } else {
        dispatch(
          removeBulkEnvelopeTags({
            uuids: [envelope.uuid],
            value: item.value,
          })
        );
      }
    }
    event.stopPropagation();
    handleClose(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();
    const value = e?.target.value.toLowerCase();
    setSearchValue(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();

      // If allowNew is true and the input value is not already a tag, add it as a new tag
      if (searchValue.trim()) {
        dispatch(
          addBulkEnvelopeTags({
            uuids: [envelope.uuid],
            value: searchValue,
          })
        );
        setSearchValue('');
        handleClose(false);
      }
    }
  };

  const handleLoadMore = (): void => {
    dispatch(
      fetchTagsForFilter({
        limit: 25,
        searchValue: debouncedSearchValue,
        offset: offset + 25,
      })
    );
    setOffset((o) => o + 25);
  };

  const sortTags = (tags: Tag[], filteredTags: EntityTag[] | undefined): Tag[] => {
    const sortedTags: Tag[] = [];
    if (filteredTags && !searchValue) {
      const tagValues = filteredTags?.map((tag) => tag?.tag_value);
      tagValues?.forEach((tag) => sortedTags.push(tag as Tag));
    }
    tags?.forEach((tag) => {
      if (!sortedTags.find((t) => t.uuid === tag.uuid)) {
        sortedTags.push(tag);
      }
    });

    return sortedTags;
  };

  const tags = sortTags(allTags, envelopeTags);

  return (
    <div
      ref={wrapperRef}
      className={`flex flex-col w-45 rounded-md z-100 ${className || ''}`}
      style={{ boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)' }}
    >
      <div className="relative flex flex-row justify-start items-center w-full min-h-8  px-2  bg-litlingo-white envelope-list-tags border-b border-litlingo-gray-2 rounded-t-md">
        <input
          ref={inputRef}
          id="search"
          name="search"
          className="react-tags__search-input max-h-5 w-full envelope-list-tags ml-1 font-normal text-base text-litlingo-gray-5"
          placeholder="Add tag"
          data-testid={dataTestid}
          type="text"
          onChange={handleSearchChange}
          onFocus={(e): void => e.stopPropagation()}
          onClick={(e): void => e.stopPropagation()}
          value={searchValue}
          autoComplete="off"
          onKeyDown={handleKeyDown}
        />
      </div>

      <LoadingOverlayWrapper active={loading} spinner={<LoadingIndicator />} fadeSpeed={0}>
        <div className="w-full min-h-8 max-h-52 overflow-auto rounded-b-md  bg-litlingo-white custom-scrollbar ">
          <fieldset className="ml-1.5">
            {tags.length ? (
              tags.map((item: Tag, idx) => (
                <div key={item.uuid} className={idx !== 0 ? 'mt-2' : 'mt-0.5'}>
                  <div
                    id={item.uuid}
                    role="button"
                    aria-hidden
                    className="relative flex items-center cursor-pointer "
                    data-checked={
                      envelopeTags
                        ? !!envelopeTags.find((t) => t.tag_value_uuid === item.uuid)
                        : false
                    }
                    onClick={(e): void => handleInputChange(e)}
                  >
                    <div className="text-sm leading-5 flex flex-col items-start justify-center cursor-pointer">
                      <div className="flex flex-row items-center cursor-pointer">
                        <div className="relative flex justify-start items-start">
                          <div className="flex items-center h-5 mr-1">
                            <input
                              id={item.uuid}
                              data-testid={`${item.uuid}-checkbox`}
                              type="checkbox"
                              className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out cursor-pointer litlingo-checkbox"
                              checked={
                                envelopeTags
                                  ? !!envelopeTags.find((t) => t.tag_value_uuid === item.uuid)
                                  : false
                              }
                            />
                          </div>

                          <div className="flex flex-col text-sm leading-5 self-end">
                            <span className="text-litlingo-gray-6 text-xxs text-left  font-normal select-none break-word">
                              {item.value}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-small">No tags found</div>
            )}
          </fieldset>
          {offset + 25 < totalCountTags - 1 && (
            <button
              type="button"
              className="w-full py-1 text-small border-t border-litlingo-gray-2 focus:outline-none"
              onClick={(e): void => {
                e.stopPropagation();
                handleLoadMore();
              }}
            >
              <span className="text-litlingo-primary">Load More</span>
            </button>
          )}
        </div>
      </LoadingOverlayWrapper>
    </div>
  );
};

export default EnvelopeTagButton;
