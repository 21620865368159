import { HighlightedSentence } from '@litlingo/react';
import { MESSAGE_TYPES } from 'components/utils/EventOptions';
import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getEventWholeSetences } from 'selectors/events';
import { getPermissionsPolicy } from 'selectors/permissions';
import type { UUID } from 'types';
import { getFieldAuthorization } from 'utils/permissions';

type ComponentProps = {
  eventId: UUID;
  loading: boolean;
  messageType: string;
};

const AbridgedWholeSentence: React.FC<ComponentProps> = ({ eventId, loading, messageType }) => {
  const wholeSentence = useSelector(getEventWholeSetences(eventId));
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const entityFieldLevel = getFieldAuthorization('communications.entity', user.roles, policy);

  if (loading && !wholeSentence) return null;

  return (
    <>
      {messageType === MESSAGE_TYPES.WHOLE_SENTENCE && wholeSentence && (
        <>
          {wholeSentence.map((sentence, idx) => (
            <div key={sentence.index} className={idx !== 0 ? 'mt-2' : ''}>
              <HighlightedSentence
                nodes={sentence.nodes}
                useHover={user.roles.includes('super-admin')}
                protectedFields={{
                  entity: entityFieldLevel,
                }}
              />
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default AbridgedWholeSentence;
