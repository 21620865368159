import React from 'react';

type ButtonProps = {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  outline?: boolean;
};

const Button: React.FC<ButtonProps> = (props) => {
  const { children, onClick, outline } = props;

  const classes = outline
    ? 'bg-white hover:bg-litlingo-primary hover:text-white text-litlingo-primary'
    : 'bg-litlingo-primary hover:opacity-75 text-white';

  return (
    <button
      type="button"
      className={`border border-litlingo-primary duration-150 ease-in-out focus:outline-none px-4 py-2 rounded transition ${classes}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
Button.defaultProps = {
  outline: false,
};

export default Button;
