import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useCategories from 'hooks/categories/useCategories';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';
import React from 'react';
import { useSelector } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';

const CategoryListCount: React.FC = () => {
  const navParams = useSelector(getNavParamsByResource(resourceQueryParamName.category));
  const offset = parseInt(navParams.offset as string, 10);

  const { data, isFetching } = useCategories();

  if (!data) return null;

  return (
    <div>
      <div className={`${isFetching ? 'text-litlingo-gray-2' : ''}`}>
        <span className="font-bold">{` ${data.records.length === 0 ? 0 : offset + 1}-${
          offset + data.records.length
        }`}</span>
        {` of ${
          data.count >= 10000
            ? '+10,000'
            : pluralize('use case'.split(' ').map(capitalize).join(' '), data.count, true)
        }`}
      </div>
    </div>
  );
};

export default CategoryListCount;
