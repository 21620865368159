import { setURLParams } from 'actions';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import { oldToNewLabel } from 'components/utils/EventOptions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';

type ComponentProps = {
  className?: string;
  resource: string;
};

const EventsListStateFilter: React.FC<ComponentProps> = (props) => {
  const { className, resource } = props;

  const dispatch = useDispatch();
  const filters = useSelector(getNavParamsByResource(resource)).states || [];

  const handleChange = (value: string): void => {
    const idx = filters.indexOf(value);
    if (idx === -1) {
      dispatch(
        setURLParams({ [`${resource}__states`]: [...filters, value], [`${resource}__offset`]: '0' })
      );
    } else {
      dispatch(
        setURLParams({
          [`${resource}__states`]: [...filters.slice(0, idx), ...filters.slice(idx + 1)],
          [`${resource}__offset`]: '0',
        })
      );
    }
  };

  const capitalize = (label: string): string => {
    if (label.length > 0) {
      return [label[0].toUpperCase()].concat(label.slice(1)).join('');
    }
    return label;
  };

  return (
    <MultiSelectDropdown
      className={className || ''}
      title="Status"
      dataTestid="events-filter-status"
      onChange={handleChange}
      options={Object.entries(oldToNewLabel)
        .sort((a, b) => {
          if (filters.includes(a[0])) return -1;
          if (filters.includes(b[0])) return 1;
          return 0;
        })
        .map(([key, label]) => ({
          value: key,
          label: capitalize(label),
        }))}
      selectedItems={filters as string[]}
    />
  );
};

export default EventsListStateFilter;
