import { API, TestCase } from '@litlingo/client';
import LoadingIndicator from 'components/LoadingIndicator';
import React from 'react';
import { useSelector } from 'react-redux';
import { getSelectedNode } from 'selectors/config';
import RuleManagerSerchBar from '../RuleManagerSearchBar';
import RuleManagerTestSuiteSidebarList from './RuleManagerTestSuiteSidebarList';

interface RuleManagerAddNewSideBarProps {
  setSearchItem: (value: React.SetStateAction<TestCase | null>) => void;
  isLoading: boolean;
  setSearch: (value: React.SetStateAction<string>) => void;
  search: string;
  data: API.WrappedAPIResponse<TestCase> | null;
  searchItem: TestCase | null;
  handleSelectSearchItem: (item: TestCase) => void;
  errors: { [key: string]: { message: string } };
  setTestString: (value: React.SetStateAction<string>) => void;
  testString: string;
  shouldMatch: boolean | null;
  handleChangeShouldMatch: (value: boolean) => void;
  addNewTestCase: () => void;
  setAddModal: (value: React.SetStateAction<boolean>) => void;
  nodeSelected: number | null;
  addBranchesLoadingState: boolean;
  testCases: TestCase[];
  handleAddFromSameModel: (selectedTests: string[]) => void;
}

const RuleManagerAddNewSidebar: React.FC<RuleManagerAddNewSideBarProps> = ({
  setSearchItem,
  isLoading,
  setSearch,
  search,
  data,
  searchItem,
  handleSelectSearchItem,
  errors,
  setTestString,
  testString,
  shouldMatch,
  handleChangeShouldMatch,
  addNewTestCase,
  setAddModal,
  nodeSelected,
  addBranchesLoadingState,
  testCases,
  handleAddFromSameModel,
}) => {
  const [selected, setSelected] = React.useState<string[]>([]);

  const selectedNode = useSelector(getSelectedNode);

  const handleChangeSelections = (name: string): void => {
    if (selected.includes(name)) {
      setSelected(selected.filter((item) => item !== name));
    } else {
      setSelected([...selected, name]);
    }
  };

  const handleChangeRowMatch = (): void => {
    handleAddFromSameModel(selected);
  };

  if (addBranchesLoadingState) {
    return <LoadingIndicator size="5" className="self-center" />;
  }

  return (
    <div className="flex flex-col">
      <div
        className="flex items-center pl-4 pr-2.5 py-2.5 bg-litlingo-green-light min-h-13"
        role="button"
        aria-hidden="true"
        data-testid="collapse-button"
      >
        <div className="w-full flex flex-row justify-between items-center gap-3">
          <div className="text-heading-3 overflow-visible">Add New</div>
        </div>
      </div>

      <div className="p-4 flex flex-col gap-2">
        <RuleManagerSerchBar
          className="w-full"
          handleSearch={(value): void => {
            setSearchItem(null);
            setSearch(value);
          }}
          placeholder="Search all test cases"
          value={search}
          data={data}
          isLoading={isLoading}
          handleClick={handleSelectSearchItem}
        />

        {!searchItem && (
          <>
            {nodeSelected && nodeSelected !== 0 && nodeSelected !== -1 ? (
              <h3 className="font-bold text-lg">OR</h3>
            ) : null}
            <div className="flex flex-row gap-1">
              <span>Test Case</span>
              <span className="litlingo-red-color">*</span>
            </div>
            <textarea
              id="test-case-input"
              name="test-case-input"
              rows={3}
              placeholder="Enter your sentence here"
              className="form-input w-full text-litlingo-gray-6 text-body placeholder-italic placeholder-litlingo-gray-6 resize-none"
              value={testString}
              onChange={(e): void => setTestString(e.target.value)}
            />
            {errors.testString && (
              <span className="text-litlingo-alert">{errors.testString.message}</span>
            )}
          </>
        )}
        <div className="flex flex-col gap-1">
          <div className="flex flex-row gap-1">
            <span>This test case should</span>
            <span className="litlingo-red-color">*</span>
          </div>
          <label htmlFor="match" className="flex flex-row items-center gap-1">
            <input
              type="radio"
              id="match"
              name="match"
              className="input-radio"
              checked={shouldMatch === true}
              onChange={(): void => handleChangeShouldMatch(true)}
            />
            <span>Match</span>
          </label>
          <label htmlFor="no-match" className="flex flex-row items-center gap-1">
            <input
              type="radio"
              id="no-match"
              name="no-match"
              className="input-radio"
              checked={shouldMatch === false}
              onChange={(): void => handleChangeShouldMatch(false)}
            />
            <span>Not match</span>
          </label>
          {errors.shouldMatch && (
            <span className="text-litlingo-alert">{errors.shouldMatch.message}</span>
          )}
        </div>
        <div className="flex flex-row gap-2 items-center justify-end w-full text-body">
          <button
            type="button"
            onClick={(): void => setAddModal(false)}
            className="button button--secondary"
          >
            <span className="font-bold">Cancel</span>
          </button>
          <button
            className="button button--primary text-body"
            type="button"
            onClick={addNewTestCase}
          >
            <span className="font-bold text-white">Add</span>
          </button>
        </div>
      </div>
      {!selectedNode ||
        (selectedNode > 0 && (
          <>
            <div
              className="flex items-center pl-4 pr-2.5 py-2.5 bg-litlingo-green-light min-h-13"
              role="button"
              aria-hidden="true"
              data-testid="collapse-button"
            >
              <div className="w-full flex flex-row justify-between items-center gap-3">
                <div className="text-heading-3 overflow-visible">Teset cases on this model</div>
              </div>
            </div>

            <RuleManagerTestSuiteSidebarList
              handleChangeRowMatch={handleChangeRowMatch}
              handleChangeSelections={handleChangeSelections}
              handleUnlink={(): void => {
                /* noop */
              }}
              selected={selected}
              selectedNode={-1}
              setSelected={setSelected}
              testCases={testCases}
              removeBranchesLoadingState={false}
            />
          </>
        ))}
    </div>
  );
};

export default RuleManagerAddNewSidebar;
