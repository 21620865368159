import { cleanChangedNodes } from 'actions';
import { updateRule } from 'actions/ruleGroup';
import React from 'react';
import { useDispatch } from 'react-redux';
import type { MRuleRevision } from 'types';

type ComponentProps = {
  rule: MRuleRevision;
};

const RuleManagerSaveButton: React.FC<ComponentProps> = ({ rule }) => {
  const dispatch = useDispatch();

  const handleSaveClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    evt.stopPropagation();
    dispatch(updateRule({ rule }));
    dispatch(cleanChangedNodes());
  };

  return (
    <button
      type="button"
      onClick={(evt): void => handleSaveClick(evt)}
      className="button button--primary text-body h-8 w-20 justify-center"
      data-testid="rule-mananger-save"
      id="save-rule-button"
    >
      <span className="text-white font-bold ">Save</span>
    </button>
  );
};

export default RuleManagerSaveButton;
