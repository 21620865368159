/* eslint-disable camelcase */
import { prepareUndeleteAlert, showErrorAlert, showSuccessAlert } from 'actions/alerts';
import { clearTestSentence } from 'actions/communication';
import {
  addAnnotationMatcherToRule,
  cloneRule,
  cloneRuleFailure,
  cloneRuleRequest,
  cloneRuleSuccess,
  createRuleConfig,
  createRuleGroup,
  createRuleGroupFailure,
  createRuleGroupRequest,
  createRuleGroupSuccess,
  deleteRule,
  deleteRuleFailure,
  deleteRuleRequest,
  deleteRuleSuccess,
  fetchAllRuleGroups,
  fetchAllRuleGroupsFailure,
  fetchAllRuleGroupsRequest,
  fetchAllRuleGroupsSuccess,
  fetchAllRuleGroupsWithSummary,
  fetchAllRulesDebounced,
  fetchFilterRules,
  fetchRelationshipsSuccess,
  fetchRuleCategory,
  fetchRuleCategoryFailure,
  fetchRuleCategoryFulfill,
  fetchRuleCategoryRequest,
  fetchRuleCategorySuccess,
  fetchRuleConfigSuccess,
  fetchRuleContext,
  fetchRuleContextFailure,
  fetchRuleContextRequest,
  fetchRuleContextSuccess,
  fetchRuleCustomers,
  fetchRuleCustomersFailure,
  fetchRuleCustomersFulfill,
  fetchRuleCustomersRequest,
  fetchRuleCustomersSuccess,
  fetchRulesForFilterPills,
  fetchRulesForFilterPillsFailure,
  fetchRulesForFilterPillsRequest,
  fetchRulesForFilterPillsSuccess,
  fetchSingleRuleGroup,
  fetchSingleRuleGroupFailure,
  fetchSingleRuleGroupRequest,
  fetchSingleRuleGroupSuccess,
  fetchTestCaseSummaryRuleGroup,
  fetchTestCaseSummaryRuleGroupFailure,
  fetchTestCaseSummaryRuleGroupRequest,
  fetchTestCaseSummaryRuleGroupSuccess,
  previewRuleGroup,
  previewRuleGroupFailure,
  previewRuleGroupFulfill,
  previewRuleGroupRequest,
  previewRuleGroupSuccess,
  publishRuleVersion,
  publishRuleVersionFailure,
  publishRuleVersionRequest,
  publishRuleVersionSuccess,
  saveRelationshipsSuccess,
  saveRuleConfigSuccess,
  saveRuleFailure,
  saveRuleRequest,
  saveRuleSuccess,
  scheduleCompare,
  scheduleCompareFailure,
  scheduleCompareRequest,
  scheduleCompareSuccess,
  setShowUtilization,
  undoRuleVersion,
  undoRuleVersionFailure,
  undoRuleVersionRequest,
  undoRuleVersionSuccess,
  updateConfigGroups,
  updateRule,
} from 'actions/ruleGroup';
import { apiClient as LitLingoClient, apiClientV2 as LitLingoClientV2 } from 'client';
import { push } from 'connected-react-router';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import type { GlobalState } from 'reducers';
import constructConfigTree from 'reducers/util/constructConfigTree';
import { normalizeRuleConfig } from 'reducers/util/flattenConfigData';
import type { Channel } from 'redux-saga';
import { buffers } from 'redux-saga';
import {
  actionChannel,
  all,
  call,
  debounce,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { getCustomerDomain } from 'selectors/auth';
import { getHasUnsavedChanges, getSelectedNode } from 'selectors/config';
import { getNavParamsByResource } from 'selectors/nav';
import { getConfigRuleAsArray } from 'selectors/ruleGroup';
import { getSavedSearch } from 'selectors/savedSearches';
import type {
  API,
  APIV2,
  MRuleConfigNode,
  Rule,
  RuleConfig,
  SagaReturn,
  SavedSearch,
  UUID,
} from 'types';
import { buildObjFromQSEntries, reverse } from 'utils/urls';
import { v4 as uuidv4, v4 } from 'uuid';

function* updateRuleSaga(action: ReturnType<typeof updateRule>): SagaReturn {
  const { payload } = action;
  const { config, relationship } = (yield select()) as GlobalState;
  const { rule } = payload;
  const configData = constructConfigTree(rule.rootConfigId, config.items, relationship);

  const data = {
    name: rule.name,
    description: rule.description,
    config: configData,
    uuid: rule.uuid,
    keep_lockstep: 'true',
  };

  yield put(saveRuleRequest());
  yield put(clearTestSentence());

  const response = (yield call(
    [LitLingoClientV2.resources.ruleGroups.extras, 'upsertRuleRevision'],
    {
      params: { relationships: ['identifiers', 'identifiers.identifier'] },
      urlParams: { ruleId: rule.rule_group_uuid },
      data,
    }
  )) as API.Response<APIV2.RuleGroups.UpsertRuleRevision>;

  if (response.error != null) {
    yield put(saveRuleFailure(response.error));
  } else {
    const rootConfigId = response.data.config.uuid || uuidv4();

    yield put(saveRuleSuccess({ ...response.data, rootConfigId }));
    yield put(showSuccessAlert('Saved Model'));

    const newConfig = {
      ...response.data.config,
      rootConfigId,
    };
    const { ruleConfig, relationships } = normalizeRuleConfig({
      config: newConfig,
      // @ts-expect-error starship
      rule: response.data,
      useIdentifier: true,
    });

    yield put(fetchRuleConfigSuccess(ruleConfig));
    yield put(fetchRelationshipsSuccess(relationships));
    yield put(setShowUtilization(true));
  }
}

function* fetchAllRuleGroupsSaga({ payload }: ReturnType<typeof fetchAllRuleGroups>): SagaReturn {
  yield put(fetchAllRuleGroupsRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.ruleGroups)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = {
    ...payload,
    include_count: true,
    ...resourceParams,
    fuzzy_name_search: 'true',
    relationships: ['created_by', 'updated_by'],
  };

  const response = (yield call([LitLingoClientV2.resources.ruleGroups, 'list'], {
    params,
  })) as API.Response<APIV2.RuleGroups.List>;
  if (response.error != null) {
    yield put(fetchAllRuleGroupsFailure(response.error));
  } else {
    yield put(fetchAllRuleGroupsSuccess(response.data));
  }
}

function* fetchAllRuleGroupsWithSummarySaga({
  payload,
}: ReturnType<typeof fetchAllRuleGroups>): SagaReturn {
  yield put(fetchAllRuleGroupsRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.ruleGroups)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = {
    ...payload,
    include_count: true,
    ...resourceParams,
    relationships: ['created_by', 'updated_by'],
    fuzzy_name_search: 'true',
  };

  const response = (yield call([LitLingoClientV2.resources.ruleGroups, 'list'], {
    params,
  })) as API.Response<APIV2.RuleGroups.List>;
  if (response.error != null) {
    yield put(fetchAllRuleGroupsFailure(response.error));
  } else {
    yield put(fetchAllRuleGroupsSuccess(response.data));
    yield all(
      response.data.records.map((rule) =>
        put(fetchTestCaseSummaryRuleGroup({ rule_uuids: rule.uuid }))
      )
    );
  }
}

function* fetchFiltersRulesSaga({ payload }: ReturnType<typeof fetchFilterRules>): SagaReturn {
  yield put(fetchAllRuleGroupsRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.rule)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = {
    ...payload,
    include_count: true,
    ...resourceParams,
    selectable_fields: ['name', 'uuid', 'rule_uuid'],
  };

  const response = (yield call([LitLingoClient.resources.rules, 'list'], {
    params,
  })) as API.Response<API.Rules.List>;
  if (response.error != null) {
    yield put(fetchAllRuleGroupsFailure(response.error));
  } else {
    yield put(fetchAllRuleGroupsSuccess(response.data));
  }
}

function* fetchRulesForFilterPillsSaga(
  action: ReturnType<typeof fetchRulesForFilterPills>
): SagaReturn {
  const { ruleIds } = action.payload;

  const params = { uuids: ruleIds, include_pii: true };
  yield put(fetchRulesForFilterPillsRequest());

  const response = (yield call([LitLingoClient.resources.rules, 'list'], {
    params,
  })) as API.Response<API.Rules.List>;
  if (response.error != null) {
    yield put(fetchRulesForFilterPillsFailure(response.error));
  } else {
    yield put(fetchRulesForFilterPillsSuccess(response.data));
  }
}

function* fetchSingleRuleSaga({ payload }: ReturnType<typeof fetchSingleRuleGroup>): SagaReturn {
  yield put(fetchSingleRuleGroupRequest());

  const { ruleId } = payload;

  const unsavedChanges = (yield select(getHasUnsavedChanges)) as boolean;

  if (unsavedChanges) {
    return;
  }

  const response = (yield call([LitLingoClientV2.resources.ruleGroups, 'retrieve'], ruleId, {
    params: {
      relationships: ['updated_by', 'model_groups'],
      include_pii: true,
    },
  })) as API.Response<APIV2.RuleGroups.Retrieve>;
  if (response.error != null) {
    yield put(fetchSingleRuleGroupFailure(response.error));
  } else {
    const responseTwo = (yield call(
      [LitLingoClientV2.resources.ruleGroups.extras, 'getRevisions'],
      {
        params: {
          rule_uuids: [response.data.uuid],
          relationships: ['identifiers', 'identifiers.identifier', 'updated_by'],
          order_by: 'created_at',
          order_desc: 'true',
          include_pii: true,
          heads_only: 'true',
        },
      }
    )) as API.Response<APIV2.RuleGroups.GetRevisions>;

    if (responseTwo.error != null) {
      yield put(fetchSingleRuleGroupFailure(responseTwo.error));
    } else {
      const latestRuleRevision = responseTwo.data.records[0];

      const rootConfigId = latestRuleRevision.config.uuid || uuidv4();
      yield put(
        fetchSingleRuleGroupSuccess({
          ruleGroup: response.data,
          ruleRevision: { ...latestRuleRevision, rootConfigId },
        })
      );
      const config = {
        ...latestRuleRevision.config,
        rootConfigId,
      };
      const { ruleConfig, relationships } = normalizeRuleConfig({
        config,
        // @ts-ignore
        rule: latestRuleRevision,
        useIdentifier: true,
      });

      yield put(fetchRuleConfigSuccess(ruleConfig));
      yield put(fetchRelationshipsSuccess(relationships));
    }
  }
}

export function* createRuleGroupSaga({ payload }: ReturnType<typeof createRuleGroup>): SagaReturn {
  const { name, navigate = true } = payload;

  yield put(createRuleGroupRequest());

  const response = (yield call([LitLingoClientV2.resources.ruleGroups, 'upsert'], {
    data: { name },
  })) as API.Response<APIV2.RuleGroups.Upsert>;
  if (response.error != null) {
    yield put(createRuleGroupFailure(response.error));
  } else {
    const responseTwo = (yield call(
      [LitLingoClientV2.resources.ruleGroups.extras, 'upsertRuleRevision'],
      {
        urlParams: { ruleId: response.data.uuid },
        data: {
          name,
          description: '',
          config: {
            operands: [],
            operator: 'AND',
            uuid: v4(),
          },
        },
      }
    )) as API.Response<APIV2.RuleGroups.UpsertRuleRevision>;

    if (responseTwo.error != null) {
      yield put(createRuleGroupFailure(responseTwo.error));
    } else if (navigate) {
      yield put(createRuleGroupSuccess());

      const customerDomain = (yield select(getCustomerDomain)) as string;
      const path = reverse({
        routeName: 'global-rule-group-manager',
        routeParams: {
          ruleId: response.data.uuid,
        },
        customerDomain,
      });
      yield put(push(path));
    }
  }
}

function* addAnnotationMatcherToRuleSaga(
  action: ReturnType<typeof addAnnotationMatcherToRule>
): SagaReturn {
  const { payload } = action;
  const { annotator, rule, dropIndex } = payload;

  const nodeIdx: number = dropIndex ?? ((yield select(getSelectedNode)) as number);
  const arrayTree: MRuleConfigNode[] = (yield select(getConfigRuleAsArray)) as MRuleConfigNode[];

  if (nodeIdx && arrayTree[nodeIdx]) {
    const { name } = arrayTree[nodeIdx];
    if (name !== 'AND' && name !== 'OR' && name !== 'RELATIONSHIP_MATCH') {
      yield put(showErrorAlert("Can't add identifier to selected item"));
      return;
    }
  }

  const newId = uuidv4();
  const ruleConfig = {
    [newId]: {
      name: annotator.name,
      parent: nodeIdx ? arrayTree[nodeIdx].id : rule.rootConfigId,
      annotatorId: annotator.uuid,
      typeOfConfig: 'ANNOTATION_MATCH' as const,
      negated: undefined,
      id: newId,
    },
  };

  // BAD:  I don't like how I need to do step 1,2 before 3
  yield put(createRuleConfig(ruleConfig));
  yield put(
    updateConfigGroups({
      parentId: nodeIdx ? arrayTree[nodeIdx].id : (rule.rootConfigId as UUID),
      newConfigId: newId,
    })
  );
}

function* cloneRuleSaga({ payload }: ReturnType<typeof cloneRule>): SagaReturn {
  yield put(cloneRuleRequest());

  const { ruleOutcomeId, campaignId } = payload;

  const response = (yield call([LitLingoClient.resources.campaigns.extras, 'clone'], {
    urlParams: { ruleOutcomeId, campaignId },
  })) as API.Response<API.Campaigns.CloneRule>;
  if (response.error != null) {
    yield put(cloneRuleFailure(response.error));
  } else {
    yield put(showSuccessAlert('Model Duplicated'));
    yield put(cloneRuleSuccess());
  }
}

type CreateRulePayload = {
  rule: {
    name: string;
    description: string;
    config: {
      operator: 'OR';
      operands: [];
    };
    campaignId: UUID;
    outcomes: UUID[];
  };
};

export function* createRule(payload: CreateRulePayload): SagaReturn<Rule | boolean> {
  const { rule } = payload;
  const response = (yield call([LitLingoClient.resources.rules, 'upsert'], {
    params: { relationships: ['annotators', 'annotators.annotator'] },
    data: rule,
  })) as API.Response<API.Rules.Upsert>;

  if (response.error != null) {
    yield put(saveRuleFailure(response.error));
    return false;
  }

  const newId = response.data.uuid;

  const rootConfigId = uuidv4();
  const newRule = {
    [newId]: {
      ...response.data,
      campaign_uuid: rule.campaignId,
      rootConfigId,
      outcomes: rule.outcomes,
    },
  };

  const configData = {
    ...({} as RuleConfig),
    rootConfigId,
  };

  const { ruleConfig, relationships } = normalizeRuleConfig({
    config: configData,
    rule: newRule[newId],
    useIdentifier: true,
  });

  yield put(saveRuleConfigSuccess(ruleConfig));
  yield put(saveRelationshipsSuccess(relationships));

  // @ts-ignore
  yield put(saveRuleSuccess(newRule));

  return newRule[newId];
}

function* deleteRuleSaga(action: ReturnType<typeof deleteRule>): SagaReturn {
  const { payload } = action;
  const { ruleId } = payload;

  yield put(deleteRuleRequest(ruleId));
  const response = (yield call(
    [LitLingoClientV2.resources.ruleGroups, 'delete'],
    ruleId
  )) as API.Response<APIV2.RuleGroups.Delete>;
  if (response.error != null) {
    yield put(deleteRuleFailure(response.error));
  } else {
    yield put(deleteRuleSuccess(ruleId));
    const customerDomain = (yield select(getCustomerDomain)) as string;
    const path = reverse({ routeName: 'global-models-list', customerDomain });
    yield put(push(path));
    yield put(
      prepareUndeleteAlert({
        resource: 'rules',
        id: ruleId,
        fetchAction: fetchAllRuleGroups,
      })
    );
  }
}

function* scheduleCompareSaga(action: ReturnType<typeof scheduleCompare>): SagaReturn {
  const { payload } = action;
  const { ruleUuids, savedSearchId } = payload;
  yield put(scheduleCompareRequest());

  const savedSearch = (yield select(getSavedSearch(savedSearchId))) as SavedSearch;
  const savedSearchUrl = savedSearch.url.replace(/envelopes__/g, '').replace('?', '');
  const urlParams = new URLSearchParams(savedSearchUrl);
  const params = buildObjFromQSEntries(urlParams.entries());
  const response = (yield call([LitLingoClient.resources.rules.extras, 'scheduleCompare'], {
    params,
    data: {
      saved_search_name: savedSearch.name,
      rule_uuids: ruleUuids,
    },
  })) as API.Response<unknown>;

  if (response.error != null) {
    yield put(scheduleCompareFailure(response.error));
  } else {
    yield put(scheduleCompareSuccess());
    yield put(
      showSuccessAlert(
        'Success. When processing completes a data export will be sent to your inbox'
      )
    );
  }
}

function* fetchTestCasesSummaryRuleSaga({
  payload,
}: ReturnType<typeof fetchTestCaseSummaryRuleGroup>): SagaReturn {
  const { rule_uuids: ruleUuid } = payload;

  yield put(fetchTestCaseSummaryRuleGroupRequest());

  const response = (yield call([LitLingoClient.resources.testCases.extras, 'summary'], {
    params: payload,
  })) as API.Response<API.Tests.Summary>;

  if (response.error != null) {
    yield put(fetchTestCaseSummaryRuleGroupFailure(response.error));
  } else {
    const data = {
      ...response.data,
      ruleUuid,
    };
    yield put(fetchTestCaseSummaryRuleGroupSuccess(data));
  }
}

function* publishRuleVersionSaga({ payload }: ReturnType<typeof publishRuleVersion>): SagaReturn {
  const { ruleId, version } = payload;

  yield put(publishRuleVersionRequest());

  const response = (yield call([LitLingoClient.resources.rules.extras, 'publishVersion'], {
    params: {
      relationships: ['annotators', 'annotators.annotator', 'updated_by'],
      include_pii: true,
    },
    urlParams: { ruleId, version },
  })) as API.Response<API.Rules.PublishVersion>;

  if (response.error != null) {
    yield put(publishRuleVersionFailure(response.error));
  } else {
    const rootConfigId = response.data.config.uuid || uuidv4();
    yield put(publishRuleVersionSuccess({ ...response.data, rootConfigId }));

    const config = {
      ...response.data.config,
      rootConfigId,
    };
    const { ruleConfig, relationships } = normalizeRuleConfig({
      config,
      rule: response.data,
      useIdentifier: true,
    });

    yield put(fetchRuleConfigSuccess(ruleConfig));
    yield put(fetchRelationshipsSuccess(relationships));
    yield put(showSuccessAlert('Version published.'));
  }
}

function* undoRuleVersionSaga({ payload }: ReturnType<typeof undoRuleVersion>): SagaReturn {
  const { ruleId } = payload;

  yield put(undoRuleVersionRequest());

  const response = (yield call([LitLingoClient.resources.rules.extras, 'undoVersion'], {
    urlParams: { ruleId },
    params: {
      relationships: ['annotators', 'annotators.annotator', 'updated_by'],
      include_pii: true,
    },
  })) as API.Response<API.Rules.UndoVersion>;

  if (response.error != null) {
    yield put(undoRuleVersionFailure(response.error));
  } else {
    const rootConfigId = response.data.config.uuid || uuidv4();
    yield put(undoRuleVersionSuccess({ ...response.data, rootConfigId }));

    const config = {
      ...response.data.config,
      rootConfigId,
    };
    const { ruleConfig, relationships } = normalizeRuleConfig({
      config,
      rule: response.data,
      useIdentifier: true,
    });

    yield put(fetchRuleConfigSuccess(ruleConfig));
    yield put(fetchRelationshipsSuccess(relationships));

    yield put(showSuccessAlert('Model restored to last version.'));
  }
}

function* previewRuleGroupSaga({ payload }: ReturnType<typeof previewRuleGroup>): SagaReturn {
  const {
    body,
    platform,
    ruleRevisionId,
    ruleGroupId,
    customer_uuid,
    force_v2_graphs = false,
  } = payload;

  yield put(previewRuleGroupRequest());

  let response = null;
  if (ruleGroupId) {
    if (customer_uuid) {
      response = (yield call([LitLingoClientV2.resources.ruleGroups.extras, 'previewGroup'], {
        urlParams: { ruleGroupId },
        data: {
          body,
          platform,
          force_v2_graphs,
          customer_uuid,
        },
      })) as API.Response<APIV2.RuleGroups.PreviewRevision>;
    } else {
      response = (yield call([LitLingoClientV2.resources.ruleGroups.extras, 'previewGroup'], {
        urlParams: { ruleGroupId },
        data: {
          body,
          platform,
          force_v2_graphs,
        },
      })) as API.Response<APIV2.RuleGroups.PreviewRevision>;
    }
  } else {
    response = (yield call([LitLingoClientV2.resources.ruleGroups.extras, 'preview'], {
      urlParams: { revisionId: ruleRevisionId },
      data: {
        body,
        platform,
        force_v2_graphs,
      },
    })) as API.Response<APIV2.RuleGroups.PreviewRevision>;
  }

  if (response.error != null) {
    yield put(previewRuleGroupFailure(response.error));
  } else {
    yield put(previewRuleGroupSuccess(response.data));
  }
  yield put(previewRuleGroupFulfill());
}

function* fetchRuleCustomersSaga({ payload }: ReturnType<typeof fetchRuleCustomers>): SagaReturn {
  yield put(fetchRuleCustomersRequest());

  const response = (yield call([LitLingoClientV2.resources.ruleGroups.extras, 'getCustomers'], {
    urlParams: { revisionsId: payload },
  })) as API.Response<APIV2.RuleGroups.GetCustomers>;

  if (response.error != null) {
    yield put(fetchRuleCustomersFailure(response.error));
  } else {
    yield put(fetchRuleCustomersSuccess(response.data));
  }
  yield put(fetchRuleCustomersFulfill());
}

function* fetchRuleCategorySaga(action: ReturnType<typeof fetchRuleCategory>): SagaReturn {
  const { payload } = action;
  yield put(fetchRuleCategoryRequest());
  const response = (yield call([LitLingoClientV2.resources.categories, 'list'], {
    params: {
      include_pii: 'true',
      relationships: ['model'],
      rule_uuids: payload.ruleId,
    },
  })) as API.Response<APIV2.Categories.List>;
  if (response.error != null) {
    yield put(fetchRuleCategoryFailure(response.error));
  } else {
    yield put(fetchRuleCategorySuccess(response.data.records[0]));
  }
  yield put(fetchRuleCategoryFulfill());
}

function* fetchRuleContextSaga(action: ReturnType<typeof fetchRuleContext>): SagaReturn {
  const { payload } = action;
  yield put(fetchRuleContextRequest());
  const response = (yield call([LitLingoClientV2.resources.ruleGroups, 'list'], {
    params: { uuids: payload.uuids },
  })) as API.Response<APIV2.RuleGroups.List>;
  if (response.error != null) {
    yield put(fetchRuleContextFailure(response.error));
  } else {
    yield put(fetchRuleContextSuccess(response.data.records));
  }
}

function* channels(): SagaReturn {
  const fetchAllRulesChan = (yield actionChannel(
    fetchAllRulesDebounced.toString(),
    buffers.sliding(1)
  )) as Channel<ReturnType<typeof fetchAllRulesDebounced>>;

  yield debounce(400, fetchAllRulesChan, fetchAllRuleGroupsSaga);
}

function* rulesSaga(): SagaReturn {
  yield takeLatest(cloneRule, cloneRuleSaga);
  yield takeEvery(fetchSingleRuleGroup.toString(), fetchSingleRuleSaga);
  yield takeEvery(createRuleGroup.toString(), createRuleGroupSaga);
  yield takeLatest(fetchAllRuleGroups.toString(), fetchAllRuleGroupsSaga);
  yield takeLatest(updateRule.toString(), updateRuleSaga);
  yield takeLatest(addAnnotationMatcherToRule.toString(), addAnnotationMatcherToRuleSaga);
  yield takeLatest(fetchRulesForFilterPills.toString(), fetchRulesForFilterPillsSaga);
  yield takeLatest(deleteRule.toString(), deleteRuleSaga);
  yield takeLatest(scheduleCompare.toString(), scheduleCompareSaga);
  yield takeEvery(fetchTestCaseSummaryRuleGroup.toString(), fetchTestCasesSummaryRuleSaga);
  yield takeLatest(publishRuleVersion.toString(), publishRuleVersionSaga);
  yield takeLatest(undoRuleVersion.toString(), undoRuleVersionSaga);
  yield takeLatest(fetchFilterRules.toString(), fetchFiltersRulesSaga);
  yield takeLatest(fetchAllRuleGroupsWithSummary.toString(), fetchAllRuleGroupsWithSummarySaga);
  yield takeLatest(previewRuleGroup.toString(), previewRuleGroupSaga);
  yield takeLatest(fetchRuleCustomers.toString(), fetchRuleCustomersSaga);
  yield takeLatest(fetchRuleCategory.toString(), fetchRuleCategorySaga);
  yield takeLatest(fetchRuleContext.toString(), fetchRuleContextSaga);
  // @ts-expect-error check types
  yield channels();
}

export default rulesSaga;
