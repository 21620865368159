import { labelOptions } from 'components/utils/EventOptions';
import React from 'react';

type ComponentProps = {
  label: string | null;
};

const EventStatus: React.FC<ComponentProps> = (props) => {
  const { label } = props;

  const getColor = (key: keyof typeof labelOptions): string => {
    if (labelOptions[key] === labelOptions.acceptable) return 'text text-litlingo-success';
    if (labelOptions[key] === labelOptions.violation) return 'text text-litlingo-warning';
    return 'text text--primary';
  };

  const getLabelIcon = (key: keyof typeof labelOptions): JSX.Element => {
    const acceptable = (
      <svg
        className="h-4 w-4 text-litlingo-success"
        fill="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        viewBox="0 0 20 20"
        stroke="currentColor"
      >
        <path d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zm12.73-1.41A8 8 0 104.34 4.34a8 8 0 0011.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z" />
      </svg>
    );

    const violation = (
      <svg
        className="h-4 w-4 text text-litlingo-warning"
        fill="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        viewBox="0 0 20 20"
        stroke="currentColor"
      >
        <path d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zm1.41-1.41A8 8 0 1015.66 4.34 8 8 0 004.34 15.66zm9.9-8.49L11.41 10l2.83 2.83-1.41 1.41L10 11.41l-2.83 2.83-1.41-1.41L8.59 10 5.76 7.17l1.41-1.41L10 8.59l2.83-2.83 1.41 1.41z" />
      </svg>
    );

    const pending = (
      <svg width="14" height="14">
        <circle cx="7" cy="7" r="5" stroke="#1c5579" strokeWidth="4" fill="none" />
      </svg>
    );
    if (labelOptions[key] === labelOptions.acceptable) return acceptable;
    if (labelOptions[key] === labelOptions.violation) return violation;
    return pending;
  };

  return (
    <>
      {getLabelIcon(label as keyof typeof labelOptions)}
      <span
        className={`${getColor(label as keyof typeof labelOptions)} font-semibold text-xs pl-2`}
      >
        {labelOptions[label as keyof typeof labelOptions]}
      </span>
    </>
  );
};

export default EventStatus;
