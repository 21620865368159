import { importTestCases } from 'actions';
import classNames from 'classnames';
import { TestCaseEntity } from 'constants/testCases';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { importTestCasesLoading } from 'selectors/testCases';
import { UUID } from 'types';

type ImportButtonProps = {
  entity?: TestCaseEntity;
  entityId?: UUID;
  className?: string;
};

const ImportButton: React.FC<ImportButtonProps> = (props) => {
  const { entity, entityId, className } = props;

  const dispatch = useDispatch();
  const loading = useSelector(importTestCasesLoading);
  const fileInputEl = useRef<HTMLInputElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation();

    if (fileInputEl.current != null) {
      fileInputEl.current.click();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files != null) {
      const [file] = e.target.files;
      dispatch(importTestCases({ file, entity, entityId }));
    }
  };

  return (
    <span className={classNames(className, 'rounded-md ')}>
      <input
        ref={fileInputEl}
        type="file"
        accept=".csv"
        onChange={handleChange}
        style={{ position: 'fixed', top: '-100em' }}
      />
      <button
        type="button"
        disabled={loading}
        onClick={handleClick}
        className={classNames(
          'flex flex-row justify-center button button--secondary-redesign font-bold h-8',
          {
            'disabled-button': loading,
          }
        )}
        data-testid="import-button"
      >
        <span>Import</span>
      </button>
    </span>
  );
};

export default ImportButton;
