import { MINUS_ICON, PLUS_ICON } from 'constants/filterIcons';
import React, { useEffect, useState } from 'react';

type ComponentProps = {
  headerTitle: string;
  customLoading?: boolean;
  children: JSX.Element;
  isDefaultClosed?: boolean;
  dataTestid?: string;
  extraAction?: JSX.Element | null;
  bulkReview?: boolean;
  open?: boolean;
  groupExtraAction?: boolean;
};

const EnvelopeCollapsableSection: React.FC<ComponentProps> = ({
  headerTitle,
  customLoading,
  children,
  isDefaultClosed,
  dataTestid,
  extraAction,
  bulkReview,
  open = false,
  groupExtraAction = false,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!!isDefaultClosed);

  useEffect(() => {
    if (open) {
      setIsCollapsed(false);
    }
  }, [open]);

  const handleCollapse = (): void => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div data-testid={dataTestid || 'section'} className="border-b border-litlingo-gray-1">
      <div
        className={`pl-4 pr-5 ${
          bulkReview ? 'bg-litlingo-secondary-80' : 'bg-litlingo-green-light'
        } ${extraAction ? 'py-2.5' : 'py-4'}`}
        role="button"
        onClick={handleCollapse}
        aria-hidden="true"
        data-testid="collapse-button"
      >
        {groupExtraAction ? (
          <div className="flex flex-row justify-between items-center gap-3">
            <div className="flex flex-row gap-2 items-center">
              <div className="overflow-visible title text-base font-bold leading-5 truncate">
                {headerTitle}
              </div>
              {customLoading && (
                <div className="flex float-left loader ease-linear rounded-full border-2 border-gray-200 h-4 w-4 self-center" />
              )}
              {extraAction}
            </div>
            <div className="w-4 flex-none">{isCollapsed ? PLUS_ICON() : MINUS_ICON()}</div>
          </div>
        ) : (
          <div className="flex flex-row justify-between items-center gap-3">
            <div className="overflow-visible title text-base font-bold leading-5 truncate">
              {headerTitle}
            </div>
            {customLoading && (
              <div className="flex float-left loader ease-linear rounded-full border-2 border-gray-200 h-4 w-4 self-center" />
            )}
            {extraAction}
            <div className="w-4 flex-none">{isCollapsed ? PLUS_ICON() : MINUS_ICON()}</div>
          </div>
        )}
      </div>
      {!isCollapsed && !customLoading && (
        <div className="relative" data-testid="collapsable-content">
          <div
            className={`flex flex-col ${
              isCollapsed ? 'litlingo-collapsed opacity-0' : 'opacity-100'
            }`}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default EnvelopeCollapsableSection;
