import { taggingEnvelope } from 'actions/envelopeListView';
import EnvelopeLabel from 'components/EnvelopeLabel';
import EnvelopeChip from 'components/EnvelopeLabel/EnvelopeChip';
import { PLUS_ICON, ROUNDED_PLUS_ICON } from 'constants/envelopeIcons';
import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useDispatch } from 'react-redux';
import type { CommunicationEnvelope, EntityTag } from 'types';
import EnvelopeTagButton from './EnvelopeTagButton';

type ComponentProps = {
  tags?: EntityTag[];
  className?: string;
  expanded?: boolean;
  envelope: CommunicationEnvelope;
  dropdown?: boolean;
};

const RenderTags: React.FC<ComponentProps> = ({
  tags,
  className,
  expanded,
  envelope,
  dropdown = true,
}) => {
  const dispatch = useDispatch();

  const [addTag, setAddTag] = useState(false);

  const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const { styles, attributes } = usePopper(ref.current, popperRef, {
    placement: 'bottom-start',
  });

  const renderDropdown = (): React.ReactPortal | null => {
    const portal = document.getElementById('dropdown-portal');

    const dropdownElement = (
      <div
        className="relative"
        ref={(node): void => {
          setPopperRef(node);
        }}
        style={{ ...styles.popper }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes.popper}
      >
        <EnvelopeTagButton handleClose={setAddTag} envelope={envelope} envelopeTags={tags} />
      </div>
    );

    if (portal) {
      return ReactDOM.createPortal(dropdownElement, portal);
    }
    return null;
  };

  return (
    <div
      ref={ref}
      className={`flex  gap-1 ${
        expanded ? 'flex-col items-start' : 'flex-row items-center flex-wrap'
      } ${className}`}
    >
      {!expanded && tags && tags.map((tag) => <EnvelopeChip key={tag.uuid} tag={tag} />)}
      {expanded &&
        tags &&
        tags.map(
          (tag) =>
            tag.tag_value != null && (
              <EnvelopeLabel
                key={tag.uuid}
                text={tag.tag_value.value}
                backgroundColor={tag.tag_value.color}
                className="litlingo-tag-color"
              />
            )
        )}
      {dropdown && tags && tags.length === 0 && (
        <button
          type="button"
          className={`flex flex-row items-center gap-x-1 text-xss px-0.5 focus:outline-none rounded border ${
            addTag && 'bg-litlingo-gray-0.5'
          }`}
          onClick={(e): void => {
            e.stopPropagation();
            setAddTag(true);
            dispatch(taggingEnvelope(true));
          }}
        >
          {PLUS_ICON}
          <span>Add tag</span>
        </button>
      )}
      {dropdown && tags && tags.length > 0 && (
        <button
          type="button"
          className="focus:outline-none"
          onClick={(e): void => {
            e.stopPropagation();
            setAddTag(true);
            dispatch(taggingEnvelope(true));
          }}
        >
          {ROUNDED_PLUS_ICON}
        </button>
      )}
      {addTag && renderDropdown()}
    </div>
  );
};

export default RenderTags;
