import type {
  AnnotationConfig,
  AnnotationOperand,
  AnnotatorRelationship,
  ModeOfSpeech,
  MRule,
  MRuleConfigNode,
  MRuleConfigNodeMatch,
  MRuleConfigNodeRelationshipMatch,
  NormalizedResource,
  RelationshipOperand,
  RuleConfig,
  UUID,
} from 'types';
import { v4 as uuidv4 } from 'uuid';

type RuleConfigWithId = RuleConfig & { rootConfigId: string };
type RuleAnnotationConfig = AnnotationOperand['config'];

const checkForModifiers = (
  config: MRuleConfigNode,
  obj: RuleConfigWithId | RelationshipOperand | AnnotationOperand | AnnotationConfig
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const newConfig = { ...config };
  if (
    newConfig.typeOfConfig === 'AND' ||
    newConfig.typeOfConfig === 'OR' ||
    newConfig.typeOfConfig === 'RELATIONSHIP_MATCH' ||
    newConfig.typeOfConfig === 'ANNOTATION_MATCH'
  ) {
    if ('modifiers' in obj) {
      const { modifiers } = obj;
      newConfig.modifiers = modifiers;
    } else {
      newConfig.modifiers = {};
    }
  }
  return newConfig;
};

type ReturnTypeFlattenConfigData = [
  NormalizedResource<MRuleConfigNode>,
  NormalizedResource<Omit<AnnotatorRelationship, 'configId'>>
];

const flattenConfigData = (
  obj:
    | RuleConfigWithId
    | RelationshipOperand
    | (AnnotationOperand & {
        negated?: boolean | undefined;
        // eslint-disable-next-line camelcase
        mode_of_speech?: ModeOfSpeech | undefined;
      })
    | RuleAnnotationConfig,
  buildingObj: [
    NormalizedResource<MRuleConfigNode>,
    NormalizedResource<Omit<AnnotatorRelationship, 'configId'>>
  ],
  parentId?: UUID,
  useIdentifier = false
): ReturnTypeFlattenConfigData => {
  const annotatorsObj = buildingObj[0];
  const relationshipsObj = buildingObj[1];
  let newId = '';
  let aux: MRuleConfigNode = {} as MRuleConfigNode;
  if (parentId != null) {
    newId = obj.uuid || uuidv4();
    aux.parent = parentId;
    const parentAnnotators = annotatorsObj[parentId] as
      | MRuleConfigNodeMatch
      | MRuleConfigNodeRelationshipMatch;
    const groups = parentAnnotators.groups ? parentAnnotators.groups : [];
    parentAnnotators.groups = [...groups, newId];
  } else if ('rootConfigId' in obj) {
    // first iteration of flattenConfigData, root node
    newId = obj.rootConfigId;
  }
  aux.id = newId;

  // If the obj is an annotation match inside a relationship match
  if (!('operator' in obj) && !('rootConfigId' in obj)) {
    aux.typeOfConfig = 'ANNOTATION_MATCH';
    aux.name = obj.name;
    aux = checkForModifiers(aux, obj);
    if (aux.typeOfConfig === 'ANNOTATION_MATCH') {
      // @ts-expect-error starship
      aux.annotatorId = useIdentifier ? obj.identifier_revision_uuid : obj.annotator_uuid;
      if (obj.negated != null) {
        aux.negated = obj.negated;
      }
      if (obj.mode_of_speech != null) {
        aux.mode_of_speech = obj.mode_of_speech;
      }
      // @ts-ignore starship
      aux.body_only = obj.body_only || false;
    }

    annotatorsObj[newId] = aux;
    // Check types for this return;
    // @ts-ignore
    return annotatorsObj;
  }

  // eslint-disable-next-line default-case
  switch (obj.operator) {
    case 'ANNOTATION_MATCH': {
      // We should remove this condition when we remove ANNOTATION_MATCH
      // as an option for root config operator
      if (!('rootConfigId' in obj)) {
        aux.typeOfConfig = 'ANNOTATION_MATCH';
        aux.name = obj.config.name;
        aux = checkForModifiers(aux, obj);
        if (aux.typeOfConfig === 'ANNOTATION_MATCH') {
          aux.annotatorId = useIdentifier
            ? // @ts-ignore starship
              obj.config.identifier_revision_uuid
            : obj.config.annotator_uuid;
          if (aux.annotatorId != null && obj.negated != null) {
            aux.negated = obj.negated;
          }

          if (obj.mode_of_speech != null) {
            aux.mode_of_speech = obj.mode_of_speech;
          }
          // @ts-ignore starship
          aux.body_only = obj.config.body_only || false;
        }
      }

      annotatorsObj[newId] = aux;

      return [annotatorsObj, relationshipsObj];
    }
    case 'RELATIONSHIP_MATCH': {
      aux.typeOfConfig = 'RELATIONSHIP_MATCH';
      aux.name = 'RELATIONSHIP_MATCH';
      aux.description = obj.description || '';
      aux.color = obj.color || '';
      aux = checkForModifiers(aux, obj);
      if (aux.typeOfConfig === 'RELATIONSHIP_MATCH') {
        aux.relationship = [];
        aux.groups = [];
        // @ts-ignore starship
        aux.body_only = obj.config.body_only || false;
      }
      const tempRelationship: string[] = [];
      obj.config.annotation_link_configs.forEach((relationship) => {
        const id = relationship.uuid || uuidv4();
        tempRelationship.push(id);
        relationshipsObj[id] = {
          id,
          type: relationship.type,
          annotation_a: {
            id: relationship.annotation_a,
          },
          annotation_b: {
            id: relationship.annotation_b,
          },
          ...(relationship.annotation_c != null
            ? {
                annotation_c: {
                  id: relationship.annotation_c,
                },
              }
            : {}),
        };
      });
      if (aux.typeOfConfig === 'RELATIONSHIP_MATCH') {
        aux.relationship = tempRelationship;
      }

      annotatorsObj[newId] = aux;
      obj.config.annotation_configs.forEach((operand) =>
        flattenConfigData(operand, [annotatorsObj, relationshipsObj], newId)
      );
      break;
    }
    case 'AND':
    case 'OR': {
      aux.typeOfConfig = obj.operator;
      aux.name = obj.operator;
      aux.description = obj.description || '';
      aux.color = obj.color || '';
      aux = checkForModifiers(aux, obj);
      if ('operands' in obj) {
        annotatorsObj[newId] = aux;
        obj.operands.map((operand) =>
          flattenConfigData(operand, [annotatorsObj, relationshipsObj], newId)
        );

        if (!obj.operands.length) {
          if (aux.typeOfConfig === 'AND' || aux.typeOfConfig === 'OR') {
            aux.groups = [];
          }
        }
      }
      break;
    }
  }
  annotatorsObj[newId] = aux;
  return [annotatorsObj, relationshipsObj];
};

export const addRootOperand = (configData: RuleConfigWithId): RuleConfigWithId => {
  // if the root is an annotation_match
  // we should put an AND/OR over it. There shouldn't be a ANNOTATION_MATCH
  // as root
  if (configData.operator && configData.operator !== 'ANNOTATION_MATCH') return configData;

  const { rootConfigId, ...configBody } = configData;
  let cleanedConfig: RuleConfigWithId = {} as RuleConfigWithId;
  // Check if this step still necessary
  if (Object.keys(configBody).length !== 0) {
    cleanedConfig = {
      operator: 'AND',
      // @ts-ignore
      operands: [{ ...configBody }],
      rootConfigId,
    };
  } else {
    cleanedConfig = {
      operator: 'AND',
      operands: [],
      rootConfigId,
    };
  }
  return cleanedConfig;
};

type NormalizeRuleConfigPayload = {
  config: RuleConfigWithId;
  rule: MRule;
  useIdentifier?: boolean;
};

type NormalizeRuleConfigReturnType = {
  ruleConfig: NormalizedResource<MRuleConfigNode>;
  relationships: NormalizedResource<
    Pick<AnnotatorRelationship, 'type' | 'id' | 'annotation_a' | 'annotation_b' | 'annotation_c'>
  >;
};

export const normalizeRuleConfig = (
  payload: NormalizeRuleConfigPayload
): NormalizeRuleConfigReturnType => {
  const { config, rule, useIdentifier } = payload;
  const cleanedConfig = addRootOperand(config);
  const [ruleConfig, relationships] = flattenConfigData(
    cleanedConfig,
    [{}, {}],
    undefined,
    useIdentifier || false
  );
  Object.keys(ruleConfig).forEach((key) => {
    ruleConfig[key] = { ...ruleConfig[key], ...{ ruleId: rule.uuid } };
    ruleConfig[key] = { ...ruleConfig[key], ...{ campaignId: rule.campaign_uuid } };
  });
  return { ruleConfig, relationships };
};

export default flattenConfigData;
