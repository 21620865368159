import React from 'react';
import type { Arrow, ArrowsOrder, DocumentVizColWidths } from '../types';
import {
  calculateXPos,
  calculateYPos,
  insertInOrder,
  relationshipsSVGHeight,
} from '../utils/visualizer';
import DocumentVizArrow from './DocumentVizArrow';

type DocumentVizRelationshipsProps = {
  geekMode: string;
  widths: DocumentVizColWidths;
  arrows: (Arrow & { lineArrowIndex: number })[];
  flipped?: boolean;
};

const DocumentVizRelationships: React.FC<DocumentVizRelationshipsProps> = (
  props
) => {
  const { geekMode, widths, arrows, flipped = false } = props;

  const svgArrows: JSX.Element[] = [];
  let maxTopY = 0;
  let height = 0;

  if (arrows) {
    const filtered = arrows.filter((arrow) => {
      if (geekMode === 'basic_mode') return arrow.type === 'relationship';
      if (geekMode === 'dependent_mode') return arrow.type === 'dependent';
      return arrow.type === 'edge';
    });

    const order = filtered.reduce(
      (curr, arrow) => ({
        ...curr,
        [arrow.root]: insertInOrder(
          curr[arrow.root],
          arrow.root,
          arrow.head,
          'root'
        ),
        [arrow.head]: insertInOrder(
          curr[arrow.head],
          arrow.head,
          arrow.root,
          'head'
        ),
      }),
      {} as ArrowsOrder
    );

    filtered.forEach((arrow) => {
      const { root, head } = arrow;
      const [, topY] = calculateYPos(root, head, filtered);
      maxTopY = Math.max(maxTopY, Math.abs(topY));
    });
    height = relationshipsSVGHeight(maxTopY);

    const svgLineArrows = filtered.map((arrow) => {
      const { root, head, relationship, type } = arrow;

      const [rootX, headX] = calculateXPos(
        root,
        head,
        arrow.lineArrowIndex,
        widths,
        order
      );
      const [bottomY, topY] = calculateYPos(root, head, filtered);

      return (
        <DocumentVizArrow
          key={`${type}-${root}-${head}-${relationship}-${rootX}-${headX}-${bottomY}-${topY}`}
          rootX={rootX}
          headX={headX}
          bottomY={flipped ? -bottomY - height : bottomY}
          topY={flipped ? -topY - height : topY}
          text={relationship}
          geekMode={geekMode}
          flipped={flipped}
        />
      );
    });

    svgArrows.push(...svgLineArrows);
  }

  return (
    <div>
      <svg height={height} width="100%">
        <defs>
          <marker
            id="arrowhead"
            viewBox="0 0 10 10"
            refX="8"
            refY="5"
            markerWidth="6"
            markerHeight="6"
            orient="auto-start-reverse"
          >
            <path
              d="M 0 0 L 10 5 L 0 10 z"
              className="fill-current text-black"
            />
          </marker>
        </defs>

        <g
          className="litlingo__doc_viz__arrows_group"
          transform={`translate(0,${height})`}
        >
          {svgArrows}
        </g>
      </svg>
    </div>
  );
};

export default DocumentVizRelationships;
