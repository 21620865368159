import { setHasEventsFilter } from 'actions/envelopeListView';
import LoadingIndicator from 'components/LoadingIndicator';
import useClickOutside from 'components/utils/useClickOutside';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';

type ComponentSelectProps = {
  onChange: (keyword: string) => void;
  options: {
    value: string;
    label: string;
  }[];
  selectedItems: string[];
  dataTestid?: string;
  title?: string;
};

const SelectDropdown: React.FC<ComponentSelectProps> = (props) => {
  const { title, onChange, options, selectedItems, dataTestid } = props;

  const [isOpen, setIsOpen] = useState(false);

  // click outside functionality
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleButtonClick = (): void => setIsOpen(!isOpen);

  return (
    <div ref={wrapperRef} className="relative text-left h-6">
      <button
        type="button"
        data-testid={`${dataTestid || 'dropdown'}-button`}
        className="w-full h-6 flex flex-row items-center rounded border px-2 py-1 bg-white text-base font-sans text-litlingo-gray-5 font-bold leading-5 focus:outline-none active:bg-gray-50  transition ease-in-out duration-150 border-litlingo-gray-3"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={handleButtonClick}
      >
        {title || ''}
        <svg className="-mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          className="absolute left-0 mt-1 origin-top-left rounded-md shadow-lg w-32 z-20 "
          style={{ filter: 'drop-shadow(3px 1px 8px rgba(0, 0, 0, 0.5))' }}
          id="multi-select-dropdown-content"
        >
          <div className="rounded-md bg-white shadow-xs">
            <div role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <div className="p-2 overflow-auto">
                <fieldset data-testid={`${dataTestid || 'dropdown'}-options`}>
                  {options.map((option, idx) => (
                    <div key={option.value} className={idx !== 0 ? 'mt-2' : ''}>
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5 mr-2">
                          <input
                            id={option.value}
                            data-testid={`${option.value}-checkbox`}
                            type="checkbox"
                            value={option.value}
                            className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out"
                            onChange={(e): void => onChange(e.target.value)}
                            checked={selectedItems && selectedItems.includes(option.value)}
                          />
                        </div>
                        <div className="text font-sans leading-5">
                          <label htmlFor={option.value} className="">
                            {option.label}
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface ExploreFilterProps {
  resource: string;
  loading: boolean;
}
const ExploreFilter: React.FC<ExploreFilterProps> = ({ resource, loading }) => {
  const dispatch = useDispatch();

  const hasEventsFilter = useSelector(getNavParamsByResource(resource)).has_events as string[];
  const filters = hasEventsFilter;
  const options = { match: 'Matches', noMatch: 'No Matches' };

  const handleChange = (key: string): void => {
    const value = key === 'match';

    logEvent(`events-filters-match-${value}`);
    if (filters.includes(value.toString()) && filters.length > 1) {
      dispatch(setHasEventsFilter({ value: [...filters.filter((v) => v !== value.toString())] }));
    } else if (filters.includes((!value).toString())) {
      dispatch(setHasEventsFilter({ value: [...filters, value.toString()] }));
    }
  };

  const selected: string[] = [];
  if (filters?.includes('true')) {
    selected.push('match');
  }
  if (filters?.includes('false')) {
    selected.push('noMatch');
  }

  const getTitle = (): string => {
    if (selected.includes('match') && selected.includes('noMatch')) {
      return 'All';
    }
    if (selected.includes('match') && !selected.includes('noMatch')) {
      return 'Matches';
    }
    if (selected.includes('noMatch') && !selected.includes('match')) {
      return 'No Matches';
    }
    return 'Make Selection';
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <SelectDropdown
      title={getTitle()}
      onChange={handleChange}
      options={Object.keys(options).map((key) => ({
        label: options[key as keyof typeof options],
        value: key,
      }))}
      selectedItems={selected as string[]}
      dataTestid="active"
    />
  );
};

export default ExploreFilter;
