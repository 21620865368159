import type { Platform } from 'types';

// If you'll add more options to this object, please keep in mind
// this is used in the outcomes table for the platforms options,
// so be careful
const platform = {
  active_o365_teams: 'Microsoft Teams',
  active_slack: 'Slack',
  active_gmail: 'Gmail',
  active_zendesk: 'Zendesk',
  active_chrome: 'Chrome',
  active_glia: 'Glia',
  active_o365: 'Microsoft O365',
};

export const notImplementedPlatforms = {
  active_twitter: 'Twitter',
  active_hubspot: 'Hubspot',
  active_salesforce: 'Salesforce',
};

export const areasToMonitor = {
  active_inbound: 'Inbound',
  active_outbound: 'Outbound',
};

export const allPlatforms = { ...platform, ...notImplementedPlatforms };

export const additionalSettings = {
  active_inbound: 'Apply To Inbound',
};

export const scopeOnlySettings = {
  active_outbound: 'Apply To Outbound',
};

type PlatformNames = {
  [P in Platform]: string;
};
export const platformNames: PlatformNames = {
  slack: 'Slack',
  gmail: 'Gmail',
  zendesk: 'Zendesk',
  chrome: 'Chrome',
  glia: 'Glia',
  o365: 'Outlook',
  o365_teams: 'Microsoft Teams',
  all: 'All',
};

export const providerS3Keys = {
  o365: 'microsoft',
  zendesk: 'zendesk-ticket',
};

export const platformTooltipParser = (p: Platform, integrationType: string): string => {
  if (p === 'gmail' || p === 'chrome') {
    if (integrationType === 'app') {
      return 'gmail_app';
    }
  }
  return p;
};

export default platform;
