import React, { useState } from 'react';

type AnnotatedNodeProps = {
  pos: string;
  showPos: boolean;
  text: string;
  isPerson: boolean;
};

const AnnotatedNode: React.FC<AnnotatedNodeProps> = (props) => {
  const { pos, showPos, text, isPerson } = props;

  const [width, setWidth] = useState<number | null>(null);
  const [hovering, setHovering] = useState(false);

  const textRef = (ref: HTMLDivElement | null): void => {
    if (ref == null) {
      return;
    }

    setWidth(ref.getBoundingClientRect().width);
  };

  const handleMouseEnter = (): void => setHovering(true);
  const handleMouseLeave = (): void => setHovering(false);

  const posStyle: React.CSSProperties = {};
  if (width != null) {
    posStyle.minWidth = `${width}px`;

    if (!hovering) {
      posStyle.width = `${width}px`;
    }
  }

  if (isPerson) {
    return (
      <div
        className={`relative pb-2 text-left ${hovering ? 'z-50' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          ref={textRef}
          className={`text-sm border-b-2 leading-tight ${
            showPos || hovering ? 'border-gray-700' : 'border-white'
          }`}
        >
          <span className="w-10 h-3 bg-black inline-block" />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`relative pb-2 text-left ${hovering ? 'z-50' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={textRef}
        className={`text-sm border-b-2 leading-tight ${
          showPos || hovering ? 'border-gray-700' : 'border-white'
        }`}
      >
        {text}
      </div>

      {(showPos || hovering) && (
        <div
          style={posStyle}
          className={`leading-tight absolute bg-white pl-px litlingo__annotated_node__pos truncate uppercase ${
            hovering ? 'shadow pr-px' : ''
          }`}
        >
          {pos}
        </div>
      )}
    </div>
  );
};

export default AnnotatedNode;
