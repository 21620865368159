import { useLocation, useRouteMatch } from 'react-router-dom';
import routes from 'routes';
import { buildQSObjFromQS, getRoute, Params } from 'utils/urls';

const useNavParams = (): Params => {
  const route = useRouteMatch();
  const location = useLocation();

  let params = {};
  const spec = routes.find((r) => r.path === route.path);
  const routeSpec = getRoute(spec);

  if (routeSpec && routeSpec.data && routeSpec.data.params) {
    params = buildQSObjFromQS(location.search, routeSpec.data.params);
  }

  return params;
};

export default useNavParams;
