import { UUID } from '@litlingo/client';
import { AnnotatedGraph, HighlightedSentence } from '@litlingo/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getPermissionsPolicy } from 'selectors/permissions';
import { getFieldAuthorization } from 'utils/permissions';

type ComponentProps = {
  annotationsMatched: UUID[];
  classes?: string;
  resultLines: AnnotatedGraph[];
};

const SentencesViolatedGlobalSidebarSection: React.FC<ComponentProps> = ({
  classes,
  resultLines,
  annotationsMatched,
}) => {
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const entityFieldLevel = getFieldAuthorization('communications.entity', user.roles, policy);

  return (
    <div className="flex flex-col gap-4">
      {resultLines.map((line, idx) => (
        <div key={line.index} className={idx !== 0 ? 'mt-2' : ''}>
          <HighlightedSentence
            nodes={line.nodes}
            useHover={user.roles.includes('super-admin')}
            protectedFields={{
              entity: entityFieldLevel,
            }}
            portal="tooltip-portal"
          />
        </div>
      ))}
      <div className="w-full flex flex-col p-4 bg-litlingo-gray-0 overflow-hidden text-small gap-2">
        {annotationsMatched && annotationsMatched.length ? (
          <div>
            {annotationsMatched.map((match) => (
              <button
                key={match}
                type="button"
                className="flex flex-col focus:outline-none hover:underline"
                data-testid={`annotation-button-${match}`}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={(): void => {}}
              >
                <span className="text-left">{match}</span>
              </button>
            ))}
          </div>
        ) : (
          <div className={`w-full flex flex-row items-center gap-2 ${classes}`} key="svg-container">
            <svg
              className="h-5 w-5 text text--bold"
              fill="#333333"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              viewBox="-0.5 0 22 20"
              stroke="#333333"
            >
              <path d="M0 10a10 10 0 1120 0 10 10 0 01-20 0zm16.32-4.9L5.09 16.31A8 8 0 0016.32 5.09zm-1.41-1.42A8 8 0 003.68 14.91L14.91 3.68z" />
            </svg>
            <span className="text-heading-2">No language matched</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SentencesViolatedGlobalSidebarSection;
