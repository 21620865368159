/* eslint-disable camelcase */
import WidgetHeader from 'decorators/widget/WidgetHeader';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { DashboardWidget as DashboardWidgetType, IntersectedError } from 'types';
import DashboardWidget from './DashboardWidget';

const reduceWidgetGroup =
  (intersected: IntersectedError | null, key: string, inGroup = false) =>
  // eslint-disable-next-line react/display-name
  (
    previousValue: (JSX.Element | DashboardWidgetType)[],
    currentValue: DashboardWidgetType,
    currentIndex: number | string
  ): (JSX.Element | DashboardWidgetType)[] => {
    const { widget, type, group } = currentValue;
    const location = useLocation();

    // TODO: make sure that's ok
    // const currentUrl = window.location.href;

    const { pathname } = location;

    const widgetId =
      type != null
        ? `${pathname}--${widget}--${type}--${currentIndex}--${key}`
        : `${pathname}--${widget}--${currentIndex}--${key}`;

    if (group) {
      const {
        widget: _widget,
        columns_start: colsStart,
        columns_width: colsWidth,
        rows_start: rowsStart,
        rows_height: rowsHeight,
        container_class_name: containerClassName,
        title,
        title_class_name,
      } = currentValue;

      const reduced = [...group.reduce(reduceWidgetGroup(intersected, key, true), [])];

      return [
        <div
          key={_widget}
          style={{
            gridColumnStart: colsStart,
            gridColumnEnd: colsStart + colsWidth,
            gridRowStart: rowsStart,
            gridRowEnd: rowsStart + rowsHeight,
          }}
        >
          <div
            className={`flex flex-col justify-start h-full p-4 bg-white border rounded-md box-shadown ${containerClassName}`}
          >
            {title && (
              <WidgetHeader widgetId={widgetId} title={title} titleClassName={title_class_name} />
            )}
            <div className="h-full content-between grid grid-cols-8 auto-rows-layout row-gap-4 col-gap-auto">
              {reduced}
            </div>
          </div>
        </div>,

        ...previousValue,
      ];
    }

    return [
      <DashboardWidget
        key={widgetId}
        config={currentValue}
        intersected={intersected}
        widgetId={widgetId}
        inGroup={inGroup}
      />,

      ...previousValue,
    ];
  };

export default reduceWidgetGroup;
