import {
  addBulkTestCaseTagsRequest,
  addTestCaseTagRequest,
  addTestCaseToBranchRequest,
  bulkDeleteTestCases,
  bulkDuplicateTestCases,
  evaluateAsyncRequest,
  evaluateRequest,
  exportTestCases,
  fetchSingleTestCaseRequest,
  fetchTestCasesRequest,
  importTestCases,
  removeTestCaseFromBranchRequest,
  removeTestCaseTagRequest,
  saveTestCaseRequest,
} from 'actions/testCases';
import type { MTestCase, Selector, TestCasesSummary, UUID } from 'types';

export const saveTestCaseRequestLoading: Selector<boolean> = (state) =>
  state.testCases.loading.includes(saveTestCaseRequest.toString());

export const evaluateTestCaseLoading: Selector<boolean> = (state) =>
  state.testCases.loading.includes(evaluateRequest.toString());

export const evaluateAsyncTestCaseLoading: Selector<boolean> = (state) =>
  state.testCases.asyncLoading.includes(evaluateAsyncRequest.toString());

export const exportTestCasesLoading: Selector<boolean> = (state) =>
  state.testCases.loading.includes(exportTestCases.toString());

export const importTestCasesLoading: Selector<boolean> = (state) =>
  state.testCases.loading.includes(importTestCases.toString());

export const bulkDeleteTestCasesLoading: Selector<boolean> = (state) =>
  state.testCases.loading.includes(bulkDeleteTestCases.toString());

export const bulkDuplicateTestCasesLoading: Selector<boolean> = (state) =>
  state.testCases.loading.includes(bulkDuplicateTestCases.toString());

export const addTestCaseTagLoading: Selector<boolean> = (state) =>
  state.testCases.loading.includes(addTestCaseTagRequest.toString());

export const removeTestCaseTagLoading: Selector<boolean> = (state) =>
  state.testCases.loading.includes(removeTestCaseTagRequest.toString());

export const addBulkTestCaseTagLoading: Selector<boolean> = (state) =>
  state.testCases.loading.includes(addBulkTestCaseTagsRequest.toString());

export const getTestCases: Selector<MTestCase[]> = (state) => {
  const { items } = state.testCases;

  return Object.keys(items).map((uuid) => items[uuid]);
};
export const getTestCasesLoading: Selector<boolean> = (state) =>
  state.testCases.loading.includes(fetchTestCasesRequest.toString()) ||
  state.testCases.loading.includes(fetchSingleTestCaseRequest.toString());

export const getTestCase: Selector<MTestCase, [UUID]> = (state, id) => state.testCases.items[id];

export const getTestCasesTotalCount: Selector<number> = (state) => state.testCases.count;

export const getTestCasesResultsKey: Selector<string | null> = (state) =>
  state.testCases.resultsKey;

export const getTestCaseSummary: Selector<TestCasesSummary> = (state) => state.testCases.summary;

export const getLastTestCases: Selector<string[]> = (state) => state.testCases.lastTestCases;

export const getAddToBranchLoading: Selector<boolean> = (state) =>
  state.testCases.loading.includes(addTestCaseToBranchRequest.toString());

export const getRemoveFromBranchLoading: Selector<boolean> = (state) =>
  state.testCases.loading.includes(removeTestCaseFromBranchRequest.toString());

export const getGeneratedTestCase: Selector<string> = (state) => state.testCases.generatedTestCase;
