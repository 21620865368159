import { deleteIdentity, showSuccessAlert } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import CreateIdentity from 'components/IdentitiesList/CreateIdentity';
import ListFooter from 'components/ListFooter';
import Navbar from 'components/Navbar';
import SearchInput from 'components/SearchInput';
import { COPY_CLIPBOARD } from 'constants/commonIcons';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getIdentities,
  getIdentitiesList,
  getIdentitiesLoading,
  getIdentitiesTotalCount,
} from 'selectors/identities';
import { useSelector } from 'store';
import type { Identity, UUID } from 'types';

const IdentitiesList: React.FC = () => {
  const dispatch = useDispatch();
  const identities = useSelector(getIdentities);
  const [isCreateNewIdentityOpen, setIsCreateNewIdentityOpen] = useState(false);
  const [editIdentity, setEditIdentity] = useState<Identity | null>(null);
  const [isDeletingIdentity, setIsDeletingIdentity] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<UUID | null>(null);

  const toggleConfirmModal = (): void => setIsDeletingIdentity(false);

  const handleIdentityClick = (identity: Identity): void => {
    setEditIdentity(identity);
    setIsCreateNewIdentityOpen(true);
  };

  const handleAddNewIdentity = (): void => {
    setEditIdentity(null);
    setIsCreateNewIdentityOpen(true);
  };

  const handleDelete = (): void => {
    if (idToBeDeleted != null) {
      dispatch(deleteIdentity({ uuid: idToBeDeleted }));
      toggleConfirmModal();
    }
  };

  const handleCopyClick = async (
    event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>,
    uuid: string
  ): Promise<void> => {
    event.stopPropagation();
    await navigator.clipboard.writeText(uuid);
    dispatch(showSuccessAlert('Identity UUID copied to your clipboard'));
  };

  const handleClickOnDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ): void => {
    event.stopPropagation();
    setIsDeletingIdentity(true);
    setIdToBeDeleted(id);
  };

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />
      <div className="py-10">
        <header>
          <div className="max-w-7xl mx-auto px-1 sm:px-12 lg:px-14">
            <div>
              <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                  <h2 className="title">Identities</h2>
                </div>
                <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
                  <SearchInput resource={resourceQueryParamName.identity} field="broad_search" />
                  {!isCreateNewIdentityOpen ? (
                    <span className="ml-3 shadow-sm rounded-md">
                      <button
                        onClick={handleAddNewIdentity}
                        type="button"
                        className="button button--primary focus:outline-none"
                        key="add"
                      >
                        Add New
                      </button>
                    </span>
                  ) : (
                    <span className="ml-3 shadow-sm rounded-md">
                      <button
                        onClick={(): void => setIsCreateNewIdentityOpen(false)}
                        type="button"
                        key="cancel"
                        className="inline-flex items-center px-4 py-2 border border-red-300 text rounded-md text-red-500 hover:bg-red-500 hover:text-white focus:outline-none focus:shadow-outline-indigo transition duration-150 ease-in-out"
                      >
                        Cancel
                      </button>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>
        <main>
          <div className="mt-10 mb-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            {isCreateNewIdentityOpen && (
              <div className="mb-10">
                <CreateIdentity
                  editIdentity={editIdentity as Identity}
                  setIsCreateNewIdentityOpen={setIsCreateNewIdentityOpen}
                />
              </div>
            )}
            <div className="table-wrapper">
              <table className="table-wrapper__table">
                <thead>
                  <tr>
                    <th className="table-wrapper__th">Email</th>
                    <th className="table-wrapper__th">Email Verified</th>
                    <th aria-label="copy" className="table-wrapper__th" />
                    <th aria-label="delete" className="table-wrapper__th" />
                  </tr>
                </thead>
                <tbody className="table-wrapper__tbody" data-testid="identities-table">
                  {identities &&
                    Object.keys(identities).map((key) => (
                      <tr
                        key={identities[key].email}
                        onClick={(): void => handleIdentityClick(identities[key])}
                        className="border-t border-gray-200 hover:bg-gray-100 table-row"
                      >
                        <td className="table-wrapper__td table-wrapper__td--align-left">
                          <div className="flex items-center">
                            <div>
                              <div className="text">{identities[key].email}</div>
                            </div>
                          </div>
                        </td>
                        <td className="table-wrapper__td">
                          <div className="text-left">
                            {identities[key].email_verified ? (
                              <svg
                                fill="none"
                                className="h-6 w-6 text-green-700 float-left"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                            ) : (
                              <svg
                                fill="none"
                                className="h-6 w-6 text-red-700 float-left"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                            )}
                          </div>
                        </td>
                        <td className="table-wrapper__td">
                          <div className="flex items-center">
                            <button
                              className="focus:outline-none text-black"
                              type="button"
                              onClick={(event): void => {
                                handleCopyClick(event, identities[key].uuid);
                              }}
                            >
                              {COPY_CLIPBOARD}
                            </button>
                          </div>
                        </td>
                        <td className="table-wrapper__td">
                          <button
                            type="button"
                            className="lg:mr-4"
                            onClick={(event): void => {
                              handleClickOnDelete(event, key);
                            }}
                          >
                            <svg
                              className="mr-1.5 h-5 w-5 text-gray-700  hover:text-black"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <ListFooter
              resourceStateName={resourceQueryParamName.identity}
              resourceName="identity"
              getResourceList={getIdentitiesList}
              getResourceTotalCount={getIdentitiesTotalCount}
              getResourceLoading={getIdentitiesLoading}
            />
          </div>
        </main>
      </div>
      {isDeletingIdentity && idToBeDeleted && (
        <ConfirmModal
          text="BE CAREFUL, this will remove all tokens, codes, external identities and invitations associated to this identity, are you sure you want to delete this anyway?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDelete}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </div>
  );
};

export default IdentitiesList;
