import { EXPAND_ICON } from 'constants/dashboardIcons';
import React from 'react';
import { getAnnotator } from 'selectors/annotator';
import { getCustomerDomain } from 'selectors/auth';
import { useSelector } from 'store';

type ComponentProps = {
  annotatorId: string;
  annotatorSource: string | undefined;
  setAnnotatorSource: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const AnnotatorCompareRow: React.FC<ComponentProps> = ({
  annotatorId,
  annotatorSource,
  setAnnotatorSource,
}) => {
  const annotator = useSelector((state) => getAnnotator(state, annotatorId));
  const customerDomain = useSelector(getCustomerDomain);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();
    if (e.target.checked) {
      setAnnotatorSource(annotator.uuid);
    } else {
      setAnnotatorSource(undefined);
    }

    const element = document.activeElement as HTMLInputElement;
    if (element) {
      element.blur();
    }
  };

  const handleOpenAnnotator = (): void => {
    const path = `/${customerDomain}/annotator/${annotatorId}`;
    window.open(path, '_blank');
  };

  return (
    <div className=" border-b py-2 ">
      <div className="flex flex-row justify-between items-center">
        <div className="text">
          <label htmlFor={`custom-checkbox-${annotator.uuid}`} className="cursor-pointer">
            <input
              id={`custom-checkbox-${annotator.uuid}`}
              type="checkbox"
              className="custom-checkbox mr-1"
              onChange={handleCheckboxChange}
              checked={annotatorSource === annotator.uuid}
            />
            <span className="custom" />
          </label>
          {`${annotator.name} (v${annotator.version})`}
        </div>
        <button type="button" className="focus:outline-none" onClick={handleOpenAnnotator}>
          {EXPAND_ICON}
        </button>
      </div>
    </div>
  );
};

export default AnnotatorCompareRow;
