/* eslint-disable max-lines */
import LinkLookup from 'components/LinkLookup';
import React from 'react';
import { getDiffOriginalModel, getRuleInCompare } from 'selectors/rulesCompare';
import { useSelector } from 'store';

const RulesCompareHeader: React.FC = () => {
  const ruleOne = useSelector((state) => getRuleInCompare(state, 'left'));
  const ruleTwo = useSelector((state) => getRuleInCompare(state, 'right'));

  const originalRuleId = useSelector(getDiffOriginalModel);

  return (
    <div
      className="w-full h-14 py-3 px-6 flex flex-row justify-between items-center"
      style={{ boxShadow: '5px 6px 18px 0px rgba(0, 0, 0, 0.10)' }}
    >
      <span className="text-heading-2">
        {ruleOne?.uuid === ruleTwo?.uuid ? ruleOne?.name : `${ruleOne?.name} vs ${ruleTwo?.name}`}
      </span>

      {originalRuleId && (
        <LinkLookup
          routeName="rule-manager"
          routeParams={{ ruleId: originalRuleId }}
          className="button button--secondary h-full w-25 justify-center"
        >
          <span className="font-bold">Exit</span>
        </LinkLookup>
      )}
    </div>
  );
};
export default RulesCompareHeader;
