import React from 'react';

type ComponentProps = {
  title: string;
  icon?: JSX.Element;
  subtitle?: string;
  body?: JSX.Element | null;
  className?: string;
  disabled?: boolean;
};

const DashboardWidget: React.FC<ComponentProps> = ({
  title,
  icon,
  subtitle,
  body,
  className,
  disabled,
}) => (
  <div
    className={`overview-items-container box-shadown p-5 ${className || ''} ${
      disabled ? 'disabled-dashboard-widget' : ''
    }`}
  >
    <div className="flex flex-row items-center justify-between">
      <div className="text text--left text--title text-2xl text--semibold line-height-100">
        {title}
      </div>

      {icon || null}
    </div>

    <p className="text text--left text--label pt-1">{subtitle || ''}</p>
    {body || null}
  </div>
);

export default DashboardWidget;
