import { addAnnotationMatcherToRule } from 'actions';
import LinkLookup from 'components/LinkLookup';
import { previewImage } from 'components/RuleManagerTable/RuleManagerUtils';
import { EXPAND_ICON } from 'constants/dashboardIcons';
import { PLUS_ICON } from 'constants/filterIcons';
import React, { useEffect, useState } from 'react';
import {
  DragElementWrapper,
  DragPreviewImage,
  DragPreviewOptions,
  DragSourceOptions,
} from 'react-dnd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import type { MRule, NormalizedAnnotator, UUID } from 'types';
import TermsSerchMatch from './TermsSerchMatch';

type ComponentProps = {
  annotator: NormalizedAnnotator;
  rule: MRule;
  campaignId: UUID;
  drag: DragElementWrapper<DragSourceOptions>;
  preview: DragElementWrapper<DragPreviewOptions>;
  searchValue: string;
};

const RenderAnnotator: React.FC<ComponentProps> = ({
  annotator,
  rule,
  campaignId,
  drag,
  preview,
  searchValue,
}) => {
  const { ruleId } = useParams<{ ruleId: UUID }>();

  const dispatch = useDispatch();
  const { name: annotatorName, uuid } = annotator;

  const [image, setImage] = useState('');
  const [showRules, setShowRules] = useState(false);

  useEffect(() => {
    setImage(previewImage(annotator.name).src);
  }, [annotator]);

  const handleAddAnnotator = (): void => {
    dispatch(addAnnotationMatcherToRule({ rule, annotator }));
  };

  return (
    <>
      <DragPreviewImage connect={preview} src={image} />
      <div
        ref={drag}
        className="flex flex-row w-full py-2 border-t-2"
        data-testid="sidebar-annotator"
      >
        <div className="w-full self-center">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-2">
              <button
                data-testid="add-annotator-button"
                onClick={(): void => handleAddAnnotator()}
                type="button"
                className="w-3 inline-flex text-litlingo-primary focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              >
                {PLUS_ICON()}
              </button>
              <h3 className={`text-lg font-bold leading-5 text-gray-900 `}>{annotatorName}</h3>
            </div>
            <LinkLookup
              routeName="annotator"
              routeParams={{ annotatorId: uuid, campaignId, ruleId }}
              type="button"
              className="ml-4  w-3 inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
            >
              {EXPAND_ICON}
            </LinkLookup>
          </div>
          <div className=" text-litlingo-gray-5 text-xss">
            <div className="pl-5">
              <TermsSerchMatch annotatorId={uuid} searchValue={searchValue} />
              <div className="mt-1">
                {`Uses: ${
                  // @ts-ignore
                  annotator.rule_annotators?.length ?? 0
                }`}
              </div>
            </div>
            {showRules && (
              <ul className="list-disc pl-10">
                {
                  // @ts-ignore
                  annotator.rule_annotators?.map((r) => (
                    <li key={r.rule.uuid}>{`${r.rule.name} (v${r.rule.version})`}</li>
                  ))
                }
              </ul>
            )}
            {
              // @ts-ignore
              !!annotator.rule_annotators?.length && (
                <div className="flex flex-row justify-end ">
                  <button
                    type="button"
                    className=" underline text-litlingo-primary focus:outline-none"
                    onClick={(): void => setShowRules(!showRules)}
                  >
                    {showRules ? 'Show Less' : 'Show More'}
                  </button>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default RenderAnnotator;
