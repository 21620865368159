import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import tooltipLookup from 'utils/tooltipLookup';

export enum TooltipPosition {
  left = 'translateX(-100%)',
  center = 'translateX(-50%)',
  right = 'translateX(0%)',
}

type ComponentProps = {
  tooltip?: string;
  component?: JSX.Element;
  content?: string;
  redesign?: boolean;
  tooltipPosition?: TooltipPosition;
};

const Tooltip: React.FC<ComponentProps> = ({
  tooltip = '',
  component,
  content,
  children,
  redesign = false,
  tooltipPosition = TooltipPosition.center,
}) => {
  const [hover, setHover] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const bottomSeparation = 30;

  const [group, item] = tooltip.split('.');

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    setPosition({ left: e.clientX, top: e.clientY + bottomSeparation });
  };

  if (tooltip === '' && !content && !component) {
    return <>{children}</>;
  }

  const renderTooltip = (): React.ReactPortal | null => {
    const portal = document.getElementById('tooltip-portal');

    const ttip = (
      <div
        className={`fixed flex w-auto z-50 rounded-sm text-base leading-5 whitespace-pre ${
          redesign
            ? 'text-litlingo-gray-6 bg-litlingo-info-light'
            : 'text-litlingo-off-white bg-litlingo-gray-600 opacity-85'
        }`}
        style={{
          left: position.left,
          top: position.top,
          transform: tooltipPosition,
          filter: redesign ? 'drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25))' : 'none',
        }}
        data-testid="tooltip-text"
      >
        {component ? (
          <>{component}</>
        ) : (
          <div className="px-2">{content || tooltipLookup[group][item]}</div>
        )}
      </div>
    );
    if (portal) {
      return ReactDOM.createPortal(ttip, portal);
    }
    return null;
  };

  return (
    <div
      className="flex"
      onMouseEnter={(): void => setHover(true)}
      onMouseLeave={(): void => setHover(false)}
      onMouseMove={(e): void => handleMouseMove(e)}
      ref={ref}
    >
      {children}
      {hover && position.left !== 0 && renderTooltip()}
    </div>
  );
};

export default Tooltip;
