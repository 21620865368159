import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  fetchActiveTasks,
  fetchActiveTasksFailure,
  fetchActiveTasksFulfill,
  fetchActiveTasksRequest,
  fetchActiveTasksSuccess,
} from 'actions/tasks';
import type { ActiveTask, ErrorObject, NormalizedResource, Task } from 'types';

type TaskState = {
  loading: string[];
  error: ErrorObject | null;
  items: NormalizedResource<Task>;
  count: number;
  activeTasks: ActiveTask[];
};

type TaskReducer<P = void> = (state: TaskState, payload: PayloadAction<P>) => TaskState;

const defaultState: TaskState = {
  loading: [],
  error: null,
  items: {},
  count: 0,
  activeTasks: [],
};

const handleFetchActiveTasksRequest: TaskReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchActiveTasksRequest.toString()],
});

const handleFetchActiveTasksFailure: TaskReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleFetchActiveTasksSuccess: TaskReducer<ActiveTask[]> = (state, { payload }) => ({
  ...state,
  activeTasks: payload,
});

const handleFetchActiveTasksFulfill: TaskReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchActiveTasksRequest.toString()),
});

const handlers = {
  [fetchActiveTasks.toString()]: handleFetchActiveTasksRequest,
  [fetchActiveTasksSuccess.toString()]: handleFetchActiveTasksSuccess,
  [fetchActiveTasksFailure.toString()]: handleFetchActiveTasksFailure,
  [fetchActiveTasksFulfill.toString()]: handleFetchActiveTasksFulfill,
};

const tasksReducer = createReducer(defaultState, handlers);

export default tasksReducer;
