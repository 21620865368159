import { selectAnnotators } from 'actions';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getAnnotatorsFromRules, getSelectedAnnotators } from 'selectors/rule';
import { useSelector } from 'store';

type ComponentProps = {
  ruleId: string;
};

const RuleAnnotatorBank: React.FC<ComponentProps> = ({ ruleId }) => {
  const dispatch = useDispatch();
  const annotators = useSelector((state) => getAnnotatorsFromRules(state, ruleId));
  const selectedAnnotators = useSelector(getSelectedAnnotators);

  const handleSelectAnnotator = (annotatorId: string | undefined): void => {
    if (annotatorId) {
      if (selectedAnnotators.includes(annotatorId)) {
        const newAnnotators = selectedAnnotators.filter((ann) => ann !== annotatorId);
        dispatch(selectAnnotators(newAnnotators));
      } else {
        dispatch(selectAnnotators([...selectedAnnotators, annotatorId]));
      }
    }
  };

  return (
    <div className="flex flex-col mb-2">
      <div className="flex flex-row justify-between">
        <div className="text-litlingo-gray-6">Identifier Bank</div>
        <div>
          <button
            type="button"
            aria-labelledby="clear-selection"
            className="text-litlingo-gray-6 focus:outline-none"
            onClick={(): { payload: string[]; type: string } => dispatch(selectAnnotators([]))}
          >
            <span id="clear-selection" className=" underline">
              Clear Selection
            </span>
          </button>
        </div>
      </div>
      <div data-testid="container" className="flex flex-row flex-grow-0 flex-wrap gap-1 mt-1">
        {annotators?.map((ann) => {
          const { annotator } = ann;
          return (
            <button
              key={annotator?.uuid}
              type="button"
              className={`rounded text-xss leading-4 px-1 focus:outline-none ${
                selectedAnnotators.includes(annotator?.uuid as string)
                  ? 'bg-litlingo-green-text text-white'
                  : 'bg-litlingo-green-bg text-litlingo-gray-6'
              }`}
              onClick={(): void => handleSelectAnnotator(annotator?.uuid)}
            >
              <span>{annotator?.name}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default RuleAnnotatorBank;
