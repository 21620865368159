import React from 'react';
import { useSelector } from 'react-redux';
import { Selector } from 'types';

type MinimumResource = {
  uuid: string;
};

type ComponentProps<T extends MinimumResource> = {
  handleDelete: (labelId: string, exclude: boolean) => void;
  filterData: string[];
  notFilterData: string[];
  getResourceList: Selector<T[], []>;
  nameKey: keyof T;
  itemField?: keyof T;
  showLabelKey?: keyof T;
};

const ShowLabelsDashboard = <T extends MinimumResource>({
  handleDelete,
  getResourceList,
  filterData,
  notFilterData,
  nameKey,
  itemField = 'uuid',
  showLabelKey,
}: ComponentProps<T>): JSX.Element => {
  const allData = useSelector(getResourceList);

  return (
    <div className="flex flex-col">
      {[...filterData, ...notFilterData].map((labelId) => {
        const label = allData.find((tag) => {
          const value = tag[itemField];
          const tagName = typeof value === 'string' ? value : tag.uuid;
          return tagName === labelId;
        });
        if (label) {
          const value = label[itemField];
          const labelName = typeof value === 'string' ? value : label.uuid;
          return (
            <div
              key={label.uuid}
              role="button"
              aria-hidden
              className="relative flex justify-start items-center mt-2.5"
              onClick={(): void => handleDelete(labelName, notFilterData.includes(labelName))}
            >
              <div className="flex items-center h-5 mr-1">
                <input
                  id={`${label.uuid}-show-label`}
                  data-testid={`${label.uuid}-show-label`}
                  type="checkbox"
                  className={`form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out cursor-pointer ${
                    notFilterData.includes(labelId) ? 'litlingo-checkbox-negated' : ''
                  }`}
                  checked={filterData.includes(labelName) || notFilterData.includes(labelName)}
                />
              </div>
              <div className="text-sm leading-5">
                <label htmlFor={label.uuid} className="text-white text-xss cursor-pointer">
                  <span className="select-none break-all">{label[showLabelKey || nameKey]} </span>
                </label>
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default ShowLabelsDashboard;
