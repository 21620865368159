import { requestTreeFiltersToogle, setTree } from 'actions';
import { fromAssignments } from 'actions/envelopeListView';
import ButtonMenuGeneral from 'components/Button/ButtonMenuGeneral';
import FilterPills from 'components/EnvelopeList/FilterPills';
import AppendSampleModal from 'components/Sample/AppendSampleModal';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useTree from 'hooks/envelopes/useTree';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getEnvelopesTotalCount } from 'selectors/envelopes';
import { getSelectedReviewSet } from 'selectors/savedSearches';
import { useSelector } from 'store';
import { RouteParams, Tree } from 'types';
import {
  Operator,
  buildFSFromParams,
  fillDefaultTree,
  fillUrlWithFilters,
  getAllFieldsValuesWithLabels,
  getNavParamsFromFilters,
} from 'utils/parserTree';
import { getParamsFromUrl, reverse, useHistory } from 'utils/urls';
import { v4 } from 'uuid';

const FilterSection: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(getUser);
  const reviewSet = useSelector(getSelectedReviewSet);
  const envelopesCount = useSelector(getEnvelopesTotalCount);

  const [isAppendModalOpen, setIsAppendModalOpen] = useState(false);

  let navParams: RouteParams = {};

  if (user.customer) {
    if (reviewSet) {
      navParams = getParamsFromUrl(
        reviewSet.url,
        resourceQueryParamName.envelopes,
        'envelope-list',
        user.customer?.config
      );
    }
  }
  const filterSearch = (navParams.filters_search ?? buildFSFromParams(navParams)) as string;
  const { data, error, isLoading } = useTree(filterSearch);

  let tree: Tree | null = {
    op: Operator.AND,
    data: [],
    id: v4(),
  };
  if (data && data.data.length > 0 && !error) {
    navParams = getNavParamsFromFilters(filterSearch, navParams);
    tree = fillDefaultTree(data, navParams);
  }

  const treeParams = getAllFieldsValuesWithLabels(tree);

  if (!reviewSet) return null;

  const toggleAppendModal = (): void => {
    setIsAppendModalOpen(!isAppendModalOpen);
  };

  const buildUrl = (editReview: boolean): string => {
    const params = { ...navParams };
    let url = new URLSearchParams();
    if (params.filters_search) {
      const fs = params.filters_search as string;
      url = fillUrlWithFilters(url, fs, params);

      Object.entries(params).forEach(([key, value]) => {
        if (key === 'sample_uuid') {
          url.append(`envelopes__${key}`, value as string);
        }
      });
    }
    if (editReview) url.append('envelopes__review_set_edit_uuid', `${reviewSet.uuid.toString()}`);
    return url.toString();
  };

  const handleNavigateEditUrl = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    editSearch: boolean
  ): void => {
    const url = buildUrl(editSearch);
    dispatch(requestTreeFiltersToogle({ value: true }));
    dispatch(
      setTree({
        tree: {
          op: 'and',
          data: [],
          id: v4(),
        },
      })
    );

    if (e.ctrlKey || e.metaKey) {
      window.open(
        reverse({
          routeName: 'envelope-list',
          queryParams: url,
          customerDomain: user.customer?.domain,
        }),
        '_blank'
      );
      return;
    }

    dispatch(
      fromAssignments({
        assignmentUuid: '',
        reviewSetName: reviewSet.name,
        reviewSetUrl: `?${url}`,
      })
    );

    history.push(
      reverse({
        routeName: 'envelope-list',
        queryParams: url,
        customerDomain: user.customer?.domain,
      })
    );
  };

  return (
    <>
      <div className="flex flex-col gap-1">
        <div className="flex flex-row items-end justify-between text-body">
          <span className="font-bold">Filter</span>
          {/* @ts-ignore */}
          {reviewSet.category !== 'sample' && (
            <div className="flex flex-row self-end w-24">
              <ButtonMenuGeneral
                primaryLabel="Actions"
                options={[
                  {
                    id: 'edit',
                    label: 'Update Filter',
                    action: (e): void => handleNavigateEditUrl(e, true),
                  },
                  { id: 'append', label: 'Append Sample', action: toggleAppendModal },
                ]}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-4 justify-between p-4 w-full bg-litlingo-gray-1 bg-opacity-30">
          {!isLoading && tree && (
            <FilterPills
              propParams={navParams}
              treePropsParams={treeParams}
              allowDelete={false}
              envelopesPage={false}
              customTree={tree}
            />
          )}

          <button
            type="button"
            onClick={(e): void => handleNavigateEditUrl(e, false)}
            className="self-end underline text-litlingo-primary-120 focus:outline-none"
          >
            {`${reviewSet.record_count} ${pluralize('Message', reviewSet.record_count)}`}
          </button>
        </div>
      </div>
      {isAppendModalOpen && (
        <AppendSampleModal
          isModalOpen={isAppendModalOpen}
          toggleModalOpen={toggleAppendModal}
          envelopesTotalCount={envelopesCount}
          navParams={navParams}
          treeParams={treeParams}
          tree={tree}
        />
      )}
    </>
  );
};

export default FilterSection;
