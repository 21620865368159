import { addValueToTree, removeValueFromTree } from 'actions';
import MultiSelect from 'components/MultiSelect';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getNavParamsFromTree } from 'selectors/nav';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';

type ComponentProps = {
  options: {
    [key: string]: string;
  };
  filter: string;
  notFilter?: string;
  allowMultipleValues?: boolean;
};

const MultiSelectFilter: React.FC<ComponentProps> = (props) => {
  const { options, filter, notFilter = '', allowMultipleValues = false } = props;

  const dispatch = useDispatch();

  const params = useSelector(getNavParamsFromTree);
  const filters = (params[filter] ?? []) as string[];
  const notFilters = (params[notFilter] ?? []) as string[];

  const handleChange = (key: string): void => {
    if (filters.includes(key)) {
      dispatch(removeValueFromTree({ value: key, field: filter }));
    } else if (notFilters.includes(key)) {
      dispatch(removeValueFromTree({ value: key, field: notFilter }));
    } else {
      dispatch(
        addValueToTree({
          field: filter,
          value: key,
          label: options[key],
          isSingleValue: !allowMultipleValues,
        })
      );
    }
    logEvent(`envelopes-list-filter-${filter}`);
  };

  const selected: string[] = [];
  filters.forEach((v) => {
    if (options[v]) {
      selected.push(v);
    }
  });

  const notSelected: string[] = [];
  notFilters.forEach((v) => {
    if (options[v]) {
      notSelected.push(v);
    }
  });

  return (
    <div className="mt-1">
      <MultiSelect
        options={options}
        onChange={handleChange}
        selectedItems={selected}
        notSelectedItems={notSelected}
        labelClasses="text-white font-normal text-xss"
        prefix={filter}
      />
    </div>
  );
};

export default MultiSelectFilter;
