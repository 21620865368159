import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import SearchInput from 'components/SearchInput';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getDeleteTemplateLoading,
  getFetchAllTemplatesLoading,
  getUpsertTemplateLoading,
} from 'selectors/templates';
import type { Template } from 'types';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import TemplateForm from './TemplateForm';
import TemplatesList from './TemplatesList';

const TemplatesManager: React.FC = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<Template>({} as Template);
  const [toggleForm, setToggleForm] = useState<boolean>(false);
  const upsertLoading = useSelector(getUpsertTemplateLoading);
  const deleteLoading = useSelector(getDeleteTemplateLoading);
  const fetchLoading = useSelector(getFetchAllTemplatesLoading);

  const handleChangeTemplate = (template: Template): void => {
    setSelectedTemplate(template);
    setToggleForm(true);
  };

  const handleNewTemplate = (): void => {
    handleChangeTemplate({
      name: '',
      value: '',
    } as Template);
  };

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <div
      className="min-w-screen bg-white"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />
      <div
        className="flex flex-row"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <SettingSidebar />
        <div className="py-10 overflow-auto">
          <div>
            <header>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="mt-2 md:flex md:items-center md:justify-between">
                  <div className="flex-1 min-w-0">
                    <div className="flex flex-row items-center">
                      <h2 className="text-left text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
                        Templates{' '}
                      </h2>
                      {(upsertLoading || deleteLoading || fetchLoading) && (
                        <LoadingIndicator className="ml-2" size="5" />
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-4 flex-shrink-0 flex justify-between">
                  <SearchInput resource={resourceQueryParamName.templates} field="broad_search" />
                  <span className="ml-3 shadow-sm rounded-md">
                    <button
                      onClick={handleNewTemplate}
                      type="button"
                      className="button button--primary"
                      data-testid="new-template-button"
                    >
                      Create New Template
                    </button>
                  </span>
                </div>

                <div className="mt-4">
                  <TemplateForm
                    template={selectedTemplate}
                    toggleForm={toggleForm}
                    setToggleForm={setToggleForm}
                  />
                </div>
              </div>
            </header>
          </div>
          <main>
            <div className="mt-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-left">
              <TemplatesList handleChangeTemplate={handleChangeTemplate} />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default TemplatesManager;
