import { createAction } from '@reduxjs/toolkit';
import type {
  CommonWords,
  CommsByPlatforms,
  DashboardMetric,
  EnvelopeBucket,
  ErrorObject,
  EventsBucket,
  EventsByCampaign,
  EventsByState,
  MetricsData,
  QueryMetricObject,
  SentimentOverTime,
  TagsCounts,
  timeGroupingType,
  TopSender,
  WorkforceRisk,
} from 'types';
import type { TimeRange } from 'utils/timeRanges';

type AnalyticsPayload = TimeRange & {
  limit?: number;
};

export type CountPayload = { count: number };

export const fetchWorkforceRisk = createAction<AnalyticsPayload>('ANALYTICS/FETCH_WORKFORCE_RISK');
export const fetchWorkforceRiskRequest = createAction('ANALYTICS/FETCH_WORKFORCE_RISK_REQUEST');
export const fetchWorkforceRiskSuccess = createAction<WorkforceRisk>(
  'ANALYTICS/FETCH_WORKFORCE_RISK_SUCCESS'
);
export const fetchWorkforceRiskFailure = createAction<ErrorObject>(
  'ANNOTATORS/FETCH_WORKFORCE_RISK_FAILURE'
);

export const fetchAllPendingEvents = createAction<AnalyticsPayload>(
  'ANALYTICS/FETCH_PENDING_EVENTS'
);
export const fetchAllPendingEventsRequest = createAction('ANALYTICS/FETCH_PENDING_EVENTS_REQUEST');
export const fetchAllPendingEventsSuccess = createAction<CountPayload>(
  'ANALYTICS/FETCH_PENDING_EVENTS_SUCCESS'
);
export const fetchAllPendingEventsFailure = createAction<ErrorObject>(
  'ANNOTATORS/FETCH_PENDING_EVENTS_FAILURE'
);

export const fetchAllEventsCount = createAction<AnalyticsPayload>('ANALYTICS/FETCH_EVENTS');
export const fetchAllEventsRequest = createAction('ANALYTICS/FETCH_EVENTS_REQUEST');
export const fetchAllEventsSuccess = createAction<EventsByState>('ANALYTICS/FETCH_EVENTS_SUCCESS');
export const fetchAllEventsFailure = createAction<ErrorObject>('ANNOTATORS/FETCH_EVENTS_FAILURE');

export const fetchViolatedEventsCount = createAction<AnalyticsPayload>(
  'ANALYTICS/FETCH_VIOLATED_EVENTS'
);
export const fetchViolatedEventsRequest = createAction('ANALYTICS/FETCH_VIOLATED_EVENTS_REQUEST');
export const fetchViolatedEventsSuccess = createAction<CountPayload>(
  'ANALYTICS/FETCH_VIOLATED_EVENTS_SUCCESS'
);
export const fetchViolatedEventsFailure = createAction<ErrorObject>(
  'ANNOTATORS/FETCH_VIOLATED_EVENTS_FAILURE'
);

export const fetchCommsByPlatform = createAction<AnalyticsPayload>(
  'ANALYTICS/FETCH_COMMS_BY_PLATFORMS'
);
export const fetchCommsByPlatformRequest = createAction(
  'ANALYTICS/FETCH_COMMS_BY_PLATFORMS_REQUEST'
);
export const fetchCommsByPlatformSuccess = createAction<CommsByPlatforms>(
  'ANALYTICS/FETCH_COMMS_BY_PLATFORMS_SUCCESS'
);
export const fetchCommsByPlatformFailure = createAction<ErrorObject>(
  'ANALYTICS/FETCH_COMMS_BY_PLATFORMS_FAILURE'
);

export const fetchTopUsersByEvents = createAction<AnalyticsPayload>(
  'ANALYTICS/FETCH_TOP_USERS_BY_EVENTS'
);
export const fetchTopUsersByEventsRequest = createAction(
  'ANALYTICS/FETCH_TOP_USERS_BY_EVENTS_REQUEST'
);
export const fetchTopUsersByEventsSuccess = createAction<TopSender[]>(
  'ANALYTICS/FETCH_TOP_USERS_BY_EVENTS_SUCCESS'
);
export const fetchTopUsersByEventsFailure = createAction<ErrorObject>(
  'ANALYTICS/FETCH_TOP_USERS_BY_EVENTS_FAILURE'
);

export const fetchCampaignsPerformance = createAction<AnalyticsPayload>(
  'ANALYTICS/FETCH_CAMPAIGN_PERFORMANCE'
);
export const fetchCampaignsPerformanceRequest = createAction(
  'ANALYTICS/FETCH_CAMPAIGN_PERFORMANCE_REQUEST'
);
export const fetchCampaignsPerformanceSuccess = createAction<EventsByCampaign[]>(
  'ANALYTICS/FETCH_CAMPAIGN_PERFORMANCE_SUCCESS'
);
export const fetchCampaignsPerformanceFailure = createAction<ErrorObject>(
  'ANALYTICS/FETCH_CAMPAIGN_PERFORMANCE_FAILURE'
);

export const fetchEventBuckets = createAction<AnalyticsPayload>('ANALYTICS/FETCH_EVENT_BUCKETS');
export const fetchEventBucketsRequest = createAction('ANALYTICS/FETCH_EVENT_BUCKETS_REQUEST');
export const fetchEventBucketsSuccess = createAction<EventsBucket[]>(
  'ANALYTICS/FETCH_EVENT_BUCKETS_SUCCESS'
);
export const fetchEventBucketsFailure = createAction<ErrorObject>(
  'ANALYTICS/FETCH_EVENT_BUCKETS_FAILURE'
);

export const fetchEnvelopesBuckets = createAction<AnalyticsPayload>(
  'ANALYTICS/FETCH_ENVELOPES_BUCKETS'
);
export const fetchEnvelopesBucketsRequest = createAction(
  'ANALYTICS/FETCH_ENVELOPES_BUCKETS_REQUEST'
);
export const fetchEnvelopesBucketsSuccess = createAction<EnvelopeBucket[]>(
  'ANALYTICS/FETCH_ENVELOPES_BUCKETS_SUCCESS'
);
export const fetchEnvelopesBucketsFailure = createAction<ErrorObject>(
  'ANALYTICS/FETCH_ENVELOPES_BUCKETS_FAILURE'
);

export const fetchSentimentOverTime = createAction('ANALYTICS/FETCH_SENTIMENT_OVER_TIME');
export const fetchSentimentOverTimeRequest = createAction(
  'ANALYTICS/FETCH_SENTIMENT_OVER_TIME_REQUEST'
);
export const fetchSentimentOverTimeSuccess = createAction<SentimentOverTime>(
  'ANALYTICS/FETCH_SENTIMENT_OVER_TIME_SUCCESS'
);
export const fetchSentimentOverTimeFailure = createAction<ErrorObject>(
  'ANNOTATORS/FETCH_SENTIMENT_OVER_TIME_FAILURE'
);

export const fetchCommonWords = createAction('ANALYTICS/FETCH_COMMON_WORDS');
export const fetchCommonWordsRequest = createAction('ANALYTICS/FETCH_COMMON_WORDS_REQUEST');
export const fetchCommonWordsSuccess = createAction<CommonWords>(
  'ANALYTICS/FETCH_COMMON_WORDS_SUCCESS'
);
export const fetchCommonWordsFailure = createAction<ErrorObject>(
  'ANNOTATORS/FETCH_COMMON_WORDS_FAILURE'
);

export const fetchTagsAnalytics = createAction('ANALYTICS/FETCH_TAGS');
export const fetchTagsAnalyticsSuccess = createAction<TagsCounts>('ANALYTICS/FETCH_TAGS_SUCCESS');
export const fetchTagsAnalyticsFailure = createAction<ErrorObject>('ANALYTICS/FETCH_TAGS_FAILURE');
export const fetchTagsAnalyticsFulfill = createAction('ANALYTICS/FETCH_TAGS_FULFILL');

export const fetchMetricsData = createAction<{
  metrics: DashboardMetric[];
  fetchOnePeriod: boolean;
  fetchAvgPeriod: boolean;
  widgetId: string;
  timeRange?: string;
  metricType?: string;
  timeGrouping?: timeGroupingType;
  unitOfTime?: string;
  use_cache?: boolean;
  customer_uuids?: string[];
}>('ANALYTICS/FETCH_METRICS');

export const fetchMetricsExportData = createAction<{
  metrics: DashboardMetric[];
  fetchOnePeriod: boolean;
  fetchAvgPeriod: boolean;
  widgetId: string;
  timeRange?: string;
  metricType?: string;
  timeGrouping?: timeGroupingType;
  finalTitle?: string;
}>('ANALYTICS/FETCH_METRICS_EXPORT');

export const fetchMetricsDataRequest = createAction<{ widgetId: string }>(
  'ANALYTICS/FETCH_METRICS_REQUEST'
);
export const fetchMetricsDataSuccess = createAction<MetricsData & { widgetId: string }>(
  'ANALYTICS/FETCH_METRICS_SUCCESS'
);
export const fetchMetricsDataFailure = createAction<ErrorObject & { widgetId: string }>(
  'ANALYTICS/FETCH_METRICS_FAILURE'
);
export const fetchMetricsDataFulfill = createAction<{ widgetId: string }>(
  'ANALYTICS/FETCH_METRICS_FULFILL'
);
export const saveWidgetQueryData = createAction<QueryMetricObject & { id: string }>(
  'ANALYTICS/SAVE_WIDGET_QUERY_DATA'
);
