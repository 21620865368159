import { saveUsers, sendUserInvitation, showSuccessAlert, spoofRoles } from 'actions';
import { fetchUserInvitationState, setNeedToApplyChanges } from 'actions/userPermissionsPage';
import EnvelopeCollapsableSection from 'components/CollapsableSection/EnvelopeCollapsableSection';
import LinkLookup from 'components/LinkLookup';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUser } from 'selectors/auth';
import getUsers, {
  getCanApplySaveSingleUser,
  getExcludedRoles,
  getIncludedRoles,
  getInvitationState,
  getSelectedUsers,
} from 'selectors/userPermissionsPage';
import { useSelector } from 'store';
import { meetsRolesReqs } from 'utils/permissions';
import UserInformationSection from './UserInformationSection';
import UsersChangePermissions from './UsersChangePermissions';

type ComponentProps = {
  footer?: boolean;
  saveInApply?: boolean;
  shouldDeleteUser?: boolean;
};

const SingleUserSidebar: React.FC<ComponentProps> = ({
  footer = true,
  saveInApply = false,
  shouldDeleteUser = false,
}) => {
  const dispatch = useDispatch();
  const selectedUsers = useSelector(getSelectedUsers);
  const canApplySingleUser = useSelector((state) =>
    getCanApplySaveSingleUser(state, saveInApply ? 'true' : 'false')
  );
  const invitationState = useSelector(getInvitationState);

  const users = useSelector(getUsers);
  const user = useSelector(getUser);

  const [sendInvitation, setSendInvitation] = useState(true);

  const includedRoles = useSelector(getIncludedRoles);
  const excludedRoles = useSelector(getExcludedRoles);

  const [canSendInvitation, setCanSendInvitation] = useState(false);

  const renderSettings = (): boolean => meetsRolesReqs(user.roles, ['super-admin', 'system-admin']);

  useEffect(() => {
    if (!saveInApply) {
      // @ts-ignore
      const canSend = users[0].roles.includes('Reviewer');
      setCanSendInvitation(canSend);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users[0], includedRoles, excludedRoles]);

  useEffect(() => {
    if (renderSettings()) {
      dispatch(fetchUserInvitationState({ userId: selectedUsers[0].uuid }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedUsers]);

  const handleConfirmSave = (): void => {
    if (canApplySingleUser) {
      dispatch(setNeedToApplyChanges(true));
      return;
    }

    dispatch(
      saveUsers({
        users,
        sendInvitation: !!(sendInvitation && canSendInvitation),
        redirect: true,
        fromPermissionsPage: true,
      })
    );
  };

  const handleSendInvitation = (event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>): void => {
    event.stopPropagation();
    dispatch(sendUserInvitation({ users: selectedUsers }));
  };

  const handleCopyClick = async (
    event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>
  ): Promise<void> => {
    event.stopPropagation();
    const ids = selectedUsers.reduce((acc, value, idx) => {
      if (idx === 0) {
        return value.uuid;
      }
      return `${acc}, ${value.uuid}`;
    }, '');
    await navigator.clipboard.writeText(ids);
    dispatch(showSuccessAlert('User UUID(s) copied to your clipboard'));
  };

  const handleSpoofClick = (event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>): void => {
    event.stopPropagation();
    dispatch(
      spoofRoles({
        userUuid: selectedUsers[0].uuid,
        userEmail: selectedUsers[0].email || undefined,
      })
    );
    dispatch(showSuccessAlert('View Changed'));
  };

  return (
    <div className="relative h-full flex flex-col bg-white">
      <div className="flex-1 overflow-auto no-scrollbar">
        <div className="h-full">
          <EnvelopeCollapsableSection headerTitle="Selected User">
            <>
              <div className="px-3 py-2 flex flex-row gap-2 bg-litlingo-gray-1 bg-opacity-50">
                <button
                  onClick={(e): void => handleSpoofClick(e)}
                  type="submit"
                  className="button button--secondary h-8"
                  data-testid="save-button"
                  disabled={selectedUsers.length > 1}
                >
                  <span className="font-bold whitespace-no-wrap">View As</span>
                </button>
                <button
                  onClick={(e): void => handleSendInvitation(e)}
                  type="submit"
                  className="button button--secondary h-8"
                  data-testid="save-button"
                >
                  <span className="font-bold whitespace-no-wrap">Send Invitation</span>
                </button>
                <button
                  onClick={(e): void => {
                    handleCopyClick(e);
                  }}
                  type="submit"
                  className="flex button button--secondary h-8"
                  data-testid="save-button"
                >
                  <span className="font-bold whitespace-no-wrap">Copy ID</span>
                </button>
              </div>
              <UserInformationSection
                user={selectedUsers[0]}
                shouldDeleteUser={shouldDeleteUser}
                invitationState={invitationState}
              />
              <UsersChangePermissions
                saveInApply={saveInApply}
                sendInvitation={sendInvitation}
                setSendInvitation={setSendInvitation}
                canSendInvitation={canSendInvitation}
              />
            </>
          </EnvelopeCollapsableSection>
        </div>
      </div>

      {footer && (
        <div
          className="flex flex-col gap-1 bg-white border border-litlingo-gray-1 p-4"
          style={{ filter: 'drop-shadow(3px 1px 8px rgba(0, 0, 0, 0.25))' }}
        >
          <div className="flex flex-row items-center justify-between gap-6 h-8">
            <LinkLookup
              routeName="users"
              data-testid="next-envelope-button"
              className="button button--secondary w-27 py-1.5 h-full"
            >
              <div className="flex w-full justify-center items-center font-bold">Exit</div>
            </LinkLookup>
            <button
              type="button"
              id="next-envelope-button"
              data-testid="next-envelope-button"
              className="button  button--primary min-w-27 h-full py-1.5 focus:outline-none"
              onClick={handleConfirmSave}
              disabled={canApplySingleUser ?? false}
            >
              <div className="flex w-full justify-center items-center text-base font-bold leading-5">
                Save & Exit
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default SingleUserSidebar;
