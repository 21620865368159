import { closeFilter, openFilter } from 'actions/envelopeListView';
import { FILTER_SELECTED_ICON, MINUS_ICON, PLUS_ICON } from 'constants/filterIcons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getIsFilterOpen from 'selectors/envelopeListView';

type ComponentProps = {
  headerTitle: string;
  children: JSX.Element;
  customLoading?: boolean;
  dataTestid?: string;
  isDefaultClosed?: boolean;
  selectedFilters?: string | string[] | undefined;
  border?: boolean;
  isOpen?: boolean;
};

const EnvelopeListSidebarCollapsableSection: React.FC<ComponentProps> = (props) => {
  const {
    headerTitle,
    children,
    isDefaultClosed,
    isOpen,
    dataTestid,
    customLoading,
    selectedFilters,
    border = true,
  } = props;

  const dispatch = useDispatch();

  const [isOpening, setIsOpening] = useState(false);

  const filterKey = headerTitle.toLowerCase().replace(' ', '-');

  const openState = useSelector(getIsFilterOpen(filterKey));

  const headerClasses = 'h-5 px-0';
  const containerClasses = `pt-2 pb-2.5 ${border && 'border-b border-litlingo-white'}`;
  const titleClasses = ' text-base font-bold text-white leading-5';
  const contentClasses = 'px-0';

  useEffect(() => {
    if (!isDefaultClosed && openState === null) {
      dispatch(openFilter(filterKey));
    }
  }, [dispatch, filterKey, isDefaultClosed, openState]);

  // allows us to inject open state
  useEffect(() => {
    if (isOpen) {
      dispatch(openFilter(filterKey));
    }
  }, [dispatch, filterKey, isOpen]);

  const handleCollapse = (): void => {
    if (openState) {
      setIsOpening(false);
      dispatch(closeFilter(filterKey));
    } else {
      setIsOpening(true);
      dispatch(openFilter(filterKey));
    }
  };

  const isSelectedFilter = selectedFilters && selectedFilters.length > 0;

  return (
    <div
      data-testid={dataTestid || 'section'}
      className={`envelope-sidebar-collapsable ${containerClasses}`}
    >
      <div
        className={headerClasses}
        role="button"
        onClick={handleCollapse}
        aria-hidden="true"
        data-testid="collapse-button"
      >
        <div className="flex flex-row justify-between items-center text-white">
          <div className="flex items-center">
            <h1 className={titleClasses}>{headerTitle}</h1>
            {customLoading && (
              <div className="ml-4 flex float-left loader ease-linear rounded-full border-2 border-gray-200 h-4 w-4 self-center" />
            )}
            {isSelectedFilter && <span className="ml-2">{FILTER_SELECTED_ICON()}</span>}
          </div>
          {openState ? MINUS_ICON('white') : PLUS_ICON('white')}
        </div>
      </div>
      <div className={contentClasses} data-testid="collapsable-content">
        <div
          className={`collapsable-content-container flex flex-col ${
            openState ? 'opacity-100' : 'litlingo-collapsed opacity-0'
          }`}
        >
          {React.createElement(children.type, { ...children.props, isOpening })}
        </div>
      </div>
    </div>
  );
};

export default EnvelopeListSidebarCollapsableSection;
