import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { useNavParamsByResource } from 'hooks/nav/useNavParamsByResource';
import { RequestParamsType, RequestResponse } from 'hooks/types/client';
import useRequest from '../client/useRequest';

type Options = { redirectOnError: boolean; broad_search?: string; offset?: number };

type UseTeams = (options?: Options) => RequestResponse<'teams.list'>;

const useTeams: UseTeams = (options) => {
  const resourceParams = useNavParamsByResource(resourceQueryParamName.teams);

  const params: RequestParamsType = {
    queryParams: { ...resourceParams, ...options, relationships: ['users'], include_count: true },
    redirectOnError: options?.redirectOnError,
  };

  const result = useRequest('teams.list', params);

  return result;
};

export default useTeams;
