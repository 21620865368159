/* eslint-disable react/jsx-props-no-spreading */
import {
  cloneAnnotator,
  deleteAnnotator,
  fetchAnnotatorTypes,
  saveAnnotatorRequest,
  upsertAnnotator,
} from 'actions';
import AnnotatorAuditLogsTable from 'components/Annotator/AnnotatorAuditLogsTable';
import RenderOptionsConfig from 'components/Annotator/RenderOptionsConfig';
import CampaignRuleOutcomesTable from 'components/CampaignRuleOutcomesTable';
import ConfirmModal from 'components/ConfirmModal';
import CustomerSimpleList from 'components/CustomerSimpleList';
import LanguageMatcher from 'components/LanguageMatcher';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import Navbar from 'components/Navbar';
import RenderTestSuite from 'components/RenderTestSuite';
import TestVisualizer from 'components/TestVisualizer';
import keyMap from 'constants/configHotKeys';
import { fieldTypes } from 'constants/fieldTypes';
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { GlobalHotKeys } from 'react-hotkeys';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAnnotator, getAnnotatorTypes } from 'selectors/annotator';
import { getTestSentenceLoading } from 'selectors/communications';
import { useSelector } from 'store';
import type { Annotator as AnnotatorT, UUID } from 'types';
import AnnotatorHeader from './AnnotatorHeader';

type RouterParams = {
  annotatorId: UUID;
};

const Annotator: React.FC = () => {
  const { annotatorId } = useParams<RouterParams>();
  const dispatch = useDispatch();
  const annotator = useSelector((state) => getAnnotator(state, annotatorId)) || {};
  const testSentenceLoading = useSelector(getTestSentenceLoading);
  const types = useSelector(getAnnotatorTypes);
  const methods = useForm<AnnotatorT>();

  const [isDeletingAnnotator, setIsDeletingAnnotator] = useState(false);
  const [isCloneAnnotatorOpen, setIsCloneAnnotatorOpen] = useState(false);

  useEffect(() => {
    if (annotatorId) {
      dispatch(fetchAnnotatorTypes());
    }
  }, [annotatorId, dispatch]);

  useEffect(() => {
    if (
      annotator &&
      annotator.options_config &&
      Object.keys(annotator.options_config) &&
      types &&
      Object.keys(types).length > 0
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const defaultData: { [key: string]: any } = {};
      Object.entries(types[annotator.type].options).forEach(([key, value]) => {
        let inputValue;
        if (annotator.options_config[key]) {
          inputValue = annotator.options_config[key];
        } else if (value?.type === fieldTypes.bool) {
          inputValue = false;
        } else {
          inputValue = '';
        }

        defaultData[key] = Array.isArray(inputValue) ? inputValue : [inputValue];
      });
      methods.reset(defaultData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotator.options_config, types]);

  const toggleConfirmModal = (): void => setIsDeletingAnnotator(false);

  const handleDeleteClick = async (): Promise<void> => {
    dispatch(deleteAnnotator({ annotatorId: annotator.uuid, redirect: true }));
    toggleConfirmModal();
  };

  const toggleIsCloneAnnotatorOpen = (): void => {
    setIsCloneAnnotatorOpen(!isCloneAnnotatorOpen);
  };

  const handlers = {
    SAVE_ANNOTATOR: (event: KeyboardEvent | undefined): void => {
      if (event) {
        event.preventDefault();
        const button = document.querySelector('#save-annotator-setup-button') as HTMLButtonElement;
        if (button) button.click();
      }
    },
  };

  const renderAnnotatorScreen = (
    <CustomerSimpleList
      toggleShowCloneResourceModalOpen={toggleIsCloneAnnotatorOpen}
      resourceId={annotatorId}
      cloneResource={cloneAnnotator}
    />
  );

  const onSubmit = async (values?: AnnotatorT): Promise<void> => {
    if (!annotator.name && !values) return;

    const options = {};
    const typeObj = types[annotator.type];
    if (typeObj && typeObj.options && Object.keys(typeObj.options) && values != null) {
      (Object.keys(typeObj.options) as Array<keyof typeof typeObj.options>).forEach((key) => {
        // @ts-ignores
        let val = values[key];
        if (typeObj.options[key]?.type === 'float') {
          val = parseFloat(val);
        }

        if (Array.isArray(val)) {
          const arrVal: string[] = [];
          val.forEach((el) => {
            const elements = el.trim().split('\n');
            if (elements.length > 1) {
              arrVal.push(...elements.map((e: string) => e.trim()));
            } else {
              arrVal.push(el.trim());
            }
          });
          val = arrVal.filter((el) => !/^\s*$/.test(el));
        }
        // @ts-ignore
        options[key] = val;
      });
    }
    dispatch(saveAnnotatorRequest());

    const annotatorToSend = annotator;

    dispatch(
      upsertAnnotator({
        ...annotatorToSend,
        options_config: values != null ? options : annotator.options_config,
      })
    );
  };

  if (!annotator) return <LoadingIndicator className="m-auto mt-20" size="20" />;

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <div className="flex flex-col bg-white min-w-screen">
        <Navbar />
        <FormContext {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4 h-full pt-6 pb-4">
              <AnnotatorHeader />

              <main>
                <div className="flex flex-col gap-2 px-6">
                  {annotator && annotator.type === 'custom' && (
                    <LanguageMatcher annotatorData={annotator} />
                  )}

                  {annotator &&
                    annotator.options_config &&
                    Object.keys(annotator.options_config).length > 0 && (
                      <RenderOptionsConfig annotator={annotator} types={types} />
                    )}
                  <div className="flex flex-col mt-2 px-2">
                    <div className="flex flex-row items-center gap-2">
                      <h2 className="text-heading-2">Test Visualizer</h2>
                      <span>
                        {testSentenceLoading ? (
                          <LoadingIndicator size="5" data-testid="loading-indicator" />
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    <TestVisualizer />
                  </div>

                  <RenderTestSuite entity="annotator" entityId={annotatorId} />

                  <CampaignRuleOutcomesTable entity="annotator_uuids" value={annotatorId} />

                  <AnnotatorAuditLogsTable annotatorId={annotatorId} />
                </div>
              </main>
            </div>
          </form>
        </FormContext>

        {isCloneAnnotatorOpen && (
          <Modal
            body={renderAnnotatorScreen}
            title="Choose a customer"
            okButton
            okButtonText="Done"
            okButtonOnClick={toggleIsCloneAnnotatorOpen}
            toggleShowModal={toggleIsCloneAnnotatorOpen}
          />
        )}
        {isDeletingAnnotator && (
          <ConfirmModal
            data-testid="delete-modal"
            text="Are you sure you want to delete this identifier?"
            okButtonText="Yes"
            cancelButtonText="No"
            okButtonOnClick={handleDeleteClick}
            toggleShowModal={toggleConfirmModal}
          />
        )}
      </div>
    </>
  );
};

export default Annotator;
