import type { RecursivePartial } from 'types';

export type NodeEnv = 'test' | 'development' | 'production';

export interface OIDCConfig {
  clientId: string;
  responseType: string;
  redirectURI: string;
  scope: string;
  accessType: string;
  prompt: string;
  discoveryURL: string;
  provider: string;
}

// common configs
const CHROME_DOMAIN = 'https://mail.google.com';

const GOOGLE_CLIENT_ID = '135764768326-mi63rbfrs6o7pb6dhahvhj41a3c6b5os.apps.googleusercontent.com';
const LITLINGO_CLIENT_ID = 'ip93cA5k5ACoE1Bsht';
const SENTRY_DSN = 'https://7c8cca1daf314bebb6f52da89e84ce5a@o351283.ingest.sentry.io/5249549';
const ANALYTICS = '31a9c13d169d0f9246be5aa674ad0160';
const TERMS_URL = 'https://k8s.litlingo.com/terms-of-service';
const PRIVACY_URL = 'https://k8s.litlingo.com/privacy-policy';
const GENERAL_URL = 'https://www.litlingo.com/';

const base = {
  node: {
    env: process.env.NODE_ENV as NodeEnv,
  },
  sentry: {
    dsn: SENTRY_DSN,
  },
  app: {
    prNumber: process.env.REACT_APP_PR_NUMBER,
    releaseSha: process.env.REACT_APP_RELEASE_SHA,
  },
  backend: {
    base: '__REPLACE__',
  },
  google: {
    provider: 'google',
    clientId: GOOGLE_CLIENT_ID,
    responseType: 'token',
    redirectURI: `${window.location.origin}/login`,
    scope: 'email profile',
    accessType: 'online',
    prompt: 'consent',

    // discovery
    discoveryURL: 'https://accounts.google.com/.well-known/openid-configuration',
  } as OIDCConfig,
  litlingo: {
    provider: 'litlingo',
    clientId: LITLINGO_CLIENT_ID,
    responseType: 'code',
    redirectURI: `${window.location.origin}/login`,
    scope: 'email openid',
    discoveryURL: 'https://accounts.litlingo.com/.well-known/openid-configuration',
  } as OIDCConfig,
  analytics: {
    value: ANALYTICS,
  },
  extensions: {
    chrome: {
      domain: CHROME_DOMAIN,
    },
  },
  terms: {
    url: TERMS_URL,
  },
  privacy: {
    url: PRIVACY_URL,
  },
  demo: {
    url: GENERAL_URL,
  },
};

export type Config = typeof base;
export type PartialConfig = RecursivePartial<Config>;

export default base;
