/* eslint-disable max-lines */
import { clearState } from 'actions/userPermissionsPage';
import LocalListFooter from 'components/ListFooter/LocalListFooter';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import UserSidebar from 'components/UserSidebar/UserSidebar';
import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import getUsers, { getSelectedUsers, getUnsavedChanges } from 'selectors/userPermissionsPage';
import { getUsersLoading } from 'selectors/users';
import { useSelector } from 'store';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import SaveChangesModal from './SaveChangesModal';
import UserPermissionsFilters from './UserPermissionsFilters';
import UsersPermissionsHeader from './UsersPermissionsHeader';
import UserPermissionsTable from './UsersTable';

const UsersPermissions: React.FC = () => {
  const dispatch = useDispatch();

  const [paginationParams, setPaginationParams] = useState<{ offset: number; limit: number }>({
    limit: 25,
    offset: 0,
  });

  const users = useSelector(getUsers);
  const selectedUsers = useSelector(getSelectedUsers);
  const unsavedChanges = useSelector(getUnsavedChanges);

  const [filteredUsers, setFilteredUsers] = useState(users);
  const [isSavingChanges, setIsSavingChanges] = useState(unsavedChanges);

  const toggleUnsavedChangesModal = (): void => {
    setIsSavingChanges(!isSavingChanges);
  };

  const loading = useSelector(getUsersLoading);

  useEffect(
    () => () => {
      dispatch(clearState());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <>
      <div
        className="bg-white"
        style={{
          height: `calc(100vh - ${warningBarOffset}px)`,
        }}
      >
        <Navbar />
        <div
          className="flex flex-row z-0"
          style={{
            height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
          }}
        >
          <div className="flex flex-col gap-4 w-full h-full overflow-hidden z-10">
            <div className="h-full w-full flex flex-col px-6 pt-4 pb-3 gap-2 overflow-hidden">
              <UsersPermissionsHeader />
              <main className="w-full h-full flex flex-col gap-2 overflow-hidden">
                <div className="flex flex-col gap-1 text-body">
                  <div className="h-8 flex flex-row justify-between">
                    <UserPermissionsFilters setFilteredUsers={setFilteredUsers} users={users} />
                    <span className="self-end whitespace-no-wrap">
                      {`${filteredUsers.length} ${pluralize('User', filteredUsers.length)}`}
                    </span>
                  </div>
                </div>

                <div className="flex flex-col flex-1 gap-2 w-full overflow-hidden">
                  <div className="table-wrapper__new overflow-hidden h-full">
                    <LoadingOverlay
                      active={loading}
                      spinner={<LoadingIndicator size="10" />}
                      className="flex flex-col relative w-full h-full"
                      fadeSpeed={0}
                      styles={{
                        content: (base) => ({
                          ...base,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }),
                        wrapper: () => ({}),
                        spinner: () => ({}),
                        overlay: (base) => ({
                          ...base,
                          zIndex: 10,
                        }),
                      }}
                    >
                      <div className="border-t border-litlingo-gray-1 sticky bottom-0 z-10 bg-white h-full no-scrollbar overflow-auto">
                        <UserPermissionsTable
                          allUsers={filteredUsers}
                          users={filteredUsers.slice(
                            paginationParams.offset,
                            paginationParams.offset + paginationParams.limit
                          )}
                          loading={false}
                          selectedUsers={selectedUsers}
                        />
                      </div>
                      <div className="border-t border-litlingo-gray-1 sticky bottom-0 z-10 bg-white">
                        <LocalListFooter
                          resourceName="user"
                          paginationParams={paginationParams}
                          setPaginationParams={setPaginationParams}
                          totalCount={filteredUsers.length}
                          getResourceLoading={(): boolean => false}
                        />
                      </div>
                    </LoadingOverlay>
                  </div>
                </div>
              </main>
            </div>
          </div>
          {selectedUsers.length > 0 && (
            <div className="envelope-right-sidebar">
              <UserSidebar />
            </div>
          )}
        </div>
      </div>
      {unsavedChanges && <SaveChangesModal toggleModalOpen={toggleUnsavedChangesModal} />}
    </>
  );
};

export default UsersPermissions;
