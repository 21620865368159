import React from 'react';
import { isCustomerAccount } from '../utils/customers';

type CustomerWarningProps = {
  customerId: string | null;
  userEmail: string | null;
  className?: string;
};

const CustomerWarning: React.FC<CustomerWarningProps> = (props) => {
  const { customerId, userEmail, className } = props;

  if (
    customerId == null ||
    userEmail == null ||
    !isCustomerAccount(customerId, userEmail)
  ) {
    return null;
  }

  return (
    <div
      className={`bg-yellow-400 font-medium p-1 pl-5 text-left text-sm ${className}`}
    >
      You&apos;re in a customer account. Proceed with caution.
    </div>
  );
};
CustomerWarning.defaultProps = {
  className: '',
};

export default CustomerWarning;
