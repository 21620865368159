import AuditLogsTable from 'components/AuditLogsTable';
import CollapsableCard from 'components/CollapsableCard';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAnnotatorsAuditLogs } from 'reducers/annotatorsAuditLogs';
import {
  getAnnotatorsAuditLogsCount,
  getAnnotatorsAuditLogsList,
  getFetchAnnotatorsAuditLogsLoading,
} from 'selectors/annotatorsAuditLogs';
import { getNavParamsByResource } from 'selectors/nav';
import type { UUID } from 'types';
import { objToString } from 'utils/objToString';

type AnnotatorAuditLogsTableProps = {
  annotatorId: UUID;
};

const AnnotatorAuditLogsTable: React.FC<AnnotatorAuditLogsTableProps> = (props) => {
  const { annotatorId } = props;

  const dispatch = useDispatch();
  const params = useSelector(getNavParamsByResource(resourceQueryParamName.auditLogs));
  const formattedParams = objToString(params);

  useEffect(() => {
    dispatch(fetchAnnotatorsAuditLogs({ annotator_uuids: [annotatorId] }));
  }, [dispatch, annotatorId, formattedParams]);

  return (
    <CollapsableCard headerTitle="Audit logs">
      <div className="p-4">
        <AuditLogsTable
          getList={getAnnotatorsAuditLogsList}
          getCount={getAnnotatorsAuditLogsCount}
          getLoading={getFetchAnnotatorsAuditLogsLoading}
        />
      </div>
    </CollapsableCard>
  );
};

export default AnnotatorAuditLogsTable;
