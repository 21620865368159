import moment from 'moment';
import React from 'react';

export type Header = { label: string | number; value?: string | number };
export type Row = { label?: string; id?: string; values: number[] };

type ComponentProps = {
  headers: Header[];
  rows: Row[];
  reversed: boolean;
  setReversed: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedView?: boolean;
};

const WidgetTable: React.FC<ComponentProps> = (props) => {
  const { headers, rows, reversed, setReversed, isExpandedView } = props;

  let finalHeaders: Header[] = [...headers];
  let finalRows: Row[] = [...rows];

  if (reversed) {
    finalHeaders = rows.map((r) => ({
      label: r.label || '',
    }));
    finalRows = headers.map((h, index) => ({
      id: `${h.label.toString()}-${index}`,
      label: h.label.toString(),
      values: rows.map((r) => r.values[index]),
    }));
  }

  const hasLabelColumn = finalRows.some((r) => r.label);

  return (
    <div className="flex w-full overflow-hidden">
      <table className="relative widget-table custom-scrollbar">
        <thead>
          <tr className="relative">
            {hasLabelColumn && (
              <th aria-hidden className="h-full bg-litlingo-gray-2 left-0 z-10">
                {!isExpandedView && (
                  <button
                    type="button"
                    aria-label="reverse"
                    className="h-full absolute top-0 left-0 z-50 focus:outline-none"
                    onClick={(): void => setReversed(!reversed)}
                  >
                    <svg
                      height="100%"
                      viewBox="0 0 78 78"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0.0958557 0H78L0.0958557 78V0Z" fill="#58A280" />
                    </svg>
                  </button>
                )}
              </th>
            )}
            {finalHeaders.map((header) => {
              const { label, value } = header;
              const date = moment(label);
              const labelText = date.isValid() ? date.format('MM/DD/YY') : label;

              return (
                <th key={value} className="bg-white">
                  <span className="font-medium">{labelText}</span>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {finalRows.map((row) => {
            const { label, values, id } = row;
            const date = moment(label);
            const labelText = date.isValid() ? date.format('MM/DD/YY') : label;

            return (
              <tr key={id}>
                {hasLabelColumn && (
                  <td className="px-1 bg-litlingo-gray-2 text-body whitespace-no-wrap sticky left-0">
                    <span className="font-bold">{labelText}</span>
                  </td>
                )}

                {values.map((value, index) => (
                  <td key={`${finalHeaders[index].label}-${id}`}>{value}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default WidgetTable;
