import { createAction } from '@reduxjs/toolkit';
import type { AnnotatorRelationship, UUID } from 'types';

type UpsertRelationship = Pick<AnnotatorRelationship, 'configId' | 'id'> & {
  [K in 'annotation_a' | 'annotation_b']?: AnnotatorRelationship[K];
};
export const upsertRelationship = createAction<UpsertRelationship>('RELATIONSHIP/UPSERT');

export type PatchItemsToRelationshipPayload = {
  relationshipId: UUID;
  payload: AnnotatorRelationship;
};
export const patchItemsToRelationship =
  createAction<PatchItemsToRelationshipPayload>('RELATIONSHIP/PATCH');

export type DeleteRelationshipPayload = {
  configId: UUID;
  relationshipId: UUID;
};
export const deleteRelationship = createAction<DeleteRelationshipPayload>('RELATIONSHIP/DELETE');

export type DeleteRelationshipFromConfigPayload = {
  configId: UUID;
  relationshipId: UUID;
};
export const deleteRelationshipFromConfig = createAction<DeleteRelationshipFromConfigPayload>(
  'CONFIG/DELETE_RELATIONSHIP'
);

export type PatchRuleConfigWithRelationshipPayload = {
  configId: UUID;
  id: UUID;
};
export const patchRuleConfigWithRelationship = createAction<PatchRuleConfigWithRelationshipPayload>(
  'CONFIG/APPEND_RELATIONSHIP'
);

export const createRelationship = createAction<UpsertRelationship>('RELATIONSHIP/CREATE');

export type RemoveRelationshipPayload = {
  configId: UUID;
  relationshipId: UUID;
};

export const removeRelationship = createAction<RemoveRelationshipPayload>('RELATIONSHIP/REMOVE');
