import type { Selector, Team, User } from 'types';

export const getSelectedUser: Selector<User | null> = (state) =>
  state.ui.userProfilePage.selectedUser;

export const getTeamsUser: Selector<Team[]> = (state) => state.ui.userProfilePage.teams;

export const getRolesUser: Selector<string[]> = (state) => state.ui.userProfilePage.roles;

export const getInsightUser: Selector<string[]> = (state) => state.ui.userProfilePage.insight;

export const getPreventUser: Selector<string[]> = (state) => state.ui.userProfilePage.prevent;

export const getPrivacyLevelUser: Selector<User['privacy_level']> = (state) =>
  state.ui.userProfilePage.privacyLevel;

export default getSelectedUser;
