import type { Datum, Serie } from '@nivo/line';
import {
  fetchAllEventsRequest,
  fetchAllPendingEventsRequest,
  fetchCampaignsPerformanceRequest,
  fetchCommonWordsRequest,
  fetchCommsByPlatformRequest,
  fetchEnvelopesBucketsRequest,
  fetchEventBucketsRequest,
  fetchMetricsDataRequest,
  fetchSentimentOverTimeRequest,
  fetchTagsAnalytics,
  fetchTopUsersByEventsRequest,
  fetchViolatedEventsRequest,
  fetchWorkforceRiskRequest,
} from 'actions/analytics';
import moment from 'moment';
import { GlobalState } from 'reducers';
import { createSelector } from 'reselect';
import type {
  EnvelopeBucket,
  ErrorObject,
  EventsBucket,
  EventsByCampaign,
  MetricsData,
  QueryMetricObject,
  Selector,
  SentimentOverTime,
  TopSender,
  UUID,
  WorkforceRisk,
} from 'types';

const kFormatter = (num: number): string =>
  Math.abs(num) > 999 ? `${(num / 1000).toFixed(1)}K` : `${num}`;

export const getAllPendingEventsCount: Selector<string> = (state) =>
  kFormatter(state.analytics.eventsByState.NEW || 0);

export const getAllPendingEventsLoading: Selector<boolean> = (state) =>
  state.analytics.loading.includes(fetchAllPendingEventsRequest.toString());

export const getAllEventsCount: Selector<string> = (state) =>
  kFormatter(state.analytics.eventsByState.all || 0);

export const getAllEventsLoading: Selector<boolean> = (state) =>
  state.analytics.loading.includes(fetchAllEventsRequest.toString());

export const getViolatedEventsCount: Selector<string> = (state) =>
  kFormatter(state.analytics.eventsByState.contains_issues || 0);

export const getViolatedEventsLoading: Selector<boolean> = (state) =>
  state.analytics.loading.includes(fetchViolatedEventsRequest.toString());

export const getCampaignsPerformance: Selector<EventsByCampaign[]> = (state) =>
  state.analytics.campaignsPerformance;

export const getSingleCampaignTotalCount: Selector<number, [UUID]> = (state, targetUuid) => {
  let singleCampaignCount = 0;
  const performanceObj = state.analytics.campaignsPerformance.find(
    (campaignPerf) => campaignPerf.campaign.uuid === targetUuid
  );
  if (performanceObj && performanceObj.metrics) {
    singleCampaignCount = Object.values(performanceObj?.metrics).reduce((a, b) => a + b, 0);
  }

  return singleCampaignCount;
};

export const getSingleCampaignPendingCount: Selector<number, [UUID]> = (state, targetUuid) => {
  const performanceObj = state.analytics.campaignsPerformance.find(
    (campaignPerf) => campaignPerf.campaign.uuid === targetUuid
  );
  return performanceObj?.metrics.NEW || 0;
};

export const getCampaignsPerformanceLoading: Selector<boolean> = (state) =>
  state.analytics.loading.includes(fetchCampaignsPerformanceRequest.toString());

export const getEventBuckets: Selector<EventsBucket[]> = (state) => state.analytics.eventBuckets;

export const getEventBucketsLoading: Selector<boolean> = (state) =>
  state.analytics.loading.includes(fetchEventBucketsRequest.toString());

export const getEnvelopesBuckets: Selector<EnvelopeBucket[]> = (state) =>
  state.analytics.envelopesBuckets;

export const getEnvelopesBucketsLoading: Selector<boolean> = (state) =>
  state.analytics.loading.includes(fetchEnvelopesBucketsRequest.toString());

export const getTopSenders: Selector<TopSender[]> = (state) => state.analytics.topSenders;

export const getTopSendersLoading: Selector<boolean> = (state) =>
  state.analytics.loading.includes(fetchTopUsersByEventsRequest.toString());

export const getCommsByPlatLoading: Selector<boolean> = (state) =>
  state.analytics.loading.includes(fetchCommsByPlatformRequest.toString());

export const getEmailCommsCount: Selector<string> = (state) =>
  kFormatter(state.analytics.gmailCommsCount);

export const getSlackCommsCount: Selector<string> = (state) =>
  kFormatter(state.analytics.slackCommsCount);

export const getZendeskCommsCount: Selector<string> = (state) =>
  kFormatter(state.analytics.zendeskCommsCount);

export const getTotalComms: Selector<string> = (state) => {
  const { gmailCommsCount, slackCommsCount, zendeskCommsCount, o365CommsCount } = state.analytics;

  return kFormatter(gmailCommsCount + slackCommsCount + zendeskCommsCount + o365CommsCount);
};

export const getWorkforceRisk: Selector<WorkforceRisk | null> = (state) =>
  state.analytics.workforceRisk;

export const getWorkforceRiskTotal: Selector<number | null> = (state) => {
  if (state.analytics.workforceRisk != null) return state.analytics.workforceRisk.total;
  return null;
};

export const getWorkforceRiskLoading: Selector<boolean> = (state) =>
  state.analytics.loading.includes(fetchWorkforceRiskRequest.toString());

export const getSentimentOverTime: Selector<Serie[] | null> = createSelector(
  [(state): GlobalState['analytics'] => state.analytics],
  (analytics) => {
    const processData = (
      data: SentimentOverTime
    ): {
      id: string;
      color: string;
      data: Serie[];
    }[] => {
      const firstLine: Datum[] = [];
      const secondLine: Datum[] = [];
      const thirdLine: Datum[] = [];
      const fourthLine: Datum[] = [];
      const mark = new Set();

      Object.keys(data).forEach((key) => {
        const item = data[key];
        const date = moment(item.key_as_string).format('MMM D');

        if (!mark.has(date)) {
          firstLine.push({
            x: `${date}`,
            y: Number(item.sentiment_buckets?.values['-0.5'] || '0'),
          });
          secondLine.push({
            x: `${date}`,
            y: Number(item.sentiment_buckets?.values['-0.1'] || '0'),
          });
          thirdLine.push({
            x: `${date}`,
            y: 100 - Number(item.sentiment_buckets?.values['0.1'] || '0'),
          });
          fourthLine.push({
            x: `${date}`,
            y: Number(item.sentiment_buckets?.values['0.5'] || '0'),
          });
          mark.add(date);
        }
      });

      return [
        // {
        //   id: '-0.5',
        //   color: '#161F63',
        //   data: firstLine as Serie[],
        // },
        {
          id: '-0.1',
          color: '#1D2E81',
          data: secondLine as Serie[],
        },
        {
          id: '0.1',
          color: '#3984B6',
          data: thirdLine as Serie[],
        },
        // {
        //   id: '0.5',
        //   color: '#47AED0',
        //   data: fourthLine as Serie[],
        // },
      ];
    };
    if (!analytics.sentimentOverTime) return null;
    return processData(analytics.sentimentOverTime || {});
  }
);

export const getSentimentOverTimeLoading: Selector<boolean> = (state) =>
  state.analytics.loading.includes(fetchSentimentOverTimeRequest.toString());

export const getCommonWordsLoading: Selector<boolean> = (state) =>
  state.analytics.loading.includes(fetchCommonWordsRequest.toString());

type CommonWordsWordBubble = {
  name: string;
  color: string;
  children: { name: string; total: number }[];
};

export const getCommonWords: Selector<CommonWordsWordBubble | null, [string]> = (state, entity) => {
  const words = state.analytics.commonWords?.[entity];
  if (!words) return null;
  const children = Object.keys(words).map((item) => ({
    name: item,
    total: words[item],
    shortName: `${item.slice(0, 4)}..`,
  }));
  return {
    name: 'nivo',
    color: '#25628F',
    children,
  };
};

type EntitySelector = {
  label: string;
  value: string;
};

export const getCommonWordEntities: Selector<EntitySelector[] | null> = (state) => {
  if (!state.analytics.commonWords) return null;
  return Object.keys(state.analytics.commonWords).map((item) => ({ label: item, value: item }));
};

export const getTagsAnalyticsLoading: Selector<boolean> = (state) =>
  state.analytics.loading.includes(fetchTagsAnalytics.toString());

type TagsWordBubble = {
  name: string;
  color: string;
  children: { name: string; total: number; shortName: string }[];
};

type TagsAnalytics = {
  [key: string]: number;
};

export const getSimpleTagsAnalytics: Selector<TagsAnalytics | null> = (state) => {
  if (state.analytics.tags == null) {
    return null;
  }
  return state.analytics.tags;
};

export const getTagsAnalytics: Selector<TagsWordBubble | null> = (state) => {
  if (state.analytics.tags == null) {
    return null;
  }

  return {
    name: 'nivo',
    color: '#25628F',
    children: Object.entries(state.analytics.tags).map(([value, count]) => ({
      name: value,
      total: count,
      shortName: `${value.slice(0, 4)}...`,
    })),
  };
};

export const getWidgetDataById: Selector<MetricsData | null, [string]> = (state, widgetId) =>
  state.analytics.items[widgetId] || null;

export const getWidgetDataLoading: Selector<boolean, [string]> = (state, widgetId) =>
  state.analytics.loading.includes(`${widgetId}/${fetchMetricsDataRequest.toString()}`);

export const getAnyWidgetDataLoading: Selector<boolean> = (state) =>
  state.analytics.loading.some((action) => action.includes(fetchMetricsDataRequest.toString()));

export const getQueryDataById: Selector<QueryMetricObject | null, [string]> = (state, widgetId) =>
  state.analytics.queriesMetricData[widgetId] || null;

export const getMetricsError: Selector<ErrorObject | null, [string]> = (state, widgetId) =>
  (state.analytics.metricsError && state.analytics.metricsError[widgetId]) || null;
