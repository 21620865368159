/* eslint-disable max-lines */
import { Team } from '@litlingo/client';
import {
  addInsightUser,
  addPreventUser,
  addRolesUser,
  addTeamsUser,
  removeInsightUser,
  removePreventUser,
  removeRolesUser,
  removeTeamsUser,
} from 'actions/userProfilePage';
import TeamSelectRedesign from 'components/TeamsManager/TeamSelectRedesign';
import PermissionsSelect from 'components/UsersPermissions/UserPermissionsForm/PermissionsSelect';
import { selectableUserTypes } from 'constants/userRoles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInsightUser,
  getPreventUser,
  getRolesUser,
  getTeamsUser,
} from 'selectors/userProfilePage';

const insightOptions = ['email', 'chat'];
const preventOptions = ['email', 'chat'];

const UserEditProfilePermissions: React.FC = () => {
  const dispatch = useDispatch();

  const selectedTeams = useSelector(getTeamsUser);

  const handleAddTeams = (teams: Team[]): void => {
    dispatch(addTeamsUser(teams));
  };

  const handleRemoveTeams = (teams: Team[]): void => {
    dispatch(removeTeamsUser(teams));
  };

  return (
    <div className="flex flex-col gap-2">
      <TeamSelectRedesign
        allowExclude
        includedTeams={selectedTeams}
        addIncludedTeams={handleAddTeams}
        removeIncludedTeams={handleRemoveTeams}
      />

      <PermissionsSelect
        includedItemsSelector={getRolesUser}
        addIncludedAction={addRolesUser}
        removeIncludedAction={removeRolesUser}
        options={Object.values(selectableUserTypes).map((r) => r.label)}
        title="Roles"
      />

      <PermissionsSelect
        includedItemsSelector={getInsightUser}
        addIncludedAction={addInsightUser}
        removeIncludedAction={removeInsightUser}
        options={insightOptions}
        title="Insight Integrations"
      />

      <PermissionsSelect
        includedItemsSelector={getPreventUser}
        addIncludedAction={addPreventUser}
        removeIncludedAction={removePreventUser}
        options={preventOptions}
        title="Prevent Integrations"
      />
    </div>
  );
};

export default UserEditProfilePermissions;
