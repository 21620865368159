import { fetchSingleCampaign } from 'actions';
import Campaign from 'components/Campaign';
import CampaignsList from 'components/CampaignsList';
import CreateRule from 'components/CreateRule';
import { RouteSpec, SuperRouteSpec } from 'types';

const campaignRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'campaign-add-rule',
    path: '/:customerDomain/campaign/:campaignId/add-rule',
    component: CreateRule,
    private: true,
    permissions: 'rules.create',
  },
  {
    name: 'campaign-detail',
    path: '/:customerDomain/campaign/:campaignId',
    component: Campaign,
    private: true,
    data: {
      actions: [fetchSingleCampaign],
      idFromPath: true,
      params: {
        body: {},
        order_by: {
          defaultValue: 'created_at',
        },
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        test_cases: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            broad_search: {},
            order_desc: {
              defaultValue: 'true',
            },
            order_by: {
              defaultValue: 'created_at',
              options: [
                'created_at',
                'created_by_uuid',
                'test_string',
                'should_match',
                'most_recent_run_passed',
              ],
            },
            match_criterias: {
              list: true,
            },
          },
        },
      },
    },
  },
  {
    name: 'campaign-list',
    path: '/:customerDomain/campaign',
    component: CampaignsList,
    private: true,
    data: {
      params: {
        relationships: {
          defaultValue: ['campaign', 'actions', 'rule', 'updated_by'],
          options: ['campaign', 'actions', 'rule', 'created_by'],
          list: true,
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        campaigns: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            platforms: {
              list: true,
            },
            active: {
              list: true,
            },
            name: {},
            broad_search: {},
            order_by: {
              defaultValue: 'created_at',
              options: ['created_at', 'platform', 'name', 'active', 'updated_at'],
            },
            order_desc: {
              defaultValue: 'true',
              options: ['true', 'false'],
            },
          },
        },
      },
    },
  },
  {
    path: '/campaign',
    component: (): null => null,
    private: true,
  },
];

export default campaignRoutes;
