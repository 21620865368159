/* eslint-disable camelcase */
import LinkLookup from 'components/LinkLookup';
import React from 'react';
import type { Annotator, Campaign, Rule } from 'types';
import { EXTERNAL_LINK_ICON } from '../../constants/envelopeIcons';
import SearchInputByResource from './searchInputByResource';

type ComponentProps = {
  title: string;
  options: {
    [key: string]: string;
  };
  cleanSearchValue?: boolean;
  selectedEntity: string;
  entity?: Campaign | Rule | Annotator;
  setSelectedId: React.Dispatch<React.SetStateAction<string | null>>;
  handleChangeEntity: (() => void) | ((event: React.ChangeEvent<HTMLSelectElement>) => void);
  className?: string;
  selectedId?: string | null;
  maxHeight?: number;
};

const SelectAndSearchByResource: React.FC<ComponentProps> = ({
  title,
  options,
  cleanSearchValue,
  selectedEntity,
  entity,
  setSelectedId,
  handleChangeEntity,
  className,
  selectedId,
  maxHeight,
}) => {
  let routeInfo: { routeName: string; routeParams: { [key: string]: string } };

  if (selectedId) {
    if (selectedEntity === 'campaign_uuid') {
      routeInfo = { routeName: 'campaign-detail', routeParams: { campaignId: selectedId } };
    } else if (selectedEntity === 'rule_uuid') {
      routeInfo = { routeName: 'rule-manager', routeParams: { ruleId: selectedId } };
    } else {
      routeInfo = { routeName: 'annotator', routeParams: { annotatorId: selectedId } };
    }
  } else {
    routeInfo = { routeName: '', routeParams: {} };
  }

  return (
    <div className={`${className} flex flex-col items-start`}>
      <span className="text text--semibold">{title}</span>
      <div className="flex flex-row items-center">
        <select
          value={selectedEntity}
          className="truncate form-select py-1 mr-2 text text--large"
          onChange={handleChangeEntity}
        >
          {Object.keys(options).map((key) => (
            <option className="text text--large" key={key} value={key}>
              {options[key]}
            </option>
          ))}
        </select>
        <SearchInputByResource
          resource={options[selectedEntity]}
          setSelectedId={setSelectedId}
          entity={entity}
          cleanSearchValue={cleanSearchValue}
          maxHeight={maxHeight}
        />
        {entity && selectedId && (
          <LinkLookup routeName={routeInfo.routeName} routeParams={routeInfo.routeParams}>
            <div className="m-1">{EXTERNAL_LINK_ICON(20, 20)}</div>
          </LinkLookup>
        )}
      </div>
    </div>
  );
};

export default SelectAndSearchByResource;
