import { ValueFormatter } from '@nivo/axes';
import { AxisProps, AxisUserProps, NivoBarProps, NivoBarUserProps, yScaleProps } from 'types';

export const formatFunctionDefaults: Record<string, ValueFormatter<number>> = {
  percentage: (v: number) => `${Math.abs(Number(v))}%`,
  normal: (v: number) => `${v}`,
};

export const axisUserPropsDefault: AxisUserProps = {
  enable: false,
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  tickValues: 5,
  formatType: 'normal',
};

export const yScalePropsDefault: yScaleProps = {
  type: 'linear',
  stacked: false,
};

export const NivoBarUserPropsDefault: NivoBarUserProps = {
  groupMode: 'stacked',
  valueScale: {
    type: 'linear',
  },
  layout: 'horizontal',
  minValue: 0,
  maxValue: 0,
  padding: 0.2,
  innerPadding: 0,
  colors: [],
  borderRadius: 0,
  borderWidth: 1,
  enableLabel: false,

  enableGridX: false,
  gridXValues: 0,
  enableGridY: true,
  gridYValues: 0,

  axisLeft: { ...axisUserPropsDefault, enable: true },
  axisRight: axisUserPropsDefault,
  axisTop: axisUserPropsDefault,
  axisBottom: { ...axisUserPropsDefault, enable: true },

  yScale: yScalePropsDefault,
  enableArea: false,
};

export const axisPropsDefault: AxisProps = {
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  tickValues: 5,
  format: formatFunctionDefaults.normal,
};

export const NivoBarPropsDefault: NivoBarProps = {
  groupMode: 'stacked',
  layout: 'vertical',
  valueScale: {
    type: 'linear',
  },
  maxValue: 'auto',
  minValue: 'auto',
  padding: 0.2,
  innerPadding: 0,

  colors: [],
  borderRadius: 0,
  borderWidth: 0,
  enableLabel: false,

  enableGridX: false,
  gridXValues: 5,
  enableGridY: true,
  gridYValues: 5,

  axisLeft: { ...axisPropsDefault },
  axisRight: null,
  axisTop: null,
  axisBottom: { ...axisPropsDefault, tickRotation: -49, tickPadding: 5 },
};
