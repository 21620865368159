import type { ErrorData } from '@litlingo/client';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  fetchTeamsFailure,
  fetchTeamsForFilterPillsFailure,
  fetchTeamsForFilterPillsRequest,
  fetchTeamsForFilterPillsSuccess,
  fetchTeamsRequest,
  fetchTeamsSuccess,
  fetchTeamsSuccessAppend,
} from 'actions';
import type { API, ErrorObject, NormalizedResource, Team, UUID } from 'types';

export type TeamsState = {
  selectedTeam: Team;
  items: NormalizedResource<Team>;
  filterItems: NormalizedResource<Team>;
  listIds: UUID[];
  count: number;
  loading: string[];
  error: ErrorData | null;
};

type TeamsReducer<P = void> = (state: TeamsState, action: PayloadAction<P>) => void;

const initialState: TeamsState = {
  selectedTeam: {} as Team,
  error: null,
  loading: [],
  items: {},
  filterItems: {},
  listIds: [],
  count: 0,
};

const handleFetchTeamsRequest: TeamsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchTeamsRequest.toString()],
});

const handleFetchTeamsFailure: TeamsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchTeamsRequest.toString()),
});

const handleFetchTeamsSuccess: TeamsReducer<API.WrappedAPIResponse<Team>> = (
  state,
  { payload }
) => {
  const teams: NormalizedResource<Team> = {};
  const ids: UUID[] = [];

  payload.records.forEach((team) => {
    teams[team.uuid] = team;
    ids.push(team.uuid);
  });

  return {
    ...state,
    items: teams,
    listIds: ids,
    count: payload.count,
    loading: state.loading.filter((s) => s !== fetchTeamsRequest.toString()),
  };
};

const handleFetchTeamsSuccessAppend: TeamsReducer<API.WrappedAPIResponse<Team>> = (
  state,
  { payload }
) => {
  const teams: NormalizedResource<Team> = {};
  const ids: UUID[] = [];

  payload.records.forEach((team) => {
    teams[team.uuid] = team;
    ids.push(team.uuid);
  });

  return {
    ...state,
    items: { ...state.items, ...teams },
    listIds: ids,
    count: payload.count,
    loading: state.loading.filter((s) => s !== fetchTeamsRequest.toString()),
  };
};

const handleFetchTeamsForFilterPillsRequest: TeamsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchTeamsForFilterPillsRequest.toString()],
});

const handleFetchTeamsForFilterPillsFailure: TeamsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchTeamsForFilterPillsRequest.toString()),
});

const handleFetchTeamsForFilterPillsSuccess: TeamsReducer<API.WrappedAPIResponse<Team>> = (
  state,
  { payload }
) => {
  const normalizedTeams: NormalizedResource<Team> = {};
  payload.records.forEach((team) => {
    normalizedTeams[team.uuid] = team;
  });
  return {
    ...state,
    filterItems: normalizedTeams,
    loading: state.loading.filter((s) => s !== fetchTeamsForFilterPillsRequest.toString()),
    count: payload.count,
  };
};

const handlers = {
  [fetchTeamsSuccess.toString()]: handleFetchTeamsSuccess,
  [fetchTeamsSuccessAppend.toString()]: handleFetchTeamsSuccessAppend,
  [fetchTeamsFailure.toString()]: handleFetchTeamsFailure,
  [fetchTeamsRequest.toString()]: handleFetchTeamsRequest,
  [fetchTeamsForFilterPillsRequest.toString()]: handleFetchTeamsForFilterPillsRequest,
  [fetchTeamsForFilterPillsSuccess.toString()]: handleFetchTeamsForFilterPillsSuccess,
  [fetchTeamsForFilterPillsFailure.toString()]: handleFetchTeamsForFilterPillsFailure,
};

const TeamsReducer = createReducer(initialState, handlers);

export default TeamsReducer;
