/* eslint-disable import/prefer-default-export */
import {
  GMAIL_ICON,
  OUTLOOK_ICON,
  SERVER_SIDE_ICON,
  SLACK_ICON,
  TEAMS_ICON,
  ZENDESK_ICON,
} from 'constants/envelopeIcons';
import React from 'react';
import type { Platform } from 'types';

type PlatformIconType = (
  platform: Platform,
  integrationType: string,
  isEnvelopeList?: boolean
) => JSX.Element;

export const platformIcon: PlatformIconType = (
  platform,
  integrationType,
  isEnvelopeInfo = false
) => {
  const getIcon = (): JSX.Element => {
    if (platform === 'gmail' || platform === 'chrome') {
      if (integrationType === 'app') {
        return GMAIL_ICON;
      }
      if (platform === 'chrome') {
        return GMAIL_ICON;
      }
      return GMAIL_ICON;
    }
    if (platform === 'slack') {
      return SLACK_ICON;
    }

    if (platform === 'o365') {
      return OUTLOOK_ICON;
    }

    if (platform === 'o365_teams') {
      return TEAMS_ICON;
    }

    return ZENDESK_ICON;
  };

  if (isEnvelopeInfo) {
    return (
      <div className="relative flex justify-center items-center h-5 w-5 rounded-full bg-white">
        <div className="w-4">{getIcon()}</div>
        {integrationType === 'api' && (
          <div className="absolute w-2 h-2" style={{ bottom: 0, right: -4 }}>
            {SERVER_SIDE_ICON}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="relative">
      {getIcon()}
      {integrationType === 'api' && (
        <div className="absolute w-2 h-2" style={{ bottom: -1, right: -6 }}>
          {SERVER_SIDE_ICON}
        </div>
      )}
    </div>
  );
};
