import { fetchAllRulesDebounced } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getFetchAllRulesLoading, getRulesList } from 'selectors/rules';
import { useSelector } from 'store';
import { UUID } from 'types';

type ComponentProps = {
  handleClick: (uuid: UUID, name: string) => void;
  customerId: string | undefined;
};

const RuleList: React.FC<ComponentProps> = ({ handleClick, customerId }) => {
  const dispatch = useDispatch();
  const rules = useSelector(getRulesList);
  const loading = useSelector(getFetchAllRulesLoading);

  useEffect(() => {
    dispatch(fetchAllRulesDebounced({ limit: '-1', customer_uuid: customerId }));
  }, [dispatch, customerId]);

  return (
    <div style={{ maxHeight: '500px' }} className="overflow-y-scroll">
      {loading ? (
        <div className="flex justify-center items-center mx-4">
          <LoadingIndicator size="10" />
        </div>
      ) : (
        <table className="min-w-full">
          <thead>
            <tr />
          </thead>
          <tbody className="bg-white text-left">
            {rules &&
              rules.map((rule) => (
                <tr
                  key={rule.uuid}
                  onClick={(): void => handleClick(rule.uuid, rule.name)}
                  className="border-t border-gray-200 hover:bg-gray-100 table-row"
                >
                  <td className="px-6 py-4 border-b border-gray-200">
                    <div className="flex items-center">
                      <div>
                        <div className="text">{rule.name}</div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default RuleList;
