/* eslint-disable max-lines */
/* eslint-disable camelcase */
import LoadingIndicator from 'components/LoadingIndicator';
import { NEW_EDIT_ICON } from 'constants/commonIcons';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { saveAnnotatorRequestLoading } from 'selectors/annotator';

import { getReport } from 'selectors/customReports';
import { useSelector } from 'store';
import EditReportModal from './EditReportModal';

type RouterParams = { customReportId: string };

const CustomReportHeader: React.FC = () => {
  const { customReportId } = useParams<RouterParams>();

  const report = useSelector((state) => getReport(state, customReportId)) || {};
  const loading = useSelector(saveAnnotatorRequestLoading);

  const [isEditingReport, setIsEditingReport] = useState(false);

  const handleClinkOnEdit = (): void => {
    setIsEditingReport(true);
  };

  const toggleEditModal = (): void => setIsEditingReport(false);

  if (!report) return null;

  return (
    <>
      <header>
        <div className="p-6">
          <div className="flex flex-col gap-1">
            <div className="flex justify-between w-full">
              <div className="flex items-center w-3/5">
                <div className="flex flex-row items-baseline gap-2">
                  <span className="break-words text-heading-2">{report.kind}</span>
                  <button
                    type="button"
                    className="flex-none w-5 focus:outline-none"
                    onClick={handleClinkOnEdit}
                  >
                    {NEW_EDIT_ICON('text-litlingo-primary-120')}
                  </button>
                  {loading && <LoadingIndicator data-testid="loading-indicator" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {isEditingReport && (
        <EditReportModal
          isModalOpen={isEditingReport}
          toggleModalOpen={toggleEditModal}
          reportToEdit={report}
        />
      )}
    </>
  );
};

export default CustomReportHeader;
