import type {
  AnnotationConfig,
  AnnotationLinkConfig,
  AnnotatorRelationship,
  MRuleConfigNode,
  NormalizedResource,
  UUID,
} from 'types';

const constructAnnotationConfig = (
  configId: UUID,
  config: NormalizedResource<MRuleConfigNode>
): AnnotationConfig | null => {
  const item = config[configId];
  if (item.typeOfConfig === 'AND' || item.typeOfConfig === 'OR') return null;

  return {
    name: item.name,
    pos: null,
    uuid: item.id,
    modifiers: item.modifiers,
    ...(item.typeOfConfig === 'ANNOTATION_MATCH'
      ? {
          negated: item.negated,
          mode_of_speech: item.mode_of_speech,
          annotator_uuid: item.annotatorId,
          identifier_revision_uuid: item.annotatorId,
          body_only: item.body_only,
        }
      : {}),
  };
};

const constructAnnotationLinkConfig = (
  id: UUID,
  relationships: NormalizedResource<AnnotatorRelationship>,
  possibleConfigIds: string[] | undefined
): (Omit<AnnotationLinkConfig, 'id'> & { uuid: string }) | boolean => {
  if (!relationships[id] || !possibleConfigIds) return false;
  const relationship = relationships[id];
  if (!relationship.annotation_a || !relationship.annotation_b) return false;
  if (
    !possibleConfigIds.includes(relationship.annotation_a.id) ||
    !possibleConfigIds.includes(relationship.annotation_b.id) ||
    (relationship.annotation_c != null && !possibleConfigIds.includes(relationship.annotation_c.id))
  ) {
    return false;
  }
  return {
    annotation_a: relationship.annotation_a.id,
    annotation_b: relationship.annotation_b.id,
    ...(relationship.annotation_c != null ? { annotation_c: relationship.annotation_c.id } : {}),
    type: relationship.type,
    uuid: id,
  };
};

const constructConfigTree = (
  configId: UUID | undefined,
  config: NormalizedResource<MRuleConfigNode>,
  relationship: NormalizedResource<AnnotatorRelationship>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (!config || configId == null || Object.keys(config).length === 0) return {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buildingObj: any = {};

  const item = config[configId];
  buildingObj.operator = item.typeOfConfig;
  buildingObj.uuid = item.id;
  if ('modifiers' in item && item.modifiers && Object.keys(item.modifiers).length > 0) {
    buildingObj.modifiers = item.modifiers;
  }
  if (item.typeOfConfig === 'ANNOTATION_MATCH') {
    if (item.negated) buildingObj.negated = true;
    if (item.mode_of_speech != null) buildingObj.mode_of_speech = item.mode_of_speech;
  }

  switch (item.typeOfConfig) {
    case 'ANNOTATION_MATCH': {
      buildingObj.config = {
        name: item.name,
        pos: null,
        uuid: item.id,
      };
      buildingObj.config.body_only = item.body_only;
      if (item.annotatorId) {
        buildingObj.config.annotator_uuid = item.annotatorId;
        buildingObj.config.identifier_revision_uuid = item.annotatorId;
      }
      break;
    }
    // case 'NOT':
    case 'AND':
    case 'OR': {
      if (item.groups) {
        buildingObj.operands = [];
        buildingObj.description = item.description;
        buildingObj.color = item.color;
        item.groups.forEach((id) => {
          const operand = constructConfigTree(id, config, relationship);
          buildingObj.operands.push(operand);
        });
      }
      break;
    }
    case 'RELATIONSHIP_MATCH': {
      buildingObj.config = { annotation_configs: [], annotation_link_configs: [] };
      buildingObj.description = item.description;
      buildingObj.color = item.color;
      buildingObj.config.body_only = item.body_only;
      if (!item.groups) break;
      item.groups.forEach((id) => {
        const operand = constructAnnotationConfig(id, config);
        if (operand) {
          buildingObj.config.annotation_configs.push(operand);
        }
      });
      if (!item.relationship) break;
      item.relationship.forEach((id) => {
        const linkConfig = constructAnnotationLinkConfig(id, relationship, item.groups);
        if (linkConfig) {
          buildingObj.config.annotation_link_configs.push(linkConfig);
        }
      });
      break;
    }
    default: {
      break;
    }
  }
  return buildingObj;
};

export default constructConfigTree;
