/* eslint-disable max-lines */
import {
  patchSingleRuleConfig,
  removeItem,
  selectItem,
  setMatchBodyOnly,
  updateModeOfSpeech,
  updateModifier,
  updateNegated,
} from 'actions';

import SelectRedesign from 'components/Select/SelectRedesign';
import SplitButton from 'components/SplitButton';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllChildNodes, getConfigItems } from 'selectors/config';
import { useSelector } from 'store';
import type { MRuleConfig, MRuleConfigNode, ModeOfSpeech, Modifiers, RuleTree } from 'types';
import { checkForChildren } from 'utils/checkForChildren';
import { operatorName } from './RuleManagerUtils';

type NodeNameType =
  | 'AND'
  | 'OR'
  | 'RELATIONSHIP_MATCH'
  | 'ANNOTATION_MATCH'
  | 'AND_NOT'
  | 'OR_NOT'
  | 'RELATIONSHIP_MATCH_NOT';

type ComponentProps = {
  selectedId: string;
  setIdToMove: React.Dispatch<React.SetStateAction<string | null>>;
  idToMove: string | null;
  toggleShowModal: () => void;
  arrayTree: MRuleConfig[];
  setIsCollapsedAll: (value: boolean) => void;
};

const RuleManagerAction: React.FC<ComponentProps> = ({
  arrayTree,
  selectedId,
  setIdToMove,
  idToMove,
  toggleShowModal,
  setIsCollapsedAll,
}) => {
  const dispatch = useDispatch();
  const node = useSelector((state) => state.config.items[selectedId]);
  const allChildNodes = useSelector((state) => getAllChildNodes(state, selectedId));
  const items = useSelector(getConfigItems);

  const isInsideSameSentence = useSelector((state) => {
    if (node.parent == null) {
      return false;
    }

    const parent = state.config.items[node.parent];

    return parent.typeOfConfig === 'RELATIONSHIP_MATCH';
  });

  const buttonMainClass = 'button button--secondary font-bold h-8 whitespace-no-wrap';

  const isRoot = node.parent == null;

  const [modifiers, setModifiers] = useState(
    'modifiers' in node && node.modifiers ? node.modifiers : { NOT: false, DISABLE: false }
  );

  useEffect(() => {
    if (isRoot && node.typeOfConfig !== 'ANNOTATION_MATCH' && node.groups.length > 5) {
      setIsCollapsedAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  useEffect(() => {
    setModifiers(
      'modifiers' in node && node.modifiers ? node.modifiers : { NOT: false, DISABLE: false }
    );
  }, [node]);

  const handleChangeModifiers = (modifier: keyof Modifiers): void => {
    if (modifier === 'NOT' || modifier === 'DISABLE') {
      const localModifiers = { ...modifiers };

      if (localModifiers[modifier] === true) {
        delete localModifiers[modifier];
      } else {
        localModifiers[modifier] = true;
      }

      dispatch(updateModifier({ id: selectedId, modifiers: localModifiers }));

      if (node.typeOfConfig !== 'ANNOTATION_MATCH' && node.groups && modifier === 'DISABLE') {
        allChildNodes.forEach((item) => {
          const childNode = items[item];

          dispatch(
            updateModifier({
              id: item,
              modifiers: { ...childNode?.modifiers, DISABLE: localModifiers.DISABLE },
            })
          );
        });
      }
    }
  };

  const handleRemoveItem = (): void => {
    dispatch(removeItem(selectedId));
    dispatch(selectItem({ index: null }));
  };

  const handleChangeRelationType = (name: NodeNameType): void => {
    const actualName = name.replace('_NOT', '') as MRuleConfigNode['typeOfConfig'];

    dispatch(
      // @ts-ignore
      patchSingleRuleConfig({
        id: selectedId,
        name: actualName,
        typeOfConfig: actualName,
        relationship: [],
      })
    );

    if (!modifiers?.NOT && name.includes('NOT')) {
      handleChangeModifiers('NOT');
    } else if (modifiers?.NOT && !name.includes('NOT')) {
      handleChangeModifiers('NOT');
    }
  };

  const handleMoveItem = (): void => {
    setIdToMove(selectedId as string);
  };

  const handleMatchOnlyBody = (): void => {
    if (node.typeOfConfig === 'ANNOTATION_MATCH' || node.typeOfConfig === 'RELATIONSHIP_MATCH') {
      dispatch(setMatchBodyOnly({ id: selectedId, matchOnlyBody: !node.body_only }));
    }
  };

  const renderTypeOfConfigButtons = (): JSX.Element | null => {
    const casted = node.name as keyof typeof operatorName;
    if (operatorName[casted] == null) {
      return null;
    }

    const options: { value: NodeNameType; label: string }[] = [
      {
        value: 'AND',
        label: 'AND',
      },
      {
        value: 'OR',
        label: 'OR',
      },

      {
        value: 'AND_NOT',
        label: 'AND NOT',
      },
      {
        value: 'OR_NOT',
        label: 'OR NOT',
      },
    ];

    if (!isRoot) {
      options.splice(2, 0, {
        value: 'RELATIONSHIP_MATCH',
        label: 'SS',
      });
      options.push({
        value: 'RELATIONSHIP_MATCH_NOT',
        label: 'SS NOT',
      });
    }

    let parsedName = node.name;

    if (modifiers?.NOT) {
      parsedName = `${node.name}_NOT`;
    }

    return (
      <div className="w-25 bg-white">
        <SelectRedesign
          className="font-bold"
          dataTestid="config-type-select"
          onChange={(value): void => {
            handleChangeRelationType(value as NodeNameType);
          }}
          options={options}
          value={parsedName}
          placeholder=""
          valueClassName="font-bold"
        />
      </div>
    );
  };

  const handleChangeNegation = (value: string): void => {
    let negated;

    if (value === 'negated') negated = true;
    if (value === 'not_negated') negated = false;

    dispatch(updateNegated({ id: node.id, negated }));
  };

  const renderNegatedButtons = (): JSX.Element | null => {
    if (node.typeOfConfig !== 'ANNOTATION_MATCH' || !isInsideSameSentence) {
      return null;
    }

    const options = [
      {
        label: 'Negated',
        value: 'negated',
      },
      {
        label: 'Not Negated',
        value: 'not_negated',
      },
      {
        label: '-',
        value: 'indiferent',
      },
    ];

    let value = '';

    if (node.negated === true) value = 'negated';
    if (node.negated === false) value = 'not_negated';

    return (
      <div className="w-26">
        <SelectRedesign
          className="bg-white"
          optionClassname="bg-white"
          placeholder="Negation"
          options={options}
          onChange={handleChangeNegation}
          value={value}
        />
      </div>
    );
  };

  const handleModeOfSpeechClick = (value: string): void => {
    let mode = null;
    if (value !== 'indiferent') mode = value;

    dispatch(updateModeOfSpeech({ id: node.id, mode: mode as ModeOfSpeech }));
  };

  const renderModeOfSpeechButtons = (): JSX.Element | null => {
    if (node.typeOfConfig !== 'ANNOTATION_MATCH' || !isInsideSameSentence) {
      return null;
    }

    const options = [
      {
        label: 'Object',
        value: 'object',
      },
      {
        label: 'Subject',
        value: 'subject',
      },
      {
        label: '-',
        value: 'indiferent',
      },
    ];

    return (
      <div className="w-33">
        <SelectRedesign
          className="bg-white"
          optionClassname="bg-white"
          placeholder="Part of speech"
          options={options}
          onChange={handleModeOfSpeechClick}
          value={node.mode_of_speech || ''}
        />
      </div>
    );
  };

  const renderRelationshipButton = (): JSX.Element | null => {
    // TODO: check this cast
    const tree = arrayTree as RuleTree[];
    if (node.typeOfConfig !== 'RELATIONSHIP_MATCH' || !checkForChildren(tree, selectedId)) {
      return null;
    }

    return (
      <button onClick={toggleShowModal} type="button" className={buttonMainClass}>
        Edit Relationship
      </button>
    );
  };

  const renderMatchBodyOnly = (): JSX.Element | null => {
    if (node.typeOfConfig === 'ANNOTATION_MATCH' || node.typeOfConfig === 'RELATIONSHIP_MATCH') {
      return (
        <button
          type="button"
          onClick={handleMatchOnlyBody}
          className={`${buttonMainClass} w-36 flex justify-center`}
        >
          {node?.body_only ? 'Match All' : 'Match body Only'}
        </button>
      );
    }
    return null;
  };

  const renderMoveMode = (): JSX.Element | null => {
    if (idToMove == null) {
      return null;
    }

    return (
      <div data-testid="move-mode" className="w-full flex flex-row justify-end">
        <button type="button" onClick={(): void => setIdToMove(null)} className={buttonMainClass}>
          Cancel
        </button>
      </div>
    );
  };

  const renderSplitButton = (): JSX.Element => {
    const disableOption = {
      label: modifiers?.DISABLE ? 'Enable' : 'Disable',
      action: (): void => handleChangeModifiers('DISABLE'),
    };
    const moveOption = { label: 'Move', action: (): void => handleMoveItem() };
    const deleteOption = {
      label: 'Delete',
      action: (): void => handleRemoveItem(),
      textColorClass: 'text-litlingo-alert',
    };
    const mainOption = node.typeOfConfig === 'ANNOTATION_MATCH' ? deleteOption : disableOption;

    const options = [];

    if (!isRoot) {
      options.push(moveOption);
      if (node.typeOfConfig !== 'ANNOTATION_MATCH') {
        options.push(deleteOption);
      } else {
        options.push(disableOption);
      }
    }

    return (
      <div className="h-8 w-27">
        <SplitButton
          primaryLabel={mainOption.label}
          primaryAction={mainOption.action}
          primaryLabelColor={node.typeOfConfig === 'ANNOTATION_MATCH' ? 'text-litlingo-alert' : ''}
          options={options}
          buttonStyle="secondary"
        />
      </div>
    );
  };

  const renderNotMoveMode = (): JSX.Element | null => {
    if (idToMove != null) {
      return null;
    }

    return (
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-row gap-4">
          {renderTypeOfConfigButtons()}

          <div className="flex flex-row gap-1">
            {renderNegatedButtons()}
            {renderModeOfSpeechButtons()}

            {renderRelationshipButton()}
            {renderMatchBodyOnly()}
          </div>
        </div>
        {renderSplitButton()}
      </div>
    );
  };

  return (
    <div className="z-10 flex flex-row justify-between flex-1 bg-litlingo-gray-0.5">
      <div className="flex flex-row gap-4 w-full">
        {renderMoveMode()}
        {renderNotMoveMode()}
      </div>
    </div>
  );
};

export default RuleManagerAction;
