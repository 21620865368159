export const filterQueryParams = (pathSearch: string, names: string[]): string =>
  pathSearch
    ? pathSearch
        .split('?')[1]
        .split('&')
        .filter(
          (item) =>
            (!names.some((n) => item.includes(n)) && !item.includes('from')) ||
            item.includes('days_from_oor')
        )
        .join('&')
    : '';

export const concatFromQueryParam = (
  pathSearch: string,
  pathName: string,
  resource: string
): string => `${pathSearch}${pathSearch ? '&' : ''}${resource}__from=${pathName.split('/').pop()}`;

export const eventPaths = {
  events: 'envelope-list',
};
