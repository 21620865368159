/* eslint-disable camelcase */
import React from 'react';

export type SummaryTagType = 'reduced-all' | 'reduced-some' | 'high-risk' | 'abandoned' | 'none';

type ComponentProps = {
  type: SummaryTagType;
};

const SummaryTag: React.FC<ComponentProps> = ({ type }) => {
  const styledTextMap: Record<SummaryTagType, JSX.Element | null> = {
    'reduced-all': (
      <span className="rounded-sm py-0.5 px-1 bg-litlingo-success-light">
        <b>Edited: </b> Reduced risk for all matches
      </span>
    ),
    'reduced-some': (
      <span className="rounded-sm py-0.5 px-1 bg-litlingo-success-light">
        <b>Edited: </b> Reduced risk for some matches
      </span>
    ),
    'high-risk': (
      <span className="rounded-sm py-0.5 px-1 bg-litlingo-alert-light">Sent with high risk</span>
    ),
    abandoned: (
      <span className="rounded-sm py-0.5 px-1 bg-litlingo-gray-1">
        <b>Abandoned message</b>
      </span>
    ),
    none: null,
  };

  return <div className="flex rounded-sm text-small">{styledTextMap[type]}</div>;
};

export default SummaryTag;
