import React from 'react';

const FULL_LOGO = (
  <svg
    width="303"
    height="50"
    viewBox="0 0 303 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M72.487 11.1038H75.4243V36.3553H90.9874V38.8962H72.487V11.1038Z"
      fill="#0D2928"
    />
    <path
      d="M102.223 11.1038H105.161V38.8962H102.223V11.1038Z"
      fill="#0D2928"
    />
    <path
      d="M125.966 13.6447H116.198V11.1038H138.67V13.6447H128.903V38.8962H125.966V13.6447Z"
      fill="#0D2928"
    />
    <path
      d="M148.478 11.1038H154.909V33.6543H168.846V38.8962H148.478V11.1038Z"
      fill="#0D2928"
    />
    <path d="M178.809 11.1038H185.24V38.8962H178.809V11.1038Z" fill="#0D2928" />
    <path
      d="M223.674 11.1038V38.8962H218.394L204.538 22.022V38.8962H198.186V11.1038H203.506L217.324 27.9779V11.1038H223.674Z"
      fill="#0D2928"
    />
    <path
      d="M255.397 24.563H261.274V35.8395C259.765 36.9778 258.019 37.8519 256.032 38.4592C254.048 39.069 252.048 39.3714 250.038 39.3714C247.152 39.3714 244.56 38.7565 242.255 37.5241C239.953 36.2943 238.147 34.5868 236.836 32.4017C235.524 30.219 234.871 27.7493 234.871 24.9975C234.871 22.2457 235.527 19.7759 236.836 17.5932C238.147 15.4106 239.966 13.7031 242.296 12.4708C244.626 11.241 247.246 10.6235 250.157 10.6235C252.592 10.6235 254.802 11.0352 256.787 11.8533C258.771 12.674 260.441 13.8657 261.79 15.4259L257.661 19.2372C255.676 17.146 253.28 16.1017 250.475 16.1017C248.702 16.1017 247.126 16.4727 245.752 17.2147C244.374 17.9566 243.302 19.0009 242.535 20.3501C241.767 21.6994 241.384 23.2493 241.384 24.9949C241.384 26.7151 241.767 28.2498 242.535 29.6016C243.302 30.9508 244.367 32.0027 245.731 32.7574C247.093 33.512 248.651 33.8881 250.396 33.8881C252.249 33.8881 253.915 33.4917 255.399 32.6964V24.563H255.397Z"
      fill="#0D2928"
    />
    <path
      d="M279.238 37.5063C276.921 36.2613 275.109 34.5487 273.798 32.3661C272.487 30.1834 271.834 27.7264 271.834 25C271.834 22.2736 272.49 19.8191 273.798 17.6339C275.109 15.4513 276.921 13.7361 279.238 12.4911C281.553 11.246 284.155 10.6261 287.039 10.6261C289.923 10.6261 292.517 11.2486 294.822 12.4911C297.124 13.7361 298.938 15.4487 300.262 17.6339C301.585 19.8165 302.246 22.2736 302.246 25C302.246 27.7264 301.583 30.1809 300.262 32.3661C298.938 34.5487 297.126 36.2638 294.822 37.5063C292.519 38.7514 289.925 39.3714 287.039 39.3714C284.155 39.3714 281.553 38.7514 279.238 37.5063ZM291.485 32.7625C292.809 32.0078 293.848 30.9559 294.603 29.6067C295.358 28.2574 295.734 26.7227 295.734 25C295.734 23.2798 295.358 21.7451 294.603 20.3933C293.848 19.0441 292.809 17.9922 291.485 17.2375C290.162 16.4829 288.68 16.1068 287.039 16.1068C285.397 16.1068 283.916 16.4829 282.592 17.2375C281.268 17.9922 280.229 19.0441 279.474 20.3933C278.72 21.7425 278.344 23.2798 278.344 25C278.344 26.7202 278.72 28.2549 279.474 29.6067C280.229 30.9559 281.268 32.0078 282.592 32.7625C283.916 33.5171 285.397 33.8932 287.039 33.8932C288.68 33.8932 290.162 33.5171 291.485 32.7625Z"
      fill="#0D2928"
    />
    <path
      d="M35.7506 14.2418V21.364H30.712L28.6335 22.6649V14.2418H35.7506Z"
      fill="#F8A01A"
    />
    <path
      d="M21.4554 7.11963V0H0V7.11963V50H5.63828H21.4554V42.8778H7.11709V7.11963H21.4554Z"
      fill="#58A280"
    />
    <path
      d="M28.5725 0V7.11963H42.8677V42.8778H28.5725V50H49.9847V45.8456V42.8778V7.11963V4.15439V0H28.5725Z"
      fill="#58A280"
    />
    <path
      d="M35.7506 28.5319V35.7582H14.2342V14.2418H21.463V28.5319H35.7506Z"
      fill="#0D2928"
    />
  </svg>
);

export const ICON_LOGO = (
  <svg
    height="100%"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.7506 14.2418V21.364H30.712L28.6335 22.6649V14.2418H35.7506Z"
      fill="#F8A01A"
    />
    <path
      d="M21.4554 7.11963V0H0V7.11963V50H5.63828H21.4554V42.8778H7.11709V7.11963H21.4554Z"
      fill="#58A280"
    />
    <path
      d="M28.5725 0V7.11963H42.8677V42.8778H28.5725V50H49.9847V45.8456V42.8778V7.11963V4.15439V0H28.5725Z"
      fill="#58A280"
    />
    <path
      d="M35.7506 28.5319V35.7582H14.2342V14.2418H21.463V28.5319H35.7506Z"
      fill="#0D2928"
    />
  </svg>
);

export const LOGO_BLACK = (
  <svg
    width="200"
    height="34"
    viewBox="0 0 200 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_79_34)">
      <path
        d="M47.9656 7.3475H49.9092V24.0568H60.2075V25.7381H47.9656V7.3475Z"
        fill="white"
      />
      <path d="M67.6423 7.3475H69.586V25.7381H67.6423V7.3475Z" fill="white" />
      <path
        d="M83.353 9.02885H76.8899V7.3475H91.7598V9.02885H85.2966V25.7381H83.353V9.02885Z"
        fill="white"
      />
      <path
        d="M98.2498 7.3475H102.505V22.2695H111.727V25.7381H98.2498V7.3475Z"
        fill="white"
      />
      <path d="M118.32 7.3475H122.576V25.7381H118.32V7.3475Z" fill="white" />
      <path
        d="M148.008 7.3475V25.7381H144.514L135.345 14.5723V25.7381H131.142V7.3475H134.663L143.806 18.5134V7.3475H148.008Z"
        fill="white"
      />
      <path
        d="M168.999 16.2536H172.888V23.7155C171.889 24.4687 170.734 25.0471 169.419 25.4489C168.106 25.8524 166.783 26.0525 165.453 26.0525C163.543 26.0525 161.828 25.6456 160.303 24.8302C158.78 24.0164 157.584 22.8865 156.717 21.4406C155.849 19.9963 155.417 18.362 155.417 16.5411C155.417 14.7202 155.851 13.086 156.717 11.6417C157.584 10.1974 158.788 9.06752 160.33 8.25207C161.872 7.43829 163.605 7.02973 165.532 7.02973C167.143 7.02973 168.606 7.3021 169.919 7.8435C171.232 8.38658 172.337 9.17513 173.229 10.2075L170.497 12.7295C169.184 11.3458 167.599 10.6547 165.742 10.6547C164.569 10.6547 163.526 10.9002 162.617 11.3911C161.705 11.8821 160.996 12.5731 160.488 13.4659C159.98 14.3587 159.727 15.3844 159.727 16.5394C159.727 17.6777 159.98 18.6933 160.488 19.5877C160.996 20.4805 161.7 21.1766 162.603 21.676C163.505 22.1753 164.535 22.4242 165.69 22.4242C166.916 22.4242 168.019 22.1619 169.001 21.6356V16.2536H168.999Z"
        fill="white"
      />
      <path
        d="M184.775 24.8184C183.242 23.9945 182.043 22.8613 181.176 21.417C180.308 19.9728 179.876 18.3469 179.876 16.5428C179.876 14.7387 180.31 13.1145 181.176 11.6686C182.043 10.2243 183.242 9.08938 184.775 8.26552C186.307 7.44166 188.029 7.03141 189.937 7.03141C191.846 7.03141 193.562 7.44334 195.087 8.26552C196.61 9.08938 197.811 10.2226 198.687 11.6686C199.563 13.1129 200 14.7387 200 16.5428C200 18.3469 199.561 19.9711 198.687 21.417C197.811 22.8613 196.612 23.9962 195.087 24.8184C193.564 25.6423 191.847 26.0525 189.937 26.0525C188.029 26.0525 186.307 25.6423 184.775 24.8184ZM192.88 21.6793C193.756 21.18 194.443 20.4839 194.943 19.5911C195.442 18.6983 195.691 17.6828 195.691 16.5428C195.691 15.4045 195.442 14.389 194.943 13.4945C194.443 12.6017 193.756 11.9056 192.88 11.4063C192.004 10.9069 191.023 10.6581 189.937 10.6581C188.851 10.6581 187.871 10.9069 186.995 11.4063C186.119 11.9056 185.431 12.6017 184.932 13.4945C184.432 14.3873 184.184 15.4045 184.184 16.5428C184.184 17.6811 184.432 18.6966 184.932 19.5911C185.431 20.4839 186.119 21.18 186.995 21.6793C187.871 22.1787 188.851 22.4275 189.937 22.4275C191.023 22.4275 192.004 22.1787 192.88 21.6793Z"
        fill="white"
      />
      <path
        d="M23.6565 9.42397V14.1368H20.3224L18.947 14.9976V9.42397H23.6565Z"
        fill="#F8A01A"
      />
      <path
        d="M14.1973 4.71114V0H0V4.71114V33.0856H3.73092H14.1973V28.3728H4.70947V4.71114H14.1973Z"
        fill="#58A280"
      />
      <path
        d="M18.9067 0V4.71114H28.366V28.3728H18.9067V33.0856H33.0755V30.3366V28.3728V4.71114V2.74901V0H18.9067Z"
        fill="#58A280"
      />
      <path
        d="M23.6566 18.8799V23.6616H9.41895V9.42397H14.2024V18.8799H23.6566Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_79_34">
        <rect width="200" height="33.0856" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const SIMPLE_WHITE_LOGO = (
  <svg
    height="100%"
    viewBox="0 0 500 500"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M261.614 421.351C263.722 408.36 254.9 396.12 241.909 394.011C228.918 391.903 216.677 400.725 214.569 413.716C212.46 426.707 221.283 438.948 234.274 441.056C247.265 443.165 259.506 434.343 261.614 421.351Z"
      fill="white"
    />
    <path
      d="M331.489 421.355C333.597 408.364 324.775 396.123 311.784 394.015C298.793 391.907 286.552 400.729 284.444 413.72C282.335 426.711 291.157 438.952 304.149 441.06C317.14 443.169 329.38 434.346 331.489 421.355Z"
      fill="white"
    />
    <path
      d="M401.364 421.359C403.473 408.368 394.65 396.127 381.659 394.019C368.668 391.91 356.427 400.732 354.319 413.724C352.211 426.715 361.033 438.955 374.024 441.064C387.015 443.172 399.256 434.35 401.364 421.359Z"
      fill="white"
    />
    <path
      d="M394.33 372.36H379.05C369.26 372.36 361.33 364.43 361.33 354.64C361.33 284.92 334.55 219.59 285.58 169.96C235.76 119.47 169.19 91.64 98.3301 91.64V58.64C177.39 58.64 251.73 89.43 307.63 145.34C363.54 201.25 394.33 273.56 394.33 352.63V372.36Z"
      fill="white"
    />
    <path
      d="M254.59 372.36H239.31C229.52 372.36 221.59 364.43 221.59 354.64C221.59 286.95 166.52 231.88 98.8301 231.88V198.88C184.72 198.88 254.59 268.76 254.59 354.64V372.36Z"
      fill="white"
    />
    <path
      d="M324.46 372.36H309.18C299.39 372.36 291.46 364.43 291.46 354.64C291.46 248.42 205.04 162 98.8201 162V129C159.09 129 215.75 152.47 258.37 195.09C300.99 237.71 324.46 294.37 324.46 354.64V372.36Z"
      fill="white"
    />
  </svg>
);
export const SIMPLE_BLUE_LOGO = (
  <svg
    height="100%"
    viewBox="0 0 500 500"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M261.614 421.351C263.722 408.36 254.9 396.12 241.909 394.011C228.918 391.903 216.677 400.725 214.569 413.716C212.46 426.707 221.283 438.948 234.274 441.056C247.265 443.165 259.506 434.343 261.614 421.351Z"
      fill="#006DD4"
    />
    <path
      d="M331.489 421.355C333.597 408.364 324.775 396.123 311.784 394.015C298.793 391.907 286.552 400.729 284.444 413.72C282.335 426.711 291.158 438.952 304.149 441.06C317.14 443.169 329.381 434.346 331.489 421.355Z"
      fill="#006DD4"
    />
    <path
      d="M401.364 421.359C403.472 408.368 394.65 396.127 381.659 394.019C368.668 391.91 356.427 400.732 354.319 413.724C352.21 426.715 361.033 438.955 374.024 441.064C387.015 443.172 399.256 434.35 401.364 421.359Z"
      fill="#006DD4"
    />
    <path
      d="M394.33 372.36H379.05C369.26 372.36 361.33 364.43 361.33 354.64C361.33 284.92 334.55 219.59 285.58 169.96C235.76 119.47 169.19 91.64 98.3301 91.64V58.64C177.39 58.64 251.73 89.43 307.63 145.34C363.54 201.25 394.33 273.56 394.33 352.63V372.36Z"
      fill="#006DD4"
    />
    <path
      d="M254.59 372.36H239.31C229.52 372.36 221.59 364.43 221.59 354.64C221.59 286.95 166.52 231.88 98.8301 231.88V198.88C184.72 198.88 254.59 268.76 254.59 354.64V372.36Z"
      fill="#006DD4"
    />
    <path
      d="M324.46 372.36H309.18C299.39 372.36 291.46 364.43 291.46 354.64C291.46 248.42 205.04 162 98.8201 162V129C159.09 129 215.75 152.47 258.37 195.09C300.99 237.71 324.46 294.37 324.46 354.64V372.36Z"
      fill="#006DD4"
    />
  </svg>
);

export const EXCLAMATION_LOGO = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99992 0.000171335C7.34777 0.000171335 4.80431 1.05369 2.92887 2.92905C1.05352 4.8044 0 7.34807 0 10.0001C0 12.6521 1.05352 15.1957 2.92887 17.0711C4.80423 18.9465 7.3479 20 9.99992 20C12.6519 20 15.1955 18.9465 17.071 17.0711C18.9463 15.1958 19.9998 12.6521 19.9998 10.0001C19.9998 8.24477 19.5378 6.52028 18.6602 4.99991C17.7825 3.47981 16.52 2.21736 14.9999 1.33964C13.4796 0.462045 11.7551 0 9.99974 0L9.99992 0.000171335ZM9.99992 18.0003C7.87813 18.0003 5.84326 17.1574 4.34317 15.657C2.84282 14.1568 1.9999 12.122 1.9999 10.0003C1.9999 7.87856 2.84282 5.8436 4.34317 4.34351C5.84335 2.84316 7.87822 2.00024 9.99992 2.00024C12.1216 2.00024 14.1566 2.84316 15.6567 4.34351C17.157 5.84369 17.9999 7.87856 17.9999 10.0003C17.9999 11.4045 17.6303 12.7841 16.928 14.0002C16.2259 15.2165 15.216 16.2263 13.9998 16.9284C12.7837 17.6307 11.4041 18.0004 9.99983 18.0004L9.99992 18.0003Z"
      fill="#333333"
    />
    <path
      d="M9.99995 8C9.73477 8 9.4803 8.10547 9.2928 8.29297C9.1053 8.48047 9 8.73479 9 9.00012V15.0001C9 15.3574 9.19051 15.6875 9.49989 15.8662C9.80926 16.0448 10.1906 16.0448 10.5 15.8662C10.8094 15.6875 10.9999 15.3574 10.9999 15.0001V9.00012C10.9999 8.73477 10.8946 8.48047 10.7071 8.29297C10.5196 8.10547 10.2652 8 9.99995 8Z"
      fill="#333333"
    />
    <path
      d="M10.7101 4.29025C10.5695 4.15147 10.3908 4.05755 10.197 4.02022C10.0029 3.98272 9.8022 4.00364 9.62005 4.08032C9.36877 4.17122 9.17103 4.3691 9.08013 4.62023C9.02372 4.73893 8.99626 4.86917 9.00011 5.00026C9.00011 5.26561 9.10541 5.5199 9.29291 5.7074C9.48041 5.89491 9.73486 6.00037 10.0001 6.00037C10.1308 5.9987 10.2599 5.97158 10.3801 5.92035C10.5011 5.86946 10.6128 5.79847 10.7101 5.71025C10.8488 5.56963 10.9428 5.39116 10.9803 5.19712C11.0176 5.00309 10.9967 4.80236 10.92 4.62022C10.8724 4.4975 10.8011 4.38534 10.7101 4.29025L10.7101 4.29025Z"
      fill="#333333"
    />
  </svg>
);

export default FULL_LOGO;
