import React, { useState } from 'react';

import DocumentVizAnnotation from './DocumentVizAnnotation';

import type { AnnotationsMapItem } from '../types';

type DocumentVizAnnotationsProps = {
  annotationsBasePath: string;
  annotations?: AnnotationsMapItem[];
  index: number;
  onShowMoreClick: () => void;
};

const DocumentVizAnnotations: React.FC<DocumentVizAnnotationsProps> = (
  props
) => {
  const { annotationsBasePath, annotations, index, onShowMoreClick } = props;
  const [expanded, setExpanded] = useState(false);

  const handleClick = (): void => {
    setExpanded(true);
    onShowMoreClick();
  };

  if (annotations == null || annotations.length === 0) {
    return null;
  }

  if (!expanded || annotations.length === 1) {
    return (
      <>
        <DocumentVizAnnotation
          annotationsBasePath={annotationsBasePath}
          annotation={annotations[0]}
        />

        {annotations.length > 1 && (
          <button
            className="mt-2 text-litlingo-primary text-xs"
            type="button"
            onClick={handleClick}
          >
            {`${annotations.length - 1} `}
            more identifier
            {annotations.length > 2 ? 's' : ''}
          </button>
        )}
      </>
    );
  }

  return (
    <>
      {annotations.map((annotation, idx) => (
        <DocumentVizAnnotation
          key={`annotations-${index}-${annotation}`}
          annotationsBasePath={annotationsBasePath}
          annotation={annotation}
          className={idx !== 0 ? 'mt-3' : ''}
        />
      ))}
    </>
  );
};

export default DocumentVizAnnotations;
