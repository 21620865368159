import type { CommunicationEnvelope, Tag, TagGroup as TagGroupType } from '@litlingo/client';
import { MINUS_ICON, PLUS_ICON } from 'constants/filterIcons';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTags } from 'selectors/envelopeReview';
import TagItem from './EnvelopeTagItem';

interface TagGroupProps {
  group: TagGroupType;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, tag: Tag) => void;
  envelope: CommunicationEnvelope;
  renderGroupName?: boolean;
  type?: 'checkbox' | 'radio';
  name?: string;
  handleToggleCollapse: (group: string) => void;
  collapsed: boolean;
}

const TagGroup: React.FC<TagGroupProps> = ({
  group,
  handleChange,
  envelope,
  renderGroupName = true,
  type = 'checkbox',
  name = '',
  handleToggleCollapse,
  collapsed,
}) => {
  const selectedTags = useSelector(getTags);
  if (!group.tag_values) return null;

  return (
    <>
      <div key={group.uuid} className="flex flex-col gap-1">
        {renderGroupName && (
          <div className="flex flex-row justify-between text-body pb-1 border-b border-litlingo-gray-1">
            <div className="font-bold">
              {group.name.charAt(0).toUpperCase() + group.name.slice(1)}
            </div>
            <button
              type="button"
              onClick={(): void => handleToggleCollapse(group.uuid)}
              className="focus:outline-none"
            >
              {collapsed ? PLUS_ICON() : MINUS_ICON()}
            </button>
          </div>
        )}
        {group.tag_values && !collapsed && (
          <div className="flex flex-col gap-2 pl-2">
            {[...group.tag_values]
              .sort((a, b) => ((a.updated_at as string) >= (b.updated_at as string) ? 1 : -1))
              .map((tag) => (
                <TagItem
                  key={tag.uuid}
                  tag={tag}
                  defaultChecked={
                    envelope.tags?.some((t) => t.tag_value_uuid === tag.uuid) || false
                  }
                  handleChange={handleChange}
                  name={name}
                  type={type}
                  checked={selectedTags?.some((t) => t.tag_value_uuid === tag.uuid) || false}
                />
              ))}
          </div>
        )}
      </div>
    </>
  );
};

interface Props {
  tagGroups: TagGroupType[];
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, tag: Tag) => void;
  envelope: CommunicationEnvelope;
  renderGroupName?: boolean;
  type?: 'checkbox' | 'radio';
  name?: string;
}

const GroupTags: React.FC<Props> = ({
  tagGroups,
  handleChange,
  envelope,
  renderGroupName = true,
  type = 'checkbox',
  name = '',
}) => {
  const collapsedTagGroups = JSON.parse(
    window.localStorage.getItem('collapsed_tag_groups') || '[]'
  ) as string[];

  const [collapsed, setCollapsed] = useState<string[]>(collapsedTagGroups);

  const handleToggleCollapse = (group: string): void => {
    let newColl = [];
    if (collapsed.includes(group)) {
      newColl = collapsed.filter((c) => c !== group);
    } else {
      newColl = [...collapsed, group];
    }

    window.localStorage.setItem('collapsed_tag_groups', JSON.stringify(newColl));
    setCollapsed(newColl);
  };

  if (tagGroups.length === 0) return null;

  return (
    <>
      {tagGroups.map(
        (group) =>
          group.tag_values && (
            <TagGroup
              handleChange={handleChange}
              envelope={envelope}
              renderGroupName={renderGroupName}
              type={type}
              name={name}
              group={group}
              handleToggleCollapse={handleToggleCollapse}
              collapsed={collapsed.includes(group.uuid)}
            />
          )
      )}
    </>
  );
};

export default GroupTags;
