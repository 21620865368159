import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { getFetchAllTagsLoading, getTags, getTagsTotalCount } from 'selectors/tags';

const TagCount: React.FC = () => {
  const navParams = useSelector(getNavParamsByResource('tags'));
  const tags = useSelector(getTags);
  const totalCount = useSelector(getTagsTotalCount);
  const loadingAll = useSelector(getFetchAllTagsLoading);

  const [initNumber, setInitNumber] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);

  const offset = parseInt(navParams.offset as string, 10);

  useEffect(() => {
    if ((firstLoad && offset > 0) || offset === 0) {
      setInitNumber(offset + 1);
      setFirstLoad(false);
    }
  }, [offset, firstLoad]);

  return (
    <div className="text-body">
      <span className="font-bold">
        {!loadingAll &&
          `${tags.length === 0 ? 0 : initNumber}-${Math.min(
            initNumber + tags.length - 1,
            totalCount
          )}`}
      </span>
      {!loadingAll && ` of ${totalCount === 10000 ? '+10,000' : totalCount} tags`}
    </div>
  );
};

export default TagCount;
