/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

const PREFIX = 'litlingo_session';
const duration = 60 * 60 * 8; // 8 hours

type Session = {
  id: string;
  expirationDate: string;
};

export const createSession = (): void => {
  if (!window.localStorage.getItem(PREFIX)) {
    const session: Session = {
      id: uuidv4(),
      expirationDate: moment().add(duration, 'seconds').utc().format(),
    };

    window.localStorage.setItem(PREFIX, JSON.stringify(session));
  }
};

export const setSession = (session: Session): void => {
  window.localStorage.setItem(PREFIX, JSON.stringify(session));
};

export const getSession = (): Session | null => {
  const session = window.localStorage.getItem(PREFIX);
  if (session) return JSON.parse(session);
  return null;
};

export const updateSession = (): void => {
  const session = window.localStorage.getItem(PREFIX);
  const newSession: Session = {
    id: uuidv4(),
    expirationDate: moment().add(duration, 'seconds').utc().format(),
  };
  if (!session) return;

  const parsed = JSON.parse(session) as Session;
  const exp = moment(parsed.expirationDate);

  if (moment() > exp) {
    window.localStorage.setItem(PREFIX, JSON.stringify(newSession));
  }
};

export const removeSession = (): void => {
  window.localStorage.removeItem(PREFIX);
};
