import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useCustomerDomain from 'hooks/nav/useCustomerDomainFromUrl';
import { useNavParamsByResource } from 'hooks/nav/useNavParamsByResource';
import { RequestParamsType, RequestResponse } from 'hooks/types/client';
import useRequest from '../client/useRequest';

type UseAllTeams = () => RequestResponse<'teams.list'>;

const useAllTeams: UseAllTeams = () => {
  const resourceParams = useNavParamsByResource(resourceQueryParamName.teams);
  const customerDomain = useCustomerDomain();
  const params: RequestParamsType = {
    queryParams: {
      ...resourceParams,
      relationships: ['users'],
      include_count: true,
      limit: '-1',
      customer_domain: customerDomain,
    },
  };

  const result = useRequest('teams.list', params);

  return result;
};

export default useAllTeams;
