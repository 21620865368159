import { selectCommunication, showSuccessAlert } from 'actions';
import { setEnvelopePreview } from 'actions/envelopeListView';
import { showMoreLikeThis } from 'actions/envelopeView';
import EnvelopeSkeleton from 'components/Envelope/EnvelopeSkeleton';
import RenderEnvelopeBoxes from 'components/Envelope/RenderEnvelopeBoxes';
import Permissions from 'components/Permissions';
import { CLOSE_MODAL_ICON } from 'constants/commonIcons';
import { COPY_ID_ICON, LINK_ICON } from 'constants/envelopeIcons';
import { concatFromQueryParam } from 'constants/eventsUtils';
import { SEARCH_ICON } from 'constants/filterIcons';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCustomerDomain } from 'selectors/auth';
import { getCommunicationPlatformGuid } from 'selectors/communications';
import { getEnvelopeToPreview } from 'selectors/envelopeListView';
import { getFetchSingleEnvelopeLoading } from 'selectors/envelopes';
import { useHistory } from 'utils/urls';

const EnvelopePreview = (): JSX.Element | null => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const customerDomain = useSelector(getCustomerDomain);
  const envelopeToPreview = useSelector(getEnvelopeToPreview);
  const envelopeLoading = useSelector(getFetchSingleEnvelopeLoading);

  const communicationPlatformGuid = useSelector(getCommunicationPlatformGuid);

  if (!envelopeToPreview) return null;

  const handleClosePreview = (): void => {
    dispatch(setEnvelopePreview(null));
    dispatch(selectCommunication({ communication: null }));
  };

  const handleCopyUrl = async (): Promise<void> => {
    await navigator.clipboard.writeText(
      `${window.location.origin}/${customerDomain}/communication-envelopes/${envelopeToPreview.envelopeId}`
    );
    dispatch(showSuccessAlert('URL copied to your clipboard'));
  };

  const handleCopyId = async (): Promise<void> => {
    await navigator.clipboard.writeText(communicationPlatformGuid ?? '');
    dispatch(showSuccessAlert('ID copied to your clipboard'));
  };

  const queryParams = concatFromQueryParam(
    location.search,
    location.pathname,
    resourceQueryParamName.envelopes
  );

  const handlePreviewReviewMessage = (): void => {
    dispatch(setEnvelopePreview(null));
    history.pushLookup({
      customerDomain,
      routeName: 'envelope-detail',
      routeParams: { envelopeId: envelopeToPreview.envelopeId },
      queryParams: `${queryParams}&threads__entry_point=${envelopeToPreview.envelopeId}`,
    });
  };

  const handleOpenMoreLikeThis = (): void => {
    dispatch(showMoreLikeThis(true));
  };

  return (
    <div
      className="absolute right-0 pt-6 pb-2 h-full w-148 flex flex-col  bg-white border-l border-litlingo-gray-2 overflow-hidden z-100"
      style={{ filter: 'drop-shadow(3px 1px 4px rgba(0, 0, 0, 0.25))' }}
    >
      <div className="flex flex-row gap-2 px-6">
        <div className="flex flex-1 items-start">
          <span className="text-heading-2" id="modal-headline">
            {`Message View: #${envelopeToPreview.envelopeIdx + 1}`}
          </span>
        </div>

        <div className="flex">
          <button
            type="button"
            data-testid="close-button"
            className="w-6 z-10 focus:outline-none"
            aria-label="Close"
            onClick={handleClosePreview}
          >
            {CLOSE_MODAL_ICON}
          </button>
        </div>
      </div>
      <div className="flex items-center gap-2 text-body mt-1 px-6">
        <Permissions action="communications.seeCommunicationSlug">
          <button type="button" className="focus:outline-none" onClick={handleCopyId}>
            {COPY_ID_ICON}
          </button>
        </Permissions>
        <button type="button" className="focus:outline-none" onClick={handleCopyUrl}>
          {LINK_ICON}
        </button>

        <Permissions action="communication_envelopes.more_like_this">
          <button type="button" className="focus:outline-none" onClick={handleOpenMoreLikeThis}>
            <div className="w-5">{SEARCH_ICON()}</div>
          </button>
        </Permissions>

        <button
          type="button"
          className="underline text-litlingo-primary-120 focus:outline-none"
          onClick={handlePreviewReviewMessage}
        >
          <span>Review Message</span>
        </button>
      </div>
      {envelopeLoading ? (
        <EnvelopeSkeleton />
      ) : (
        <div className="mt-2 overflow-hidden h-full">
          <RenderEnvelopeBoxes />
        </div>
      )}
    </div>
  );
};

export default EnvelopePreview;
