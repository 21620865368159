import CollapsableCard from 'components/CollapsableCard';
import SearchInput from 'components/SearchInput';
import TestCasesActions from 'components/TestCasesActions';
import TestCaseList from 'components/TestCaseTable';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import { evaluateAsyncTestCaseLoading, getTestCasesLoading } from 'selectors/testCases';
import { useSelector } from 'store';
import type { UUID } from 'types';

type ComponentProps = {
  entityId: UUID;
  entity: 'annotator' | 'campaign' | 'rule';
  includingRules?: boolean;
};

const RenderTestSuite: React.FC<ComponentProps> = ({
  entityId,
  entity,
  includingRules = false,
}) => {
  const evaluateAsyncTestLoading = useSelector(evaluateAsyncTestCaseLoading);
  const testCasesLoading = useSelector(getTestCasesLoading);

  const [searchValue, setSearchValue] = useState('');

  const action = <TestCasesActions entity={entity} entityId={entityId} />;

  const handleSearchChange = (value: string): void => {
    setSearchValue(value);
  };

  return (
    <CollapsableCard
      headerTitle="Test Suite"
      customAction={action}
      customLoading={evaluateAsyncTestLoading || testCasesLoading}
    >
      <div className="flex flex-col gap-2.5 p-4">
        <div className="flex-shrink-0 flex justify-between">
          <SearchInput
            resource={resourceQueryParamName.testCase}
            field="broad_search"
            handleSearch={handleSearchChange}
          />
        </div>
        <TestCaseList
          entity={entity}
          value={entityId}
          loading={testCasesLoading}
          campaignIncludingRules={includingRules}
          searchValue={searchValue}
        />
      </div>
    </CollapsableCard>
  );
};

export default RenderTestSuite;
