import React from 'react';

type ComponentProps = {
  completed: number;
  current?: number;
  overdue?: boolean;
};

const ReviewProgressBar: React.FC<ComponentProps> = ({ completed, current, overdue = false }) => {
  const completedCapped = completed > 100 ? 100 : completed;

  return (
    <div
      className="flex flex-row justify-start w-full h-6 rounded bg-litlingo-gray-1 overflow-hidden"
      style={{ width: '100%' }}
      data-testid="container-bar"
    >
      <div
        className={`h-full ${
          overdue ? 'bg-litlingo-alert bg-opacity-50' : 'bg-litlingo-primary-100'
        }`}
        style={{ width: `${completedCapped}%` }}
        data-testid="completed-bar"
      />
      {current && (
        <div
          className="h-full bg-litlingo-orange"
          style={{ width: `${current}%` }}
          data-testid="step-bar"
        />
      )}
    </div>
  );
};

export default ReviewProgressBar;
