/* eslint-disable react/jsx-indent */
import { HighlightedSentence } from '@litlingo/react';
import { fetchEventDocComm } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getEventFullMessageHighlighted } from 'selectors/events';
import { getPermissionsPolicy } from 'selectors/permissions';
import type { UUID } from 'types';
import { getFieldAuthorization } from 'utils/permissions';

type ComponentProps = {
  eventId: UUID;
  commUuid: UUID | null;
  onlySubject?: boolean;
};

const WrapperHighlightedSentenceComponent: React.FC<ComponentProps> = ({
  eventId,
  commUuid,
  onlySubject,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const entityFieldLevel = getFieldAuthorization('communications.entity', user.roles, policy);
  const resultLines = useSelector(getEventFullMessageHighlighted(eventId));

  useEffect(() => {
    if (eventId && commUuid && !resultLines) {
      dispatch(fetchEventDocComm({ eventId, commUuid }));
    }
  }, [commUuid, dispatch, eventId, resultLines]);

  if (!resultLines) return <LoadingIndicator />;

  if (onlySubject) {
    return (
      <div className="mb-4">
        {resultLines
          .filter((line) => line.type === 'subject')
          .map((line, idx) => (
            <div key={line.index} className={idx !== 0 ? 'mt-2' : ''}>
              <HighlightedSentence
                nodes={line.nodes}
                useHover={user.roles.includes('super-admin')}
                protectedFields={{
                  entity: entityFieldLevel,
                }}
                portal="tooltip-portal"
              />
            </div>
          ))}
      </div>
    );
  }

  const renderContent = (): JSX.Element[] =>
    resultLines
      .filter((line) => line.type === 'line')
      .map((line, idx) => (
        <div key={line.index} className={idx !== 0 ? 'mt-2' : ''}>
          <HighlightedSentence
            nodes={line.nodes}
            useHover={user.roles.includes('super-admin')}
            protectedFields={{
              entity: entityFieldLevel,
            }}
            portal="tooltip-portal"
          />
        </div>
      ));

  return <>{renderContent()}</>;
};

export default WrapperHighlightedSentenceComponent;
