import { resourceToHumanLabel } from 'constants/resourceQueryNames';
import React, { useCallback, useMemo, useState } from 'react';
import { captureException } from 'utils/reports';

export type AlertType = 'success' | 'error' | 'undelete' | 'neutral' | 'query';
export type Alert = {
  message: string;
  type: AlertType;
  resource?: keyof typeof resourceToHumanLabel;
  id?: string;
};

export type AlertContext = {
  alert: Alert | null;
  showSuccessAlert: (message: string) => void;
  showErrorAlert: (message: string) => void;
  showQueryErrorAlert: (message: string) => void;
  dismissAlert: () => void;
};

const AlertsContext = React.createContext<AlertContext>({
  alert: null,
  showErrorAlert: () => {
    // do nothing default
  },
  showQueryErrorAlert: () => {
    // do nothing default
  },
  showSuccessAlert: () => {
    // do nothing default
  },
  dismissAlert: () => {
    // do nothing default
  },
});

const DEFAULT_TIMEOUT = 4000;

export const AlertsProvider: React.FC = ({ children }) => {
  const [alertState, setAlertState] = useState<Alert | null>(null);

  const eraseAlert = useCallback((timeout: number | undefined = DEFAULT_TIMEOUT): void => {
    setTimeout(() => setAlertState(null), timeout);
  }, []);

  const showSuccessAlert = useCallback(
    (message: string): void => {
      setAlertState({ message, type: 'success' });
      eraseAlert();
    },
    [eraseAlert]
  );

  const showErrorAlert = useCallback(
    (message: string): void => {
      captureException(message);
      setAlertState({ message, type: 'error' });
      eraseAlert();
    },
    [eraseAlert]
  );

  const showQueryErrorAlert = useCallback(
    (message: string): void => {
      captureException(message);
      setAlertState({ message, type: 'query' });
      eraseAlert();
    },
    [eraseAlert]
  );

  const dismissAlert = (): void => {
    setAlertState(null);
  };

  const value = useMemo(
    () => ({
      alert: alertState,
      showErrorAlert,
      showQueryErrorAlert,
      showSuccessAlert,
      dismissAlert,
    }),
    [alertState, showErrorAlert, showQueryErrorAlert, showSuccessAlert]
  );

  return <AlertsContext.Provider value={value}>{children}</AlertsContext.Provider>;
};

export default AlertsContext;
