import { saveUsers } from 'actions';
import { setNeedToApplyChanges } from 'actions/userPermissionsPage';
import EnvelopeCollapsableSection from 'components/CollapsableSection/EnvelopeCollapsableSection';
import LinkLookup from 'components/LinkLookup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getUsers, {
  getCanApply,
  getExcludedRoles,
  getIncludedRoles,
  getIsSelectAll,
  getSelectedUsers,
} from 'selectors/userPermissionsPage';
import { getUsersTotalCount } from 'selectors/users';
import SelectedUsersSection from './SelectedUsersSection';
import UsersChangePermissions from './UsersChangePermissions';
import UsersInformationSection from './UsersInformationSection';
import UsersStats from './UsersStats';

type ComponentProps = {
  footer?: boolean;
  saveInApply?: boolean;
  shouldDeleteUser?: boolean;
};

const MultipleUserSidebar: React.FC<ComponentProps> = ({
  footer = true,
  saveInApply = false,
  shouldDeleteUser = false,
}) => {
  const dispatch = useDispatch();

  const users = useSelector(getUsers);
  const selectedUsers = useSelector(getSelectedUsers);
  const isSelectAll = useSelector(getIsSelectAll);
  const usersCount = useSelector(getUsersTotalCount);
  const canApply = useSelector(getCanApply);

  const [sendInvitation, setSendInvitation] = useState(true);

  const includedRoles = useSelector(getIncludedRoles);
  const excludedRoles = useSelector(getExcludedRoles);

  const [canSendInvitation, setCanSendInvitation] = useState(false);

  useEffect(() => {
    // @ts-ignore
    const canSend = selectedUsers.every((user) => user.roles.includes('Reviewer'));
    setCanSendInvitation(canSend);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers.length, includedRoles, excludedRoles]);

  const handleConfirmSave = (): void => {
    if (canApply) {
      dispatch(setNeedToApplyChanges(true));
      return;
    }
    dispatch(
      saveUsers({
        users,
        sendInvitation: !!(canSendInvitation && sendInvitation),
        redirect: true,
        fromPermissionsPage: true,
      })
    );
  };

  return (
    <div className="relative h-full flex flex-col bg-white">
      <div className="flex-1 overflow-auto no-scrollbar">
        <div className="h-full">
          <EnvelopeCollapsableSection
            headerTitle={`Selected Users (${isSelectAll ? usersCount : selectedUsers.length})`}
          >
            <SelectedUsersSection shouldRemoveUsers={!saveInApply} />
          </EnvelopeCollapsableSection>
          <EnvelopeCollapsableSection headerTitle="Stats" isDefaultClosed>
            <UsersStats />
          </EnvelopeCollapsableSection>
          <EnvelopeCollapsableSection
            headerTitle="Bulk User Permissions"
            bulkReview={selectedUsers.length > 1}
          >
            <>
              <UsersInformationSection users={selectedUsers} shouldDeleteUser={shouldDeleteUser} />
              <UsersChangePermissions
                saveInApply={saveInApply}
                sendInvitation={sendInvitation}
                setSendInvitation={setSendInvitation}
                canSendInvitation={canSendInvitation}
              />
            </>
          </EnvelopeCollapsableSection>
        </div>
      </div>

      {footer && (
        <div
          className="flex flex-col gap-1 bg-white border border-litlingo-gray-1 p-4"
          style={{ filter: 'drop-shadow(3px 1px 8px rgba(0, 0, 0, 0.25))' }}
        >
          <div className="flex flex-row items-center justify-between gap-6 h-8">
            <LinkLookup
              routeName="users"
              data-testid="next-envelope-button"
              className="button button--secondary w-27 py-1.5 h-full"
            >
              <div className="flex w-full justify-center items-center font-bold">Exit</div>
            </LinkLookup>
            <button
              type="button"
              id="next-envelope-button"
              data-testid="next-envelope-button"
              className="button  button--primary min-w-27 h-full py-1.5 focus:outline-none"
              onClick={handleConfirmSave}
              disabled={canApply}
            >
              <div className="flex w-full justify-center items-center text-base font-bold leading-5">
                Save & Exit
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default MultipleUserSidebar;
