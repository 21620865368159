import type { AuditLog, Selector } from 'types';

export const getFetchAnnotatorsAuditLogsLoading: Selector<boolean> = (state) =>
  state.annotatorsAuditLogs.loading.includes('annotators-audit-logs');

export const getAnnotatorsAuditLogsCount: Selector<number> = (state) =>
  state.annotatorsAuditLogs.count;

export const getAnnotatorsAuditLogsList: Selector<AuditLog[]> = (state) => {
  if (state.annotatorsAuditLogs.list == null) {
    return [];
  }

  return state.annotatorsAuditLogs.list.map((uuid) => state.annotatorsAuditLogs.items[uuid]);
};
