import { setURLParams } from 'actions';
import SelectRedesign from 'components/Select/SelectRedesign';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';

type ComponentProps = {
  className?: string;
  resource: string;
};

const TrueUsersFilter: React.FC<ComponentProps> = (props) => {
  const { className, resource } = props;

  const dispatch = useDispatch();
  const filter = (useSelector(getNavParamsByResource(resource)).true_users || 'true') as string;

  const options = [
    {
      label: 'True Users',
      value: 'true',
    },
    {
      label: 'All users',
      value: 'false',
    },
  ];

  const handleChange = (option: string): void => {
    logEvent(`true-users-filter-${option}`);
    dispatch(
      setURLParams({
        [`${resource}__true_users`]: option,
        [`${resource}__offset`]: '0',
      })
    );
  };

  return (
    <div className="w-28">
      <SelectRedesign
        className={className || ''}
        placeholder="True users"
        options={options}
        dataTestid="true-users-filter"
        onChange={handleChange}
        value={filter}
      />
    </div>
  );
};

export default TrueUsersFilter;
