import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useNavParams from 'hooks/nav/useNavParams';
import { useNavParamsByResource } from 'hooks/nav/useNavParamsByResource';
import { RequestParamsType, RequestResponse } from 'hooks/types/client';
import { getCustomerId } from 'selectors/auth';
import { useSelector } from 'store';
import useRequest from '../client/useRequest';

type Options = { redirectOnError: boolean };

type UseCategories = (options?: Options) => RequestResponse<'categories.list'>;

const useCategories: UseCategories = (options) => {
  const resourceParams = useNavParamsByResource(resourceQueryParamName.category);
  const navParams = useNavParams() as { customer_owned: string };
  const customer = useSelector(getCustomerId);

  const params: RequestParamsType = {
    queryParams: {
      ...resourceParams,
      customer_uuids: [navParams.customer_owned && customer ? customer : ''],
      relationships: ['updated_by', 'rule', 'customer', 'model', 'outcome'],
      include_count: true,
      include_pii: true,
    },
    redirectOnError: options?.redirectOnError,
  };

  const result = useRequest('categories.list', params);

  return result;
};

export default useCategories;
