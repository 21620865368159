import { FallbackRender } from '@sentry/react/dist/errorboundary';
import React from 'react';

const SentryError: FallbackRender = () => {
  const reload = (): void => {
    window.location.reload();
  };

  const redirectHome = (): void => {
    window.location.replace('/');
  };

  return (
    <div className="min-h-screen flex flex-col pt-64 items-center">
      <div className="w-3/5 flex flex-col items-center gap-8">
        <p className="text-heading-1 w-full text-center">Something unexpected happened</p>
        <div className="flex flex-row justify-start gap-8">
          <button type="button" className="button button--primary font-bold" onClick={reload}>
            Reload page
          </button>
          <button
            type="button"
            className="button button--secondary font-bold"
            onClick={redirectHome}
          >
            Redirect home
          </button>
        </div>
      </div>
    </div>
  );
};

export default SentryError;
