import { fetchSavedSearches } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import useClickOutside from 'components/utils/useClickOutside';
import { SEARCH_ICON } from 'constants/filterIcons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSavedSearchesList, getSavedSearchesLoading } from 'selectors/savedSearches';
import 'styles/labelsStyle.css';
import type { SavedSearch } from 'types';

type UserSelectProps = {
  selectSavedSearch: (user: SavedSearch, checked: boolean) => void;
  disabled?: boolean;
  className?: string;
  selectedSavedSearches?: SavedSearch[];
};

const SavedSearchSelect: React.FC<UserSelectProps> = (props) => {
  const { selectSavedSearch, disabled, selectedSavedSearches, className = '' } = props;

  const dispatch = useDispatch();
  const savedSearches = useSelector(getSavedSearchesList);
  const savedSearchesLoading = useSelector(getSavedSearchesLoading);
  const [isOpen, setIsOpen] = useState(false);

  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const handleClickOutside = useCallback(() => {
    setSearch('');
    setIsOpen(false);
  }, [setIsOpen]);

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleButtonClick = (): void => {
    setIsOpen(true);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    savedSearch: SavedSearch
  ): void => {
    selectSavedSearch(savedSearch, event.target.checked);

    event.stopPropagation();
  };

  useEffect(() => {
    const t = setTimeout(() => setDebouncedSearch(search), 500);
    return (): void => {
      clearTimeout(t);
    };
  }, [search]);

  useEffect(() => {
    dispatch(fetchSavedSearches({ broad_search: debouncedSearch, limit: 25 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, dispatch]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e?.target.value.toLowerCase();
    setSearch(value);
  };

  const renderOptions = (): JSX.Element => {
    if (savedSearchesLoading) return <LoadingIndicator />;

    return (
      <fieldset className="m-2 flex flex-col gap-2" data-testid="savedSearches-list">
        {savedSearches.length > 0 ? (
          savedSearches.sort().map((searchItem, idx) => (
            <div
              key={searchItem.uuid}
              data-testid={`searchItem-option-${idx}`}
              className="cursor-pointer"
            >
              <label
                htmlFor={`searchItem-option-${searchItem.uuid}`}
                className="flex flex-row items-center gap-1"
              >
                <input
                  type="checkbox"
                  id={`searchItem-option-${searchItem.uuid}`}
                  data-testid={`searchItem-select-button-${idx}`}
                  onChange={(e): void => handleInputChange(e, searchItem)}
                  checked={selectedSavedSearches?.some((u) => u.uuid === searchItem.uuid)}
                  className="form-checkbox litlingo-checkbox"
                />

                <div className="text-small select-none break-all">{searchItem.name}</div>
              </label>
            </div>
          ))
        ) : (
          <div className="text-small">No saved searches found</div>
        )}
      </fieldset>
    );
  };

  return (
    <div ref={wrapperRef} className={`relative inline-block text-left w-full ${className || ''}`}>
      <div className="rounded-md shadow-sm">
        <div className="flex flex-row items-center w-full h-8 bg-white">
          <div className="absolute left-0 ml-1.5 w-5">{SEARCH_ICON()}</div>
          <input
            id="search"
            name="search"
            disabled={disabled}
            data-testid="user-select-button"
            className="form-input h-full w-full text-litlingo-gray-6 pl-8 text-body placeholder-italic placeholder-litlingo-gray-6"
            value={search}
            placeholder="Search searches"
            onChange={handleSearchChange}
            onFocus={handleButtonClick}
            autoComplete="off"
          />
        </div>
      </div>

      {isOpen && (
        <div className="origin-top-left absolute left-0 mt-1 w-full rounded-md shadow-lg z-40">
          <div className="rounded-md bg-white shadow-xs">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="max-h-select-dropdown overflow-auto custom-scrollbar-select-dropdown">
                {renderOptions()}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SavedSearchSelect;
