import React, { useCallback, useRef } from 'react';
import useClickOutside from '../utils/useClickOutside';

type ComponentProps = {
  className: string;
  onChange: (keyword?: string | boolean | undefined) => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  options: {
    value: string | number;
    label: string | number;
  }[];
  selectedItems: (string | number)[];
  dataTestid: string;
  justify: string;
};

const AnnotatorMultiSelectDropdown: React.FC<ComponentProps> = (props) => {
  const { className, onChange, isOpen, setIsOpen, options, selectedItems, dataTestid, justify } =
    props;

  // click outside functionality

  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleButtonClick = (): void => setIsOpen(!isOpen);

  return (
    <div ref={wrapperRef} className={`relative inline-block text-left ${className || ''}`}>
      <div className="rounded-md shadow-sm">
        <button
          type="button"
          data-testid={`${dataTestid}-button`}
          // hover:font-bold
          className={`inline-flex ${justify} w-full rounded-md border px-4 py-2 bg-white text text--lighter-4 hover:text-gray-500 focus:outline-none focus:border-litlingo-success focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 ${
            selectedItems.length !== 0 ? 'border-gray-700' : 'border-gray-300'
          }`}
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={handleButtonClick}
        >
          {`Selected ${selectedItems.length > 0 ? selectedItems.length : ''}`}
          <svg className="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute left-0 my-2 origin-top-left rounded-md shadow-lg z-10"
          id="multi-select-dropdown-content"
        >
          <div className="rounded-md bg-white shadow-xs">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="m-2 mr-2 max-h-filter overflow-auto">
                <fieldset data-testid={`${dataTestid}-options`}>
                  {options.map((option, idx) => (
                    <div key={option.value} className={idx !== 0 ? 'mt-4 mr-4' : 'mr-4'}>
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5 mr-2">
                          <input
                            id={option.value.toString()}
                            data-testid={`${option.value}-checkbox`}
                            type="checkbox"
                            value={option.value}
                            className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out"
                            onChange={(e): void => onChange(e.target.value)}
                            checked={selectedItems.includes(option.value)}
                          />
                        </div>
                        <div className="text-sm leading-5">
                          <label htmlFor={option.value.toString()} className="text-gray-700">
                            {option.label}
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                  {options.length === 0 && (
                    <div className="text-center">
                      <span className="text-sm text-gray-700">No items</span>
                    </div>
                  )}
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnnotatorMultiSelectDropdown;
