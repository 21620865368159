/* eslint-disable max-lines */

import { ResponsiveLine } from '@nivo/line';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import React, { useMemo } from 'react';
import { DefaultWidgetComponentProps, MetricsDataV2 } from 'types';
import logEvent from 'utils/analytics';
import {
  buildStackedDateHistogramLineDataFromMetricsV2,
  getTotalCountFromDateHistogramLineData,
} from 'utils/dashboard';

// FIXME: Change DefaultWidgetComponentProps to use new metrics type
// once the migration to the new structure is done
const StackedDateHistogramWidget: React.FC<
  DefaultWidgetComponentProps & { widgetData: MetricsDataV2 }
> = (props) => {
  const { widgetId, widgetData, queryData, nivoProps } = props;

  const processedData = useMemo(
    () =>
      widgetData &&
      queryData &&
      buildStackedDateHistogramLineDataFromMetricsV2(widgetData, queryData),
    [widgetData, queryData]
  );

  if (widgetData == null || queryData == null || !processedData) {
    return <WidgetLoading />;
  }

  const totalCount = getTotalCountFromDateHistogramLineData(processedData);
  if (totalCount === 0) {
    return <WidgetEmptyState />;
  }

  const handleMouseEnter = (): void => {
    const [id, type] = widgetId.split('--');
    logEvent(`Dashboard-hover-${id}-${type}`);
  };

  return (
    <>
      {/* <WidgetHeader widgetId={widgetId} title={title} subtitle={subtitle} /> */}
      <div className="h-full">
        <div className="h-dashboard-events" onMouseEnter={handleMouseEnter}>
          <ResponsiveLine
            curve="monotoneX"
            colors={[
              // greens dark to light
              '#65A782',
              '#93C6A8',
              '#B6DDC6',
              // yellows dark to light
              '#F4A21F',
              '#FFCB48',
              '#FFEDA1',
            ]}
            pointSize={8}
            enableArea={false}
            legends={[
              {
                // dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                itemWidth: 100,
                itemHeight: 20,
                translateX: 130,
                toggleSerie: true,
              },
            ]}
            animate
            enableSlices="x"
            margin={{ top: 10, right: 220, bottom: 50, left: 50 }}
            axisLeft={{
              format: (value): string => value,
            }}
            data={processedData.data}
            axisBottom={{
              tickRotation: -49,
              legendOffset: 30,
              tickPadding: 5,
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...nivoProps}
          />
        </div>
      </div>
    </>
  );
};

export default withWidget({
  title: 'Events',
  subtitle: 'Communications flagged by active models',
  // noHeader: true,
})(StackedDateHistogramWidget as React.FC<DefaultWidgetComponentProps>);
