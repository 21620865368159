import { fetchActiveTasks } from 'actions/tasks';
import Tasks from 'components/Tasks';
import { RouteSpec, SuperRouteSpec } from 'types';

const tasksRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'tasks',
    path: '/:customerDomain/tasks',
    component: Tasks,
    private: true,
    permissions: 'tasks.list',
    data: {
      actions: [fetchActiveTasks],
      params: {
        include_count: {
          defaultValue: '1',
        },
        include_pii: {
          defaultValue: 'true',
        },
        tasks: {
          nestedResource: true,
          params: {
            broad_search: {},
            order_by: {
              defaultValue: 'created_at',
              options: ['created_at', 'random'],
            },
            order_desc: {
              defaultValue: 'true',
            },
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            created_by: {
              list: true,
            },
          },
        },
      },
    },
  },
];

export default tasksRoutes;
