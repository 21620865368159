import { deleteCustomReport, upsertCustomReport } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import Modal from 'components/Modal';
import SavedSearchLabels from 'components/SavedSearchesManager/SavedSearchLabels';
import SavedSearchSelect from 'components/SavedSearchesManager/SavedSearchSelect';
import SelectRedesign from 'components/Select/SelectRedesign';
import UserLabels from 'components/User/UserLabels';
import UserSelect from 'components/User/UserSelect';
import frequencyOptions from 'constants/customReports';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomReport, SavedSearch, User } from 'types';
import logEvent from 'utils/analytics';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
  reportToEdit?: CustomReport;
  isCreating?: boolean;
};

const EditReportModal: React.FC<ComponentProps> = ({
  isModalOpen,
  toggleModalOpen,
  reportToEdit,
  isCreating,
}) => {
  const dispatch = useDispatch();
  const report = reportToEdit;

  const [isDeletingReport, setIsDeletingReport] = useState(false);
  const [users, setUsers] = useState([] as User[]);
  const [searches, setSearches] = useState([] as SavedSearch[]);
  const [frequency, setFrequency] = useState('');

  useEffect(() => {
    if (report) {
      if (report.user) {
        setUsers([report?.user]);
      }
      if (report.search) {
        setSearches([report?.search]);
      }
      if (report.kind) {
        setFrequency(report?.kind);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteButtonStyle = 'text-litlingo-alert underline self-end focus:outline-none';

  const onSubmit = (): void => {
    if (frequency.length && users.length && searches.length) {
      dispatch(
        upsertCustomReport({
          uuid: report?.uuid,
          kind: frequency,
          user_uuid: users[0].uuid,
          saved_search_uuid: searches[0].uuid,
        })
      );
      toggleModalOpen();
    }
  };

  const handleSaveButton = (): void => {
    onSubmit();
  };

  const toggleConfirmModal = (): void => setIsDeletingReport(!isDeletingReport);

  const handleDelete = (): void => {
    if (reportToEdit) {
      dispatch(deleteCustomReport({ uuid: reportToEdit.uuid }));
      setIsDeletingReport(false);
      toggleConfirmModal();
    }
  };

  const handleDeleteUser = (user: User): void => {
    setUsers(users.filter((u) => u.uuid !== user.uuid));
    logEvent('report-remove-user');
  };

  const handleSelectUser = (user: User, checked: boolean): void => {
    if (checked && users.length === 0) {
      setUsers((userList) => [...userList, user]);
      logEvent('report-add-reviewer');
    } else {
      handleDeleteUser(user);
    }
  };

  const handleDeleteSavedSearch = (savedSearch: SavedSearch): void => {
    setSearches(searches.filter((s) => s.uuid !== savedSearch.uuid));
    logEvent('report-remove-saved-search');
  };

  const handleSelectSavedSearch = (savedSearch: SavedSearch, checked: boolean): void => {
    if (checked && searches.length === 0) {
      setSearches((searchesList) => [...searchesList, savedSearch]);
      logEvent('report-add-saved-search');
    } else {
      handleDeleteSavedSearch(savedSearch);
    }
  };

  const renderModelForm = (
    <div className="pt-4 pb-4">
      <form className="flex flex-col items-start">
        <div className="flex flex-col items-start gap-4 w-full">
          <div className="flex flex-col gap-1 mt-2 text-body">
            <div className="flex flex-row gap-1">
              <span>Frequency</span>
              <span className="litlingo-red-color">*</span>
            </div>
            <div className="flex flex-row items-start gap-5">
              <div className="flex-shrink-0 w-44">
                <SelectRedesign
                  onChange={(value: string): void => setFrequency(value)}
                  options={frequencyOptions}
                  value={frequency}
                  placeholder="Select frequency"
                  dataTestid="review-type-select"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex flex-row gap-1 text-body">
              <span>User</span>
              <span className="litlingo-red-color">*</span>
            </div>
            <div className="flex flex-row items-start w-full gap-5">
              <div className="w-44">
                <UserSelect
                  selectUser={handleSelectUser}
                  selectedUsers={users}
                  className="min-w-44"
                />
              </div>
              <UserLabels
                users={users}
                handleDelete={handleDeleteUser}
                className="flex-wrap self-end"
              />
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex flex-row gap-1 text-body">
              <span>Saved Search</span>
              <span className="litlingo-red-color">*</span>
            </div>
            <div className="flex flex-row items-start w-full gap-5">
              <div className="w-44">
                <SavedSearchSelect
                  selectSavedSearch={handleSelectSavedSearch}
                  selectedSavedSearches={searches}
                  className="min-w-44"
                />
              </div>
              <SavedSearchLabels
                searches={searches}
                handleDelete={handleDeleteSavedSearch}
                className="flex-wrap self-end"
              />
            </div>
          </div>

          <div className="w-full border-bottom" />
        </div>
      </form>
    </div>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderModelForm}
          title={isCreating ? 'Create New Report' : 'Edit Report'}
          okButton
          okButtonText="Save"
          okButtonOnClick={handleSaveButton}
          toggleShowModal={toggleModalOpen}
          secondaryButton={reportToEdit !== undefined}
          secondaryButtonOnclick={toggleConfirmModal}
          secondaryButtonStyle={deleteButtonStyle}
          secondaryButtonText="Delete Report"
        />
      )}
      {isDeletingReport && (
        <ConfirmModal
          text="Are you sure you want to delete this report?"
          okButtonText="Yes"
          cancelButtonText="No"
          data-testid="delete-modal"
          okButtonOnClick={handleDelete}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </>
  );
};

export default EditReportModal;
