/* eslint-disable max-lines */
/* eslint-disable camelcase */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { addValueToTree, fetchAllCampaignRuleOutcomes, removeValueFromTree } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import useClickOutside from 'components/utils/useClickOutside';
import { SEARCH_ICON } from 'constants/filterIcons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import { getCampaignRuleOutcomesList } from 'selectors/campaignRuleOutcomes';
import { getNavParamsFromTree } from 'selectors/nav';
import { useSelector } from 'store';
import { Selector, UUID } from 'types';
import logEvent from 'utils/analytics';
import ShowLabels from './ShowLabels';

type ResourceFilterProps<N, A, S> = {
  className?: string;
  filterKey: string;
  fetchAllAction?: A;
  getResourceList: S;
  getLoading?: Selector<boolean>;
  title?: string;
  nameKey: N;
  allowExclude?: boolean;
  dropdown?: boolean;
  dataTestid?: string;
  isRule?: boolean;
  colorChip?: boolean;
  isOpening?: boolean;
};

const ResourceFilter = <
  N extends string,
  T extends { uuid: UUID; color?: string | null; envelopes_count?: number } & Record<
    N,
    string | null
  >,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  A extends ActionCreatorWithOptionalPayload<any, string>,
  S extends Selector<T[], []>
>(
  props: ResourceFilterProps<N, A, S>
): ReturnType<React.FC> => {
  const {
    fetchAllAction,
    filterKey,
    getResourceList,
    getLoading,
    title = '',
    nameKey,
    allowExclude = true,
    dropdown = false,
    className,
    dataTestid,
    isRule = false,
    colorChip = false,
    isOpening,
  } = props;

  const dispatch = useDispatch();
  const treeParams = useSelector(getNavParamsFromTree);
  const allData = useSelector(getResourceList);
  const [isOpen, setIsOpen] = useState(false);
  const [filtered, setFiltered] = useState<T[]>([]);
  const [search, setSearch] = useState('');
  const outcomes = useSelector(getCampaignRuleOutcomesList);

  const loading = useSelector((state) => (getLoading ? getLoading(state) : false));

  const inputRef = useRef<HTMLInputElement>(null);

  const filterData = treeParams[filterKey] || [];
  const notFilterData = treeParams[`not_${filterKey}`] || [];
  const andFilterData = treeParams[`${filterKey}_and`] || [];
  const andNotFilterData = treeParams[`not_${filterKey}_and`] || [];

  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const filterBySearch = useCallback((): void => {
    setFiltered(
      allData.filter((item) => {
        const name = item[nameKey];
        if (name != null) return name.toLowerCase().includes(search.toLowerCase() || '');
        return '';
      })
    );
  }, [allData, nameKey, search]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    filterBySearch();
  }, [search, filterBySearch, allData]);

  useEffect(() => {
    if (fetchAllAction != null) {
      dispatch(fetchAllAction({ limit: -1, include_envelopes_count: false }));
      if (isRule) {
        dispatch(fetchAllCampaignRuleOutcomes({ limit: -1 }));
      }
    }
  }, [dispatch, fetchAllAction, title, isRule]);

  useEffect(() => {
    if (isOpening) {
      inputRef.current?.focus();
    }
  }, [inputRef, isOpening]);

  const handleButtonClick = (): void => {
    if (isOpen) {
      return;
    }
    setIsOpen(true);
  };

  const handleDelete = (labelId: string, exclude: boolean): void => {
    if (exclude) {
      dispatch(
        removeValueFromTree({ value: labelId, field: `not_${filterKey}`.replace('_and', '') })
      );
    }

    dispatch(
      removeValueFromTree({
        value: labelId,
        field: filterKey.replace('_and', ''),
      })
    );
  };

  const handleInputChange = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    logEvent(`envelopes-list-filter-${title.toLowerCase()}`);

    const { shiftKey } = event.nativeEvent;
    const { id: state } = event.currentTarget;

    let exclude = shiftKey;
    if (allowExclude === false) exclude = false;

    if (exclude && filterData.includes(state)) return;
    if (!exclude && notFilterData.includes(state)) return;
    let itemsNames: string[] = [];

    if (exclude) {
      itemsNames = [...notFilterData];
    } else {
      itemsNames = [...filterData];
    }

    if (dropdown) {
      const itemsFilterData = typeof filterData === 'string' ? [filterData] : [...filterData];
      const itemsNotFilterData =
        typeof notFilterData === 'string' ? [notFilterData] : [...notFilterData];
      itemsNames = !exclude ? itemsFilterData : itemsNotFilterData;
    }

    event.currentTarget.dataset.checked =
      event.currentTarget.dataset.checked === 'true' ? 'false' : 'true';

    if (event.currentTarget.dataset.checked === 'true') {
      itemsNames.push(state);
      const label = filtered.find((item) => item.uuid === state)?.[nameKey];

      dispatch(
        addValueToTree({
          field: exclude ? `not_${filterKey}`.replace('_and', '') : filterKey.replace('_and', ''),
          value: state,
          label: label ?? undefined,
        })
      );
    } else {
      itemsNames = itemsNames.filter((s) => s !== state);
      handleDelete(state, exclude);
    }

    event.stopPropagation();
  };
  const sortOptions = (a: T, b: T): number => {
    const filterIds = [...filterData, ...andFilterData, ...notFilterData, ...andNotFilterData];

    if (filterIds.includes(a.uuid) && !filterIds.includes(b.uuid)) {
      return -1;
    }
    if (filterIds.includes(b.uuid)) {
      return 1;
    }
    return 0;
  };

  return (
    <div ref={wrapperRef} className={`flex flex-col ${className || ''}"`}>
      <div className="relative flex flex-row justify-start items-center w-full min-h-8 rounded p-1.5 bg-litlingo-success-light bg-opacity-10">
        <div className="w-5">{SEARCH_ICON('white')}</div>
        <input
          id="search"
          name="search"
          ref={inputRef}
          value={search}
          className="max-h-5 w-full text-white ml-1 font-normal text-base bg-transparent placeholder-white placeholder-italic"
          placeholder={`Search ${title}`}
          type="text"
          data-testid={dataTestid}
          onFocus={handleButtonClick}
          onChange={handleSearchChange}
          autoComplete="off"
        />
      </div>
      {isOpen && (
        <>
          <LoadingOverlayWrapper active={loading} spinner={<LoadingIndicator />} fadeSpeed={0}>
            <div className="w-full max-h-filter z-10 overflow-auto bg-litlingo-gray-1 custom-scrollbar">
              <fieldset className="m-2 min-h-5">
                {filtered
                  .sort(sortOptions)
                  .slice(0, 25)
                  .map((item, idx) => {
                    const ruleOutcome = outcomes.find((outcome) => outcome.rule_uuid === item.uuid);
                    if (
                      isRule &&
                      (!ruleOutcome || (ruleOutcome && ruleOutcome.campaign?.deleted_at))
                    )
                      return null;

                    return (
                      <div key={item.uuid} className={idx !== 0 ? 'mt-2' : ''}>
                        <div
                          id={item.uuid}
                          role="button"
                          aria-hidden
                          className="relative flex items-center cursor-pointer"
                          data-checked={[
                            ...filterData,
                            ...notFilterData,
                            ...andFilterData,
                            ...andNotFilterData,
                          ].includes(item.uuid)}
                          onClick={handleInputChange}
                        >
                          <div className="text-sm leading-5 flex flex-col items-start justify-center cursor-pointer">
                            <div className="flex flex-row items-center cursor-pointer">
                              <div className="relative flex justify-start items-center">
                                <div className="flex items-center h-5 mr-1">
                                  <input
                                    id={item.uuid}
                                    data-testid={`${item.uuid}-checkbox`}
                                    type="checkbox"
                                    className={`form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out cursor-pointer  ${
                                      notFilterData.includes(item.uuid) ||
                                      andNotFilterData.includes(item.uuid)
                                        ? 'litlingo-checkbox-negated'
                                        : ''
                                    }`}
                                    checked={
                                      filterData.includes(item.uuid) ||
                                      notFilterData.includes(item.uuid) ||
                                      andNotFilterData.includes(item.uuid) ||
                                      andFilterData.includes(item.uuid)
                                    }
                                  />
                                </div>
                                {colorChip && (
                                  <div
                                    className="rounded-full min-w-3 min-h-3 mr-2"
                                    style={{ backgroundColor: item.color || '' }}
                                  />
                                )}
                                <div className="text-sm leading-5">
                                  <label
                                    htmlFor={item.uuid}
                                    className="text-litlingo-gray-600 text-sm cursor-pointer"
                                  >
                                    <span className="text-gray-700 select-none break-all">
                                      {item[nameKey]}{' '}
                                      {item.envelopes_count != null
                                        ? `(${item.envelopes_count})`
                                        : ''}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            {ruleOutcome && (
                              <span className="text-xs text-gray-500 select-none break-all">
                                {ruleOutcome.campaign?.name}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </fieldset>
            </div>
          </LoadingOverlayWrapper>
          {allowExclude && isOpen && (
            <div className="flex self-end mt-2 -mb-2">
              <span className="text-white font-normal text-xss">Shift + click to exclude</span>
            </div>
          )}
        </>
      )}
      {!isOpen && (
        <ShowLabels
          handleDelete={handleDelete}
          getResourceList={getResourceList}
          filterData={typeof filterData === 'string' ? [filterData] : filterData}
          notFilterData={notFilterData as string[]}
          andFilterData={andFilterData as string[]}
          andNotFilterData={andNotFilterData as string[]}
          nameKey={nameKey}
        />
      )}
    </div>
  );
};

export default ResourceFilter;
