import { removeRuleOutcome, updateCampaignRuleOutcomeConfig } from 'actions';
import EmptyTableState from 'components/EmptyTableState';
import RenderJsonEditorModal from 'components/OutcomeTable/RenderJsonEditorModal';
import ToggleButton from 'components/ToggleButton';
import { ADJUSTMENT_ICON } from 'constants/envelopeIcons';
import platform, { additionalSettings } from 'constants/platform';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCustomerDomain } from 'selectors/auth';
import { getRuleOutcomes } from 'selectors/outcomes';
import { useSelector } from 'store';
import type { CampaignRuleOutcome, UUID } from 'types';
import { useHistory } from 'utils/urls';

type ComponentProps = {
  ruleId: UUID;
};

const OutcomeTable: React.FC<ComponentProps> = ({ ruleId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [currentConfig, setCurrentConfig] = useState<CampaignRuleOutcome['config'] | null>(null);
  const [currentId, setCurrentId] = useState<string | null>(null);
  const customerDomain = useSelector(getCustomerDomain);
  const ruleOutcomes = useSelector((state) =>
    getRuleOutcomes(state, ruleId)
  ) as CampaignRuleOutcome[];

  const handleDelete = (ruleOutcome: CampaignRuleOutcome): void => {
    dispatch(
      removeRuleOutcome({
        outcomeId: ruleOutcome.outcome_uuid,
        ruleId: ruleOutcome.rule_uuid,
        campaignId: ruleOutcome.campaign_uuid,
      })
    );
  };

  const upsertRuleOutcome = (
    uuid: UUID,
    key: string,
    config: CampaignRuleOutcome['config']
  ): void => {
    const newConfig = {
      ...config,
      [key]: !config[key as keyof CampaignRuleOutcome['config']],
    };

    dispatch(updateCampaignRuleOutcomeConfig({ uuid, config: newConfig }));
  };

  const extendedPlatforms = { ...platform, ...additionalSettings };

  const handleAdvanced = (config: CampaignRuleOutcome['config'], uuid: string): void => {
    setCurrentConfig(config);
    setOpenModal(true);
    setCurrentId(uuid);
  };

  return (
    <div className="mt-px text-left">
      <div className="table-wrapper">
        <table className="table-wrapper__table">
          <thead>
            <tr>
              <th className="table-wrapper__th">Name</th>
              <th className="table-wrapper__th">Recipient Type</th>
              {Object.values(extendedPlatforms).map((value) => (
                <th key={value} className="table-wrapper__th">
                  {value}
                </th>
              ))}
              <th aria-label="Action" className="table-wrapper__th" />
              <th aria-label="Action" className="table-wrapper__th" />
            </tr>
          </thead>
          <tbody className="table-wrapper__tbody">
            {ruleOutcomes.length === 0 && (
              <EmptyTableState message="There are no outcomes to display" colCount="6" />
            )}
            {ruleOutcomes.map((ruleOutcome) => (
              <tr key={ruleOutcome.uuid} className="border-t border-gray-200 hover:bg-gray-100">
                <td className="table-wrapper__td">
                  <div className="flex items-center">
                    <div className="">
                      <div className="text">{ruleOutcome.outcome?.name}</div>
                    </div>
                  </div>
                </td>
                <td className="table-wrapper__td">
                  <div className="flex items-center">
                    <div className="">
                      <div className="text">{ruleOutcome.outcome?.type}</div>
                    </div>
                  </div>
                </td>
                {Object.keys(extendedPlatforms).map((key) => (
                  <td key={key} className="table-wrapper__td">
                    <div className="flex items-center">
                      <div className="">
                        <div className="text">
                          <ToggleButton
                            dataTestid={`${key}-toggle-button`}
                            // @ts-ignore
                            isOn={
                              ruleOutcome && ruleOutcome.config
                                ? ruleOutcome.config[key as keyof CampaignRuleOutcome['config']]
                                : false
                            }
                            handleToggleClick={(): void => {
                              upsertRuleOutcome(ruleOutcome.uuid, key, ruleOutcome.config);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                ))}

                <td className="table-wrapper__td">
                  <div className="flex items-center">
                    <div className="">
                      <div className="text">
                        {ruleOutcome.config?.human_verification_config != null ? (
                          <button
                            type="button"
                            onClick={(): void => {
                              handleAdvanced(ruleOutcome.config, ruleOutcome.uuid);
                            }}
                          >
                            {ADJUSTMENT_ICON}
                          </button>
                        ) : (
                          <span className="text text--italic">NA</span>
                        )}
                      </div>
                    </div>
                  </div>
                </td>

                <td className="lg:table-wrapper__td">
                  <div className="flex justify-between">
                    <button
                      onClick={(): void => {
                        history.pushLookup({
                          routeName: 'rule-campaign-outcome-id',
                          routeParams: {
                            outcomeId: ruleOutcome.outcome_uuid,
                            campaignId: ruleOutcome.campaign_uuid,
                            ruleId: ruleOutcome.rule_uuid,
                          },
                          customerDomain,
                        });
                      }}
                      type="button"
                      className="lg:mr-4"
                    >
                      <svg
                        fill="none"
                        className="h-4 w-4 text-gray-700 hover:text-gray-900 text-xs"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                      </svg>
                    </button>
                    {ruleOutcomes.length > 1 && (
                      <button
                        type="button"
                        className="lg:mr-4"
                        onClick={(): void => handleDelete(ruleOutcome)}
                      >
                        <svg
                          className="mr-1.5 h-4 w-4 text-gray-700  hover:text-black"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {openModal && (
        <RenderJsonEditorModal
          setOpenModal={setOpenModal}
          setCurrentConfig={setCurrentConfig}
          currentConfig={currentConfig}
          currentId={currentId}
        />
      )}
    </div>
  );
};

export default OutcomeTable;
