import Badge from 'components/Badge';
import React from 'react';
import { SavedSearch } from 'types';

type ComponentProps = {
  searches: SavedSearch[];
  className?: string;
  handleDelete?: (search: SavedSearch) => void;
};

const SavedSearchLabels: React.FC<ComponentProps> = ({ searches, className, handleDelete }) => (
  <div className={`flex flex-row items-center ${className}`}>
    {searches &&
      searches.length > 0 &&
      searches.map((search) => (
        <div key={search.uuid} className="mx-1">
          <Badge
            rounded={false}
            title={search.name || ''}
            colorClass="#A3E1C1"
            deleteAction={handleDelete ? (): void => handleDelete(search) : undefined}
          />
        </div>
      ))}
  </div>
);

export default SavedSearchLabels;
