export const customersEmails = ['litlingo.com', 'meltstudio.co'];

export const whiteListCustomersUuids = [
  'a659b252-ec51-4008-bb1f-2ae3779f3a82',
  'bc88d774-b2fe-4264-8fea-eb79290a4750',
  'd2432204-b146-4093-a67a-87b6ca3f2e1b',
  '7c165096-f37d-449c-83ec-2575df6a244f',
  '57579a22-b734-4bc7-96c3-3e5b5713b79f',
  '1997c7b6-61af-4f10-a9a1-f80d50d8250c',
];
