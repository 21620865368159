import React, { Fragment } from 'react';
import type { AnnotatedSuperNodeT, ProtectedFields } from '../types';
import { remapNodesHighlighted } from '../utils/annotations';
import { superNodeNeedsSpaceBefore } from '../utils/spacing';
import HighlightedSuperNode from './HighlightedSuperNode';

type HighlightedSentenceProps = {
  nodes: AnnotatedSuperNodeT[];
  useHover: boolean;
  protectedFields?: ProtectedFields;
  portal?: string;
  hideHighlight?: boolean;
  ruleId?: string;
  blockHighlight?: boolean;
  borderSeparation?: string;
  modelTransparencyMode?: boolean;
  highlightSentenceOnCLickHandle?: () => void;
};

const HighlightedSentence: React.FC<HighlightedSentenceProps> = (props) => {
  const {
    nodes,
    useHover,
    protectedFields,
    portal,
    hideHighlight = false,
    ruleId,
    blockHighlight = false,
    modelTransparencyMode = false,
    borderSeparation = 'pl-1',
    highlightSentenceOnCLickHandle,
  } = props;

  const memoizedNodes = React.useMemo<AnnotatedSuperNodeT[]>(
    () => remapNodesHighlighted(nodes),
    [nodes]
  );

  const containerClass = (): string => {
    const className = `${borderSeparation} pr-5`;
    return className;
  };

  const renderSpace = (
    superNode: AnnotatedSuperNodeT,
    idx: number
  ): JSX.Element => {
    if (
      blockHighlight &&
      superNode.sentenceHighlight &&
      superNodeNeedsSpaceBefore(nodes, idx)
    ) {
      let background = '';
      if (superNode.sentenceHighlight === 'highlight') {
        background = 'bg-litlingo-highlight';
      }
      if (superNode.sentenceHighlight === 'underline') {
        background = 'bg-litlingo-gray-1';
      }

      if (modelTransparencyMode) {
        if (superNode.annotations?.some((h) => h.highlight)) {
          background = 'bg-litlingo-highlight';
        } else {
          background = 'bg-litlingo-gray-1';
        }
      }

      return <span className={`inline-block ${background}`}>{'\u00A0'}</span>;
    }
    if (superNodeNeedsSpaceBefore(nodes, idx)) return <> </>;

    return <></>;
  };

  const getSentenceHighlight = (superNode: AnnotatedSuperNodeT): string => {
    if (modelTransparencyMode) {
      if (superNode.annotations?.some((h) => h.highlight)) {
        return 'highlight';
      }
      if (superNode.sentenceHighlight) {
        return 'underline';
      }
      return '';
    }
    if (blockHighlight) return superNode.sentenceHighlight || '';

    return '';
  };

  return (
    <div className={containerClass()}>
      {memoizedNodes.map((superNode, idx) => (
        <Fragment key={superNode.index}>
          {renderSpace(superNode, idx)}
          <HighlightedSuperNode
            nodes={superNode.nodes}
            annotations={superNode.annotations}
            useHover={useHover}
            protectedFields={protectedFields}
            portal={portal}
            hideHighlight={hideHighlight || blockHighlight}
            ruleId={ruleId}
            sentenceHighlight={getSentenceHighlight(superNode)}
            highlightSentenceOnCLickHandle={highlightSentenceOnCLickHandle}
          />
        </Fragment>
      ))}
    </div>
  );
};

HighlightedSentence.defaultProps = {
  protectedFields: { entity: 'FIELD_IS_HIDDEN' },
};

export default HighlightedSentence;
