import Modal from 'components/Modal';
import { MODAL_BACKGROUND } from 'constants/common';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'utils/urls';

type ComponentProps = {
  shouldBlockNavigation?: boolean;
  setShouldBlockNavigation: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  text?: string;
  navigateButtontext?: string;
  cancelOrActionNavigateButtontext?: string;
  navigateAction?: () => void;
  cancelAction: () => void;
  navigateBack?: boolean;
};

const GoBackLeavePageModal: React.FC<ComponentProps> = (props) => {
  const {
    shouldBlockNavigation,
    setShouldBlockNavigation,
    title,
    text,
    navigateButtontext,
    cancelOrActionNavigateButtontext,
    navigateAction,
    cancelAction,
    navigateBack = true,
  } = props;

  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (shouldBlockNavigation) {
      setIsModalOpen(true);
    }
  }, [shouldBlockNavigation]);

  const toggleShowModal = (): void => {
    setIsModalOpen(!isModalOpen);
    setShouldBlockNavigation(false);
  };

  const handleOkButtonClick = (): void => {
    if (navigateAction) {
      navigateAction();
    }

    setIsModalOpen(false);
    setShouldBlockNavigation(false);
    if (navigateBack) {
      history.goBack();
    }
  };

  const handleCancelButtonClick = (): void => {
    setIsModalOpen(false);
    setShouldBlockNavigation(false);
    if (cancelAction) {
      cancelAction();
    }

    history.goBack();
  };

  const renderBody = (): JSX.Element => (
    <>
      <div className="absolute top-5 left-0 right-0 h-40 z-0">{MODAL_BACKGROUND}</div>

      <div className="flex flex-col mt-30 mb-8 gap-2">
        <h2 className="text-heading-1">{title || 'You Have Unsaved Changes'}</h2>
        <p className="text-body">{text || 'Are you sure you want to leave without saving?'}</p>
      </div>
    </>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          title=" "
          body={renderBody()}
          okButton
          okButtonText={cancelOrActionNavigateButtontext || 'Go back'}
          okButtonOnClick={handleOkButtonClick}
          okButtonStyle="whitespace-no-wrap w-full px-4 mr-4"
          cancelButtonText={navigateButtontext || 'Discard & Exit'}
          cancelButtonOnclick={handleCancelButtonClick}
          toggleShowModal={toggleShowModal}
        />
      )}
    </>
  );
};

export default GoBackLeavePageModal;
