import { fetchAllEventsCount, fetchCampaignsPerformance, fetchCommsByPlatform } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import Permissions from 'components/Permissions';
import PermissionLink from 'components/PermissionsLink';
import withWidget from 'decorators/widget';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getAllEventsCount,
  getAllEventsLoading,
  getCampaignsPerformanceLoading,
  getCommsByPlatLoading,
  getSingleCampaignTotalCount,
  getTotalComms,
} from 'selectors/analytics';
import { useSelector } from 'store';
import { useTimeRange } from 'utils/timeRanges';

const TYPE_1_CAMPAIGN_UUID = '0366fc5f-21e9-48e3-886d-ba391e31dbf2';
const TYPE_2_CAMPAIGN_UUID = '71e7388b-8be3-49e7-a2dd-8a5fdf5701e9';
const ActivityForGpac: React.FC = () => {
  const dispatch = useDispatch();

  const commsByPlatLoading = useSelector(getCommsByPlatLoading);
  const totalCommsCount = useSelector(getTotalComms);

  const totalCount = useSelector(getAllEventsCount);
  const totalLoading = useSelector(getAllEventsLoading);

  const campaignPerformanceLoading = useSelector(getCampaignsPerformanceLoading);

  const type1TotalCount = useSelector((state) =>
    getSingleCampaignTotalCount(state, TYPE_1_CAMPAIGN_UUID)
  );

  const type2TotalCount = useSelector((state) =>
    getSingleCampaignTotalCount(state, TYPE_2_CAMPAIGN_UUID)
  );

  const range = useTimeRange();

  useEffect(() => {
    dispatch(fetchAllEventsCount(range));
    dispatch(fetchCommsByPlatform(range));
    dispatch(fetchCampaignsPerformance({ limit: 6, ...range }));
  }, [dispatch, range]);

  const spinner = (
    <div className="h-8">
      <LoadingIndicator className="ml-2" size="5" />
    </div>
  );

  return (
    <div className="grid grid-cols-4">
      <div className="flex items-center">
        <dl>
          <dt className="text text--left text--label">Messages</dt>
          <dd className="items-baseline">
            <div className="flex items-baseline text-2xl leading-8 text font-bold">
              {commsByPlatLoading ? spinner : totalCommsCount}
            </div>
          </dd>
        </dl>
      </div>

      <div className="flex items-center">
        <dl>
          <dt className="text text--left text--label">Events</dt>
          <dd className="items-baseline">
            <PermissionLink
              action="events.list"
              routeName="envelope-list"
              className="hover:underline text"
              queryParams={{
                envelopes__created_before: range.created_before || null,
                envelopes__created_after: range.created_after || null,
              }}
            >
              <div className="flex items-baseline text-2xl leading-8 text font-bold">
                {totalLoading ? spinner : totalCount}
              </div>
            </PermissionLink>
          </dd>
        </dl>
      </div>

      <div className="flex items-center">
        <dl>
          <dt className="text text--left text--label">Type 1 Matches</dt>
          <div className="flex flex-row items-baseline">
            <dd className="items-baseline">
              <PermissionLink
                action="events.list"
                routeName="envelope-list"
                className="hover:underline text"
                queryParams={{
                  envelopes__created_before: range.created_before || null,
                  envelopes__created_after: range.created_after || null,
                  envelopes__campaign_uuids: TYPE_1_CAMPAIGN_UUID,
                }}
              >
                <div className="flex items-baseline text-2xl leading-8 text font-bold">
                  {totalLoading ? spinner : type1TotalCount}
                </div>
              </PermissionLink>
            </dd>
            <div className="flex flex-row">
              <PermissionLink
                action="events.list"
                routeName="envelope-list"
                queryParams={{
                  envelopes__campaign_uuids: TYPE_1_CAMPAIGN_UUID,
                  envelopes__created_before: range.created_before || null,
                  envelopes__created_after: range.created_after || null,
                  envelopes__states: 'NEW',
                }}
                className="bg-white border-0 focus:outline-none font-medium hover:text-blue-500 inline-flex items-left text text--primary"
              >
                <Permissions action="events.list">
                  {!totalLoading && <span className="pl-1">Review</span>}
                </Permissions>
              </PermissionLink>
            </div>
          </div>
        </dl>
      </div>

      <div className="flex items-center">
        <dl>
          <dt className="text text--left text--label">Type 2 Matches</dt>
          <div className="flex flex-row items-baseline">
            <dd className="items-baseline">
              <PermissionLink
                action="events.list"
                routeName="envelope-list"
                className="hover:underline text"
                queryParams={{
                  envelopes__created_before: range.created_before || null,
                  envelopes__created_after: range.created_after || null,
                  envelopes__campaign_uuids: TYPE_2_CAMPAIGN_UUID,
                }}
              >
                <div className="flex items-baseline text-2xl leading-8 text font-bold">
                  {campaignPerformanceLoading ? spinner : type2TotalCount}
                </div>
              </PermissionLink>
            </dd>
            <div className="flex flex-row">
              <PermissionLink
                action="events.list"
                routeName="envelope-list"
                queryParams={{
                  envelopes__campaign_uuids: TYPE_2_CAMPAIGN_UUID,
                  envelopes__created_before: range.created_before || null,
                  envelopes__created_after: range.created_after || null,
                  envelopes__states: 'NEW',
                }}
                className="bg-white border-0 focus:outline-none font-medium hover:text-blue-500 inline-flex items-left text text--primary"
              >
                <Permissions action="events.list">
                  {!totalLoading && <span className="pl-1">Review</span>}
                </Permissions>
              </PermissionLink>
            </div>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default withWidget({
  title: 'Activity',
  minWidth: 9,
  minHeight: 6,
})(ActivityForGpac);
