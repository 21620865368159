import { createOutcome, fetchOutcomeTypes } from 'actions';
import CampaignRuleOutcomesTable from 'components/CampaignRuleOutcomesTable';
import CollapsableCard from 'components/CollapsableCard';
import Navbar from 'components/Navbar';
import OutcomeConfirmModal from 'components/Outcome/OutcomeConfirmModal';
import Preview from 'components/Outcome/Preview';
import RuleOutcomeForm from 'components/Outcome/RuleOutcomeForm';
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getCampaignRuleOutcomesList } from 'selectors/campaignRuleOutcomes';
import { getOutcomeTypes, getSingleOutcome } from 'selectors/outcomes';
import type { OutcomeTypeKey, UUID } from 'types';

export const NEW_OUTCOME_UUID = 'new';

type RouteParams = {
  campaignId: UUID;
  outcomeId: UUID;
};

const OutcomeStarship: React.FC = () => {
  const history = useHistory();
  const { outcomeId } = useParams<RouteParams>();
  const outcome = useSelector(getSingleOutcome(outcomeId));
  const outcomes = useSelector(getCampaignRuleOutcomesList);
  const dispatch = useDispatch();
  const methods = useForm();
  const outcomeTypes = useSelector(getOutcomeTypes) || {};
  const [typeSelection, setTypeSelection] = useState('echo');
  const [errors, setErrors] = useState<{
    [key: string]: {
      message: string;
    };
  }>({});
  const [markDownContent, setMarkDownContent] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);

  const handleSaveClick = async (): Promise<void> => {
    const name: string = methods.getValues('name');
    const clickErrors: { [name: string]: { message: string } } = {};
    const type = typeSelection;
    if (!name || !type) {
      clickErrors[name] = {
        message: 'Field is required',
      };
    }
    let config: { [key: string]: string } = {};
    const typeItem = outcomeTypes[typeSelection] || {};

    Object.keys(typeItem).forEach((key) => {
      let value = methods.getValues(key);

      // FIXME: THIS IS A MESS, use CustomField component instead, that will
      // handle this automatically
      const typeItemValue = typeItem[key as OutcomeTypeKey];
      if (typeItemValue != null) {
        // @ts-ignore
        if (typeItemValue.type !== 'bool') {
          if (!value || !value.length) {
            if ('required' in typeItemValue && typeItemValue.required) {
              clickErrors[key] = { message: 'Field is required' };
            }
            setModalOpen(false);
            return;
          }
          if ('is_list' in typeItemValue && typeItemValue.is_list) {
            value = value.trim().split(',');
          }
        }
      }

      if (key === 'recipients') {
        value = JSON.parse(value);
      }

      config = {
        ...config,
        [key]: value,
      };
    });
    // @ts-ignore
    config = { highlights: [], ...config };
    if (Object.keys(clickErrors).length > 0) {
      setErrors(clickErrors);
      setModalOpen(false);
      return;
    }
    const uuid = outcomeId === NEW_OUTCOME_UUID ? undefined : outcomeId;
    const formData = {
      name,
      type,
      config,
      uuid,
    };
    dispatch(createOutcome({ formData }));
  };

  useEffect(() => {
    dispatch(fetchOutcomeTypes());
  }, [dispatch]);

  useEffect(() => {
    if (!outcome) return;
    methods.setValue('name', outcome.name);
    setTypeSelection(outcome.type);
    setMarkDownContent(outcome.config?.template);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outcome]);

  return (
    <>
      <div className="min-h-screen min-w-screen bg-white">
        <Navbar />
        <div className="py-10">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormContext {...methods}>
            <form>
              <header>
                <div className="max-w-7xl mx-auto px-1 sm:px-6 lg:px-8">
                  <div>
                    <div className="mt-2 md:flex-col md:items-center md:justify-between">
                      <div className="flex justify-between">
                        <div className="flex items-center">
                          <h2 className="title">
                            {methods && methods.watch && methods.watch('name')}
                          </h2>
                        </div>
                        <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
                          <span className="shadow-sm rounded-md">
                            <button
                              onClick={(): void => history.goBack()}
                              type="button"
                              data-testid="back-outcome-button"
                              className="inline-flex items-center px-4 py-2 border border-gray-300 text rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                            >
                              Back to Outcome Manager
                            </button>
                          </span>
                          <span className="ml-3 shadow-sm rounded-md">
                            <button
                              data-testid="outcome-save-button"
                              onClick={(): void => {
                                if (outcomeId !== NEW_OUTCOME_UUID && outcomes.length > 1) {
                                  setModalOpen(true);
                                } else {
                                  handleSaveClick();
                                }
                              }}
                              type="button"
                              className="button button--primary"
                            >
                              Save
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <CollapsableCard
                        headerTitle="Setup"
                        containerClasses="mt-10 mr-3 w-1/2 h-full"
                      >
                        <div className="p-4">
                          <RuleOutcomeForm
                            outcomeTypes={outcomeTypes}
                            typeSelection={typeSelection}
                            outcome={outcome}
                            setMarkDownContent={setMarkDownContent}
                            setTypeSelection={setTypeSelection}
                            errors={errors}
                            setErrors={setErrors}
                          />
                        </div>
                      </CollapsableCard>
                      <CollapsableCard
                        headerTitle="Preview"
                        containerClasses="mt-10 ml-3 w-1/2 h-full"
                      >
                        <div className="p-4">
                          <Preview
                            outcomeTypes={outcomeTypes}
                            typeSelection={typeSelection}
                            markDownContent={markDownContent}
                          />
                        </div>
                      </CollapsableCard>
                    </div>
                  </div>
                </div>
              </header>
              <main className="px-8 mt-8">
                {outcomeId !== NEW_OUTCOME_UUID && (
                  <CampaignRuleOutcomesTable entity="outcome_uuids" value={outcomeId} />
                )}
              </main>
            </form>
          </FormContext>
        </div>
      </div>
      {isModalOpen && outcomes.length > 1 && (
        <OutcomeConfirmModal
          countOutcomes={outcomes.length}
          okButtonOnClick={handleSaveClick}
          toggleShowModal={(): void => setModalOpen(!isModalOpen)}
        />
      )}
    </>
  );
};

export default OutcomeStarship;
