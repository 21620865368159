import { litlingoPatentUrl } from 'constants/dashboard';
import { CHEVRON_LEFT } from 'constants/filterIcons';
import React, { useRef, useState } from 'react';
import logEvent from 'utils/analytics';

type ComponentProps = {
  baseSidebar: JSX.Element;
  advancedSidebar?: JSX.Element;
  dashboardView?: boolean;
};

const GeneralSidebar: React.FC<ComponentProps> = (props) => {
  const { baseSidebar, advancedSidebar, dashboardView } = props;

  const [collapsed, setCollapsed] = useState(false);
  const [isAdvanceFilter, setIsAdvanceFilter] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <button
        type="button"
        data-testid="collapse-button"
        className={`absolute flex items-center justify-center min-w-4 min-h-7 ${
          isAdvanceFilter ? 'bg-litlingo-gray-6' : ''
        } ${
          collapsed ? 'bg-litlingo-primary-140' : ''
        } rounded-br transition-all duration-300 z-100 focus:outline-none`}
        onClick={(): void => {
          logEvent('events-filters-collapse-sidebar');
          setCollapsed(!collapsed);
        }}
        style={{
          left: collapsed ? 0 : 224,
        }}
      >
        <span
          className={`transition-transform duration-300 ${collapsed ? 'transform rotate-180' : ''}`}
        >
          {CHEVRON_LEFT}
        </span>
      </button>
      <div className="flex flex-col">
        <div
          ref={ref}
          data-testid="sidebar-container"
          className="relative h-full flex flex-col bg-litlingo-gray-6 text-white overflow-y-scroll no-scrollbar transition-all duration-300"
          style={{
            minWidth: 250,
            maxWidth: 250,
            marginLeft: collapsed ? '-250px' : '',
          }}
        >
          <div
            className={`absolute top-0 w-full min-h-full h-auto pt-8 pb-4 px-4 bg-litlingo-primary-140 z-20 transition-all duration-300 flex flex-col justify-between ${
              isAdvanceFilter ? '-left-full' : 'left-0'
            }`}
            style={{
              minWidth: 250,
              maxWidth: 250,
            }}
          >
            {React.createElement(baseSidebar.type, { ...baseSidebar.props, setIsAdvanceFilter })}
            {dashboardView && (
              <div className="mt-10 w-full flex flex-row gap-1 text-body z-100">
                <span className="text-litlingo-white">Patent</span>
                <a
                  target="_blank"
                  href={litlingoPatentUrl}
                  rel="noreferrer"
                  className="text-litlingo-white underline focus:outline-none"
                >
                  litlingo.com/patents
                </a>
              </div>
            )}
          </div>

          {isAdvanceFilter && advancedSidebar && (
            <div className="py-8 px-4 z-10 bg-litlingo-gray-6">
              {React.createElement(advancedSidebar.type, {
                ...advancedSidebar.props,
                setIsAdvanceFilter,
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GeneralSidebar;
