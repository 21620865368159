import {
  deleteIdentity,
  deleteIdentityFailure,
  deleteIdentityFulfill,
  deleteIdentityRequest,
  deleteIdentitySuccess,
  fetchIdentities,
  fetchIdentitiesFailure,
  fetchIdentitiesFulfill,
  fetchIdentitiesRequest,
  fetchIdentitiesSuccess,
  showSuccessAlert,
  upsertIdentity,
  upsertIdentityFailure,
  upsertIdentityFulfill,
  upsertIdentityRequest,
  upsertIdentitySuccess,
} from 'actions';
import { accountsClient as LitlingoAccountsClient } from 'client';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getNavParamsByResource } from 'selectors/nav';
import type { AccountsAPI, SagaReturn } from 'types';

// eslint-disable-next-line camelcase
function* fetchIdentitiesListSaga(action: ReturnType<typeof fetchIdentities>): SagaReturn {
  const { payload } = action;
  yield put(fetchIdentitiesRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.identity)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = { include_count: true, ...resourceParams, ...payload };

  const response = (yield call([LitlingoAccountsClient.resources.identities, 'list'], {
    params,
  })) as AccountsAPI.Response<AccountsAPI.Identities.List>;
  if (response.error != null) {
    yield put(fetchIdentitiesFailure(response.error));
  } else {
    yield put(fetchIdentitiesSuccess(response.data));
  }
  yield put(fetchIdentitiesFulfill());
}

function* upsertIdentitySaga(action: ReturnType<typeof upsertIdentity>): SagaReturn {
  const { payload } = action;
  yield put(upsertIdentityRequest());

  const response = (yield call([LitlingoAccountsClient.resources.identities, 'upsert'], {
    data: payload,
  })) as AccountsAPI.Response<AccountsAPI.Identities.Upsert>;
  if (response.error) {
    yield put(upsertIdentityFailure(response.error));
  } else {
    yield put(upsertIdentitySuccess(response.data));
    yield put(showSuccessAlert('Identity Saved'));
  }
  yield put(upsertIdentityFulfill());
}

function* deleteIdentitySaga(action: ReturnType<typeof deleteIdentity>): SagaReturn {
  const { payload } = action;
  yield put(deleteIdentityRequest());

  const response = (yield call(
    [LitlingoAccountsClient.resources.identities, 'delete'],
    payload.uuid
  )) as AccountsAPI.Response<AccountsAPI.Identities.Delete>;
  if (response.error != null) {
    yield put(deleteIdentityFailure(response.error));
  } else {
    yield put(deleteIdentitySuccess(payload.uuid));
  }
  yield put(deleteIdentityFulfill());
}

function* identitiesSaga(): SagaReturn {
  yield takeLatest(upsertIdentity.toString(), upsertIdentitySaga);
  yield takeLatest(deleteIdentity.toString(), deleteIdentitySaga);
  yield takeLatest(fetchIdentities.toString(), fetchIdentitiesListSaga);
}

export default identitiesSaga;
