import Badge from 'components/Badge';
import React from 'react';
import { Campaign } from 'types';

type ComponentProps = {
  campaigns: Campaign[];
  className?: string;
  handleDelete: (team: Campaign) => void;
};

const CampaignLabels: React.FC<ComponentProps> = ({ campaigns, className, handleDelete }) => (
  <div className={`flex flex-row content-start overflow-auto ${className}`}>
    {campaigns &&
      campaigns.length > 0 &&
      campaigns.map((campaign) => (
        <div key={campaign.uuid} className="mx-1 mt-1">
          <Badge
            rounded={false}
            title={campaign.name || ''}
            colorClass="#A3E1C1"
            deleteAction={(): void => handleDelete(campaign)}
          />
        </div>
      ))}
  </div>
);

export default CampaignLabels;
