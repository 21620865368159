import {
  addUserToSavedSearch,
  fetchTagGroups,
  openMessagesModal,
  openSubsamplesModal,
  removeUserFromSavedSearch,
} from 'actions';
import { NEW_EDIT_ICON } from 'constants/commonIcons';

import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MessagesModal from 'components/EnvelopeList/FilterPills/MessagesModal';
import SubsamplesModal from 'components/Sample/SubsamplesModal';
import DigestUserTable from 'components/SavedSearchesManager/Sidebar/DigestUserTable';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useTree from 'hooks/envelopes/useTree';
import { cloneDeep } from 'lodash';
import { getUser } from 'selectors/auth';
import {
  getMessagesModalOpen,
  getSelectedReviewSet,
  getSubsamplesModalOpen,
} from 'selectors/savedSearches';
import { RouteParams, User } from 'types';
import {
  buildFSFromParams,
  fillDefaultTree,
  getAllFieldsValuesWithLabels,
  getNavParamsFromFilters,
  Operator,
  Tree,
} from 'utils/parserTree';
import { getParamsFromUrl } from 'utils/urls';
import { v4 } from 'uuid';
import ChangelogSection from './ChangelogSection';
import DetailsSection from './DetailsSection';
import FilterSection from './FilterSection';
import PriorityTags from './PriorityTags';

type ComponentProps = {
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
};
const InfoSidebar: React.FC<ComponentProps> = ({ setEditing }) => {
  const dispatch = useDispatch();

  const reviewSet = useSelector(getSelectedReviewSet);
  const user = useSelector(getUser);
  const subsamplesModalOpen = useSelector(getSubsamplesModalOpen);
  const isMessagesModalOpen = useSelector(getMessagesModalOpen);

  useEffect(() => {
    if (
      reviewSet &&
      reviewSet.config &&
      reviewSet.config.tag_values_groups_uuids &&
      reviewSet.config.tag_values_groups_uuids?.length > 0
    ) {
      dispatch(fetchTagGroups({ uuids: reviewSet.config.tag_values_groups_uuids }));
    }
  }, [dispatch, reviewSet]);

  let navParams: RouteParams = {};

  if (user.customer) {
    if (reviewSet) {
      navParams = getParamsFromUrl(
        reviewSet.url,
        resourceQueryParamName.envelopes,
        'envelope-list',
        user.customer?.config
      );
    }
  }
  const filterSearch = (navParams.filters_search ?? buildFSFromParams(navParams)) as string;

  const { data, error } = useTree(filterSearch, !!reviewSet?.uuid);
  let tree: Tree = {
    op: Operator.AND,
    data: [],
    id: v4(),
  };

  if (data && data.data.length > 0 && !error) {
    tree = fillDefaultTree(cloneDeep(data), getNavParamsFromFilters(filterSearch, navParams));
  }

  const treeParams = getAllFieldsValuesWithLabels(tree);

  const toggleSubsamplesModal = (): void => {
    dispatch(openSubsamplesModal());
  };
  const toggleMessagesModal = (): void => {
    dispatch(openMessagesModal());
  };

  const handleSelectUser = (us: User, checked: boolean): void => {
    if (reviewSet) {
      if (checked) {
        dispatch(addUserToSavedSearch({ user: us, savedSearchId: reviewSet.uuid }));
      } else {
        dispatch(removeUserFromSavedSearch({ user: us, savedSearchId: reviewSet.uuid }));
      }
    }
  };

  if (!reviewSet) return null;

  return (
    <>
      <div className="flex flex-col w-full h-full bg-white overflow-auto no-scrollbar">
        <div className="flex flex-col w-full p-8">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-1">
                <div className="flex flex-row gap-2">
                  <span className="text-heading-2 break-normal">{reviewSet.name}</span>
                  {reviewSet.category !== 'sample' && (
                    <button
                      type="button"
                      onClick={(): void => setEditing(true)}
                      className="w-5 focus:outline-none flex-none"
                    >
                      {NEW_EDIT_ICON('text-litlingo-primary-120')}
                    </button>
                  )}
                </div>
                <div className="flex flex-row items-end gap-2 h-5">
                  {reviewSet.priority && <PriorityTags type={reviewSet.priority} />}
                  <span className="text-small">
                    Last updated {moment(reviewSet.updated_at).format('MMMM YYYY')} by{' '}
                    {reviewSet.updated_by?.name || reviewSet.created_by?.name}
                  </span>
                </div>
              </div>
              <p className="text-body">{reviewSet.description}</p>
            </div>

            <FilterSection />

            <div className="mt-5 flex flex-col gap-4">
              <DetailsSection />
              <DigestUserTable
                reviewStream={reviewSet}
                users={reviewSet.users || []}
                handleSelectUser={handleSelectUser}
              />
            </div>
          </div>
        </div>
        <ChangelogSection />
      </div>

      {subsamplesModalOpen && (
        <SubsamplesModal
          isModalOpen={subsamplesModalOpen}
          toggleModalOpen={toggleSubsamplesModal}
          treeParams={treeParams}
          tree={tree}
        />
      )}
      {isMessagesModalOpen && (
        <MessagesModal
          isModalOpen={isMessagesModalOpen}
          toggleModalOpen={toggleMessagesModal}
          treeParams={treeParams}
          tree={tree}
        />
      )}
    </>
  );
};

export default InfoSidebar;
