import { REVIEW_SET_CHECK_ICON, REVIEW_SET_CLOCK_ICON } from 'constants/reviewSets';
import withWidget from 'decorators/widget';
import React from 'react';
import { DefaultWidgetComponentProps } from 'types';
import { buildStatisticDataFromSingleMetric } from 'utils/dashboard';

const SingleReviewSetMetric: React.FC<DefaultWidgetComponentProps> = (props) => {
  const { widgetData, queryData, icon } = props;

  if (widgetData == null || queryData == null) return <></>;

  const mapIcons = {
    review_set_check_icon: REVIEW_SET_CHECK_ICON,
    review_set_clock_icon: REVIEW_SET_CLOCK_ICON,
  };

  const processedData = buildStatisticDataFromSingleMetric(widgetData, queryData);

  return (
    <div className="p-0 border border-litlingo-gray-2 rounded grid items-center w-full h-full">
      {'data' in processedData && (
        <div className="flex flex-row items-center gap-6 h-full flex-auto px-6">
          {icon && <div className="w-10">{mapIcons[icon as keyof typeof mapIcons]}</div>}
          <div className="flex flex-col gap-1">
            <span className="text-heading-2">{`${processedData.data} ${processedData.label}`}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default withWidget({
  fetchOnePeriod: true,
})(SingleReviewSetMetric);
