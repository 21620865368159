import { clearURLParams } from 'actions';
import Campaignsfilter from 'components/Filters/CampaignsFilter';
import DateFilter from 'components/Filters/DateFilter';
import EventsListStateFilter from 'components/Filters/EventsListStateFilter';
import InboundEventsFilter from 'components/Filters/InboundEventsFilter';
import IsStarredFilter from 'components/Filters/IsStarredFilter';
import PlatformsFilter from 'components/Filters/PlatformsFilter';
import RuleFilter from 'components/Filters/RulesFilter';
import ShowEventsFilter from 'components/Filters/ShowEventsFilter';
import SliderFilter from 'components/Filters/SliderFilter';
import UserFilters from 'components/Filters/UsersFilter';
import LinkLookup from 'components/LinkLookup';
import Permissions from 'components/Permissions';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomersAllowFulTextSearch } from 'selectors/auth';
import SearchInput from '../SearchInput';
import IsReadFilter from './IsReadFilter';

type ComponentProps = {
  resource: string;
};

const EventsFilters: React.FC<ComponentProps> = ({ resource }) => {
  const dispatch = useDispatch();
  const handleResetFiltersClick = (): void => {
    dispatch(clearURLParams());
  };
  const showSearchBar = useSelector(getCustomersAllowFulTextSearch);

  return (
    <div className="flex flex-row justify-between relative z-20">
      <div className="flex flex-row flex-wrap max-w-7xl">
        <IsStarredFilter resource={resource} />
        <Campaignsfilter withExclude resource={resource} className="mr-1 mb-1" />
        <RuleFilter withExclude resource={resource} className="mr-4 mb-1" />
        <Permissions action="users.list">
          <UserFilters
            resource={resource}
            title="Sent by"
            resourceId="created_by"
            className="mr-1 mb-1"
          />
        </Permissions>
        <DateFilter resource={resource} className="mr-1 mb-1" />
        <EventsListStateFilter resource={resource} className="mr-1 mb-1" />
        <PlatformsFilter resource={resource} className="mr-1 mb-1" />

        <Permissions action="events.broadSearch">
          <ShowEventsFilter resource={resource} className="mr-1 mb-1" />
          {showSearchBar && (
            <div className="ml-2">
              <SearchInput resource={resource} field="broad_search" />
            </div>
          )}
        </Permissions>

        <InboundEventsFilter resource={resource} className="ml-1 mb-1" />
        <IsReadFilter resource={resource} className="ml-1 mb-1" />
        {resource === resourceQueryParamName.analytics && (
          <SliderFilter resource={resource} className="ml-4 mb-1" />
        )}
        <button
          data-testid="clear-filters-button"
          type="button"
          className="bg-white border-0 focus:outline-none font-medium inline-flex items-center leading-4 text text-litlingo-gray-6 hover:text-litlingo-gray-4 mb-1 px-3 py-2 underline"
          onClick={handleResetFiltersClick}
        >
          Clear Filters
        </button>
        <LinkLookup
          routeName="tags"
          className="bg-white border-0 focus:outline-none hover:text-blue-500 inline-flex items-center leading-4 text text-litlingo-info mb-1 px-3 py-2 underline"
        >
          <button data-testid="manage-tags-filters-button" type="button" className="font-medium">
            Manage Tags
          </button>
        </LinkLookup>
      </div>
    </div>
  );
};

export default EventsFilters;
