/* eslint-disable max-lines */
import React from 'react';

type ComponentProps = {
  includedLabels: { value: string; label: string }[];
  excludedLabels: { value: string; label: string }[];
  handleDelete: ({ label, value }: { label: string; value: string }) => void;
};

const Labels: React.FC<ComponentProps> = ({ includedLabels, excludedLabels, handleDelete }) => {
  const renderLabel = (included: boolean, label: string, value: string): JSX.Element => (
    <div
      key={value}
      className={`flex flex-row gap-1 items-center px-1 py-0.5 rounded ${
        included ? 'bg-litlingo-success-light' : 'bg-litlingo-alert-light'
      }`}
    >
      <span className="whitespace-no-wrap">{`${included ? '+' : '-'} ${label}`}</span>
      <button
        type="button"
        className="flex justify-center items-center bg-litlingo-gray-3 w-2.5 h-2.5 text-2xs rounded-full focus:outline-none"
        onClick={(): void => handleDelete({ label, value })}
      >
        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.56006 1.56006L4.47206 4.47246"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.47206 1.56006L1.56006 4.47246"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );

  return (
    <div className="flex flex-row gap-1 self-end text-small flex-wrap">
      {includedLabels.map((l) => renderLabel(true, l.label, l.value))}
      {excludedLabels.map((l) => renderLabel(false, l.label, l.value))}
    </div>
  );
};

export default Labels;
