/* eslint-disable @typescript-eslint/ban-types */
import LinkLookup from 'components/LinkLookup';
import Permissions from 'components/Permissions';
import React from 'react';

type ComponentProps = {
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
  action: string;
  routeName: string;
  routeParams?: {};
  queryParams?: string | {};
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  target?: string;
};

const PermissionsLink: React.FC<ComponentProps> = (props) => {
  const { children, className, action, routeName, routeParams, queryParams, onClick, target } =
    props;

  const renderLink = (authorized: boolean): string | JSX.Element | JSX.Element[] =>
    authorized ? (
      <LinkLookup
        className={className || ''}
        routeName={routeName}
        routeParams={routeParams || {}}
        queryParams={queryParams || {}}
        onClick={onClick}
        target={target || '_self'}
      >
        {children}
      </LinkLookup>
    ) : (
      children
    );

  return <Permissions action={action}>{renderLink}</Permissions>;
};

export default PermissionsLink;
