import { createAction } from '@reduxjs/toolkit';
import type {
  API,
  ErrorObject,
  MOutcome,
  NormalizedResource,
  Outcome,
  OutcomeTypes,
  UUID,
} from 'types';

export type CreateOutcomePayload = {
  formData: {
    name: string;
    type: string;
    // FIXME: finde proper type for this
    config: { [key: string]: string };
    uuid?: UUID;
  };
};

export const createOutcome = createAction<CreateOutcomePayload>('OUTCOME/CREATE_OUTCOME');
export const createOutcomeRequest = createAction('OUTCOME/CREATE_OUTCOME_REQUEST');
export const createOutcomeSuccess =
  createAction<NormalizedResource<MOutcome>>('CREATE_OUTCOME_SUCCESS');
export const createOutcomeFailure = createAction<ErrorObject>('OUTCOME/CREATE_OUTCOME_FAILURE');

type FetchOutcomesPayload = {
  searchTerm?: { name?: string };
};
export const fetchOutcomes = createAction<FetchOutcomesPayload>('OUTCOME/FETCH_OUTCOMES');
export const fetchOutcomesRequest = createAction('OUTCOMES/FETCH_REQUEST');
export const fetchOutcomesSuccess =
  createAction<API.WrappedAPIResponse<Outcome>>('OUTCOMES/FETCH_SUCCESS');
export const fetchOutcomesFailure = createAction<ErrorObject>('OUTCOMES/FETCH_FAILURE');

export type FetchSingleOutcomePayload = { outcomeId: UUID };

export const fetchSingleOutcome = createAction<FetchSingleOutcomePayload>('OUTCOME/FETCH_SINGLE');
export const fetchSingleOutcomeRequest = createAction('OUTCOME/FETCH_SINGLE_REQUEST');
export const fetchSingleOutcomeSuccess = createAction<Outcome>('OUTCOME/FETCH_SINGLE_SUCCESS');
export const fetchSingleOutcomeFailure = createAction<ErrorObject>('OUTCOME/FETCH_SINGLE_FAILURE');

export const fetchOutcomeTypes = createAction('OUTCOME/FETCH_OUTCOME_TYPES');
export const fetchOutcomeTypesRequest = createAction('OUTCOME/FETCH_OUTCOME_TYPES_REQUEST');
export const fetchOutcomeTypesSuccess = createAction<OutcomeTypes>(
  'OUTCOME/FETCH_OUTCOME_TYPES_SUCCESS'
);
export const fetchOutcomeTypesFailure = createAction<ErrorObject>(
  'OUTCOME/FETCH_OUTCOME_TYPES_FAILURE'
);

export const cleanOutcomes = createAction('OUTCOME/CLEAN_OUTCOMES');
export const removeOutcome = createAction<UUID>('OUTCOME/REMOVE_OUTCOME');

type UpdateOutcomePayload = {
  ruleId: UUID;
  campaignId: UUID;
  outcomeId: UUID;
};

export const updateOutcome = createAction<UpdateOutcomePayload>('OUTCOME/UPDATE_OUTCOME');
