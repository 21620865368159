import Badge from 'components/Badge';
import React from 'react';
import { User } from 'types';

type ComponentProps = {
  users: User[];
  className?: string;
  handleDelete?: (team: User) => void;
};

const UserLabels: React.FC<ComponentProps> = ({ users, className, handleDelete }) => (
  <div className={`flex flex-row items-center ${className}`}>
    {users &&
      users.length > 0 &&
      users.map((user) => (
        <div key={user.uuid} className="mx-1">
          <Badge
            rounded={false}
            title={user.name || ''}
            colorClass="#A3E1C1"
            deleteAction={handleDelete ? (): void => handleDelete(user) : undefined}
          />
        </div>
      ))}
  </div>
);

export default UserLabels;
