import { fetchEnvelopeEsDocs, fetchEnvelopeEsDocsAsIndexed } from 'actions';
import Permissions from 'components/Permissions';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

type EnvelopeJsonType = 'esDocs' | 'esDocsAsIndexed';

type ComponentProps = {
  toggleJsonModal: (type: EnvelopeJsonType | false) => void;
};

const EnvelopeEsDocs: React.FC<ComponentProps> = ({ toggleJsonModal }) => {
  const { envelopeId } = useParams<{ envelopeId: string }>();
  const dispatch = useDispatch();

  const handleClick = (type: EnvelopeJsonType): void => {
    if (type === 'esDocs') {
      dispatch(fetchEnvelopeEsDocs({ envelopeId }));
    } else {
      dispatch(fetchEnvelopeEsDocsAsIndexed({ envelopeId }));
    }

    toggleJsonModal(type);
  };

  return (
    <>
      <Permissions action="communications.viewCommunicationsAsJson">
        <div>
          <button
            type="button"
            data-testid="es-docs-button"
            className="text-litlingo-primary font-medium focus:outline-none"
            onClick={(): void => handleClick('esDocs')}
          >
            Generate View Es Docs
          </button>
        </div>
      </Permissions>
      <Permissions action="communications.viewCommunicationsAsJson">
        <div>
          <button
            type="button"
            data-testid="es-docs-indexed-button"
            className="text-litlingo-primary font-medium focus:outline-none"
            onClick={(): void => handleClick('esDocsAsIndexed')}
          >
            View Es Docs as indexed
          </button>
        </div>
      </Permissions>
    </>
  );
};

export default EnvelopeEsDocs;
