import useClickOutside from 'components/utils/useClickOutside';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

type ComponentProps = {
  containerRef: React.RefObject<HTMLElement>;
  defaultRange: DateRange;
  setShowCalendar: (value: boolean) => void;
  setDateRange: (range: DateRange) => void;
  dateOption: string;
};

const DateRangePicker: React.FC<ComponentProps> = (props) => {
  const { containerRef, defaultRange, setShowCalendar, setDateRange, dateOption } = props;

  const [range, setRange] = useState<DateRange | undefined>(defaultRange);

  const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(containerRef.current, popperRef, {
    placement: 'bottom-start',
  });

  useClickOutside({ current: popperRef }, () => setShowCalendar(false));

  useEffect(() => {
    setRange(defaultRange);
  }, [defaultRange]);

  if (!containerRef.current) return null;

  const handleApplyClick = (): void => {
    if (range) {
      setDateRange(range);
    }
    setShowCalendar(false);
  };

  const handleCancelClick = (): void => {
    setShowCalendar(false);
  };

  const handleSelectInSingleMonth = (day: Date): void => {
    if (dateOption === 'after') {
      setRange({ from: day, to: moment().utc().toDate() });
      setDateRange({ from: day, to: moment().utc().toDate() });
    } else {
      setRange({ from: moment(0).add(1, 'day').utc().toDate(), to: day });
      setDateRange({ from: moment(0).add(1, 'day').utc().toDate(), to: day });
    }
  };

  if (dateOption === 'date-range' && range && range.to && !range.from) {
    setRange((r) => ({ from: r?.to, to: undefined }));
  }

  const renderDatePicker = (): React.ReactPortal | null => {
    const portal = document.getElementById('dropdown-portal');

    const datePickerElement = (
      <div
        ref={(node): void => {
          setPopperRef(node);
        }}
        className="fixed flex flex-col gap-2 items-center min-w-54  px-3 pt-3 pb-2 mt-1 bg-white border border-litlingo-gray-2 rounded z-alert"
        style={{ ...styles.popper, filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))' }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes.popper}
        aria-hidden
        onClick={(e): void => e.stopPropagation()}
      >
        {dateOption === 'date-range' ? (
          <DayPicker
            mode="range"
            selected={range}
            onSelect={(dateRange: DateRange | undefined): void => {
              // the idea is to change the closest to the changed range
              // if there is no from or to we just set
              if (!dateRange?.from || !dateRange.to) {
                setRange(dateRange);
                return;
              }
              // if there is no from or to we just set
              if (!range?.from || !range.to) {
                setRange(dateRange);
                return;
              }

              // if we have both we set only the changed one to reset
              if (range?.from !== dateRange.from) {
                setRange({ from: dateRange.from, to: undefined });
              } else {
                setRange({ from: undefined, to: dateRange.to });
              }
            }}
            numberOfMonths={2}
            defaultMonth={moment(range?.from).startOf('month').toDate()}
          />
        ) : (
          <DayPicker
            mode="single"
            selected={dateOption === 'after' ? range?.from : range?.to}
            onSelect={(day: Date | undefined): void => {
              if (day) {
                handleSelectInSingleMonth(day);
              }
            }}
            numberOfMonths={1}
            defaultMonth={moment(dateOption === 'after' ? range?.from : range?.to)
              .startOf('month')
              .toDate()}
          />
        )}
        {dateOption === 'date-range' && (
          <div className="self-end flex flex-row gap-1">
            <button
              type="button"
              className="button button--secondary flex justify-center w-20 h-7"
              onClick={handleCancelClick}
            >
              <span className="font-bold">Cancel</span>
            </button>
            <button
              type="button"
              className="button button--primary flex justify-center w-20 h-7"
              onClick={handleApplyClick}
              disabled={!range?.from && !range?.to}
            >
              Apply
            </button>
          </div>
        )}
      </div>
    );

    if (portal) {
      return ReactDOM.createPortal(datePickerElement, portal);
    }
    return null;
  };

  return renderDatePicker();
};

export default DateRangePicker;
