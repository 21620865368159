import { createSelector } from '@reduxjs/toolkit';
import { deleteTagRequest, fetchAllTagsRequest, upsertTagRequest } from 'actions/tags';
import { GlobalState } from 'reducers';
import type { Selector, Tag, UUID } from 'types';

export const getFetchAllTagsLoading: Selector<boolean> = (state) =>
  state.tags.loading.includes(fetchAllTagsRequest.toString());

export const getUpsertTagLoading: Selector<boolean> = (state) =>
  state.tags.loading.includes(upsertTagRequest.toString());

export const getDeleteTagLoading: Selector<boolean> = (state) =>
  state.tags.loading.includes(deleteTagRequest.toString());

export const getTags: Selector<Tag[]> = createSelector(
  [(state: GlobalState): GlobalState['tags']['items'] => state.tags.items],
  (tags) => Object.values(tags)
);

export const getTagsTotalCount: Selector<number> = (state) => state.tags.count;

export type FilterTag = {
  uuid: UUID;
  value: string;
  count?: number;
};

export const getTagsForFilters: Selector<Tag[]> = (state) =>
  Object.values(state.tags.items).map((tag) => ({
    ...tag,
  }));

export const getTagsForFilterPills: Selector<Tag[]> = createSelector(
  [(state: GlobalState): GlobalState['tags']['filterItems'] => state.tags.filterItems],
  (tags) => Object.values(tags)
);

export const getTagFilterItems: Selector<{ [key: string]: Tag }> = (state) =>
  state.tags.filterItems;

export const getAllTagsLoading: Selector<boolean> = (state) => !!state.tags.loading.length;
