import { fetchAllEventsCount } from 'actions';
import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import Permissions from 'components/Permissions';
import withWidget from 'decorators/widget';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAllPendingEventsCount, getAllPendingEventsLoading } from 'selectors/analytics';
import { getUser } from 'selectors/auth';
import { getPermissionsPolicy } from 'selectors/permissions';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';
import { isActionAuthorized } from 'utils/permissions';
import { useTimeRange } from 'utils/timeRanges';

const PendingEvents: React.FC = () => {
  const dispatch = useDispatch();
  const count = useSelector(getAllPendingEventsCount);
  const loading = useSelector(getAllPendingEventsLoading);

  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);

  const range = useTimeRange();

  const buttonClass = 'focus:outline-none hover:outline-none hover:underline text-white';

  useEffect(() => {
    dispatch(fetchAllEventsCount(range));
  }, [dispatch, range]);

  const renderLinkBody = (
    <div className="flex h-full items-center justify-between">
      <div className="text text--left text-2xl text--white">
        {loading ? <LoadingIndicator size="5" /> : `${count} pending events`}
      </div>
      <Permissions action="events.list">
        <div className="flex flex-row items-center">
          <div className="text text-2xl self-center pr-3 text--white">Review</div>
          <svg
            className="h-6 w-6 text text--bold text--white"
            fill="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </div>
      </Permissions>
    </div>
  );

  return (
    <div className="w-full">
      {isActionAuthorized('events.list', user.roles, policy) ? (
        <LinkLookup
          className={buttonClass}
          routeName="envelope-list"
          queryParams={{
            envelopes__states: 'NEW',
            envelopes__created_before: range.created_before || null,
            envelopes__created_after: range.created_after || null,
          }}
          onClick={(): Promise<void> => logEvent('Dashboard-pending-events')}
        >
          {renderLinkBody}
        </LinkLookup>
      ) : (
        <button
          className={`${buttonClass} pointer-events-none cursor-default no-underline`}
          type="button"
        >
          {renderLinkBody}
        </button>
      )}
    </div>
  );
};

export default withWidget({
  minWidth: 5,
  minHeight: 3,
  bgClassColor: 'bg-litlingo-primary',
})(PendingEvents);
