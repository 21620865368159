import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import SearchInput from 'components/SearchInput';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useDeleteTeam from 'hooks/teams/useDeleteTeam';
import useTeams from 'hooks/teams/useTeams';
import useUpsertTeam from 'hooks/teams/useUpsertTeam';
import React, { useState } from 'react';
import type { Team, User } from 'types';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import TeamForm from './TeamForm';
import TeamsList from './TeamsList';

const TeamsManager: React.FC = () => {
  const [selectedTeam, setSelectedTeam] = useState<Team>({} as Team);
  const [toggleForm, setToggleForm] = useState<boolean>(false);

  const { isLoading: fetchLoading } = useTeams({ redirectOnError: true });
  const { isLoading: deleteLoading } = useDeleteTeam();
  const { isLoading: upsertLoading } = useUpsertTeam();

  const handleChangeTeam = (team: Team): void => {
    setSelectedTeam(team);
    setToggleForm(true);
  };

  const handleNewTeam = (): void => {
    handleChangeTeam({
      name: '',
      users: [] as User[],
    } as Team);
  };

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <div
      className="min-w-screen bg-white"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />
      <div
        className="flex flex-row"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <SettingSidebar />

        <div className="py-10 overflow-auto">
          <div>
            <header>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="mt-2 md:flex md:items-center md:justify-between">
                  <div className="flex-1 min-w-0">
                    <div className="flex flex-row items-center">
                      <h2 className="text-left text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
                        Divisions{' '}
                      </h2>
                      {(upsertLoading || deleteLoading || fetchLoading) && (
                        <div data-testid="loading-spinner">
                          <LoadingIndicator className="ml-2" size="5" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-4 flex-shrink-0 flex justify-between">
                  <SearchInput resource={resourceQueryParamName.teams} field="broad_search" />
                  <span className="ml-3 shadow-sm rounded-md">
                    <button
                      onClick={handleNewTeam}
                      type="button"
                      className="button button--primary"
                      data-testid="new-team-button"
                    >
                      Create New Division
                    </button>
                  </span>
                </div>

                <div className="mt-4">
                  <TeamForm
                    team={selectedTeam}
                    toggleForm={toggleForm}
                    setToggleForm={setToggleForm}
                  />
                </div>
              </div>
            </header>
          </div>
          <main>
            <div className="mt-10 mb-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-left">
              <TeamsList handleChangeTeam={handleChangeTeam} />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default TeamsManager;
