import { clearURLParams } from 'actions';
import React, { ComponentType } from 'react';
import { useDispatch } from 'react-redux';
import logEvent from 'utils/analytics';

type ContexProps = {
  envelopesCount: number;
  loadingContent: boolean;
};

const EmptyEnvelopeList: ComponentType<{ context: ContexProps }> = ({ context }) => {
  const { envelopesCount, loadingContent } = context;

  const dispatch = useDispatch();

  const noResults =
    'Suggestions: Make sure all words are spelled correctly, try different keywords, or try more general keywords.';
  const offCase = 'The results may have updated with the applied filter criteria.';

  const handleAction = (): void => {
    logEvent('no-result-reset-filters');
    dispatch(clearURLParams());
  };

  if (loadingContent) {
    return null;
  }

  return (
    <div className="absolute w-full flex flex-col justify-center items-center mt-20">
      <span className="font-header font-bold text-xl">No results found</span>

      <span className="w-112 text-center">{envelopesCount === 0 ? noResults : offCase}</span>
      <button
        className="flex flex-row justify-center button button--primary mt-4 w-48 h-8"
        type="button"
        onClick={handleAction}
      >
        <span>{envelopesCount === 0 ? 'Clear Filter' : 'Refresh Table'}</span>
      </button>
    </div>
  );
};

export default EmptyEnvelopeList;
