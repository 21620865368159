import { EntityTag, Tag, TagGroup } from '@litlingo/client';
import React, { useEffect, useState } from 'react';
import TagItem from './EnvelopeTagItem';

interface Props {
  tagGroup: TagGroup;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, tag: Tag, unselectedTag?: Tag) => void;
  tags: EntityTag[];
  renderGroupName?: boolean;
  name?: string;
  disabled?: boolean;
}

const EnvelopeRadioGroupTags: React.FC<Props> = ({
  tagGroup,
  handleChange,
  tags,
  renderGroupName = true,
  name = '',
  disabled = false,
}) => {
  const [selectedTag, setSelectedTag] = useState<Tag | undefined>();

  useEffect(() => {
    if (tags) {
      const tag = tags.find((t) =>
        tagGroup.tag_values.some((tagValue) => tagValue.uuid === t.tag_value_uuid)
      );

      setSelectedTag(tag?.tag_value);
    }
  }, [tags, tagGroup]);

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>, tag: Tag): void => {
    handleChange(e, tag, selectedTag);
    setSelectedTag(tag);
  };

  if (tagGroup.tag_values.length === 0) return null;

  return (
    <div className="flex flex-col gap-1">
      {renderGroupName && (
        <div className="text-body font-normal pb-1 border-b border-litlingo-gray-1">
          <div className="font-bold">
            {tagGroup.name.charAt(0).toUpperCase() + tagGroup.name.slice(1)}
          </div>
        </div>
      )}
      <div className="flex flex-col gap-2 pl-2">
        {tagGroup.tag_values &&
          tagGroup.tag_values.map((tag) => (
            <TagItem
              key={tag.uuid}
              tag={tag}
              defaultChecked={selectedTag?.uuid === tag.uuid || false}
              handleChange={handleRadioChange}
              name={name}
              type="radio"
              disabled={disabled}
              checked={selectedTag?.uuid === tag.uuid}
            />
          ))}
      </div>
    </div>
  );
};

export default EnvelopeRadioGroupTags;
