import { allowedDimensions, metricsLinksMap } from 'constants/dashboard';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetError from 'decorators/widget/WidgetError';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getNavParamsByResource, getNavWidgetFilters } from 'selectors/nav';
import { getPermissionsPolicy } from 'selectors/permissions';
import { DashboardMetric, DefaultWidgetComponentProps } from 'types';
import logEvent from 'utils/analytics';
import { buildSimpleDataFromMetrics } from 'utils/dashboard';
import { isActionAuthorized } from 'utils/permissions';
import { useHistory } from 'utils/urls';

const GreenSimpleMetricsWidget: React.FC<DefaultWidgetComponentProps> = (props) => {
  const { widgetData, queryData, widgetId } = props;
  const history = useHistory();
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const customerDomain = useSelector(getCustomerDomain);
  const widgetFilters = useSelector(getNavWidgetFilters(widgetId));
  const filters = useSelector(getNavParamsByResource(resourceQueryParamName.metrics));

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  const processedData = buildSimpleDataFromMetrics(widgetData, queryData);
  if ('error' in processedData && processedData.error != null) {
    return <WidgetError msg={processedData.error} />;
  }

  if ('data' in processedData && processedData.data.length === 0) {
    return <WidgetEmptyState />;
  }

  const handleClick = (query: DashboardMetric): void => {
    const [id, type] = widgetId.split('--');
    logEvent(`Dashboard-click-${id}-${type}`);

    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info == null || !isActionAuthorized(info.action, user.roles, policy)) {
      return;
    }

    const dimensions: Record<string, string | string[]> = {};
    const infoDimension = info.dimensions;
    if (infoDimension != null) {
      // Top level dimensions
      Object.entries(infoDimension).forEach(([dimension, param]) => {
        const allowed = allowedDimensions[query.metric];

        // if allowedDimension is not defined or the dimension is not included,
        // just ignore it
        if (allowed == null || !allowed.includes(dimension)) {
          return;
        }

        let value: string | string[] = '';
        if (filters[dimension] != null) {
          const topValue = filters[dimension];
          value = topValue;
        }

        if (widgetFilters[dimension] != null) {
          const widgetValue = filters[dimension];
          value = widgetValue;
        }

        dimensions[`${info.resource}__${param}`] = value;
      });

      // Metric level dimensions
      if (query.dimensions != null) {
        query.dimensions.forEach((dimension) => {
          const allowed = allowedDimensions[query.metric];

          // if allowedDimension is not defined or the dimension is not included,
          // just ignore it
          if (allowed == null || !allowed.includes(dimension.name)) {
            return;
          }

          dimensions[`${info.resource}__${infoDimension[dimension.name]}`] = dimension.value;
        });
      }
    }

    // Metric level dimensions have more priority than top leve dimensions

    const start = moment.utc(queryData.start_time * 1000).format(FULL_DATE_FORMAT);
    const end = moment.utc(queryData.end_time * 1000).format(FULL_DATE_FORMAT);

    history.pushLookup({
      customerDomain,
      routeName: info.route,
      queryParams: {
        [`${info.resource}__created_after`]: start,
        [`${info.resource}__created_before`]: end,
        ...dimensions,
      },
    });
  };

  return (
    <div
      // when two items or less, use wide cols
      // className="grid items-center grid-cols-3 gap-3"
      style={{ display: 'grid', gridTemplateColumns: '1fr 0.1fr 1fr' }}
    >
      {'data' in processedData &&
        processedData.data.map(
          (data, idx, arr) =>
            queryData.queries[idx]?.id && (
              <>
                <div className="flex flex-row ">
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${queryData.queries[idx]?.id}--${idx}`}
                    className="flex w-full  flex-col items-center"
                  >
                    <div className="items-baseline">
                      <div className="flex items-center text-3xl font-bold leading-8  text-litlingo-primary-120">
                        <span className="mr-1">{data.icon && data.icon}</span>
                        <span
                          className="cursor-pointer hover:underline"
                          aria-hidden
                          onClick={(): void => handleClick(queryData.queries[idx])}
                        >
                          {data.count}
                        </span>
                      </div>
                    </div>
                    <div className="text text--left text-litlingo-gray-6">{data.label}</div>
                  </div>
                </div>
                {idx !== arr.length - 1 && (
                  <span className="text-litlingo-primary text-3xl font-bold items-center">/</span>
                )}
              </>
            )
        )}
    </div>
  );
};

export default withWidget({
  fetchOnePeriod: true,
})(GreenSimpleMetricsWidget);
