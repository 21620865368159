import { UUID } from '@litlingo/client';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  clearAlert,
  showActionAlert,
  showErrorAlert,
  showQueryErrorAlert,
  showSuccessAlert,
  showUndeleteAlert,
} from 'actions';
import { resourceToHumanLabel } from 'constants/resourceQueryNames';

type AlertState<T extends Record<string, string | number | boolean>> = {
  alert: {
    msg: string;
    level: string;
    id: UUID;
    resource: keyof typeof resourceToHumanLabel;
    action?: {
      actionMsg: string;
      actionPayload: T;
      action: (payload: T) => void;
    };
  } | null;
};

type AlertReducer<P = void> = (
  state: AlertState<Record<string, string | number | boolean>>,
  action: PayloadAction<P>
) => void;

type ShowAlert = (
  state: AlertState<Record<string, string | number | boolean>>,
  msg: string,
  level: string
) => void;

type ShowMessagePayload = {
  msg: string;
};

type ShowUndeletePayload = {
  resource: keyof typeof resourceToHumanLabel;
  id: UUID;
};

export type ShowActionPayload<T extends Record<string, string | number | boolean>> = {
  id: UUID;
  msg: string;
  actionMsg: string;
  actionPayload: T;
  action: (payload: T) => void;
};

const defaultState: AlertState<Record<string, string | number | boolean>> = {
  alert: null,
};

const handleShowAlert: ShowAlert = (state, msg, level) => ({ ...state, alert: { msg, level } });

const handleShowSuccessAlert: AlertReducer<ShowMessagePayload> = (state, { payload }) => {
  const { msg } = payload;

  return handleShowAlert(state, msg, 'success');
};

const handleShowErrorAlert: AlertReducer<ShowMessagePayload> = (state, { payload }) => {
  const { msg } = payload;

  return handleShowAlert(state, msg, 'error');
};

const handleShowQueryErrorAlert: AlertReducer<ShowMessagePayload> = (state, { payload }) => {
  const { msg } = payload;

  return handleShowAlert(state, msg, 'query');
};

const handleShowUndeleteAlert: AlertReducer<ShowUndeletePayload> = (state, { payload }) => ({
  ...state,
  alert: {
    id: payload.id,
    level: 'undelete',
    msg: `${resourceToHumanLabel[payload.resource]} deleted successfully`,
    resource: payload.resource,
  },
});

const handleShowActionAlert: AlertReducer<
  ShowActionPayload<Record<string, string | number | boolean>>
> = (state, { payload }) => ({
  ...state,
  alert: {
    id: payload.id,
    level: 'action',
    msg: payload.msg,
    action: {
      actionMsg: payload.actionMsg,
      actionPayload: payload.actionPayload,
      action: payload.action,
    },
  },
});

const handleClearAlert: AlertReducer = (state) => ({ ...state, alert: null });

const handlers = {
  [showSuccessAlert.toString()]: handleShowSuccessAlert,
  [showUndeleteAlert.toString()]: handleShowUndeleteAlert,
  [showActionAlert.toString()]: handleShowActionAlert,
  [showErrorAlert.toString()]: handleShowErrorAlert,
  [showQueryErrorAlert.toString()]: handleShowQueryErrorAlert,
  [clearAlert.toString()]: handleClearAlert,
};

const alertsReducer = createReducer(defaultState, handlers);

export default alertsReducer;
