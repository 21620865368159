import { setTree } from 'actions';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { fillDefaultTree } from 'utils/parserTree';
import useTree from './useTree';

type UseAddTreeToStore = (fs: string) => void;

const useAddTreeToStore: UseAddTreeToStore = (fs: string) => {
  const dispatch = useDispatch();
  const { data } = useTree(fs);

  if (data) {
    const fullTree = fillDefaultTree(cloneDeep(data));

    if (fullTree.op) {
      dispatch(setTree({ tree: fullTree }));
    }
  }
};

export default useAddTreeToStore;
