import { fetchProcessingStatus } from 'actions';
import Card from 'components/Card';
import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import Permissions from 'components/Permissions';
import { LEFT_ARROW_ICON } from 'constants/envelopeIcons';
import React, { useEffect } from 'react';
import ReactJson from 'react-json-view';
import { useDispatch } from 'react-redux';
import { getProcessingStatus, getProcessingStatusLoading } from 'selectors/auth';
import { useSelector } from 'store';

const ProcessingStatus: React.FC = () => {
  const dispatch = useDispatch();
  const processingStatusDoc = useSelector(getProcessingStatus);
  const loading = useSelector(getProcessingStatusLoading);

  useEffect(() => {
    dispatch(fetchProcessingStatus());
  }, [dispatch]);

  const header = (
    <div className="flex justify-between ">
      <div className="pt-5 pb-12 flex items-center">
        <LinkLookup routeName="customer" className="mx-6 focus:outline-none">
          {LEFT_ARROW_ICON}
        </LinkLookup>
        <span className="text text--large text--bold">Processing Status Information</span>
        <span className="ml-2">{loading && <LoadingIndicator />}</span>
      </div>
    </div>
  );

  const editor = (
    <div className="px-5 py-4">
      <ReactJson
        src={processingStatusDoc}
        theme="chalk"
        style={{ paddingTop: '10px', paddingBottom: '25px' }}
      />
    </div>
  );

  return (
    <Permissions field="customers.config">
      <div className="litlingo-gray-bg-color-4">
        <Navbar />

        <div
          className="flex flex-row overflow-auto"
          style={{
            minHeight: 'calc(100vh - 4rem)',
            height: 'calc(100vh - 4rem)',
          }}
        >
          <SettingSidebar />
          <div className="mt-0 mx-auto min-w-2xl w-8/12 max-w-6xl text-left overflow-auto">
            {processingStatusDoc && (
              <Card
                items={[header, Object.keys(processingStatusDoc).length === 0 ? <></> : editor]}
              />
            )}
          </div>
        </div>
      </div>
    </Permissions>
  );
};

export default ProcessingStatus;
