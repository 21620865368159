import { createAction } from '@reduxjs/toolkit';
import type {
  API,
  CustomReport,
  CustomReportConfig,
  ErrorObject,
  SavedSearch,
  UUID,
  User,
} from 'types';

type FetchCustomReportsPayload = {
  limit?: number;
  // eslint-disable-next-line camelcase
  include_count?: boolean;
};
export const fetchCustomReports = createAction<FetchCustomReportsPayload>('CUSTOM_REPORT/FETCH');
export const fetchCustomReportsRequest = createAction('CUSTOM_REPORT/FETCH_REQUEST');
export const fetchCustomReportsSuccess = createAction<API.WrappedAPIResponse<CustomReport>>(
  'CUSTOM_REPORT/FETCH_SUCCESS'
);
export const fetchCustomReportsFailure = createAction<ErrorObject>('CUSTOM_REPORT/FETCH_FAILURE');
export const fetchCustomReportsFulfill = createAction('CUSTOM_REPORT/FETCH_FULFILL');

type UpsertCustomReportPayload = {
  uuid?: UUID;
  kind: string;
  user_uuid: UUID;
  user?: User;
  saved_search_uuid: UUID;
  search?: SavedSearch;
  config?: CustomReportConfig;
};

export const upsertCustomReport = createAction<UpsertCustomReportPayload>('CUSTOM_REPORT/UPSERT');
export const upsertCustomReportRequest = createAction('CUSTOM_REPORT/UPSERT_REQUEST');
export const upsertCustomReportSuccess = createAction<CustomReport>('CUSTOM_REPORT/UPSERT_SUCCESS');
export const upsertCustomReportFailure = createAction<ErrorObject>('CUSTOM_REPORT/UPSERT_FAILURE');
export const upsertCustomReportFulfill = createAction('CUSTOM_REPORT/UPSERT_FULFILL');

export const deleteCustomReport = createAction<{ uuid: UUID }>('CUSTOM_REPORT/DELETE');
export const deleteCustomReportRequest = createAction('CUSTOM_REPORT/DELETE_REQUEST');
export const deleteCustomReportSuccess = createAction<UUID>('CUSTOM_REPORT/DELETE_SUCCESS');
export const deleteCustomReportFailure = createAction<ErrorObject>('CUSTOM_REPORT/DELETE_FAILURE');
export const deleteCustomReportFulfill = createAction('CUSTOM_REPORT/DELETE_FULFILL');

export type SetUserReportPayload = {
  report: CustomReport | null;
  delete: boolean;
};

export const setUserReport = createAction<SetUserReportPayload>('CUSTOM_REPORT/SET_USER_REPORT');
