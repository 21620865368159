/* eslint-disable max-lines */
import { createCategory, createCategoryRule } from 'actions';
import SelectCustomerModal from 'components/CustomerList/SelectCustomerModal';
import LoadingIndicator from 'components/LoadingIndicator';
import SelectModelModal from 'components/Model/SelectModelModal';
import SelectOutcomeModal from 'components/Outcome/SelectOutcomeModal';
import SetRuleNameModal from 'components/RuleGroupsList/SetRuleNameModal';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getFetchSingleCategoryLoading } from 'selectors/categories';
import { useSelector } from 'store';
import type { UUID } from 'types';

type ComponentProps = {
  modelId?: UUID;
  ruleId?: UUID;
  customerId?: UUID;
  outcomeId?: UUID;
  askForOutcome?: boolean;
  toggleModal: () => void;
  redirect?: string;
  fromRule?: boolean;
};

const CreateCategoryModal: React.FC<ComponentProps> = ({
  modelId,
  ruleId,
  customerId,
  outcomeId,
  askForOutcome = false,
  toggleModal,
  redirect = 'global-category-manager',
  fromRule = false,
}) => {
  const dispatch = useDispatch();

  const [model, setModel] = useState(modelId || '');
  const [isModelModalOpen, setIsModelModalOpen] = useState(false);

  const [customer, setCustomer] = useState(customerId || '');
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);

  const [outcome, setOutcome] = useState(outcomeId || '');
  const [isOutcomeModalOpen, setIsOutcomeModalOpen] = useState(false);

  const [ruleName, setRuleName] = useState('');
  const [isCreateRuleModalOpen, setIsCreateRuleModalOpen] = useState(false);

  const loading = useSelector(getFetchSingleCategoryLoading);

  const handleStep = (): void => {
    if (!customer) {
      setIsCustomerModalOpen(true);
      return;
    }

    if (!model) {
      setIsModelModalOpen(true);
      return;
    }

    if (!outcome && askForOutcome) {
      setIsOutcomeModalOpen(true);
      return;
    }

    if (ruleId) {
      dispatch(
        createCategory({
          modelId: model,
          customerId: customer,
          redirect,
          fromRule,
          ruleId,
          ...(outcome ? { outcomeId: outcome } : {}),
        })
      );
      return;
    }

    if (ruleName) {
      dispatch(
        createCategoryRule({
          modelId: model,
          ruleName,
          customerId: customer,
          redirect,
          fromRule,
          ...(outcome ? { outcomeId: outcome } : {}),
        })
      );
    }
  };

  useEffect(() => {
    handleStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, customer, outcome]);

  const handleSelectModel = (m: UUID): void => {
    setModel(m);
    setIsModelModalOpen(!isModelModalOpen);
  };

  const handleSelectCustomer = (c: {
    domain: string | null;
    uuid: string;
    label: string | null;
  }): void => {
    setCustomer(c.uuid);
    setIsCustomerModalOpen(!isCustomerModalOpen);
    if (!ruleId) setIsCreateRuleModalOpen(true);
  };

  const handleSetRuleName = (name: string): void => {
    setRuleName(name);
  };

  const handleSelectOutcome = (o: { name: string; uuid: string }): void => {
    setOutcome(o.uuid);
    setIsOutcomeModalOpen(!isOutcomeModalOpen);
  };

  if (loading) return <LoadingIndicator className="m-auto mt-20" size="20" />;

  return (
    <>
      {isModelModalOpen && (
        <SelectModelModal
          toggleModalOpen={toggleModal}
          onSelect={handleSelectModel}
          onBackButtonClick={toggleModal}
        />
      )}
      {isCreateRuleModalOpen && (
        <SetRuleNameModal
          ruleName={ruleName}
          setRuleName={handleSetRuleName}
          toggleModalOpen={toggleModal}
          handleStep={handleStep}
        />
      )}
      {isCustomerModalOpen && (
        <SelectCustomerModal selectCustomer={handleSelectCustomer} toggleModalOpen={toggleModal} />
      )}

      {isOutcomeModalOpen && (
        <SelectOutcomeModal
          toggleModalOpen={toggleModal}
          onSelect={handleSelectOutcome}
          onBackButtonClick={toggleModal}
        />
      )}
    </>
  );
};
export default CreateCategoryModal;
