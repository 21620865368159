/* eslint-disable camelcase */
import { upsertTestCase } from 'actions';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import type { TestCase } from 'types';
import SelectAndSearchByResource from '../SelectAndSearchByResource';

type ComponentProps = {
  testCase: TestCase;
};

const TestCaseForm: React.FC<ComponentProps> = ({ testCase }) => {
  const dispatch = useDispatch();
  const [selectedEntity, setSelectedEntity] = useState('campaign_uuid');
  const [selectedId, setSelectedId] = useState<string | null>('');
  const [cleanSearchValue, setCleanSearchValue] = useState(false);

  const { register, getValues, watch } = useForm({
    defaultValues: {
      test_string: testCase.test_string,
      comment: testCase.comment,
      should_match: testCase.should_match,
      priority: testCase.priority || 0,
    },
  });

  const options = {
    campaign_uuid: 'Use Case',
    rule_uuid: 'Model',
    annotator_uuid: 'Identifier',
  };

  const priorityOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleChange = (): void => {
    const campaign_uuid = selectedEntity === 'campaign_uuid' ? selectedId : null;
    const rule_uuid = selectedEntity === 'rule_uuid' ? selectedId : null;
    const annotator_uuid = selectedEntity === 'annotator_uuid' ? selectedId : null;
    dispatch(
      upsertTestCase({
        uuid: testCase.uuid,
        platform: testCase.platform,
        campaign_uuid,
        rule_uuid,
        annotator_uuid,
        ...getValues(),
      })
    );
  };

  const handleChangeEntity = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { value } = event.target;
    Object.keys(options).forEach((key) => {
      if (key === value) {
        setSelectedEntity(key);
        setCleanSearchValue(true);
      }
    });
  };

  useEffect(() => {
    if (testCase.campaign_uuid) {
      setSelectedEntity('campaign_uuid');
    }
    if (testCase.rule_uuid) {
      setSelectedEntity('rule_uuid');
    }
    if (testCase.annotator_uuid) {
      setSelectedEntity('annotator_uuid');
    }
  }, [testCase]);

  useEffect(() => {
    if (selectedId) {
      handleChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  return (
    <div className="border rounded-lg litlingo-gray-bg-color">
      <div className="p-4 flex">
        <form className="w-full">
          <div className="grid grid-cols-2 grid-rows-1 gap-5">
            <div className="col-start-1 col-end-2">
              <label htmlFor="test_string" className="block text text--bold">
                Test phrase
                <div className="mt-1 rounded-md shadow-sm">
                  <textarea
                    id="test_string"
                    name="test_string"
                    rows={8}
                    ref={register}
                    onChange={handleChange}
                    className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 litlingo-gray-bg-color"
                  />
                </div>
                <p className="mt-2 text-xs text-gray-600 font-normal">
                  Use this field to edit the test phrase
                </p>
              </label>
            </div>
            <div className="col-start-2 col-end-3 flex flex-col justify-around">
              <div className="flex flex-row flex-wrap items-between mb-4">
                <div className="mr-4">
                  <label htmlFor="test_string" className="block text text--bold">
                    Expected results
                    <div className="mt-1 flex items-center">
                      <label className="switch relative inline-block" htmlFor="should_match">
                        <input
                          id="should_match"
                          name="should_match"
                          ref={register}
                          type="checkbox"
                          onChange={handleChange}
                          className="litlingo-checkbox-input w-0 h-0 opacity-0"
                        />
                        <span className="slider round absolute inset-0 duration-500 rounded-full py-2 px-4 cursor-pointer" />
                      </label>
                      <span className="font-normal ml-4">
                        {watch('should_match') ? 'Match' : 'No match'}
                      </span>
                    </div>
                  </label>
                </div>
                <div className="flex flex-col items-start justify-center mr-4 pb-4">
                  <span className="text text--semibold">Priority</span>
                  <select
                    id="priority"
                    name="priority"
                    ref={register}
                    className="truncate form-select py-1 mr-2 text text--large"
                    onChange={handleChange}
                  >
                    {priorityOptions.map((key) => (
                      <option className="text text--large" key={key} value={key}>
                        {key}
                      </option>
                    ))}
                  </select>
                </div>
                <SelectAndSearchByResource
                  title="Choose test entity"
                  options={options}
                  cleanSearchValue={cleanSearchValue}
                  selectedEntity={selectedEntity}
                  entity={testCase.campaign || testCase.rule || testCase.annotator}
                  setSelectedId={setSelectedId}
                  selectedId={selectedId}
                  handleChangeEntity={handleChangeEntity}
                />
              </div>
              <div>
                <label htmlFor="comment" className="block text text--bold">
                  Admin notes
                  <div className="mt-1 rounded-md shadow-sm">
                    <textarea
                      id="comment"
                      name="comment"
                      rows={3}
                      ref={register}
                      onChange={handleChange}
                      className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 litlingo-gray-bg-color"
                    />
                  </div>
                  <p className="mt-2 text-xs text-gray-600 font-normal">
                    Use this field to add notes
                  </p>
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TestCaseForm;
