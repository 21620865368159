/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TAG_REMOVE_ICON } from 'constants/filterIcons';
import React, { useMemo } from 'react';
import { ValueNode } from 'utils/parserTree';

type ComponentProps = {
  option: {
    title: string;
    id: string;
  };
  customLeftIcon?: JSX.Element | false;

  node?: ValueNode;
  selectedFilters: string[];
  setSelectedFilters: React.Dispatch<React.SetStateAction<string[]>>;
};

const OptionBadgeFilter: React.FC<ComponentProps> = ({
  option,
  customLeftIcon,
  node,
  selectedFilters,
  setSelectedFilters,
}) => {
  const bgColor = useMemo(() => {
    if (!selectedFilters.includes(option.id)) {
      return 'bg-litlingo-gray-1';
    }

    return 'bg-litlingo-success-light';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters.length]);

  return (
    <div data-testid={`container-badge-${option.id}`} className={`inline-block rounded ${bgColor}`}>
      <span data-testid="text" className="text-small flex my-0 px-1 py-0.5 items-center gap-1 ">
        {customLeftIcon && <span className="mr-2">{customLeftIcon}</span>}
        <button
          data-testid="filter-badge"
          type="button"
          onClick={(e): void => {
            e.stopPropagation();
            setSelectedFilters((prev) => {
              if (!prev.includes(option.id)) {
                return [...prev, option.id];
              }
              return prev;
            });
          }}
          role={node?.id ?? 'button'}
          className="focus:outline-none"
        >
          {option.title}
        </button>
        {selectedFilters.includes(option.id) && (
          <button
            data-testid="delete-button"
            onClick={(e): void => {
              e.stopPropagation();
              setSelectedFilters((prev) => prev.filter((filter) => filter !== option.id));
            }}
            className="focus:outline-none outline-none"
            type="button"
          >
            {TAG_REMOVE_ICON}
          </button>
        )}
      </span>
    </div>
  );
};

export default OptionBadgeFilter;
