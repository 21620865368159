/* eslint-disable max-lines */
/* eslint-disable camelcase */
import {
  deleteTest,
  fetchAllTestCases,
  fetchTestCaseSummary,
  saveTestCase,
  SaveTestCasePayload,
  showSuccessAlert,
  upsertTestCase,
} from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import EmptyTableState from 'components/EmptyTableState';
import ListFooter from 'components/ListFooter';
import Modal from 'components/Modal';
import SelectAndSearchByResource from 'components/SelectAndSearchByResource';
import { MemoizedTestCaseListActionsRow } from 'components/TestCaseList/TestCaseListActionsRow';
import TestCaseListItem from 'components/TestCaseTable/TestCaseListItem';
import Tooltip from 'components/Tooltip';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { entityToQueryMap, TestCaseEntity } from 'constants/testCases';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedNode } from 'selectors/config';
import { getNavParamsByResource } from 'selectors/nav';
import { getConfigRuleAsArray } from 'selectors/rule';
import {
  evaluateTestCaseLoading,
  getTestCases,
  getTestCasesLoading,
  getTestCasesResultsKey,
  getTestCasesTotalCount,
} from 'selectors/testCases';
import type { MTestCase, UUID } from 'types';
import { objToString } from 'utils/objToString';
import { useHistory } from 'utils/urls';

type ComponentProps = {
  entity?: TestCaseEntity;
  value?: string;
  campaignIncludingRules?: boolean;
  loading?: boolean;
  searchValue?: string;
};

const TestCaseTable: React.FC<ComponentProps> = (props) => {
  const { entity, value, campaignIncludingRules, loading, searchValue = '' } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const [isDeletingTestcase, setIsDeletingTestcase] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<null | UUID>(null);
  const [isDuplicatingTestcase, setIsDuplicatingTestcase] = useState(false);
  const [testToBeDuplicated, setTestToBeDuplicated] = useState<null | SaveTestCasePayload>(null);

  const [selectedEntity, setSelectedEntity] = useState('campaign_uuid');
  const [selectedId, setSelectedId] = useState<string | null>('');
  const [cleanSearchValue, setCleanSearchValue] = useState(false);

  const [selectedTest, setSelectedTest] = useState<null | MTestCase>(null);
  const [changeEntityModal, setChangeEntityModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<UUID[]>([]);

  const testCases = useSelector(getTestCases);
  const isEmpty = testCases.length === 0;
  const params = useSelector(getNavParamsByResource(resourceQueryParamName.testCase));
  const evaluateTestLoading = useSelector(evaluateTestCaseLoading);
  const formattedParams = objToString(params);
  const evaluateTestResultsKey = useSelector(getTestCasesResultsKey);
  const arrayTree = useSelector(getConfigRuleAsArray);
  const selectedNode = useSelector(getSelectedNode);

  const toggleConfirmModal = (): void => {
    setIsDeletingTestcase(false);
    setIsDuplicatingTestcase(false);
  };

  const toggleChangeEntityModal = (): void => {
    setSelectedTest(null);
    setChangeEntityModal(false);
    setCleanSearchValue(false);
  };

  const options = {
    campaign_uuid: 'Use Case',
    rule_uuid: 'Model',
    annotator_uuid: 'Identifier',
  };

  const saveEntityChanges = (): void => {
    const campaign_uuid = selectedEntity === 'campaign_uuid' ? selectedId : null;
    const rule_uuid = selectedEntity === 'rule_uuid' ? selectedId : null;
    const annotator_uuid = selectedEntity === 'annotator_uuid' ? selectedId : null;
    dispatch(
      upsertTestCase({
        ...selectedTest,
        campaign_uuid,
        rule_uuid,
        annotator_uuid,
      })
    );
    toggleChangeEntityModal();
  };

  const handleChangeEntity = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { value: val } = event.target;
    Object.keys(options).forEach((key) => {
      if (key === val) {
        setSelectedEntity(key);
        setCleanSearchValue(true);
      }
    });
  };

  useEffect(() => {
    if (selectedTest?.campaign_uuid) {
      setSelectedEntity('campaign_uuid');
    }
    if (selectedTest?.rule_uuid) {
      setSelectedEntity('rule_uuid');
    }
    if (selectedTest?.annotator_uuid) {
      setSelectedEntity('annotator_uuid');
    }
  }, [selectedTest]);

  const query = useMemo(() => {
    const q: Record<string, string | boolean> = {};
    if (entity != null && value != null) {
      q[entityToQueryMap[entity]] = value;
    }
    if (campaignIncludingRules) {
      q.campaign_including_rules = true;
    }

    if (searchValue !== '') {
      q.broad_search = searchValue;
    }

    if (
      selectedNode !== null &&
      selectedNode !== 0 &&
      selectedNode !== -1 &&
      arrayTree[selectedNode]
    ) {
      q.branch_uuid = arrayTree[selectedNode].id;
    }

    return q;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, value, campaignIncludingRules, searchValue, selectedNode]);

  useEffect(() => {
    if (!evaluateTestLoading && !evaluateTestResultsKey) {
      dispatch(fetchAllTestCases(query));
    }
  }, [dispatch, query, evaluateTestLoading, evaluateTestResultsKey]);

  useEffect(() => {
    if (!evaluateTestResultsKey) {
      dispatch(fetchAllTestCases(query));
    }
  }, [dispatch, query, evaluateTestResultsKey, formattedParams]);

  useEffect(() => {
    dispatch(fetchTestCaseSummary(query));
  }, [dispatch, query]);

  const handleDelete = (): void => {
    if (idToBeDeleted != null) {
      if (entity != null && value != null) {
        dispatch(deleteTest({ id: idToBeDeleted, entity: { name: entity, value } }));
      } else {
        dispatch(deleteTest({ id: idToBeDeleted }));
      }
      toggleConfirmModal();
    }
  };

  const handleDuplicate = (): void => {
    if (testToBeDuplicated) {
      dispatch(saveTestCase(testToBeDuplicated));
    }
    toggleConfirmModal();
  };

  const handleClickOnDuplicate = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    testData: SaveTestCasePayload
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    setIsDuplicatingTestcase(true);
    setTestToBeDuplicated(testData);
  };

  const handleClickOnDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: UUID
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    setIsDeletingTestcase(true);
    setIdToBeDeleted(id);
  };

  const handleCopyClick = async (
    event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>,
    testPhrase: string
  ): Promise<void> => {
    event.stopPropagation();
    event.preventDefault();
    await navigator.clipboard.writeText(testPhrase);
    dispatch(showSuccessAlert('Test phrase copied to your clipboard'));
  };

  const handleRowSelect = (checked: boolean, uuid: UUID): void => {
    if (checked) {
      setSelectedRows((selected) => [...selected, uuid]);
    } else {
      setSelectedRows((selected) => selected.filter((id) => id !== uuid));
    }
  };

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();

    if (selectedRows.length === testCases.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(testCases.map((testCase) => testCase.uuid));
    }
  };

  return (
    <>
      <div className="table-wrapper__new">
        <table className="table-wrapper__table">
          <thead>
            <tr className="table-wrapper__header-tr h-10">
              <th className="table-wrapper__new-th table-wrapper__th--w-3">
                <div className="px-2">
                  <Tooltip tooltip="envelopesListActions.selectAll" redesign>
                    <label
                      htmlFor="select-all"
                      className="flex justify-start items-center w-full cursor-pointer"
                    >
                      <input
                        id="select-all"
                        data-testid="select-all"
                        className={`cursor-pointer form-checkbox litlingo-checkbox w-5 h-5 rounded-sm ${
                          selectedRows.length > 0 &&
                          selectedRows?.length < Object.keys(testCases).length
                            ? 'checkbox-select-all-partial'
                            : ''
                        }`}
                        type="checkbox"
                        onChange={handleSelectAllChange}
                        checked={testCases.length > 0 && selectedRows.length === testCases.length}
                      />
                    </label>
                  </Tooltip>
                </div>
              </th>
              <th className="table-wrapper__new-th table-wrapper__th--w-22">Test Case</th>
              <td className="table-wrapper__th table-wrapper__th--w-4" />
              {!history.location.pathname.includes('test-cases') && (
                <td className="table-wrapper__th table-wrapper__th--w-4" />
              )}
              <th className="table-wrapper__new-th table-wrapper__th--w-25">Expected</th>
              <th className="table-wrapper__new-th table-wrapper__th--w-18">Status</th>
              <th className="table-wrapper__new-th table-wrapper__th--w-16">Tags</th>
              <th className="table-wrapper__new-th table-wrapper__th--w-16">Created By</th>
              <th className="table-wrapper__new-th table-wrapper__th--w-16">Entity</th>
              <th
                aria-label="Delete"
                className="table-wrapper__th table-wrapper__th--w-4 lg:px-2 md:px-0"
              />
            </tr>
            {selectedRows?.length > 0 && (
              <MemoizedTestCaseListActionsRow
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            )}
          </thead>
          <tbody className="table-wrapper__tbody">
            {!loading && isEmpty ? (
              <EmptyTableState
                message="There are no tests to display"
                colCount={!history.location.pathname.includes('test-cases') ? '10' : '9'}
              />
            ) : (
              testCases &&
              testCases.map((testItem) => (
                <TestCaseListItem
                  key={testItem.uuid}
                  testItem={testItem}
                  handleClickOnDelete={handleClickOnDelete}
                  handleClickOnDuplicate={handleClickOnDuplicate}
                  handleCopyClick={handleCopyClick}
                  setSelectedTest={setSelectedTest}
                  setChangeEntityModal={setChangeEntityModal}
                  onRowSelect={handleRowSelect}
                  selected={selectedRows.includes(testItem.uuid)}
                />
              ))
            )}
          </tbody>
        </table>
        <ListFooter
          resourceName="test case"
          resourceStateName={resourceQueryParamName.testCase}
          getResourceLoading={getTestCasesLoading}
          getResourceList={getTestCases}
          getResourceTotalCount={getTestCasesTotalCount}
        />
        {isDeletingTestcase && idToBeDeleted && (
          <ConfirmModal
            text="Are you sure you want to delete this test case?"
            okButtonText="Yes"
            cancelButtonText="No"
            okButtonOnClick={handleDelete}
            toggleShowModal={toggleConfirmModal}
          />
        )}
        {isDuplicatingTestcase && (
          <ConfirmModal
            text="Are you sure you want to duplicate this test case?"
            title="Confirm Duplication"
            okButtonText="Yes"
            cancelButtonText="No"
            okButtonOnClick={handleDuplicate}
            toggleShowModal={toggleConfirmModal}
          />
        )}
        {changeEntityModal && (
          <Modal
            style={{ overflow: 'visible' }}
            body={
              selectedTest && (
                <div className="flex flex-row justify-center my-10">
                  <SelectAndSearchByResource
                    title="Choose test entity"
                    options={options}
                    cleanSearchValue={cleanSearchValue}
                    selectedEntity={selectedEntity}
                    entity={selectedTest.campaign || selectedTest.rule || selectedTest.annotator}
                    setSelectedId={setSelectedId}
                    selectedId={selectedId}
                    handleChangeEntity={handleChangeEntity}
                    maxHeight={300}
                  />
                </div>
              )
            }
            title="Change Entity"
            okButton
            okButtonText="Save"
            okButtonOnClick={saveEntityChanges}
            cancelButtonOnclick={toggleChangeEntityModal}
            toggleShowModal={toggleChangeEntityModal}
          />
        )}
      </div>
    </>
  );
};

export default TestCaseTable;
