import Modal from 'components/Modal';
import { MODAL_BACKGROUND } from 'constants/common';
import type * as H from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

type ComponentProps = {
  shouldBlockNavigation?: boolean;
  title?: string;
  text?: string;
  navigateButtontext?: string;
  cancelOrActionNavigateButtontext?: string;
  navigateAction?: () => boolean | void;
  dicardAction?: () => void;
};

const LeavePageModal: React.FC<ComponentProps> = (props) => {
  const {
    shouldBlockNavigation,
    title,
    text,
    navigateButtontext,
    cancelOrActionNavigateButtontext,
    navigateAction,
    dicardAction,
  } = props;

  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toLocation, setToLocation] = useState({ pathname: '/', search: '' });
  const [confirmNavigation, setConfirmNavigation] = useState(false);

  const handleLeavePage = (location: H.Location): boolean => {
    setToLocation(location);
    if (history.location.pathname === location.pathname) return true;
    if (!confirmNavigation && shouldBlockNavigation) {
      setIsModalOpen(true);
      return false;
    }
    return true;
  };

  const handleConfirmOnClick = (): void => {
    if (dicardAction) {
      dicardAction();
    }
    setIsModalOpen(false);
    setConfirmNavigation(true);
  };

  useEffect(() => {
    if (confirmNavigation || history.location.pathname === toLocation.pathname) {
      history.push(toLocation.pathname + toLocation.search);
    }
  }, [confirmNavigation, toLocation.pathname, history, toLocation.search]);

  const toggleShowModal = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  const handleOkButtonClick = (): void => {
    if (navigateAction) {
      const result = navigateAction();
      if (result === false) {
        setIsModalOpen(false);
        return;
      }
      handleConfirmOnClick();
    } else {
      setIsModalOpen(false);
    }
  };

  const renderBody = (): JSX.Element => (
    <>
      <div className="absolute top-5 left-0 right-0 h-40 z-0">{MODAL_BACKGROUND}</div>

      <div className="flex flex-col mt-30 mb-8 gap-2">
        <h2 className="text-heading-1">{title || 'You Have Unsaved Changes'}</h2>
        <p className="text-body">{text || 'Are you sure you want to leave without saving?'}</p>
      </div>
    </>
  );

  return (
    <>
      <Prompt
        message={(location): boolean => handleLeavePage(location)}
        when={shouldBlockNavigation || false}
      />
      {isModalOpen && (
        <Modal
          title=" "
          body={renderBody()}
          okButton
          okButtonText={cancelOrActionNavigateButtontext || 'Go back'}
          okButtonOnClick={handleOkButtonClick}
          okButtonStyle="whitespace-no-wrap w-full px-4 mr-4"
          cancelButtonText={navigateButtontext || 'Discard & Exit'}
          cancelButtonOnclick={handleConfirmOnClick}
          toggleShowModal={toggleShowModal}
        />
      )}
    </>
  );
};

export default LeavePageModal;
