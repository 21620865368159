import { ResponsiveTreeMapHtml } from '@nivo/treemap';
import { fetchTagsAnalytics } from 'actions';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTagsAnalytics, getTagsAnalyticsLoading } from 'selectors/analytics';
import logEvent from 'utils/analytics';

const TagAnalytics: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getTagsAnalyticsLoading);
  const tags = useSelector(getTagsAnalytics);

  useEffect(() => {
    dispatch(fetchTagsAnalytics());
  }, [dispatch]);

  const colors = {
    0: '#B6DDC6',
  };
  const getColors = (): string => colors[0];

  if (loading) {
    return <WidgetLoading />;
  }

  if (tags == null || (tags?.children.length === 0 && !loading)) {
    return <WidgetEmptyState />;
  }

  return (
    <div className="h-72 overflow-hidden font-bold">
      <ResponsiveTreeMapHtml
        data={tags}
        identity="name"
        value="total"
        valueFormat=".02s"
        margin={{ top: 0, right: 5, bottom: 0, left: 5 }}
        label="id"
        labelSkipSize={10}
        enableParentLabel={false}
        leavesOnly
        innerPadding={4}
        borderWidth={0}
        labelTextColor="#65A782"
        onMouseEnter={(): Promise<void> => logEvent('Dashboard-hover-tag-analytics')}
        colors={getColors}
      />
    </div>
  );
};

export default withWidget({
  title: 'Tag Analytics (beta)',
})(TagAnalytics);
