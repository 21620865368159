import { ARROW_CLOSE_ENVELOPE, ARROW_OPEN_ENVELOPE } from 'constants/envelopeIcons';
import React, { useState } from 'react';

type ComponentProps = {
  headerTitle: string;
  customAction?: JSX.Element;
  secondaryCustomAction?: JSX.Element;
  customLoading?: boolean;
  containerClasses?: string;
  children: JSX.Element;
  isDefaultClosed?: boolean;
  stickyHeader?: boolean;
  dataTestid?: string;
  headerClasses?: string;
  contentContainerClasses?: string;
};

const CollapsableCard: React.FC<ComponentProps> = ({
  headerTitle,
  customAction,
  secondaryCustomAction,
  customLoading,
  containerClasses,
  children,
  isDefaultClosed,
  dataTestid,
  headerClasses,
  stickyHeader,
  contentContainerClasses,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!!isDefaultClosed);

  const handleCollapse = (): void => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div
      data-testid={dataTestid || 'card'}
      className={`border border-litlingo-gray-1 rounded ${containerClasses || ''}`}
    >
      <div
        role="button"
        onClick={(e): void => {
          handleCollapse();
          e.stopPropagation();
        }}
        aria-hidden="true"
        className={`flex flex-row justify-between items-center h-13 px-4 bg-litlingo-gray-0.5 ${
          stickyHeader ? 'sticky top-0 z-10' : ''
        }`}
      >
        <div className="flex justify-center items-center gap-2">
          {isCollapsed ? ARROW_CLOSE_ENVELOPE() : ARROW_OPEN_ENVELOPE()}
          <h1 className={`text-heading-3 ${headerClasses}`}>{headerTitle}</h1>
          {secondaryCustomAction}
          {customLoading && (
            <div className="ml-4 flex float-left loader ease-linear rounded-full border-2 border-litlingo-gray h-4 w-4 self-center" />
          )}
        </div>

        {customAction}
      </div>

      {!isCollapsed && <div className="w-full border-t border-litlingo-gray-1" />}
      <div
        className={`litlingo-card-content relative z-0 ${isCollapsed ? 'litlingo-collapsed' : ''} ${
          contentContainerClasses || ''
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default CollapsableCard;
