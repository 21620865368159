import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';
import React from 'react';
import { useSelector } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import {
  getFetchAllRuleGroupsLoading,
  getRuleGroupsList,
  getRuleGroupsTotalCount,
} from 'selectors/ruleGroups';

const RuleGroupListCount: React.FC = () => {
  const rules = useSelector(getRuleGroupsList);
  const loadingAll = useSelector(getFetchAllRuleGroupsLoading);
  const rulesCount = useSelector(getRuleGroupsTotalCount);
  const navParams = useSelector(getNavParamsByResource(resourceQueryParamName.ruleGroups));
  const offset = parseInt(navParams.offset as string, 10);

  return (
    <div>
      <div className={`${loadingAll ? 'text-litlingo-gray-2' : ''}`}>
        <span className="font-bold">{` ${rules.length === 0 ? 0 : offset + 1}-${
          offset + rules.length
        }`}</span>
        {` of ${
          rulesCount >= 10000
            ? '+10,000'
            : pluralize('model'.split(' ').map(capitalize).join(' '), rulesCount, true)
        }`}
      </div>
    </div>
  );
};

export default RuleGroupListCount;
