import { fetchEventBodyComm } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { getRawCorrespondence } from 'selectors/events';
import type { UUID } from 'types';
import parseBody from 'utils/strings';

type ComponentProps = {
  eventId: UUID;
  commUuid: UUID | null;
};

const RawEmail: React.FC<ComponentProps> = ({ eventId, commUuid }) => {
  const dispatch = useDispatch();
  const rawCorrespondence = useSelector(getRawCorrespondence(eventId));

  const newLineWord = 'From:';
  const wordRegex = new RegExp(`${newLineWord}`, 'g');
  const replaceString = `&nbsp;\r\n___\r\n&nbsp;`;
  const finalRawCorrespondence = rawCorrespondence?.replace(
    wordRegex,
    `${replaceString}
    ${newLineWord}`
  );

  useEffect(() => {
    if (eventId && commUuid && !rawCorrespondence) {
      dispatch(fetchEventBodyComm({ eventId, commUuid }));
    }
  }, [commUuid, dispatch, eventId, rawCorrespondence]);

  const sliceRaw = (): string => {
    let result = finalRawCorrespondence;

    if (result) {
      if (result[0] === '"') {
        result = result.substring(1);
      }
      if (result[result.length - 1] === '"') {
        result = result.substring(0, result.length - 1);
      }
      return result;
    }

    return '';
  };

  return (
    <div className="flex flex-row flex-wrap items-end">
      {rawCorrespondence || rawCorrespondence === '' ? (
        <ReactMarkdown className="w-full" renderers={{ link: () => <></> }}>
          {parseBody(sliceRaw())}
        </ReactMarkdown>
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
};

export default RawEmail;
