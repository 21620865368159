import { fetchAnnotatorChangelog } from 'actions';
import { apiClient as LitLingoClient } from 'client';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import {
  fetchAnnotatorsAuditLogs,
  fetchAnnotatorsAuditLogsFailure,
  fetchAnnotatorsAuditLogsFulfill,
  fetchAnnotatorsAuditLogsSuccess,
} from 'reducers/annotatorsAuditLogs';
import {
  fetchChangelogFailure,
  fetchChangelogFulfill,
  fetchChangelogRequest,
  fetchChangelogSuccess,
} from 'reducers/auditLogs';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getNavParamsByResource } from 'selectors/nav';
import type { API, ErrorData, SagaReturn } from 'types';

type FetchAction = ReturnType<typeof fetchAnnotatorsAuditLogs>;
function* fetchAnnotatorsAuditLogsSaga(action: FetchAction): SagaReturn {
  try {
    const resourceParams = (yield select(
      getNavParamsByResource(resourceQueryParamName.auditLogs)
    )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

    const params = {
      ...resourceParams,
      ...action.payload,
      include_pii: 'true',
    };

    const response = (yield call([LitLingoClient.resources.annotators.extras, 'auditLogs'], {
      params,
    })) as API.Response<API.Annotators.AuditLogs>;
    if (response.error != null) {
      yield put(fetchAnnotatorsAuditLogsFailure(response.error));
    } else {
      yield put(fetchAnnotatorsAuditLogsSuccess(response.data));
    }
  } catch (error) {
    const errorData = error as ErrorData;
    yield put(fetchAnnotatorsAuditLogsFailure(errorData));
  } finally {
    yield put(fetchAnnotatorsAuditLogsFulfill());
  }
}

function* fetchAnnotatorChangelogSaga(
  action: ReturnType<typeof fetchAnnotatorChangelog>
): SagaReturn {
  yield put(fetchChangelogRequest());

  const { version, auditlogUuid, annotatorId } = action.payload;

  const response = (yield call([LitLingoClient.resources.annotators.extras, 'changelog'], {
    urlParams: { annotatorId, version },
  })) as API.Response<API.Customers.Changelog>;

  if (response.error != null) {
    yield put(
      fetchChangelogFailure({ ...response.error, message: response.error.message, auditlogUuid })
    );
  } else {
    yield put(fetchChangelogSuccess({ auditlogUuid, changelog: response.data.payload }));
  }
  yield put(fetchChangelogFulfill());
}

function* annotatorsAuditLogsSaga(): SagaReturn {
  yield takeLatest(fetchAnnotatorsAuditLogs, fetchAnnotatorsAuditLogsSaga);
  yield takeLatest(fetchAnnotatorChangelog, fetchAnnotatorChangelogSaga);
}

export default annotatorsAuditLogsSaga;
