import TestSentenceVisualizerSidebar from 'components/TestSentenceVisualizer/TestSentenceVisualizerSidebar';
import React from 'react';

const TestPhraseSidebarSection: React.FC = () => (
  <div className="px-3 pt-6 pb-4">
    <TestSentenceVisualizerSidebar />
  </div>
);

export default TestPhraseSidebarSection;
