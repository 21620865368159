import ProgressBar from 'components/ProgressBar';
import Tooltip from 'components/Tooltip';
import withWidget from 'decorators/widget';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import React from 'react';
import { DefaultWidgetComponentProps } from 'types';
import logEvent from 'utils/analytics';

const ProgressBarWidget: React.FC<DefaultWidgetComponentProps> = (props) => {
  const { widgetData, queryData, subtitle } = props;

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  // denominator is first element, every other metric is summed into numerator
  const [first, ...rest] = [...widgetData.results].reverse();
  const numerator = rest.reduce(
    (acc, data) => acc + data.y_axis.reduce((prevValue, value) => prevValue + value, 0),
    0
  );
  const denominator = first.y_axis.reduce((prevValue, value) => prevValue + value, 0);
  // when more than 2 elements we want denominator to be a total of all in the group
  // when only two we are comparing 2 hard values
  const percentage = numerator / (rest.length > 1 ? numerator + denominator : denominator);
  const rounded = Math.round(percentage * 100);

  return (
    <div>
      {'results' in widgetData && (
        <div key={queryData.queries[0]?.label} className="-mt-10">
          <div className="gap-3 mt-2 pt-6 items-center ">
            <div>
              <div onMouseEnter={(): Promise<void> => logEvent('Dashboard-hover-progress-bar')}>
                <Tooltip content={`${rounded}%`}>
                  <ProgressBar
                    completed={rounded}
                    height="h-6"
                    bgcolor2="bg-litlingo-primary-80"
                    bgcolor="bg-litlingo-gray-1"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="pt-1 flex flex-row justify-end">
            {/* TODO: add a tooltip param to widget config */}
            {/* <Tooltip content="Active users who have triggered an event"> */}
            <div
              className="flex flex-row"
              onMouseEnter={(): void => {
                logEvent('Dashboard-hover-progress-bar-data');
              }}
            >
              <div className="ml-2 text text-litlingo-gray-6 text-base font-normal line-height-100">
                <b>{numerator}</b> out of{' '}
                <b>{rest.length > 1 ? numerator + denominator : denominator}</b> {subtitle}
              </div>
            </div>
            {/* </Tooltip> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default withWidget({
  fetchOnePeriod: true,
  title: 'Risk',
  containerClassName: 'pl-2',
  customSubtitle: true,
})(ProgressBarWidget);
