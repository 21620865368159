import { ReviewValueConfigType } from 'types';

export const labelOptions = {
  pending: 'Pending',
  violation: 'Out of Policy',
  acceptable: 'Acceptable',
};

export const newToOldLabel = {
  pending: 'NEW',
  violation: 'contains_issues',
  acceptable: 'no_issue',
};

export const oldToNewLabel = {
  NEW: 'pending',
  contains_issues: 'violation',
  no_issue: 'acceptable',
} as const;

const envelopeReviewLabelOptions: Partial<ReviewValueConfigType> = {
  pending: 'Pending',
  in_policy: 'Acceptable',
  out_of_policy: 'Out of Policy',
  escalated: 'Escalated',
  skipped: 'Skipped',
};

export const MESSAGE_TYPES = {
  ABRIDGED: 'ABRIDGED',
  WHOLE_SENTENCE: 'WHOLE_SENTENCE',
  RAW_CORRESPONDENCE: 'RAW_CORRESPONDENCE',
};

export default envelopeReviewLabelOptions;
