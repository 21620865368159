/* eslint-disable max-lines */
import { UUID } from '@litlingo/client';
import { clearURLParams, deleteModel } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
// import EditModelModal from 'components/Model/EditModelModal';
import GlobalNavbar from 'components/Navbar/GlobalNavbar';
import Permissions from 'components/Permissions';
import SearchInput from 'components/SearchInput';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useModels from 'hooks/models/useModels';
import React, { useEffect, useState } from 'react';

import QueryNavbar from 'components/Navbar/QueryNavbar';
import { useDispatch } from 'react-redux';
import logEvent from 'utils/analytics';
import CreateModelModal from './CreateModelModal';
import ModelListCount from './ModelListCount';
import ModelTable from './ModelTable';

export const reviewManagementNavigationOptions = [
  {
    label: 'Production',
    value: 'production',
    route: 'global-production-models',
  },
  {
    label: 'Development',
    value: 'development',
    route: 'global-development-models',
  },
  {
    label: 'Testing',
    value: 'testing',
    route: 'global-testing-models',
  },
  {
    label: 'All',
    value: 'uncategorized',
    route: 'global-models-list',
  },
];

const ModelsList: React.FC = () => {
  const dispatch = useDispatch();

  const { data } = useModels({ redirectOnError: true });

  const [isCreatingModel, setIsCreatingModel] = useState(false);

  const [selectedModels, setSelectedModels] = useState<UUID[]>([]);
  const [showModels, setShowModels] = useState(false);
  const [isDeletingModel, setIsDeletingModel] = useState(false);

  useEffect(() => {
    if (!selectedModels.length) {
      setShowModels(false);
    }
  }, [selectedModels]);

  const toggleConfirmModal = (): void => setIsDeletingModel(!isDeletingModel);

  const handleCreateNew = async (): Promise<void> => {
    setIsCreatingModel(!isCreatingModel);
  };

  const handleResetFiltersClick = (): void => {
    logEvent('use-case-reset-filters');
    dispatch(clearURLParams());
  };

  const handleModelSelect = (checked: boolean, uuid: UUID): void => {
    if (checked) {
      setSelectedModels((selected) => [...selected, uuid]);
    } else {
      setSelectedModels((selected) => selected.filter((id) => id !== uuid));
    }
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();

    if (selectedModels.length === data?.records.length) {
      setSelectedModels([]);
    } else {
      setSelectedModels(data?.records.map((model) => model.uuid) || []);
    }
  };

  const handleDelete = (): void => {
    dispatch(deleteModel({ id: selectedModels[0] }));
    setIsDeletingModel(false);
    setSelectedModels([]);
  };

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <GlobalNavbar />
      <div className="pt-8">
        <div>
          <header>
            <div className="max-w-7xl mx-auto px-6">
              <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="flex gap-x-6 min-w-0">
                  <div className="flex flex-row items-center">
                    <h2 className="title">Use Cases</h2>
                  </div>
                  <div className="pt-4">
                    <QueryNavbar options={reviewManagementNavigationOptions} />
                  </div>
                </div>
                <div className="flex flex-row gap-x-2">
                  <Permissions action="models.create">
                    <button
                      onClick={handleCreateNew}
                      type="button"
                      data-testid="create-model-button"
                      className="flex flex-row justify-center button button--primary h-8 w-30  shadow-sm rounded"
                    >
                      <span>Add New</span>
                    </button>
                  </Permissions>
                </div>
              </div>

              <div className="flex flex-row flex-shrink-0  justify-between mt-4">
                <div className="flex flex-row gap-x-2 h-8">
                  <SearchInput
                    resource={resourceQueryParamName.model}
                    field="name"
                    logEventMessage="use-case-search"
                    className="h-8"
                    placeholder="Search Use Cases"
                  />
                  <button
                    type="button"
                    className="bg-white border-0 focus:outline-none inline-flex items-end leading-4 text text-body text-litlingo-gray-6 hover:text-litlingo-gray-4 underline"
                    data-testid="reset-filters-button"
                    onClick={handleResetFiltersClick}
                  >
                    Clear Filters
                  </button>
                </div>
                <ModelListCount />
              </div>
            </div>
          </header>
        </div>
        <main className="z-10">
          <ModelTable
            showModels={showModels}
            selectedModels={selectedModels}
            handleModelSelect={handleModelSelect}
            handleSelectAll={handleSelectAll}
            setSelectedModels={setSelectedModels}
          />
        </main>
      </div>
      {isCreatingModel && (
        <CreateModelModal isModalOpen={isCreatingModel} toggleModalOpen={handleCreateNew} />
      )}

      {isDeletingModel && (
        <ConfirmModal
          text="Are you sure you want to delete this Use Case?"
          okButtonText="Yes"
          cancelButtonText="No"
          data-testid="delete-modal"
          okButtonOnClick={handleDelete}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </div>
  );
};

export default ModelsList;
