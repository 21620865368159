import { getEmailTemplate, notifyEventAuthor, renderEmailTemplate } from 'actions/notifications';
import {
  APIRule,
  CommunicationEnvelope,
  EmailTemplate,
  RenderedEmailTemplate,
  Selector,
} from 'types';
import { getMatchedSentencesFromEvent } from './events';

export const getEmailTemplateLoading: Selector<boolean> = (state) =>
  state.notifications.loading.includes(getEmailTemplate.toString());

export const getEmailTemplateById =
  (id: string): Selector<EmailTemplate | null> =>
  (state): EmailTemplate | null => {
    const { notifications } = state;
    if (notifications.emailTemplates[id] == null) {
      return null;
    }
    return notifications.emailTemplates[id];
  };

export const getRenderEmailTemplateLoading: Selector<boolean> = (state) =>
  state.notifications.loading.includes(renderEmailTemplate.toString());

export const getRenderedEmailTemplate =
  (id: string): Selector<RenderedEmailTemplate | null> =>
  ({ notifications }): RenderedEmailTemplate | null => {
    if (notifications.renderedTemplates[id] == null) {
      return null;
    }

    return notifications.renderedTemplates[id];
  };

export const getNotifyEventAuthorLoading: Selector<boolean> = (state) =>
  state.notifications.loading.includes(notifyEventAuthor.toString());

export const getEventsDataFromEnvelopeRules =
  (
    envelope: CommunicationEnvelope,
    rules: APIRule[]
  ): Selector<Array<{ phrase: string | null; rule_notify: string | null }>> =>
  (state): Array<{ phrase: string | null; rule_notify: string | null }> => {
    if (!envelope.events) {
      return [];
    }
    return envelope.events.map(({ uuid, rule_uuid: ruleUuid }) => {
      const phrase = getMatchedSentencesFromEvent(uuid)(state);
      const rule = rules.find((item) => item.uuid === ruleUuid);

      return {
        phrase,
        // @ts-ignore
        rule_notify: rule?.notify_users_copy || null,
      };
    });
  };
