import { receiveNewKeyword } from 'actions/identifier';
import ToggleButton from 'components/ToggleButton';
import { possibleTypes } from 'constants/annotator';
import { PLUS_ADD_FIELD_ICON } from 'constants/annotatorIcons';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import type { LanguageMatcherFormChoices } from 'types';
import AnnotatorMultiSelectDropdown from '../AnnotatorMultiSelectDropdown';

type ComponentProps = {
  inputType: string;
  inputValue: string | number | boolean | string[] | null | undefined;
  inputChoices: LanguageMatcherFormChoices[] | null;
  form: 'status' | 'name' | 'description' | 'uuid' | 'annotator_uuid' | 'type' | 'customer_uuids';
  type: 'keyword' | 'token' | 'token_with_modifier' | 'adjacent_tokens' | 'url' | 'regex';
  languageMatcherId: string;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
};

const RenderForm: React.FC<ComponentProps> = ({
  inputType,
  inputValue,
  inputChoices,
  form,
  type,
  languageMatcherId,
  setError,
}) => {
  const dispatch = useDispatch();
  const { register, getValues, setValue } = useForm({ mode: 'onSubmit' });
  const [isOpen, setIsOpen] = useState(false);
  const [isInputTrue, setIsInputTrue] = useState<string | number | string[] | boolean>(false);
  const [intValue, setIntValue] = useState<string | number | boolean | string[]>('');
  const [formEditing, setFormEditing] = useState(false);
  const myInputRef = useRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (formEditing) {
      if (myInputRef.current != null) {
        myInputRef.current.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formEditing, JSON.stringify(inputValue || {})]);

  useEffect(() => {
    if (!inputType || inputValue == null) return;
    if (inputType === 'bool') setIsInputTrue(inputValue);
    if (inputType === 'int') setIntValue(inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputType, inputValue]);

  const handleAddKeywordClick = (keyword?: string | boolean): void => {
    let value: undefined | string | boolean | number | (undefined | string | boolean | number)[] =
      '';
    switch (inputType) {
      case possibleTypes.multiSelect:
        value = [keyword];
        break;
      case possibleTypes.bool:
      case possibleTypes.singleSelect:
        value = keyword;
        break;
      case possibleTypes.int:
        if (typeof keyword === 'string') {
          value = parseInt(keyword, 10);
        }
        break;
      default:
        value = getValues(form).trim().split('\n');
        break;
    }

    if (Array.isArray(value) && inputValue && typeof inputValue === 'object') {
      if (
        inputType === possibleTypes.freeForm &&
        inputValue.includes(value[0] as string) &&
        value.length <= 1
      ) {
        setError('This item is already in the list');
        return;
      }
    }

    if (value === '' && !Array.isArray(value)) {
      setError('Field is Required');
      return;
    }

    if (Array.isArray(value) && value[0] === '') {
      if (inputValue && typeof inputValue === 'object' && !inputValue[0]) {
        setError('Field is Required');
      }
      return;
    }

    setError(null);
    setFormEditing(true);

    dispatch(
      receiveNewKeyword({
        languageMatcherId,
        parentType: type,
        data: {
          key: form,
          value,
        },
      })
    );

    dispatch(
      receiveNewKeyword({
        languageMatcherId,
        parentType: type,
        data: {
          key: 'saved',
          value: false,
        },
      })
    );

    setValue(form, '');
  };

  const keyPressed = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddKeywordClick();
    }
  };

  const numberValidation = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    // eslint-disable-next-line no-restricted-globals
    const isInteger = Number(e.key);
    if (!isInteger) {
      setError('You should type only numbers in this field');
    }
  };

  const handleToggleClick = (): void => {
    const newVal = !isInputTrue;
    setIsInputTrue(newVal);
    handleAddKeywordClick(newVal);
  };

  const handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIntValue(e.target.value);
    handleAddKeywordClick(e.target.value);
  };

  const FreeForm = (): JSX.Element => (
    <div className="flex rounded-md shadow-sm">
      <div className="relative flex-grow focus-within:z-10">
        <textarea
          id={form}
          data-testid={`${form}-textarea`}
          name={form}
          ref={(e): void => {
            if (e != null) {
              register(e, { required: true });
              // @ts-ignore
              myInputRef.current = e;
            }
          }}
          rows={1}
          className="form-input block w-full rounded-none rounded-l-md transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          onKeyPress={(e): void => keyPressed(e)}
          onBlur={(): void => handleAddKeywordClick()}
        />
      </div>
      <button
        type="button"
        data-testid={`${form}-add-button`}
        onClick={(): void => handleAddKeywordClick()}
        className="litlingo-annotator-add-button-field flex justfy-center items-center focus:outline-none"
      >
        <span className="px-3">{PLUS_ADD_FIELD_ICON}</span>
      </button>
    </div>
  );

  const SelectSingleType = (): JSX.Element => (
    <div>
      <select
        onChange={(e): void => handleAddKeywordClick(e.target.value)}
        ref={(e): void => {
          if (e != null) {
            register(e, { required: true });
            // @ts-ignore
            myInputRef.current = e;
          }
        }}
        data-testid={`${type}-select-single`}
        name={form}
        value={inputValue as string}
        id={form}
        className="block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
      >
        {inputChoices != null &&
          inputChoices.map((c) => (
            <option key={c.value} value={c.value}>
              {c.name}
            </option>
          ))}
      </select>
    </div>
  );

  const IntType = (): JSX.Element => (
    <div className="flex rounded-md shadow-sm">
      <div className="relative flex-grow focus-within:z-10">
        <input
          id={form}
          name={form}
          type="number"
          value={intValue as number}
          onChange={(e): void => handleChangeNumber(e)}
          ref={(e): void => {
            if (e != null) {
              register(e, { required: true });
              // @ts-ignore
              myInputRef.current = e;
            }
          }}
          className="form-input block w-full rounded-none rounded-l-md transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          onKeyPress={(e): void => {
            numberValidation(e);
          }}
        />
      </div>
    </div>
  );

  switch (inputType) {
    case possibleTypes.multiSelect: {
      const selectedItems: string[] = (inputValue as string[]) || [];
      if (inputChoices != null) {
        return (
          <AnnotatorMultiSelectDropdown
            onChange={handleAddKeywordClick}
            options={inputChoices.map((c) => ({ value: c.value, label: c.name }))}
            selectedItems={selectedItems}
            dataTestid={`${form}-language-matcher`}
            className="w-full"
            justify="justify-between"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        );
      }
      return <></>;
    }
    case possibleTypes.singleSelect:
      return SelectSingleType();
    case possibleTypes.bool: {
      return <ToggleButton isOn={isInputTrue as boolean} handleToggleClick={handleToggleClick} />;
    }
    case possibleTypes.int: {
      return IntType();
    }
    case possibleTypes.freeForm: {
      return FreeForm();
    }
    default:
      return IntType();
  }
};

export default RenderForm;
