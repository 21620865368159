import { addValueToTree, removeFieldFromTree } from 'actions';
import SelectRedesign from 'components/Select/SelectRedesign';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getNavParamsByResource, getNavParamsFromTree } from 'selectors/nav';
import { useSelector } from 'store';

interface DaysOutOfRetentionFilterProps {
  className?: string;
  resource: string;
}

enum DateOption {
  initial = '',
  day = '1',
  week = '7',
  month = '30',
}

type TreeParams = {
  date_range: string[];
  days_from_oor: string[];
};

export const datesOutOfRetention: Record<string, string> = {
  [DateOption.day.toString()]: 'Day',
  [DateOption.week.toString()]: 'Week',
  [DateOption.month.toString()]: 'Month',
};

const DaysOutOfRetentionFilter: FunctionComponent<DaysOutOfRetentionFilterProps> = ({
  resource,
  className,
}) => {
  const dispatch = useDispatch();
  const navParams = useSelector(getNavParamsByResource(resource));

  const treeParams = useSelector(getNavParamsFromTree) as TreeParams;
  const { date_range: dateRange, days_from_oor: dOorParam } = treeParams;

  const [timeFromOorTree] = dOorParam || [];
  const timeFromOor = timeFromOorTree || (navParams.days_from_oor as string) || '';
  const [createdAfter, createdBefore] =
    dateRange?.[0].split('<>') || [
      navParams.created_after as string,
      navParams.created_before as string,
    ] ||
    [];

  useEffect(() => {
    if (!timeFromOor) return;
    if (createdAfter || createdBefore) {
      dispatch(removeFieldFromTree({ field: 'days_from_oor' }));
    }
  }, [createdAfter, createdBefore, dispatch, timeFromOor]);

  const handleDateOptionChanged = (dateOpt: string): void => {
    if (dateOpt === timeFromOor) {
      dispatch(removeFieldFromTree({ field: 'days_from_oor' }));
      return;
    }
    if (dateRange) {
      dispatch(
        removeFieldFromTree({
          field: 'date_range',
        })
      );
    }

    dispatch(
      addValueToTree({
        field: 'days_from_oor',
        value: dateOpt,
        isSingleValue: true,
        label: datesOutOfRetention[dateOpt],
      })
    );
  };
  return (
    <div className={`flex flex-col gap-2 mt-2 shadow-sm ${className || ''}`}>
      <div className="bg-litlingo-success-light bg-opacity-10 rounded ">
        <SelectRedesign
          className="bg-transparent border-none"
          valueClassName="text-white"
          optionClassname="text-litlingo-gray-6"
          onChange={(value): void => {
            handleDateOptionChanged(value);
          }}
          options={Object.entries(datesOutOfRetention).map(([value, label]) => ({ value, label }))}
          value={timeFromOor ?? DateOption.initial}
          placeholder="Select a date range"
        />
      </div>
    </div>
  );
};

export default DaysOutOfRetentionFilter;
