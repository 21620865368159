/* eslint-disable max-lines */

import LoadingIndicator from 'components/LoadingIndicator';
import Tooltip from 'components/Tooltip';
import { REVIEW_SET_EXPLORE_ICON } from 'constants/reviewSets';
import React from 'react';

type ComponentProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  text: string;
  loading?: boolean;
  tooltip?: string;
};

const ExploreButton: React.FC<ComponentProps> = ({ onClick, text, loading = false, tooltip }) => {
  const button = (
    <button
      type="button"
      className="button--task-button"
      data-testid="task-button"
      onClick={onClick}
    >
      <div className="w-8">{loading ? <LoadingIndicator size="5" /> : REVIEW_SET_EXPLORE_ICON}</div>
      <div>{text}</div>
    </button>
  );

  if (tooltip) return <Tooltip tooltip={tooltip}>{button}</Tooltip>;

  return button;
};
export default ExploreButton;
