import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import type {
  API,
  ErrorObject,
  MOutcome,
  NormalizedResource,
  Outcome,
  OutcomeTypes,
  SortField,
  UUID,
} from 'types';
import {
  cleanOutcomes,
  createOutcomeFailure,
  createOutcomeRequest,
  createOutcomeSuccess,
  fetchOutcomesFailure,
  fetchOutcomesRequest,
  fetchOutcomesSuccess,
  fetchOutcomeTypesFailure,
  fetchOutcomeTypesRequest,
  fetchOutcomeTypesSuccess,
  fetchSingleOutcomeFailure,
  fetchSingleOutcomeRequest,
  fetchSingleOutcomeSuccess,
  removeOutcome,
} from '../actions';

type OutcomeState = {
  outcomes: NormalizedResource<Outcome>;
  activeOutcomes: NormalizedResource<MOutcome>;
  loading: string[];
  types: OutcomeTypes;
  error: ErrorObject | null;
  sort: SortField;
};

export type OutcomeReducer<P = void> = (
  state: OutcomeState,
  payload: PayloadAction<P>
) => OutcomeState;

const defaultState: OutcomeState = {
  outcomes: {},
  types: {} as OutcomeTypes,
  activeOutcomes: {},
  error: null,
  loading: [],
  sort: {
    field: 'created_at',
    desc: true,
  },
};

export const handleCreateOutcomeRequest: OutcomeReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, createOutcomeRequest.toString()],
});

export const handleCreateOutcomeSuccess: OutcomeReducer<NormalizedResource<MOutcome>> = (
  state,
  { payload }
) => ({
  ...state,
  loading: state.loading.filter((s) => s !== createOutcomeRequest.toString()),
  activeOutcomes: {
    ...state.activeOutcomes,
    ...payload,
  },
});

export const handleCreateOutcomeFailure: OutcomeReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== createOutcomeRequest.toString()),
});

export const handleFetchOutcomesRequest: OutcomeReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchOutcomesRequest.toString()],
});

export const handleFetchOutcomesSuccess: OutcomeReducer<API.WrappedAPIResponse<Outcome>> = (
  state,
  { payload }
) => {
  const normalizedOutcomes: NormalizedResource<Outcome> = {};
  payload.records.forEach((outcome) => {
    const currentOutcome = state.outcomes[outcome.uuid] || {};
    normalizedOutcomes[outcome.uuid] = { ...currentOutcome, ...outcome };
  });

  return {
    ...state,
    outcomes: normalizedOutcomes,
    loading: state.loading.filter((s) => s !== fetchOutcomesRequest.toString()),
  };
};

export const handleFetchOutcomesFailure: OutcomeReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchOutcomesRequest.toString()),
});

export const handleFetchSingleOutcomeRequest: OutcomeReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchSingleOutcomeRequest.toString()],
});

export const handleFetchSingleOutcomeSuccess: OutcomeReducer<Outcome> = (state, { payload }) => ({
  ...state,
  outcomes: {
    ...state.outcomes,
    [payload.uuid]: {
      ...(state.outcomes[payload.uuid] || {}),
      ...payload,
    },
  },
  loading: state.loading.filter((s) => s !== fetchSingleOutcomeRequest.toString()),
});

export const handleFetchSingleOutcomeFailure: OutcomeReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchSingleOutcomeRequest.toString()),
});

export const handleFetchOutcomeTypesRequest: OutcomeReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchOutcomeTypesRequest.toString()],
});

export const handleFetchOutcomeTypesSuccess: OutcomeReducer<OutcomeTypes> = (
  state,
  { payload }
) => ({
  ...state,
  types: {
    ...payload,
  },
  loading: state.loading.filter((s) => s !== fetchOutcomeTypesRequest.toString()),
});

export const handleFetchOutcomeTypesFailure: OutcomeReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchOutcomeTypesRequest.toString()),
});

export const handleCleanOutcomes: OutcomeReducer = (state) => ({
  ...state,
  activeOutcomes: {},
});

export const handleRemoveOutcome: OutcomeReducer<UUID> = (state, { payload }) => {
  const items = { ...state.activeOutcomes };
  delete items[payload];

  return {
    ...state,
    activeOutcomes: items,
  };
};

const handlers = {
  [createOutcomeRequest.toString()]: handleCreateOutcomeRequest,
  [createOutcomeSuccess.toString()]: handleCreateOutcomeSuccess,
  [createOutcomeFailure.toString()]: handleCreateOutcomeFailure,
  [fetchOutcomesRequest.toString()]: handleFetchOutcomesRequest,
  [fetchOutcomesSuccess.toString()]: handleFetchOutcomesSuccess,
  [fetchOutcomesFailure.toString()]: handleFetchOutcomesFailure,
  [fetchSingleOutcomeRequest.toString()]: handleFetchSingleOutcomeRequest,
  [fetchSingleOutcomeSuccess.toString()]: handleFetchSingleOutcomeSuccess,
  [fetchSingleOutcomeFailure.toString()]: handleFetchSingleOutcomeFailure,
  [fetchOutcomeTypesRequest.toString()]: handleFetchOutcomeTypesRequest,
  [fetchOutcomeTypesSuccess.toString()]: handleFetchOutcomeTypesSuccess,
  [fetchOutcomeTypesFailure.toString()]: handleFetchOutcomeTypesFailure,
  [cleanOutcomes.toString()]: handleCleanOutcomes,
  [removeOutcome.toString()]: handleRemoveOutcome,
};

const annotatorReducer = createReducer(defaultState, handlers);

export default annotatorReducer;
