import React from 'react';

type AlertProps = {
  children: React.ReactNode;
};

const PolicyAmountAlert: React.FC<AlertProps> = ({ children }) => {
  const className = 'litlingo__alert__policy__amount';

  return (
    <div
      className={`flex flex-row items-center p-2 rounded-sm text-xl w-full pr-8 ${className}`}
    >
      <div className="font-bold pl-6">{children}</div>
    </div>
  );
};

export default PolicyAmountAlert;
