import AssignmentList from 'components/AssignmentList';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import React from 'react';
import { useSelector } from 'react-redux';
import { getAssignmentsLoading } from 'selectors/assignments';

const AssignmentsManager: React.FC = () => {
  const loading = useSelector(getAssignmentsLoading);

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />
      <div className="py-10">
        <div>
          <header>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                  <div className="flex flex-row items-center">
                    <h2 className="text-left text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
                      Assignments{' '}
                    </h2>
                    {loading && <LoadingIndicator className="ml-2" size="5" />}
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
        <main>
          <div className="mt-10 mb-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-left">
            <AssignmentList />
          </div>
        </main>
      </div>
    </div>
  );
};

export default AssignmentsManager;
