import { logoutUser } from 'actions';
import { DOWNLOAD_LITLINGO_LOGO, LEFT_DOTS, RIGHT_DOTS, STOP_ICON } from 'constants/common';

import React from 'react';
import { useDispatch } from 'react-redux';

const NotAuthorizedPage: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <div className="relative flex justify-center items-center flex-col h-screen">
      <div className="absolute top-8 left-8">{DOWNLOAD_LITLINGO_LOGO}</div>
      <div className="absolute top-10 left-24">{LEFT_DOTS}</div>
      <div className="absolute top-9/20 right-8">{RIGHT_DOTS}</div>
      <div>{STOP_ICON}</div>
      <h1 className="text-2xl font-bold text-center mt-9">
        You are not authorized to use this tool
      </h1>
      <h2 className="text-base text-center text-litlingo-gray-6 mt-2 w-104">
        Contact your customer administrator or support@litlingo.com for help. We hope to see you
        soon!
      </h2>
      <div className="flex flex-row mt-6">
        <div>
          <button
            onClick={(): void => {
              dispatch(logoutUser());
            }}
            type="button"
            data-skip
            className="flex justify-center button button--primary w-37 h-8"
          >
            <span>Sign out</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotAuthorizedPage;
