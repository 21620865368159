import SummaryTag, { SummaryTagType } from 'components/Envelope/SummaryTag';
import Tooltip from 'components/Tooltip';
import { keyActionsIconsEventsRedesign, LINE_ICON } from 'constants/envelopeIcons';
import React from 'react';

type ComponentProps = {
  keys: string[];
  envelopeUuid: string;
  summaryTag: SummaryTagType;
  platform: string;
};

const KeyActionsIconsRedesign: React.FC<ComponentProps> = ({
  keys,
  envelopeUuid,
  summaryTag,
  platform,
}) => {
  const icons = keyActionsIconsEventsRedesign;

  const finalIcons: (keyof typeof icons)[] = [];

  if (keys.some((k) => k === 'show_modal')) {
    finalIcons.push('show_modal');
  }
  if (keys.some((k) => k === 'back_to_edit')) {
    finalIcons.push('back_to_edit');
  }
  if (summaryTag === 'reduced-all' || summaryTag === 'reduced-some') {
    finalIcons.push('sent_within_policy');
  }
  if (summaryTag === 'high-risk' && platform !== 'o365_teams') {
    finalIcons.push('sent_out_of_policy');
  }
  if (summaryTag === 'abandoned') {
    finalIcons.push('abandoned');
  }

  return (
    <span className="flex items-center">
      {finalIcons.map((key, idx) => {
        if (idx > 0) {
          return (
            <React.Fragment key={`${key}-${envelopeUuid}`}>
              <span className="w-2 mx-1">{LINE_ICON}</span>

              <Tooltip
                tooltip={`envelopeKeyActionsIcons.${key}`}
                component={<SummaryTag type={summaryTag} />}
                redesign
              >
                <div className="w-3">{icons[key]}</div>
              </Tooltip>
            </React.Fragment>
          );
        }

        return (
          <Tooltip
            key={`${key}-${envelopeUuid}`}
            component={<SummaryTag type={summaryTag} />}
            tooltip={`envelopeKeyActionsIcons.${key}`}
            redesign
          >
            <div className="w-3">{icons[key]}</div>
          </Tooltip>
        );
      })}
    </span>
  );
};

export default KeyActionsIconsRedesign;
