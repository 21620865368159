import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  addInsightUser,
  addPreventUser,
  addRolesUser,
  addTeamsUser,
  applyChangesToUser,
  changePrivacyLevelUser,
  removeInsightUser,
  removePreventUser,
  removeRolesUser,
  removeTeamsUser,
  selectUser,
} from 'actions/userProfilePage';
import type { ErrorObject, Team, User } from 'types';
import { userRolesToTypes } from 'utils/userRoles';

type UserState = {
  loading: string[];
  error: ErrorObject | null;
  selectedUser: User | null;
  teams: Team[];
  roles: string[];
  insight: string[];
  prevent: string[];
  privacyLevel: User['privacy_level'];
};

type UserReducer<P = void> = (state: UserState, payload: PayloadAction<P>) => UserState;

const defaultState: UserState = {
  loading: [],
  error: null,
  selectedUser: null,
  teams: [],
  roles: [],
  insight: [],
  prevent: [],
  privacyLevel: 'standard',
};

const handleSelectUser: UserReducer<User> = (state, { payload }) => {
  const insight = [];

  if (payload.insight_active_email) {
    insight.push('email');
  }
  if (payload.insight_active_chat) {
    insight.push('chat');
  }

  const prevent = [];

  if (payload.prevent_active_email) {
    prevent.push('email');
  }
  if (payload.prevent_active_chat) {
    prevent.push('chat');
  }

  return {
    ...state,
    selectedUser: payload,
    privacyLevel: payload.privacy_level,
    roles: userRolesToTypes(payload.roles).map((t) => t.label),
    teams: payload.teams || [],
    insight,
    prevent,
  };
};

const handleAddTeams: UserReducer<Team[]> = (state, { payload }) => ({
  ...state,
  teams: [...state.teams, ...payload],
});

const handleRemoveTeams: UserReducer<Team[]> = (state, { payload }) => ({
  ...state,
  teams: state.teams.filter((t) => payload.some((team) => team.uuid !== t.uuid)),
});

const handleAddRoles: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  roles: [...state.roles, ...payload],
});

const handleRemoveRoles: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  roles: state.roles.filter((r) => payload.some((role) => role !== r)),
});

const handleAddInsight: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  insight: [...state.insight, ...payload],
});

const handleRemoveInsight: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  insight: state.insight.filter((i) => payload.some((insight) => insight !== i)),
});

const handleAddPrevent: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  prevent: [...state.prevent, ...payload],
});

const handleRemovePrevent: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  prevent: state.prevent.filter((p) => payload.some((prevent) => prevent !== p)),
});

const handleChangePrivacyLevel: UserReducer<User['privacy_level']> = (state, { payload }) => ({
  ...state,
  privacyLevel: payload,
});

const handleApplyChanges: UserReducer<User> = (state, { payload }) => ({
  ...state,
  selectedUser: payload,
});

const handlers = {
  [selectUser.toString()]: handleSelectUser,

  [addTeamsUser.toString()]: handleAddTeams,
  [removeTeamsUser.toString()]: handleRemoveTeams,

  [addRolesUser.toString()]: handleAddRoles,
  [removeRolesUser.toString()]: handleRemoveRoles,

  [addInsightUser.toString()]: handleAddInsight,
  [removeInsightUser.toString()]: handleRemoveInsight,

  [addPreventUser.toString()]: handleAddPrevent,
  [removePreventUser.toString()]: handleRemovePrevent,

  [changePrivacyLevelUser.toString()]: handleChangePrivacyLevel,

  [applyChangesToUser.toString()]: handleApplyChanges,
};

const userProfilePageReducer = createReducer(defaultState, handlers);

export default userProfilePageReducer;
