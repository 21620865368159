type TooltipGroup = {
  [key: string]: string;
};

const tooltipsText: { [key: string]: TooltipGroup } = {
  envelopeActions: {
    addComment: 'Add new comment',
  },
  envelopesListActions: {
    starred: 'Starred',
    select: 'Select',
    expand: 'Expand',
    view_details: 'View Details',
    selectAll: 'Select All',
    redesign: 'Redesign Experimental',
    comment: 'Envelope has comments',
    author_notify: 'Author notified',
  },
  envelopesTestCasesActions: {
    add: 'Add to training set',
    view: 'View test case',
  },
  envelopeStatus: {
    acceptable: 'Communication: acceptable',
    pending: 'Communication: pending',
    violation: 'Communication: out of policy ',
  },
  envelopeKeyActionsIcons: {
    show_modal: 'Guidance shown',
    back_to_edit: 'Message edited',
    send_anyways: 'Message sent out of policy',
    sent_within_policy: 'Message sent within policy',
    sent_out_of_policy: 'Message sent out of policy',
    timeout: 'Timeout',
    warnings_disabled: 'Warnings disabled in settings',
    author_notify: 'Author notified',
    abandoned: 'Message abandoned',
  },
  platforms: {
    gmail: 'Gmail',
    chrome: 'Chrome',
    gmail_app: 'Gmail on Chrome',
    slack: 'Slack',
    zendesk: 'Zendesk',
    o365: 'Outlook',
    o365_teams: 'Microsoft Teams',
  },
  reviewSetActions: {
    view_assignments: 'View assignments',
    configure: 'Configure review stream',
    edit: 'Edit saved search',
    close: 'Close assignment',
    play: 'Begin assignment',
    continue: 'Continue assignment',
    close_before_play: 'You have an active assignment.\nPlease close it before starting another',
    delete: 'Delete review stream',
  },
  ruleConfig: {
    deletedAnnotator: 'Identifier deleted',
    disabledBranch: 'Branch is disabled',
  },
  testTooltip: {
    tootip: 'This is for jest testing',
  },
  testCasesListActions: {
    select: 'Select',
    selectAll: 'Select all',
  },
  dashboard: {
    priorities: 'Trends based on average',
    activePrevent: 'The average is based on the last 3 periods',
    tableView: 'Switch to table view',
    chartView: 'Switch to chart view',
    downloadData: 'Download data',
    expandTable: 'Open expanded view',
  },
};

export default tooltipsText;
