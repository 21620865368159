import React from 'react';

type LoadingIndicatorProps = {
  className: string;
  size: string;
};

const LoadingIndicator: React.FC<LoadingIndicatorProps> = (props) => {
  const { className, size } = props;

  return (
    <div
      className={`litlingo__loader_indicator ease-linear rounded-full border-2 border-gray-200 h-${size} w-${size} ${className}`}
    />
  );
};
export default LoadingIndicator;
