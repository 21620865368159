import {
  deleteEnvelopeAction,
  deleteEnvelopeActionFailure,
  deleteEnvelopeActionRequest,
  deleteEnvelopeActionSuccess,
  fetchEnvelopeActionFailure,
  fetchEnvelopeActionRequest,
  fetchEnvelopeActionSuccess,
  updateEnvelopeAction,
  updateEnvelopeActionFailure,
  updateEnvelopeActionRequest,
  updateEnvelopeActionSuccess,
} from 'actions/envelopeActions';
import { apiClient as LitLingoClient } from 'client';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API, SagaReturn, UUID } from 'types';

function* fetchSingleAction({ envActionUuid }: { envActionUuid: UUID }): SagaReturn {
  yield put(fetchEnvelopeActionRequest());
  const response = (yield call(
    [LitLingoClient.resources.envelope_actions, 'retrieve'],
    envActionUuid
  )) as API.Response<API.EnvelopeActions.Retrieve>;

  if (response.error !== null) {
    yield put(fetchEnvelopeActionFailure(response.error));
  } else {
    yield put(fetchEnvelopeActionSuccess(response.data));
  }
}

function* handleUpdateEnvelopeAction({
  payload,
}: ReturnType<typeof updateEnvelopeAction>): SagaReturn {
  yield put(updateEnvelopeActionRequest());
  const response = (yield call([LitLingoClient.resources.envelope_actions, 'upsert'], {
    data: payload,
  })) as API.Response<API.EnvelopeActions.Upsert>;

  if (response.error !== null) {
    yield put(updateEnvelopeActionFailure(response.error));
  } else {
    yield put(updateEnvelopeActionSuccess(response.data));
  }
}

function* handleDeleteEnvelopeAction({
  payload,
}: ReturnType<typeof deleteEnvelopeAction>): SagaReturn {
  yield put(deleteEnvelopeActionRequest());
  const response = (yield call(
    [LitLingoClient.resources.envelope_actions, 'delete'],
    payload.envActionUuid
  )) as API.Response<API.EnvelopeActions.Delete>;

  if (response.error !== null) {
    yield put(deleteEnvelopeActionFailure(response.error));
  } else {
    yield put(deleteEnvelopeActionSuccess(payload));
    yield call(fetchSingleAction, {
      envActionUuid: payload.envActionUuid,
    });
  }
}

function* envelopeActionsSaga(): SagaReturn {
  yield takeLatest(updateEnvelopeAction.toString(), handleUpdateEnvelopeAction);
  yield takeLatest(deleteEnvelopeAction.toString(), handleDeleteEnvelopeAction);
}
export default envelopeActionsSaga;
