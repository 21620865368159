import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { apiClient as LitLingoClient } from 'client';
import { providerS3Keys } from 'constants/platform';
import {
  uploadFileS3,
  uploadFileS3Failure,
  uploadFileS3Fulfill,
  uploadFileS3Progress,
  uploadFileS3Success,
} from 'reducers/fileUploads';
import type { EventChannel } from 'redux-saga';
import { END, buffers, eventChannel } from 'redux-saga';
import { call, put, take, takeLatest } from 'redux-saga/effects';
import type { API, ErrorObject, SagaReturn } from 'types';

const buildFilename = (file: File, provider: 'o365' | 'zendesk'): string => {
  const parts = file.name.split('.');
  const extension = parts[parts.length - 1];

  const timestamp = new Date().toISOString();

  return `${providerS3Keys[provider]}-${timestamp}.${extension}`;
};

type UploadInfo = {
  progress?: number;
  success?: true;
  error?: ErrorObject;
};
const createChannel = (url: string, file: File): EventChannel<UploadInfo> =>
  eventChannel<UploadInfo>(
    (emmiter) => {
      const source = axios.CancelToken.source();
      const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': file.type,
        },
        cancelToken: source.token,
        onUploadProgress: (progress) => {
          const percent = progress.total ? Math.round((progress.loaded * 100) / progress.total) : 0;
          emmiter({ progress: percent });
        },
      };

      axios
        .put(url, file, config)
        .then(() => {
          emmiter({ success: true });
          emmiter(END);
        })
        .catch((reason) => {
          emmiter({ error: reason });
          emmiter(END);
        });

      return (): void => {
        source.cancel();
      };
    },
    // the buffer is 2 to only save the 2 last status updates
    buffers.sliding(2)
  );

type UploadAction = ReturnType<typeof uploadFileS3>;
function* uploadFileS3Saga(action: UploadAction): SagaReturn {
  try {
    const filename = buildFilename(action.payload.file, action.payload.provider);

    const response = (yield call([LitLingoClient.resources.customers.extras, 'uploadURL'], {
      params: { file_name: filename },
    })) as API.Response<API.Customers.UploadURL>;

    if (response.error != null) {
      yield put(uploadFileS3Failure({ error: response.error }));
    } else {
      const channel = createChannel(response.data.payload, action.payload.file);

      while (true) {
        const { progress, error, success } = (yield take(channel)) as UploadInfo;
        if (error != null) {
          yield put(uploadFileS3Failure({ error }));
          break;
        }

        if (success) {
          yield put(uploadFileS3Success());
          break;
        }

        if (progress != null) {
          yield put(uploadFileS3Progress({ progress }));
        }
      }
    }
  } catch (error) {
    const errorObject = error as ErrorObject;
    yield put(uploadFileS3Failure({ error: errorObject }));
  } finally {
    yield put(uploadFileS3Fulfill());
  }
}

function* fileUploadsSaga(): SagaReturn {
  yield takeLatest(uploadFileS3, uploadFileS3Saga);
}

export default fileUploadsSaga;
