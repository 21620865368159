/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { appendSample, fetchAllEnvelopesForSample, fetchPrompts, smartSample } from 'actions';
import EnvelopeListFilterPills from 'components/EnvelopeList/FilterPills';
import DateFilterGeneral from 'components/Filters/SidebarTypeFilters/DateFilterGeneral';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import SelectRedesign from 'components/Select/SelectRedesign';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getPrompts } from 'selectors/entities';
import { getFetchAllEnvelopesLoading } from 'selectors/envelopes';
import { useSelector } from 'store';
import { RouteParams, Tree } from 'types';
import {
  getAllFieldsValuesWithLabels,
  removeField,
  TreeFieldsValuesWithLabels,
} from 'utils/parserTree';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
  envelopesTotalCount: number;
  navParams: RouteParams;
  treeParams: TreeFieldsValuesWithLabels;
  tree: Tree;
};

const AppendSampleModal: React.FC<ComponentProps> = ({
  isModalOpen,
  toggleModalOpen,
  envelopesTotalCount,
  navParams,
  treeParams,
  tree,
}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(getFetchAllEnvelopesLoading);
  const prompts = useSelector(getPrompts);

  const defaultSize = envelopesTotalCount > 50 ? 50 : envelopesTotalCount;
  const defaultPercentage = Number(((defaultSize / envelopesTotalCount) * 100).toFixed(1));
  const [dateRange, setDateRange] = useState({ createdAfter: '', createdBefore: '' });
  const [overrideDateRange, setOverrideDateRange] = useState<boolean | null>(null);
  const [prompt, setPrompt] = useState<string>('');
  const [showPromptError, setShowPromptError] = useState<boolean>(false);

  // @ts-ignore
  const options = prompts.map((p) => ({ value: p.uuid, label: p?.name }));

  const range = getAllFieldsValuesWithLabels(tree).date_range;
  let initialCreatedAfterTree: string | undefined;
  let initialCreatedBeforeTree: string | undefined;
  if (range) {
    [initialCreatedAfterTree, initialCreatedBeforeTree] = range[0].value.split('<>') || [];
  }

  const currentCreatedAfter = dateRange.createdAfter;
  const currentCreatedBefore = dateRange.createdBefore;

  const isCurrentDateRangeExtended = (): boolean => {
    if (!initialCreatedAfterTree && !initialCreatedBeforeTree) {
      return false;
    }
    if (!currentCreatedAfter && !currentCreatedBefore) {
      return false;
    }
    return (
      moment(currentCreatedAfter).isBefore(initialCreatedAfterTree) ||
      moment(currentCreatedBefore).isAfter(initialCreatedBeforeTree)
    );
  };

  let treeForFilters = { ...tree };
  treeForFilters = removeField(treeForFilters, 'sample_uuids');
  treeForFilters = removeField(treeForFilters, 'date_range');

  const treeParamsForFilters = { ...treeParams };
  delete treeParamsForFilters.sample_uuids;
  delete treeParamsForFilters.date_range;

  const { register, getValues, handleSubmit, setValue, errors, reset, watch } = useForm<{
    sampleSize: number;
    samplePercentage: number;
    createSmartSample: boolean;
  }>({
    defaultValues: {
      sampleSize: defaultSize,
      samplePercentage: defaultPercentage,
      createSmartSample: false,
    },
  });

  const onSubmit = (): void => {
    const { sampleSize, createSmartSample } = getValues();

    if (isCurrentDateRangeExtended() && overrideDateRange === null) {
      return;
    }

    if (createSmartSample) {
      if (!prompt) {
        setShowPromptError(true);
        return;
      }

      dispatch(
        smartSample({
          sample_size: Number(sampleSize),
          prompt_uuid: prompt,
          tree,
        })
      );
      reset();
      toggleModalOpen();
      return;
    }

    dispatch(
      appendSample({
        name: `${moment(dateRange.createdAfter).format('MMMDD_YYYY')}-${moment(
          dateRange.createdBefore
        ).format('MMMDD_YYYY')}`,
        sample_size: Number(sampleSize),
        description: '',
        dateRange,
        params: navParams,
        tree,
        overrideDateRange: !!overrideDateRange && isCurrentDateRangeExtended(),
        newDateRange: {
          createdAfter:
            (initialCreatedAfterTree &&
              moment(initialCreatedAfterTree).isBefore(currentCreatedAfter)) ||
            (initialCreatedAfterTree && !currentCreatedAfter)
              ? initialCreatedAfterTree
              : currentCreatedAfter,
          createdBefore:
            (initialCreatedBeforeTree &&
              moment(initialCreatedBeforeTree).isAfter(currentCreatedBefore)) ||
            (initialCreatedBeforeTree && !currentCreatedBefore)
              ? initialCreatedBeforeTree
              : currentCreatedBefore,
        },
        prevDateRange: !!range,
      })
    );
    reset();
    toggleModalOpen();
  };

  useEffect(() => {
    if (watch('createSmartSample')) {
      dispatch(
        // @ts-ignore
        fetchAllEnvelopesForSample({
          ...navParams,
          tree,
          smartSample: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('createSmartSample')]);

  useEffect(() => {
    if (dateRange.createdAfter) {
      setValue(
        'samplePercentage',
        ((Number(getValues().sampleSize) / envelopesTotalCount) * 100).toFixed(
          1
        ) as unknown as number
      );
    } else {
      setValue('samplePercentage', 0);
      setValue('sampleSize', 0);
    }
  }, [envelopesTotalCount, getValues, setValue, dateRange.createdAfter]);

  useEffect(() => {
    dispatch(fetchPrompts({ kind: 'rerank' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const handleChangeDate = useCallback(
    (dateRangeValue: { createdAfter: string; createdBefore: string }): void => {
      dispatch(
        // @ts-ignore
        fetchAllEnvelopesForSample({
          ...navParams,
          tree,
          created_after: dateRangeValue.createdAfter,
          created_before: dateRangeValue.createdBefore,
        })
      );
      setDateRange(dateRangeValue);
    },
    [tree, navParams, dispatch]
  );

  const renderItemsCount = (): JSX.Element | null => {
    if (isLoading) {
      return <LoadingIndicator size="5" />;
    }
    if (!dateRange.createdAfter && !watch('createSmartSample')) {
      return null;
    }
    return (
      <span className="font-bold">
        {envelopesTotalCount === 10000 ? '+10,000' : envelopesTotalCount.toLocaleString()} items
      </span>
    );
  };

  const renderBody = (
    <>
      <div className="flex flex-col gap-4 mb-20 mt-4">
        <div className="flex flex-col gap-1">
          <EnvelopeListFilterPills
            placeholder="No filters applied"
            propParams={navParams}
            treePropsParams={treeParamsForFilters}
            allowDelete={false}
            envelopesPage={false}
            customTree={treeForFilters}
            renderSampleButton={false}
          />
        </div>

        <form className="flex flex-col items-start">
          <div className="flex flex-col items-start gap-4 w-full">
            <div className="flex flex-col justify-start gap-2">
              <div className="flex flex-row gap-4">
                <label htmlFor="createSmartSample" className="flex flex-row items-center gap-1">
                  <input
                    type="checkbox"
                    id="createSmartSample"
                    name="createSmartSample"
                    className="form-checkbox litlingo-checkbox"
                    ref={register()}
                  />
                  <span>Make smart sample</span>
                </label>
                <div className="text-body">{watch('createSmartSample') && renderItemsCount()}</div>
              </div>

              {watch('createSmartSample') && (
                <div className="flex flex-row justify-start  w-52">
                  <SelectRedesign
                    className="bg-white"
                    optionClassname="bg-white"
                    placeholder="Prompt"
                    options={options}
                    onChange={(value): void => {
                      setPrompt(value);
                    }}
                    value={prompt}
                  />
                </div>
              )}
            </div>
            {watch('createSmartSample') && showPromptError && (
              <div className="text-small">
                <span className=" text-litlingo-alert">Please select a prompt</span>
              </div>
            )}
            {!watch('createSmartSample') && (
              <div className="flex flex-col text-body">
                <div className="flex flex-col gap-1.5">
                  <span className="font-bold">between</span>
                  <div className="flex flex-row gap-1">
                    <span>Date Range</span>
                    <span className="litlingo-red-color">*</span>
                  </div>
                </div>
                <div className="flex flex-row gap-4 items-end">
                  <DateFilterGeneral
                    showRangeOptions={false}
                    dateRange={dateRange}
                    handleSetDate={handleChangeDate}
                  />
                  <div className="text-body">{renderItemsCount()}</div>
                </div>
                {isCurrentDateRangeExtended() && (
                  <div className="flex flex-col gap-2 mt-2">
                    <div className="flex flex-row gap-1">
                      <span>
                        Update sample set date range to{' '}
                        <b>
                          {moment(currentCreatedAfter).isBefore(initialCreatedAfterTree)
                            ? moment(currentCreatedAfter).format('MM/DD/YY')
                            : moment(initialCreatedAfterTree).format('MM/DD/YY')}
                          –
                          {moment(currentCreatedBefore).isAfter(initialCreatedBeforeTree)
                            ? moment(currentCreatedBefore).format('MM/DD/YY')
                            : moment(initialCreatedBeforeTree).format('MM/DD/YY')}
                        </b>{' '}
                        when appending?
                      </span>
                      <span className="litlingo-red-color">*</span>
                    </div>
                    <div className="flex flex-row items-center gap-14">
                      <label htmlFor="radio-override" className="flex flex-row items-center gap-1">
                        <input
                          type="radio"
                          id="radio-override"
                          name="override"
                          value="false"
                          className="input-radio"
                          onChange={(): void => setOverrideDateRange(true)}
                          checked={overrideDateRange === true}
                        />
                        <span>Yes</span>
                      </label>
                      <label
                        htmlFor="radio-no-override"
                        className="flex flex-row items-center gap-1"
                      >
                        <input
                          type="radio"
                          id="radio-no-override"
                          name="no-override"
                          value="false"
                          className="input-radio"
                          onChange={(): void => setOverrideDateRange(false)}
                          checked={overrideDateRange === false}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </div>
                )}
              </div>
            )}
            <label htmlFor="size" className="flex flex-col gap-1 text-body">
              <div className="flex flex-row">
                <span>Sample size</span>
                <span className="litlingo-red-color">*</span>
              </div>
              <div className="flex flex-row items-center gap-2">
                <input
                  id="sampleSize"
                  type="number"
                  ref={register({ required: true, min: 1, max: envelopesTotalCount })}
                  name="sampleSize"
                  onChange={(e): void => {
                    const { value } = e.target;
                    setValue(
                      'samplePercentage',
                      ((Number(value) / envelopesTotalCount) * 100).toFixed(1) as unknown as number
                    );
                  }}
                  className="form-input p-1.5 w-16 text-center text-body transition duration-150 ease-in-out"
                />
                <div className="flex flex-row gap-1">
                  <span>Communications</span>
                  <span className="font-bold">OR</span>
                </div>
                <input
                  id="samplePercentage"
                  type="number"
                  ref={register({
                    required: true,
                    min: (100 / envelopesTotalCount).toFixed(1),
                    max: 100,
                  })}
                  name="samplePercentage"
                  onChange={(e): void => {
                    const { value } = e.target;
                    setValue(
                      'sampleSize',
                      ((Number(value) / 100) * envelopesTotalCount).toFixed() as unknown as number
                    );
                  }}
                  className="form-input p-1.5 w-16 text-center text-body transition duration-150 ease-in-out "
                />
                <div className="flex flex-row gap-1">
                  <span className="text-lg">%</span>
                  <span>of current count </span>
                </div>
              </div>
            </label>
            {(errors.sampleSize || errors.samplePercentage) && (
              <div className="text-small">
                <span className=" text-litlingo-alert">
                  Sample size must be a positive number between 1 and current count
                </span>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderBody}
          title="Append Sample"
          okButton
          okButtonText="Append"
          okButtonOnClick={handleCreateButton}
          okButtonDisabled={!watch('createSmartSample') && !dateRange.createdAfter}
          toggleShowModal={toggleModalOpen}
          style={{
            width: '800px',
          }}
        />
      )}
    </>
  );
};

export default AppendSampleModal;
