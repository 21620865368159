import React, { useCallback, useRef, useState } from 'react';
import useClickOutside from '../utils/useClickOutside';

export type MultiSelectOption = {
  label: string;
  value: string;
  disabled?: boolean;
};

type MultiSelectOptionProps = {
  onChange: (
    option: MultiSelectOption,
    exclude?: boolean,
    e?: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => void;
  option: MultiSelectOption;
  selected: boolean;
  excludedSelected: boolean;
  dataTestid: string;
};

const MultiSelectOption: React.FC<MultiSelectOptionProps> = (props) => {
  const { onChange, option, selected, excludedSelected, dataTestid } = props;

  const handleChange = (e: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
    const { shiftKey } = e.nativeEvent;

    onChange(option, shiftKey, e);
  };

  return (
    <div className="flex w-full">
      <label
        htmlFor={`${option.value}-status-checkbox`}
        className="flex flex-row items-center gap-1 text-small cursor-pointer select-none"
      >
        <input
          id={`${option.value}-status-checkbox`}
          type="checkbox"
          className={`form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out cursor-pointer ${
            excludedSelected ? 'litlingo-checkbox-negated' : ''
          }`}
          data-testid={dataTestid}
          onClick={(e): void => handleChange(e)}
          checked={selected || excludedSelected}
          disabled={option.disabled}
        />
        <div className="flex flex-row gap-1">{option.label}</div>
      </label>
    </div>
  );
};

type ComponentProps = {
  onChange: (
    option: MultiSelectOption,
    exclude?: boolean,
    e?: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => void;
  placeholder?: string;
  dataTestid?: string;
  options: MultiSelectOption[];
  className?: string;
  selectedItems: MultiSelectOption[];
  excludedSelectedItems?: MultiSelectOption[];
  counts?: Record<string, number>;
};

const MultiselectDropdownRedesign: React.FC<ComponentProps> = (props) => {
  const {
    onChange,
    options,
    placeholder,
    selectedItems,
    dataTestid,
    className,
    excludedSelectedItems = [],
    counts,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  // click outside functionality
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleClick = (): void => setIsOpen(!isOpen);

  return (
    <div ref={wrapperRef} className="relative w-full text-body">
      <button
        type="button"
        className={`flex h-8 w-full justify-between items-center px-2 text-litling0-gray-6 border border-litlingo-gray-2 rounded focus:outline-none disabled:opacity-50 ${className}`}
        id="options-menu"
        data-testid={dataTestid || 'select'}
        aria-haspopup="true"
        aria-expanded="true"
        onClick={handleClick}
      >
        <span
          className={`truncate ${
            selectedItems.length && !isOpen ? 'text-litlingo-gray-6' : 'text-litlingo-gray-4 italic'
          }`}
        >
          {placeholder || 'Select an option'}
        </span>

        <div className="flex gap-x-1">
          {!!selectedItems.length && !isOpen && (
            <div className="flex items-center justify-center w-4.5 h-4.5 rounded-full bg-litlingo-primary-60">
              <span>{selectedItems.length}</span>
            </div>
          )}

          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.49026 8.44392L1.83398 3L0 4.91123L7.49035 12.0926L15 4.91123L13.166 3L7.49026 8.44392Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </button>

      {isOpen && (
        <div
          data-testid="dropdown-menu"
          style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}
          className="origin-bottom-left absolute mt-1 w-full rounded z-50 border border-litlingo-gray-2"
        >
          <div className="rounded bg-white shadow-xs overflow-auto max-h-72 custom-scrollbar">
            <div
              role="menu"
              data-testid="options-container"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
              className="flex flex-col gap-2 p-2"
            >
              {options.map((option) => (
                <div
                  className="flex flex-row justify-between items-center"
                  key={`${option.label}-${option.value}`}
                >
                  <MultiSelectOption
                    key={`${option.label}-${option.value}`}
                    onChange={onChange}
                    option={option}
                    selected={selectedItems.some((i) => i.value === option.value)}
                    excludedSelected={excludedSelectedItems.some((i) => i.value === option.value)}
                    dataTestid={`${dataTestid || 'select'}-${option.label}-option`}
                  />
                  {counts && counts[option.value] && (
                    <div className="text-small text-litlingo-gray-4">{counts[option.value]}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiselectDropdownRedesign;
