import { BarExtendedDatum, BarMouseEventHandler, ResponsiveBar } from '@nivo/bar';
import { fetchAllTags, fetchTagsAnalytics } from 'actions';
import withWidget from 'decorators/widget';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSimpleTagsAnalytics, getTagsAnalyticsLoading } from 'selectors/analytics';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getPermissionsPolicy } from 'selectors/permissions';
import { getTags } from 'selectors/tags';
import { isActionAuthorized } from 'utils/permissions';
import { useHistory } from 'utils/urls';

type ProcessedTags = {
  data: { category: string; count: number }[];
  keys: string[];
  indexBy: string;
};

const TagsVerticalHistogram: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(getTagsAnalyticsLoading);
  const tags = useSelector(getSimpleTagsAnalytics);
  const allTags = useSelector(getTags);
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const customerDomain = useSelector(getCustomerDomain);

  const [processedTags, setProcessedTags] = useState<ProcessedTags>({} as ProcessedTags);

  useEffect(() => {
    if (!tags) {
      return;
    }

    const tempTags: ProcessedTags = {
      data: [],
      keys: ['count'],
      indexBy: 'category',
    };

    Object.keys(tags).forEach((t) => {
      tempTags.data.push({ category: t, count: tags[t] });
    });
    setProcessedTags(tempTags);
  }, [tags]);

  useEffect(() => {
    dispatch(fetchAllTags({ limit: -1 }));
    dispatch(fetchTagsAnalytics());
  }, [dispatch]);

  const colors = {
    0: '#BDDAEA',
  };

  const handleClick = (e: BarExtendedDatum): void => {
    const tagId = allTags.find((t) => t.value === processedTags.data[e.index].category);
    if (isActionAuthorized('events.list', user.roles, policy)) {
      history.pushLookup({
        routeName: 'envelope-list',
        queryParams: {
          envelopes__tags_and: tagId?.uuid || '',
        },
        customerDomain,
      });
    }
  };

  const getColors = (): string => colors[0];

  if (loading) {
    return <WidgetLoading />;
  }
  if (!tags || !processedTags.data) return <h2>No data found.</h2>;

  return (
    <div className="h-full w-full">
      <ResponsiveBar
        data={processedTags.data.slice(0, 10)}
        keys={processedTags.keys}
        indexBy={processedTags.indexBy}
        margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
        innerPadding={4}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        colors={getColors()}
        enableGridY={false}
        layout="vertical"
        axisBottom={{
          format: (v): string =>
            v.toString().length > 19 ? `${v.toString().slice(0, 16)}...` : v.toString(),
          tickRotation: 30,
        }}
        theme={{
          tooltip: {
            container: {
              background: 'none',
              boxShadow: 'none',
            },
          },
        }}
        tooltip={(d): JSX.Element => (
          <div className="bg-litlingo-off-white p-1 rounded flex items-center shadow-md">
            <div
              style={{
                background: d.color,
              }}
              className="h-3 w-3 mr-2"
            />
            <span className="text">
              {d.data.category}: <b>{d.value}</b>
            </span>
          </div>
        )}
        onClick={handleClick}
        onMouseEnter={
          ((_data, event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
            event.currentTarget.style.cursor = 'pointer';
          }) as BarMouseEventHandler<SVGElement>
        }
        animate
      />
    </div>
  );
};

export default withWidget({
  title: 'Tag Analytics (beta)',
})(TagsVerticalHistogram);
