import { fetchAllTestCases, fetchTestCaseSummary } from 'actions/testCases';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getSelectedNode } from 'selectors/config';
import { getConfigRuleAsArray } from 'selectors/rule';
import { useSelector } from 'store';
import RuleManagerSidebarContent from './RuleManagerSidebarContent';
import TestSuiteHeader from './TestSuiteHeader';

type ComponentProps = {
  toggleTestSuiteSidebar: () => void;
  ruleId: string;
};

const RuleManagerTestSuiteSidebar: React.FC<ComponentProps> = ({
  toggleTestSuiteSidebar,
  ruleId,
}) => {
  const dispatch = useDispatch();
  const arrayTree = useSelector(getConfigRuleAsArray);
  const selectedNode = useSelector(getSelectedNode);

  const fetchTestCases = useCallback((): void => {
    if (selectedNode !== null) {
      if (selectedNode === 0 || selectedNode === -1) {
        dispatch(fetchAllTestCases({ rule_uuids: ruleId }));
        dispatch(fetchTestCaseSummary({ rule_uuids: ruleId }));
      } else {
        dispatch(
          fetchAllTestCases({
            rule_uuids: ruleId,
            branch_uuid: arrayTree[selectedNode as number].id,
          })
        );
        dispatch(
          fetchTestCaseSummary({
            rule_uuids: ruleId,
            branch_uuid: arrayTree[selectedNode as number].id,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode]);

  useEffect(() => {
    fetchTestCases();
  }, [fetchTestCases]);

  return (
    <div className="flex flex-col h-full bg-white overflow-auto custom-scrollbar">
      <TestSuiteHeader toogleTestSuiteSidebar={toggleTestSuiteSidebar} ruleId={ruleId} />
      <RuleManagerSidebarContent ruleId={ruleId} />
    </div>
  );
};

export default RuleManagerTestSuiteSidebar;
