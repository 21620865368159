import { continueAssignment, setShowSkippedEnvelopes } from 'actions';
import Permissions from 'components/Permissions';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  getAssignmentsLoading,
  getShowSkippedEnvelopes,
  getUsersSingleAssigment,
} from 'selectors/assignments';
import { getCustomerDomain } from 'selectors/auth';
import { useSelector } from 'store';
import { reverse, useHistory } from 'utils/urls';
import ReviewProgressBar from '../ReviewProgressBar';

const EnvelopeSideBarHeader: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const assignment = useSelector(getUsersSingleAssigment);
  const assignmentLoading = useSelector(getAssignmentsLoading);
  const showSkippedEnvelopes = useSelector(getShowSkippedEnvelopes);
  const customerDomain = useSelector(getCustomerDomain);

  if (assignmentLoading || assignment == null) {
    return null;
  }

  let progressCount = assignment.count;

  if (assignment.saved_search && assignment.saved_search?.record_count < assignment.count) {
    progressCount = assignment.saved_search.record_count;
  }

  const completed = assignment.count - assignment.pending;
  const percentageCompleted = (completed / progressCount) * 100;
  const stepWidth = 100 / progressCount;

  const handleOpenSkippeds = (): void => {
    const url = new URLSearchParams();
    url.append('envelopes__skipped_assignment_uuid', assignment?.saved_search?.uuid ?? '');
    const path = reverse({
      routeName: 'envelope-list',
      queryParams: url.toString(),
      customerDomain,
    });

    history.push(path);
    dispatch(setShowSkippedEnvelopes(true));
  };

  const handleBackToRemaining = (): void => {
    dispatch(setShowSkippedEnvelopes(false));
    dispatch(continueAssignment({ uuid: assignment.uuid }));
  };

  if (
    assignment.saved_search &&
    assignment.saved_search?.config?.default_count !== -1 &&
    assignment.saved_search?.record_count > assignment.count
  ) {
    return (
      <div className="flex flex-col items-start gap-1 px-4 pt-4 pb-2 bg-white border border-b-0 border-litlingo-gray-1">
        <div className="text-heading-3">{assignment.saved_search?.name}</div>

        <div className="flex flex-col gap-1 w-full">
          <ReviewProgressBar completed={percentageCompleted} current={stepWidth} />

          {assignment.count !== -1 && (
            <div className="text-small self-end">
              {completed + 1} of {progressCount}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col items-start px-4 pt-4 pb-2 bg-white border border-b-0 border-litlingo-gray-1  `}
    >
      <div className="flex flex-col gap-2">
        <div className={`${showSkippedEnvelopes ? 'text-base' : 'text-heading-3'}`}>
          {assignment.saved_search?.name}
        </div>
      </div>
      <div className="flex flex-row justify-between w-full">
        {showSkippedEnvelopes ? (
          <div className="text-heading-1">{`${
            assignment.saved_search?.skipped ?? 0
          } skipped left`}</div>
        ) : (
          <div className="text-heading-1">{`${assignment.saved_search?.record_count} remaining`}</div>
        )}
        {showSkippedEnvelopes && (
          <button
            type="button"
            className="flex underline  text-litlingo-gray-5 focus:outline-none"
            onClick={handleBackToRemaining}
          >
            <span className="self-end ">Exit skips</span>
          </button>
        )}
        {!!assignment?.saved_search?.skipped &&
          assignment?.saved_search?.skipped > 0 &&
          !showSkippedEnvelopes && (
            <Permissions action="communication_envelopes.skipped_flow">
              <button
                type="button"
                className="flex underline  text-litlingo-gray-5 focus:outline-none"
                onClick={handleOpenSkippeds}
                disabled={assignment.saved_search?.skipped === 0}
              >
                <span className="self-end ">{`${
                  assignment?.saved_search?.skipped ?? 0
                } skipped`}</span>
              </button>
            </Permissions>
          )}
      </div>
    </div>
  );
};

export default EnvelopeSideBarHeader;
