import useClickOutside from 'components/utils/useClickOutside';
import React, { useCallback, useRef, useState } from 'react';

type ComponentProps = {
  className?: string;
  handleModalAddTestSuitFromEvent: () => void;
};

const ThreeDotsDropdown: React.FC<ComponentProps> = ({
  className,
  handleModalAddTestSuitFromEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentLabel, setCurrentLabel] = useState<string | null>(null);
  const options = {
    createTest: 'Create test case from event',
  };
  // click outside functionality
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleButtonClick = (): void => setIsOpen(!isOpen);

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    const { id: state } = evt.target;
    if (evt.target.checked) {
      if (state === 'createTest') {
        handleModalAddTestSuitFromEvent();
      }
      setCurrentLabel(state);
      handleButtonClick();
    }
  };

  const getOption = (key: keyof typeof options): JSX.Element => (
    <div className="relative flex items-start">
      <div className="items-center h-5 mr-2 hidden">
        <input
          id={key}
          type="checkbox"
          className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out"
          data-testid={`${key}-option-button`}
          onChange={handleInputChange}
          checked={currentLabel === options[key]}
        />
      </div>
      <div className="text-sm leading-5 ml-2 self-center">
        <label htmlFor={key} className="litlingo-black-colorfont-medium cursor-pointer">
          {options[key]}
        </label>
      </div>
    </div>
  );

  return (
    <div ref={wrapperRef} className={`relative inline-block text-left ${className || ''}`}>
      <div className="rounded-md shadow-sm bg-white w-10">
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md border px-2 py-2 text-sm leading-5 focus:outline-none focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 border-litlingo-line"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          data-testid="three-dots-button"
          onClick={(evt): void => {
            evt.stopPropagation();
            handleButtonClick();
          }}
        >
          <svg viewBox="0 0 20 20" fill="#4A5568" className="dots-vertical w-5 h-5">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-1 w-56 rounded-md shadow-lg z-10">
          <div className="rounded-md bg-white shadow-xs">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="m-2">
                <fieldset>
                  {(Object.keys(options) as Array<keyof typeof options>).map((key) => (
                    <div className="hover:bg-gray-200" key={key}>
                      {getOption(key)}
                    </div>
                  ))}
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ThreeDotsDropdown;
