// FIXME: We should have a table creator for these types of nested custom tables
// because we should dispatch the actions in the parent component,
// these components should NOT dispatch any actions and also we could avoid
// creation of multiples table with similar UI
import { fetchAllCampaignRuleOutcomes } from 'actions';
import CollapsableMainSection from 'components/CollapsableCard/CollapsableMainSection';
import EmptyTableState from 'components/EmptyTableState';
import LinkLookup from 'components/LinkLookup';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCampaignRuleOutcomesList } from 'selectors/campaignRuleOutcomes';
import { useSelector } from 'store';
import type { CampaignRuleOutcome, UUID } from 'types';

type ComponentProps = {
  value: UUID;
  entity: string;
};

const CampaignRuleOutcomesTable: React.FC<ComponentProps> = ({ entity, value }) => {
  const dispatch = useDispatch();
  const campaignRuleOutcomes: CampaignRuleOutcome[] = useSelector(getCampaignRuleOutcomesList);

  useEffect(() => {
    dispatch(fetchAllCampaignRuleOutcomes({ [entity]: value }));
  }, [dispatch, value, entity]);

  const renderTable = (
    <>
      <div className="table-wrapper">
        <table className="table-wrapper__table">
          <thead>
            <tr>
              <th className="table-wrapper__th">Model name</th>
              <th className="table-wrapper__th">Related Use Case</th>
            </tr>
          </thead>
          {campaignRuleOutcomes && (
            <tbody className="table-wrapper__tbody">
              {campaignRuleOutcomes.length === 0 ? (
                <EmptyTableState message="There are no use cases to display" colCount="2" />
              ) : (
                campaignRuleOutcomes.map((campaignRuleOutcome) => (
                  <>
                    {campaignRuleOutcome.rule && (
                      <tr>
                        <td className="table-wrapper__td">
                          <LinkLookup
                            routeName="rule-manager"
                            routeParams={{ ruleId: campaignRuleOutcome.rule_uuid as UUID }}
                          >
                            <div className="flex items-center">
                              <div>
                                <div className="text">{campaignRuleOutcome?.rule?.name}</div>
                              </div>
                            </div>
                          </LinkLookup>
                        </td>
                        <td className="table-wrapper__td">
                          <LinkLookup
                            routeName="campaign-detail"
                            routeParams={{
                              campaignId: campaignRuleOutcome?.campaign?.uuid as UUID,
                            }}
                          >
                            <div className="flex items-center">
                              <div>
                                <div className="text">{campaignRuleOutcome?.campaign?.name}</div>
                              </div>
                            </div>
                          </LinkLookup>
                        </td>
                      </tr>
                    )}
                  </>
                ))
              )}
            </tbody>
          )}
        </table>
      </div>
    </>
  );

  return (
    <CollapsableMainSection
      headerTitle={`Associated Model Outcomes (${campaignRuleOutcomes.length})`}
      isDefaultClosed
    >
      <div className="p-4">{renderTable}</div>
    </CollapsableMainSection>
  );
};

export default CampaignRuleOutcomesTable;
