import EmptyTableState from 'components/EmptyTableState';
import LoadingIndicator from 'components/LoadingIndicator';

import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';

import useNavParams from 'hooks/nav/useNavParams';
import type { Category } from 'types';
import CustomerCategoryItem from './CustomerCategoryItem';

type ComponentProps = {
  categories: Category[];
  loading?: boolean;
};

const CustomersCategoriesTable: React.FC<ComponentProps> = ({ categories, loading }) => {
  const { customer_owned: customerOwned } = useNavParams() as { customer_owned: string };

  return (
    <LoadingOverlay
      active={loading}
      spinner={<LoadingIndicator size="10" />}
      fadeSpeed={0}
      styles={{
        content: (base) => ({
          ...base,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }),
        wrapper: () => ({}),
        spinner: () => ({}),
        overlay: (base) => ({
          ...base,
          zIndex: 10,
        }),
      }}
    >
      <div className="table-wrapper__new">
        <table className="table-wrapper__table">
          <thead>
            <tr>
              <th className="table-wrapper__new-th pl-4">Use Case</th>
              <th className="table-wrapper__new-th">{customerOwned ? 'Outcome' : 'Customer'}</th>
              <th className="table-wrapper__new-th">Platforms/Outcomes</th>
              <th className="table-wrapper__new-th table-wrapper__th--w-8">Inbound</th>
              <th className="table-wrapper__new-th table-wrapper__th--w-8">Outbound</th>
              <th className="table-wrapper__new-th table-wrapper__th--w-12">Last Modified</th>
              <th className="table-wrapper__new-th table-wrapper__th--w-12">Modified By</th>
            </tr>
          </thead>
          <tbody className="table-wrapper__tbody" data-testid="categories-table">
            {!loading && categories?.length === 0 ? (
              <EmptyTableState message="There are no Use cases to display" colCount="4" />
            ) : (
              categories.map((category) => (
                <CustomerCategoryItem key={category.uuid} category={category} />
              ))
            )}
          </tbody>
        </table>
      </div>
    </LoadingOverlay>
  );
};

export default CustomersCategoriesTable;
