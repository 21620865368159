import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteURLParams, setURLParams } from '../../../actions/nav';
import { getNavParams } from '../../../selectors/nav';
import { useSelector } from '../../../store';
import logEvent from '../../../utils/analytics';
import {
  days,
  halfYearToDate,
  hours,
  TIME_RANGE_FIELD,
  UNIT_OF_TIME,
  yearToDate,
} from '../../../utils/timeRanges';
import SelectSidebar from '../../SelectSidebar';

export const OPTIONS = [
  { label: 'Past 24 Hours', value: hours(-24) },
  { label: 'Past 7 Days', value: days(-7) },
  { label: 'Past 30 Days', value: days(-30) },
  { label: 'Past 60 Days', value: days(-60) },
  { label: 'Past 90 Days', value: days(-90) },
  { label: 'Past 6 Months', value: halfYearToDate() },
  { label: 'Year to Date', value: yearToDate() },
  { label: `In ${moment().format('MMMM')}`, value: '-1month' },
];

const TimeRangeFilterRedesign: React.FC = () => {
  const dispatch = useDispatch();
  const value = useSelector((state) => getNavParams(state)[TIME_RANGE_FIELD] || '');

  const handleChange = (range: string): void => {
    logEvent(`Dashboard-date-filter-${range}`);
    dispatch(setURLParams({ [TIME_RANGE_FIELD]: range }));
    dispatch(deleteURLParams([UNIT_OF_TIME]));
  };

  return (
    <SelectSidebar
      dataTestid="filter-select"
      onChange={handleChange}
      options={OPTIONS}
      value={value as string}
    />
  );
};

export default TimeRangeFilterRedesign;
