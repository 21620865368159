import React from 'react';
import { getWidgetDataById } from 'selectors/analytics';
import { getUser } from 'selectors/auth';
import { useSelector } from 'store';
import { DashboardWidget as DashboardWidgetT, IntersectedError } from 'types';
import { widgetsMap } from './Widgets';

type WidgetProps = {
  config: DashboardWidgetT;
  intersected: IntersectedError | null;
  widgetId: string;
  inGroup: boolean;
};

const DashboardWidget: React.FC<WidgetProps> = (props) => {
  const {
    config,
    intersected,
    widgetId,
    config: {
      widget,
      columns_start: colsStart,
      columns_width: colsWidth,
      hide_if_empty: hideIfEmpty,
      rows_start: rowsStart,
      rows_height: rowsHeight,
      allowed_user_roles: allowedRoles,
    },
    inGroup,
  } = props;

  const widgetData = useSelector((state) => getWidgetDataById(state, widgetId));
  const total = widgetData?.results.reduce((acc, value) => {
    if (value.y_axis.length > 0) {
      return acc + value.y_axis.reduce((acc1, value1) => acc1 + value1, 0);
    }
    return acc;
  }, 0);

  const user = useSelector(getUser);

  if (!(widget in widgetsMap)) {
    return null;
  }

  if (allowedRoles != null && (!user || !user.roles.some((r) => allowedRoles?.includes(r)))) {
    return null;
  }

  const component = widgetsMap[widget as keyof typeof widgetsMap];

  return (
    <div
      style={{
        gridColumnStart: colsStart,
        gridColumnEnd: colsStart + colsWidth,
        gridRowStart: rowsStart,
        gridRowEnd: rowsStart + rowsHeight,
        display: total === 0 && hideIfEmpty ? 'none' : '',
      }}
    >
      {/* @ts-expect-error check types */}
      {React.createElement(component, { ...config, intersected, widgetId, inGroup })}
    </div>
  );
};

export default DashboardWidget;
