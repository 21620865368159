import { setURLParams } from 'actions';
import MultiselectDropdownRedesign, {
  MultiSelectOption,
} from 'components/MultiSelectDropdown/MultiSelectDropdownRedesign';
import { userRolesGroupMapping } from 'constants/userRoles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getUserRoles } from 'selectors/auth';
import { getNavParamsByResource } from 'selectors/nav';
import { getUserRolesCount } from 'selectors/users';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';

type ComponentProps = {
  className?: string;
  resource: string;
};

const UserRolesFilter: React.FC<ComponentProps> = (props) => {
  const { className, resource } = props;

  const dispatch = useDispatch();
  const filters = (useSelector(getNavParamsByResource(resource)).group_roles || []) as string[];
  const userRoles = useSelector(getUserRoles);
  const userRolesCount = useSelector(getUserRolesCount);

  let optionsArray = Object.entries(userRolesGroupMapping).map(([key, value]) => ({
    label: key,
    value,
  }));

  if (!userRoles.includes('super-admin')) {
    optionsArray = optionsArray.filter((option) => option.value !== 'admin');
  }

  const handleChange = (option: MultiSelectOption): void => {
    logEvent(`use-cases-enabled-filter-${option.value}`);

    const idx = filters.indexOf(option.value);
    if (idx === -1) {
      dispatch(
        setURLParams({
          [`${resource}__group_roles`]: [...filters, option.value],
          [`${resource}__offset`]: '0',
        })
      );
    } else {
      dispatch(
        setURLParams({
          [`${resource}__group_roles`]: [...filters.slice(0, idx), ...filters.slice(idx + 1)],
          [`${resource}__offset`]: '0',
        })
      );
    }
  };

  return (
    <div className="w-32">
      <MultiselectDropdownRedesign
        className={className || ''}
        placeholder="Roles"
        onChange={handleChange}
        options={optionsArray}
        selectedItems={filters.map((f) => ({ label: f, value: f }))}
        dataTestid="active"
        counts={userRolesCount}
      />
    </div>
  );
};

export default UserRolesFilter;
