import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query';
import AlertsContext from 'contexts/AlertsContext';
import useCustomerDomain from 'hooks/nav/useCustomerDomainFromUrl';
import type {
  FetcherError,
  FetcherParamsType,
  MutationConfig,
  MutationResponse,
  MutationVariables,
  RequestParamsType,
  RequestTypes,
  ResponseType,
} from 'hooks/types/client';
import { useContext } from 'react';
import { useHistory } from 'utils/urls';
import { mutationFetcher } from './fetcher';

const baseConfig = {};

function useRequestMutation<K extends keyof RequestTypes>(
  endpoint: K,
  requestConfig?: MutationConfig<K>
): MutationResponse<K> {
  const history = useHistory();
  const queryClient = useQueryClient();
  const customerDomain = useCustomerDomain();
  const { showErrorAlert, showSuccessAlert } = useContext(AlertsContext);

  const onError = (err: FetcherError, variables: MutationVariables, context: unknown): void => {
    showErrorAlert(requestConfig?.errorAlert || err.message);

    if (requestConfig?.onError) {
      requestConfig.onError(err, variables, context);
    }

    if (requestConfig?.redirectOnError) {
      history.pushLookup({ routeName: 'home', customerDomain });
    }
  };

  const onSuccess = (
    data: ResponseType<K>,
    variables: MutationVariables,
    context: unknown
  ): void => {
    if (requestConfig?.successAlert) {
      showSuccessAlert(requestConfig.successAlert);
    }

    if (requestConfig?.onSuccess) {
      requestConfig.onSuccess(data, variables, context);
    }

    if (requestConfig?.invalidate && requestConfig.invalidate.length > 0) {
      requestConfig.invalidate.forEach((q) => {
        queryClient.invalidateQueries(q);
      });
    }

    if (requestConfig?.redirect) {
      history.pushLookup(requestConfig.redirect);
    }
  };

  const config = { ...baseConfig, ...requestConfig, onError, onSuccess };

  const {
    isLoading,
    isError,
    data = null,
    error = null,
    mutate,
    isSuccess,
    status,
    mutateAsync,
  } = useMutation<ResponseType<K>, FetcherError, MutationVariables>(
    [endpoint],
    mutationFetcher,
    config
  );

  const mutationFunction = (params: RequestParamsType): void => {
    const requestParams: FetcherParamsType = {
      urlParams: params.urlParams || {},
      queryParams: params.queryParams || {},
      data: params.data || {},
    };

    mutate({ params: requestParams, endpoint });
  };

  const asyncMutationFunction = async (params: RequestParamsType): Promise<void> => {
    const requestParams: FetcherParamsType = {
      urlParams: params.urlParams || {},
      queryParams: params.queryParams || {},
      data: params.data || {},
    };

    await mutateAsync({ params: requestParams, endpoint });
  };

  const isMutating = useIsMutating([endpoint]) > 0;

  const response = {
    data,
    error,
    isLoading: isLoading || isMutating,
    isError,
    mutate: (params: RequestParamsType) => mutationFunction(params),
    mutateAsync: (params: RequestParamsType) => asyncMutationFunction(params),
    isSuccess,
    status,
  };

  return response;
}

export default useRequestMutation;
