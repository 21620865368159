import React from 'react';

type AlertProps = {
  type: 'warning';
  children: React.ReactNode;
};

const WARNING_ICON = (
  <svg
    className="h-6 w-6"
    fill="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    stroke="#ffe8d3"
  >
    <path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
  </svg>
);

const icons = {
  warning: WARNING_ICON,
};

const Alert: React.FC<AlertProps> = (props) => {
  const { type, children } = props;

  const className = 'litlingo__alert__warning';

  return (
    <div className={`flex flex-row items-center p-1 rounded-sm ${className}`}>
      <div className="flex items-center">{icons[type]}</div>
      <div className="font-medium pl-1">{children}</div>
    </div>
  );
};

export default Alert;
