import { EmailTemplate, RenderedEmailTemplate, UUID } from '@litlingo/client';
import { createAction } from '@reduxjs/toolkit';
import { DynamicFormValues, ErrorObject } from 'types';

export const getEmailTemplate = createAction<{ id: string; envelopeId: string }>(
  'NOTIFICATIONS/GET_EMAIL_TEMPLATE'
);
export const getEmailTemplateSuccess = createAction<EmailTemplate & { id: string }>(
  'NOTIFICATIONS/GET_EMAIL_TEMPLATE_SUCCESS'
);
export const getEmailTemplateFailure = createAction<ErrorObject>(
  'NOTIFICATIONS/GET_EMAIL_TEMPLATE_FAILURE'
);
export const getEmailTemplateFulfill = createAction('NOTIFICATIONS/GET_EMAIL_TEMPLATE_FULFILL');

export const renderEmailTemplate = createAction<{ id: string; values: DynamicFormValues }>(
  'NOTIFICATIONS/RENDER_EMAIL_TEMPLATE'
);
export const renderEmailTemplateSuccess = createAction<{ id: string; data: RenderedEmailTemplate }>(
  'NOTIFICATIONS/RENDER_EMAIL_TEMPLATE_SUCCESS'
);
export const renderEmailTemplateFailure = createAction<ErrorObject>(
  'NOTIFICATIONS/RENDER_EMAIL_TEMPLATE_FAILURE'
);
export const renderEmailTemplateFulfill = createAction(
  'NOTIFICATIONS/RENDER_EMAIL_TEMPLATE_FULFILL'
);

type NotifyEventAuthorPayload = {
  uuid: UUID;
  context: {
    subject: string;
    body: string;
  };
};
export const notifyEventAuthor = createAction<NotifyEventAuthorPayload>(
  'NOTIFICATIONS/NOTIFY_AUTHOR'
);
export const notifyEventAuthorSuccess = createAction('NOTIFICATIONS/NOTIFY_AUTHOR_SUCCESS');
export const notifyEventAuthorFailure = createAction<ErrorObject>(
  'NOTIFICATIONS/NOTIFY_AUTHOR_FAILURE'
);
export const notifyEventAuthorFulfill = createAction('NOTIFICATIONS/NOTIFY_AUTHOR_FULFILL');

// FIXME: this is a little hacky but it's the easiest way to add the default
// body for the violation notice template
export const setEventId = createAction<string>('NOTIFICATIONS/SET_EVENT_ID');
