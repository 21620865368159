import { createAction } from '@reduxjs/toolkit';
import type { API, ErrorObject, Invitation, UUID, User } from 'types';

export type FetchInvitationsPayload = {
  // eslint-disable-next-line camelcase
  oauth2_identity_uuid?: UUID | null;
};

export const fetchInvitations = createAction<FetchInvitationsPayload>('INVITATIONS/FETCH');
export const fetchInvitationsRequest = createAction('INVITATIONS/FETCH_REQUEST');
export const fetchInvitationsSuccess = createAction<API.WrappedAPIResponse<Invitation>>(
  'INVITATIONS/FETCH_SUCCESS'
);
export const fetchInvitationsFailure = createAction<ErrorObject>('INVITATIONS/FETCH_FAILURE');
export const fetchInvitationsFulfill = createAction('INVITATIONS/FETCH_FULFILL');

export const upsertInvitation = createAction<Invitation>('INVITATIONS/UPSERT');
export const upsertInvitationRequest = createAction('INVITATIONS/UPSERT_REQUEST');
export const upsertInvitationSuccess = createAction<Invitation>('INVITATIONS/UPSERT_SUCCESS');
export const upsertInvitationFailure = createAction<ErrorObject>('INVITATIONS/UPSERT_FAILURE');
export const upsertInvitationFulfill = createAction('INVITATIONS/UPSERT_FULFILL');

export const deleteInvitation = createAction<{ uuid: UUID }>('INVITATIONS/DELETE');
export const deleteInvitationRequest = createAction('INVITATIONS/DELETE_REQUEST');
export const deleteInvitationSuccess = createAction<UUID>('INVITATIONS/DELETE_SUCCESS');
export const deleteInvitationFailure = createAction<ErrorObject>('INVITATIONS/DELETE_FAILURE');
export const deleteInvitationFulfill = createAction('INVITATIONS/DELETE_FULFILL');

export const sendInvitation = createAction('INVITATIONS/SEND');
export const sendInvitationFailure = createAction<ErrorObject>('INVITATIONS/SEND_FAILURE');
export const sendInvitationFulfill = createAction('INVITATIONS/SEND_FULFILL');

export const resendInvitation = createAction<{ uuid: UUID }>('INVITATIONS/RESEND');
export const resendInvitationSuccess = createAction('INVITATIONS/RESEND_SUCCESS');
export const resendInvitationFailure = createAction<ErrorObject>('INVITATIONS/RESEND_FAILURE');
export const resendInvitationFulfill = createAction('INVITATIONS/RESEND_FULFILL');

export const sendUserInvitation = createAction<{
  users: User[];
}>('INVITATIONS/SEND_INVITATION');
export const sendUserInvitationRequest = createAction('INVITATIONS/SEND_INVITATION_REQUEST');
export const sendUserInvitationSuccess = createAction('INVITATIONS/SEND_INVITATION_SUCCESS');
export const sendUserInvitationFailure = createAction<ErrorObject>(
  'INVITATIONS/SEND_INVITATION_FAILURE'
);
export const sendUserInvitationFulfill = createAction('INVITATIONS/SEND_INVITATION_FULFILL');

export const sendUserInvitationQuery = createAction('INVITATIONS/SEND_INVITATION_QUERY');
export const sendUserInvitationQueryRequest = createAction(
  'INVITATIONS/SEND_INVITATION_QUERY_REQUEST'
);
export const sendUserInvitationQuerySuccess = createAction(
  'INVITATIONS/SEND_INVITATION_QUERY_SUCCESS'
);
export const sendUserInvitationQueryFailure = createAction<ErrorObject>(
  'INVITATIONS/SEND_INVITATION_FAILURE'
);
