import { createSelector } from '@reduxjs/toolkit';
import {
  addTagsToTagGroupRequest,
  deleteTagGroupRequest,
  fetchAllTagGroupsRequest,
  fetchSingleTagGroupRequest,
  fetchTagGroupsRequest,
  removeTagsFromTagGroupRequest,
  upsertTagGroupRequest,
} from 'actions/tagGroups';
import { GlobalState } from 'reducers';
import type { NormalizedResource, Selector, TagGroup, UUID } from 'types';

export const getFetchAllTagGroupsLoading: Selector<boolean> = (state) =>
  state.tagGroups.loading.includes(fetchAllTagGroupsRequest.toString());

export const getFetchTagGroupsLoading: Selector<boolean> = (state) =>
  state.tagGroups.loading.includes(fetchTagGroupsRequest.toString());

export const getFetchSingleTagGroupLoading: Selector<boolean> = (state) =>
  state.tagGroups.loading.includes(fetchSingleTagGroupRequest.toString());

export const getSelectedTagGroup: Selector<TagGroup | null> = (state) =>
  state.tagGroups.selectedTagGroup;

export const getUpsertTagGroupLoading: Selector<boolean> = (state) =>
  state.tagGroups.loading.includes(upsertTagGroupRequest.toString());

export const getDeleteTagGroupLoading: Selector<boolean> = (state) =>
  state.tagGroups.loading.includes(deleteTagGroupRequest.toString());

export const getAddTagsToTagGroupLoading: Selector<boolean> = (state) =>
  state.tagGroups.loading.includes(addTagsToTagGroupRequest.toString());

export const getRemoveTagsFromTagGroupLoading: Selector<boolean> = (state) =>
  state.tagGroups.loading.includes(removeTagsFromTagGroupRequest.toString());

export const getTagGroups: Selector<TagGroup[]> = createSelector(
  [(state: GlobalState): GlobalState['tagGroups']['items'] => state.tagGroups.items],
  (tagGroups) => Object.values(tagGroups)
);

export const getTagGroupById =
  (uuid: string): Selector<TagGroup | undefined> =>
  (state) =>
    Object.values(state.tagGroups.items).find((item) => item.uuid === uuid);

export const getFilterTagGroups: Selector<TagGroup[]> = createSelector(
  [(state: GlobalState): GlobalState['tagGroups']['filterItems'] => state.tagGroups.filterItems],
  (tagGroups) => Object.values(tagGroups)
);

export const getTagGroupsForSavedSearch: Selector<TagGroup[], [UUID]> = (state, savedSearchId) => {
  const savedSearch = state.savedSearches.items[savedSearchId];

  if (!savedSearch) return [];

  const tagGroupsIds = savedSearch.config?.tag_values_groups_uuids;

  const tagGroups: TagGroup[] = [];

  Object.values(state.tagGroups.filterItems).forEach(
    (t) => tagGroupsIds?.includes(t.uuid) && tagGroups.push(t)
  );

  return tagGroups;
};

export const getTagGroupsTotalCount: Selector<number> = (state) => state.tagGroups.count;

export const getTagGroupsForAssignment: Selector<TagGroup[], [UUID]> = (state, assignmentId) => {
  const assignment = state.assignments.items[assignmentId];

  if (!assignment) return [];

  const tagGroupsIds = assignment.saved_search?.config?.tag_values_groups_uuids;

  const tagGroups: TagGroup[] = [];

  Object.values(state.tagGroups.filterItems).forEach(
    (t) => tagGroupsIds?.includes(t.uuid) && tagGroups.push(t)
  );

  return tagGroups;
};

export const getTagGroupsForCustomer: Selector<TagGroup[]> = createSelector(
  [
    (state: GlobalState): string[] => state?.auth?.user?.customer?.config?.tag_group_uuids || [],
    (state: GlobalState): NormalizedResource<TagGroup> => state.tagGroups.filterItems,
  ],
  (customerTagGroups, filterItems) => {
    if (!customerTagGroups || customerTagGroups.length === 0) return [];

    const tagGroups: TagGroup[] = [];

    customerTagGroups.forEach((t) => {
      const group = Object.values(filterItems).find((g) => g.uuid === t);
      if (group) {
        tagGroups.push(group);
      }
    });

    return tagGroups;
  }
);

export const getFurtherReviewTagGroup: Selector<TagGroup | null> = createSelector(
  [
    (state: GlobalState): string | undefined =>
      state?.auth?.user?.customer?.config?.further_review_tag_group || '',
    (state: GlobalState): NormalizedResource<TagGroup> => state.tagGroups.filterItems,
  ],
  (furtherReviewTagGroupId, filterItems) => {
    const furtherReviewTagGroup = Object.values(filterItems).find(
      (t) => t.uuid === furtherReviewTagGroupId
    );

    return furtherReviewTagGroup || null;
  }
);
