import React from 'react';
import { getTriggeredNodes } from 'selectors/communications';
import { getSelectedRuleIdentifiers } from 'selectors/ruleGroup';
import { useSelector } from 'store';
import type { MRuleConfigNode, RuleIdentifier, UUID } from 'types';
import { RELATIONSHIP_BOX_WIDTH_TAILWIND } from 'utils/dimensions';

type ComponentProps = {
  identifiers: MRuleConfigNode[];
};

const RenderIdentifiers: React.FC<ComponentProps> = ({ identifiers }) => {
  const ruleIdentifiers = useSelector(getSelectedRuleIdentifiers);
  const triggeredNodes = useSelector(getTriggeredNodes);

  const getIdentifierRealName = (identifierId: UUID | undefined): RuleIdentifier | undefined => {
    if (ruleIdentifiers != null) {
      return ruleIdentifiers.find((element) => element.identifier_uuid === identifierId);
    }

    return undefined;
  };

  const createBox = (name: string, id: string): JSX.Element => (
    <div
      className={`border-2 border-gray-500 py-1 pl-3 my-2 text-left ${RELATIONSHIP_BOX_WIDTH_TAILWIND}  ${
        triggeredNodes.includes(id) ? 'bg-gray-400 border-gray-600' : 'bg-white border-gray-500'
      }`}
    >
      {name}
    </div>
  );

  return (
    <>
      {identifiers.map((node) => (
        <div key={node.id} className="flex flex-row justify-between w-full">
          {createBox(
            getIdentifierRealName('annotatorId' in node ? node.annotatorId : '')?.identifier
              ?.name || node.name,
            node.id
          )}
        </div>
      ))}
    </>
  );
};

export default RenderIdentifiers;
