import { API, TestCase } from '@litlingo/client';
import LoadingIndicator from 'components/LoadingIndicator';
import useClickOutside from 'components/utils/useClickOutside';
import { SEARCH_ICON } from 'constants/filterIcons';
import { debounce } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import logEvent from 'utils/analytics';

type ComponentProps = {
  placeholder?: string;
  className?: string;
  logEventMessage?: string;
  handleSearch?: (value: string) => void;
  value: string;
  isLoading: boolean;
  data: API.WrappedAPIResponse<TestCase> | null;
  handleClick: (testCase: TestCase) => void;
};

const RuleManagerSerchBar: React.FC<ComponentProps> = (props) => {
  const {
    value,
    placeholder,
    className,
    logEventMessage,
    handleSearch,
    isLoading,
    data,
    handleClick,
  } = props;

  const [body, setBody] = useState<string>(value);
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = useCallback(() => {
    setBody('');
    setIsOpen(false);
  }, [setIsOpen]);

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleSearchChange = (eventValue: string): void => {
    if (logEventMessage) logEvent(`${logEventMessage}-${eventValue}`);

    if (handleSearch) {
      handleSearch(eventValue);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(
    debounce((eventValue) => {
      handleSearchChange(eventValue);
    }, 500),
    []
  );

  const handleButtonClick = (): void => {
    setIsOpen(true);
  };

  const handleAddToList = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    test: TestCase
  ): void => {
    handleClick(test);
    e.stopPropagation();
  };

  const renderOption = (testCase: TestCase): JSX.Element => (
    <button
      key={testCase.uuid}
      type="button"
      data-testid={`test-select-button-${testCase.uuid}`}
      className="w-full flex flex-col text-left border-litlingo-gray-2 p-2 focus:outline-none hover:bg-litlingo-gray-1"
      onClick={(e): void => handleAddToList(e, testCase)}
    >
      <div className="w-full flex flex-row gap-1 text-body to-clamp-text ">
        <span className="clamp-text-2">{testCase.test_string}</span>
      </div>
    </button>
  );

  return (
    <div ref={wrapperRef} className={`relative inline-block text-left w-full ${className || ''}`}>
      <div className="rounded-md shadow-sm">
        <div className="flex flex-row items-center w-full h-8 bg-white">
          <div className="absolute left-0 ml-1.5 w-5">{SEARCH_ICON()}</div>
          <input
            id="search"
            name="search"
            data-testid="user-select-button"
            className="form-input h-full w-full text-litlingo-gray-6 pl-8 text-body placeholder-italic placeholder-litlingo-gray-6"
            value={body}
            placeholder={placeholder || 'Search'}
            onChange={(event): void => {
              debouncedChange(event.target.value);
              setBody(event.target.value);
            }}
            onFocus={handleButtonClick}
            autoComplete="off"
          />
        </div>
      </div>

      {isOpen && (
        <div
          data-testid="dropdown-menu"
          className="origin-bottom-left absolute mt-1 w-full rounded shadow-lg z-50 border border-litlingo-gray-2 max-h-80"
        >
          <LoadingOverlayWrapper
            active={isLoading}
            spinner={<LoadingIndicator size="5" />}
            fadeSpeed={0}
          >
            <div
              className="flex flex-col w-full rounded bg-white shadow-xs overflow-y-auto overflow-x-hidden max-h-80 custom-scrollbar"
              style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}
            >
              {data && data.records.length > 0 ? (
                data.records.map((testCase) => renderOption(testCase))
              ) : (
                <div className="h-20 p-2">{!isLoading && <span>No tests found</span>}</div>
              )}
            </div>
          </LoadingOverlayWrapper>
        </div>
      )}
    </div>
  );
};

export default RuleManagerSerchBar;
