import {
  deleteInvitation,
  deleteInvitationFailure,
  deleteInvitationFulfill,
  deleteInvitationRequest,
  deleteInvitationSuccess,
  fetchInvitations,
  fetchInvitationsFailure,
  fetchInvitationsFulfill,
  fetchInvitationsRequest,
  fetchInvitationsSuccess,
  resendInvitation,
  resendInvitationFailure,
  resendInvitationFulfill,
  resendInvitationSuccess,
  sendInvitation,
  sendInvitationFailure,
  sendInvitationFulfill,
  sendUserInvitation,
  sendUserInvitationFailure,
  sendUserInvitationFulfill,
  sendUserInvitationQuery,
  sendUserInvitationQueryFailure,
  sendUserInvitationQueryRequest,
  sendUserInvitationQuerySuccess,
  sendUserInvitationRequest,
  sendUserInvitationSuccess,
  showSuccessAlert,
  upsertInvitation,
  upsertInvitationFailure,
  upsertInvitationFulfill,
  upsertInvitationRequest,
  upsertInvitationSuccess,
} from 'actions';
import { accountsClient as LitlingoAccountsClient } from 'client';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getCustomerMemberInviteConfig } from 'selectors/auth';
import { getNavParamsByResource } from 'selectors/nav';
import { getSelectedUser } from 'selectors/users';
import type { AccountsAPI, SagaReturn } from 'types';

// @ts-ignore
function* fetchInvitationsListSaga({ payload }): SagaReturn {
  yield put(fetchInvitationsRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.invitation)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const params = {
    include_count: true,
    relationships: ['oauth2_identity'],
    ...resourceParams,
    ...payload,
  };

  const response = (yield call([LitlingoAccountsClient.resources.invitations, 'list'], {
    params,
  })) as AccountsAPI.Response<AccountsAPI.Invitations.List>;
  if (response.error != null) {
    yield put(fetchInvitationsFailure(response.error));
  } else {
    yield put(fetchInvitationsSuccess(response.data));
  }
  yield put(fetchInvitationsFulfill());
}

function* upsertInvitationSaga(action: ReturnType<typeof upsertInvitation>): SagaReturn {
  const { payload } = action;
  yield put(upsertInvitationRequest());

  const response = (yield call([LitlingoAccountsClient.resources.invitations, 'upsert'], {
    data: payload,
    params: { relationships: ['oauth2_identity'] },
  })) as AccountsAPI.Response<AccountsAPI.Invitations.Upsert>;
  if (response.error) {
    yield put(upsertInvitationFailure(response.error));
  } else {
    yield put(upsertInvitationSuccess(response.data));
    yield put(showSuccessAlert('Invitation Saved'));
  }
  yield put(upsertInvitationFulfill());
}

function* deleteInvitationSaga(action: ReturnType<typeof deleteInvitation>): SagaReturn {
  const { payload } = action;
  yield put(deleteInvitationRequest());

  const response = (yield call(
    [LitlingoAccountsClient.resources.invitations, 'delete'],
    payload.uuid
  )) as AccountsAPI.Response<AccountsAPI.Invitations.Delete>;
  if (response.error != null) {
    yield put(deleteInvitationFailure(response.error));
  } else {
    yield put(deleteInvitationSuccess(payload.uuid));
  }
  yield put(deleteInvitationFulfill());
}

function* sendInvitationSaga(): SagaReturn {
  const config = (yield select(getCustomerMemberInviteConfig)) as ReturnType<
    typeof getCustomerMemberInviteConfig
  >;

  const user = (yield select(getSelectedUser)) as ReturnType<typeof getSelectedUser>;

  const inviteUpsertResponse = (yield call(
    [LitlingoAccountsClient.resources.invitations, 'upsert'],
    {
      data: {
        expires_in: 16800,
        next_page: `${window.location.origin}/login`,
        oauth2_client_uuid: config?.client_id,
        oauth2_identity_uuid: user?.sub,
        provider_hints: config?.provider_hints || 'google,microsoft',
        used_on: null,
      },
      params: { relationships: ['oauth2_identity'] },
    }
  )) as AccountsAPI.Response<AccountsAPI.Invitations.Upsert>;
  if (inviteUpsertResponse.error != null) {
    yield put(sendInvitationFailure(inviteUpsertResponse.error));
  } else {
    navigator.clipboard.writeText(
      `https://accounts.litlingo.com/oauth2/v1/register?registration_token=${inviteUpsertResponse.data.registration_token}`
    );
    yield put(showSuccessAlert('Invitation url copied to your clipboard'));
    yield put(sendInvitationFulfill);
  }
}

function* resendInvitationSaga(action: ReturnType<typeof resendInvitation>): SagaReturn {
  const response = (yield call([LitlingoAccountsClient.resources.invitations.extras, 'resend'], {
    urlParams: { invitationId: action.payload.uuid },
  })) as AccountsAPI.Response<AccountsAPI.Invitations.Resend>;
  if (response.error != null) {
    yield put(resendInvitationFailure(response.error));
  } else {
    yield put(resendInvitationSuccess());
    yield put(showSuccessAlert('Invitation sent'));
  }
  yield put(resendInvitationFulfill());
}

function* sendUserInvitationSaga(action: ReturnType<typeof sendUserInvitation>): SagaReturn {
  yield put(sendUserInvitationRequest());

  const { users } = action.payload;

  const response = (yield call(
    [LitlingoAccountsClient.resources.invitations.extras, 'sendInvitations'],
    {
      data: { uuids: users.map((u) => u.uuid) },
    }
  )) as AccountsAPI.Response<AccountsAPI.Invitations.Send>;

  if (response.error != null) {
    yield put(sendUserInvitationFailure(response.error));
  } else {
    yield put(sendUserInvitationSuccess());

    if (users.length === 1) {
      yield put(showSuccessAlert(`Invitation sent to ${users[0].name}`));
    } else {
      yield put(showSuccessAlert(`Invitations sent to ${users.length} users`));
    }
  }
  yield put(sendUserInvitationFulfill());
}

function* sendUserInvitationQuerySaga(): SagaReturn {
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.user)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const params = {
    ...resourceParams,
    offset: 0,
    limit: -1,
  };

  yield put(sendUserInvitationQueryRequest());

  const response = (yield call(
    [LitlingoAccountsClient.resources.invitations.extras, 'bulkSendInvitationQuery'],
    {
      params,
    }
  )) as AccountsAPI.Response<AccountsAPI.Invitations.sendBulkQuery>;

  if (response.error != null) {
    yield put(sendUserInvitationQueryFailure(response.error));
  } else {
    yield put(sendUserInvitationQuerySuccess());
    yield put(showSuccessAlert(`Invitations sent to all users`));
  }
}

function* invitationsSaga(): SagaReturn {
  yield takeLatest(upsertInvitation.toString(), upsertInvitationSaga);
  yield takeLatest(deleteInvitation.toString(), deleteInvitationSaga);
  yield takeLatest(fetchInvitations, fetchInvitationsListSaga);
  yield takeLatest(sendInvitation.toString(), sendInvitationSaga);
  yield takeLatest(resendInvitation.toString(), resendInvitationSaga);
  yield takeLatest(sendUserInvitation.toString(), sendUserInvitationSaga);
  yield takeLatest(sendUserInvitationQuery.toString(), sendUserInvitationQuerySaga);
}

export default invitationsSaga;
