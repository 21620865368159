import { Role } from '@litlingo/client';

/* eslint-disable import/prefer-default-export */
export const DEFAULT_ROLES = ['user', 'anonymous'];

export type UserTypeKey = 'endUser' | 'reviewer' | 'projectManager' | 'admin' | 'systemAdmin';

export type UserType = { roles: Role[]; label: string };

export const userTypesMapping: Record<UserTypeKey, UserType> = {
  endUser: { roles: ['user'], label: 'End User' },
  reviewer: { roles: ['super-reviewer', 'admin'], label: 'Reviewer' },
  projectManager: {
    roles: ['assignment-admin', 'super-reviewer', 'admin'],
    label: 'Project Manager',
  },
  admin: { roles: ['super-reviewer', 'super-admin'], label: 'Litlingo Admin' },
  systemAdmin: { roles: ['system-admin'], label: 'System User' },
};

export const userRolesGroupMapping = {
  'End User': 'end_user',
  Reviewer: 'reviewer',
  'Project Manager': 'project_manager',
  'Litlingo Admin': 'admin',
  'System User': 'system_admin',
};

export const selectableUserTypes = (({
  reviewer,
  projectManager,
  admin,
  endUser,
  systemAdmin,
}: typeof userTypesMapping): Partial<typeof userTypesMapping> => ({
  reviewer,
  projectManager,
  admin,
  endUser,
  systemAdmin,
}))(userTypesMapping);
