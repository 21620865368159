import Navbar from 'components/Navbar';
import React from 'react';
import ReviewSetsList from './ReviewSetsList';
import ReviewSetSidebar from './Sidebar';

const ReviewSetsManager: React.FC = () => {
  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <div
      className="flex flex-col min-w-screen bg-white overflow-hidden"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />

      <div className="h-full w-full flex flex-row overflow-hidden">
        <div className="h-full w-3/5 flex flex-col overflow-hidden">
          <ReviewSetsList />
        </div>
        <div
          className="h-full w-2/5 flex flex-col bg-litlingo-gray-0.5 border-l border-litlingo-gray-2 overflow-hidden"
          style={{
            boxShadow: '3px 1px 10px rgba(0, 0, 0, 0.25)',
          }}
        >
          <ReviewSetSidebar />
        </div>
      </div>
    </div>
  );
};

export default ReviewSetsManager;
