import {
  addNewLanguageMatcher,
  editLanguageMatcher,
  fetchLanguageMatcherTypes,
  setActiveLanguageMatcherId,
  setHasChanges,
  upsertAnnotator,
} from 'actions';
import type { UpsertAnnotatorPayload } from 'actions/annotator';
import CollapsableCard from 'components/CollapsableCard';
import EmptyLanguageMatcherState from 'components/EmptyLanguageMatcherState';
import DeleteMatcherButton from 'components/LanguageMatcher/DeleteMatcherButton';
import LanguageMatcherSidebarList from 'components/LanguageMatcher/LanguageMatcherSidebarList';
import LeavePageModal from 'components/LeavePageModal';
import WarningMsg from 'components/WarningMsg';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getActiveLanguageMatcher,
  getAnnotator,
  getAnnotatorRequestLoading,
  getLanguageMatcherByAnnotatorId,
  getLanguageMatcherTypes,
  saveAnnotatorRequestLoading,
  unsavedChanges,
} from 'selectors/annotator';
import { useSelector } from 'store';
import type { MLanguageMatcher, UUID } from 'types';
import UserNotificationMsgs from 'utils/userNotificationMsgs';
import { v4 as uuidv4 } from 'uuid';
import ChooseCombinationType from './ChooseCombinationType';

type LanguageMatcherType = {
  annotatorData: UpsertAnnotatorPayload;
};

const LanguageMatcher: React.FC<LanguageMatcherType> = ({ annotatorData }) => {
  const dispatch = useDispatch();

  const [isSaved, setIsSaved] = useState(false);
  const [languageMatcherFilter, setLanguageMatcherFilter] = useState('');
  const [highlightedLanguageMatcher, setHighlightedLanguageMatcher] = useState<
    string | undefined
  >();

  const { annotatorId } = useParams<{ annotatorId: string }>();
  const unsaved = useSelector(unsavedChanges);
  const activeLanguageMatcher = useSelector(getActiveLanguageMatcher);
  /* const activeLanguageMatcherId = useSelector(getActiveLanguageMatcherId); */
  const annotator = useSelector((state) => getAnnotator(state, annotatorId)) || {};
  const languageMatcherTypes = useSelector(getLanguageMatcherTypes);
  const isLoadingSaveAnnotator = useSelector(saveAnnotatorRequestLoading);
  const isGetAnnotatorRequestLoading = useSelector(getAnnotatorRequestLoading);
  const languageMatchers = useSelector((state) =>
    getLanguageMatcherByAnnotatorId(state, annotatorId, languageMatcherFilter.toUpperCase())
  );

  const { register, setValue, handleSubmit } = useForm<MLanguageMatcher>();

  useEffect(() => {
    dispatch(fetchLanguageMatcherTypes());
  }, [dispatch]);

  useEffect(() => {
    setHighlightedLanguageMatcher(languageMatchers[0]?.uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeLanguageMatcher && activeLanguageMatcher.name) {
      setValue('name', activeLanguageMatcher.name);
      setValue('description', activeLanguageMatcher.description);
    }
  }, [activeLanguageMatcher, setValue]);

  useEffect(() => {
    if (!isGetAnnotatorRequestLoading && highlightedLanguageMatcher) {
      dispatch(setActiveLanguageMatcherId(highlightedLanguageMatcher));
    }
  }, [dispatch, highlightedLanguageMatcher, isGetAnnotatorRequestLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLanguageMatcherClick = (languageMatcherId: UUID): void => {
    setHighlightedLanguageMatcher(languageMatcherId);
    dispatch(setActiveLanguageMatcherId(languageMatcherId));
  };

  const onEditLanguageMatcher = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!activeLanguageMatcher.uuid || e.target.value === null) return;
    dispatch(
      editLanguageMatcher({
        languageMatcherId: activeLanguageMatcher.uuid,
        data: { [e.target.name]: e.target.value },
      })
    );
  };

  const onSubmit = (): void => {
    dispatch(
      upsertAnnotator({ ...annotatorData, annotatorId: annotator.uuid, options_config: {} })
    );
    setIsSaved(true);
  };

  const addLocalLanguageMatcher = (): void => {
    const newMatcherId = uuidv4();

    const defaultValues: { [key: string]: string | number } = {};
    Object.entries(languageMatcherTypes.adjacent_tokens).forEach(([key, field]) => {
      if (field != null && field.default) {
        defaultValues[key] = field.default;
      }
    });
    const matcherBody = {
      uuid: newMatcherId,
      name: `New ${languageMatchers.length + 1}`,
      saved: false,
      type: 'token',
      isNew: true,
      ...defaultValues,
    };

    dispatch(
      addNewLanguageMatcher({
        annotatorId,
        languageMatcherId: newMatcherId,
        matcherBody,
      })
    );
    handleLanguageMatcherClick(newMatcherId);
  };

  const saveButton = (
    <div className="flex flex-row items-end gap-4">
      <WarningMsg
        message={UserNotificationMsgs.unsavedChanges}
        showLoading={isLoadingSaveAnnotator}
        showWarning={unsaved}
        showSavedMsg={isSaved}
      />

      <button
        data-testid="save-matchers-button"
        type="button"
        className="button button--primary h-8"
        onClick={(e): void => {
          handleSubmit(onSubmit)();
          e.stopPropagation();
        }}
      >
        Save
      </button>
    </div>
  );

  return (
    <CollapsableCard headerTitle="Matchers" customAction={saveButton}>
      <div className="flex relative h-104">
        <LanguageMatcherSidebarList
          languageMatchers={languageMatchers}
          languageMatcherFilter={languageMatcherFilter}
          setLanguageMatcherFilter={setLanguageMatcherFilter}
          highlightedLanguageMatcher={highlightedLanguageMatcher}
          addLocalLanguageMatcher={addLocalLanguageMatcher}
          handleLanguageMatcherClick={handleLanguageMatcherClick}
        />
        <div className="w-3/4 flex flex-col justify-center">
          <div
            className={`flex flex-col flex-1 overflow-y-auto pb-3 ${
              !highlightedLanguageMatcher && 'justify-center'
            }`}
          >
            {!highlightedLanguageMatcher ? (
              <EmptyLanguageMatcherState addNewLanguageMatcher={addLocalLanguageMatcher} />
            ) : (
              <>
                <div className="grid gap-x-4 grid-cols-2 px-6 pt-6">
                  <label htmlFor="name" className="block litlingo-annotator-label-name text-sm">
                    Name
                    <input
                      ref={register({ required: true, pattern: /\S/i })}
                      name="name"
                      id="name"
                      data-testid="matcher-name-input"
                      defaultValue={activeLanguageMatcher && activeLanguageMatcher.name}
                      className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      onChange={onEditLanguageMatcher}
                    />
                  </label>
                  <label
                    htmlFor="description"
                    className="block litlingo-annotator-label-name text-sm"
                  >
                    {'Description '}
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        data-testid="matcher-description-input"
                        ref={register}
                        defaultValue={activeLanguageMatcher && activeLanguageMatcher.description}
                        id="description"
                        name="description"
                        className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        onChange={onEditLanguageMatcher}
                      />
                    </div>
                  </label>
                </div>
                <div className="flex px-6 justify-between">
                  <div className="w-full">
                    <ChooseCombinationType activeLanguageMatcher={activeLanguageMatcher} />
                  </div>
                </div>
              </>
            )}
          </div>
          {highlightedLanguageMatcher && (
            <DeleteMatcherButton
              setValue={setValue}
              setHighlightedLanguageMatcher={setHighlightedLanguageMatcher}
              activeLanguageMatcher={activeLanguageMatcher}
            />
          )}
          <LeavePageModal
            shouldBlockNavigation={unsaved}
            dicardAction={(): {
              payload: boolean;
              type: string;
            } => dispatch(setHasChanges(false))}
          />
        </div>
      </div>
    </CollapsableCard>
  );
};

export default LanguageMatcher;
