import { showSuccessAlert } from 'actions';
import Modal from 'components/Modal';
import { COPY_CLIPBOARD } from 'constants/commonIcons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';

type ComponentProps = {
  toggleShowModal: () => void;
};

const CustomerZendeskKeyModal: React.FC<ComponentProps> = ({ toggleShowModal }) => {
  const dispatch = useDispatch();
  const zendeskToken = useSelector((state) => state.auth.zendeskKey);

  const handleCopyClick = async (): Promise<void> => {
    if (zendeskToken != null) {
      await navigator.clipboard.writeText(zendeskToken);
      dispatch(showSuccessAlert('Key copied to your clipboard'));
    }
  };

  const modalBody = (
    <div className="mb-4 mt-6">
      <div className="flex">
        <input
          id="secret"
          name="secret"
          defaultValue={zendeskToken != null ? zendeskToken : ''}
          disabled
          className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mb-2"
        />
        <button className="focus:outline-none " type="button" onClick={handleCopyClick}>
          {COPY_CLIPBOARD}
        </button>
      </div>
      <div className="flex justify-start items-center">
        <div>
          <svg
            fill="none"
            className="text-gray-600 w-4 h-4 mr-2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
          </svg>
        </div>
        <div>
          <p className="text-gray-600 text-sm">Keys are encrypted to keep your account safe</p>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      body={modalBody}
      title="Zendesk Key"
      okButton
      okButtonText="Done"
      okButtonOnClick={toggleShowModal}
      toggleShowModal={toggleShowModal}
    />
  );
};

export default CustomerZendeskKeyModal;
