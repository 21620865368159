/* eslint-disable camelcase */
/* eslint-disable max-lines */
import { fetchAggs, fetchCampaignsForFilterPills, fetchTagsForFilterPills } from 'actions';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getExperiments } from 'selectors/auth';
import { getCampaignFilterItems } from 'selectors/campaigns';
import { getEnvelopesAggs } from 'selectors/envelopes';
import { getTagFilterItems } from 'selectors/tags';
import { useSelector } from 'store';
import { v4 } from 'uuid';
import EnvelopeListFilterPills from './FilterPills';

const ExpirementCard: React.FC<{
  aggs:
    | {
        [key: string]: {
          [key: string]: {
            count: {
              buckets: { key: string; doc_count: number }[];
            };
            doc_count: number;
          };
        };
      }
    | undefined;
  exp: {
    id: string;
    corpus: { uuid: string }[];
    positive: { uuid: string }[];
    negative: { uuid: string }[];
    testing: { uuid: string }[];
  };
}> = ({ aggs, exp }) => {
  const dispatch = useDispatch();

  const campaignById = useSelector(getCampaignFilterItems);
  const tagById = useSelector(getTagFilterItems);

  const formattedAggs: {
    [key: string]: {
      false_positive: number;
      true_positive: number;
      false_negative: number;
      precision: number;
      recall: number;
      f1: number;
    };
  } = {};

  if (aggs && aggs[exp.id]) {
    aggs[exp.id].false_positive.count.buckets.forEach((b: { key: string; doc_count: number }) => {
      formattedAggs[b.key] = {
        ...(formattedAggs[b.key] || {}),
        false_positive: b.doc_count,
      };
    });

    aggs[exp.id].true_positive.count.buckets.forEach((b: { key: string; doc_count: number }) => {
      if (formattedAggs[b.key]) {
        const true_positive = b.doc_count;
        const { false_positive } = formattedAggs[b.key];
        const tp_total = aggs[exp.id].true_positive.doc_count;
        const false_negative = tp_total - true_positive;
        const precision = true_positive / (true_positive + false_positive);
        const recall = true_positive / (true_positive + false_negative);
        const f1 = (2 * (precision * recall)) / (precision + recall);

        formattedAggs[b.key] = {
          ...(formattedAggs[b.key] || {}),
          true_positive,
          false_negative,
          precision,
          recall,
          f1,
        };
      }
    });

    if (Object.keys(campaignById).length > 0) {
      Object.keys(formattedAggs).forEach((key) => {
        const copy = formattedAggs[key];
        if (campaignById[key]) {
          delete formattedAggs[key];
          formattedAggs[campaignById[key].name] = copy;
        }
      });
    }
  }

  useEffect(() => {
    dispatch(
      fetchAggs({
        aggQuery: {
          true_positive: {
            filter: {
              bool: {
                should: exp.positive.map((item: { uuid: string }) => ({
                  term: {
                    tags: item.uuid,
                  },
                })),
              },
            },
            aggs: {
              count: {
                terms: {
                  field: 'campaign_uuids',
                  include: exp.testing.map((item: { uuid: string }) => item.uuid),
                  size: 10000,
                },
              },
            },
          },
          false_positive: {
            filter: {
              bool: {
                should: exp.negative.map((item: { uuid: string }) => ({
                  term: {
                    tags: item.uuid,
                  },
                })),
              },
            },
            aggs: {
              count: {
                terms: {
                  field: 'campaign_uuids',
                  include: exp.testing.map((item: { uuid: string }) => item.uuid),
                  size: 10000,
                },
              },
            },
          },
        },
        expId: exp.id,
      })
    );

    dispatch(
      fetchTagsForFilterPills({
        uuids: [
          ...exp.positive.map((item: { uuid: string }) => item.uuid),
          ...exp.negative.map((item: { uuid: string }) => item.uuid),
        ],
      })
    );

    dispatch(
      fetchCampaignsForFilterPills({
        campaignIds: exp.testing.map((item: { uuid: string }) => item.uuid),
      })
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex flex-row pl-3 pr-3.5 mb-2">
      <div
        id="pills-section"
        className={`items-baseline px-2 justify-between transition-all duration-500 pb-1.5 overflow-x-hidden pt-2 `}
      >
        <div className="items-baseline text-xss font-base text-litlingo-gray-6 whitespace-no-wrap">
          Corpus:
          <div className="ml-1">
            <EnvelopeListFilterPills
              customTree={{
                id: v4(),
                op: 'and',
                data: [
                  {
                    op: 'field',
                    field: 'tags',
                    data: [
                      {
                        op: 'or',
                        data: exp.corpus.map((item) => ({
                          op: 'word',
                          value: item.uuid,
                          label: tagById[item.uuid]?.value,
                          id: v4(),
                          parent: v4(),
                        })),

                        id: v4(),
                        parent: v4(),
                      },
                    ],
                    id: v4(),
                    parent: v4(),
                  },
                ],
              }}
            />
            {aggs && aggs[exp.id]
              ? aggs[exp.id].true_positive.doc_count + aggs[exp.id].false_positive.doc_count
              : null}
          </div>
        </div>
        <div className="items-baseline text-xss font-base text-litlingo-gray-6 whitespace-no-wrap">
          Positive:
          <div className="ml-1">
            <EnvelopeListFilterPills
              customTree={{
                id: v4(),
                op: 'and',
                data: [
                  {
                    op: 'field',
                    field: 'tags',
                    data: [
                      {
                        op: 'or',
                        data: exp.positive.map((item) => ({
                          op: 'word',
                          value: item.uuid,
                          label: tagById[item.uuid]?.value,
                          id: v4(),
                          parent: v4(),
                        })),
                        id: v4(),
                        parent: v4(),
                      },
                    ],
                    id: v4(),
                    parent: v4(),
                  },
                ],
              }}
            />
            {aggs && aggs[exp.id] ? aggs[exp.id].true_positive.doc_count : null}
          </div>
        </div>

        <div className="tems-baseline text-xss font-base text-litlingo-gray-6 whitespace-no-wrap">
          Negative:
          <div className="ml-1">
            <EnvelopeListFilterPills
              customTree={{
                id: v4(),
                op: 'and',
                data: [
                  {
                    op: 'field',
                    field: 'tags',
                    data: [
                      {
                        op: 'or',
                        data: exp.negative.map((item) => ({
                          op: 'word',
                          value: item.uuid,
                          label: tagById[item.uuid]?.value,
                          id: v4(),
                          parent: v4(),
                        })),
                        id: v4(),
                        parent: v4(),
                      },
                    ],
                    id: v4(),
                    parent: v4(),
                  },
                ],
              }}
            />
            {aggs && aggs[exp.id] ? aggs[exp.id].false_positive.doc_count : null}
          </div>
        </div>
      </div>
      <div>
        <pre>{JSON.stringify(formattedAggs, null, 4).replace(/["{[,}\]]/g, '')}</pre>
      </div>
    </div>
  );
};

const EnvelopeListContent: React.FC = () => {
  const aggs = useSelector(getEnvelopesAggs);
  const experiments = useSelector(getExperiments);

  return (
    <>
      <div
        id="envelope-list-content"
        className="pb-4 transition-all duration-500 flex flex-col w-full h-full overflow-scroll pt-6"
        aria-hidden
      >
        {experiments.map((exp) => (
          <ExpirementCard key={JSON.stringify(exp)} exp={exp} aggs={aggs} />
        ))}
      </div>
    </>
  );
};

export default EnvelopeListContent;
