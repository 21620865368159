/* eslint-disable camelcase */
import React from 'react';

import { setSelectedModel } from 'actions';
import { useDispatch } from 'react-redux';
import { getSelectedModel, Tracing } from 'selectors/envelopeReview';
import { useSelector } from 'store';
import { MRuleConfig } from 'types';
import EnvelopeModelRowItem from './EnvelopeModelRowItem';

type ComponentProps = {
  tracing: Tracing[];
  arrayTree: MRuleConfig[];
};

const EventSection: React.FC<ComponentProps> = ({ tracing, arrayTree }) => {
  const dispatch = useDispatch();
  const selectedModel = useSelector(getSelectedModel);

  if (!tracing) return null;

  const handleCheck = (values: string[]): void => {
    if (selectedModel.includes(values[0])) {
      dispatch(setSelectedModel([]));
    } else {
      dispatch(setSelectedModel(values));
    }
  };

  const actionItem = (annotatorIds: string[], branchId: string): JSX.Element => (
    <input
      type="checkbox"
      className="form-checkbox litlingo-checkbox w-4 h-4"
      checked={selectedModel.includes(branchId)}
      onChange={(): void => handleCheck([branchId, ...annotatorIds])}
    />
  );

  const renderRows = (): JSX.Element[] => {
    const rowsInfo = arrayTree.map((node, rowInd, arr) => {
      const {
        name,
        description,
        id,
        level,
        relationship,
        modifiers,
        annotatorId,
        identifierId,
        deleted,
        negated,
        mode_of_speech,
        parent,
        color,
      } = node;

      return {
        id,
        index: rowInd,
        level,
        name,
        description,
        relationship,
        modifiers,
        deleted,
        annotatorId,
        identifierId,
        negated,
        mode_of_speech,
        parent,
        color,
        actionItem:
          id === tracing[0].id ? (
            actionItem(
              arr.reduce<string[]>((acc, val) => {
                if (tracing.find((et) => et.id === val.id) && val.annotatorId) {
                  return [...acc, val.annotatorId];
                }
                return acc;
              }, []),
              id
            )
          ) : (
            <div />
          ),
      };
    });

    const renderedRows = rowsInfo
      .filter((n) => tracing.find((et) => et.id === n.id))
      .map((n) => (
        <EnvelopeModelRowItem key={n.id} data={n} eventTracing={tracing} active={false} />
      ));

    return renderedRows;
  };

  return (
    <div>
      {arrayTree && (
        <div className="flex flex-row items-start">
          <div>{renderRows()}</div>
        </div>
      )}
    </div>
  );
};
export default EventSection;
