import { editCustomer, fetchSingleCustomer, saveCustomer } from 'actions';
import Card from 'components/Card';
import CustomerSaveButton from 'components/Customer/CustomerSaveButton';
import LinkLookup from 'components/LinkLookup';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import Permissions from 'components/Permissions';
import { LEFT_ARROW_ICON } from 'constants/envelopeIcons';
import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useDispatch } from 'react-redux';
import { getCustomer, getUser } from 'selectors/auth';
import { useSelector } from 'store';
import type { CustomerConfig } from 'types';
import CustomerLeaveConfirmModal from './CustomerLeaveConfirmModal';

const CustomerAdvanced: React.FC = () => {
  const dispatch = useDispatch();

  const [isSaved, setIsSaved] = useState(false);
  const [unsaved, setUnsaved] = useState(false);
  const [isLeaveModalOpen, setLeaveModalOpen] = useState(false);

  const user = useSelector(getUser);
  const customer = useSelector(getCustomer);

  const handleEdit = (data: CustomerConfig): void => {
    setUnsaved(true);
    dispatch(editCustomer({ config: data }));
  };

  const handleSave = async (): Promise<void> => {
    dispatch(saveCustomer({}));
    setIsSaved(true);
    setUnsaved(false);
  };

  useEffect(() => {
    if (user) {
      dispatch(fetchSingleCustomer());
    }
  }, [dispatch, user]);

  const header = (
    <div className="flex justify-between sticky top-0 z-10 bg-litlingo-white">
      <div className="pt-5 pb-12 flex items-center">
        <LinkLookup
          data-testid="customer-json-editor-left-button"
          routeName="customer"
          className="mx-6 focus:outline-none"
          onClick={(e): void => {
            if (unsaved) {
              e.preventDefault();
              setLeaveModalOpen(true);
            }
          }}
        >
          {LEFT_ARROW_ICON}
        </LinkLookup>
        <span className="text text--large text--bold">JSON editor</span>
      </div>
      <CustomerSaveButton
        data-testid="customer-save-button"
        handleSave={handleSave}
        unsaved={unsaved}
        isSaved={isSaved}
        className="sm:px-2 lg:px-5 px-5 py-3"
      />
    </div>
  );

  const editor = (
    <div className="px-5 py-4">
      <div className="flex items-center pb-2">
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.25685 1.09812C7.02185 -0.261875 8.97885 -0.261875 9.74285 1.09812L15.3228 11.0181C16.0728 12.3521 15.1098 13.9981 13.5808 13.9981H2.41985C0.889848 13.9981 -0.0731517 12.3521 0.676848 11.0181L6.25685 1.09812ZM8.99985 10.9991C8.99985 11.2643 8.89449 11.5187 8.70695 11.7062C8.51942 11.8938 8.26506 11.9991 7.99985 11.9991C7.73463 11.9991 7.48028 11.8938 7.29274 11.7062C7.10521 11.5187 6.99985 11.2643 6.99985 10.9991C6.99985 10.7339 7.10521 10.4796 7.29274 10.292C7.48028 10.1045 7.73463 9.99913 7.99985 9.99913C8.26506 9.99913 8.51942 10.1045 8.70695 10.292C8.89449 10.4796 8.99985 10.7339 8.99985 10.9991ZM7.99985 2.99913C7.73463 2.99913 7.48028 3.10448 7.29274 3.29202C7.10521 3.47955 6.99985 3.73391 6.99985 3.99913V6.99913C6.99985 7.26434 7.10521 7.5187 7.29274 7.70623C7.48028 7.89377 7.73463 7.99913 7.99985 7.99913C8.26506 7.99913 8.51942 7.89377 8.70695 7.70623C8.89449 7.5187 8.99985 7.26434 8.99985 6.99913V3.99913C8.99985 3.73391 8.89449 3.47955 8.70695 3.29202C8.51942 3.10448 8.26506 2.99913 7.99985 2.99913Z"
            fill="#F2994A"
          />
        </svg>

        <span className="text-sm ml-3">
          Caution: don’t edit these settings unless you know what you’re doing
        </span>
      </div>
      <ReactJson
        src={customer.config}
        theme="chalk"
        onEdit={(event): void => handleEdit(event.updated_src as CustomerConfig)}
        onDelete={(event): void => handleEdit(event.updated_src as CustomerConfig)}
        onAdd={(event): void => handleEdit(event.updated_src as CustomerConfig)}
        style={{ paddingTop: '10px', paddingBottom: '25px' }}
      />
    </div>
  );

  return (
    <Permissions field="customers.config">
      <div className="litlingo-gray-bg-color-4">
        <Navbar />

        <div
          className="flex flex-row overflow-auto"
          style={{
            minHeight: 'calc(100vh - 4rem)',
            height: 'calc(100vh - 4rem)',
          }}
        >
          <SettingSidebar />
          <div className="mt-0 mx-auto min-w-2xl w-8/12 max-w-6xl text-left overflow-auto">
            <Card items={[header, editor]} />
          </div>
          {isLeaveModalOpen && (
            <CustomerLeaveConfirmModal
              toggleShowModal={(): void => setLeaveModalOpen(!isLeaveModalOpen)}
              setUnsaved={setUnsaved}
            />
          )}
        </div>
      </div>
    </Permissions>
  );
};

export default CustomerAdvanced;
