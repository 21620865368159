import notFoundAnimation from 'assets/404-animation.json';
import Navbar from 'components/Navbar';
import React from 'react';
import Lottie from 'react-lottie';
import { useHistory } from 'utils/urls';

const NotFoundPage: React.FC = () => {
  const history = useHistory();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: notFoundAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />
      <div className="flex justify-center items-center flex-col h-screen">
        <Lottie
          options={defaultOptions}
          height={300}
          width={500}
          isStopped={false}
          isPaused={false}
        />
        <h1 className="text-xl text-center text-gray-600 my-4">
          You don&apos;t have access to this page or the data is out of retention.
        </h1>
        <div className="flex flex-row mt-4">
          <div>
            <button
              type="button"
              onClick={(): void => history.go(-1)}
              data-skip
              className="button button--primary"
            >
              Go back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
