import React from 'react';
import { useSelector } from 'react-redux';
import { getTree } from 'selectors/envelopes';
import { getAllFieldsValuesWithLabels } from 'utils/parserTree';

type ComponentProps = {
  handleDelete: (labelId: string, exclude: boolean) => void;
  filterData: string[];
  notFilterData: string[];
  getResourceKey: string;
};

const ShowLabels = ({
  handleDelete,
  getResourceKey,
  filterData,
  notFilterData,
}: ComponentProps): JSX.Element => {
  const tree = useSelector(getTree);
  const data = getAllFieldsValuesWithLabels(tree)[getResourceKey] || [];
  const negatedData = getAllFieldsValuesWithLabels(tree)[`not_${getResourceKey}`] || [];

  return (
    <div className="flex flex-col">
      {[...filterData, ...notFilterData].map((labelId) => {
        const node = [...data, ...negatedData]?.find((item) => item.value === labelId);
        if (node) {
          const { label, value } = node;
          return (
            <div
              key={value}
              role="button"
              aria-hidden
              className="relative flex justify-start items-center mt-2.5"
              onClick={(): void => handleDelete(value, notFilterData.includes(value))}
            >
              <div className="flex items-center h-5 mr-1">
                <input
                  id={`${value}-show-label`}
                  data-testid={`${value}-show-label`}
                  type="checkbox"
                  className={`form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out cursor-pointer ${
                    notFilterData.includes(labelId) ? 'litlingo-checkbox-negated' : ''
                  }`}
                  checked={filterData.includes(value) || notFilterData.includes(value)}
                />
              </div>
              <div className="text-sm leading-5">
                <label htmlFor={value} className="text-white text-xss cursor-pointer">
                  <span className="select-none break-all">{label} </span>
                </label>
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default ShowLabels;
