import { createModel } from 'actions';
import Modal from 'components/Modal';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
};

const CreateModelModal: React.FC<ComponentProps> = ({ isModalOpen, toggleModalOpen }) => {
  const dispatch = useDispatch();

  const { register, getValues, handleSubmit, watch } = useForm<{
    name: string;
    description: string;
    priority: number;
    active: string;
  }>({
    defaultValues: {
      name: '',
      description: '',
      priority: 0,
    },
  });

  const onSubmit = (): void => {
    const { name, description, priority } = getValues();

    dispatch(createModel({ name, description, priority }));

    toggleModalOpen();
  };

  const handleSaveButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const body = (
    <div className="flex flex-col mb-4 h-full text-body">
      <form className="flex flex-col items-start">
        <div className="flex flex-col items-start gap-4 w-full">
          <label htmlFor="name" className="flex flex-col gap-1 mt-2 text-body">
            <div className="flex flex-row gap-1 text-small">
              <span>Name</span>
              <span className="litlingo-red-color">*</span>
            </div>

            <input
              id="name"
              ref={register({ required: true, pattern: /\S/i })}
              name="name"
              placeholder="Use Case name"
              className="form-input h-9 w-66 p-2 text-body rounded placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>

          <label htmlFor="description" className="flex flex-col gap-1 mt-2 text-body">
            <div className="flex flex-row gap-1 text-small">
              <span>Description</span>
            </div>

            <textarea
              id="description"
              ref={register()}
              name="description"
              rows={3}
              placeholder="Use this description to help others understand the purpose of this model."
              className="form-input w-112 p-2 text-body rounded resize-none placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>

          <label htmlFor="priority" className="flex flex-col gap-1 mt-2 text-body">
            <div className="flex flex-row gap-1 text-small">
              <span>Priority (1 - 5)</span>
            </div>

            <input
              id="priority"
              type="number"
              ref={register({ min: 1, max: 5 })}
              name="priority"
              placeholder="1"
              className="form-input h-9 w-20 p-2 text-body rounded placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>
        </div>
      </form>
      {/* <label htmlFor="name">
        <span>
          Name<span className="text-litlingo-alert">*</span>
        </span>
        <input
          id="name"
          type="text"
          className="form-input h-8 w-full text-litlingo-gray-6 text-body"
          onChange={(e): void => setName(e.target.value)}
          value={name}
        />
      </label>
      <label htmlFor="name">
        <span>Description</span>
        <textarea
          id="description"
          rows={5}
          className="form-input w-full text-litlingo-gray-6 text-body resize-none"
          onChange={(e): void => setDescription(e.target.value)}
          value={description}
        />
      </label> */}
    </div>
  );

  const handleCancelReplace = (): void => {
    toggleModalOpen();
  };

  return (
    <>
      {isModalOpen && (
        <Modal
          title="Create Use Case"
          body={body}
          okButton
          okButtonText="Create"
          okButtonDisabled={!watch('name')}
          okButtonOnClick={handleSaveButton}
          toggleShowModal={toggleModalOpen}
          style={{ width: '37.5rem' }}
          cancelButton
          cancelButtonOnclick={handleCancelReplace}
          xButton
        />
      )}
    </>
  );
};

export default CreateModelModal;
