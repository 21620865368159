import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { useNavParamsByResource } from 'hooks/nav/useNavParamsByResource';
import { RequestParamsType, RequestResponse } from 'hooks/types/client';
import useRequest from '../client/useRequest';

type Options = { redirectOnError: boolean };

type UseCampaigns = (options?: Options) => RequestResponse<'campaigns.list'>;

const useCampaigns: UseCampaigns = (options) => {
  const resourceParams = useNavParamsByResource(resourceQueryParamName.campaign);

  const params: RequestParamsType = {
    queryParams: {
      ...resourceParams,
      relationships: ['rule_outcomes', 'rule_outcomes.rule', 'rule_outcomes.outcome', 'updated_by'],
      include_count: true,
      include_pii: true,
    },
    redirectOnError: options?.redirectOnError,
  };

  const result = useRequest('campaigns.list', params);

  return result;
};

export default useCampaigns;
