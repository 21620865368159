import LinkLookup from 'components/LinkLookup';
import { EXPLORE_GRAPHIC } from 'constants/reviewSets';
import React from 'react';

type ComponentProps = {
  colCount?: string;
};

// If the table have table-layout: fixed, you must provide the colCount
// prop, which is the total number of <th> in the <thead>
// This component MUST be inside the <tbody> element
const EmptyReviewList: React.FC<ComponentProps> = ({ colCount }) => (
  <tr>
    {/* @ts-ignore */}
    <td colSpan={colCount || '100%'}>
      <div className="flex flex-col items-center pb-10 pt-8 px-40">
        <div className="self-center">{EXPLORE_GRAPHIC}</div>
        <div className="flex flex-col gap-1 text-center mt-3">
          <span className="text-heading-2">No review streams to manage</span>
          <div className="text-body mt-1">
            <p className="whitespace-no-wrap">
              To get started, go to the Explore page to create review streams.
            </p>
            There are filters you can apply to monitor communications you’re interested in
            reviewing.
          </div>
        </div>
        <LinkLookup
          routeName="envelope-list"
          className="button button--primary w-48 py-1.5 flex justify-center items-center mt-4"
        >
          Get Started
        </LinkLookup>
      </div>
    </td>
  </tr>
);

export default EmptyReviewList;
