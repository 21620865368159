import { setURLParams } from 'actions';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { useSelector } from 'store';

type ComponentProps = {
  className?: string;
  resource: string;
};

const IsReadFilter: React.FC<ComponentProps> = (props) => {
  const { className, resource } = props;

  const dispatch = useDispatch();
  const filters = useSelector(getNavParamsByResource(resource)).is_read || [];
  const options = { true: 'True', false: 'False' };

  const handleChange = (value: string): void => {
    if (filters.includes(value)) {
      dispatch(
        setURLParams({
          [`${resource}__is_read`]: [],
          [`${resource}__offset`]: '0',
        })
      );
    } else {
      dispatch(
        setURLParams({
          [`${resource}__is_read`]: [value],
          [`${resource}__offset`]: '0',
        })
      );
    }
  };

  return (
    <MultiSelectDropdown
      className={className || ''}
      title="Read"
      onChange={handleChange}
      options={Object.entries(options).map(([key, value]) => ({
        label: value,
        value: key,
      }))}
      selectedItems={filters as string[]}
    />
  );
};

export default IsReadFilter;
