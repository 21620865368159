import { FallbackRender } from '@sentry/react/dist/errorboundary';
import React from 'react';

const SentryDevError: FallbackRender = ({ error, componentStack }) => {
  const stack = componentStack?.split(' in ').slice(1) || [];

  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <div className="w-3/5 flex flex-col items-center gap-4">
        <p className="text-heading-2 w-full">
          {error.name} : {error.message}
        </p>

        <ul className="max-h-128 text-body flex flex-col gap-2 px-2 overflow-auto w-full">
          {stack.map((i) => (
            <li key={i} className="w-full">{`in ${i}`}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SentryDevError;
