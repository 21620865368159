import type { ReactElement } from 'react';
import React, { useRef, Fragment, useState } from 'react';
import { createPortal } from 'react-dom';
import type {
  AnnotatedNodeT,
  AnnotationsMapItem,
  ProtectedFields,
} from '../types';
import { nodeNeedsSpaceBefore } from '../utils/spacing';

type HighlightedSuperNode = {
  annotations?: AnnotationsMapItem[];
  nodes: AnnotatedNodeT[];
  useHover: boolean;
  protectedFields?: ProtectedFields;
  portal?: string;
  hideHighlight?: boolean;
  ruleId?: string;
  sentenceHighlight?: string;
  highlightSentenceOnCLickHandle?: () => void;
};

const HighlightedSuperNode: React.FC<HighlightedSuperNode> = (props) => {
  const {
    nodes,
    annotations,
    useHover,
    protectedFields,
    portal,
    hideHighlight = false,
    ruleId,
    sentenceHighlight = false,
    highlightSentenceOnCLickHandle,
  } = props;
  const [hovering, setHovering] = useState(false);
  const [hoveringMultipleHighlight, setHoveringMultipleHighlight] =
    useState(false);
  const [hoveredNode, setHoveredNode] = useState(-1);
  const [showPersonName, setShowPersonName] = useState([] as number[]);
  const [left, setLeft] = useState('0%');
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const separation = 30;

  const ref = useRef<HTMLDivElement>(null);

  const checkOverflow = (reference: HTMLParagraphElement | null): void => {
    if (reference == null) {
      return;
    }

    if (
      reference.getBoundingClientRect().width +
        reference.getBoundingClientRect().x >
      window.screen.width - 50
    ) {
      setLeft('-90%');
    }
  };

  const handleMouseEnter = (
    multipleHighlight?: boolean,
    nodeIndex = -1
  ): void => {
    if (multipleHighlight) {
      setHovering(false);
      setHoveredNode(nodeIndex);
      setHoveringMultipleHighlight(true);
    } else setHovering(true);
  };
  const handleMouseLeave = (multipleHighlight?: boolean): void => {
    if (multipleHighlight) {
      setHoveringMultipleHighlight(false);
      setHoveredNode(-1);
      setHovering(true);
    } else setHovering(false);
  };
  const handleClickOnName = (id: number): void => {
    const value = showPersonName.includes(id) ? [] : [id];
    setShowPersonName([
      ...showPersonName.filter((item) => item !== id),
      ...value,
    ]);
  };
  const handleEnterOnName = (event: React.KeyboardEvent, id: number): void => {
    if (event.keyCode === 13) {
      handleClickOnName(id);
    }
  };

  const handleMouseMove = (): void => {
    if (!ref || !ref.current) return;

    setPosition({
      left: ref.current.getBoundingClientRect().x,
      top: ref.current.getBoundingClientRect().y - separation,
    });
  };

  const renderAnnotators = (
    annotationsArr: AnnotationsMapItem[]
  ): ReactElement => {
    const p = portal ? document.getElementById(portal) : null;
    const tooltipSeparation = annotationsArr.length * 12;

    const style =
      annotationsArr.length === 1
        ? 'litlingo__highlighted_sentence_above__tooltip'
        : 'litlingo__highlighted_sentence_below__tooltip';

    const annotationsElement = (
      <div
        style={{
          left: position.left,
          top:
            annotationsArr.length === 1
              ? position.top
              : position.top - tooltipSeparation,
        }}
        className={`absolute litlingo__highlighted_sentence__tooltip z-10 ${style} leading-relaxed bg-white px-2 text-sm truncate border rounded shadow`}
      >
        {annotationsArr.map((item) => (
          <p key={item.uuid} ref={checkOverflow}>
            {item.name}
          </p>
        ))}
      </div>
    );

    if (p) {
      return createPortal(annotationsElement, p);
    }
    return (
      <div
        style={{ left }}
        className={`absolute litlingo__highlighted_sentence__tooltip z-10 ${style} leading-relaxed bg-white px-2 text-sm truncate border rounded shadow`}
      >
        {annotationsArr.map((item) => (
          <p key={item.uuid} ref={checkOverflow}>
            {item.name}
          </p>
        ))}
      </div>
    );
  };

  const text: JSX.Element[] = [];
  nodes.forEach((node, idx) => {
    if (nodeNeedsSpaceBefore(nodes, idx)) {
      text.push(<Fragment key={`space-${node.index}`}> </Fragment>);
    }
    if (
      node.entity === 'PERSON' &&
      protectedFields?.entity === 'FIELD_IS_PROTECTED'
    ) {
      text.push(
        <Fragment key={`node-${node.index}`}>
          <span
            className={`
              ${
                showPersonName.includes(node.index)
                  ? ''
                  : 'w-10 h-3 bg-black inline-block'
              }`}
            onClick={(): void => handleClickOnName(node.index)}
            onKeyDown={(event): void => handleEnterOnName(event, node.index)}
            role="button"
            tabIndex={0}
            id={`person-${node.index}`}
          >
            {showPersonName.includes(node.index) ? node.text : ''}
          </span>
        </Fragment>
      );
    } else if (
      node.entity === 'PERSON' &&
      protectedFields?.entity === 'FIELD_IS_HIDDEN'
    ) {
      text.push(
        <Fragment key={`node-${node.index}`}>
          <span
            className="w-10 h-3 bg-black inline-block"
            id={`person-${node.index}`}
          />
        </Fragment>
      );
    } else if (node.multipleHighlight && annotations) {
      const filteredAnnotations = annotations.filter(
        (annotation) =>
          annotation.matches.includes(node.index) &&
          annotation.type !== 'sentence'
      );
      text.push(
        <Fragment key={`node-${node.index}`}>
          <div
            onMouseEnter={(): void => {
              handleMouseEnter(true, node.index);
            }}
            onMouseLeave={(): void => handleMouseLeave(true)}
            className="bg-yellow inline-block relative"
          >
            {node.text}
            {useHover &&
              hoveringMultipleHighlight &&
              hoveredNode === node.index &&
              renderAnnotators(filteredAnnotations)}
          </div>
        </Fragment>
      );
    } else {
      let background = '';
      if (sentenceHighlight === 'highlight') {
        background = 'bg-litlingo-highlight';
      }
      if (sentenceHighlight === 'underline') background = 'bg-litlingo-gray-1';

      text.push(
        <button
          type="button"
          className={`inline-block ${background} focus:outline-none select-text`}
          onClick={(): void => {
            if (highlightSentenceOnCLickHandle) {
              highlightSentenceOnCLickHandle();
            }
          }}
        >
          {node.text}
        </button>
      );
    }
  });

  if (annotations != null) {
    const isSentenceType = annotations.some(
      (annotation) => annotation.type === 'sentence'
    );
    const sentenceStyle = isSentenceType
      ? 'border-0 border-b-2 border-solid border-orange-300'
      : '';

    const cleanAnnotations = isSentenceType
      ? annotations.filter((annotation) => annotation.type === 'sentence')
      : annotations;

    const uniqueAnnotations = cleanAnnotations.filter(
      (item, index, self) =>
        index === self.findIndex((other) => item.name === other.name)
    );

    const getStyle = (): {
      backgroundColor: string;
      textDecoration: string;
    } => {
      const style = {
        backgroundColor: '#FFE4A2',
        textDecoration: 'underline #F8A01A 2px',
      };

      if (hideHighlight) {
        style.backgroundColor = '';
        style.textDecoration = '';
      }

      if (!annotations[0].highlight) {
        style.backgroundColor = '';
      }

      if (!annotations[0].underline) {
        style.textDecoration = '';
      }

      return style;
    };

    return (
      <div
        id={ruleId}
        ref={ref}
        className={`${sentenceStyle} ${
          useHover ? 'cursor-pointer' : ''
        } inline-block relative z-auto`}
        onMouseEnter={(): void => handleMouseEnter()}
        onMouseLeave={(): void => handleMouseLeave()}
        style={getStyle()}
        onMouseMove={(): void => handleMouseMove()}
      >
        {text}
        {useHover && hovering && renderAnnotators(uniqueAnnotations)}
      </div>
    );
  }

  return <>{text}</>;
};

export default HighlightedSuperNode;
