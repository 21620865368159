import { fetchOwnAssignments } from 'actions';
import withWidget from 'decorators/widget';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import logEvent from 'utils/analytics';

const ShowAll: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOwnAssignments({ is_open: true }));
  }, [dispatch]);

  const handleFinishAssignment = (): void => {
    logEvent('Dashboard-finish-assignment');
  };

  return (
    <div className="flex items-center w-full">
      <button type="button" className="w-full focus:outline-none" onClick={handleFinishAssignment}>
        <div className="flex h-full items-center justify-between">
          <div className="text text--left text-l text--bold text--white">Show All</div>
        </div>
      </button>
    </div>
  );
};

export default withWidget({
  // minWidth: 5,
  // minHeight: 3,
  bgClassColor: 'bg-litlingo-white',
})(ShowAll);
