import { exportTestCases } from 'actions';
import classNames from 'classnames';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { entityToQueryMap, TestCaseEntity } from 'constants/testCases';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { exportTestCasesLoading } from 'selectors/testCases';
import { UUID } from 'types';

type ExportButtonProps = {
  entity?: TestCaseEntity;
  entityId?: UUID;
  className?: string;
};

const ExportButton: React.FC<ExportButtonProps> = (props) => {
  const { entity, entityId, className } = props;

  const dispatch = useDispatch();
  const navParams = useSelector(getNavParamsByResource(resourceQueryParamName.testCase));
  const loading = useSelector(exportTestCasesLoading);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation();

    if (entity == null) {
      dispatch(exportTestCases({ ...navParams, limit: -1 }));
    } else if (entityId != null) {
      dispatch(exportTestCases({ [entityToQueryMap[entity]]: entityId }));
    }
  };

  return (
    <span className={classNames(className, 'rounded-md ')}>
      <button
        type="button"
        disabled={loading}
        onClick={handleClick}
        className={classNames(
          'flex flex-row justify-center button button--secondary-redesign font-bold h-8',
          {
            'disabled-button': loading,
          }
        )}
        data-testid="export-button"
      >
        <span>Export</span>
      </button>
    </span>
  );
};

export default ExportButton;
