import {
  createOutcomeSuccess,
  createRuleAndOutcome,
  deleteCampaignRuleOutcome,
  fetchAllCampaignRuleOutcomes,
  fetchAllCampaignRuleOutcomesFailure,
  fetchAllCampaignRuleOutcomesRequest,
  fetchAllCampaignRuleOutcomesSuccess,
  fetchFilterCampaignRuleOutcomes,
  fetchSingleRuleSuccess,
  receiveRuleOutcomes,
  removeRuleOutcome,
  showErrorAlert,
  updateCampaignRuleOutcome,
  updateCampaignRuleOutcomeConfig,
  updateCampaignRuleOutcomeConfigFailure,
  updateCampaignRuleOutcomeConfigRequest,
  updateCampaignRuleOutcomeConfigSuccess,
} from 'actions';
import { apiClient as LitLingoClient } from 'client';
import { push } from 'connected-react-router';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { createRule } from 'sagas/rules';
import { getCustomerDomain, getDefaultOutcome } from 'selectors/auth';
import type {
  API,
  CampaignRuleOutcome,
  MOutcome,
  MRule,
  NormalizedResource,
  Rule,
  SagaReturn,
  UUID,
} from 'types';
import { reverse } from 'utils/urls';

function* fetchAllCampaignRuleOutcomesSaga({
  payload,
}: ReturnType<typeof fetchAllCampaignRuleOutcomes>): SagaReturn {
  yield put(fetchAllCampaignRuleOutcomesRequest());

  const response = (yield call([LitLingoClient.resources.campaignRuleOutcomes, 'list'], {
    params: { ...payload, relationships: ['campaign', 'outcome', 'rule'] },
  })) as API.Response<API.CampaignRuleOutcomes.List>;
  if (response.error != null) {
    yield put(fetchAllCampaignRuleOutcomesFailure(response.error));
  } else {
    const normalizedRuleOutcome: NormalizedResource<CampaignRuleOutcome> = {};
    response.data.records.forEach((ruleOutcome) => {
      normalizedRuleOutcome[ruleOutcome.uuid] = ruleOutcome;
    });
    yield put(fetchAllCampaignRuleOutcomesSuccess(normalizedRuleOutcome));
  }
}

function* fetchFilterCampaignRuleOutcomesSaga({
  payload,
}: ReturnType<typeof fetchFilterCampaignRuleOutcomes>): SagaReturn {
  yield put(fetchAllCampaignRuleOutcomesRequest());

  const response = (yield call([LitLingoClient.resources.campaignRuleOutcomes, 'list'], {
    params: { ...payload, selectable_fields: ['uuid', 'rule_uuid', 'campaign.[deleted_at, name]'] },
  })) as API.Response<API.CampaignRuleOutcomes.List>;
  if (response.error != null) {
    yield put(fetchAllCampaignRuleOutcomesFailure(response.error));
  } else {
    const normalizedRuleOutcome: NormalizedResource<CampaignRuleOutcome> = {};
    response.data.records.forEach((ruleOutcome) => {
      normalizedRuleOutcome[ruleOutcome.uuid] = ruleOutcome;
    });
    yield put(fetchAllCampaignRuleOutcomesSuccess(normalizedRuleOutcome));
  }
}

function* updateRuleOutcomeConfigSaga({
  payload,
}: ReturnType<typeof updateCampaignRuleOutcomeConfig>): SagaReturn {
  yield put(updateCampaignRuleOutcomeConfigRequest(payload));
  const response = (yield call([LitLingoClient.resources.campaignRuleOutcomes, 'upsert'], {
    data: payload,
  })) as API.Response<API.CampaignRuleOutcomes.Upsert>;
  if (response.error != null) {
    yield put(updateCampaignRuleOutcomeConfigFailure(response.error));
  } else {
    yield put(updateCampaignRuleOutcomeConfigSuccess());
  }
}

type CreateRuleOutcomePayload = {
  ruleId: UUID;
  campaignId: UUID;
  outcomeId: UUID;
};

export function* createRuleOutcome(
  payload: CreateRuleOutcomePayload
): SagaReturn<boolean | CampaignRuleOutcome> {
  const { ruleId, outcomeId, campaignId } = payload;
  const ruleOutcome = {
    rule_uuid: ruleId,
    outcome_uuid: outcomeId,
    campaign_uuid: campaignId,
  };

  const response = (yield call([LitLingoClient.resources.campaigns.extras, 'addRuleOutcome'], {
    urlParams: { campaignId: ruleOutcome.campaign_uuid },
    data: ruleOutcome,
  })) as API.Response<API.Campaigns.AddRuleOutcome>;

  if (response.error != null) {
    return false;
  }

  yield put(
    updateCampaignRuleOutcome({
      ruleId: response.data.rule_uuid,
      campaignId: response.data.campaign_uuid,
    })
  );
  return response.data;
}

function* createRuleAndOutcomeSaga(action: ReturnType<typeof createRuleAndOutcome>): SagaReturn {
  const { payload } = action;
  const { campaignId, name, description } = payload;
  const defaultOutcome = (yield select(getDefaultOutcome)) as UUID | null;

  // FIXME: BETTER ERROR HANDING
  if (defaultOutcome) {
    const rule = (yield call(createRule, {
      rule: {
        name,
        description,
        config: {
          operator: 'OR',
          operands: [],
        },
        campaignId,
        outcomes: [defaultOutcome],
      },
    })) as Rule;

    if (rule) {
      const res = (yield call(createRuleOutcome, {
        ruleId: rule.uuid,
        outcomeId: defaultOutcome,
        campaignId,
      })) as CampaignRuleOutcome | false;

      const customerDomain = (yield select(getCustomerDomain)) as string;
      const path = reverse({
        routeName: 'rule-manager-campaign',
        // @ts-ignore
        routeParams: { ruleId: res.rule_uuid, campaignId },
        customerDomain,
      });
      yield put(push(path));
    }
  }
}

function* removeRuleOutcomeSaga(action: ReturnType<typeof removeRuleOutcome>): SagaReturn {
  const { payload } = action;
  const { ruleId, outcomeId, campaignId } = payload;
  const data = {
    rule_uuid: ruleId,
    outcome_uuid: outcomeId,
  };
  const response = (yield call([LitLingoClient.resources.campaigns.extras, 'removeRuleOutcome'], {
    urlParams: { campaignId },
    data,
  })) as API.Response<API.Campaigns.RemoveRuleOutcome>;
  if (response.error != null) {
    yield put(showErrorAlert(response.error.message));
  } else {
    yield put(deleteCampaignRuleOutcome({ ruleId, outcomeId, campaignId }));
  }
}

function* receiveRuleOutcomesSaga(action: ReturnType<typeof receiveRuleOutcomes>): SagaReturn {
  const { payload } = action;
  const normalizedRule: NormalizedResource<MRule> = {};
  const normalizedOutcome: NormalizedResource<MOutcome> = {};
  const normalizedRuleOutcome: NormalizedResource<CampaignRuleOutcome> = {};

  yield all(
    payload.map((payloadObj) => {
      const { rule, outcome, ...ruleOutcomeBody } = payloadObj;
      normalizedRuleOutcome[ruleOutcomeBody.uuid] = ruleOutcomeBody;

      if (outcome != null) {
        normalizedOutcome[outcome.uuid] = {
          ...outcome,
          campaign_uuid: ruleOutcomeBody.campaign_uuid,
          ruleOutcome_uuid: ruleOutcomeBody.uuid,
        };
        if (rule != null) {
          const { ...ruleBody } = rule;
          if (rule.uuid in normalizedRule) {
            normalizedRule[rule.uuid].outcomes = [
              ...(normalizedRule[rule.uuid].outcomes ?? []),
              outcome.uuid,
            ];
          } else {
            normalizedRule[rule.uuid] = {
              ...ruleBody,
              campaign_uuid: ruleOutcomeBody.campaign_uuid,
              ruleOutcome_uuid: ruleOutcomeBody.uuid,
              outcomes: [outcome.uuid],
            } as MRule;
          }
          return put(fetchSingleRuleSuccess(normalizedRule[rule.uuid]));
        }
      }

      return null;
    })
  );

  yield put(fetchAllCampaignRuleOutcomesSuccess(normalizedRuleOutcome));
  yield put(createOutcomeSuccess(normalizedOutcome));
}

function* campaignRuleOutcomesSaga(): SagaReturn {
  yield takeLatest(fetchAllCampaignRuleOutcomes.toString(), fetchAllCampaignRuleOutcomesSaga);
  yield takeLatest(updateCampaignRuleOutcomeConfig.toString(), updateRuleOutcomeConfigSaga);
  yield takeLatest(createRuleAndOutcome.toString(), createRuleAndOutcomeSaga);
  yield takeLatest(removeRuleOutcome.toString(), removeRuleOutcomeSaga);
  yield takeLatest(receiveRuleOutcomes.toString(), receiveRuleOutcomesSaga);
  yield takeLatest(fetchFilterCampaignRuleOutcomes.toString(), fetchFilterCampaignRuleOutcomesSaga);
}

export default campaignRuleOutcomesSaga;
