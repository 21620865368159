import EmptyTableState from 'components/EmptyTableState';
import ListFooterHooksBased from 'components/ListFooter/ListFooterHooksBased';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import AlertsContext from 'contexts/AlertsContext';
import useTeams from 'hooks/teams/useTeams';
import React, { useContext } from 'react';
import type { Team } from 'types';
import TeamListItem from './TeamListItem';

type ComponentProps = {
  handleChangeTeam: (team: Team) => void;
};

const TeamsList: React.FC<ComponentProps> = ({ handleChangeTeam }) => {
  const { data: teams, isLoading } = useTeams();

  const { showSuccessAlert } = useContext(AlertsContext);

  const handleCopyClick = async (
    event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>,
    testPhrase: string
  ): Promise<void> => {
    event.stopPropagation();
    event.preventDefault();
    await navigator.clipboard.writeText(testPhrase);
    showSuccessAlert('Team UUID copied to your clipboard');
  };

  return (
    <div className="table-wrapper">
      <table className="table-wrapper__table">
        <thead>
          <tr>
            <th className="table-wrapper__th">Name</th>
            <th className="table-wrapper__th">Users</th>
            <th className="table-wrapper__th">Actions</th>
          </tr>
        </thead>
        <tbody className="table-wrapper__tbody">
          {!isLoading && teams?.records.length === 0 ? (
            <EmptyTableState message="There are no divisions to display" colCount="3" />
          ) : (
            teams &&
            teams.records.map((team) => (
              <TeamListItem
                team={team}
                key={team.uuid}
                handleChangeTeam={handleChangeTeam}
                handleCopyClick={handleCopyClick}
              />
            ))
          )}
        </tbody>
      </table>
      <ListFooterHooksBased
        resourceStateName={resourceQueryParamName.teams}
        resourceName="team"
        fetchHook={useTeams}
      />
    </div>
  );
};

export default TeamsList;
