import React from 'react';
import { IconLogo } from './Logo';

const EmptyState: React.FC = () => (
  <div className="litlingo__robot_center">
    <div className="h-20 flex justify-center">
      <IconLogo />
    </div>
    <h3 className="litlingo__color_grey text-2xl font-bold mt-5">
      Better customer service
    </h3>
    <p className="litlingo__color_grey_2 text-base pt-1">
      Hi there! I&apos;m here to help you provide the best quality service to
      our customers.
    </p>
  </div>
);

export default EmptyState;
