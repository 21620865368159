import { fetchAllCategories } from 'actions';
import LinkLookup from 'components/LinkLookup';
import Navbar from 'components/Navbar';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCustomerId } from 'selectors/auth';
import { getOutcomeFromCampaignRuleOutcome } from 'selectors/outcomes';
import { getSelectedRuleRevision } from 'selectors/ruleGroups';
import { useSelector } from 'store';
import OutcomeManagerSidebar from './OutcomeManagerSidebar';
import OutcomeTable from './OutcomeTable';

type RouterParams = {
  ruleId: string;
};

const OutcomeManagerStarship: React.FC = () => {
  const dispatch = useDispatch();

  const { ruleId } = useParams<RouterParams>();

  const ruleRevision = useSelector(getSelectedRuleRevision);
  const outcomes = useSelector(getOutcomeFromCampaignRuleOutcome);
  const customerId = useSelector(getCustomerId);

  useEffect(() => {
    if (ruleRevision) {
      dispatch(
        fetchAllCategories({
          rule_revision_uuids: ruleRevision.uuid,
          customer_uuids: [customerId || ''],
          relationships: ['outcome'],
        })
      );
    }
  }, [dispatch, ruleRevision, customerId]);

  if (!outcomes) return null;

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <div
      className="flex flex-col bg-white min-w-screen overflow-hidden"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />

      <div className="flex flex-row h-full overflow-hidden">
        <div className="h-full flex flex-col gap-4 py-8 px-6 w-9/12 overflow-hidden">
          <header>
            <div className="flex flex-col gap-2">
              <span>
                <LinkLookup
                  routeName="global-rule-group-manager"
                  routeParams={{ ruleId }}
                  className="text-small hover:underline"
                >
                  {'< Back'}
                </LinkLookup>
              </span>

              <h2 className="text-heading-1">Outcome Manager</h2>
            </div>
          </header>
          <main className="h-full flex overflow-hidden">
            <OutcomeTable ruleGroupId={ruleId} />
          </main>
        </div>
        <div className="w-3/12 min-h-wo-navbar h-wo-navbar overflow-y-scroll">
          <OutcomeManagerSidebar />
        </div>
      </div>
    </div>
  );
};

export default OutcomeManagerStarship;
