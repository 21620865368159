/* eslint-disable camelcase */
import React from 'react';

type ComponentProps = {
  scrollRef: React.RefObject<HTMLElement>;
  showButton?: boolean;
  bottomOffset?: number;
  rightOffset?: number;
  extraAction?: () => void;
};

const BackToTopButton: React.FC<ComponentProps> = (props) => {
  const { scrollRef, showButton = true, bottomOffset = 20, rightOffset = 20, extraAction } = props;

  const handleBackToTop = (): void => {
    if (extraAction) extraAction();
    scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      type="button"
      className={`back-to-top-button focus:outline-none transition ${
        showButton ? 'opacity-100 duration-500' : 'opacity-0 duration-0'
      }`}
      style={{ bottom: `${bottomOffset}px`, right: `${rightOffset}px` }}
      onClick={handleBackToTop}
    >
      <svg
        width="18"
        height="24"
        viewBox="0 0 18 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5 9.59729L9.05814 2.11667L1.5 9.6748"
          stroke="white"
          strokeWidth="2.91807"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.17432 22L9.05804 2.11628"
          stroke="white"
          strokeWidth="2.91807"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

export default BackToTopButton;
