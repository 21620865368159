import EmptyTableState from 'components/EmptyTableState';
import LoadingIndicator from 'components/LoadingIndicator';

import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';

import type { Category } from 'types';
import CategoryListItem from './CategoryListItem';

type ComponentProps = {
  categories: Category[];
  loading?: boolean;
};

export type groupedCategoryByRule = {
  uuid: string;
  name: string;
  description: string;
  categories: Category[];
};

export const groupCategories = (categories: Category[]): Record<string, groupedCategoryByRule> =>
  categories.reduce<Record<string, groupedCategoryByRule>>((acc, curr) => {
    if (acc[curr.rule_revision_uuid]) {
      acc[curr.rule_revision_uuid].categories.push(curr);
    } else {
      acc[curr.rule_revision_uuid] = {
        uuid: curr.rule_revision_uuid,
        name: curr.rule?.name || '',
        description: curr.rule?.description || '',
        categories: [curr],
      };
    }
    return acc;
  }, {});

const CategoriesByRuleTable: React.FC<ComponentProps> = ({ categories, loading }) => {
  const groupedCategories = groupCategories(categories);

  return (
    <LoadingOverlay
      active={loading}
      spinner={<LoadingIndicator size="10" />}
      fadeSpeed={0}
      styles={{
        content: (base) => ({
          ...base,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }),
        wrapper: () => ({}),
        spinner: () => ({}),
        overlay: (base) => ({
          ...base,
          zIndex: 10,
        }),
      }}
    >
      <div className="table-wrapper__new">
        <table className="table-wrapper__table">
          <thead>
            <tr>
              <th className="table-wrapper__new-th table-wrapper__th--w-4" aria-hidden />
              <th className="table-wrapper__new-th table-wrapper__th--w-20 pl-4">Model</th>
              <th className="table-wrapper__new-th">Description/Platforms</th>
              <th className="table-wrapper__new-th table-wrapper__th--w-12">Use Cases</th>
              <th className="table-wrapper__new-th table-wrapper__th--w-12">Customers</th>
              <th aria-hidden className="table-wrapper__new-th table-wrapper__th--w-5" />
            </tr>
          </thead>
          <tbody className="table-wrapper__tbody" data-testid="categories-table">
            {!loading && categories?.length === 0 ? (
              <EmptyTableState message="There are no models to display" colCount="5" />
            ) : (
              Object.values(groupedCategories)
                .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                .map((ruleCategory) => (
                  <CategoryListItem key={ruleCategory.uuid} ruleCategory={ruleCategory} />
                ))
            )}
          </tbody>
        </table>
      </div>
    </LoadingOverlay>
  );
};

export default CategoriesByRuleTable;
