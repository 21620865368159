import React from 'react';
import { Link } from 'react-router-dom';

import type { AnnotationsMapItem } from '../types';

type DocumentVizAnnotationProps = {
  className?: string;
  annotationsBasePath: string;
  annotation: AnnotationsMapItem;
};

const DocumentVizAnnotation: React.FC<DocumentVizAnnotationProps> = (props) => {
  const { className, annotationsBasePath, annotation } = props;

  return (
    <div className={className}>
      <Link to={`${annotationsBasePath}/${annotation.uuid}`} target="_blank">
        <span className="bg-light-blue p-2 rounded-full uppercase">
          {annotation.name}
        </span>
      </Link>
    </div>
  );
};
// #D9F6DC
export default DocumentVizAnnotation;
