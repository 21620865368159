/* eslint-disable max-lines */
/* eslint-disable camelcase */

import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const DarkerSkeleton: React.FC<{ width: number; height: number; circle?: boolean }> = ({
  width,
  height,
  circle,
}) => (
  <SkeletonTheme color="#ADADAD" highlightColor="#BDBDBD">
    <Skeleton width={width} height={height} style={{ borderRadius: 0 }} circle={circle} />
  </SkeletonTheme>
);

const FieldSkeleton: React.FC<{ width: number; height: number; circle?: boolean }> = ({
  width,
  height,
  circle,
}) => (
  <SkeletonTheme color="#D6D6D6" highlightColor="#D6D6D6">
    <Skeleton width={width} height={height} style={{ borderRadius: 0 }} circle={circle} />
  </SkeletonTheme>
);

const ValueSkeleton: React.FC<{ width: number; height: number }> = ({ width, height }) => (
  <SkeletonTheme color="#EBEBEB" highlightColor="#EFEFEF">
    <Skeleton width={width} height={height} style={{ borderRadius: 0 }} />
  </SkeletonTheme>
);

const SidebarSkeleton: React.FC = () => (
  <div className="flex flex-col">
    <div className="pl-3 flex flex-col bg-litlingo-gray-0.5">
      <div className="h-8 flex items-center">
        <DarkerSkeleton width={66} height={16} />
      </div>
      <div className="pl-3 h-6 flex items-center gap-1">
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.08961 9.91604C2.1425 9.96937 2.21437 9.99955 2.28948 9.99999C2.32675 10.0003 2.3637 9.99307 2.39807 9.97864C2.43244 9.9642 2.4635 9.9429 2.48934 9.91604L7.20219 5.20319C7.22866 5.17707 7.24968 5.14595 7.26402 5.11164C7.27837 5.07733 7.28575 5.04051 7.28575 5.00333C7.28575 4.96614 7.27837 4.92932 7.26402 4.89501C7.24968 4.8607 7.22866 4.82958 7.20219 4.80346L2.48934 0.0896109C2.46343 0.0619091 2.43221 0.0397035 2.39754 0.0243155C2.36287 0.00892748 2.32545 0.000671647 2.28753 3.93014e-05C2.2496 -0.000593044 2.21193 0.00641102 2.17677 0.0206347C2.1416 0.0348583 2.10966 0.0560109 2.08283 0.0828334C2.05601 0.109656 2.03486 0.1416 2.02063 0.176766C2.00641 0.211931 1.99941 0.249598 2.00004 0.287526C2.00067 0.325453 2.00893 0.362866 2.02432 0.397537C2.0397 0.432209 2.06191 0.46343 2.08961 0.489344L6.6026 5.00333L2.08961 9.51631C2.06326 9.5425 2.04235 9.57364 2.02809 9.60794C2.01382 9.64224 2.00647 9.67903 2.00647 9.71618C2.00647 9.75333 2.01382 9.79011 2.02809 9.82441C2.04235 9.85871 2.06326 9.88985 2.08961 9.91604Z"
            fill="#333333"
          />
        </svg>
        <FieldSkeleton width={200} height={16} />
      </div>
    </div>

    <div className="flex flex-col bg-white">
      <div className="pl-10 h-6 flex flex-row items-center gap-1 border-b border-litlingo-gray-1">
        <div className="h-4.5">
          <FieldSkeleton width={12} height={12} circle />
        </div>
        <div className="h-4.5">
          <FieldSkeleton width={12} height={12} circle />
        </div>
        <div className="h-4.5">
          <FieldSkeleton width={12} height={12} circle />
        </div>
      </div>
      <div className="pl-3 h-8 flex items-center border-b border-litlingo-gray-1">
        <DarkerSkeleton width={100} height={16} />
      </div>
      <div className="pl-3 h-6 flex items-center gap-1 border-b border-litlingo-gray-1">
        <div className="pt-1 h-3 flex items-center">
          <FieldSkeleton width={12} height={12} />
        </div>
        <ValueSkeleton width={260} height={16} />
      </div>
      <div className="pl-3 h-6 flex items-center gap-1 border-b border-litlingo-gray-1">
        <div className="pt-1 h-3 flex items-center">
          <FieldSkeleton width={12} height={12} />
        </div>
        <ValueSkeleton width={260} height={16} />
      </div>
    </div>
    <div className="px-3 pt-4 flex flex-col gap-1">
      <div className="flex flex-row items-center justify-between">
        <DarkerSkeleton width={100} height={16} />
        <div className="-mt-1">
          <FieldSkeleton width={20} height={20} circle />
        </div>
      </div>
      <div>
        <FieldSkeleton width={276} height={90} />
      </div>
    </div>
  </div>
);

export default SidebarSkeleton;
