export const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };
  }
  return { r: 0, g: 0, b: 0 };
};

export const textContrastColor = (color: string): string => {
  const rgbColor = hexToRgb(color);
  const averageColor = rgbColor.r * 0.3 + rgbColor.g * 0.59 + rgbColor.b * 0.11;

  return averageColor > 150 ? 'text-black' : 'text-white';
};

export const contrastColor = (color: string): string => {
  const rgbColor = hexToRgb(color);
  const averageColor = rgbColor.r * 0.3 + rgbColor.g * 0.59 + rgbColor.b * 0.11;

  return averageColor > 150 ? '#000' : '#FFF';
};
