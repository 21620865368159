import { redirectToLitlingoLogin } from 'actions';
import logo from 'assets/logo.png';
import React from 'react';
import { useDispatch } from 'react-redux';

const SignInWithLitlingoButton: React.FC = () => {
  const dispatch = useDispatch();

  const handleLoginClick = (): void => {
    dispatch(redirectToLitlingoLogin({}));
  };

  return (
    <button
      type="button"
      className="bg-litlingo-background flex justify-center items-center border border-litlingo-line py-2 w-full focus:outline-none"
      onClick={handleLoginClick}
    >
      <img className="mr-2" width={20} height={20} src={logo} alt="Logo" />
      <span className="text text--medium">
        Sign in with <b>Litlingo</b>
      </span>
    </button>
  );
};

export default SignInWithLitlingoButton;
