import React from 'react';
import RenderEnvelopeBoxes from './RenderEnvelopeBoxes';

const EnvelopeContent: React.FC = () => (
  <div className="flex h-full overflow-hidden" data-testid="envelope-list-events">
    <RenderEnvelopeBoxes />
  </div>
);

export default EnvelopeContent;
