/* eslint-disable max-lines */
import { User } from '@litlingo/client';
import MultiselectDropdownRedesign, {
  MultiSelectOption,
} from 'components/MultiSelectDropdown/MultiSelectDropdownRedesign';
import SelectRedesign from 'components/Select/SelectRedesign';
import useAllTeams from 'hooks/teams/useAllTeams';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAvailablesRoles } from 'selectors/users';
import UserPermissionsSelectFilter from './UserPermissionsSelectFilter';

const activeOptions = [
  { value: 'true', label: 'Has Integrations' },
  { value: 'false', label: 'No Integrations' },
  { value: 'show-all', label: 'Show All' },
];

type ComponentProps = {
  users: User[];
  setFilteredUsers: React.Dispatch<React.SetStateAction<User[]>>;
};

const UserPermissionsFilters: React.FC<ComponentProps> = ({ users, setFilteredUsers }) => {
  const { data } = useAllTeams();
  const roles = useSelector(getAvailablesRoles);

  const [active, setActive] = useState<string>('');
  const [selectedRoles, setSelectedRoles] = useState<{ label: string; value: string }[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<{ label: string; value: string }[]>([]);

  const teams = data?.records || [];

  const teamOptions = teams.map((team) => ({
    label: team.name,
    value: team.uuid,
  }));

  const rolesOptions = roles.map((key) => ({
    label: key,
    value: key,
  }));

  useEffect(() => {
    let filteredUsers = users;
    if (active && active !== 'show-all') {
      filteredUsers = filteredUsers.filter((user) => user.sync_emails?.toString() === active);
    }

    if (selectedRoles.length > 0) {
      filteredUsers = filteredUsers.filter((user) =>
        user.roles.some((r) => selectedRoles.some((sR) => sR.value === r))
      );
    }

    if (selectedTeams.length > 0) {
      filteredUsers = filteredUsers.filter((user) =>
        user.teams?.some((t) => selectedTeams.some((sT) => sT.value === t.uuid))
      );
    }

    setFilteredUsers(filteredUsers);
  }, [active, selectedRoles, setFilteredUsers, users, selectedTeams]);

  const handleChangeActive = (value: string): void => {
    setActive(value);
  };

  const handleChangeRoles = (option: MultiSelectOption): void => {
    const selected = selectedRoles.some((r) => r.value === option.value);
    if (selected) {
      setSelectedRoles((rol) => rol.filter((r) => r.value !== option.value));
    } else {
      setSelectedRoles((rol) => [...rol, option]);
    }
  };

  const handleChangeTeams = (option: MultiSelectOption): void => {
    const selected = selectedTeams.some((t) => t.value === option.value);
    if (selected) {
      setSelectedTeams((team) => team.filter((t) => t.value !== option.value));
    } else {
      setSelectedTeams((team) => [...team, option]);
    }
  };

  return (
    <div className="flex flex-row gap-2 w-full">
      <div className="w-80">
        <UserPermissionsSelectFilter />
      </div>

      <div className="w-30">
        <MultiselectDropdownRedesign
          placeholder="Roles"
          onChange={handleChangeRoles}
          options={rolesOptions}
          selectedItems={selectedRoles}
          dataTestid="active"
        />
      </div>
      <div className="w-32">
        <MultiselectDropdownRedesign
          placeholder="Division"
          onChange={handleChangeTeams}
          options={teamOptions}
          selectedItems={selectedTeams}
          dataTestid="active"
        />
      </div>
      <div className="w-37">
        <SelectRedesign
          placeholder="Activity"
          onChange={handleChangeActive}
          options={activeOptions}
          value={active}
        />
      </div>
    </div>
  );
};

export default UserPermissionsFilters;
