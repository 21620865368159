import AuditLogsTable from 'components/AuditLogsTable';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import React from 'react';
import { getAuditLogsList, getFetchAuditLogsLoading } from 'selectors/auditLogs';
import { useSelector } from 'store';

const AuditLogs: React.FC = () => {
  const loading = useSelector(getFetchAuditLogsLoading);

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />

      <div className="py-10">
        <div>
          <header>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                  <div className="flex flex-row items-center">
                    <h2 className="title">Audit logs</h2>
                    {loading && <LoadingIndicator className="ml-2" size="5" />}
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>

        <main>
          <div className="mt-10 mb-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-left">
            <div>
              <div className="flex flex-col">
                <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                  <AuditLogsTable getList={getAuditLogsList} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AuditLogs;
