/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { getSurroundingContextRulesIds } from 'selectors/auth';
import { useSelector } from 'store';
import type { CommunicationEnvelopeSummary } from 'types';

type GetCampaignsRules = (
  events: CommunicationEnvelopeSummary['events']
) => Record<string, { ruleName: string; priority: number }>;

const getRules: GetCampaignsRules = (events) => {
  if (!events) return {};

  const rules: Record<string, { ruleName: string; priority: number }> = {};

  events.forEach((e) => {
    if (e.rule_uuid) {
      if (rules[e.rule_uuid]) {
        if (e.campaign_priority && rules[e.rule_uuid].priority < e.campaign_priority) {
          rules[e.rule_uuid] = { ruleName: e.rule_name, priority: e.campaign_priority };
        }
      } else {
        rules[e.rule_uuid] = { ruleName: e.rule_name, priority: e.campaign_priority || 0 };
      }
    }
  });

  return rules;
};

const sortRules = (
  [, a]: [string, { ruleName: string; priority: number }],
  [, b]: [string, { ruleName: string; priority: number }]
): number => {
  const aPrio = a.priority && a.priority !== 0 ? a.priority : 1000;
  const bPrio = b.priority && b.priority !== 0 ? b.priority : 1000;

  if (aPrio < bPrio) {
    return -1;
  }
  if (aPrio > bPrio) {
    return 1;
  }

  if (a.ruleName < b.ruleName) {
    return -1;
  }
  if (a.ruleName > b.ruleName) {
    return 1;
  }

  return 0;
};

type ComponentProps = {
  events: CommunicationEnvelopeSummary['events'];
};

const EnvelopeListCondensedCampaignsRules: React.FC<ComponentProps> = ({ events }) => {
  const contextRulesIds = useSelector(getSurroundingContextRulesIds);
  const rules = getRules(events.filter((e) => !contextRulesIds.includes(e.rule_uuid || '')));

  const containerRef = useRef<HTMLDivElement>(null);
  const [needsWrap, setNeedsWrap] = useState(false);

  const handleContainerSpace = (): void => {
    const container = containerRef.current;
    if (container) {
      setNeedsWrap(container.scrollWidth > container.clientWidth);
    }
  };

  useEffect(() => {
    handleContainerSpace();
  }, []);

  if (!events || events.length === 0) {
    return <div className="text-body italic leading-5">No Matches</div>;
  }

  if (!rules || Object.keys(rules).length === 0) return null;

  return (
    <>
      <div
        ref={containerRef}
        className={`w-full flex flex-row gap-1 ${needsWrap ? 'overflow-hidden' : ''}`}
      >
        <div className="min-h-4.5 min-w-4.5 h-4.5 w-4.5 flex justify-center items-center bg-litlingo-primary-60 rounded-full">
          {Object.keys(rules).length}
        </div>
        <div className="flex flex-row gap-1">
          {Object.entries(rules)
            .sort(sortRules)
            .map(([key, rule]) => (
              <div
                className="text-small px-1 py-0.5 rounded bg-litlingo-gray-1 whitespace-no-wrap"
                key={key}
              >
                {rule.ruleName}
              </div>
            ))}
        </div>
      </div>
      {needsWrap && (
        <div className="text-small px-1 py-0.5 rounded bg-litlingo-gray-1 whitespace-no-wrap">
          ...
        </div>
      )}
    </>
  );
};

export default EnvelopeListCondensedCampaignsRules;
