import { Identifier } from '@litlingo/client';
import { EXPAND_ICON } from 'constants/dashboardIcons';
import React from 'react';

type ComponentProps = {
  identifierId: string;
  identifierSource: string | undefined;
  setIdentifierSource: React.Dispatch<React.SetStateAction<string | undefined>>;
  identifiers: Identifier[];
};

const IdentifierCompareRow: React.FC<ComponentProps> = ({
  identifierId,
  identifierSource,
  setIdentifierSource,
  identifiers,
}) => {
  const identifier = identifiers.find((i) => i.uuid === identifierId);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();

    if (!identifier) {
      return;
    }

    if (e.target.checked) {
      setIdentifierSource(identifier.uuid);
    } else {
      setIdentifierSource(undefined);
    }

    const element = document.activeElement as HTMLInputElement;
    if (element) {
      element.blur();
    }
  };

  const handleOpenIdentifier = (): void => {
    const path = `/global/identifier/${identifierId}`;
    window.open(path, '_blank');
  };

  if (!identifier) {
    return null;
  }

  return (
    <div className=" border-b py-2 ">
      <div className="flex flex-row justify-between items-center">
        <div className="text">
          <label htmlFor={`custom-checkbox-${identifier.uuid}`} className="cursor-pointer">
            <input
              id={`custom-checkbox-${identifier.uuid}`}
              type="checkbox"
              className="custom-checkbox mr-1"
              onChange={handleCheckboxChange}
              checked={identifierSource === identifier.uuid}
            />
            <span className="custom" />
          </label>
          {identifier.name}
        </div>
        <button type="button" className="focus:outline-none" onClick={handleOpenIdentifier}>
          {EXPAND_ICON}
        </button>
      </div>
    </div>
  );
};

export default IdentifierCompareRow;
