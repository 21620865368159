import LinkLookup from 'components/LinkLookup';
import React from 'react';

export type InnerNavbarOption = { label: string; value: string; route: string };

type ComponentProps = {
  options: InnerNavbarOption[];
  selectedOption: string;
};

const InnerNavbar: React.FC<ComponentProps> = ({ options, selectedOption }) => {
  const renderLinkOption = (option: InnerNavbarOption): JSX.Element => (
    <LinkLookup
      routeName={option.route}
      className="text-menu-item border-b-4 border-transparent cursor-pointer pb-2"
    >
      {option.label}
    </LinkLookup>
  );

  const renderSelectedOption = (option: InnerNavbarOption): JSX.Element => (
    <div className="text-menu-item border-b-4 border-litlingo-orange pb-2">{option.label}</div>
  );

  return (
    <div className="flex items-center gap-4">
      {options.map((option) => {
        if (option.value === selectedOption) {
          return renderSelectedOption(option);
        }
        return renderLinkOption(option);
      })}
    </div>
  );
};

export default InnerNavbar;
