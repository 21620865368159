/* eslint-disable camelcase */
import React, { useEffect } from 'react';

import { setRulesToShow, setSelectedModel, setSelectedSentence, setSelectedText } from 'actions';
import LinkLookup from 'components/LinkLookup';
import { CLOSE_ICON } from 'constants/envelopeIcons';
import pluralize from 'pluralize';
import { useDispatch } from 'react-redux';
import { getEventRulesToShow, getSelectedText } from 'selectors/envelopeReview';
import { useSelector } from 'store';
import RuleSection from './RuleSection';

const CommunicationModelsSection: React.FC = () => {
  const dispatch = useDispatch();

  const rules = useSelector(getEventRulesToShow);
  const selectedText = useSelector(getSelectedText);

  useEffect(
    () => () => {
      dispatch(setSelectedSentence({ index: -1, communicationId: '' }));
      dispatch(setSelectedText(''));
      dispatch(setRulesToShow([]));
      dispatch(setSelectedModel([]));
    },
    [dispatch]
  );

  const handleClose = (): void => {
    dispatch(setSelectedSentence({ index: -1, communicationId: '' }));
    dispatch(setSelectedText(''));
    dispatch(setRulesToShow([]));
    dispatch(setSelectedModel([]));
  };

  if (rules.length === 0) return null;

  return (
    <div>
      <div
        className="py-4 pl-4 pr-5 flex flex-row justify-between items-center bg-litlingo-gray-0.5 border-t border-b border-litlingo-gray-2"
        style={{
          boxShadow: '0px 6px 8px -6px rgba(0, 0, 0, 0.40)',
        }}
      >
        <span className="text-heading-3">{`Matching on ${rules.length} ${pluralize(
          'model',
          rules.length
        )}`}</span>
        <div className="flex flex-row items-center gap-4">
          <LinkLookup
            routeName="global-test-viz"
            queryParams={{ body_visualizer: selectedText }}
            className="h-8 button button--secondary"
          >
            <span className="font-bold">Language Visualizer</span>
          </LinkLookup>
          <button type="button" className="focus:outline-none" onClick={handleClose}>
            {CLOSE_ICON}
          </button>
        </div>
      </div>
      <div className="min-h-78 max-h-78 pt-4 pb-8 px-6 flex flex-col gap-4 overflow-auto">
        {rules.map((r) => (
          <RuleSection key={r.uuid} ruleId={r.uuid} />
        ))}
      </div>
    </div>
  );
};
export default CommunicationModelsSection;
