import type { ErrorData } from '@litlingo/client';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  addTagToFilterItems,
  deleteTagFailure,
  deleteTagRequest,
  deleteTagSuccess,
  fetchAllTagsFailure,
  fetchAllTagsRequest,
  fetchAllTagsSuccess,
  fetchTagsForFilterPillsFailure,
  fetchTagsForFilterPillsRequest,
  fetchTagsForFilterPillsSuccess,
  fetchTagsSuccessAppend,
  upsertTagFailure,
  upsertTagRequest,
  upsertTagSuccess,
} from 'actions/tags';
import type { API, ErrorObject, NormalizedResource, Tag, UUID } from 'types';

export type TagsState = {
  error: ErrorData | null;
  loading: string[];
  items: NormalizedResource<Tag>;
  filterItems: NormalizedResource<Tag>;
  listIds: UUID[];
  count: number;
};

type TagsReducer<P = void> = (state: TagsState, action: PayloadAction<P>) => void;

const initialState: TagsState = {
  error: null,
  loading: [],
  items: {},
  filterItems: {},
  listIds: [],
  count: 0,
};

const handleFetchAllTagsRequest: TagsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAllTagsRequest.toString()],
});

const handleFetchAllTagsFailure: TagsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchAllTagsRequest.toString()),
});

const handleFetchAllTagsSuccess: TagsReducer<API.WrappedAPIResponse<Tag>> = (
  state,
  { payload }
) => {
  const tags: NormalizedResource<Tag> = {};
  const ids: UUID[] = [];

  payload.records.forEach((tag) => {
    tags[tag.uuid] = tag;
    ids.push(tag.uuid);
  });

  return {
    ...state,
    items: tags,
    listIds: ids,
    count: payload.count,
    loading: state.loading.filter((s) => s !== fetchAllTagsRequest.toString()),
  };
};

const handleFetchTagsSuccessAppend: TagsReducer<API.WrappedAPIResponse<Tag>> = (
  state,
  { payload }
) => {
  const tags: NormalizedResource<Tag> = {};
  const ids: UUID[] = [];

  payload.records.forEach((tag) => {
    tags[tag.uuid] = tag;
    ids.push(tag.uuid);
  });

  return {
    ...state,
    items: { ...state.items, ...tags },
    listIds: ids,
    count: payload.count,
    loading: state.loading.filter((s) => s !== fetchAllTagsRequest.toString()),
  };
};

const handleUpsertTagRequest: TagsReducer<Tag> = (state) => ({
  ...state,
  loading: [...state.loading, upsertTagRequest.toString()],
});

const handleUpsertTagSuccess: TagsReducer<Tag> = (state, { payload }) => ({
  ...state,
  items: {
    ...state.items,
    [payload.uuid]: {
      ...state.items[payload.uuid],
      ...payload,
    },
  },
  loading: state.loading.filter((s) => s !== upsertTagRequest.toString()),
});

const handleUpsertTagFailure: TagsReducer<Tag> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== upsertTagRequest.toString()),
});

const handleDeleteTagRequest: TagsReducer<string> = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, deleteTagRequest.toString()],
});

const handleDeleteTagSuccess: TagsReducer<string> = (state, { payload: id }) => {
  const items = { ...state.items };
  delete items[id];

  return {
    ...state,
    items,
    loading: state.loading.filter((s) => s !== deleteTagRequest.toString()),
  };
};

const handleDeleteTagFailure: TagsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== deleteTagRequest.toString()),
});

const handleFetchTagsForFilterPillsRequest: TagsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchTagsForFilterPillsRequest.toString()],
});

const handleFetchTagsForFilterPillsFailure: TagsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchTagsForFilterPillsRequest.toString()),
});

const handleFetchTagsForFilterPillsSuccess: TagsReducer<API.WrappedAPIResponse<Tag>> = (
  state,
  { payload }
) => {
  const tags: NormalizedResource<Tag> = {};
  const ids: UUID[] = [];

  payload.records.forEach((tag) => {
    tags[tag.uuid] = tag;
    ids.push(tag.uuid);
  });

  return {
    ...state,
    filterItems: { ...state.filterItems, ...tags },
    loading: state.loading.filter((s) => s !== fetchTagsForFilterPillsRequest.toString()),
  };
};

const handleAddTagToFilterItems: TagsReducer<Tag> = (state, { payload }) => ({
  ...state,
  filterItems: {
    ...state.filterItems,
    [payload.uuid]: payload,
  },
});

const handlers = {
  [fetchAllTagsSuccess.toString()]: handleFetchAllTagsSuccess,
  [fetchAllTagsFailure.toString()]: handleFetchAllTagsFailure,
  [fetchAllTagsRequest.toString()]: handleFetchAllTagsRequest,
  [fetchTagsSuccessAppend.toString()]: handleFetchTagsSuccessAppend,
  [upsertTagSuccess.toString()]: handleUpsertTagSuccess,
  [upsertTagFailure.toString()]: handleUpsertTagFailure,
  [upsertTagRequest.toString()]: handleUpsertTagRequest,
  [deleteTagSuccess.toString()]: handleDeleteTagSuccess,
  [deleteTagFailure.toString()]: handleDeleteTagFailure,
  [deleteTagRequest.toString()]: handleDeleteTagRequest,
  [fetchTagsForFilterPillsRequest.toString()]: handleFetchTagsForFilterPillsRequest,
  [fetchTagsForFilterPillsSuccess.toString()]: handleFetchTagsForFilterPillsSuccess,
  [fetchTagsForFilterPillsFailure.toString()]: handleFetchTagsForFilterPillsFailure,
  [addTagToFilterItems.toString()]: handleAddTagToFilterItems,
};

const tagsReducer = createReducer(initialState, handlers);

export default tagsReducer;
