import { editCustomer, fetchSingleCustomer, saveCustomer } from 'actions';
import Card from 'components/Card';
import CustomerSaveButton from 'components/Customer/CustomerSaveButton';
import LinkLookup from 'components/LinkLookup';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import ToggleButton from 'components/ToggleButton';
import { LEFT_ARROW_ICON } from 'constants/envelopeIcons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCustomer, getUser } from 'selectors/auth';
import { useSelector } from 'store';
import CustomerLeaveConfirmModal from './CustomerLeaveConfirmModal';

const CustomerEditChrome: React.FC = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);
  const customer = useSelector(getCustomer);
  const keyName = 'chrome_extension_config';

  const [isSaved, setIsSaved] = useState(false);
  const [unsaved, setUnsaved] = useState(false);
  const [isLeaveModalOpen, setLeaveModalOpen] = useState(false);

  const user = useSelector(getUser);

  useEffect(() => {
    if (user && !customer.config) {
      dispatch(fetchSingleCustomer());
    }
  }, [dispatch, user, customer]);

  useEffect(() => {
    if (!customer.config) return;
    setStatus(customer.config[keyName]?.enabled);
  }, [customer]);

  const handleSave = async (): Promise<void> => {
    dispatch(saveCustomer({}));
    setIsSaved(true);
    setUnsaved(false);
  };

  const header = (
    <div className={` max-w-7xl`}>
      <div className="flex justify-between">
        <div className="litlingo-customer-back-header-button">
          <LinkLookup
            data-testid="customer-edit-gmail-left-button"
            routeName="customer"
            className="mx-6 focus:outline-none"
            onClick={(e): void => {
              if (unsaved) {
                e.preventDefault();
                setLeaveModalOpen(true);
              }
            }}
          >
            {LEFT_ARROW_ICON}
          </LinkLookup>
          <span className="text text--large text--bold">Chrome</span>
        </div>
        <CustomerSaveButton
          unsaved={unsaved}
          isSaved={isSaved}
          handleSave={handleSave}
          className="litlingo-customer-save-button"
        />
      </div>
    </div>
  );

  const statusView = (
    <div className="flex justify-between">
      <div className="my-4 mx-5">
        <p className="text-sm">Status</p>
        <p className="text-sm">Enable to allow users to use extension</p>
      </div>
      <div className="my-6 mx-5">
        <ToggleButton
          dataTestid="customer-edit-chrome-status-button"
          isOn={customer?.config?.[keyName].enabled}
          handleToggleClick={(): void => {
            setUnsaved(true);
            dispatch(
              editCustomer({
                config: {
                  ...customer?.config,
                  [keyName]: {
                    ...customer?.config[keyName],
                    enabled: !status,
                  },
                },
              })
            );
          }}
        />
      </div>
    </div>
  );

  const minimumVersion = (
    <div className="flex justify-between items-center">
      <div className="my-4 mx-5">
        <span className="flex text-sm">Minimum version</span>
      </div>
      <div className="my-6 mx-5">{customer.config?.[keyName]?.minimum_version}</div>
    </div>
  );

  const targetVersion = (
    <div className="flex justify-between items-center">
      <div className="my-4 mx-5">
        <span className="flex text-sm">Target version</span>
      </div>
      <div className="my-6 mx-5">{customer.config?.[keyName].target_version}</div>
    </div>
  );

  return (
    <div className="litlingo-gray-bg-color-4">
      <Navbar />

      <div
        className="flex flex-row overflow-auto"
        style={{
          minHeight: 'calc(100vh - 4rem)',
          height: 'calc(100vh - 4rem)',
        }}
      >
        <SettingSidebar />
        <div className="mt-0 mx-auto min-w-2xl w-8/12 max-w-6xl text-left">
          <Card items={[header, statusView, minimumVersion, targetVersion]} />
        </div>
      </div>
      <div>
        {isLeaveModalOpen && (
          <CustomerLeaveConfirmModal
            toggleShowModal={(): void => setLeaveModalOpen(!isLeaveModalOpen)}
            setUnsaved={setUnsaved}
          />
        )}
      </div>
    </div>
  );
};

export default CustomerEditChrome;
