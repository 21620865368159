/* eslint-disable no-unused-expressions */
import { TestCommunication } from '@litlingo/client';
import { testSentenceSuccess } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TestResultsVisualizer from '.';

export type TestResultMessage = {
  communication: TestCommunication | null;
};

const TestResultsVisualizerPage: React.FC = () => {
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    const channel = new BroadcastChannel('test-visualizer-channel');
    channel.postMessage({ ready: true });
  }, []);

  useEffect(() => {
    const handleEvent = (event: MessageEvent<TestResultMessage>): void => {
      setInitialLoading(false);

      const { communication } = event.data;
      if (communication) dispatch(testSentenceSuccess(communication));
    };

    const channel = new BroadcastChannel('test-visualizer-channel');
    channel.addEventListener('message', handleEvent);

    return () => {
      channel.removeEventListener('message', handleEvent);
    };
  }, [dispatch]);

  if (initialLoading)
    return (
      <div className="h-screen flex items-center justify-center">
        <LoadingIndicator size="20" />
      </div>
    );

  return <TestResultsVisualizer showHighlights={false} />;
};

export default TestResultsVisualizerPage;
