import { UUID } from '@litlingo/client';
import React from 'react';
import { getCommEnvelopeReviewHistory } from 'selectors/envelopes';
import { useSelector } from 'store';
import EnvelopeLogsList from '../EnvelopeLogList';

type ComponentProps = {
  envelopeId: UUID;
};

const EnvelopeSidebarDetails: React.FC<ComponentProps> = ({ envelopeId }) => {
  const actions = useSelector((state) => getCommEnvelopeReviewHistory(state, envelopeId));

  return (
    <div className="py-4 pr-4 pl-6 bg-white border border-litlingo-gray-1">
      {actions.length > 0 && <EnvelopeLogsList logs={actions} />}
      {actions.length <= 0 && (
        <div className="text-body italic">
          Review context like the selections made on the coding panel will be shown here.
        </div>
      )}
    </div>
  );
};

export default EnvelopeSidebarDetails;
