/* eslint-disable max-lines */
import {
  copyItem,
  createNewGroup,
  pasteItem,
  patchSingleRuleConfig,
  removeItem,
  selectItem,
  updateModeOfSpeech,
  updateModifier,
  updateNegated,
} from 'actions';
import {
  operatorAcceptsOperatorChildren,
  operatorName,
} from 'components/RuleManagerTable/RuleManagerUtils';
import SelectRedesign from 'components/Select/SelectRedesign';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllChildNodes } from 'selectors/config';
import { useSelector } from 'store';
import type { MRuleConfig, MRuleConfigNode, ModeOfSpeech, Modifiers, RuleTree } from 'types';
import { checkForChildren } from 'utils/checkForChildren';

type NodeNameType =
  | 'AND'
  | 'OR'
  | 'RELATIONSHIP_MATCH'
  | 'ANNOTATION_MATCH'
  | 'AND_NOT'
  | 'OR_NOT'
  | 'RELATIONSHIP_MATCH_NOT';

type ComponentProps = {
  selectedId: string;
  setIdToMove: React.Dispatch<React.SetStateAction<string | null>>;
  idToMove: string | null;
  selectedNode: number | null;
  toggleShowModal: () => void;
  arrayTree: MRuleConfig[];
  handleCollapse: (collapseId: string) => void;
  handleUncollapse: (
    uncollapseId: string,
    isExpand?: boolean,
    isRoot?: boolean,
    shouldUncollapse?: boolean,
    defaultToUncollapse?: number
  ) => void;
  isCollapsedAll: boolean | undefined;
  setIsCollapsedAll: (value: boolean) => void;
  handleNavigateRuleBranchUUID: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const RuleManagerAction: React.FC<ComponentProps> = ({
  arrayTree,
  selectedId,
  setIdToMove,
  idToMove,
  toggleShowModal,
  handleCollapse,
  handleUncollapse,
  isCollapsedAll,
  setIsCollapsedAll,
  selectedNode,
  handleNavigateRuleBranchUUID,
}) => {
  const dispatch = useDispatch();
  const node = useSelector((state) => state.config.items[selectedId]);
  const allChildNodes = useSelector((state) => getAllChildNodes(state, selectedId));

  const isInsideSameSentence = useSelector((state) => {
    if (node.parent == null) {
      return false;
    }

    const parent = state.config.items[node.parent];

    return parent.typeOfConfig === 'RELATIONSHIP_MATCH';
  });

  const buttonMainClass = 'button button--secondary font-bold h-8 whitespace-no-wrap';
  const buttonFocusClass = 'button button--primary text-base font-bold h-8 whitespace-no-wrap';
  const buttonGroupLeft = 'rounded-l rounded-r-none border-r-0';
  const buttonGroupCenter = 'rounded-none';
  const buttonGroupRight = 'rounded-r rounded-l-none border-l-0';

  const isRoot = node.parent == null;

  const [modifiers, setModifiers] = useState(
    'modifiers' in node && node.modifiers ? node.modifiers : { NOT: false, DISABLE: false }
  );

  useEffect(() => {
    if (isRoot && node.typeOfConfig !== 'ANNOTATION_MATCH' && node.groups.length > 5) {
      setIsCollapsedAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  useEffect(() => {
    setModifiers(
      'modifiers' in node && node.modifiers ? node.modifiers : { NOT: false, DISABLE: false }
    );
  }, [node]);

  const handleChangeModifiers = (modifier: keyof Modifiers): void => {
    if (modifier === 'NOT' || modifier === 'DISABLE') {
      const localModifiers = { ...modifiers };

      if (localModifiers[modifier] === true) {
        delete localModifiers[modifier];
      } else {
        localModifiers[modifier] = true;
      }

      dispatch(updateModifier({ id: selectedId, modifiers: localModifiers }));

      if (node.typeOfConfig !== 'ANNOTATION_MATCH' && node.groups && modifier === 'DISABLE') {
        const childrenModifiers = { DISABLE: localModifiers.DISABLE };
        allChildNodes.forEach((item) => {
          dispatch(updateModifier({ id: item, modifiers: childrenModifiers }));
        });
      }
    }
  };

  const handleRemoveItem = (): void => {
    dispatch(removeItem(selectedId));
    dispatch(selectItem({ index: null }));
  };

  const handleChangeRelationType = (name: NodeNameType): void => {
    const actualName = name.replace('_NOT', '') as MRuleConfigNode['typeOfConfig'];

    dispatch(
      // @ts-ignore
      patchSingleRuleConfig({
        id: selectedId,
        name: actualName,
        typeOfConfig: actualName,
        relationship: [],
      })
    );

    if (!modifiers?.NOT && name.includes('NOT')) {
      handleChangeModifiers('NOT');
    } else if (modifiers?.NOT && !name.includes('NOT')) {
      handleChangeModifiers('NOT');
    }
  };

  const handleCreateNewGroup = (position: string): void => {
    let parentId = selectedId;
    if (position === 'sibling' && node.parent) {
      parentId = node.parent;
    }
    if (isRoot && position === 'sibling') return;
    dispatch(createNewGroup({ parentId }));
  };

  const handleMoveItem = (): void => {
    setIdToMove(selectedId as string);
  };

  const handleCopyItem = (): void => {
    dispatch(copyItem({ idToCopy: selectedId }));
  };

  const handlePasteItem = (): void => {
    dispatch(pasteItem({ targetNodeId: selectedId }));
  };

  const handleNegatedButtonClick = (negated?: boolean) => (): void => {
    dispatch(updateNegated({ id: node.id, negated }));
  };

  const handleModeOfSpeechClick = (mode: ModeOfSpeech) => (): void => {
    dispatch(updateModeOfSpeech({ id: node.id, mode }));
  };

  const renderTypeOfConfigButtons = (): JSX.Element | null => {
    const casted = node.name as keyof typeof operatorName;
    if (operatorName[casted] == null) {
      return null;
    }

    const options: { value: NodeNameType; label: string }[] = [
      {
        value: 'AND',
        label: 'AND',
      },
      {
        value: 'OR',
        label: 'OR',
      },

      {
        value: 'AND_NOT',
        label: 'AND NOT',
      },
      {
        value: 'OR_NOT',
        label: 'OR NOT',
      },
    ];

    if (!isRoot) {
      options.splice(2, 0, {
        value: 'RELATIONSHIP_MATCH',
        label: 'SS',
      });
      options.push({
        value: 'RELATIONSHIP_MATCH_NOT',
        label: 'SS NOT',
      });
    }

    let parsedName = node.name;

    if (modifiers?.NOT) {
      parsedName = `${node.name}_NOT`;
    }

    return (
      <div className="w-30">
        <SelectRedesign
          className="font-bold"
          dataTestid="config-type-select"
          onChange={(value): void => {
            handleChangeRelationType(value as NodeNameType);
          }}
          options={options}
          value={parsedName}
          placeholder=""
          valueClassName="font-bold"
        />
      </div>
    );
  };

  const renderSelectButton = (): JSX.Element | null => {
    const casted = node.name as keyof typeof operatorAcceptsOperatorChildren;
    if (operatorAcceptsOperatorChildren[casted] == null) {
      return null;
    }

    let options = [
      {
        value: 'child',
        label: 'As child',
      },
      {
        value: 'sibling',
        label: 'As sibling',
      },
    ];

    if (isRoot) {
      options = options.filter((option) => option.value !== 'sibling');
    }

    return (
      <div className="w-30">
        <SelectRedesign
          onChange={(value): void => {
            handleCreateNewGroup(value);
          }}
          options={options}
          value=""
          placeholder="Insert group"
        />
      </div>
    );
  };

  const renderMoveDeleteButtons = (): JSX.Element => {
    if (isRoot) {
      return (
        <div className="flex flex-row gap-3">
          <button type="button" onClick={handleCopyItem} className={buttonMainClass}>
            Copy
          </button>
          {operatorAcceptsOperatorChildren[
            node.name as keyof typeof operatorAcceptsOperatorChildren
          ] != null && (
            <button type="button" onClick={handlePasteItem} className={buttonMainClass}>
              Paste
            </button>
          )}
        </div>
      );
    }

    return (
      <div className="flex flex-row gap-3">
        {operatorName[node.name as keyof typeof operatorName] != null && (
          <>
            <button type="button" onClick={handleCopyItem} className={buttonMainClass}>
              Copy
            </button>
            {operatorAcceptsOperatorChildren[
              node.name as keyof typeof operatorAcceptsOperatorChildren
            ] != null && (
              <>
                <button type="button" onClick={handlePasteItem} className={buttonMainClass}>
                  Paste
                </button>
              </>
            )}
          </>
        )}
        <button
          type="button"
          onClick={handleMoveItem}
          data-testid="move-button"
          className={buttonMainClass}
        >
          Move
        </button>
      </div>
    );
  };

  const renderDeleteButton = (): JSX.Element => (
    <button
      data-testid="delete-button"
      onClick={handleRemoveItem}
      type="button"
      className="text-body focus:outline-none"
    >
      <span className="text-litlingo-alert underline">Delete</span>
    </button>
  );

  const handleCollapseAll = (): void => {
    if (isCollapsedAll) {
      handleUncollapse(selectedId, true);
      setIsCollapsedAll(false);
    } else if (isRoot) {
      handleCollapse(selectedId);
      if (node.typeOfConfig !== 'ANNOTATION_MATCH' && node.groups.length < 5) {
        handleUncollapse(selectedId, false, true, true, 0);
      } else {
        handleUncollapse(selectedId, false);
      }
      setIsCollapsedAll(true);
    } else {
      handleCollapse(selectedId);
      setIsCollapsedAll(true);
    }
  };

  const renderEpandAllButton = (): JSX.Element | null => {
    const casted = node.name as keyof typeof operatorName;
    if (operatorName[casted] == null) {
      return null;
    }
    return (
      <button
        type="button"
        className="underline text-litlingo-primary-120 focus:outline-none whitespace-no-wrap"
        onClick={handleCollapseAll}
      >
        {isCollapsedAll ? 'Expand All' : 'Collapse All'}
      </button>
    );
  };

  const renderDisabledButton = (): JSX.Element => (
    <button
      type="button"
      data-testid="DISABLE-button"
      onClick={(): void => {
        handleChangeModifiers('DISABLE');
      }}
      className={modifiers?.DISABLE ? buttonFocusClass : buttonMainClass}
    >
      {modifiers?.DISABLE ? 'Enable' : 'Disable'}
    </button>
  );

  const renderNegatedButtons = (): JSX.Element | null => {
    if (node.typeOfConfig !== 'ANNOTATION_MATCH' || !isInsideSameSentence) {
      return null;
    }

    return (
      <div className="flex flex-row">
        <button
          type="button"
          data-testid="negated-button"
          onClick={handleNegatedButtonClick(true)}
          className={`${buttonGroupLeft} ${
            node.negated === true ? buttonFocusClass : buttonMainClass
          }`}
        >
          Negated
        </button>
        <button
          type="button"
          className={`${buttonGroupCenter} ${
            node.negated === false ? buttonFocusClass : buttonMainClass
          }`}
          onClick={handleNegatedButtonClick(false)}
        >
          Not Negated
        </button>
        <button
          type="button"
          className={`${buttonGroupRight} ${
            node.negated == null ? buttonFocusClass : buttonMainClass
          }`}
          onClick={handleNegatedButtonClick()}
        >
          Indifferent
        </button>
      </div>
    );
  };

  const renderModeOfSpeechButtons = (): JSX.Element | null => {
    if (node.typeOfConfig !== 'ANNOTATION_MATCH' || !isInsideSameSentence) {
      return null;
    }

    return (
      <div className="flex flex-row">
        <button
          type="button"
          onClick={handleModeOfSpeechClick('subject')}
          className={`${buttonGroupLeft} ${
            node.mode_of_speech === 'subject' ? buttonFocusClass : buttonMainClass
          }`}
        >
          Subject
        </button>
        <button
          type="button"
          className={`${buttonGroupCenter} ${
            node.mode_of_speech === 'object' ? buttonFocusClass : buttonMainClass
          }`}
          onClick={handleModeOfSpeechClick('object')}
        >
          Object
        </button>
        <button
          type="button"
          className={`${buttonGroupRight} ${
            node.mode_of_speech == null ? buttonFocusClass : buttonMainClass
          }`}
          onClick={handleModeOfSpeechClick(null)}
        >
          Indifferent
        </button>
      </div>
    );
  };

  const renderRelationshipButton = (): JSX.Element | null => {
    // TODO: check this cast
    const tree = arrayTree as RuleTree[];
    if (node.typeOfConfig !== 'RELATIONSHIP_MATCH' || !checkForChildren(tree, selectedId)) {
      return null;
    }

    return (
      <button onClick={toggleShowModal} type="button" className={buttonMainClass}>
        Edit Relationship
      </button>
    );
  };

  const renderMoveMode = (): JSX.Element | null => {
    if (idToMove == null) {
      return null;
    }

    return (
      <div data-testid="move-mode">
        <button type="button" onClick={(): void => setIdToMove(null)} className={buttonMainClass}>
          Cancel
        </button>
      </div>
    );
  };

  const renderNotMoveMode = (): JSX.Element | null => {
    if (idToMove != null) {
      return null;
    }

    const renderShowHits = (): JSX.Element | null => {
      if (selectedNode == null) return null;
      const branchNode = arrayTree[selectedNode];
      if (branchNode.nodeGroups) {
        return (
          <div data-testid="show-hits-button">
            <button
              type="button"
              onClick={(e): void => handleNavigateRuleBranchUUID(e)}
              className={buttonMainClass}
            >
              Show hits
            </button>
          </div>
        );
      }
      return null;
    };

    return (
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-row gap-3">
          {renderTypeOfConfigButtons()}
          {renderSelectButton()}

          {renderMoveDeleteButtons()}

          <div className="flex flex-row gap-3">{renderDisabledButton()}</div>
          {renderNegatedButtons()}
          {renderModeOfSpeechButtons()}

          {renderRelationshipButton()}
          {renderShowHits()}
          {renderEpandAllButton()}
        </div>
        {renderDeleteButton()}
      </div>
    );
  };

  return (
    <div className="sticky z-10 flex flex-row justify-between bg-white py-3 px-6 top-13">
      <div className="flex flex-row gap-4 w-full">
        {renderMoveMode()}
        {renderNotMoveMode()}
      </div>
    </div>
  );
};

export default RuleManagerAction;
