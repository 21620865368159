/* eslint-disable max-lines */
import { setURLParams } from 'actions';
import ListFooter from 'components/ListFooter';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import UserSidebar from 'components/UserSidebar/UserSidebar';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import { getSelectedUsers } from 'selectors/userPermissionsPage';
import { getUsersList, getUsersLoading, getUsersTotalCount } from 'selectors/users';
import { useSelector } from 'store';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import UsersListHeader from './UserListHeader';
import UserTable from './UserTable';

const UsersList: React.FC = () => {
  const dispatch = useDispatch();

  const selectedUsers = useSelector(getSelectedUsers);
  const loading = useSelector(getUsersLoading);

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  useEffect(() => {
    dispatch(
      setURLParams({
        [`${resourceQueryParamName.user}__sync_emails`]: ['true', 'false'],
      })
    );
  }, [dispatch]);

  return (
    <div
      className="flex flex-col min-w-screen bg-white overflow-hidden"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />

      <div
        className="flex flex-row z-0"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <SettingSidebar />
        <div className="h-full w-full flex flex-col px-6 py-6 gap-4 overflow-hidden">
          <UsersListHeader />
          <main className="flex w-full h-full overflow-hidden">
            <div className="flex flex-col gap-4  w-full h-full overflow-hidden">
              <div className="table-wrapper__new no-scrollbar overflow-auto">
                <LoadingOverlay
                  active={loading}
                  spinner={<LoadingIndicator size="10" />}
                  className="relative w-full h-full"
                  fadeSpeed={0}
                  styles={{
                    content: (base) => ({
                      ...base,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }),
                    wrapper: () => ({}),
                    spinner: () => ({}),
                    overlay: (base) => ({
                      ...base,
                      zIndex: 10,
                    }),
                  }}
                >
                  <UserTable />
                  <div className="border-t border-litlingo-gray-1 sticky bottom-0 z-10 bg-white">
                    <ListFooter
                      resourceStateName={resourceQueryParamName.user}
                      resourceName="user"
                      getResourceList={getUsersList}
                      getResourceTotalCount={getUsersTotalCount}
                      getResourceLoading={getUsersLoading}
                    />
                  </div>
                </LoadingOverlay>
              </div>
            </div>
          </main>
        </div>
        {selectedUsers.length > 0 && (
          <div className="user-right-sidebar">
            <UserSidebar footer={false} saveInApply shouldDeleteUser />
          </div>
        )}
      </div>
    </div>
  );
};

export default UsersList;
