import React from 'react';

const NOTES_ICON = (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.875 2.125V7.75H7.75V10.875H2.125V2.125H10.875ZM10.875 0.875H2.125C1.4375 0.875 0.875 1.4375 0.875 2.125V10.875C0.875 11.5625 1.4375 12.125 2.125 12.125H8.375L12.125 8.375V2.125C12.125 1.4375 11.5625 0.875 10.875 0.875ZM6.5 7.75H3.375V6.5H6.5V7.75ZM9.625 5.25H3.375V4H9.625V5.25Z"
      fill="#F2C94C"
    />
  </svg>
);

export const NOTES_ICON_REDESIGN = (disabled: boolean, noneUtlization = false): JSX.Element => {
  const getNotesIconColor = (): string => {
    if (noneUtlization && disabled) return '#EBEBEB';
    if (disabled) return '#D6D6D6';
    return '#FFC945';
  };

  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.55856 6.14286e-05C7.58128 6.14286e-05 7.60377 0.00162394 7.62605 0.00452571L13.1004 5.46853C13.1011 5.47902 13.1014 5.48951 13.1014 5.5V13.5C13.1014 14.8807 11.9734 16 10.5819 16H2.51951C1.12805 16 0 14.8807 0 13.5V2.5C0 1.11931 1.12805 0 2.51951 0L7.55856 6.14286e-05ZM9.57416 5.00006C8.73924 5.00006 8.06246 4.32852 8.06246 3.50006V1.7072L11.381 5.00006H9.57416ZM3.52734 7.00006H9.57416C9.85244 7.00006 10.0781 7.22394 10.0781 7.50006C10.0781 7.77618 9.85244 8.00006 9.57416 8.00006H3.52734C3.24907 8.00006 3.02344 7.77618 3.02344 7.50006C3.02344 7.22394 3.24907 7.00006 3.52734 7.00006ZM9.57416 9.50006H3.52734C3.24907 9.50006 3.02344 9.72394 3.02344 10.0001C3.02344 10.2762 3.24907 10.5001 3.52734 10.5001H9.57416C9.85244 10.5001 10.0781 10.2762 10.0781 10.0001C10.0781 9.72394 9.85244 9.50006 9.57416 9.50006ZM5.03905 12.0001H8.31441C8.59268 12.0001 8.81831 12.2239 8.81831 12.5001C8.81831 12.7762 8.59268 13.0001 8.31441 13.0001H5.03905C4.76078 13.0001 4.53515 12.7762 4.53515 12.5001C4.53515 12.2239 4.76078 12.0001 5.03905 12.0001Z"
        fill={getNotesIconColor()}
      />
    </svg>
  );
};

export const NONE_ICON = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 12L10 10L8 12ZM10 10L12 8L10 10ZM10 10L8 8L10 10ZM10 10L12 12L10 10ZM19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
      stroke="#EB5757"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NOTES_ICON;
