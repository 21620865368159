import { setURLParams } from 'actions';
import Modal from 'components/Modal';
import { MODAL_BACKGROUND } from 'constants/common';
import React from 'react';
import { useDispatch } from 'react-redux';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
  navigateToReviewManagement: () => void;
};

const FirstTimeCreateReviewSetModal: React.FC<ComponentProps> = ({
  isModalOpen,
  toggleModalOpen,
  navigateToReviewManagement,
}) => {
  const dispatch = useDispatch();
  const bodyModal = (
    <>
      <div className="absolute top-5 left-0 right-0 h-40 z-0">{MODAL_BACKGROUND}</div>

      <div className="flex flex-col mt-30 mb-8 gap-2 max-h-128 overflow-hidden">
        <h2 className="text-heading-1">Review Created </h2>
        <p className="text-body">
          You’ll be able to find all your reviews in Review Management under Messages. There you can
          assign reviewers to the stream and add tag groups.
        </p>
      </div>
    </>
  );
  const handleCancel = (): void => {
    dispatch(
      setURLParams({
        [`envelopes__is_first_time_create_review_set`]: '',
      })
    );
    toggleModalOpen();
  };
  return (
    <>
      {isModalOpen && (
        <Modal
          body={bodyModal}
          title=" "
          toggleShowModal={handleCancel}
          cancelButton
          okButton
          okButtonText="Stay on Page"
          okButtonOnClick={handleCancel}
          okButtonStyle="h-10"
          cancelButtonText="Go To Review Management"
          cancelButtonOnclick={navigateToReviewManagement}
          cancelButtonStyle="h-10"
        />
      )}
    </>
  );
};

export default FirstTimeCreateReviewSetModal;
