import { fetchAllCategories } from 'actions';
import CustomersCategoriesTable from 'components/CategoriesTable/CustomersCategoriesTable';
import CollapsableCard from 'components/CollapsableCard';
import LinkLookup from 'components/LinkLookup';
import Permissions from 'components/Permissions';
import useNavParams from 'hooks/nav/useNavParams';
import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCustomerId } from 'selectors/auth';
import { getCategoriesList } from 'selectors/categories';
import type { Category, UUID } from 'types';

type RouteParams = {
  categoryId: UUID;
};

type ComponentProps = {
  item: Category;
};

const RenderCustomersCategories: React.FC<ComponentProps> = ({ item }) => {
  const { categoryId } = useParams<RouteParams>();
  const dispatch = useDispatch();
  const { customer_owned: customerOwned } = useNavParams() as { customer_owned: string };

  const categories = useSelector(getCategoriesList);
  const customerId = useSelector(getCustomerId);

  useEffect(() => {
    if (item.rule_revision_uuid) {
      dispatch(
        fetchAllCategories({
          limit: '-1',
          rule_revision_uuids: item.rule_revision_uuid,
          customer_uuids: customerOwned && customerId ? [customerId] : [],
          relationships: ['customer', 'outcome', 'rule', 'updated_by', 'model'],
        })
      );
    }
  }, [dispatch, item.rule_revision_uuid, customerOwned, customerId]);

  const createCategoryButton = (
    <Permissions action="rules.create">
      <span className="rounded-md">
        {/* <LinkLookup
          onClick={(e): void => e.stopPropagation()}
          routeName="global-category-manager"
          routeParams={{ categoryId }}
          type="button"
          data-testid="create-rule-form"
          className="button button--secondary button--green-text font-bold h-8"
        >
          Create Category
        </LinkLookup> */}
      </span>
    </Permissions>
  );

  const skeletonLoading = (): JSX.Element => (
    <div className="px-12 py-5">
      <Skeleton count={5} />
    </div>
  );

  const renderContent = (): JSX.Element => {
    if (categories.length === 0) {
      return (
        <div className="flex flex-col justify-center items-center gap-4 py-8">
          <span className="text-body">
            Models make up the conditions the Use Case will monitor and match against
          </span>
          <LinkLookup
            onClick={(e): void => e.stopPropagation()}
            routeName="global-category-manager"
            routeParams={{ categoryId }}
            type="button"
            data-testid="create-rule-form"
            className="button button--primary h-8"
          >
            Create Use Case
          </LinkLookup>
        </div>
      );
    }

    return (
      <div className="border rounded">
        {!categories ? skeletonLoading() : <CustomersCategoriesTable categories={categories} />}
      </div>
    );
  };

  return (
    <CollapsableCard headerTitle="Related Use Cases" customAction={createCategoryButton}>
      <div className="p-4">{renderContent()}</div>
    </CollapsableCard>
  );
};

export default RenderCustomersCategories;
