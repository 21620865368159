import { fetchCommunicationSummary } from 'actions';
import BackToTopButton from 'components/Button/BackToTopButton';
import ModalAddTestSuiteFromComm from 'components/ModalAddTestSuiteFromComm';
import Permissions from 'components/Permissions';
import Redacted from 'components/Redacted';
import fileExtensionIconParser from 'constants/fileIcons';
import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { getSingleCommunication } from 'selectors/communications';
import { getEnvelopeToPreview } from 'selectors/envelopeListView';
import { getShowMoreLikeThis } from 'selectors/envelopeView';
import { useSelector } from 'store';
import { CommunicationWithEvents } from 'types';
import parseBody from 'utils/strings';
import AttachmentTranslation from './AttachmentTranslation';
import MoreLikeThis from './MoreLikeThis';

type ComponentProps = {
  communication: CommunicationWithEvents;
};

const NoMatchAttachmentContent: React.FC<ComponentProps> = ({ communication }) => {
  const dispatch = useDispatch();
  const communicationSummary = useSelector((state) =>
    getSingleCommunication(state, communication.uuid)
  );
  const envelopeToPreview = useSelector(getEnvelopeToPreview);
  const showMoreLikeThis = useSelector(getShowMoreLikeThis);

  const [showButton, setShowButton] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(fetchCommunicationSummary({ commId: communication.uuid }));
  }, [dispatch, communication.uuid]);

  useEffect(() => {
    if ((contentRef.current?.clientHeight || 0) < (contentRef.current?.scrollHeight || 0)) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [communicationSummary]);

  const toggleModal = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  if (!communicationSummary) return null;

  const name = communication.meta_data?.filename?.split('/').pop() || '';
  const extension = communication.meta_data?.filename?.split('/').pop()?.split('.').pop() || '';

  return (
    <div className="overflow-y-hidden w-full h-full flex flex-col justify-between">
      <div className="relative w-full flex flex-col gap-4 overflow-hidden">
        <BackToTopButton scrollRef={contentRef} showButton={showButton} rightOffset={30} />

        <div
          ref={contentRef}
          className={`relative flex flex-col gap-4 py-4 overflow-auto ${
            !envelopeToPreview && 'px-6'
          }`}
        >
          <div className="w-full flex flex-row items-center gap-2">
            <div className="flex flex-none h-5 w-5">{fileExtensionIconParser(extension || '')}</div>
            <div className="w-full flex flex-row justify-between">
              <span className="truncate">{name}</span>
              <AttachmentTranslation />
            </div>
          </div>

          <Redacted field="communications.body">
            <ReactMarkdown renderers={{ link: () => <></> }}>
              {parseBody(communicationSummary.body.substring(0, communicationSummary.body.length))}
            </ReactMarkdown>
          </Redacted>
        </div>
      </div>
      {isModalOpen && (
        <ModalAddTestSuiteFromComm communication={communicationSummary} toggleModal={toggleModal} />
      )}
      {showMoreLikeThis && communication.envelope_uuid && (
        <Permissions action="communication_envelopes.more_like_this">
          <div className={`relative flex flex-col  ${!envelopeToPreview && 'px-0'}`}>
            <MoreLikeThis envelopeUuid={communication.envelope_uuid} />
          </div>
        </Permissions>
      )}
    </div>
  );
};

export default NoMatchAttachmentContent;
