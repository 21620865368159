import { fetchCustomersMe, selectCustomer } from 'actions';
import CollapsableList from 'components/CollapsableList';
import LoadingIndicator from 'components/LoadingIndicator';
import NoUserNavbar from 'components/NoUserNavbar';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomersMeLoading, getSelectCustomerList } from 'selectors/auth';
import { Customer } from 'types';

type ItemType = {
  uuid: string;
  label: string | null;
  domain: string | null;
  subitems: ItemType[];
};

const CustomerList: React.FC = () => {
  const dispatch = useDispatch();

  const customers = useSelector(getSelectCustomerList);
  const loading = useSelector(getCustomersMeLoading);

  useEffect(() => {
    dispatch(fetchCustomersMe);
  }, [dispatch]);

  useEffect(() => {
    if (customers.length === 1) {
      const { domain } = customers[0];
      if (domain) {
        dispatch(selectCustomer({ domain }));
      } else {
        throw new Error(`Wrong customer domain: ${domain} in <CustomerList />`);
      }
    }
  }, [dispatch, customers]);

  const changeCustomer = (domain: string): void => {
    if (domain != null) {
      window.localStorage.setItem('__customer__', domain);
      dispatch(selectCustomer({ domain }));
    }
  };

  const reprocess = (node: ItemType, leaves: Customer[]): ItemType[] =>
    leaves
      .filter((i) => i.clone_of_customer_uuid === node.uuid)
      .map((l) => {
        const newNode = { uuid: l.uuid, subitems: [], label: l.name, domain: l.domain };
        return {
          ...newNode,
          sub: reprocess(newNode, leaves),
          subitems: reprocess(newNode, leaves),
        };
      });

  const customersList = (): ItemType[] => {
    const root: ItemType[] = [];
    const leaves: Customer[] = [];

    const sortedCustomers = [...customers];
    sortedCustomers.sort((a, b) =>
      a.name && b.name && a.name?.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );

    sortedCustomers.forEach((c) => {
      if (c.clone_of_customer_uuid === null) {
        root.push({ uuid: c.uuid, subitems: [], label: c.name, domain: c.domain });
      } else {
        leaves.push(c);
      }
    });

    root.forEach((r) => {
      r.subitems = reprocess(r, leaves);
    });

    return root;
  };

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <div
      className="flex flex-col min-w-screen bg-white"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <NoUserNavbar />
      <div className="flex flex-col w-full h-full gap-10 p-10 overflow-hidden">
        <header>
          <h2 className="title">Select Workspace</h2>
        </header>
        <main className="flex w-full h-full overflow-hidden">
          <div className="flex w-full h-full px-2 overflow-hidden">
            {loading ? (
              <LoadingIndicator size="10" />
            ) : (
              <CollapsableList
                list={customersList()}
                handleOnClick={(e, item): void => {
                  e.preventDefault();
                  changeCustomer(item.domain || '');
                }}
              />
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default CustomerList;
