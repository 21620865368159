import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';
import { useSelector } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';

type ComponentProps = {
  colCount?: string;
};

// If the table have table-layout: fixed, you must provide the colCount
// prop, which is the total number of <th> in the <thead>
// This component MUST be inside the <tbody> element
const EmptyUsersTable: React.FC<ComponentProps> = ({ colCount }) => {
  const value = useSelector(getNavParamsByResource(resourceQueryParamName.user)).broad_search || '';

  if (value) {
    return (
      <tr>
        {/* @ts-ignore */}
        <td colSpan={colCount || '100%'}>
          <div className="flex flex-col items-center gap-4 py-20 px-52">
            <div className="w-112 flex flex-col gap-1 text-center">
              <span className="text-heading-2">User not found</span>
              <div className="text-body">
                Suggestions: Make sure all words are spelled correctly or try a more general search.
              </div>
            </div>
            <button
              onClick={(): void => {
                // empty
              }}
              type="button"
              className="w-52 h-8 flex flex-row justify-center button button--primary whitespace-no-wrap focus:outline-none"
              key="add"
            >
              <span className="text-base">Add a New User</span>
            </button>
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tr>
      {/* @ts-ignore */}
      <td colSpan={colCount || '100%'}>
        <div className="flex flex-col items-center gap-4 py-20 px-52">
          <div className="w-112 flex flex-col gap-1 text-center">
            <span className="text-heading-2">Users added will show up here</span>
            <div className="text-body">
              Import your list of users from an excel file or enter them in manually in Step 1.
              You’ll see all the permissions applied here (Step 2) before they’re finalized upon
              clicking “Save and Exit”.
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default EmptyUsersTable;
