import { createAction } from '@reduxjs/toolkit';
import type { ErrorObject, Team, UUID, User } from 'types';

export const addUsers = createAction<User[]>('USERS_PERMISSIONS_PAGE/ADD_USERS');
export const removeUsers = createAction<User[]>('USERS_PERMISSIONS_PAGE/REMOVE_USERS');

export const selectUsers = createAction<User[]>('USERS_PERMISSIONS_PAGE/SELECT_USERS');
export const selectAllUsers = createAction<boolean>('USERS_PERMISSIONS_PAGE/SELECT_ALL_USERS');

export const changeName = createAction<string>('USERS_PERMISSIONS_PAGE/CHANGE_NAME');
export const changeEmail = createAction<string>('USERS_PERMISSIONS_PAGE/CHANGE_EMAIL');

export const addIncludedTeams = createAction<Team[]>('USERS_PERMISSIONS_PAGE/ADD_INCLUDED_TEAMS');
export const removeIncludedTeams = createAction<Team[]>(
  'USERS_PERMISSIONS_PAGE/REMOVE_INCLUDED_TEAMS'
);
export const addExcludedTeams = createAction<Team[]>('USERS_PERMISSIONS_PAGE/ADD_EXCLUDED_TEAMS');
export const removeExcludedTeams = createAction<Team[]>(
  'USERS_PERMISSIONS_PAGE/REMOVE_EXCLUDED_TEAMS'
);

export const addIncludedRoles = createAction<string[]>('USERS_PERMISSIONS_PAGE/ADD_INCLUDED_ROLES');
export const removeIncludedRoles = createAction<string[]>(
  'USERS_PERMISSIONS_PAGE/REMOVE_INCLUDED_ROLES'
);
export const addExcludedRoles = createAction<string[]>('USERS_PERMISSIONS_PAGE/ADD_EXCLUDED_ROLES');
export const removeExcludedRoles = createAction<string[]>(
  'USERS_PERMISSIONS_PAGE/REMOVE_EXCLUDED_ROLES'
);

export const addIncludedInsight = createAction<string[]>(
  'USERS_PERMISSIONS_PAGE/ADD_INCLUDED_INSIGHT'
);
export const removeIncludedInsight = createAction<string[]>(
  'USERS_PERMISSIONS_PAGE/REMOVE_INCLUDED_INSIGHT'
);
export const addExcludedInsight = createAction<string[]>(
  'USERS_PERMISSIONS_PAGE/ADD_EXCLUDED_INSIGHT'
);
export const removeExcludedInsight = createAction<string[]>(
  'USERS_PERMISSIONS_PAGE/REMOVE_EXCLUDED_INSIGHT'
);

export const addIncludedPrevent = createAction<string[]>(
  'USERS_PERMISSIONS_PAGE/ADD_INCLUDED_PREVENT'
);
export const removeIncludedPrevent = createAction<string[]>(
  'USERS_PERMISSIONS_PAGE/REMOVE_INCLUDED_PREVENT'
);
export const addExcludedPrevent = createAction<string[]>(
  'USERS_PERMISSIONS_PAGE/ADD_EXCLUDED_PREVENT'
);
export const removeExcludedPrevent = createAction<string[]>(
  'USERS_PERMISSIONS_PAGE/REMOVE_EXCLUDED_PREVENT'
);

export const changePrivacyLevel = createAction<User['privacy_level']>(
  'USERS_PERMISSIONS_PAGE/CHANGE_PRIVACY_LEVEL'
);

export const changeSendInvitation = createAction<boolean>(
  'USERS_PERMISSIONS_PAGE/CHANGE_SEND_INVITATION'
);

export const clearState = createAction('USERS_PERMISSIONS_PAGE/CLEAR_STATE');

export const clearPermissions = createAction('USERS_PERMISSIONS_PAGE/CLEAR_PERMISSIONS');

export const applyPermissions = createAction('USERS_PERMISSIONS_PAGE/APPLY_PERMISSIONS');

export const applyPermissionsSingleUser = createAction(
  'USERS_PERMISSIONS_PAGE/APPLY_PERMISSIONS_SINGLE_USER'
);

export type InvitationStates = {
  invitation: 'Sent' | 'Accepted' | null;
};

export const fetchUserInvitationState = createAction<{ userId: UUID }>(
  'USERS/FETCH_USER_INVITATION'
);
export const fetchUserInvitationStateRequest = createAction('USERS/FETCH_USER_INVITATION_REQUEST');
export const fetchUserInvitationStateSuccess = createAction<InvitationStates>(
  'USERS/FETCH_USER_INVITATION_SUCCESS'
);
export const fetchUserInvitationStateFailure = createAction<ErrorObject>(
  'USERS/FETCH_USER_INVITATION_FAILURE'
);
export const fetchUserInvitationStateFulfill = createAction('USERS/FETCH_USER_INVITATION_FULFILL');

export const setNeedToApplyChanges = createAction<boolean>(
  'USERS_PERMISSIONS_PAGE/SET_NEED_TO_APPLY_CHANGES'
);
