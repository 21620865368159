import { deleteLanguageMatcher, setActiveLanguageMatcherId } from 'actions/identifier';
import ConfirmModal from 'components/ConfirmModal';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLanguageMatcherFromSelectedIdentifier } from 'selectors/identifier';
import { useSelector } from 'store';
import type { MLanguageMatcher, UUID } from 'types';

type ComponentProps = {
  setHighlightedLanguageMatcher: React.Dispatch<React.SetStateAction<string | undefined>>;
  activeLanguageMatcher: MLanguageMatcher;
  setValue: (field: string, value: string) => void;
};

const DeleteMatcherButton: React.FC<ComponentProps> = ({
  setValue,
  setHighlightedLanguageMatcher,
  activeLanguageMatcher,
}) => {
  const { identifierId } = useParams<{ identifierId: string }>();

  const dispatch = useDispatch();
  const [isDeletingLanguageMatcher, setIsDeletingLanguageMatcher] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<null | UUID>(null);
  const matchers = useSelector((state) => getLanguageMatcherFromSelectedIdentifier(state, ''));

  const toggleConfirmModal = (): void => setIsDeletingLanguageMatcher(false);

  const handleDelete = (): void => {
    if (idToBeDeleted != null) {
      dispatch(deleteLanguageMatcher({ languageMatcherId: idToBeDeleted, identifierId }));
      toggleConfirmModal();
      setValue('name', '');
      setValue('description', '');
      setHighlightedLanguageMatcher('');

      const matchersLeft = matchers.filter((m) => m.uuid !== idToBeDeleted);

      if (matchersLeft.length > 0) {
        dispatch(setActiveLanguageMatcherId(matchersLeft[0].uuid));
      }
    }
  };

  const handleDeleteClick = (languageMatcherId: UUID): void => {
    setIsDeletingLanguageMatcher(true);
    setIdToBeDeleted(languageMatcherId);
  };

  return (
    <>
      <div className="flex flex-row h-full items-center">
        <button
          onClick={(e): void => {
            e.stopPropagation();
            handleDeleteClick(activeLanguageMatcher.uuid);
          }}
          type="button"
          className="text-body focus:outline-none"
        >
          <span className="text-litlingo-alert underline">Delete</span>
        </button>
      </div>
      {isDeletingLanguageMatcher && idToBeDeleted && (
        <ConfirmModal
          text="Are you sure you want to delete this language matcher?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDelete}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </>
  );
};

export default DeleteMatcherButton;
