/* eslint-disable max-lines */
import { cloneDeep } from 'lodash';
import React from 'react';
import { getTree } from 'selectors/envelopes';
import { useSelector } from 'store';
import { Tree, allNodesLabeled } from 'utils/parserTree';
import FilterPillsTree from './FilterPillsTree';

export type FilterValue = { id: string; value: string };
type ComponentProps = {
  envelopesPage?: boolean;
  scrolled?: boolean;
  showAllFilters?: boolean;
  filtersModified?: boolean;
  customTree?: Tree;
};

const FilterPills: React.FC<ComponentProps> = ({
  envelopesPage = true,
  scrolled,
  showAllFilters,
  filtersModified,
  customTree,
}) => {
  const tree = useSelector(getTree);

  const renderTree = (): JSX.Element => {
    const treeCopy = cloneDeep(customTree ?? tree);

    if (!allNodesLabeled(treeCopy) && !envelopesPage) {
      return <></>;
    }

    return (
      <FilterPillsTree
        tree={treeCopy}
        filtersModified={filtersModified}
        allowDelete={false}
        envelopesPage={envelopesPage}
      />
    );
  };

  const filterSectionStyles = 'flex flex-row items-center gap-1 flex-wrap flex-grow-0';

  return (
    <div className="flex items-center">
      <div
        className={`${
          envelopesPage
            ? `flex gap-1 ${!scrolled ? 'flex-wrap' : 'flex-no-wrap'}`
            : filterSectionStyles
        } `}
        style={{
          gridTemplateColumns: 'auto 1fr',
          flexWrap: showAllFilters || filtersModified ? 'wrap' : undefined,
        }}
      >
        {renderTree()}
      </div>
    </div>
  );
};

export default FilterPills;
