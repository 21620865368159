import amplitude from 'amplitude-js';

type Data = { [key: string]: unknown };

const logEvent = async (event: string, data: Data = {}): Promise<void> => {
  const customerDomain = window.localStorage.getItem('__customer__');

  amplitude.getInstance().logEvent(event, {
    ...data,
    customerDomain,
  });
};

export default logEvent;
