import { showSuccessAlert, spoofRoles } from 'actions';
import { customersEmails, whiteListCustomersUuids } from 'constants/customerAccounts';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  getCustomerConfigError,
  getSpoofedUserEmail,
  getSpoofedUserUuid,
  getUser,
} from 'selectors/auth';
import { useSelector } from 'store';

const CustomerWarning: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const spoofedUserUuid = useSelector(getSpoofedUserUuid);
  const spoofedUserEmail = useSelector(getSpoofedUserEmail);

  const customerError = useSelector(getCustomerConfigError);
  const handleSpoofClick = (event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>): void => {
    event.stopPropagation();
    dispatch(spoofRoles({ userUuid: undefined, userEmail: undefined }));
    dispatch(showSuccessAlert('View Changed back'));
  };

  const handleMessage = (): JSX.Element => {
    if (customerError) {
      return (
        <div className="bg-red-600 text-sm text-left p-1 pl-5 text-white">{customerError}</div>
      );
    }
    if (user.email) {
      const emailDomain = user.email.split('@')[1];
      if (
        customersEmails.find((email) => email === emailDomain) &&
        !whiteListCustomersUuids.find((uuid) => uuid === user.customer?.uuid)
      ) {
        return (
          <div className="bg-yellow-400 text-sm text-left p-1 pl-5">
            You&apos;re in a customer account. Proceed with caution.
          </div>
        );
      }
    }
    return <></>;
  };

  return (
    <div id="customer-warning">
      {handleMessage()}
      {spoofedUserUuid && (
        <div className="bg-red-600 text-sm text-left p-1 pl-5 text-white flex justify-between">
          <span>{`YOU ARE VIEWING AS ${spoofedUserEmail}`}</span>
          <button
            className="button button--secondary pt-0 pb-0"
            type="button"
            onClick={handleSpoofClick}
          >
            Click here to return back to original view
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomerWarning;
