import React from 'react';

type ComponentProps = {
  isOn: boolean;
  handleToggleClick:
    | (() => void)
    | ((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void);
  disabled?: boolean;
  dataTestid?: string;
};

const ToggleButtonRedesign: React.FC<ComponentProps> = (props) => {
  const { isOn, handleToggleClick, disabled, dataTestid } = props;

  return (
    <button
      data-testid={dataTestid || 'id'}
      type="button"
      role="checkbox"
      tabIndex={0}
      aria-checked="false"
      onClick={handleToggleClick}
      className={`${isOn ? 'bg-litlingo-success' : 'bg-litlingo-gray-100'}
      ${disabled ? 'pointer-events-none opacity-50' : ''}
       relative inline-flex flex-shrink-0 h-6  w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none`}
    >
      <span
        aria-hidden="true"
        className={`${
          isOn ? 'translate-x-5' : 'translate-x-0'
        } relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
      />
    </button>
  );
};

export default ToggleButtonRedesign;
