import { typeOptionsColorMap, typeOptionsMap } from 'constants/models';
import React from 'react';

type ComponentProps = {
  group: string;
};

const RuleGroupTag: React.FC<ComponentProps> = ({ group }) => {
  const colors = typeOptionsColorMap[group as keyof typeof typeOptionsColorMap];

  return (
    <div
      className={`flex items-center gap-1.5 px-2 py-1 rounded h-7 text-body ${colors.className}`}
    >
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="5" cy="5" r="5" fill={colors.ellipse} />
      </svg>
      <span>{typeOptionsMap[group as keyof typeof typeOptionsMap]}</span>
    </div>
  );
};

export default RuleGroupTag;
