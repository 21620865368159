import {
  deleteTemplateRequest,
  fetchSingleTemplateRequest,
  fetchTemplatesRequest,
  upsertTemplateRequest,
} from 'actions';
import type { Selector, Template, UUID } from 'types';

export const getFetchAllTemplatesLoading: Selector<boolean> = (state) =>
  state.templates.loading.includes(fetchTemplatesRequest.toString());

export const getFetchSingleTemplateLoading: Selector<boolean> = (state) =>
  state.templates.loading.includes(fetchSingleTemplateRequest.toString());

export const getUpsertTemplateLoading: Selector<boolean> = (state) =>
  state.templates.loading.includes(upsertTemplateRequest.toString());

export const getDeleteTemplateLoading: Selector<boolean> = (state) =>
  state.templates.loading.includes(deleteTemplateRequest.toString());

export const getSelectedTemplate: Selector<Template> = (state) => state.templates.selectedTemplate;

export const getTemplates: Selector<Template[]> = (state) => Object.values(state.templates.items);

export const getTemplatesTotalCount: Selector<number> = (state) => state.templates.count;

export type FilterTemplate = {
  uuid: UUID;
  name: string;
  count?: number;
};
