import CollapsableCard from 'components/CollapsableCard';
import Permissions from 'components/Permissions';
import TestResultsVisualizer from 'components/TestResultsVisualizer';
import TestSentenceVisualizerV2 from 'components/TestSentenceVisualizerV2';
import React from 'react';
import { MModel } from 'types';

interface Props {
  item: MModel;
}

const RenderTestSuite: React.FC<Props> = ({ item }): JSX.Element => (
  <CollapsableCard headerTitle="Test a Phrase">
    <div className="p-4">
      <Permissions action="test_cases.list">
        <TestSentenceVisualizerV2 modelId={item.uuid} />
        <TestResultsVisualizer />
      </Permissions>
    </div>
  </CollapsableCard>
);

export default RenderTestSuite;
