/* eslint-disable max-lines */
import { startCase } from 'lodash';
import pluralize from 'pluralize';
import React from 'react';
import { useSelector } from 'react-redux';
import { getPermissionsCounts } from 'selectors/userPermissionsPage';

const UsersStats: React.FC = () => {
  const { roles, divisions, insight, prevent } = useSelector(getPermissionsCounts);

  return (
    <div className="pt-2 px-3 pb-4 flex flex-col justify-between bg-white">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <span>Role</span>

          <ul className="list-disc list-outside pl-7">
            {Object.entries(roles).map(([label, count]) => (
              <li key={label}>
                {startCase(label)}: {count} {pluralize('User', count)}{' '}
              </li>
            ))}
          </ul>
        </div>

        <div className="flex flex-col gap-1">
          <span>Insight Integration(s)</span>

          <ul className="list-disc list-outside pl-7">
            {Object.entries(insight).map(([label, count]) => (
              <li key={label}>
                {startCase(label)}: {count} {pluralize('User', count)}{' '}
              </li>
            ))}
          </ul>
        </div>

        <div className="flex flex-col gap-1">
          <span>Prevent Integration(s)</span>

          <ul className="list-disc list-outside pl-7">
            {Object.entries(prevent).map(([label, count]) => (
              <li key={label}>
                {startCase(label)}: {count} {pluralize('User', count)}{' '}
              </li>
            ))}
          </ul>
        </div>

        <div className="flex flex-col gap-1">
          <span>Division(s)</span>

          <ul className="list-disc list-outside pl-7">
            {Object.entries(divisions).map(([label, count]) => (
              <li key={label}>
                {startCase(label)}: {count} {pluralize('User', count)}{' '}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UsersStats;
