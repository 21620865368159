import { RequestResponse } from 'hooks/types/client';
import useRequest from '../client/useRequest';

type UseTree = (fs: string, enable?: boolean) => RequestResponse<'envelopes.extras.searchTree'>;

const useTree: UseTree = (fs: string, enable = false) => {
  const params = {
    queryParams: {
      filters_search: fs,
    },
  };
  const result = useRequest('envelopes.extras.searchTree', params, {
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log('error', error);
    },
    enabled: enable || !!fs,
  });

  return result;
};

export default useTree;
