import { fetchIdentitiesRequest } from 'actions';
import type { Identity, NormalizedResource, Selector } from 'types';

export const getIdentitiesLoading: Selector<boolean> = (state) =>
  state.identities.loading.includes(fetchIdentitiesRequest.toString());

export const getIdentities: Selector<NormalizedResource<Identity>> = (state) =>
  state.identities.items;

export const getIdentitiesTotalCount: Selector<number> = (state) => state.identities.count;

export const getIdentitiesList: Selector<Identity[]> = (state) => {
  const { items } = state.identities;

  return Object.keys(items).map((uuid) => items[uuid]);
};
