import type { APIClientSpec, RecursivePartial } from '@types';
import { merge as _merge } from 'lodash';

type PartialAPIClientSpec = RecursivePartial<APIClientSpec>;

// FIXME: the return type of this should be S1 & S2 (or a deep merged type), but
// using that makes the client lose all the typing for resources
// the best solution could be using a deep merged type
export function merge<
  S1 extends APIClientSpec,
  S2 extends PartialAPIClientSpec
>(spec: S1, overrides: S2): S1 {
  return _merge({}, spec, overrides);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function serializer(qs: any): string {
  const params = new URLSearchParams();

  Object.entries(qs).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => params.append(key, v as string));
      return;
    }

    if (value !== '') {
      params.append(key, value as string);
    }
  });

  return params.toString();
}
