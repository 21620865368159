/* eslint-disable max-lines */
import { Logo as FullLogo } from '@litlingo/react';
import { logoutUser } from 'actions';
import { SIGN_OUT_ICON } from 'constants/headerMenuIcons';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Transition from 'styles/Transition';

const NoUserNavbar: React.FC = () => {
  const dispatch = useDispatch();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const buttonMenuRef = useRef<HTMLButtonElement | null>(null);

  const [menuIsOpen, menuSetIsOpen] = useState(false);
  const menuItemClass =
    'w-full text-left px-3 py-2 block text text--lighter-4 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (e: any): void => {
    const element = menuRef.current;
    const buttonElement = buttonMenuRef.current;
    if (
      menuRef &&
      buttonMenuRef &&
      element != null &&
      buttonElement != null &&
      !element.contains(e.target) &&
      !buttonElement.contains(e.target)
    ) {
      menuSetIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return (): void => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const renderLogo = (): JSX.Element => (
    <div className="inline-flex items-center">
      <div className="flex items-center h-full w-48">
        <FullLogo />
      </div>
    </div>
  );

  return (
    <nav className="bg-white border-b border-gray-200 shadow-sm h-16">
      <div className="max-w-7xl mx-auto px-4 px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">{renderLogo()}</div>
          <div className="ml-6 flex items-center">
            <div className="ml-3 relative">
              <button
                type="button"
                onClick={(): void => menuSetIsOpen(!menuIsOpen)}
                ref={buttonMenuRef}
                className="flex text-sm border-2 rounded-md focus:outline-none focus:border-gray-500 transition duration-150 ease-in-out"
                id="user-menu"
                aria-label="User menu"
                aria-haspopup="true"
              >
                <div className="px-2 py-1" />
              </button>
              <Transition
                show={menuIsOpen}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div
                  ref={menuRef}
                  className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-navbar"
                >
                  <div className="py-1 flex flex-col rounded-md bg-white shadow-xs">
                    <tbody className="flex flex-col">
                      <button
                        type="button"
                        className={`${menuItemClass} m-0 hover:bg-gray-100`}
                        onClick={(): void => {
                          dispatch(logoutUser());
                        }}
                        data-skip
                      >
                        <tr>
                          <td>{SIGN_OUT_ICON}</td>
                          <td className="pl-3">Sign Out</td>
                        </tr>
                      </button>
                    </tbody>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NoUserNavbar;
