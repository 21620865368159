import { Line } from '@nivo/line';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import React from 'react';
import { DefaultWidgetComponentProps } from 'types';
import { buildSparkLineDateHistogramDataFromMetrics } from 'utils/dashboard';

const SparkLineMetricWidget: React.FC<DefaultWidgetComponentProps> = (props) => {
  const { widgetData, queryData } = props;

  if (widgetData == null || queryData == null) {
    return <WidgetEmptyState />;
  }

  const processedData = buildSparkLineDateHistogramDataFromMetrics(widgetData, queryData);

  return (
    <div className="flex flex-col h-full justify-evenly">
      <div className="flex justify-center">
        <span className="text text--title text--extra-large text--semibold mb-2">
          Keyword Monitors
        </span>
      </div>
      {processedData.data.map((serie) => (
        <div className="flex justify-around items-center" key={serie.id}>
          <span className="w-3/10 text text--extra-small truncate">{serie.label}</span>
          <div className="w-66/10 flex justify-center items-center">
            <Line
              height={30}
              width={150}
              data={[serie]}
              axisLeft={null}
              axisBottom={null}
              enablePoints={false}
              useMesh
              tooltip={(d): JSX.Element => (
                <div className="bg-litlingo-off-white p-1 border-radius flex items-center shadow-md">
                  <div
                    style={{
                      background: d.point.color,
                    }}
                    className="h-3 w-3 mr-2"
                  />
                  <span className="text">
                    x: <b>{d.point.data.x}</b> , y: <b>{d.point.data.y}</b>
                  </span>
                </div>
              )}
              enableGridX={false}
              enableGridY={false}
              theme={{
                tooltip: {
                  container: {
                    background: 'none',
                    boxShadow: 'none',
                  },
                },
                labels: {
                  text: {
                    fontWeight: 'bold',
                  },
                },
              }}
              margin={{ top: 5, right: 10, bottom: 5, left: 10 }}
            />
          </div>
          <span className="w-1/10 text text--extra-small">
            {serie.data[serie.data.length - 1].y}{' '}
          </span>
        </div>
      ))}
    </div>
  );
};

export default withWidget({
  minWidth: 4,
  minHeight: 10,
})(SparkLineMetricWidget);
