import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  ReviewSkippedEnvelopesSuccessPayload,
  closeAssignment,
  closeAssignmentFailure,
  closeAssignmentFulfill,
  closeAssignmentSuccess,
  continueAssignmentFailure,
  continueAssignmentFulfill,
  continueAssignmentRequest,
  continueAssignmentSuccess,
  continueSkippedAssignmentFailure,
  continueSkippedAssignmentFulfill,
  continueSkippedAssignmentRequest,
  deleteAssignmentFailure,
  deleteAssignmentFulfill,
  deleteAssignmentRequest,
  deleteAssignmentSuccess,
  fetchAssignmentsFailure,
  fetchAssignmentsFulfill,
  fetchAssignmentsRequest,
  fetchAssignmentsSuccess,
  fetchOwnAssignmentsSuccess,
  openAssignment,
  openAssignmentFailure,
  openAssignmentFulfill,
  openAssignmentSuccess,
  reviewAndContinueAssignment,
  reviewAndContinueAssignmentFulfill,
  reviewAndContinueAssignmentRequest,
  reviewSkippedEnvelopesFailure,
  reviewSkippedEnvelopesRequest,
  reviewSkippedEnvelopesSuccess,
  setLastAssignment,
  setShowSkippedEnvelopes,
  upsertAssignmentFailure,
  upsertAssignmentFulfill,
  upsertAssignmentRequest,
  upsertAssignmentSuccess,
} from 'actions';
import type { API, Assignment, ErrorObject, NormalizedResource } from 'types';

export type AssignmentState = {
  loading: string[];
  error: ErrorObject | null;
  items: NormalizedResource<Assignment>;
  envelopeAvailable: boolean;
  count: number;
  selectedAssignment: Assignment | null;
  lastAssignment: Assignment | null;
  showSkippedEnvelopes: boolean;
};

type AssignmentReducer<P = void> = (
  state: AssignmentState,
  payload: PayloadAction<P>
) => AssignmentState;

const defaultState: AssignmentState = {
  loading: [],
  error: null,
  items: {},
  envelopeAvailable: true,
  count: 0,
  selectedAssignment: null,
  lastAssignment: null,
  showSkippedEnvelopes: false,
};

const handleFetchOwnAssignmentsSuccess: AssignmentReducer<Assignment[]> = (state, { payload }) => {
  const normalizedAssignments: NormalizedResource<Assignment> = {};
  payload.forEach((assignment) => {
    normalizedAssignments[assignment.uuid] = assignment;
  });
  return {
    ...state,
    items: normalizedAssignments,
  };
};

const handleFetchAssignmentsRequest: AssignmentReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAssignmentsRequest.toString()],
});

const handleFetchAssignmentsFailure: AssignmentReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleFetchAssignmentsSuccess: AssignmentReducer<API.WrappedAPIResponse<Assignment>> = (
  state,
  { payload }
) => {
  const normalizedAssignments: NormalizedResource<Assignment> = {};
  payload.records.forEach((assignment) => {
    normalizedAssignments[assignment.uuid] = assignment;
  });
  return {
    ...state,
    items: normalizedAssignments,
    count: payload.count,
  };
};

const handleFetchAssignmentsFulfill: AssignmentReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchAssignmentsRequest.toString()),
});

const handleUpsertAssignmentRequest: AssignmentReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, upsertAssignmentRequest.toString()],
});

const handleUpsertAssignmentFailure: AssignmentReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleUpsertAssignmentSuccess: AssignmentReducer<Assignment> = (state, { payload }) => {
  const normalizedAssignments: NormalizedResource<Assignment> = {
    [payload.uuid]: payload,
  };

  return {
    ...state,
    items: { ...state.items, ...normalizedAssignments },
  };
};

const handleUpsertAssignmentFulfill: AssignmentReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== upsertAssignmentRequest.toString()),
});

const handleDeleteAssignmentRequest: AssignmentReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, deleteAssignmentRequest.toString()],
});

const handleDeleteAssignmentSuccess: AssignmentReducer<string> = (state, { payload }) => {
  const id = payload;
  const assignments = { ...state.items };
  delete assignments[id];

  return {
    ...state,
    items: assignments,
  };
};

const handleDeleteAssignmentFailure: AssignmentReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleDeleteAssignmentFulfill: AssignmentReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== deleteAssignmentRequest.toString()),
});

const handleContinueAssignmentRequest: AssignmentReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, continueAssignmentRequest.toString()],
});

const handleContinueAssignmentSuccess: AssignmentReducer<API.Assignments.GetNextEnvelope> = (
  state,
  action
) => {
  const { assignment, envelope, record_count: recordCount } = action.payload;
  const newRecordCount =
    recordCount != null ? recordCount : (assignment.saved_search?.record_count as number);

  return {
    ...state,
    items: {
      ...state.items,
      [assignment.uuid]: {
        ...state.items[assignment.uuid],
        ...assignment,
        ...(assignment.saved_search != null
          ? { saved_search: { ...assignment.saved_search, record_count: newRecordCount } }
          : {}),
      },
    },
    envelopeAvailable: envelope != null,
    error: null,
  };
};

const handleContinueAssignmentFailure: AssignmentReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleContinueAssignmentFulfill: AssignmentReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== continueAssignmentRequest.toString()),
});

const handleContinueSkippedAssignmentRequest: AssignmentReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, continueSkippedAssignmentRequest.toString()],
});

const handleContinueSkippedAssignmentFailure: AssignmentReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
});

const handleContinueSkippedAssignmentFulfill: AssignmentReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== continueSkippedAssignmentRequest.toString()),
});

const handleCloseAssignment: AssignmentReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, closeAssignment.toString()],
});

const handleCloseAssignmentSuccess: AssignmentReducer<Assignment> = (state, { payload }) => ({
  ...state,
  items: {
    ...state.items,
    [payload.uuid]: {
      ...state.items[payload.uuid],
      ...payload,
    },
  },
});

const handleCloseAssignmentFailure: AssignmentReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleCloseAssignmentFulfill: AssignmentReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== closeAssignment.toString()),
});

const handleOpenAssignment: AssignmentReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, openAssignment.toString()],
});

const handleOpenAssignmentSuccess: AssignmentReducer<Assignment> = (state, { payload }) => ({
  ...state,
  items: {
    ...state.items,
    [payload.uuid]: {
      ...state.items[payload.uuid],
      ...payload,
    },
  },
});

const handleOpenAssignmentFailure: AssignmentReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleOpenAssignmentFulfill: AssignmentReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== openAssignment.toString()),
});

const handleReviewAndContinueAssignmentRequest: AssignmentReducer = (state) => ({
  ...state,
  loading: [...state.loading, reviewAndContinueAssignment.toString()],
});

const handleReviewAndContinueAssignmentFulfill: AssignmentReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== reviewAndContinueAssignment.toString()),
});

const handleReviewSkippedEnvelopesRequest: AssignmentReducer = (state) => ({
  ...state,
  loading: [...state.loading, reviewSkippedEnvelopesRequest.toString()],
  error: null,
});

const handleReviewSkippedEnvelopesSuccess: AssignmentReducer<
  ReviewSkippedEnvelopesSuccessPayload
> = (state, { payload }) => ({
  ...state,
  loading: state.loading.filter((s) => s !== reviewSkippedEnvelopesRequest.toString()),
  lastAssignment: null,
  items: {
    ...state.items,
    [payload.assignment.uuid]: {
      ...state.items[payload.assignment.uuid],
      ...payload.assignment,
      saved_search: payload.saved_search,
    },
  },
});

const handleReviewSkippedEnvelopesFailure: AssignmentReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== reviewSkippedEnvelopesRequest.toString()),
  lastAssignment: null,
});

const handleLastAssignment: AssignmentReducer<Assignment> = (state, { payload }) => ({
  ...state,
  lastAssignment: payload,
});

const handleSetShowSkippedEnvelopes: AssignmentReducer<boolean> = (state, { payload }) => ({
  ...state,
  showSkippedEnvelopes: payload,
});

const handlers = {
  [fetchAssignmentsRequest.toString()]: handleFetchAssignmentsRequest,
  [fetchOwnAssignmentsSuccess.toString()]: handleFetchOwnAssignmentsSuccess,
  [fetchAssignmentsSuccess.toString()]: handleFetchAssignmentsSuccess,
  [fetchAssignmentsFailure.toString()]: handleFetchAssignmentsFailure,
  [fetchAssignmentsFulfill.toString()]: handleFetchAssignmentsFulfill,
  [upsertAssignmentRequest.toString()]: handleUpsertAssignmentRequest,
  [upsertAssignmentSuccess.toString()]: handleUpsertAssignmentSuccess,
  [upsertAssignmentFailure.toString()]: handleUpsertAssignmentFailure,
  [upsertAssignmentFulfill.toString()]: handleUpsertAssignmentFulfill,
  [deleteAssignmentRequest.toString()]: handleDeleteAssignmentRequest,
  [deleteAssignmentSuccess.toString()]: handleDeleteAssignmentSuccess,
  [deleteAssignmentFailure.toString()]: handleDeleteAssignmentFailure,
  [deleteAssignmentFulfill.toString()]: handleDeleteAssignmentFulfill,
  [continueAssignmentRequest.toString()]: handleContinueAssignmentRequest,
  [continueAssignmentSuccess.toString()]: handleContinueAssignmentSuccess,
  [continueAssignmentFailure.toString()]: handleContinueAssignmentFailure,
  [continueAssignmentFulfill.toString()]: handleContinueAssignmentFulfill,
  [closeAssignment.toString()]: handleCloseAssignment,
  [closeAssignmentSuccess.toString()]: handleCloseAssignmentSuccess,
  [closeAssignmentFailure.toString()]: handleCloseAssignmentFailure,
  [closeAssignmentFulfill.toString()]: handleCloseAssignmentFulfill,
  [openAssignment.toString()]: handleOpenAssignment,
  [openAssignmentSuccess.toString()]: handleOpenAssignmentSuccess,
  [openAssignmentFailure.toString()]: handleOpenAssignmentFailure,
  [openAssignmentFulfill.toString()]: handleOpenAssignmentFulfill,
  [reviewAndContinueAssignmentRequest.toString()]: handleReviewAndContinueAssignmentRequest,
  [reviewAndContinueAssignmentFulfill.toString()]: handleReviewAndContinueAssignmentFulfill,
  [reviewSkippedEnvelopesRequest.toString()]: handleReviewSkippedEnvelopesRequest,
  [reviewSkippedEnvelopesSuccess.toString()]: handleReviewSkippedEnvelopesSuccess,
  [reviewSkippedEnvelopesFailure.toString()]: handleReviewSkippedEnvelopesFailure,
  [continueSkippedAssignmentRequest.toString()]: handleContinueSkippedAssignmentRequest,
  [continueSkippedAssignmentFailure.toString()]: handleContinueSkippedAssignmentFailure,
  [continueSkippedAssignmentFulfill.toString()]: handleContinueSkippedAssignmentFulfill,
  [setLastAssignment.toString()]: handleLastAssignment,
  [setShowSkippedEnvelopes.toString()]: handleSetShowSkippedEnvelopes,
};

const AssignmentesReducer = createReducer(defaultState, handlers);

export default AssignmentesReducer;
