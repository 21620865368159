/* eslint-disable max-lines */
import { Role, Team, User } from '@litlingo/client';
import { addUsers, selectUsers } from 'actions/userPermissionsPage';
import BadgeRedesign from 'components/Badge/BadgeRedesign';
import Modal from 'components/Modal';
import fileExtensionIconParser from 'constants/fileIcons';
import useAllTeams from 'hooks/teams/useAllTeams';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDomain } from 'selectors/auth';
import loadAndParseXLSXToJSON, { parseUsersFromXLSXToJSON } from 'utils/files';
import { useHistory } from 'utils/urls';
import { v4 } from 'uuid';

type ComponentProps = {
  setIsCreateNewUserOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddUserModal: React.FC<ComponentProps> = ({ setIsCreateNewUserOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const domain = useSelector(getCustomerDomain);

  const [usersToAdd, setUsersToAdd] = useState<User[]>([]);

  const { data: teams } = useAllTeams();
  const { register, getValues, setValue, handleSubmit } = useForm<User>();

  const onSubmit = async (): Promise<void> => {
    const { name, email } = getValues();
    setUsersToAdd((users) => [
      ...users,
      {
        name,
        email,
        uuid: v4(),
        roles: [] as Role[],
        sync_emails: false,
        teams: [] as Team[],
        privacy_level: 'standard',
      } as User,
    ]);

    setValue('name', '');
    setValue('email', '');
  };

  const loadFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files) return;

    loadAndParseXLSXToJSON(e.target.files[0], (data) => {
      const users = parseUsersFromXLSXToJSON(data, teams?.records || []);

      dispatch(addUsers(users));
      dispatch(selectUsers(users));
      history.pushLookup({ routeName: 'users-permissions', customerDomain: domain });
    });
  };

  const handleSaveButton = (): void => {
    dispatch(addUsers(usersToAdd));
    dispatch(selectUsers(usersToAdd));

    history.pushLookup({ routeName: 'users-permissions', customerDomain: domain });
  };

  const renderAddUser = (
    <div className="flex flex-row">
      <div className="w-1/2 mt-6 mb-4 pb-8 pr-6 flex flex-col gap-1 overflow-hidden border-r border-litlingo-gray-1">
        <div className="flex flex-col gap-3">
          <span className="text-body">
            Download the starter file, fill in the rows and import your users.
          </span>
          <div className="flex flex-col gap-2.5">
            <div className="flex flex-row items-center gap-2">
              <span className="w-5 h-5">{fileExtensionIconParser('xlsx')}</span>
              <span className="text-small underline">UserListStarterFile.xlsx</span>
            </div>
            <label
              htmlFor="file-input"
              className="button button--secondary self-end h-8 cursor-pointer"
            >
              <span className="font-bold">Import Users</span>
              <input id="file-input" type="file" className="hidden" onChange={loadFile} />
            </label>
          </div>
          <div className="mt-7 flex flex-col gap-2.5">
            <span className="font-bold">OR add users manually</span>
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-1">
                <label htmlFor="name" className="text-body">
                  Name <span className="text-litlingo-alert">*</span>
                  <div className="rounded-md">
                    <input
                      id="name"
                      name="name"
                      ref={register({ required: true })}
                      className="h-9 form-input w-full transition duration-150 ease-in-out"
                    />
                  </div>
                </label>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="email" className="text-body">
                  Email <span className="text-litlingo-alert">*</span>
                  <div className="rounded-md">
                    <input
                      id="email"
                      name="email"
                      ref={register({ required: true })}
                      className="h-9 form-input w-full transition duration-150 ease-in-out"
                    />
                  </div>
                </label>
              </div>
            </div>
            <button
              type="button"
              className="w-30 mt-3.5 flex justify-center button button--secondary self-end h-8"
              onClick={handleSubmit(onSubmit)}
            >
              <span className="font-bold">Add</span>
            </button>
          </div>
        </div>
      </div>
      <div className="w-1/2 ml-6 mt-6 flex flex-col gap-4">
        <span className="font-bold">New Users</span>
        <div className="flex flex-row gap-1 items-start flex-wrap text-small content-start">
          {usersToAdd.map((user) => (
            <BadgeRedesign
              key={user.uuid}
              title={user.name || ''}
              deleteAction={(): void => {
                setUsersToAdd((sUsers) => sUsers.filter((u) => u.uuid !== user.uuid));
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      body={renderAddUser}
      title="Add Your Users"
      okButton
      okButtonText="Save"
      okButtonOnClick={handleSaveButton}
      okButtonDisabled={usersToAdd.length === 0}
      toggleShowModal={(): void => setIsCreateNewUserOpen(false)}
      style={{ width: '50rem' }}
    />
  );
};

export default AddUserModal;
