import { fetchAllCustomers } from 'actions';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { UUID } from 'types';

type ComponentProps = {
  handleClick: (uuid: UUID, name: string) => void;
};

const CustomerList: React.FC<ComponentProps> = ({ handleClick }) => {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.auth.customerList);

  const sortedCustomers = [...customers];
  sortedCustomers.sort((a, b) =>
    a.name && b.name && a.name?.toLowerCase() > b.name.toLowerCase() ? 1 : -1
  );

  useEffect(() => {
    dispatch(fetchAllCustomers({ limit: -1 }));
  }, [dispatch]);

  return (
    <div style={{ maxHeight: '500px' }} className="overflow-y-scroll">
      <table className="min-w-full">
        <thead>
          <tr />
        </thead>
        <tbody className="bg-white text-left">
          {sortedCustomers &&
            sortedCustomers.map((customer) => (
              <tr
                key={customer.uuid}
                onClick={(): void => handleClick(customer.uuid, customer.name || customer.uuid)}
                className="border-t border-gray-200 hover:bg-gray-100 table-row"
              >
                <td className="px-6 py-4 border-b border-gray-200">
                  <div className="flex items-center">
                    <div>
                      <div className="text">{customer.name}</div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomerList;
