import LogItem from 'components/LogsList/LogItem';
import React from 'react';
import { RenderedAction } from 'types';

type EnvelopeLogsListProps = {
  logs: RenderedAction[];
};

const EnvelopeLogsList: React.FC<EnvelopeLogsListProps> = (props) => {
  const { logs } = props;

  return (
    <div>
      {logs.map((log, index) => (
        <LogItem key={log.uuid} log={log} index={index} length={logs.length} />
      ))}
    </div>
  );
};

export default EnvelopeLogsList;
