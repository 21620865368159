import useRequest from 'hooks/client/useRequest';
import { BaseResponse, RequestParamsType } from 'hooks/types/client';

const useFetchTestCases = (
  broadSearch: string,
  ruleId?: string
): BaseResponse<'testCases.list'> => {
  const params: RequestParamsType = {
    queryParams: { broad_search: broadSearch, rule_uuids: ruleId || [] },
  };
  const response = useRequest('testCases.list', params);
  return response;
};

export default useFetchTestCases;
