import { cleanChangedNodes, updateRule } from 'actions';
import React from 'react';
import { useDispatch } from 'react-redux';
import type { MRule } from 'types';

type ComponentProps = {
  rule: MRule;
};

const RuleManagerSaveButton: React.FC<ComponentProps> = ({ rule }) => {
  const dispatch = useDispatch();

  const handleSaveClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    evt.stopPropagation();
    dispatch(updateRule({ rule }));
    dispatch(cleanChangedNodes());
  };

  return (
    <button
      type="button"
      onClick={(evt): void => handleSaveClick(evt)}
      className="button button--primary h-8"
      data-testid="rule-mananger-save"
      id="save-rule-button"
    >
      Save
    </button>
  );
};

export default RuleManagerSaveButton;
