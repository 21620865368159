/* eslint-disable react/jsx-curly-newline */
import { deleteDashboard, upsertDashboard } from 'actions/dashboard';
import Card from 'components/Card';
import CustomerAllowedDomainsThreeDots from 'components/Customer/CustomerAllowedDomainsThreeDots';
import LinkLookup from 'components/LinkLookup';
import Modal from 'components/Modal';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import { LEFT_ARROW_ICON } from 'constants/envelopeIcons';
import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import { useDispatch } from 'react-redux';
import { getAvailableRoles } from 'selectors/auth';
import { getLoadedDashboards } from 'selectors/dashboard';
import { useSelector } from 'store';
import { Dashboard, DashboardConfig } from 'types';
import CustomerLeaveConfirmModal from './CustomerLeaveConfirmModal';

const Dashboards: React.FC = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);

  const [unsaved, setUnsaved] = useState(false);
  const [isLeaveModalOpen, setLeaveModalOpen] = useState(false);

  const [selectedDashboard, setSelectedDashboard] = useState<Dashboard | null>(null);

  const loadedDashboards = useSelector(getLoadedDashboards);
  const roles: string[] = useSelector(getAvailableRoles);

  const handleSave = (dashboard: Dashboard): void => {
    dispatch(upsertDashboard(dashboard));
    setUnsaved(true);
    setModalOpen(false);
  };

  const handleDelete = (): void => {
    if (selectedDashboard) dispatch(deleteDashboard({ id: selectedDashboard.uuid }));
    setUnsaved(true);
  };

  const header = (
    <div>
      <div className="flex justify-between">
        <div className="pt-5 pb-12 flex items-center">
          <LinkLookup
            key="goback"
            data-testid="customer-goback-button"
            routeName="customer"
            className="mx-6 focus:outline-none"
            type="button"
            onClick={(e): void => {
              if (unsaved) {
                e.preventDefault();
                setLeaveModalOpen(true);
              }
            }}
          >
            {LEFT_ARROW_ICON}
          </LinkLookup>
          <span className="text text--large text--bold">Dashboards</span>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <p className="text-sm font-bold mx-6">Dashboards</p>
        <button
          type="button"
          data-testid="add-domain-button"
          className="mb-3 mr-5 litlingo-test-case-button focus:outline-none litlingo-test-case-button-outline litlingo-test-button-rounded bg-litlingo-primary"
          onClick={(): void => {
            setSelectedDashboard({} as Dashboard);
            setModalOpen(true);
          }}
        >
          Add
        </button>
      </div>
    </div>
  );

  const renderDomain = (dashboard: Dashboard): JSX.Element => (
    <div key={dashboard.name} className="my-4 mx-5 flex justify-between">
      <span className="flex items-center text-sm">{dashboard.name}</span>
      <CustomerAllowedDomainsThreeDots
        setModalOpen={setModalOpen}
        handleRemoveDomain={handleDelete}
        idx={0}
        setCurrentIdx={(): void => {
          setSelectedDashboard(dashboard);
        }}
      />
    </div>
  );

  const renderDomains = loadedDashboards.map((dashboard) => renderDomain(dashboard));

  const handleRolesChange = (value: string): void => {
    if (!selectedDashboard) return;
    if (!selectedDashboard.allowed_roles) {
      setSelectedDashboard({
        ...selectedDashboard,
        allowed_roles: [value],
      });
      return;
    }

    const idx = selectedDashboard.allowed_roles.indexOf(value);

    if (idx === -1) {
      setSelectedDashboard({
        ...selectedDashboard,
        allowed_roles: [...selectedDashboard.allowed_roles, value],
      });
    } else {
      setSelectedDashboard({
        ...(selectedDashboard as Dashboard),
        allowed_roles: [
          ...selectedDashboard.allowed_roles.slice(0, idx),
          ...selectedDashboard.allowed_roles.slice(idx + 1),
        ],
      });
    }
  };

  return (
    <div className="litlingo-gray-bg-color-4">
      <Navbar />

      <div
        className="flex flex-row overflow-auto"
        style={{
          minHeight: 'calc(100vh - 4rem)',
          height: 'calc(100vh - 4rem)',
        }}
      >
        <SettingSidebar />
        <div className="mt-0 mx-auto min-w-2xl w-8/12 max-w-6xl text-left">
          <Card items={[header, renderDomains, <div key="div" />]} />
          {selectedDashboard && isModalOpen && (
            <Modal
              body={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <>
                  <div className="mt-4 max-h-128 overflow-auto ">
                    <ReactJson
                      // eslint-disable-next-line @typescript-eslint/ban-types
                      src={selectedDashboard.config as object}
                      theme="chalk"
                      onEdit={(event): void =>
                        setSelectedDashboard({
                          ...selectedDashboard,
                          config: event.updated_src as DashboardConfig,
                        })
                      }
                      onDelete={(event): void =>
                        setSelectedDashboard({
                          ...selectedDashboard,
                          config: event.updated_src as DashboardConfig,
                        })
                      }
                      onAdd={(event): void =>
                        setSelectedDashboard({
                          ...selectedDashboard,
                          config: event.updated_src as DashboardConfig,
                        })
                      }
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '25px',
                      }}
                      collapsed
                    />
                  </div>
                  <input
                    id="name"
                    data-testid="input-name"
                    onChange={(event): void =>
                      setSelectedDashboard({ ...selectedDashboard, name: event.target.value })
                    }
                    value={selectedDashboard?.name}
                    className="litlingo-customer-card-input form-input my-4 w-full"
                  />
                  <MultiSelectDropdown
                    onChange={handleRolesChange}
                    title="Roles"
                    options={roles.map((r) => ({ value: r, label: r }))}
                    selectedItems={selectedDashboard.allowed_roles}
                    dataTestid="roles-dropdown"
                  />
                </>
              }
              title="Add/edit dashboard"
              okButton
              okButtonText="Save"
              okButtonOnClick={(): void => {
                handleSave(selectedDashboard);
                setUnsaved(true);
                setModalOpen(false);
              }}
              toggleShowModal={(): void => setModalOpen(!isModalOpen)}
              style={{
                width: '650px',
              }}
            />
          )}
          {isLeaveModalOpen && (
            <CustomerLeaveConfirmModal
              toggleShowModal={(): void => setLeaveModalOpen(!isLeaveModalOpen)}
              setUnsaved={setUnsaved}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboards;
