import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import type {
  DeleteRelationshipPayload,
  PatchItemsToRelationshipPayload,
} from 'actions/relationship';
import type { AnnotatorRelationship, NormalizedResource } from 'types';
import {
  deleteRelationship,
  fetchRelationshipsSuccess,
  patchItemsToRelationship,
  saveRelationshipsSuccess,
  upsertRelationship,
} from '../actions';

type RelationshipState = NormalizedResource<AnnotatorRelationship>;

type RelationshipReducer<P = void> = (
  state: NormalizedResource<AnnotatorRelationship>,
  payload: PayloadAction<P>
) => NormalizedResource<AnnotatorRelationship>;

const defaultState: RelationshipState = {};

const handleUpsertRelationship: RelationshipReducer<AnnotatorRelationship> = (
  state,
  { payload }
) => {
  const { id } = payload;
  const relat = state[id] ? state[id] : {};
  return {
    ...state,
    [id]: {
      ...relat,
      ...payload,
    },
  };
};

const handlePatchItemsToRelationship: RelationshipReducer<PatchItemsToRelationshipPayload> = (
  state,
  { payload }
) => {
  const { relationshipId } = payload;
  return {
    ...state,
    [relationshipId]: {
      ...state[relationshipId],
      ...payload.payload,
    },
  };
};

const handleFetchRelationshipSuccess: RelationshipReducer<
  NormalizedResource<AnnotatorRelationship>
> = (state, { payload }) => ({
  ...state,
  ...payload,
});

const handleDeleteRelationship: RelationshipReducer<DeleteRelationshipPayload> = (
  state,
  { payload }
) => {
  const { relationshipId } = payload;
  const relationships = { ...state };
  delete relationships[relationshipId];

  return {
    ...relationships,
  };
};

const handleSaveRelationshipSuccess: RelationshipReducer<
  NormalizedResource<AnnotatorRelationship>
> = (state, { payload }) => ({
  ...state,
  ...payload,
});

const handlers = {
  [upsertRelationship.toString()]: handleUpsertRelationship,
  [patchItemsToRelationship.toString()]: handlePatchItemsToRelationship,
  [fetchRelationshipsSuccess.toString()]: handleFetchRelationshipSuccess,
  [deleteRelationship.toString()]: handleDeleteRelationship,
  [saveRelationshipsSuccess.toString()]: handleSaveRelationshipSuccess,
};

const relationshipReducer = createReducer(defaultState, handlers);

export default relationshipReducer;
