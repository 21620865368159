import { fetchOutcomes, updateOutcome } from 'actions';
import LinkLookup from 'components/LinkLookup';
import { debounce } from 'lodash';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getOutcomes } from 'selectors/outcomes';
import type { Outcome, UUID } from 'types';

type RouteParams = {
  campaignId: UUID;
  ruleId: UUID;
};

const OutcomeManagerSidebar: React.FC = () => {
  const dispatch = useDispatch();
  const { ruleId, campaignId } = useParams<RouteParams>();
  const { register, getValues } = useForm();
  const outcomes = useSelector(getOutcomes);

  useEffect(() => {
    dispatch(fetchOutcomes({}));
  }, [dispatch]);

  const handleAddOutcome = (outcomeId: UUID): void => {
    dispatch(updateOutcome({ ruleId, campaignId, outcomeId }));
  };

  const handleSearchChange = debounce(() => {
    const value = getValues('search');
    dispatch(fetchOutcomes({ searchTerm: { name: value } }));
  }, 300);

  const renderOutcome = (outcome: Outcome): JSX.Element => {
    const { name, type, uuid } = outcome;
    return (
      <div key={uuid} className="flex flex-row py-4 border-t-2">
        <div className="w-full self-center">
          <div className="flex">
            <h3 className="text-lg leading-5 text-gray-900">{name}</h3>
            <LinkLookup
              routeName="rule-campaign-outcome-id"
              routeParams={{ ruleId, campaignId, outcomeId: uuid }}
              type="button"
              className="ml-4 inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
            >
              <svg
                fill="none"
                className="h-4 w-4 text-gray-700 hover:text-gray-900 text-xs"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
              </svg>
            </LinkLookup>
          </div>
          {type && <p className="mt-1 text text--lighter-4 self-center">{type}</p>}
        </div>
        <div className="self-center">
          <button
            onClick={(): void => handleAddOutcome(uuid)}
            type="button"
            className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
          >
            <svg
              className="h-6 w-6 text-gray-700 hover:text-gray-900"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 110-20 10 10 0 010 20zm0-2a8 8 0 100-16 8 8 0 000 16z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  };

  const buttonMainClass =
    'outline-none font-bold inline-flex items-center px-2 h-full border bg-gray-800 border-gray-800 text-xs leading-none font-medium  text-white bg-white hover:text-gray-500 active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150';
  return (
    <div className="border-solid border border-gray-500 bg-gray-200 overflow-hidden">
      <div className="mr-4 mt-6 h-6 flex float-right">
        <Link to="outcome/new" type="button" className={`${buttonMainClass} rounded-md`}>
          Create New
        </Link>
      </div>
      <div className="ml-4 mt-6 flex text-left">
        <p className="text text--large text--bold">Outcomes</p>
      </div>
      <div>
        <div className="mt-1 relative rounded-md shadow-sm py-4 mx-4">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg className="h-4 w-4 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
              <path d="M12.9 14.32a8 8 0 111.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 108 2a6 6 0 000 12z" />
            </svg>
          </div>
          <input
            id="search"
            name="search"
            className="form-input block w-full pl-10 sm:text-sm sm:leading-5"
            placeholder="Search"
            onChange={handleSearchChange}
            ref={register()}
          />
        </div>
      </div>
      <div className="flex flex-row p-4 text-left w-full min-h-wo-navbar">
        <div className="my-4 w-full">{outcomes.map((item) => renderOutcome(item))}</div>
      </div>
    </div>
  );
};

export default OutcomeManagerSidebar;
