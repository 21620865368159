import { fetchWorkforceRisk } from 'actions/analytics';
import ProgressBar from 'components/ProgressBar';
import withWidget from 'decorators/widget';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getWorkforceRisk, getWorkforceRiskLoading } from 'selectors/analytics';
import { useSelector } from 'store';
import { useTimeRange } from 'utils/timeRanges';

const WorkforceRisk: React.FC = () => {
  const dispatch = useDispatch();
  const workforceRisk = useSelector(getWorkforceRisk);
  const loading = useSelector(getWorkforceRiskLoading);

  const range = useTimeRange();

  useEffect(() => {
    dispatch(fetchWorkforceRisk(range));
  }, [dispatch, range]);

  if (workforceRisk == null || loading) {
    return <WidgetLoading size="8" />;
  }

  const percentage = workforceRisk.ratio * 100;
  const rounded = (Math.round(percentage) * 100) / 100;

  return (
    <div>
      <div className="">
        <span className="text text--left text--bold text-2xl">{`${rounded}%`}</span>
      </div>
      <ProgressBar
        completed={percentage}
        bgcolor="bg-litlingo-background"
        bgcolor2="bg-litlingo-old-blue"
        height="h-workforce-risk-bar"
      />
    </div>
  );
};

const Icon: React.FC = () => {
  const workforceRisk = useSelector(getWorkforceRisk);
  const loading = useSelector(getWorkforceRiskLoading);

  return (
    <div className="flex flex-row items-center">
      <svg className="h-6 w-6 fill-current text" viewBox="0 0 20 20">
        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
      </svg>
      {!loading && workforceRisk != null && (
        <div className="ml-2 text text-2xl font-semibold line-height-100">
          {workforceRisk.total}
        </div>
      )}

      {/* TODO: add count on icon */}
    </div>
  );
};

export default withWidget({
  title: 'Workforce Risk',
  subtitle: 'Percentage of workers that have sent an out of policy message',
  icon: Icon,
  minWidth: 6,
  minHeight: 6,
})(WorkforceRisk);
