import Tooltip, { TooltipPosition } from 'components/Tooltip';
import React from 'react';
import { DashboardFilter, WidgetDecoratorFilterConfig } from 'types';
import WidgetConfigFilter from './WidgetConfigFilter';
import WidgetDecoratorFilter from './WidgetDecoratorFilter';

type WidgetHeaderProps = {
  widgetId: string;
  title?: string;
  subtitle?: string;
  icon?: JSX.Element | React.FC;
  iconTooltip?: string;
  iconContent?: string;
  decoratorFilters?: WidgetDecoratorFilterConfig[];
  configFilters?: DashboardFilter[];
  additionalFilterComponent?: JSX.Element | null;
  customSubtitle?: boolean;
  titleClassName?: string;
};

const WidgetHeader: React.FC<WidgetHeaderProps> = (props) => {
  const {
    widgetId,
    title,
    subtitle,
    icon,
    iconTooltip,
    iconContent,
    decoratorFilters,
    configFilters,
    additionalFilterComponent,
    customSubtitle,
    titleClassName,
  } = props;

  if (!title && !subtitle && !icon && decoratorFilters == null && configFilters == null) {
    return null;
  }

  let iconEl: JSX.Element | React.FC | null | undefined = icon;
  if (icon != null && typeof icon === 'function') {
    iconEl = React.createElement(icon);
  }

  const titleClass = titleClassName || 'text-2xl';

  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        {title && (
          <div className={`text text--center text--title text--bold line-height-100 ${titleClass}`}>
            {title}
          </div>
        )}
        {iconTooltip && (
          <Tooltip tooltip={iconTooltip} tooltipPosition={TooltipPosition.left}>
            {iconEl}
          </Tooltip>
        )}
        {iconContent && (
          <Tooltip content={iconContent} tooltipPosition={TooltipPosition.left}>
            {iconEl}
          </Tooltip>
        )}
        {(decoratorFilters != null ||
          configFilters != null ||
          additionalFilterComponent != null) && (
          <div className="flex flex-row">
            {decoratorFilters &&
              decoratorFilters.map((filter) => (
                <WidgetDecoratorFilter
                  key={`${widgetId}__${filter.prefix}__${filter.name}`}
                  prefix={filter.prefix}
                  name={filter.name}
                  allLabel={filter.allLabel}
                  fetchDataAction={filter.fetchDataAction}
                  getDataSelector={filter.getDataSelector}
                />
              ))}

            {configFilters &&
              configFilters.map((filter) => (
                <WidgetConfigFilter
                  key={`${widgetId}__${filter.dimension}`}
                  widgetId={widgetId}
                  dimension={filter.dimension}
                  options={filter.options}
                  allLabel={filter.all_label}
                  defaultValue={filter.default_value}
                />
              ))}
            {additionalFilterComponent && <div className="ml-2">{additionalFilterComponent}</div>}
          </div>
        )}
      </div>

      {subtitle && !customSubtitle && (
        <p className="text text--label pt-1 text--left">{subtitle}</p>
      )}
    </div>
  );
};

export default WidgetHeader;
