/* eslint-disable react/jsx-props-no-spreading */

import { ResponsiveBar } from '@nivo/bar';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import React from 'react';
import { DefaultWidgetComponentProps } from 'types';
import { buildDateHistogramDataFromMetrics } from 'utils/dashboard';

const SentimentWidget: React.FC<DefaultWidgetComponentProps> = (props) => {
  const { widgetData, queryData } = props;

  if (widgetData == null || queryData == null) {
    return <WidgetEmptyState />;
  }

  const processedData = buildDateHistogramDataFromMetrics(widgetData, queryData);

  return (
    <div className="h-dashboard-sentiment">
      {/* @ts-ignore */}
      <ResponsiveBar
        data={processedData.data}
        keys={processedData.keys}
        indexBy={processedData.indexBy}
        margin={{ top: 50, right: 10, bottom: 50, left: 50 }}
        enableLabel={false}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        theme={{
          tooltip: {
            container: {
              background: 'none',
              boxShadow: 'none',
            },
          },
        }}
        tooltip={(d): JSX.Element => (
          <div className="bg-litlingo-off-white p-1 rounded flex items-center shadow-md">
            <div
              style={{
                background: d.color,
              }}
              className="h-3 w-3 mr-2"
            />
            <span className="text">
              {processedData.labelsMap[d.id as number]} - {d.indexValue}: <b>{d.value}</b>
            </span>
          </div>
        )}
        animate
      />
    </div>
  );
};

export default withWidget({
  title: 'Sentiment',
  subtitle: ' ',
  minWidth: 4,
  minHeight: 8,
})(SentimentWidget);
