import { deleteCampaignRule, removeRuleOutcome } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import Permissions from 'components/Permissions';
import PermissionsRowLink from 'components/PermissionsRowLink';
import daysAgo from 'components/utils/daysAgo';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getOutcomesFromRule, getRuleOutcomes } from 'selectors/outcomes';
import { getRule, getRuleTestCaseSummary } from 'selectors/rules';
import { useSelector } from 'store';
import type { UUID } from 'types';

type ComponentProps = {
  ruleId: UUID;
  campaignId: UUID;
};

const RuleListItem: React.FC<ComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { ruleId, campaignId } = props;
  const [isDeletingRule, setIsDeletingRule] = useState(false);

  const rule = useSelector(getRule(ruleId));

  const outcomes = useSelector((state) => getOutcomesFromRule(state, ruleId)) || [];
  const ruleOutcomes = useSelector((state) => getRuleOutcomes(state, ruleId));
  const summaryTestCases = useSelector((state) => getRuleTestCaseSummary(state, ruleId));

  const { passing, failing, not_run: notRun } = summaryTestCases;

  const totalTests = passing + failing + notRun;

  const totalGood = Math.round((100 * passing) / totalTests);

  const routeName = campaignId ? 'rule-manager-campaign' : 'rule-manager';
  const routeParams = campaignId
    ? { ruleId: rule?.uuid || ruleId, campaignId }
    : { ruleId: rule?.uuid || ruleId };

  const handleDelete = (): void => {
    dispatch(removeRuleOutcome({ outcomeId: outcomes[0].uuid, ruleId, campaignId }));
    dispatch(deleteCampaignRule({ ruleId, campaignId }));
    setIsDeletingRule(false);
  };

  const handleClickOnDeleteIcon = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    setIsDeletingRule(true);
  };

  const toggleConfirmModal = (): void => setIsDeletingRule(false);

  return (
    <>
      <PermissionsRowLink
        className={`${
          rule.unSaved ? 'opacity-25' : ''
        } table-row align-middle  border-t border-gray-200 hover:bg-gray-100`}
        action="rules.retrieve"
        routeName={routeName}
        routeParams={routeParams}
        as="tr"
      >
        <td className="table-wrapper__new-td">
          <div className="flex items-center h-8 pl-8">
            <div className="overflow-hidden">
              <div className="text-base font-bold truncate">{`${rule.name} (v${rule.version})`}</div>
            </div>
          </div>
        </td>
        <td className={`table-wrapper__new-td ${campaignId ? ' pl-2' : ''}`}>
          <div className="flex items-center">
            <div className="text-body">{rule.updated_at != null && daysAgo(rule.updated_at)}</div>
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="flex items-center">
            <div className="text-body">{rule.updated_by?.name}</div>
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="flex items-center">
            <div className="text-body">{totalTests || '-'}</div>
          </div>
        </td>

        <Permissions action="test_cases.viewMetrics">
          <td className="table-wrapper__new-td">
            {totalGood ? (
              <div
                className={` text-base ${
                  totalGood >= 66 ? 'text-litlingo-primary-120' : 'text-litlingo-alert'
                }`}
              >{` ${totalGood}% `}</div>
            ) : (
              <div className="text-base">-</div>
            )}
          </td>
        </Permissions>
        {campaignId ? (
          <>
            <td className="table-wrapper__new-td">
              <ul>
                {ruleOutcomes.map((item) => {
                  const activeInbound =
                    item.config && item.config.active_inbound ? 'Active Inbound' : '';
                  return (
                    <li key={item.uuid}>
                      <p className="text-body">
                        {`${item.outcome?.name} (${item.outcome?.type})  `}
                        <span className="text-blue-900">{activeInbound}</span>
                      </p>
                    </li>
                  );
                })}
              </ul>
            </td>
            <td className="table-wrapper__new-td">
              <Permissions action="rules.delete">
                <div className="h-full w-full flex items-center justify-center">
                  {outcomes.length === 1 && (
                    <button
                      type="button"
                      data-testid="rule-item-delete"
                      onClick={handleClickOnDeleteIcon}
                    >
                      <svg
                        className="flex-shrink-0 h-5 w-5 text-gray-400  hover:text-black"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  )}
                </div>
              </Permissions>
            </td>
          </>
        ) : (
          <></>
        )}
      </PermissionsRowLink>
      {isDeletingRule && (
        <ConfirmModal
          text="Are you sure you want to delete this model?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDelete}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </>
  );
};

export default RuleListItem;
