import { fetchAllCampaigns } from 'actions';
import useClickOutside from 'components/utils/useClickOutside';
import { CHEVRON_DOWN, CHEVRON_UP } from 'constants/filterIcons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignsList } from 'selectors/campaigns';
import 'styles/labelsStyle.css';
import type { Campaign } from 'types';

type CampaignSelectProps = {
  selectCampaign: (campaign: Campaign) => void;
  disabled?: boolean;
  className?: string;
  selectedCampaigns?: Campaign[];
};

const CampaignSelect: React.FC<CampaignSelectProps> = (props) => {
  const { selectCampaign, disabled, selectedCampaigns, className = '' } = props;

  const dispatch = useDispatch();
  const campaigns = useSelector(getCampaignsList);
  const [isOpen, setIsOpen] = useState(false);
  const [filtered, setFiltered] = useState<Campaign[]>([]);

  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  useEffect(() => {
    dispatch(fetchAllCampaigns);
  }, [dispatch]);

  const handleButtonClick = (): void => {
    setFiltered(campaigns);
    setIsOpen(!isOpen);
  };

  const handleInputChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    campaign: Campaign
  ): void => {
    setIsOpen(false);
    selectCampaign(campaign);
    event.stopPropagation();
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e?.target.value.toLowerCase();

    setFiltered(
      campaigns.filter((item: Campaign) => item.name?.toLowerCase().includes(value || ''))
    );
  };

  return (
    <div ref={wrapperRef} className={`relative inline-block w-48 text-left ${className || ''}`}>
      <div className="rounded-md shadow-sm">
        <button
          type="button"
          disabled={disabled}
          className={`flex flex-row justify-between items-center w-full rounded-md border px-4 py-2 text text--lighter-4 focus:outline-none focus:border-litlingo-success focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 ${
            disabled ? 'bg-litlingo-gray-50 text-gray-500' : 'bg-white hover:text-gray-500'
          }`}
          id="options-menu"
          data-testid="campaign-select-button"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={handleButtonClick}
        >
          <span>Select a campaign</span>
          <span> {isOpen ? CHEVRON_UP : CHEVRON_DOWN}</span>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-left absolute left-0 mt-2 w-64 rounded-md shadow-lg z-40">
          <div className="rounded-md bg-white shadow-xs">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="m-2 relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg className="h-4 w-4 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M12.9 14.32a8 8 0 111.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 108 2a6 6 0 000 12z" />
                  </svg>
                </div>
                <input
                  id="search"
                  name="search"
                  data-testid="resource-search"
                  className="form-input block w-full pl-10 sm:text-sm sm:leading-5 shadow-sm"
                  onChange={handleSearchChange}
                  onBlur={(): void => setIsOpen(true)}
                  autoComplete="off"
                />
              </div>

              <div className="max-h-filter overflow-auto">
                <fieldset className="m-2 h-36" datta-testid="campaigns-list">
                  {filtered
                    .filter((u) => !selectedCampaigns?.some((s) => s.uuid === u.uuid))
                    .sort()
                    .slice(0, 25)
                    .map((campaign, idx) => (
                      <React.Fragment key={campaign.uuid}>
                        <div
                          className={idx !== 0 ? 'mt-2 mb-2' : 'mb-1'}
                          data-testid={`campaign-option-${idx}`}
                        >
                          <div
                            id={campaign.uuid}
                            role="button"
                            aria-hidden
                            className="relative flex items-center"
                            data-testid={`campaign-select-button-${idx}`}
                            onClick={(e): void => handleInputChange(e, campaign)}
                          >
                            <div className="text-sm leading-5 flex flex-col items-start justify-center ml-2">
                              <div className="flex flex-row items-center ">
                                <span className="text-gray-700 select-none break-all">
                                  {campaign.name}{' '}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="w-full" />
                      </React.Fragment>
                    ))}
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignSelect;
