import { setURLParams } from 'actions';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { useSelector } from 'store';

type ComponentProps = {
  className?: string;
  resource: string;
};

const InboundEventsFilter: React.FC<ComponentProps> = (props) => {
  const { className, resource } = props;

  const dispatch = useDispatch();
  const filters = useSelector(getNavParamsByResource(resource)).inbound || [];
  const options = { true: 'True', false: 'False' };

  const handleChange = (value: string): void => {
    if (filters.includes(value)) {
      dispatch(
        setURLParams({
          [`${resource}__inbound`]: [],
          [`${resource}__offset`]: '0',
        })
      );
    } else {
      dispatch(
        setURLParams({
          [`${resource}__inbound`]: [value],
          [`${resource}__offset`]: '0',
        })
      );
    }
  };

  return (
    <MultiSelectDropdown
      className={className || ''}
      title="Inbound Only"
      onChange={handleChange}
      options={Object.keys(options).map((key) => ({
        label: options[key as keyof typeof options],
        value: key,
      }))}
      selectedItems={filters as string[]}
      dataTestid="active"
    />
  );
};

export default InboundEventsFilter;
