/* eslint-disable max-lines */
import { UUID } from '@litlingo/client';
import LinkLookup from 'components/LinkLookup';
import React from 'react';
import { useParams } from 'react-router-dom';
import { getAnnotatorsDiff, getModifiedArray, getRuleInCompare } from 'selectors/rulesCompare';
import { useSelector } from 'store';
import RulesCompareArrows from './RuleManagerTable/RulesCompareArrows';
import RulesCompareLeftPanel from './RulesCompareLeftPanel';
import RulesCompareRightPanel from './RulesCompareRightPanel';

const RulesCompareContent: React.FC = () => {
  const { customerOneId, customerTwoId, ruleOneId, ruleTwoId } =
    useParams<{ customerOneId: UUID; customerTwoId: UUID; ruleOneId: UUID; ruleTwoId: UUID }>();

  const ruleOne = useSelector((state) => getRuleInCompare(state, 'left'));
  const ruleTwo = useSelector((state) => getRuleInCompare(state, 'right'));

  const { arrows } = useSelector((state) => getModifiedArray(state, 'left'));
  const { shared, added, deleted } = useSelector(getAnnotatorsDiff);

  /* useEffect(() => {
    if (ruleOne?.uuid && ruleTwo?.uuid && customerId) {
      dispatch(
        fetchRulesDiffForCompare({
          ruleOneId: ruleOne.uuid,
          ruleTwoId: ruleTwo?.uuid,
          customerOneId: customerId,
          customerTwoId: customerId,
        })
      );
    }
  }, [dispatch, ruleOne?.uuid, ruleTwo?.uuid, customerId]); */

  /* const handleFlipRules = (): void => {
    dispatch(flipReferenceRulesCompare());
  }; */

  return (
    <div className="w-full h-full flex flex-col overflow-hidden">
      <div className="flex flex-row">
        <div className="flex flex-col gap-2 w-full py-4 px-6 text-body">
          <div className="flex flex-row justify-between">
            <span className="font-bold">{ruleOne?.customer?.name}</span>
            <LinkLookup
              routeName="rules-compare"
              routeParams={{
                customerOneId: customerTwoId,
                customerTwoId: customerOneId,
                ruleOneId: ruleTwoId,
                ruleTwoId: ruleOneId,
              }}
              className="button button--secondary h-5"
            >
              <span className="font-bold">Flip reference</span>
            </LinkLookup>
          </div>
          <span>Identifiers</span>
          <div data-testid="container" className="flex flex-row flex-grow-0 flex-wrap gap-1">
            {[...shared, ...deleted].map((annotator, idx) => (
              <div
                key={`left-${annotator.annotator_uuid}`}
                className={`rounded text-small px-1 py-0.5 ${
                  idx >= shared.length ? 'bg-litlingo-deleted-annotator' : 'bg-litlingo-gray-0.5'
                }`}
              >
                <span>{annotator.annotator?.name}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="h-full border-r border-litlingo-gray-2" />
        <div className="flex flex-col gap-2 w-full py-4 px-6 text-body">
          <span className="font-bold">{ruleTwo?.customer?.name}</span>
          <span>Identifiers</span>
          <div data-testid="container" className="flex flex-row flex-grow-0 flex-wrap gap-1">
            {[...shared, ...added].map((annotator, idx) => (
              <div
                key={`left-${annotator.annotator_uuid}`}
                className={`rounded text-small px-1 py-0.5 ${
                  idx >= shared.length ? 'bg-litlingo-primary-60' : 'bg-litlingo-gray-0.5'
                }`}
              >
                <span>{annotator.annotator?.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full border-b border-litlingo-gray-2" />
      <div className="h-full w-full overflow-auto no-scrollbar">
        <div className="relative w-full flex flex-row justify-between">
          <RulesCompareLeftPanel />
          <RulesCompareArrows arrows={arrows} />
          <RulesCompareRightPanel />
        </div>
      </div>
    </div>
  );
};
export default RulesCompareContent;
