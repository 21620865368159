import React from 'react';
import { IntersectedError as IntersectedErrorT } from 'types';

const IntersectedError: React.FC<IntersectedErrorT> = ({
  widget1,
  widget2,
  Ax1,
  Ax2,
  Ay1,
  Ay2,
  Bx1,
  Bx2,
  By1,
  By2,
}) => (
  <div className="flex flex-col ml-4">
    <span className="text text--medium mb-2">
      Error, dashboard config json is not valid,{' '}
      <span className="text text--medium text--italic text--bold">{widget1}</span> and{' '}
      <span className="text text--medium text--italic text--bold">{widget2}</span> overlap each
      other in these points:
    </span>
    <div className="flex">
      <div className="flex flex-col mr-4">
        <span className="text text--medium text--italic text--bold">{widget1}</span>
        <span className="text text--medium text--italic text--bold">x1: {Ax1}</span>
        <span className="text text--medium text--italic text--bold">x2: {Ax2}</span>
        <span className="text text--medium text--italic text--bold">y1: {Ay1}</span>
        <span className="text text--medium text--italic text--bold">y2: {Ay2}</span>
      </div>
      <div className="flex flex-col ml-4">
        <span className="text text--medium text--italic text--bold">{widget2}</span>
        <span className="text text--medium text--italic text--bold">x1: {Bx1}</span>
        <span className="text text--medium text--italic text--bold">x2: {Bx2}</span>
        <span className="text text--medium text--italic text--bold">y1: {By1}</span>
        <span className="text text--medium text--italic text--bold">y2: {By2}</span>
      </div>
    </div>
  </div>
);

export default IntersectedError;
