import { addUserMetadata, upsertUserMetadata } from 'actions';
import ToggleButtonRedesign from 'components/ToggleButton/ToggleButtonRedesign';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getUserShowHighlights } from 'selectors/users';

const UserHighlightSettings: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const highlights = useSelector(getUserShowHighlights);

  const [isOn, setIsOn] = useState(false);

  useEffect(() => {
    if (highlights) {
      setIsOn(highlights.data.showHighlights);
    } else {
      setIsOn(false);
    }
  }, [highlights]);

  const handleEdit = (): void => {
    if (highlights) {
      dispatch(
        upsertUserMetadata({
          ...highlights,
          data: {
            showHighlights: !highlights.data.showHighlights,
          },
        })
      );
    } else {
      dispatch(
        addUserMetadata({
          userUuid: user.uuid,
          type: 'User',
          data: {
            showHighlights: true,
          },
        })
      );
    }
  };

  return (
    <div className="flex flex-col w-112 mt-6 px-4 border-b border-litlingo-gray-2">
      <span className="font-header font-bold text-xl">User Settings</span>
      <div className=" flex flex-row justify-between mt-4">
        <div>
          <span className="">Reviewer View </span>
          <span className="italic text-litlingo-gray-100">(Ctrl + R) to toggle</span>
        </div>
        <ToggleButtonRedesign
          dataTestid="show-highlight-button"
          isOn={isOn}
          handleToggleClick={(): void => {
            handleEdit();
          }}
        />
      </div>
      <span className="mb-4 pl-4">This view includes passage highlights</span>
    </div>
  );
};

export default UserHighlightSettings;
