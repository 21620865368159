import {
  deleteTag,
  deleteTagFailure,
  deleteTagRequest,
  deleteTagSuccess,
  fetchAllTags,
  fetchAllTagsFailure,
  fetchAllTagsRequest,
  fetchAllTagsSuccess,
  fetchTagsForFilter,
  fetchTagsForFilterPills,
  fetchTagsForFilterPillsFailure,
  fetchTagsForFilterPillsRequest,
  fetchTagsForFilterPillsSuccess,
  fetchTagsSuccessAppend,
  showSuccessAlert,
  upsertTag,
  upsertTagFailure,
  upsertTagRequest,
  upsertTagSuccess,
} from 'actions';
import { apiClient as LitLingoClient } from 'client';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getNavParamsByResource } from 'selectors/nav';
import type { API, SagaReturn } from 'types';

function* fetchAllTagsSaga(action: ReturnType<typeof fetchAllTags>): SagaReturn {
  yield put(fetchAllTagsRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.tags)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params: {
    include_count: boolean;
    order_by: string;
    order_desc: boolean;
    include_envelopes_count: boolean;
    relationships: string[];
    offset?: string;
  } = {
    include_count: true,
    order_by: 'envelopes_count',
    order_desc: true,
    include_envelopes_count: true,
    relationships: ['group'],
    ...resourceParams,
    ...action.payload,
  };

  const response = (yield call([LitLingoClient.resources.tags, 'list'], {
    params,
  })) as API.Response<API.Tags.List>;
  if (response.error != null) {
    yield put(fetchAllTagsFailure(response.error));
  } else {
    yield put(fetchAllTagsSuccess(response.data));
  }
}

function* fetchTagsListForFilter(action: ReturnType<typeof fetchTagsForFilter>): SagaReturn {
  const { payload } = action;
  const { searchValue = '', limit = 25, offset = 0 } = payload;
  const params = {
    include_pii: 'true',
    order_by: 'envelopes_count',
    order_desc: true,
    broad_search: searchValue,
    limit,
    offset,
    include_count: true,
  };
  yield put(fetchAllTagsRequest());

  const response = (yield call([LitLingoClient.resources.tags, 'list'], {
    params,
  })) as API.Response<API.Tags.List>;
  if (response.error != null) {
    yield put(fetchAllTagsFailure(response.error));
  } else if (params.offset !== undefined && params.offset === 0) {
    yield put(fetchAllTagsSuccess(response.data));
  } else {
    yield put(fetchTagsSuccessAppend(response.data));
  }
}

function* upsertTagSaga(action: ReturnType<typeof upsertTag>): SagaReturn {
  const { payload } = action;

  yield put(upsertTagRequest());

  const response = (yield call([LitLingoClient.resources.tags, 'upsert'], {
    data: payload,
  })) as API.Response<API.Tags.Upsert>;
  if (response.error != null) {
    yield put(upsertTagFailure(response.error));
  } else {
    yield put(upsertTagSuccess(response.data));
    yield put(showSuccessAlert('Saved tag'));
  }
}

function* deleteTagSaga(action: ReturnType<typeof deleteTag>): SagaReturn {
  const { payload } = action;
  const { id } = payload;
  yield put(deleteTagRequest());
  const response = (yield call(
    [LitLingoClient.resources.tags, 'delete'],
    id
  )) as API.Response<API.Tags.Delete>;
  if (response.error != null) {
    yield put(deleteTagFailure(response.error));
  } else {
    yield put(deleteTagSuccess(id));
  }
}

function* fetchTagsForFilterPillsSaga(action: ReturnType<typeof fetchAllTags>): SagaReturn {
  yield put(fetchTagsForFilterPillsRequest());

  const params = {
    limit: -1,
    ...action.payload,
  };

  const response = (yield call([LitLingoClient.resources.tags, 'list'], {
    params,
  })) as API.Response<API.Tags.List>;

  if (response.error != null) {
    yield put(fetchTagsForFilterPillsFailure(response.error));
  } else {
    yield put(fetchTagsForFilterPillsSuccess(response.data));
  }
}

export default function* tagsSaga(): SagaReturn {
  yield takeLatest(fetchAllTags.toString(), fetchAllTagsSaga);
  yield takeLatest(upsertTag.toString(), upsertTagSaga);
  yield takeLatest(deleteTag.toString(), deleteTagSaga);
  yield takeEvery(fetchTagsForFilterPills.toString(), fetchTagsForFilterPillsSaga);
  yield takeLatest(fetchTagsForFilter.toString(), fetchTagsListForFilter);
}
