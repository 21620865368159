import LoadingIndicator from 'components/LoadingIndicator';
import React from 'react';

type ComponentProps = {
  message: string;
  showLoading: boolean;
  showWarning: boolean;
  showSavedMsg: boolean;
  classes?: string;
};

const WarningMsg: React.FC<ComponentProps> = ({
  message,
  showLoading,
  showWarning,
  showSavedMsg,
  classes,
}) => {
  const loading = (
    <div data-testid="loading-div" className={`flex flex-row  ${classes}`}>
      <LoadingIndicator className="ml-2" size="5" />
      <span className="pl-2">Saving...</span>
    </div>
  );

  const warning = (
    <div className={`flex flex-row relative warning-bg-color py-1 px-2 h-6 ${classes}`}>
      <svg
        className="w-4 h-4 text-gray-700 self-center"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
      </svg>
      <div className="self-center font-sans text-xs text-gray-700 font-semibold not-italic leading-tight flex whitespace-no-wrap px-2">
        <span className="" data-testid="unsaved-message-span">
          {message}
        </span>
      </div>
    </div>
  );

  const savedMsg = (
    <div data-testid="saved-message-div" className={`flex flex-row relative ${classes}`}>
      <span>All changes saved</span>
    </div>
  );

  if (showLoading) return loading;
  if (showWarning) return warning;
  if (showSavedMsg) return savedMsg;
  return null;
};

export default WarningMsg;
