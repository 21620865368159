/* eslint-disable no-console */
import { BarExtendedDatum, BarMouseEventHandler, ResponsiveBar } from '@nivo/bar';
import { allowedReadOnlyDimensions, metricsLinksMap } from 'constants/dashboard';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getNavParamsByResource, getNavWidgetFilters } from 'selectors/nav';
import { getPermissionsPolicy } from 'selectors/permissions';
import { DefaultWidgetComponentProps } from 'types';
import {
  buildDateHistogramDataFromMetrics,
  getTotalCountFromDateHistogramData,
} from 'utils/dashboard';
import { isActionAuthorized } from 'utils/permissions';
import { useHistory } from 'utils/urls';

const DateHistogramWidget: React.FC<DefaultWidgetComponentProps> = (props) => {
  const { widgetId, widgetData, queryData, nivoProps } = props;

  const history = useHistory();
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const customerDomain = useSelector(getCustomerDomain);
  const widgetFilters = useSelector(getNavWidgetFilters(widgetId));
  const filters = useSelector(getNavParamsByResource(resourceQueryParamName.metrics));

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  const processedData = buildDateHistogramDataFromMetrics(widgetData, queryData);
  const totalCount = getTotalCountFromDateHistogramData(processedData);
  if (totalCount === 0) {
    return <WidgetEmptyState />;
  }

  const handleClick = (datum: BarExtendedDatum): void => {
    const query = queryData.queries.find((q) => q.id === datum.id);
    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info == null || !isActionAuthorized(info.action, user.roles, policy)) {
      return;
    }

    const series = widgetData.results.find((s) => s.id === datum.id);
    if (series == null) {
      return;
    }

    const start = Number(series.x_axis[datum.index].value) * 1000;
    const end = Number(series.x_axis[datum.index + 1]?.value ?? queryData.end_time) * 1000;

    // TODO: extract this to a utility function
    const dimensions: Record<string, string[]> = {};
    if (info.dimensions != null) {
      Object.entries(info.dimensions).forEach(([dimension, param]) => {
        const allowed = allowedReadOnlyDimensions[query.metric];

        // if allowedDimension is not defined or the dimension is not included,
        // just ignore it
        if (allowed == null || !allowed.includes(dimension)) {
          return;
        }

        const value = [];
        if (filters[dimension] != null) {
          const topValue = filters[dimension];
          if (Array.isArray(topValue)) {
            topValue.forEach((v) => value.push(v));
          } else {
            value.push(topValue);
          }
        }

        if (widgetFilters[dimension] != null) {
          const widgetValue = filters[dimension];
          if (Array.isArray(widgetValue)) {
            widgetValue.forEach((v) => value.push(v));
          } else {
            value.push(widgetValue);
          }
        }

        dimensions[`${info.resource}__${param}`] = value;
      });
    }

    history.pushLookup({
      customerDomain,
      routeName: info.route,
      queryParams: {
        [`${info.resource}__created_after`]: moment.utc(start).format(FULL_DATE_FORMAT),
        [`${info.resource}__created_before`]: moment.utc(end).format(FULL_DATE_FORMAT),
        ...dimensions,
      },
    });
  };

  const handleMouseEnter = (
    datum: BarExtendedDatum,
    event: React.MouseEvent<SVGRectElement, MouseEvent>
  ): void => {
    const query = queryData.queries.find((q) => q.id === datum.id);
    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info != null) {
      event.currentTarget.style.cursor = 'pointer';
    }
  };

  return (
    <div className="h-dashboard-events">
      <ResponsiveBar
        colors={['#B6DDC6']}
        data={processedData.data}
        keys={processedData.keys}
        indexBy={processedData.indexBy}
        margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
        innerPadding={2}
        enableLabel={false}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        theme={{
          tooltip: {
            container: {
              background: 'none',
              boxShadow: 'none',
            },
          },
        }}
        axisBottom={{
          tickRotation: queryData.queries[0].period === 604800 ? -20 : -49,
          legendOffset: 30,
          tickPadding: 5,
        }}
        tooltip={(d): JSX.Element => (
          <div className="bg-litlingo-off-white p-1 rounded flex items-center shadow-md">
            <div
              style={{
                background: d.color,
              }}
              className="h-3 w-3 mr-2"
            />
            <span className="text">
              {processedData.labelsMap[d.id]} - {d.indexValue}: <b>{d.value}</b>
            </span>
          </div>
        )}
        animate
        onClick={handleClick}
        onMouseEnter={handleMouseEnter as BarMouseEventHandler<SVGElement>}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...nivoProps}
      />
    </div>
  );
};

export default withWidget({
  title: 'Events',
  subtitle: 'Communications flagged by active use cases',
})(DateHistogramWidget);
