/* eslint-disable max-lines */
import { UUID } from '@litlingo/client';
import { clearURLParams, cloneCampaign, deleteCampaign } from 'actions';
import EditCampaignModal from 'components/Campaign/EditCampaignModal';
import CampaignListRow from 'components/CampaignsList/CampaignListRow';
import CompareRules from 'components/CompareRules';
import ConfirmModal from 'components/ConfirmModal';
import CustomerSimpleList from 'components/CustomerSimpleList';
import EmptyTableState from 'components/EmptyTableState';
import ActiveCampaignsFilter from 'components/Filters/ActiveCampaignsFilter';
import FilterListHeaderRedesign from 'components/Filters/FilterListHeaderRedesign';
import PlatformsFilter from 'components/Filters/PlatformsFilter';
import ListFooterHooksBased from 'components/ListFooter/ListFooterHooksBased';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import Navbar from 'components/Navbar';
import Permissions from 'components/Permissions';
import SearchInput from 'components/SearchInput';
import Tooltip from 'components/Tooltip';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useCampaigns from 'hooks/campaigns/useCampaigns';
import React, { useEffect, useRef, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import logEvent from 'utils/analytics';
import CampaignListActionsRow from './CampaignListActionRow';
import CampaignListCount from './CampaignListCount';

const CampaignsList: React.FC = () => {
  const dispatch = useDispatch();

  const { data, isLoading, isFetching } = useCampaigns({ redirectOnError: true });
  const isEmpty = data?.records.length === 0;

  const [isCompareRulesOpen, setIsCompareRulesOpen] = useState(false);
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);
  const compareModalRef = useRef();

  const [selectedCampaigns, setSelectedCampaigns] = useState<UUID[]>([]);
  const [showModels, setShowModels] = useState(false);
  const [isDeletingCampaign, setIsDeletingCampaign] = useState(false);
  const [isCloneCampaignOpen, setIsCloneCampaignOpen] = useState(false);

  useEffect(() => {
    if (!selectedCampaigns.length) {
      setShowModels(false);
    }
  }, [selectedCampaigns]);

  const toggleConfirmModal = (): void => setIsDeletingCampaign(!isDeletingCampaign);

  const handleCreateNew = async (): Promise<void> => {
    setIsCreatingCampaign(true);
  };

  const toggleIsCompareRulesOpen = (): void => {
    setIsCompareRulesOpen(!isCompareRulesOpen);
  };

  const toggleIsCloneCampaignOpen = (): void => {
    setIsCloneCampaignOpen(!isCloneCampaignOpen);
  };

  const toggleCreateModal = (): void => setIsCreatingCampaign(!isCreatingCampaign);

  const handleCompareRules = (): void => {
    // @ts-ignore
    compareModalRef.current.compareRules();
  };

  const handleResetFiltersClick = (): void => {
    logEvent('use-case-reset-filters');
    dispatch(clearURLParams());
  };

  const compareRulesBody = (
    <CompareRules ref={compareModalRef} toggleIsCompareRulesOpen={toggleIsCompareRulesOpen} />
  );

  const renderCampaignScreen = (
    <CustomerSimpleList
      toggleShowCloneResourceModalOpen={toggleIsCloneCampaignOpen}
      resourceId={selectedCampaigns[0]}
      cloneResource={cloneCampaign}
    />
  );

  const handleCampaignSelect = (checked: boolean, uuid: UUID): void => {
    if (checked) {
      setSelectedCampaigns((selected) => [...selected, uuid]);
    } else {
      setSelectedCampaigns((selected) => selected.filter((id) => id !== uuid));
    }
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();

    if (selectedCampaigns.length === data?.records.length) {
      setSelectedCampaigns([]);
    } else {
      setSelectedCampaigns(data?.records.map((campaign) => campaign.uuid) || []);
    }
  };

  const handleDelete = (): void => {
    dispatch(deleteCampaign({ id: selectedCampaigns[0] }));
    setIsDeletingCampaign(false);
    setSelectedCampaigns([]);
  };

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />
      <div className="pt-8">
        <div>
          <header>
            <div className="max-w-7xl mx-auto px-6">
              <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                  <div className="flex flex-row items-center">
                    <h2 className="title">Use Cases</h2>
                    {isFetching && <LoadingIndicator className="ml-2" size="5" />}
                  </div>
                </div>
                <div className="flex flex-row gap-x-2">
                  <Permissions action="campaigns.create">
                    <button
                      onClick={toggleIsCompareRulesOpen}
                      type="button"
                      className="flex flex-row justify-center button button--secondary h-8 w-30  shadow-sm rounded"
                    >
                      <span>Evaluate</span>
                    </button>
                  </Permissions>
                  <Permissions action="campaigns.create">
                    <button
                      onClick={handleCreateNew}
                      type="button"
                      data-testid="create-campaign-button"
                      className="flex flex-row justify-center button button--primary h-8 w-30  shadow-sm rounded"
                    >
                      <span>Add New</span>
                    </button>
                  </Permissions>
                </div>
              </div>

              <div className="flex flex-row flex-shrink-0  justify-between mt-4">
                <div className="flex flex-row gap-x-2 h-8">
                  <SearchInput
                    resource={resourceQueryParamName.campaign}
                    field="broad_search"
                    logEventMessage="use-case-search"
                    className="h-8"
                  />
                  <PlatformsFilter resource={resourceQueryParamName.campaign} className="h-8" />
                  <ActiveCampaignsFilter resource={resourceQueryParamName.campaign} />
                  <button
                    type="button"
                    className="bg-white border-0 focus:outline-none inline-flex items-end leading-4 text text-body text-litlingo-gray-6 hover:text-litlingo-gray-4 underline"
                    data-testid="reset-filters-button"
                    onClick={handleResetFiltersClick}
                  >
                    Clear Filters
                  </button>
                </div>
                <CampaignListCount />
              </div>
            </div>
          </header>
        </div>
        <main className="z-10">
          <div className="mt-2 mb-8 px-6 text-left">
            <LoadingOverlay
              active={isLoading}
              spinner={<LoadingIndicator size="10" />}
              fadeSpeed={0}
              styles={{
                content: (base) => ({
                  ...base,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }),
                wrapper: (base) => ({
                  ...base,
                  height: '100%',
                }),
                spinner: () => ({}),
                overlay: (base) => ({
                  ...base,
                  zIndex: 10,
                }),
              }}
            >
              <div className="table-wrapper__new">
                <table className="table-wrapper__table">
                  <thead>
                    <tr>
                      <th className="table-wrapper__new-th table-wrapper__th--w-3">
                        <div className="px-2">
                          <Tooltip tooltip="envelopesListActions.selectAll" redesign>
                            <label
                              htmlFor="select-all"
                              className="flex justify-start items-center w-full cursor-pointer"
                            >
                              <input
                                id="select-all"
                                data-testid="select-all"
                                className={`cursor-pointer form-checkbox litlingo-checkbox w-5 h-5 rounded-sm ${
                                  selectedCampaigns.length > 0 &&
                                  data &&
                                  selectedCampaigns?.length < data.records.length
                                    ? 'checkbox-select-all-partial'
                                    : ''
                                }`}
                                type="checkbox"
                                checked={
                                  selectedCampaigns?.length === data?.records.length &&
                                  selectedCampaigns?.length > 0
                                }
                                onChange={handleSelectAll}
                              />
                            </label>
                          </Tooltip>
                        </div>
                      </th>

                      <FilterListHeaderRedesign
                        resource={resourceQueryParamName.campaign}
                        text="Use Case"
                        field="name"
                      />
                      <th className="table-wrapper__new-th">Platform/Outcomes</th>
                      <th className="table-wrapper__new-th table-wrapper__th--w-8">Inbound</th>
                      <th className="table-wrapper__new-th table-wrapper__th--w-8">Outbound</th>
                      <th className="table-wrapper__new-th table-wrapper__th--w-8">Enabled</th>
                      <FilterListHeaderRedesign
                        resource={resourceQueryParamName.campaign}
                        text="Last Modified"
                        field="updated_at"
                        className="table-wrapper__th--w-12"
                      />
                      <th className="table-wrapper__new-th table-wrapper__th--w-15">Modified By</th>
                    </tr>
                    {selectedCampaigns?.length > 0 && (
                      <CampaignListActionsRow
                        selectedCampaigns={selectedCampaigns}
                        setShowModels={setShowModels}
                        setIsDeletingCampaign={setIsDeletingCampaign}
                        showModels={showModels}
                        setIsCloneCampaignOpen={setIsCloneCampaignOpen}
                      />
                    )}
                  </thead>
                  <tbody className="table-wrapper__tbody">
                    {!isLoading && isEmpty ? (
                      <EmptyTableState message="There are no use cases to display" colCount="8" />
                    ) : (
                      data?.records.map((campaign) => (
                        <CampaignListRow
                          campaign={campaign}
                          key={campaign.uuid}
                          selected={selectedCampaigns.includes(campaign.uuid)}
                          handleCampaignSelect={handleCampaignSelect}
                          showModels={showModels}
                        />
                      ))
                    )}
                  </tbody>
                </table>
                <ListFooterHooksBased
                  resourceStateName={resourceQueryParamName.campaign}
                  resourceName="use case"
                  fetchHook={useCampaigns}
                />
              </div>
            </LoadingOverlay>
          </div>
        </main>
      </div>
      {isCompareRulesOpen && (
        <Modal
          body={compareRulesBody}
          title="Compare Models"
          okButton
          okButtonText="Compare"
          okButtonOnClick={handleCompareRules}
          toggleShowModal={toggleIsCompareRulesOpen}
        />
      )}
      {isCreatingCampaign && (
        <EditCampaignModal
          isCreating
          isModalOpen={isCreatingCampaign}
          toggleModalOpen={toggleCreateModal}
        />
      )}

      {isCloneCampaignOpen && (
        <Modal
          body={renderCampaignScreen}
          title="Choose a workspace"
          okButton
          okButtonText="Done"
          okButtonOnClick={toggleIsCloneCampaignOpen}
          toggleShowModal={toggleIsCloneCampaignOpen}
        />
      )}

      {isDeletingCampaign && (
        <ConfirmModal
          text="Are you sure you want to delete this Use Case?"
          okButtonText="Yes"
          cancelButtonText="No"
          data-testid="delete-modal"
          okButtonOnClick={handleDelete}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </div>
  );
};

export default CampaignsList;
