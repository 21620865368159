import { createAction } from '@reduxjs/toolkit';
import type { API, ErrorObject, Team, UUID } from 'types';

export type FetchTeamsPayload = {
  name?: string;
  limit?: string;
  ids?: UUID[];
};

// REDUX - Delete when dashboard changes filters to hooks
export const fetchTeams = createAction<FetchTeamsPayload>('TEAMS/FETCH');
export const fetchTeamsRequest = createAction('TEAMS/FETCH_REQUEST');
export const fetchTeamsFailure = createAction<ErrorObject>('TEAMS/FETCH_FAILURE');
export const fetchTeamsSuccess = createAction<API.WrappedAPIResponse<Team>>('TEAMS/FETCH_SUCCESS');
export const fetchTeamsSuccessAppend = createAction<API.WrappedAPIResponse<Team>>(
  'TEAMS/FETCH_SUCCESS_APPEND'
);

type FetchTeamsForFilterPillsPayload = { uuids: string[] };
export const fetchTeamsForFilterPills =
  createAction<FetchTeamsForFilterPillsPayload>('TEAMS/FETCH_BY_ID');
export const fetchTeamsForFilterPillsRequest = createAction('TEAMS/FETCH_BY_ID_REQUEST');
export const fetchTeamsForFilterPillsSuccess = createAction<API.WrappedAPIResponse<Team>>(
  'TEAMS/FETCH_BY_ID_SUCCESS'
);
export const fetchTeamsForFilterPillsFailure = createAction<ErrorObject>(
  'TEAMS/FETCH_BY_ID_FAILURE'
);

type FetchTeamsForFilterPayload = {
  searchValue: string;
  limit?: number;
  offset?: number;
};
export const fetchTeamsForFilter = createAction<FetchTeamsForFilterPayload>('TEAMS_FILTER/FETCH');
