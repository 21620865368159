import { fetchAllEnvelopes, fetchSingleEnvelope } from 'actions';
import Envelope from 'components/Envelope';
import EnvelopeList from 'components/EnvelopeList';
import { RouteSpec, SuperRouteSpec } from 'types';

const PARAMS = {
  order_by: {
    defaultValue: 'sent_at',
    options: [
      'created_at',
      'random',
      'reviewed_at',
      'subject',
      'user_name',
      'review_value',
      'sent_at',
    ],
  },
  order_desc: {
    defaultValue: 'true',
  },
  limit: {
    defaultValue: '25',
  },
  offset: {
    defaultValue: '0',
  },
  created_by: {
    list: true,
  },
  not_created_by: {
    list: true,
  },
  recipients: {
    list: true,
  },
  not_recipients: {
    list: true,
  },
  reviewed_by_user_uuids: {
    list: true,
  },
  not_reviewed_by_user_uuids: {
    list: true,
  },
  campaign_uuids: {
    list: true,
  },
  not_campaign_uuids: {
    list: true,
  },
  campaign_uuids_and: {
    list: true,
  },
  not_campaign_uuids_and: {
    list: true,
  },
  rule_uuids: {
    list: true,
  },
  not_rule_uuids: {
    list: true,
  },
  rule_uuids_and: {
    list: true,
  },
  not_rule_uuids_and: {
    list: true,
  },
  rule_context_uuids: {
    list: true,
  },
  not_rule_context_uuids: {
    list: true,
  },
  rule_context_uuids_and: {
    list: true,
  },
  not_rule_context_uuids_and: {
    list: true,
  },
  annotator_uuids: {
    list: true,
  },
  not_annotator_uuids: {
    list: true,
  },
  attachment_campaign_uuids: {
    list: true,
  },
  not_attachment_campaign_uuids: {
    list: true,
  },
  attachment_rule_uuids: {
    list: true,
  },
  not_attachment_rule_uuids: {
    list: true,
  },
  attachment_annotator_uuids: {
    list: true,
  },
  not_attachment_annotator_uuids: {
    list: true,
  },
  body_campaign_uuids: {
    list: true,
  },
  not_body_campaign_uuids: {
    list: true,
  },
  body_campaign_uuids_and: {
    list: true,
  },
  not_body_campaign_uuids_and: {
    list: true,
  },
  body_rule_uuids: {
    list: true,
  },
  not_body_rule_uuids: {
    list: true,
  },
  body_annotator_uuids: {
    list: true,
  },
  not_body_annotator_uuids: {
    list: true,
  },
  platforms: {
    list: true,
  },
  not_platforms: {
    list: true,
  },
  states: {
    list: true,
  },
  not_states: {
    list: true,
  },
  review_values: {
    list: true,
  },
  not_review_values: {
    list: true,
  },
  is_starred: {},
  is_external: {},
  same_sentence: {},
  is_read: {
    list: true,
  },
  is_review_confirmed: {},
  has_events: {
    list: true,
    defaultValue: [],
  },
  has_attachments: {
    list: true,
  },
  has_attachment_events: {
    list: true,
  },
  has_translation: {
    list: true,
  },
  created_after: {},
  created_before: {},
  inbound: {
    list: true,
  },
  broad_search: {},
  platform_thread_guid: {},
  uuids: {
    list: true,
  },
  tags: {
    list: true,
  },
  not_tags: {
    list: true,
  },
  tags_and: {
    list: true,
  },
  not_tags_and: {
    list: true,
  },
  tag_value_groups: {
    list: true,
  },
  not_tag_value_groups: {
    list: true,
  },
  tag_value_groups_and: {
    list: true,
  },
  not_tag_value_groups_and: {
    list: true,
  },
  key_actions: {
    list: true,
  },
  not_key_actions: {
    list: true,
  },
  key_actions_and: {
    list: true,
  },
  not_key_actions_and: {
    list: true,
  },
  sender_team_uuids: {
    list: true,
  },
  not_sender_team_uuids: {
    list: true,
  },
  sender_team_uuids_and: {
    list: true,
  },
  sample_uuid: {},
  integration_types: {
    list: true,
  },
  subject: {},
  is_first_time_create_review_set: {},
  matches: {
    list: true,
    defaultValue: [],
  },
  review_set_edit_uuid: {},
  filters_search: {},
  has_comments: {},
  days_from_oor: {},
  rule_branch_uuid: {},
  sender_domain: {
    list: true,
  },
  not_sender_domain: {
    list: true,
  },
  recipient_domains: {
    list: true,
  },
  not_recipient_domains: {
    list: true,
  },
  sender_domain_and: {
    list: true,
  },
  not_sender_domain_and: {
    list: true,
  },
  recipient_domains_and: {
    list: true,
  },
  not_recipient_domains_and: {
    list: true,
  },
  skipped_assignment_uuid: {},
};

const envelopesRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'envelope-detail',
    path: '/:customerDomain/communication-envelopes/:envelopeId',
    component: Envelope,
    private: true,
    data: {
      actions: [fetchSingleEnvelope],
      idFromPath: true,
      params: {
        relationships: {
          defaultValue: ['campaign', 'actions', 'rule', 'created_by', 'actions.created_by'],
          options: ['campaign', 'actions', 'rule', 'created_by', 'actions.created_by'],
          list: true,
        },
        include_count: {
          defaultValue: '1',
        },
        envelopes: {
          nestedResource: true,
          params: {
            currentReview: {
              defaultValue: '',
            },
            page_id: {},
            event_uuid: {},
            ...PARAMS,
          },
        },
        threads: {
          nestedResource: true,
          params: {
            order_by: {
              defaultValue: 'created_at',
            },
            order_desc: {
              defaultValue: 'true',
            },
            limit: {
              defaultValue: '10',
            },
            offset: {
              defaultValue: '0',
            },
            entry_point: {},
          },
        },
      },
    },
  },
  {
    name: 'envelope-list',
    path: '/:customerDomain/communication-envelopes',
    component: EnvelopeList,
    private: true,
    data: {
      actions: [fetchAllEnvelopes],
      params: {
        include_count: {
          defaultValue: '1',
        },
        envelopes: {
          nestedResource: true,
          params: PARAMS,
        },
      },
    },
  },
  {
    path: '/communication-envelopes',
    component: (): null => null,
    private: true,
  },
];

export default envelopesRoutes;
