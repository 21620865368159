/* eslint-disable max-lines */
import { addValueToTree, showErrorAlert } from 'actions';
import DateRangePicker from 'components/Calendar';
import SelectRedesign from 'components/Select/SelectRedesign';
import moment from 'moment'; // require
import React, { useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { DateRange } from 'react-day-picker';
import { useDispatch } from 'react-redux';
import { getNavParamsByResource, getNavParamsFromTree } from 'selectors/nav';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';
import { isValidDate } from 'utils/dateValidation';
import { dateFormat, dateFormatHalfYear, dateFormatYear } from 'utils/timeRanges';
import { dateOptions } from './DashboardDateFilter';

type ComponentProps = {
  className?: string;
  resource: string;
};

type DateOption = 'date-range' | 'before' | 'after';

export const timeRangeOptions = [
  { label: 'Past 24 Hours', value: dateFormat(24, 'hours') },
  { label: 'Past 7 Days', value: dateFormat(7, 'days') },
  { label: 'Past 30 Days', value: dateFormat(30, 'days') },
  { label: 'Past 60 Days', value: dateFormat(60, 'days') },
  { label: 'Past 90 Days', value: dateFormat(90, 'days') },
  { label: 'Past 6 Months', value: dateFormatHalfYear() },
  { label: 'Year to Date', value: dateFormatYear() },
];

const fullDateOptions = [...dateOptions, ...timeRangeOptions];

const DateFilter: React.FC<ComponentProps> = (props) => {
  const { className, resource } = props;

  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const [showCalendar, setShowCalendar] = useState(false);
  const [dateOption, setDateOption] = useState<DateOption>('date-range');

  const dateRange = useSelector(getNavParamsFromTree).date_range as string[];
  const [createdAfterTree, createdBeforeTree] = dateRange?.[0].split('<>') || [];

  const createdAfterUrl = useSelector(getNavParamsByResource(resource)).created_after as string;
  const createdBeforeUrl = useSelector(getNavParamsByResource(resource)).created_before as string;

  const createdAfter = createdAfterTree || createdAfterUrl;
  const createdBefore = createdBeforeTree || createdBeforeUrl;

  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');

  useEffect(() => {
    const createAfterDate = isValidDate(createdAfter)
      ? new Date(createdAfter as string)
      : undefined;
    const createdBeforeDate = isValidDate(createdBefore)
      ? new Date(createdBefore as string)
      : undefined;

    if (createAfterDate) {
      setFromValue(moment(createAfterDate).format('MM/DD/yyyy'));
    } else {
      setFromValue('');
    }
    if (createdBeforeDate) {
      setToValue(moment(createdBeforeDate).format('MM/DD/yyyy'));
    } else {
      setToValue('');
    }
  }, [createdAfter, createdBefore]);

  useEffect(() => {
    if (fromValue && !toValue) {
      setDateOption('after');
    }
    if (!fromValue && toValue) {
      setDateOption('before');
    }
  }, [toValue, fromValue]);

  useEffect(() => {
    if (!createdAfter && !createdBefore) {
      setDateOption('date-range');
    }
  }, [createdAfter, createdBefore]);

  const createdAfterDate = isValidDate(createdAfter) ? new Date(createdAfter as string) : undefined;
  const createdBeforeDate = isValidDate(createdBefore)
    ? new Date(createdBefore as string)
    : undefined;

  const handleSetDateRange = (range: DateRange): void => {
    logEvent('envelopes-list-filter-date');

    const { from, to } = range;

    const fromDate = from ? moment(from) : null;
    const toDate = to ? moment(to) : null;

    const fromUrl = fromDate ? fromDate.startOf('day').utc().format() : null;
    const toUrl = toDate ? toDate.endOf('day').utc().format() : null;

    let treeValue = '';

    if (fromUrl && !toUrl && dateOption === 'date-range') {
      treeValue = `${fromUrl}<>${moment(from).endOf('day').utc().format() ?? ''}`;
    } else {
      treeValue = `${fromUrl}<>${toUrl}`;
    }
    dispatch(addValueToTree({ field: 'date_range', value: treeValue, isSingleValue: true }));
  };

  const handleChangeDate = (mode: string, value: Date | undefined): void => {
    if (mode === 'after') {
      handleSetDateRange({ from: value, to: moment(toValue, 'MM/DD/YYYY', true).toDate() });
    } else {
      handleSetDateRange({ from: moment(fromValue, 'MM/DD/YYYY', true).toDate(), to: value });
    }
  };

  const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setFromValue(value);

    const fromDate = moment(value, 'MM/DD/YYYY', true);

    if (createdBeforeDate && moment(createdBeforeDate).isValid()) {
      const beforeDate = moment(createdBeforeDate);
      if (fromDate.isAfter(beforeDate)) {
        dispatch(showErrorAlert('Invalid Date Range'));
        return;
      }
    }

    if (fromDate.isValid()) {
      handleChangeDate('after', fromDate.toDate());
    } else if (value.length > 9) {
      dispatch(showErrorAlert('Invalid Date'));
    } else if (value.length === 0) {
      handleChangeDate('after', undefined);
    }
  };

  const handleToChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setToValue(value);

    const toDate = moment(value, 'MM/DD/YYYY', true);

    if (createdAfterDate && moment(createdAfterDate)) {
      const afterDate = moment(createdAfterDate);
      if (toDate.isBefore(afterDate)) {
        dispatch(showErrorAlert('Invalid Date Range'));
        return;
      }
    }

    if (toDate.isValid()) {
      handleChangeDate('before', toDate.toDate());
    } else if (value.length > 9) {
      dispatch(showErrorAlert('Invalid Date'));
    } else if (value.length === 0) {
      handleChangeDate('before', undefined);
    }
  };

  const defaultSelected: DateRange = {
    from: createdAfterDate,
    to: createdBeforeDate,
  };

  const handleDateOptionChanged = (value: DateOption): void => {
    const prevOption = dateOption;
    setDateOption(value);

    if (value === 'after') {
      if (prevOption === 'before' && createdBefore) {
        addValueToTree({ field: 'date_range', value: `${createdBefore}<>`, isSingleValue: true });
      }
      if (createdAfter) {
        dispatch(
          addValueToTree({ field: 'date_range', value: `${createdAfter}<>`, isSingleValue: true })
        );
      }
    } else if (value === 'before') {
      if (prevOption === 'after' && createdAfter) {
        dispatch(
          addValueToTree({ field: 'date_range', value: `<>${createdAfter}`, isSingleValue: true })
        );
      }
      if (createdBefore) {
        dispatch(
          addValueToTree({ field: 'date_range', value: `<>${createdBefore}`, isSingleValue: true })
        );
      }
    } else if (value === 'date-range') {
      if (prevOption === 'before') {
        let after = moment().startOf('month').utc();
        if (moment(createdBefore).isBefore(after)) {
          after = moment(createdBefore);
        }

        logEvent(`envelopes-date-filter-${value}`);
        dispatch(
          addValueToTree({
            field: 'date_range',
            value: `${after.format()}<>${createdBefore}`,
            isSingleValue: true,
          })
        );
      }
    } else if (value !== 'date-range') {
      logEvent(`envelopes-date-filter-${value}`);
      dispatch(
        addValueToTree({
          field: 'date_range',
          value: `${value}<>${moment().utc().format()}`,
          isSingleValue: true,
        })
      );
    }
  };

  return (
    <div
      aria-hidden
      ref={ref}
      className={`flex flex-col gap-2 mt-2 shadow-sm ${className || ''}`}
      onClick={(): void => setShowCalendar(true)}
    >
      <div className="bg-litlingo-success-light bg-opacity-10 rounded ">
        <SelectRedesign
          className="bg-transparent border-none"
          valueClassName="text-white"
          optionClassname="text-litlingo-gray-6"
          onChange={(value): void => {
            handleDateOptionChanged(value as DateOption);
          }}
          options={fullDateOptions}
          value={dateOption}
        />
      </div>
      {dateOption === 'after' || dateOption === 'before' || dateOption === 'date-range' ? (
        <div className="w-full h-8 flex flex-row justify-start gap-1 px-1 py-1.5 text-body bg-litlingo-success-light bg-opacity-10 rounded">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.5 2.75C5.5 2.33579 5.16421 2 4.75 2C4.33579 2 4 2.33579 4 2.75V4H3C1.89543 4 1 4.89543 1 6V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V6C19 4.89543 18.1046 4 17 4H16.5V2.75C16.5 2.33579 16.1642 2 15.75 2C15.3358 2 15 2.33579 15 2.75V4H5.5V2.75ZM17.5 8H2.5V17C2.5 17.2761 2.72386 17.5 3 17.5H17C17.2761 17.5 17.5 17.2761 17.5 17V8Z"
              fill="white"
            />
          </svg>

          <div className="w-full flex flex-row gap-1">
            {dateOption !== 'before' && (
              <input
                data-testid="date-filter-input"
                type="text"
                placeholder={moment().format('MM/DD/yyyy')}
                className="text-white bg-transparent placeholder-litlingo-gray-3 w-19"
                value={fromValue}
                onChange={handleFromChange}
              />
            )}
            {dateOption !== 'before' && dateOption !== 'after' && (
              <span className="text-white">-</span>
            )}
            {dateOption !== 'after' && (
              <input
                data-testid="date-filter-input"
                type="text"
                placeholder={moment().format('MM/DD/yyyy')}
                className="text-white bg-transparent placeholder-litlingo-gray-3 w-19"
                value={toValue}
                onChange={handleToChange}
              />
            )}
          </div>
        </div>
      ) : null}

      {showCalendar && (
        <DateRangePicker
          containerRef={ref}
          defaultRange={defaultSelected}
          setShowCalendar={(): void => setShowCalendar(!showCalendar)}
          setDateRange={handleSetDateRange}
          dateOption={dateOption}
        />
      )}
    </div>
  );
};
export default DateFilter;
