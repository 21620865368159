import { selectCommunication } from 'actions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedCommunication } from 'selectors/communications';
import { ATTACHMENT_TYPES } from 'selectors/envelopeReview';
import EnvelopeSkeleton from './EnvelopeSkeleton';
import NoMatchAttachmentContent from './NoMatchAttachmentContent';
import NoMatchCommunicationContent from './NoMatchCommunicationContent';

const NoMatchCommunicationContainer: React.FC = () => {
  const dispatch = useDispatch();

  const communication = useSelector(getSelectedCommunication);

  useEffect(() => {
    dispatch(selectCommunication({ communication }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const renderEvent = (): JSX.Element | null => {
    if (!communication) return null;

    if (ATTACHMENT_TYPES.includes(communication.communication_type)) {
      return <NoMatchAttachmentContent communication={communication} />;
    }

    return <NoMatchCommunicationContent communication={communication} />;
  };

  if (!communication) return <EnvelopeSkeleton />;

  return (
    <div className="w-full h-full flex flex-col overflow-hidden" data-testid="communication-box">
      {renderEvent()}
    </div>
  );
};
export default NoMatchCommunicationContainer;
