import type { AuditLog, Selector, UUID } from 'types';

export const getFetchAuditLogsLoading: Selector<boolean> = (state) =>
  state.auditLogs.loading.includes('audit-logs');

export const getAuditLogsList: Selector<AuditLog[]> = (state) => {
  if (state.auditLogs.list == null) {
    return [];
  }

  return state.auditLogs.list.map((uuid) => state.auditLogs.items[uuid]);
};

export const getAuditLog =
  (auditlogId: UUID): Selector<AuditLog | null> =>
  ({ auditLogs }): AuditLog | null =>
    auditLogs.items[auditlogId] || null;

export const getFetchChangelogLoading: Selector<boolean> = (state) =>
  state.auditLogs.loading.includes('changelog');
