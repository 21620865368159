import useClickOutside from 'components/utils/useClickOutside';
import { THREE_DOTS_ICON } from 'constants/customerIcons';
import React, { useCallback, useRef, useState } from 'react';

type ComponentProps = {
  className?: string;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  idx: number;
  setCurrentIdx: React.Dispatch<React.SetStateAction<number>>;
  handleRemoveDomain: (idx: number) => void;
};

const CustomerAllowedDomainsThreeDots: React.FC<ComponentProps> = ({
  className,
  setModalOpen,
  idx,
  setCurrentIdx,
  handleRemoveDomain,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentLabel, setCurrentLabel] = useState<string | null>(null);
  const options = {
    edit: 'Edit',
    remove: 'Remove',
  };

  // click outside functionality
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleButtonClick = (): void => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    const { id: state } = evt.target;
    setCurrentIdx(idx);
    if (evt.target.checked) {
      if (state.startsWith('edit')) {
        setModalOpen(true);
      } else {
        handleRemoveDomain(idx);
      }

      setCurrentLabel(state);
    }
  };

  const getOption = (key: keyof typeof options): JSX.Element => (
    <div className="relative flex items-start">
      <div className="hidden">
        <input
          id={key + idx}
          data-testid={`customer-three-dots-${key}`}
          type="checkbox"
          onChange={handleInputChange}
          checked={currentLabel === options[key]}
          className="form-checkbox litlingo-checkbox"
        />
      </div>
      <div className="text-sm leading-5 m-2 self-center">
        <label htmlFor={key + idx} className="text font-medium cursor-pointer">
          {options[key]}
        </label>
      </div>
    </div>
  );

  return (
    <div className={`flex flex-row items-center ${className || ''}`}>
      <div ref={wrapperRef} className="relative inline-block text-left ml-4">
        <button
          data-testid="customer-three-dots-menu"
          className="focus:outline-none"
          type="button"
          onClick={handleButtonClick}
        >
          {THREE_DOTS_ICON}
        </button>
        {isOpen && (
          <div className="origin-top-right absolute right-0 mt-1 rounded-md shadow-lg z-10">
            <div className="rounded-md bg-white shadow-xs">
              <div
                className="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <div className="m-2">
                  <fieldset>
                    {(Object.keys(options) as Array<keyof typeof options>).map((key) => (
                      <div className="hover:bg-gray-200" key={key + idx}>
                        {getOption(key)}
                      </div>
                    ))}
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerAllowedDomainsThreeDots;
