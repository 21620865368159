import React from 'react';
import { getSelectedCommunicationWithEvents } from 'selectors/communications';
import { useSelector } from 'store';

import { addEnvelopeToSavedSearch } from 'actions';
import Permissions from 'components/Permissions';
import ReviewSetSelect from 'components/ReviewSets/ReviewSetSelect';
import { useDispatch } from 'react-redux';
import getSelectedEnvelope, {
  ATTACHMENT_TYPES,
  getSelectedSection,
} from 'selectors/envelopeReview';
import { SavedSearch } from 'types';
import AttachmentContent from './AttachmentContent';
import CommunicationContent from './CommunicationContent';
import CommunicationModelsSection from './CommunicationModelsSection';
import NoMatchCommunicationContainer from './NoMatchCommunicationContainer';

const CommunicationContainer: React.FC = () => {
  const dispatch = useDispatch();

  const selectedEnvelope = useSelector(getSelectedEnvelope);
  const selectedCommunication = useSelector(getSelectedCommunicationWithEvents);
  const selectedSection = useSelector(getSelectedSection);

  const handleSelectReviewSet = (reviewSet: SavedSearch): void => {
    if (selectedEnvelope) {
      dispatch(addEnvelopeToSavedSearch({ envelope: selectedEnvelope, savedSearch: reviewSet }));
    }
  };

  const renderContent = (): JSX.Element | null => {
    if (!selectedCommunication) return null;
    if (
      ATTACHMENT_TYPES.includes(selectedCommunication.communication_type) &&
      selectedSection === 'attachment'
    ) {
      return <AttachmentContent communication={selectedCommunication} />;
    }
    return <CommunicationContent communication={selectedCommunication} />;
  };

  const renderEvent = (): JSX.Element | null => {
    if (!selectedCommunication) return null;

    // check if there are events
    if (selectedCommunication.events && selectedCommunication.events.length > 0) {
      return renderContent();
    }

    return <NoMatchCommunicationContainer />;
  };

  return (
    <div className="w-full h-full flex flex-col gap-2 overflow-hidden">
      <div className="pr-6 self-end">
        <Permissions action="reviewSets.appendEnvelope">
          <ReviewSetSelect selectReviewSet={handleSelectReviewSet} />
        </Permissions>
      </div>
      {renderEvent()}
      <Permissions action="communication_envelopes.model_transparency">
        <CommunicationModelsSection />
      </Permissions>
    </div>
  );
};
export default CommunicationContainer;
