import EmptyTableState from 'components/EmptyTableState';
import LoadingIndicator from 'components/LoadingIndicator';
import RuleListItem from 'components/RulesList/RuleListItem';
import { useSelector } from 'store';

import FilterListHeaderRedesign from 'components/Filters/FilterListHeaderRedesign';
import ListFooter from 'components/ListFooter';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getFetchAllRulesLoading, getRulesList, getRulesTotalCount } from 'selectors/rules';
import type { MRule, UUID } from 'types';

type ComponentProps = {
  rules: UUID[] | MRule[];
  campaignId?: UUID;
  loading?: boolean;
};

const isStringArray = (arr: MRule[] | string[]): arr is string[] =>
  !!(arr[0] && typeof arr[0] === 'string');

const RulesTable: React.FC<ComponentProps> = ({ rules, campaignId, loading }) => {
  const rulesArray = useSelector(getRulesList);

  let campaignRules: MRule[] | string[] = [];

  if (isStringArray(rules)) {
    campaignRules = rulesArray
      .filter((r) => rules.includes(r.uuid))
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((r) => r.uuid);
  } else {
    campaignRules = rules.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner={<LoadingIndicator size="10" />}
      fadeSpeed={0}
      styles={{
        content: (base) => ({
          ...base,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }),
        wrapper: () => ({}),
        spinner: () => ({}),
        overlay: (base) => ({
          ...base,
          zIndex: 10,
        }),
      }}
    >
      <div className="table-wrapper__new">
        <table className="table-wrapper__table">
          <thead>
            <tr>
              {campaignId != null ? (
                <th className="table-wrapper__new-th pl-8">Model</th>
              ) : (
                <FilterListHeaderRedesign
                  resource={resourceQueryParamName.rule}
                  text="Model"
                  field="name"
                  className="pl-8"
                />
              )}

              {campaignId != null ? (
                <th className="table-wrapper__new-th table-wrapper__th--w-12 pl-2 ">
                  Last Modified
                </th>
              ) : (
                <FilterListHeaderRedesign
                  resource={resourceQueryParamName.rule}
                  text="Last Modified"
                  field="updated_at"
                  className="table-wrapper__new-th table-wrapper__th--w-15"
                />
              )}

              {campaignId != null ? (
                <th className="table-wrapper__new-th table-wrapper__th--w-15 ">Modified By</th>
              ) : (
                <th className="table-wrapper__new-th table-wrapper__th--w-20">Modified By</th>
              )}

              <th className="table-wrapper__new-th table-wrapper__th--w-12">Test Cases</th>

              <th className="table-wrapper__new-th table-wrapper__th--w-12">Tests Results</th>

              {campaignId && (
                <>
                  <th className="table-wrapper__new-th">Outcomes</th>
                  <th
                    aria-label="Delete model"
                    className="table-wrapper__new-th table-wrapper__th--w-8"
                  />
                </>
              )}
            </tr>
          </thead>
          <tbody className="table-wrapper__tbody" data-testid="rules-table">
            {!loading && rules?.length === 0 ? (
              <EmptyTableState message="There are no models to display" colCount="4" />
            ) : (
              campaignRules.map((rule) => (
                <RuleListItem
                  key={typeof rule !== 'string' ? rule.uuid : rule}
                  ruleId={typeof rule !== 'string' ? rule.uuid : rule}
                  campaignId={campaignId || ''}
                />
              ))
            )}
          </tbody>
        </table>
        {!campaignId && (
          <ListFooter
            resourceStateName={resourceQueryParamName.rule}
            resourceName="model"
            getResourceList={getRulesList}
            getResourceTotalCount={getRulesTotalCount}
            getResourceLoading={getFetchAllRulesLoading}
          />
        )}
      </div>
    </LoadingOverlay>
  );
};

export default RulesTable;
