import React from 'react';
import { useSelector } from 'react-redux';
import { Selector, UUID } from 'types';

type ComponentProps<N, S> = {
  handleDelete: (labelId: string, exclude: boolean) => void;
  filterData: string[];
  notFilterData: string[];
  andFilterData: string[];
  andNotFilterData: string[];
  getResourceList: S;
  nameKey: N;
};

const ShowLabels = <
  N extends string,
  T extends { uuid: UUID; color?: string | null; envelopes_count?: number } & Record<
    N,
    string | null
  >,
  S extends Selector<T[], []>
>({
  handleDelete,
  getResourceList,
  filterData,
  notFilterData,
  andFilterData,
  andNotFilterData,
  nameKey,
}: ComponentProps<N, S>): ReturnType<React.FC> => {
  const allData = useSelector(getResourceList);

  return (
    <div className="flex flex-col">
      {[...filterData, ...notFilterData, ...andFilterData, ...andNotFilterData].map((labelId) => {
        const label = allData.find((tag) => tag.uuid === labelId);
        if (label) {
          return (
            <div
              key={label.uuid}
              role="button"
              aria-hidden
              className="relative flex justify-start items-center mt-2.5"
              onClick={(): void => handleDelete(label.uuid, notFilterData.includes(label.uuid))}
            >
              <div className="flex items-center h-5 mr-1">
                <input
                  id={label.uuid}
                  data-testid={`${label.uuid}-checkbox`}
                  type="checkbox"
                  className={`form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out cursor-pointer ${
                    notFilterData.includes(labelId) || andNotFilterData.includes(labelId)
                      ? 'litlingo-checkbox-negated'
                      : ''
                  }`}
                  checked={
                    filterData.includes(label.uuid) ||
                    notFilterData.includes(label.uuid) ||
                    andFilterData.includes(labelId) ||
                    andNotFilterData.includes(labelId)
                  }
                />
              </div>
              <div className="text-sm leading-5">
                <label htmlFor={label.uuid} className="text-white text-xss cursor-pointer">
                  <span className="select-none break-all">{label[nameKey]} </span>
                </label>
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default ShowLabels;
