import EmptyTableState from 'components/EmptyTableState';
import ListFooter from 'components/ListFooter';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';
import { useSelector } from 'react-redux';
import type { AuditLog, Selector } from 'types';
import AuditLogsTableItem from './AuditLogsTableItem';

type AuditLogsTableProps = {
  getList: Selector<AuditLog[]>;
  getCount?: Selector<number>;
  getLoading?: Selector<boolean>;
};

const AuditLogsTable: React.FC<AuditLogsTableProps> = (props) => {
  const { getList, getCount, getLoading } = props;

  const logs = useSelector(getList);

  return (
    <div className="table-wrapper__new">
      <table className="table-wrapper__table">
        <thead>
          <tr>
            <th aria-hidden className="table-wrapper__th table-wrapper__th--w-2" />
            <th className="table-wrapper__new-th table-wrapper__th--w-50">Action</th>
            <th className="table-wrapper__new-th table-wrapper__th--w-20">User</th>
            <th className="table-wrapper__new-th table-wrapper__th--w-20">Date</th>
            <th aria-label="expand" className="table-wrapper__th table-wrapper__th--w-4" />
          </tr>
        </thead>

        <tbody className="table-wrapper__new-tbody">
          {logs.length === 0 ? (
            <EmptyTableState message="There are no logs to display" colCount="3" />
          ) : (
            logs.map((log) => <AuditLogsTableItem log={log} key={log.uuid} />)
          )}
        </tbody>
      </table>

      {getCount != null && getLoading != null && (
        <ListFooter
          resourceName="audit log"
          resourceStateName={resourceQueryParamName.auditLogs}
          getResourceList={getList}
          getResourceTotalCount={getCount}
          getResourceLoading={getLoading}
        />
      )}
    </div>
  );
};

export default AuditLogsTable;
