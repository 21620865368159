/* eslint-disable jsx-a11y/control-has-associated-label */
import { fetchUsersForFilter, selectReviewSet } from 'actions';
import SearchInput from 'components/SearchInput';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useEffect } from 'react';

import DropdownFilter from 'components/Filters/DropdownFilter/DropdownFilter';
import InnerNavbar from 'components/Navbar/InnerNavbar';
import { reviewManagementNavigationOptions } from 'components/ReviewSetsManagerView/ReviewSetsList';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getSavedSearchesList,
  getSavedSearchesLoading,
  getSelectedReviewSet,
} from 'selectors/savedSearches';
import { getUsersList, getUsersLoading } from 'selectors/users';
import ReviewSetTable from './SavedSearchesTable';

const ReviewSetsList: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const inSamples = location.pathname.includes('samples');

  const loading = useSelector(getSavedSearchesLoading);
  const reviewSets = useSelector(getSavedSearchesList);
  const selectedReviewSet = useSelector(getSelectedReviewSet);

  useEffect(() => {
    if (!reviewSets[0] || loading) {
      dispatch(selectReviewSet({ reviewSet: null }));
      return;
    }

    if (!selectedReviewSet || !reviewSets.find((r) => r.uuid === selectedReviewSet.uuid)) {
      dispatch(selectReviewSet({ reviewSet: reviewSets[0] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewSets, loading]);

  return (
    <div className="flex flex-col gap-4 py-8 pl-6 pr-5 h-full overflow-hidden">
      <div className="flex flex-col gap-2">
        <div className="text-heading-2">Review Management</div>
        <InnerNavbar
          options={reviewManagementNavigationOptions}
          selectedOption={inSamples ? 'samples' : 'saved-searches'}
        />
      </div>
      <div className="flex flex-col items-start gap-4 h-full w-full overflow-hidden">
        <div className="flex flex-row justify-between w-full">
          <SearchInput
            resource={resourceQueryParamName.savedSearch}
            field="broad_search"
            className="h-8 max-w-64"
            placeholder={inSamples ? 'Search samples' : 'Search by saved search'}
          />

          {/* FRO-1090 <div className="w-40 mr-px">
            <SortFilter resource={resourceQueryParamName.savedSearch} />
          </div> */}
          <div className="w-40">
            <DropdownFilter
              resource={resourceQueryParamName.savedSearch}
              fetchAction={fetchUsersForFilter}
              filterKey="created_by"
              itemField="uuid"
              getResourceList={getUsersList}
              getLoading={getUsersLoading}
              title="Created By"
              nameKey="name"
              notFoundText="No users found"
            />
          </div>
        </div>
        <ReviewSetTable />
      </div>
    </div>
  );
};

export default ReviewSetsList;
