import LinkLookup from 'components/LinkLookup';
import React from 'react';
import type { TagGroup } from 'types';

interface Props {
  tagGroup: TagGroup;
}

const TagGroupListItem: React.VFC<Props> = ({ tagGroup }) => {
  const { uuid, name, description, tag_values: tags } = tagGroup;

  return (
    <LinkLookup key={uuid} routeName="tag-group-detail" routeParams={{ tagGroupId: uuid }}>
      <div className="flex flex-col gap-2 border rounded-md p-4 card-shadow">
        <h3 className="text-heading-3">{name}</h3>
        <p className="text-body">{description}</p>
        {tags && tags.length ? (
          <div className="flex items-start gap-1 mt-4">
            <div className="text-body whitespace-no-wrap">
              <span className="font-bold">
                {tags.length} Tag{tags.length > 1 ? 's' : ''}:
              </span>
            </div>
            <div className="flex flex-wrap gap-1">
              {tags.map(({ uuid: tagUuid, value: tagName }) => (
                <div
                  key={tagUuid}
                  className="bg-litlingo-primary-60 rounded px-1 text-xss text-litlingo-gray-6"
                >
                  {tagName.length > 15 ? `${tagName.slice(0, 15)}...` : tagName}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </LinkLookup>
  );
};

export default TagGroupListItem;
