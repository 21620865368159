import { UUID } from '@litlingo/client';
import { RequestParamsType, RequestResponse } from 'hooks/types/client';
import useRequest from '../client/useRequest';

type Options = { redirectOnError: boolean };

type UseCategories = (
  revisionsId: UUID,
  options?: Options
) => RequestResponse<'identifiers.extras.getCategories'>;

const useIdentifierCategories: UseCategories = (revisionsId, options) => {
  const params: RequestParamsType = {
    urlParams: { revisionsId },
    queryParams: {
      include_count: true,
      include_pii: true,
      relationships: ['updated_by', 'rule', 'customer', 'model'],
    },
    redirectOnError: options?.redirectOnError,
  };

  const result = useRequest('identifiers.extras.getCategories', params, {});

  return result;
};

export default useIdentifierCategories;
