/* eslint-disable camelcase */
import { getMatchedPlainText } from '@litlingo/react';
import { fetchEventSummary, saveTestCase } from 'actions';
import Modal from 'components/Modal';
import Permissions from 'components/Permissions';
import SelectAndSearchByResource from 'components/SelectAndSearchByResource';
import ToggleButton from 'components/ToggleButton';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEventFullMessageHighlighted,
  getMatchedSentencesFromEvent,
  getRawCorrespondence,
} from 'selectors/events';
import type { Rule, UUID } from 'types';

type ComponentProps = {
  toggleModal: () => void;
  eventId: UUID;
  rule?: Rule;
};

const ModalAddTestSuitFromEvent: React.FC<ComponentProps> = ({ toggleModal, eventId, rule }) => {
  const dispatch = useDispatch();
  const [phrase, setPhrase] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [selectedTestSuite, setSelectedTestSuite] = useState<
    'campaign_uuid' | 'rule_uuid' | 'annotator_uuid'
  >('rule_uuid');
  const [shouldTestFire, setShouldTestFire] = useState(true);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const resultLines = useSelector(getEventFullMessageHighlighted(eventId));
  const rawCorrespondence = useSelector(getRawCorrespondence(eventId));
  const matchedSentence = useSelector(getMatchedSentencesFromEvent(eventId));

  const options = {
    campaign_uuid: 'Use Case',
    rule_uuid: 'Model',
    annotator_uuid: 'Identifier',
  };

  const handleClickOnAdd = (): void => {
    dispatch(
      saveTestCase({
        rule_uuid: selectedTestSuite === 'rule_uuid' ? selectedId : null,
        campaign_uuid: selectedTestSuite === 'campaign_uuid' ? selectedId : null,
        annotator_uuid: selectedTestSuite === 'annotator_uuid' ? selectedId : null,
        platform: 'slack',
        should_match: shouldTestFire,
        test_string: phrase,
        event_uuid: eventId,
        comment,
      })
    );
    dispatch(fetchEventSummary({ eventId }));
    toggleModal();
  };

  const handleChangeTestSuite = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { value } = event.target;
    (Object.keys(options) as Array<keyof typeof options>).forEach((key) => {
      if (key === value) {
        setSelectedTestSuite(key);
        setSelectedId(null);
      }
    });
  };

  useEffect(() => {
    if (phrase === '') {
      if (matchedSentence) setPhrase(matchedSentence);
      else if (resultLines) {
        const filteredResultLines = resultLines.filter((line) => line.type === 'line');
        const fullText = getMatchedPlainText(filteredResultLines, true);
        setPhrase(fullText.length > 0 ? fullText : (rawCorrespondence as string));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultLines, matchedSentence]);

  const renderBody = (
    <div className="mt-4 text-left">
      <span className="text text--semibold">Test phrase</span>

      <textarea
        id="TestPhrase"
        name="test suit body"
        rows={3}
        onChange={(event): void => setPhrase(event.target.value)}
        className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 litlingo-gray-bg-color border-2 border-gray-200 h-32 overflow-auto p-2 text-sm"
        value={phrase}
      />

      <label htmlFor="comment" className=" mt-4 flex flex-col gap-0.5 text-small">
        <span className="text text--semibold">Comment</span>
        <input
          id="comment"
          name="comment"
          className="form-input h-9 w-full p-2 text-body rounded placeholder-italic placeholder-litlingo-gray-6"
          onChange={(event): void => setComment(event.target.value)}
        />
      </label>

      <Permissions action="test_cases.chooseEntity">
        <SelectAndSearchByResource
          title="Choose test suite"
          options={options}
          selectedEntity={selectedTestSuite}
          entity={rule}
          setSelectedId={setSelectedId}
          handleChangeEntity={handleChangeTestSuite}
          className="mt-8"
          cleanSearchValue
        />
      </Permissions>

      <span className="mr-4 flex flex-col items-start">
        <p className="mr-4 mt-8 text text--semibold">Expected result</p>
        <div className="flex">
          <ToggleButton
            isOn={shouldTestFire}
            handleToggleClick={(): void => setShouldTestFire(!shouldTestFire)}
          />
          <span className="ml-2">{shouldTestFire ? 'Match' : 'No match'}</span>
        </div>
      </span>
    </div>
  );

  return (
    <Modal
      body={renderBody}
      title="Add phrase to training set"
      okButton
      okButtonText="Add"
      okButtonOnClick={handleClickOnAdd}
      toggleShowModal={toggleModal}
      style={{ maxWidth: '50%' }}
    />
  );
};

export default ModalAddTestSuitFromEvent;
