/* eslint-disable max-lines */
import {
  postTestSentence,
  SaveTestCasePayload,
  setURLParams,
  testAnnotator,
  upsertTestCase,
} from 'actions';
import classNames from 'classnames';
import Badge from 'components/Badge';
import LinkLookup from 'components/LinkLookup';
import Permissions from 'components/Permissions';
import {
  COPY_CLIPBOARD,
  CURSOR_CLICK,
  DELETE_ICON,
  DUPLICATE_ICON,
  EDIT_ICON,
} from 'constants/commonIcons';
import NOTES_ICON from 'constants/testCaseListIcons';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getRule } from 'selectors/rule';
import type { MTestCase, Platform, TestCase, UUID } from 'types';
import { useHistory } from 'utils/urls';

type RouterParams = {
  ruleId: string;
  campaignId: string;
  annotatorId: string;
};

type ComponentProps = {
  testItem: TestCase;
  handleClickOnDelete: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: UUID) => void;
  handleClickOnDuplicate: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    testData: SaveTestCasePayload
  ) => void;
  handleCopyClick: (
    event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>,
    testPhrase: string
  ) => void;
  setSelectedTest: React.Dispatch<React.SetStateAction<MTestCase | null>>;
  setChangeEntityModal: React.Dispatch<React.SetStateAction<boolean>>;
  onRowSelect: (checked: boolean, uuid: UUID) => void;
  selected: boolean;
};

const TestCaseListItem: React.FC<ComponentProps> = (props) => {
  // this needs to be like this for tests to work
  const { useEffect } = React;

  const {
    testItem,
    handleClickOnDelete,
    handleClickOnDuplicate,
    handleCopyClick,
    setChangeEntityModal,
    setSelectedTest,
    onRowSelect,
    selected,
  } = props;

  const history = useHistory();

  const [notes, setNotes] = useState(testItem.comment);
  const [debouncedNotes, setDebouncedNotes] = useState(notes);
  const [hover, setHover] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [isEditingTestText, setIsEditingTestText] = useState(false);
  const [testText, setTestText] = useState(testItem.test_string);

  const { ruleId, campaignId, annotatorId } = useParams<RouterParams>();
  const rule = useSelector(getRule(ruleId)) || {};

  const dispatch = useDispatch();

  const ref = useRef<HTMLInputElement>(null);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const hiddenDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedNotes(notes), 500);
    return (): void => {
      clearTimeout(timeoutId);
    };
  }, [notes]);

  useEffect(() => {
    if (testItem.comment !== debouncedNotes) {
      dispatch(
        upsertTestCase({
          uuid: testItem.uuid,
          platform: testItem.platform,
          test_string: testItem.test_string,
          comment: debouncedNotes,
          should_match: testItem.should_match,
        })
      );
    }
  }, [
    debouncedNotes,
    dispatch,
    testItem.uuid,
    testItem.platform,
    testItem.test_string,
    testItem.should_match,
    testItem.comment,
  ]);

  const renderEntityLink = (testCase: TestCase): JSX.Element => {
    if (testCase.campaign_uuid) {
      return (
        <LinkLookup
          type="button"
          className="font-normal text-body flex items-center text text-litlingo-gray-600 underline hover:text-litlingo-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
          routeName="campaign-detail"
          routeParams={{ campaignId: testCase.campaign_uuid }}
        >
          Use Case
          {testCase.campaign && ` - ${testCase.campaign.name}`}
        </LinkLookup>
      );
    }
    if (testCase.rule_uuid) {
      return (
        <LinkLookup
          type="button"
          routeName="rule-manager"
          routeParams={{ ruleId: testCase.rule_uuid }}
          className="font-normal text-body flex items-center text text-litlingo-gray-600 underline hover:text-litlingo-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
        >
          Model
          {testCase.rule && ` - ${testCase.rule.name}`}
        </LinkLookup>
      );
    }
    if (testCase.annotator_uuid) {
      return (
        <LinkLookup
          routeName="annotator-detail"
          routeParams={{ annotatorId: testCase.annotator_uuid }}
          type="button"
          className="font-normal text-body flex items-center text text-litlingo-gray-600 underline hover:text-litlingo-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-700 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
        >
          Identifier
          {testCase.annotator && ` - ${testCase.annotator.name}`}
        </LinkLookup>
      );
    }
    return <span className="text">No entity</span>;
  };

  const handleInputFocus = (e: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
    e.preventDefault();
    e.stopPropagation();
    setInputFocused(true);
  };

  const handleCopyAndPaste = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(
      setURLParams({
        [`body`]: testItem.test_string,
      })
    );

    const rules = rule.uuid ? [rule.uuid] : undefined;
    const campaigns = campaignId ? [campaignId] : undefined;
    if (annotatorId) {
      dispatch(testAnnotator({ body: testItem.test_string, annotator: annotatorId }));
    } else {
      let platforms: Platform[] = [];
      platforms = ['all'];

      dispatch(postTestSentence({ body: testItem.test_string, rules, campaigns, platforms }));
    }
  };

  const renderNotes = (): JSX.Element => {
    if (notes || inputFocused) {
      return (
        <>
          {NOTES_ICON}
          <input
            ref={ref}
            placeholder="Add Note..."
            data-testid="notes-input"
            value={notes || ''}
            onChange={(e): void => setNotes(e.target.value)}
            onClick={(e): void => handleInputFocus(e)}
            onBlur={(): void => setInputFocused(false)}
            className="text-litlingo-gray-300 italic px-1 mx-1 w-full text-sx block border border-transparent border-solid box-border bg-transparent transition duration-150 ease-in-out sm:text-sm sm:leading-5 hover:border hover:border-litlingo-gray-200 hover:border-solid focus:border focus:border-litlingo-gray-200 focus:border-solid"
          />
        </>
      );
    }
    return (
      <>
        {inputFocused && NOTES_ICON}
        <input
          ref={ref}
          placeholder="Add Note..."
          data-testid="notes-input"
          value={notes || ''}
          onChange={(e): void => setNotes(e.target.value)}
          onClick={(e): void => handleInputFocus(e)}
          onBlur={(): void => setInputFocused(false)}
          className={`w-full text-xs text-litlingo-gray-300 block px-1 border border-transparent border-solid box-border bg-transparent cursor-pointer transition duration-150 ease-in-out sm:text-sm sm:leading-5 hover:border hover:border-litlingo-gray-200 hover:border-solid focus:border focus:border-litlingo-gray-200 focus:border-solid  focus:w-full focus:px-1 focus:mx-1 focus:italic ${
            !hover && !inputFocused && 'invisible'
          }`}
        />
      </>
    );
  };

  const renderCopyAndPaste = (): JSX.Element | null => {
    if (history.location.pathname.includes('test-cases')) {
      return null;
    }
    return (
      <td className="table-wrapper__new-td px-0">
        {hover && (
          <button
            className="mt-2 focus:outline-none ext-gray-700 hover:text-black"
            data-testid="copy-paste-button"
            type="button"
            onClick={(event): void => handleCopyAndPaste(event)}
          >
            {CURSOR_CLICK}
          </button>
        )}
      </td>
    );
  };

  const handleRowCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();

    onRowSelect(e.target.checked, testItem.uuid);
    const element = document.activeElement as HTMLInputElement;
    if (element) {
      element.blur();
    }
  };

  useEffect(() => {
    if (textareaRef && textareaRef.current && hiddenDivRef && hiddenDivRef.current) {
      // show div with test phrase to get its height
      hiddenDivRef.current.innerHTML = `${textareaRef.current.value.replace(/\n/g, '<br>')}`;
      hiddenDivRef.current.style.visibility = 'hidden';
      hiddenDivRef.current.style.display = 'block';

      const textAreaDefaultLineHeight = window.getComputedStyle(textareaRef.current).lineHeight;
      const nRows = 5;
      const fiveRowsHeight = nRows * parseInt(textAreaDefaultLineHeight, 10);
      // set textArea height the same as the div if this is smaller than 5 rows
      if (fiveRowsHeight >= hiddenDivRef.current.offsetHeight) {
        textareaRef.current.style.height = `${hiddenDivRef.current.offsetHeight}px`;
      }
      // hide div again
      hiddenDivRef.current.style.display = 'none';
    }
  });

  return (
    <LinkLookup
      onMouseEnter={(): void => setHover(true)}
      onMouseLeave={(): void => setHover(false)}
      routeName="test-cases-detail"
      routeParams={{ testCaseId: testItem.uuid }}
      className={classNames('table-wrapper__new-tr table-row align-top', {
        'litlingo-gray-bg-color-4': hover,
        'bg-litlingo-complementary-lighter-yellow': testItem.priority === 10,
      })}
      as="tr"
    >
      <td className="table-wrapper__new-td ">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
        <div className="table-wrapper__new-cell-content h-12 flex">
          <input
            id={`checkbox-test-case-${testItem.uuid}`}
            type="checkbox"
            className="cursor-pointer form-checkbox litlingo-checkbox w-5 h-5"
            onChange={handleRowCheckboxChange}
            onClick={(e): void => e.stopPropagation()}
            checked={selected}
          />
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-auto pr-4 flex flex-col items-start text-body">
          <div className="flex items-center w-full">
            <div className="overflow-hidden w-full">
              <div className="text text--extra-small litlingo-flex-break-words w-full">
                <div ref={hiddenDivRef} className="hidden">
                  {testText}
                </div>
                <textarea
                  ref={textareaRef}
                  value={testText}
                  rows={5}
                  onFocus={(): void => setIsEditingTestText(true)}
                  onChange={(e): void => setTestText(e.target.value)}
                  onClick={(e): void => e.stopPropagation()}
                  onBlur={(e): void => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsEditingTestText(false);
                    if (testItem.test_string !== testText) {
                      dispatch(
                        upsertTestCase({
                          uuid: testItem.uuid,
                          platform: testItem.platform,
                          test_string: testText,
                          should_match: testItem.should_match,
                        })
                      );
                    }
                  }}
                  className={`text-body  block border border-transparent border-solid box-border bg-transparent transition duration-150 ease-in-out sm:leading-5 hover:border hover:border-litlingo-gray-200 hover:border-solid focus:border focus:border-litlingo-gray-200 focus:border-solid w-full resize-none overflow-${
                    isEditingTestText ? 'y-auto' : 'hidden'
                  }`}
                />
              </div>
            </div>
          </div>
          {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
          <div
            className="w-full py-2 flex flex-row items-center"
            role="button"
            onClick={(e): void => {
              e.preventDefault();
              if (ref && ref.current) {
                setInputFocused(true);
                ref.current.focus();
              }
            }}
            onKeyDown={(): void => {
              // do-nothing
            }}
          >
            {renderNotes()}
          </div>
        </div>
      </td>
      <td className="table-wrapper__new-td px-0">
        {hover && !isEditingTestText && (
          <button
            className="mt-2 focus:outline-none ext-gray-700 hover:text-black"
            type="button"
            data-testid="copy-button"
            onClick={(event): void => handleCopyClick(event, testItem.test_string)}
          >
            {COPY_CLIPBOARD}
          </button>
        )}
      </td>
      {renderCopyAndPaste()}
      <td className="table-wrapper__new-td table-wrapper__td--align-left py-2">
        <div className="flex items-center">
          <div>
            <div className="text text-body font-normal text-litlingo-gray-600">
              {testItem.should_match ? 'Match' : 'No match'}
            </div>
          </div>
        </div>
      </td>
      <td className="table-wrapper__new-td table-wrapper__td--align-left py-2">
        <div className="flex items-center w-full">
          {testItem.most_recent_ran_at && (
            <div className="flex flex-col items-start w-full">
              <div className="flex flex-col items-center">
                <div className="text font-medium">
                  {testItem.most_recent_run_passed === true ? (
                    <span className="px-2 inline-flex text-body leading-5 rounded-full bg-litlingo-green-bg text-litlingo-green-text">
                      PASSED
                    </span>
                  ) : (
                    <span className="px-2 inline-flex text-body leading-5 rounded-full bg-litlingo-red-bg text-litlingo-red-text">
                      FAILED
                    </span>
                  )}
                </div>
                <div className="text text--lighter-2">
                  {moment(testItem.most_recent_ran_at).fromNow()}
                </div>
              </div>
            </div>
          )}
        </div>
      </td>
      <Permissions action="test_cases.tags">
        <td className="table-wrapper__new-td table-wrapper__td--align-left py-2">
          <div className="flex items-center">
            <div className="overflow-hidden">
              <div className="flex flex-col items-start gap-1">
                {testItem.tags &&
                  testItem.tags.map((t) => (
                    <Badge
                      key={t.uuid}
                      colorClass={t.tag_value?.color || ''}
                      title={t.tag_value?.value || ''}
                    />
                  ))}
              </div>
            </div>
          </div>
        </td>
      </Permissions>
      <td className="table-wrapper__new-td table-wrapper__td--align-left py-2">
        <div className="flex items-center ">
          <div className="overflow-hidden">
            <div className="text text-body litlingo-flex-break-words">
              {testItem.created_by && testItem.created_by.name}
            </div>
          </div>
        </div>
      </td>
      <td className="table-wrapper__new-td py-2">
        <div className="flex flex-row justify-between items-center ">
          <span className="text">{renderEntityLink(testItem)}</span>
          <button
            type="button"
            className="mx-1 self-start focus:outline-none"
            onClick={(e): void => {
              e.preventDefault();
              e.stopPropagation();
              setSelectedTest(testItem);
              setChangeEntityModal(true);
            }}
          >
            {EDIT_ICON(25)}
          </button>
        </div>
      </td>
      <td className="table-wrapper__new-td py-2 flex flex-col justify-center items center">
        {hover && (
          <div className="flex flex-row h-full justify-center items-center mr-2">
            <button
              type="button"
              onClick={(event): void => {
                const testData = {
                  test_string: testItem.test_string,
                  should_match: testItem.should_match,
                  platform: testItem.platform,
                  annotator_uuid: testItem.annotator_uuid,
                  rule_uuid: testItem.rule_uuid,
                  campaign_uuid: testItem.campaign_uuid,
                };
                handleClickOnDuplicate(event, testData);
              }}
              className="focus:outline-none"
            >
              {DUPLICATE_ICON}
            </button>
            <button
              type="button"
              data-testid="delete-button"
              onClick={(event): void => handleClickOnDelete(event, testItem.uuid)}
              className="focus:outline-none"
            >
              {DELETE_ICON}
            </button>
          </div>
        )}
      </td>
    </LinkLookup>
  );
};

export default TestCaseListItem;
