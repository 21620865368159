import type { RecursivePartial } from '@types';

export type NodeEnv = 'test' | 'development' | 'production';

const base = {
  node: {
    env: process.env.NODE_ENV as NodeEnv,
  },
  api: {
    base: '__REPLACE__',
    v1: '__REPLACE__',
    v2: '__REPLACE__',
    integrations: '__REPLACE__',
    customers: '__REPLACE__',
    user: '__REPLACE__',
  },
  auth: {
    v1: 'https://accounts.litlingo.com/auth/api/v1',
    config: 'https://accounts.litlingo.com/.well-known/openid-configuration',
    logout: 'https://accounts.litlingo.com/oauth2/v1/logout',
    storageKey: 'LitLingoAuth__',
    expirationLeeway: 3600, // 1 hour
  },
};

export type Config = typeof base;
export type PartialConfig = RecursivePartial<Config>;

export default base;
