import React from 'react';

type ComponentProps = {
  addNewLanguageMatcher: () => void;
};

const EmptyLanguageMatcherState: React.FC<ComponentProps> = ({ addNewLanguageMatcher }) => (
  <div className="flex flex-col justify-center items-center">
    <span className="text text--large">Select a language matcher or add a new one</span>
    <span className="mt-3 shadow-sm rounded-md">
      <button type="button" className="button button--primary" onClick={addNewLanguageMatcher}>
        Add new language matcher
      </button>
    </span>
  </div>
);

export default EmptyLanguageMatcherState;
