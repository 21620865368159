const triggerDownload = (blobData: Blob, fileTitle: string): void => {
  // a little hacky, but needed to download the file
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(new Blob([blobData]));
  link.download = `${fileTitle}.csv`;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export default triggerDownload;
