import React from 'react';

type DocumentVizArrowProps = {
  rootX: number | null;
  headX: number | null;
  bottomY: number;
  topY: number;
  text: string;
  geekMode: string;
  flipped?: boolean;
};

const DocumentVizArrow: React.FC<DocumentVizArrowProps> = (props) => {
  const {
    rootX,
    headX,
    bottomY,
    topY,
    text,
    geekMode,
    flipped = false,
  } = props;

  if (rootX == null || headX == null) {
    return null;
  }

  const arrow = (
    <g className="litlingo__doc_viz__arrow">
      <polyline
        className="litlingo__doc_viz__arrow__line"
        points={`${rootX},${bottomY} ${rootX},${topY} ${headX},${topY} ${headX},${bottomY}`}
      />
      <text
        data-testid="text"
        className="litlingo__doc_viz__arrow__text"
        x={(rootX + headX) / 2}
        y={flipped ? topY + 14 : topY - 5}
      >
        {text}
      </text>
    </g>
  );

  return (
    <>
      {geekMode === 'advanced_mode' ? (
        <a
          href={`https://universaldependencies.org/docs/en/dep/${text}`}
          target="_blank"
          rel="noreferrer"
        >
          {arrow}
        </a>
      ) : (
        arrow
      )}
    </>
  );
};

export default DocumentVizArrow;
