import AbridgedWholeSentence from 'components/AbridgedWholeSentence';
import HighlightedSentenceComponent from 'components/HighlightedSentenceComponent';
import LinkLookup from 'components/LinkLookup';
import Redacted from 'components/Redacted';
import React, { useEffect, useState } from 'react';
import {
  getEventSummaryLoading,
  getEventWholeSetences,
  getRelatedEvents,
  getSingleEvent,
} from 'selectors/events';
import { useSelector } from 'store';
import type { UUID } from 'types';
import logEvent from 'utils/analytics';
import { MESSAGE_TYPES, oldToNewLabel } from '../utils/EventOptions';

type ComponentProps = {
  eventId: UUID;
  commUuid: UUID | null;
  isCommEnvelope?: boolean;
};

const EventContent: React.FC<ComponentProps> = ({ eventId, commUuid, isCommEnvelope }) => {
  const FULL_OPTIONS = {
    HIGHLIGHTED: 'HIGHLIGHTED',
    PLAIN: 'PLAIN',
  };

  const [messageType, setMessageType] = useState(MESSAGE_TYPES.RAW_CORRESPONDENCE);
  const [fullMessageOptions, setFullMessageOptions] = useState(FULL_OPTIONS.HIGHLIGHTED);
  const relatedEvents = useSelector((state) => getRelatedEvents(state, eventId));
  const loading = useSelector(getEventSummaryLoading);
  const wholeSentence = useSelector(getEventWholeSetences(eventId));
  const event = useSelector((state) => getSingleEvent(state, eventId));

  useEffect(() => {
    setMessageType(MESSAGE_TYPES.RAW_CORRESPONDENCE);
    setFullMessageOptions(FULL_OPTIONS.HIGHLIGHTED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const getFormattedName = (name: keyof typeof oldToNewLabel): string => {
    const newName = oldToNewLabel[name];
    return `${newName[0].toUpperCase()}${newName.slice(1)}`;
  };

  const renderMessageBody = (): JSX.Element | null => {
    if (wholeSentence?.length && messageType === MESSAGE_TYPES.WHOLE_SENTENCE) {
      return (
        <Redacted field="events.audit">
          <AbridgedWholeSentence loading={loading} eventId={eventId} messageType={messageType} />
        </Redacted>
      );
    }

    if (messageType === MESSAGE_TYPES.WHOLE_SENTENCE && wholeSentence?.length === 0) {
      setMessageType(MESSAGE_TYPES.RAW_CORRESPONDENCE);
      setFullMessageOptions(FULL_OPTIONS.HIGHLIGHTED);
    }

    if (messageType === MESSAGE_TYPES.RAW_CORRESPONDENCE && !loading) {
      return (
        <Redacted field="communications.body">
          <div className="flex flex-col">
            {event.communication?.subject && fullMessageOptions === FULL_OPTIONS.HIGHLIGHTED && (
              <>
                <span className="text block">Subject:</span>
                <HighlightedSentenceComponent
                  eventId={eventId}
                  commUuid={commUuid}
                  fullMessageOptions={fullMessageOptions}
                  onlySubject
                />
              </>
            )}
            {fullMessageOptions === FULL_OPTIONS.HIGHLIGHTED && (
              <span className="text block">Body:</span>
            )}
            <HighlightedSentenceComponent
              eventId={eventId}
              commUuid={commUuid}
              fullMessageOptions={fullMessageOptions}
            />
          </div>
        </Redacted>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col pt-5 px-5 text-left w-full">
      <h2 className="text-left my-2 text font-bold text-sm">Message</h2>
      <div>
        <div className="hidden sm:block">
          <div className="relative">
            <nav className="flex justify-between ml-4">
              <div>
                {wholeSentence != null && wholeSentence?.length > 0 && (
                  <button
                    type="button"
                    onClick={(): void => {
                      logEvent('event-details-matches');
                      setMessageType(MESSAGE_TYPES.WHOLE_SENTENCE);
                    }}
                    className={`${
                      messageType === MESSAGE_TYPES.WHOLE_SENTENCE
                        ? 'litlingo-envelope-tab-message-box-selected'
                        : ''
                    } litlingo-envelope-tab-message-box`}
                  >
                    Matches
                  </button>
                )}
                <button
                  type="button"
                  onClick={(): void => {
                    logEvent('event-details-full-message');
                    setMessageType(MESSAGE_TYPES.RAW_CORRESPONDENCE);
                    setFullMessageOptions(FULL_OPTIONS.HIGHLIGHTED);
                  }}
                  className={`${
                    messageType === MESSAGE_TYPES.RAW_CORRESPONDENCE &&
                    fullMessageOptions === FULL_OPTIONS.HIGHLIGHTED
                      ? 'litlingo-envelope-tab-message-box-selected'
                      : ''
                  } litlingo-envelope-tab-message-box`}
                >
                  Full Message
                </button>
                <button
                  className={`litlingo-envelope-tab-message-box ${
                    messageType === MESSAGE_TYPES.RAW_CORRESPONDENCE &&
                    fullMessageOptions === FULL_OPTIONS.PLAIN
                      ? 'litlingo-envelope-tab-message-box-selected'
                      : ''
                  }`}
                  type="button"
                  onClick={(): void => {
                    logEvent('event-details-raw-text');
                    setMessageType(MESSAGE_TYPES.RAW_CORRESPONDENCE);
                    setFullMessageOptions(FULL_OPTIONS.PLAIN);
                  }}
                >
                  Raw Text
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="bg-white px-4 overflow-y-auto litlingo-envelope-message-box py-6">
        {renderMessageBody()}
      </div>
      <div>
        {relatedEvents.length !== 0 && !isCommEnvelope && (
          <div className="mt-8">
            <h3>Other events in this message</h3>
            <div className="">
              {relatedEvents.map((relatedEvent) => (
                <div
                  key={relatedEvent.uuid}
                  className="flex mt-2 border-b border-solid border-gray-200"
                >
                  <div className="w-9/12 flex flex-row">
                    <LinkLookup
                      routeName="envelope-list"
                      routeParams={{ eventId: relatedEvent.uuid }}
                      className="mt-1 group items-center py-2 text text-blue-800 rounded-md focus:outline-none transition ease-in-out duration-150 truncate"
                    >
                      <span className="">{`${relatedEvent.campaign?.name}`}</span>
                    </LinkLookup>
                    <span className="self-center text-sm pt-1 px-1">/</span>
                    <LinkLookup
                      routeName="envelope-list"
                      routeParams={{ eventId: relatedEvent.uuid }}
                      className="mt-1 group items-center py-2 text text-blue-800 rounded-md focus:outline-none transition ease-in-out duration-150 truncate"
                    >
                      <span className="">
                        {`${relatedEvent.rule != null && relatedEvent.rule.name}`}
                      </span>
                    </LinkLookup>
                  </div>
                  <div className="self-center w-3/12 text-gray-700 text-sm truncate">
                    {getFormattedName(relatedEvent.state as keyof typeof oldToNewLabel)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="flex-shrink-0 justify-between flex mt-5">
        <span className="shadow-sm rounded-md" />
      </div>
    </div>
  );
};

export default EventContent;
