import { capitalize, startCase } from 'lodash';

export const printStringArray = (arr: string[], capitalized = false): string => {
  const result = arr.reduce((acc, value, idx) => {
    if (idx === 0) {
      return `${capitalized ? capitalize(startCase(value)) : startCase(value)}`;
    }
    return `${acc}, ${capitalized ? capitalize(startCase(value)) : startCase(value)}`;
  }, '');

  return result;
};

export const handleUnicode = (text: string): string =>
  text.replace(/\\u[\dA-F]{4}/gi, (match) =>
    String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
  );

export const handleEscaped = (text: string): string => {
  const result = text.replace(/(?!\\u)(\\[a-z]{1})/gi, (match) => {
    switch (match) {
      case '\\n':
        return '\n';
      case '\\t':
        return '\t';
      case '\\v':
        return '\v';
      case '\\b':
        return '\b';
      case '\\r':
        return '\r';
      default:
        return '';
    }
  });
  return result;
};

export default (text: string): string => {
  const result = handleUnicode(text);
  return handleEscaped(result);
};

export const verifyEmail =
  // eslint-disable-next-line no-control-regex
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

export const verifyStringNumber = /^\s*[+-]?(\d+|\d*\.\d+|\d+\.\d*)([Ee][+-]?\d+)?\s*%*$/;
