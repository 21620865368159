import { Team } from '@litlingo/client';
import { setURLParams } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import useClickOutside from 'components/utils/useClickOutside';
import { SEARCH_ICON } from 'constants/filterIcons';
import useTeams from 'hooks/teams/useTeams';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { getTeamUsersCount } from 'selectors/users';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';

type ComponentProps = {
  className?: string;
  resource: string;
};

const UserDivisionsFilter: React.FC<ComponentProps> = (props) => {
  const { className, resource } = props;

  const dispatch = useDispatch();
  const filters = (useSelector(getNavParamsByResource(resource)).team_uuids || []) as string[];
  const teamsUserCount = useSelector(getTeamUsersCount);

  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const { data, isLoading } = useTeams({ redirectOnError: false, broad_search: debouncedSearch });
  const options = data?.records || [];

  const handleClickOutside = useCallback(() => {
    setSearch('');
    setIsOpen(false);
  }, [setIsOpen]);

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  useEffect(() => {
    const t = setTimeout(() => setDebouncedSearch(search), 500);
    return (): void => {
      clearTimeout(t);
    };
  }, [search]);

  const handleButtonClick = (): void => {
    setIsOpen(true);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e?.target.value.toLowerCase();
    setSearch(value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, team: Team): void => {
    logEvent(`use-cases-enabled-filter-${team.uuid}`);
    e.stopPropagation();

    const idx = filters.indexOf(team.uuid);
    if (idx === -1) {
      dispatch(
        setURLParams({
          [`${resource}__team_uuids`]: [...filters, team.uuid],
          [`${resource}__offset`]: '0',
        })
      );
    } else {
      dispatch(
        setURLParams({
          [`${resource}__team_uuids`]: [...filters.slice(0, idx), ...filters.slice(idx + 1)],
          [`${resource}__offset`]: '0',
        })
      );
    }
  };

  const renderOptions = (): JSX.Element => {
    if (isLoading)
      return (
        <div className="flex h-20 items-center justify-center">
          <LoadingIndicator size="8" />
        </div>
      );

    return (
      <fieldset className="m-2 flex flex-col gap-2" data-testid="teams-list">
        {options.length > 0 ? (
          options.sort().map((team, idx) => (
            <div
              key={team.uuid}
              data-testid={`team-option-${idx}`}
              className="flex flex-row justify-between cursor-pointer"
            >
              <label
                htmlFor={`team-option-${team.uuid}`}
                className="flex flex-row items-center gap-1"
              >
                <input
                  type="checkbox"
                  id={`team-option-${team.uuid}`}
                  data-testid={`team-select-button-${idx}`}
                  onChange={(e): void => handleInputChange(e, team)}
                  checked={filters?.some((t) => t === team.uuid)}
                  className="form-checkbox litlingo-checkbox"
                />

                <div className="text-small select-none break-all">{team.name}</div>
              </label>
              <div className="text-small">
                {teamsUserCount[team.uuid] > 0 ? teamsUserCount[team.uuid] : '-'}
              </div>
            </div>
          ))
        ) : (
          <div className="text-small">No divisions found</div>
        )}
      </fieldset>
    );
  };

  return (
    <div ref={wrapperRef} className={`relative inline-block text-left w-full ${className || ''}`}>
      <div className="">
        <div className="relative flex flex-row items-center w-full h-8 bg-white">
          <div className="absolute left-0 ml-1.5 w-5">{SEARCH_ICON()}</div>
          <input
            id="search"
            name="search"
            data-testid="team-select-button"
            className={`rounded border border-litlingo-gray-2 h-full w-full text-litlingo-gray-6 pl-8 text-body placeholder-italic  ${
              filters.length ? 'placeholder-litlingo-gray-6' : 'placeholder-litlingo-gray-4'
            }`}
            value={search}
            placeholder="Division"
            onChange={handleSearchChange}
            onFocus={handleButtonClick}
            autoComplete="off"
          />
          {filters.length > 0 && !isOpen && (
            <div className="flex absolute right-0 mr-1.5 items-center justify-center w-4.5 h-4.5 rounded-full bg-litlingo-primary-60">
              <span className="">{filters.length}</span>
            </div>
          )}
        </div>
      </div>

      {isOpen && (
        <div
          className="origin-top-left absolute left-0 mt-1 w-52 rounded-md shadow-lg z-40"
          style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}
        >
          <div className="rounded bg-white">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="max-h-select-dropdown overflow-auto custom-scrollbar">
                {renderOptions()}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDivisionsFilter;
