import { createRuleAndOutcome, fetchSingleCampaign } from 'actions';
import Navbar from 'components/Navbar';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCustomerDomain } from 'selectors/auth';
import { useSelector } from 'store';
import type { UUID } from 'types';

type RouteParams = {
  campaignId: UUID;
};

type FormValues = {
  name: string;
  description: string;
};

const CreateRule: React.FC = () => {
  const { campaignId } = useParams<RouteParams>();
  const dispatch = useDispatch();
  const customerDomain = useSelector(getCustomerDomain);
  const { handleSubmit, register, errors } = useForm<FormValues>();

  const onSubmit = (values: FormValues): void => {
    dispatch(createRuleAndOutcome({ ...values, campaignId }));
  };

  useEffect(() => {
    dispatch(fetchSingleCampaign({ campaignId }));
  }, [campaignId, dispatch, customerDomain]);

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />
      <div className="py-10">
        <header>
          <div className="max-w-7xl mx-auto px-1 sm:px-12 lg:px-14">
            <div>
              <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                  <div className="flex flex-row items-center">
                    <h2 className="title">Add Model</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main>
          <div className="mt-10 mb-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-left">
            <div className="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 mb-4">
              <div className="md:grid md:grid-cols-3 md:gap-6 text-left">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Create a New Model
                  </h3>
                  <p className="mt-1 text">This is mostly used to identify your identifier later</p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Model Name
                          <input
                            id="name"
                            data-testid="rule-name-input"
                            name="name"
                            ref={register({
                              required: 'Required',
                            })}
                            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                          {errors.name && errors.name.message}
                        </label>
                      </div>
                      <div className="col-span-6 sm:col-span-4">
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Model Description
                          <input
                            id="description"
                            data-testid="rule-description-input"
                            name="description"
                            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            ref={register()}
                          />
                          {errors.description && errors.description.message}
                        </label>
                      </div>
                    </div>
                    <button
                      type="submit"
                      data-testid="create-rule-button"
                      className="mt-5 -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-litlingo-success active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                    >
                      <svg
                        className="h-5 w-5 text-gray-400"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 4v16m8-8H4" />
                      </svg>
                      <span className="ml-2">Add</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default CreateRule;
