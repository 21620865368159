/* eslint-disable camelcase */
import {
  evaluateTestSentenceFailure,
  evaluateTestSentenceRequest,
  evaluateTestSentenceSuccess,
  fetchAllCommunicationDocument,
  fetchAllCommunicationDocumentRequest,
  fetchAllCommunicationDocumentSuccess,
  fetchAttachments,
  fetchAttachmentsFailure,
  fetchAttachmentsFulfill,
  fetchAttachmentsRequest,
  fetchAttachmentsSuccess,
  fetchCommunicationDocument,
  fetchCommunicationDocumentFailure,
  fetchCommunicationDocumentRequest,
  fetchCommunicationDocumentSuccess,
  fetchCommunicationSummary,
  fetchCommunicationSummaryFailure,
  fetchCommunicationSummaryRequest,
  fetchCommunicationSummarySuccess,
  postEvaluateTestSentence,
  postTestSentence,
  postTestSentenceV2,
  testAnnotator,
  testSentenceFailure,
  testSentenceRequest,
  testSentenceSuccess,
} from 'actions/communication';
import { previewRuleGroupSuccess } from 'actions/ruleGroup';
import { apiClient as LitLingoClient, apiClientV2 as LitLingoClientV2 } from 'client';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getCommunications } from 'selectors/communications';
import type { API, SagaReturn } from 'types';

function* postTestSentenceSaga(action: ReturnType<typeof postTestSentence>): SagaReturn {
  const { payload } = action;
  const { body, rules, campaigns, annotators, platforms } = payload;

  yield put(testSentenceRequest());
  const response = (yield call([LitLingoClient.resources.communications.extras, 'preview'], {
    data: {
      body,
      to: [{ type: 'channel', name: 'engineering', id: 'engineering' }],
      type: 'slack',
      platforms: platforms.map((p) => p.toLowerCase()),
      rules,
      campaigns,
      annotators,
    },
  })) as API.Response<API.Communications.Preview>;

  if (response.error != null) {
    yield put(testSentenceFailure(response.error));
  } else {
    yield put(testSentenceSuccess(response.data));
  }
}

function* postTestSentenceV2Saga(action: ReturnType<typeof postTestSentenceV2>): SagaReturn {
  const { payload } = action;
  const {
    body,
    platform,
    identifiers,
    models,
    subject,
    inRuleGroup = false,
    force_v2_graphs = false,
  } = payload;

  yield put(testSentenceRequest());
  const data: Record<string, unknown> = {
    body,
    to: [{ type: 'channel', name: 'engineering', id: 'engineering' }],
    type: 'slack',
    platform,
    force_v2_graphs,
  };
  if (subject) {
    data.subject = subject;
  }

  if (identifiers) {
    data.identifiers = identifiers;
  }
  if (models) {
    data.models = models;
  }
  const response = (yield call([LitLingoClientV2.resources.communications.extras, 'preview'], {
    data,
  })) as API.Response<API.Communications.Preview>;

  if (response.error != null) {
    yield put(testSentenceFailure(response.error));
  } else if (inRuleGroup) {
    yield put(previewRuleGroupSuccess(response.data));
  } else {
    yield put(testSentenceSuccess(response.data));
  }
}

function* fetchCommunicationSummarySaga(
  action: ReturnType<typeof fetchCommunicationSummary>
): SagaReturn {
  const { payload } = action;
  const { commId } = payload;

  yield put(fetchCommunicationSummaryRequest());

  const response = (yield call([LitLingoClient.resources.communications.extras, 'body'], {
    urlParams: { commUuid: commId },
  })) as API.Response<API.Communications.Body>;
  if (response.error != null) {
    yield put(fetchCommunicationSummaryFailure(response.error));
  } else {
    yield put(fetchCommunicationSummarySuccess({ ...response.data, uuid: commId }));
  }
}

function* postEvaluateTestSentenceSaga(
  action: ReturnType<typeof postEvaluateTestSentence>
): SagaReturn {
  const { payload } = action;
  const { body, platform } = payload;

  yield put(evaluateTestSentenceRequest());
  const response = (yield call([LitLingoClient.resources.communications.extras, 'evaluate'], {
    data: {
      body,
      to: [{ type: 'channel', name: 'engineering', id: 'engineering' }],
      type: 'slack',
      platform: platform.toLowerCase(),
    },
  })) as API.Response<API.Communications.Evaluate>;

  if (response.error != null) {
    yield put(evaluateTestSentenceFailure(response.error));
  } else {
    yield put(evaluateTestSentenceSuccess());
  }
}

function* testAnnotatorSaga(action: ReturnType<typeof testAnnotator>): SagaReturn {
  const { payload } = action;
  const { annotator, body } = payload;

  yield put(testSentenceRequest());
  const response = (yield call(
    [LitLingoClient.resources.communications.extras, 'previewAnnotations'],
    {
      data: {
        body,
        to: [{ type: 'channel', name: 'engineering', id: 'engineering' }],
        type: 'slack',
        platform: 'slack',
        annotator,
      },
    }
  )) as API.Response<API.Communications.PreviewAnnotations>;

  if (response.error != null) {
    yield put(testSentenceFailure(response.error));
  } else {
    yield put(testSentenceSuccess(response.data));
  }
}

function* fetchAttachmentsSaga(action: ReturnType<typeof fetchAttachments>): SagaReturn {
  const { payload } = action;
  const { commUuid } = payload;

  yield put(fetchAttachmentsRequest);
  const response = (yield call([LitLingoClient.resources.communications.extras, 'attachments'], {
    urlParams: { commUuid },
  })) as API.Response<API.Communications.Attachments>;

  if (response.error != null) {
    yield put(fetchAttachmentsFailure(response.error));
  } else {
    yield put(fetchAttachmentsSuccess(response.data));
  }
  yield put(fetchAttachmentsFulfill);
}

export function* fetchCommunicationDocumentSaga(
  action: ReturnType<typeof fetchCommunicationDocument>
): SagaReturn {
  const { communication } = action.payload;
  yield put(fetchCommunicationDocumentRequest());
  const response = (yield call([LitLingoClient.resources.communications.extras, 'document'], {
    urlParams: { commUuid: communication.uuid },
  })) as API.Response<API.Communications.Document>;

  if (response.error != null) {
    yield put(fetchCommunicationDocumentFailure(response.error));
  } else {
    yield put(fetchCommunicationDocumentSuccess({ communication, document: response.data }));
  }
}

export function* fetchAllCommunicationDocumentSaga({
  payload,
}: ReturnType<typeof fetchAllCommunicationDocument>): SagaReturn {
  const { communications } = payload;

  const existingCommunications = (yield select(getCommunications)) as ReturnType<
    typeof getCommunications
  >;

  yield put(fetchAllCommunicationDocumentRequest());

  yield all(
    communications
      .filter(
        (c) => !existingCommunications.some((comm) => comm?.uuid === c.uuid && comm?.document)
      )
      .map((c) =>
        call(fetchCommunicationDocumentSaga, {
          payload: {
            communication: c,
          },
          type: fetchCommunicationDocument.toString(),
        })
      )
  );

  yield put(fetchAllCommunicationDocumentSuccess());
}

export default function* communicationSaga(): SagaReturn {
  yield takeLatest(postTestSentence.toString(), postTestSentenceSaga);
  yield takeLatest(postTestSentenceV2.toString(), postTestSentenceV2Saga);
  yield takeLatest(postEvaluateTestSentence.toString(), postEvaluateTestSentenceSaga);
  yield takeLatest(testAnnotator.toString(), testAnnotatorSaga);
  yield takeEvery(fetchCommunicationSummary.toString(), fetchCommunicationSummarySaga);
  yield takeLatest(fetchAttachments.toString(), fetchAttachmentsSaga);
  yield takeLatest(fetchCommunicationDocument.toString(), fetchCommunicationDocumentSaga);
  yield takeLatest(fetchAllCommunicationDocument.toString(), fetchAllCommunicationDocumentSaga);
}
