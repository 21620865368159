import CollapsableMainSection from 'components/CollapsableCard/CollapsableMainSection';
import EmptyTableState from 'components/EmptyTableState';
import LoadingIndicator from 'components/LoadingIndicator';
import useIdentifierCategories from 'hooks/identifiers/useIdentifierCategories';
import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import type { UUID } from 'types';
import IdentifierCategoryItem from './IdentifierCategoriesItem';

type ComponentProps = {
  identifierId: UUID;
};

const RenderIdentifierCategories: React.FC<ComponentProps> = ({ identifierId }) => {
  const { data, isLoading } = useIdentifierCategories(identifierId);

  const isEmpty = data?.length === 0;

  const renderContent = (): JSX.Element => (
    <div className="border rounded">
      <LoadingOverlay
        active={isLoading}
        spinner={<LoadingIndicator size="10" />}
        fadeSpeed={0}
        styles={{
          content: (base) => ({
            ...base,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }),
          wrapper: () => ({}),
          spinner: () => ({}),
          overlay: (base) => ({
            ...base,
            zIndex: 10,
          }),
        }}
      >
        <div className="table-wrapper__new">
          <table className="table-wrapper__table">
            <thead>
              <tr>
                <th className="table-wrapper__new-th pl-4">Category</th>
                <th className="table-wrapper__new-th">Customer</th>
                <th className="table-wrapper__new-th">Platforms/Outcomes</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-8">Inbound</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-8">Outbound</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-8">Enabled</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-12">Last Modified</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-12">Modified By</th>
              </tr>
            </thead>
            <tbody className="table-wrapper__tbody" data-testid="categories-table">
              {!isLoading && isEmpty ? (
                <EmptyTableState message="There are no categories to display" colCount="8" />
              ) : (
                data?.map((category) => (
                  <IdentifierCategoryItem key={category.uuid} category={category} />
                ))
              )}
            </tbody>
          </table>
        </div>
      </LoadingOverlay>
    </div>
  );

  return (
    <CollapsableMainSection headerTitle="Categories" isDefaultClosed>
      <div className="p-4">{renderContent()}</div>
    </CollapsableMainSection>
  );
};

export default RenderIdentifierCategories;
