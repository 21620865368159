import { evaluateAsync } from 'actions';
import classNames from 'classnames';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { entityToQueryMap, TestCaseEntity } from 'constants/testCases';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { evaluateAsyncTestCaseLoading } from 'selectors/testCases';
import { UUID } from 'types';

type EvaluateButtonProps = {
  entity?: TestCaseEntity;
  entityId?: UUID;
  className?: string;
};

const EvaluateButton: React.FC<EvaluateButtonProps> = (props) => {
  const { entity, entityId, className } = props;

  const dispatch = useDispatch();
  const navParams = useSelector(getNavParamsByResource(resourceQueryParamName.testCase));
  const loading = useSelector(evaluateAsyncTestCaseLoading);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation();

    const filteredParams = Object.entries(navParams).reduce((acc, [key, value]) => {
      if (!['offset', 'order_desc', 'order_by'].includes(key)) {
        return { ...acc, [key]: value };
      }
      return acc;
    }, {});

    if (entity == null) {
      dispatch(evaluateAsync({ ...filteredParams, limit: -1 }));
    } else if (entityId != null) {
      dispatch(evaluateAsync({ [entityToQueryMap[entity]]: entityId }));
    }
  };

  return (
    <span className={classNames(className, 'rounded-md')}>
      <button
        type="button"
        disabled={loading}
        onClick={handleClick}
        className={classNames(
          'flex flex-row justify-center button button--secondary-redesign font-bold h-8',
          {
            'disabled-button': loading,
          }
        )}
        data-testid="evaluate-button"
      >
        <span>Evaluate</span>
      </button>
    </span>
  );
};

export default EvaluateButton;
