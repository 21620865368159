import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';

import {
  EditRulePayload,
  annotatorPosition,
  clearRuleGroupTest,
  createRuleGroupFailure,
  createRuleGroupRequest,
  createRuleGroupSuccess,
  deleteRule,
  editRule,
  fetchAllRuleGroupsFailure,
  fetchAllRuleGroupsRequest,
  fetchAllRuleGroupsSuccess,
  fetchRuleCategoryFailure,
  fetchRuleCategoryFulfill,
  fetchRuleCategoryRequest,
  fetchRuleCategorySuccess,
  fetchRuleContextSuccess,
  fetchRuleCustomersFailure,
  fetchRuleCustomersFulfill,
  fetchRuleCustomersRequest,
  fetchRuleCustomersSuccess,
  fetchRulesForFilterPillsFailure,
  fetchRulesForFilterPillsRequest,
  fetchRulesForFilterPillsSuccess,
  fetchSingleRuleGroupFailure,
  fetchSingleRuleGroupRequest,
  fetchSingleRuleGroupSuccess,
  fetchTestCaseSummaryRuleGroupFailure,
  fetchTestCaseSummaryRuleGroupRequest,
  fetchTestCaseSummaryRuleGroupSuccess,
  previewRuleGroupFailure,
  previewRuleGroupFulfill,
  previewRuleGroupRequest,
  previewRuleGroupSuccess,
  publishRuleVersionFailure,
  publishRuleVersionRequest,
  publishRuleVersionSuccess,
  saveRuleFailure,
  saveRuleRequest,
  saveRuleSuccess,
  selectAnnotators,
  selectRule,
  selectRuleType,
  setCurrentTestRuleId,
  setHighlightCustomerScope,
  setShowHeatmap,
  setShowHighlights,
  setShowUtilization,
  setToAnnotator,
  toggleShowV2Graph,
  undoRuleVersionFailure,
  undoRuleVersionRequest,
  undoRuleVersionSuccess,
} from 'actions/ruleGroup';
import type {
  API,
  Category,
  Customer,
  ErrorObject,
  MRuleGroup,
  MRuleRevision,
  NormalizedResource,
  RuleGroup,
  RuleTestCasesSummary,
  TestCommunication,
  UUID,
} from 'types';

export type RuleState = {
  loading: string[];
  error: ErrorObject | null;
  rules: NormalizedResource<MRuleGroup>;
  filterItems: NormalizedResource<RuleGroup>;
  count: number;
  selectedRuleGroup: RuleGroup | null;
  selectedRule: MRuleRevision | null;
  selectedIdentifiers: string[];
  showHighlights: boolean;
  showHeatmap: boolean;
  testRuleId: string;
  identifierPosition: string | null;
  toIdentifier: boolean;
  test: TestCommunication | null;
  ruleCustomers: Customer[];
  ruleCategory: Category | null;
  showUtilization: boolean;
  highlightCustomerScope: string | null;
  showV2: boolean;
  ruleRevisions: MRuleRevision[];
};

type RuleReducer<P = void> = (state: RuleState, payload: PayloadAction<P>) => RuleState;

type PayloadDelete = {
  ruleId: UUID;
};

const defaultState: RuleState = {
  rules: {},
  filterItems: {},
  loading: [],
  error: null,
  count: 0,
  selectedRuleGroup: null,
  selectedRule: null,
  selectedIdentifiers: [],
  showHighlights: true,
  showHeatmap: false,
  testRuleId: '',
  identifierPosition: null,
  toIdentifier: false,
  test: null,
  ruleCustomers: [],
  ruleCategory: null,
  showUtilization: false,
  highlightCustomerScope: null,
  showV2: true,
  ruleRevisions: [],
};

const handleCreateRuleGroupRequest: RuleReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, createRuleGroupRequest.toString()],
});

const handleCreateRuleGroupFailure: RuleReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== createRuleGroupRequest.toString()),
});

const handleCreateRuleGroupSuccess: RuleReducer = (state) => ({
  ...state,
  error: null,
  loading: state.loading.filter((s) => s !== createRuleGroupRequest.toString()),
});

const handleSaveRuleRequest: RuleReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, saveRuleRequest.toString()],
});

const handleSaveRuleFailure: RuleReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== saveRuleRequest.toString()),
});

const handleSaveRuleSuccess: RuleReducer<MRuleRevision> = (state, { payload }) => ({
  ...state,
  selectedRule: payload,
  loading: state.loading.filter((s) => s !== saveRuleRequest.toString()),
});

const handleFetchRuleRequest: RuleReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchSingleRuleGroupRequest.toString()],
});

const handleFetchRuleFailure: RuleReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchSingleRuleGroupRequest.toString()),
});

const handleFetchRuleSuccess: RuleReducer<{
  ruleGroup: MRuleGroup;
  ruleRevision: MRuleRevision;
}> = (state, { payload }) => {
  const { ruleGroup, ruleRevision } = payload;

  return {
    ...state,
    selectedRuleGroup: ruleGroup,
    selectedRule: ruleRevision,
    loading: state.loading.filter((s) => s !== fetchSingleRuleGroupRequest.toString()),
  };
};

const handleFetchAllRulesRequest: RuleReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAllRuleGroupsRequest.toString()],
});

const handleFetchAllRulesFailure: RuleReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchAllRuleGroupsRequest.toString()),
});

const handleFetchAllRulesSuccess: RuleReducer<API.WrappedAPIResponse<MRuleGroup>> = (
  state,
  { payload }
) => {
  const rules: NormalizedResource<MRuleGroup> = {};

  payload.records.forEach((rule) => {
    rules[rule.uuid] = {
      ...rule,
      summaryTestCases: state.rules[rule.uuid]?.summaryTestCases,
    };
  });

  return {
    ...state,
    rules,
    count: payload.count,
    loading: state.loading.filter((s) => s !== fetchAllRuleGroupsRequest.toString()),
  };
};

const handleFetchRulesForFilterPillsRequest: RuleReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchRulesForFilterPillsRequest.toString()],
});

const handleFetchRulesForFilterPillsFailure: RuleReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchRulesForFilterPillsRequest.toString()),
});

const handleFetchRulesForFilterPillsSuccess: RuleReducer<API.WrappedAPIResponse<RuleGroup>> = (
  state,
  { payload }
) => {
  const normalizedRules: NormalizedResource<RuleGroup> = {};
  payload.records.forEach((Rule) => {
    normalizedRules[Rule.uuid] = Rule;
  });
  return {
    ...state,
    filterItems: normalizedRules,
    loading: state.loading.filter((s) => s !== fetchRulesForFilterPillsRequest.toString()),
    count: payload.count,
  };
};

const handleDeleteRule: RuleReducer<PayloadDelete> = (state, { payload }) => {
  const { ruleId } = payload;
  const rules = { ...state.rules };
  delete rules[ruleId];

  return {
    ...state,
    rules,
  };
};

const handleEditRule: RuleReducer<EditRulePayload> = (state, { payload }) => {
  const { id, data } = payload;
  const item = state.rules[id];
  return {
    ...state,
    rules: {
      ...state.rules,
      [id]: {
        ...item,
        ...data,
      },
    },
  };
};

const handleSelectRule: RuleReducer<selectRuleType> = (state, { payload }) => ({
  ...state,
  selectedRule: payload.rule,
});

const handleFetchTestCaseSummaryRuleGroupRequest: RuleReducer = (state) => ({
  ...state,
  loading: [...state.loading, fetchTestCaseSummaryRuleGroupRequest.toString()],
});

const handleFetchTestCaseSummaryRuleGroupSuccess: RuleReducer<RuleTestCasesSummary> = (
  state,
  { payload }
) => {
  const data = {
    failing: payload.failing || 0,
    passing: payload.passing || 0,
    false_negatives: payload.false_negatives || 0,
    not_run: payload.not_run || 0,
    true_positives: payload.true_positives || 0,
    true_negatives: payload.true_negatives || 0,
  };

  return {
    ...state,
    rules: {
      ...state.rules,
      [payload.ruleUuid]: {
        ...state.rules[payload.ruleUuid],
        summaryTestCases: data,
      },
    },
    loading: state.loading.filter((s) => s !== fetchTestCaseSummaryRuleGroupRequest.toString()),
  };
};

const handleFetchTestCaseSummaryRuleGroupFailure: RuleReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchTestCaseSummaryRuleGroupRequest.toString()),
});

const handlePublishRuleVersionRequest: RuleReducer = (state) => ({
  ...state,
  loading: [...state.loading, publishRuleVersionRequest.toString()],
});

const handlePublishRuleVersionSuccess: RuleReducer<MRuleRevision> = (state, { payload }) => {
  const item = payload;
  const rule = state.rules[payload.uuid] || {};
  return {
    ...state,
    rules: {
      ...state.rules,
      [payload.uuid]: { ...rule, ...item },
    },
    loading: state.loading.filter((s) => s !== publishRuleVersionRequest.toString()),
  };
};

const handlePublishRuleVersionFailure: RuleReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== publishRuleVersionRequest.toString()),
});

const handleUndoRuleVersionRequest: RuleReducer = (state) => ({
  ...state,
  loading: [...state.loading, undoRuleVersionRequest.toString()],
});

const handleUndoRuleVersionSuccess: RuleReducer<MRuleRevision> = (state, { payload }) => {
  const item = payload;
  const rule = state.rules[payload.uuid] || {};
  return {
    ...state,
    rules: {
      ...state.rules,
      [payload.uuid]: { ...rule, ...item },
    },
    loading: state.loading.filter((s) => s !== undoRuleVersionRequest.toString()),
  };
};

const handleUndoRuleVersionFailure: RuleReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== undoRuleVersionRequest.toString()),
});

const handleSelectedAnnotators: RuleReducer<string[]> = (state, { payload }) => ({
  ...state,
  selectedIdentifiers: payload,
});

const handleShowHighlights: RuleReducer<boolean> = (state, { payload }) => ({
  ...state,
  showHighlights: payload,
});

const handleCurrentTestRuleId: RuleReducer<string> = (state, { payload }) => ({
  ...state,
  testRuleId: payload,
});

const handleAnnotatorPosition: RuleReducer<string | null> = (state, { payload }) => ({
  ...state,
  annotatorPosition: payload,
});

const handleSetToAnnotator: RuleReducer<boolean> = (state, { payload }) => ({
  ...state,
  toAnnotator: payload,
});

const handlePreviewRuleGroupRequest: RuleReducer = (state) => ({
  ...state,
  loading: [...state.loading, previewRuleGroupRequest.toString()],
});
const handlePreviewRuleGroupSuccess: RuleReducer<TestCommunication> = (state, { payload }) => ({
  ...state,
  test: payload,
});
const handlePreviewRuleGroupFailure: RuleReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});
const handlePreviewRuleGroupFulfill: RuleReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== previewRuleGroupRequest.toString()),
});

const handleFetchRuleCustomersRequest: RuleReducer = (state) => ({
  ...state,
  loading: [...state.loading, fetchRuleCustomersRequest.toString()],
});
const handleFetchRuleCustomersSuccess: RuleReducer<Customer[]> = (state, { payload }) => ({
  ...state,
  ruleCustomers: payload,
});
const handleFetchRuleCustomersFailure: RuleReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});
const handleFetchRuleCustomersFulfill: RuleReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchRuleCustomersRequest.toString()),
});

const handleFetchRuleCategoryRequest: RuleReducer = (state) => ({
  ...state,
  loading: [...state.loading, fetchRuleCategoryRequest.toString()],
});
const handleFetchRuleCategorySuccess: RuleReducer<Category> = (state, { payload }) => ({
  ...state,
  ruleCategory: payload,
});
const handleFetchRuleCategoryFailure: RuleReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});
const handleFetchRuleCategoryFulfill: RuleReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchRuleCategoryRequest.toString()),
});

const handleClearRuleGroupTest: RuleReducer = (state) => ({
  ...state,
  test: null,
});

const handleSetShowHeatmap: RuleReducer<boolean> = (state, { payload }) => ({
  ...state,
  showHeatmap: payload,
});

const handleSetShowUtilization: RuleReducer<boolean> = (state, { payload }) => ({
  ...state,
  showUtilization: payload,
});

const handleSetHighlightCustomerScope: RuleReducer<string> = (state, { payload }) => ({
  ...state,
  highlightCustomerScope: payload,
});

const handleToggleShowV2Graph: RuleReducer = (state) => ({
  ...state,
  showV2: !state.showV2,
});

const handleFetchRuleContextSuccess: RuleReducer<MRuleRevision[]> = (state, { payload }) => ({
  ...state,
  ruleRevisions: payload,
});

const handlers = {
  [saveRuleRequest.toString()]: handleSaveRuleRequest,
  [saveRuleFailure.toString()]: handleSaveRuleFailure,
  [saveRuleSuccess.toString()]: handleSaveRuleSuccess,
  [fetchSingleRuleGroupRequest.toString()]: handleFetchRuleRequest,
  [fetchSingleRuleGroupFailure.toString()]: handleFetchRuleFailure,
  [fetchSingleRuleGroupSuccess.toString()]: handleFetchRuleSuccess,
  [fetchAllRuleGroupsRequest.toString()]: handleFetchAllRulesRequest,
  [fetchAllRuleGroupsFailure.toString()]: handleFetchAllRulesFailure,
  [fetchAllRuleGroupsSuccess.toString()]: handleFetchAllRulesSuccess,
  [fetchRulesForFilterPillsRequest.toString()]: handleFetchRulesForFilterPillsRequest,
  [fetchRulesForFilterPillsSuccess.toString()]: handleFetchRulesForFilterPillsSuccess,
  [fetchRulesForFilterPillsFailure.toString()]: handleFetchRulesForFilterPillsFailure,
  [fetchTestCaseSummaryRuleGroupRequest.toString()]: handleFetchTestCaseSummaryRuleGroupRequest,
  [fetchTestCaseSummaryRuleGroupSuccess.toString()]: handleFetchTestCaseSummaryRuleGroupSuccess,
  [fetchTestCaseSummaryRuleGroupFailure.toString()]: handleFetchTestCaseSummaryRuleGroupFailure,
  [publishRuleVersionRequest.toString()]: handlePublishRuleVersionRequest,
  [publishRuleVersionSuccess.toString()]: handlePublishRuleVersionSuccess,
  [publishRuleVersionFailure.toString()]: handlePublishRuleVersionFailure,
  [createRuleGroupRequest.toString()]: handleCreateRuleGroupRequest,
  [createRuleGroupSuccess.toString()]: handleCreateRuleGroupSuccess,
  [createRuleGroupFailure.toString()]: handleCreateRuleGroupFailure,
  [undoRuleVersionRequest.toString()]: handleUndoRuleVersionRequest,
  [undoRuleVersionSuccess.toString()]: handleUndoRuleVersionSuccess,
  [undoRuleVersionFailure.toString()]: handleUndoRuleVersionFailure,
  [deleteRule.toString()]: handleDeleteRule,
  [editRule.toString()]: handleEditRule,
  [selectRule.toString()]: handleSelectRule,
  [selectAnnotators.toString()]: handleSelectedAnnotators,
  [setShowHighlights.toString()]: handleShowHighlights,
  [setCurrentTestRuleId.toString()]: handleCurrentTestRuleId,
  [annotatorPosition.toString()]: handleAnnotatorPosition,
  [setToAnnotator.toString()]: handleSetToAnnotator,
  [previewRuleGroupRequest.toString()]: handlePreviewRuleGroupRequest,
  [previewRuleGroupSuccess.toString()]: handlePreviewRuleGroupSuccess,
  [previewRuleGroupFailure.toString()]: handlePreviewRuleGroupFailure,
  [previewRuleGroupFulfill.toString()]: handlePreviewRuleGroupFulfill,
  [fetchRuleCustomersRequest.toString()]: handleFetchRuleCustomersRequest,
  [fetchRuleCustomersSuccess.toString()]: handleFetchRuleCustomersSuccess,
  [fetchRuleCustomersFailure.toString()]: handleFetchRuleCustomersFailure,
  [fetchRuleCustomersFulfill.toString()]: handleFetchRuleCustomersFulfill,
  [fetchRuleCategoryRequest.toString()]: handleFetchRuleCategoryRequest,
  [fetchRuleCategorySuccess.toString()]: handleFetchRuleCategorySuccess,
  [fetchRuleCategoryFailure.toString()]: handleFetchRuleCategoryFailure,
  [fetchRuleCategoryFulfill.toString()]: handleFetchRuleCategoryFulfill,
  [clearRuleGroupTest.toString()]: handleClearRuleGroupTest,
  [setShowHeatmap.toString()]: handleSetShowHeatmap,
  [setShowUtilization.toString()]: handleSetShowUtilization,
  [setHighlightCustomerScope.toString()]: handleSetHighlightCustomerScope,
  [toggleShowV2Graph.toString()]: handleToggleShowV2Graph,
  [fetchRuleContextSuccess.toString()]: handleFetchRuleContextSuccess,
};

const ruleReduer = createReducer(defaultState, handlers);

export default ruleReduer;
