/* eslint-disable camelcase */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setURLParams } from '../../../actions/nav';
import { getNavParams } from '../../../selectors/nav';
import { useSelector } from '../../../store';
import logEvent from '../../../utils/analytics';
import { TIME_RANGE_FIELD, UNIT_OF_TIME, useTimeRange } from '../../../utils/timeRanges';
import SelectSidebar from '../../SelectSidebar';

const OPTIONS = [
  { label: 'hours', value: 'hours' },
  { label: 'days', value: 'days' },
  { label: 'weeks', value: 'weeks' },
  { label: 'months', value: 'months' },
  /* { label: 'years', value: 'years' }, */
];

const UnitOfTimeFilter: React.FC = () => {
  const dispatch = useDispatch();
  const value = useSelector((state) => getNavParams(state)[UNIT_OF_TIME] || '');
  const range = useSelector((state) => getNavParams(state)[TIME_RANGE_FIELD]);
  const [options, setOptions] = useState(OPTIONS);
  const { created_after, created_before } = useTimeRange();

  const diff = moment.duration(moment(created_before)?.diff(moment(created_after)));
  const days = Number(diff.asDays().toFixed());

  useEffect(() => {
    if (days >= 29) {
      setOptions(OPTIONS.filter((option) => option.label !== 'hours'));
      return;
    }
    setOptions(OPTIONS);
  }, [created_after, created_before, dispatch, range, days]);

  const handleChange = (unitOfTime: string): void => {
    logEvent(`Dashboard-date-filter-${unitOfTime}`);
    dispatch(setURLParams({ [UNIT_OF_TIME]: unitOfTime }));
  };

  if (days === 1) {
    return null;
  }

  return (
    <div data-testid="section" className="pb-2.5 border-b border-litlingo-white">
      <SelectSidebar
        dataTestid="filter-select"
        onChange={handleChange}
        options={options}
        value={value as string}
        customLabel="Grouped by"
        placeholder="Grouped by"
      />
    </div>
  );
};

export default UnitOfTimeFilter;
