import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import { merge } from 'lodash';

import { updatePermissionsPolicy } from 'actions/permissions';
import type { CustomerPrivacyConfig, HomeConfig, Policy, RoleAccessConfig } from 'types';
import { ACTION_ROLES, USER_HOME } from 'utils/userRoles';

export type PermissionsState = {
  policy: Policy;
  home: HomeConfig;
};

type PermissionsReducer<P> = (
  state: PermissionsState,
  action: PayloadAction<P>
) => PermissionsState;

const defaultState = {
  policy: { ...ACTION_ROLES },
  home: { ...USER_HOME },
};

// we use default state here b/c we effectively want to build off of default -> not last customers state
const handleUpdatePermissionsPolicy: PermissionsReducer<{
  privacyConfig: CustomerPrivacyConfig;
  roleAccessConfig: RoleAccessConfig;
}> = (_, { payload: { privacyConfig, roleAccessConfig } }) => {
  const updatedPerm = {} as Policy;

  Object.keys(privacyConfig).forEach((key) => {
    const k = key as keyof Policy;

    updatedPerm[k] = {
      ...defaultState.policy[k],
      protectedFields: {
        ...defaultState.policy[k]?.protectedFields,
        ...privacyConfig[k],
      },
    };
  });

  const state = {
    ...defaultState,
    policy: {
      ...defaultState.policy,
      ...updatedPerm,
    },
  };

  const newPolicyState = {};

  merge(newPolicyState, state.policy, roleAccessConfig);

  state.policy = newPolicyState;

  return state;
};

const handlers = {
  [updatePermissionsPolicy.toString()]: handleUpdatePermissionsPolicy,
};

const permissionsReducer = createReducer(defaultState, handlers);

export default permissionsReducer;
