/* eslint-disable jsx-a11y/control-has-associated-label */
import EmptyTableState from 'components/EmptyTableState';
import FilterListHeader from 'components/Filters/FilterListHeader';
import ListFooter from 'components/ListFooter';
import LoadingIndicator from 'components/LoadingIndicator';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getSavedSearchesList,
  getSavedSearchesLoading,
  getSavedSearchesTotalCount,
} from 'selectors/savedSearches';
import ReviewSetListItem from './SavedSearchListItem';

const SavedSearchTable: React.FC = () => {
  const location = useLocation();
  const inSamples = location.pathname.includes('samples');

  const loading = useSelector(getSavedSearchesLoading);
  const reviewSets = useSelector(getSavedSearchesList);

  return (
    <div className="table-wrapper__new no-scrollbar overflow-auto">
      <LoadingOverlay
        active={loading}
        spinner={<LoadingIndicator size="10" />}
        className="relative w-full h-full"
        fadeSpeed={0}
        styles={{
          content: (base) => ({
            ...base,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }),
          wrapper: () => ({}),
          spinner: () => ({}),
          overlay: (base) => ({
            ...base,
            zIndex: 10,
          }),
        }}
      >
        <table className="table-wrapper__table table-wrapper_table--fixed">
          <thead className="sticky top-0 z-10">
            <tr className="table-wrapper__header-tr h-10">
              <FilterListHeader
                resource={resourceQueryParamName.savedSearch}
                text={inSamples ? 'Sample' : 'Saved Search'}
                field="name"
                className="table-wrapper__new-th table-wrapper__th--w-35 pl-6"
                redesign
              />
              {/* FRO-1090 <FilterListHeader
                resource={resourceQueryParamName.savedSearch}
                text="Priority"
                field="priority"
                className="table-wrapper__new-th table-wrapper__th--w-14 p-0"
                inverted
                redesign
              /> */}
              <FilterListHeader
                resource={resourceQueryParamName.savedSearch}
                text="Count"
                field="_record_count"
                className="table-wrapper__new-th table-wrapper__th--w-14 p-0"
                redesign
              />
              <th className="table-wrapper__new-th table-wrapper__th--w-20 p-0">Created by</th>

              <FilterListHeader
                resource={resourceQueryParamName.savedSearch}
                text="Created on"
                field="created_at"
                className="table-wrapper__new-th table-wrapper__th--w-14 p-0"
                redesign
              />
            </tr>
          </thead>
          <tbody className="table-wrapper__tbody">
            {!loading && reviewSets.length === 0 ? (
              <EmptyTableState
                message={inSamples ? 'No samples found' : 'No saved searches found'}
                colCount="4"
              />
            ) : (
              <>
                {reviewSets.map((reviewSet) => (
                  <ReviewSetListItem reviewSet={reviewSet} key={reviewSet.uuid} />
                ))}
              </>
            )}
          </tbody>
        </table>
        <div className="border-t border-gray-200 sticky bottom-0 z-10 bg-white">
          <ListFooter
            resourceStateName={resourceQueryParamName.savedSearch}
            resourceName={inSamples ? 'samples' : 'saved search'}
            getResourceList={getSavedSearchesList}
            getResourceTotalCount={getSavedSearchesTotalCount}
            getResourceLoading={getSavedSearchesLoading}
          />
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default SavedSearchTable;
