/* eslint-disable max-lines */
import React from 'react';

const UsersPermissionsHeader: React.FC = () => (
  <header className="flex flex-col">
    <span className="w-full text-heading-1">New Users</span>
  </header>
);

export default UsersPermissionsHeader;
