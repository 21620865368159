import { SHIELD_ICON } from 'constants/envelopeIcons';
import React from 'react';
import { useSelector } from 'react-redux';
import getEnvelopeReviewLabelOptions from 'selectors/reviewStatus';
import { ReviewValueConfigType } from 'types';

type ComponentProps = {
  reviewValue: string;
  isConfirmed?: boolean;
};

const ReviewValueLabel: React.FC<ComponentProps> = ({ reviewValue, isConfirmed = false }) => {
  const reviewLabelOptions = useSelector(getEnvelopeReviewLabelOptions);
  return (
    <div className="flex flex-row items-center gap-1 py-0.5 px-1 text-small bg-litlingo-gray-1 rounded whitespace-no-wrap">
      {reviewLabelOptions[reviewValue as keyof ReviewValueConfigType]}
      {isConfirmed && <div className="min-w-3 w-3">{SHIELD_ICON}</div>}
    </div>
  );
};

export default ReviewValueLabel;
