import {
  fetchAllCampaignRuleOutcomesRequest,
  updateCampaignRuleOutcomeConfigRequest,
} from 'actions/campaignRuleOutcomes';
import { GlobalState } from 'reducers';
import { createSelector } from 'reselect';
import type { Campaign, CampaignRuleOutcome, NormalizedResource, Selector } from 'types';

export const getFetchAllCampaignRuleOutcomesLoading: Selector<boolean> = (state) =>
  state.campaignRuleOutcomes.loading.includes(fetchAllCampaignRuleOutcomesRequest.toString());

export const getCampaignRuleOutcomesList: Selector<CampaignRuleOutcome[]> = createSelector(
  [
    (state: GlobalState): GlobalState['campaignRuleOutcomes']['items'] =>
      state.campaignRuleOutcomes.items,
  ],
  (campaignRuleOutcomes) => Object.values(campaignRuleOutcomes)
);

export const getCampaignRuleOutcomes: Selector<NormalizedResource<CampaignRuleOutcome>> = (state) =>
  state.campaignRuleOutcomes.items;

export const getCampaignRuleOutcome =
  (id: string): Selector<CampaignRuleOutcome> =>
  (state): CampaignRuleOutcome =>
    state.campaignRuleOutcomes.items[id];

export const getCampaignFromCampaignRuleOutcome: Selector<Campaign | null> = (state) => {
  const campaignRuleOutcomes = getCampaignRuleOutcomesList(state);
  if (campaignRuleOutcomes.length > 0) {
    return campaignRuleOutcomes[0].campaign || null;
  }
  return null;
};

export const getUpdateCampaignRuleOutcomeConfigLoading: Selector<boolean> = (state) =>
  state.campaignRuleOutcomes.loading.includes(updateCampaignRuleOutcomeConfigRequest.toString());
