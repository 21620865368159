/* eslint-disable camelcase */
import { AnnotatedGraph, superNodeNeedsSpaceBefore } from '@litlingo/react';
import { setRulesToShow, setSelectedModel, setSelectedSentence, setSelectedText } from 'actions';
import Redacted from 'components/Redacted';
import moment from 'moment-timezone';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { SectionType } from 'reducers/envelopeReview';
import { getCustomerO365Config, getUser } from 'selectors/auth';
import { getSelectedSection } from 'selectors/envelopeReview';
import { getSingleEvent } from 'selectors/events';
import { getPermissionsPolicy } from 'selectors/permissions';
import { useSelector } from 'store';
import { CommunicationWithEvents } from 'types';
import { isActionAuthorized } from 'utils/permissions';
import HighlightedSentenceComponent from './HighlightedSentence';
import RenderThread from './RenderThread';

type ComponentProps = {
  communication: CommunicationWithEvents;
};

const CommunicationHistoryItem: React.FC<ComponentProps> = ({ communication }) => {
  const sections: SectionType[] = ['message', 'history', 'attachment'];

  const dispatch = useDispatch();

  const eventId =
    communication.events && communication.events[0] ? communication.events[0].uuid : '';

  const event = useSelector((state) => getSingleEvent(state, eventId));
  const o365Config = useSelector(getCustomerO365Config);
  const selectedSection = useSelector(getSelectedSection);

  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);

  const teamsContextDepth = o365Config.teams_thread_context_depth || 5;
  const isTeamsComm = event?.communication?.platform === 'o365_teams';

  const contentRef = useRef<HTMLDivElement>(null);

  const getDate = (date: string): string => {
    const fixedDate = date.charAt(date.length - 1) !== 'Z' ? date.concat('Z') : date;
    const eventDate = moment(fixedDate).utc().local();

    return eventDate.format('hh:mm A');
  };

  const handleSentenceClick = (line: AnnotatedGraph): void => {
    const rules: string[] = [];
    line.nodes.forEach((node) => {
      if (node.annotations) {
        node.annotations.forEach((a) => {
          if (a.rule_uuid) {
            rules.push(a.rule_uuid);
          }
        });
      }
    });

    const text = line.nodes.reduce((acc, val, idx, arr) => {
      if (superNodeNeedsSpaceBefore(arr, idx)) {
        return `${acc} ${val.nodes[0].text}`;
      }
      return `${acc}${val.nodes[0].text}`;
    }, '');

    const uniqueRules = [...new Set(rules)];

    if (isActionAuthorized('communication_envelopes.model_transparency', user.roles, policy)) {
      dispatch(setSelectedSentence({ index: line.index, communicationId: communication.uuid }));
      dispatch(setRulesToShow(uniqueRules));
      dispatch(setSelectedText(text));
      dispatch(setSelectedModel([]));
    }
  };

  return (
    <div ref={contentRef} className="relative flex flex-col gap-8 overflow-x px-2">
      <Redacted field="communications.body">
        <div className="flex flex-row justify-between gap-4">
          <div className="flex flex-row gap-1 break-words pl-7.5">
            <span>{communication.platform === 'o365_teams' ? 'Channel:' : 'Subject:'}</span>
            <HighlightedSentenceComponent
              eventId={eventId || `no-event-${communication.uuid}`}
              commUuid={communication.uuid || ''}
              fullMessageOptions="HIGHLIGHTED"
              onlySubject
              handleSentenceClick={handleSentenceClick}
            />
          </div>
        </div>
      </Redacted>
      {isTeamsComm && event.communication?.platform_guid ? (
        <Redacted field="communications.thread_context">
          <div className="pl-8">
            <RenderThread
              eventId={eventId}
              commUuid={event.communication_uuid}
              commPlatformUuid={event.communication?.platform_guid}
              contextPadding={teamsContextDepth - Math.floor(teamsContextDepth / 2)}
              authorOnly
              highRiskOnly
              hideHighlight={!sections.includes(selectedSection)}
              handleSentenceClick={handleSentenceClick}
            />
          </div>
        </Redacted>
      ) : (
        <Redacted field="communications.body">
          <div className="flex flex-col break-words">
            <HighlightedSentenceComponent
              eventId={eventId || `no-event-${communication.uuid}`}
              commUuid={communication.uuid || ''}
              fullMessageOptions="HIGHLIGHTED"
              borderSeparation="pl-9"
              highRiskOnly
              hideHighlight={!sections.includes(selectedSection)}
              handleSentenceClick={handleSentenceClick}
            />
          </div>
        </Redacted>
      )}
      <div className="absolute text-small transform -translate-x-1/2" style={{ top: 2, left: 0 }}>
        <div className="border-2 border-litlingo-gray-2 rounded px-0.5 font-bold bg-white">
          {getDate(communication?.sent_at || '')}
        </div>
      </div>
    </div>
  );
};

export default CommunicationHistoryItem;
