import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  CountPayload,
  fetchAllEventsFailure,
  fetchAllEventsRequest,
  fetchAllEventsSuccess,
  fetchAllPendingEventsFailure,
  fetchAllPendingEventsRequest,
  fetchAllPendingEventsSuccess,
  fetchCampaignsPerformanceFailure,
  fetchCampaignsPerformanceRequest,
  fetchCampaignsPerformanceSuccess,
  fetchCommonWordsFailure,
  fetchCommonWordsRequest,
  fetchCommonWordsSuccess,
  fetchCommsByPlatformFailure,
  fetchCommsByPlatformRequest,
  fetchCommsByPlatformSuccess,
  fetchEnvelopesBucketsFailure,
  fetchEnvelopesBucketsRequest,
  fetchEnvelopesBucketsSuccess,
  fetchEventBucketsFailure,
  fetchEventBucketsRequest,
  fetchEventBucketsSuccess,
  fetchMetricsDataFailure,
  fetchMetricsDataFulfill,
  fetchMetricsDataRequest,
  fetchMetricsDataSuccess,
  fetchSentimentOverTimeFailure,
  fetchSentimentOverTimeRequest,
  fetchSentimentOverTimeSuccess,
  fetchTagsAnalytics,
  fetchTagsAnalyticsFailure,
  fetchTagsAnalyticsFulfill,
  fetchTagsAnalyticsSuccess,
  fetchTopUsersByEventsFailure,
  fetchTopUsersByEventsRequest,
  fetchTopUsersByEventsSuccess,
  fetchViolatedEventsFailure,
  fetchViolatedEventsRequest,
  fetchViolatedEventsSuccess,
  fetchWorkforceRiskFailure,
  fetchWorkforceRiskRequest,
  fetchWorkforceRiskSuccess,
  saveWidgetQueryData,
} from 'actions/analytics';
import type {
  CommonWords,
  CommsByPlatforms,
  EnvelopeBucket,
  ErrorObject,
  EventsBucket,
  EventsByCampaign,
  EventsByState,
  MetricsData,
  NormalizedResource,
  QueryMetricObject,
  SentimentOverTime,
  TagsCounts,
  TopSender,
  WorkforceRisk,
} from 'types';

type AnalyticState = {
  error: ErrorObject | null;
  // FIXME: Temporal, change when the API is deployed
  metricsError: NormalizedResource<ErrorObject> | null;
  loading: string[];
  pendingEventsCount: number;
  eventsByState: EventsByState;
  violatedEventsCount: number;
  gmailCommsCount: number;
  slackCommsCount: number;
  zendeskCommsCount: number;
  o365CommsCount: number;
  campaignsPerformance: EventsByCampaign[];
  eventBuckets: EventsBucket[];
  envelopesBuckets: EnvelopeBucket[];
  topSenders: TopSender[];
  workforceRisk: WorkforceRisk | null;
  sentimentOverTime: SentimentOverTime | null;
  commonWords: CommonWords | null;
  tags: { [value: string]: number } | null;
  items: NormalizedResource<MetricsData>;
  queriesMetricData: NormalizedResource<QueryMetricObject>;
};

type AnalyticsReducer<P = void> = (state: AnalyticState, action: PayloadAction<P>) => AnalyticState;

const defaultState: AnalyticState = {
  error: null,
  metricsError: {},
  loading: [],
  pendingEventsCount: 0,
  eventsByState: {} as EventsByState,
  violatedEventsCount: 0,
  gmailCommsCount: 0,
  slackCommsCount: 0,
  zendeskCommsCount: 0,
  o365CommsCount: 0,
  campaignsPerformance: [],
  eventBuckets: [],
  envelopesBuckets: [],
  topSenders: [],
  workforceRisk: null,
  sentimentOverTime: null,
  commonWords: null,
  tags: null,
  queriesMetricData: {},
  items: {},
};

const handleFetchAllPendingEventsRequest: AnalyticsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAllPendingEventsRequest.toString()],
});

const handleFetchAllPendingEventsSuccess: AnalyticsReducer<CountPayload> = (
  state,
  { payload }
) => ({
  ...state,
  pendingEventsCount: payload.count,
  loading: state.loading.filter((s) => s !== fetchAllPendingEventsRequest.toString()),
});

const handleFetchAllPendingEventsFailure: AnalyticsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchAllPendingEventsRequest.toString()),
});

const handleFetchCommsByPlatformRequest: AnalyticsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchCommsByPlatformRequest.toString()],
});

const handleFetchCommsByPlatformSuccess: AnalyticsReducer<CommsByPlatforms> = (
  state,
  { payload }
) => ({
  ...state,
  gmailCommsCount: payload.gmail || 0,
  slackCommsCount: payload.slack || 0,
  zendeskCommsCount: payload.zendesk || 0,
  o365CommsCount: payload.o365 || 0,
  loading: state.loading.filter((s) => s !== fetchCommsByPlatformRequest.toString()),
});

const handleFetchCommsByPlatformFailure: AnalyticsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchCommsByPlatformRequest.toString()),
});

const handleFetchAllEventsRequest: AnalyticsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAllEventsRequest.toString()],
});

const handleFetchAllEventsSuccess: AnalyticsReducer<EventsByState> = (state, { payload }) => ({
  ...state,
  eventsByState: payload,
  loading: state.loading.filter((s) => s !== fetchAllEventsRequest.toString()),
});

const handleFetchAllEventsFailure: AnalyticsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchAllEventsRequest.toString()),
});

const handleFetchViolatedEventsRequest: AnalyticsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchViolatedEventsRequest.toString()],
});

const handleFetchViolatedEventsSuccess: AnalyticsReducer<CountPayload> = (state, { payload }) => ({
  ...state,
  violatedEventsCount: payload.count,
  loading: state.loading.filter((s) => s !== fetchViolatedEventsRequest.toString()),
});

const handleFetchViolatedEventsFailure: AnalyticsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchViolatedEventsRequest.toString()),
});

const handleFetchCampaignsPerformanceRequest: AnalyticsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchCampaignsPerformanceRequest.toString()],
});

const handleFetchCampaignsPerformanceSuccess: AnalyticsReducer<EventsByCampaign[]> = (
  state,
  { payload }
) => ({
  ...state,
  campaignsPerformance: payload,
  loading: state.loading.filter((s) => s !== fetchCampaignsPerformanceRequest.toString()),
});

const handleFetchCampaignsPerformanceFailure: AnalyticsReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchCampaignsPerformanceRequest.toString()),
});
const handleFetchEventBucketsRequest: AnalyticsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchEventBucketsRequest.toString()],
});

const handleFetchEventBucketsSuccess: AnalyticsReducer<EventsBucket[]> = (state, { payload }) => ({
  ...state,
  eventBuckets: payload,
  loading: state.loading.filter((s) => s !== fetchEventBucketsRequest.toString()),
});

const handleFetchEventBucketsFailure: AnalyticsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchEventBucketsRequest.toString()),
});

const handleFetchEnvelopesBucketsRequest: AnalyticsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchEnvelopesBucketsRequest.toString()],
});

const handleFetchEnvelopesBucketsSuccess: AnalyticsReducer<EnvelopeBucket[]> = (
  state,
  { payload }
) => ({
  ...state,
  envelopesBuckets: payload,
  loading: state.loading.filter((s) => s !== fetchEnvelopesBucketsRequest.toString()),
});

const handleFetchEnvelopesBucketsFailure: AnalyticsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchEnvelopesBucketsRequest.toString()),
});

const handleFetchTopUsersByEventsRequest: AnalyticsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchTopUsersByEventsRequest.toString()],
});

const handleFetchTopUsersByEventsSuccess: AnalyticsReducer<TopSender[]> = (state, { payload }) => ({
  ...state,
  topSenders: payload,
  loading: state.loading.filter((s) => s !== fetchTopUsersByEventsRequest.toString()),
});

const handleFetchTopUsersByEventsFailure: AnalyticsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchTopUsersByEventsRequest.toString()),
});

const handleFetchWorkforceRiskRequest: AnalyticsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchWorkforceRiskRequest.toString()],
});

const handleFetchWorkforceRiskSuccess: AnalyticsReducer<WorkforceRisk> = (state, { payload }) => ({
  ...state,
  workforceRisk: payload,
  loading: state.loading.filter((s) => s !== fetchWorkforceRiskRequest.toString()),
});

const handleFetchWorkforceRiskFailure: AnalyticsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchWorkforceRiskRequest.toString()),
});

const handleFetchSentimentOverTimeRequest: AnalyticsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchSentimentOverTimeRequest.toString()],
});

const handleFetchSentimentOverTimeSuccess: AnalyticsReducer<SentimentOverTime> = (
  state,
  { payload }
) => ({
  ...state,
  sentimentOverTime: payload,
  loading: state.loading.filter((s) => s !== fetchSentimentOverTimeRequest.toString()),
});

const handleFetchSentimentOverTimeFailure: AnalyticsReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchSentimentOverTimeRequest.toString()),
});

const handleFetchCommonWordsRequest: AnalyticsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchCommonWordsRequest.toString()],
});

const handleFetchCommonWordsSuccess: AnalyticsReducer<CommonWords> = (state, { payload }) => ({
  ...state,
  commonWords: payload,
  loading: state.loading.filter((s) => s !== fetchCommonWordsRequest.toString()),
});

const handleFetchCommonWordsFailure: AnalyticsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchCommonWordsRequest.toString()),
});

const handleFetchTagsAnalytics: AnalyticsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchTagsAnalytics.toString()],
});

const handleFetchTagsAnalyticsSuccess: AnalyticsReducer<TagsCounts> = (state, action) => ({
  ...state,
  tags: action.payload,
});

const handleFetchTagsAnalyticsFailure: AnalyticsReducer<ErrorObject> = (state, action) => ({
  ...state,
  error: action.payload,
});

const handleFetchTagsAnalyticsFulfill: AnalyticsReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchTagsAnalytics.toString()),
});

// TODO: Add error handler when the api response is ready
const handleFetchMetricsDataRequest: AnalyticsReducer<{ widgetId: string }> = (
  state,
  { payload: { widgetId } }
) => {
  if (state.loading.includes(`${widgetId}/${fetchMetricsDataRequest.toString()}`)) {
    return state;
  }

  return {
    ...state,
    error: null,
    loading: [...state.loading, `${widgetId}/${fetchMetricsDataRequest.toString()}`],
  };
};
const handleFetchMetricsDataSuccess: AnalyticsReducer<MetricsData & { widgetId: string }> = (
  state,
  action
) => {
  const metricsError = { ...state.metricsError };
  delete metricsError[action.payload.widgetId];

  return {
    ...state,
    items: { ...state.items, [action.payload.widgetId]: action.payload },
    metricsError,
  };
};

const handleFetchMetricsDataFailure: AnalyticsReducer<ErrorObject & { widgetId: string }> = (
  state,
  action
) => ({
  ...state,
  metricsError: { ...state.metricsError, [action.payload.widgetId]: action.payload },
});

const handleFetchMetricsDataFulfill: AnalyticsReducer<{ widgetId: string }> = (
  state,
  { payload: { widgetId } }
) => ({
  ...state,
  loading: state.loading.filter((s) => s !== `${widgetId}/${fetchMetricsDataRequest.toString()}`),
});

const handleSaveWidgetQueryData: AnalyticsReducer<QueryMetricObject & { id: string }> = (
  state,
  action
) => ({
  ...state,
  queriesMetricData: { ...state.queriesMetricData, [action.payload.id]: action.payload },
});

const handlers = {
  [fetchAllPendingEventsRequest.toString()]: handleFetchAllPendingEventsRequest,
  [fetchAllPendingEventsSuccess.toString()]: handleFetchAllPendingEventsSuccess,
  [fetchAllPendingEventsFailure.toString()]: handleFetchAllPendingEventsFailure,
  [fetchCampaignsPerformanceRequest.toString()]: handleFetchCampaignsPerformanceRequest,
  [fetchCampaignsPerformanceSuccess.toString()]: handleFetchCampaignsPerformanceSuccess,
  [fetchCampaignsPerformanceFailure.toString()]: handleFetchCampaignsPerformanceFailure,
  [fetchEventBucketsRequest.toString()]: handleFetchEventBucketsRequest,
  [fetchEventBucketsSuccess.toString()]: handleFetchEventBucketsSuccess,
  [fetchEventBucketsFailure.toString()]: handleFetchEventBucketsFailure,
  [fetchEnvelopesBucketsRequest.toString()]: handleFetchEnvelopesBucketsRequest,
  [fetchEnvelopesBucketsSuccess.toString()]: handleFetchEnvelopesBucketsSuccess,
  [fetchEnvelopesBucketsFailure.toString()]: handleFetchEnvelopesBucketsFailure,
  [fetchTopUsersByEventsRequest.toString()]: handleFetchTopUsersByEventsRequest,
  [fetchTopUsersByEventsSuccess.toString()]: handleFetchTopUsersByEventsSuccess,
  [fetchTopUsersByEventsFailure.toString()]: handleFetchTopUsersByEventsFailure,
  [fetchAllEventsRequest.toString()]: handleFetchAllEventsRequest,
  [fetchAllEventsSuccess.toString()]: handleFetchAllEventsSuccess,
  [fetchAllEventsFailure.toString()]: handleFetchAllEventsFailure,
  [fetchViolatedEventsRequest.toString()]: handleFetchViolatedEventsRequest,
  [fetchViolatedEventsSuccess.toString()]: handleFetchViolatedEventsSuccess,
  [fetchViolatedEventsFailure.toString()]: handleFetchViolatedEventsFailure,
  [fetchCommsByPlatformRequest.toString()]: handleFetchCommsByPlatformRequest,
  [fetchCommsByPlatformSuccess.toString()]: handleFetchCommsByPlatformSuccess,
  [fetchCommsByPlatformFailure.toString()]: handleFetchCommsByPlatformFailure,
  [fetchWorkforceRiskRequest.toString()]: handleFetchWorkforceRiskRequest,
  [fetchWorkforceRiskSuccess.toString()]: handleFetchWorkforceRiskSuccess,
  [fetchWorkforceRiskFailure.toString()]: handleFetchWorkforceRiskFailure,
  [fetchSentimentOverTimeRequest.toString()]: handleFetchSentimentOverTimeRequest,
  [fetchSentimentOverTimeSuccess.toString()]: handleFetchSentimentOverTimeSuccess,
  [fetchSentimentOverTimeFailure.toString()]: handleFetchSentimentOverTimeFailure,
  [fetchCommonWordsRequest.toString()]: handleFetchCommonWordsRequest,
  [fetchCommonWordsSuccess.toString()]: handleFetchCommonWordsSuccess,
  [fetchCommonWordsFailure.toString()]: handleFetchCommonWordsFailure,
  [fetchTagsAnalytics.toString()]: handleFetchTagsAnalytics,
  [fetchTagsAnalyticsSuccess.toString()]: handleFetchTagsAnalyticsSuccess,
  [fetchTagsAnalyticsFailure.toString()]: handleFetchTagsAnalyticsFailure,
  [fetchTagsAnalyticsFulfill.toString()]: handleFetchTagsAnalyticsFulfill,
  [fetchMetricsDataRequest.toString()]: handleFetchMetricsDataRequest,
  [fetchMetricsDataSuccess.toString()]: handleFetchMetricsDataSuccess,
  [fetchMetricsDataFailure.toString()]: handleFetchMetricsDataFailure,
  [fetchMetricsDataFulfill.toString()]: handleFetchMetricsDataFulfill,
  [saveWidgetQueryData.toString()]: handleSaveWidgetQueryData,
};

const analyticsReducer = createReducer(defaultState, handlers);

export default analyticsReducer;
