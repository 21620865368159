import { setURLParams } from 'actions';
import React, { useState } from 'react';
import Slider from 'react-rangeslider';
import { useDispatch } from 'react-redux';

type ComponentProps = {
  className?: string;
  resource: string;
};

const EnvelopesIsReadFilter: React.FC<ComponentProps> = (props) => {
  const { resource, className } = props;
  const [reservedValue, setReservedValue] = useState<number>(0);

  const dispatch = useDispatch();

  const handleChange = (value: number): void => {
    setReservedValue(Number.parseFloat(value.toFixed(1)));
    dispatch(
      setURLParams({
        [`${resource}__sentiment_less_than`]: Number.parseFloat(
          value.toFixed(1)
        ) as unknown as string,
        [`${resource}__offset`]: '0',
      })
    );
  };

  return (
    <div className={`w-80 ${className}`}>
      <Slider
        min={-1}
        step={0.1}
        max={1}
        value={reservedValue}
        orientation="horizontal"
        onChange={handleChange}
      />
    </div>
  );
};

export default EnvelopesIsReadFilter;
