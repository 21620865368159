/* eslint-disable max-lines */
import { fetchCustomersMe } from 'actions';
import CollapsableList from 'components/CollapsableList';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import SearchInput from 'components/SearchInput';
import { MODAL_BACKGROUND_REDESIGN } from 'constants/common';
import { CLOSE_MODAL_ICON } from 'constants/commonIcons';
import { ARROW_CLOSE_ENVELOPE } from 'constants/envelopeIcons';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCustomerDomain,
  getCustomersMeLoading,
  getSelectCustomerList,
  getUser,
} from 'selectors/auth';
import { Customer } from 'types';
import { useHistory } from 'utils/urls';

type ItemType = {
  uuid: string;
  label: string | null;
  domain: string | null;
  subitems: ItemType[];
};

type ComponentProps = {
  toggleModalOpen: () => void;
  selectCustomer: (customer: { domain: string | null; uuid: string; label: string | null }) => void;
  showActionsButton?: boolean;
  onlyCloneCustomers?: boolean;
};

const SelectCustomerModal: React.FC<ComponentProps> = ({
  toggleModalOpen,
  selectCustomer,
  showActionsButton = true,
  onlyCloneCustomers = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const customerDomain = useSelector(getCustomerDomain);
  const customers = useSelector(getSelectCustomerList);
  const loading = useSelector(getCustomersMeLoading);
  const user = useSelector(getUser);
  const [filtered, setFiltered] = useState<Customer[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(fetchCustomersMe);
  }, [dispatch]);

  const filterBySearch = useCallback((): void => {
    setFiltered(
      customers.filter((item) => {
        const { name } = item;
        if (name != null) return name.toLowerCase().includes(search.toLowerCase() || '');
        return '';
      })
    );
  }, [customers, search]);

  useEffect(() => {
    filterBySearch();
  }, [search, filterBySearch, customers]);

  const handleSearchChange = (value: string): void => {
    setSearch(value);
  };

  const handleAddNewCustomer = (): void => {
    history.pushLookup({
      customerDomain,
      routeName: 'create-customer',
    });
    toggleModalOpen();
  };

  const handleSelectCustomer = (customer: ItemType): void => {
    selectCustomer(customer);
  };

  const reprocess = (node: ItemType, leaves: Customer[]): ItemType[] =>
    leaves
      .filter((i) => i.clone_of_customer_uuid === node.uuid)
      .map((l) => {
        const newNode = { uuid: l.uuid, subitems: [], label: l.name, domain: l.domain };
        return {
          ...newNode,
          sub: reprocess(newNode, leaves),
          subitems: reprocess(newNode, leaves),
        };
      });

  const customersList = (): ItemType[] => {
    const root: ItemType[] = [];
    let leaves: Customer[] = [];

    const sortedCustomers = [...filtered];
    sortedCustomers.sort((a, b) =>
      a.name && b.name && a.name?.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );

    sortedCustomers.forEach((c) => {
      if (c.clone_of_customer_uuid === null) {
        root.push({ uuid: c.uuid, subitems: [], label: c.name, domain: c.domain });
      } else {
        leaves.push(c);
      }
    });

    root.forEach((r) => {
      r.subitems = reprocess(r, leaves);
      leaves = leaves.filter((c) => !r.subitems.some((s) => s.uuid === c.uuid));
    });

    leaves.forEach((c) => {
      root.push({ uuid: c.uuid, subitems: [], label: c.name, domain: c.domain });
    });

    if (onlyCloneCustomers) {
      const customerId = user.customer?.uuid;
      const customer = customers.find((c) => c.uuid === customerId);
      if (customer?.clone_of_customer_uuid !== null) {
        const original = customers.find((c) => c.uuid === customer?.clone_of_customer_uuid);
        if (original) {
          return [
            { uuid: original.uuid, subitems: [], label: original.name, domain: original.domain },
          ];
        }
      }
      const clones = root.reduce((acc, r) => {
        if (r.uuid === customerId) {
          r.subitems.forEach((s) => {
            acc.push(s);
          });
        }
        return acc;
      }, [] as ItemType[]);
      return clones;
    }

    return root;
  };

  const customerListComponent = (
    item: ItemType,
    level: number,
    expanded: boolean,
    handleExpandClick: () => void
  ): JSX.Element => {
    const isChildren = level > 0;
    const hasChildren = item.subitems.length > 0;

    return (
      <button
        type="button"
        onClick={(e): void => {
          e.preventDefault();
          handleSelectCustomer(item);
        }}
        className={`flex flex-row justify-between items-stretch w-full pl-6 pr-4 text-body text-litlingo-gray-3 border-b  border-litlingo-gray-1  focus:outline-none ${
          isChildren
            ? 'bg-litlingo-gray-0.5 hover:bg-litlingo-gray-1'
            : 'bg-white hover:bg-litlingo-gray-1'
        }`}
        style={isChildren ? { paddingLeft: 16 + level * 24 } : {}}
      >
        <span className="py-1.5 font-bold text-litlingo-gray-5">{item.label}</span>
        {hasChildren && (
          <div className="flex flex-row justify-center items-center">
            <button
              type="button"
              onClick={(e): void => {
                e.stopPropagation();
                handleExpandClick();
              }}
              className={`flex flex-row justify-end w-24 focus:outline-none `}
            >
              <span
                className={`py-1.5  focus:outline-none transition-transform duration-300  ${
                  expanded && 'transform rotate-90'
                }`}
              >
                {ARROW_CLOSE_ENVELOPE()}
              </span>
            </button>
          </div>
        )}
      </button>
    );
  };

  const customerEmptyListComponent = (): JSX.Element => (
    <div className="flex flex-col items-center text-center gap-2 pt-10 ">
      <h2 className="text-heading-3">Workspace not found</h2>
      <span>
        Suggestions: Make sure all words are spelled correctly or try a more general search.
      </span>
      <div>
        <button type="button" className="button button--primary h-8" onClick={handleAddNewCustomer}>
          Add a New Workspace
        </button>
      </div>
    </div>
  );

  const customerListBody = (
    <>
      <div className="absolute top-0 left-0 right-0 h-22 z-0">{MODAL_BACKGROUND_REDESIGN}</div>
      <div className="flex flex-row justify-end pr-6 pt-6">
        <button
          type="button"
          data-testid="close-button"
          className="w-6 z-10 focus:outline-none "
          aria-label="Close"
          onClick={toggleModalOpen}
        >
          {CLOSE_MODAL_ICON}
        </button>
      </div>

      <div className="flex flex-col mt-14 overflow-hidden transition-all duration-300">
        {loading && customers.length === 0 ? (
          <div className="w-full flex flex-row justify-center my-14 px-6">
            <LoadingIndicator size="20" />
          </div>
        ) : (
          <>
            <div className="px-6">
              <h2 className="text-heading-1">Select a workspace</h2>
              <div className="pt-4">
                <SearchInput
                  placeholder="Search workspace"
                  className="w-full"
                  handleSearch={handleSearchChange}
                />
              </div>
            </div>

            <div className="border-b border-litlingo-gray-2 mt-2" />
            {filtered.length ? (
              <div
                className={`${
                  customersList().length <= 3 && customersList().length >= 1 ? 'pb-18' : 'h-52'
                }  `}
              >
                <CollapsableList
                  list={customersList()}
                  renderExpandIcon={false}
                  indentation={false}
                  component={customerListComponent}
                  className="custom-scrollbar"
                  elementsGap={false}
                />
              </div>
            ) : (
              customerEmptyListComponent()
            )}
          </>
        )}
      </div>
    </>
  );

  const noCustomersBody = (
    <>
      <div className="absolute top-0 left-0 right-0 h-22 z-0">{MODAL_BACKGROUND_REDESIGN}</div>
      <div className="flex flex-row justify-end pr-6 pt-6">
        <button
          type="button"
          data-testid="close-button"
          className="w-6 z-10 focus:outline-none "
          aria-label="Close"
          onClick={toggleModalOpen}
        >
          {CLOSE_MODAL_ICON}
        </button>
      </div>
      <div className="flex flex-col items-center px-6 mt-30 mb-44 gap-2 max-h-128 overflow-hidden">
        <h2 className="text-heading-1">No other workspaces available</h2>
        <p className="text-body">Doesn’t seem right? Talk to your admin to get added.</p>
      </div>
    </>
  );

  const differentCustomers = customers.some((c) => c.uuid !== user.customer?.uuid);

  return (
    <Modal
      body={!differentCustomers && !loading ? noCustomersBody : customerListBody}
      title=" "
      toggleShowModal={toggleModalOpen}
      cancelButton={
        showActionsButton && customersList().length <= 3 && customersList().length >= 1 && !loading
      }
      okButton={
        showActionsButton && customersList().length <= 3 && customersList().length >= 1 && !loading
      }
      okButtonText="Add New"
      okButtonStyle="mr-6"
      okButtonOnClick={handleAddNewCustomer}
      xButton={false}
      style={{
        width: '546px',
        minHeight: '415px',
        maxHeight: '415px',
        padding: '0px',
      }}
    />
  );
};

export default SelectCustomerModal;
