import {
  deleteTemplate,
  deleteTemplateFailure,
  deleteTemplateRequest,
  deleteTemplateSuccess,
  fetchAllTemplates,
  fetchSingleTemplate,
  fetchSingleTemplateFailure,
  fetchSingleTemplateRequest,
  fetchSingleTemplateSuccess,
  fetchTemplates,
  fetchTemplatesFailure,
  fetchTemplatesRequest,
  fetchTemplatesSuccess,
  showSuccessAlert,
  upsertTemplate,
  upsertTemplateFailure,
  upsertTemplateRequest,
  upsertTemplateSuccess,
} from 'actions';
import { apiClient as LitLingoClient } from 'client';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getNavParamsByResource } from 'selectors/nav';
import type { API, SagaReturn } from 'types';

function* fetchAllTemplatesSaga(): SagaReturn {
  yield put(fetchTemplatesRequest());

  const params = {
    include_count: true,
    limit: -1,
  };

  const response = (yield call([LitLingoClient.resources.templates, 'list'], {
    params,
  })) as API.Response<API.Templates.List>;

  if (response.error != null) {
    yield put(fetchTemplatesFailure(response.error));
  } else {
    yield put(fetchTemplatesSuccess(response.data));
  }
}

function* fetchTemplatesSaga(action: ReturnType<typeof fetchTemplates>): SagaReturn {
  yield put(fetchTemplatesRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.templates)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = {
    include_count: true,
    ...resourceParams,
    ...action.payload,
  };

  const response = (yield call([LitLingoClient.resources.templates, 'list'], {
    params: { ...params },
  })) as API.Response<API.Templates.List>;

  if (response.error != null) {
    yield put(fetchTemplatesFailure(response.error));
  } else {
    yield put(fetchTemplatesSuccess(response.data));
  }
}

function* fetchSingleTemplateSaga({ payload }: ReturnType<typeof fetchSingleTemplate>): SagaReturn {
  yield put(fetchSingleTemplateRequest());

  const response = (yield call(
    [LitLingoClient.resources.templates, 'retrieve'],
    payload.id
  )) as API.Response<API.Templates.Retrieve>;

  if (response.error != null) {
    yield put(fetchSingleTemplateFailure(response.error));
  } else {
    yield put(fetchSingleTemplateSuccess(response.data));
  }
}

function* upsertTemplateSaga(action: ReturnType<typeof upsertTemplate>): SagaReturn {
  const { payload } = action;

  yield put(upsertTemplateRequest());

  const response = (yield call([LitLingoClient.resources.templates, 'upsert'], {
    data: payload,
  })) as API.Response<API.Templates.Upsert>;
  if (response.error != null) {
    yield put(upsertTemplateFailure(response.error));
  } else {
    yield put(upsertTemplateSuccess(response.data));
    yield put(showSuccessAlert('Saved template'));
  }
}

function* deleteTemplateSaga(action: ReturnType<typeof deleteTemplate>): SagaReturn {
  const { payload } = action;
  const { id } = payload;
  yield put(deleteTemplateRequest());
  const response = (yield call(
    [LitLingoClient.resources.templates, 'delete'],
    id
  )) as API.Response<API.Templates.Delete>;
  if (response.error != null) {
    yield put(deleteTemplateFailure(response.error));
  } else {
    yield put(deleteTemplateSuccess(id));
  }
}

export default function* templatesSaga(): SagaReturn {
  yield takeLatest(fetchAllTemplates.toString(), fetchAllTemplatesSaga);
  yield takeLatest(fetchTemplates.toString(), fetchTemplatesSaga);
  yield takeLatest(fetchSingleTemplate.toString(), fetchSingleTemplateSaga);
  yield takeLatest(upsertTemplate.toString(), upsertTemplateSaga);
  yield takeLatest(deleteTemplate.toString(), deleteTemplateSaga);
}
