import { UUID } from '@litlingo/client';
import { cloneIdentifier } from 'actions/identifier';
import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';

type ComponentProps = {
  selectedIdentifiers: UUID[];
  toggleModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  // setShowIdentifierModels: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeletingIdentifier: React.Dispatch<React.SetStateAction<boolean>>;
  // showIdentifierModels: boolean;
};

const IdentifierListActionsRow: React.FC<ComponentProps> = ({
  selectedIdentifiers,
  toggleModalOpen,
  // setShowIdentifierModels,
  setIsDeletingIdentifier,
  // showIdentifierModels,
}) => {
  const dispatch = useDispatch();

  const disableButtons = selectedIdentifiers.length !== 1;
  const disableMergeButton = selectedIdentifiers.length < 2;
  const identifierCount = selectedIdentifiers.length;

  const handleDeleteIdentifier = (): void => {
    setIsDeletingIdentifier(true);
  };

  const handleCloneIdentifier = (): void => {
    dispatch(cloneIdentifier({ identifierId: selectedIdentifiers[0] }));
  };

  return (
    <tr className="bg-litlingo-gray-2">
      <th
        colSpan={7}
        className="table-wrapper__new-td table-wrapper__bulk-area bg-litlingo-gray-2 "
      >
        <div className="table-wrapper__new-cell-content flex flex-row justify-between items-start gap-3 py-2 m-0 h-auto overflow-visible">
          {/* starship <div className="flex flex-row justify-between items-start gap-2 ml-6 h-auto overflow-visible">
            <button
              type="button"
              className={classNames('button button--secondary h-8 ', {
                'disabled-button': showIdentifierModels,
              })}
              onClick={(): void => setShowIdentifierModels(true)}
            >
              <span className="text-litlingo-gray-600 font-bold">Show Models</span>
            </button>
            <button
              type="button"
              className={classNames('button button--secondary h-8 ', {
                'disabled-button': !showIdentifierModels,
              })}
              onClick={(): void => setShowIdentifierModels(false)}
            >
              <span className="text-litlingo-gray-600 font-bold">Hide Models</span>
            </button>
            <button
              type="button"
              disabled={disableButtons}
              onClick={(): void => toggleModalOpen(true)}
              className={classNames('button button--secondary h-8 ', {
                'disabled-button': disableButtons,
              })}
            >
              <span className="text-litlingo-gray-600 font-bold">Merge</span>
            </button>
          </div> */}
          <div className="flex flex-row justify-between items-start gap-2 ml-6 h-auto overflow-visible">
            <button
              type="button"
              disabled={disableButtons}
              onClick={handleCloneIdentifier}
              className={classNames('button button--secondary h-8 ', {
                'disabled-button': disableButtons,
              })}
            >
              <span className="text-litlingo-gray-600 font-bold whitespace-no-wrap">
                Make a copy of this identifier
              </span>
            </button>
            <button
              type="button"
              disabled={disableMergeButton}
              onClick={(): void => toggleModalOpen(true)}
              className={classNames('button button--secondary h-8 ', {
                'disabled-button': disableMergeButton,
              })}
            >
              <span className="text-litlingo-gray-600 font-bold">Merge</span>
            </button>
          </div>

          <div className="flex flex-row w-full justify-end h-8">
            <div className="text-body whitespace-no-wrap self-end mx-2">{`${identifierCount.toLocaleString()} selected`}</div>
            <button
              type="button"
              className={classNames('button button--secondary flex justify-center w-32 h-8 ml-2', {
                'disabled-button': false,
              })}
              data-testid="bulk-delete-button"
              onClick={handleDeleteIdentifier}
            >
              <span className="text-litlingo-red-bg font-bold">Delete</span>
            </button>
          </div>
        </div>
      </th>
    </tr>
  );
};

export const MemoizedTestCaseListActionsRow = React.memo(IdentifierListActionsRow);

export default IdentifierListActionsRow;
