import LoadingIndicator from 'components/LoadingIndicator';
import TestResultsVisualizer from 'components/TestResultsVisualizer';
import TestSentenceVisualizer from 'components/TestSentenceVisualizer';
import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { getAnnotatedTestSentenceResult, getTestSentenceLoading } from 'selectors/communications';
import { saveRuleRequestLoading } from 'selectors/rule';
import { useSelector } from 'store';

type ComponentProps = {
  title?: string;
  body?: JSX.Element | null;
  okButton?: boolean;
  okButtonText?: string;
  saveButtonOnClick?: () => void;
  okButtonOnClick?: () => void;
  cancelButtonText?: string;
  cancelButtonOnclick?: () => void;
  toggleShowModal?: () => void;
  style?: { [key: string]: string };
  setAdvancedModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const RelationshipManagerAdvancedModal: React.FC<ComponentProps> = ({
  title,
  body,
  okButton,
  okButtonText,
  okButtonOnClick,
  saveButtonOnClick,
  cancelButtonText,
  cancelButtonOnclick,
  toggleShowModal,
  style,
  setAdvancedModal,
}) => {
  const resultLines = useSelector(getAnnotatedTestSentenceResult);
  const testSentenceLoading = useSelector(getTestSentenceLoading);
  const updateRuleLoading = useSelector(saveRuleRequestLoading);

  const renderOkButton = (): JSX.Element | null => {
    if (!okButton) return null;
    return (
      <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
        <button
          type="button"
          data-testid="ok-button"
          className="button button--primary"
          onClick={okButtonOnClick}
        >
          {okButtonText || 'Ok'}
        </button>
      </span>
    );
  };

  return (
    <div
      className="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-modal"
      style={{ minWidth: '1024px' }}
    >
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-500 opacity-75" />
      </div>
      <div
        style={style || { minWidth: '1024px' }}
        className="flex flex-col justify-between bg-white h-4/5 max-w-4/5 rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all sm:w-full sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="h-2/5 max-h-2/5">
          <div className="flex flex-row justify-between">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:text-left">
                <h3 className="text-xl leading-7 font-bold text-gray-900" id="modal-headline">
                  {title || 'Custom tittle'}
                </h3>
              </div>
            </div>
            <div className="hidden sm:block">
              <button
                type="button"
                data-testid="close-button"
                className="text-gray-700 hover:text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                aria-label="Close"
                onClick={toggleShowModal}
              >
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex flex-row justify-between max-h-9/10">
            <div className="flex flex-col w-2/5 mr-4">{body || null}</div>
            <div className="flex flex-col w-3/5 h-full overflow-auto">
              <TestSentenceVisualizer width="w-full" />
            </div>
          </div>
        </div>
        <div
          className={`relative flex flex-col h-1/2 w-full mt-1 pt-1 overflow-y-auto border-t border-litlingo-line ${
            resultLines ? '' : 'justify-center items-center'
          }`}
        >
          <LoadingOverlay
            data-testid="loading-overlay"
            active={testSentenceLoading}
            spinner={<LoadingIndicator size="10" />}
            fadeSpeed={0}
            styles={{
              content: (base) => ({
                ...base,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }),
              wrapper: () => ({}),
              spinner: () => ({}),
              overlay: (base) => ({
                ...base,
                zIndex: 10,
              }),
            }}
          >
            {resultLines ? <TestResultsVisualizer /> : <h3>Run a test to see the results.</h3>}
          </LoadingOverlay>
        </div>
        <div className="mt-2 sm:mt-1 sm:flex sm:flex-row-reverse justify-between">
          <div className="sm:flex sm:flex-row-reverse items-center">
            {renderOkButton()}
            <span className="sm:ml-3 mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
              <button
                type="button"
                data-testid="save-button"
                className="button bg-litlingo-gray-50 text text-base"
                onClick={saveButtonOnClick}
              >
                Save
              </button>
            </span>
            <span className="sm:ml-3 mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
              <button
                type="button"
                data-testid="cancel-button"
                className="button bg-litlingo-gray-50 text text-base"
                onClick={cancelButtonOnclick || toggleShowModal}
              >
                {cancelButtonText || 'Cancel'}
              </button>
            </span>
            {updateRuleLoading && <LoadingIndicator data-testid="loading-indicator" size="5" />}
          </div>
          <div>
            <span className="sm:ml-3 mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
              <button
                type="button"
                data-testid="basic-view-button"
                className="button bg-litlingo-gray-50 text text-base"
                onClick={(): void => setAdvancedModal(false)}
              >
                Basic view
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelationshipManagerAdvancedModal;
