import React from 'react';
import ReactMarkdownWithHtml from 'react-markdown/with-html';
import type { AnnotatedGraph } from '../types';
import HighlightedSentence from './HighlightedSentence';

type OutcomeCardProps = {
  lines: AnnotatedGraph[];
  title: string;
  customer?: string;
  outcome: string;
};

const OutcomeCard: React.FC<OutcomeCardProps> = (props) => {
  const { lines, title, customer, outcome } = props;

  return (
    <div className="bg-white flex flex-col litlingo__outcome_card w-full">
      <div className="p-2 litlingo__color_grey">
        <div
          style={{ fontSize: '14px' }}
          className="font-medium mb-3 text-left uppercase"
        >
          {title}
        </div>

        <div className="w-full text-sm leading-5 text-left overflow-auto">
          {lines.map((line, idx) => (
            <div key={line.index} className={idx !== 0 ? 'mt-2' : ''}>
              <HighlightedSentence
                nodes={line.nodes}
                useHover={false}
                protectedFields={{
                  entity: 'FIELD_IS_UNPROTECTED',
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="litlingo__color_grey p-2 text-left text-sm whitespace-normal bg-litlingo-light-blue">
        {customer != null && (
          <span className="font-bold">{customer} Note: </span>
        )}
        <div className="markdown-body litlingo-video-wrapper">
          <ReactMarkdownWithHtml allowDangerousHtml>
            {outcome}
          </ReactMarkdownWithHtml>
        </div>
      </div>
    </div>
  );
};

export default OutcomeCard;
