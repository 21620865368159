import Permissions from 'components/Permissions';
import useClickOutside from 'components/utils/useClickOutside';
import React, { useCallback, useRef, useState } from 'react';
import Transition from 'styles/Transition';

type Option = {
  id: string;
  label: string;
  action: () => void;
  permissions?: string;
};

type ComponentProps = {
  options: Option[];
  colorClass?: string;
  translate?: boolean;
};

const ThreeDotsMenu: React.FC<ComponentProps> = (props) => {
  const { options, colorClass, translate = true } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleClick = (action: Option['action']): void => {
    setIsOpen(false);
    action();
  };

  const renderOption = (o: Option): JSX.Element => {
    if (o.permissions) {
      return (
        <Permissions action={o.permissions} key={o.id}>
          <button
            data-testid={`menu-option-${o.id}`}
            type="button"
            className="flex flex-row py-2 items-center hover:bg-gray-100 focus:outline-none"
            onClick={(): void => handleClick(o.action)}
          >
            <div className="px-2">{o.label}</div>
          </button>
        </Permissions>
      );
    }

    return (
      <button
        key={o.id}
        type="button"
        data-testid={`menu-option-${o.id}`}
        className="flex flex-row py-2 items-center hover:bg-gray-100 focus:outline-none"
        onClick={(): void => handleClick(o.action)}
      >
        <div className="px-2">{o.label}</div>
      </button>
    );
  };

  return (
    <div className="relative flex items-center" ref={wrapperRef}>
      <button
        className={`ml-2 text-litlingo-label focus:outline-none ${colorClass}`}
        type="button"
        data-testid="three-dots-menu"
        onClick={(): void => setIsOpen(!isOpen)}
      >
        <svg
          width="4"
          height="18"
          viewBox="0 0 4 18"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2V2.01V2ZM2 9V9.01V9ZM2 16V16.01V16ZM2 3C1.73478 3 1.48043 2.89464 1.29289 2.70711C1.10536 2.51957 1 2.26522 1 2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1C2.26522 1 2.51957 1.10536 2.70711 1.29289C2.89464 1.48043 3 1.73478 3 2C3 2.26522 2.89464 2.51957 2.70711 2.70711C2.51957 2.89464 2.26522 3 2 3ZM2 10C1.73478 10 1.48043 9.89464 1.29289 9.70711C1.10536 9.51957 1 9.26522 1 9C1 8.73478 1.10536 8.48043 1.29289 8.29289C1.48043 8.10536 1.73478 8 2 8C2.26522 8 2.51957 8.10536 2.70711 8.29289C2.89464 8.48043 3 8.73478 3 9C3 9.26522 2.89464 9.51957 2.70711 9.70711C2.51957 9.89464 2.26522 10 2 10ZM2 17C1.73478 17 1.48043 16.8946 1.29289 16.7071C1.10536 16.5196 1 16.2652 1 16C1 15.7348 1.10536 15.4804 1.29289 15.2929C1.48043 15.1054 1.73478 15 2 15C2.26522 15 2.51957 15.1054 2.70711 15.2929C2.89464 15.4804 3 15.7348 3 16C3 16.2652 2.89464 16.5196 2.70711 16.7071C2.51957 16.8946 2.26522 17 2 17Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div
          className="absolute left-full top-full w-max w-36 mt-1 rounded-md shadow-lg z-50"
          style={translate ? { transform: 'translateX(-100%)' } : {}}
        >
          <div className="flex flex-col h-max rounded-md bg-white text-litlingo-gray-6 shadow-xs overflow-auto no-scrollbar z-100">
            {options.map((o) => renderOption(o))}
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default ThreeDotsMenu;
