/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
import { clearTree, requestTreeFiltersToogle } from 'actions';
import Modal from 'components/Modal';
import { MODAL_BACKGROUND_REDESIGN } from 'constants/common';
import { CLOSE_MODAL_ICON } from 'constants/commonIcons';
import {
  allowedReadOnlyDimensions,
  bgSimpleMetricMap,
  borderSimpleMetricMap,
  metricsLinksMap,
  newIconsMap,
} from 'constants/dashboard';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetError from 'decorators/widget/WidgetError';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCustomerDomain, getOfficeExtensionEnabled, getUser } from 'selectors/auth';
import { getNavParamsByResource, getNavWidgetFilters } from 'selectors/nav';
import { getPermissionsPolicy } from 'selectors/permissions';
import { useSelector } from 'store';
import { DashboardMetric, DefaultWidgetComponentProps } from 'types';
import logEvent from 'utils/analytics';
import { buildPeriodTrendAndSimpleDataFromMetrics } from 'utils/dashboard';
import { getValuesFromDimensions, setNewValue, transformToString } from 'utils/parserTree';
import { isActionAuthorized } from 'utils/permissions';
import { useHistory } from 'utils/urls';

const NewSimpleMetricsWidget: React.FC<DefaultWidgetComponentProps> = (props) => {
  // @ts-ignore
  const { widgetId, widgetData, queryData, fetchAvgPeriod } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const customerDomain = useSelector(getCustomerDomain);
  const widgetFilters = useSelector(getNavWidgetFilters(widgetId));
  const filters = useSelector(getNavParamsByResource(resourceQueryParamName.metrics));
  const preventEnabled = useSelector(getOfficeExtensionEnabled);

  const [showModal, setShowModal] = useState(false);

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  const processedData = buildPeriodTrendAndSimpleDataFromMetrics(widgetData, queryData, true);
  if ('error' in processedData && processedData.error != null) {
    return <WidgetError msg={processedData.error} />;
  }

  if ('error' in processedData) {
    return <WidgetError msg={processedData.error} />;
  }

  if ('data' in processedData && processedData.data.length === 0) {
    return <WidgetEmptyState />;
  }

  const toggleModal = (): void => {
    setShowModal(!showModal);
  };

  const handleClick = (query: DashboardMetric): void => {
    if (!preventEnabled) {
      toggleModal();
      return;
    }

    const [id, type] = widgetId.split('--');
    logEvent(`Dashboard-click-${id}-${type}`);
    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];

    if (info == null || !isActionAuthorized(info.action, user.roles, policy)) {
      return;
    }

    const dimensions: Record<string, string | string[]> = {};
    const infoDimension = info.dimensions;
    if (infoDimension != null) {
      // Top level dimensions
      Object.entries(infoDimension).forEach(([dimension, param]) => {
        const allowed = allowedReadOnlyDimensions[query.metric];

        // if allowedDimension is not defined or the dimension is not included,
        // just ignore it
        if (allowed == null || !allowed.includes(dimension)) {
          return;
        }

        let value: string | string[] = '';
        if (filters[dimension] != null) {
          const topValue = filters[dimension];
          value = topValue;
        }

        if (widgetFilters[dimension] != null) {
          const widgetValue = filters[dimension];
          value = widgetValue;
        }

        dimensions[`${info.resource}__${param}`] = value;
      });
      // Metric level dimensions
      if (query.dimensions != null) {
        query.dimensions.forEach((dimension) => {
          const allowed = allowedReadOnlyDimensions[query.metric];

          // if allowedDimension is not defined or the dimension is not included,
          // just ignore it
          if (allowed == null || !allowed.includes(dimension.name)) {
            return;
          }

          if (dimension.name === 'active_prevent') {
            dimensions[`${info.resource}__key_actions_and`] = 'sent_within_policy';
          }

          if (!dimensions[`${info.resource}__${infoDimension[dimension.name]}`]) {
            dimensions[`${info.resource}__${infoDimension[dimension.name]}`] = [dimension.value];
          } else if (
            !dimensions[`${info.resource}__${infoDimension[dimension.name]}`].includes(
              dimension.value
            )
          ) {
            dimensions[`${info.resource}__${infoDimension[dimension.name]}`] = [
              ...dimensions[`${info.resource}__${infoDimension[dimension.name]}`],
              dimension.value,
            ];
          }
        });
      }

      if (query.metric.includes('active_prevent')) {
        if (query.metric === 'active_prevent') {
          dimensions[`${info.resource}__key_actions_and`] = 'sent_within_policy';
        }

        if (query.metric === 'active_prevent_sent_anyways') {
          dimensions[`${info.resource}__key_actions_and`] = 'send_anyways';
        }
      }

      if (['out_of_policy', 'reviewed', 'escalated'].includes(query.metric)) {
        dimensions[`${info.resource}__review_values`] = query.metric;
      }
      if (query.metric === 'acceptable') {
        dimensions[`${info.resource}__review_values`] = 'in_policy';
      }
    }

    const dayAdjust = moment
      .duration(1, query.unit_of_time as moment.unitOfTime.DurationConstructor)
      .asSeconds();
    // Metric level dimensions have more priority than top leve dimensions
    let start;
    if (fetchAvgPeriod) {
      start = moment(
        (queryData.end_time -
          (moment
            .duration(query.period, query.unit_of_time as moment.unitOfTime.DurationConstructor)
            .asSeconds() -
            dayAdjust)) *
          1000
      )
        .startOf('day')
        .utc()
        .format(FULL_DATE_FORMAT);
    } else {
      start = moment.utc(queryData.start_time * 1000).format(FULL_DATE_FORMAT);
    }

    const end = moment.utc(queryData.end_time * 1000).format(FULL_DATE_FORMAT);

    const tree = getValuesFromDimensions(dimensions);

    const newTree = setNewValue(tree, 'date_range', `${start}<>${end}`, '', true);

    dispatch(requestTreeFiltersToogle({ value: true }));
    dispatch(clearTree());

    history.pushLookup({
      customerDomain,
      routeName: info.route,
      queryParams: {
        [`${info.resource}__filters_search`]: transformToString(newTree),
        [`${info.resource}__has_events`]: ['true', 'false'],
      },
    });
  };

  let finalIcon = queryData.queries[0].icon || '';

  if (finalIcon === 'prevented_message' && !preventEnabled) {
    finalIcon = 'prevented_message_off';
  }
  if (finalIcon === 'guidance_ignored' && !preventEnabled) {
    finalIcon = 'guidance_ignored_off';
  }

  const tryPreventModal = (
    <>
      <div className="w-full absolute top-6 left-0 right-0 h-22 z-0">
        {MODAL_BACKGROUND_REDESIGN}
      </div>
      <div className="flex flex-row justify-end pr-6 pt-6">
        <button
          type="button"
          data-testid="close-button"
          className="w-6 z-10 focus:outline-none "
          aria-label="Close"
          onClick={toggleModal}
        >
          {CLOSE_MODAL_ICON}
        </button>
      </div>

      <div className="flex flex-col mt-24 overflow-hidden transition-all duration-300">
        <>
          <div className="flex flex-col gap-3 px-6 pb-6">
            <h2 className="text-heading-1"> Experience LitLingo Prevent in action</h2>

            <p>
              Alert employees in real-time before they send messages that could breach policy.
              Curious? Reach out to your LitLingo customer success manager to learn more.
            </p>
          </div>
        </>
      </div>
    </>
  );

  return (
    <div className="w-full h-full flex flex-col gap-1">
      <div
        className={`w-full flex flex-row ${bgSimpleMetricMap[finalIcon || '']} ${
          borderSimpleMetricMap[finalIcon || '']
        } rounded border-4 `}
      >
        <div
          className={`flex justify-center items-center border-t-4 border-b-4 border-r-4 ${
            borderSimpleMetricMap[finalIcon || '']
          }`}
        >
          <span className="w-11 flex justify-center items-center">
            {processedData.data[0].icon && newIconsMap[finalIcon]}
          </span>
        </div>
        <div className="flex flex-row gap-1 w-full">
          {processedData.data.map((data, idx) => (
            <button
              type="button"
              className="h-full flex w-full flex-row justify-center items-center gap-1 xl:gap-2 pl-1 2lg:pl-2 xl:pl-4 py-1 bg-white hover:bg-litlingo-gray-0.5 focus:outline-none"
              key={data.label}
              onClick={(): void => handleClick(queryData.queries[idx])}
            >
              <div className="text-title-activity-widget">
                <span className="font-normal">{data.count ? data.count : 0}</span>
              </div>
              <div className="text-body">{data.label}</div>
            </button>
          ))}
        </div>
      </div>
      <span className="min-h-4 text-small self-end">
        {processedData.data.length === 1 &&
          processedData.data[0].perChange &&
          processedData.data[0].perChange}
      </span>

      {showModal && (
        <Modal
          body={tryPreventModal}
          title=" "
          okButton
          okButtonText="Got it"
          okButtonOnClick={toggleModal}
          okButtonStyle="mr-4"
          cancelButton
          cancelButtonText="Learn More"
          cancelButtonOnclick={(): void => {
            window.open('https://www.litlingo.com/products/prevent');
          }}
          xButton={false}
          style={{
            width: '600px',
            padding: '0px 0px 20px 0px',
          }}
        />
      )}
    </div>
  );
};

export default withWidget({
  bgClassColor: 'bg-transparent',
})(NewSimpleMetricsWidget);

export const NewActivePreventWidget = withWidget({
  fetchAvgPeriod: true,
  bgClassColor: 'bg-transparent',
})(NewSimpleMetricsWidget);
