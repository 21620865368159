import config from '@config';

// to have the proper types on the client this can't have a type, if debugging
// is needed ClientSpec can be used as the type
const BaseAPISpec = {
  base: config.api.base,
  logs: false,

  headers: {
    'x-litlingo-auth-no-redirect': 'true',
    'block-overwrite': 'true',
    'cache-control': 'no-cache',
  },

  resources: {
    logMetrics: {
      path: '/queue-stats',
      include: [],
      extras: {
        logMetrics: {
          path: '',
          method: 'GET',
        },
      },
    },
  },
} as const;

export default Object.freeze(BaseAPISpec);
