import { CirclePacking } from '@nivo/circle-packing';
import Navbar from 'components/Navbar';
import React, { useState } from 'react';
import type { WordBubbleData } from 'types';

const WordBubble: React.FC = () => {
  const [wordBubbleData, setWordBubbleData] = useState<WordBubbleData | null>(null);

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const fileReader = new FileReader();
    if (e.target.files != null) {
      fileReader.readAsText(e.target.files[0], 'UTF-8');
      fileReader.onload = (event): void => {
        if (event.target != null && event.target.result != null)
          setWordBubbleData(JSON.parse(event.target.result as string));
      };
    }
  };

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />
      <label htmlFor="sankey-file" className="litlingo-annotator-label-name flex flex-col mt-6">
        <span>Please upload a JSON file with the data for the Bubble chart</span>
        <input type="file" name="sankey-file" id="sankey-file" onChange={handleChangeFile} />
      </label>

      {wordBubbleData && (
        <div className="h-sankey-events">
          <CirclePacking
            root={wordBubbleData}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            identity="name"
            value="loc"
            colors={{ scheme: 'purple_blue_green' }}
            padding={6}
            labelTextColor={{ from: 'color', modifiers: [['darker', 0.8]] }}
            borderWidth={2}
            borderColor={{ from: 'color' }}
            defs={[
              {
                id: 'lines',
                type: 'patternLines',
                background: 'none',
                color: 'inherit',
                rotation: -45,
                lineWidth: 5,
                spacing: 8,
              },
            ]}
            fill={[{ match: { depth: 1 }, id: 'lines' }]}
            animate
            motionStiffness={90}
            motionDamping={12}
          />
        </div>
      )}
    </div>
  );
};

export default WordBubble;
