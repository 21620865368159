import { selectItem } from 'actions';
import { fetchAllTestCases } from 'actions/testCases';
import TestCaseStateFilter from 'components/Filters/TestCaseStateFilter';
import TestCasePieChart from 'components/TestCasePieChart';
import { CLOSE_MODAL_ICON } from 'constants/commonIcons';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import pluralize from 'pluralize';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getSelectedNode } from 'selectors/config';
import { getTestCaseSummary, getTestCasesTotalCount } from 'selectors/testCases';
import { useSelector } from 'store';

type ComponentProps = {
  toogleTestSuiteSidebar: () => void;
  ruleId: string;
};

const TestSuiteHeader: React.FC<ComponentProps> = ({ toogleTestSuiteSidebar, ruleId }) => {
  const dispatch = useDispatch();
  const summary = useSelector(getTestCaseSummary);
  const selectedNode = useSelector(getSelectedNode);
  const testCasesCount = useSelector(getTestCasesTotalCount);

  const handleBackToAll = (): void => {
    dispatch(fetchAllTestCases({ rule_uuids: ruleId }));
    dispatch(selectItem({ index: 0 }));
  };

  return (
    <div className="flex flex-col py-4 px-3 bg-white">
      <div className="flex flex-row items-start justify-between">
        <div className="flex flex-col gap-1 justify-between">
          <h3 className="text-heading-3">
            {!selectedNode || selectedNode === -1
              ? 'All Test Cases'
              : `Branch, Row ${selectedNode}`}
          </h3>

          <span className="text-body">
            {`${testCasesCount} Test ${pluralize('Cases', testCasesCount)}`}
          </span>
        </div>
        <button
          type="button"
          data-testid="close-button"
          className={
            selectedNode && selectedNode !== -1
              ? 'text-body focus:outline-none'
              : 'w-5 z-10 focus:outline-none'
          }
          aria-label="Close"
          onClick={selectedNode && selectedNode !== -1 ? handleBackToAll : toogleTestSuiteSidebar}
        >
          {selectedNode && selectedNode !== -1 ? 'Back to All >' : CLOSE_MODAL_ICON}
        </button>
      </div>
      <div className="flex flex-row justify-between items-end">
        {selectedNode && selectedNode !== -1 ? (
          <button
            type="button"
            className="flex flex-row gap-1 items-center text-body hover:underline focus:outline-none"
          >
            <span className="w-3 h-3">{CLOSE_MODAL_ICON}</span>
            <span className="whitespace-no-wrap">Clear Filter</span>
          </button>
        ) : (
          <TestCasePieChart size={60} summary={summary} returnPlaceholder />
        )}
        <TestCaseStateFilter resource={resourceQueryParamName.testCase} className="flex" />
      </div>
    </div>
  );
};

export default TestSuiteHeader;
