import Navbar from 'components/Navbar';
import useLogUnmountEvent from 'components/utils/useLogUnmountEvent';
import React, { useEffect } from 'react';
import logEvent from 'utils/analytics';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import ExperimentsListContent from './ExperimentsListContent';

const EnvelopeList: React.FC = () => {
  useLogUnmountEvent();

  useEffect(() => {
    logEvent('experiments-list-load');
  }, []);

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <>
      <div
        className="bg-white"
        style={{
          height: `calc(100vh - ${warningBarOffset}px)`,
        }}
      >
        <Navbar />
        <div
          className="relative flex flex-row"
          style={{
            height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
          }}
        >
          <ExperimentsListContent />
        </div>
      </div>
    </>
  );
};

export default EnvelopeList;
