/* eslint-disable max-lines */

import { User } from '@litlingo/client';
import {
  addUserToViewers,
  deleteSavedSearch,
  removeUserFromViewers,
  upsertSavedSearch,
} from 'actions';
import UserLabels from 'components/User/UserLabels';
import UserSelect from 'components/User/UserSelect';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getSelectedReviewSet } from 'selectors/savedSearches';
import { useSelector } from 'store';
import FilterSection from './FilterSection';

type ComponentProps = {
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditSidebar: React.FC<ComponentProps> = ({ setEditing }) => {
  const dispatch = useDispatch();

  const reviewSet = useSelector(getSelectedReviewSet);

  const [users, setUsers] = useState(reviewSet?.viewers as User[]);
  const [diplayToAllUsers, setDiplayToAllUsers] = useState(
    reviewSet?.display_in_assignments ?? false
  );

  const { register, getValues, handleSubmit } = useForm<{
    name: string;
    /* FRO-1090 description: string; */
    size: number;
    diplay_in_assignments: boolean;
  }>({
    defaultValues: {
      size: reviewSet?.config?.default_count || 100,
      name: reviewSet?.name,
      /* FRO-1090 description: reviewSet?.description, */
      diplay_in_assignments: reviewSet?.display_in_assignments,
    },
  });

  const handleDeleteUser = (user: User): void => {
    setUsers(users.filter((u) => u.uuid !== user.uuid));
    if (reviewSet) {
      dispatch(removeUserFromViewers({ savedSearchId: reviewSet.uuid, user }));
    }
  };

  const handleSelectUser = (user: User, checked: boolean): void => {
    if (checked) {
      setUsers((userList) => [...userList, user]);
      if (reviewSet) {
        dispatch(addUserToViewers({ savedSearchId: reviewSet.uuid, user }));
      }
    } else {
      handleDeleteUser(user);
    }
  };

  const handleChangeDisplayToAllUsers = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setDiplayToAllUsers(e.target.checked);

  const onSubmit = (): void => {
    const {
      name,
      /* FRO-1090 description, */ size,
      diplay_in_assignments: displayInAssignments,
    } = getValues();

    // add cadence later
    if (reviewSet) {
      dispatch(
        upsertSavedSearch({
          ...reviewSet,
          name,
          /* FRO-1090 description, */
          config: {
            ...reviewSet.config,
            default_count: Math.round(size) || -1,
          },
          display_in_assignments: displayInAssignments,
        })
      );
      setEditing(false);
    }
  };

  const handleSaveButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const handleDelete = (): void => {
    if (reviewSet) {
      dispatch(deleteSavedSearch({ uuid: reviewSet?.uuid }));
      setEditing(false);
    }
  };

  return (
    <div className="flex flex-col w-full h-full px-8 py-6 bg-white overflow-auto no-scrollbar">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row justify-between h-12">
            <span className="text-heading-2 self-end">Edit Saved Search</span>
            <button
              type="button"
              className="button button--error h-8 w-20 p-0 text-body"
              onClick={(): void => handleDelete()}
            >
              <span className="flex items-center justify-center text-litlingo-alert font-bold h-full w-full hover:text-white">
                Delete
              </span>
            </button>
          </div>

          <div className="flex flex-col gap-4 text-body">
            <label htmlFor="name" className="flex flex-col gap-1">
              <div className="flex flex-row gap-1">
                <span>Name</span>
                <span className="litlingo-red-color">*</span>
              </div>

              <input
                id="name"
                ref={register({ required: true, pattern: /\S/i })}
                name="name"
                placeholder="New save search"
                className="form-input h-9 w-64 p-2 text-body rounded placeholder-italic placeholder-litlingo-gray-6"
              />
            </label>

            {/* FRO-1090 <label htmlFor="description" className="flex flex-col gap-1">
              <div className="flex flex-row gap-1">
                <span>Description</span>
              </div>

              <textarea
                id="description"
                ref={register()}
                name="description"
                rows={3}
                placeholder="A short description that can help others understand the purpose of this Save Search"
                className="form-input w-88 p-2 text-body rounded resize-none placeholder-italic placeholder-litlingo-gray-6"
              />
            </label> */}

            {!diplayToAllUsers && (
              <div className="flex flex-col gap-1 mt-2 w-full">
                <span className="text-body">Users that can view</span>
                <div className="flex flex-row items-start w-full gap-5">
                  <div className="w-44">
                    <UserSelect
                      selectUser={handleSelectUser}
                      selectedUsers={users}
                      className="min-w-44"
                      roles={['reviewer', 'super-reviewer']}
                    />
                  </div>
                  <UserLabels users={users} handleDelete={handleDeleteUser} className="flex-wrap" />
                </div>
              </div>
            )}

            <label htmlFor="description" className="flex flex-row gap-1 items-center">
              <div className="flex flex-row gap-1">
                <span>Display to all users</span>
              </div>
              <input
                id="diplay_in_assignments"
                name="diplay_in_assignments"
                type="checkbox"
                ref={register()}
                className="form-checkbox litlingo-checkbox"
                onChange={handleChangeDisplayToAllUsers}
              />
            </label>
          </div>
        </div>

        <FilterSection />

        <div className="flex flex-row gap-2 self-end pt-6">
          <button
            type="button"
            className="button button--secondary h-8 w-40 p-0"
            onClick={(): void => setEditing(false)}
          >
            <span className="flex items-center justify-center font-bold h-full w-full">Cancel</span>
          </button>

          <button
            type="button"
            className="button button--primary h-8 w-40 p-0 text-body"
            onClick={handleSaveButton}
          >
            <span className="flex items-center justify-center text-white font-bold h-full w-full">
              Save
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSidebar;
