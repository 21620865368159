import { deleteRule, updateRule } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import Modal from 'components/Modal';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignRuleOutcomes } from 'selectors/campaignRuleOutcomes';
import { MRule } from 'types';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
  rule: MRule;
};

const EditRuleModal: React.FC<ComponentProps> = ({ isModalOpen, toggleModalOpen, rule }) => {
  const dispatch = useDispatch();

  const [isDeletingRule, setIsDeletingRule] = useState(false);
  const campaignsRuleOutcomes = useSelector(getCampaignRuleOutcomes);

  const { register, getValues, handleSubmit } = useForm<{
    name: string;
    description: string;
  }>({ defaultValues: { name: rule.name, description: rule.description || '' } });

  const deleteButtonStyle = 'text-litlingo-alert underline self-end focus:outline-none';

  const onSubmit = (): void => {
    const { name, description } = getValues();

    dispatch(
      updateRule({
        rule: { ...rule, name, description },
      })
    );
    toggleModalOpen();
  };

  const handleDelete = (): void => {
    dispatch(deleteRule({ ruleId: rule.uuid }));
    setIsDeletingRule(false);
    toggleModalOpen();
  };

  const handleSaveButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const toggleConfirmModal = (): void => setIsDeletingRule(!isDeletingRule);

  const renderCategoryForm = (
    <div className="pt-4 pb-8">
      <form className="flex flex-col items-start">
        <div className="flex flex-col items-start gap-4 w-full">
          <label htmlFor="name" className="flex flex-col gap-1 mt-2 text-body">
            <div className="flex flex-row gap-1 text-small">
              <span>Name</span>
              <span className="litlingo-red-color">*</span>
            </div>

            <input
              id="name"
              ref={register({ required: true, pattern: /\S/i })}
              name="name"
              placeholder="Model name"
              className="form-input h-9 w-66 p-2 text-body rounded placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>

          <label htmlFor="description" className="flex flex-col gap-1 mt-2 text-body">
            <div className="flex flex-row gap-1 text-small">
              <span>Description</span>
            </div>

            <textarea
              id="description"
              ref={register()}
              name="description"
              rows={3}
              placeholder="Use this description to help others understand the purpose of this model. Only visible to you and other administrators."
              className="form-input w-112 p-2 text-body rounded resize-none placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>
        </div>
      </form>
    </div>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderCategoryForm}
          title="Edit Model"
          okButton
          okButtonText="Save"
          okButtonOnClick={handleSaveButton}
          toggleShowModal={toggleModalOpen}
          secondaryButton={Object.values(campaignsRuleOutcomes).length > 0}
          secondaryButtonOnclick={toggleConfirmModal}
          secondaryButtonStyle={deleteButtonStyle}
          secondaryButtonText="Delete Model"
        />
      )}
      {isDeletingRule && (
        <ConfirmModal
          text="Are you sure you want to delete this model?"
          okButtonText="Yes"
          cancelButtonText="No"
          data-testid="delete-modal"
          okButtonOnClick={handleDelete}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </>
  );
};

export default EditRuleModal;
