import React from 'react';
import { useSelector } from 'react-redux';
import type { RedirectProps } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { getCustomerDomain } from 'selectors/auth';
import { reverse } from 'utils/urls';

type RedirectLookupProps = Omit<RedirectProps, 'to'> & {
  routeName: string;
};

const RedirectLookup: React.FC<RedirectLookupProps> = (props) => {
  const { routeName } = props;
  const customerDomain = useSelector(getCustomerDomain);
  const path = reverse({
    routeName,
    customerDomain,
  });
  return <Redirect to={path} />;
};

export default RedirectLookup;
