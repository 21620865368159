import type { CampaignRuleOutcome, MOutcome, Outcome, OutcomeTypes, Selector, UUID } from 'types';

export const getOutcomeFromCampaignRuleOutcome: Selector<MOutcome[]> = (state) => {
  const campaignRuleOutcomes = state.campaignRuleOutcomes.items;
  if (!campaignRuleOutcomes) {
    return [];
  }

  const outcomesIds: UUID[] = [];
  Object.keys(campaignRuleOutcomes).forEach((key) => {
    outcomesIds.push(campaignRuleOutcomes[key].outcome_uuid);
  });

  return Object.values(state.outcome.activeOutcomes).filter((element: MOutcome) =>
    outcomesIds.includes(element.uuid)
  );
};

export const getRuleOutcomes: Selector<CampaignRuleOutcome[], [UUID]> = (state, ruleId) =>
  Object.keys(state.campaignRuleOutcomes.items)
    .filter((key) => state.campaignRuleOutcomes.items[key].rule_uuid === ruleId)
    .map((key) => {
      const outcome =
        state.outcome.activeOutcomes[state.campaignRuleOutcomes.items[key].outcome_uuid];
      return { outcome, ...state.campaignRuleOutcomes.items[key] };
    })
    .sort((a, b) => {
      if (a.outcome?.name.toLowerCase() < b.outcome?.name.toLowerCase()) return -1;
      if (a.outcome?.name.toLowerCase() > b.outcome?.name.toLowerCase()) return 1;
      return 0;
    });

export const getOutcomes: Selector<Outcome[]> = (state) => Object.values(state.outcome.outcomes);

export const getOutcomesFromRule: Selector<Outcome[], [UUID]> = (state, ruleId) => {
  const rule = state.rule.rules[ruleId];
  if (!rule || !rule.outcomes) {
    return [];
  }

  return rule.outcomes
    .filter((outcomeId) => outcomeId in state.outcome.activeOutcomes)
    .map((outcomeId) => state.outcome.activeOutcomes[outcomeId]);
};

export const getSingleOutcome =
  (outcomeId: UUID): Selector<Outcome> =>
  (state): Outcome =>
    state.outcome.outcomes[outcomeId];

export const getOutcomeTypes: Selector<OutcomeTypes> = (state) => state.outcome.types;
