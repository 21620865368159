import ProgressBar from 'components/ProgressBar';
import moment from 'moment';
import React from 'react';
import type { Assignment } from 'types';

type ComponentProps = {
  assignment: Assignment;
};

const AssignmentListItem: React.FC<ComponentProps> = ({ assignment }) => {
  const getDate = (date: string): string => {
    const assignmentDate = moment(date);
    let dateToShow = assignmentDate.format('MMM D');
    if (assignmentDate.isSame(moment(), 'day')) {
      dateToShow = assignmentDate.format('h:mm A');
    }
    return dateToShow;
  };

  return (
    <>
      <div
        className="border-t border-gray-200 hover:bg-gray-100 table-row align-middle"
        role="button"
        aria-hidden
      >
        <td className="table-wrapper__td">
          <div className="text text--truncate">{assignment.user?.name}</div>
        </td>
        <td className="table-wrapper__td">
          <div className="text text--truncate">
            {assignment.updated_at && getDate(assignment.updated_at)}
          </div>
        </td>
        <td className="table-wrapper__td">
          {assignment && (
            <div className="mt-2">
              <div className="border">
                <ProgressBar
                  simple
                  completed={((assignment.count - assignment.pending) / assignment.count) * 100}
                />
              </div>
              <div className="text-center">
                {assignment.count - assignment.pending} of {assignment.count}
              </div>
            </div>
          )}
        </td>

        <td className="table-wrapper__td">
          <div className="text text--truncate">{assignment.is_open ? 'Open' : 'Closed'}</div>
        </td>
      </div>
    </>
  );
};

export default AssignmentListItem;
