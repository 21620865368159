import { BAD_TREND, GOOD_TREND } from 'constants/dashboardIcons';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import React from 'react';
import { DefaultWidgetComponentProps } from 'types';
import { buildTrendDataFromMetrics } from 'utils/dashboard';
import { kFormatter } from 'utils/timeRanges';

const MetricWithTrendWidget: React.FC<DefaultWidgetComponentProps> = (props) => {
  const { widgetData } = props;

  if (widgetData == null) {
    return <WidgetEmptyState />;
  }

  const processedData = buildTrendDataFromMetrics(widgetData);
  if ('error' in processedData && processedData.error != null) {
    return (
      <span className="text text--medium text--italic text--bold text--center">
        {processedData.error}
      </span>
    );
  }

  return (
    <div className="flex flex-col justify-around items-center overflow-hidden">
      <span className="text text mb-2">{widgetData.results[0].label}</span>
      <span className="text text--2xextra-large text--bold text-litlingo-title mb-2">
        {'count' in processedData && processedData.count}
      </span>
      {'difference' in processedData && processedData.difference != null && (
        <div className="flex justify-center items-center">
          <span>{Math.sign(processedData.difference) === 1 ? GOOD_TREND : BAD_TREND}</span>
          <span className="text text-litlingo-complementary-green text--extra-small text--bold">
            {kFormatter(Math.abs(processedData.difference))}
          </span>
        </div>
      )}
    </div>
  );
};

export default withWidget({
  minWidth: 4,
  minHeight: 5,
  fetchOnePeriod: true,
})(MetricWithTrendWidget);
