import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import type {
  DeleteCampaignRuleOutcomePayload,
  FetchAllCampaignRuleOutcomesSuccessPayload,
} from 'actions/campaignRuleOutcomes';
import {
  deleteCampaignRuleOutcome,
  fetchAllCampaignRuleOutcomesFailure,
  fetchAllCampaignRuleOutcomesRequest,
  fetchAllCampaignRuleOutcomesSuccess,
  updateCampaignRuleOutcomeConfigFailure,
  updateCampaignRuleOutcomeConfigRequest,
  updateCampaignRuleOutcomeConfigSuccess,
} from 'actions/campaignRuleOutcomes';
import type { CampaignRuleOutcome, ErrorObject, NormalizedResource } from 'types';

type CampaignRuleOutcomeState = {
  items: NormalizedResource<CampaignRuleOutcome>;
  loading: string[];
  error: ErrorObject | null;
};

type CampaignRuleOutcomeReducer<P = void> = (
  state: CampaignRuleOutcomeState,
  action: PayloadAction<P>
) => CampaignRuleOutcomeState;

const defaultState: CampaignRuleOutcomeState = {
  items: {},
  loading: [],
  error: null,
};

const handleFetchAllCampaignRuleOutcomesRequest: CampaignRuleOutcomeReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAllCampaignRuleOutcomesRequest.toString()],
});

const handleFetchAllCampaignRuleOutcomesFailure: CampaignRuleOutcomeReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchAllCampaignRuleOutcomesRequest.toString()),
});

const handleFetchAllCampaignRuleOutcomesSuccess: CampaignRuleOutcomeReducer<
  FetchAllCampaignRuleOutcomesSuccessPayload
> = (state, { payload }) => ({
  ...state,
  items: payload,
  loading: state.loading.filter((s) => s !== fetchAllCampaignRuleOutcomesRequest.toString()),
});

const handleUpdateCampaignRuleOutcomeConfigRequest: CampaignRuleOutcomeReducer<
  CampaignRuleOutcome
> = (state, { payload }) => ({
  ...state,
  error: null,
  items: {
    ...state.items,
    [payload.uuid]: {
      ...state.items[payload.uuid],
      config: payload.config,
    },
  },
  loading: [...state.loading, updateCampaignRuleOutcomeConfigRequest.toString()],
});

const handleUpdateCampaignRuleOutcomeConfigFailure: CampaignRuleOutcomeReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== updateCampaignRuleOutcomeConfigRequest.toString()),
});

const handleUpdateCampaignRuleOutcomeConfigSuccess: CampaignRuleOutcomeReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== updateCampaignRuleOutcomeConfigRequest.toString()),
});

const handleDeleteCampaignRuleOutcome: CampaignRuleOutcomeReducer<
  DeleteCampaignRuleOutcomePayload
> = (state, { payload }) => {
  const { outcomeId, ruleId } = payload;

  const { items } = state;
  const filtered = Object.fromEntries(
    Object.entries(items).filter(([, v]) => v.outcome_uuid !== outcomeId || v.rule_uuid !== ruleId)
  );

  return {
    ...state,
    items: filtered,
  };
};

const handlers = {
  [fetchAllCampaignRuleOutcomesRequest.toString()]: handleFetchAllCampaignRuleOutcomesRequest,
  [fetchAllCampaignRuleOutcomesSuccess.toString()]: handleFetchAllCampaignRuleOutcomesSuccess,
  [fetchAllCampaignRuleOutcomesFailure.toString()]: handleFetchAllCampaignRuleOutcomesFailure,
  [updateCampaignRuleOutcomeConfigRequest.toString()]: handleUpdateCampaignRuleOutcomeConfigRequest,
  [updateCampaignRuleOutcomeConfigSuccess.toString()]: handleUpdateCampaignRuleOutcomeConfigSuccess,
  [updateCampaignRuleOutcomeConfigFailure.toString()]: handleUpdateCampaignRuleOutcomeConfigFailure,
  [deleteCampaignRuleOutcome.toString()]: handleDeleteCampaignRuleOutcome,
};

const campaignRuleOutcomesReducer = createReducer(defaultState, handlers);

export default campaignRuleOutcomesReducer;
