import type { APIClient } from '@api';
import { ClientError } from '@errors';
import type {
  APIClientResourceSpec,
  APIClientResourceSpecExtras,
  APIClientSpec,
  BasicResourceMethod,
  ClientResponseP,
} from '@types';
import type { AxiosRequestConfig } from 'axios';

type ParamsValues = { [param: string]: string };

type ExtraRequestConfig = AxiosRequestConfig & { urlParams?: ParamsValues };

type Extras<S extends APIClientResourceSpecExtras> = {
  [M in keyof S]: <D = unknown>(
    options?: ExtraRequestConfig
  ) => ClientResponseP<D>;
};

class APIResource<S extends APIClientResourceSpec> {
  private _name: string;

  private _path: string;

  private _allowed: readonly BasicResourceMethod[];

  private _client: APIClient<APIClientSpec>;

  extras: Extras<S['extras']>;

  constructor(client: APIClient<APIClientSpec>, name: string, spec: S) {
    this._name = name;
    this._path = spec.path;
    this._allowed = spec.include || [];
    this._client = client;

    this.extras = {} as Extras<S['extras']>;
    this._createMethods(spec.extras);
  }

  private _createMethods(extras: S['extras']): void {
    Object.entries(extras).forEach(([extra, extraSpec]) => {
      const name = extra as keyof S['extras'];
      const { path, method } = extraSpec;

      this.extras[name] = <D = unknown>(
        options: ExtraRequestConfig = {}
      ): ClientResponseP<D> => {
        const { urlParams, ...config } = options;

        const url = this._buildPath(path, urlParams);

        return this._client.request<D>({
          ...config,
          method,
          url,
        });
      };
    });
  }

  private _buildPath(path: string, params?: ParamsValues): string | never {
    // FIXME: include numbers
    const patterns = path.match(/{[A-Za-z]+}/g);
    if (patterns == null) {
      return `${this._path}${path}`;
    }

    if (params == null) {
      throw new Error(`Missing params object for keys: ${patterns.join(', ')}`);
    }

    const missing: string[] = [];
    patterns.forEach((pattern) => {
      const clean = pattern.replace('{', '').replace('}', '');
      if (params[clean] == null) {
        missing.push(clean);
      }
    });
    if (missing.length !== 0) {
      throw new Error(`Missing params: ${missing.join(', ')}`);
    }

    const extra = patterns.reduce((curr, pattern) => {
      const clean = pattern.replace('{', '').replace('}', '');

      return curr.replace(pattern, params[clean]);
    }, path);

    return `${this._path}${extra}`;
  }

  private _check(method: BasicResourceMethod): void {
    if (!this._allowed.includes(method)) {
      throw new ClientError(
        `Method '${method}' is not allowed for resource '${this._name}'`
      );
    }
  }

  list<D = unknown>(options?: AxiosRequestConfig): ClientResponseP<D> {
    this._check('LIST');
    const url = this._buildPath('');

    return this._client.request<D>({ ...options, method: 'GET', url });
  }

  upsert<D = unknown>(options?: AxiosRequestConfig): ClientResponseP<D> {
    this._check('UPSERT');
    const url = this._buildPath('');

    return this._client.request<D>({ ...options, method: 'PUT', url });
  }

  retrieve<D = unknown>(
    id: string,
    options?: AxiosRequestConfig
  ): ClientResponseP<D> {
    this._check('RETRIEVE');
    const url = this._buildPath('/{id}', { id });

    return this._client.request<D>({ ...options, method: 'GET', url });
  }

  delete<D = unknown>(
    id: string,
    options?: AxiosRequestConfig
  ): ClientResponseP<D> {
    this._check('DELETE');
    const url = this._buildPath('/{id}', { id });

    return this._client.request<D>({ ...options, method: 'DELETE', url });
  }
}

export default APIResource;
