import { showSuccessAlert } from 'actions';
import {
  addTagsToTagGroup,
  addTagsToTagGroupFailure,
  addTagsToTagGroupRequest,
  addTagsToTagGroupSuccess,
  deleteTagGroup,
  deleteTagGroupFailure,
  deleteTagGroupRequest,
  deleteTagGroupSuccess,
  fetchAllTagGroups,
  fetchAllTagGroupsFailure,
  fetchAllTagGroupsRequest,
  fetchAllTagGroupsSuccess,
  fetchSingleTagGroup,
  fetchSingleTagGroupFailure,
  fetchSingleTagGroupRequest,
  fetchSingleTagGroupSuccess,
  fetchTagGroups,
  fetchTagGroupsFailure,
  fetchTagGroupsRequest,
  fetchTagGroupsSuccess,
  removeTagsFromTagGroup,
  removeTagsFromTagGroupFailure,
  removeTagsFromTagGroupRequest,
  removeTagsFromTagGroupSuccess,
  upsertTagGroup,
  upsertTagGroupFailure,
  upsertTagGroupRequest,
  upsertTagGroupSuccess,
} from 'actions/tagGroups';
import { apiClient as LitLingoClient } from 'client';
import { push } from 'connected-react-router';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getCustomerDomain } from 'selectors/auth';
import { getNavParamsByResource } from 'selectors/nav';
import type { API, SagaReturn } from 'types';
import { reverse } from 'utils/urls';

function* fetchAllTagGroupsSaga(action: ReturnType<typeof fetchAllTagGroups>): SagaReturn {
  yield put(fetchAllTagGroupsRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.tagGroups)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const params = {
    include_count: true,
    order_desc: true,
    ...resourceParams,
    ...action.payload,
    relationships: ['tag_values'],
  };

  const response = (yield call([LitLingoClient.resources.tagGroups, 'list'], {
    params,
  })) as API.Response<API.TagGroups.List>;

  if (response.error != null) {
    yield put(fetchAllTagGroupsFailure(response.error));
  } else {
    yield put(fetchAllTagGroupsSuccess(response.data));
  }
}

function* fetchTagGroupsSaga(action: ReturnType<typeof fetchTagGroups>): SagaReturn {
  yield put(fetchTagGroupsRequest());

  const params = {
    include_count: true,
    order_desc: true,
    uuids: action.payload?.uuids || [],
    relationships: ['tag_values'],
  };

  const response = (yield call([LitLingoClient.resources.tagGroups, 'list'], {
    params,
  })) as API.Response<API.TagGroups.List>;

  if (response.error != null) {
    yield put(fetchTagGroupsFailure(response.error));
  } else {
    yield put(fetchTagGroupsSuccess(response.data));
  }
}

function* fetchSingleTagGroupSaga(action: ReturnType<typeof fetchSingleTagGroup>): SagaReturn {
  const { payload } = action;
  const { tagGroupId } = payload;

  yield put(fetchSingleTagGroupRequest());
  const response = (yield call([LitLingoClient.resources.tagGroups, 'retrieve'], tagGroupId, {
    params: { relationships: ['tag_values'] },
  })) as API.Response<API.TagGroups.Retrieve>;

  if (response.error != null) {
    yield put(fetchSingleTagGroupFailure(response.error));
  } else {
    yield put(fetchSingleTagGroupSuccess(response.data));
  }
}

function* upsertTagGroupSaga(action: ReturnType<typeof upsertTagGroup>): SagaReturn {
  const { payload } = action;

  yield put(upsertTagGroupRequest());

  const response = (yield call([LitLingoClient.resources.tagGroups, 'upsert'], {
    data: payload,
  })) as API.Response<API.TagGroups.Upsert>;
  if (response.error != null) {
    yield put(upsertTagGroupFailure(response.error));
  } else {
    yield put(upsertTagGroupSuccess(response.data));
    yield put(showSuccessAlert('Saved tag group'));
  }
}

function* deleteTagGroupSaga(action: ReturnType<typeof deleteTagGroup>): SagaReturn {
  const { payload } = action;
  const { id } = payload;
  yield put(deleteTagGroupRequest());
  const response = (yield call(
    [LitLingoClient.resources.tagGroups, 'delete'],
    id
  )) as API.Response<API.TagGroups.Delete>;
  if (response.error != null) {
    yield put(deleteTagGroupFailure(response.error));
  } else {
    const customerDomain = (yield select(getCustomerDomain)) as string;
    const path = reverse({ routeName: 'tag-groups', customerDomain });
    yield put(push(path));
    yield put(deleteTagGroupSuccess(id));
    yield put(showSuccessAlert('Deleted tag group'));
  }
}

function* addTagsToTagGroupSaga(action: ReturnType<typeof addTagsToTagGroup>): SagaReturn {
  const { tags, uuid } = action.payload;
  yield put(addTagsToTagGroupRequest());

  const response = (yield call([LitLingoClient.resources.tagGroups.extras, 'addTags'], {
    urlParams: { tagGroupId: uuid },
    data: { tag_value_uuids: tags },
  })) as API.Response<API.TagGroups.AddTags>;

  if (response.error != null) {
    yield put(addTagsToTagGroupFailure(response.error));
  } else {
    yield put(addTagsToTagGroupSuccess({ tagGroupId: uuid, tags: response.data }));
  }
}

function* removeTagsFromTagGroupSaga(
  action: ReturnType<typeof removeTagsFromTagGroup>
): SagaReturn {
  const { tags, uuid } = action.payload;
  yield put(removeTagsFromTagGroupRequest());

  const response = (yield call([LitLingoClient.resources.tagGroups.extras, 'removeTags'], {
    urlParams: { tagGroupId: uuid },
    data: { tag_value_uuids: tags },
  })) as API.Response<API.TagGroups.RemoveTags>;
  if (response.error != null) {
    yield put(removeTagsFromTagGroupFailure(response.error));
  } else {
    yield put(removeTagsFromTagGroupSuccess({ tagGroupId: uuid, tags: response.data }));
  }
}

export default function* tagsSaga(): SagaReturn {
  yield takeLatest(fetchAllTagGroups.toString(), fetchAllTagGroupsSaga);
  yield takeLatest(fetchTagGroups.toString(), fetchTagGroupsSaga);
  yield takeLatest(fetchSingleTagGroup.toString(), fetchSingleTagGroupSaga);
  yield takeLatest(upsertTagGroup.toString(), upsertTagGroupSaga);
  yield takeLatest(deleteTagGroup.toString(), deleteTagGroupSaga);
  yield takeLatest(addTagsToTagGroup.toString(), addTagsToTagGroupSaga);
  yield takeLatest(removeTagsFromTagGroup.toString(), removeTagsFromTagGroupSaga);
}
