import Badge from 'components/Badge';
import React from 'react';
import { Tag } from 'types';

type ComponentProps = {
  tags: Tag[];
  className?: string;
  handleDelete: (group: Tag) => void;
};

const TagsLabels: React.FC<ComponentProps> = ({ tags, className, handleDelete }) => (
  <div className={`flex flex-row items-center gap-1 ${className}`}>
    {tags &&
      tags.length > 0 &&
      tags.map((tag) => (
        <div key={tag.uuid}>
          <Badge
            rounded={false}
            title={tag.value || ''}
            colorClass={tag.color}
            deleteAction={(): void => handleDelete(tag)}
          />
        </div>
      ))}
  </div>
);

export default TagsLabels;
