import { Customer } from '@litlingo/client';
import { changeScopeIdentifier, fetchIdentifierCustomers } from 'actions/identifier';
import LoadingIndicator from 'components/LoadingIndicator';
import { EYE_ICON } from 'constants/commonIcons';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getActiveLanguageMatcherId,
  getFetchIdentifierCustomersLoading,
  getIdentifierCustomers,
  getSelectedCustomerIdentifier,
  getSelectedIdentifierRevision,
  getSelectedMatcherScopesForUser,
} from 'selectors/identifier';
import { useSelector } from 'store';

type CustomerItemProps = {
  customer: Customer;
  handleScopeCustomer: (customer: string) => void;
};

const CustomerItem: React.FC<CustomerItemProps> = ({ customer, handleScopeCustomer }) => {
  const scopedCustomers = useSelector(getSelectedMatcherScopesForUser);
  const selectedCustomer = useSelector(getSelectedCustomerIdentifier);

  return (
    <div key={customer.uuid} className="flex flex-row justify-between items-center">
      <label htmlFor={customer.uuid} className="flex flex-row gap-1 items-center">
        <input
          id={customer.uuid}
          type="checkbox"
          className="form-checkbox litlingo-checkbox"
          onChange={(): void => handleScopeCustomer(customer.uuid)}
          checked={scopedCustomers.includes(customer.uuid)}
        />
        <div>{customer.name}</div>
      </label>
      <div className="flex flex-row items-center gap-2">
        {selectedCustomer === customer.uuid && <span className="w-4">{EYE_ICON}</span>}
        <div>Y</div>
      </div>
    </div>
  );
};

const ScopesSection: React.FC = () => {
  const dispatch = useDispatch();
  const customers = useSelector(getIdentifierCustomers);
  const scopedCustomers = useSelector(getSelectedMatcherScopesForUser);
  const activeMatcher = useSelector(getActiveLanguageMatcherId);
  const identifierRevision = useSelector(getSelectedIdentifierRevision);
  const loading = useSelector(getFetchIdentifierCustomersLoading);

  useEffect(() => {
    if (identifierRevision?.uuid) {
      dispatch(fetchIdentifierCustomers(identifierRevision.uuid));
    }
  }, [dispatch, identifierRevision?.uuid]);

  const handleScopeCustomer = (customer: string): void => {
    if (activeMatcher) {
      if (scopedCustomers.includes(customer)) {
        dispatch(
          changeScopeIdentifier({
            act: 'remove',
            customerId: customer,
            matcherId: activeMatcher,
          })
        );
      } else {
        dispatch(
          changeScopeIdentifier({
            act: 'add',
            customerId: customer,
            matcherId: activeMatcher,
          })
        );
      }
    }
  };

  const handleSelectAll = (): void => {
    if (activeMatcher) {
      if (scopedCustomers.length === customers.length) {
        customers.forEach((customer) => {
          if (scopedCustomers.includes(customer.uuid)) {
            dispatch(
              changeScopeIdentifier({
                act: 'remove',
                customerId: customer.uuid,
                matcherId: activeMatcher,
              })
            );
          }
        });
      } else {
        customers.forEach((customer) => {
          if (!scopedCustomers.includes(customer.uuid)) {
            dispatch(
              changeScopeIdentifier({
                act: 'add',
                customerId: customer.uuid,
                matcherId: activeMatcher,
              })
            );
          }
        });
      }
    }
  };

  return (
    <div className="flex flex-col gap-2 py-4">
      <div className="flex flex-row justify-between">
        <label htmlFor="all-customers-checkbox" className="flex flex-row gap-1 items-center">
          <input
            id="all-customers-checkbox"
            type="checkbox"
            className={`form-checkbox litlingo-checkbox ${
              scopedCustomers.length > 0 && scopedCustomers.length < customers.length
                ? 'checkbox-select-all-partial'
                : ''
            }`}
            onChange={handleSelectAll}
            checked={scopedCustomers.length === customers.length}
          />
          <div>{customers.length} Workspaces</div>
        </label>

        <div>Prevent?</div>
      </div>
      <div className="flex flex-col gap-2 text-small">
        {loading ? (
          <div className="flex justify-center">
            <LoadingIndicator size="10" />
          </div>
        ) : (
          customers.map((customer) => (
            <CustomerItem
              key={customer.uuid}
              customer={customer}
              handleScopeCustomer={handleScopeCustomer}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default ScopesSection;
