import { TAG_REMOVE_ICON } from 'constants/filterIcons';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTagsForFilterPills } from 'selectors/tags';

type ComponentProps = {
  title: string;
  deleteAction?: () => void;
  customLeftIcon?: JSX.Element | false;
  negated?: boolean;
};

const TagBadge: React.FC<ComponentProps> = ({
  negated = false,
  title,
  deleteAction,
  customLeftIcon,
}) => {
  const allTags = useSelector(getTagsForFilterPills);

  const item = allTags.find((tag) => tag.uuid === title);

  return (
    <div
      data-testid="container"
      className={`inline-block rounded ${
        negated ? 'bg-litlingo-alert-light' : 'bg-litlingo-success-light'
      }`}
    >
      <span data-testid="text" className="text-small flex my-0 px-1 py-0.5 items-center gap-1">
        {customLeftIcon && <span className="">{customLeftIcon}</span>}
        {item ? item.value : title}
        {deleteAction && (
          <button
            data-testid="delete-button"
            onClick={deleteAction}
            className="focus:outline-none outline-none"
            type="button"
          >
            {TAG_REMOVE_ICON}
          </button>
        )}
      </span>
    </div>
  );
};

export default TagBadge;
