import { HighlightedSentence } from '@litlingo/react';
import React from 'react';
import { getUser } from 'selectors/auth';
import { getNoMatchSetencesFromSummaryByCommunication } from 'selectors/envelopes';
import { getPermissionsPolicy } from 'selectors/permissions';
import { useSelector } from 'store';
import type { UUID } from 'types';
import { getFieldAuthorization } from 'utils/permissions';

type ComponentProps = {
  envelopeId: UUID;
  loading: boolean;
};

const NoMatchSentences: React.FC<ComponentProps> = ({ envelopeId, loading }) => {
  const sentences = useSelector((state) =>
    getNoMatchSetencesFromSummaryByCommunication(state, envelopeId)
  );
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const entityFieldLevel = getFieldAuthorization('communications.entity', user.roles, policy);

  if (loading && !sentences) return null;

  if (!sentences || sentences?.length === 0) {
    return <div className="text-body italic leading-5">Empty message</div>;
  }

  return (
    <>
      {sentences.map((s, idx) => (
        <div key={s.index} className={idx !== 0 ? 'mt-2' : ''}>
          <HighlightedSentence
            nodes={s.nodes}
            useHover={user.roles.includes('super-admin')}
            protectedFields={{
              entity: entityFieldLevel,
            }}
            portal="tooltip-portal"
            hideHighlight={false}
          />
        </div>
      ))}
    </>
  );
};

export default NoMatchSentences;
