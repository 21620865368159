import { editCustomer, fetchSingleCustomer, saveCustomer } from 'actions';
import Card from 'components/Card';
import CustomerEditSecretModal from 'components/Customer/CustomerEditSecretModal';
import LinkLookup from 'components/LinkLookup';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import ToggleButton from 'components/ToggleButton';
import { LEFT_ARROW_ICON, OK_ICON, WRONG_ICON } from 'constants/envelopeIcons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getCustomer, getUser } from 'selectors/auth';
import { useSelector } from 'store';
import { CustomerConfig } from 'types';
import CustomerLeaveConfirmModal from './CustomerLeaveConfirmModal';
import CustomerSaveButton from './CustomerSaveButton';

const CustomerEditGmail: React.FC = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [syncUsers, setSyncUsers] = useState(false);
  const [syncEmails, setSyncEmails] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [unsaved, setUnsaved] = useState(false);
  const [isLeaveModalOpen, setLeaveModalOpen] = useState(false);
  // const [verified, setVerified] = useState(false);
  const { register, getValues, reset } = useForm();
  const user = useSelector(getUser);
  const customer = useSelector(getCustomer);
  const apiKeyName = 'gmail_api';
  const gmailAdminEmail = 'gmail_admin_email';
  const gmailApi = customer?.secrets_summary?.[apiKeyName];
  // const verifiedCredentials = useSelector(getCustomerGmailVerifyCredentials);
  // const loading = useSelector(getCustomerGmailVerifyCredentialsLoading);

  const handleEdit = (data: CustomerConfig): void => {
    if (JSON.stringify(data) === JSON.stringify(customer)) return;

    setUnsaved(true);
    dispatch(editCustomer({ config: data }));
  };

  const handleSave = async (): Promise<void> => {
    dispatch(saveCustomer({}));
    setIsSaved(true);
    setUnsaved(false);
  };

  // const handleVerifyCredentials = () => {
  //   setVerified(true);
  //   dispatch(gmailVerifyCredentials());
  // };

  useEffect(() => {
    if (user && !customer.config) {
      dispatch(fetchSingleCustomer());
    }
  }, [dispatch, user, customer]);

  useEffect(() => {
    reset({
      [gmailAdminEmail]: customer.config?.[gmailAdminEmail],
    });
  }, [customer, reset]);

  useEffect(() => {
    if (!customer.config) return;

    setSyncUsers(customer.config.sync_users);
    setSyncEmails(customer.config.sync_emails);
  }, [customer.config]);

  const header = (
    <div className="flex justify-between">
      <div className="litlingo-customer-back-header-button">
        <LinkLookup
          data-testid="customer-edit-gmail-left-button"
          routeName="customer"
          className="mx-6 focus:outline-none"
          onClick={(e): void => {
            if (unsaved) {
              e.preventDefault();
              setLeaveModalOpen(true);
            }
          }}
        >
          {LEFT_ARROW_ICON}
        </LinkLookup>
        <span className="text text--large text--bold">Gmail</span>
      </div>

      <CustomerSaveButton
        unsaved={unsaved}
        isSaved={isSaved}
        handleSave={handleSave}
        className="litlingo-customer-save-button"
      />
    </div>
  );

  const apiKey = (
    <div className="px-5 py-4">
      <div className="flex justify-between">
        <div>API key</div>
        <div className="flex">
          <span>******</span>
          <div className="mx-8">{gmailApi ? OK_ICON : WRONG_ICON}</div>
          <button
            data-testid="customer-edit-gmail-replace-button"
            type="button"
            onClick={(): void => setModalOpen(true)}
            className="button button--primary button--blue-textfocus:outline-none"
          >
            {gmailApi ? 'Replace' : 'Setup'}
          </button>
        </div>
      </div>
    </div>
  );

  const adminEmail = (
    <div className="px-5 py-4">
      <div className="flex justify-between items-center">
        <div>Admin email</div>
        <div className="flex">
          <input
            data-testid="customer-edit-gmail-admin-email-input"
            id={gmailAdminEmail}
            name={gmailAdminEmail}
            onBlur={(): void => {
              handleEdit({
                ...customer?.config,
                [gmailAdminEmail]: getValues(gmailAdminEmail),
              });
            }}
            ref={register()}
            className="litlingo-customer-card-input form-input"
          />
        </div>
      </div>
    </div>
  );

  const syncUsersView = (
    <div className="flex justify-between">
      <div className="my-4 mx-5">
        <span className="flex items-center text-sm">Sync users</span>
        <p className="text-sm">Creates litlingo accounts for authorized google users</p>
      </div>
      <div className="my-6 mx-5">
        <ToggleButton
          dataTestid="customer-edit-gmail-sync-users-button"
          isOn={customer?.config?.sync_users}
          handleToggleClick={(): void => {
            handleEdit({
              ...customer?.config,
              sync_users: !syncUsers,
            });
          }}
        />
      </div>
    </div>
  );

  const syncEmailsView = (
    <div className="flex justify-between">
      <div className="my-4 mx-5">
        <span className="flex items-center text-sm">Sync emails</span>
        <p className="text-sm">Load emails asynchronously</p>
      </div>
      <div className="my-6 mx-5">
        <ToggleButton
          dataTestid="customer-edit-gmail-sync-emails-button"
          isOn={customer?.config?.sync_emails}
          handleToggleClick={(): void => {
            handleEdit({
              ...customer?.config,
              sync_emails: !syncEmails,
            });
          }}
        />
      </div>
    </div>
  );

  // const verifiedCredentialsView = () => {
  //   const message = `Credentials${verifiedCredentials ? ' verified' : ' failed'}`;

  //   return (
  //     <div className="px-5 py-4">
  //       <div className="flex justify-between">
  //         <div />
  //         {verified && !loading && (
  //           <VerifyCredentialsMsg ok={verifiedCredentials} message={message} />
  //         )}
  //         {!verified && !loading && (
  //           <button
  //             data-testid="customer-edit-gmail-test-credentials-button"
  //             type="button"
  //             className="button button--secondary text text-base border-black"
  //             onClick={handleVerifyCredentials}
  //           >
  //             Test Credentials
  //           </button>
  //         )}
  //         {loading && (
  //           <div className="h-8">
  //             <LoadingIndicator className="ml-2" size="5" />
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className="litlingo-gray-bg-color-4">
      <Navbar />
      <div className="flex flex-row">
        <SettingSidebar />
        <div className="w-full max-w-7xl">
          <header>
            <div className="md:flex md:items-center md:justify-between">
              <div className="flex-1 min-w-0">
                {/* <div className="flex flex-row items-center">
                {(fetchLoading || saveLoading) && <LoadingIndicator className="ml-2" size="5" />}
              </div> */}
              </div>
            </div>
          </header>
          <main>
            <div
              className="overflow-auto"
              style={{
                minHeight: 'calc(100vh - 4rem)',
                height: 'calc(100vh - 4rem)',
              }}
            >
              <div className="mt-0 mx-auto min-w-2xl w-8/12 max-w-6xl text-left">
                <Card
                  items={[
                    header,
                    apiKey,
                    adminEmail,
                    syncUsersView,
                    syncEmailsView,
                    // verifiedCredentialsView(),
                  ]}
                />
                {isModalOpen && (
                  <CustomerEditSecretModal
                    secretKeyName={apiKeyName}
                    toggleShowModal={(): void => setModalOpen(!isModalOpen)}
                  />
                )}
                {isLeaveModalOpen && (
                  <CustomerLeaveConfirmModal
                    toggleShowModal={(): void => setLeaveModalOpen(!isLeaveModalOpen)}
                    setUnsaved={setUnsaved}
                  />
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default CustomerEditGmail;
