import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  getEmailTemplate,
  getEmailTemplateFailure,
  getEmailTemplateFulfill,
  getEmailTemplateSuccess,
  notifyEventAuthor,
  notifyEventAuthorFulfill,
  renderEmailTemplate,
  renderEmailTemplateFailure,
  renderEmailTemplateFulfill,
  renderEmailTemplateSuccess,
  setEventId,
} from 'actions/notifications';
import {
  DynamicFormValues,
  EmailTemplate,
  ErrorObject,
  NormalizedResource,
  RenderedEmailTemplate,
  UUID,
} from 'types';

export type NotificationsState = {
  emailTemplates: NormalizedResource<EmailTemplate & { id: string }>;
  renderedTemplates: NormalizedResource<RenderedEmailTemplate>;
  error: ErrorObject | null;
  loading: string[];
  // FIXME: this is a little hacky but it's the easiest way to add the default
  // body for the violation notice template
  eventId: UUID | null;
};

type NotificationsReducer<P = void> = (
  state: NotificationsState,
  payload: PayloadAction<P>
) => NotificationsState;

const defaultState: NotificationsState = {
  emailTemplates: {},
  renderedTemplates: {},
  error: null,
  loading: [],
  eventId: null,
};

const handleGetEmailTemplate: NotificationsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, getEmailTemplate.toString()],
});

const handleGetEmailTemplateSuccess: NotificationsReducer<EmailTemplate & { id: string }> = (
  state,
  { payload }
) => ({
  ...state,
  emailTemplates: {
    ...state.emailTemplates,
    [payload.id]: payload,
  },
});

const handleGetEmailTemplateFailure: NotificationsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleGetEmailTemplateFulfill: NotificationsReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== getEmailTemplate.toString()),
});

const handleRenderEmailTemplate: NotificationsReducer<{ id: string; values: DynamicFormValues }> = (
  state
) => ({
  ...state,
  error: null,
  loading: [...state.loading, renderEmailTemplate.toString()],
});

const handleRenderEmailTemplateSuccess: NotificationsReducer<{
  id: string;
  data: RenderedEmailTemplate;
}> = (state, { payload }) => ({
  ...state,
  renderedTemplates: {
    ...state.renderedTemplates,
    [payload.id]: payload.data,
  },
});

const handleRenderEmailTemplateFailure: NotificationsReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
});

const handleRenderEmailTemplateFulfill: NotificationsReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== renderEmailTemplate.toString()),
});

const handleNotifyEventAuthor: NotificationsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, notifyEventAuthor.toString()],
});

const handleNotifyEventAuthorFulfill: NotificationsReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== notifyEventAuthor.toString()),
});

const handleSetEventId: NotificationsReducer<string> = (state, action) => ({
  ...state,
  eventId: action.payload,
});

const handlers = {
  [getEmailTemplate.toString()]: handleGetEmailTemplate,
  [getEmailTemplateSuccess.toString()]: handleGetEmailTemplateSuccess,
  [getEmailTemplateFailure.toString()]: handleGetEmailTemplateFailure,
  [getEmailTemplateFulfill.toString()]: handleGetEmailTemplateFulfill,
  [renderEmailTemplate.toString()]: handleRenderEmailTemplate,
  [renderEmailTemplateSuccess.toString()]: handleRenderEmailTemplateSuccess,
  [renderEmailTemplateFailure.toString()]: handleRenderEmailTemplateFailure,
  [renderEmailTemplateFulfill.toString()]: handleRenderEmailTemplateFulfill,
  [notifyEventAuthor.toString()]: handleNotifyEventAuthor,
  [notifyEventAuthorFulfill.toString()]: handleNotifyEventAuthorFulfill,
  [setEventId.toString()]: handleSetEventId,
};

const notificationsReducer = createReducer(defaultState, handlers);

export default notificationsReducer;
