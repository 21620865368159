import {
  fetchActiveIntegrationsRequest,
  fetchAllCampaignsRequest,
  fetchSingleCampaignRequest,
  upsertCampaignRequest,
} from 'actions/campaigns';
import platform from 'constants/platform';
import { GlobalState } from 'reducers';
import { createSelector } from 'reselect';
import type { MCampaign, NormalizedResource, Selector, UUID } from 'types';

export const getFetchAllCampaignsLoading: Selector<boolean> = (state) =>
  state.campaign.loading.includes(fetchAllCampaignsRequest.toString());

export const getFetchSingleCampaignLoading: Selector<boolean> = (state) =>
  state.campaign.loading.includes(fetchSingleCampaignRequest.toString());

export const getUpsertCampaignLoading: Selector<boolean> = (state) =>
  state.campaign.loading.includes(upsertCampaignRequest.toString());

export const getCampaign =
  (id: UUID): Selector<MCampaign> =>
  (state): MCampaign =>
    state.campaign.campaigns[id];

export const getAllCampaigns: Selector<NormalizedResource<MCampaign>> = (state) =>
  state.campaign.campaigns;

export const getCampaignFilterItems: Selector<NormalizedResource<MCampaign>> = (state) =>
  state.campaign.filterItems;

export const getCampaignsForFilterPills: Selector<MCampaign[]> = createSelector(
  [(state: GlobalState): GlobalState['campaign']['filterItems'] => state.campaign.filterItems],
  (filterItems) => Object.keys(filterItems).map((uuid) => filterItems[uuid])
);

export const getCampaignRules: Selector<UUID[] | null, [UUID]> = (state, id) => {
  const campaign = getCampaign(id)(state);
  if (!campaign || !campaign.rules || !campaign.rules.length) {
    return null;
  }

  return [...new Set(campaign.rules)]; // skip duplicates
};

export const getCampaignsList: Selector<MCampaign[]> = createSelector(
  [(state: GlobalState): GlobalState['campaign']['campaigns'] => state.campaign.campaigns],
  (campaigns) => {
    const campaignItems = Object.keys(campaigns).map((key) => {
      const campaign = {
        ...campaigns[key],
        platforms: [] as string[],
      };

      type Key = keyof typeof platform;
      Object.keys(platform).forEach((platformKey) => {
        if (campaign[platformKey as Key]) {
          campaign.platforms.push(platform[platformKey as Key]);
        }
      });

      return campaign;
    });

    return campaignItems;
  }
);

export const getActiveCampaign: Selector<UUID> = (state) => state.campaign.activeCampaign;

export const getCampaignsTotalCount: Selector<number> = (state) => state.campaign.count;

export const getActiveIntegrationsLoading: Selector<boolean> = (state) =>
  state.campaign.loading.includes(fetchActiveIntegrationsRequest.toString());
