import { fetchTeamsRequest } from 'actions';
import type { Selector, Team } from 'types';

export const getTeams: Selector<Team[]> = (state) => Object.values(state.teams.items);

export const getTeamsLoading: Selector<boolean> = (state) =>
  state.teams.loading.includes(fetchTeamsRequest.toString());

export const getTeamsCount: Selector<number> = (state) => state.teams.count;

export const getTeamsForFilterPills: Selector<Team[]> = (state) => {
  const { filterItems } = state.teams;

  return Object.keys(filterItems).map((uuid) => filterItems[uuid]);
};
