import { fetchAllTestCases } from 'actions';
import TestCaseStateFilter from 'components/Filters/TestCaseStateFilter';
import SearchInput from 'components/SearchInput';
import TestCasePieChart from 'components/TestCasePieChart';
import TestCaseList from 'components/TestCaseTable';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import pluralize from 'pluralize';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTestCaseSummary,
  getTestCasesLoading,
  getTestCasesTotalCount,
} from 'selectors/testCases';
import type { UUID } from 'types';

type ComponentProps = {
  ruleId: UUID;
  toggleTestSuiteSidebar: () => void;
};

const RuleManagerTestSuite: React.FC<ComponentProps> = ({ ruleId, toggleTestSuiteSidebar }) => {
  const dispatch = useDispatch();

  const testCasesLoading = useSelector(getTestCasesLoading);
  const count = useSelector(getTestCasesTotalCount);

  const handleSearchChange = (value: string): void => {
    dispatch(fetchAllTestCases({ rule_uuids: ruleId, broad_search: value }));
  };

  const summary = useSelector(getTestCaseSummary);

  return (
    <div className="px-3 pt-4 flex flex-col gap-2.5">
      <div className="flex flex-row gap-4 items-end justify-between flex-1">
        <div className="flex-initial flex flex-col gap-3 justify-between w-full">
          <button
            className="flex justify-center button button--secondary w-44 py-1.5 px-4"
            onClick={toggleTestSuiteSidebar}
            type="button"
          >
            <span className="text-litlingo-primary font-bold">View Test Coverage</span>
          </button>
          <div className="flex flex-row gap-2 w-full">
            <SearchInput className="w-full max-w-80 h-8" handleSearch={handleSearchChange} />

            <span className="whitespace-no-wrap self-end">{`${count} Test ${pluralize(
              'Case',
              count
            )}`}</span>
          </div>
        </div>

        <div className="flex flex-row flex-none gap-6 items-end">
          <TestCasePieChart summary={summary} />
          <TestCaseStateFilter resource={resourceQueryParamName.testCase} />
        </div>
      </div>
      <TestCaseList entity="rule" value={ruleId} loading={testCasesLoading} />
    </div>
  );
};

export default RuleManagerTestSuite;
