import {
  clearTestSentence,
  postEvaluateTestSentence,
  saveTestCase,
  setURLParams,
  testAnnotator,
} from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import Permissions from 'components/Permissions';
import SentencesViolatedSection from 'components/SentencesViolatedSection';
import ToggleButton from 'components/ToggleButton';
import Config from 'config';
import { platformNames } from 'constants/platform';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUser } from 'selectors/auth';
import {
  getAnnotatedTestSentenceResult,
  getAnnotationMatchedLanguage,
  getRulesViolated,
  getTestSentenceLoading,
} from 'selectors/communications';
import { getNavParams } from 'selectors/nav';
import { getRule } from 'selectors/rule';
import { useSelector } from 'store';
import TestResultsVisualizer from '../TestResultsVisualizer';

type RouterParams = {
  ruleId: string;
  campaignId: string;
  annotatorId: string;
};

type Platform = keyof typeof platformNames;

// TODO: clean this component, a lot of things are not needed on the test-viz-admin
const TestPageAdmin: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getTestSentenceLoading);
  const { ruleId, campaignId, annotatorId } = useParams<RouterParams>();
  const rule = useSelector(getRule(ruleId)) || {};
  const resultLines = useSelector(getAnnotatedTestSentenceResult);
  const { rulesViolated } = useSelector(getRulesViolated);
  const annotationsMatched = useSelector(getAnnotationMatchedLanguage);
  const body = useSelector((state) => (getNavParams(state).body as string) || '');
  const user = useSelector(getUser);
  const [shouldTestFire, setShouldTestFire] = useState(false);
  const [platform, setPlatform] = useState<Platform>('slack');

  // works as a componentWillUnmount to avoid having data when returning to this view
  // @ts-ignore
  useEffect(() => (): void => dispatch(clearTestSentence()), [dispatch]);

  if (!user.roles.includes('super-admin') || !['local', 'development'].includes(Config.target)) {
    return null;
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    dispatch(setURLParams({ body: event.target.value }));
  };

  const handlePlatformChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setPlatform(event.target.value as Platform);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    if (annotatorId) {
      dispatch(testAnnotator({ body, annotator: annotatorId }));
    } else {
      dispatch(postEvaluateTestSentence({ body, platform }));
    }
  };

  const handleSaveTestCase = (): void => {
    const entities = {
      annotator_uuid: annotatorId,
      rule_uuid: annotatorId ? undefined : rule.uuid, // if there's an annotator, not rule
      campaign_uuid: rule.uuid || annotatorId ? undefined : campaignId, // if theres a rule, save rule not campaign
    };
    dispatch(saveTestCase({ test_string: body, should_match: shouldTestFire, ...entities }));
  };

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />
      <div className="py-10 min-h-wo-navbar">
        <header>
          <div className="max-w-7xl mx-auto px-1 sm:px-12 lg:px-14">
            <div className="mt-2 md:flex md:items-center md:justify-between">
              <div className="flex-1 min-w-0">
                <div className="flex flex-row items-center">
                  <h2 className="title">Test Sentence Visualizer (Admin mode)</h2>
                  {loading && <LoadingIndicator className="ml-2" size="5" />}
                </div>
              </div>
            </div>
          </div>
        </header>
        <main>
          <div className="mt-5 mb-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="mt-6 sm:mt-5 sm:border-t sm:border-gray-200 sm:pt-5 text-left">
              <div>
                <div>
                  <div className="flex">
                    <div className="w-2/3 p-4">
                      <form onSubmit={handleSubmit}>
                        <div className="text-left">
                          <label
                            htmlFor="about"
                            className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                          >
                            <div className="mt-1 sm:mt-0 ">
                              <div className="flex rounded-md shadow-sm">
                                <textarea
                                  id="about"
                                  rows={6}
                                  className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                  onChange={handleInputChange}
                                  value={body}
                                />
                              </div>
                            </div>
                          </label>
                        </div>
                        <div className="flex justify-end">
                          {!campaignId && !ruleId && !annotatorId && (
                            <div className="mt-2 mr-8">
                              <span className="self-center">Platform</span>
                              <select
                                aria-label="Selected tab"
                                className="form-select block pl-3 pr-10 py-px text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150"
                                onChange={handlePlatformChange}
                                value={platform}
                              >
                                {(
                                  Object.keys(platformNames) as Array<keyof typeof platformNames>
                                ).map((key) => (
                                  <option key={platform} value={key}>
                                    {platformNames[key]}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                          <div className="flex justify-end mt-4 items-center">
                            {(campaignId || ruleId || annotatorId) && (
                              <Permissions action="test_cases.create">
                                <span className="mr-4 flex items-center">
                                  <p className="text text--medium mr-4">Should Match</p>
                                  <ToggleButton
                                    isOn={shouldTestFire}
                                    handleToggleClick={(): void => {
                                      setShouldTestFire(!shouldTestFire);
                                    }}
                                  />
                                </span>
                                <span className="mr-4 inline-flex rounded-md shadow-sm">
                                  <button
                                    type="button"
                                    className={`button button--secondary ${
                                      body === '' ? 'bg-opacity-25' : ''
                                    }`}
                                    disabled={body === ''}
                                    onClick={handleSaveTestCase}
                                  >
                                    Save to Suite
                                  </button>
                                </span>
                              </Permissions>
                            )}
                            <span className="inline-flex rounded-md shadow-sm">
                              <button
                                type="submit"
                                className={`button button--primary ${
                                  body === '' ? 'bg-opacity-25' : ''
                                }`}
                                disabled={body === ''}
                              >
                                Run Test
                              </button>
                            </span>
                          </div>
                        </div>
                      </form>
                    </div>
                    {resultLines && (
                      <SentencesViolatedSection
                        annotatorId={annotatorId}
                        annotationsMatched={annotationsMatched}
                        rulesViolated={rulesViolated}
                      />
                    )}
                  </div>
                </div>
                <TestResultsVisualizer />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default TestPageAdmin;
