import { createAction } from '@reduxjs/toolkit';
import { TestCaseEntity } from 'constants/testCases';
import type {
  API,
  ErrorObject,
  Platform,
  TestCase,
  TestCasesSummary,
  TestResult,
  UUID,
} from 'types';

export const fetchSingleTestCase = createAction<{ testCaseId: UUID }>('TEST_CASE/FETCH');
export const fetchSingleTestCaseRequest = createAction('TEST_CASE/FETCH_REQUEST');
export const fetchSingleTestCaseFailure = createAction<ErrorObject>('TEST_CASE/FETCH_FAILURE');
export const fetchSingleTestCaseSuccess = createAction<TestCase>('TEST_CASE/FETCH_SUCCESS');

/* eslint-disable camelcase */
export type FetchAllTestCasesPayload = {
  campaign_uuids?: UUID;
  annotator_uuids?: UUID;
  rule_uuids?: UUID;
  campaign_including_rules?: boolean;
  limit?: number;
  broad_search?: string;
  branch_uuid?: UUID;
};
/* eslint-enable camelcase */
export const fetchAllTestCases = createAction<FetchAllTestCasesPayload>('TEST_CASES/FETCH');

export const fetchTestCasesRequest = createAction('TEST_CASES/FETCH_REQUEST');
export const fetchTestCasesSuccess = createAction<API.WrappedAPIResponse<TestCase>>(
  'TEST_CASES/FETCH_SUCCESS'
);
export const fetchTestCasesFailure = createAction<ErrorObject>('TEST_CASES/FETCH_FAILURE');

// eslint-disable-next-line camelcase
export const fetchTestResults = createAction<{ test_case_uuids: UUID }>('TEST_RESULTS/FETCH');
export const fetchTestResultsRequest = createAction('TEST_RESULTS/FETCH_REQUEST');

export type FetchTestResultsSuccessPayload = {
  data: API.WrappedAPIResponse<TestResult>;
  uuid: UUID;
};
export const fetchTestResultsSuccess = createAction<FetchTestResultsSuccessPayload>(
  'TEST_RESULTS/FETCH_SUCCESS'
);
export const fetchTestResultsFailure = createAction<ErrorObject>('TEST_RESULTS/FETCH_FAILURE');

export const evaluateRequest = createAction('TEST_CASES/EVALUATE_REQUEST');
export const evaluateSuccess = createAction('TEST_CASES/EVALUATE_SUCCESS');
export const evaluateFailure = createAction<ErrorObject>('TEST_CASES/EVALUATE_FAILURE');

/* eslint-disable camelcase */
export type EvaluateAsyncPayload = {
  campaign_uuids?: UUID;
  annotator_uuids?: UUID;
  rule_uuids?: UUID;
  uuids?: UUID;
  limit?: number;
};
/* eslint-enable camelcase */
export const evaluateAsync = createAction<EvaluateAsyncPayload>('TEST_CASES/EVALUATE_ASYNC');
export const evaluateAsyncRequest = createAction('TEST_CASES/EVALUATE_ASYNC_REQUEST');

export type EvaluateAsyncSuccessPayload = {
  // eslint-disable-next-line camelcase
  result_key: string;
};
// TODO: Add payload when the results_key is returned back from the backend
export const evaluateAsyncSuccess = createAction('TEST_CASES/EVALUATE_ASYNC_SUCCESS');
export const evaluateAsyncFailure = createAction<ErrorObject>('TEST_CASES/EVALUATE_ASYNC_FAILURE');

export const upsertTestCase = createAction<Partial<TestCase>>('TEST_CASE/UPSERT');
export const upsertTestCaseRequest = createAction('TEST_CASE/UPSERT_REQUEST');
export const upsertTestCaseSuccess = createAction<TestCase>('TEST_CASE/UPSERT_SUCCESS');
export const upsertTestCaseFailure = createAction<ErrorObject>('TEST_CASE/UPSERT_FAILURE');

export const evaluateAsyncResults = createAction<string>('TEST_CASES/EVALUATE_ASYNC_RESULTS');
export const evaluateAsyncResultsRequest = createAction(
  'TEST_CASES/EVALUATE_ASYNC_RESULTS_REQUEST'
);

export type EvaluateAsyncResultsSuccessPayload = {
  results: TestResult[];
};
export const evaluateAsyncResultsSuccess = createAction<EvaluateAsyncResultsSuccessPayload>(
  'TEST_CASES/EVALUATE_ASYNC_RESULTS_SUCCESS'
);
export const evaluateAsyncResultsFailure = createAction<ErrorObject>(
  'TEST_CASES/EVALUATE_ASYNC_RESULTS_FAILURE'
);

/* eslint-disable camelcase */
export type SaveTestCasePayload = {
  test_string: string;
  should_match: boolean;
  annotator_uuid?: UUID | null;
  rule_uuid?: UUID | null;
  campaign_uuid?: UUID | null;
  platform?: Platform;
  event_uuid?: UUID;
  comment?: string;
  branch_uuid?: UUID | null;
  refetch?: boolean;
};

export type UpsertTestCaseFromBranchPayload = {
  testCaseIds: UUID[];
  branchId: UUID;
  ruleId?: UUID;
  refetch?: boolean;
};
/* eslint-enable camelcase */
export const saveTestCase = createAction<SaveTestCasePayload>('TEST_CASES/SAVE');
export const saveTestCaseRequest = createAction('TEST_CASES/SAVE_REQUEST');
export const saveTestCaseSuccess = createAction<TestCase>('TEST_CASES/SAVE_SUCCESS');
export const saveTestCaseFailure = createAction<ErrorObject>('TEST_CASES/SAVE_FAILURE');

export const updateTestCasesMatch =
  createAction<{ ids: UUID[]; shouldMatch: boolean }>('TEST_CASES/UPDATE_MATCH');
export const updateTestCasesMatchRequest = createAction('TEST_CASES/UPDATE_MATCH_REQUEST');
export const updateTestCasesMatchSuccess = createAction<TestCase[]>(
  'TEST_CASES/UPDATE_MATCH_SUCCESS'
);
export const updateTestCasesMatchFailure = createAction<ErrorObject>(
  'TEST_CASES/UPDATE_MATCH_FAILURE'
);

export const addTestCaseToBranch = createAction<UpsertTestCaseFromBranchPayload>(
  'TEST_CASES/ADD_TO_BRANCH'
);
export const addTestCaseToBranchRequest = createAction('TEST_CASES/ADD_TO_BRANCH_REQUEST');
export const addTestCaseToBranchSuccess = createAction<TestCase[]>(
  'TEST_CASES/ADD_TO_BRANCH_SUCCESS'
);
export const addTestCaseToBranchFailure = createAction<ErrorObject>(
  'TEST_CASES/ADD_TO_BRANCH_FAILURE'
);

export const removeTestCaseFromBranch = createAction<UpsertTestCaseFromBranchPayload>(
  'TEST_CASES/REMOVE_FROM_BRANCH'
);
export const removeTestCaseFromBranchRequest = createAction(
  'TEST_CASES/REMOVE_FROM_BRANCH_REQUEST'
);
export const removeTestCaseFromBranchSuccess = createAction<TestCase[]>(
  'TEST_CASES/REMOVE_FROM_BRANCH_SUCCESS'
);
export const removeTestCaseFromBranchFailure = createAction<ErrorObject>(
  'TEST_CASES/REMOVE_FROM_BRANCH_FAILURE'
);

export const deleteTest =
  createAction<{ id: UUID; entity?: { name: string; value: string } }>('TEST_CASES/DELETE');
export const deleteTestCaseRequest = createAction('TEST_CASES/DELETE_REQUEST');
export const deleteTestCaseSuccess = createAction<string>('TEST_CASES/DELETE_SUCCESS');
export const deleteTestCaseFailure = createAction<ErrorObject>('TEST_CASES/DELETE_FAILURE');

export const nextPageRequest = createAction('TEST_CASES/NEXT_PAGE');
export const previousPageRequest = createAction('TEST_CASES/PREVIOUS_PAGE');

export const fetchTestCaseSummary =
  createAction<FetchAllTestCasesPayload>('TEST_CASE_SUMMARY/FETCH');
export const fetchTestCaseSummaryRequest = createAction('TEST_CASE_SUMMARY/FETCH_REQUEST');
export const fetchTestCaseSummarySuccess = createAction<TestCasesSummary>(
  'TEST_CASE_SUMMARY/FETCH_SUCCESS'
);
export const fetchTestCaseSummaryFailure = createAction<ErrorObject>(
  'TEST_CASE_SUMMARY/FETCH_FAILURE'
);

export const exportTestCases = createAction<EvaluateAsyncPayload>('TEST_CASES/EXPORT');
export const exportTestCasesSuccess = createAction('TEST_CASES/EXPORT_SUCCESS');
export const exportTestCasesFailure = createAction<ErrorObject>('TEST_CASES/EXPORT_FAILURE');
export const exportTestCasesFulfill = createAction('TEST_CASES/EXPORT_FULFILL');

export const importTestCases = createAction<{
  file: File;
  entity?: TestCaseEntity;
  entityId?: UUID;
}>('TEST_CASES/IMPORT');
export const importTestCasesSuccess = createAction('TEST_CASES/IMPORT_SUCCESS');
export const importTestCasesFailure = createAction<ErrorObject>('TEST_CASES/IMPORT_FAILURE');
export const importTestCasesFulfill = createAction('TEST_CASES/IMPORT_FULFILL');

export const bulkDeleteTestCases = createAction<{ uuids: UUID[] }>('TEST_CASES/BULK_DELETE');
export const bulkDeleteTestCasesSuccess = createAction<{ uuids: UUID[] }>(
  'TEST_CASES/BULK_DELETE_SUCCESS'
);
export const bulkDeleteTestCasesFailure = createAction<ErrorObject>(
  'TEST_CASES/BULK_DELETE_FAILURE'
);
export const bulkDeleteTestCasesFulfill = createAction('TEST_CASES/BULK_DELETE_FULFILL');

export const bulkDuplicateTestCases = createAction<{ uuids: UUID[] }>('TEST_CASES/BULK_DUPLICATE');
export const bulkDuplicateTestCasesSuccess = createAction('TEST_CASES/BULK_DUPLICATE_SUCCESS');
export const bulkDuplicateTestCasesFailure = createAction<ErrorObject>(
  'TEST_CASES/BULK_DUPLICATE_FAILURE'
);

type AddTestCaseTagPayload = {
  testCaseId: string;
  tag: string;
};
export const addTestCaseTag = createAction<AddTestCaseTagPayload>('TEST_CASES/ADD_TAG');
export const addTestCaseTagRequest = createAction('TEST_CASES/ADD_TAG_REQUEST');
export const addTestCaseTagFailure = createAction<ErrorObject>('TEST_CASES/ADD_TAG_FAILURE');
export const addTestCaseTagSuccess = createAction<TestCase>('TEST_CASES/ADD_TAG_SUCCESS');

type RemoveTestCaseTagPayload = {
  testCaseId: string;
  tag: string;
};
export const removeTestCaseTag = createAction<RemoveTestCaseTagPayload>('TEST_CASES/REMOVE_TAG');
export const removeTestCaseTagRequest = createAction('TEST_CASES/REMOVE_TAG_REQUEST');
export const removeTestCaseTagFailure = createAction<ErrorObject>('TEST_CASES/REMOVE_TAG_FAILURE');
export const removeTestCaseTagSuccess = createAction<TestCase>('TEST_CASES/REMOVE_TAG_SUCCESS');

type addBulkTestCaseTagsPayload = {
  uuids: UUID[];
  value: string;
};
export const addBulkTestCaseTags = createAction<addBulkTestCaseTagsPayload>(
  'TEST_CASES/ADD_TAGS_BULK'
);
export const addBulkTestCaseTagsRequest = createAction('TEST_CASES/ADD_TAGS_BULK_REQUEST');
export const addBulkTestCaseTagsFailure = createAction<ErrorObject>(
  'TEST_CASES/ADD_TAGS_BULK_FAILURE'
);
export const addBulkTestCaseTagsSuccess = createAction<TestCase>(
  'TEST_CASES/ADD_TAGS_BULK_SUCCESS'
);

export const upsertTestCaseAndAddToBranch = createAction<
  { test: Partial<TestCase> } & Omit<UpsertTestCaseFromBranchPayload, 'testCaseIds'>
>('TEST_CASE/UPSERT_AND_ADD_TO_BRANCH');

export const addToLastTestCases = createAction<string[]>('TEST_CASE/ADD_TO_LAST_TEST_CASES');

export const clearLastTestCases = createAction('TEST_CASE/CLEAR_LAST_TEST_CASES');

type createTestCasePayload = {
  prompt: string;
};

export const createTestCase = createAction<createTestCasePayload>('TEST_CASE/CREATE');
export const createTestCaseRequest = createAction('TEST_CASE/CREATE_REQUEST');
export const createTestCaseSuccess = createAction<API.Tests.GenerateTestCase>(
  'TEST_CASE/CREATE_SUCCESS'
);
export const createTestCaseFailure = createAction<ErrorObject>('TEST_CASE/CREATE_FAILURE');
