import { createAction } from '@reduxjs/toolkit';
import type {
  AnnotatorRelationship,
  API,
  ErrorObject,
  MRule,
  MRuleConfigNode,
  NormalizedAnnotator,
  NormalizedResource,
  Rule,
  RuleTestCasesSummary,
  UUID,
} from 'types';

export type EditRulePayload = {
  id: UUID;
  data: Partial<MRule>;
};
export const editRule = createAction<EditRulePayload>('RULE/EDIT');

export type UpdateRulePayload = {
  rule: MRule;
};
export const updateRule = createAction<UpdateRulePayload>('RULE/UPDATE_RULE');
export const saveRuleRequest = createAction('RULE/SAVE_REQUEST');
export const saveRuleFailure = createAction<ErrorObject>('RULE/SAVE_FAILURE');
export const saveRuleSuccess = createAction<NormalizedResource<Rule>>('RULE/SAVE_SUCCESS');

export type CloneRulePayload = {
  ruleOutcomeId: UUID;
  campaignId: UUID;
};

export const cloneRule = createAction<CloneRulePayload>('RULE/CLONE');
export const cloneRuleRequest = createAction('RULE/CLONE_REQUEST');
export const cloneRuleFailure = createAction<ErrorObject>('RULE/CLONE_FAILURE');
export const cloneRuleSuccess = createAction('RULE/CLONE_SUCCESS');

export type FetchSingleRulePayload = {
  ruleId: UUID;
};

export const fetchSingleRule = createAction<FetchSingleRulePayload>('RULE/FETCH');
export const fetchSingleRuleRequest = createAction('RULE/FETCH_REQUEST');
export const fetchSingleRuleFailure = createAction<ErrorObject>('RULE/FETCH_FAILURE');
export const fetchSingleRuleSuccess = createAction<MRule>('RULE/FETCH_SUCCESS');

export type FetchAllRulesPayload = {
  name?: string;
  limit?: string;
  // eslint-disable-next-line camelcase
  customer_uuid?: string;
};

export const fetchAllRulesDebounced = createAction<FetchAllRulesPayload>('RULES/DEBOUNCED_FETCH');
export const fetchAllRules = createAction<FetchAllRulesPayload>('RULES/FETCH');
export const fetchAllRulesWithSummary = createAction<FetchAllRulesPayload>('RULES_SUMMARY/FETCH');
export const fetchFilterRules = createAction<FetchAllRulesPayload>('RULES/FETCH_FILTER_REQUEST');
export const fetchAllRulesRequest = createAction('RULES/FETCH_REQUEST');
export const fetchAllRulesFailure = createAction<ErrorObject>('RULES/FETCH_FAILURE');
export const fetchAllRulesSuccess =
  createAction<API.WrappedAPIResponse<Rule>>('RULES/FETCH_SUCCESS');

type FetchRulesForFilterPillsPayload = { ruleIds: string[] };

export const fetchRulesForFilterPills =
  createAction<FetchRulesForFilterPillsPayload>('RULES/FETCH_BY_ID');
export const fetchRulesForFilterPillsRequest = createAction('RULES/FETCH_BY_ID_REQUEST');
export const fetchRulesForFilterPillsSuccess = createAction<API.WrappedAPIResponse<Rule>>(
  'RULES/FETCH_BY_ID_SUCCESS'
);
export const fetchRulesForFilterPillsFailure = createAction<ErrorObject>(
  'RULES/FETCH_BY_ID_FAILURE'
);

export type DeleteRulePayload = {
  ruleId: UUID;
};

export const deleteRule = createAction<DeleteRulePayload>('RULE/DELETE');
export const deleteRuleRequest = createAction<string>('RULE/DELETE_REQUEST');
export const deleteRuleSuccess = createAction<string>('RULE/DELETE_SUCCESS');
export const deleteRuleFailure = createAction<ErrorObject>('RULE/DELETE_FAILURE');

export const saveRelationshipsSuccess = createAction<
  NormalizedResource<Omit<AnnotatorRelationship, 'configId'>>
>('RELATIONSHIP/SAVE_SUCCESS');
export const fetchRelationshipsSuccess = createAction<
  NormalizedResource<Omit<AnnotatorRelationship, 'configId'>>
>('RELATIONSHIP/FETCH_SUCCESS');

export type UpdateConfigGroupsPayload = {
  parentId: UUID;
  newConfigId: UUID;
};
export const updateConfigGroups = createAction<UpdateConfigGroupsPayload>(
  'CONFIG/UPDATE_CONFIG_GROUPS'
);
export const createRuleConfig = createAction<NormalizedResource<MRuleConfigNode>>('CONFIG/CREATE');
export const saveRuleConfigSuccess =
  createAction<NormalizedResource<MRuleConfigNode>>('CONFIG/SAVE_SUCCESS');
export const fetchRuleConfigSuccess =
  createAction<NormalizedResource<MRuleConfigNode>>('CONFIG/FETCH_SUCCESS');

export type AddAnnotationMatcherToRulePayload = {
  annotator: NormalizedAnnotator;
  rule: MRule;
  dropIndex?: number;
};

export const addAnnotationMatcherToRule = createAction<AddAnnotationMatcherToRulePayload>(
  'RULE/ADD_ANNOTATION_MATCHER_TO_RULE'
);

export type selectRuleType = {
  rule: Rule | null;
};

export const selectRule = createAction<selectRuleType>('RULE/SELECT');

export type FetchRuleChangelogType = {
  ruleId: UUID;
  auditlogUuid: UUID;
  version: string;
};

export const fetchRuleChangelog = createAction<FetchRuleChangelogType>('RULE/FETCH_CHANGELOG');

type ScheduleComparePayload = {
  savedSearchId: UUID;
  ruleUuids: UUID[];
};
export const scheduleCompare = createAction<ScheduleComparePayload>(
  'RULES/SCHEDULE_COMPARE_FULFILL'
);
export const scheduleCompareRequest = createAction('RULES/SCHEDULE_COMPARE_REQUEST');
export const scheduleCompareSuccess = createAction('RULES/SCHEDULE_COMPARE_SUCCESS');
export const scheduleCompareFailure = createAction<ErrorObject>('RULES/SCHEDULE_COMPARE_FAILURE');
export const scheduleCompareFulfill = createAction('RULES/SCHEDULE_COMPARE_FULFILL');

export type FetchRuleTestCasesPayload = {
  rule_uuids: UUID;
};

export const fetchTestCaseSummaryRule = createAction<FetchRuleTestCasesPayload>(
  'TEST_CASE_SUMMARY_RULE/FETCH'
);
export const fetchTestCaseSummaryRuleRequest = createAction('TEST_CASE_SUMMARY_RULE/FETCH_REQUEST');
export const fetchTestCaseSummaryRuleSuccess = createAction<RuleTestCasesSummary>(
  'TEST_CASE_SUMMARY_RULE/FETCH_SUCCESS'
);
export const fetchTestCaseSummaryRuleFailure = createAction<ErrorObject>(
  'TEST_CASE_SUMMARY_RULE/FETCH_FAILURE'
);

export type PublisRuleVersion = {
  ruleId: UUID;
  version: string;
};

export const publishRuleVersion = createAction<PublisRuleVersion>('RULES/PUBLISH_VERSION');
export const publishRuleVersionRequest = createAction('RULES/PUBLISH_VERSION_REQUEST');
export const publishRuleVersionSuccess = createAction<MRule>('RULES/PUBLISH_VERSION_SUCCESS');
export const publishRuleVersionFailure = createAction<ErrorObject>('RULES/PUBLISH_VERSION_FAILURE');

export type UndoRuleVersion = {
  ruleId: UUID;
};

export const undoRuleVersion = createAction<UndoRuleVersion>('RULES/UNDO_VERSION');
export const undoRuleVersionRequest = createAction('RULES/UNDO_VERSION_REQUEST');
export const undoRuleVersionSuccess = createAction<MRule>('RULES/UNDO_VERSION_SUCCESS');
export const undoRuleVersionFailure = createAction<ErrorObject>('RULES/UNDO_VERSION_FAILURE');

export const selectAnnotators = createAction<string[]>('RULE/SET_SELECTED_ANOTATORS');

export const setShowHighlights = createAction<boolean>('RULE/SHOW_HIGHLIGHTS');

export const setCurrentTestRuleId = createAction<string>('RULE/TEST_RULE_ID');

export const annotatorPosition = createAction<string | null>('RULE/ANNOTATOR_POSITION');

export const setToAnnotator = createAction<boolean>('RULE/SET_TO_ANNOTATOR');
