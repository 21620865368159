/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import getSelectedEnvelope from 'selectors/envelopeReview';
import CommunicationContainer from './CommunicationContainer';
import EnvelopeSkeleton from './EnvelopeSkeleton';

const RenderEnvelopeBoxes: React.FC = () => {
  const envelope = useSelector(getSelectedEnvelope);

  if (envelope && envelope.communications && envelope.communications.length > 0) {
    return <CommunicationContainer />;
  }

  return <EnvelopeSkeleton />;
};

export default RenderEnvelopeBoxes;
