import { deleteUser, sendUserInvitation, sendUserInvitationQuery, showSuccessAlert } from 'actions';
import { removeUsers, selectUsers } from 'actions/userPermissionsPage';
import BadgeRedesign from 'components/Badge/BadgeRedesign';
import ConfirmModal from 'components/ConfirmModal';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsSelectAll, getSelectedUsers } from 'selectors/userPermissionsPage';
import { getUsers } from 'selectors/users';

type ComponentProps = {
  shouldRemoveUsers?: boolean;
};

const SelectedUsersSection: React.FC<ComponentProps> = ({ shouldRemoveUsers = false }) => {
  const dispatch = useDispatch();

  const [showMore, setShowMore] = useState(false);
  const [overflowingContainer, setOverflowingContainer] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);

  const selectedUsers = useSelector(getSelectedUsers);
  const isSelectAll = useSelector(getIsSelectAll);
  const users = useSelector(getUsers);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current } = containerRef;

    if (current) {
      if (
        current.clientWidth < current.scrollWidth ||
        current.clientHeight < current.scrollHeight
      ) {
        setOverflowingContainer(true);
      } else {
        setOverflowingContainer(false);
      }
    }
  }, [containerRef, selectedUsers.length]);

  const toggleConfirmModal = (): void => setIsDeletingUser(false);

  const handleDeleteUser = (): void => {
    dispatch(deleteUser({ uuids: selectedUsers.map((u) => u.uuid) }));
    dispatch(selectUsers([]));
    toggleConfirmModal();
  };

  const handleSendInvitation = (event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>): void => {
    event.stopPropagation();
    if (selectedUsers.length === Object.keys(users).length) {
      dispatch(sendUserInvitationQuery());
    } else {
      dispatch(sendUserInvitation({ users: selectedUsers }));
    }
  };

  const handleCopyClick = async (
    event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>
  ): Promise<void> => {
    event.stopPropagation();
    const ids = selectedUsers.reduce((acc, value, idx) => {
      if (idx === 0) {
        return value.uuid;
      }
      return `${acc}, ${value.uuid}`;
    }, '');
    await navigator.clipboard.writeText(ids);
    dispatch(showSuccessAlert('User UUID(s) copied to your clipboard'));
  };

  const handleDelete = (): void => {
    if (shouldRemoveUsers) {
      dispatch(removeUsers(selectedUsers));
      dispatch(selectUsers([]));
    } else {
      setIsDeletingUser(true);
    }
  };

  return (
    <div className="bg-white flex flex-col">
      <div className="px-3 py-2 flex flex-row gap-1 bg-litlingo-gray-1 bg-opacity-50">
        <button
          onClick={(e): void => handleSendInvitation(e)}
          type="submit"
          className="button button--secondary h-8"
          data-testid="save-button"
        >
          <span className="font-bold whitespace-no-wrap">Send Invitation</span>
        </button>
        {(!isSelectAll || (isSelectAll && selectedUsers.length < 25)) && (
          <button
            onClick={(e): void => {
              handleCopyClick(e);
            }}
            type="submit"
            className="flex button button--secondary h-8"
            data-testid="save-button"
          >
            <span className="font-bold whitespace-no-wrap">Copy ID</span>
          </button>
        )}
        {!isSelectAll ||
          (isSelectAll && selectedUsers.length < 25 && (
            <button
              onClick={handleDelete}
              type="submit"
              className="flex text-litlingo-alert underline self-end ml-2 focus:outline-none"
              data-testid="save-button"
            >
              <span className="whitespace-no-wrap">Delete Users</span>
            </button>
          ))}
      </div>
      {(!isSelectAll || (isSelectAll && selectedUsers.length < 25)) && (
        <div className="px-3 pt-2 pb-3">
          <div className={`flex flex-row gap-1 items-center ${showMore ? '' : ''}`}>
            <div
              ref={containerRef}
              className={`flex flex-row gap-1 items-center flex-grow-0 flex-wrap text-small ${
                showMore ? 'flex-wrap' : 'overflow-hidden h-10'
              }`}
            >
              {selectedUsers.map((user) => (
                <BadgeRedesign
                  key={user.uuid}
                  title={user.name || ''}
                  deleteAction={(): void => {
                    dispatch(selectUsers(selectedUsers.filter((u) => u.uuid !== user.uuid)));
                  }}
                />
              ))}
              {showMore && (
                <button
                  type="button"
                  className="text-body whitespace-no-wrap focus:outline-none"
                  onClick={(): void => setShowMore(!showMore)}
                >
                  <span className="text-litlingo-primary underline">Show Less</span>
                </button>
              )}
            </div>
            {!showMore && overflowingContainer && (
              <button
                type="button"
                className="self-end text-body whitespace-no-wrap focus:outline-none"
                onClick={(): void => setShowMore(!showMore)}
              >
                <span className="text-litlingo-primary underline">Show All</span>
              </button>
            )}
          </div>
        </div>
      )}

      {isDeletingUser && (
        <ConfirmModal
          text={`Are you sure you want to delete ${selectedUsers.length} users?`}
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDeleteUser}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </div>
  );
};
export default SelectedUsersSection;
