import { createAction } from '@reduxjs/toolkit';
import type { API, ErrorObject, Tag, UUID } from 'types';

type FetchAllTagsPayload =
  | {
      uuids?: UUID[];
      limit?: number;
      include_envelopes_count?: boolean;
      broad_search?: string;
      offset?: string;
    }
  | undefined;

export const fetchAllTags = createAction<FetchAllTagsPayload>('TAGS/FETCH');
export const fetchAllTagsRequest = createAction('TAGS/FETCH_REQUEST');
export const fetchAllTagsFailure = createAction<ErrorObject>('TAGS/FETCH_FAILURE');
export const fetchAllTagsSuccess = createAction<API.WrappedAPIResponse<Tag>>('TAGS/FETCH_SUCCESS');
export const fetchTagsSuccessAppend = createAction<API.WrappedAPIResponse<Tag>>(
  'TAGS/FETCH_SUCCESS_APPEND'
);

type FetchTagsForFilterPayload = {
  searchValue: string;
  limit?: number;
  offset?: number;
};
export const fetchTagsForFilter = createAction<FetchTagsForFilterPayload>('TAGS_FILTER/FETCH');

type FetchTagsForFilterPillsPayload =
  | {
      uuids?: UUID[];
    }
  | undefined;

export const fetchTagsForFilterPills =
  createAction<FetchTagsForFilterPillsPayload>('TAGS/FETCH_FILTER_PILLS');
export const fetchTagsForFilterPillsRequest = createAction('TAGS/FETCH_FILTER_PILLS_REQUEST');
export const fetchTagsForFilterPillsFailure = createAction<ErrorObject>(
  'TAGS/FETCH_FILTER_PILLS_FAILURE'
);
export const fetchTagsForFilterPillsSuccess = createAction<API.WrappedAPIResponse<Tag>>(
  'TAGS/FETCH_FILTER_PILLS_SUCCESS'
);

export const upsertTag = createAction<
  Pick<Tag, 'value' | 'description' | 'color'> & { uuid?: Tag['uuid'] }
>('TAGS/UPSERT');
export const upsertTagRequest = createAction('TAGS/UPSERT_REQUEST');
export const upsertTagFailure = createAction<ErrorObject>('TAGS/UPSERT_FAILURE');
export const upsertTagSuccess = createAction<Tag>('TAGS/UPSERT_SUCCESS');

export const deleteTag = createAction<{ id: UUID }>('TAGS/DELETE');
export const deleteTagRequest = createAction('TAGS/DELETE_REQUEST');
export const deleteTagSuccess = createAction<string>('TAGS/DELETE_SUCCESS');
export const deleteTagFailure = createAction<ErrorObject>('TAGS/DELETE_FAILURE');

export const addTagToFilterItems = createAction<Tag>('TAGS/ADD_TO_FILTER_ITEMS');
