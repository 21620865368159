import { UUID } from '@litlingo/client';
import { SEARCH_ICON } from 'constants/filterIcons';
import React from 'react';
import type { MLanguageMatcher } from 'types';
import LanguageMatcherSidebarItem from './LanguageMatcherSidebarItem';

type ComponentProps = {
  languageMatchers: MLanguageMatcher[];
  languageMatcherFilter: string;
  highlightedLanguageMatcher: string | undefined;
  setLanguageMatcherFilter: React.Dispatch<React.SetStateAction<string>>;
  addLocalLanguageMatcher: () => void;
  handleLanguageMatcherClick: (languageMatcherId: UUID) => void;
};

const LanguageMatcherSidebarList: React.FC<ComponentProps> = ({
  languageMatchers,
  languageMatcherFilter,
  highlightedLanguageMatcher,
  setLanguageMatcherFilter,
  addLocalLanguageMatcher,
  handleLanguageMatcherClick,
}) => {
  const handleFilterChange = (value: string): void => {
    setLanguageMatcherFilter(value);
  };

  return (
    <div className="w-full flex flex-col relative">
      <div className="px-4 py-2 flex flex-row gap-2">
        <div className="relative w-104">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <div className="absolute left-0 ml-1 w-5">{SEARCH_ICON()}</div>
          </div>
          <input
            id="search"
            name="search"
            className="form-input pl-7 pr-1 py-1.5 h-8 w-full text-litlingo-gray-6 text-body placeholder-italic placeholder-litlingo-gray-6"
            placeholder="Search Matchers and Terms"
            onChange={(event): void => {
              handleFilterChange(event.target.value);
            }}
            value={languageMatcherFilter}
            autoComplete="off"
            data-testid="search-input"
          />
        </div>
        <button
          type="button"
          onClick={addLocalLanguageMatcher}
          className="button button--secondary h-8"
          data-testid="add-matcher-button"
        >
          <span className="font-bold">Add</span>
        </button>
      </div>
      <div className="w-full border-b border-litlingo-gray-1" />
      <div className="flex flex-col w-full">
        {languageMatchers.length > 0 ? (
          languageMatchers.map((languageMatcher) => (
            <LanguageMatcherSidebarItem
              key={languageMatcher.uuid}
              languageMatcher={languageMatcher}
              handleLanguageMatcherClick={handleLanguageMatcherClick}
              highlightedLanguageMatcher={highlightedLanguageMatcher}
            />
          ))
        ) : (
          <div className="flex justify-center py-6">
            <span className="text-sm font-semibold">No language matchers to show</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageMatcherSidebarList;
