import Modal from 'components/Modal';
import React from 'react';

type ComponentProps = {
  title?: string;
  text: string;
  okButtonText: string;
  okButtonOnClick: () => void;
  cancelButtonText: string;
  toggleShowModal: () => void;
};

const ConfirmModal: React.FC<ComponentProps> = ({
  title,
  text,
  okButtonText,
  okButtonOnClick,
  cancelButtonText,
  toggleShowModal,
}) => {
  const body = <h3 className="my-4">{text}</h3>;
  return (
    <Modal
      body={body}
      title={title || 'Confirm Deletion'}
      okButton
      okButtonText={okButtonText || 'Ok'}
      cancelButtonText={cancelButtonText || 'Cancel'}
      okButtonOnClick={okButtonOnClick}
      toggleShowModal={toggleShowModal}
    />
  );
};

export default ConfirmModal;
