import EnvelopeListContent from 'components/EnvelopeList/EnvelopeListContent';
import EnvelopeListSidebar from 'components/EnvelopeList/EnvelopeListSidebar';
import Navbar from 'components/Navbar';
import useLogUnmountEvent from 'components/utils/useLogUnmountEvent';
import keyMap from 'constants/configHotKeys';
import React, { useEffect } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useSelector } from 'react-redux';
import { getShowSkippedEnvelopes } from 'selectors/assignments';
import logEvent from 'utils/analytics';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import EnvelopePreview from './EnvelopePreview';

const EnvelopeList: React.FC = () => {
  const showSkippedEnvelopes = useSelector(getShowSkippedEnvelopes);

  useLogUnmountEvent();

  useEffect(() => {
    logEvent('envelope-list-load');
  }, []);

  const handlers = {
    FOCUS_TAGS_INPUT: (): void => {
      const ele = document.querySelector('.react-tags__search-input') as HTMLInputElement;
      if (!ele || ele === document.activeElement) return;
      ele.value = '';
      setTimeout(() => {
        ele.focus();
      }, 100);
    },
    CHECK_ENVELOPE: (event: KeyboardEvent | undefined): void => {
      if (event) {
        event.preventDefault();
        document.querySelectorAll('.envelope-id-class').forEach((row) => {
          if (row.matches(':hover')) {
            const checkboxId = `#checkbox-${row.id}`;
            const checkbox = document.querySelector(checkboxId) as HTMLInputElement;
            if (checkbox) {
              checkbox.click();
              checkbox.blur();
            }
          }
        });
      }
    },
    TOGGLE_ENVELOPE: (event: KeyboardEvent | undefined): void => {
      if (event) {
        document.querySelectorAll('.envelope-id-class').forEach((row) => {
          if (row.matches(':hover')) {
            const toggleId = `#checkbox-${row.id}`;
            const toggle = document.querySelector(toggleId) as HTMLInputElement;
            if (toggle) {
              toggle.click();
              toggle.blur();
            }
          }
        });
      }
    },
  };

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <div
        className="bg-white"
        style={{
          height: `calc(100vh - ${warningBarOffset}px)`,
        }}
      >
        <Navbar />
        <div
          className="relative flex flex-row"
          style={{
            height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
          }}
        >
          {!showSkippedEnvelopes && <EnvelopeListSidebar />}
          <EnvelopeListContent />
          <EnvelopePreview />
        </div>
      </div>
    </>
  );
};

export default EnvelopeList;
