import { routerMiddleware } from 'connected-react-router';
import type { History } from 'history';
import type { RootReducer } from 'reducers';
import { AnyAction, applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import type { RootSaga } from 'sagas';

const initStore = (
  rootReducer: RootReducer,
  rootSaga: RootSaga,
  history: History
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Store<any, AnyAction> & {
  dispatch: unknown;
} => {
  const saga = createSagaMiddleware();
  const middlewares = [saga, thunk, routerMiddleware(history)];

  const store = createStore(rootReducer, {}, composeWithDevTools(applyMiddleware(...middlewares)));

  saga.run(rootSaga);

  return store;
};

export default initStore;
