import { useSelector } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getPermissionsPolicy } from 'selectors/permissions';
import { isActionAuthorized, isFieldUnprotected } from 'utils/permissions';

type ComponentProps = {
  action?: string;
  field?: string;
  children: React.ReactNode;
};

const Permissions: React.FC<ComponentProps> = (props) => {
  const { action, field, children } = props;

  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);

  let authorized = false;
  if (action != null) {
    authorized = isActionAuthorized(action, user.roles, policy);
  } else if (field != null) {
    authorized = isFieldUnprotected(field, user.roles, policy);
  } else {
    throw new Error("'action' or 'field' props are required to render Permission");
  }

  if (typeof children === 'function') {
    return children(authorized);
  }

  if (authorized) {
    return children;
  }

  return null;
};

export default Permissions;
