import Navbar from 'components/Navbar';
import React from 'react';
import CustomReportHeader from './CustomReportHeader';

const CustomReport: React.FC = () => (
  <>
    {/* <GlobalHotKeys keyMap={keyMap} handlers={handlers} /> */}
    <div className="flex flex-col bg-white min-w-screen">
      <Navbar />
      <CustomReportHeader />
      {/* {isDeletingReport && (
          <ConfirmModal
            data-testid="delete-modal"
            text="Are you sure you want to delete this report?"
            okButtonText="Yes"
            cancelButtonText="No"
            okButtonOnClick={handleDeleteClick}
            toggleShowModal={toggleConfirmModal}
          />
        )} */}
    </div>
  </>
);

export default CustomReport;
