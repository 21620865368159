import LinkLookup from 'components/LinkLookup';
import daysAgo from 'components/utils/daysAgo';
import React from 'react';
import type { AnnotatorVersion, UUID } from 'types';

type ComponentProps = {
  annotator: AnnotatorVersion;
  handleAnnotatorSelect: (checked: boolean, uuid: UUID) => void;
  selected: boolean;
  showAnnotatorModels: boolean;
};

const AnnotatorListRow: React.FC<ComponentProps> = ({
  annotator,
  handleAnnotatorSelect,
  selected,
  showAnnotatorModels,
}) => {
  const handleRowCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();

    handleAnnotatorSelect(e.target.checked, annotator.uuid);
    const element = document.activeElement as HTMLInputElement;
    if (element) {
      element.blur();
    }
  };

  return (
    <>
      <LinkLookup
        routeName="annotator-detail"
        routeParams={{ annotatorId: annotator.uuid }}
        className="border-t border-gray-200 hover:bg-gray-100 table-row"
        as="tr"
      >
        <td className="table-wrapper__new-td flex items-center justify-center ">
          <div className="flex items-center h-8 ">
            <input
              id={`checkbox-user-${annotator.uuid}`}
              data-testid="select-button"
              type="checkbox"
              className="cursor-pointer form-checkbox litlingo-checkbox w-5 h-5"
              onClick={(e): void => e.stopPropagation()}
              onChange={(e): void => {
                handleRowCheckboxChange(e);
              }}
              checked={selected}
            />
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center">
            <div>
              <div className="text-body">{`${annotator.name}`}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center">
            <div>
              <div className="text-body">{annotator.type}</div>
            </div>
          </div>
        </td>

        <td className="table-wrapper__new-td ">
          {
            // @ts-ignore
            <div className="text-body">{annotator.rule_annotators?.length || '-'}</div>
          }
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center">
            <div className="text-body ">
              {annotator.updated_at != null && daysAgo(annotator.updated_at)}
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="text-body">{annotator.updated_by?.name}</div>
        </td>
      </LinkLookup>
      {
        // @ts-ignore
        selected && showAnnotatorModels && !!annotator.rule_annotators.length && (
          <>
            <tr className="border-t border-gray-200 bg-litlingo-gray-0 table-row h-8">
              <td className="table-wrapper__new-td" />
              <td className="table-wrapper__new-td font-bold text-base">
                <div className="pl-8">Model</div>
              </td>
              <td className="table-wrapper__new-td font-bold text-base">
                <div>Use Case</div>
              </td>
              <td className="table-wrapper__new-td" />
              <td className="table-wrapper__new-td" />
              <td className="table-wrapper__new-td" />
            </tr>

            {
              // @ts-ignore
              annotator.rule_annotators.map((r) => (
                <tr
                  key={r.uuid}
                  className="border-t border-gray-200 bg-litlingo-gray-0 table-row h-8"
                >
                  <td className="table-wrapper__new-td " />
                  <td className="table-wrapper__new-td ">
                    {r.rule.campaign_rule_outcomes?.length ? (
                      <LinkLookup
                        routeName="campaign-detail"
                        routeParams={{ campaignId: r.uuid }}
                        type="button"
                        className="pl-8 self-end underline text-base text-litlingo-primary-120 focus:outline-none"
                      >
                        {`${r.rule.name} (v${r.rule.version})`}
                      </LinkLookup>
                    ) : (
                      <LinkLookup
                        routeName="campaign-detail"
                        routeParams={{ campaignId: r.uuid }}
                        type="button"
                        className="pl-8 self-end underline text-base text-litlingo-primary-120 focus:outline-none"
                      >
                        {`${r.rule.name} (v${r.rule.version})`}
                      </LinkLookup>
                    )}
                  </td>
                  <td className="table-wrapper__new-td">
                    {
                      <div className="flex flex-col ">
                        {
                          // @ts-ignore
                          r.rule.campaign_rule_outcomes
                            .filter(
                              // @ts-ignore
                              (c, index, self) =>
                                // @ts-ignore
                                self.findIndex((item) => item.campaign.uuid === c.campaign.uuid) ===
                                index
                            )
                            // @ts-ignore
                            .map((c) => (
                              <div key={c.uuid}>
                                <LinkLookup
                                  routeName="campaign-detail"
                                  routeParams={{ campaignId: c.campaign.uuid }}
                                  type="button"
                                  className="self-end underline text-base text-litlingo-primary-120 focus:outline-none"
                                >
                                  {c.campaign.name}
                                </LinkLookup>
                              </div>
                            ))
                        }
                      </div>
                    }
                  </td>
                  <td className="table-wrapper__new-td" />
                  <td className="table-wrapper__new-td" />
                  <td className="table-wrapper__new-td" />
                </tr>
              ))
            }
          </>
        )
      }
    </>
  );
};

export default AnnotatorListRow;
