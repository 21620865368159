import { createAction } from '@reduxjs/toolkit';
import type { API, ErrorObject, Template, UUID } from 'types';

type FetchSingleTemplatePayload = {
  id: string;
};

export const fetchSingleTemplate =
  createAction<FetchSingleTemplatePayload>('TEMPLATES/SINGLE_FETCH');
export const fetchSingleTemplateRequest = createAction('TEMPLATES/SINGLE_FETCH_REQUEST');
export const fetchSingleTemplateFailure = createAction<ErrorObject>(
  'TEMPLATES/SINGLE_FETCH_FAILURE'
);
export const fetchSingleTemplateSuccess = createAction<Template>('TEMPLATES/SINGLE_FETCH_SUCCESS');

export const fetchAllTemplates = createAction('TEMPLATES/FETCH_ALL');

export type FetchTemplatesPayload = {
  name?: string;
  limit?: string;
};

export const fetchTemplates = createAction<FetchTemplatesPayload>('TEMPLATES/FETCH');
export const fetchTemplatesRequest = createAction('TEMPLATES/FETCH_REQUEST');
export const fetchTemplatesFailure = createAction<ErrorObject>('TEMPLATES/FETCH_FAILURE');
export const fetchTemplatesSuccess =
  createAction<API.WrappedAPIResponse<Template>>('TEMPLATES/FETCH_SUCCESS');

export const upsertTemplate = createAction<
  Pick<Template, 'name' | 'value'> & { uuid?: Template['uuid'] }
>('TEMPLATES/UPSERT');

export const upsertTemplateRequest = createAction('TEMPLATES/UPSERT_REQUEST');
export const upsertTemplateFailure = createAction<ErrorObject>('TEMPLATES/UPSERT_FAILURE');
export const upsertTemplateSuccess = createAction<Template>('TEMPLATES/UPSERT_SUCCESS');

export const deleteTemplate = createAction<{ id: UUID }>('TEMPLATES/DELETE');
export const deleteTemplateRequest = createAction('TEMPLATES/DELETE_REQUEST');
export const deleteTemplateSuccess = createAction<string>('TEMPLATES/DELETE_SUCCESS');
export const deleteTemplateFailure = createAction<ErrorObject>('TEMPLATES/DELETE_FAILURE');
