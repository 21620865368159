// eslint-disable-next-line max-classes-per-file
import type { AxiosResponse } from 'axios';

export class ClientError extends Error {
  constructor(message: string) {
    super(message);

    this.name = 'ClientError';
    this.message = message;
  }
}

export class AuthError extends ClientError {}

export class DialogClosedError extends AuthError {}

export class StorageError extends ClientError {}

export class LitlingoAxiosError extends Error {
  readonly response: AxiosResponse;

  readonly isAxiosError: boolean;

  constructor({
    message,
    response,
    isAxiosError,
  }: {
    message: string;
    response: AxiosResponse;
    isAxiosError: boolean;
  }) {
    super(message);
    this.response = response;
    this.isAxiosError = isAxiosError;
  }
}
