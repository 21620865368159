import { activityLogIcon, GRAY_SQUARE_ICON, VERTICAL_LINE_ICON } from 'constants/envelopeIcons';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { RenderedAction } from 'types';

type LogItemProps = {
  log: RenderedAction;
  index: number;
  length: number;
};
type IconKey = keyof typeof activityLogIcon;

const LogItem: React.FC<LogItemProps> = (props) => {
  const { log, index, length } = props;

  const icon = activityLogIcon[log.icon as IconKey] ?? GRAY_SQUARE_ICON;

  return (
    <div className="mr-3">
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center">
          <div className="w-5">{icon}</div>
          <div className="text-md py-1 ml-4">
            <ReactMarkdown>{log.title}</ReactMarkdown>
          </div>
        </div>
      </div>
      <div className="flex">
        {index + 1 < length && length > 1 ? (
          <div>{VERTICAL_LINE_ICON}</div>
        ) : (
          <div className="w-5" />
        )}
        <ReactMarkdown
          className={`text text-xs ml-4 overflow-hidden ${
            log.icon === 'comment' ? 'comment-log' : ''
          }`}
        >
          {log.description}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default LogItem;
