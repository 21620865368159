import config from 'config';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ChromeExtAuth: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.opener && window.opener.postMessage) {
      window.opener.postMessage(location.hash, config.extensions.chrome.domain);
    }
  }, [location.hash]);

  return null;
};

export default ChromeExtAuth;
