/* eslint-disable react/jsx-props-no-spreading */
import { fetchAnnotatorTypes } from 'actions';
import { createIdentifier } from 'actions/identifier';
import CustomField from 'components/CustomField';
import GlobalNavbar from 'components/Navbar/GlobalNavbar';
import PageHeader from 'components/PageHeader';
import PapaParse from 'papaparse';
import React, { useEffect, useRef } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAnnotatorTypes } from 'selectors/annotator';
import type { Annotator, AnnotatorTypes } from 'types';
import { useHistory } from 'utils/urls';

type RouteParams = {
  type: string;
};

const allowedImportCsvTypes = ['recipient', 'sender'];

const IdentifierName: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { type } = useParams<RouteParams>();

  const methods = useForm<Annotator>();

  const types = useSelector(getAnnotatorTypes);
  const typeObj = types[type];

  const fileInputEl = useRef<HTMLInputElement>(null);

  const [fileData, setFileData] = React.useState<Record<string, string>[] | null>([]);

  const formatedFileData = fileData?.reduce<Record<string, string[]>>((acc, item) => {
    Object.entries(item).forEach(([key, value]) => {
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(value);
    });
    return acc;
  }, {});

  useEffect(() => {
    dispatch(fetchAnnotatorTypes());
  }, [dispatch]);

  const onSubmit = async (values: Annotator): Promise<void> => {
    const options: AnnotatorTypes[string]['options'] = {};
    if (typeObj && typeObj.options && Object.keys(typeObj.options)) {
      (Object.keys(typeObj.options) as Array<keyof typeof typeObj.options>).forEach((key) => {
        // @ts-ignore
        let val = values[key];
        if (typeObj.options[key]?.type === 'float') {
          val = parseFloat(val);
        }

        if (Array.isArray(val)) {
          val = val.filter((el) => !/^\s*$/.test(el));
        }

        options[key] = val;
      });
    }

    dispatch(createIdentifier({ ...values, kind: type, options_config: options, redirect: true }));
  };

  const renderOptions = (): JSX.Element[] | null => {
    if (!typeObj || !typeObj.options || !Object.keys(typeObj.options)) return null;
    return (Object.keys(typeObj.options) as Array<keyof typeof typeObj.options>).map((key) => {
      const input = typeObj.options[key];

      const data = formatedFileData ? formatedFileData[key] : undefined;

      return (
        <CustomField
          key={key}
          inputKey={key}
          name={input?.name}
          type={input?.type as 'str' | 'float' | 'bool'}
          isList={input?.is_list}
          customElement={input?.ui_element}
          isRequired={input?.required}
          data={data}
        />
      );
    });
  };

  const handleFileClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation();

    if (fileInputEl.current != null) {
      fileInputEl.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const fileReader = new FileReader();
    if (e.target.files != null) {
      fileReader.readAsText(e.target.files[0], 'UTF-8');
      fileReader.onload = (event): void => {
        if (event.target != null && event.target.result != null) {
          const parsed = PapaParse.parse(event.target.result as string, {
            header: true,
            skipEmptyLines: true,
          });
          setFileData(parsed.data as Record<string, string>[]);
        }
      };
    }
  };

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <GlobalNavbar />
      <div className="py-10">
        <header>
          <div className="max-w-7xl mx-auto px-1 sm:px-12 lg:px-14">
            <div>
              <PageHeader />
              <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                  <h2 className="title">Name Your Identifier</h2>
                </div>
                <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
                  <span className="shadow-sm rounded-md">
                    <button
                      type="button"
                      data-testid="go-back-button"
                      onClick={(): void => history.goBack()}
                      className="inline-flex items-center px-4 py-2 border border-gray-300 text rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                    >
                      Back
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main>
          <div className="mt-20 mb-20 max-w-7xl mx-auto px-4 sm:px-12 lg:px-14 text-left">
            <div className="mx-auto">
              <div className="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      General Information
                    </h3>
                    <p className="mt-1 text">
                      This is mostly used to identify your identifier later
                    </p>
                  </div>
                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <FormContext {...methods}>
                      <form data-testid="form" onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-2">
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium leading-5 text-gray-700"
                            >
                              Identifier Name
                              <input
                                id="name"
                                data-testid="annotator-name-input"
                                name="name"
                                ref={methods.register({
                                  required: 'Required',
                                })}
                                className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                              />
                              {methods.errors.name && methods.errors.name.message}
                            </label>
                          </div>

                          <div className="col-span-6 sm:col-span-4">
                            <label
                              htmlFor="description"
                              className="block text-sm font-medium leading-5 text-gray-700"
                            >
                              Identifier Description
                              <input
                                id="description"
                                data-testid="annotator-description-input"
                                name="description"
                                className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                ref={methods.register()}
                              />
                              {methods.errors.description && methods.errors.description.message}
                            </label>
                          </div>
                          <div className="col-span-6 sm:col-span-4">{renderOptions()}</div>
                        </div>

                        <div className="flex gap-2">
                          <button
                            data-testid="save-annotator-button"
                            type="submit"
                            className="mt-5 -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                          >
                            <svg
                              className="h-5 w-5 text-gray-400"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M12 4v16m8-8H4" />
                            </svg>
                            <span className="ml-2">Save</span>
                          </button>
                          {allowedImportCsvTypes.includes(type) && (
                            <span className="rounded-md self-end">
                              <input
                                ref={fileInputEl}
                                type="file"
                                accept=".csv"
                                onChange={handleFileChange}
                                style={{ position: 'fixed', top: '-100em' }}
                              />
                              <button
                                type="button"
                                onClick={handleFileClick}
                                className="flex flex-row justify-center button button--secondary-redesign font-bold h-8"
                                data-testid="import-button"
                              >
                                <span>Import</span>
                              </button>
                            </span>
                          )}
                        </div>
                      </form>
                    </FormContext>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default IdentifierName;
