import React, { Fragment, useState } from 'react';
import type { AnnotatedNodeT, AnnotationsMapItem, EllipsisArr } from '../types';
import { nodeNeedsSpaceBefore } from '../utils/spacing';
import AnnotatedNode from './AnnotatedNode';

type AnnotatedSuperNodeProps = {
  annotations?: AnnotationsMapItem[];
  nodes: AnnotatedNodeT[];
  ellipsisArr: EllipsisArr;
  first: boolean;
  last: boolean;
};

const AnnotatedSuperNode: React.FC<AnnotatedSuperNodeProps> = (props) => {
  const { annotations, nodes, ellipsisArr, first, last } = props;

  const [width, setWidth] = useState<number | null>(null);
  const [hovering, setHovering] = useState(false);

  const nodesRef = (ref: HTMLDivElement | null): void => {
    if (ref == null) {
      return;
    }

    setWidth(ref.getBoundingClientRect().width);
  };

  const handleMouseEnter = (): void => setHovering(true);
  const handleMouseLeave = (): void => setHovering(false);

  const annotationsStyle: React.CSSProperties = {};
  if (width != null) {
    annotationsStyle.minWidth = `${width}px`;

    if (!hovering) {
      annotationsStyle.width = `${width}px`;
    }
  }

  return (
    <>
      {ellipsisArr && ellipsisArr.left && (
        <span
          className={`bg-gray-200 px-2 litlingo__ellipsis_abridge ${
            first ? 'mr-4' : 'ml-3 mr-4'
          }`}
        >
          &#8943;
        </span>
      )}
      <div
        className={`relative pt-6 text-left ${hovering ? 'z-50' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {annotations && (
          <div
            style={annotationsStyle}
            className={`absolute bg-gray-300 border-gray-700 p-1 rounded-t transform -translate-y-full text-center mr-1 ${
              hovering ? 'shadow rounded-br' : ''
            }`}
          >
            <div className="bg-white px-1 rounded truncate uppercase litlingo__annotated_super_node__text_xs leading-tight">
              {annotations.map((annotator) => (
                <span key={annotator.name}>{annotator.name}</span>
              ))}
            </div>
          </div>
        )}

        <div
          ref={nodesRef}
          className={
            annotations
              ? `relative flex flex-row bg-gray-300 pt-0 text-2xl ${
                  hovering ? 'shadow' : ''
                }`
              : `text-2xl`
          }
        >
          {nodes.map((node, idx) => (
            <Fragment key={node.index}>
              {nodeNeedsSpaceBefore(nodes, idx) ? (
                <div className="relative pb-2 text-left">
                  <div className="text-sm border-b-2 leading-tight border-gray-700">
                    &nbsp;
                  </div>

                  <div className="leading-tight absolute bg-white pl-px litlingo__annotated_super_node__text_xs truncate uppercase">
                    &nbsp;
                  </div>
                </div>
              ) : null}
              <AnnotatedNode
                pos={node.pos}
                showPos={annotations != null}
                text={node.text}
                isPerson={node.entity === 'PERSON'}
              />
            </Fragment>
          ))}
        </div>
      </div>
      {ellipsisArr && ellipsisArr.right && last && (
        <span className="bg-gray-200 px-2 litlingo__ellipsis_abridge ml-4">
          &#8943;
        </span>
      )}
    </>
  );
};

export default AnnotatedSuperNode;
