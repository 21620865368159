/* eslint-disable max-lines */
/* eslint-disable camelcase */

import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const FieldSkeleton: React.FC<{ width: number; height: number }> = ({ width, height }) => (
  <SkeletonTheme color="#D6D6D6" highlightColor="#DCDCDC">
    <Skeleton width={width} height={height} style={{ borderRadius: 0 }} />
  </SkeletonTheme>
);

const ValueSkeleton: React.FC<{ width: number; height: number }> = ({ width, height }) => (
  <SkeletonTheme color="#EBEBEB" highlightColor="#EFEFEF">
    <Skeleton width={width} height={height} style={{ borderRadius: 0 }} />
  </SkeletonTheme>
);

const EnvelopeSkeleton: React.FC = () => (
  <div className="px-6 my-12 flex flex-col gap-10">
    <div className="flex flex-col">
      <div className="flex flex-row gap-2">
        <FieldSkeleton width={50} height={24} />
        <ValueSkeleton width={230} height={24} />
      </div>
      <div className="flex flex-row gap-2">
        <FieldSkeleton width={29} height={24} />
        <ValueSkeleton width={450} height={24} />
      </div>
      <div className="flex flex-row gap-2">
        <FieldSkeleton width={37} height={24} />
        <ValueSkeleton width={160} height={24} />
      </div>
      <div className="flex flex-row gap-2">
        <FieldSkeleton width={37} height={24} />
        <ValueSkeleton width={43} height={24} />
      </div>
      <div className="flex flex-row gap-2">
        <FieldSkeleton width={46} height={24} />
        <ValueSkeleton width={96} height={24} />
      </div>
      <div className="flex flex-row gap-2">
        <FieldSkeleton width={88} height={24} />
        <ValueSkeleton width={282} height={24} />
      </div>
    </div>
    <div className="flex flex-col gap-1">
      <ValueSkeleton width={650} height={24} />
      <ValueSkeleton width={500} height={24} />
      <ValueSkeleton width={580} height={24} />
      <ValueSkeleton width={550} height={24} />
      <ValueSkeleton width={600} height={24} />
    </div>
    <div className="flex flex-col gap-1">
      <ValueSkeleton width={500} height={24} />
      <ValueSkeleton width={680} height={24} />
      <ValueSkeleton width={580} height={24} />
      <ValueSkeleton width={570} height={24} />
      <ValueSkeleton width={600} height={24} />
    </div>
  </div>
);

export default EnvelopeSkeleton;
