import { upsertIdentity } from 'actions';
import ToggleBoolean from 'components/ToggleButton';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import type { Identity } from 'types';

type ComponentProps = {
  editIdentity: Identity;
  setIsCreateNewIdentityOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateIdentity: React.FC<ComponentProps> = ({ editIdentity, setIsCreateNewIdentityOpen }) => {
  const dispatch = useDispatch();
  const { register, getValues, setValue } = useForm<Identity>();
  const [emailVerified, setEmailVerified] = useState<boolean>(false);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const values = getValues() as unknown as Identity;
    const newIdentityData = {
      ...values,
      email_verified: emailVerified,
    };
    const dataToSave = editIdentity ? { ...editIdentity, ...newIdentityData } : newIdentityData;
    dispatch(upsertIdentity(dataToSave));
    setIsCreateNewIdentityOpen(false);
  };

  useEffect(() => {
    if (!editIdentity) {
      setValue('email', '');
      return;
    }
    setValue('email', editIdentity.email);
    setEmailVerified(editIdentity.email_verified);
  }, [editIdentity, setValue]);

  return (
    <div className="flex mt-10 text-left">
      <form className="w-7/12 mr-20" onSubmit={onSubmit}>
        <div>
          <div className="sm:col-span-6 mt-6">
            <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
              Email
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="email"
                  name="email"
                  ref={register()}
                  className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </label>
          </div>
          <div className="col-span-6 sm:col-span-4 mt-6 flex">
            <p className="mr-4">Email Verified</p>
            <ToggleBoolean
              isOn={!!emailVerified}
              handleToggleClick={(): void => setEmailVerified(!emailVerified)}
            />
          </div>
        </div>
        <button
          data-testid="create-user-button"
          type="submit"
          className="mt-5 -ml-px button button--primary"
        >
          <svg
            className="h-5 w-5 text-white"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M12 4v16m8-8H4" />
          </svg>
          <span className="ml-2">Save</span>
        </button>
      </form>
    </div>
  );
};

export default CreateIdentity;
