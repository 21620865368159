/* eslint-disable max-lines */
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import React from 'react';
import { getRulesCompareLoading } from 'selectors/rulesCompare';
import { useSelector } from 'store';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import RulesCompareContent from './RulesCompareContent';
import RulesCompareHeader from './RulesCompareHeader';

const RulesCompare: React.FC = () => {
  const loading = useSelector(getRulesCompareLoading);

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  if (loading) return <LoadingIndicator className="m-auto mt-20" size="20" />;

  return (
    <div
      className="flex flex-col min-w-screen bg-white overflow-hidden "
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />

      <div
        className="flex flex-col z-0"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <RulesCompareHeader />
        <RulesCompareContent />
      </div>
    </div>
  );
};

export default RulesCompare;
