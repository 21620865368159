import { fetchAllEventsCount } from 'actions';
import LinkLookup from 'components/LinkLookup';
import withWidget from 'decorators/widget';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getPermissionsPolicy } from 'selectors/permissions';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';
import { isActionAuthorized } from 'utils/permissions';
import { useTimeRange } from 'utils/timeRanges';

const ReviewButtonWindget: React.FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);

  const range = useTimeRange();

  const buttonClass = 'focus:outline-none hover:outline-none hover:underline text-white';

  useEffect(() => {
    dispatch(fetchAllEventsCount(range));
  }, [dispatch, range]);

  const renderLinkBody = (
    <div className="flex flex-row items-center justify-center">
      <div className="text-xl font-bold text--white">Continue Review</div>
    </div>
  );

  return (
    <div className="border-transparent w-full">
      {isActionAuthorized('events.list', user.roles, policy) ? (
        <LinkLookup
          className={buttonClass}
          routeName="envelope-list"
          queryParams={{
            envelopes__review_values: 'pending',
            envelopes__created_after: moment
              .utc(new Date())
              .startOf('week')
              .subtract(1, 'week')
              .format(),
            envelopes__created_before: moment
              .utc(new Date())
              .endOf('week')
              .subtract(1, 'week')
              .format(),
          }}
          onClick={(): Promise<void> => logEvent('Dashboard-continue-review')}
        >
          {renderLinkBody}
        </LinkLookup>
      ) : (
        <button
          className={`${buttonClass} pointer-events-none cursor-default no-underline`}
          type="button"
        >
          {renderLinkBody}
        </button>
      )}
    </div>
  );
};

export default withWidget({
  minWidth: 5,
  minHeight: 2,
  bgClassColor: 'bg-litlingo-primary',
  containerClassName: 'h-4/5 rounded',
})(ReviewButtonWindget);
