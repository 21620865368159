import CollapsableCard from 'components/CollapsableCard';
import CustomField from 'components/CustomField';
import React from 'react';
import { getFetchAnnotatorsTypesLoading } from 'selectors/annotator';
import { useSelector } from 'store';
import type { AnnotatorTypes, NormalizedAnnotator } from 'types';

type ComponentProps = {
  annotator: NormalizedAnnotator;
  types: AnnotatorTypes;
};
const RenderOptionsConfig: React.FC<ComponentProps> = ({ annotator, types }) => {
  const loadingTypes = useSelector(getFetchAnnotatorsTypesLoading);

  const renderOptions = (): JSX.Element[] | null => {
    if (
      !annotator ||
      !annotator.options_config ||
      !Object.keys(annotator.options_config) ||
      loadingTypes ||
      Object.keys(types).length === 0
    )
      return null;

    return Object.entries(types[annotator.type].options).map(([key, value]) => {
      if (value == null) {
        throw new Error(`Undefined options for type ${key}`);
      }

      return (
        <CustomField
          key={value.name}
          inputKey={key}
          name={value.name}
          type={value.type as 'str' | 'float' | 'bool'}
          customElement={value.ui_element}
          isList={value.is_list}
          isRequired={value.required}
        />
      );
    });
  };

  const renderSaveConfig = (
    <button
      id="save-annotator-setup-button"
      onClick={(e): void => e.stopPropagation()}
      type="submit"
      className="button button--primary h-8"
    >
      Save
    </button>
  );

  return (
    <CollapsableCard headerTitle="Options" customAction={renderSaveConfig}>
      <div className="p-4">{renderOptions()}</div>
    </CollapsableCard>
  );
};

export default RenderOptionsConfig;
