import {
  PAGINATION_FIRST,
  PAGINATION_LAST,
  PAGINATION_NEXT,
  PAGINATION_PREV,
} from 'constants/commonIcons';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';
import React from 'react';
import { useSelector } from 'react-redux';
import type { Selector } from 'types';

type ComponentProps = {
  resourceName: string;

  paginationParams: {
    offset: number;
    limit: number;
  };
  setPaginationParams: React.Dispatch<
    React.SetStateAction<{
      offset: number;
      limit: number;
    }>
  >;
  totalCount: number;
  getResourceLoading: Selector<boolean, []>;
  firstLastPage?: boolean;
};

const LocalListFooter: React.FC<ComponentProps> = ({
  paginationParams,
  resourceName,
  setPaginationParams,
  totalCount,
  getResourceLoading,
  firstLastPage = false,
}) => {
  const loading = useSelector(getResourceLoading);

  const hasPrevious = paginationParams.offset !== 0 && !loading;
  const hasNext = paginationParams.offset + paginationParams.limit < totalCount && !loading;

  const handlePreviousClick = (): void => {
    const { offset, limit } = paginationParams;
    setPaginationParams({ offset: offset - limit >= 0 ? offset - limit : 0, limit });
  };

  const handleNextClick = (): void => {
    const { offset, limit } = paginationParams;
    setPaginationParams({ offset: offset + limit, limit });
  };

  const handleFirstClick = (): void => {
    const { limit } = paginationParams;
    setPaginationParams({ offset: 0, limit });
  };

  const handleLastClick = (): void => {
    const { limit } = paginationParams;
    setPaginationParams({ offset: totalCount - limit, limit });
  };

  return (
    <nav className="flex justify-center items-center h-9 bg-white border-llitlingo-gray-1">
      <div className="flex items-center justify-center gap-2">
        {firstLastPage && (
          <button
            data-testid="first-button"
            className={`flex justify-center items-center h-5 w-5 focus:outline-none ${
              !hasPrevious ? 'text-litlingo-gray-2' : 'text-litlingo-gray-5'
            }`}
            type="button"
            disabled={!hasPrevious}
            onClick={handleFirstClick}
          >
            {PAGINATION_FIRST}
          </button>
        )}
        <button
          data-testid="previous-button"
          className={`flex justify-center items-center h-5 w-5 focus:outline-none ${
            !hasPrevious ? 'text-litlingo-gray-2' : 'text-litlingo-gray-5'
          }`}
          type="button"
          disabled={!hasPrevious}
          onClick={handlePreviousClick}
        >
          {PAGINATION_PREV}
        </button>
        <span className="text-small">
          {loading ? (
            'Loading...'
          ) : (
            <>
              <span className="font-bold">
                {` ${totalCount === 0 ? 0 : paginationParams.offset + 1}-${
                  paginationParams.offset + paginationParams.limit < totalCount
                    ? paginationParams.offset + paginationParams.limit
                    : totalCount
                }`}
              </span>
              {` of ${totalCount > 10000 ? '+10,000' : totalCount}
                  ${pluralize(resourceName.split(' ').map(capitalize).join(' '), totalCount)}`}
            </>
          )}
        </span>
        <button
          data-testid="next-button"
          className={`flex justify-center items-center h-5 w-5 focus:outline-none ${
            !hasNext ? 'text-litlingo-gray-2' : 'text-litlingo-gray-5'
          }`}
          type="button"
          disabled={!hasNext}
          onClick={handleNextClick}
        >
          {PAGINATION_NEXT}
        </button>

        {firstLastPage && (
          <button
            data-testid="last-button"
            className={`flex justify-center items-center h-5 w-5 focus:outline-none ${
              !hasNext ? 'text-litlingo-gray-2' : 'text-litlingo-gray-5'
            }`}
            type="button"
            disabled={!hasNext}
            onClick={handleLastClick}
          >
            {PAGINATION_LAST}
          </button>
        )}
      </div>
    </nav>
  );
};

export default LocalListFooter;
