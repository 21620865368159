import React from 'react';

type WidgetEmptyStateProps = {
  msg?: string;
};

const WidgetEmptyState: React.FC<WidgetEmptyStateProps> = (props) => {
  const { msg = 'No data to show' } = props;

  return (
    <div className="flex justify-center items-center h-full w-full">
      <span className="text text--extra-large">{msg}</span>
    </div>
  );
};

export default WidgetEmptyState;
