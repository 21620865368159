import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useIsFirstRender = (): boolean => {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
};

export const useUpdateEffect = (effect: EffectCallback, deps?: DependencyList): void => {
  const isFirst = useIsFirstRender();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isFirst) {
      return effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
