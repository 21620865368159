import { fetchOutcomes } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import SearchInput from 'components/SearchInput';
import { MODAL_BACKGROUND_REDESIGN } from 'constants/common';
import { CLOSE_MODAL_ICON } from 'constants/commonIcons';
import React, { useEffect, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { useDispatch, useSelector } from 'react-redux';
import { getOutcomes } from 'selectors/outcomes';

type ItemType = {
  uuid: string;
  name: string;
};

type ComponentProps = {
  toggleModalOpen: () => void;
  onSelect: (outcome: ItemType) => void;
  onBackButtonClick?: () => void;
};

const SelectOutcomeModal: React.FC<ComponentProps> = ({
  toggleModalOpen,
  onSelect,
  onBackButtonClick,
}) => {
  const dispatch = useDispatch();
  const outcomes = useSelector(getOutcomes);
  // const loading = useSelector(getFetchOutcomesLoading);
  const loading = false;
  const [search, setSearch] = useState('');

  const backButton = !!onBackButtonClick;

  useEffect(() => {
    dispatch(
      fetchOutcomes({
        searchTerm: { name: search },
      })
    );
  }, [dispatch, search]);

  const handleSearchChange = (value: string): void => {
    setSearch(value);
  };

  const selectOutcome = (item: ItemType): void => {
    onSelect(item);
  };

  const outcomesListComponent = (item: ItemType): JSX.Element => (
    <button
      key={item.uuid}
      type="button"
      onClick={(e): void => {
        e.preventDefault();
        selectOutcome(item);
      }}
      className="flex flex-row justify-between items-stretch w-full pl-6 pr-4 text-body text-litlingo-gray-3 border-b  border-litlingo-gray-1  focus:outline-none bg-white hover:bg-litlingo-gray-1"
    >
      <span className="py-1.5 font-bold text-litlingo-gray-5">{item.name}</span>
    </button>
  );

  const outcomesEmptyListComponent = (): JSX.Element => (
    <div className="flex flex-col items-center text-center gap-2 pt-10 h-48">
      <h2 className="text-heading-3">Outcome not found</h2>
      <span>
        Suggestions: Make sure all words are spelled correctly or try a more general search.
      </span>
    </div>
  );

  const outcomesListBody = (
    <>
      <div className="absolute top-0 left-0 right-0 h-22 z-0">{MODAL_BACKGROUND_REDESIGN}</div>
      <div className="flex flex-row justify-end pr-6 pt-6">
        <button
          type="button"
          data-testid="close-button"
          className="w-6 z-10 focus:outline-none "
          aria-label="Close"
          onClick={toggleModalOpen}
        >
          {CLOSE_MODAL_ICON}
        </button>
      </div>

      <div className="flex flex-col mt-14 overflow-hidden transition-all duration-300 border-b border-litlingo-gray-1">
        <>
          <div className="px-6">
            <div className="flex flex-row gap-2 items-end">
              <h2 className="text-heading-1">Select a outcome</h2>
            </div>
            <div className="pt-4">
              <SearchInput
                placeholder="Search workspace"
                className="w-full"
                handleSearch={handleSearchChange}
                debounceDelay={300}
              />
            </div>
          </div>

          <div className="border-b border-litlingo-gray-2 mt-2" />
          <LoadingOverlayWrapper active={loading} spinner={<LoadingIndicator />} fadeSpeed={0}>
            {outcomes.length ? (
              <div className="flex flex-col w-full custom-scrollbar overflow-auto h-48">
                {outcomes.map((outcome) => outcomesListComponent(outcome))}
              </div>
            ) : (
              outcomesEmptyListComponent()
            )}
          </LoadingOverlayWrapper>
        </>
      </div>
    </>
  );

  return (
    <Modal
      body={outcomesListBody}
      title=" "
      cancelButton={false}
      toggleShowModal={toggleModalOpen}
      xButton={false}
      style={{
        width: '546px',
        minHeight: '415px',
        maxHeight: '415px',
        padding: '0px',
      }}
      secondaryButton={backButton}
      secondaryButtonText="< Back"
      secondaryButtonStyle="text-body pt-1 pl-6 underline focus:outline-none"
      secondaryButtonOnclick={onBackButtonClick}
    />
  );
};

export default SelectOutcomeModal;
