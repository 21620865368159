/* eslint-disable max-lines */
import { addBulkTestCaseTags, bulkDeleteTestCases, bulkDuplicateTestCases } from 'actions';
import classNames from 'classnames';
import TagBadge from 'components/EnvelopeList/FilterPills/TagBadge';
import Permissions from 'components/Permissions';
import TestCaseMultiTags from 'components/TestCase/TestCaseMultiTags';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTagsForFilters } from 'selectors/tags';
import {
  bulkDeleteTestCasesLoading,
  bulkDuplicateTestCasesLoading,
  getTestCases,
  getTestCasesTotalCount,
} from 'selectors/testCases';
import { useHistory } from 'utils/urls';

type ComponentProps = {
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
};

const TestCaseListActionsRow: React.FC<ComponentProps> = ({ selectedRows, setSelectedRows }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [tagsSelected, setTagsSelected] = useState<string[]>([]);
  const allTags = useSelector(getTagsForFilters);

  const testCases = useSelector(getTestCases);
  const totalCount = useSelector(getTestCasesTotalCount);

  const deleteBulkLoading = useSelector(bulkDeleteTestCasesLoading);
  const duplicateBulkLoading = useSelector(bulkDuplicateTestCasesLoading);
  const disableButtons = deleteBulkLoading || duplicateBulkLoading;

  useEffect(() => {
    if (selectedRows.length === 0) {
      setTagsSelected([]);
    }
  }, [selectedRows.length]);

  const handleRemoveTag = (tag: string): void => {
    const newTags = tagsSelected.filter((t) => t !== tag);
    setTagsSelected(newTags);
  };

  const handleApplyChanges = (): void => {
    if (selectedRows.length) {
      tagsSelected.forEach((tag) => {
        const item = allTags.find((t) => t.uuid === tag);
        dispatch(
          addBulkTestCaseTags({
            uuids: selectedRows,
            value: item ? item.value : tag,
          })
        );
      });
    }
    setTimeout(() => {
      setTagsSelected([]);
      setSelectedRows([]);
    }, 1000);
  };

  const handleBulkDeleteClick = (): void => {
    dispatch(bulkDeleteTestCases({ uuids: selectedRows }));
  };

  const handleDuplicateClick = (): void => {
    dispatch(bulkDuplicateTestCases({ uuids: selectedRows }));
  };

  const testCasesCount =
    testCases.length === selectedRows.length ? totalCount : selectedRows.length;

  return (
    <tr className="bg-litlingo-gray-2">
      <th
        colSpan={!history.location.pathname.includes('test-cases') ? 10 : 9}
        className="table-wrapper__new-td table-wrapper__bulk-area bg-litlingo-gray-2 "
      >
        <div className="table-wrapper__new-cell-content flex flex-row justify-between items-start gap-3 py-2 m-0 h-auto overflow-visible">
          <div className="flex flex-row justify-between items-start gap-2 ml-6 h-auto overflow-visible">
            <button
              type="button"
              disabled={disableButtons}
              onClick={handleDuplicateClick}
              className={classNames('button button--secondary h-8 ', {
                'disabled-button': disableButtons,
              })}
            >
              <span className="text-litlingo-gray-600 font-bold">Clone</span>
            </button>

            <Permissions action="test_cases.tags">
              <TestCaseMultiTags setTagsSelected={setTagsSelected} tagsSelected={tagsSelected} />
            </Permissions>
            <div className="flex flex-row flex-grow-0 flex-wrap gap-1 mb-0.5 self-end">
              {tagsSelected.map((tag) => (
                <React.Fragment key={`${tag}-tag`}>
                  <TagBadge
                    title={tag}
                    deleteAction={(): void => handleRemoveTag(tag)}
                    customLeftIcon={<span>+</span>}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>

          <div className="flex flex-row">
            <div className="text-body whitespace-no-wrap self-end">{`${testCasesCount.toLocaleString()} selected`}</div>
            <button
              onClick={(): void => handleApplyChanges()}
              type="submit"
              className="flex justify-center self-end w-32 h-8 box-border py-1.5 ml-2 button button--secondary border-litlingo-gray-100 text-body focus:outline-none"
              data-testid="save-button"
            >
              <span className="text-litlingo-gray-600 font-bold">Apply</span>
            </button>

            <button
              type="button"
              disabled={disableButtons}
              onClick={handleBulkDeleteClick}
              className={classNames('button button--secondary flex justify-center w-32 h-8 ml-2', {
                'disabled-button': disableButtons,
              })}
              data-testid="bulk-delete-button"
            >
              <span className="text-litlingo-red-bg font-bold">Delete</span>
            </button>
          </div>
        </div>
      </th>
    </tr>
  );
};

export const MemoizedTestCaseListActionsRow = React.memo(TestCaseListActionsRow);

export default TestCaseListActionsRow;
