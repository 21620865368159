import Modal from 'components/Modal';
import React from 'react';
import { useHistory } from 'utils/urls';

type ComponentProps = {
  setUnsaved: React.Dispatch<React.SetStateAction<boolean>>;
  toggleShowModal: () => void;
};

const CustomerLeaveConfirmModal: React.FC<ComponentProps> = ({ setUnsaved, toggleShowModal }) => {
  const history = useHistory();
  const body = (
    <div className="text mt-5">
      <p>You have unsaved changes!</p>
    </div>
  );

  return (
    <Modal
      body={body}
      title="Are you sure?"
      okButton
      okButtonText="Exit without Save"
      cancelButtonText="Cancel"
      okButtonOnClick={(): void => {
        toggleShowModal();
        setUnsaved(false);
        history.goBack();
      }}
      toggleShowModal={toggleShowModal}
    />
  );
};

export default CustomerLeaveConfirmModal;
