import { TAG_REMOVE_ICON } from 'constants/filterIcons';
import React from 'react';

type ComponentProps = {
  title: string;
  deleteAction?: () => void;
  customLeftIcon?: JSX.Element | false;
  negated?: boolean;
};

const BadgeRedesign: React.FC<ComponentProps> = ({
  negated = false,
  title,
  deleteAction,
  customLeftIcon,
}) => (
  <div
    data-testid="container"
    className={`h-5 inline-block rounded ${
      negated ? 'bg-litlingo-alert-light' : 'bg-litlingo-success-light'
    }`}
  >
    <span data-testid="text" className="text-small flex my-0 px-1 py-0.5 items-center gap-1">
      {customLeftIcon && <span className="">{customLeftIcon}</span>}
      {title}
      {deleteAction && (
        <button
          data-testid="delete-button"
          onClick={deleteAction}
          className="focus:outline-none outline-none"
          type="button"
        >
          {TAG_REMOVE_ICON}
        </button>
      )}
    </span>
  </div>
);

export default BadgeRedesign;
