import {
  fetchAllRulesRequest,
  fetchSingleRuleRequest,
  fetchTestCaseSummaryRuleRequest,
} from 'actions/rule';
import { GlobalState } from 'reducers';
import { createSelector } from 'reselect';
import type { MRule, Rule, Selector, TestCasesSummary, UUID } from 'types';
import { getEnvelope } from './envelopes';

export const getFetchAllRulesLoading: Selector<boolean> = (state) =>
  state.rule.loading.includes(fetchAllRulesRequest.toString());

export const getFetchSingleRuleLoading: Selector<boolean> = (state) =>
  state.rule.loading.includes(fetchSingleRuleRequest.toString());

export const getRulesList: Selector<MRule[]> = createSelector(
  [(state: GlobalState): GlobalState['rule']['rules'] => state.rule.rules],
  (rules) => Object.values(rules)
);

export const getRulesForFilterPills: Selector<Rule[]> = createSelector(
  [(state: GlobalState): GlobalState['rule']['filterItems'] => state.rule.filterItems],
  (filterItems) => Object.keys(filterItems).map((uuid) => filterItems[uuid])
);

export const getRulesTotalCount: Selector<number> = (state) => state.rule.count;

export const getRule =
  (id: UUID): Selector<MRule> =>
  (state): MRule =>
    state.rule.rules[id];

export const getRuleUuidsFromEnvelope =
  (envelopeId: UUID): Selector<string[] | null> =>
  (state): string[] | null => {
    const { events } = getEnvelope(state, envelopeId);
    if (!events) {
      return null;
    }

    return events.map(({ rule_uuid: uuid }) => uuid);
  };

export const getRuleTestCaseSummary: Selector<Omit<TestCasesSummary, 'false_positives'>, [UUID]> = (
  state,
  ruleId
) =>
  state.rule.rules[ruleId]?.summaryTestCases ?? {
    failing: 0,
    passing: 0,
    false_negatives: 0,
    not_run: 0,
    true_negatives: 0,
    true_positives: 0,
  };

export const getRuleTestCaseSummaryLoading: Selector<boolean> = (state) =>
  state.rule.loading.includes(fetchTestCaseSummaryRuleRequest.toString());

export const getShowRuleHighlights: Selector<boolean> = (state) => state.rule.showHighlights;
