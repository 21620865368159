import {
  fetchAllCampaignRuleOutcomes,
  fetchAllRulesWithSummary,
  fetchSingleOutcome,
  fetchSingleRule,
} from 'actions';
import { fetchRulesForCompare } from 'actions/rulesCompare';
import Outcome from 'components/Outcome';
import OutcomeManager from 'components/OutcomeManager';
import RuleManager from 'components/RuleManager';
import RulesCompare from 'components/RulesCompare';
import RulesList from 'components/RulesList';
import { RouteSpec, SuperRouteSpec } from 'types';

const ruleRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'rule-list',
    path: '/:customerDomain/rules',
    component: RulesList,
    private: true,
    permissions: 'rules.list',
    data: {
      actions: [fetchAllRulesWithSummary],
      params: {
        order_by: {
          defaultValue: 'created_at',
        },
        relationships: {
          defaultValue: ['updated_by'],
          list: true,
        },
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        rules: {
          nestedResource: true,
          params: {
            relationships: {
              defaultValue: ['updated_by', 'created_by  '],
              list: true,
            },
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            include_pii: {
              defaultValue: 'true',
            },
            name: {},
            order_by: {
              defaultValue: 'created_at',
              options: ['created_at', 'name', 'created_by_uuid', 'updated_at'],
            },
            order_desc: {
              defaultValue: 'true',
            },
          },
        },
      },
    },
  },
  {
    name: 'rule-manager-campaign',
    path: '/:customerDomain/rule/:ruleId/campaign/:campaignId',
    exact: true,
    component: RuleManager,
    private: true,
    permissions: 'rules.retrieve',
    data: {
      idFromPath: true,
      actions: [fetchSingleRule],
      params: {
        body: {},
        include_count: {
          defaultValue: '1',
        },
        order_by: {
          defaultValue: 'created_at',
        },
        order_desc: {
          defaultValue: 'true',
        },
        annotators: {
          nestedResource: true,
          params: {
            broad_search: {},
          },
        },
        highlighted_rule: {},
        test_cases: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            broad_search: {},
            order_desc: {
              defaultValue: 'true',
            },
            order_by: {
              defaultValue: 'created_at',
              options: [
                'created_at',
                'created_by_uuid',
                'test_string',
                'should_match',
                'most_recent_run_passed',
              ],
            },
            match_criterias: {
              list: true,
            },
          },
        },
        audit_logs: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '10',
            },
            offset: {
              defaultValue: '0',
            },
            relationships: {
              defaultValue: ['updated_by'],
            },
            include_count: {
              defaultValue: '1',
            },
            order_by: {
              defaultValue: 'updated_at',
            },
            order_desc: {
              defaultValue: 'true',
            },
          },
        },
      },
    },
  },
  {
    name: 'rule-manager',
    path: '/:customerDomain/rule/:ruleId',
    component: RuleManager,
    exact: true,
    private: true,
    permissions: 'rules.retrieve',
    data: {
      idFromPath: true,
      actions: [fetchSingleRule],
      params: {
        body: {},
        order_by: {
          defaultValue: 'created_at',
        },
        include_count: {
          defaultValue: '1',
        },
        order_desc: {
          defaultValue: 'true',
        },
        highlighted_rule: {},
        annotators: {
          nestedResource: true,
          params: {
            broad_search: {},
          },
        },
        test_cases: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            broad_search: {},
            order_desc: {
              defaultValue: 'true',
            },
            order_by: {
              defaultValue: 'created_at',
              options: [
                'created_at',
                'created_by_uuid',
                'test_string',
                'should_match',
                'most_recent_run_passed',
              ],
            },
            match_criterias: {
              list: true,
            },
          },
        },
        audit_logs: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '10',
            },
            offset: {
              defaultValue: '0',
            },
            relationships: {
              defaultValue: ['updated_by'],
            },
            include_count: {
              defaultValue: '1',
            },
            order_by: {
              defaultValue: 'updated_at',
            },
            order_desc: {
              defaultValue: 'true',
            },
          },
        },
      },
    },
  },

  {
    name: 'rules-compare',
    path: '/:customerDomain/rules-compare/customer/:customerOneId/rule/:ruleOneId/customer/:customerTwoId/rule/:ruleTwoId',
    exact: true,
    component: RulesCompare,
    private: true,
    permissions: 'rules.compare',
    data: {
      idFromPath: true,
      actions: [fetchRulesForCompare],
    },
  },

  {
    name: 'rule-campaign-outcome-id',
    path: '/:customerDomain/rule/:ruleId?/campaign/:campaignId?/outcome/:outcomeId',
    component: Outcome,
    private: true,
    permissions: 'outcomes.retrieve',
    data: {
      idFromPath: true,
      actions: [fetchSingleOutcome],
    },
  },
  {
    name: 'rule-campaign-outcome',
    path: '/:customerDomain/rule/:ruleId?/campaign/:campaignId?/outcome',
    component: OutcomeManager,
    exact: true,
    private: true,
    permissions: 'outcomes.list',
    data: {
      idFromPath: true,
      actions: [fetchAllCampaignRuleOutcomes],
    },
  },
  {
    path: '/rules',
    component: (): null => null,
    private: true,
  },
];

export default ruleRoutes;
