import EmptyTableState from 'components/EmptyTableState';
import LoadingIndicator from 'components/LoadingIndicator';

import FilterListHeaderRedesign from 'components/Filters/FilterListHeaderRedesign';
import ListFooter from 'components/ListFooter';
import RuleGroupListItem from 'components/RuleGroupsList/RuleGroupListItem';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import {
  getFetchAllRuleGroupsLoading,
  getRuleGroupsList,
  getRuleGroupsTotalCount,
} from 'selectors/ruleGroups';
import type { MRuleGroup } from 'types';

type ComponentProps = {
  rules: MRuleGroup[];
  loading?: boolean;
};

const RuleGroupsTable: React.FC<ComponentProps> = ({ rules, loading }) => (
  <LoadingOverlay
    active={loading}
    spinner={<LoadingIndicator size="10" />}
    fadeSpeed={0}
    styles={{
      content: (base) => ({
        ...base,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }),
      wrapper: () => ({}),
      spinner: () => ({}),
      overlay: (base) => ({
        ...base,
        zIndex: 10,
      }),
    }}
  >
    <div className="table-wrapper__new">
      <table className="table-wrapper__table">
        <thead>
          <tr>
            <FilterListHeaderRedesign
              resource={resourceQueryParamName.ruleGroups}
              text="Model"
              field="name"
              className="pl-8"
            />
            <FilterListHeaderRedesign
              resource={resourceQueryParamName.ruleGroups}
              text="Last Modified"
              field="updated_at"
              className="table-wrapper__new-th table-wrapper__th--w-15"
            />
            <th className="table-wrapper__new-th table-wrapper__th--w-20">Modified By</th>
            <th className="table-wrapper__new-th table-wrapper__th--w-12">Test Cases</th>
            <th className="table-wrapper__new-th table-wrapper__th--w-12">Tests Results</th>
          </tr>
        </thead>
        <tbody className="table-wrapper__tbody" data-testid="rules-table">
          {!loading && rules?.length === 0 ? (
            <EmptyTableState message="There are no models to display" colCount="4" />
          ) : (
            rules.map((rule) => <RuleGroupListItem key={rule.uuid} ruleId={rule.uuid} />)
          )}
        </tbody>
      </table>

      <ListFooter
        resourceStateName={resourceQueryParamName.ruleGroups}
        resourceName="model"
        getResourceList={getRuleGroupsList}
        getResourceTotalCount={getRuleGroupsTotalCount}
        getResourceLoading={getFetchAllRuleGroupsLoading}
      />
    </div>
  </LoadingOverlay>
);

export default RuleGroupsTable;
