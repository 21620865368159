import '@litlingo/react/dist/styles.css';
import amplitude from 'amplitude-js';
import App from 'components/App';
import SentryFallbackError from 'components/SentryFallbackError';
import config from 'config';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-rangeslider/lib/index.css';
import { Provider } from 'react-redux';
import { store } from 'store';
import { ErrorBoundary } from 'utils/reports';
import './styles/style.css';

if (config.node.env === 'production') {
  amplitude.getInstance().init(config.analytics.value);
}

ReactDOM.render(
  <ErrorBoundary fallback={SentryFallbackError}>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);
