/* eslint-disable max-lines */
import { setURLParams } from 'actions';
import { fromAssignments, setEnvelopesSelected } from 'actions/envelopeListView';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useAddTreeToStore from 'hooks/envelopes/useAddTreeToStore';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getShowSkippedEnvelopes } from 'selectors/assignments';
import { getLowDataMode } from 'selectors/auth';
import { envelopeListContentState } from 'selectors/envelopeListView';
import { getEnvelopes } from 'selectors/envelopes';
import { getNavParamsByResourceMemo } from 'selectors/nav';
import { useSelector } from 'store';
import type { CommunicationEnvelopeSummary } from 'types';
import logEvent from 'utils/analytics';
import EnvelopeListCondensedTable from './EnvelopeListCondensedTable';
import EnvelopeListContentHeader from './EnvelopeListContentHeader';
import EnvelopeListTable from './EnvelopeListTable';

const EnvelopeListContent: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const navParams = useSelector((state) =>
    getNavParamsByResourceMemo(state, resourceQueryParamName.envelopes)
  );

  const filteredParams = Object.entries(navParams).filter(
    ([key]) => !['offset', 'limit', 'order_desc', 'order_by', 'has_events', 'matches'].includes(key)
  );

  const { totalCount, envelopesSelected, comingFromAssignments } =
    useSelector(envelopeListContentState);

  const offset = parseInt(navParams.offset as string, 10);

  const envelopes = useSelector(getEnvelopes);
  const showSkippedEnvelopes = useSelector(getShowSkippedEnvelopes);
  const lowDataMode = useSelector(getLowDataMode);

  const [selectedFormat, setSelectedFormat] = useState<'expanded' | 'contracted'>('expanded');

  const [isAtTop, setIsAtTop] = useState(true);
  const [scrolled, setScrolled] = useState(false);

  const [firstLoading, setFirstLoading] = useState(true);

  const [shiftPressed, setShiftPressed] = useState(false);
  const [lastSelected, setLastSelected] = useState<string | null>(null);
  const [secondLastSelected, setSecondLastSelected] = useState<string | null>(null);

  const [allSelected, setAllSelected] = useState(false);

  useAddTreeToStore(navParams.filters_search as string);

  const contentContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (allSelected && envelopesSelected.length < envelopes.length && envelopes.length > 0) {
      const notSelected = envelopes
        .filter((e) => !envelopesSelected.includes(e.envelope.uuid))
        .map((e) => e.envelope.uuid);
      dispatch(setEnvelopesSelected(envelopesSelected?.concat(notSelected)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, envelopes, allSelected]);

  useEffect(() => {
    if (envelopesSelected.length > 0 && envelopesSelected.length === envelopes.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [envelopes, envelopesSelected]);

  const handleCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: CommunicationEnvelopeSummary
  ): void => {
    e.stopPropagation();
    if (e.target.checked) {
      if (shiftPressed && lastSelected) {
        const idx = envelopes.findIndex((env) => env.envelope.uuid === item.envelope.uuid);
        const lastIdx = envelopes.findIndex((env) => env.envelope.uuid === lastSelected);

        const first = idx > lastIdx ? lastIdx : idx;
        const second = idx < lastIdx ? lastIdx : idx;

        dispatch(
          setEnvelopesSelected(
            envelopesSelected?.concat(
              envelopes.slice(first, second + 1).map((i) => i.envelope.uuid)
            )
          )
        );

        setSecondLastSelected(item.envelope.uuid);
      } else {
        setLastSelected(item.envelope?.uuid);
        setSecondLastSelected(null);
        dispatch(setEnvelopesSelected(envelopesSelected?.concat(item.envelope?.uuid)));
      }
    } else if (shiftPressed && lastSelected) {
      const idx = envelopes.findIndex((env) => env.envelope.uuid === item.envelope.uuid);
      const lastIdx = envelopes.findIndex((env) => env.envelope.uuid === lastSelected);
      const secondLastIdx = envelopes.findIndex((env) => env.envelope.uuid === secondLastSelected);

      let direction = 0;
      if (lastIdx > idx) {
        direction = -1;
      } else if (lastIdx < idx) {
        direction = 1;
      } else {
        direction = lastIdx > secondLastIdx ? -1 : 1;
      }

      const envelopesToUncheck: string[] = [];

      for (let i = lastIdx; i < envelopes.length && i >= 0; i += direction) {
        if ((direction > 0 && i >= idx) || (direction < 0 && i <= idx)) {
          if (envelopesSelected.includes(envelopes[i].envelope.uuid)) {
            envelopesToUncheck.push(envelopes[i].envelope.uuid);
          } else {
            break;
          }
        }
      }
      dispatch(
        setEnvelopesSelected(
          envelopesSelected?.filter((element) => !envelopesToUncheck.includes(element))
        )
      );
    } else {
      dispatch(
        setEnvelopesSelected(
          envelopesSelected?.filter((element) => element !== item.envelope?.uuid)
        )
      );
    }
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    logEvent('envelopes-list-select-all');

    if (e.target.checked) {
      const envelopesIds = envelopes.map((envelope) => envelope.envelope?.uuid);
      dispatch(setEnvelopesSelected(envelopesIds));
    } else {
      dispatch(setEnvelopesSelected([]));
    }
    setLastSelected(null);
    setSecondLastSelected(null);
  };

  const handleLoadMore = (): void => {
    if (envelopes.length > 0 && offset + 25 < totalCount) {
      let newOffset = offset + Math.min(25, totalCount);
      if (newOffset >= totalCount) {
        newOffset = offset;
      }
      dispatch(
        setURLParams({
          [`${resourceQueryParamName.envelopes}__offset`]: newOffset as unknown as string,
        })
      );
      logEvent('envelopes-list-load-more');
    }
  };

  useEffect(() => {
    if (comingFromAssignments?.reviewSetUrl !== location.search) {
      dispatch(fromAssignments(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filteredParams)]);

  useEffect(() => {
    if (envelopes.length > 0) {
      setFirstLoading(false);
    }
  }, [envelopes]);

  const handleScrollSave = (scroll: number): void => {
    sessionStorage.setItem('scrollPosition_envelopes-list-table', scroll.toString());
  };

  const getPaddingTop = (): string => {
    if (showSkippedEnvelopes) {
      return 'pt-6';
    }
    if (scrolled) {
      return 'pt-2';
    }
    return 'pt-6';
  };

  return (
    <>
      <div
        ref={contentContainerRef}
        id="envelope-list-content"
        className={`pb-4 transition-all duration-500 flex flex-col w-full h-full overflow-hidden ${getPaddingTop()} `}
        onKeyDown={(e): void => {
          if (e.key === 'Shift') setShiftPressed(true);
        }}
        onKeyUp={(e): void => {
          if (e.key === 'Shift') setShiftPressed(false);
        }}
        aria-hidden
        onWheel={(e): void => {
          if (e.deltaY > 0 && isAtTop && !scrolled) {
            setScrolled(true);
          } else if (e.deltaY < 0 && isAtTop && scrolled) {
            setScrolled(false);
          }
        }}
      >
        <EnvelopeListContentHeader
          scrolled={scrolled}
          selectedFormat={selectedFormat}
          setSelectedFormat={setSelectedFormat}
        />
        {lowDataMode ? (
          <EnvelopeListCondensedTable
            firstLoading={firstLoading}
            allSelected={allSelected}
            setIsAtTop={setIsAtTop}
            setScrolled={setScrolled}
            handleScrollSave={handleScrollSave}
            handleLoadMore={handleLoadMore}
            handleCheck={handleCheck}
            handleSelectAll={handleSelectAll}
          />
        ) : (
          <EnvelopeListTable
            firstLoading={firstLoading}
            allSelected={allSelected}
            setIsAtTop={setIsAtTop}
            setScrolled={setScrolled}
            handleScrollSave={handleScrollSave}
            handleLoadMore={handleLoadMore}
            handleCheck={handleCheck}
            handleSelectAll={handleSelectAll}
            selectedFormat={selectedFormat}
          />
        )}
      </div>
    </>
  );
};

export default EnvelopeListContent;
