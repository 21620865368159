import { requestTreeFiltersToogle, setTree } from 'actions/envelopes';
import FilterPills from 'components/EnvelopeList/FilterPills';
import LoadingIndicator from 'components/LoadingIndicator';
import Permissions from 'components/Permissions';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useTree from 'hooks/envelopes/useTree';
import pluralize from 'pluralize';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getSelectedReviewSet } from 'selectors/savedSearches';
import { useSelector } from 'store';
import { RouteParams, Tree } from 'types';
import {
  Operator,
  buildFSFromParams,
  fillDefaultTree,
  fillUrlWithFilters,
  getAllFieldsValuesWithLabels,
  getNavParamsFromFilters,
} from 'utils/parserTree';
import { getParamsFromUrl, reverse, useHistory } from 'utils/urls';
import { v4 } from 'uuid';

const FilterSection: React.FC = () => {
  const user = useSelector(getUser);
  const reviewSet = useSelector(getSelectedReviewSet);
  const history = useHistory();
  const dispatch = useDispatch();

  let navParams: RouteParams = {};

  if (user.customer) {
    if (reviewSet) {
      navParams = getParamsFromUrl(
        reviewSet.url,
        resourceQueryParamName.envelopes,
        'envelope-list',
        user.customer?.config
      );
    }
  }
  const filterSearch = (navParams.filters_search ?? buildFSFromParams(navParams)) as string;

  const { data, error, isLoading } = useTree(filterSearch, !!reviewSet?.uuid);
  let tree: Tree | null = {
    op: Operator.AND,
    data: [],
    id: v4(),
  };
  if (data && data.data.length > 0 && !error) {
    navParams = getNavParamsFromFilters(filterSearch, navParams);
    tree = fillDefaultTree(data, navParams);
  }
  const treeParams = getAllFieldsValuesWithLabels(tree);

  if (!reviewSet) return null;

  const buildUrl = (editSavedSearch: boolean): string => {
    const params = { ...navParams };
    let url = new URLSearchParams();
    if (params.filters_search) {
      const fs = params.filters_search as string;
      url = fillUrlWithFilters(url, fs, params);

      Object.entries(params).forEach(([key, value]) => {
        if (key === 'sample_uuid') {
          url.append(`envelopes__${key}`, value as string);
        }
      });
    }
    if (editSavedSearch) {
      url.append('envelopes__review_set_edit_uuid', `${reviewSet.uuid.toString()}-saved`);
    }
    return url.toString();
  };

  const handleNavigateEditUrl = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    editSearch: boolean
  ): void => {
    const url = buildUrl(editSearch);
    dispatch(requestTreeFiltersToogle({ value: true }));
    dispatch(
      setTree({
        tree: {
          op: 'and',
          data: [],
          id: v4(),
        },
      })
    );

    if (e.ctrlKey || e.metaKey) {
      window.open(
        reverse({
          routeName: 'envelope-list',
          queryParams: url,
          customerDomain: user.customer?.domain,
        }),
        '_blank'
      );
      return;
    }

    history.push(
      reverse({
        routeName: 'envelope-list',
        queryParams: url,
        customerDomain: user.customer?.domain,
      })
    );
  };

  const renderFilterPills = (): JSX.Element | null => {
    if (isLoading) {
      return (
        <div className="w-full flex flex-row justify-center">
          <LoadingIndicator size="10" />
        </div>
      );
    }

    if (tree) {
      return (
        <FilterPills
          propParams={navParams}
          treePropsParams={treeParams}
          allowDelete={false}
          envelopesPage={false}
          customTree={tree}
        />
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col gap-2 justify-between p-4 w-full bg-litlingo-gray-1 bg-opacity-30">
      <div className="flex flex-row items-center justify-between">
        <span className="font-bold">Filter</span>
        <Permissions action="reviewSets.edit">
          <button
            onClick={(e): void => handleNavigateEditUrl(e, true)}
            type="button"
            className="button button--secondary font-bold h-5"
          >
            Edit Filter
          </button>
        </Permissions>
      </div>
      <div className="flex flex-col gap-2 justify-between w-full">
        {renderFilterPills()}

        <button
          onClick={(e): void => handleNavigateEditUrl(e, false)}
          type="button"
          className="self-end underline text-litlingo-primary-120 focus:outline-none"
        >
          {`${reviewSet.record_count} ${pluralize('Message', reviewSet.record_count)}`}
        </button>
      </div>
    </div>
  );
};

export default FilterSection;
