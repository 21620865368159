import Modal from 'components/Modal';
import React from 'react';

type ComponentProps = {
  ruleName: string;
  setRuleName: (name: string) => void;
  toggleModalOpen: () => void;
  handleStep: () => void;
};

const SetRuleNameModal: React.FC<ComponentProps> = ({
  ruleName,
  setRuleName,
  toggleModalOpen,
  handleStep,
}) => {
  const body = (
    <div className=" flex flex-col mb-4 h-full text-body">
      <label htmlFor="name">
        <span>
          Name<span className="text-litlingo-alert">*</span>
        </span>
        <input
          id="name"
          type="text"
          className="form-input h-8 w-full text-litlingo-gray-6 text-body"
          onChange={(e): void => setRuleName(e.target.value)}
          value={ruleName}
        />
      </label>
    </div>
  );

  const handleCreateButton = (): void => {
    handleStep();
    toggleModalOpen();
  };

  const handleCancelReplace = (): void => {
    toggleModalOpen();
  };

  return (
    <Modal
      title="Model"
      body={body}
      toggleShowModal={toggleModalOpen}
      style={{ width: '37.5rem' }}
      okButton
      okButtonText="Create"
      okButtonDisabled={!ruleName}
      okButtonOnClick={handleCreateButton}
      cancelButton
      cancelButtonOnclick={handleCancelReplace}
      xButton
    />
  );
};

export default SetRuleNameModal;
