import React, { Fragment } from 'react';
import type { AnnotatedSuperNodeT, EllipsisArr } from '../types';
import { superNodeNeedsSpaceBefore } from '../utils/spacing';
import AnnotatedSuperNode from './AnnotatedSuperNode';

type AnnotatedSentenceProps = {
  nodes: AnnotatedSuperNodeT[];
  ellipsisArr?: EllipsisArr[];
};

const AnnotatedSentence: React.FC<AnnotatedSentenceProps> = (props) => {
  const { nodes, ellipsisArr } = props;
  let sentenceIndex = 0;

  const checkEllipsis = (
    idx: number,
    superNode: AnnotatedSuperNodeT
  ): EllipsisArr => {
    if (!superNode.annotations || !ellipsisArr) {
      return {
        left: false,
        right: false,
      };
    }
    const cur = ellipsisArr[sentenceIndex];
    sentenceIndex += 1;
    if (idx > 0 && !nodes[idx - 1].annotations && cur) cur.left = false;
    return cur;
  };

  return (
    <div className="flex flex-row flex-wrap items-end">
      {nodes.map((superNode, idx) => {
        return (
          <Fragment key={superNode.index}>
            {superNodeNeedsSpaceBefore(nodes, idx) ? <div>&nbsp;</div> : null}
            <AnnotatedSuperNode
              annotations={superNode.annotations}
              nodes={superNode.nodes}
              ellipsisArr={checkEllipsis(idx, superNode)}
              first={idx === 0}
              last={idx + 1 === nodes.length}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default AnnotatedSentence;
