import { setURLParams } from 'actions';
import withWidget from 'decorators/widget';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDashboardState } from 'selectors/dashboard';
import { useSelector } from 'store';

const ExportDashboardWidget: React.FC = () => {
  const dispatch = useDispatch();
  const { activeUuid } = useParams<{ activeUuid: string }>();

  const dashboardState = useSelector(getDashboardState(activeUuid));
  const { widgets } = dashboardState;
  const canExportWidgets = widgets.some((w) => !!w.export_label);

  const handleExport = (): void => {
    dispatch(
      setURLParams({
        is_exporting: 'true',
      })
    );
  };

  return (
    <button
      type="button"
      className="button button--primary flex justify-center items-center w-full min-h-full text-body"
      onClick={handleExport}
      disabled={!canExportWidgets}
    >
      <div className="text-white font-bold">Export Charts</div>
    </button>
  );
};

export default withWidget({
  // minWidth: 5,
  // minHeight: 3,
  bgClassColor: 'bg-litlingo-primary',
  containerClassName: 'rounded p-0',
})(ExportDashboardWidget);
