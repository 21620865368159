import { REMOVE_ICON } from 'constants/envelopeIcons';
import React from 'react';
import { textContrastColor } from '../../utils/tagColorUtils';

type ComponentProps = {
  colorClass: string;
  title: string;
  deleteAction?: () => void;
  customLeftIcon?: JSX.Element | false;
  rounded?: boolean;
};

const Badge: React.FC<ComponentProps> = ({
  rounded = true,
  colorClass,
  title,
  deleteAction,
  customLeftIcon,
}) => (
  <div
    data-testid="container"
    style={{ backgroundColor: `${colorClass}` }}
    className={`inline-block px-1 py-0.5 ${rounded ? 'rounded-lg' : 'rounded'}`}
  >
    <span
      data-testid="text"
      className={`text-sm flex items-center ${textContrastColor(colorClass)}`}
    >
      {customLeftIcon && <span className="mr-2">{customLeftIcon}</span>}
      {title}
      {deleteAction && (
        <button
          data-testid="delete-button"
          onClick={deleteAction}
          className="ml-2 focus:outline-none outline-none"
          type="button"
        >
          {REMOVE_ICON('#fff')}
        </button>
      )}
    </span>
  </div>
);

export default Badge;
