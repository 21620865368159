import type { Selector } from 'types';

const getThreadHighlightMode: Selector<boolean> = (state) =>
  state.ui?.envelopeView.threadHighlightMode;

export const getTranslationMode: Selector<boolean> = (state) =>
  state.ui?.envelopeView.translationMode;

export const getOpenAttachmentState: Selector<boolean> = (state) =>
  state.ui?.envelopeView.attachmentOpen;

export const getCollapsedLeftPanelState: Selector<boolean> = (state) =>
  state.ui?.envelopeView.collapsedLeftPanel;

export const getShowMoreLikeThis: Selector<boolean> = (state) =>
  state.ui?.envelopeView.showMoreLikeThis;

export default getThreadHighlightMode;
