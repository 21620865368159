import {
  fetchTeams,
  fetchTeamsFailure,
  fetchTeamsForFilter,
  fetchTeamsForFilterPills,
  fetchTeamsForFilterPillsFailure,
  fetchTeamsForFilterPillsRequest,
  fetchTeamsForFilterPillsSuccess,
  fetchTeamsRequest,
  fetchTeamsSuccess,
  fetchTeamsSuccessAppend,
} from 'actions';
import { apiClient as LitLingoClient } from 'client';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getNavParamsByResource } from 'selectors/nav';
import type { API, SagaReturn } from 'types';

function* fetchTeamsSaga(action: ReturnType<typeof fetchTeams>): SagaReturn {
  yield put(fetchTeamsRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.teams)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = {
    include_count: true,
    ...resourceParams,
    ...action.payload,
  };

  const response = (yield call([LitLingoClient.resources.teams, 'list'], {
    params: { ...params, relationships: 'users' },
  })) as API.Response<API.Teams.List>;

  if (response.error != null) {
    yield put(fetchTeamsFailure(response.error));
  } else {
    yield put(fetchTeamsSuccess(response.data));
  }
}

function* fetchTeamsListForFilter(params: {
  include_pii: string;
  broad_search: string;
  offset?: number;
}): SagaReturn {
  yield put(fetchTeamsRequest());

  const response = (yield call([LitLingoClient.resources.teams, 'list'], {
    params,
  })) as API.Response<API.Teams.List>;
  if (response.error != null) {
    yield put(fetchTeamsFailure(response.error));
  } else if (params.offset !== undefined && params.offset === 0) {
    yield put(fetchTeamsSuccess(response.data));
  } else {
    yield put(fetchTeamsSuccessAppend(response.data));
  }
}

function* fetchTeamsForFilterSaga(action: ReturnType<typeof fetchTeamsForFilter>): SagaReturn {
  const { payload } = action;
  const { searchValue = '', limit = 25, offset = 0 } = payload;
  const params = {
    include_pii: 'true',
    order_by: 'name',
    broad_search: searchValue,
    limit,
    offset,
    include_count: true,
  };
  yield call(fetchTeamsListForFilter, params);
}

function* fetchTeamsForFilterPillsSaga(
  action: ReturnType<typeof fetchTeamsForFilterPills>
): SagaReturn {
  const { uuids } = action.payload;

  const params = { uuids, include_pii: true };
  yield put(fetchTeamsForFilterPillsRequest());

  const response = (yield call([LitLingoClient.resources.teams, 'list'], {
    params,
  })) as API.Response<API.Teams.List>;
  if (response.error != null) {
    yield put(fetchTeamsForFilterPillsFailure(response.error));
  } else {
    yield put(fetchTeamsForFilterPillsSuccess(response.data));
  }
}

export default function* teamsSaga(): SagaReturn {
  yield takeLatest(fetchTeams.toString(), fetchTeamsSaga);
  yield takeLatest(fetchTeamsForFilter.toString(), fetchTeamsForFilterSaga);
  yield takeLatest(fetchTeamsForFilterPills.toString(), fetchTeamsForFilterPillsSaga);
}
