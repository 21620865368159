import type { AuditLog, Selector } from 'types';

export const getFetchRulesAuditLogsLoading: Selector<boolean> = (state) =>
  state.rulesAuditLogs.loading.includes('rules-audit-logs');

export const getRulesAuditLogsCount: Selector<number> = (state) => state.rulesAuditLogs.count;

export const getRulesAuditLogsList: Selector<AuditLog[]> = (state) => {
  if (state.rulesAuditLogs.list == null) {
    return [];
  }

  return state.rulesAuditLogs.list.map((uuid) => state.rulesAuditLogs.items[uuid]);
};
