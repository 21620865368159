import AuditLogs from 'components/AuditLogs';
import { fetchAuditLogs } from 'reducers/auditLogs';
import { RouteSpec, SuperRouteSpec } from 'types';

const auditLogsRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'audit-logs',
    path: '/:customerDomain/audit-logs',
    component: AuditLogs,
    private: true,
    permissions: 'auditLogs.list',
    data: {
      actions: [fetchAuditLogs],
    },
  },
];

export default auditLogsRoutes;
