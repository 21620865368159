import { RouteParams } from 'types';
import { Params } from 'utils/urls';
import useNavParams from './useNavParams';

export const useNavParamsByResource = (resource: string): Params => {
  const params = useNavParams();

  const resourceParams: RouteParams = {};

  Object.entries(params).forEach(([key, value]) => {
    const [paramResource, ...field] = key.split('__');
    if (paramResource === resource && value) {
      resourceParams[field.join('__')] = value;
    }
  });

  return resourceParams;
};

export default useNavParamsByResource;
