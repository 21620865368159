// eslint-disable-next-line import/prefer-default-export
export const resourceQueryParamName = {
  campaign: 'campaigns',
  category: 'categories',
  testCase: 'test_cases',
  event: 'events',
  annotator: 'annotators',
  identifier: 'identifiers',
  rule: 'rules',
  ruleGroups: 'rule_groups',
  user: 'users',
  envelopes: 'envelopes',
  auditLogs: 'audit_logs',
  analytics: 'analytics',
  identity: 'identities',
  invitation: 'invitations',
  savedSearch: 'saved_searches',
  tags: 'tags',
  tagGroups: 'tag_group',
  teams: 'teams',
  widgets: 'widgets',
  metrics: 'metrics',
  assignments: 'assignments',
  templates: 'templates',
  dashboards: 'dashboards',
  envelopeThread: 'threads',
  tasks: 'tasks',
  customReport: 'custom_reports',
  model: 'models',
  entities: 'entities',
  prompts: 'prompts',
};

export const resourceToHumanLabel = {
  campaigns: 'Use Case',
  annotators: 'Identifier',
  rules: 'Model',
  testCases: 'Test case',
};
