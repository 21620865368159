import { createCustomer } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getCreateCustomerLoading } from 'selectors/auth';
import { useSelector } from 'store';

const CreateCustomer: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getCreateCustomerLoading);
  const { register, getValues, setError, errors } = useForm({
    defaultValues: { name: '', domain: '' },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.stopPropagation();
    event.preventDefault();

    const values = getValues();

    // eslint-disable-next-line no-useless-escape
    const match = values.domain.match(/[ !@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]/g);

    if (match) {
      setError('domain', 'manual', 'Invalid Characters');
    } else {
      dispatch(createCustomer(values));
    }
  };

  return (
    <div className="">
      <Navbar />
      <div className="pt-10 px-6 max-w-3xl m-auto">
        <header className="mb-4 flex items-center">
          <h1 className="title">Create Workspace</h1>
          {loading && (
            <div className="md:flex md:items-center md:justify-between">
              <LoadingIndicator className="ml-2" size="5" />
            </div>
          )}
        </header>

        <main>
          <form onSubmit={handleSubmit} data-testid="form">
            <div className="mb-2">
              <label htmlFor="name" className="block text text--bold">
                {'Name '}
                <span className="litlingo-red-color">*</span>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="name"
                    name="name"
                    ref={register}
                    className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 litlingo-gray-bg-color"
                    data-testid="name-input"
                  />
                </div>
              </label>
            </div>

            <div className="mb-4">
              <label htmlFor="domain" className="block text text--bold">
                {'Domain '}
                <span className="litlingo-red-color">*</span>
                <div className="mt-1 rounded-md">
                  <input
                    id="domain"
                    name="domain"
                    ref={register}
                    className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 litlingo-gray-bg-color"
                    data-testid="domain-input"
                  />
                  <p className="mt-2 text-xs text-gray-600 font-normal">
                    this will be used at the end of the Litlingo URL. Alphanumerics only
                  </p>
                  {errors.domain && (
                    <p
                      className="mt-2 text-xs text-red-600 font-normal"
                      data-testid="error-element"
                    >
                      {errors.domain.message}
                    </p>
                  )}
                </div>
              </label>
            </div>

            <div className="flex justify-end">
              <button type="submit" className="button button--primary" data-testid="submit-button">
                Create
              </button>
            </div>
          </form>
        </main>
      </div>
    </div>
  );
};

export default CreateCustomer;
