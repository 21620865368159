/* eslint-disable max-lines */
import { deleteCategory } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import GlobalNavbar from 'components/Navbar/GlobalNavbar';
import keyMap from 'constants/configHotKeys';
import useNavParams from 'hooks/nav/useNavParams';
import React, { useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCategory, getFetchSingleCategoryLoading } from 'selectors/categories';
import { useSelector } from 'store';
import type { UUID } from 'types';
import CategoryHeader from './CategoryHeader';
import RenderCustomersCategories from './RenderCustomersCategories';

type RouteParams = {
  categoryId: UUID;
};

const Category: React.FC = () => {
  const { categoryId } = useParams<RouteParams>();
  const dispatch = useDispatch();

  const { customer_owned: customerOwned } = useNavParams() as { customer_owned: string };

  const item = useSelector(getCategory(categoryId)) || { active: false };
  const loading = useSelector(getFetchSingleCategoryLoading);

  const [isDeletingCategory, setIsDeletingCategory] = useState(false);

  /* starship useEffect(() => {
    // dispatch(fetchSingleCategoryWithRules({ id: categoryId }));
    dispatch(fetchAllTestCases({ category_uuids: categoryId, category_including_rules: true }));
    dispatch(fetchTestCaseSummary({ category_uuids: categoryId, category_including_rules: true }));
  }, [categoryId, dispatch]); */

  const handleDelete = async (): Promise<void> => {
    dispatch(deleteCategory({ id: categoryId }));
  };

  const toggleConfirmModal = (): void => setIsDeletingCategory(false);

  const handlers = {
    SAVE_CATEGORY: (event: KeyboardEvent | undefined): void => {
      if (event) {
        event.preventDefault();
        const button = document.querySelector('#save-category-setup-button') as HTMLButtonElement;
        if (button) button.click();
      }
    },
  };

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  if (loading) return <LoadingIndicator className="m-auto mt-20" size="20" />;

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <div
        className="flex flex-col bg-white min-w-screen"
        style={{
          height: `calc(100vh - ${warningBarOffset}px)`,
        }}
      >
        {customerOwned ? <Navbar /> : <GlobalNavbar />}
        <div className="flex flex-col gap-4 h-full pt-6 pb-4">
          <CategoryHeader />

          <main>
            <div className="flex flex-col gap-2 px-6">
              <RenderCustomersCategories item={item} />
            </div>
          </main>
        </div>

        {isDeletingCategory && (
          <ConfirmModal
            text="Are you sure you want to delete this category?"
            okButtonText="Yes"
            cancelButtonText="No"
            okButtonOnClick={handleDelete}
            toggleShowModal={toggleConfirmModal}
          />
        )}
      </div>
    </>
  );
};
export default Category;
