import {
  fetchActiveTasks,
  fetchActiveTasksFailure,
  fetchActiveTasksFulfill,
  fetchActiveTasksRequest,
  fetchActiveTasksSuccess,
} from 'actions/tasks';
import { apiClient as LitLingoClient } from 'client';
import { call, put, takeLatest } from 'redux-saga/effects';
import type { API, SagaReturn } from 'types';

function* fetchActiveTasksSaga(): SagaReturn {
  yield put(fetchActiveTasksRequest());

  const response = (yield call([LitLingoClient.resources.tasks.extras, 'getActiveTasks'], {
    params: {},
  })) as API.Response<API.Tasks.getActiveTasks>;

  if (response.error != null) {
    yield put(fetchActiveTasksFailure(response.error));
  } else {
    yield put(fetchActiveTasksSuccess(response.data));
  }
  yield put(fetchActiveTasksFulfill());
}

function* tasksSaga(): SagaReturn {
  yield takeLatest(fetchActiveTasks.toString(), fetchActiveTasksSaga);
}

export default tasksSaga;
