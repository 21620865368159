import type { AnnotatedGraph, EllipsisArr } from '../types';

// eslint-disable-next-line import/prefer-default-export
export const buildEllipsisArr = (
  wholeSentence: AnnotatedGraph[]
): EllipsisArr[] => {
  const output: EllipsisArr[] = [];
  wholeSentence.forEach((node) => {
    node.nodes.forEach((curNode, idx) => {
      if (curNode.annotations) {
        const notEmpty = curNode.annotations.length > 0;
        const obj = {
          left: false,
          right: false,
        };
        if (
          notEmpty &&
          idx > 0 &&
          node.nodes[idx - 1] &&
          !node.nodes[idx - 1].annotations
        ) {
          obj.left = true;
        }
        if (
          notEmpty &&
          node.nodes[idx + 1] &&
          !node.nodes[idx + 1].annotations
        ) {
          obj.right = true;
        }
        output.push(obj);
      }
    });
  });
  return output;
};
