import { fetchSingleOutcome } from 'actions/outcome';
import { fetchSingleRuleGroup } from 'actions/ruleGroup';
import OutcomeManagerStarship from 'components/OutcomeManagerStarship';
import OutcomeStarship from 'components/OutcomeStarship';
import { RouteSpec, SuperRouteSpec } from 'types';

const outcomeRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'rule-group-outcome',
    path: '/:customerDomain/rule-group/:ruleId?/outcome',
    component: OutcomeManagerStarship,
    exact: true,
    private: true,
    permissions: 'outcomes.list',
    data: {
      idFromPath: true,
      actions: [fetchSingleRuleGroup],
    },
  },

  {
    name: 'rule-group-outcome-id',
    path: '/:customerDomain/rule-group/:ruleId?/outcome/:outcomeId',
    component: OutcomeStarship,
    private: true,
    permissions: 'outcomes.retrieve',
    data: {
      idFromPath: true,
      actions: [fetchSingleOutcome],
    },
  },
];

export default outcomeRoutes;
