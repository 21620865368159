import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { Outcome, OutcomeTypeKey, OutcomeTypes } from 'types';

type ComponentProps = {
  outcomeTypes: OutcomeTypes;
  typeSelection: string;
  outcome: Outcome;
  setMarkDownContent: React.Dispatch<React.SetStateAction<string>>;
  setErrors: React.Dispatch<
    React.SetStateAction<{
      [key: string]: {
        message: string;
      };
    }>
  >;
  setTypeSelection: React.Dispatch<React.SetStateAction<string>>;
  errors: {
    [key: string]: {
      message: string;
    };
  };
};

const RuleOutcomeForm: React.FC<ComponentProps> = ({
  outcomeTypes,
  typeSelection,
  outcome,
  setMarkDownContent,
  setTypeSelection,
  setErrors,
  errors,
}) => {
  const { getValues, register } = useFormContext();

  const clearErrors = ({
    isRequired,
    key,
    value,
  }: {
    isRequired: boolean;
    key: string;
    value: string;
  }): void => {
    if (isRequired && value && errors[key]) {
      setErrors((prevState) => {
        const newState = { ...prevState };
        delete newState[key];
        return { ...newState };
      });
    }
  };

  const renderTypeOptions = (): JSX.Element[] | null => {
    if (
      !outcomeTypes ||
      !typeSelection ||
      !outcomeTypes[typeSelection as keyof typeof outcomeTypes]
    )
      return null;
    const typeItem = outcomeTypes[typeSelection as keyof typeof outcomeTypes];
    // FIXME: THIS IS A MESS, we should be using CustomField and ValueField components for this
    return (
      Object.keys(typeItem)
        // @ts-ignore
        .filter((key) => !typeItem[key as keyof typeof typeItem].hidden && key !== 'type_config')
        .map((key) => {
          const defaultValue = outcome ? outcome.config[key as keyof typeof outcome.config] : '';
          let fieldIsRequired = false;
          // @ts-ignore
          if (typeItem[key as OutcomeTypeKey].required) {
            fieldIsRequired = true;
          }
          let inputType = (
            <input
              id={key}
              name={key}
              defaultValue={defaultValue as unknown as string}
              className="mt-1 form-input w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out block text litlingo-gray-bg-color"
              onChange={(e): void =>
                clearErrors({ isRequired: fieldIsRequired, key, value: e.target.value })
              }
              ref={register()}
            />
          );
          // @ts-ignore
          if (typeItem[key as keyof typeof typeItem].type === 'mustache_template') {
            inputType = (
              <textarea
                id={key}
                name={key}
                ref={register()}
                rows={4}
                defaultValue={defaultValue as unknown as string}
                className="form-input block w-full rounded-none rounded-l-md transition ease-in-out duration-150 sm:text-sm sm:leading-5 litlingo-gray-bg-color"
                onChange={(e): void => {
                  clearErrors({ isRequired: fieldIsRequired, key, value: e.target.value });
                  setMarkDownContent(getValues(key));
                }}
              />
            );
          }
          // @ts-ignore
          if (typeItem[key as keyof typeof typeItem].type === 'bool') {
            inputType = (
              <label className="switch relative inline-block mt-2" htmlFor={key}>
                <input
                  id={key}
                  name={key}
                  defaultChecked={defaultValue as unknown as boolean}
                  ref={register()}
                  type="checkbox"
                  className="litlingo-checkbox-input w-0 h-0 opacity-0"
                />
                <span className="slider round absolute inset-0 duration-500 rounded-full py-2 px-4 cursor-pointer" />
              </label>
            );
          }
          // @ts-ignore
          if (typeItem[key as keyof typeof typeItem].type === 'dict') {
            inputType = (
              <textarea
                id={key}
                name={key}
                ref={register()}
                rows={3}
                defaultValue={
                  defaultValue ? JSON.stringify(defaultValue, null, '\t') : defaultValue
                }
                className="form-input block w-full rounded-none rounded-l-md transition ease-in-out duration-150 sm:text-sm sm:leading-5 litlingo-gray-bg-color"
                onChange={(e): void => {
                  clearErrors({ isRequired: fieldIsRequired, key, value: e.target.value });
                  setMarkDownContent(getValues(key));
                }}
              />
            );
          }
          return (
            <div className="col-span-6 sm:col-span-4 mt-5" key={key}>
              <label htmlFor="description" className="block text">
                {/*
                  // @ts-ignore */}
                <p className="font-bold">{typeItem[key as OutcomeTypeKey].name}</p>
                {inputType}
                <p className="mt-2 text-sm">
                  {/*
                  // @ts-ignore */}
                  {typeItem[key as OutcomeTypeKey].description}
                  {/*
                  // @ts-ignore */}
                  {typeItem[key as OutcomeTypeKey].name === 'Body Template' && (
                    <>
                      {'\u00A0'}
                      <a
                        href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Markdown
                      </a>
                    </>
                  )}
                </p>
                {errors[key] && <p className="text-pink-700">{errors[key].message}</p>}
              </label>
            </div>
          );
        })
    );
  };

  return (
    <>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <div className="w-full">
          <label htmlFor="name" className="block text text--bold">
            Name
            <input
              id="name"
              name="name"
              data-testid="name-input"
              ref={register()}
              className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:z-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 litlingo-gray-bg-color"
            />
            {errors.name && errors.name.message}
          </label>
        </div>
        <div className="w-1/2">
          <label htmlFor="keyword" className="block text text--bold mt-5">
            Type
            <div>
              <select
                value={typeSelection}
                onChange={(e): void => setTypeSelection(e.target.value)}
                id="Outcome Type"
                className="mt-1 block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                data-testid="outcome-type-select"
              >
                {Object.keys(outcomeTypes).map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </label>
        </div>
      </div>
      <div>{renderTypeOptions()}</div>
    </>
  );
};

export default RuleOutcomeForm;
