import React from 'react';

type ComponentProps = {
  placeholder: string;
  handleSearchChange: (value: string) => void;
};

const SearchCampaignRule: React.FC<ComponentProps> = (props) => {
  const { placeholder, handleSearchChange } = props;

  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <svg className="h-4 w-4 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
          <path d="M12.9 14.32a8 8 0 111.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 108 2a6 6 0 000 12z" />
        </svg>
      </div>
      <input
        id="search"
        name="search"
        className="form-input block w-full pl-10 sm:text-sm sm:leading-5 shadow-sm"
        data-testid="search-input"
        placeholder={placeholder || 'Search'}
        onChange={(event): void => handleSearchChange(event.target.value)}
      />
    </div>
  );
};

export default SearchCampaignRule;
