import { showErrorAlert } from 'actions';
import useUpsertTeam from 'hooks/teams/useUpsertTeam';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import type { Team } from 'types';

type ComponentProps = {
  team: Omit<Team, 'uuid'> & { uuid?: Team['uuid'] };
  toggleForm: boolean;
  setToggleForm: React.Dispatch<React.SetStateAction<boolean>>;
};
const TeamForm: React.FC<ComponentProps> = (props) => {
  const { team, toggleForm, setToggleForm } = props;

  const dispatch = useDispatch();
  const upsertTeamHook = useUpsertTeam();

  const { register, reset, getValues } = useForm({
    defaultValues: { name: team.name },
  });

  useEffect(() => {
    reset({ name: team.name });
  }, [team, reset]);

  const handleSubmitTeamForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    if (!getValues().name || getValues().name.trim() === '') {
      dispatch(showErrorAlert('Division needs a name'));
      return;
    }

    upsertTeamHook.mutate({
      ...(team.uuid ? { uuid: team.uuid } : {}),
      users: team.users?.length > 0 ? team.users : [],

      external_id: team.external_id ?? getValues().name.replace(/ /g, '_').toLowerCase(),
      ...getValues(),
    });

    /* dispatch(
      upsertTeam({
        ...(team.uuid ? { uuid: team.uuid } : {}),
        users: team.users?.length > 0 ? team.users : [],

        external_id: team.external_id ?? getValues().name.replace(/ /g, '_').toLowerCase(),
        ...getValues(),
      })
    ); */
    setToggleForm(false);
  };

  return (
    <div
      className={`litlingo-gray-bg-color overflow-hidden transition-all duration-500 ease-in-out ${
        toggleForm ? 'max-h-96 p-4 border rounded-lg' : 'max-h-0 p-0'
      }`}
    >
      <h2 className="text-xl font-bold leading-5 text mb-6">Create or edit a division</h2>
      <form>
        <div className="flex justify-between flex-wrap">
          <div className="w-2/5">
            <label htmlFor="name" className="block text-sm font-bold leading-5 text">
              {'Name '}
              <span className="litlingo-red-color">*</span>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="name"
                  name="name"
                  ref={register}
                  className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 litlingo-gray-bg-color"
                />
              </div>
            </label>
          </div>
        </div>
        <button
          onClick={handleSubmitTeamForm}
          type="submit"
          className="button button--primary mt-6"
          data-testid="save-button"
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default TeamForm;
