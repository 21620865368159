import { downloadCustomerDocument } from 'actions';
import ChromeExtAuth from 'components/ChromeExtAuth';
import CustomerList from 'components/CustomerList';
import DownloadLanding from 'components/DownloadLanding';
import Login from 'components/Login';
import NotAuthorizedPage from 'components/NotAuthorizedPage';
import NotFoundPage from 'components/NotFoundPage';
import SankeyChart from 'components/SankeyChart';
import SwarmPlot from 'components/SwarmPlot';
import WordBubble from 'components/WordBubble';
import { RouteSpec, SuperRouteSpec } from 'types';

const generalRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'login',
    path: '/login',
    component: Login,
  },
  {
    name: 'select-customer',
    path: '/select-customer',
    component: CustomerList,
  },
  {
    name: 'not-authorized',
    path: '/not-authorized',
    component: NotAuthorizedPage,
  },
  {
    name: 'not-found',
    path: '/not-found',
    component: NotFoundPage,
  },
  {
    name: 'redirect',
    path: '/r',
    component: (): null => null,
    private: true,
  },
  {
    name: 'chrome',
    path: '/chrome',
    component: ChromeExtAuth,
    private: false,
    data: {},
  },
  {
    path: '/:customerDomain/sankey-chart',
    component: SankeyChart,
    private: true,
  },
  {
    path: '/:customerDomain/word-bubble',
    component: WordBubble,
    private: true,
  },
  {
    path: '/:customerDomain/swarm-plot',
    component: SwarmPlot,
    private: true,
  },
  {
    name: 'documents',
    path: '/:customerDomain/documents/:documentId',
    component: DownloadLanding,
    private: true,
    data: {
      actions: [downloadCustomerDocument],
      idFromPath: true,
    },
  },
  {
    name: 'home',
    path: '/',
    component: (): null => null,
    private: true,
    exact: true,
  },
  {
    path: '/login',
    component: (): null => null,
    private: true,
    exact: true,
  },
];
export default generalRoutes;
