/* eslint-disable max-lines */
import {
  cleanChangedNodes,
  evaluateAsyncResults,
  fetchAllCampaignRuleOutcomes,
  fetchTestCaseSummary,
} from 'actions';
import CampaignSimpleList from 'components/CampaignSimpleList';
import SelectCustomerModal from 'components/CustomerList/SelectCustomerModal';
import LinkLookup from 'components/LinkLookup';
import Modal from 'components/Modal';
import PageHeader from 'components/PageHeader';
import SplitButton from 'components/SplitButton';
import SummarySection from 'components/SummarySection';
import { INDENTATION_ICON, NEW_EDIT_ICON } from 'constants/commonIcons';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getCampaignRuleOutcomes } from 'selectors/campaignRuleOutcomes';
import { getPermissionsPolicy } from 'selectors/permissions';
import { getRule } from 'selectors/rules';
import { getTestCaseSummary, getTestCasesResultsKey } from 'selectors/testCases';
import { useSelector } from 'store';
import { isActionAuthorized } from 'utils/permissions';
import { useHistory } from 'utils/urls';
import EditRuleModal from './EditRuleModal';
import SelectUseCaseModal from './SelectUseCaseModal';

type RouterParams = { ruleId: string; campaignId?: string };

const RuleHeader: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { ruleId, campaignId } = useParams<RouterParams>();
  const rule = useSelector(getRule(ruleId));
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);

  const evaluateTestResultsKey = useSelector(getTestCasesResultsKey);
  const campaignsRuleOutcomes = useSelector(getCampaignRuleOutcomes);
  const summary = useSelector(getTestCaseSummary);
  const { customer } = useSelector(getUser);
  const domain = useSelector(getCustomerDomain);

  const [isFullDescription, setIsFullDescription] = useState(false);
  const [clampedDescription, setClampedDescription] = useState(false);

  const [isEditingRule, setIsEditingRule] = useState(false);
  const [isCloneRuleModalOpen, setIsCloneRuleModalOpen] = useState(false);
  const [isCompareRuleModalOpen, setIsCompareRuleModalOpen] = useState(false);
  const [isSelectCustomerModalOpen, setIsSelectCustomerModalOpen] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState<{
    domain: string | null;
    uuid: string;
    label: string | null;
  } | null>(null);

  const descriptionRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        const newC = node.scrollHeight > node.clientHeight + 2;
        setClampedDescription(newC);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rule, isFullDescription]
  );

  // @ts-ignore
  useEffect(() => (): void => dispatch(cleanChangedNodes()), [dispatch]);

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;
    if (evaluateTestResultsKey) {
      dispatch(evaluateAsyncResults(evaluateTestResultsKey));
      dispatch(fetchTestCaseSummary({ rule_uuids: ruleId }));
      intervalId = setInterval(() => {
        dispatch(evaluateAsyncResults(evaluateTestResultsKey));
        dispatch(fetchTestCaseSummary({ rule_uuids: ruleId }));
      }, 2000);
    }
    return (): void => clearInterval(intervalId);
  }, [dispatch, evaluateTestResultsKey, ruleId]);

  useEffect(() => {
    dispatch(fetchAllCampaignRuleOutcomes({ rule_uuid: ruleId, campaign_uuid: campaignId }));
  }, [dispatch, ruleId, campaignId]);

  const toggleShowCloneRuleModalOpen = (): void => {
    setIsCloneRuleModalOpen(!isCloneRuleModalOpen);
  };

  const toggleShowCompareRuleModalOpen = (): void => {
    setIsCompareRuleModalOpen(!isCompareRuleModalOpen);
  };

  const toggleSelectCustomerModalOpen = (): void => {
    setIsSelectCustomerModalOpen(!isSelectCustomerModalOpen);
  };

  // FIXME: FIND A BETTER WAY TO DO THIS, CURRENTLY IT'S USING THE FIRST CAMPAIGN
  // RULE OUTCOME THAT HAS THE SAME RULE_UUID
  const ruleOutcomeForRule = Object.values(campaignsRuleOutcomes).find(
    (cro) => cro.rule_uuid === ruleId
  );
  const ruleOutcomeId = ruleOutcomeForRule != null ? ruleOutcomeForRule.uuid : '';
  const renderCampaignScreen = (
    <CampaignSimpleList
      toggleShowCloneRuleModalOpen={toggleShowCloneRuleModalOpen}
      ruleOutcomeId={ruleOutcomeId}
    />
  );

  const handleClinkOnEdit = (): void => {
    setIsEditingRule(true);
  };
  const toggleEditModal = (): void => setIsEditingRule(false);

  const models = Object.values(campaignsRuleOutcomes)
    .filter((campaign, index, arr) => arr.findIndex((c) => campaign.uuid === c.uuid) === index)
    .map((outcome) => outcome.campaign);

  const renderSummary = (): JSX.Element => <SummarySection summary={summary} renderTotalTests />;

  const handleSelectCustomer = (sCustomer: {
    domain: string | null;
    uuid: string;
    label: string | null;
  }): void => {
    setSelectedCustomer(sCustomer);
    toggleSelectCustomerModalOpen();
    toggleShowCompareRuleModalOpen();
  };

  const handleBackToSelectCustomer = (): void => {
    toggleSelectCustomerModalOpen();
    toggleShowCompareRuleModalOpen();
  };

  const handleCompare = (ruleTwoId: string): void => {
    if (customer && selectedCustomer) {
      history.pushLookup({
        customerDomain: domain,
        routeName: 'rules-compare',
        routeParams: {
          customerOneId: customer.uuid,
          customerTwoId: selectedCustomer?.uuid,
          ruleOneId: ruleId,
          ruleTwoId,
        },
      });
    }
  };

  const options = [
    {
      label: 'Compare',
      action: toggleSelectCustomerModalOpen,
    },
  ];

  return (
    <>
      <header>
        <div className="px-6">
          <div className="flex flex-col gap-1">
            <div className="flex flex-row justify-between items-baseline">
              <div className="flex w-full">
                <PageHeader />
              </div>
              <div className="flex justify-end gap-2 w-full h-8">
                <div className="w-40">
                  {isActionAuthorized('rules.compare', user.roles, policy) ? (
                    <SplitButton
                      buttonStyle="secondary"
                      primaryLabel="Clone"
                      primaryAction={toggleShowCloneRuleModalOpen}
                      options={options}
                      dropdownDirection="down"
                      loading={false}
                    />
                  ) : (
                    <button
                      type="button"
                      onClick={toggleShowCloneRuleModalOpen}
                      className="button button--secondary w-40 flex justify-center h-8"
                    >
                      <span className="font-bold">Clone</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex items-center w-3/5">
                <div className="flex flex-row items-baseline gap-2">
                  <span className="text-heading-2 break-words">{rule && rule.name}</span>
                  <button
                    type="button"
                    className="flex-none w-5 self-center focus:outline-none"
                    onClick={handleClinkOnEdit}
                  >
                    {NEW_EDIT_ICON('text-litlingo-primary-120')}
                  </button>
                  <span className="flex-none text-small">Model</span>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between items-stretch mt-1">
              <div className="flex flex-col h-full justify-start gap-2 w-3/5">
                {rule && rule.description && (
                  <div
                    className={`${
                      isFullDescription ? 'break-words' : 'flex flex-row break-all'
                    } text-body w-80ch`}
                  >
                    <span
                      ref={descriptionRef}
                      className={`description ${isFullDescription ? '' : 'clamped-description'}`}
                    >
                      {rule && rule.description}
                    </span>
                    {(clampedDescription || isFullDescription) && (
                      <button
                        className={`text-body focus:outline-none ${
                          isFullDescription ? 'ml-2' : ''
                        }`}
                        type="button"
                        onClick={(): void => setIsFullDescription((v) => !v)}
                      >
                        <span className="underline text-litlingo-primary whitespace-no-wrap">
                          {isFullDescription ? 'Less' : 'More'}
                        </span>
                      </button>
                    )}
                  </div>
                )}
                {models[0] && models[0].uuid && (
                  <div className="flex flex-row gap-2 pl-6 text-small">
                    {INDENTATION_ICON}
                    <span className="flex gap-1">
                      <span>Used in:</span>
                      <LinkLookup
                        routeName="campaign-detail"
                        routeParams={{ campaignId: models[0].uuid }}
                        type="button"
                        className="self-end underline text-litlingo-primary-120 focus:outline-none"
                      >
                        {models[0].name}
                      </LinkLookup>
                      <span>Use Case</span>
                    </span>
                  </div>
                )}
              </div>
              <div className="self-end">{renderSummary()}</div>
            </div>
          </div>
        </div>
      </header>

      {isCloneRuleModalOpen && (
        <Modal
          body={renderCampaignScreen}
          title="Choose a use case"
          okButton
          okButtonText="Done"
          okButtonOnClick={toggleShowCloneRuleModalOpen}
          toggleShowModal={toggleShowCloneRuleModalOpen}
        />
      )}
      {isSelectCustomerModalOpen && (
        <SelectCustomerModal
          toggleModalOpen={toggleSelectCustomerModalOpen}
          selectCustomer={handleSelectCustomer}
        />
      )}
      {isCompareRuleModalOpen && (
        <SelectUseCaseModal
          toggleModalOpen={toggleShowCompareRuleModalOpen}
          onSelect={handleCompare}
          customerId={selectedCustomer?.uuid}
          customerName={selectedCustomer?.label || ''}
          onBackButtonClick={handleBackToSelectCustomer}
        />
      )}
      {isEditingRule && (
        <EditRuleModal isModalOpen={isEditingRule} toggleModalOpen={toggleEditModal} rule={rule} />
      )}
    </>
  );
};

export default RuleHeader;
