import { fetchAssignments } from 'actions';
import AssignmentsManager from 'components/AssignmentsManager';
import { RouteSpec, SuperRouteSpec } from 'types';

const assignmentsRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'assignments',
    path: '/:customerDomain/assignments',
    component: AssignmentsManager,
    private: true,
    permissions: 'assignments.list',
    data: {
      actions: [fetchAssignments],
      params: {
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        assignments: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            broad_search: {},
            order_by: {
              defaultValue: 'created_at',
              options: ['created_at'],
            },
            order_desc: {
              defaultValue: 'true',
              options: ['true', 'false'],
            },
            saved_search_uuid: {
              defaultValue: '',
            },
          },
        },
      },
    },
  },
];

export default assignmentsRoutes;
