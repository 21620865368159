import config from '@config';

// to have the proper types on the client this can't have a type, if debugging
// is needed ClientSpec can be used as the type
const AccountSpec = {
  base: config.auth.v1,
  logs: false,

  headers: {
    'x-litlingo-auth-no-redirect': 'true',
    'block-overwrite': 'true',
    'cache-control': 'no-cache',
  },

  resources: {
    identities: {
      path: '/identities',
      include: ['LIST', 'UPSERT', 'RETRIEVE', 'DELETE'],
      extras: {},
    },
    invitations: {
      path: '/invitations',
      include: ['LIST', 'UPSERT', 'RETRIEVE', 'DELETE'],
      extras: {
        resend: {
          path: '/{invitationId}/resend',
          method: 'POST',
        },
        send: {
          path: '/{userUuid}/send_invitation',
          method: 'POST',
        },
        sendInvitations: {
          path: '/send_invitations',
          method: 'POST',
        },
        bulkSendInvitationQuery: {
          path: '/bulk_send_invitation_query',
          method: 'POST',
        },
      },
    },
    oauth2Clients: {
      path: '/clients',
      include: ['LIST', 'RETRIEVE'],
      extras: {},
    },

    oauth2Providers: {
      path: '/providers',
      include: ['LIST', 'RETRIEVE'],
      extras: {},
    },
  },
} as const;

export default Object.freeze(AccountSpec);
