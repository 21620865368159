import Card from 'components/Card';
import CustomerEditSecretModal from 'components/Customer/CustomerEditSecretModal';
import LinkLookup from 'components/LinkLookup';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import { LEFT_ARROW_ICON, OK_ICON, WRONG_ICON } from 'constants/envelopeIcons';
import React, { useState } from 'react';
import { getCustomer } from 'selectors/auth';
import { useSelector } from 'store';

const CustomerEditGlia: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const customer = useSelector(getCustomer);
  const apiKeyName = 'glia_auth_token';
  const gliaToken = customer?.secrets_summary?.[apiKeyName];

  const header = (
    <div className="flex justify-between">
      <div className="pt-5 pb-12 flex items-center">
        <LinkLookup
          data-testid="customer-edit-glia-left-button"
          routeName="customer"
          className="mx-6 focus:outline-none"
        >
          {LEFT_ARROW_ICON}
        </LinkLookup>
        <span className="text text--large text--bold">Glia</span>
      </div>
    </div>
  );

  const apiKey = (
    <div className="px-5 py-4">
      <div className="flex justify-between">
        <div>Auth token</div>
        <div className="flex">
          <span>******</span>
          <div className="mx-8">{gliaToken ? OK_ICON : WRONG_ICON}</div>
          <button
            data-testid="glia-token-edit-button"
            type="button"
            onClick={(): void => setModalOpen(true)}
            className="button button--primary button--blue-textfocus:outline-none"
          >
            {gliaToken ? 'Replace' : 'Setup'}
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="litlingo-gray-bg-color-4">
      <Navbar />

      <div
        className="flex flex-row overflow-auto"
        style={{
          minHeight: 'calc(100vh - 4rem)',
          height: 'calc(100vh - 4rem)',
        }}
      >
        <SettingSidebar />
        <div className="mt-0 mx-auto min-w-2xl w-8/12 max-w-6xl text-left">
          <Card items={[header, apiKey]} />
          {isModalOpen && (
            <CustomerEditSecretModal
              secretKeyName={apiKeyName}
              toggleShowModal={(): void => setModalOpen(!isModalOpen)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerEditGlia;
