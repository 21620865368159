import Modal from 'components/Modal';
import React, { useState } from 'react';

export type ModalStageType = {
  title: string;
  body: JSX.Element;
  okButton?: boolean;
  okButtonText?: string;
  okButtonOnClick?: () => void;
  cancelButtonText?: string;
  cancelButtonOnClick?: () => void;
  style?: { [key: string]: string };
  cancelButtonStyle?: string;
};

type ModalFlowProps = {
  stages: ModalStageType[];
  toggleShowModal?: () => void;
};

const ModalFlow: React.FC<ModalFlowProps> = (props) => {
  const { stages, toggleShowModal } = props;
  const [modalStage, setModalStage] = useState(0);

  const modalStagePrev = (): void => {
    if (modalStage > 0) {
      setModalStage((stage) => stage - 1);
    }
  };

  const modalStageNext = (): void => {
    if (modalStage < stages.length - 1) {
      setModalStage((stage) => stage + 1);
    }
  };

  return (
    <Modal
      title={stages[modalStage].title}
      body={stages[modalStage].body}
      okButton={stages[modalStage].okButton}
      okButtonText={stages[modalStage].okButtonText || 'Next'}
      okButtonOnClick={stages[modalStage].okButtonOnClick || modalStageNext}
      cancelButtonText={stages[modalStage].cancelButtonText || 'Cancel'}
      cancelButtonOnclick={stages[modalStage].cancelButtonOnClick || toggleShowModal}
      cancelButtonStyle={stages[modalStage].cancelButtonStyle}
      style={stages[modalStage].style}
      toggleShowModal={toggleShowModal}
      prevButtonOnClick={modalStage !== 0 ? modalStagePrev : undefined}
    />
  );
};

export default ModalFlow;
