/* eslint-disable max-lines */
import { UUID } from '@litlingo/client';
import { clearURLParams, deleteCategory } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import EmptyTableState from 'components/EmptyTableState';
import FilterListHeaderRedesign from 'components/Filters/FilterListHeaderRedesign';
import ListFooterHooksBased from 'components/ListFooter/ListFooterHooksBased';
import LoadingIndicator from 'components/LoadingIndicator';
// import EditCategoryModal from 'components/Category/EditCategoryModal';
import CategoryListRow from 'components/CategoriesList/CategoryListRow';
import CreateCategoryModal from 'components/Category/CreateCategoryModal';
import Navbar from 'components/Navbar';
import GlobalNavbar from 'components/Navbar/GlobalNavbar';
import Permissions from 'components/Permissions';
import SearchInput from 'components/SearchInput';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useCategories from 'hooks/categories/useCategories';
import useNavParams from 'hooks/nav/useNavParams';
import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import { getCustomerId } from 'selectors/auth';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';
import CategoryListCount from './CategoryListCount';

const CategoriesList: React.FC = () => {
  const dispatch = useDispatch();

  const { customer_owned: customerOwned } = useNavParams() as { customer_owned: string };

  const { data, isLoading, isFetching } = useCategories({ redirectOnError: true });
  const isEmpty = data?.records.length === 0;

  const customerId = useSelector(getCustomerId);

  const [isCreatingCategory, setIsCreatingCategory] = useState(false);

  const [selectedCategories, setSelectedCategories] = useState<UUID[]>([]);
  const [isDeletingCategory, setIsDeletingCategory] = useState(false);

  const toggleConfirmModal = (): void => setIsDeletingCategory(!isDeletingCategory);

  const toggleCreateCategoryModal = (): void => {
    setIsCreatingCategory(!isCreatingCategory);
  };

  const handleResetFiltersClick = (): void => {
    logEvent('use-case-reset-filters');
    dispatch(clearURLParams());
  };

  // const handleCategorieselect = (checked: boolean, uuid: UUID): void => {
  //   if (checked) {
  //     setSelectedCategories((selected) => [...selected, uuid]);
  //   } else {
  //     setSelectedCategories((selected) => selected.filter((id) => id !== uuid));
  //   }
  // };

  // const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
  //   e.stopPropagation();

  //   if (selectedCategories.length === data?.records.length) {
  //     setSelectedCategories([]);
  //   } else {
  //     setSelectedCategories(data?.records.map((category) => category.uuid) || []);
  //   }
  // };

  const handleDelete = (): void => {
    dispatch(deleteCategory({ id: selectedCategories[0] }));
    setIsDeletingCategory(false);
    setSelectedCategories([]);
  };

  return (
    <div className="min-h-screen min-w-screen bg-white">
      {customerOwned ? <Navbar /> : <GlobalNavbar />}
      <div className="pt-8">
        <div>
          <header>
            <div className="max-w-7xl mx-auto px-6">
              <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                  <div className="flex flex-row items-center">
                    <h2 className="title">Categories</h2>
                    {isFetching && <LoadingIndicator className="ml-2" size="5" />}
                  </div>
                </div>
                <div className="flex flex-row gap-x-2">
                  <Permissions action="categories.create">
                    <button
                      onClick={toggleCreateCategoryModal}
                      type="button"
                      data-testid="create-category-button"
                      className="flex flex-row justify-center button button--primary h-8 w-30  shadow-sm rounded"
                    >
                      <span>Add New</span>
                    </button>
                  </Permissions>
                </div>
              </div>

              <div className="flex flex-row flex-shrink-0  justify-between mt-4">
                <div className="flex flex-row gap-x-2 h-8">
                  <SearchInput
                    resource={resourceQueryParamName.category}
                    field="broad_search"
                    logEventMessage="use-case-search"
                    className="h-8"
                  />
                  <button
                    type="button"
                    className="bg-white border-0 focus:outline-none inline-flex items-end leading-4 text text-body text-litlingo-gray-6 hover:text-litlingo-gray-4 underline"
                    data-testid="reset-filters-button"
                    onClick={handleResetFiltersClick}
                  >
                    Clear Filters
                  </button>
                </div>
                <CategoryListCount />
              </div>
            </div>
          </header>
        </div>
        <main className="z-10">
          <div className="mt-2 mb-8 px-6 text-left">
            <LoadingOverlay
              active={isLoading}
              spinner={<LoadingIndicator size="10" />}
              fadeSpeed={0}
              styles={{
                content: (base) => ({
                  ...base,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }),
                wrapper: (base) => ({
                  ...base,
                  height: '100%',
                }),
                spinner: () => ({}),
                overlay: (base) => ({
                  ...base,
                  zIndex: 10,
                }),
              }}
            >
              <div className="table-wrapper__new">
                <table className="table-wrapper__table">
                  <thead>
                    <tr>
                      {/* <th className="table-wrapper__new-th table-wrapper__th--w-3">
                        <div className="px-2">
                          <Tooltip tooltip="envelopesListActions.selectAll" redesign>
                            <label
                              htmlFor="select-all"
                              className="flex justify-start items-center w-full cursor-pointer"
                            >
                              <input
                                id="select-all"
                                data-testid="select-all"
                                className={`cursor-pointer form-checkbox litlingo-checkbox w-5 h-5 rounded-sm ${
                                  selectedCategories.length > 0 &&
                                  data &&
                                  selectedCategories?.length < data.records.length
                                    ? 'checkbox-select-all-partial'
                                    : ''
                                }`}
                                type="checkbox"
                                checked={
                                  selectedCategories?.length === data?.records.length &&
                                  selectedCategories?.length > 0
                                }
                                onChange={handleSelectAll}
                              />
                            </label>
                          </Tooltip>
                        </div>
                      </th> */}

                      <th className="table-wrapper__new-th pl-4">Model</th>
                      <th className="table-wrapper__new-th">Rule</th>
                      <th className="table-wrapper__new-th">Outcome</th>
                      <th className="table-wrapper__new-th">Customer</th>
                      <FilterListHeaderRedesign
                        resource={resourceQueryParamName.category}
                        text="Last Modified"
                        field="updated_at"
                        className="table-wrapper__th--w-12"
                      />
                      <th className="table-wrapper__new-th table-wrapper__th--w-12">Modified By</th>
                    </tr>
                    {/* starship {selectedCategories?.length > 0 && (
                      <CategoryListActionsRow
                        selectedCategories={selectedCategories}
                        setShowCategories={setShowCategories}
                        setIsDeletingCategory={setIsDeletingCategory}
                        showCategories={showCategories}
                        setIsCloneCategoryOpen={setIsCloneCategoryOpen}
                      />
                    )} */}
                  </thead>
                  <tbody className="table-wrapper__tbody">
                    {!isLoading && isEmpty ? (
                      <EmptyTableState message="There are no Use Cases to display" colCount="4" />
                    ) : (
                      data?.records.map((category) => (
                        <CategoryListRow
                          category={category}
                          key={category.uuid}
                          // selected={selectedCategories.includes(category.uuid)}
                          // handleCategorieselect={handleCategorieselect}
                        />
                      ))
                    )}
                  </tbody>
                </table>
                <ListFooterHooksBased
                  resourceStateName={resourceQueryParamName.category}
                  resourceName="category"
                  fetchHook={useCategories}
                />
              </div>
            </LoadingOverlay>
          </div>
        </main>
      </div>

      {isCreatingCategory && (
        <CreateCategoryModal
          toggleModal={toggleCreateCategoryModal}
          customerId={customerOwned && customerId ? customerId : ''}
          redirect={customerOwned ? 'category-manager' : 'global-category-manager'}
          askForOutcome={!!customerOwned}
        />
      )}

      {isDeletingCategory && (
        <ConfirmModal
          text="Are you sure you want to delete this Category?"
          okButtonText="Yes"
          cancelButtonText="No"
          data-testid="delete-modal"
          okButtonOnClick={handleDelete}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </div>
  );
};

export default CategoriesList;
