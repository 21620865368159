import { Logo } from '@litlingo/react';
import { checkLoginRedirect } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import SignInWithGoogleButton from 'components/Login/SignInWithGoogleButton';
import SignInWithLitlingoButton from 'components/Login/SignInWithLitlingoButton';
import SignInWithOffice365Button from 'components/Login/SignInWithOffice365Button';
import config from 'config';
import { LOGIN_BACKGROUND } from 'constants/common';
import { ERROR_ICON } from 'constants/commonIcons';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getAuthError, getIsVerifying } from 'selectors/auth';

const Login: React.FC = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const loading = useSelector(getIsVerifying);
  const error = useSelector(getAuthError);

  useEffect(() => {
    if (location.search !== '') dispatch(checkLoginRedirect());
  }, [dispatch, location.hash, location.search]);

  const renderCenteredContent = (): JSX.Element => {
    if (loading) {
      return <LoadingIndicator size="20" />;
    }

    return (
      <>
        <div className="my-1 w-full">
          <SignInWithGoogleButton />
        </div>
        <div className="my-1 w-full">
          <SignInWithOffice365Button />
        </div>

        <div className="my-1 w-full hidden">
          <SignInWithLitlingoButton />
        </div>

        {error && error.code === 'login' && (
          <div className="flex justify-center items-center flex-col error-bg-opacity py-2 px-4 w-full	mt-4">
            <div className="flex justify-center items-center">
              <span className="mr-2">{ERROR_ICON}</span>
              <span className="text text--bold text-litlingo-error">Account not found</span>
            </div>
            <span className="text text-litlingo-error text-center mt-2 w-full">
              We couldn&apos;t locate your email. Try choosing a different account, or{' '}
              <span className="text text--primary cursor-pointer hover:underline">
                <a href="mailto:support@litlingo.com">contact support</a>
              </span>
            </span>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="relative min-h-screen bg-white flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center rounded-sm shadow-login-card bg-litlingo-off-white login-card px-10 z-10">
        <div className="flex flex-col justify-center items-center w-4/5 mb-12">
          <Logo />
        </div>
        <div className="flex flex-col justify-center items-center w-4/5">
          {renderCenteredContent()}
          <span className="text text-center mt-6 w-4/5 font-normal">
            By signing in you agree to LitLingo’s{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={config.privacy.url}
              className="text underline text-litlingo-info cursor-pointer font-normal"
            >
              Privacy Policy
            </a>
          </span>
        </div>
      </div>
      <div className="mt-6 z-10">
        <span className="text font-normal">Interested in mitigating risk for your business? </span>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={config.demo.url}
          className="text text-litlingo-info cursor-pointer underline font-normal"
        >
          See a demo
        </a>
      </div>
      <div className="absolute bottom-0 h-full w-full z-0">{LOGIN_BACKGROUND}</div>
    </div>
  );
};

export default Login;
