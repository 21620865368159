import TeamsManager from 'components/TeamsManager';
import { RouteSpec, SuperRouteSpec } from 'types';

const teamsRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'teams-manager',
    path: '/:customerDomain/teams',
    component: TeamsManager,
    private: true,
    permissions: 'teams.list',
    data: {
      params: {
        teams: {
          nestedResource: true,
          params: {
            broad_search: {},
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            order_by: {
              defaultValue: 'name',
            },
            order_desc: {
              defaultValue: 'false',
            },
            include_pii: {
              defaultValue: 'true',
            },
            include_count: {
              defaultValue: '1',
            },
          },
        },
      },
    },
  },
];

export default teamsRoutes;
