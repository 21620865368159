import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  AddNewLanguageMatcherPayload,
  ChangeLanguageMatcherTypePayload,
  ChangeScopePayload,
  DeleteLanguageMatcherPayload,
  EditIdentifierPayload,
  EditLanguageMatcherPayload,
  ReceiveNewKeywordPayload,
  RemoveKeywordPayload,
  addNewLanguageMatcher,
  bulkDeleteIdentifierFailure,
  changeLanguageMatcherType,
  changeScopeIdentifier,
  clearIdentifier,
  deleteIdentifierFailure,
  deleteIdentifierRequest,
  deleteIdentifierSuccess,
  deleteLanguageMatcher,
  discardIdentifierChanges,
  editIdentifier,
  editLanguageMatcher,
  fetchAllIdentifiersFailure,
  fetchAllIdentifiersRequest,
  fetchAllIdentifiersSuccess,
  fetchAllIdentifiersSuccessAppend,
  fetchIdentifierCustomersFailure,
  fetchIdentifierCustomersFulfill,
  fetchIdentifierCustomersRequest,
  fetchIdentifierCustomersSuccess,
  fetchIdentifierRevisionFailure,
  fetchIdentifierRevisionRequest,
  fetchIdentifierRevisionSuccess,
  fetchIdentifierTypesFailure,
  fetchIdentifierTypesRequest,
  fetchIdentifierTypesSuccess,
  fetchIdentifiersByIdsFailure,
  fetchIdentifiersByIdsRequest,
  fetchIdentifiersByIdsSuccess,
  fetchLanguageMatcherTypesFailure,
  fetchLanguageMatcherTypesRequest,
  fetchLanguageMatcherTypesSuccess,
  fetchRelationshipTypesFailure,
  fetchRelationshipTypesRequest,
  fetchRelationshipTypesSuccess,
  fetchSingleIdentifierFailure,
  fetchSingleIdentifierRequest,
  fetchSingleIdentifierSuccess,
  generateTermsRequest,
  generateTermsSuccess,
  receiveNewKeyword,
  removeKeyword,
  saveIdentifierFailure,
  saveIdentifierFulfill,
  saveIdentifierLocalFailure,
  saveIdentifierRequest,
  saveIdentifierSuccess,
  selectCustomerIdentifier,
  setActiveLanguageMatcherId,
  setCurrentTestIdentifierRevisionId,
  setDropPosition,
  setHasChanges,
  setShowEditIdentifier,
  setShowUtilization,
} from 'actions/identifier';
import type {
  API,
  AnnotatorTypes,
  Customer,
  ErrorObject,
  Identifier,
  IdentifierRevision,
  LanguageMatchersTypes,
  MLanguageMatcher,
  NormalizedIdentifier,
  NormalizedRelationshipType,
  NormalizedResource,
  RelationshipType,
  UUID,
} from 'types';
import { v4 as uuidv4 } from 'uuid';

export type IdentifierState = {
  unsavedChanges: boolean;
  items: NormalizedResource<NormalizedIdentifier>;
  filterItems: NormalizedResource<NormalizedIdentifier>;
  types: AnnotatorTypes;
  languageMatchers: NormalizedResource<MLanguageMatcher>;
  activeLanguageMatcher: string;
  loading: string[];
  relationshipTypes: NormalizedRelationshipType[];
  languageMatcherTypes: LanguageMatchersTypes;
  generatedTerms: Record<string, string[]>;
  dropPosition: number;
  error: ErrorObject | null;
  count: number;
  selectedIdentifier: Identifier | null;
  selectedIdentifierRevision: NormalizedIdentifier | null;
  originalIdentifier: {
    identifier: Identifier;
    identifierRevision: IdentifierRevision;
  } | null;
  selectedCustomer: UUID;
  identifierCustomers: Customer[];
  testIdentifierRevisionId: string;
  showUtilization: boolean;
  showEditIdentifier: boolean;
};

type IdentifierReducer<P = void> = (
  state: IdentifierState,
  action: PayloadAction<P>
) => IdentifierState;

const defaultState: IdentifierState = {
  unsavedChanges: false,
  items: {},
  filterItems: {},
  types: {} as AnnotatorTypes,
  languageMatchers: {},
  activeLanguageMatcher: '',
  loading: [],
  relationshipTypes: [],
  languageMatcherTypes: {} as LanguageMatchersTypes,
  generatedTerms: {},
  dropPosition: -1,
  error: null,
  count: 0,
  selectedIdentifier: null,
  selectedIdentifierRevision: null,
  originalIdentifier: null,
  selectedCustomer: '',
  identifierCustomers: [],
  testIdentifierRevisionId: '',
  showUtilization: false,
  showEditIdentifier: false,
};

const handleFetchAllIdentifiersRequest: IdentifierReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAllIdentifiersRequest.toString()],
});

const handleFetchAllIdentifiersSuccess: IdentifierReducer<
  API.WrappedAPIResponse<IdentifierRevision>
> = (state, { payload }) => {
  const identifiers: { [uuid: string]: NormalizedIdentifier } = {};
  const languageMatchers: { [uuid: string]: MLanguageMatcher } = {};
  payload.records.forEach((identifier) => {
    const { uuid, language_matchers: identifierLanguageMatchers } = identifier;
    // @ts-ignore
    const normalizedIdentifier: NormalizedIdentifier = {
      ...identifier,
      language_matchers: identifierLanguageMatchers.map((matcher) => {
        // @ts-ignore
        const matcherId = matcher.id || uuidv4();

        languageMatchers[matcherId] = {
          ...matcher,
          id: matcherId,
          annotator_uuid: uuid,
          saved: true,
        };

        return matcherId;
      }),
    };

    identifiers[uuid] = normalizedIdentifier;
  });

  return {
    ...state,
    items: identifiers,
    languageMatchers: { ...state.languageMatchers, ...languageMatchers },
    count: payload.count,
    loading: state.loading.filter((s) => s !== fetchAllIdentifiersRequest.toString()),
  };
};

const handleFetchAllIdentifiersFailure: IdentifierReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchAllIdentifiersRequest.toString()),
});

const handleFetchIdentifiersByIdsRequest: IdentifierReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchIdentifiersByIdsRequest.toString()],
});

const handleFetchIdentifiersByIdsSuccess: IdentifierReducer<
  API.WrappedAPIResponse<IdentifierRevision>
> = (state, { payload }) => {
  const identifiers: { [uuid: string]: NormalizedIdentifier } = {};
  const languageMatchers: { [uuid: string]: MLanguageMatcher } = {};
  payload.records.forEach((identifier) => {
    const { uuid, language_matchers: identifierLanguageMatchers } = identifier;
    // @ts-ignore
    const normalizedIdentifier: NormalizedIdentifier = {
      ...identifier,
      language_matchers: identifierLanguageMatchers.map((matcher) => {
        // @ts-ignore
        const matcherId = matcher.id || uuidv4();

        languageMatchers[matcherId] = {
          ...matcher,
          id: matcherId,
          annotator_uuid: uuid,
          saved: true,
        };

        return matcherId;
      }),
    };

    identifiers[uuid] = normalizedIdentifier;
  });

  return {
    ...state,
    filterItems: identifiers,
    loading: state.loading.filter((s) => s !== fetchIdentifiersByIdsRequest.toString()),
  };
};

const handleFetchIdentifiersByIdsFailure: IdentifierReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchIdentifiersByIdsRequest.toString()),
});

const handleDeleteIdentifierRequest: IdentifierReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, deleteIdentifierRequest.toString()],
});

const handleDeleteIdentifierSuccess: IdentifierReducer<string> = (state, { payload }) => {
  const id = payload;
  const identifiers = { ...state.items };
  delete identifiers[id];

  return {
    ...state,
    items: identifiers,
    loading: state.loading.filter((s) => s !== deleteIdentifierRequest.toString()),
  };
};

const handleFetchSingleIdentifierRequest: IdentifierReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchSingleIdentifierRequest.toString()],
});

const handleFetchSingleIdentifierSuccess: IdentifierReducer<{
  identifier: Identifier;
  identifierRevision: IdentifierRevision;
}> = (state, { payload }) => {
  const { identifier, identifierRevision } = payload;

  const { language_matchers: identifierLanguageMatchers } = identifierRevision;
  const languageMatchers: { [uuid: string]: MLanguageMatcher } = {};
  // @ts-ignore
  const normalizedIdentifier: NormalizedIdentifier = {
    ...identifierRevision,
    language_matchers: identifierLanguageMatchers.map((matcher) => {
      const matcherId = matcher.uuid;
      languageMatchers[matcherId] = {
        ...matcher,
        saved: true,
      };

      return matcherId;
    }),
  };

  return {
    ...state,
    selectedIdentifier: identifier,
    selectedIdentifierRevision: normalizedIdentifier,
    languageMatchers: {
      ...state.languageMatchers,
      ...languageMatchers,
    },
    loading: state.loading.filter((s) => s !== fetchSingleIdentifierRequest.toString()),
    unsavedChanges: false,
    originalIdentifier: payload,
  };
};

const handleFetchSingleIdentifierFailure: IdentifierReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchSingleIdentifierRequest.toString()),
});

const handleDeleteIdentifierFailure: IdentifierReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== deleteIdentifierRequest.toString()),
});

const handleBulkDeleteIdentifierFailure: IdentifierReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== deleteIdentifierRequest.toString()),
});

const handleSaveIdentifierRequest: IdentifierReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, saveIdentifierRequest.toString()],
});

const handleSaveIdentifierSuccess: IdentifierReducer<IdentifierRevision> = (state, { payload }) => {
  const identifierRevision = payload;

  const { language_matchers: identifierLanguageMatchers } = identifierRevision;
  const languageMatchers: { [uuid: string]: MLanguageMatcher } = {};
  // @ts-ignore
  const normalizedIdentifier: NormalizedIdentifier = {
    ...identifierRevision,
    language_matchers: identifierLanguageMatchers.map((matcher) => {
      const matcherId = matcher.uuid;
      languageMatchers[matcherId] = {
        ...matcher,
        saved: true,
      };

      return matcherId;
    }),
  };

  if (!state.selectedIdentifier) return state;

  return {
    ...state,
    selectedIdentifierRevision: normalizedIdentifier,
    languageMatchers,
    unsavedChanges: false,
    originalIdentifier: { identifier: state.selectedIdentifier, identifierRevision: payload },
  };
};

const handleSaveIdentifierFailure: IdentifierReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleSaveIdentifierFulfill: IdentifierReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== saveIdentifierRequest.toString()),
});

const handleSaveIdentifierLocalFailure: IdentifierReducer<IdentifierRevision> = (
  state,
  { payload }
) => {
  const normalizedLanguageMatchers: NormalizedResource<MLanguageMatcher> = {};
  payload.language_matchers.forEach((languageMatcher) => {
    normalizedLanguageMatchers[languageMatcher.uuid] = languageMatcher;
  });
  return {
    ...state,
    languageMatchers: {
      ...state.languageMatchers,
      ...normalizedLanguageMatchers,
    },
  };
};
const handleFetchIdentifierTypesRequest: IdentifierReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchIdentifierTypesRequest.toString()],
});

const handleFetchIdentifierTypesSuccess: IdentifierReducer<AnnotatorTypes> = (
  state,
  { payload }
) => {
  const types = Object.entries(payload).reduce(
    (curr, [key, type]) => ({
      ...curr,
      [key]: {
        ...type,
        name: type.meta.name,
        description: type.meta.description,
        key,
      },
    }),
    {}
  );

  return {
    ...state,
    types: {
      ...state.types,
      ...types,
    },
    loading: state.loading.filter((s) => s !== fetchIdentifierTypesRequest.toString()),
  };
};

const handleFetchIdentifierTypesFailure: IdentifierReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchIdentifierTypesRequest.toString()),
});

const handleFetchLanguageMatcherTypesRequest: IdentifierReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchLanguageMatcherTypesRequest.toString()],
});

const handleFetchLanguageMatcherTypesSuccess: IdentifierReducer<LanguageMatchersTypes> = (
  state,
  { payload }
) => ({
  ...state,
  languageMatcherTypes: payload,
  loading: state.loading.filter((s) => s !== fetchLanguageMatcherTypesRequest.toString()),
});

const handleFetchLanguageMatcherTypesFailure: IdentifierReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchLanguageMatcherTypesRequest.toString()),
});

const handleFetchRelationshipTypesRequest: IdentifierReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchRelationshipTypesRequest.toString()],
});

const handleFetchRelationshipTypesSuccess: IdentifierReducer<RelationshipType[]> = (
  state,
  { payload }
) => {
  const formattedData = payload.map((relationshipType) => ({
    id: relationshipType.type,
    name: relationshipType.name,
  }));
  return {
    ...state,
    relationshipTypes: formattedData,
    loading: state.loading.filter((s) => s !== fetchRelationshipTypesRequest.toString()),
  };
};

const handleFetchRelationshipTypesFailure: IdentifierReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchRelationshipTypesRequest.toString()),
});

const handleAddNewLanguageMatcher: IdentifierReducer<AddNewLanguageMatcherPayload> = (
  state,
  { payload }
) => {
  const { languageMatcherId, matcherBody } = payload;
  const languageMatcherItem = state.languageMatchers[languageMatcherId] || {};
  const identifierItem = state.selectedIdentifierRevision;

  if (!identifierItem) return state;

  return {
    ...state,

    languageMatchers: {
      ...state.languageMatchers,
      [languageMatcherId]: {
        ...languageMatcherItem,
        ...matcherBody,
      },
    },
    selectedIdentifierRevision: {
      ...identifierItem,
      language_matchers: [...identifierItem.language_matchers, languageMatcherId],
    },
    unsavedChanges: true,
  };
};

// FIXME: need to find a way to use proper types for this
// @ts-ignore
const handleReceiveNewKeyword: IdentifierReducer<ReceiveNewKeywordPayload> = (
  state,
  { payload }
) => {
  const { languageMatcherId, data, parentType } = payload;
  const languageMatcherItem: MLanguageMatcher = state.languageMatchers[languageMatcherId] || {};
  let newVal = data.value;

  if (Array.isArray(data.value)) {
    const existingKeywords = languageMatcherItem[data.key] || [];

    if (Array.isArray(existingKeywords)) {
      const tempVal = data.value
        .filter((v, index, arr) => index === arr.indexOf(v))
        .filter((val) => val)
        .map((val) => val?.toString().trim());
      newVal = [...new Set([...existingKeywords, ...tempVal])];
    }
  }

  return {
    ...state,
    languageMatchers: {
      ...state.languageMatchers,
      [languageMatcherId]: {
        ...languageMatcherItem,
        type: parentType,
        [data.key]: newVal,
        uuid: languageMatcherId,
      },
    },
    activeLanguageMatcher: languageMatcherId,
    unsavedChanges: true,
  };
};

const handleChangeLanguageMatcherType: IdentifierReducer<ChangeLanguageMatcherTypePayload> = (
  state,
  { payload }
) => {
  const { languageMatcherId, type, ...args } = payload;

  const baseLanguageMatcher = state.languageMatchers[languageMatcherId];
  const newLanguageMatcher = {
    uuid: baseLanguageMatcher.uuid,
    name: baseLanguageMatcher.name,
    type,
  } as MLanguageMatcher;
  if ('isNew' in baseLanguageMatcher) {
    newLanguageMatcher.isNew = baseLanguageMatcher.isNew;
  }
  if ('saved' in baseLanguageMatcher) {
    newLanguageMatcher.saved = baseLanguageMatcher.saved;
  }
  Object.assign(newLanguageMatcher, args);

  return {
    ...state,
    languageMatchers: {
      ...state.languageMatchers,
      [languageMatcherId]: newLanguageMatcher,
    },
  };
};

const handleSetActiveLanguageMatcherId: IdentifierReducer<string> = (state, { payload }) => ({
  ...state,
  activeLanguageMatcher: payload,
});

const handleDeleteLanguageMatcher: IdentifierReducer<DeleteLanguageMatcherPayload> = (
  state,
  { payload }
) => {
  const { languageMatcherId } = payload;
  const languageMatchers = { ...state.languageMatchers };
  delete languageMatchers[languageMatcherId];

  const identifierItem = state.selectedIdentifierRevision;
  if (!identifierItem) return state;

  const itemLanguageMatchers = identifierItem.language_matchers;
  const index = itemLanguageMatchers.indexOf(languageMatcherId);
  if (index === -1) return { ...state };
  return {
    ...state,
    selectedIdentifierRevision: {
      ...identifierItem,
      language_matchers: [
        ...itemLanguageMatchers.slice(0, index),
        ...itemLanguageMatchers.slice(index + 1),
      ],
    },
    languageMatchers,
    unsavedChanges: true,
  };
};

// FIXME: need to find a way to use proper types for this
// @ts-ignore
const handleEditLanguageMatcherName: IdentifierReducer<EditLanguageMatcherPayload> = (
  state,
  { payload }
) => {
  const { languageMatcherId, data } = payload;
  const { ...args } = data;
  const languageMatcherItem = state.languageMatchers[languageMatcherId] || {};
  return {
    ...state,
    languageMatchers: {
      ...state.languageMatchers,
      [languageMatcherId]: {
        ...languageMatcherItem,
        ...args,
      },
    },
    activeLanguageMatcher: languageMatcherId,
    unsavedChanges: true,
  };
};

const handleRemoveKeyword: IdentifierReducer<RemoveKeywordPayload> = (state, { payload }) => {
  const { languageMatcherId, keyword, partOfSpeech } = payload;
  const languageMatcherItem = state.languageMatchers[languageMatcherId];
  // FIXME: Remove 'as unknown' when the payload type is fixed
  const list = state.languageMatchers[languageMatcherId][partOfSpeech] as unknown as string[];
  const index = list.indexOf(keyword as string);

  return {
    ...state,
    languageMatchers: {
      ...state.languageMatchers,
      [languageMatcherId]: {
        ...languageMatcherItem,
        [partOfSpeech]: [...list.slice(0, index), ...list.slice(index + 1)],
      },
    },
    unsavedChanges: true,
  };
};

const handleEditIdentifier: IdentifierReducer<EditIdentifierPayload> = (state, { payload }) => {
  const { id, data } = payload;
  const item = state.items[id];
  return {
    ...state,
    items: {
      ...state.items,
      [id]: {
        ...item,
        ...data,
      },
    },
  };
};

const handleSetHasChanges: IdentifierReducer<boolean> = (state, { payload }) => ({
  ...state,
  unsavedChanges: payload,
});

const handleFetchAllIdentifiersSuccessAppend: IdentifierReducer<
  API.WrappedAPIResponse<NormalizedIdentifier>
> = (state, { payload }) => {
  const normalizedIdentifiers: NormalizedResource<NormalizedIdentifier> = {};
  payload.records.forEach((identifier) => {
    normalizedIdentifiers[identifier.uuid] = identifier;
  });
  return {
    ...state,
    items: { ...state.items, ...normalizedIdentifiers },
    loading: state.loading.filter((s) => s !== fetchAllIdentifiersRequest.toString()),
    count: payload.count,
  };
};

const handleGenerateTermsRequest: IdentifierReducer<string[]> = (state) => ({
  ...state,
  loading: [...state.loading, generateTermsRequest.toString()],
});

const handleGenerateTermsSuccess: IdentifierReducer<string[]> = (state, { payload }) => ({
  ...state,
  generatedTerms: { ...state.generatedTerms, [state.activeLanguageMatcher]: payload },
  loading: state.loading.filter((r) => r !== generateTermsRequest.toString()),
});

const handleDropPosition: IdentifierReducer<number> = (state, { payload }) => ({
  ...state,
  dropPosition: payload,
});

const handleDiscardChanges: IdentifierReducer = (state) => {
  if (!state.originalIdentifier) return state;

  return {
    ...handleFetchSingleIdentifierSuccess(state, {
      payload: state.originalIdentifier,
      type: 'IDENTIFIERS/FETCH_SINGLE_SUCCESS',
    }),
    activeLanguageMatcher: '',
  };
};

const handleSelectCustomerIdentifier: IdentifierReducer<string> = (state, { payload }) => ({
  ...state,
  selectedCustomer: payload,
});

const handleChangeScopeIdentifier: IdentifierReducer<ChangeScopePayload> = (state, { payload }) => {
  const { act, customerId, matcherId } = payload;

  const scopedCustomers = state.languageMatchers[matcherId].customer_uuids || [];
  let newScopedCustomers = [...scopedCustomers];

  if (act === 'add') {
    if (!scopedCustomers.includes(customerId)) {
      newScopedCustomers.push(customerId);
    }
  } else {
    newScopedCustomers = newScopedCustomers.filter((c) => c !== customerId);
  }

  const matcher = { ...state.languageMatchers[matcherId], customer_uuids: newScopedCustomers };

  return {
    ...state,
    languageMatchers: { ...state.languageMatchers, [matcherId]: matcher },
    unsavedChanges: true,
  };
};

const handleFetchIdentifierCustomersRequest: IdentifierReducer = (state) => ({
  ...state,
  loading: [...state.loading, fetchIdentifierCustomersRequest.toString()],
});
const handleFetchIdentifierCustomersSuccess: IdentifierReducer<Customer[]> = (
  state,
  { payload }
) => ({
  ...state,
  identifierCustomers: payload,
});
const handleFetchIdentifierCustomersFailure: IdentifierReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
});
const handleFetchIdentifierCustomersFulfill: IdentifierReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchIdentifierCustomersRequest.toString()),
});

const handleCurrentTestIdentifierRevisionId: IdentifierReducer<string> = (state, { payload }) => ({
  ...state,
  testIdentifierRevisionId: payload,
});

const handleClearIdentifier: IdentifierReducer = (state) => ({
  ...state,
  activeLanguageMatcher: '',
  languageMatchers: {},
});

const handleSetShowUtilization: IdentifierReducer<boolean> = (state, { payload }) => ({
  ...state,
  showUtilization: payload,
});

const handleSetShowEditIdentifier: IdentifierReducer<boolean> = (state, { payload }) => ({
  ...state,
  showEditIdentifier: payload,
});

const handlers = {
  [fetchAllIdentifiersRequest.toString()]: handleFetchAllIdentifiersRequest,
  [fetchAllIdentifiersSuccess.toString()]: handleFetchAllIdentifiersSuccess,
  [fetchAllIdentifiersFailure.toString()]: handleFetchAllIdentifiersFailure,
  [fetchIdentifiersByIdsRequest.toString()]: handleFetchIdentifiersByIdsRequest,
  [fetchIdentifiersByIdsSuccess.toString()]: handleFetchIdentifiersByIdsSuccess,
  [fetchIdentifiersByIdsFailure.toString()]: handleFetchIdentifiersByIdsFailure,
  [fetchSingleIdentifierRequest.toString()]: handleFetchSingleIdentifierRequest,
  [fetchSingleIdentifierSuccess.toString()]: handleFetchSingleIdentifierSuccess,
  [fetchSingleIdentifierFailure.toString()]: handleFetchSingleIdentifierFailure,
  [fetchIdentifierRevisionRequest.toString()]: handleFetchSingleIdentifierRequest,
  [fetchIdentifierRevisionSuccess.toString()]: handleFetchSingleIdentifierSuccess,
  [fetchIdentifierRevisionFailure.toString()]: handleFetchSingleIdentifierFailure,
  [deleteIdentifierRequest.toString()]: handleDeleteIdentifierRequest,
  [deleteIdentifierSuccess.toString()]: handleDeleteIdentifierSuccess,
  [deleteIdentifierFailure.toString()]: handleDeleteIdentifierFailure,
  [bulkDeleteIdentifierFailure.toString()]: handleBulkDeleteIdentifierFailure,
  [saveIdentifierRequest.toString()]: handleSaveIdentifierRequest,
  [saveIdentifierSuccess.toString()]: handleSaveIdentifierSuccess,
  [saveIdentifierFailure.toString()]: handleSaveIdentifierFailure,
  [saveIdentifierFulfill.toString()]: handleSaveIdentifierFulfill,
  [saveIdentifierLocalFailure.toString()]: handleSaveIdentifierLocalFailure,
  [fetchIdentifierTypesRequest.toString()]: handleFetchIdentifierTypesRequest,
  [fetchIdentifierTypesSuccess.toString()]: handleFetchIdentifierTypesSuccess,
  [fetchIdentifierTypesFailure.toString()]: handleFetchIdentifierTypesFailure,
  [fetchLanguageMatcherTypesRequest.toString()]: handleFetchLanguageMatcherTypesRequest,
  [fetchLanguageMatcherTypesSuccess.toString()]: handleFetchLanguageMatcherTypesSuccess,
  [fetchLanguageMatcherTypesFailure.toString()]: handleFetchLanguageMatcherTypesFailure,
  [fetchRelationshipTypesRequest.toString()]: handleFetchRelationshipTypesRequest,
  [fetchRelationshipTypesSuccess.toString()]: handleFetchRelationshipTypesSuccess,
  [fetchRelationshipTypesFailure.toString()]: handleFetchRelationshipTypesFailure,

  [fetchIdentifierCustomersRequest.toString()]: handleFetchIdentifierCustomersRequest,
  [fetchIdentifierCustomersSuccess.toString()]: handleFetchIdentifierCustomersSuccess,
  [fetchIdentifierCustomersFailure.toString()]: handleFetchIdentifierCustomersFailure,
  [fetchIdentifierCustomersFulfill.toString()]: handleFetchIdentifierCustomersFulfill,

  [addNewLanguageMatcher.toString()]: handleAddNewLanguageMatcher,
  [receiveNewKeyword.toString()]: handleReceiveNewKeyword,
  [setActiveLanguageMatcherId.toString()]: handleSetActiveLanguageMatcherId,
  [deleteLanguageMatcher.toString()]: handleDeleteLanguageMatcher,
  [removeKeyword.toString()]: handleRemoveKeyword,
  [editIdentifier.toString()]: handleEditIdentifier,
  [editLanguageMatcher.toString()]: handleEditLanguageMatcherName,
  [changeLanguageMatcherType.toString()]: handleChangeLanguageMatcherType,
  [setHasChanges.toString()]: handleSetHasChanges,
  [fetchAllIdentifiersSuccessAppend.toString()]: handleFetchAllIdentifiersSuccessAppend,
  [generateTermsSuccess.toString()]: handleGenerateTermsSuccess,
  [generateTermsRequest.toString()]: handleGenerateTermsRequest,
  [setDropPosition.toString()]: handleDropPosition,
  [discardIdentifierChanges.toString()]: handleDiscardChanges,
  [selectCustomerIdentifier.toString()]: handleSelectCustomerIdentifier,
  [changeScopeIdentifier.toString()]: handleChangeScopeIdentifier,
  [setCurrentTestIdentifierRevisionId.toString()]: handleCurrentTestIdentifierRevisionId,
  [clearIdentifier.toString()]: handleClearIdentifier,
  [setShowUtilization.toString()]: handleSetShowUtilization,
  [setShowEditIdentifier.toString()]: handleSetShowEditIdentifier,
};

const IdentifierReducer = createReducer(defaultState, handlers);

export default IdentifierReducer;
