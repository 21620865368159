import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getPermissionsPolicy } from 'selectors/permissions';
import {
  FIELD_IS_HIDDEN,
  FIELD_IS_PROTECTED,
  FIELD_IS_UNPROTECTED,
  getFieldAuthorization,
} from 'utils/permissions';

type ComponentProps = {
  field: string;
  className?: string;
};

// @ts-ignore
const Redacted: React.FC<ComponentProps> = (props) => {
  const { field, className, children } = props;

  const [show, setShow] = useState(false);
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);

  const level = getFieldAuthorization(field, user.roles, policy);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation();
    event.preventDefault();
    setShow(true);
  };

  if (level === FIELD_IS_HIDDEN) {
    return 'Redacted';
  }

  if (level === FIELD_IS_UNPROTECTED || show) {
    return children;
  }

  if (level === FIELD_IS_PROTECTED) {
    return (
      <button
        type="button"
        onClick={handleClick}
        className={className || 'flex font-medium text-blue-500'}
      >
        Click to show
      </button>
    );
  }

  throw new Error(`Level '${level}' does not match any know level`);
};

export default Redacted;
