/* eslint-disable max-lines */
import { SimpleBlackLogo } from '@litlingo/react';
import { logoutUser, selectCustomer, setShowSkippedEnvelopes, setTree } from 'actions';
import SelectCustomerModal from 'components/CustomerList/SelectCustomerModal';
import LinkLookup from 'components/LinkLookup';
import Permissions from 'components/Permissions';
import {
  ANALYTIC_ICON,
  AUDIT_LOGS_ICON,
  CHANGE_CUSTOMER_ICON,
  CREATE_CUSTOMER_ICON,
  MANAGE_USERS_ICON,
  SETTINGS_ICON,
  SIGN_OUT_ICON,
} from 'constants/headerMenuIcons';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUser } from 'selectors/auth';
import { getPermissionsPolicy } from 'selectors/permissions';
import Transition from 'styles/Transition';
import type { Role } from 'types';
import { isActionAuthorized, meetsRolesReqs } from 'utils/permissions';
import { USER_HOME } from 'utils/userRoles';
import { v4 } from 'uuid';

const initialTree = {
  op: 'and',
  data: [],
  id: v4(),
};

const Navbar: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const buttonMenuRef = useRef<HTMLButtonElement | null>(null);

  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);

  const [menuIsOpen, menuSetIsOpen] = useState(false);
  const [campaignMenuIsOpen, setCampaignMenuIsOpen] = useState(false);
  const [eventsMenuIsOpen, setEventsMenuIsOpen] = useState(false);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);

  const navItemClass =
    'inline-flex items-center px-5 text-menu-item-navbar focus:outline-none transition duration-150 ease-in-out';
  const selectedClasses = 'border-litlingo-secondary-100 border-b-4 font-semibold';
  const unselectedClasses = 'border-transparent border-b-4 font-medium';

  const menuItemClass =
    'w-full text-left px-4 py-2 block text-menu-item-navbar focus:outline-none transition duration-150 ease-in-out';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (e: any): void => {
    const element = menuRef.current;
    const buttonElement = buttonMenuRef.current;
    if (
      menuRef &&
      buttonMenuRef &&
      element != null &&
      buttonElement != null &&
      !element.contains(e.target) &&
      !buttonElement.contains(e.target)
    ) {
      menuSetIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return (): void => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const borderBottomDecider = (item: string): boolean => {
    const root = location.pathname.split('/').filter(Boolean)[1] || '/';
    if (item === root || `${item}s` === root) return true;
    return false;
  };

  const getHome = (): string => {
    const homeList = Object.keys(USER_HOME);
    for (let i = 0; i < homeList.length; i += 1) {
      if (user.roles?.includes(homeList[i] as Role)) {
        return USER_HOME[homeList[i]].routeName;
      }
    }
    return USER_HOME.default.routeName;
  };

  const renderSettings = (): boolean => meetsRolesReqs(user.roles, ['super-admin', 'system-admin']);

  const renderLogo = (): JSX.Element => (
    <LinkLookup routeName={getHome()} className="inline-flex items-center">
      <div className="h-3/5 w-28">
        <SimpleBlackLogo />
      </div>
    </LinkLookup>
  );

  const toggleCustomerModal = (): void => {
    setIsCustomerModalOpen(!isCustomerModalOpen);
  };

  const handleSelectCustomer = (customer: { domain: string | null; uuid: string }): void => {
    if (customer.domain != null) {
      window.localStorage.setItem('__customer__', customer.domain);
      dispatch(selectCustomer({ domain: customer.domain }));
    }
  };

  const renderOnlyAssignments = (): boolean => {
    if (isActionAuthorized('reviewSets.reviewManager', user.roles, policy)) {
      return false;
    }
    if (isActionAuthorized('events.viewExplore', user.roles, policy)) {
      return false;
    }
    return true;
  };

  return (
    <>
      <nav
        className="sticky top-0 z-navbar bg-litlingo-gray-600 h-16"
        style={{ filter: 'drop-shadow(5px 6px 18px rgba(0, 0, 0, 0.1))' }}
      >
        <div className="max-w-7xl mx-auto px-4  lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              {renderLogo()}
              <div className="mb-px ml-36 flex">
                <Permissions action="analytics.list">
                  <LinkLookup
                    routeName="dashboard"
                    className={`${
                      borderBottomDecider('/') ? selectedClasses : unselectedClasses
                    } ${navItemClass}`}
                  >
                    Dashboard
                  </LinkLookup>
                </Permissions>

                <Permissions action="events.list">
                  {renderOnlyAssignments() ? (
                    <LinkLookup
                      routeName="review-set"
                      className={`${
                        borderBottomDecider('review-set') ? selectedClasses : unselectedClasses
                      } h-full ${navItemClass}`}
                    >
                      My Assignments
                    </LinkLookup>
                  ) : (
                    <div
                      className="relative transition duration-150 ease-in-out"
                      onMouseEnter={(): void => setEventsMenuIsOpen(true)}
                      onMouseLeave={(): void => setEventsMenuIsOpen(false)}
                    >
                      <LinkLookup
                        routeName={
                          user.roles.includes('super-reviewer') ? 'envelope-list' : 'review-set'
                        }
                        className={`${
                          borderBottomDecider('communication-envelopes') ||
                          borderBottomDecider('saved-searches') ||
                          borderBottomDecider('review-set-manager') ||
                          borderBottomDecider('review-set')
                            ? selectedClasses
                            : unselectedClasses
                        } h-full ${navItemClass}`}
                        onClick={(): void => {
                          dispatch(setTree({ tree: initialTree }));
                          dispatch(setShowSkippedEnvelopes(false));
                        }}
                      >
                        Explore
                        <Permissions action="reviewSets.list">
                          <svg
                            className="-mr-1 ml-1 h-5 w-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </Permissions>
                      </LinkLookup>

                      <Permissions action="reviewSets.list">
                        <Transition
                          show={eventsMenuIsOpen}
                          enter="transition ease-out duration-100"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <div className="-translate-x-1/2 left-50 absolute w-48 rounded-md shadow-lg z-50">
                            <div className="py-1 rounded-md bg-litlingo-gray-600 shadow-xs">
                              <Permissions action="events.viewExplore">
                                <LinkLookup
                                  type="button"
                                  role="link"
                                  className={menuItemClass}
                                  routeName={
                                    user.roles.includes('super-reviewer')
                                      ? 'envelope-list'
                                      : 'review-set'
                                  }
                                  onClick={(): void => {
                                    dispatch(setTree({ tree: initialTree }));
                                    dispatch(setShowSkippedEnvelopes(false));
                                  }}
                                >
                                  Explore
                                </LinkLookup>
                              </Permissions>
                              <Permissions action="reviewSets.reviewManager">
                                <LinkLookup
                                  routeName="review-set-manager"
                                  className={menuItemClass}
                                >
                                  Review Management
                                </LinkLookup>
                              </Permissions>

                              <LinkLookup routeName="review-set" className={menuItemClass}>
                                My Assignments
                              </LinkLookup>
                            </div>
                          </div>
                        </Transition>
                      </Permissions>
                    </div>
                  )}
                </Permissions>
              </div>
            </div>
            <div className="flex items-center">
              {/* FRO-1090 <button
                type="button"
                className="p-1 border-2 border-transparent text-white-900 rounded-full hover:text-gray-600 focus:outline-none focus:text-white-800 focus:bg-litlingo-gray-400-200 transition duration-150 ease-in-out"
                aria-label="Notifications"
              >
                <svg className="h-6 w-6" stroke="white" fill="none" viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg>
              </button> */}
              <div className="flex flex-row h-full">
                <Permissions action="campaigns.list">
                  <div
                    className="relative transition duration-150 ease-in-out"
                    onMouseEnter={(): void => setCampaignMenuIsOpen(true)}
                    onMouseLeave={(): void => setCampaignMenuIsOpen(false)}
                  >
                    <LinkLookup
                      routeName="global-models-list"
                      className={`${
                        borderBottomDecider('models') ||
                        borderBottomDecider('identifiers') ||
                        borderBottomDecider('test-viz') ||
                        borderBottomDecider('campaign') ||
                        borderBottomDecider('rules') ||
                        borderBottomDecider(`annotator`)
                          ? selectedClasses
                          : unselectedClasses
                      } h-full ${navItemClass}`}
                    >
                      Build
                      <Permissions action="annotators.list">
                        <svg className="-mr-1 ml-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Permissions>
                    </LinkLookup>
                    {/* TODO https://github.com/litlingo/frontend/issues/891 */}
                    <Permissions action="annotators.list">
                      <Transition
                        show={campaignMenuIsOpen}
                        enter="transition ease-out duration-100"
                        enterFrom="opacity-0 scale-100"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <div className="absolute left-50 -translate-x-1/2 w-48 rounded-md shadow-lg z-50">
                          <div className="py-1 rounded-md bg-litlingo-gray-600 shadow-xs">
                            <Permissions action="campaigns.list">
                              <LinkLookup routeName="global-models-list" className={menuItemClass}>
                                Use Cases
                              </LinkLookup>
                            </Permissions>
                            <Permissions action="annotators.list">
                              <LinkLookup
                                routeName="global-identifiers-list"
                                className={menuItemClass}
                              >
                                Identifiers
                              </LinkLookup>
                            </Permissions>
                            <Permissions action="communications.preview">
                              <LinkLookup routeName="global-test-viz" className={menuItemClass}>
                                Test
                              </LinkLookup>
                            </Permissions>

                            <div>
                              <div className="mt-1 mx-4 pt-1 mb-2 text-menu-item-navbar border-t border-litlingo-gray-3">
                                <span className="text-litlingo-gray-3">Legacy</span>
                              </div>
                              <Permissions action="campaigns.list">
                                <LinkLookup
                                  routeName="campaign-list"
                                  className={`${menuItemClass} pl-9`}
                                >
                                  Use Cases
                                </LinkLookup>
                              </Permissions>
                              <Permissions action="rules.list">
                                <LinkLookup
                                  routeName="rule-list"
                                  className={`${menuItemClass} pl-9`}
                                >
                                  Models
                                </LinkLookup>
                              </Permissions>
                              <Permissions action="annotators.list">
                                <LinkLookup
                                  routeName="annotator-list"
                                  className={`${menuItemClass} pl-9`}
                                >
                                  Identifiers
                                </LinkLookup>
                              </Permissions>
                              {/* <Permissions action="categories.list">
                              <LinkLookup routeName="categories-list" className={dropdownItemClass}>
                                Categories
                              </LinkLookup>
                            </Permissions> */}
                            </div>
                          </div>
                        </div>
                      </Transition>
                    </Permissions>
                  </div>
                </Permissions>
                <div className="ml-4 relative self-center">
                  <button
                    type="button"
                    onClick={(): void => menuSetIsOpen(!menuIsOpen)}
                    ref={buttonMenuRef}
                    className="flex text-sm border-2 rounded-lg focus:outline-none focus:border-gray-500 transition duration-150 ease-in-out border-litlingo-gray-200"
                    id="user-menu"
                    aria-label="User menu"
                    aria-haspopup="true"
                  >
                    <div className="px-2 py-1">
                      {user.customer && (
                        <span className="text-xss font-header font-bold leading-4 items-center justify-center pr-2 text-white">
                          {user.customer.name}
                        </span>
                      )}
                      {user.email && (
                        <span className="inline-flex h-8 w-8 items-center justify-center rounded-full text text--large text--bold leading-none text-litlingo-gray-800 bg-yellow-200">
                          {user.email[0].toUpperCase()}
                        </span>
                      )}
                    </div>
                  </button>
                  <Transition
                    show={menuIsOpen}
                    enter="transition ease-out duration-100 transform"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75 transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <div
                      ref={menuRef}
                      className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-50"
                    >
                      <div className="py-1 flex flex-col rounded-md bg-litlingo-gray-600 shadow-xs">
                        <tbody className="flex flex-col">
                          <Permissions action="customers.list">
                            <tr className="hover:bg-litlingo-gray-400-100">
                              <td className="pl-3 hover:bg-litlingo-gray-400-100">
                                {SETTINGS_ICON}
                              </td>
                              <td>
                                <LinkLookup
                                  data-skip
                                  className={menuItemClass}
                                  routeName="customer"
                                  data-testid="customer-profile-menu-link"
                                >
                                  Settings
                                </LinkLookup>
                              </td>
                            </tr>
                          </Permissions>
                          {!renderSettings() && (
                            <Permissions action="customers.showUsers">
                              <tr className="hover:bg-litlingo-gray-400-100">
                                <td className="pl-3 hover:bg-litlingo-gray-400-100">
                                  {MANAGE_USERS_ICON}
                                </td>
                                <td>
                                  <LinkLookup
                                    data-skip
                                    className={menuItemClass}
                                    routeName="users"
                                    data-testid="users-menu-link"
                                  >
                                    Users
                                  </LinkLookup>
                                </td>
                              </tr>
                            </Permissions>
                          )}

                          <Permissions action="analytics.seeAnalyticsPage">
                            <tr className="hover:bg-litlingo-gray-400-100">
                              <td className="pl-3 hover:bg-litlingo-gray-400-100">
                                {ANALYTIC_ICON}
                              </td>
                              <td>
                                <LinkLookup
                                  data-skip
                                  className={menuItemClass}
                                  routeName="analytics"
                                >
                                  Analytics
                                </LinkLookup>
                              </td>
                            </tr>
                          </Permissions>
                          <Permissions action="auditLogs.list">
                            <tr className="hover:bg-litlingo-gray-400-100">
                              <td className="pl-3 hover:bg-litlingo-gray-400-100">
                                {AUDIT_LOGS_ICON}
                              </td>
                              <td>
                                <LinkLookup
                                  data-skip
                                  className={menuItemClass}
                                  routeName="audit-logs"
                                  data-testid="audit-logs-menu-link"
                                >
                                  Audit logs
                                </LinkLookup>
                              </td>
                            </tr>
                          </Permissions>

                          <Permissions action="customers.changeCustomer">
                            <tr className="hover:bg-litlingo-gray-400-100">
                              <td className="pl-3">{CHANGE_CUSTOMER_ICON}</td>
                              <td>
                                <button
                                  type="button"
                                  className={menuItemClass}
                                  onClick={(): void => {
                                    menuSetIsOpen(false);
                                    setIsCustomerModalOpen(true);
                                  }}
                                >
                                  Change Workspaces
                                </button>
                              </td>
                            </tr>
                          </Permissions>
                          <Permissions action="customers.create">
                            <tr className="hover:bg-litlingo-gray-400-100">
                              <td className="pl-3">{CREATE_CUSTOMER_ICON}</td>
                              <td>
                                <LinkLookup
                                  routeName="create-customer"
                                  data-skip
                                  className={menuItemClass}
                                >
                                  Create Workspace
                                </LinkLookup>
                              </td>
                            </tr>
                          </Permissions>
                          <button
                            type="button"
                            className={`${menuItemClass} m-0 hover:bg-litlingo-gray-400-100`}
                            onClick={(): void => {
                              dispatch(logoutUser());
                            }}
                            data-skip
                          >
                            <tr>
                              <td>{SIGN_OUT_ICON}</td>
                              <td className="pl-3">Sign Out</td>
                            </tr>
                          </button>
                        </tbody>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
            </div>
            {/* <div className="-mr-2 flex items-center sm:hidden">
            <button
              type="button"
              onClick={() => menuSetIsOpen(!menuIsOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-litlingo-gray-400-100 focus:outline-none focus:bg-litlingo-gray-400-100 focus:text-gray-500 transition duration-150 ease-in-out"
            >
              <svg
                className={`${menuIsOpen ? 'hidden' : 'block'} h-6 w-6`}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`${menuIsOpen ? 'block' : 'hidden'} h-6 w-6`}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div> */}
          </div>
        </div>
      </nav>
      {isCustomerModalOpen && (
        <SelectCustomerModal
          toggleModalOpen={toggleCustomerModal}
          selectCustomer={handleSelectCustomer}
        />
      )}
    </>
  );
};

export default Navbar;
