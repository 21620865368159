import { createAction } from '@reduxjs/toolkit';
import type { API, ErrorObject, Identity, UUID } from 'types';

type FetchIdentitiesPayload = {
  limit?: number;
};
export const fetchIdentities = createAction<FetchIdentitiesPayload>('IDENTITIES/FETCH');
export const fetchIdentitiesRequest = createAction('IDENTITIES/FETCH_REQUEST');
export const fetchIdentitiesSuccess = createAction<API.WrappedAPIResponse<Identity>>(
  'IDENTITIES/FETCH_SUCCESS'
);
export const fetchIdentitiesFailure = createAction<ErrorObject>('IDENTITIES/FETCH_FAILURE');
export const fetchIdentitiesFulfill = createAction('IDENTITIES/FETCH_FULFILL');

export const upsertIdentity = createAction<Identity>('IDENTITIES/UPSERT');
export const upsertIdentityRequest = createAction('IDENTITIES/UPSERT_REQUEST');
export const upsertIdentitySuccess = createAction<Identity>('IDENTITIES/UPSERT_SUCCESS');
export const upsertIdentityFailure = createAction<ErrorObject>('IDENTITIES/UPSERT_FAILURE');
export const upsertIdentityFulfill = createAction('IDENTITIES/UPSERT_FULFILL');

export const deleteIdentity = createAction<{ uuid: UUID }>('IDENTITIES/DELETE');
export const deleteIdentityRequest = createAction('IDENTITIES/DELETE_REQUEST');
export const deleteIdentitySuccess = createAction<UUID>('IDENTITIES/DELETE_SUCCESS');
export const deleteIdentityFailure = createAction<ErrorObject>('IDENTITIES/DELETE_FAILURE');
export const deleteIdentityFulfill = createAction('IDENTITIES/DELETE_FULFILL');
