import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { fetchAllCustomers } from 'actions';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import type { UUID } from 'types';

type ComponentProps = {
  resourceId: UUID;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cloneResource: ActionCreatorWithOptionalPayload<any, string>;
  toggleShowCloneResourceModalOpen: () => void;
};

const CustomerSimpleList: React.FC<ComponentProps> = ({
  resourceId,
  cloneResource,
  toggleShowCloneResourceModalOpen,
}) => {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.auth.customerList);

  const sortedCustomers = [...customers];
  sortedCustomers.sort((a, b) =>
    a.name && b.name && a.name?.toLowerCase() > b.name.toLowerCase() ? 1 : -1
  );

  useEffect(() => {
    dispatch(fetchAllCustomers({ limit: -1 }));
  }, [dispatch]);

  const handleClick = (id: UUID): void => {
    dispatch(cloneResource({ customerId: id, resourceId }));
    toggleShowCloneResourceModalOpen();
  };

  return (
    <div style={{ maxHeight: '500px' }} className="my-2 overflow-y-scroll">
      <table className="min-w-full">
        <thead>
          <tr />
        </thead>
        <tbody className="bg-white text-left">
          {sortedCustomers &&
            sortedCustomers.map((customer) => (
              <tr
                key={customer.uuid}
                onClick={(): void => handleClick(customer.uuid)}
                className="border-t border-gray-200 hover:bg-gray-100 table-row"
              >
                <td className="px-6 py-4 border-b border-gray-200">
                  <div className="flex items-center">
                    <div>
                      <div className="text">{customer.name}</div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomerSimpleList;
