import EmptyTableState from 'components/EmptyTableState';
import ListFooter from 'components/ListFooter';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getFetchAllTemplatesLoading,
  getTemplates,
  getTemplatesTotalCount,
} from 'selectors/templates';
import type { Template } from 'types';
import TemplateListItem from './TemplateListItem';

type ComponentProps = {
  handleChangeTemplate: (template: Template) => void;
};

const TemplatesList: React.FC<ComponentProps> = ({ handleChangeTemplate }) => {
  const loading = useSelector(getFetchAllTemplatesLoading);
  const templates = useSelector(getTemplates);

  return (
    <div className="table-wrapper">
      <table className="table-wrapper__table">
        <thead>
          <tr>
            <th className="table-wrapper__th w-1/5">Name</th>
            <th className="table-wrapper__th">Body</th>
            <th className="table-wrapper__th w-1/5">Actions</th>
          </tr>
        </thead>
        <tbody className="table-wrapper__tbody">
          {!loading && templates.length === 0 ? (
            <EmptyTableState message="There are no templates to display" colCount="3" />
          ) : (
            templates &&
            templates.map((template) => (
              <TemplateListItem
                template={template}
                key={template.uuid}
                handleChangeTemplate={handleChangeTemplate}
              />
            ))
          )}
        </tbody>
      </table>
      <ListFooter
        resourceStateName={resourceQueryParamName.templates}
        resourceName="template"
        getResourceList={getTemplates}
        getResourceTotalCount={getTemplatesTotalCount}
        getResourceLoading={getFetchAllTemplatesLoading}
      />
    </div>
  );
};

export default TemplatesList;
