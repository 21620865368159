import Tooltip from 'components/Tooltip';
import React from 'react';
import type { EntityTag } from 'types';

type ComponentProps = {
  tag: EntityTag;
};

const EnvelopeChip: React.FC<ComponentProps> = ({ tag }) => {
  if (!tag.tag_value) return null;

  return (
    <Tooltip content={`${tag.tag_value.value} tag`} redesign>
      <svg key={tag.uuid} width={12} height={12} viewBox="0 0 12 12">
        <circle r={6} cx={6} cy={6} fill={tag.tag_value.color} />
      </svg>
    </Tooltip>
  );
};

export default EnvelopeChip;
