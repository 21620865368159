/* eslint-disable camelcase */
import { createSelector } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { ModalAfterReview } from 'actions';
import { SummaryTagType } from 'components/Envelope/SummaryTag';
import { GlobalState } from 'reducers';
import { EnvelopeReviewRule, SectionType, SelectedSentenceType } from 'reducers/envelopeReview';
import { normalizeRuleConfig } from 'reducers/util/flattenConfigData';
import { getSurroundingContextRulesIds } from 'selectors/auth';
import type {
  AnnotatorRelationship,
  Attachment,
  Communication,
  CommunicationEnvelope,
  CommunicationEnvelopeSummary,
  CommunicationWithEvents,
  EntityTag,
  Event,
  ModeOfSpeech,
  MRuleConfig,
  MRuleConfigNode,
  MRuleConfigRelationship,
  NormalizedResource,
  Rule,
  RuleIdentifier,
  Selector,
  UUID,
} from 'types';
import { TERMINAL_ACTIONS } from 'utils/activityLogLookup';
import sortCommunications from 'utils/communications';

export const TRANSLATION_COMM_TYPES = [
  'translation',
  'email_translation',
  'translation_translation',
  'o365_translation',
  'direct_translation',
  'channel_translation',
];

export const ATTACHMENT_TRANSLATED_TYPES = ['attachment_translation'];
export const ATTACHMENT_TYPES = ['attachment', 'attachment_translation'];

const getSelectedEnvelope: Selector<CommunicationEnvelope | null> = (state) =>
  state.envelopeReview?.selectedEnvelope;

export const getSelectedRule: Selector<EnvelopeReviewRule | null> = (state) =>
  state.envelopeReview?.selectedRule;

export const getSelectedCampaign: Selector<string | null> = (state) =>
  state.envelopeReview?.selectedCampaign;

export const getTags: Selector<EntityTag[] | null> = (state) => state.envelopeReview?.tags;

export const getDeclaredStatus: Selector<string | null> = (state) =>
  state.envelopeReview?.declaredStatus;

export const getSelectedReviewer: Selector<string | null> = (state) =>
  state.envelopeReview.selectedReviewer;

export const getCodingReviewStatus: Selector<boolean> = (state) =>
  state.envelopeReview.codingReview;

export const getBulkReviewStatus: Selector<boolean> = (state) => state.envelopeReview.bulkReview;

export const getSelectedThreads: Selector<UUID[]> = (state) => state.envelopeReview.selectedThreads;

export const getModalAfterReview: Selector<ModalAfterReview> = (state) =>
  state.envelopeReview.modalAfterReview;

export const getHasChanges: Selector<boolean> = (state) => {
  const envelope = state.envelopeReview.selectedEnvelope;
  const { tags } = state.envelopeReview;
  const newReviewValue = state.envelopeReview.declaredStatus;

  if (!envelope) return false;

  if (tags.length !== envelope?.tags?.length) return true;

  const differentTags = !tags.every((tag) =>
    envelope?.tags?.some((t) => t.tag_value?.value === tag.tag_value?.value)
  );

  if (differentTags) return true;

  if (newReviewValue !== envelope.review_value) return true;

  return false;
};

export const getBulkReviewHasChanges: Selector<boolean> = (state) => {
  const envelope = state.envelopeReview.selectedEnvelope;
  const threads = state.envelopes.envelopeThread;

  const reviewValuesThread = Object.values(threads).map((env) => env.envelope.review_value);

  const { tags } = state.envelopeReview;
  const newReviewValue = state.envelopeReview.declaredStatus;

  if (tags.length !== envelope?.tags?.length) return true;

  const differentTags = !tags.every((tag) =>
    envelope?.tags?.some((t) => t.tag_value?.value === tag.tag_value?.value)
  );

  if (differentTags) return true;

  if (newReviewValue !== envelope.review_value) return true;

  if (reviewValuesThread.some((status) => status !== newReviewValue)) return true;

  return false;
};

export const getEnvelopeCommunications: Selector<Communication[] | undefined> = (state) =>
  state.envelopeReview.selectedEnvelope?.communications;

export const getEnvelopeEvents: Selector<Event[] | undefined> = (state) =>
  state.envelopeReview.selectedEnvelope?.events;

export const getCommunicationsWithEvents: Selector<CommunicationWithEvents[] | null> =
  createSelector([getEnvelopeCommunications, getEnvelopeEvents], (communications, events) => {
    if (!events || !communications) return null;

    const comms = communications
      .filter((c) => c.communication_type !== 'attachment')
      .filter((c) => !TRANSLATION_COMM_TYPES.includes(c.communication_type))
      .map((c) => {
        let evs: Event[] | null = [];
        evs = events.filter((e) => !e.hidden).filter((e) => c.uuid === e.communication_uuid);
        return {
          ...c,
          events: evs,
        };
      })
      .sort((a, b): number => sortCommunications(a, b, false));

    return comms;
  });

export const getEnvelopeVersions: Selector<Communication[]> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
      state.envelopeReview.selectedEnvelope,
  ],
  (envelope) => {
    if (envelope?.communications) {
      return envelope.communications
        .filter(
          (c) =>
            c.communication_type !== 'attachment' &&
            !ATTACHMENT_TRANSLATED_TYPES.includes(c.communication_type) &&
            !TRANSLATION_COMM_TYPES.includes(c.communication_type)
        )
        .sort(sortCommunications);
    }

    return [];
  }
);

export const getLastVersion: Selector<Communication | null> = createSelector(
  [getEnvelopeVersions],
  (communication) => communication[0]
);

export const getEnvelopeTranslations: Selector<Communication[]> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
      state.envelopeReview.selectedEnvelope,
  ],
  (envelope) => {
    if (envelope?.communications) {
      return envelope.communications
        .filter((c) => TRANSLATION_COMM_TYPES.includes(c.communication_type))
        .sort((a, b) => sortCommunications(a, b, true))
        .slice(0, 1);
    }
    return [];
  }
);

export const getAttachmentTranslations: Selector<Communication[]> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
      state.envelopeReview.selectedEnvelope,
  ],
  (envelope) => {
    if (envelope?.communications) {
      return envelope.communications.filter((c) =>
        ATTACHMENT_TRANSLATED_TYPES.includes(c.communication_type)
      );
    }
    return [];
  }
);

export const getSelectedEnvelopeAttachments: Selector<Attachment[]> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
      state.envelopeReview.selectedEnvelope,
  ],
  (envelope) => {
    if (envelope?.attachments) {
      return envelope.attachments;
    }

    return [];
  }
);

export const getCommunicationFromAttachment: Selector<Communication | null, [string]> =
  createSelector(
    [getEnvelopeCommunications, (_state, attachmentName): string => attachmentName],
    (envelopeComms, attachmentName) => {
      if (envelopeComms) {
        const commAttachment = envelopeComms.filter((c) => {
          if (
            c.meta_data?.filename?.split('/').pop() === attachmentName &&
            c.communication_type === 'attachment'
          ) {
            return true;
          }
          return false;
        });
        if (commAttachment && commAttachment[0]) {
          return commAttachment[0];
        }
      }

      return null;
    }
  );

export const getCommunicationFromId: Selector<Communication | null, [string]> = createSelector(
  [getEnvelopeCommunications, (_state, communicationId): string => communicationId],
  (envelopeComms, communicationId) => {
    if (envelopeComms) {
      const communications = envelopeComms.filter((c) => {
        if (c.uuid === communicationId) {
          return true;
        }
        return false;
      });
      if (communications && communications[0]) {
        return communications[0];
      }
    }

    return null;
  }
);

export const getOriginalCommunication: Selector<Communication | null, [string]> = createSelector(
  [getEnvelopeCommunications, (_state, parentId): string => parentId],
  (envelopeComms, parentId) => {
    if (envelopeComms) {
      const originalComm = envelopeComms.filter((c) => c.uuid === parentId);
      return originalComm[0];
    }
    return null;
  }
);

export const getSelectedCommunicationWithEvents: Selector<CommunicationWithEvents | null> = (
  state
) => {
  const envelope = state.envelopeReview.selectedEnvelope;
  const communication = state.communication.selectedCommunication;

  if (!envelope || !envelope.events || !communication) return null;

  const communicationEvents = envelope.events.filter(
    (e) => communication.uuid === e.communication_uuid
  );

  return { ...communication, events: communicationEvents };
};

export const getTranslationCommunication: Selector<Communication | null | undefined, [string]> = (
  state,
  attachment
) => {
  const commUuid = state.communication.selectedCommunication?.uuid;
  const envelopeComms = state.envelopeReview.selectedEnvelope?.communications;

  if (commUuid && envelopeComms) {
    const tranlatedComm = envelopeComms.filter(
      (c) => c.meta_data.parent_communication_uuid === commUuid
    );
    if (tranlatedComm) {
      if (attachment) {
        return tranlatedComm.find((c) => c.communication_type === 'attachment_translation');
      }
      if (!attachment) {
        return tranlatedComm.find((c) => TRANSLATION_COMM_TYPES.includes(c.communication_type));
      }
    }
    return null;
  }
  return null;
};

export const getTranslationFromCommunication: Selector<
  Communication | null | undefined,
  [string]
> = (state, uuid) => {
  const envelopeComms = state.envelopeReview.selectedEnvelope?.communications;

  if (uuid && envelopeComms) {
    const tranlatedComm = envelopeComms.filter(
      (c) => c.meta_data.parent_communication_uuid === uuid
    );
    if (tranlatedComm) {
      return tranlatedComm.find((c) => TRANSLATION_COMM_TYPES.includes(c.communication_type));
    }
    return null;
  }
  return null;
};

export const getCommunicationTranslationFromAttachment: Selector<Communication | null, [string]> =
  createSelector(
    [getEnvelopeCommunications, (_state, attachmentName): string => attachmentName],
    (envelopeComms, attachmentName) => {
      if (envelopeComms) {
        const commAttachment = envelopeComms.filter((c) => {
          if (
            c.meta_data?.filename?.split('/').pop() === attachmentName &&
            c.communication_type === 'attachment_translation'
          ) {
            return true;
          }
          return false;
        });
        if (commAttachment && commAttachment[0]) {
          return commAttachment[0];
        }
      }

      return null;
    }
  );

export const getEnvelopeHasAttachmentHit: Selector<boolean> = (state) => {
  const events = state.envelopeReview.selectedEnvelope?.events;
  const communications = state.envelopeReview.selectedEnvelope?.communications;

  if (!events) return false;
  if (!communications) return false;

  return events.some((e) =>
    communications.some(
      (c) => e.communication_uuid === c.uuid && c.communication_type === 'attachment'
    )
  );
};

export const getIsAttachmentHit: Selector<boolean, [UUID]> = (state, ruleId) => {
  const events = state.envelopeReview.selectedEnvelope?.events;
  const communications = state.envelopeReview.selectedEnvelope?.communications;

  if (!events) return false;
  if (!communications) return false;

  const ruleEvents = events.filter((e) => e.rule_uuid === ruleId);

  return ruleEvents.some((e) =>
    communications.some(
      (c) => e.communication_uuid === c.uuid && c.communication_type === 'attachment'
    )
  );
};

export const getIsMessageHit: Selector<boolean, [UUID]> = (state, ruleId) => {
  const events = state.envelopeReview.selectedEnvelope?.events;
  const communications = state.envelopeReview.selectedEnvelope?.communications;

  if (!events) return false;
  if (!communications) return false;

  const ruleEvents = events.filter((e) => e.rule_uuid === ruleId);

  return ruleEvents.some((e) =>
    communications.some(
      (c) => e.communication_uuid === c.uuid && c.communication_type !== 'attachment'
    )
  );
};

export const getRuleHits: Selector<
  Record<string, { attachmentHit: boolean; messageHit: boolean }>
> = createSelector([getEnvelopeCommunications, getEnvelopeEvents], (communications, events) => {
  if (!communications) return {};
  if (!events) return {};

  const hits: Record<string, { attachmentHit: boolean; messageHit: boolean }> = {};

  events.forEach((e) => {
    hits[e.rule_uuid] = {
      attachmentHit: false,
      messageHit: false,
    };
  });

  Object.keys(hits).forEach((r) => {
    const ruleEvents = events.filter((e) => e.rule_uuid === r);

    if (
      ruleEvents.some((e) =>
        communications.some(
          (c) => e.communication_uuid === c.uuid && c.communication_type !== 'attachment'
        )
      )
    ) {
      hits[r].messageHit = true;
    }
    if (
      ruleEvents.some((e) =>
        communications.some(
          (c) => e.communication_uuid === c.uuid && c.communication_type === 'attachment'
        )
      )
    ) {
      hits[r].attachmentHit = true;
    }
  });

  return hits;
});

export type NormalizedCampaignWithRules = {
  [key: string]: CampaignWithRules;
};

export type CampaignWithRules = {
  campaignName: string;
  campaignPriority: number;
  rules: (EnvelopeReviewRule & { attachmentHit: boolean; messageHit: boolean })[];
};

export const getCampaignsRules: Selector<NormalizedCampaignWithRules | null> = createSelector(
  [getEnvelopeEvents, getSurroundingContextRulesIds, getEnvelopeCommunications, getRuleHits],
  (events, excludedRules, envelopeComms, ruleHits) => {
    const campaigns: NormalizedCampaignWithRules = {};

    if (!events) return null;

    const nonHiddenEvents = events
      .filter((e) => e.hidden === false)
      .filter(
        (e) =>
          envelopeComms?.find((c) => c.uuid === e.communication_uuid)?.communication_type !==
          'attachment'
      )
      .sort((a, b) => {
        if (a.campaign && b.campaign) {
          if (a.campaign?.name < b.campaign?.name) {
            return -1;
          }
          if (a.campaign?.name > b.campaign?.name) {
            return 1;
          }
        }
        return 0;
      });

    nonHiddenEvents.forEach((e) => {
      if (e.campaign_uuid && e.campaign) {
        campaigns[e.campaign_uuid] = {
          campaignName: e.campaign.name,
          campaignPriority: e.campaign.priority || 0,
          rules: [],
        };
      }
    });

    nonHiddenEvents.forEach((e) => {
      if (e.campaign_uuid) {
        if (e.rule_uuid && e.rule) {
          if (
            !campaigns[e.campaign_uuid].rules.some(
              (r) => r.uuid === e.rule?.uuid || r.rule_group_uuid === e.rule_uuid
            ) &&
            !excludedRules.includes(e.rule_uuid)
          ) {
            const ruleToAdd = {
              ...e.rule,
              attachmentHit: ruleHits[e.rule_uuid].attachmentHit || false,
              messageHit: ruleHits[e.rule_uuid].messageHit || false,
            };

            campaigns[e.campaign_uuid].rules.push(ruleToAdd);
          }
        }
      }
    });

    Object.entries(campaigns).forEach(
      ([key, value]) => value.rules.length === 0 && delete campaigns[key]
    );

    return campaigns;
  }
);

export const getCampaignsRulesFromAttachment: Selector<
  NormalizedCampaignWithRules | null,
  [string]
> = createSelector(
  [
    getEnvelopeEvents,
    getSurroundingContextRulesIds,
    getRuleHits,
    (state: GlobalState, attachmentName: string): Communication | null =>
      getCommunicationFromAttachment(state, attachmentName),
  ],
  (events, excludedRules, ruleHits, communication) => {
    const campaigns: NormalizedCampaignWithRules = {};

    if (!events || !communication) return null;

    const nonHiddenEvents = events
      .filter((e) => e.hidden === false)
      .filter((e) => e.communication_uuid === communication.uuid)
      .sort((a, b) => {
        if (a.campaign && b.campaign) {
          if (a.campaign?.name < b.campaign?.name) {
            return -1;
          }
          if (a.campaign?.name > b.campaign?.name) {
            return 1;
          }
        }
        return 0;
      });

    nonHiddenEvents.forEach((e) => {
      if (e.campaign_uuid && e.campaign) {
        campaigns[e.campaign_uuid] = {
          campaignName: e.campaign.name,
          campaignPriority: e.campaign.priority || 0,
          rules: [],
        };
      }
    });

    nonHiddenEvents.forEach((e) => {
      if (e.campaign_uuid) {
        if (e.rule_uuid && e.rule) {
          if (
            !campaigns[e.campaign_uuid].rules.some(
              (r) => r.uuid === e.rule?.uuid || r.rule_group_uuid === e.rule_uuid
            ) &&
            !excludedRules.includes(e.rule_uuid)
          ) {
            const ruleToAdd = {
              ...e.rule,
              attachmentHit: ruleHits[e.rule_uuid].attachmentHit || false,
              messageHit: ruleHits[e.rule_uuid].messageHit || false,
            };

            campaigns[e.campaign_uuid].rules.push(ruleToAdd);
          }
        }
      }
    });

    Object.entries(campaigns).forEach(
      ([key, value]) => value.rules.length === 0 && delete campaigns[key]
    );

    return campaigns;
  }
);

export const getCampaignsRulesFromCommunication: Selector<
  NormalizedCampaignWithRules | null,
  [string]
> = createSelector(
  [
    getEnvelopeEvents,
    getSurroundingContextRulesIds,
    getRuleHits,
    (_state: GlobalState, communicationId: string): string | null => communicationId,
  ],
  (events, excludedRules, ruleHits, communicationId) => {
    const campaigns: NormalizedCampaignWithRules = {};

    if (!events || !communicationId) return null;

    const nonHiddenEvents = events
      .filter((e) => e.hidden === false)
      .filter((e) => e.communication_uuid === communicationId)
      .sort((a, b) => {
        if (a.campaign && b.campaign) {
          if (a.campaign?.name < b.campaign?.name) {
            return -1;
          }
          if (a.campaign?.name > b.campaign?.name) {
            return 1;
          }
        }
        return 0;
      });

    nonHiddenEvents.forEach((e) => {
      if (e.campaign_uuid && e.campaign) {
        campaigns[e.campaign_uuid] = {
          campaignName: e.campaign.name,
          campaignPriority: e.campaign.priority || 0,
          rules: [],
        };
      }
    });

    nonHiddenEvents.forEach((e) => {
      if (e.campaign_uuid) {
        if (e.rule_uuid && e.rule) {
          if (
            !campaigns[e.campaign_uuid].rules.some(
              (r) => r.uuid === e.rule?.uuid || r.rule_group_uuid === e.rule_uuid
            ) &&
            !excludedRules.includes(e.rule_uuid)
          ) {
            const ruleToAdd = {
              ...e.rule,
              attachmentHit: ruleHits[e.rule_uuid].attachmentHit || false,
              messageHit: ruleHits[e.rule_uuid].messageHit || false,
            };

            campaigns[e.campaign_uuid].rules.push(ruleToAdd);
          }
        }
      }
    });

    Object.entries(campaigns).forEach(
      ([key, value]) => value.rules.length === 0 && delete campaigns[key]
    );

    return campaigns;
  }
);

export const getHighRiskCampaignsRulesFromCommunication: Selector<
  NormalizedCampaignWithRules | null,
  [string]
> = createSelector(
  [
    getEnvelopeEvents,
    getSurroundingContextRulesIds,
    getRuleHits,
    (_state: GlobalState, communicationId: string): string | null => communicationId,
  ],
  (events, excludedRules, ruleHits, communicationId) => {
    const campaigns: NormalizedCampaignWithRules = {};

    if (!events || !communicationId) return null;

    const nonHiddenEvents = events
      .filter((e) => e.hidden === false)
      .filter((e) => e.communication_uuid === communicationId)
      .filter((e) =>
        e.actions?.some(
          (a) =>
            a.type === 'show_modal' ||
            // @ts-ignore
            a.kind === 'show_modal' ||
            a.type === 'o365_teams_tombstone' ||
            // @ts-ignore
            a.kind === 'o365_teams_tombstone'
        )
      )
      .sort((a, b) => {
        if (a.campaign && b.campaign) {
          if (a.campaign?.name < b.campaign?.name) {
            return -1;
          }
          if (a.campaign?.name > b.campaign?.name) {
            return 1;
          }
        }
        return 0;
      });

    nonHiddenEvents.forEach((e) => {
      if (e.campaign_uuid && e.campaign) {
        campaigns[e.campaign_uuid] = {
          campaignName: e.campaign.name,
          campaignPriority: e.campaign.priority || 0,
          rules: [],
        };
      }
    });

    nonHiddenEvents.forEach((e) => {
      if (e.campaign_uuid) {
        if (e.rule_uuid && e.rule) {
          if (
            !campaigns[e.campaign_uuid].rules.some(
              (r) => r.uuid === e.rule?.uuid || r.rule_group_uuid === e.rule_uuid
            ) &&
            !excludedRules.includes(e.rule_uuid)
          ) {
            const ruleToAdd = {
              ...e.rule,
              attachmentHit: ruleHits[e.rule_uuid].attachmentHit || false,
              messageHit: ruleHits[e.rule_uuid].messageHit || false,
            };

            campaigns[e.campaign_uuid].rules.push(ruleToAdd);
          }
        }
      }
    });

    Object.entries(campaigns).forEach(
      ([key, value]) => value.rules.length === 0 && delete campaigns[key]
    );

    return campaigns;
  }
);

export const getCampaignsRulesForHistory: Selector<NormalizedCampaignWithRules | null> =
  createSelector(
    [getEnvelopeEvents, getSurroundingContextRulesIds, getRuleHits],
    (events, excludedRules, ruleHits) => {
      const campaigns: NormalizedCampaignWithRules = {};

      if (!events) return null;

      const nonHiddenEvents = events
        .filter((e) => e.hidden === false)
        .filter((e) => e.communication?.communication_type !== 'attachment')
        .filter((e) => {
          if (
            e &&
            e.communication &&
            e.communication?.meta_data &&
            'source' in (e.communication?.meta_data as Record<string, unknown>)
          ) {
            return true;
          }
          return e.actions?.some(
            (a) =>
              a.type === 'show_modal' ||
              // @ts-ignore
              a.kind === 'show_modal' ||
              a.type === 'o365_teams_tombstone'
          );
        })
        .sort((a, b) => {
          if (a.campaign && b.campaign) {
            if (a.campaign?.name < b.campaign?.name) {
              return -1;
            }
            if (a.campaign?.name > b.campaign?.name) {
              return 1;
            }
          }
          return 0;
        });

      nonHiddenEvents.forEach((e) => {
        if (e.campaign_uuid && e.campaign) {
          campaigns[e.campaign_uuid] = {
            campaignName: e.campaign.name,
            campaignPriority: e.campaign.priority || 0,
            rules: [],
          };
        }
      });

      nonHiddenEvents.forEach((e) => {
        if (e.campaign_uuid) {
          if (e.rule_uuid && e.rule) {
            if (
              !campaigns[e.campaign_uuid].rules.some(
                (r) => r.uuid === e.rule?.uuid || r.rule_group_uuid === e.rule_uuid
              ) &&
              !excludedRules.includes(e.rule_uuid)
            ) {
              const ruleToAdd = {
                ...e.rule,
                attachmentHit: ruleHits[e.rule_uuid].attachmentHit || false,
                messageHit: ruleHits[e.rule_uuid].messageHit || false,
              };

              campaigns[e.campaign_uuid].rules.push(ruleToAdd);
            }
          }
        }
      });

      Object.entries(campaigns).forEach(
        ([key, value]) => value.rules.length === 0 && delete campaigns[key]
      );

      return campaigns;
    }
  );

export const getCampaignRulesFromSummaries: Selector<NormalizedCampaignWithRules | null> =
  createSelector(
    [
      (state: GlobalState): GlobalState['envelopes']['envelopeThread'] =>
        state.envelopes.envelopeThread,
      getRuleHits,
      getSelectedEnvelope,
      getSurroundingContextRulesIds,
    ],
    (threads, ruleHits, selectedEnvelope, excludedRules) => {
      const campaigns: NormalizedCampaignWithRules = {};
      let events: CommunicationEnvelopeSummary['events'] = [];

      if (Object.values(threads).length <= 1) return null;

      Object.values(threads)
        .filter(
          (thread) =>
            thread.envelope.platform_thread_guid === selectedEnvelope?.platform_thread_guid &&
            thread.envelope.uuid !== selectedEnvelope?.uuid
        )
        .forEach((envelope) => {
          events = [...events, ...envelope.events];
        });

      if (!events) return null;

      const nonHiddenEvents = events.sort((a, b) => {
        if (a.campaign_name && b.campaign_name) {
          if (a.campaign_name < b.campaign_name) {
            return -1;
          }
          if (a.campaign_name > b.campaign_name) {
            return 1;
          }
        }
        return 0;
      });

      nonHiddenEvents.forEach((e) => {
        if (e.campaign_uuid && e.campaign_name) {
          campaigns[e.campaign_uuid] = {
            campaignName: e.campaign_name,
            campaignPriority: e.campaign_priority || 0,
            rules: [],
          };
        }
      });

      nonHiddenEvents.forEach((e) => {
        if (e.campaign_uuid) {
          if (e.rule_uuid && e.rule_name) {
            if (
              !campaigns[e.campaign_uuid].rules.some((r) => r.uuid === e.rule_uuid) &&
              !excludedRules.includes(e.rule_uuid)
            ) {
              const ruleToAdd = {
                name: e.rule_name,
                uuid: e.rule_uuid,
                attachmentHit: ruleHits[e.rule_uuid]?.attachmentHit || false,
                messageHit: ruleHits[e.rule_uuid]?.messageHit || false,
              };

              campaigns[e.campaign_uuid].rules.push(ruleToAdd);
            }
          }
        }
      });

      Object.entries(campaigns).forEach(
        ([key, value]) => value.rules.length === 0 && delete campaigns[key]
      );

      return campaigns;
    }
  );

export const getBackButtonHitted: Selector<boolean> = (state) => state.envelopeReview.backButtonHit;

export const getSelectedSection: Selector<SectionType> = (state) =>
  state.envelopeReview.selectedSection;

export const getReviewOnSkipView: Selector<boolean> = (state) =>
  state.envelopeReview.reviewOnSkipView;

export const getSummaryTag: Selector<SummaryTagType, [UUID]> = createSelector(
  [getSelectedEnvelope, getCampaignsRulesForHistory, getHighRiskCampaignsRulesFromCommunication],
  (envelope, historyCampaignsRules, lastCampaignsRules) => {
    if (envelope?.communications?.length === 1) return 'none';

    if (envelope && envelope.platform !== 'o365_teams') {
      if (
        !envelope.action_summary?.some(
          (a) =>
            Object.keys(TERMINAL_ACTIONS).includes(a.type) ||
            // @ts-ignore
            Object.keys(TERMINAL_ACTIONS).includes(a.kind)
        ) &&
        envelope.integration_type === 'app' &&
        !envelope.communications?.some((c) => c.meta_data.folder_id === 'sentitems')
      ) {
        return 'abandoned';
      }
    }

    if (!lastCampaignsRules) return 'reduced-all';

    if (historyCampaignsRules && lastCampaignsRules) {
      let historyRules = Object.values(historyCampaignsRules).reduce<string[]>(
        (acc, val) => [...acc, ...val.rules.map((r) => r.uuid)],
        []
      );
      historyRules = [...new Set(historyRules)];

      let lastRules = Object.values(lastCampaignsRules).reduce<string[]>(
        (acc, val) => [...acc, ...val.rules.map((r) => r.uuid)],
        []
      );
      lastRules = [...new Set(lastRules)];

      if (lastRules.length === 0) return 'reduced-all';
      if (lastRules.length < historyRules.length) return 'reduced-some';
      if (historyRules.some((r) => !lastRules.includes(r))) return 'reduced-some';
    }

    return 'high-risk';
  }
);

export const getRuleFlips: Selector<number, [UUID]> = createSelector(
  [getCampaignsRulesForHistory, getHighRiskCampaignsRulesFromCommunication],
  (historyCampaignsRules, lastCampaignsRules) => {
    if (historyCampaignsRules && lastCampaignsRules) {
      let historyRules = Object.values(historyCampaignsRules).reduce<string[]>(
        (acc, val) => [...acc, ...val.rules.map((r) => r.uuid)],
        []
      );
      historyRules = [...new Set(historyRules)];

      let lastRules = Object.values(lastCampaignsRules).reduce<string[]>(
        (acc, val) => [...acc, ...val.rules.map((r) => r.uuid)],
        []
      );
      lastRules = [...new Set(lastRules)];

      return historyRules.length - lastRules.length;
    }
    return 0;
  }
);

export const getCommentError: Selector<boolean> = (state) => state.envelopeReview.errors.comment;

export const getEventRulesToShow: Selector<Rule[]> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
      state.envelopeReview.selectedEnvelope,
    (state: GlobalState): string[] => state.envelopeReview.rulesToShow,
  ],
  (selectedEnvelope, rulesToShow) => {
    if (selectedEnvelope) {
      const { events } = selectedEnvelope;

      if (!events || events?.length === 0) return [];

      const rules = events.reduce<Rule[]>((acc, val) => {
        if (val.rule && !acc.find((r) => r.uuid === val.rule?.uuid)) {
          return [...acc, val.rule];
        }

        return acc;
      }, []);

      return rules.filter(
        // @ts-ignore
        (r) => rulesToShow.includes(r.uuid) || rulesToShow.includes(r.rule_group_uuid)
      );
    }
    return [];
  }
);

export const getEventRule: Selector<Rule | null, [string]> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
      state.envelopeReview.selectedEnvelope,
    (_state: GlobalState, ruleId: string): string => ruleId,
  ],
  (selectedEnvelope, ruleId) => {
    if (selectedEnvelope) {
      const { events } = selectedEnvelope;

      if (!events || events?.length === 0) return null;

      const event = events.find((e) => e.rule && e.rule.uuid === ruleId);
      if (event && event.rule) {
        return event.rule;
      }
    }
    return null;
  }
);

export const getEventRuleConfigItems: Selector<NormalizedResource<MRuleConfigNode>> =
  createSelector(
    [
      (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
        state.envelopeReview.selectedEnvelope,
    ],
    (selectedEnvelope) => {
      const event = selectedEnvelope?.events?.[0];
      const rule = event?.rule;

      if (event && rule && rule.config.uuid) {
        const rootConfigId = rule.config.uuid;

        const config = {
          ...rule.config,
          rootConfigId,
        };

        const { ruleConfig } = normalizeRuleConfig({
          config,
          // @ts-expect-error
          rule,
        });

        return ruleConfig;
      }
      return {};
    }
  );

export const getEventRuleConfigRelationships: Selector<NormalizedResource<AnnotatorRelationship>> =
  createSelector(
    [
      (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
        state.envelopeReview.selectedEnvelope,
    ],
    (selectedEnvelope) => {
      const event = selectedEnvelope?.events?.[0];
      const rule = event?.rule;

      if (event && rule && rule.config.uuid) {
        const rootConfigId = rule.config.uuid;

        const config = {
          ...rule.config,
          rootConfigId,
        };

        const { relationships } = normalizeRuleConfig({
          config,
          // @ts-expect-error
          rule,
        });

        return relationships as NormalizedResource<AnnotatorRelationship>;
      }
      return {};
    }
  );

export const getEventRuleIdentifiers: Selector<RuleIdentifier[]> = (state) =>
  // @ts-expect-error
  state.envelopeReview.selectedEnvelope?.events?.[0].rule?.identifiers || [];

export const getRuleConfigId: Selector<string | null> = (state) =>
  state.envelopeReview.selectedEnvelope?.events?.[0].rule?.config.uuid || null;

export const getEventConfigRuleAsArray: Selector<MRuleConfig[]> = createSelector(
  [
    getEventRuleConfigItems,
    getEventRuleConfigRelationships,
    getEventRuleIdentifiers,
    getRuleConfigId,
  ],
  (config, relationshipState, identifiers, rootConfigId) => {
    const traverseTree = (curIdNode: UUID, level: number, parent: UUID[]): MRuleConfig[] => {
      const curNode = config[curIdNode];
      if (!curNode) {
        return [];
      }
      const { id, name, description, color } = curNode;

      let negated;
      let mode_of_speech: ModeOfSpeech | undefined;
      let annotatorId: UUID | undefined;
      let identifierId: UUID | undefined;
      let modifiers;
      let nodeGroups;

      if (curNode.typeOfConfig === 'ANNOTATION_MATCH') {
        mode_of_speech = curNode.mode_of_speech;
        negated = curNode.negated;
        annotatorId = curNode.annotatorId;

        const identifier = identifiers.find((element) => element.identifier_uuid === annotatorId);
        identifierId = identifier?.identifier?.identifier_uuid;
      } else {
        modifiers = curNode.modifiers;
        nodeGroups = curNode.groups;
      }
      const relationship: MRuleConfigRelationship[] = [];

      if (curNode.parent != null) {
        const parentNode = config[curNode.parent];
        const curRelationship = 'relationship' in parentNode ? parentNode.relationship : null;

        if (curRelationship != null) {
          curRelationship.forEach((key) => {
            const cur = relationshipState[key];

            if (cur && cur.annotation_a != null && cur.annotation_b != null) {
              const annotatorAId = cur.annotation_a.id;
              const annotatorBId = cur.annotation_b.id;

              if (annotatorAId === id && config[annotatorBId]) {
                const configB = config[annotatorBId];
                if (identifiers != null && 'annotatorId' in configB) {
                  const actualIdentifier = identifiers.find(
                    (element) => element.identifier_uuid === configB.annotatorId
                  );
                  relationship.push({
                    id: cur.id,
                    name: `${cur.type} ${actualIdentifier?.identifier?.name}`,
                    deleted: !!(actualIdentifier && actualIdentifier.deleted_at != null),
                  });
                }
              }
            }
          });
        }
      }

      // FIXME: Create a function to process the identifier name
      let ruleName = '';
      let deleted = false;
      if (!identifiers) {
        ruleName = name;
      } else if (identifiers != null) {
        const actualIdentifier = identifiers.find(
          (element) => element.identifier_uuid === annotatorId
        );
        if (actualIdentifier && annotatorId) {
          if (actualIdentifier && actualIdentifier.identifier == null) {
            ruleName = `Identifier doesn't exist ${annotatorId}`;
            deleted = true;
          } else if (actualIdentifier.deleted_at == null) {
            ruleName = actualIdentifier.identifier?.name ?? '';
          } else {
            ruleName = actualIdentifier.identifier?.name ?? '';
            deleted = true;
            // @ts-ignore
            if (config.lastChangedNode === annotatorId) {
              Sentry.captureMessage(`${annotatorId} identifier was deleted`);
            }
          }
        } else {
          ruleName = name;
        }
      }

      const curItem: MRuleConfig[] = [
        {
          id,
          name: ruleName,
          ...(curNode.typeOfConfig !== 'ANNOTATION_MATCH' ? { description } : {}),
          ...(curNode.typeOfConfig !== 'ANNOTATION_MATCH' ? { color } : {}),
          level,
          parent,
          deleted,
          relationship,
          identifierId,
          modifiers,
          nodeGroups,
          ...(curNode.typeOfConfig === 'ANNOTATION_MATCH'
            ? { negated, mode_of_speech, annotatorId }
            : {}),
        },
      ];

      const groups = 'groups' in curNode ? curNode.groups : [];
      return groups.reduce(
        (curArray, nextNodeId) =>
          traverseTree(nextNodeId, level + 1, [...parent, id]).concat(curArray),
        curItem
      );
    };
    return traverseTree(rootConfigId || '', 0, []).reverse();
  }
);

export const getConfigAsArrayFromRule: Selector<MRuleConfig[], [string]> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
      state.envelopeReview.selectedEnvelope,
    (_state: GlobalState, ruleId: string): string => ruleId,
  ],
  (selectedEnvelope, ruleId) => {
    if (!selectedEnvelope) return [];

    const rule = selectedEnvelope.events?.find((e) => e.rule && e.rule.uuid === ruleId)?.rule;

    if (!rule || !rule.config.uuid) return [];

    const rootConfigId = rule.config.uuid;
    // @ts-ignore
    const identifiers = (rule.identifiers || []) as RuleIdentifier[];

    const tempConfig = {
      ...rule.config,
      rootConfigId,
    };

    const { ruleConfig: config, relationships: relationshipState } = normalizeRuleConfig({
      config: tempConfig,
      // @ts-expect-error
      rule,
    });

    const traverseTree = (curIdNode: UUID, level: number, parent: UUID[]): MRuleConfig[] => {
      const curNode = config[curIdNode];
      if (!curNode) {
        return [];
      }
      const { id, name, description, color } = curNode;

      let negated;
      let mode_of_speech: ModeOfSpeech | undefined;
      let annotatorId: UUID | undefined;
      let identifierId: UUID | undefined;
      let modifiers;
      let nodeGroups;

      if (curNode.typeOfConfig === 'ANNOTATION_MATCH') {
        mode_of_speech = curNode.mode_of_speech;
        negated = curNode.negated;
        annotatorId = curNode.annotatorId;

        const identifier = identifiers.find((element) => element.identifier_uuid === annotatorId);
        identifierId = identifier?.identifier?.identifier_uuid;
      } else {
        modifiers = curNode.modifiers;
        nodeGroups = curNode.groups;
      }
      const relationship: MRuleConfigRelationship[] = [];

      if (curNode.parent != null) {
        const parentNode = config[curNode.parent];
        const curRelationship = 'relationship' in parentNode ? parentNode.relationship : null;

        if (curRelationship != null) {
          curRelationship.forEach((key) => {
            const cur = relationshipState[key];

            if (cur && cur.annotation_a != null && cur.annotation_b != null) {
              const annotatorAId = cur.annotation_a.id;
              const annotatorBId = cur.annotation_b.id;

              if (annotatorAId === id && config[annotatorBId]) {
                const configB = config[annotatorBId];
                if (identifiers != null && 'annotatorId' in configB) {
                  const actualIdentifier = identifiers.find(
                    (element) => element.identifier_uuid === configB.annotatorId
                  );
                  relationship.push({
                    id: cur.id,
                    name: `${cur.type} ${actualIdentifier?.identifier?.name}`,
                    deleted: !!(actualIdentifier && actualIdentifier.deleted_at != null),
                  });
                }
              }
            }
          });
        }
      }

      // FIXME: Create a function to process the identifier name
      let ruleName = '';
      let deleted = false;
      if (!identifiers) {
        ruleName = name;
      } else if (identifiers != null) {
        const actualIdentifier = identifiers.find(
          (element) => element.identifier_uuid === annotatorId
        );
        if (actualIdentifier && annotatorId) {
          if (actualIdentifier && actualIdentifier.identifier == null) {
            ruleName = `Identifier doesn't exist ${annotatorId}`;
            deleted = true;
          } else if (actualIdentifier.deleted_at == null) {
            ruleName = actualIdentifier.identifier?.name ?? '';
          } else {
            ruleName = actualIdentifier.identifier?.name ?? '';
            deleted = true;
            // @ts-ignore
            if (config.lastChangedNode === annotatorId) {
              Sentry.captureMessage(`${annotatorId} identifier was deleted`);
            }
          }
        } else {
          ruleName = name;
        }
      }

      const curItem: MRuleConfig[] = [
        {
          id,
          name: ruleName,
          ...(curNode.typeOfConfig !== 'ANNOTATION_MATCH' ? { description } : {}),
          ...(curNode.typeOfConfig !== 'ANNOTATION_MATCH' ? { color } : {}),
          level,
          parent,
          deleted,
          relationship,
          identifierId,
          modifiers,
          nodeGroups,
          ...(curNode.typeOfConfig === 'ANNOTATION_MATCH'
            ? { negated, mode_of_speech, annotatorId }
            : {}),
        },
      ];

      const groups = 'groups' in curNode ? curNode.groups : [];
      return groups.reduce(
        (curArray, nextNodeId) =>
          traverseTree(nextNodeId, level + 1, [...parent, id]).concat(curArray),
        curItem
      );
    };
    return traverseTree(rootConfigId || '', 0, []).reverse();
  }
);

export const getEventRuleConfigNode: Selector<MRuleConfigNode, [string]> = createSelector(
  [getEventRuleConfigItems, (_state, id): string => id],
  (configItems, id) => configItems[id]
);

type RuleTracingNode = { [key: string]: RuleTracingNode };

type RuleTracingContext = {
  tree: Record<string, RuleTracingNode>;
};

export const getRelevantTracingNodes: Selector<{ id: string; level: number }[]> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
      state.envelopeReview.selectedEnvelope,
  ],
  (selectedEnvelope) => {
    const tracingContext = selectedEnvelope?.events?.[0].rule_tracing_context as RuleTracingContext;
    if (tracingContext.tree) {
      const relevantTracing: { id: string; level: number }[] = [];

      const getRelevantTracing = (node: RuleTracingNode): void => {
        Object.entries(node).forEach(([k, v]) => {
          if (
            Object.keys(v).length > 0 &&
            Object.values(v).every((n) => Object.values(n).length === 0)
          ) {
            relevantTracing.push({ id: k, level: 0 });
            Object.keys(v).forEach((key) => {
              relevantTracing.push({ id: key, level: 1 });
            });
          }
        });

        Object.values(node).some((n) => getRelevantTracing(n));
      };

      getRelevantTracing(tracingContext.tree);

      return relevantTracing;
    }

    return [];
  }
);

export const getEventTracing: Selector<{ id: string; level: number }[], [string]> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
      state.envelopeReview.selectedEnvelope,
    (_state: GlobalState, eventId): string => eventId,
  ],
  (selectedEnvelope, eventId) => {
    if (!selectedEnvelope || !selectedEnvelope.events) return [];

    const event = selectedEnvelope.events?.find((e) => e.uuid === eventId);

    if (!event) return [];

    const tracingContext = event.rule_tracing_context as RuleTracingContext;

    if (tracingContext.tree) {
      const relevantTracing: { id: string; level: number }[] = [];

      const getRelevantTracing = (node: RuleTracingNode): void => {
        Object.entries(node).forEach(([k, v]) => {
          if (
            Object.keys(v).length > 0 &&
            Object.values(v).every((n) => Object.values(n).length === 0)
          ) {
            relevantTracing.push({ id: k, level: 0 });
            Object.keys(v).forEach((key) => {
              relevantTracing.push({ id: key, level: 1 });
            });
          }
        });

        Object.values(node).some((n) => getRelevantTracing(n));
      };

      getRelevantTracing(tracingContext.tree);

      return relevantTracing;
    }

    return [];
  }
);

export const getEventsByRule: Selector<Event[], [string]> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
      state.envelopeReview.selectedEnvelope,
    (_state: GlobalState, ruleId: string): string => ruleId,
  ],
  (selectedEnvelope, ruleId) => {
    if (!selectedEnvelope) return [];
    const events = selectedEnvelope.events?.filter((e) => e.rule && e.rule.uuid === ruleId);
    return events || [];
  }
);

export type Tracing = { id: string; level: number };

export const getRuleTracings: Selector<Tracing[][], [string]> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
      state.envelopeReview.selectedEnvelope,
    (_state: GlobalState, ruleId): string => ruleId,
  ],
  (selectedEnvelope, ruleId) => {
    if (!selectedEnvelope || !selectedEnvelope.events) return [];

    const events = selectedEnvelope.events?.filter((e) => e.rule && e.rule.uuid === ruleId);

    if (!events || events.length === 0) return [];

    let tracings: Tracing[][] = [];

    events.forEach((event) => {
      const tracingContext = event.rule_tracing_context as RuleTracingContext;

      if (tracingContext.tree) {
        const relevantTracings: Tracing[][] = [];

        const getRelevantTracing = (node: RuleTracingNode): void => {
          Object.entries(node).forEach(([k, v]) => {
            const tr = [];
            if (
              Object.keys(v).length > 0 &&
              Object.values(v).every((n) => Object.values(n).length === 0)
            ) {
              tr.push({ id: k, level: 0 });
              Object.keys(v).forEach((key) => {
                tr.push({ id: key, level: 1 });
              });
            }
            if (tr.length > 0) relevantTracings.push(tr);
          });

          Object.values(node).forEach((n) => getRelevantTracing(n));
        };

        getRelevantTracing(tracingContext.tree);
        tracings = [...tracings, ...relevantTracings];
      }
    });

    const uniqueTracings = tracings.filter(
      (tracing, idx, arr) => arr.findIndex((t) => t[0].id === tracing[0].id) === idx
    );

    return uniqueTracings;
  }
);

export const getSelectedText: Selector<string> = (state) => state.envelopeReview.selectedText;

export const getSelectedSentence: Selector<SelectedSentenceType> = (state) =>
  state.envelopeReview.selectedSentence;

export const getHoverIdentifier: Selector<string> = (state) => state.envelopeReview.hoverIdentifier;

export const getSelectedModel: Selector<string[]> = (state) => state.envelopeReview.selectedModel;

export const getModelTransparencyMode: Selector<boolean> = createSelector(
  [getSelectedSentence, getHoverIdentifier, getSelectedModel],
  (selectedSentence, hoverIdentifier, selectedModel) => {
    if (selectedSentence.index !== -1) {
      if (hoverIdentifier || selectedModel.length > 0) return true;
    }
    return false;
  }
);

export default getSelectedEnvelope;
