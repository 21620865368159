import { ActiveTask } from '@litlingo/client';
import { upperFirst } from 'lodash';
import React from 'react';

type ComponentProps = {
  task: ActiveTask;
};

const TaskListItem: React.FC<ComponentProps> = ({ task }) => (
  <tr className="table-row border-t border-gray-200 align-middle hover:bg-litlingo-gray-0">
    <td className="table-wrapper__new-td pl-2">
      <div className="table-wrapper__new-cell-content h-12 m-0 p-0 flex text-body items-center">
        {task.job_uuid}
      </div>
    </td>
    <td className="table-wrapper__new-td pl-0">
      <div className="table-wrapper__new-cell-content h-12 m-0 p-0 flex text-body items-center">
        {task.comms_count}
      </div>
    </td>
    <td className="table-wrapper__new-td pl-0">
      <div className="table-wrapper__new-cell-content h-12 m-0 p-0 flex text-body items-center">
        {upperFirst(task.block_status)}
      </div>
    </td>
    <td className="table-wrapper__new-td pl-0">
      <div className="table-wrapper__new-cell-content h-12 m-0 p-0 flex text-body items-center">
        {task.blocks_count}
      </div>
    </td>
    <td className="table-wrapper__new-td pl-0">
      <div className="table-wrapper__new-cell-content h-12 m-0 p-0 flex text-body items-center">
        {task.customer_uuid}
      </div>
    </td>
    <td className="table-wrapper__new-td pl-0">
      <div className="table-wrapper__new-cell-content h-12 m-0 p-0 flex text-body items-center">
        {task.created_by_uuid}
      </div>
    </td>
  </tr>
);

export default TaskListItem;
