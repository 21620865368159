import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  deleteRule,
  editRule,
  fetchAllRulesFailure,
  fetchAllRulesRequest,
  fetchAllRulesSuccess,
  fetchSingleRuleFailure,
  fetchSingleRuleRequest,
  fetchSingleRuleSuccess,
  fetchTestCaseSummaryRuleFailure,
  fetchTestCaseSummaryRuleRequest,
  fetchTestCaseSummaryRuleSuccess,
  saveRuleFailure,
  saveRuleRequest,
  saveRuleSuccess,
} from 'actions';
import {
  EditRulePayload,
  annotatorPosition,
  fetchRulesForFilterPillsFailure,
  fetchRulesForFilterPillsRequest,
  fetchRulesForFilterPillsSuccess,
  publishRuleVersionFailure,
  publishRuleVersionRequest,
  publishRuleVersionSuccess,
  selectAnnotators,
  selectRule,
  selectRuleType,
  setCurrentTestRuleId,
  setShowHighlights,
  setToAnnotator,
  undoRuleVersionFailure,
  undoRuleVersionRequest,
  undoRuleVersionSuccess,
} from 'actions/rule';
import type {
  API,
  ErrorObject,
  MRule,
  NormalizedResource,
  Rule,
  RuleTestCasesSummary,
  UUID,
} from 'types';

export type RuleState = {
  loading: string[];
  error: ErrorObject | null;
  rules: NormalizedResource<MRule>;
  filterItems: NormalizedResource<Rule>;
  count: number;
  selectedRule: Rule | null;
  selectedAnnotators: string[];
  showHighlights: boolean;
  testRuleId: string;
  annotatorPosition: string | null;
  toAnnotator: boolean;
};

type RuleReducer<P = void> = (state: RuleState, payload: PayloadAction<P>) => RuleState;

type PayloadDelete = {
  ruleId: UUID;
};

const defaultState: RuleState = {
  rules: {},
  filterItems: {},
  loading: [],
  error: null,
  count: 0,
  selectedRule: null,
  selectedAnnotators: [],
  showHighlights: false,
  testRuleId: '',
  annotatorPosition: null,
  toAnnotator: false,
};

const handleSaveRuleRequest: RuleReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, saveRuleRequest.toString()],
});

const handleSaveRuleFailure: RuleReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== saveRuleRequest.toString()),
});

const handleSaveRuleSuccess: RuleReducer<NormalizedResource<MRule>> = (state, { payload }) => ({
  ...state,
  rules: {
    ...state.rules,
    ...payload,
  },
  loading: state.loading.filter((s) => s !== saveRuleRequest.toString()),
});

const handleFetchRuleRequest: RuleReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchSingleRuleRequest.toString()],
});

const handleFetchRuleFailure: RuleReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchSingleRuleRequest.toString()),
});

const handleFetchRuleSuccess: RuleReducer<MRule> = (state, { payload }) => {
  const item = payload;
  const rule = state.rules[payload.uuid] || {};
  return {
    ...state,
    rules: {
      ...state.rules,
      [payload.uuid]: { ...rule, ...item },
    },
    loading: state.loading.filter((s) => s !== fetchSingleRuleRequest.toString()),
  };
};

const handleFetchAllRulesRequest: RuleReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAllRulesRequest.toString()],
});

const handleFetchAllRulesFailure: RuleReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchAllRulesRequest.toString()),
});

const handleFetchAllRulesSuccess: RuleReducer<API.WrappedAPIResponse<MRule>> = (
  state,
  { payload }
) => {
  const rules: NormalizedResource<MRule> = {};

  const { pathname } = window.location;
  const ruleId = pathname.split('/')[pathname.split('/').indexOf('rule') + 1];
  const selectedRule = ruleId ? { [ruleId]: state.rules[ruleId] } : null;
  payload.records.forEach((rule) => {
    rules[rule.uuid] = {
      ...rule,
      summaryTestCases: state.rules[rule.uuid]?.summaryTestCases,
    };
  });

  return {
    ...state,
    rules: { ...rules, ...(selectedRule || {}) },
    count: payload.count,
    loading: state.loading.filter((s) => s !== fetchAllRulesRequest.toString()),
  };
};

const handleFetchRulesForFilterPillsRequest: RuleReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchRulesForFilterPillsRequest.toString()],
});

const handleFetchRulesForFilterPillsFailure: RuleReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchRulesForFilterPillsRequest.toString()),
});

const handleFetchRulesForFilterPillsSuccess: RuleReducer<API.WrappedAPIResponse<Rule>> = (
  state,
  { payload }
) => {
  const normalizedRules: NormalizedResource<Rule> = {};
  payload.records.forEach((Rule) => {
    normalizedRules[Rule.uuid] = Rule;
  });
  return {
    ...state,
    filterItems: normalizedRules,
    loading: state.loading.filter((s) => s !== fetchRulesForFilterPillsRequest.toString()),
    count: payload.count,
  };
};

const handleDeleteRule: RuleReducer<PayloadDelete> = (state, { payload }) => {
  const { ruleId } = payload;
  const rules = { ...state.rules };
  delete rules[ruleId];

  return {
    ...state,
    rules,
  };
};

const handleEditRule: RuleReducer<EditRulePayload> = (state, { payload }) => {
  const { id, data } = payload;
  const item = state.rules[id];
  return {
    ...state,
    rules: {
      ...state.rules,
      [id]: {
        ...item,
        ...data,
      },
    },
  };
};

const handleSelectRule: RuleReducer<selectRuleType> = (state, { payload }) => ({
  ...state,
  selectedRule: payload.rule,
});

const handleFetchTestCaseSummaryRuleRequest: RuleReducer = (state) => ({
  ...state,
  loading: [...state.loading, fetchTestCaseSummaryRuleRequest.toString()],
});

const handleFetchTestCaseSummaryRuleSuccess: RuleReducer<RuleTestCasesSummary> = (
  state,
  { payload }
) => {
  const data = {
    failing: payload.failing || 0,
    passing: payload.passing || 0,
    false_negatives: payload.false_negatives || 0,
    not_run: payload.not_run || 0,
    true_positives: payload.true_positives || 0,
    true_negatives: payload.true_negatives || 0,
  };

  return {
    ...state,
    rules: {
      ...state.rules,
      [payload.ruleUuid]: {
        ...state.rules[payload.ruleUuid],
        summaryTestCases: data,
      },
    },
    loading: state.loading.filter((s) => s !== fetchTestCaseSummaryRuleRequest.toString()),
  };
};

const handleFetchTestCaseSummaryRuleFailure: RuleReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchTestCaseSummaryRuleRequest.toString()),
});

const handlePublishRuleVersionRequest: RuleReducer = (state) => ({
  ...state,
  loading: [...state.loading, publishRuleVersionRequest.toString()],
});

const handlePublishRuleVersionSuccess: RuleReducer<MRule> = (state, { payload }) => {
  const item = payload;
  const rule = state.rules[payload.uuid] || {};
  return {
    ...state,
    rules: {
      ...state.rules,
      [payload.uuid]: { ...rule, ...item },
    },
    loading: state.loading.filter((s) => s !== publishRuleVersionRequest.toString()),
  };
};

const handlePublishRuleVersionFailure: RuleReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== publishRuleVersionRequest.toString()),
});

const handleUndoRuleVersionRequest: RuleReducer = (state) => ({
  ...state,
  loading: [...state.loading, undoRuleVersionRequest.toString()],
});

const handleUndoRuleVersionSuccess: RuleReducer<MRule> = (state, { payload }) => {
  const item = payload;
  const rule = state.rules[payload.uuid] || {};
  return {
    ...state,
    rules: {
      ...state.rules,
      [payload.uuid]: { ...rule, ...item },
    },
    loading: state.loading.filter((s) => s !== undoRuleVersionRequest.toString()),
  };
};

const handleUndoRuleVersionFailure: RuleReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== undoRuleVersionRequest.toString()),
});

const handleSelectedAnnotators: RuleReducer<string[]> = (state, { payload }) => ({
  ...state,
  selectedAnnotators: payload,
});

const handleShowHighlights: RuleReducer<boolean> = (state, { payload }) => ({
  ...state,
  showHighlights: payload,
});

const handleCurrentTestRuleId: RuleReducer<string> = (state, { payload }) => ({
  ...state,
  testRuleId: payload,
});

const handleAnnotatorPosition: RuleReducer<string | null> = (state, { payload }) => ({
  ...state,
  annotatorPosition: payload,
});

const handleSetToAnnotator: RuleReducer<boolean> = (state, { payload }) => ({
  ...state,
  toAnnotator: payload,
});

const handlers = {
  [saveRuleRequest.toString()]: handleSaveRuleRequest,
  [saveRuleFailure.toString()]: handleSaveRuleFailure,
  [saveRuleSuccess.toString()]: handleSaveRuleSuccess,
  [fetchSingleRuleRequest.toString()]: handleFetchRuleRequest,
  [fetchSingleRuleFailure.toString()]: handleFetchRuleFailure,
  [fetchSingleRuleSuccess.toString()]: handleFetchRuleSuccess,
  [fetchAllRulesRequest.toString()]: handleFetchAllRulesRequest,
  [fetchAllRulesFailure.toString()]: handleFetchAllRulesFailure,
  [fetchAllRulesSuccess.toString()]: handleFetchAllRulesSuccess,
  [fetchRulesForFilterPillsRequest.toString()]: handleFetchRulesForFilterPillsRequest,
  [fetchRulesForFilterPillsSuccess.toString()]: handleFetchRulesForFilterPillsSuccess,
  [fetchRulesForFilterPillsFailure.toString()]: handleFetchRulesForFilterPillsFailure,
  [fetchTestCaseSummaryRuleRequest.toString()]: handleFetchTestCaseSummaryRuleRequest,
  [fetchTestCaseSummaryRuleSuccess.toString()]: handleFetchTestCaseSummaryRuleSuccess,
  [fetchTestCaseSummaryRuleFailure.toString()]: handleFetchTestCaseSummaryRuleFailure,
  [publishRuleVersionRequest.toString()]: handlePublishRuleVersionRequest,
  [publishRuleVersionSuccess.toString()]: handlePublishRuleVersionSuccess,
  [publishRuleVersionFailure.toString()]: handlePublishRuleVersionFailure,
  [undoRuleVersionRequest.toString()]: handleUndoRuleVersionRequest,
  [undoRuleVersionSuccess.toString()]: handleUndoRuleVersionSuccess,
  [undoRuleVersionFailure.toString()]: handleUndoRuleVersionFailure,
  [deleteRule.toString()]: handleDeleteRule,
  [editRule.toString()]: handleEditRule,
  [selectRule.toString()]: handleSelectRule,
  [selectAnnotators.toString()]: handleSelectedAnnotators,
  [setShowHighlights.toString()]: handleShowHighlights,
  [setCurrentTestRuleId.toString()]: handleCurrentTestRuleId,
  [annotatorPosition.toString()]: handleAnnotatorPosition,
  [setToAnnotator.toString()]: handleSetToAnnotator,
};

const ruleReduer = createReducer(defaultState, handlers);

export default ruleReduer;
