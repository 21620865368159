import { ResponsiveLine } from '@nivo/line';
import type { Serie } from '@nivo/line';
import LoadingIndicator from 'components/LoadingIndicator';
import React from 'react';
import { useSelector } from 'react-redux';
import { getSentimentOverTime, getSentimentOverTimeLoading } from 'selectors/analytics';
import DashboardWidget from './DashboardWidget';

const DashboardSentiment: React.FC = () => {
  const sentimentOverTime = useSelector(getSentimentOverTime);
  const loading = useSelector(getSentimentOverTimeLoading);

  const spinner = (
    <div className="flex items-center justify-center h-12">
      <LoadingIndicator className="ml-2" size="8" />
    </div>
  );

  if ((!sentimentOverTime || sentimentOverTime[0].data.length === 0) && !loading)
    return (
      <div className="flex justify-center mt-2">
        <span>There is no data to show for sentiment</span>
      </div>
    );

  const body = (
    <div className="h-72">
      <ResponsiveLine
        data={sentimentOverTime as Serie[]}
        margin={{ top: 30, right: 10, bottom: 45, left: 50 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
        yFormat=" >-.2f"
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Sentiment Over Time',
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '% sentiment',
          legendOffset: -40,
          legendPosition: 'middle',
          format(value): string {
            return `${value} %`;
          },
        }}
        pointSize={10}
        colors={{ datum: 'color' }}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh
        legends={[
          {
            anchor: 'top-left',
            direction: 'row',
            justify: false,
            translateX: 100,
            translateY: -30,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 50,
            itemHeight: 20,
            itemOpacity: 1,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
          },
        ]}
      />
    </div>
  );

  return (
    <DashboardWidget
      title="Sentiment"
      className="h-dashboard-campaign-performance"
      body={loading ? spinner : body}
    />
  );
};

export default DashboardSentiment;
