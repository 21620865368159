import { toggleShortcutsModal } from 'actions';
import Modal from 'components/Modal';
import keyMap from 'constants/configHotKeys';
import React from 'react';
import { getApplicationKeyMap, GlobalHotKeys } from 'react-hotkeys';
import { useDispatch, useSelector } from 'react-redux';
import { getDisplayShortcutsModal } from 'selectors/auth';

const HotKeysHelpModal: React.FC = () => {
  const dispatch = useDispatch();
  const displayShortcutsModal = useSelector(getDisplayShortcutsModal);

  const renderDialog = (): JSX.Element => {
    const allKeyMap = getApplicationKeyMap();

    return (
      <div>
        <table>
          <tbody>
            {Object.keys(allKeyMap).map((actionName) => {
              const { sequences, name } = allKeyMap[actionName];

              return (
                <tr className="border-b border-gray-300 my-5" key={name || actionName}>
                  <td className="text-sm">{name}</td>
                  <td className="text-sm pl-5">
                    {sequences.map(({ sequence }) => (
                      <span key={sequence as string}>{sequence}</span>
                    ))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const toggleModal = (): void => {
    dispatch(toggleShortcutsModal());
  };

  const handlers = {
    SHOW_DIALOG: (): void => {
      toggleModal();
    },
  };

  const body = <h3 className="my-4">{renderDialog()}</h3>;

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />

      {displayShortcutsModal && (
        <Modal
          body={body}
          title="Keyboard shortcuts"
          cancelButtonText="Close"
          okButtonOnClick={(): void => toggleModal()}
          toggleShowModal={(): void => toggleModal()}
        />
      )}
    </>
  );
};

export default HotKeysHelpModal;
