/* eslint-disable max-lines */
import {
  cleanChangedAndNotSelectedNodes,
  cleanChangedNodes,
  evaluateAsyncResults,
  fetchAllCampaignRuleOutcomes,
  fetchTestCaseSummary,
  selectAnnotators,
  updateRule,
} from 'actions';
import LeavePageModal from 'components/LeavePageModal';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import Navbar from 'components/Navbar';
import RelationshipManager from 'components/RelationshipManager';
import RuleManagerContent from 'components/RuleManager/RuleManagerContent';
import RuleManagerSidebar from 'components/RuleManager/RuleManagerSidebar';
import keyMap from 'constants/configHotKeys';
import React, { useEffect, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getModifiedNodes } from 'selectors/config';
import { getRule } from 'selectors/rules';
import { getTestCasesResultsKey } from 'selectors/testCases';
import { useSelector } from 'store';
import RelationshipManagerAdvancedModal from './RelationshipManagerAdvancedModal';
import RuleAnnotatorBank from './RuleAnnotatorBank';
import RuleHeader from './RuleHeader';
import RuleManagerTestSuiteSidebar from './TestSuiteSidebar';

type RouterParams = { ruleId: string; campaignId?: string };

const RuleManager: React.FC = () => {
  const dispatch = useDispatch();
  const { ruleId, campaignId } = useParams<RouterParams>();
  const modifiedNodes = useSelector(getModifiedNodes);
  const rule = useSelector(getRule(ruleId));
  const evaluateTestResultsKey = useSelector(getTestCasesResultsKey);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [advancedModal, setAdvancedModal] = useState(false);
  const [testSuiteSidebar, setTestSuiteSidebar] = useState(false);

  useEffect(() => {
    dispatch(selectAnnotators([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleId]);
  // @ts-ignore
  useEffect(() => (): void => dispatch(cleanChangedNodes()), [dispatch]);

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;
    if (evaluateTestResultsKey) {
      dispatch(evaluateAsyncResults(evaluateTestResultsKey));
      dispatch(fetchTestCaseSummary({ rule_uuids: ruleId }));
      intervalId = setInterval(() => {
        dispatch(evaluateAsyncResults(evaluateTestResultsKey));
        dispatch(fetchTestCaseSummary({ rule_uuids: ruleId }));
      }, 2000);
    }
    return (): void => clearInterval(intervalId);
  }, [dispatch, evaluateTestResultsKey, ruleId]);

  useEffect(() => {
    dispatch(fetchAllCampaignRuleOutcomes({ rule_uuid: ruleId, campaign_uuid: campaignId }));
  }, [dispatch, ruleId, campaignId]);

  const toggleShowModal = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSaveRelationship = (): void => {
    dispatch(updateRule({ rule }));
    dispatch(cleanChangedAndNotSelectedNodes());
  };

  const handleSaveRelationshipAndCloseModal = (): void => {
    dispatch(updateRule({ rule }));
    dispatch(cleanChangedNodes());
    setIsModalOpen(!isModalOpen);
  };

  const handlers = {
    SAVE_RULE: (event: KeyboardEvent | undefined): void => {
      if (event) {
        event.preventDefault();
        const button = document.querySelector('#save-rule-button') as HTMLButtonElement;
        if (button) button.click();
      }
    },
  };

  const handleToggleTestSuiteSidebar = (): void => setTestSuiteSidebar(!testSuiteSidebar);

  const renderRelationship = (): JSX.Element | null => {
    if (!rule) return null;
    return (
      <RelationshipManager
        rule={rule}
        setAdvancedModal={setAdvancedModal}
        advancedModal={advancedModal}
      />
    );
  };

  const renderRelationshipModal = (): JSX.Element => {
    if (!advancedModal) {
      return (
        <Modal
          body={renderRelationship()}
          title="Edit relationship: Same sentence"
          okButton
          okButtonText="Save"
          okButtonOnClick={handleSaveRelationshipAndCloseModal}
          toggleShowModal={toggleShowModal}
        />
      );
    }
    return (
      <RelationshipManagerAdvancedModal
        body={renderRelationship()}
        title="Edit relationship: Same sentence"
        okButton
        okButtonText="Save and close"
        okButtonOnClick={handleSaveRelationshipAndCloseModal}
        saveButtonOnClick={handleSaveRelationship}
        toggleShowModal={toggleShowModal}
        setAdvancedModal={setAdvancedModal}
      />
    );
  };

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  if (!rule) return <LoadingIndicator className="m-auto mt-20" size="20" />;

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <div
        className="flex flex-col bg-white min-w-screen"
        style={{
          height: `calc(100vh - ${warningBarOffset}px)`,
        }}
      >
        <Navbar />
        <div className="flex flex-row h-full overflow-hidden">
          <div className="flex flex-col gap-4 h-full pt-6 w-full overflow-hidden">
            <RuleHeader />
            <main className="flex flex-col gap-2 h-full overflow-auto no-scrollbar min-w-full px-6 pb-4">
              <RuleAnnotatorBank ruleId={ruleId} />
              <RuleManagerContent
                campaignId={campaignId}
                ruleId={ruleId}
                rule={rule}
                toggleShowModal={toggleShowModal}
                toggleTestSuiteSidebar={handleToggleTestSuiteSidebar}
              />
            </main>
          </div>
          <div className="test-right-sidebar flex flex-col h-full border-l border-litlingo-gray-2 overflow-auto no-scrollbar">
            {testSuiteSidebar ? (
              <RuleManagerTestSuiteSidebar
                toggleTestSuiteSidebar={handleToggleTestSuiteSidebar}
                ruleId={ruleId}
              />
            ) : (
              <RuleManagerSidebar rule={rule} />
            )}
          </div>
        </div>

        {isModalOpen && renderRelationshipModal()}

        <LeavePageModal shouldBlockNavigation={modifiedNodes.length > 0} />
      </div>
    </>
  );
};

export default RuleManager;
