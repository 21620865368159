import { ACTIVE_ICON, INACTIVE_ICON, TEST_ICON } from 'constants/annotatorIcons';

export * from 'constants/annotatorIcons';

const MATCHER_TYPE = {
  development: {
    name: 'Test',
    icon: TEST_ICON,
  },
  disabled: {
    name: 'Inactive',
    icon: INACTIVE_ICON,
  },
  active: {
    name: 'Active',
    icon: ACTIVE_ICON,
  },
};

export const mapInputChoices = (
  choices: (string | number)[],
  formType: string
): {
  name: React.Key;
  value: React.Key;
}[] => {
  const prunedChoices = choices.map((choice) => {
    if (formType !== 'status') return { name: choice, value: choice };
    const name =
      choice in MATCHER_TYPE ? MATCHER_TYPE[choice as keyof typeof MATCHER_TYPE].name : choice;
    return { name, value: choice };
  });
  return prunedChoices;
};

export const possibleTypes = {
  freeForm: 'freeForm',
  bool: 'bool',
  multiSelect: 'multiSelect',
  singleSelect: 'singleSelect',
  int: 'int',
};

export default MATCHER_TYPE;
