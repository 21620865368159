// @ts-ignore
import DocumentViz from '@litlingo/react/dist/DocumentViz';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCustomerDomain } from 'selectors/auth';
import { getEventWholeSentencesForGraph } from 'selectors/events';
import type { UUID } from 'types';

type ComponentProps = {
  show: boolean;
  eventId: UUID;
  onCloseClick: () => void;
};

const EventsVisualizer: React.FC<ComponentProps> = (props) => {
  const { show, eventId, onCloseClick } = props;
  const wholeSentences = useSelector(getEventWholeSentencesForGraph(eventId));
  const customerDomain = useSelector(getCustomerDomain);

  if (!show || wholeSentences == null) {
    return null;
  }

  return (
    <div className="absolute bg-gray-100 bottom-0 h-full/2 left-0 max-w-full p-5 text-left w-full">
      <div className="flex items-center justify-between mb-3">
        <div className="flex flex-row">
          <svg
            className="h-6 w-6 pr-2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
          </svg>
          <span className="text-xl">Visualizer</span>
        </div>

        <div>
          <button type="button" onClick={onCloseClick}>
            <svg
              className="h-5 w-5 text-gray-700 hover:text-gray-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="overflow-y-auto h-vizualizer">
        <DocumentViz
          annotationsBasePath={`/${customerDomain}/annotator`}
          lines={wholeSentences.sentences}
          arrows={wholeSentences.arrows}
        />
      </div>
    </div>
  );
};

export default EventsVisualizer;
