import EmptyTableState from 'components/EmptyTableState';
import FilterListHeaderRedesign from 'components/Filters/FilterListHeaderRedesign';
import ListFooterHooksBased from 'components/ListFooter/ListFooterHooksBased';
import LoadingIndicator from 'components/LoadingIndicator';
import Tooltip from 'components/Tooltip';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useModels from 'hooks/models/useModels';
import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { UUID } from 'types';
import ModelListActionsRow from './ModelListActionsRow';
import ModelListRow from './ModelListRow';

type ComponentProps = {
  selectedModels: string[];
  handleModelSelect: (checked: boolean, uuid: UUID) => void;
  handleSelectAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showModels: boolean;
  setSelectedModels: React.Dispatch<React.SetStateAction<string[]>>;
};

const ModelTable: React.FC<ComponentProps> = ({
  selectedModels,
  handleModelSelect,
  handleSelectAll,
  showModels,
  setSelectedModels,
}) => {
  const { data, isLoading } = useModels({ redirectOnError: true });
  const isEmpty = data?.records.length === 0;

  return (
    <div className="mt-2 mb-8 px-6 text-left">
      <LoadingOverlay
        active={isLoading}
        spinner={<LoadingIndicator size="10" />}
        fadeSpeed={0}
        styles={{
          content: (base) => ({
            ...base,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }),
          wrapper: (base) => ({
            ...base,
            height: '100%',
          }),
          spinner: () => ({}),
          overlay: (base) => ({
            ...base,
            zIndex: 10,
          }),
        }}
      >
        <div className="table-wrapper__new">
          <table className="table-wrapper__table">
            <thead>
              <tr>
                <th className="table-wrapper__new-th table-wrapper__th--w-3">
                  <div className="px-2">
                    <Tooltip tooltip="envelopesListActions.selectAll" redesign>
                      <label
                        htmlFor="select-all"
                        className="flex justify-start items-center w-full cursor-pointer"
                      >
                        <input
                          id="select-all"
                          data-testid="select-all"
                          className={`cursor-pointer form-checkbox litlingo-checkbox w-5 h-5 rounded-sm ${
                            selectedModels.length > 0 &&
                            data &&
                            selectedModels?.length < data.records.length
                              ? 'checkbox-select-all-partial'
                              : ''
                          }`}
                          type="checkbox"
                          checked={
                            selectedModels?.length === data?.records.length &&
                            selectedModels?.length > 0
                          }
                          onChange={handleSelectAll}
                        />
                      </label>
                    </Tooltip>
                  </div>
                </th>

                <FilterListHeaderRedesign
                  resource={resourceQueryParamName.model}
                  text="Use Case"
                  field="name"
                  className="items-center"
                />
                <th className="table-wrapper__new-th">Description</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-6">Priority</th>
                <FilterListHeaderRedesign
                  resource={resourceQueryParamName.model}
                  text="Last Modified"
                  field="updated_at"
                  className="table-wrapper__th--w-12"
                />
                <th className="table-wrapper__new-th table-wrapper__th--w-12">Modified By</th>
              </tr>
              {selectedModels?.length > 0 && (
                <ModelListActionsRow
                  selectedModels={selectedModels}
                  setSelectedModels={setSelectedModels}
                />
              )}
            </thead>
            <tbody className="table-wrapper__tbody">
              {!isLoading && isEmpty ? (
                <EmptyTableState message="There are no models to display" colCount="6" />
              ) : (
                data?.records.map((model) => (
                  <ModelListRow
                    model={model}
                    key={model.uuid}
                    selected={selectedModels.includes(model.uuid)}
                    handleModelSelect={handleModelSelect}
                    showModels={showModels}
                  />
                ))
              )}
            </tbody>
          </table>
          <ListFooterHooksBased
            resourceStateName={resourceQueryParamName.model}
            resourceName="use case"
            fetchHook={useModels}
          />
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default ModelTable;
