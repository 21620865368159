import { KeyMap } from 'react-hotkeys';

const keyMap: KeyMap = {
  PREV_ENVELOPE: {
    name: 'Go previous envelope',
    sequence: 'ctrl+left',
    action: 'keydown',
  },
  ACCEPT_ENVELOPE: {
    name: 'Mark envelope as "Acceptable"',
    sequence: '',
    sequences: ['ctrl+option+a', 'ctrl+option+A'],
    action: 'keydown',
  },
  REJECT_ENVELOPE: {
    name: 'Mark envelope as "Out of policy"',
    sequence: '',
    sequences: ['ctrl+option+o', 'ctrl+option+O'],
    action: 'keydown',
  },
  ESCALATE_ENVELOPE: {
    name: 'Mark envelope as "Escalated"',
    sequence: '',
    sequences: ['ctrl+option+q', 'ctrl+option+Q'],
    action: 'keydown',
  },
  REVIEW_ENVELOPE: {
    name: 'Mark envelope as "Reviewed"',
    sequence: '',
    sequences: ['ctrl+option+r', 'ctrl+option+R'],
    action: 'keydown',
  },
  SAVE_ENVELOPE: {
    name: 'Save changes in envelope',
    sequence: '',
    sequences: ['ctrl+option+s', 'ctrl+option+S'],
    action: 'keydown',
  },
  FOCUS_TAGS_INPUT: {
    name: 'Set focus on tags input in envelopes',
    sequence: '',
    sequences: ['ctrl+option+t', 'ctrl+option+T'],
    action: 'keydown',
  },
  RUN_TEST_PHRASE: {
    name: 'Run test for a phrase',
    sequence: 'ctrl+enter',
    action: 'keydown',
  },
  // TODO - figure out a cleaner way to add alternatives, passing an array in as a sequence causes error in lib
  RUN_TEST_PHRASE_ALT: {
    name: 'Run test for a phrase',
    sequence: 'command+enter',
    action: 'keydown',
  },
  SAVE_TO_SUITE: {
    name: 'Save test to suite',
    sequence: 'ctrl+s',
    action: 'keydown',
  },
  CHECK_ENVELOPE: {
    name: 'Check envelope if mouse is hover',
    sequence: 'ctrl+c',
    action: 'keydown',
  },
  TOGGLE_ENVELOPE: {
    name: 'Toggle envelope if mouse is hover',
    sequence: 'ctrl+v',
    action: 'keydown',
  },
  OPEN_SAVE_TEST_MODAL: {
    name: 'Open save test from event modal',
    sequence: 'tab+s',
    action: 'keydown',
  },
  SAVE_CAMPAIGN: {
    name: 'Save use case',
    sequence: 'ctrl+s',
    action: 'keydown',
  },
  SAVE_ANNOTATOR: {
    name: 'Save identifier',
    sequence: 'ctrl+s',
    action: 'keydown',
  },
  SAVE_RULE: {
    name: 'Save model',
    sequence: 'tab+s',
    action: 'keydown',
  },
  SHOW_DIALOG: {
    name: 'Display a modal with all available shortcuts',
    sequence: 'ctrl+?',
    action: 'keydown',
  },
  PRESS_KEY_DOWN: {
    name: '<SearchInputByResource /> move down on items',
    sequence: 'down',
    action: 'keydown',
  },
  PRESS_KEY_UP: {
    name: '<SearchInputByResource /> move up on items',
    sequence: 'up',
    action: 'keydown',
  },
  PRESS_KEY_ENTER: {
    name: '<SearchInputByResource /> select an item',
    sequence: 'enter',
    action: 'keydown',
  },
};

export default keyMap;
