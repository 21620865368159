import { apiClient as LitLingoClient, apiClientV2 as LitLingoClientV2 } from 'client';
import { RequestCtxAndFnName, RequestTypes } from 'hooks/types/client';

// TODO - Check this type with Piedra - Move to the client?
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const requestEndpoints: Record<keyof RequestTypes, RequestCtxAndFnName<any, string>> = {
  'teams.retrieve': [LitLingoClient.resources.teams, 'retrieve'],
  'teams.list': [LitLingoClient.resources.teams, 'list'],
  'teams.upsert': [LitLingoClient.resources.teams, 'upsert'],
  'teams.delete': [LitLingoClient.resources.teams, 'delete'],
  'teams.extras.addUser': [LitLingoClient.resources.teams.extras, 'addUser'],
  'teams.extras.removeUser': [LitLingoClient.resources.teams.extras, 'removeUser'],
  'testCases.list': [LitLingoClient.resources.testCases, 'list'],
  'envelopes.extras.searchTree': [LitLingoClient.resources.envelopes.extras, 'searchTree'],
  'customReports.list': [LitLingoClient.resources.customReports, 'list'],
  'annotators.list': [LitLingoClient.resources.annotators, 'list'],
  'campaigns.list': [LitLingoClient.resources.campaigns, 'list'],
  'communications.clones': [LitLingoClient.resources.communications.extras, 'clones'],
  'identifiers.list': [LitLingoClientV2.resources.identifiers, 'list'],
  'identifiers.getRevisions': [LitLingoClientV2.resources.identifiers.extras, 'getRevisions'],
  'identifiers.extras.getCategories': [
    LitLingoClientV2.resources.identifiers.extras,
    'getCategories',
  ],
  'models.list': [LitLingoClientV2.resources.models, 'list'],
  'categories.list': [LitLingoClientV2.resources.categories, 'list'],
};

export default requestEndpoints;
