import { deleteIdentifier, upsertIdentifier } from 'actions/identifier';
import ConfirmModal from 'components/ConfirmModal';
import Modal from 'components/Modal';
import SelectRedesign from 'components/Select/SelectRedesign';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { NormalizedIdentifier } from 'types';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
  identifier: NormalizedIdentifier;
  title?: string;
};

const anotatorRetentionOptions = [
  {
    value: 'false',
    label: 'Never save',
  },
  {
    value: 'true',
    label: 'Always save',
  },
];

const EditIdentifierModal: React.FC<ComponentProps> = ({
  isModalOpen,
  toggleModalOpen,
  identifier,
  title,
}) => {
  const dispatch = useDispatch();
  const [retention, setRetention] = useState<string>(identifier.always_save.toString());
  const [isDeletingIdentifier, setIsDeletingIdentifier] = useState(false);

  const { register, getValues, handleSubmit } = useForm<{
    name: string;
    description: string;
  }>({
    defaultValues: {
      name: identifier.name,
      description: identifier.description || '',
    },
  });

  const deleteButtonStyle = 'text-litlingo-alert underline self-end focus:outline-none';

  const onSubmit = (): void => {
    const { name, description } = getValues();

    dispatch(
      upsertIdentifier({
        ...identifier,
        identifierId: identifier.uuid,
        name,
        description,
        always_save: retention === 'true',
      })
    );
    toggleModalOpen();
  };

  const handleSaveButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const toggleConfirmModal = (): void => setIsDeletingIdentifier(!isDeletingIdentifier);

  const handleDeleteClick = async (): Promise<void> => {
    dispatch(deleteIdentifier({ identifierId: identifier.identifier_uuid, redirect: true }));
    toggleConfirmModal();
    toggleModalOpen();
  };

  const renderModelForm = (
    <div className="pt-4 pb-8">
      <form className="flex flex-col items-start">
        <div className="flex flex-col items-start gap-4 w-full">
          <label htmlFor="name" className="flex flex-col gap-1 mt-2 text-body">
            <div className="flex flex-row gap-1 text-small">
              <span>Name</span>
              <span className="litlingo-red-color">*</span>
            </div>

            <input
              id="name"
              ref={register({ required: true, pattern: /\S/i })}
              name="name"
              placeholder="Identifier name"
              className="form-input h-9 w-66 p-2 text-body rounded placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>

          <label htmlFor="description" className="flex flex-col gap-1 mt-2 text-body">
            <div className="flex flex-row gap-1 text-small">
              <span>Description</span>
            </div>

            <textarea
              id="description"
              ref={register()}
              name="description"
              rows={3}
              placeholder="Use this description to help others understand the purpose of this identifier."
              className="form-input w-112 p-2 text-body rounded resize-none placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>

          <div className="flex flex-col gap-1 mt-2 text-body">
            <div className="flex flex-row gap-1">
              <span>Sort by</span>
            </div>

            <div className="flex-shrink-0 w-44">
              <SelectRedesign
                onChange={(value: string): void => setRetention(value)}
                options={anotatorRetentionOptions}
                value={retention}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderModelForm}
          title={title || 'Edit Identifier'}
          okButton
          okButtonText="Save"
          okButtonOnClick={handleSaveButton}
          toggleShowModal={toggleModalOpen}
          secondaryButton
          secondaryButtonOnclick={toggleConfirmModal}
          secondaryButtonStyle={deleteButtonStyle}
          secondaryButtonText="Delete Identifier"
        />
      )}
      {isDeletingIdentifier && (
        <ConfirmModal
          data-testid="delete-modal"
          text="Are you sure you want to delete this identifier?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDeleteClick}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </>
  );
};

export default EditIdentifierModal;
