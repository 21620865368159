import useRequestMutation from 'hooks/client/useMutation';
import { MutationResponse } from 'hooks/types/client';

type DeleteTeamParams = { id: string };

type UseDeleteTeam = () => Omit<MutationResponse<'teams.delete'>, 'mutate'> & {
  mutate: (params: DeleteTeamParams) => void;
};

const useDeleteTeam: UseDeleteTeam = () => {
  const { mutate, ...rest } = useRequestMutation('teams.delete', {
    invalidate: [['teams.list']],
  });

  return {
    ...rest,
    mutate: (params) => mutate({ urlParams: params }),
  };
};

export default useDeleteTeam;
