import { CLOSE_MODAL_ICON } from 'constants/commonIcons';
import { LEFT_ARROW_ICON } from 'constants/envelopeIcons';
import React, { CSSProperties } from 'react';

type SimpleModalProps = {
  title?: string;
  toggle?: () => void;
  style?: CSSProperties;
  prevButtonOnClick?: () => void;
  xButton?: boolean;
};

const SimpleModal: React.FC<SimpleModalProps> = (props) => {
  const { children, title, toggle, style, prevButtonOnClick, xButton = true } = props;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-modal">
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-litlingo-gray-6 opacity-25" />
      </div>

      <div
        style={style}
        className="bg-white rounded-lg p-6 shadow-xl transform transition-all w-148"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="flex flex-row gap-2">
          {prevButtonOnClick && (
            <div className="flex">
              <button
                type="button"
                data-testid="close-button"
                className="text-gray-700 hover:text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                aria-label="Close"
                onClick={prevButtonOnClick}
              >
                {LEFT_ARROW_ICON}
              </button>
            </div>
          )}
          <div className="flex flex-1 items-start">
            <span className="text-heading-2" id="modal-headline">
              {title}
            </span>
          </div>
          {xButton && (
            <div className="flex">
              <button
                type="button"
                data-testid="close-button"
                className="w-6 z-10 focus:outline-none"
                aria-label="Close"
                onClick={toggle}
              >
                {CLOSE_MODAL_ICON}
              </button>
            </div>
          )}
        </div>

        <div className="h-full">{children}</div>
      </div>
    </div>
  );
};

export default SimpleModal;
