/* eslint-disable camelcase */
import * as Sentry from '@sentry/react';
import { fetchRulesDiffForCompareRequest, fetchRulesForCompareRequest } from 'actions/rulesCompare';
import { isNumber, isObject } from 'lodash';
import { createSelector } from 'reselect';
import type {
  AnnotatorRelationship,
  MRule,
  MRuleConfig,
  MRuleConfigNode,
  MRuleConfigRelationship,
  MRuleConfigWithChanges,
  ModeOfSpeech,
  NormalizedResource,
  RuleAnnotator,
  RuleChange,
  Selector,
  UUID,
} from 'types';
import { v4 } from 'uuid';

type Side = 'left' | 'right';

export const getRulesCompareLoading: Selector<boolean> = (state) =>
  state.rulesCompare.loading.includes(fetchRulesForCompareRequest.toString()) ||
  state.rulesCompare.loading.includes(fetchRulesDiffForCompareRequest.toString());

export const getRuleInCompare: Selector<MRule | null, [string]> = (state, side) => {
  if (side === 'left') {
    return state.rulesCompare.ruleOne;
  }

  return state.rulesCompare.ruleTwo;
};

export const getDisabledNode: Selector<boolean, [UUID, string]> = (state, id, side) => {
  let item = state.config.items[id];

  if (side === 'left') {
    item = state.rulesCompare.configItemsRuleOne[id];
  } else {
    item = state.rulesCompare.configItemsRuleTwo[id];
  }

  if ('modifiers' in item && item.modifiers?.DISABLE === true) {
    return true;
  }

  return false;
};

export const getConfigItems: Selector<NormalizedResource<MRuleConfigNode>, [string]> = (
  state,
  side
) => {
  if (side === 'left') {
    return state.rulesCompare.configItemsRuleOne;
  }

  return state.rulesCompare.configItemsRuleTwo;
};

export const getNodeInCompare: Selector<MRuleConfigNode, [Side, string]> = (state, side, id) => {
  // if(id === null) returnr null
  if (side === 'left') {
    return state.rulesCompare.configItemsRuleOne[id];
  }

  return state.rulesCompare.configItemsRuleTwo[id];
};

export const getRulesDiffInRulesCompare: Selector<RuleChange[]> = (state) =>
  state.rulesCompare.rulesDiff;

export const getAnnotatorRelationships: Selector<
  NormalizedResource<AnnotatorRelationship>,
  [string]
> = (state, side) => {
  if (side === 'left') {
    return state.rulesCompare.relationshipsOne;
  }

  return state.rulesCompare.relationshipsTwo;
};

export const getRuleAnnotators: Selector<RuleAnnotator[] | null, [string]> = (state, side) => {
  if (side === 'left') {
    return state.rulesCompare.ruleOne?.annotators || null;
  }

  return state.rulesCompare.ruleTwo?.annotators || null;
};

export const getRuleConfigId: Selector<string | null, [string]> = (state, side) => {
  if (side === 'left') {
    return state.rulesCompare.ruleOne?.rootConfigId || null;
  }

  return state.rulesCompare.ruleTwo?.rootConfigId || null;
};

const getConfigRuleAsArray: Selector<MRuleConfig[], [string]> = createSelector(
  [getConfigItems, getAnnotatorRelationships, getRuleAnnotators, getRuleConfigId],
  (config, relationshipState, annotators, rootConfigId) => {
    const traverseTree = (curIdNode: UUID, level: number, parent: UUID[]): MRuleConfig[] => {
      const curNode = config[curIdNode];
      if (!curNode) {
        return [];
      }
      const { id, name, description, color } = curNode;

      let negated;
      let mode_of_speech: ModeOfSpeech | undefined;
      let annotatorId: UUID | undefined;
      let modifiers;
      let nodeGroups;

      if (curNode.typeOfConfig === 'ANNOTATION_MATCH') {
        mode_of_speech = curNode.mode_of_speech;
        negated = curNode.negated;
        annotatorId = curNode.annotatorId;
      } else {
        modifiers = curNode.modifiers;
        nodeGroups = curNode.groups;
      }
      const relationship: MRuleConfigRelationship[] = [];

      if (curNode.parent != null) {
        const parentNode = config[curNode.parent];
        const curRelationship = 'relationship' in parentNode ? parentNode.relationship : null;

        if (curRelationship != null) {
          curRelationship.forEach((key) => {
            const cur = relationshipState[key];

            if (cur && cur.annotation_a != null && cur.annotation_b != null) {
              const annotatorAId = cur.annotation_a.id;
              const annotatorBId = cur.annotation_b.id;

              if (annotatorAId === id && config[annotatorBId]) {
                const configB = config[annotatorBId];
                if (annotators != null && 'annotatorId' in configB) {
                  const actualAnnotator = annotators.find(
                    (element) => element.annotator_uuid === configB.annotatorId
                  );
                  relationship.push({
                    id: cur.id,
                    name: `${cur.type} ${actualAnnotator?.annotator?.name}`,
                    deleted: !!(actualAnnotator && actualAnnotator.annotator?.deleted_at != null),
                  });
                }
              }
            }
          });
        }
      }

      // FIXME: Create a function to process the annotator name
      let ruleName = '';
      let deleted = false;
      if (!annotators) {
        ruleName = name;
      } else if (annotators != null) {
        const actualAnnotator = annotators.find(
          (element) => element.annotator_uuid === annotatorId
        );
        if (actualAnnotator && annotatorId) {
          if (actualAnnotator && actualAnnotator.annotator == null) {
            ruleName = `Identifier doesn't exist ${annotatorId}`;
            deleted = true;
          } else if (actualAnnotator.annotator?.deleted_at == null) {
            ruleName = actualAnnotator.annotator?.name ?? '';
          } else {
            ruleName = actualAnnotator.annotator?.name ?? '';
            deleted = true;
            // @ts-ignore
            if (config.lastChangedNode === annotatorId) {
              Sentry.captureMessage(`${annotatorId} annotator was deleted`);
            }
          }
        } else {
          ruleName = name;
        }
      }

      const curItem: MRuleConfig[] = [
        {
          id,
          name: ruleName,
          ...(curNode.typeOfConfig !== 'ANNOTATION_MATCH' ? { description } : {}),
          ...(curNode.typeOfConfig !== 'ANNOTATION_MATCH' ? { color } : {}),
          level,
          parent,
          deleted,
          relationship,
          modifiers,
          nodeGroups,
          ...(curNode.typeOfConfig === 'ANNOTATION_MATCH'
            ? { negated, mode_of_speech, annotatorId }
            : {}),
        },
      ];

      const groups = 'groups' in curNode ? curNode.groups : [];
      return groups.reduce(
        (curArray, nextNodeId) =>
          traverseTree(nextNodeId, level + 1, [...parent, id]).concat(curArray),
        curItem
      );
    };
    return traverseTree(rootConfigId || '', 0, []).reverse();
  }
);

type RuleChangeWithIndex = RuleChange & { index: number };

export type ArrowChange = {
  id: string;
  leftId: string;
  leftIndex: number;
  leftCount: number;
  rightId: string;
  rightIndex: number;
  rightCount: number;
  type: 'add' | 'delete';
};

// Find index including relationships. Mostly for ui stuff like arrows
const findIndex = (array: MRuleConfigWithChanges[], changedNodeId: string): number => {
  let tempIndex = 0;
  let found = 0;
  array.forEach((node) => {
    if (node.id === changedNodeId) {
      found = tempIndex;
    } else {
      tempIndex += 1;
      const relationships = node.relationship;
      if (relationships) {
        relationships.forEach((r) => {
          if (r.id === changedNodeId) {
            found = tempIndex;
          }
          tempIndex += 1;
        });
      }
    }
  });

  return found;
};

// Includes relationships when calculating length - Could get relationship out of the node and make them a regular item later
const getTrueLength = (array: MRuleConfigWithChanges[]): number => {
  let tempIndex = 0;

  array.forEach((node) => {
    tempIndex += 1;
    const relationships = node.relationship;
    if (relationships) {
      tempIndex += relationships.length;
    }
  });

  return tempIndex;
};

// Get index of last item in a parent. For inserting purposes
const getMaxIndexInParent = (array: MRuleConfigWithChanges[], nodeId: string): number => {
  const index = array.findIndex((node) => node.id === nodeId);
  let temp = index + 1;

  for (let i = index + 1; i < array.length; i += 1) {
    const tempNode = array[i];
    if (tempNode.parent.includes(nodeId)) {
      temp += 1;
      const relationships = tempNode.relationship;
      if (relationships) {
        // temp += relationships.length;
      }
    } else {
      break;
    }
  }

  return temp;
};

// Apply the change to node and children recursivelly. Return amount of nodes the change applies to. Just in case we need it
const makeChanges = (
  nodeId: string,
  array: MRuleConfigWithChanges[],
  change: RuleChange['kind'],
  count = 1
): number => {
  const index = array.findIndex((node) => node.id === nodeId);
  const node = array[index];

  if (!node) return 0;

  node.changed = change;

  if (node.nodeGroups && node.nodeGroups.length > 0) {
    node.nodeGroups.forEach((nG) => {
      makeChanges(nG, array, change, count);
    });
    return count + node.nodeGroups?.length;
  }

  return count;
};

export const getModifiedArray: Selector<
  { arrayTree: MRuleConfigWithChanges[]; arrows: ArrowChange[] },
  [string]
> = (state, side) => {
  // Getting the regular configs.
  let arrayOne = getConfigRuleAsArray(state, 'left') as MRuleConfigWithChanges[];
  let arrayTwo = getConfigRuleAsArray(state, 'right') as MRuleConfigWithChanges[];

  // True lenght includes relationships.
  const arrayOneTrueLength = getTrueLength(arrayOne);
  const arrayTwoTrueLength = getTrueLength(arrayOne);

  // All changes
  const changesArray = getRulesDiffInRulesCompare(state);

  // Changes that are changes
  const pureChanges = changesArray.filter((c) => c.kind === 'change');

  // Changes that are add or delete
  const combinedChanges = changesArray
    .filter((c) => c.kind === 'add' || c.kind === 'delete')
    .filter((c) => isNumber(c.path[c.path.length - 1]));

  // Need to index the changes according to where they should go so we can sort later
  const combinedIndexedChanges: RuleChangeWithIndex[] = combinedChanges.map((change) => {
    let index = 0;
    if (change.kind === 'add') {
      if (isObject(change.new)) {
        const changedNodeId = change.new.uuid;
        index = findIndex(arrayTwo, changedNodeId);
      }
    } else if (isObject(change.old)) {
      const changedNodeId = change.old.uuid;
      index = findIndex(arrayOne, changedNodeId);
    }

    return { ...change, index };
  });

  // Sorting the changes makes it less likely that arrow cross each other
  combinedIndexedChanges.sort((a, b) => {
    if (a.index < b.index) {
      return -1;
    }

    if (a.index > b.index) {
      return 1;
    }
    if (a.kind === 'delete') {
      return -1;
    }
    return 1;
  });

  const arrows: ArrowChange[] = [];

  // Go through changes and do several things
  combinedIndexedChanges.forEach((change) => {
    let changedNodeId = '';
    let index = 0;
    let count = 0;

    // In add/delete id is the parent id
    const parentId = change.uuid;
    const parentNode = arrayOne.find((n) => n.id === parentId);

    // For add info is in change.new. For delete info is in change.old
    // Also for add change applies to arrayOne and insert space in arrayTwo. Delete is the other way around
    if (change.kind === 'add') {
      if (isObject(change.new)) {
        changedNodeId = change.new.uuid;
        index = findIndex(arrayTwo, changedNodeId);
        const node = arrayTwo.find((n) => n.id === changedNodeId);

        // If node exist apply change recursivelly. If not then is a relationship, find it and apply change
        if (node) {
          count = makeChanges(changedNodeId, arrayTwo, change.kind);
        } else {
          arrayTwo.forEach((n) => {
            const relationships = n.relationship;
            if (relationships) {
              relationships.forEach((r) => {
                if (r.id === changedNodeId) {
                  // @ts-ignore
                  r.changed = 'add';
                }
              });
            }
          });
          count = 1;
        }
      }
    } else if (isObject(change.old)) {
      changedNodeId = change.old.uuid;
      index = findIndex(arrayOne, changedNodeId);
      const node = arrayOne.find((n) => n.id === changedNodeId);

      if (node) {
        count = makeChanges(changedNodeId, arrayOne, change.kind);
      } else {
        arrayOne.forEach((n) => {
          const relationships = n.relationship;
          if (relationships) {
            relationships.forEach((r) => {
              if (r.id === changedNodeId) {
                // @ts-ignore
                r.changed = 'delete';
              }
            });
          }
        });

        count = 1;
      }
    }

    // Need to find where to insert space in the other array. At most at the end of the array
    if (change.kind === 'add') {
      if (index > arrayOneTrueLength) {
        count += index - arrayOneTrueLength;
        index = arrayOneTrueLength;
      }
    } else if (index > arrayTwoTrueLength) {
      count += index - arrayTwoTrueLength;
      index = arrayTwoTrueLength;
    }

    // Arrows - Basically we can't get the positions of the arrows here because future inserts might ruin it.
    // So we create the arrows with the ids they should point to, and get the positions after all insertion is done

    // Create mock arrow
    const arrow: ArrowChange = {
      id: v4(),
      leftId: '',
      leftIndex: 0,
      leftCount: 0,
      rightId: '',
      rightIndex: 0,
      rightCount: 0,
      type: 'add',
    };

    // Create the empty row we are gonna insert to signal the change in the other array
    // We need the id so we can look for it later
    const insertId = v4();
    const temp = new Array(1).fill({
      // So ui renders empty row
      empty: true,
      id: insertId,
      // We need the parents for later
      parent: [...(parentNode ? parentNode?.parent : []), parentId],
    });

    // Insert the values we know in the arrow
    if (change.kind === 'add') {
      arrow.leftId = insertId;
      arrow.leftCount = 1;
      arrow.rightId = changedNodeId;
      arrow.rightCount = count;
    } else {
      arrow.leftId = changedNodeId;
      arrow.leftCount = count;
      arrow.rightId = insertId;
      arrow.rightCount = 1;
      arrow.type = 'delete';
    }

    // Push arrow
    arrows.push(arrow);

    // Empty rows are inserted as the last child of the parent.
    if (change.kind === 'add') {
      const ind = getMaxIndexInParent(arrayOne, parentId);
      arrayOne = [...arrayOne.slice(0, ind), ...temp, ...arrayOne.slice(ind)];
    } else {
      const ind = getMaxIndexInParent(arrayTwo, parentId);
      arrayTwo = [...arrayTwo.slice(0, ind), ...temp, ...arrayTwo.slice(ind)];
    }
  });

  // Go through the arrows and get the actual positions they should point to
  arrows.forEach((arrow) => {
    let foundLeft = 0;
    let foundRight = 0;

    foundLeft = findIndex(arrayOne, arrow.leftId);
    foundRight = findIndex(arrayTwo, arrow.rightId);

    arrow.leftIndex = foundLeft;
    arrow.rightIndex = foundRight;
  });

  // For regular changes only thing to do is find the nodes and add the change so ui can render it
  // Not many different type of changes now - Need to support more using an array - Need to support multiple in same node also using an array
  const operatorChanges = pureChanges.filter((change) => change.path.includes('operator'));
  const descriptionChanges = pureChanges.filter((change) => change.path.includes('description'));

  operatorChanges.forEach((change) => {
    const leftIndex = arrayOne.findIndex((node) => node.id === change.uuid);
    const rightIndex = arrayTwo.findIndex((node) => node.id === change.uuid);

    arrayOne[leftIndex] = { ...arrayOne[leftIndex], changed: 'change', change: 'operator' };
    arrayTwo[rightIndex] = { ...arrayTwo[rightIndex], changed: 'change', change: 'operator' };
  });

  descriptionChanges.forEach((change) => {
    const leftIndex = arrayOne.findIndex((node) => node.id === change.uuid);
    const rightIndex = arrayTwo.findIndex((node) => node.id === change.uuid);

    arrayOne[leftIndex] = { ...arrayOne[leftIndex], changed: 'change', change: 'description' };
    arrayTwo[rightIndex] = { ...arrayTwo[rightIndex], changed: 'change', change: 'description' };
  });

  // Return final tree depending on side. Could just return both if it's usefull
  if (side === 'right') {
    return { arrayTree: arrayTwo, arrows };
  }

  return { arrayTree: arrayOne, arrows };
};

export const getAnnotatorsDiff: Selector<{
  shared: RuleAnnotator[];
  added: RuleAnnotator[];
  deleted: RuleAnnotator[];
}> = (state) => {
  const ruleOne = getRuleInCompare(state, 'left');
  const annotatorsOne = ruleOne?.annotators;
  const ruleTwo = getRuleInCompare(state, 'right');
  const annotatorsTwo = ruleTwo?.annotators;

  const shared: RuleAnnotator[] = [];
  const deleted: RuleAnnotator[] = [];
  const added: RuleAnnotator[] = [];

  annotatorsOne?.forEach((a) => {
    if (annotatorsTwo?.some((b) => a.annotator_uuid === b.annotator_uuid)) {
      shared.push(a);
    } else {
      deleted.push(a);
    }
  });

  annotatorsTwo?.forEach((a) => {
    if (!annotatorsOne?.some((b) => a.annotator_uuid === b.annotator_uuid)) {
      added.push(a);
    }
  });

  return { shared, added, deleted };
};

export const getAllChildNodesAndRelationships: Selector<MRuleConfigNode[], [Side, UUID]> = (
  state,
  side,
  configId
) => {
  const mainNode = getNodeInCompare(state, side, configId);
  const childNodes: MRuleConfigNode[] = [];

  const recursiveGetChildNodes = (node: MRuleConfigNode): void => {
    const relationship = 'relationship' in node;
    const groups = 'groups' in node;

    if (relationship) {
      const relationships = node.relationship;
      relationships.forEach((subNode: string) => {
        childNodes.push(getNodeInCompare(state, side, subNode));
      });
    }
    if (groups) {
      const subNodes = node.groups;
      subNodes.forEach((subNode: string) => {
        const subNodeConfig = getNodeInCompare(state, side, subNode);

        childNodes.push(subNodeConfig);
        recursiveGetChildNodes(subNodeConfig);
      });
    }
  };
  recursiveGetChildNodes(mainNode);
  return childNodes;
};

export const getAllChildNodes: Selector<string[], [Side, UUID]> = (state, side, configId) => {
  const mainNode = getNodeInCompare(state, side, configId);
  const childNodes: string[] = [];

  const recursiveGetChildNodes = (node: MRuleConfigNode): void => {
    if (node.typeOfConfig !== 'ANNOTATION_MATCH') {
      const subNodes = node.groups;
      subNodes.forEach((subNode: string) => {
        childNodes.push(subNode);
        recursiveGetChildNodes(getNodeInCompare(state, side, subNode));
      });
    }
  };
  recursiveGetChildNodes(mainNode);
  return childNodes;
};

export const getAllRelationships: Selector<
  NormalizedResource<AnnotatorRelationship> | null,
  [Side, UUID]
> = (state, side, configId) => {
  const annotator = getNodeInCompare(state, side, configId);
  if (!annotator || !('relationship' in annotator)) {
    return null;
  }

  const relationships: NormalizedResource<AnnotatorRelationship> = {};
  annotator.relationship.forEach((item) => {
    relationships[item] = state.relationship[item];
  });

  return relationships;
};

/* export const getAnnotatorsForHeader: Selector<Annotator[], [Side]> = (state, side) => {
  const baseRuleAnnotators = getRuleAnnotators(state, side)
  const otherRuleAnnotators = getRuleAnnotators(state, side === 'left' ? 'left' : 'right' )

  const annotators = {shared: [], deleted: [],added: []}

  baseRuleAnnotators?.forEach((bA) => {
    if(bA)
  })

  return relationships;
}; */

export const getDiffOriginalModel: Selector<string> = (state) => {
  const customerId = state.auth.user.customer?.uuid;
  let ruleId = '';

  if (state.rulesCompare.ruleOne && customerId === state.rulesCompare.ruleOne.customer?.uuid) {
    ruleId = state.rulesCompare.ruleOne.uuid;
  } else if (state.rulesCompare.ruleTwo) {
    ruleId = state.rulesCompare.ruleTwo.uuid;
  }

  return ruleId;
};

export default getConfigRuleAsArray;
