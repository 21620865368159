import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  FetchAllCategoriesSuccessPayload,
  createCategoriesuccess,
  createCategoryFailure,
  createCategoryFulfill,
  createCategoryRequest,
  deleteCategoriesuccess,
  deleteCategoryFailure,
  deleteCategoryFulfill,
  deleteCategoryOutcomeSuccess,
  deleteCategoryRequest,
  fetchAllCategoriesFailure,
  fetchAllCategoriesFulfill,
  fetchAllCategoriesRequest,
  fetchAllCategoriesSuccess,
  fetchSingleCategoriesuccess,
  fetchSingleCategoryFailure,
  fetchSingleCategoryFulfill,
  fetchSingleCategoryRequest,
  upsertCategoriesuccess,
  upsertCategoryFailure,
  upsertCategoryFulfill,
  upsertCategoryRequest,
} from 'actions/categories';

import type { Category, ErrorObject, NormalizedResource, UUID } from 'types';

export type Categoriestate = {
  categories: NormalizedResource<Category>;
  filterItems: NormalizedResource<Category>;
  activeCategory: UUID;
  error: ErrorObject | null;
  loading: string[];
  count: number;
  activeIntegrations: string[];
};
type CategoryReducer<P = void> = (
  state: Categoriestate,
  action: PayloadAction<P>
) => Categoriestate;

const defaultState: Categoriestate = {
  categories: {},
  activeCategory: '',
  filterItems: {},
  error: null,
  loading: [],
  count: 0,
  activeIntegrations: [],
};

const handleFetchAllCategoriesRequest: CategoryReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAllCategoriesRequest.toString()],
});

const handleFetchAllCategoriesSuccess: CategoryReducer<FetchAllCategoriesSuccessPayload> = (
  state,
  { payload }
) => {
  const categories: NormalizedResource<Category> = {};
  payload.records.forEach((newCategory) => {
    const { uuid } = newCategory;
    const category = state.categories[uuid] || {};

    categories[uuid] = {
      ...category,
      ...newCategory,
    };
  });

  return {
    ...state,
    categories: {
      ...categories,
    },
    count: payload.count,
  };
};

const handleFetchAllCategoriesFailure: CategoryReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleFetchAllCategoriesFulfill: CategoryReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchAllCategoriesRequest.toString()),
});

const handleFetchSingleCategoryRequest: CategoryReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchSingleCategoryRequest.toString()],
});

const handleFetchSingleCategoriesuccess: CategoryReducer<Category> = (state, { payload }) => {
  const item = payload;
  const category = state.categories[item.uuid] || {};

  return {
    ...state,
    categories: {
      ...state.categories,
      [payload.uuid]: { ...category, ...item },
    },
  };
};

const handleFetchSingleCategoryFailure: CategoryReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleFetchSingleCategoryFulfill: CategoryReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchSingleCategoryRequest.toString()),
});

const handleCreateCategoryRequest: CategoryReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, createCategoryRequest.toString()],
});

const handleCreateCategorieSuccess: CategoryReducer<Category> = (state, { payload }) => ({
  ...state,
  categories: {
    ...state.categories,
    [payload.uuid]: { ...payload, isNew: true },
  },
});

const handleCreateCategoryFailure: CategoryReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleCreateCategoryFulfill: CategoryReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== createCategoryRequest.toString()),
});

const handleDeleteCategoryRequest: CategoryReducer<string> = (state, { payload: id }) => ({
  ...state,
  categories: {
    ...state.categories,
    [id]: { ...state.categories[id], unSaved: true },
  },
  error: null,
  loading: [...state.loading, deleteCategoryRequest.toString()],
});

const handleDeleteCategoriesuccess: CategoryReducer<string> = (state, { payload: id }) => {
  const categories = { ...state.categories };
  delete categories[id];

  return {
    ...state,
    categories,
  };
};

const handleDeleteCategoryFailure: CategoryReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleDeleteCategoryFulfill: CategoryReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== deleteCategoryRequest.toString()),
});

const handleUpsertCategoryRequest: CategoryReducer<Category> = (state) => ({
  ...state,

  loading: [...state.loading, upsertCategoryRequest.toString()],
});

const handleUpsertCategoriesuccess: CategoryReducer<Category> = (state, { payload }) => ({
  ...state,
  categories: {
    ...state.categories,
    [payload.uuid]: {
      ...state.categories[payload.uuid],
      ...payload,
    },
  },
});

const handleUpsertCategoryFailure: CategoryReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleUpsertCategoryFulfill: CategoryReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== upsertCategoryRequest.toString()),
});

const handleDeleteCategoryOutcomeSuccess: CategoryReducer<
  ReturnType<typeof deleteCategoryOutcomeSuccess>['payload']
> = (state, { payload }) => {
  const { id } = payload;
  const categories = { ...state.categories };
  delete categories[id];
  return {
    ...state,
    categories,
  };
};

const handlers = {
  [fetchAllCategoriesRequest.toString()]: handleFetchAllCategoriesRequest,
  [fetchAllCategoriesSuccess.toString()]: handleFetchAllCategoriesSuccess,
  [fetchAllCategoriesFailure.toString()]: handleFetchAllCategoriesFailure,
  [fetchAllCategoriesFulfill.toString()]: handleFetchAllCategoriesFulfill,

  [fetchSingleCategoryRequest.toString()]: handleFetchSingleCategoryRequest,
  [fetchSingleCategoriesuccess.toString()]: handleFetchSingleCategoriesuccess,
  [fetchSingleCategoryFailure.toString()]: handleFetchSingleCategoryFailure,
  [fetchSingleCategoryFulfill.toString()]: handleFetchSingleCategoryFulfill,

  [createCategoryRequest.toString()]: handleCreateCategoryRequest,
  [createCategoriesuccess.toString()]: handleCreateCategorieSuccess,
  [createCategoryFailure.toString()]: handleCreateCategoryFailure,
  [createCategoryFulfill.toString()]: handleCreateCategoryFulfill,

  [deleteCategoryRequest.toString()]: handleDeleteCategoryRequest,
  [deleteCategoriesuccess.toString()]: handleDeleteCategoriesuccess,
  [deleteCategoryFailure.toString()]: handleDeleteCategoryFailure,
  [deleteCategoryFulfill.toString()]: handleDeleteCategoryFulfill,

  [upsertCategoryRequest.toString()]: handleUpsertCategoryRequest,
  [upsertCategoriesuccess.toString()]: handleUpsertCategoriesuccess,
  [upsertCategoryFailure.toString()]: handleUpsertCategoryFailure,
  [upsertCategoryFulfill.toString()]: handleUpsertCategoryFulfill,

  [deleteCategoryOutcomeSuccess.toString()]: handleDeleteCategoryOutcomeSuccess,
};

const categoriesReducer = createReducer(defaultState, handlers);

export default categoriesReducer;
