import { UUID } from '@litlingo/client';
import { bgColorsUtilization, textUtilization } from 'constants/rowItem';
import React from 'react';
import {
  getAnnotationMatchedLanguage,
  getAnnotationMatchedLanguageV2,
  getShowV2Graph,
} from 'selectors/communications';
import {
  getIdentifierShowUtilization,
  getMatcherUtilization,
  getScopedCustomersMatcher,
  getSelectedCustomerIdentifier,
} from 'selectors/identifier';
import { useSelector } from 'store';
import type { MLanguageMatcher } from 'types';

type ComponentProps = {
  languageMatcher: MLanguageMatcher;
  highlightedLanguageMatcher: string | undefined;
  handleLanguageMatcherClick: (languageMatcherId: UUID) => void;
};

const LanguageMatcherSidebarItem: React.FC<ComponentProps> = ({
  languageMatcher,
  highlightedLanguageMatcher,
  handleLanguageMatcherClick,
}) => {
  const utilization = useSelector((state) => getMatcherUtilization(state, languageMatcher.uuid));
  const selectedCustomer = useSelector(getSelectedCustomerIdentifier);
  const scopedCustomers = useSelector((state) =>
    getScopedCustomersMatcher(state, languageMatcher.uuid)
  );

  const showV2 = useSelector(getShowV2Graph);

  const annotationsMatched = useSelector(getAnnotationMatchedLanguage);
  const annotationsMatchedV2 = useSelector(getAnnotationMatchedLanguageV2);

  const showUtilization = useSelector(getIdentifierShowUtilization);

  return (
    <div
      className={`relative px-4 flex flex-row border-b border-litlingo-gray-1 ${
        showUtilization && bgColorsUtilization[utilization]
      }`}
    >
      {selectedCustomer && scopedCustomers.includes(selectedCustomer) && (
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-litlingo-green-bg bg-opacity-50" />
      )}

      {languageMatcher.uuid === highlightedLanguageMatcher && (
        <div className="absolute left-0 top-0 bottom-0">
          <svg
            width="10"
            height="100%"
            viewBox="0 0 10 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="10" height="64" fill="#007656" />
          </svg>
        </div>
      )}

      <div
        key={languageMatcher.uuid}
        aria-hidden="true"
        className="relative py-2 flex flex-row justify-between cursor-pointer w-full overflow-hidden"
        onClick={(): void => handleLanguageMatcherClick(languageMatcher.uuid)}
      >
        <div className="flex flex-col gap-1">
          <span
            className={`text-body truncate ${
              (showV2 && annotationsMatchedV2.includes(languageMatcher.name)) ||
              (!showV2 && annotationsMatched.includes(languageMatcher.name))
                ? 'bg-litlingo-highlight'
                : ''
            }`}
          >
            {languageMatcher.name}
          </span>
          <span className="text-small">{languageMatcher.type}</span>
        </div>
        {showUtilization && (
          <div className="flex flex-col">
            <div className="text-body">
              <span className="font-bold">{textUtilization[utilization]}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageMatcherSidebarItem;
