/* eslint-disable max-lines */
import { UUID } from '@litlingo/client';
import classNames from 'classnames';
import React from 'react';

type ComponentProps = {
  selectedCampaigns: UUID[];
  setShowModels: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeletingCampaign: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCloneCampaignOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showModels: boolean;
};

const CampaignListActionsRow: React.FC<ComponentProps> = ({
  selectedCampaigns,
  setShowModels,
  setIsDeletingCampaign,
  showModels,
  setIsCloneCampaignOpen,
}) => {
  const disableDeleteButton = selectedCampaigns.length > 1;
  const disableCloneButton = selectedCampaigns.length > 1;
  const annotatorCount = selectedCampaigns.length;

  const handleDeleteAnnotator = (): void => {
    setIsDeletingCampaign(true);
  };

  const handleCloneCampaign = (): void => {
    setIsCloneCampaignOpen(true);
  };

  return (
    <>
      <tr className="bg-litlingo-gray-2">
        <th
          colSpan={8}
          className="table-wrapper__new-td table-wrapper__bulk-area bg-litlingo-gray-2 "
        >
          <div className="table-wrapper__new-cell-content flex flex-row justify-between items-start gap-3 py-2 m-0 h-auto overflow-visible">
            <div className="flex flex-row justify-between items-start gap-2 ml-6 h-auto overflow-visible">
              <button
                type="button"
                className={classNames('button button--secondary h-8 ', {
                  'disabled-button': showModels,
                })}
                onClick={(): void => setShowModels(true)}
              >
                <span className="text-litlingo-gray-600 font-bold">Show Models</span>
              </button>
              <button
                type="button"
                className={classNames('button button--secondary h-8 ', {
                  'disabled-button': !showModels,
                })}
                onClick={(): void => setShowModels(false)}
              >
                <span className="text-litlingo-gray-600 font-bold">Hide Models</span>
              </button>
              <button
                type="button"
                onClick={handleCloneCampaign}
                className={classNames('button button--secondary h-8 ', {
                  'disabled-button': disableCloneButton,
                })}
              >
                <span className="text-litlingo-gray-600 font-bold">Clone</span>
              </button>
            </div>

            <div className="flex flex-row justify-end h-8">
              <div className="text-body whitespace-no-wrap self-end mx-2">{`${annotatorCount.toLocaleString()} selected`}</div>
              <button
                type="button"
                className={classNames(
                  'button button--secondary flex justify-center w-32 h-8 ml-2',
                  {
                    'disabled-button': disableDeleteButton,
                  }
                )}
                data-testid="bulk-delete-button"
                onClick={handleDeleteAnnotator}
              >
                <span className="text-litlingo-red-bg font-bold">Delete</span>
              </button>
            </div>
          </div>
        </th>
      </tr>
    </>
  );
};

export const MemoizedTestCaseListActionsRow = React.memo(CampaignListActionsRow);

export default CampaignListActionsRow;
