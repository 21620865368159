import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getEnvelopes,
  getEnvelopesTotalCount,
  getFetchAllEnvelopesLoading,
} from 'selectors/envelopes';
import { getNavParamsByResource } from 'selectors/nav';

const EnvelopeListCount: React.FC = () => {
  const navParams = useSelector(getNavParamsByResource('envelopes'));
  const envelopes = useSelector(getEnvelopes);
  const totalCount = useSelector(getEnvelopesTotalCount);
  const loadingAll = useSelector(getFetchAllEnvelopesLoading);

  const [initNumber, setInitNumber] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);

  const offset = parseInt(navParams.offset as string, 10);

  useEffect(() => {
    if ((firstLoad && offset > 0) || offset === 0) {
      setInitNumber(offset + 1);
      setFirstLoad(false);
    }
  }, [offset, firstLoad]);

  return (
    <div className={`${loadingAll ? 'text-litlingo-gray-2' : ''}`}>
      <span className="font-bold">
        {`${envelopes.length === 0 ? 0 : initNumber}-${Math.min(
          initNumber + envelopes.length - 1,
          totalCount
        )}`}
      </span>
      {` of ${totalCount === 10000 ? '+10,000' : totalCount.toLocaleString()}`}
    </div>
  );
};

export default EnvelopeListCount;
