import {
  applyPermissionsToSelectedUsersAndSave,
  applyPermissionsToSelectedUsersAndSaveQuery,
  applyPermissionsToSingleSelectedUserAndSave,
} from 'actions';
import {
  applyPermissions,
  applyPermissionsSingleUser,
  clearPermissions,
  selectUsers,
} from 'actions/userPermissionsPage';
import PermissionsSelections from 'components/UsersPermissions/UserPermissionsForm/PermissionsSelections';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  getCanApply,
  getCanApplySaveSingleUser,
  getIsSelectAll,
  getSelectedUsers,
} from 'selectors/userPermissionsPage';
import { useSelector } from 'store';
import PermissionsLabels from './PermissionsLabels';

type ComponentPorps = {
  saveInApply?: boolean;
  setSendInvitation: React.Dispatch<React.SetStateAction<boolean>>;
  sendInvitation: boolean;
  canSendInvitation: boolean;
};

const UsersChangePermissions: React.FC<ComponentPorps> = ({
  saveInApply,
  setSendInvitation,
  sendInvitation,
  canSendInvitation,
}) => {
  const dispatch = useDispatch();

  const canApply = useSelector(getCanApply);
  const selectedUsers = useSelector(getSelectedUsers);
  const isSelectAll = useSelector(getIsSelectAll);
  const singleUser = selectedUsers.length === 1;
  const canApplySingleUser = useSelector((state) =>
    getCanApplySaveSingleUser(state, saveInApply ? 'true' : 'false')
  );

  const handleApply = (): void => {
    if (saveInApply) {
      if (singleUser) {
        dispatch(applyPermissionsToSingleSelectedUserAndSave());
      } else if (isSelectAll && selectedUsers.length >= 25) {
        dispatch(applyPermissionsToSelectedUsersAndSaveQuery());
        dispatch(clearPermissions());
      } else {
        dispatch(applyPermissionsToSelectedUsersAndSave());
        dispatch(clearPermissions());
      }
    } else if (singleUser) {
      dispatch(applyPermissionsSingleUser());
    } else {
      dispatch(applyPermissions());
      dispatch(clearPermissions());
    }
  };

  const handleExit = (): void => {
    dispatch(clearPermissions());
    dispatch(selectUsers([]));
  };

  const handleSendInvitation = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (canSendInvitation) {
      setSendInvitation(e.target.checked);
    } else {
      setSendInvitation(false);
    }
  };

  return (
    <div className="bg-white py-4 px-3 flex flex-col gap-4 overflow-visible">
      <div className="flex flex-col gap-2">
        {!singleUser && (
          <span className="text-body">
            Changes here apply to all users selected whether you include or exclude.
          </span>
        )}

        <span className="italic text-small">Shift + click to exclude</span>
      </div>

      <PermissionsSelections singleUser={singleUser} />

      <PermissionsLabels />

      {canSendInvitation && !saveInApply && (
        <label htmlFor="send_invitation" className="flex flex-row gap-1 items-center">
          <div className="flex flex-row gap-1">
            <span>Send invitation</span>
          </div>
          <input
            id="send_invitation"
            name="display_in_assignments"
            type="checkbox"
            className="form-checkbox litlingo-checkbox"
            onChange={handleSendInvitation}
            checked={sendInvitation}
          />
        </label>
      )}

      <div className="mt-2 h-8 flex flex-row gap-2 self-end">
        {saveInApply && (
          <button
            type="button"
            className="button button--secondary w-26 flex justify-center"
            onClick={handleExit}
          >
            <span className="font-bold">Exit</span>
          </button>
        )}
        {singleUser ? (
          <button
            type="button"
            className={`button ${
              saveInApply ? 'button--primary' : 'button--secondary border-litlingo-primary'
            } w-26 flex justify-center`}
            onClick={handleApply}
            disabled={!canApplySingleUser}
          >
            <span
              className={`${
                saveInApply || !canApplySingleUser ? '' : 'text-litlingo-primary'
              } font-bold`}
            >
              {saveInApply ? 'Save' : 'Apply'}
            </span>
          </button>
        ) : (
          <button
            type="button"
            className={`button ${
              saveInApply ? 'button--primary' : 'button--secondary border-litlingo-primary'
            } w-26 flex justify-center`}
            onClick={handleApply}
            disabled={!canApply}
          >
            <span
              className={`${saveInApply || !canApply ? '' : 'text-litlingo-primary'} font-bold `}
            >
              {saveInApply ? 'Save' : 'Apply'}
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default UsersChangePermissions;
