/* eslint-disable max-lines */
import {
  ENVELOPE_CONTRACT_ALTER_ICON,
  ENVELOPE_CONTRACT_ICON,
  ENVELOPE_EXPAND_ALTER_ICON,
  ENVELOPE_EXPAND_ICON,
} from 'constants/envelopeIcons';
import React, { useState } from 'react';
import logEvent from 'utils/analytics';

type ComponentProps = {
  selectedFormat: 'expanded' | 'contracted';
  setSelectedFormat: React.Dispatch<React.SetStateAction<'expanded' | 'contracted'>>;
};

const ExpandContractButtons: React.FC<ComponentProps> = ({ selectedFormat, setSelectedFormat }) => {
  const [hover, setHover] = useState<'expanded' | 'contracted' | null>(null);

  const handleMouseEnter = (value: 'expanded' | 'contracted'): void => {
    if (selectedFormat !== value) setHover(value);
  };

  return (
    <div className="flex flex-row gap-1">
      <button
        type="button"
        className="focus:outline-none"
        onMouseEnter={(): void => handleMouseEnter('contracted')}
        onMouseLeave={(): void => setHover(null)}
        onClick={(): void => {
          logEvent('envelopes-list-contract');
          setSelectedFormat('contracted');
        }}
      >
        {hover === 'contracted' && selectedFormat !== 'contracted'
          ? ENVELOPE_CONTRACT_ALTER_ICON
          : ENVELOPE_CONTRACT_ICON(selectedFormat === 'contracted')}
      </button>
      <button
        type="button"
        className="focus:outline-none"
        onMouseEnter={(): void => handleMouseEnter('expanded')}
        onMouseLeave={(): void => setHover(null)}
        onClick={(): void => {
          logEvent('envelopes-list-expand');
          setSelectedFormat('expanded');
        }}
      >
        {hover === 'expanded' && selectedFormat !== 'expanded'
          ? ENVELOPE_EXPAND_ALTER_ICON
          : ENVELOPE_EXPAND_ICON(selectedFormat === 'expanded')}
      </button>
    </div>
  );
};

export default ExpandContractButtons;
