import Navbar from 'components/Navbar';
import React from 'react';
import ReviewSetsHeader from './ReviewSetsHeader';
import ReviewSetsList from './ReviewSetsList';

const ReviewSets: React.FC = () => {
  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <div
      className="flex flex-col min-w-screen bg-white overflow-hidden"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />
      <div className="h-full w-full flex flex-col overflow-hidden">
        <header className="w-full flex z-50">
          <ReviewSetsHeader />
        </header>
        <main className="h-full w-full flex overflow-hidden">
          <ReviewSetsList />
        </main>
      </div>
    </div>
  );
};

export default ReviewSets;
