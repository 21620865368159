import {
  createRelationship,
  deleteRelationship,
  deleteRelationshipFromConfig,
  patchRuleConfigWithRelationship,
  removeRelationship,
  upsertRelationship,
} from 'actions/relationship';
import { put, takeLatest } from 'redux-saga/effects';
import { SagaReturn } from 'types';

function* createRelationshipSaga({ payload }: ReturnType<typeof createRelationship>): SagaReturn {
  yield put(upsertRelationship(payload));
  yield put(patchRuleConfigWithRelationship(payload));
}

function* removeRelationshipSaga({ payload }: ReturnType<typeof removeRelationship>): SagaReturn {
  yield put(deleteRelationship(payload));
  yield put(deleteRelationshipFromConfig(payload));
}

function* relationshipsSaga(): SagaReturn {
  yield takeLatest(createRelationship.toString(), createRelationshipSaga);
  yield takeLatest(removeRelationship.toString(), removeRelationshipSaga);
}

export default relationshipsSaga;
