/* eslint-disable camelcase */
import { createSample } from 'actions';
import EnvelopeListFilterPills from 'components/EnvelopeList/FilterPills';
import Modal from 'components/Modal';
import moment from 'moment';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
  envelopesTotalCount: number;
};

const CreateSampleModal: React.FC<ComponentProps> = ({
  isModalOpen,
  toggleModalOpen,
  envelopesTotalCount,
}) => {
  const dispatch = useDispatch();

  const defaultName = moment(new Date()).format('MMMDD_YYYY_hh:mm:ss');
  const defaultSize = envelopesTotalCount > 50 ? 50 : envelopesTotalCount;
  const defaultPercentage = Number(((defaultSize / envelopesTotalCount) * 100).toFixed(1));

  const { register, getValues, handleSubmit, setValue, errors, reset } = useForm<{
    name: string;
    sampleSize: number;
    samplePercentage: number;
  }>({
    defaultValues: {
      name: defaultName,
      sampleSize: defaultSize,
      samplePercentage: defaultPercentage,
    },
  });

  const onSubmit = (): void => {
    const { name, sampleSize } = getValues();

    dispatch(
      createSample({
        name,
        sample_size: Number(sampleSize),
        description: '',
      })
    );
    reset();
    toggleModalOpen();
  };

  const handleCreateButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const renderSaveFilters = (
    <>
      <div className="flex flex-col gap-4 mb-20 mt-4">
        <div className="flex flex-col gap-1">
          <EnvelopeListFilterPills placeholder="No filters applied" />
          <div className="text-body">
            <span className="font-bold">
              {envelopesTotalCount === 10000 ? '+10,000' : envelopesTotalCount.toLocaleString()}{' '}
              items
            </span>
          </div>
        </div>

        <form className="flex flex-col items-start">
          <div className="flex flex-col items-start gap-4 w-full">
            <label htmlFor="name" className="flex flex-col gap-1 text-body">
              <div className="flex flex-row gap-1 text-base">
                <span>Name</span>
              </div>

              <input
                id="name"
                ref={register({ required: true, pattern: /\S/i })}
                name="name"
                placeholder="New sample"
                className="form-input h-9 w-64 p-2 text-body rounded-lg placeholder-italic placeholder-litlingo-gray-6"
              />
            </label>

            <label htmlFor="size" className="flex flex-row items-center gap-2 text-body">
              <div className="flex flex-row gap-1">
                <span>Sample size</span>
                <span className="litlingo-red-color">*</span>
              </div>
              <input
                id="sampleSize"
                type="number"
                ref={register({ required: true, min: 1, max: envelopesTotalCount })}
                name="sampleSize"
                onChange={(e): void => {
                  const { value } = e.target;
                  setValue(
                    'samplePercentage',
                    ((Number(value) / envelopesTotalCount) * 100).toFixed(1) as unknown as number
                  );
                }}
                className="form-input p-1.5 w-16 text-center text-body transition duration-150 ease-in-out"
              />
              <div className="flex flex-row gap-1">
                <span>communication</span>
                <span className="font-bold">OR</span>
              </div>
              <input
                id="samplePercentage"
                type="number"
                ref={register({
                  required: true,
                  min: (100 / envelopesTotalCount).toFixed(1),
                  max: 100,
                })}
                name="samplePercentage"
                onChange={(e): void => {
                  const { value } = e.target;
                  setValue(
                    'sampleSize',
                    ((Number(value) / 100) * envelopesTotalCount).toFixed() as unknown as number
                  );
                }}
                className="form-input p-1.5 w-16 text-center text-body transition duration-150 ease-in-out "
              />
              <div className="flex flex-row gap-1">
                <span className="text-lg">%</span>
                <span>of current count </span>
              </div>
            </label>
            {(errors.sampleSize || errors.samplePercentage) && (
              <div className="text-small">
                <span className=" text-litlingo-alert">
                  Sample size must be a positive number between 1 and current count
                </span>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderSaveFilters}
          title="Quick Sample"
          okButton
          okButtonText="Sample"
          okButtonOnClick={handleCreateButton}
          toggleShowModal={toggleModalOpen}
          style={{
            width: '800px',
          }}
        />
      )}
    </>
  );
};

export default CreateSampleModal;
