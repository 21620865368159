import { deleteURLParams, setURLParams } from 'actions';
import MultiselectDropdownRedesign, {
  MultiSelectOption,
} from 'components/MultiSelectDropdown/MultiSelectDropdownRedesign';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';

type ComponentProps = {
  className?: string;
  resource: string;
};

const UserInactiveFilter: React.FC<ComponentProps> = (props) => {
  const { className, resource } = props;

  const dispatch = useDispatch();
  const filters = useSelector(getNavParamsByResource(resource));

  const options = [
    { value: 'twoWeeks', label: '2+ Weeks' },
    { value: 'azurePermissions', label: 'Azure permissions' },
  ];

  const handleChange = (
    option: MultiSelectOption,
    exclude = false,
    e = {} as React.MouseEvent<HTMLInputElement, MouseEvent>
  ): void => {
    logEvent(`use-cases-enabled-filter-${option}`);

    const { ctrlKey, shiftKey } = e;

    if (option.value === 'twoWeeks') {
      if (Object.keys(filters).includes('recent_email_communication')) {
        dispatch(dispatch(deleteURLParams([`${resource}__recent_email_communication`])));
        dispatch(dispatch(deleteURLParams([`${resource}__recent_chat_communication`])));
        dispatch(dispatch(deleteURLParams([`${resource}__recent_email_app_communication`])));
        return;
      }
      dispatch(
        setURLParams({
          [`${resource}__recent_email_communication`]: '2',
          [`${resource}__recent_chat_communication`]: '2',
          [`${resource}__recent_email_app_communication`]: '2',
        })
      );
      return;
    }
    if (option.value === 'azurePermissions') {
      if (e.currentTarget.checked) {
        if (ctrlKey || shiftKey || exclude) {
          dispatch(
            setURLParams({
              [`${resource}__ms_graph_active`]: 'true',
            })
          );
        } else {
          dispatch(
            setURLParams({
              [`${resource}__ms_graph_inactive`]: 'true',
            })
          );
        }
      } else if (ctrlKey || shiftKey || exclude) {
        dispatch(deleteURLParams([`${resource}__ms_graph_active`]));
      } else {
        dispatch(deleteURLParams([`${resource}__ms_graph_inactive`]));
      }
    }
  };

  const selectedItems = [];

  if (Object.keys(filters).includes('ms_graph_inactive')) {
    selectedItems.push({ value: 'azurePermissions', label: 'Azure permissions' });
  }
  if (Object.keys(filters).includes('recent_email_communication')) {
    selectedItems.push({ value: 'twoWeeks', label: '2+ Weeks' });
  }

  const excludedSelectedItems = [];

  if (Object.keys(filters).includes('ms_graph_active')) {
    excludedSelectedItems.push({ value: 'azurePermissions', label: 'Azure permissions' });
  }

  return (
    <div className="w-35">
      <MultiselectDropdownRedesign
        className={className || ''}
        placeholder="Inactivity"
        onChange={handleChange}
        options={options}
        selectedItems={selectedItems}
        dataTestid="active"
        excludedSelectedItems={excludedSelectedItems}
      />
    </div>
  );
};

export default UserInactiveFilter;
