/* eslint-disable @typescript-eslint/no-unused-vars */
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetError from 'decorators/widget/WidgetError';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import React from 'react';
import { DefaultWidgetComponentProps } from 'types';
import { buildSimpleDataFromMetrics } from 'utils/dashboard';

const ReviewSetMetricsWidget: React.FC<DefaultWidgetComponentProps> = (props) => {
  const { widgetData, queryData } = props;

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  const processedData = buildSimpleDataFromMetrics(widgetData, queryData);
  if ('error' in processedData && processedData.error != null) {
    return <WidgetError msg={processedData.error} />;
  }

  if ('data' in processedData && processedData.data.length === 0) {
    return <WidgetEmptyState />;
  }

  return (
    <div
      className="p-0 border border-litlingo-gray-2 rounded grid items-center w-full h-full"
      style={{
        gridTemplateColumns: `repeat(${
          'data' in processedData ? processedData.data.length : 4
        }, minmax(0, ${'data' in processedData ? 100 / processedData.data.length : 100 / 4}%))`,
      }}
    >
      {'data' in processedData &&
        processedData.data.map(
          (data, idx) =>
            queryData.queries[idx]?.id && (
              <div className="flex flex-row  h-full py-4 text-center">
                <div
                  className={`flex flex-col w-full justify-center items-center px-2 ${
                    idx !== processedData.data.length - 1 ? 'border-r border-litlingo-gray-2' : ''
                  }`}
                >
                  <span className="text-heading-2">{data.count}</span>
                  <span className="text-small">{data.label}</span>
                </div>
              </div>
            )
        )}
    </div>
  );
};

export default withWidget({
  fetchOnePeriod: true,
})(ReviewSetMetricsWidget);
