import { Team } from '@litlingo/client';
import MultiselectDropdownRedesign, {
  MultiSelectOption,
} from 'components/MultiSelectDropdown/MultiSelectDropdownRedesign';
import Labels from 'components/UsersPermissions/UserPermissionsForm/Labels';
import useAllTeams from 'hooks/teams/useAllTeams';
import React from 'react';
import { useDispatch } from 'react-redux';
import 'styles/labelsStyle.css';

type TeamSelectRedesignProps = {
  className?: string;
  allowExclude?: boolean;

  includedTeams: Team[];
  excludedTeams?: Team[];
  addIncludedTeams: (teams: Team[]) => void;
  removeIncludedTeams: (teams: Team[]) => void;
  addExcludedTeams?: (teams: Team[]) => void;
  removeExcludedTeams?: (teams: Team[]) => void;
  showLabels?: boolean;
};

const TeamSelectRedesign: React.FC<TeamSelectRedesignProps> = (props) => {
  const {
    className,
    allowExclude = false,
    includedTeams,
    addIncludedTeams,
    removeIncludedTeams,
    excludedTeams,
    addExcludedTeams,
    removeExcludedTeams,
    showLabels = true,
  } = props;

  const dispatch = useDispatch();

  const { data } = useAllTeams();

  const options = data?.records || [];

  const optionsArray = options.map((team) => ({
    label: team.name,
    value: team.uuid,
  }));

  const handleChange = (option: MultiSelectOption, exclude = false): void => {
    const selected =
      excludedTeams?.find((t) => t.uuid === option.value) ||
      includedTeams.find((t) => t.uuid === option.value);

    if (exclude && allowExclude) {
      if (selected) {
        if (removeExcludedTeams) {
          removeExcludedTeams([selected]);
        }
      } else {
        const team = data?.records.find((t) => t.uuid === option.value);

        if (team) {
          if (addExcludedTeams) {
            addExcludedTeams([team]);
          }
        }
      }
    } else if (selected) {
      dispatch(removeIncludedTeams([selected]));
    } else {
      const team = data?.records.find((t) => t.uuid === option.value);
      if (team) {
        addIncludedTeams([team]);
      }
    }
  };

  const handleDelete = (option: MultiSelectOption): void => {
    const inExclude = excludedTeams?.find((t) => t.uuid === option.value);
    const inInclude = includedTeams.find((t) => t.uuid === option.value);

    const team = data?.records.find((t) => t.uuid === option.value);

    if (team) {
      if (inInclude) {
        removeIncludedTeams([team]);
      }

      if (inExclude) {
        if (removeExcludedTeams) {
          removeExcludedTeams([team]);
        }
      }
    }
  };

  return (
    <div className="flex flex-row gap-2">
      <div className="min-w-42 w-42">
        <MultiselectDropdownRedesign
          className={className || ''}
          placeholder="Division"
          onChange={handleChange}
          options={optionsArray}
          selectedItems={includedTeams.map((t) => ({ label: t.name, value: t.uuid }))}
          excludedSelectedItems={excludedTeams?.map((t) => ({ label: t.name, value: t.uuid }))}
          dataTestid="active"
        />
      </div>
      {showLabels && (
        <Labels
          includedLabels={includedTeams.map((t) => ({ value: t.uuid, label: t.name }))}
          excludedLabels={excludedTeams?.map((t) => ({ value: t.uuid, label: t.name })) || []}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default TeamSelectRedesign;
