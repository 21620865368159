import { selectSection } from 'actions';
import { collapseLeftPanel } from 'actions/envelopeView';
import { NEW_CHEVRON_LEFT } from 'constants/filterIcons';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getEnvelope, getFetchSingleEnvelopeLoading } from 'selectors/envelopes';
import { getCollapsedLeftPanelState } from 'selectors/envelopeView';
import { useSelector } from 'store';
import SidebarSkeleton from '../SidebarSkeleton';
import EnvelopeAttachments from './EnvelopeAttachments';
import EnvelopeContextInfo from './EnvelopeContextInfo';
import EnvelopeEventInfo from './EnvelopeEventInfo';
import EnvelopeMessageHistory from './EnvelopeMessageHistory';
import EnvelopeThreads from './EnvelopeThreads';

const EnvelopeLeftSidebar: React.FC = () => {
  const dispatch = useDispatch();
  const { envelopeId } = useParams<{ envelopeId: string }>();
  const collapsed = useSelector(getCollapsedLeftPanelState);

  const envelopeLoading = useSelector(getFetchSingleEnvelopeLoading);
  const envelope = useSelector((state) => getEnvelope(state, envelopeId));

  useEffect(() => {
    dispatch(selectSection('message'));
  }, [dispatch, envelopeId]);

  return (
    <>
      <button
        type="button"
        data-testid="collapse-button"
        className={`absolute button-left-sidebar flex items-center justify-center min-w-4 min-h-8 focus:outline-none transition-all duration-300 bg-transparent z-50 ${
          collapsed ? 'border-b border-r border-t border-litlingo-gray-2' : ''
        }`}
        onClick={(): void => {
          dispatch(collapseLeftPanel(!collapsed));
        }}
        style={{
          left: collapsed ? 0 : 272,
        }}
      >
        <span
          className={`transition-transform duration-300 ${collapsed ? 'transform rotate-180' : ''}`}
        >
          {NEW_CHEVRON_LEFT}
        </span>
      </button>
      <div
        data-testid="sidebar-envelope-container"
        className="relative envelope-left-sidebar flex flex-col overflow-y-scroll no-scrollbar transition-all duration-300"
        style={{
          minWidth: 300,
          maxWidth: 300,
          marginLeft: collapsed ? '-300px' : '',
        }}
      >
        <div
          className="absolute top-0 w-full min-h-full h-auto bg-litlingo-gray-0 transition-all duration-300"
          style={{
            minWidth: 300,
            maxWidth: 300,
          }}
        >
          {envelope && !envelopeLoading ? (
            <div className="relative h-full flex flex-col bg-litlingo-gray-1 overflow-auto no-scrollbar">
              <EnvelopeEventInfo />
              <EnvelopeMessageHistory />
              <EnvelopeAttachments />
              <EnvelopeThreads />
              <EnvelopeContextInfo />
            </div>
          ) : (
            <SidebarSkeleton />
          )}
        </div>
      </div>
    </>
  );
};

export default EnvelopeLeftSidebar;
