/*  eslint-disable react/jsx-props-no-spreading */
import { FallbackRender } from '@sentry/react/dist/errorboundary';
import config from 'config';
import React from 'react';
import SentryDevError from './SentryDevError';

import SentryError from './SentryError';

const SentryFallbackError: FallbackRender = (props) => {
  if (config.node.env === 'production') return <SentryError {...props} />;

  return <SentryDevError {...props} />;
};

export default SentryFallbackError;
