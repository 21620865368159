import { fetchAllCampaignRuleOutcomes, fetchAllRulesDebounced, setURLParams } from 'actions';
import useClickOutside from 'components/utils/useClickOutside';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCampaignRuleOutcomesList } from 'selectors/campaignRuleOutcomes';
import { getNavParamsByResource } from 'selectors/nav';
import { useSelector } from 'store';

type ComponentProps = {
  className?: string;
  resource: string;
  withExclude?: boolean;
};

const RulesFilter: React.FC<ComponentProps> = (props) => {
  const { className, resource, withExclude } = props;

  const dispatch = useDispatch();
  const filters = useSelector(getNavParamsByResource(resource)).rule_uuids || [];
  const notFilters = useSelector(getNavParamsByResource(resource)).not_rule_uuids || [];
  const outcomes = useSelector(getCampaignRuleOutcomesList);
  const rules = useSelector((state) => state.rule.rules);

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filterType, setFilterType] = useState('include');

  // click outside functionality
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleButtonClick = (): void => setIsOpen(!isOpen);
  const include = filterType === 'include';

  useEffect(() => {
    dispatch(fetchAllCampaignRuleOutcomes({ limit: -1 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAllRulesDebounced({ name: searchValue }));
  }, [dispatch, searchValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { id: state } = event.target;

    let ruleIds = include ? [...filters] : [...notFilters];
    if (event.target.checked) {
      ruleIds.push(state);
    } else {
      ruleIds = ruleIds.filter((s) => s !== state);
    }

    dispatch(
      setURLParams({
        [`${resource}__${include ? 'rule_uuids' : 'not_rule_uuids'}`]: ruleIds,
        [`${resource}__offset`]: '0',
      })
    );
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterType(e.target.value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  };

  return (
    <div ref={wrapperRef} className={`relative inline-block text-left ${className || ''}`}>
      <div className="rounded-md shadow-sm">
        <button
          type="button"
          className={`flex flex-row justify-between items-center rounded border bg-white text text-body text--lighter-4 hover:text-gray-500 focus:outline-none focus:border-litlingo-success focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 p-2 h-8 w-30 italic
            ${
              filters.length !== 0 || notFilters.length !== 0
                ? 'border-gray-700'
                : 'border-gray-300'
            }`}
          id="options-menu"
          data-testid="rule-filter-button"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={handleButtonClick}
        >
          <span>Models</span>
          <svg className="-mr-1 ml-2 h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-left absolute left-0 mt-2 w-64 rounded-md shadow-lg z-20">
          <div className="rounded-md bg-white shadow-xs">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="m-2 relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg className="h-4 w-4 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M12.9 14.32a8 8 0 111.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 108 2a6 6 0 000 12z" />
                  </svg>
                </div>
                <input
                  id="search"
                  name="search"
                  className="form-input block w-full pl-10 sm:text-sm sm:leading-5 shadow-sm"
                  onChange={handleSearchChange}
                  autoComplete="off"
                />
              </div>
              {withExclude && (
                <>
                  <div className="flex justify-between items-center m-2">
                    <label className="flex justify-start items-center w-1/2" htmlFor="include">
                      <input
                        type="radio"
                        id="include"
                        name="filter_type"
                        value="include"
                        className="form-radio text-litlingo-success"
                        onChange={handleCheckboxChange}
                        checked={include}
                      />
                      <span className="text ml-2">Include</span>
                    </label>
                    <label htmlFor="exclude" className="flex justify-start items-center w-1/2">
                      <input
                        type="radio"
                        id="exclude"
                        name="filter_type"
                        value="exclude"
                        className="form-radio text-litlingo-success"
                        onChange={handleCheckboxChange}
                        checked={!include}
                      />
                      <span className="text ml-2">Exclude</span>
                    </label>
                  </div>
                  <hr />
                </>
              )}
              <div className="m-2 max-h-filter overflow-auto">
                <fieldset>
                  {Object.keys(rules)
                    .sort((a, b) => {
                      if (filters.includes(a)) return -1;
                      if (filters.includes(b)) return 1;
                      return rules[a].name.toLowerCase() < rules[b].name.toLowerCase() ? -1 : 1;
                    })
                    .map((key, idx) => {
                      const ruleOutcome = outcomes.find(
                        (outcome) => outcome.rule_uuid === rules[key].uuid
                      );
                      return (
                        ruleOutcome && (
                          <div key={key} className={idx !== 0 ? 'mt-4' : ''}>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5 mr-2">
                                <input
                                  id={key}
                                  type="checkbox"
                                  className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out"
                                  onChange={handleInputChange}
                                  checked={
                                    include ? filters.includes(key) : notFilters.includes(key)
                                  }
                                />
                              </div>
                              <div className="flex flex-col text-sm leading-5">
                                <label htmlFor={key} className="text-gray-700">
                                  {rules[key].name}
                                </label>
                                {ruleOutcome && (
                                  <span className="text-xs text-gray-500 select-none break-all">
                                    {ruleOutcome.campaign?.name}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      );
                    })}
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RulesFilter;
