import { createAction } from '@reduxjs/toolkit';
import type {
  AnnotatorRelationship,
  ErrorObject,
  MRule,
  MRuleConfigNode,
  NormalizedResource,
  RuleChange,
  UUID,
} from 'types';

export type FetchRulesForComparePayload = {
  customerOneId: UUID;
  customerTwoId: UUID;
  ruleOneId: UUID;
  ruleTwoId: UUID;
};

export const fetchRulesForCompare =
  createAction<FetchRulesForComparePayload>('RULES_COMPARE/FETCH');
export const fetchRulesForCompareRequest = createAction('RULES_COMPARE/FETCH_REQUEST');
export const fetchRulesForCompareFailure = createAction<ErrorObject>('RULES_COMPARE/FETCH_FAILURE');
export const fetchRulesForCompareSuccess = createAction<{
  ruleOne: MRule;
  ruleTwo: MRule;
}>('RULES_COMPARE/FETCH_SUCCESS');

export type FetchRulesDiffForComparePayload = {
  ruleOneId: UUID;
  ruleTwoId: UUID;
  customerOneId: UUID;
  customerTwoId: UUID;
};

export const fetchRulesDiffForCompare = createAction<FetchRulesDiffForComparePayload>(
  'RULES_COMPARE/FETCH_DIFF'
);
export const fetchRulesDiffForCompareRequest = createAction('RULES_COMPARE/FETCH_DIFF_REQUEST');
export const fetchRulesDiffForCompareFailure = createAction<ErrorObject>(
  'RULES_COMPARE/FETCH_DIFF_FAILURE'
);
export const fetchRulesDiffForCompareSuccess = createAction<RuleChange[]>(
  'RULES_COMPARE/FETCH_DIFF_SUCCESS'
);

export const fetchRulesForCompareConfigsSuccess = createAction<{
  ruleOne: NormalizedResource<MRuleConfigNode>;
  ruleTwo: NormalizedResource<MRuleConfigNode>;
  relationshipsOne: NormalizedResource<Omit<AnnotatorRelationship, 'configId'>>;
  relationshipsTwo: NormalizedResource<Omit<AnnotatorRelationship, 'configId'>>;
}>('RULES_COMPARE/FETCH_CONFIG_SUCCESS');

export const flipReferenceRulesCompare = createAction('RULES_COMPARE/FLIP_REFERENCE');
