import { fetchAllCampaignRuleOutcomes, fetchSingleCampaign, fetchSingleRule } from 'actions';
import Navbar from 'components/Navbar';
import OutcomeTable from 'components/OutcomeTable';
import PageHeader from 'components/PageHeader';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getOutcomeFromCampaignRuleOutcome } from 'selectors/outcomes';
import { useSelector } from 'store';
import type { UUID } from 'types';
import OutcomeManagerSidebar from './OutcomeManagerSidebar';

type RouteParams = {
  campaignId: UUID;
  ruleId: UUID;
};

const OutcomeManager: React.FC = () => {
  const dispatch = useDispatch();
  const { ruleId, campaignId } = useParams<RouteParams>();

  const outcomes = useSelector(getOutcomeFromCampaignRuleOutcome);
  const activeOutcomes = useSelector((state) => state.outcome.activeOutcomes);

  useEffect(() => {
    dispatch(fetchSingleCampaign({ campaignId }));
    dispatch(fetchSingleRule({ ruleId }));
  }, [dispatch, campaignId, ruleId]);

  useEffect(() => {
    dispatch(fetchAllCampaignRuleOutcomes({ rule_uuid: ruleId, campaign_uuid: campaignId }));
  }, [dispatch, ruleId, activeOutcomes, campaignId]);

  if (!outcomes) return null;

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      <div className="flex flex-row">
        <div className="pt-10 w-9/12">
          <header>
            <div className="max-w-7xl mx-auto px-1 sm:px-12 lg:px-14">
              <div className="flex flex-col">
                <PageHeader />
                <div className="mt-2 md:flex md:items-center md:justify-between">
                  <div className="flex-1 min-w-0">
                    <h2 className="title">Outcome Manager</h2>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <main>
            <div className="mt-4 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <OutcomeTable ruleId={ruleId} />
            </div>
          </main>
        </div>
        <div className="w-3/12 min-h-wo-navbar h-wo-navbar overflow-y-scroll">
          <OutcomeManagerSidebar />
        </div>
      </div>
    </div>
  );
};

export default OutcomeManager;
