import { deleteInvitation, resendInvitation, showSuccessAlert } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import CreateInvitation from 'components/InvitationsList/CreateInvitation';
import ListFooter from 'components/ListFooter';
import Navbar from 'components/Navbar';
import SearchInput from 'components/SearchInput';
import { COPY_CLIPBOARD, RELOAD_ICON, TRASH_ICON } from 'constants/commonIcons';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getInvitations,
  getInvitationsList,
  getInvitationsLoading,
  getInvitationsTotalCount,
} from 'selectors/invitations';
import { useSelector } from 'store';
import type { Invitation, UUID } from 'types';

const InvitationsList: React.FC = () => {
  const dispatch = useDispatch();
  const invitations = useSelector(getInvitations);
  const [isCreateNewInvitationOpen, setIsCreateNewInvitationOpen] = useState(false);
  const [editInvitation, setEditInvitation] = useState<Invitation | null>(null);
  const [isDeletingInvitation, setIsDeletingInvitation] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<UUID | null>(null);

  const toggleConfirmModal = (): void => setIsDeletingInvitation(false);

  const handleInvitationClick = (invitation: Invitation): void => {
    setEditInvitation(invitation);
    setIsCreateNewInvitationOpen(true);
  };

  const handleAddNewInvitation = (): void => {
    setEditInvitation(null);
    setIsCreateNewInvitationOpen(true);
  };

  const handleDelete = (): void => {
    if (idToBeDeleted != null) {
      dispatch(deleteInvitation({ uuid: idToBeDeleted }));
      toggleConfirmModal();
    }
  };

  const handleResendClick = (
    event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>,
    uuid: UUID
  ): void => {
    event.stopPropagation();
    dispatch(resendInvitation({ uuid }));
  };

  const handleCopyClick = async (
    event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>,
    registrationToken: string
  ): Promise<void> => {
    event.stopPropagation();
    await navigator.clipboard.writeText(
      `https://accounts.litlingo.com/oauth2/v1/register?registration_token=${registrationToken}`
    );
    dispatch(showSuccessAlert('Invitation url copied to your clipboard'));
  };

  const handleClickOnDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ): void => {
    event.stopPropagation();
    setIsDeletingInvitation(true);
    setIdToBeDeleted(id);
  };

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />
      <div className="py-10">
        <header>
          <div className="max-w-7xl mx-auto px-1 sm:px-12 lg:px-14">
            <div>
              <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                  <h2 className="title">Invitations</h2>
                </div>
                <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
                  <SearchInput resource={resourceQueryParamName.invitation} field="broad_search" />
                  {!isCreateNewInvitationOpen ? (
                    <span className="ml-3 shadow-sm rounded-md">
                      <button
                        onClick={handleAddNewInvitation}
                        type="button"
                        className="button button--primary focus:outline-none"
                        key="add"
                      >
                        Add New
                      </button>
                    </span>
                  ) : (
                    <span className="ml-3 shadow-sm rounded-md">
                      <button
                        onClick={(): void => setIsCreateNewInvitationOpen(false)}
                        type="button"
                        key="cancel"
                        className="inline-flex items-center px-4 py-2 border border-red-300 text rounded-md text-red-500 hover:bg-red-500 hover:text-white focus:outline-none focus:shadow-outline-indigo transition duration-150 ease-in-out"
                      >
                        Cancel
                      </button>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>
        <main>
          <div className="mt-10 mb-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            {isCreateNewInvitationOpen && (
              <div className="mb-10">
                <CreateInvitation
                  editInvitation={editInvitation as Invitation}
                  setIsCreateNewInvitationOpen={setIsCreateNewInvitationOpen}
                />
              </div>
            )}
            <div className="table-wrapper">
              <table className="table-wrapper__table">
                <thead>
                  <tr>
                    <th className="table-wrapper__th">Identity</th>
                    <th className="table-wrapper__th">Registration token</th>
                    <th className="table-wrapper__th">Expires in</th>
                    <th className="table-wrapper__th">Used on</th>
                    <th className="table-wrapper__th">Provider hints</th>
                    <th className="table-wrapper__th">Client</th>
                    <th className="table-wrapper__th">Next page</th>
                    <th aria-label="resend" className="table-wrapper__th table-wrapper__th--w-4" />
                    <th aria-label="copy" className="table-wrapper__th table-wrapper__th--w-4" />
                    <th aria-label="delete" className="table-wrapper__th table-wrapper__th--w-4" />
                  </tr>
                </thead>
                <tbody className="table-wrapper__tbody" data-testid="invitations-table">
                  {invitations &&
                    Object.keys(invitations).map((key) => {
                      const invitation = invitations[key];
                      return (
                        <tr
                          key={invitation.uuid}
                          onClick={(): void => handleInvitationClick(invitation)}
                          className="border-t border-gray-200 hover:bg-gray-100 table-row"
                        >
                          <td className="table-wrapper__td table-wrapper__td--align-left">
                            <div className="flex items-center">
                              <div className="flex items-center truncate">
                                {invitation.oauth2_identity != null ? (
                                  <div className="text truncate">
                                    {invitation.oauth2_identity.email}
                                  </div>
                                ) : (
                                  <div className="text truncate">
                                    {invitation.oauth2_identity_uuid}
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="table-wrapper__td table-wrapper__td--align-left">
                            <div className="flex items-center truncate">
                              <div className="text truncate">{invitation.registration_token}</div>
                            </div>
                          </td>
                          <td className="table-wrapper__td table-wrapper__td--align-left">
                            <div className="flex items-center">
                              <div>
                                <div className="text">{invitation.expires_in}</div>
                              </div>
                            </div>
                          </td>
                          <td className="table-wrapper__td table-wrapper__td--align-left">
                            <div className="flex items-center">
                              <div>
                                {invitation.used_on != null ? (
                                  <div className="text">
                                    {moment(new Date(invitation.used_on)).format('MMM DD hh:mm A')}
                                  </div>
                                ) : (
                                  <div className="text">Not used yet</div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="table-wrapper__td table-wrapper__td--align-left">
                            <div className="flex items-center">
                              <div>
                                <div className="text">{invitation.provider_hints}</div>
                              </div>
                            </div>
                          </td>
                          <td className="table-wrapper__td table-wrapper__td--align-left table-wrapper__td--truncate">
                            <div className="flex items-center truncate">
                              <div className="text truncate">{invitation.oauth2_client_uuid}</div>
                            </div>
                          </td>
                          <td className="table-wrapper__td table-wrapper__td--align-left">
                            <div className="flex items-center truncate">
                              <div className="text truncate">{invitation.next_page}</div>
                            </div>
                          </td>
                          <td className="table-wrapper__td">
                            <div className="flex items-center">
                              <button
                                className="focus:outline-none text-black"
                                type="button"
                                onClick={(event): void => {
                                  handleResendClick(event, invitation.uuid);
                                }}
                              >
                                {RELOAD_ICON}
                              </button>
                            </div>
                          </td>
                          <td className="table-wrapper__td">
                            <div className="flex items-center">
                              <button
                                className="focus:outline-none text-black"
                                type="button"
                                onClick={(event): void => {
                                  handleCopyClick(event, invitation.registration_token);
                                }}
                              >
                                {COPY_CLIPBOARD}
                              </button>
                            </div>
                          </td>
                          <td className="table-wrapper__td">
                            <div className="flex items-center">
                              <button
                                type="button"
                                className="lg:mr-4 w-5 h-5"
                                onClick={(event): void => {
                                  handleClickOnDelete(event, key);
                                }}
                              >
                                {TRASH_ICON}
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <ListFooter
              resourceStateName={resourceQueryParamName.invitation}
              resourceName="invitation"
              getResourceList={getInvitationsList}
              getResourceTotalCount={getInvitationsTotalCount}
              getResourceLoading={getInvitationsLoading}
            />
          </div>
        </main>
      </div>
      {isDeletingInvitation && idToBeDeleted && (
        <ConfirmModal
          text="Are you sure you want to delete this invitation?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDelete}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </div>
  );
};

export default InvitationsList;
