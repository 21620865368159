import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ErrorObject } from 'types';

export type FileUploadsState = {
  error: ErrorObject | null;
  progress: number | null;
  uploading: boolean;
  success: boolean;
  actualProvider: string | null;
};

type FileUploadsReducer<P = void> = (state: FileUploadsState, action: PayloadAction<P>) => void;

const initialState: FileUploadsState = {
  error: null,
  progress: null,
  uploading: false,
  success: false,
  actualProvider: null,
};

type UploadFilePayload = {
  file: File;
  provider: 'o365' | 'zendesk';
};
const handleUploadFileS3: FileUploadsReducer<UploadFilePayload> = (state, action) => {
  state.progress = 0;
  state.uploading = true;
  state.success = false;
  state.actualProvider = action.payload.provider;
};

type UploadFileProgressPayload = {
  progress: number;
};
const handleUploadFileS3Progress: FileUploadsReducer<UploadFileProgressPayload> = (
  state,
  action
) => {
  state.progress = action.payload.progress;
};

const handleUploadFileS3Success: FileUploadsReducer = (state) => {
  state.success = true;
};

type UloadFileS3FailurePayload = {
  error: ErrorObject;
};

const handleUploadFileS3Failure: FileUploadsReducer<UloadFileS3FailurePayload> = (
  state,
  action
) => {
  const { error } = action.payload;

  state.error = error;
};

const handleUploadFileS3Fulfill: FileUploadsReducer = (state) => {
  state.progress = null;
  state.uploading = false;
};

const fileUploadsSlice = createSlice({
  name: 'fileUploads',
  initialState,
  reducers: {
    uploadFileS3: handleUploadFileS3,
    uploadFileS3Progress: handleUploadFileS3Progress,
    uploadFileS3Success: handleUploadFileS3Success,
    uploadFileS3Failure: handleUploadFileS3Failure,
    uploadFileS3Fulfill: handleUploadFileS3Fulfill,
  },
});

const { actions, reducer } = fileUploadsSlice;

export const {
  uploadFileS3,
  uploadFileS3Progress,
  uploadFileS3Success,
  uploadFileS3Failure,
  uploadFileS3Fulfill,
} = actions;

export default reducer;
