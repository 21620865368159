import {
  deleteAnnotator,
  fetchAllAnnotatorsFailure,
  fetchAllAnnotatorsSuccess,
  fetchSingleCampaign,
} from 'actions';
import AnnotatorListRow from 'components/AnnotatorList/AnnotatorListRow';
import ConfirmModal from 'components/ConfirmModal';
import EmptyTableState from 'components/EmptyTableState';
import FilterListHeaderRedesign from 'components/Filters/FilterListHeaderRedesign';
import LinkLookup from 'components/LinkLookup';
import ListFooterHooksBased from 'components/ListFooter/ListFooterHooksBased';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import PageHeader from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import Tooltip from 'components/Tooltip';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useAnnotators from 'hooks/annotators/useAnnotators';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import type { UUID } from 'types';
import AnnotatorListActionsRow from './AnnotatorListActionsRow';
import AnnotatorListCount from './AnnotatorListCount';
import ReplaceAnnotatorModal from './ReplaceAnnotatorModal';

type RouterParams = {
  campaignId: UUID;
  ruleId: UUID;
};

const AnnotatorList: React.FC = () => {
  const { campaignId, ruleId } = useParams<RouterParams>();

  const dispatch = useDispatch();

  const { data, isLoading, isFetching, error } = useAnnotators({ redirectOnError: true });

  if (error !== null) {
    dispatch(fetchAllAnnotatorsFailure(error));
  } else if (data) {
    dispatch(fetchAllAnnotatorsSuccess(data));
  }

  const [selectedAnnotators, setSelectedAnnotators] = useState<UUID[]>([]);
  const [isReplaceAnnotatorModalOpen, setIsReplaceAnnotatorModalOpen] = useState(false);
  const [showAnnotatorModels, setShowAnnotatorModels] = useState(false);
  const [isDeletingAnnotator, setIsDeletingAnnotator] = useState(false);

  const toggleConfirmModal = (): void => setIsDeletingAnnotator(false);

  const isActiveRule = campaignId && ruleId;

  useEffect(() => {
    if (!selectedAnnotators.length) {
      setShowAnnotatorModels(false);
    }
  }, [selectedAnnotators]);

  useEffect(() => {
    if (isActiveRule) {
      dispatch(fetchSingleCampaign({ campaignId }));
    }
  }, [campaignId, dispatch, isActiveRule]);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();

    if (selectedAnnotators.length === data?.records.length) {
      setSelectedAnnotators([]);
    } else {
      setSelectedAnnotators(data?.records.map((ann) => ann.uuid) || []);
    }
  };

  const handleAnnotatorSelect = (checked: boolean, uuid: UUID): void => {
    if (checked) {
      setSelectedAnnotators((selected) => [...selected, uuid]);
    } else {
      setSelectedAnnotators((selected) => selected.filter((id) => id !== uuid));
    }
  };

  const toggleReplaceAnnotatorModal = (): void => {
    setIsReplaceAnnotatorModalOpen(!isReplaceAnnotatorModalOpen);
  };

  const handleDeleteAnnotator = (): void => {
    dispatch(deleteAnnotator({ annotatorId: selectedAnnotators[0] }));
    setSelectedAnnotators([]);
    toggleConfirmModal();
  };

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />
      <div className="pt-6">
        <header>
          <div className="max-w-7xl mx-auto  px-6">
            <PageHeader />
            <div className="mt-2 md:flex md:items-center md:justify-between">
              <div className="flex-1 min-w-0">
                <div className="flex flex-row items-center">
                  <h2 className="title">Identifiers</h2>
                  {isFetching && <LoadingIndicator className="ml-2" size="5" />}
                </div>
              </div>
            </div>

            <div className="mt-4 flex-shrink-0 flex justify-between items-center">
              <div className="flex flex-row">
                <SearchInput
                  resource={resourceQueryParamName.annotator}
                  field="broad_search"
                  className="h-8"
                />
                <LinkLookup
                  routeName="annotator-choose-type"
                  type="button"
                  className="flex flex-row justify-center button button--primary h-8 w-30 ml-2 shadow-sm rounded"
                  data-testid="create-annotator-button"
                >
                  <span>Add New</span>
                </LinkLookup>
              </div>
              <AnnotatorListCount />
            </div>
          </div>
        </header>
        <main>
          <div className="h-full mt-2 mb-8 max-w-7xl mx-auto px-6">
            <LoadingOverlay
              active={isLoading}
              spinner={<LoadingIndicator size="10" />}
              fadeSpeed={0}
              styles={{
                content: (base) => ({
                  ...base,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }),
                wrapper: () => ({}),
                spinner: () => ({}),
                overlay: (base) => ({
                  ...base,
                  zIndex: 10,
                }),
              }}
            >
              <div className="table-wrapper__new h-full">
                <table className="table-wrapper__table h-full">
                  <thead>
                    <tr>
                      <th className="table-wrapper__new-th table-wrapper__th--w-3">
                        <div className="px-2">
                          <Tooltip tooltip="envelopesListActions.selectAll" redesign>
                            <label
                              htmlFor="select-all"
                              className="flex justify-start items-center w-full cursor-pointer"
                            >
                              <input
                                id="select-all"
                                data-testid="select-all"
                                className={`cursor-pointer form-checkbox litlingo-checkbox w-5 h-5 rounded-sm ${
                                  data &&
                                  selectedAnnotators.length > 0 &&
                                  selectedAnnotators?.length < data?.records.length
                                    ? 'checkbox-select-all-partial'
                                    : ''
                                }`}
                                type="checkbox"
                                checked={
                                  selectedAnnotators?.length === data?.records.length &&
                                  selectedAnnotators?.length > 0
                                }
                                onChange={handleSelectAll}
                              />
                            </label>
                          </Tooltip>
                        </div>
                      </th>

                      <FilterListHeaderRedesign
                        resource={resourceQueryParamName.annotator}
                        text="Identifier"
                        field="name"
                        className="table-wrapper__th--w-30"
                      />
                      <th className="table-wrapper__new-th">Type</th>
                      <th className="table-wrapper__new-th"># of Uses</th>
                      <FilterListHeaderRedesign
                        resource={resourceQueryParamName.annotator}
                        text="Last Modified"
                        field="updated_at"
                      />
                      <th className="table-wrapper__new-th">Modified By</th>
                    </tr>
                    {selectedAnnotators?.length > 0 && (
                      <AnnotatorListActionsRow
                        selectedAnnotators={selectedAnnotators}
                        toggleModalOpen={setIsReplaceAnnotatorModalOpen}
                        setShowAnnotatorModels={setShowAnnotatorModels}
                        setIsDeletingAnnotator={setIsDeletingAnnotator}
                        showAnnotatorModels={showAnnotatorModels}
                      />
                    )}
                  </thead>
                  <tbody className="table-wrapper__tbody h-full">
                    {!isFetching && data?.records.length === 0 && (
                      <EmptyTableState
                        data-testid="empty-table-state"
                        message="There are no identifiers to display"
                        colCount="6"
                      />
                    )}
                    {data?.records.map((annotator) => (
                      <AnnotatorListRow
                        annotator={annotator}
                        key={annotator.uuid}
                        handleAnnotatorSelect={handleAnnotatorSelect}
                        selected={selectedAnnotators.includes(annotator.uuid)}
                        showAnnotatorModels={showAnnotatorModels}
                      />
                    ))}
                  </tbody>
                </table>
                <ListFooterHooksBased
                  resourceStateName={resourceQueryParamName.annotator}
                  resourceName="identifier"
                  fetchHook={useAnnotators}
                />
              </div>
            </LoadingOverlay>
          </div>
        </main>
      </div>
      {isReplaceAnnotatorModalOpen && (
        <ReplaceAnnotatorModal
          isModalOpen={isReplaceAnnotatorModalOpen}
          toggleModalOpen={toggleReplaceAnnotatorModal}
          selectedAnnotators={selectedAnnotators}
          setSelectedAnnotators={setSelectedAnnotators}
        />
      )}
      {isDeletingAnnotator && (
        <ConfirmModal
          data-testid="delete-modal"
          text="Are you sure you want to delete this identifier?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDeleteAnnotator}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </div>
  );
};

export default AnnotatorList;
