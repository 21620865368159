import { upsertTemplate } from 'actions';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import type { Template } from 'types';

type ComponentProps = {
  template: Omit<Template, 'uuid'> & { uuid?: Template['uuid'] };
  toggleForm: boolean;
  setToggleForm: React.Dispatch<React.SetStateAction<boolean>>;
};
const TemplateForm: React.FC<ComponentProps> = (props) => {
  const { template, toggleForm, setToggleForm } = props;

  const dispatch = useDispatch();

  const { register, reset, getValues, handleSubmit } = useForm({
    defaultValues: { name: template.name, value: template.value },
  });

  useEffect(() => {
    reset({ name: template.name, value: template.value });
  }, [template, reset]);

  const onSubmit = (): void => {
    dispatch(
      upsertTemplate({
        ...(template.uuid ? { uuid: template.uuid } : {}),
        ...getValues(),
      })
    );
    setToggleForm(false);
  };

  const handleSubmitTemplateForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  return (
    <div
      className={`litlingo-gray-bg-color overflow-hidden transition-all duration-500 ease-in-out ${
        toggleForm ? 'max-h-96 p-4 border rounded-lg' : 'max-h-0 p-0'
      }`}
    >
      <h2 className="text-xl font-bold leading-5 text mb-6">Create or edit a template</h2>
      <form>
        <div className="flex flex-row justify-between flex-wrap">
          <div className="w-3/10">
            <label htmlFor="value" className="block text-sm font-bold leading-5 text">
              {'Name '}
              <span className="litlingo-red-color">*</span>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="name"
                  name="name"
                  ref={register({ required: true, pattern: /\S/i })}
                  className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 litlingo-gray-bg-color"
                />
              </div>
            </label>
          </div>
          <div className="w-6/10">
            <label htmlFor="value" className="block text-sm font-bold leading-5 text">
              {'Value '}
              <span className="litlingo-red-color">*</span>
              <div className="mt-1 rounded-md shadow-sm">
                <textarea
                  id="value"
                  name="value"
                  ref={register({ required: true, pattern: /\S/i })}
                  rows={10}
                  className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 litlingo-gray-bg-color"
                />
              </div>
            </label>
          </div>
        </div>
        <button
          onClick={handleSubmitTemplateForm}
          type="submit"
          className="button button--primary mt-6"
          data-testid="save-button"
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default TemplateForm;
