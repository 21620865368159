import type {
  AnnotatedGraph,
  Arrow,
  ArrowEndType,
  ArrowOrderItem,
  ArrowsOrder,
  DocumentVizColWidths,
} from '../types';

export const MIN_SVG_HEIGHT = 80;
export const X_DISTANCE_ARROWS = 7;
const Y_OFFSET = 15;
const Y_DISTANCE_ARROWS = 30;

export const buildWidths = (lines: AnnotatedGraph[]): DocumentVizColWidths => {
  const widths: DocumentVizColWidths = {};

  let index = 0;

  lines.forEach((line) => {
    widths[line.index] = {};

    line.nodes.forEach((superNode) => {
      superNode.nodes.forEach(() => {
        widths[line.index][index] = 0;
        index += 1;
      });
    });
  });

  return widths;
};

export const insertInOrder = (
  array: ArrowOrderItem[],
  start: number,
  end: number,
  type: ArrowEndType
): ArrowOrderItem[] => {
  if (array == null || array.length === 0) {
    return [{ end, type }];
  }

  const lower: ArrowOrderItem[] = [];
  const greater: ArrowOrderItem[] = [];
  array.forEach((e) => (e.end < start ? lower.push(e) : greater.push(e)));
  if (start > end) {
    // goes to left (order is leftmost to rightmost, - to +)
    const idx = lower.findIndex((e) => e.end < end);
    if (idx === -1) {
      lower.push({ end, type });
    } else {
      lower.splice(idx, 0, { end, type });
    }
  } else {
    // goes to right (order is rightmost to leftmost, + to -)
    const idx = greater.findIndex((e) => e.end < end);
    if (idx === -1) {
      greater.push({ end, type });
    } else {
      greater.splice(idx, 0, { end, type });
    }
  }

  return [...lower, ...greater];
};

export const getYLevel = (
  root: number,
  head: number,
  arrows: Arrow[]
): number => {
  const arrowsUnder = arrows.filter((arrow) => {
    if (arrow.head === head && arrow.root === root) {
      return false;
    }

    const arrowMax = Math.max(arrow.head, arrow.root);
    const arrowMin = Math.min(arrow.head, arrow.root);
    const max = Math.max(head, root);
    const min = Math.min(head, root);

    // Relationship line "inside" another
    if (arrowMin >= min && arrowMax <= max) {
      return true;
    }
    // Relationship line crossing another
    if (arrowMin < min) {
      if (arrowMax > min && arrowMax < max) {
        return true;
      }
    }

    return false;
  });

  if (arrowsUnder.length === 0) {
    return 1;
  }

  const levels = arrowsUnder.map((arrow) =>
    getYLevel(arrow.root, arrow.head, arrowsUnder)
  );

  return Math.max(...levels) + 1;
};

export const calculateYPos = (
  root: number,
  head: number,
  arrows: Arrow[]
): [number, number] => {
  const level = getYLevel(root, head, arrows);

  const bottomY = -2;
  const topY = -Y_OFFSET - (level - 1) * Y_DISTANCE_ARROWS;

  return [bottomY, topY];
};

export const calculateXPos = (
  root: number,
  head: number,
  line: number,
  widths: DocumentVizColWidths,
  order: ArrowsOrder
): [number | null, number | null] => {
  let offset = 0;
  for (let idx = 0; idx < line; idx += 1) {
    if (widths[idx] != null) {
      const lineWidths = Object.values(widths[idx]);
      for (let nodeIdx = 0; nodeIdx < lineWidths.length; nodeIdx += 1) {
        const width = lineWidths[nodeIdx];
        if (width != null) {
          if (width === 0) {
            return [null, null];
          }

          offset += width;
        }
      }
    }
  }

  const rootIdx = order[root].findIndex(
    (e) => e.end === head && e.type === 'root'
  );
  const headIdx = order[head].findIndex(
    (e) => e.end === root && e.type === 'head'
  );

  const rootOffset =
    -Math.floor(order[root].length / 2) +
    rootIdx +
    (order[root].length % 2 === 0 ? 0.5 : 0);
  const headOffset =
    -Math.floor(order[head].length / 2) +
    headIdx +
    (order[head].length % 2 === 0 ? 0.5 : 0);

  let rootX = offset + rootOffset * X_DISTANCE_ARROWS;
  let headX = offset + headOffset * X_DISTANCE_ARROWS;

  for (let idx = 0; idx <= Math.max(root, head); idx += 1) {
    if (widths[line] != null && widths[line][idx] != null) {
      const width = widths[line][idx];
      if (width === 0) {
        return [null, null];
      }

      if (idx < root) {
        rootX += width;
      }
      if (idx === root) {
        rootX += width / 2;
      }
      if (idx < head) {
        headX += width;
      }
      if (idx === head) {
        headX += width / 2;
      }
    }
  }

  return [rootX, headX];
};

export const relationshipsSVGHeight = (maxTopY: number): number =>
  Math.max(maxTopY + Y_DISTANCE_ARROWS, MIN_SVG_HEIGHT);
