import {
  DOWNLOAD_BACKGROUND,
  DOWNLOAD_FOOTER_BACKGROUND,
  DOWNLOAD_LITLINGO_LOGO,
} from 'constants/common';
import React from 'react';

const DownloadLanding: React.FC = () => (
  <div className="flex flex-col justify-between min-h-screen max-h-screen overflow-hidden">
    <div className="w-full p-8">{DOWNLOAD_LITLINGO_LOGO}</div>
    <div className="relative w-full bg-white ">
      <div className="">{DOWNLOAD_BACKGROUND}</div>
      <div className="absolute top-1/2 left-14/25 z-10 text-3xl font-bold leading-7 w-78">
        <span>Find your document in your downloads</span>
      </div>
    </div>
    <div className="w-full mb-18 mt-13">{DOWNLOAD_FOOTER_BACKGROUND}</div>
  </div>
);

export default DownloadLanding;
