import React from 'react';
import ReactMarkdownWithHtml from 'react-markdown/with-html';
import type { OutcomeTypes } from 'types';

type ComponentProps = {
  outcomeTypes: OutcomeTypes;
  typeSelection: string;
  markDownContent: string;
};

const Preview: React.FC<ComponentProps> = ({ outcomeTypes, typeSelection, markDownContent }) => {
  if (!outcomeTypes || !typeSelection || !outcomeTypes[typeSelection as keyof typeof outcomeTypes])
    return null;
  const typeItem = outcomeTypes[typeSelection as keyof typeof outcomeTypes];

  return (
    <>
      {Object.keys(typeItem)
        // @ts-ignore
        .filter((key) => !typeItem[key as keyof typeof typeItem]?.hidden)
        .map(
          (key, ind) =>
            // @ts-ignore
            typeItem[key as keyof typeof typeItem].name === 'Body Template' && (
              <label htmlFor="Preview" key={key}>
                <p className={`text text--bold ${ind ? 'pt-8' : ''}`}>
                  {/*
                  // @ts-ignore */}
                  {typeItem[key as keyof typeof typeItem].name}:
                </p>
                <div
                  style={{
                    minHeight: '30px',
                    maxHeight: '150px',
                    overflow: 'auto',
                  }}
                >
                  <ReactMarkdownWithHtml>{markDownContent}</ReactMarkdownWithHtml>
                </div>
              </label>
            )
        )}
    </>
  );
};

export default Preview;
