/* eslint-disable max-lines */
import React from 'react';
import RuleManagerTable from './RuleManagerTable';

// type RouterParams = { ruleOneId?: string };

const RulesCompareLeftPanel: React.FC = () => (
  //   const dispatch = useDispatch();
  // const loading = useSelector(getUsersLoading);
  // const { ruleOneId } = useParams<RouterParams>();

  <div className="w-full h-full py-4">
    <RuleManagerTable
      side="left"
      setIdToMove={(): void => {
        // moot
      }}
      idToMove=""
      collapseLevel={10}
      setLevelIsModified={(): void => {
        // moot
      }}
      setCollapseLevel={(): void => {
        // moot
      }}
    />
  </div>
);
export default RulesCompareLeftPanel;
