import { createAction } from '@reduxjs/toolkit';
import type { Team, User } from 'types';

export const selectUser = createAction<User>('USER_PROFILE_PAGE/SELECT_USER');

export const addTeamsUser = createAction<Team[]>('USER_PROFILE_PAGE/ADD_TEAMS');
export const removeTeamsUser = createAction<Team[]>('USER_PROFILE_PAGE/REMOVE_TEAMS');

export const addRolesUser = createAction<string[]>('USER_PROFILE_PAGE/ADD_ROLES');
export const removeRolesUser = createAction<string[]>('USER_PROFILE_PAGE/REMOVE_ROLES');

export const addInsightUser = createAction<string[]>('USER_PROFILE_PAGE/ADD_INSIGHT');
export const removeInsightUser = createAction<string[]>('USER_PROFILE_PAGE/REMOVE_INSIGHT');

export const addPreventUser = createAction<string[]>('USER_PROFILE_PAGE/ADD_PREVENT');
export const removePreventUser = createAction<string[]>('USER_PROFILE_PAGE/REMOVE_PREVENT');

export const changePrivacyLevelUser = createAction<User['privacy_level']>(
  'USER_PROFILE_PAGE/CHANGE_PRIVACY_LEVEL'
);

export const applyChangesToUser = createAction<User>('USER_PROFILE_PAGE/APPLY_PERMISSIONS');
