import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { useNavParamsByResource } from 'hooks/nav/useNavParamsByResource';
import { RequestParamsType, RequestResponse } from 'hooks/types/client';
import useRequest from '../client/useRequest';

type Options = { redirectOnError: boolean };

type UseModels = (options?: Options) => RequestResponse<'models.list'>;

const useModels: UseModels = (options) => {
  const resourceParams = useNavParamsByResource(resourceQueryParamName.model);

  const params: RequestParamsType = {
    queryParams: {
      ...resourceParams,
      relationships: ['updated_by'],
      include_count: true,
      include_pii: true,
    },
    redirectOnError: options?.redirectOnError,
  };

  const result = useRequest('models.list', params);

  return result;
};

export default useModels;
