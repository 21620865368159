/* eslint-disable max-lines */
import {
  editCustomer,
  fetchSingleCustomer,
  fetchZendesk,
  saveCustomer,
  showSuccessAlert,
  zendeskVerifyCredentials,
} from 'actions';
import Card from 'components/Card';
import CustomerEditSecretModal from 'components/Customer/CustomerEditSecretModal';
import CustomerSaveButton from 'components/Customer/CustomerSaveButton';
import CustomerZendeskKeyModal from 'components/Customer/CustomerZendeskKeyModal';
import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import { LEFT_ARROW_ICON, OK_ICON, WRONG_ICON } from 'constants/envelopeIcons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCustomer,
  getCustomerZendeskVerifyCredentials,
  getCustomerZendeskVerifyCredentialsLoading,
  getUser,
} from 'selectors/auth';
import { Customer } from 'types';
import CustomerEditUploadS3 from './CustomerEditUploadS3';
import CustomerLeaveConfirmModal from './CustomerLeaveConfirmModal';
import VerifyCredentialsMsg from './VerifyCredentialsMsg';

const CustomerEditZendesk: React.FC = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isZendeskKeyModalOpen, setZendeskKeyModalOpen] = useState(false);
  const [currentSecret, setCurrentSecret] = useState('');
  const [verified, setVerified] = useState(false);
  const { register, getValues, reset } = useForm();
  const customer = useSelector(getCustomer);
  const apiKeyName = 'zendesk_key';
  const apiTokenName = 'zendesk_api_token';
  const triggerPasswordName = 'zendesk_trigger_password';
  const zendeskAdminEmail = 'zendesk_admin_email';
  const zendeskDomain = 'zendesk_domain';
  const zendeskKey = customer?.secrets_summary?.[apiKeyName];
  const zendeskApiToken = customer?.secrets_summary?.[apiTokenName];
  const zendeskTriggerPassword = customer?.secrets_summary?.[triggerPasswordName];
  const verifiedCredentials = useSelector(getCustomerZendeskVerifyCredentials);
  const loading = useSelector(getCustomerZendeskVerifyCredentialsLoading);
  const [isSaved, setIsSaved] = useState(false);
  const [unsaved, setUnsaved] = useState(false);
  const [isLeaveModalOpen, setLeaveModalOpen] = useState(false);

  const user = useSelector(getUser);

  const handleEdit = (data: Customer): void => {
    if (JSON.stringify(data) === JSON.stringify(customer)) return;
    setUnsaved(true);
    dispatch(editCustomer(data));
  };

  const handleSave = async (): Promise<void> => {
    dispatch(saveCustomer({}));
    setIsSaved(true);
    setUnsaved(false);
  };

  const handleVerifyCredentials = (): void => {
    setVerified(true);
    dispatch(zendeskVerifyCredentials());
  };

  const handleZendeskCopyClick = async (): Promise<void> => {
    await navigator.clipboard.writeText(
      `https://k8s.litlingo.com/api/v1/communications/evaluate-zendesk/${customer.uuid}`
    );
    dispatch(showSuccessAlert('Zendesk Evaluate Endpoint'));
  };

  useEffect(
    () =>
      reset({
        zendesk_admin_email: customer[zendeskAdminEmail],
        zendesk_domain: customer[zendeskDomain],
      }),
    [reset, customer]
  );

  useEffect(() => {
    if (user && !customer.config) {
      dispatch(fetchSingleCustomer());
    }
  }, [dispatch, user, customer]);

  const header = (
    <div className="flex justify-between">
      <div className="litlingo-customer-back-header-button">
        <LinkLookup
          data-testid="customer-edit-zendesk-left-button"
          routeName="customer"
          className="mx-6 focus:outline-none"
          onClick={(e): void => {
            if (unsaved) {
              e.preventDefault();
              setLeaveModalOpen(true);
            }
          }}
        >
          {LEFT_ARROW_ICON}
        </LinkLookup>
        <span className="text text--large text--bold">Zendesk</span>
      </div>
      <CustomerSaveButton
        handleSave={handleSave}
        unsaved={unsaved}
        isSaved={isSaved}
        className="litlingo-customer-save-button"
      />
    </div>
  );

  const apiKey = (
    <div className="px-5 py-4 text-sm">
      <div className="flex justify-between">
        <div>
          <p>API key</p>
          <p className="text-xs">Enter this key when installing the LitLingo app in Zendesk</p>
        </div>
        <div className="flex items-center">
          <div className="mx-8">{zendeskKey ? OK_ICON : WRONG_ICON}</div>
          <button
            data-testid="zendesk-api-key-edit-button"
            type="button"
            onClick={(): void => {
              setCurrentSecret(apiKeyName);
              setModalOpen(true);
            }}
            className="button button--primary button--blue-textfocus:outline-none"
          >
            {zendeskKey ? 'Replace' : 'Setup'}
          </button>
        </div>
      </div>
    </div>
  );

  const apiToken = (
    <div className="px-5 py-4 text-sm">
      <div className="flex justify-between">
        <div>
          <p>API Token</p>
          <p className="text-xs">
            Copy token from Zendesk API settings
            (https://&lt;domain&gt;.zendesk.com/agent/admin/api/settings)
          </p>
        </div>
        <div className="flex items-center">
          <div className="mx-8">{zendeskApiToken ? OK_ICON : WRONG_ICON}</div>
          <button
            data-testid="zendesk-api-token-edit-button"
            type="button"
            onClick={(): void => {
              setCurrentSecret(apiTokenName);
              setModalOpen(true);
            }}
            className="button button--primary button--blue-textfocus:outline-none"
          >
            {zendeskApiToken ? 'Replace' : 'Setup'}
          </button>
        </div>
      </div>
    </div>
  );

  const triggerPassword = (
    <div className="px-5 py-4 text-sm">
      <div className="flex justify-between">
        <div>
          <p>Target password</p>
          <p className="text-xs">
            Copy password from your Zendesk target
            (https://&lt;domain&gt;.zendesk.com/agent/admin/triggers)
          </p>
        </div>
        <div className="flex items-center">
          <div className="mx-8">{zendeskTriggerPassword ? OK_ICON : WRONG_ICON}</div>
          <button
            data-testid="zendesk-trigger-password-edit-button"
            type="button"
            onClick={(): void => {
              setCurrentSecret(triggerPasswordName);
              setModalOpen(true);
            }}
            className="button button--primary button--blue-textfocus:outline-none"
          >
            {zendeskTriggerPassword ? 'Replace' : 'Setup'}
          </button>
        </div>
      </div>
    </div>
  );

  const adminEmail = (
    <div className="px-5 py-4 text-sm">
      <div className="flex justify-between items-center">
        <div>
          <p>Admin email</p>
          <p className="text-xs">
            Must be the same email address of the user who generate the API token
          </p>
        </div>
        <div className="flex">
          <input
            data-testid="customer-edit-zendesk-admin-email-input"
            id={zendeskAdminEmail}
            name={zendeskAdminEmail}
            onBlur={(): void => {
              handleEdit({
                ...customer,
                [zendeskAdminEmail]: getValues(zendeskAdminEmail),
              });
            }}
            ref={register()}
            className="litlingo-customer-card-input form-input"
          />
        </div>
      </div>
    </div>
  );

  const domain = (
    <div className="px-5 py-4 text-sm">
      <div className="flex justify-between items-center">
        <div>Domain</div>
        <div className="flex items-center">
          <input
            data-testid="customer-edit-zendesk-domain-input"
            id={zendeskDomain}
            name={zendeskDomain}
            onBlur={(): void => {
              handleEdit({
                ...customer,
                [zendeskDomain]: getValues(zendeskDomain),
              });
            }}
            ref={register()}
            className="litlingo-customer-card-input form-input"
          />
          <span className="text-sm">.zendesk.com</span>
        </div>
      </div>
    </div>
  );

  const evaluateEndpoint = (
    <div className="px-5 py-4 text-sm">
      <div className="flex justify-between">
        <div>Evaluate endpoint</div>
        <div className="flex items-center">
          <div className="flex items-center">
            <span className="text-sm pr-4">https://k8s.litlingo.com/api/...</span>
            <svg
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              onClick={handleZendeskCopyClick}
              className="cursor-pointer"
              data-testid="uuid-copy-clipboard-button"
            >
              <path
                d="M5 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H13C13.5304 19 14.0391 18.7893 14.4142 18.4142C14.7893 18.0391 15 17.5304 15 17V16M5 3C5 3.53043 5.21071 4.03914 5.58579 4.41421C5.96086 4.78929 6.46957 5 7 5H9C9.53043 5 10.0391 4.78929 10.4142 4.41421C10.7893 4.03914 11 3.53043 11 3M5 3C5 2.46957 5.21071 1.96086 5.58579 1.58579C5.96086 1.21071 6.46957 1 7 1H9C9.53043 1 10.0391 1.21071 10.4142 1.58579C10.7893 1.96086 11 2.46957 11 3M11 3H13C13.5304 3 14.0391 3.21071 14.4142 3.58579C14.7893 3.96086 15 4.46957 15 5V8M17 12H7M7 12L10 9M7 12L10 15"
                stroke="#1C5579"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );

  const handleGenerateZendesk = async (): Promise<void> => {
    dispatch(fetchZendesk());
    setZendeskKeyModalOpen(true);
  };

  const generateKeyButton = (
    <div className="px-5 py-4">
      <div className="flex justify-between">
        <div />
        <button
          data-testid="generate-zendesk-key-button"
          onClick={handleGenerateZendesk}
          type="button"
          className="button button--secondary text text-base border-black"
        >
          Generate Extension Auth Key
        </button>
      </div>
    </div>
  );

  const verifiedCredentialsView = (): JSX.Element => {
    const message = `Credentials${verifiedCredentials ? ' verified' : ' failed'}`;

    return (
      <div className="px-5 py-4">
        <div className="flex justify-end">
          <div />
          {verified && !loading && (
            <VerifyCredentialsMsg ok={verifiedCredentials} message={message} />
          )}
          {loading && (
            <div className="h-8">
              <LoadingIndicator className="ml-2" size="5" />
            </div>
          )}
          <button
            data-testid="customer-edit-zendesk-test-credentials-button"
            type="button"
            className="button button--secondary text text-base border-black ml-4"
            onClick={handleVerifyCredentials}
          >
            Test credentials
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="litlingo-gray-bg-color-4">
      <Navbar />
      <div className="flex flex-row">
        <SettingSidebar />
        <div className="w-full max-w-7xl">
          <header>
            <div className="md:flex md:items-center md:justify-between">
              <div className="flex-1 min-w-0">
                {/* <div className="flex flex-row items-center">
                {(fetchLoading || saveLoading) && <LoadingIndicator className="ml-2" size="5" />}
              </div> */}
              </div>
            </div>
          </header>
          <main>
            <div
              className="overflow-auto"
              style={{
                minHeight: 'calc(100vh - 4rem)',
                height: 'calc(100vh - 4rem)',
              }}
            >
              <div className="mt-0 mx-auto min-w-2xl w-8/12 max-w-6xl text-left">
                <Card
                  items={[
                    header,
                    domain,
                    apiKey,
                    apiToken,
                    adminEmail,
                    triggerPassword,
                    evaluateEndpoint,
                    generateKeyButton,
                    verifiedCredentialsView(),
                    <CustomerEditUploadS3 key="Zendesk" provider="zendesk" />,
                    <div key="div" />,
                  ]}
                />
                {isModalOpen && (
                  <CustomerEditSecretModal
                    secretKeyName={currentSecret}
                    toggleShowModal={(): void => setModalOpen(!isModalOpen)}
                  />
                )}
                {isZendeskKeyModalOpen && (
                  <CustomerZendeskKeyModal
                    toggleShowModal={(): void => setZendeskKeyModalOpen(!isZendeskKeyModalOpen)}
                  />
                )}
                {isLeaveModalOpen && (
                  <CustomerLeaveConfirmModal
                    toggleShowModal={(): void => setLeaveModalOpen(!isLeaveModalOpen)}
                    setUnsaved={setUnsaved}
                  />
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default CustomerEditZendesk;
