import config from '@config';

// to have the proper types on the client this can't have a type, if debugging
// is needed ClientSpec can be used as the type
const IntegrationsSpec = {
  base: config.api.integrations,
  logs: false,

  headers: {
    'x-litlingo-auth-no-redirect': 'true',
    'block-overwrite': 'true',
    'cache-control': 'no-cache',
    'x-litlingo-platform': '__REPLACE__',
  },

  resources: {
    integrations: {
      path: '/integrations',
      include: [],
      extras: {
        evaluate: {
          path: '/evaluate',
          method: 'POST',
        },
        updatePlatformGUIDs: {
          path: '/update-platform-guids',
          method: 'POST',
        },
        preprocess: {
          path: '/preprocess',
          method: 'POST',
        },
        review: {
          path: '/{eventId}/action?relationships=actions',
          method: 'PUT',
        },
        timeout: {
          path: '/{eventId}/timeout',
          method: 'POST',
        },
      },
    },
  },
} as const;

export default Object.freeze(IntegrationsSpec);
