import React, { useCallback, useRef, useState } from 'react';
import useClickOutside from '../utils/useClickOutside';

type SelectOptionProps = {
  onChange: (option: string) => void;
  option: {
    label: string;
    value: string;
    disabled?: boolean;
    className?: string;
  };
  selected: boolean;
  dataTestid: string;
  optionClassname?: string;
};

const SelectOption: React.FC<SelectOptionProps> = (props) => {
  const { onChange, option, selected, optionClassname, dataTestid } = props;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation();
    if (!selected) {
      onChange(option.value);
    }
  };

  return (
    <button
      type="button"
      data-testid={dataTestid}
      className={`w-full text-left border-litlingo-gray-2 p-2 focus:outline-none hover:bg-litlingo-gray-1 ${optionClassname} ${
        option.className
      } ${selected ? 'font-bold cursor-default' : 'cursor-pointer'}`}
      onClick={(e): void => handleClick(e)}
      disabled={option.disabled}
    >
      <div className="flex">
        <span>{option.label}</span>
      </div>
    </button>
  );
};

type SelectProps = {
  onChange: (option: string) => void;
  placeholder?: string;
  dataTestid?: string;
  options: SelectOptionProps['option'][];
  className?: string;
  valueClassName?: string;
  optionClassname?: string;
  value: string;
};

const SelectRedesign: React.FC<SelectProps> = (props) => {
  const {
    onChange,
    options,
    placeholder,
    value,
    dataTestid,
    className,
    valueClassName,
    optionClassname,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  // click outside functionality
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const selectedOption = options.find((option) => option.value === value);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };
  const handleChange = (option: string): void => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div ref={wrapperRef} className={`relative w-full rounded ${className}`}>
      <button
        type="button"
        className={`flex h-8 w-full justify-between items-center px-2 text-litling0-gray-6 border border-litlingo-gray-2 rounded focus:outline-none disabled:opacity-50 ${className}`}
        id="options-menu"
        data-testid={dataTestid || 'select'}
        aria-haspopup="true"
        aria-expanded="true"
        onClick={(e): void => handleClick(e)}
      >
        <span
          className={`truncate ${valueClassName || 'text-body'} ${!selectedOption ? 'italic' : ''}`}
        >
          {selectedOption ? selectedOption.label : placeholder || 'Select an option'}
        </span>

        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="flex-none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.49026 8.44392L1.83398 3L0 4.91123L7.49035 12.0926L15 4.91123L13.166 3L7.49026 8.44392Z"
            fill="currentColor"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          data-testid="dropdown-menu"
          className="origin-bottom-left absolute mt-1 w-full rounded shadow-lg z-50 border border-litlingo-gray-2"
        >
          <div
            className="rounded bg-white shadow-xs overflow-hidden"
            style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}
          >
            <div
              role="menu"
              data-testid="options-container"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {options.map((option) => (
                <SelectOption
                  key={`${option.label}-${option.value}`}
                  onChange={handleChange}
                  option={option}
                  selected={option.value === value}
                  dataTestid={`${dataTestid || 'select'}-${option.label}-option`}
                  optionClassname={optionClassname}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectRedesign;
