import { fetchSingleRule, fetchSingleSimpleCampaign } from 'actions';
import LinkLookup from 'components/LinkLookup';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getCampaign } from 'selectors/campaigns';
import { getRule } from 'selectors/rule';
import { useSelector } from 'store';
import type { UUID } from 'types';

type RouterParams = {
  ruleId: UUID;
  campaignId: UUID;
};

const PageHeader: React.FC = () => {
  const dispatch = useDispatch();
  const { ruleId, campaignId } = useParams<RouterParams>();
  const location = useLocation();
  const pathname = location.pathname.split('/').filter(Boolean);
  const isRulePage = pathname[1] === 'rule';
  const isOutcomeManager = pathname[pathname.length - 1] === 'outcome';

  const campaign = useSelector(getCampaign(campaignId));
  /* const campaignRuleOutcome = useSelector(getCampaignFromCampaignRuleOutcome); */
  const rule = useSelector(getRule(ruleId));

  useEffect(() => {
    if (campaignId) {
      dispatch(fetchSingleSimpleCampaign({ campaignId }));
    }

    if (ruleId) {
      dispatch(fetchSingleRule({ ruleId }));
    }
  }, [dispatch, campaignId, ruleId]);

  // When coming from campaign
  const campaignBased = (
    <>
      <LinkLookup routeName="campaign-list" className="text-small">
        All Use Cases
      </LinkLookup>

      {ruleId && (
        <>
          <span className="mx-2"> {'>'} </span>
          <LinkLookup
            routeName="campaign-detail"
            routeParams={{ campaignId }}
            className="text-small"
          >
            {campaign && campaign.name}
          </LinkLookup>
        </>
      )}
      {ruleId && (!isRulePage || isOutcomeManager) && (
        <>
          <span className="mx-2"> {'>'} </span>
          <LinkLookup
            routeName="rule-manager-campaign"
            routeParams={{ campaignId, ruleId }}
            className="text-small"
          >
            {rule && rule.name}
          </LinkLookup>
        </>
      )}
    </>
  );

  // When coming from rule
  const ruleBased = (
    <>
      <LinkLookup routeName="rule-list" className="text-small">
        All Models
      </LinkLookup>

      {(!isRulePage || isOutcomeManager) && (
        <>
          <span className="mx-2"> {'>'} </span>
          <LinkLookup routeName="rule-manager" routeParams={{ ruleId }} className="text-small">
            {rule && rule.name}
          </LinkLookup>
        </>
      )}
    </>
  );

  const annotatorBased = (
    <LinkLookup routeName="annotator-list" className="text-small">
      All Identifiers
    </LinkLookup>
  );

  const renderPageHeader = (): JSX.Element => {
    if (campaignId) {
      return campaignBased;
    }
    if (ruleId) {
      return ruleBased;
    }
    return annotatorBased;
  };

  return (
    <div>
      <nav className="sm:hidden">
        <LinkLookup
          routeName="campaign-list"
          className="flex items-center text text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out"
        >
          <svg
            className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
          Back
        </LinkLookup>
      </nav>
      <nav className="hidden sm:flex items-center text-small">{renderPageHeader()}</nav>
    </div>
  );
};

export default PageHeader;
