import React from 'react';
import { getTriggeredNodes } from 'selectors/communications';
import { getAnnotatorsFromRules } from 'selectors/rule';
import { useSelector } from 'store';
import type { MRuleConfigNode, RuleAnnotator, UUID } from 'types';
import { RELATIONSHIP_BOX_WIDTH_TAILWIND } from 'utils/dimensions';

type ComponentProps = {
  annotators: MRuleConfigNode[];
  ruleId: UUID;
};

const RenderAnnotators: React.FC<ComponentProps> = ({ annotators, ruleId }) => {
  const ruleAnnotators = useSelector((state) => getAnnotatorsFromRules(state, ruleId));
  const triggeredNodes = useSelector(getTriggeredNodes);

  const getAnnotatorRealName = (annotatorId: UUID | undefined): RuleAnnotator | undefined => {
    if (ruleAnnotators != null) {
      return ruleAnnotators.find((element) => element.annotator_uuid === annotatorId);
    }

    return undefined;
  };

  const createBox = (name: string, id: string): JSX.Element => (
    <div
      className={`border-2 border-gray-500 py-1 pl-3 my-2 text-left ${RELATIONSHIP_BOX_WIDTH_TAILWIND}  ${
        triggeredNodes.includes(id) ? 'bg-gray-400 border-gray-600' : 'bg-white border-gray-500'
      }`}
    >
      {name}
    </div>
  );

  return (
    <>
      {annotators.map((node) => (
        <div key={node.id} className="flex flex-row justify-between w-full">
          {createBox(
            getAnnotatorRealName('annotatorId' in node ? node.annotatorId : '')?.annotator?.name ||
              node.name,
            node.id
          )}
        </div>
      ))}
    </>
  );
};

export default RenderAnnotators;
