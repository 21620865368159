import ConfirmModal from 'components/ConfirmModal';

import useDeleteTeam from 'hooks/teams/useDeleteTeam';

import { COPY_CLIPBOARD, EDIT_ICON, TRASH_ICON } from 'constants/commonIcons';

import React, { useState } from 'react';
import type { Team } from 'types';

type ComponentProps = {
  team: Team;
  handleChangeTeam: (team: Team) => void;
  handleCopyClick: (
    event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>,
    teamUuid: string
  ) => void;
};

const TeamListItem: React.FC<ComponentProps> = ({ team, handleChangeTeam, handleCopyClick }) => {
  const [isDeletingTeam, setIsDeletingTeam] = useState(false);

  const { mutate: deleteTeam } = useDeleteTeam();

  const toggleConfirmModal = (): void => {
    setIsDeletingTeam(!isDeletingTeam);
  };

  const handleRemoveTeam = (): void => {
    deleteTeam({ id: team.uuid });
    toggleConfirmModal();
  };

  return (
    <>
      <tr className="inline-flex items-center table-row">
        <td className="table-wrapper__td">
          <div className="flex items-center">
            <div className="overflow-hidden">
              <div className="text-sm leading-5 font-medium litlingo-flex-break-words">
                {team.name}
              </div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__td">{team.users?.length || 0}</td>
        <td className="table-wrapper__td">
          <button
            type="button"
            className="mr-4"
            data-testid="edit-team-button"
            onClick={(): void => handleChangeTeam(team)}
          >
            {EDIT_ICON()}
          </button>
          <button
            type="button"
            className="mr-4 w-5 h-5"
            data-testid="delete-team-button"
            onClick={toggleConfirmModal}
          >
            {TRASH_ICON}
          </button>
          <button
            className="focus:outline-none ext-gray-700 hover:text-black"
            type="button"
            data-testid="copy-button"
            onClick={(event): void => handleCopyClick(event, team.uuid)}
          >
            {COPY_CLIPBOARD}
          </button>

          {isDeletingTeam && (
            <ConfirmModal
              text="Are you sure you want to delete this division?"
              okButtonText="Yes"
              cancelButtonText="No"
              okButtonOnClick={handleRemoveTeam}
              toggleShowModal={toggleConfirmModal}
            />
          )}
        </td>
      </tr>
    </>
  );
};

export default TeamListItem;
