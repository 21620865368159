import { selectUser } from 'actions/userProfilePage';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import Permissions from 'components/Permissions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUser, getUserLoading } from 'selectors/users';
import { UUID } from 'types';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import UserEditProfile from './UserEditProfile';
import UserHighlightSettings from './UserHighlightSettings';
import UserProfileCard from './UserProfileCard';

type RouteParams = {
  userId: UUID;
};

const User: React.FC = () => {
  const { userId } = useParams<RouteParams>();

  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);

  const isLoading = useSelector(getUserLoading);
  const user = useSelector(getUser(userId));

  useEffect(() => {
    if (user) {
      dispatch(selectUser(user));
    }
  }, [dispatch, user]);

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <div
      className="flex flex-col min-w-screen bg-white overflow-hidden"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />
      <div
        className="flex flex-row z-0"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <SettingSidebar />
        <div className="flex flex-col gap-6 py-6 px-10 w-full">
          {isLoading ? (
            <LoadingIndicator className="m-auto mt-20" size="20" />
          ) : (
            <>
              <header>
                <div className="text-heading-1">User Profile</div>
              </header>
              <main>
                {isEditing ? (
                  <UserEditProfile setIsEditing={setIsEditing} />
                ) : (
                  <UserProfileCard setIsEditing={setIsEditing} />
                )}
                <Permissions action="communication_envelopes.list">
                  <UserHighlightSettings />
                </Permissions>
              </main>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default User;
