import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { useNavParamsByResource } from 'hooks/nav/useNavParamsByResource';
import { RequestParamsType, RequestResponse } from 'hooks/types/client';
import useRequest from '../client/useRequest';

type Options = { redirectOnError: boolean };

type UseAnnotators = (options?: Options) => RequestResponse<'annotators.list'>;

const useAnnotators: UseAnnotators = (options) => {
  const resourceParams = useNavParamsByResource(resourceQueryParamName.annotator);

  const params: RequestParamsType = {
    queryParams: {
      ...resourceParams,
      relationships: [
        'rule_annotators.rule',
        'rule_annotators.rule.campaign_rule_outcomes',
        'rule_annotators.rule.campaign_rule_outcomes.campaign',
        'updated_by',
      ],
      include_count: true,
      include_pii: true,
    },
    redirectOnError: options?.redirectOnError,
  };

  const result = useRequest('annotators.list', params, {});

  return result;
};

export default useAnnotators;
