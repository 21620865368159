/* eslint-disable camelcase */
import { TestCase } from '@litlingo/client';
import { addTestCaseTag, removeTestCaseTag } from 'actions';
import { fetchAllTags } from 'actions/tags';
import EnvelopeLabel from 'components/EnvelopeLabel';
import { SEARCH_ICON } from 'constants/filterIcons';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ReactTags from 'react-tag-autocomplete';
import { getFetchAllTagsLoading, getTagsForFilters } from 'selectors/tags';
import { addTestCaseTagLoading, removeTestCaseTagLoading } from 'selectors/testCases';
import { useSelector } from 'store';
import 'styles/labelsStyle.css';

type TestCaseTagsProps = {
  testCase: TestCase;
  direction?: 'col' | 'row';
  renderLabels?: boolean;
};

const TestCaseTags: React.FC<TestCaseTagsProps> = (props) => {
  const { testCase, direction = 'col', renderLabels = true } = props;

  const dispatch = useDispatch();
  const allTags = useSelector(getTagsForFilters);
  const tagsLoading = useSelector(getFetchAllTagsLoading);

  const addLoading = useSelector(addTestCaseTagLoading);
  const removeLoading = useSelector(removeTestCaseTagLoading);

  const suggestions = allTags
    .filter((tag) => !testCase.tags?.some((t) => t.tag_value?.value === tag.value))
    .map((tag) => ({ id: tag.uuid, name: tag.value }));

  useEffect(() => {
    dispatch(fetchAllTags({ limit: -1 }));
  }, [dispatch]);

  const handleAddition = (tag: { name: string }): void => {
    dispatch(
      addTestCaseTag({
        testCaseId: testCase.uuid,
        tag: tag.name,
      })
    );
  };

  const handleRemove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, tag: string): void => {
    e.preventDefault();
    dispatch(removeTestCaseTag({ testCaseId: testCase.uuid, tag }));
  };

  const renderTags = (): JSX.Element | null => {
    if (!testCase.tags) return null;

    return (
      <>
        {testCase.tags?.map((tag) => {
          const tagObj = tag.tag_value;
          if (tagObj == null) {
            return null;
          }

          return (
            <div style={{ order: 0, margin: '2px 0' }} key={tagObj.uuid}>
              {/* Change this */}
              <EnvelopeLabel
                text={tagObj.value}
                backgroundColor={tagObj.color}
                className="litlingo-tag-color"
                allowedRemove
                handleRemove={(e): void => handleRemove(e, tagObj.value)}
              />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className={`flex gap-2 items-center w-full flex-${direction}`}>
      <div className="flex flex-row text-body items-center gap-1 py-1.5 px-1 h-8 w-44 envelope-list-tags bg-white border border-litlingo-gray-2 rounded overflow-x">
        <div className="flex items-center w-5 h-full">{SEARCH_ICON()}</div>
        <div className="flex h-full w-4/5">
          <ReactTags
            allowNew
            // placeholderText is not present on the types library but it is on the library docs
            // @ts-ignore
            placeholderText="Add tag"
            suggestions={suggestions}
            onAddition={handleAddition}
            onDelete={(): Record<string, never> => ({})}
            autofocus
            maxSuggestionsLength={25}
            minQueryLength={0}
          />
        </div>

        {(tagsLoading || addLoading || removeLoading) && (
          <div className="flex flex-row">
            <div className=" ml-3 loader ease-linear rounded-full border-2 border-gray-200 h-4 w-4 self-center" />
          </div>
        )}
      </div>
      {renderLabels && <div className="flex flex-row gap-1">{renderTags()}</div>}
    </div>
  );
};

export default TestCaseTags;
