/* eslint-disable @typescript-eslint/ban-types */
import LinkLookup from 'components/LinkLookup';
import Permissions from 'components/Permissions';
import React, { ReactHTML } from 'react';

type ComponentProps = {
  children: JSX.Element | JSX.Element[];
  className?: string;
  action: string;
  routeName: string;
  routeParams?: {};
  queryParams?: string | {};
  as?: keyof ReactHTML;
};

const PermissionsRowLink: React.FC<ComponentProps> = (props) => {
  const { children, className, action, routeName, routeParams, queryParams, as } = props;

  const renderLink = (authorized: boolean): string | JSX.Element | JSX.Element[] =>
    authorized ? (
      <LinkLookup
        className={`table-row ${className || ''}`}
        routeName={routeName}
        routeParams={routeParams || {}}
        queryParams={queryParams || {}}
        as={as}
      >
        {children}
      </LinkLookup>
    ) : (
      <tr style={{ cursor: 'revert' }}>{children}</tr>
    );

  return <Permissions action={action}>{renderLink}</Permissions>;
};

export default PermissionsRowLink;
