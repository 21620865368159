import React from 'react';

interface ButtonProps {
  clickHandler: () => void;
  disabled: boolean;
  pointToLeft?: boolean;
}

const ChevronButton: React.VFC<ButtonProps> = ({
  clickHandler,
  disabled,
  pointToLeft,
}) => {
  const strokeColor = disabled ? '#D6D6D6' : '#5C5C5C';
  return (
    <button
      type="button"
      disabled={disabled}
      className={`bg-white rounded w-5 h-5 flex items-center justify-center outline-none border-none ${
        !disabled && 'cursor-pointer hover:opacity-50'
      }`}
      onClick={clickHandler}
    >
      {pointToLeft ? (
        <svg
          width="9"
          height="14"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 12.8334L1.66667 7.00004L7.5 1.16671"
            stroke={strokeColor}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="9"
          height="14"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 1.16663L7.33333 6.99996L1.5 12.8333"
            stroke={strokeColor}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </button>
  );
};

export default ChevronButton;
