import { UUID } from '@litlingo/client';
import { ActionCreator, createAction } from '@reduxjs/toolkit';
import { resourceToHumanLabel } from 'constants/resourceQueryNames';

const prepAlert = (msg: string, timeout = 4000): { payload: { msg: string; timeout: number } } => ({
  payload: { msg, timeout },
});
const prepUndeleteAlert = (
  resource: string,
  id: UUID,
  timeout = 4000
): { payload: { resource: string; timeout: number; id: string } } => ({
  payload: { resource, id, timeout },
});

export const showSuccessAlert = createAction('ALERTS/SHOW_SUCCESS', prepAlert);
export const showErrorAlert = createAction('ALERTS/SHOW_ERROR', prepAlert);
export const showQueryErrorAlert = createAction('ALERTS/SHOW_QUERY_ERROR', prepAlert);
export const showUndeleteAlert = createAction('ALERTS/SHOW_UNDELETE', prepUndeleteAlert);
export const showActionAlert = createAction<{
  id: UUID;
  msg: string;
  actionMsg: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionPayload: { [key: string]: any };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: ActionCreator<any>;
  timeout?: number;
}>('ALERTS/SHOW_ACTION');
export const clearAlert = createAction('ALERTS/CLEAR');

export const prepareUndeleteAlert = createAction<{
  resource: keyof typeof resourceToHumanLabel;
  id: UUID;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchAction?: ActionCreator<any>;
  fetchActionArgs?: { [key: string]: string };
  timeout?: number;
}>('ALERTS/PREPARE_UNDELETE');

export const undeleteResource = createAction<{
  resource: keyof typeof resourceToHumanLabel;
  id: UUID;
}>('ALERTS/UNDELETE_RESOURCE');

export const undeleteResourceSuccess = createAction('ALERTS/UNDELETE_RESOURCE_SUCCESS');

export const undeleteResourceFailure = createAction('ALERTS/UNDELETE_RESOURCE_FAILURE');
