/* eslint-disable camelcase */

import { prepareUndeleteAlert, showErrorAlert, showSuccessAlert } from 'actions/alerts';
import {
  addBulkTestCaseTags,
  addBulkTestCaseTagsFailure,
  addBulkTestCaseTagsRequest,
  addBulkTestCaseTagsSuccess,
  addTestCaseTag,
  addTestCaseTagFailure,
  addTestCaseTagRequest,
  addTestCaseTagSuccess,
  addTestCaseToBranch,
  addTestCaseToBranchFailure,
  addTestCaseToBranchRequest,
  addTestCaseToBranchSuccess,
  addToLastTestCases,
  bulkDeleteTestCases,
  bulkDeleteTestCasesFailure,
  bulkDeleteTestCasesFulfill,
  bulkDeleteTestCasesSuccess,
  bulkDuplicateTestCases,
  bulkDuplicateTestCasesFailure,
  bulkDuplicateTestCasesSuccess,
  createTestCase,
  createTestCaseFailure,
  createTestCaseRequest,
  createTestCaseSuccess,
  deleteTest,
  deleteTestCaseFailure,
  deleteTestCaseRequest,
  deleteTestCaseSuccess,
  evaluateAsync,
  evaluateAsyncFailure,
  evaluateAsyncRequest,
  evaluateAsyncResults,
  evaluateAsyncResultsFailure,
  evaluateAsyncResultsRequest,
  evaluateAsyncResultsSuccess,
  evaluateAsyncSuccess,
  exportTestCases,
  exportTestCasesFailure,
  exportTestCasesFulfill,
  exportTestCasesSuccess,
  fetchAllTestCases,
  fetchSingleTestCase,
  fetchSingleTestCaseFailure,
  fetchSingleTestCaseRequest,
  fetchSingleTestCaseSuccess,
  fetchTestCaseSummary,
  fetchTestCaseSummaryFailure,
  fetchTestCaseSummaryRequest,
  fetchTestCaseSummarySuccess,
  fetchTestCasesFailure,
  fetchTestCasesRequest,
  fetchTestCasesSuccess,
  fetchTestResults,
  fetchTestResultsFailure,
  fetchTestResultsRequest,
  fetchTestResultsSuccess,
  importTestCases,
  importTestCasesFailure,
  importTestCasesFulfill,
  importTestCasesSuccess,
  removeTestCaseFromBranch,
  removeTestCaseFromBranchFailure,
  removeTestCaseFromBranchSuccess,
  removeTestCaseTag,
  removeTestCaseTagFailure,
  removeTestCaseTagRequest,
  removeTestCaseTagSuccess,
  saveTestCase,
  saveTestCaseFailure,
  saveTestCaseRequest,
  saveTestCaseSuccess,
  updateTestCasesMatch,
  updateTestCasesMatchFailure,
  updateTestCasesMatchRequest,
  updateTestCasesMatchSuccess,
  upsertTestCase,
  upsertTestCaseAndAddToBranch,
  upsertTestCaseFailure,
  upsertTestCaseRequest,
  upsertTestCaseSuccess,
} from 'actions/testCases';
import { apiClient as LitLingoClient } from 'client';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { getNavParams, getNavParamsByResource } from 'selectors/nav';
import { getTestCase, getTestCases } from 'selectors/testCases';
import type { API, SagaReturn } from 'types';
import triggerDownload from 'utils/triggerDownload';

function* fetchSingleTestCaseSaga({ payload }: ReturnType<typeof fetchSingleTestCase>): SagaReturn {
  yield put(fetchSingleTestCaseRequest());
  const navParams = (yield select(getNavParams)) as ReturnType<typeof getNavParams>;

  const response = (yield call(
    [LitLingoClient.resources.testCases, 'retrieve'],
    payload.testCaseId,
    {
      params: {
        ...navParams,
        relationships: ['campaign', 'rule', 'annotator', 'created_by', 'tags', 'tags.tag_value'],
      },
    }
  )) as API.Response<API.Tests.Retrieve>;
  if (response.error != null) {
    yield put(fetchSingleTestCaseFailure(response.error));
  } else {
    yield put(fetchSingleTestCaseSuccess(response.data));
  }
}

function* fetchTestCasesListSaga({ payload }: ReturnType<typeof fetchAllTestCases>): SagaReturn {
  yield put(fetchTestCasesRequest());
  const navParams = (yield select(getNavParams)) as ReturnType<typeof getNavParams>;
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.testCase)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = {
    ...navParams,
    ...resourceParams,
    ...payload,
    include_pii: true,
  };

  const response = (yield call([LitLingoClient.resources.testCases, 'list'], {
    params: {
      ...params,
      relationships: ['campaign', 'rule', 'annotator', 'created_by', 'tags', 'tags.tag_value'],
    },
  })) as API.Response<API.Tests.List>;
  if (response.error != null) {
    yield put(fetchTestCasesFailure(response.error));
  } else {
    yield put(fetchTestCasesSuccess(response.data));
  }
}

function* evaluateAsyncTestsSaga({ payload }: ReturnType<typeof evaluateAsync>): SagaReturn {
  yield put(evaluateAsyncRequest());
  const response = (yield call([LitLingoClient.resources.testCases.extras, 'evaluateAsync'], {
    params: payload,
  })) as API.Response<API.Tests.EvaluateAsync>;

  const testCases = (yield select(getTestCases)) as ReturnType<typeof getTestCases>;

  if (response.error != null) {
    yield put(evaluateAsyncFailure(response.error));
    return;
  }

  // After finish evaluate, update pie chart and filters data
  yield put(fetchTestCaseSummary(payload));
  if (testCases.length < 5 && 'results' in response.data) {
    yield put(evaluateAsyncResultsSuccess(response.data));
    yield put(showSuccessAlert('Tests evaluated successfully'));
  } else {
    // TODO: Change this when the results_key is returned back from the backend
    yield put(evaluateAsyncSuccess());
    yield put(
      showSuccessAlert('Tests sent to evaluation, please refresh the page to see the results')
    );
  }
}

function* evaluateAsyncResultsTestsSaga({
  payload,
}: ReturnType<typeof evaluateAsyncResults>): SagaReturn {
  yield put(evaluateAsyncResultsRequest());
  const response = (yield call(
    [LitLingoClient.resources.testCases.extras, 'evaluateAsyncResults'],
    { urlParams: { resultKey: payload } }
  )) as API.Response<API.Tests.EvaluateAsyncResults>;
  if (response.error != null) {
    yield put(evaluateAsyncResultsFailure(response.error));
  } else {
    yield put(evaluateAsyncResultsSuccess(response.data));
  }
}

function* upsertTestCaseSaga({ payload }: ReturnType<typeof upsertTestCase>): SagaReturn {
  yield put(upsertTestCaseRequest());

  const response = (yield call([LitLingoClient.resources.testCases, 'upsert'], {
    data: payload,
    params: {
      relationships: ['campaign', 'rule', 'annotator', 'created_by', 'tags', 'tags.tag_value'],
    },
  })) as API.Response<API.Tests.Upsert>;
  if (response.error != null) {
    yield put(upsertTestCaseFailure(response.error));
  } else {
    yield put(upsertTestCaseSuccess(response.data));
    yield put(showSuccessAlert('Tests Saved'));
  }
}

function* fetchTestCasesSummarySaga({
  payload,
}: ReturnType<typeof fetchTestCaseSummary>): SagaReturn {
  yield put(fetchTestCaseSummaryRequest());

  const response = (yield call([LitLingoClient.resources.testCases.extras, 'summary'], {
    params: payload,
  })) as API.Response<API.Tests.Summary>;
  if (response.error != null) {
    yield put(fetchTestCaseSummaryFailure(response.error));
  } else {
    yield put(fetchTestCaseSummarySuccess(response.data));
  }
}

function* fetchTestResultsSaga(action: ReturnType<typeof fetchTestResults>): SagaReturn {
  const { payload } = action;
  const params = {
    ...payload,
    order_by: 'created_at',
    order_desc: true,
  };
  yield put(fetchTestResultsRequest());
  const response = (yield call([LitLingoClient.resources.testCases.extras, 'results'], {
    params,
  })) as API.Response<API.Tests.Results>;

  if (response.error != null) {
    yield put(fetchTestResultsFailure(response.error));
  } else {
    yield put(fetchTestResultsSuccess({ uuid: payload.test_case_uuids, data: response.data }));
  }
}

function* deleteTestSaga(action: ReturnType<typeof deleteTest>): SagaReturn {
  const { payload } = action;
  yield put(deleteTestCaseRequest());
  const response = (yield call(
    [LitLingoClient.resources.testCases, 'delete'],
    payload.id
  )) as API.Response<API.Tests.Delete>;
  if (response.error != null) {
    yield put(deleteTestCaseFailure(response.error));
  } else {
    yield put(deleteTestCaseSuccess(payload.id));
    yield put(
      prepareUndeleteAlert({
        resource: 'testCases',
        id: payload.id,
        fetchAction: fetchAllTestCases,
        ...(payload.entity != null
          ? { fetchActionArgs: { [payload.entity.name]: payload.entity.value } }
          : {}),
      })
    );
  }
}

function* saveTestCaseSaga(action: ReturnType<typeof saveTestCase>): SagaReturn {
  const { payload } = action;
  const body = {
    ...payload,
    platform: 'all',
  };
  yield put(saveTestCaseRequest());
  const response = (yield call([LitLingoClient.resources.testCases, 'upsert'], {
    data: body,
    params: { relationships: ['created_by'] },
  })) as API.Response<API.Tests.Upsert>;
  if (response.error != null) {
    yield put(saveTestCaseFailure(response.error));
  } else {
    yield put(saveTestCaseSuccess(response.data));
    if (payload.branch_uuid != null) {
      yield put(
        addTestCaseToBranch({ testCaseIds: [response.data.uuid], branchId: payload.branch_uuid })
      );
    }
    yield put(showSuccessAlert('Tests Saved'));
    if (payload.refetch) {
      const param: Record<string, string> = { rule_uuids: payload.rule_uuid ?? '' };
      if (payload.branch_uuid != null) param.branch_uuid = payload.branch_uuid;
      yield put(fetchAllTestCases(param));
      yield put(fetchTestCaseSummary(param));
    }
  }
}

function* updateTestCasesMatchSaga(action: ReturnType<typeof updateTestCasesMatch>): SagaReturn {
  const { payload } = action;
  yield put(updateTestCasesMatchRequest());
  const response = (yield call([LitLingoClient.resources.testCases.extras, 'bulkMatch'], {
    data: { tests: payload.ids.map((id) => ({ uuid: id, should_match: payload.shouldMatch })) },
    params: { relationships: ['created_by'] },
  })) as API.Response<API.Tests.Upsert[]>;
  if (response.error != null) {
    yield put(updateTestCasesMatchFailure(response.error));
  } else {
    yield put(updateTestCasesMatchSuccess(response.data));
    yield put(showSuccessAlert('Tests Saved'));
  }
}

function* addTestCaseToBranchSaga(action: ReturnType<typeof addTestCaseToBranch>): SagaReturn {
  const { payload } = action;
  yield put(addTestCaseToBranchRequest());
  const response = (yield all(
    payload.testCaseIds.map((id) =>
      call([LitLingoClient.resources.testCases.extras, 'addToBranch'], {
        urlParams: { branchId: payload.branchId, testCaseId: id },
      })
    )
  )) as API.Response<API.Tests.Upsert>[];
  const errors = response.some((item) => item.error != null);
  if (errors) {
    yield put(addTestCaseToBranchFailure({ message: 'Error adding test case to branch' }));
  } else {
    const data = response.map((item) => item.data);
    const testCases = data.filter((item) => item != null) as API.Tests.Upsert[];
    yield put(addTestCaseToBranchSuccess(testCases));
    yield put(showSuccessAlert('Test Case Added to Branch'));
    if (payload.refetch) {
      const param: Record<string, string> = {
        rule_uuids: payload.ruleId ?? '',
        branch_uuid: payload.branchId,
      };
      yield put(fetchAllTestCases(param));
      yield put(fetchTestCaseSummary(param));
    }
  }
}

function* removeTestCaseFromBranchSaga(
  action: ReturnType<typeof removeTestCaseFromBranch>
): SagaReturn {
  const { payload } = action;
  const response = (yield all(
    payload.testCaseIds.map((id) =>
      call([LitLingoClient.resources.testCases.extras, 'removeFromBranch'], {
        urlParams: { branchId: payload.branchId, testCaseId: id },
      })
    )
  )) as API.Response<API.Tests.Upsert>[];
  const errors = response.some((item) => item.error != null);
  if (errors) {
    yield put(removeTestCaseFromBranchFailure({ message: 'Error removing test case from branch' }));
  } else {
    const data = response.map((item) => item.data);
    const testCases = data.filter((item) => item != null) as API.Tests.Upsert[];
    yield put(removeTestCaseFromBranchSuccess(testCases));
    yield put(showSuccessAlert('Test Case Removed from Branch'));
    if (payload.refetch) {
      const param: Record<string, string> = {
        rule_uuids: payload.ruleId ?? '',
        branch_uuid: payload.branchId,
      };
      yield put(fetchAllTestCases(param));
      yield put(fetchTestCaseSummary(param));
    }
  }
}

function* bulkDuplicateTestCasesSaga(
  action: ReturnType<typeof bulkDuplicateTestCases>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): SagaReturn<void, any> {
  const {
    payload: { uuids },
  } = action;

  let actionResult;

  for (let i = 0; i < uuids.length; i += 1) {
    const uuid = uuids[i];
    const testCase = yield select((state) => getTestCase(state, uuid) || {});
    const duplicatedData = {
      test_string: testCase.test_string,
      should_match: testCase.should_match,
      platform: testCase.platform,
      annotator_uuid: testCase.annotator_uuid,
      rule_uuid: testCase.rule_uuid,
      campaign_uuid: testCase.campaign_uuid,
    };
    yield put(saveTestCase(duplicatedData));
    actionResult = yield take([saveTestCaseSuccess.toString(), saveTestCaseFailure.toString()]);
  }

  if (actionResult.type === saveTestCaseSuccess.type) {
    yield put(bulkDuplicateTestCasesSuccess());
  } else {
    yield put(bulkDuplicateTestCasesFailure(actionResult.payload));
  }
}

function* exportTestCasesSaga(action: ReturnType<typeof exportTestCases>): SagaReturn {
  const { payload } = action;
  const response = (yield call([LitLingoClient.resources.testCases.extras, 'export'], {
    params: payload,
    responseType: 'blob',
  })) as API.Response<API.Tests.Export>;

  if (response.error != null) {
    yield put(exportTestCasesFailure(response.error));
  } else {
    triggerDownload(response.data as Blob, 'test_cases');

    yield put(exportTestCasesSuccess());
    yield put(showSuccessAlert('Successfully exported test cases'));
  }

  yield put(exportTestCasesFulfill());
}

function* importTestCasesSaga(action: ReturnType<typeof importTestCases>): SagaReturn {
  const { file, entity, entityId } = action.payload;

  const formData = new FormData();
  formData.append('file', file);

  let response: API.Response<API.Tests.Import>;
  if (entity != null && entityId != null) {
    response = (yield call([LitLingoClient.resources.testCases.extras, 'importEntity'], {
      urlParams: { entity, entityId },
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })) as API.Response<API.Tests.Import>;
  } else {
    response = (yield call([LitLingoClient.resources.testCases.extras, 'import'], {
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })) as API.Response<API.Tests.Import>;
  }

  if (response.error != null) {
    yield put(importTestCasesFailure(response.error));
  } else {
    yield put(importTestCasesSuccess());
    yield put(showSuccessAlert('Successfully imported test cases'));
  }

  yield put(importTestCasesFulfill());
}

function* bulkDeleteTestCasesSaga(action: ReturnType<typeof bulkDeleteTestCases>): SagaReturn {
  const { payload } = action;

  const response = (yield call([LitLingoClient.resources.testCases.extras, 'bulkDelete'], {
    data: { uuids: payload.uuids },
  })) as API.Response<API.Tests.BulkDelete>;
  if (response.error != null) {
    yield put(bulkDeleteTestCasesFailure(response.error));
  } else {
    yield put(bulkDeleteTestCasesSuccess(payload));
    yield put(showSuccessAlert('Successfully deleted test cases'));
  }

  yield put(bulkDeleteTestCasesFulfill());
}

function* addTestCaseTagSaga(action: ReturnType<typeof addTestCaseTag>): SagaReturn {
  const { testCaseId, tag } = action.payload;

  yield put(addTestCaseTagRequest());
  const response = (yield call([LitLingoClient.resources.testCases.extras, 'addTag'], {
    urlParams: { testCaseId },
    data: { value: tag },
  })) as API.Response<API.Tests.AddTag>;
  if (response.error != null) {
    yield put(addTestCaseTagFailure(response.error));
  } else {
    yield put(addTestCaseTagSuccess(response.data));
  }
}

function* removeTestCaseTagSaga(action: ReturnType<typeof removeTestCaseTag>): SagaReturn {
  const { testCaseId, tag } = action.payload;

  yield put(removeTestCaseTagRequest());

  const response = (yield call([LitLingoClient.resources.testCases.extras, 'removeTag'], {
    urlParams: { testCaseId },
    data: { value: tag },
  })) as API.Response<API.Tests.RemoveTag>;
  if (response.error != null) {
    yield put(removeTestCaseTagFailure(response.error));
  } else {
    yield put(removeTestCaseTagSuccess(response.data));
  }
}

function* addBulkTestCaseTagsSaga(action: ReturnType<typeof addBulkTestCaseTags>): SagaReturn {
  const { payload } = action;
  yield put(addBulkTestCaseTagsRequest());

  const response = (yield call([LitLingoClient.resources.testCases.extras, 'bulkAddTags'], {
    params: { relationships: ['tags.tag_value', 'created_by'], include_pii: 'true' },
    data: { uuids: payload.uuids, value: payload.value },
  })) as API.Response<API.Tests.RemoveTag>;
  if (response.error != null) {
    yield put(addBulkTestCaseTagsFailure(response.error));
    yield put(showErrorAlert('Error while adding tags'));
  } else if (!Array.isArray(response.data)) {
    yield put(
      addBulkTestCaseTagsFailure({
        message: 'Error while adding tags',
      })
    );
    yield put(showErrorAlert('Error while adding tags'));
  } else {
    yield put(addBulkTestCaseTagsSuccess(response.data));
    yield put(showSuccessAlert('Tags added successfuly'));
  }
}

function* handleUpsertTestCaseAndAddToBranch({
  payload,
}: ReturnType<typeof upsertTestCaseAndAddToBranch>): SagaReturn {
  const { test, branchId, ruleId, refetch } = payload;

  const { annotator_uuid, campaign_uuid, comment, platform, should_match, test_string } = test;

  yield put(
    upsertTestCase({
      annotator_uuid,
      campaign_uuid,
      comment,
      platform,
      should_match,
      test_string,
      rule_uuid: ruleId,
    })
  );
  const { payload: testCase } = (yield take(upsertTestCaseSuccess)) as ReturnType<
    typeof upsertTestCaseSuccess
  >;

  if (branchId) {
    yield put(addTestCaseToBranch({ testCaseIds: [testCase.uuid], branchId, ruleId, refetch }));
  } else {
    yield put(addToLastTestCases([testCase.uuid]));
  }
}

function* createTestCaseSaga(action: ReturnType<typeof createTestCase>): SagaReturn {
  const { prompt } = action.payload;

  yield put(createTestCaseRequest());

  const response = (yield call([LitLingoClient.resources.testCases.extras, 'generate'], {
    data: { prompt },
  })) as API.Response<API.Tests.GenerateTestCase>;
  if (response.error != null) {
    yield put(createTestCaseFailure(response.error));
  } else {
    yield put(createTestCaseSuccess(response.data));
  }
}

function* testCasesSaga(): SagaReturn {
  yield takeLatest(fetchSingleTestCase.toString(), fetchSingleTestCaseSaga);
  yield takeLatest(fetchAllTestCases.toString(), fetchTestCasesListSaga);
  yield takeLatest(evaluateAsync.toString(), evaluateAsyncTestsSaga);
  yield takeLatest(evaluateAsyncResults.toString(), evaluateAsyncResultsTestsSaga);
  yield takeLatest(upsertTestCase.toString(), upsertTestCaseSaga);
  yield takeLatest(fetchTestCaseSummary.toString(), fetchTestCasesSummarySaga);
  yield takeLatest(fetchTestResults.toString(), fetchTestResultsSaga);
  yield takeLatest(deleteTest.toString(), deleteTestSaga);
  yield takeLatest(saveTestCase.toString(), saveTestCaseSaga);
  yield takeLatest(bulkDuplicateTestCases.toString(), bulkDuplicateTestCasesSaga);
  yield takeLatest(exportTestCases.toString(), exportTestCasesSaga);
  yield takeLatest(importTestCases.toString(), importTestCasesSaga);
  yield takeLatest(bulkDeleteTestCases.toString(), bulkDeleteTestCasesSaga);
  yield takeLatest(addTestCaseTag.toString(), addTestCaseTagSaga);
  yield takeLatest(removeTestCaseTag.toString(), removeTestCaseTagSaga);
  yield takeLatest(addBulkTestCaseTags.toString(), addBulkTestCaseTagsSaga);
  yield takeLatest(updateTestCasesMatch.toString(), updateTestCasesMatchSaga);
  yield takeLatest(addTestCaseToBranch.toString(), addTestCaseToBranchSaga);
  yield takeLatest(removeTestCaseFromBranch.toString(), removeTestCaseFromBranchSaga);
  yield takeLatest(upsertTestCaseAndAddToBranch.toString(), handleUpsertTestCaseAndAddToBranch);
  yield takeLatest(createTestCase.toString(), createTestCaseSaga);
}

export default testCasesSaga;
