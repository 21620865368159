import { Team } from '@litlingo/client';
import useRequestMutation from 'hooks/client/useMutation';

import { MutationResponse } from 'hooks/types/client';

type UpsertTeamParams = Partial<Team> & { uuid?: Team['uuid'] };

type UseUpsertTeam = () => Omit<MutationResponse<'teams.upsert'>, 'mutate'> & {
  mutate: (params: UpsertTeamParams) => void;
};

const useUpsertTeam: UseUpsertTeam = () => {
  const { mutate, ...rest } = useRequestMutation('teams.upsert', {
    invalidate: [['teams.list']],
    successAlert: 'Saved division',
  });

  return {
    ...rest,
    mutate: (params) => mutate({ data: params }),
  };
};

export default useUpsertTeam;
