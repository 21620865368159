/* eslint-disable max-lines */
import * as Sentry from '@sentry/react';
import {
  addSelectedField,
  changeConditionalType,
  clearSelectedFields,
  clearSelectedFilters,
  openMessagesModal,
  openSubsamplesModal,
  removeFieldFromTree,
  removeSelectedField,
  removeValueFromTree,
  setURLParams,
} from 'actions';

import { openExploreMessagesModal, openExploreSubsamplesModal } from 'actions/envelopeListView';
import { TAG_REMOVE_ICON } from 'constants/filterIcons';
import { andFilters, filterKeysMapping } from 'constants/filtersMappingValues';
import pluralize from 'pluralize';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getSelectedFields } from 'selectors/envelopes';
import { useSelector } from 'store';
import { EnvelopeFilterKeys } from 'types/filters';
import { Condition, DataNode, lookAllForField, Tree, ValueNode } from 'utils/parserTree';
import { v4 } from 'uuid';
import AndOrBadge from './AndOrBadge';
import FilterBadge from './FilterBadge';

type ComponentProps = {
  tree: Tree | DataNode | ValueNode;
  scrolled?: boolean;
  showAllFilters?: boolean;
  filtersModified?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  canChangeConditional?: boolean;
  allowDelete?: boolean;
  removeAllFilter: () => void;
  removeFilter: (key: string, id: string) => void;
  field?: string;
  envelopesPage?: boolean;
  renderSampleButton?: boolean;
};

const FilterPillsTree: React.FC<ComponentProps> = (props) => {
  const {
    tree,
    allowDelete = true,
    scrolled,
    filtersModified,
    showAllFilters,
    removeAllFilter,
    removeFilter,
    field,
    envelopesPage,
    canChangeConditional,
    renderSampleButton,
  } = props;
  const dispatch = useDispatch();
  if ('value' in tree) {
    return (
      <FilterBadge
        key={tree.id}
        title={tree.label ?? tree.value}
        node={tree as ValueNode}
        disabled={!envelopesPage}
        deleteAction={
          allowDelete && field
            ? (): void => {
                dispatch(
                  removeValueFromTree({
                    value: tree.value,
                    field,
                  })
                );
                removeFilter(field, tree.value);
              }
            : undefined
        }
      />
    );
  }
  if (tree.op === 'and' || tree.op === 'or') {
    return (
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      <ConditionField
        tree={tree as DataNode}
        scrolled={scrolled}
        showAllFilters={showAllFilters}
        filtersModified={filtersModified}
        removeAllFilter={removeAllFilter}
        removeFilter={removeFilter}
        field={field}
        envelopesPage={envelopesPage}
        allowDelete={allowDelete}
        key={tree.id}
        canChangeConditional={canChangeConditional}
        renderSampleButton={renderSampleButton}
      />
    );
  }
  if (tree.op === 'field' && 'field' in tree) {
    return (
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      <Field
        tree={tree as DataNode}
        scrolled={scrolled}
        showAllFilters={showAllFilters}
        filtersModified={filtersModified}
        removeAllFilter={removeAllFilter}
        removeFilter={removeFilter}
        field={field}
        envelopesPage={envelopesPage}
        allowDelete={allowDelete}
        key={tree.id}
        canChangeConditional={canChangeConditional}
        renderSampleButton={renderSampleButton}
      />
    );
  }

  if (tree.op === 'group') {
    return (
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      <Group
        tree={tree as DataNode}
        scrolled={scrolled}
        showAllFilters={showAllFilters}
        filtersModified={filtersModified}
        removeAllFilter={removeAllFilter}
        removeFilter={removeFilter}
        field={field}
        envelopesPage={envelopesPage}
        allowDelete={allowDelete}
        key={tree.id}
        canChangeConditional={canChangeConditional}
        renderSampleButton={renderSampleButton}
      />
    );
  }

  return <></>;
};

function Group({
  tree,
  removeAllFilter,
  showAllFilters,
  filtersModified,
  scrolled,
  removeFilter,
  field,
  envelopesPage,
  allowDelete,
  canChangeConditional,
  renderSampleButton,
}: ComponentProps): JSX.Element {
  return (
    <div className="flex flex-row items-center gap-1" key={tree.id}>
      <span>(</span>
      {(tree as DataNode).data.map((f) => (
        <FilterPillsTree
          tree={f}
          key={f.id}
          removeAllFilter={removeAllFilter}
          showAllFilters={showAllFilters}
          filtersModified={filtersModified}
          scrolled={scrolled}
          removeFilter={removeFilter}
          envelopesPage={envelopesPage}
          field={field}
          allowDelete={allowDelete}
          canChangeConditional={canChangeConditional}
          renderSampleButton={renderSampleButton}
        />
      ))}
      <span>)</span>
    </div>
  );
}

function Field({
  tree,
  scrolled,
  showAllFilters,
  filtersModified,
  removeAllFilter,
  envelopesPage,
  removeFilter,
  allowDelete,
  renderSampleButton,
}: ComponentProps): JSX.Element {
  const dispatch = useDispatch();

  const selectedFields = useSelector(getSelectedFields);

  const handleClick = (): void => {
    if (!tree) return;
    if (selectedFields.some((field) => field.id === tree.id)) {
      dispatch(removeSelectedField({ node: tree as DataNode }));
      return;
    }
    dispatch(addSelectedField({ node: tree as DataNode }));
  };

  if (!('field' in tree)) return <></>;

  if (!filterKeysMapping[tree.field as EnvelopeFilterKeys]) {
    Sentry.captureMessage('Undefined field in filter pills', {
      extra: { field: tree.field, data: tree.data },
    });

    return <></>;
  }

  const renderFieldContent = (): JSX.Element => {
    if (tree.field === 'broad_search' && tree.raw) {
      return (
        <FilterBadge
          key={tree.id}
          title={'value' in tree.data[0] ? tree.data[0].value : tree.data[0].raw || ''}
          node={
            {
              op: 'word',
              value: 'value' in tree.data[0] ? tree.data[0].value : tree.data[0].raw,
              label: 'value' in tree.data[0] ? tree.data[0].value : tree.data[0].raw,
              id: v4(),
              parent: tree.id,
            } as ValueNode
          }
          disabled={!envelopesPage}
          deleteAction={
            allowDelete && tree.field
              ? (): void => {
                  dispatch(
                    removeFieldFromTree({
                      field: tree.field || '',
                    })
                  );
                  removeFilter(tree.field || '', tree.raw?.replace('~broad_search', '') || '');
                }
              : undefined
          }
        />
      );
    }

    if (tree.field === 'subject' && tree.raw) {
      return (
        <FilterBadge
          key={tree.id}
          title={tree.raw.replace('~subject', '')}
          node={
            {
              op: 'word',
              value: tree.raw.replace('~subject', ''),
              label: tree.raw.replace('~subject', ''),
              id: v4(),
              parent: tree.id,
            } as ValueNode
          }
          disabled={!envelopesPage}
          deleteAction={
            allowDelete && tree.field
              ? (): void => {
                  dispatch(
                    removeValueFromTree({
                      value: tree.raw?.replace('~subject', '') || '',
                      field: tree.field,
                    })
                  );
                  removeFilter(tree.field || '', tree.raw?.replace('~subject', '') || '');
                }
              : undefined
          }
        />
      );
    }

    if (tree.field === 'sample_uuids' && tree.raw) {
      const values = lookAllForField(tree, 'sample_uuids');

      if (values && values.length > 0 && renderSampleButton) {
        return (
          <div className="flex flex-row gap-1">
            <FilterBadge
              key={tree.id}
              title={`${values.length} ${pluralize('Sample', values.length)}`}
              node={
                {
                  op: 'word',
                  value: 'Sample',
                  label: 'Sample',
                  id: v4(),
                  parent: tree.id,
                } as ValueNode
              }
              disabled={!envelopesPage}
              deleteAction={
                allowDelete && tree.field
                  ? (): void => {
                      dispatch(
                        removeValueFromTree({
                          value: tree.raw?.replace('~sample_uuids', '') || '',
                          field: tree.field,
                        })
                      );
                      removeFilter(tree.field || '', tree.raw?.replace('~sample_uuids', '') || '');
                    }
                  : undefined
              }
            />

            <button
              type="button"
              className="button button--secondary h-5 px-2 py-0"
              onClick={(e): void => {
                e.stopPropagation();
                if (envelopesPage) {
                  dispatch(openExploreSubsamplesModal());
                } else {
                  dispatch(openSubsamplesModal());
                }
              }}
            >
              <span className="text-small">View Samples</span>
            </button>
          </div>
        );
      }
    }

    if (tree.field === 'uuid' && tree.raw) {
      const values = lookAllForField(tree, 'uuid');

      if (values && values.length > 0 && renderSampleButton) {
        return (
          <div className="flex flex-row gap-1">
            <FilterBadge
              key={tree.id}
              title={`${values.length} ${pluralize('Message', values.length)}`}
              node={
                {
                  op: 'word',
                  value: 'Messages',
                  label: 'Messages',
                  id: v4(),
                  parent: tree.id,
                } as ValueNode
              }
              disabled={!envelopesPage}
              deleteAction={
                allowDelete && tree.field
                  ? (): void => {
                      dispatch(
                        removeValueFromTree({
                          value: tree.raw?.replace('~uuid', '') || '',
                          field: tree.field,
                        })
                      );
                      removeFilter(tree.field || '', tree.raw?.replace('~uuid', '') || '');
                    }
                  : undefined
              }
            />

            <button
              type="button"
              className="button button--secondary h-5 px-2 py-0"
              onClick={(e): void => {
                e.stopPropagation();
                if (envelopesPage) {
                  dispatch(openExploreMessagesModal());
                } else {
                  dispatch(openMessagesModal());
                }
              }}
            >
              <span className="text-small">View Messages</span>
            </button>
          </div>
        );
      }
    }

    return (
      <>
        {tree.data.map((f) => (
          <FilterPillsTree
            tree={f}
            key={f.id}
            removeAllFilter={removeAllFilter}
            showAllFilters={showAllFilters}
            scrolled={scrolled}
            filtersModified={filtersModified}
            removeFilter={removeFilter}
            field={tree.field as string}
            allowDelete={allowDelete && tree.field !== 'date_range'}
            envelopesPage={envelopesPage}
            canChangeConditional={andFilters.includes(`${tree.field as string}_and`)}
          />
        ))}
      </>
    );
  };

  return (
    <button
      className={`relative justify-end text-body grid gap-x-2 rounded px-1 py-0.5 row_field--filter focus:outline-none ${
        selectedFields.find((field) => field.id === tree.id)
          ? 'bg-litlingo-gray-2'
          : 'bg-litlingo-gray-1'
      }`}
      type="button"
      style={{ gridTemplateColumns: 'auto 1fr' }}
      key={tree.id}
      onClick={handleClick}
    >
      {`${filterKeysMapping[tree.field as EnvelopeFilterKeys]} =`}
      <div
        className={`flex flex-row flex-grow-0  gap-0.5 ${
          !scrolled ? 'flex-wrap' : 'flex-no-wrap'
        } } `}
        style={{ flexWrap: showAllFilters || filtersModified ? 'wrap' : undefined }}
      >
        {renderFieldContent()}
      </div>
      {allowDelete && (
        <button
          type="button"
          className="absolute -top-1 -left-1.5 focus:outline-none cursor-pointer z-100 row_field--filter__delete opacity-0"
          onClick={(e): void => {
            e.stopPropagation();
            if (allowDelete) {
              if (tree.field === 'date_range') {
                dispatch(
                  setURLParams({
                    envelopes__created_after: '',
                    envelopes__created_before: '',
                  })
                );
              }
              dispatch(
                removeFieldFromTree({
                  field: tree.field as string,
                })
              );
              removeAllFilter();
            }
          }}
        >
          <span>{TAG_REMOVE_ICON}</span>
        </button>
      )}
    </button>
  );
}

function ConditionField({
  tree,
  scrolled,
  showAllFilters,
  filtersModified,
  removeAllFilter,
  removeFilter,
  field,
  envelopesPage,
  allowDelete,
  canChangeConditional,
  renderSampleButton,
}: ComponentProps): JSX.Element {
  const dispatch = useDispatch();

  const handleChangeConditional = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    if (!envelopesPage) return;
    if (!canChangeConditional && field) return;
    dispatch(
      changeConditionalType({
        node: tree as DataNode,
        condition: tree.op === Condition.AND ? Condition.OR : Condition.AND,
      })
    );
    dispatch(clearSelectedFields());
    dispatch(clearSelectedFilters());
  };

  return (
    <React.Fragment key={tree.id}>
      {(tree as DataNode).data.map((f, i) => (
        <div
          key={f.id}
          className={`flex flex-row items-center gap-0.5 ${
            envelopesPage ? 'flex-no-wrap' : 'flex-wrap flex-grow-0'
          }`}
        >
          <FilterPillsTree
            tree={f}
            key={f.id}
            removeAllFilter={removeAllFilter}
            showAllFilters={showAllFilters}
            scrolled={scrolled}
            filtersModified={filtersModified}
            removeFilter={removeFilter}
            field={field}
            envelopesPage={envelopesPage}
            allowDelete={allowDelete}
            canChangeConditional={canChangeConditional}
            renderSampleButton={renderSampleButton}
          />
          {i !== (tree as DataNode).data.length - 1 && (
            <AndOrBadge
              or={tree.op === Condition.OR}
              onClick={(e): void => handleChangeConditional(e)}
              disabled={!canChangeConditional && !!field}
            />
          )}
        </div>
      ))}
    </React.Fragment>
  );
}

export default FilterPillsTree;
