import { continueAssignment, fetchOwnAssignments } from 'actions';
import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import Permissions from 'components/Permissions';
import withWidget from 'decorators/widget';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssignmentsLoading,
  getContinueAssignmentLoading,
  getUsersSingleAssigment,
} from 'selectors/assignments';
import logEvent from 'utils/analytics';

const ViewEvents: React.FC = () => {
  const dispatch = useDispatch();

  const singleAssigment = useSelector(getUsersSingleAssigment);
  const assignmentLoading = useSelector(getAssignmentsLoading);
  const continueAssignmentLoading = useSelector(getContinueAssignmentLoading);

  useEffect(() => {
    dispatch(fetchOwnAssignments({ is_open: true }));
  }, [dispatch]);

  if (assignmentLoading) {
    return (
      <div className="flex items-center w-full">
        <WidgetLoading size="8" />
      </div>
    );
  }

  if (singleAssigment == null || singleAssigment?.pending <= 0) {
    return (
      <div className="flex items-center w-full">
        <LinkLookup
          routeName="review-set"
          className="w-full h-full"
          onClick={(): Promise<void> => logEvent('Dashboard-start-new-assignment')}
        >
          <button type="button" className="w-full h-full focus:outline-none">
            <Permissions action="events.list">
              <div className="text text--center text-lg text--white self-center">
                View Assignments
              </div>
            </Permissions>
          </button>
        </LinkLookup>
      </div>
    );
  }

  const handleFinishAssignment = (): void => {
    logEvent('Dashboard-finish-assignment');
    dispatch(continueAssignment({ uuid: singleAssigment.uuid }));
  };

  return (
    <div className="flex items-center w-full">
      <button type="button" className="w-full focus:outline-none" onClick={handleFinishAssignment}>
        <div className="flex h-full items-center justify-between">
          <div className="text text--left text-l text--bold text--white">
            {`${singleAssigment?.pending} left to review`}
          </div>
          <Permissions action="events.list">
            <div className="flex flex-row items-center">
              <div className="text text--center text-l text--white self-center pr-3">Finish</div>
              {continueAssignmentLoading && <LoadingIndicator size="5" />}
            </div>
          </Permissions>
        </div>
      </button>
    </div>
  );
};

export default withWidget({
  // minWidth: 5,
  // minHeight: 3,
  bgClassColor: 'bg-litlingo-primary',
})(ViewEvents);
