/* eslint-disable camelcase */
import Permissions from 'components/Permissions';
import TestCasePieChart from 'components/TestCasePieChart';
import React from 'react';
import type { TestCasesSummary } from 'types';

type ComponentProps = {
  summary: TestCasesSummary;
  campaignCount?: number;
  ruleCount?: number;
  renderTotalTests?: boolean;
};

const SummarySection: React.FC<ComponentProps> = ({
  summary,
  campaignCount,
  ruleCount,
  renderTotalTests,
}) => {
  const classMedium = 'text pb-2';
  const classSmall = 'text text--extra-small text--bold';
  const { failing, passing, not_run } = summary;
  const totalTests = failing + passing + not_run;

  const renderPieChart = (): JSX.Element | null => {
    if (totalTests === 0) {
      return null;
    }
    return <TestCasePieChart summary={summary} />;
  };

  return (
    <Permissions action="test_cases.viewMetrics">
      <div className="flex flex-row gap-2">
        {renderTotalTests && (
          <div className="text-small self-end">
            {totalTests > 0
              ? `Scoring against ${summary.passing + summary.failing} test cases`
              : `0 test cases`}
          </div>
        )}
        {renderPieChart()}

        {campaignCount !== undefined ||
          (ruleCount !== undefined && (
            <div className="text-center">
              <p className={classMedium}>USAGE</p>
              {campaignCount !== undefined && (
                <div className={`${classSmall} pb-2`}>
                  <span className="font-bold">{campaignCount}</span>
                  {'\u00A0'}
                  {(campaignCount || -1) > 1 ? 'Use Cases' : 'Use Case'}
                </div>
              )}
              {ruleCount !== undefined && (
                <p className={classSmall}>{`${ruleCount} ${
                  (ruleCount || -1) > 1 ? 'Models' : 'Model'
                }`}</p>
              )}
            </div>
          ))}
      </div>
    </Permissions>
  );
};

export default SummarySection;
