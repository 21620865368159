import { LoadingIndicator } from '@litlingo/react';
import LinkLookup from 'components/LinkLookup';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import SearchInput from 'components/SearchInput';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { getFetchAllTagGroupsLoading, getTagGroups } from 'selectors/tagGroup';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import TagGroupsForm from './TagGroupsForm';
import TagGroupsList from './TagGroupsList';

const TagGroups: React.VFC = () => {
  const [toggleForm, setToggleForm] = useState(false);
  const tagGroups = useSelector(getTagGroups);
  const loading = useSelector(getFetchAllTagGroupsLoading);
  const { broad_search: searchTerm } = useSelector(
    getNavParamsByResource(resourceQueryParamName.tagGroups)
  );

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <div
      className="min-w-screen bg-white"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />
      <div
        className="flex flex-row w-full"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <SettingSidebar />

        <div className="pl-6 py-4 overflow-auto w-full">
          <header className="w-158">
            <div>
              <div className="flex items-center gap-2">
                <LinkLookup
                  routeName="tags"
                  className="border-b-4 border-transparent text-menu-item cursor-pointer pb-1 px-2"
                >
                  All Tags
                </LinkLookup>
                <div
                  role="button"
                  tabIndex={0}
                  className="border-b-4 border-litlingo-orange text-menu-item cursor-pointer pb-1 px-2"
                >
                  Tag Groups
                </div>
              </div>
              <div>
                <div className="flex flex-row items-center mt-6">
                  <h2 className="text-title">Tag Groups</h2>
                  {loading && <LoadingIndicator className="ml-2" size="5" />}
                </div>

                <div className="flex flex-nowrap justify-between mt-2 h-8">
                  <div className="flex gap-4">
                    <SearchInput
                      className="w-1/2"
                      resource={resourceQueryParamName.tagGroups}
                      field="broad_search"
                      placeholder="Search tag groups"
                    />

                    <button
                      onClick={(): void => {
                        setToggleForm((prevState) => !prevState);
                      }}
                      type="button"
                      className="button button--primary text-button rounded whitespace-no-wrap"
                    >
                      Create New Tag Group
                    </button>
                  </div>
                  <div className="self-end">
                    {loading ? (
                      <LoadingIndicator className="ml-2" size="5" />
                    ) : (
                      <div className="">
                        {tagGroups.length} of {tagGroups.length} Tag Groups
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <TagGroupsForm
              toggleForm={toggleForm || (tagGroups.length === 0 && !loading && !searchTerm)}
              setToggleForm={setToggleForm}
            />
          </header>

          <main className="w-158">
            <TagGroupsList tagGroups={tagGroups} loading={loading} />
            {tagGroups.length === 0 && !loading && !searchTerm && (
              <div className="flex flex-col gap-2 border rounded-md p-4 bg-litlingo-info-light">
                <h3 className="text-heading-3">New Tag Group Example</h3>
                <p className="text-body">
                  A short description can help others understand the purpose of this tag group.
                </p>

                <div className="flex items-start gap-1 mt-4">
                  <div className="text-body whitespace-no-wrap">
                    <span className="font-bold">0 Tags:</span>
                  </div>

                  <div className="bg-litlingo-white rounded text-small p-0.5">
                    <p className="text-litlingo-gray-3">Tag example 1</p>
                  </div>
                </div>
              </div>
            )}
          </main>
        </div>
      </div>
    </div>
  );
};

export default TagGroups;
