import LinkLookup from 'components/LinkLookup';
import GlobalNavbar from 'components/Navbar/GlobalNavbar';
import React from 'react';

const Global: React.FC = () => (
  <div className="min-h-screen flex flex-col ">
    <GlobalNavbar />
    <div className="min-h-screen py-30 flex flex-col items-center gap-6">
      <LinkLookup routeName="global-models-list" className="text-heading-1 hover:underline">
        Use Cases
      </LinkLookup>
      {/* <LinkLookup routeName="global-categories-list" className="text-heading-1 hover:underline">
        Categories
      </LinkLookup> */}
      <LinkLookup routeName="global-identifiers-list" className="text-heading-1 hover:underline">
        Identifiers
      </LinkLookup>
      <LinkLookup routeName="global-test-viz" className="text-heading-1 hover:underline">
        Test Visualizer
      </LinkLookup>
    </div>
  </div>
);

export default Global;
