import { ResponsiveSankey } from '@nivo/sankey';
import Navbar from 'components/Navbar';
import React, { useState } from 'react';
import type { SankeyData } from 'types';

const SankeyChart: React.FC = () => {
  const [sankeyData, setSankeyData] = useState<SankeyData | null>(null);
  // const [selectedValue, setSelectedValue] = useState('default');

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const fileReader = new FileReader();
    if (e.target.files != null) {
      fileReader.readAsText(e.target.files[0], 'UTF-8');
      fileReader.onload = (event): void => {
        if (event.target != null && event.target.result != null)
          setSankeyData(JSON.parse(event.target.result as string));
      };
    }
  };

  // const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedValue(e.target.value);
  // };

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />
      <label
        htmlFor="sankey-file"
        className="block litlingo-annotator-label-name flex flex-col mt-6"
      >
        <span>Please upload a JSON file with the data for the sankey chart</span>
        <input type="file" name="sankey-file" id="sankey-file" onChange={handleChangeFile} />
      </label>

      {sankeyData && (
        <>
          <div className="flex justify-end" />
          <div className="h-sankey-events">
            <ResponsiveSankey
              data={sankeyData}
              margin={{ top: 40, right: 160, bottom: 40, left: 50 }}
              align="justify"
              colors={['#1C5579', '#6E5FA2', '#CD5A9B', '#FF6965', '#FFA600']}
              nodeOpacity={1}
              nodeThickness={18}
              theme={{
                fontSize: 14,
              }}
              nodeInnerPadding={3}
              nodeSpacing={24}
              nodeBorderWidth={0}
              nodeBorderColor={{ from: 'color', modifiers: [['darker', 0.8]] }}
              linkOpacity={0.25}
              linkHoverOthersOpacity={0.1}
              labelPosition="inside"
              enableLinkGradient
              labelOrientation="horizontal"
              labelPadding={16}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1]] }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SankeyChart;
