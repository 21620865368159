import React from 'react';

type ComponentProps = {
  bgcolor?: string;
  bgcolor2?: string;
  height?: string;
  simple?: boolean;
  completed?: number;
};

const ProgressBar: React.FC<ComponentProps> = ({
  bgcolor = 'bg-litlingo-primary',
  bgcolor2,
  height = 'h-3',
  simple,
  completed = 50,
}) => {
  const completedCapped = completed > 100 ? 100 : completed;

  if (simple) {
    return <div className={`${height} ${bgcolor}`} style={{ width: `${completedCapped}%` }} />;
  }

  return (
    <div className={`${bgcolor}`} style={{ width: '100%' }}>
      <div className={`${height} ${bgcolor2 || ''}`} style={{ width: `${completedCapped}%` }} />
    </div>
  );
};

export default ProgressBar;
