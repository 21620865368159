import LoadingIndicator from 'components/LoadingIndicator';
import React from 'react';

type ComponentProps = {
  size?: string;
};

const WidgetLoading: React.FC<ComponentProps> = (props) => {
  const { size = '10' } = props;

  return (
    <div className="flex items-center justify-center h-full w-full">
      <LoadingIndicator className="ml-2" size={size} />
    </div>
  );
};

export default WidgetLoading;
