/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { Tag, TagGroup } from '@litlingo/client';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { addValueToTree, fetchAllTagGroups, removeValueFromTree } from 'actions';
import ShowLabels from 'components/Filters/BackendResourceFilter/ShowLabels';
import LoadingIndicator from 'components/LoadingIndicator';
import useClickOutside from 'components/utils/useClickOutside';
import { SEARCH_ICON } from 'constants/filterIcons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import { getNavParamsFromTree } from 'selectors/nav';
import { getFetchAllTagGroupsLoading, getTagGroups } from 'selectors/tagGroup';
import { getFetchAllTagsLoading, getTags } from 'selectors/tags';
import { useSelector } from 'store';
import { Selector } from 'types';
import logEvent from 'utils/analytics';

type ResourceFilterProps = {
  className?: string;
  title?: string;
  dataTestid?: string;
  isOpening?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchActionTag: ActionCreatorWithOptionalPayload<any, string>;
  getTagCount: Selector<number>;
};

const TagsFilter: React.FC<ResourceFilterProps> = (props) => {
  const { className, title = '', dataTestid, isOpening, fetchActionTag, getTagCount } = props;

  const tagGroupsKey = 'tag_value_groups';
  const tagsKey = 'tags';

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
  const [offset, setOffset] = useState(0);

  const inputRef = useRef<HTMLInputElement>(null);

  const params = useSelector(getNavParamsFromTree);
  const allTagGroups = useSelector(getTagGroups);
  const allTags = useSelector(getTags);
  const totalCountTags = useSelector(getTagCount);

  const loadingTagGroups = useSelector(getFetchAllTagGroupsLoading);
  const loadingTags = useSelector(getFetchAllTagsLoading);
  const loading = loadingTagGroups || loadingTags;

  const filterTagGroupsItems = (params[tagGroupsKey] ?? []) as string[];
  const notFilterTagGroupsItems = (params[`not_${tagGroupsKey}`] ?? []) as string[];

  const filterTagsItems = (params[tagsKey] ?? []) as string[];
  const notFilterTagsItems = (params[`not_${tagsKey}`] ?? []) as string[];

  const allTagsFilterItems = [
    ...filterTagsItems,
    ...filterTagGroupsItems,
    ...notFilterTagsItems,
    ...notFilterTagGroupsItems,
  ];

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  useEffect(() => {
    if (isOpen) {
      /* setFirstLoad(false); */
      dispatch(
        fetchAllTagGroups({
          limit: -1,
          broad_search: debouncedSearchValue,
        })
      );
      dispatch(
        fetchActionTag({
          searchValue: debouncedSearchValue,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen, debouncedSearchValue, fetchActionTag]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedSearchValue(searchValue), 300);
    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  useEffect(() => {
    if (isOpening) {
      inputRef.current?.focus();
    }
  }, [inputRef, isOpening]);

  const handleButtonClick = (): void => {
    if (isOpen) return;
    setIsOpen(!isOpen);
  };

  const handleDelete = (labelId: string, exclude: boolean): void => {
    if (filterTagsItems.includes(labelId) || notFilterTagsItems.includes(labelId)) {
      if (exclude) {
        dispatch(
          removeValueFromTree({
            field: `not_${tagsKey}`.replace('_and', ''),
            value: labelId,
          })
        );
      } else {
        dispatch(
          removeValueFromTree({
            field: tagsKey.replace('_and', ''),
            value: labelId,
          })
        );
      }
    } else if (exclude) {
      dispatch(
        removeValueFromTree({
          field: `not_${tagGroupsKey}`.replace('_and', ''),
          value: labelId,
        })
      );
    } else {
      dispatch(
        removeValueFromTree({
          field: tagGroupsKey.replace('_and', ''),
          value: labelId,
        })
      );
    }
  };

  const handleInputChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    isTag: boolean
  ): void => {
    logEvent('envelopes-list-filter-models');

    const { shiftKey: exclude } = event.nativeEvent;
    const { id: state } = event.currentTarget;

    if (
      exclude &&
      ((isTag && filterTagsItems.includes(state)) ||
        (!isTag && filterTagGroupsItems.includes(state)))
    ) {
      return;
    }
    if (
      !exclude &&
      ((isTag && notFilterTagsItems.includes(state)) ||
        (!isTag && notFilterTagGroupsItems.includes(state)))
    ) {
      return;
    }

    let tagsNames = [];
    if (isTag) {
      if (exclude) {
        tagsNames = [...notFilterTagsItems];
      } else {
        tagsNames = [...filterTagsItems];
      }
    } else if (exclude) {
      tagsNames = [...notFilterTagGroupsItems];
    } else {
      tagsNames = [...filterTagGroupsItems];
    }

    event.currentTarget.dataset.checked =
      event.currentTarget.dataset.checked === 'true' ? 'false' : 'true';

    const item =
      allTags.find((t) => t.uuid === state) || allTagGroups.find((g) => g.uuid === state);

    if (event.currentTarget.dataset.checked === 'true') {
      let key = '';
      tagsNames.push(state);

      if (isTag) {
        key = tagsKey;
      } else {
        key = tagGroupsKey;
      }

      if (exclude) {
        dispatch(
          addValueToTree({
            field: `not_${key}`.replace('and_', ''),
            value: state,
            label: item && 'value' in item ? item.value : item?.name,
          })
        );
      } else {
        dispatch(
          addValueToTree({
            field: key.replace('_and', ''),
            value: state,
            label: item && 'value' in item ? item.value : item?.name,
          })
        );
      }
    } else {
      tagsNames = tagsNames.filter((s) => s !== state);
      handleDelete(state, exclude);
    }

    event.stopPropagation();
  };

  const handleLoadMore = (): void => {
    if (isOpen) {
      dispatch(
        fetchActionTag({
          limit: 25,
          include_envelopes_count: false,
          broad_search: debouncedSearchValue,
          offset: offset + 25,
        })
      );
      setOffset((o) => o + 25);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e?.target.value.toLowerCase();
    setSearchValue(value);
  };

  return (
    <>
      <div ref={wrapperRef} className={`flex flex-col ${className || ''}"`}>
        <div className="relative flex flex-row justify-start items-center w-full min-h-8 rounded p-1.5 bg-litlingo-success-light bg-opacity-10">
          <div className="w-5">{SEARCH_ICON('white')}</div>
          <input
            ref={inputRef}
            id="search"
            name="search"
            className="max-h-5 w-full text-white ml-1 font-normal text-base bg-transparent placeholder-white placeholder-italic"
            placeholder={`Search ${title}`}
            data-testid={dataTestid}
            type="text"
            onFocus={handleButtonClick}
            onChange={handleSearchChange}
            value={searchValue}
            autoComplete="off"
          />
        </div>

        {isOpen && (
          <>
            <LoadingOverlayWrapper active={loading} spinner={<LoadingIndicator />} fadeSpeed={100}>
              <div className="w-full max-h-filter z-10 overflow-auto bg-litlingo-gray-1 custom-scrollbar">
                <fieldset className="m-2 min-h-5">
                  {[...allTagGroups, ...allTags].map((item: Tag | TagGroup, idx) => {
                    const isTag = 'value' in item;

                    return (
                      <div key={item.uuid} className={idx !== 0 ? 'mt-2' : ''}>
                        <div
                          id={item.uuid}
                          role="button"
                          aria-hidden
                          className="relative flex items-center cursor-pointer"
                          data-checked={allTagsFilterItems.includes(item.uuid)}
                          onClick={(e): void => handleInputChange(e, isTag)}
                        >
                          <div className="text-sm leading-5 flex flex-col items-start justify-center cursor-pointer">
                            <div className="flex flex-row items-center cursor-pointer">
                              <div className="relative flex justify-start items-start">
                                <div className="flex items-center h-5 mr-1">
                                  <input
                                    id={item.uuid}
                                    data-testid={`${item.uuid}-checkbox`}
                                    type="checkbox"
                                    className={`form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out cursor-pointer ${
                                      notFilterTagGroupsItems.includes(item.uuid) ||
                                      notFilterTagsItems.includes(item.uuid)
                                        ? 'litlingo-checkbox-negated'
                                        : 'litlingo-checkbox'
                                    }`}
                                    checked={allTagsFilterItems.includes(item.uuid)}
                                  />
                                </div>

                                {!isTag && (
                                  <div className="h-5 flex items-center">
                                    <span className="mr-1">
                                      <svg
                                        width="3"
                                        height="16"
                                        viewBox="0 0 3 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect width="3" height="16" fill="#F8A01A" />
                                      </svg>
                                    </span>
                                  </div>
                                )}

                                <div className="flex flex-col text-sm leading-5">
                                  <label htmlFor={item.uuid} className="cursor-pointer">
                                    <span className="text-litlingo-gray-6 text-xxs font-normal select-none break-word">
                                      {'name' in item ? item.name : item.value}
                                    </span>
                                  </label>
                                  {'group' in item && item.group && (
                                    <span className="text-litlingo-gray-5 text-xxs font-normal select-none break-word">
                                      {item.group.name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </fieldset>
                {offset + 25 < totalCountTags - 1 && (
                  <button
                    type="button"
                    className="w-full py-1 text-small border-t border-litlingo-gray-2 focus:outline-none"
                    onClick={handleLoadMore}
                  >
                    <span className="text-litlingo-primary">Load More</span>
                  </button>
                )}
              </div>
            </LoadingOverlayWrapper>

            <div className="flex self-end mt-2 -mb-2">
              <span className="text-white font-normal text-xss">Shift + click to exclude</span>
            </div>
          </>
        )}

        {!isOpen && (
          <>
            <ShowLabels
              handleDelete={handleDelete}
              filterData={filterTagGroupsItems}
              notFilterData={notFilterTagGroupsItems}
              getResourceKey={tagGroupsKey}
            />
            <ShowLabels
              handleDelete={handleDelete}
              filterData={filterTagsItems}
              notFilterData={notFilterTagsItems}
              getResourceKey={tagsKey}
            />
          </>
        )}
      </div>
    </>
  );
};

export default TagsFilter;
