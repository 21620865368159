import React, { useCallback, useRef, useState } from 'react';
import useClickOutside from '../utils/useClickOutside';

type SelectOptionProps = {
  onChange: (option: string) => void;
  option: {
    label: string;
    value: string;
  };
  selected: boolean;
  dataTestid: string;
};

const SelectOption: React.FC<SelectOptionProps> = (props) => {
  const { onChange, option, selected, dataTestid } = props;

  const handleClick = (): void => onChange(option.value);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <li
      // id="listbox-option-0"
      role="option"
      aria-selected={selected}
      className="flex text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-litlingo-gray-1"
      onClick={(e): void => {
        e.preventDefault();
        handleClick();
      }}
    >
      <span
        data-testid={`${option.value}-${dataTestid}`}
        className={`block text-left truncate ${selected ? 'font-bold' : 'font-normal'}`}
      >
        {option.label}
      </span>

      {selected && (
        <span className="inset-y-0 right-0 flex items-center pr-4">
          <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      )}
    </li>
  );
};

type SelectProps = {
  onChange: (option: string) => void;
  id?: boolean | 'dialog' | 'menu' | 'false' | 'true' | 'listbox' | 'tree' | 'grid' | undefined;
  placeholder?: string;
  dataTestid?: string;
  options: {
    label: string;
    value: string;
  }[];
  className?: string;
  value?: string;
  customLabel?: string;
};

const Select: React.FC<SelectProps> = (props) => {
  const { id, onChange, options, placeholder, value, dataTestid, className, customLabel } = props;

  const [isOpen, setIsOpen] = useState(false);

  // click outside functionality
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const selectedOption = options.find((option) => option.value === value);
  const handleClick = (): void => setIsOpen(!isOpen);
  const handleChange = (option: string): void => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div ref={wrapperRef} className="relative pt-2">
      <div
        className="h-5 px-0"
        role="button"
        onClick={handleClick}
        aria-hidden="true"
        data-testid="collapse-button"
      >
        <div className="flex flex-row justify-between items-center text-white">
          <div className="flex items-center">
            <h1 className="text-base font-bold text-white leading-5">
              {selectedOption
                ? `${customLabel || ''} ${selectedOption.label}`
                : placeholder || 'Select an option'}
            </h1>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 20 20"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </div>

      {isOpen && (
        <div
          className={`z-50 absolute mt-1 w-full bg-white shadow-lg overflow-auto border-litlingo-success border-t-2 ${
            className || ''
          }`}
        >
          <ul
            tabIndex={-1}
            role={(id as string) || 'listbox'}
            // aria-activedescendant="listbox-item-3"
            className="max-h-60 py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
          >
            {options.map((option) => (
              <SelectOption
                key={`${option.label}-${option.value}`}
                onChange={handleChange}
                option={option}
                selected={option.value === value}
                dataTestid={`${dataTestid || 'select'}-option`}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Select;
