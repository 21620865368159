/* eslint-disable max-lines */
import { capitalize } from 'lodash';
import React from 'react';
import type { User } from 'types';
import { userRolesToTypes } from 'utils/userRoles';
import renderInsight, { renderPrevent } from 'utils/userStrings';

type ComponentProps = {
  user: User;
  selectedUsers: User[];
  handleSelectUser: (event: React.ChangeEvent<HTMLInputElement>, user: User) => void;
};

const UserPermissionsTableRow: React.FC<ComponentProps> = ({
  user,
  selectedUsers,
  handleSelectUser,
}) => {
  const renderTeamNames = (): string => {
    if (user.teams && user.teams.length > 0) {
      return user.teams.reduce(
        (acc, t, index, arr) => `${acc}${t.name}${index !== arr.length - 1 ? ', ' : ''}`,
        ''
      );
    }
    return 'No division assigned';
  };

  return (
    <tr key={user.uuid} className="table-wrapper__new-tr table-row align-top">
      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-12 flex">
          <input
            id={`checkbox-user-${user.uuid}`}
            data-testid="select-button"
            type="checkbox"
            className="cursor-pointer form-checkbox litlingo-checkbox w-5 h-5"
            onClick={(e): void => e.stopPropagation()}
            onChange={(e): void => {
              handleSelectUser(e, user);
            }}
            checked={!!selectedUsers.find((u) => u.uuid === user.uuid)}
          />
        </div>
      </td>

      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-auto pr-4 flex flex-col text-body">
          <div className="w-full truncate">{user.name}</div>
          <div className="w-full truncate">{user.email}</div>
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-12 pr-4 flex items-center">
          <div className="text-body clamped-text-2">
            {user.roles != null &&
              userRolesToTypes(user.roles).map((item, index) => {
                if (index === 0) {
                  return capitalize(item.label);
                }
                return `, ${capitalize(item.label)}`;
              })}
          </div>
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-12 pr-4 flex items-center">
          <div className="text-left text-body clamped-text-2">{renderTeamNames()}</div>
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-12 pr-4 flex items-center text-body">
          {renderInsight(user)}
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-12 pr-4 flex items-center text-body">
          {renderPrevent(user)}
        </div>
      </td>
    </tr>
  );
};

export default UserPermissionsTableRow;
