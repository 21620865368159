/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-lines */

import type { SavedSearch } from '@litlingo/client';
import { createSavedSearch } from 'actions';
import FilterPills from 'components/EnvelopeList/FilterPills';
import Modal from 'components/Modal';
import { MultiSelectOption } from 'components/MultiSelectDropdown/MultiSelectDropdownRedesign';
import SelectRedesign from 'components/Select/SelectRedesign';
import { reviewCadenceOptions, reviewPriorityOptions } from 'constants/reviewSets';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getEnvelopesTotalCount } from 'selectors/envelopes';
import { getAllNavParams, getNavParamsFromTree } from 'selectors/nav';
import getEnvelopeReviewLabelOptions from 'selectors/reviewStatus';
import { useSelector } from 'store';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
};

type Scope = 'all' | 'sample';

const CreateReviewSetModal: React.FC<ComponentProps> = ({ isModalOpen, toggleModalOpen }) => {
  const dispatch = useDispatch();

  const params = useSelector(getAllNavParams);
  const reviewLabelOptions = useSelector(getEnvelopeReviewLabelOptions);
  const scopes: Record<string, string> = {
    all: 'All Results',
    sample: 'Sample',
  };

  const reviewStatusParams = (params.envelopes__review_values || []) as string[];

  const reviewStatusDefault = Object.entries(reviewLabelOptions)
    .filter(([v]) => v !== 'skipped' && reviewStatusParams.some((r) => r === v))
    .map(([value, label]) => ({
      value,
      label,
    }));
  const stringArray = JSON.stringify(reviewStatusDefault);
  const createdBefore = params.envelopes__created_before || '';
  const treeSampleParam = useSelector(getNavParamsFromTree).sample_uuids;
  const sampleParam = params.envelopes__sample_uuid || treeSampleParam || '';

  const [reviewStatus, setReviewStatus] = useState<MultiSelectOption[]>([
    { label: 'Pending', value: 'pending' },
  ]);
  const [reviewScope, setReviewScope] = useState<Scope>('all');
  const reviewType = 'inbox-0';
  const [reviewPriority, setReviewPriority] = useState<SavedSearch['priority'] | ''>('medium');
  /* FRO-1090 const [reviewSort, setReviewSort] = useState('newest'); */
  const [reviewCadence, setReviewCadence] = useState('immediately');
  const [errors, setErrors] = useState<Record<string, string>>();
  const [basedOn, setBasedOn] = useState('size');

  const envelopesTotalCount = useSelector(getEnvelopesTotalCount);

  /* FRO-1090 const [sizeInputValue, setSizeInputValue] = useState('100'); */

  const { register, getValues, handleSubmit, setValue } = useForm<{
    name: string;
    description: string;
    size: string;
    includeInDigest: boolean;
    sampleSize: number;
  }>({
    defaultValues: {
      includeInDigest: false,
    },
  });

  useEffect(() => {
    setReviewStatus((status) => [
      ...status,
      ...reviewStatusDefault.filter((option) => option.value !== 'pending'),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringArray]);

  /* FRO-1090 useEffect(() => {
    if (envelopesTotalCount < 100) {
      setSizeInputValue(envelopesTotalCount.toLocaleString('en-US'));
    }
  }, [envelopesTotalCount]); */

  const onSubmit = (): void => {
    const { name, description, size, sampleSize } = getValues();

    if (reviewStatus.length === 0) {
      setErrors({ reviewStatus: 'You must select at least one review stats' });
      return;
    }

    /* FRO-1090 if (reviewType === '') {
      setErrors({ type: 'You must select a type' });
      return;
    } */

    if (reviewPriority === '') {
      setErrors({ priority: 'You must select a priority' });
      return;
    }

    if (createdBefore.length === 0) {
      if (reviewCadence === '') {
        setErrors({ cadence: 'You must select a cadence' });
        return;
      }
    }

    if (reviewScope === 'sample') {
      if (sampleSize === 0) {
        setErrors({ sampleSize: 'You must select a sample size' });
        return;
      }
      /* FRO-1090 if (reviewType === 'assignment' && Number(size) > Number(sampleSize)) {
        setErrors({ size: `Size can't be greater than sample size` });
        return;
      } */
    }
    if (Number(size) > envelopesTotalCount) {
      setErrors({ size: `Size can't be greater than total results` });
      return;
    }
    setErrors({});

    const urlParams = new URLSearchParams();
    const navParams = Object.entries(params).filter(
      ([key]) => !['envelopes__limit', 'envelopes__offset'].includes(key)
    );
    let filteredParams = navParams;

    if (params.envelopes__filters_search) {
      filteredParams = filteredParams.filter(([key]) =>
        ['envelopes__filters_search'].includes(key)
      );
    }

    filteredParams.forEach(([key, value]) => {
      if (!key.includes('review_values')) {
        if (Array.isArray(value)) {
          value.forEach((v) => urlParams.append(key, v));
        } else {
          urlParams.append(key, value);
        }
      }
    });

    reviewStatus.forEach((status) => urlParams.append('envelopes__review_values', status.value));
    const urlQuery = `?${urlParams.toString()}`;
    const paramCount = Object.keys(params).length;

    // add cadence later
    // add users later

    dispatch(
      createSavedSearch({
        name,
        description,
        private: true,
        url: urlQuery,
        is_starred: false,
        param_count: paramCount,
        is_assignable: true,
        priority: reviewPriority,
        config: {
          default_count: Number.parseInt(size, 10) || -1,
        },
        redirect: 'review-set-manager',
        isReviewSet: true,
        display_in_assignments: false,
        scope: sampleParam.length === 0 ? reviewScope : 'all',
        sample_size:
          reviewScope === 'sample' && sampleParam.length === 0 ? Number(sampleSize) : undefined,
        based_on: reviewScope === 'sample' && sampleParam.length === 0 ? basedOn : undefined,
        cadence: createdBefore.length === 0 && sampleParam.length === 0 ? reviewCadence : undefined,
      })
    );
    toggleModalOpen();
  };

  const handleSaveButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const renderExtraFields = (): JSX.Element | null => {
    const basedOnSizeOrPercentage = (
      <div className="flex flex-row gap-2 items-center">
        <label htmlFor="size" className="flex flex-row gap-1 items-center">
          <input
            type="radio"
            id="size"
            name="size"
            value={basedOn}
            className="input-radio"
            checked={basedOn === 'size'}
            onChange={(e): void => setBasedOn(e.target.name)}
          />
          <span className="text-xss">Based on fixed size</span>
        </label>
        <label htmlFor="percentage" className="flex flex-row gap-1 items-center">
          <input
            type="radio"
            id="percentage"
            name="percentage"
            value={basedOn}
            className="input-radio"
            checked={basedOn === 'percentage'}
            onChange={(e): void => setBasedOn(e.target.name)}
          />
          <span className="text-xss">Based on percentage</span>
        </label>
      </div>
    );

    const sizeSample = (
      <div className="flex flex-row gap-1">
        <div className="flex flex-row gap-1">
          <span>Scope</span>
          <span className="litlingo-red-color">*</span>
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="size" className="flex flex-col gap-2 text-body">
            <div className="flex flex-row items-center flex-start gap-1">
              <input
                id="sampleSize"
                type="number"
                ref={register({ required: true, min: 1, max: envelopesTotalCount })}
                name="sampleSize"
                onChange={(e): void => {
                  const { value } = e.target;
                  setValue(
                    'samplePercentage',
                    ((Number(value) / envelopesTotalCount) * 100).toFixed(1) as unknown as number
                  );
                }}
                className="form-input w-16 h-8 text-center text-body transition duration-150 ease-in-out "
              />
              <div className="flex flex-row gap-3">
                <span className="ml-2">Communications</span>
                <span className="font-bold">OR</span>
              </div>
            </div>
            <div className="flex flex-row items-center gap-1 ">
              <input
                id="samplePercentage"
                type="number"
                ref={register({
                  required: true,
                  min: (100 / envelopesTotalCount).toFixed(1),
                  max: 100,
                })}
                name="samplePercentage"
                onChange={(e): void => {
                  const { value } = e.target;
                  setValue(
                    'sampleSize',
                    ((Number(value) / 100) * envelopesTotalCount).toFixed() as unknown as number
                  );
                }}
                className="form-input w-16 h-8 text-center text-body transition duration-150 ease-in-out "
              />
              <div className="flex flex-row gap-1">
                <span className="text-lg ml-2">%</span>
                <span>of count</span>
              </div>
            </div>
          </label>
          {basedOnSizeOrPercentage}
        </div>
      </div>
    );
    /* FRO-1090 const sizeInput = (
      <div className="flex flex-col gap-1">
        <div className="flex flex-row items-center gap-2">
          <label htmlFor="size" className="text-body">
            {'Size '}
            <span className="litlingo-red-color">*</span>
          </label>

          <input
            id="size"
            type="number"
            ref={register({ required: true, min: 1 })}
            value={sizeInputValue}
            onChange={(e): void => {
              const { value } = e.target;
              setValue('size', value);
              setSizeInputValue(value);
            }}
            onClick={(): void => {
              logEvent('review-set-create-change-assignment-size');
            }}
            name="size"
            className="form-input w-16 py-2 text-center text-body transition duration-150 ease-in-out "
          />
          <span>Communications</span>
        </div>
        <div className="text-small">
          <span className="text-litlingo-alert">{errors?.size}</span>
        </div>
      </div>
    ); */

    const cadenceInput =
      createdBefore.length === 0 ? (
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-2 text-body">
            <div className="flex flex-row gap-1">
              <span>Cadence</span>
              <span className="litlingo-red-color">*</span>
            </div>

            <div className="flex-shrink-0 w-48">
              <SelectRedesign
                onChange={(value: string): void => setReviewCadence(value)}
                options={reviewCadenceOptions}
                value={reviewCadence}
                placeholder="Select frecuency"
                dataTestid="cadence-select"
              />
              <div className="text-small">
                <span className="text-litlingo-alert">{errors?.cadence}</span>
              </div>
            </div>
          </div>
          <span className="text-xss mt-1">
            *Selecting ‘Weekly’ will sample the last 7 days, etc.
          </span>
        </div>
      ) : null;

    /* FRO-1090 if (reviewType === 'assignment' && reviewScope === 'sample') {
      return (
        <div className="flex flex-col gap-2">
          {sampleParam.length === 0 ? sizeSample : null}
          {cadenceInput}
          <div className="text-base leading-5 font-bold font-sans">Assignment Details</div>
          {sizeInput}
        </div>
      );
    } */

    if (reviewScope === 'sample') {
      return (
        <div className="flex flex-col gap-2">
          {sampleParam.length === 0 ? sizeSample : null}
          {cadenceInput}
        </div>
      );
    }

    /* FRO-1090 if (reviewType === 'assignment') {
      return (
        <div className="flex flex-col gap-2">
          {sizeInput}
          {cadenceInput}
        </div>
      );
    } */
    return <></>;
  };

  /* FRO-1090 const title = (): string => {
    if (reviewScope === 'sample') {
      return 'Sample Details';
    }

    if (reviewType === 'assignment') {
      return 'Assignment Details';
    }

    return 'Shared Inbox Details';
  }; */

  const reviewStreamDetails = (): JSX.Element => (
    <div className="flex flex-col ">
      <div className="text-base leading-5 font-bold font-sans mb-2">Review Details</div>
      {renderExtraFields()}
      <div className="flex flex-col pt-2 gap-4">
        <div className="flex flex-row gap-5">
          <div className="w-44 flex flex-col gap-1 text-body">
            <div className="flex flex-row gap-1">
              <span>Priority</span>
              <span className="litlingo-red-color">*</span>
            </div>

            <div className="flex-shrink-0 w-44">
              <SelectRedesign
                onChange={(value: string): void =>
                  setReviewPriority(value as SavedSearch['priority'])
                }
                options={reviewPriorityOptions}
                value={reviewPriority || ''}
                placeholder="Select Priority"
                dataTestid="priority-select"
              />
              <div className="text-small">
                <span className="text-litlingo-alert">{errors?.priority}</span>
              </div>
            </div>
          </div>

          {/* FRO-1090 <div className="w-44 flex flex-col gap-1 text-body">
            <div className="flex flex-row gap-1">
              <span>Sort by</span>
            </div>

            <div className="flex-shrink-0 w-44">
              <SelectRedesign
                onChange={(value: string): void => setReviewSort(value)}
                options={reviewSortOptions}
                value={reviewSort}
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );

  const renderCreateReviewSet = (
    <div className="pt-4 pl-2 pb-8">
      <FilterPills allowDelete={false} />
      <form className="flex flex-col items-start">
        <div className="flex flex-row gap-x-4 w-full">
          <div className="flex flex-col items-start gap-2">
            <label htmlFor="name" className="flex flex-col gap-1 mt-2 text-body">
              <div className="flex flex-row gap-1">
                <span>Name</span>
                <span className="litlingo-red-color">*</span>
              </div>

              <input
                id="name"
                ref={register({ required: true, pattern: /\S/i })}
                name="name"
                placeholder="Review name"
                className="form-input h-9 w-64 p-2 text-body rounded placeholder-italic placeholder-litlingo-gray-6"
              />
            </label>

            <label htmlFor="description" className="flex flex-col gap-1 mt-2 text-body">
              <div className="flex flex-row gap-1">
                <span>Description</span>
              </div>

              <textarea
                id="description"
                ref={register()}
                name="description"
                rows={3}
                placeholder="A short description that can help others understand the purpose of this review."
                className="form-input w-88 p-2 text-body rounded resize-none placeholder-italic placeholder-litlingo-gray-6"
              />
            </label>
            <div className="flex flex-col gap-1 mt-2 text-body w-full">
              <div className="flex flex-row gap-1">
                <span>Scope</span>
                <span className="litlingo-red-color">*</span>
              </div>
              <div className="flex flex-row gap-10 items-center ">
                {sampleParam.length === 0
                  ? Object.keys(scopes).map((scope) => (
                      <label
                        key={scope}
                        htmlFor={`radio-${scope}`}
                        className="flex flex-row gap-1 items-center"
                      >
                        <input
                          type="radio"
                          id={`radio-${scope}`}
                          name={scope}
                          value={scope}
                          className="input-radio"
                          checked={reviewScope === scope}
                          onChange={(e): void => setReviewScope(e.target.value as Scope)}
                        />
                        <span className="text-xss">{scopes[scope]}</span>
                      </label>
                    ))
                  : Object.keys(scopes)
                      .filter((s) => s !== 'sample')
                      .map((scope) => (
                        <label
                          key={scope}
                          htmlFor={`radio-${scope}`}
                          className="flex flex-row gap-1 items-center"
                        >
                          <input
                            type="radio"
                            id={`radio-${scope}`}
                            name={scope}
                            value={scope}
                            className="input-radio"
                            checked={reviewScope === scope}
                            onChange={(e): void => setReviewScope(e.target.value as Scope)}
                          />
                          <span className="text-xss">{scopes[scope]}</span>
                        </label>
                      ))}
              </div>
            </div>
            {/* <div className="flex flex-col gap-1 mt-2 text-body">
              <div className="flex flex-row gap-1">
                <span data-testid="type-span">Type</span>
                <span className="litlingo-red-color">*</span>
              </div>
              <div className="flex flex-col items-start gap-5 w-full">
                <div className="flex-shrink-0 w-full">
                  <div className="flex flex-row gap-10">
                    {reviewTypeOptions.map((option) => (
                      <label
                        key={option.value}
                        htmlFor={`radio-${option.value}`}
                        className="flex flex-row gap-1 items-center"
                        data-testid={`radio-${option.value}`}
                      >
                        <input
                          type="radio"
                          id={`radio-${option.value}`}
                          name={option.value}
                          value={option.value}
                          className="input-radio"
                          checked={reviewType === option.value}
                          onChange={(e) => setReviewType(e.target.value)}
                        />
                        <span className="text-xss">{option.label}</span>
                      </label>
                    ))}
                  </div>
                  <div className="text-small">
                    <span className="text-litlingo-alert">{errors?.type}</span>
                  </div>
                </div>
                <div className="text-litlingo-gray-5">
                  {reviewType === 'inbox-0' && reviewScope === 'all'
                    ? reviewTypeOptions.find((o) => o.value === 'inbox-0')?.text
                    : reviewTypeOptions.find((o) => o.value === 'assignment')?.text}
                </div>
              </div>
            </div> */}
          </div>
          {reviewType && <div className="border-r border-litlingo-gray-1" />}
          {reviewStreamDetails()}
        </div>
      </form>
    </div>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderCreateReviewSet}
          title="Create Review"
          okButton
          okButtonText="Create"
          okButtonOnClick={handleSaveButton}
          toggleShowModal={toggleModalOpen}
          style={{ width: '51rem' }}
        />
      )}
    </>
  );
};

export default CreateReviewSetModal;
