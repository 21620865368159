import { merge } from 'lodash';
import base from './base';
import type { Config, PartialConfig } from './base';
import development from './development';
import local from './local';
import production from './production';
import staging from './staging';

type Target = 'local' | 'development' | 'staging' | 'production';
type FullConfig<T> = Config & { target: T };
type Configs = {
  [T in Target]: FullConfig<T>;
};

function mergeConfig<T extends Target, C extends PartialConfig>(
  target: T,
  config: C
): FullConfig<T> {
  const extra = { target };

  return merge({}, base, config, extra);
}

const configs: Configs = {
  local: mergeConfig('local', local),
  development: mergeConfig('development', development),
  staging: mergeConfig('staging', staging),
  production: mergeConfig('production', production),
};

const target = process.env.REACT_APP_BUILD_TARGET as Target;

export type { OIDCConfig } from './base';
export default configs[target];
