/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { setHoverIdentifier } from 'actions';
import { setDropPosition } from 'actions/identifier';
import LinkLookup from 'components/LinkLookup';
import Tooltip from 'components/Tooltip';
import { ALERT_ICON } from 'constants/envelopeIcons';
import { NOTES_ICON_REDESIGN } from 'constants/testCaseListIcons';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getEventRuleConfigNode, getSelectedText } from 'selectors/envelopeReview';
import { getSelectedRuleIdentifiers } from 'selectors/ruleGroup';
import { useSelector } from 'store';
import type { RuleRowDataType } from 'types';
import {
  formattedName,
  indentation,
  operatorName,
  operatorNameNot,
  relationshipIcon,
} from '../RuleGroupManagerTable/RuleManagerUtils';

type ComponentProps = {
  data: RuleRowDataType;
  eventTracing: {
    id: string;
    level: number;
  }[];
  active: boolean;
};

const EnvelopeModelRowItem: React.FC<ComponentProps> = ({ data, eventTracing, active }) => {
  const dispatch = useDispatch();

  let { name } = data;
  const { deleted } = data;
  const { id, actionItem, relationship, annotatorId, index, identifierId } = data;

  const disabled = false;

  const scopedCustomers: string[] = [];
  const allChilds = [];

  const node = useSelector((state) => getEventRuleConfigNode(state, id));
  const identifiers = useSelector(getSelectedRuleIdentifiers);
  const selectedText = useSelector(getSelectedText);

  const level = eventTracing.find((n) => n.id === id)?.level || 0;

  const [description] = useState(data.description);
  const ref = useRef(null);
  const containerP = useRef<HTMLDivElement>(null);

  const numberOfcollapsedItems = 0;

  useEffect(() => {
    const position = index + allChilds.length - numberOfcollapsedItems;
    if (active) {
      dispatch(setDropPosition(position));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, id]);

  if (!eventTracing.find((n) => n.id === id)) return null;

  const determineNotScopedIdentifier = (): boolean => {
    if (node && node.typeOfConfig === 'ANNOTATION_MATCH' && node.annotatorId) {
      const identifier = identifiers.find((ann) => ann.identifier_uuid === node.annotatorId);
      if (identifier?.identifier?.language_matchers) {
        const matchers = identifier?.identifier?.language_matchers.filter(
          (lm) => 'customer_uuids' in lm && lm.customer_uuids && lm.customer_uuids?.length > 0
        );

        if (matchers.length === 0) return false;

        if (!matchers.every((lm) => scopedCustomers.every((c) => lm.customer_uuids?.includes(c)))) {
          return true;
        }
      }
    }
    return false;
  };

  const getBackgroundColor = (): string => 'bg-transparent';

  if (data.modifiers?.NOT ?? false) {
    name = operatorNameNot[name as 'RELATIONSHIP_MATCH' | 'OR' | 'AND'];
  }
  if (data.negated) {
    name = `${name} (NEGATED)`;
  } else if (data.negated === false) {
    name = `${name} (NOT NEGATED)`;
  }

  if (data.mode_of_speech === 'subject') {
    name = `${name} (SUBJECT)`;
  } else if (data.mode_of_speech === 'object') {
    name = `${name} (OBJECT)`;
  }

  const renderRelationship = (): JSX.Element[] =>
    relationship.map((item) => (
      <Tooltip tooltip={disabled ? 'ruleConfig.disabledBranch' : ''} key={item.id}>
        <div className="relative flex flex-row items-center justify-between w-full border-litlingo-gray-2 py-0.5 bg-transparent">
          <div className="relative whitespace-no-wrap text-body text-left flex flex-row">
            <div className="flex flex-row min-w-12" />

            {indentation.repeat(level)}
            {'\u00A0\u00A0'}
            {relationshipIcon(disabled)}
            <div className="relative">
              {item.deleted ? (
                <span className="text-gray-500">{formattedName(item.name, disabled, false)}</span>
              ) : (
                formattedName(item.name, disabled, false)
              )}
            </div>
          </div>
        </div>
      </Tooltip>
    ));

  const handleMouseEnter = (hoverIdentifier: string): void => {
    dispatch(setHoverIdentifier(hoverIdentifier));
  };
  const handleMouseLeave = (): void => {
    dispatch(setHoverIdentifier(''));
  };

  const renderName = (): JSX.Element | string => {
    if (annotatorId) {
      if (deleted) {
        return (
          <Tooltip tooltip="ruleConfig.deletedAnnotator">
            <span className="text-gray-500">{formattedName(name, disabled, false)}</span>
          </Tooltip>
        );
      }
      if (!identifierId) {
        return <span className="relative mr-2.5">{formattedName(name, disabled)}</span>;
      }
      return (
        <LinkLookup
          routeName="global-identifier-manager"
          onMouseEnter={(): void => handleMouseEnter(annotatorId)}
          onMouseLeave={(): void => handleMouseLeave()}
          routeParams={{ identifierId: identifierId || '' }}
          queryParams={{ body: selectedText || '' }}
          className={`relative flex flex-row mr-2.5 hover:underline ${
            disabled ? 'text-litlingo-gray-3' : 'text-litlingo-gray-6'
          }`}
        >
          {formattedName(name, disabled)}
          {determineNotScopedIdentifier() && (
            <div className="flex flex-row gap-0.5 pl-1"> ( {ALERT_ICON} )</div>
          )}
        </LinkLookup>
      );
    }

    return (
      <div className="relative flex items-center">
        <span
          className={`absolute border-l border-litlingo-gray-2 left-1.5 top-6 `}
          style={{
            height: `${(eventTracing.length - 1) * 24}px`,
          }}
        />

        {formattedName(name, disabled, false)}
        {determineNotScopedIdentifier() && <div>({ALERT_ICON})</div>}
      </div>
    );
  };

  const renderDescription = (): JSX.Element | null => {
    if (data.description) {
      return (
        <div className="flex flex-row  items-center">
          <span>{NOTES_ICON_REDESIGN(false)}</span>
          <span>value={description || ''}</span>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Tooltip tooltip={disabled ? 'ruleConfig.disabledBranch' : ''}>
        <div
          ref={ref}
          id={id}
          data-testid={`rule-manager-table-tr-${id}`}
          className={`flex flex-row gap-2 items-center justify-between w-full relative text-body ${getBackgroundColor()} ${
            disabled ? 'text-litlingo-gray-3' : 'text-litlingo-gray-6'
          }`}
        >
          {determineNotScopedIdentifier() && (
            <div className={`absolute top-0 bottom-0 left-0 right-0 `} />
          )}

          <div
            className={`${
              actionItem === null ? 'text-gray-300' : ''
            } relative whitespace-no-wrap text-body text-left flex flex-row items-center py-0.5  w-full`}
          >
            {eventTracing[0].id === id ? (
              <div className="flex flex-row items-center gap-2 mr-4">
                <div
                  className={`flex justify-center ${
                    disabled ? 'text-litlingo-gray-3' : 'text-litlingo-gray-4'
                  } text-right ${
                    node && node.typeOfConfig === 'ANNOTATION_MATCH' && node.annotatorId
                  }`}
                  style={{ width: '24px' }}
                >
                  {index}
                </div>

                {actionItem}
              </div>
            ) : (
              <div className="min-w-12" />
            )}
            {indentation.repeat(level)}

            {renderName()}
            <div className="flex flex-row w-full items-center" ref={containerP}>
              {operatorName[name as keyof typeof operatorName] && (
                <div
                  className="ml-4"
                  style={{
                    maxWidth: 'calc(55vw - 356px)',
                  }}
                >
                  {renderDescription()}
                </div>
              )}
            </div>
          </div>
        </div>
      </Tooltip>
      {renderRelationship()}
    </div>
  );
};

export default EnvelopeModelRowItem;
