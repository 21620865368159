/* eslint-disable max-lines */

import { INDENTATION_ICON } from 'constants/commonIcons';
import { ATTACHMENT_ICON, MESSAGE_ICON } from 'constants/envelopeIcons';
import React, { useState } from 'react';
import { EnvelopeReviewRule, SectionType } from 'reducers/envelopeReview';
import { CampaignWithRules, getSelectedRule, getSelectedSection } from 'selectors/envelopeReview';
import { useSelector } from 'store';

type ComponentProps = {
  campaignId: string;
  campaignRules: CampaignWithRules;
  selectedCampaign: string | null;
  selectCampaign: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void;
  selectRuleCampaign: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rule: EnvelopeReviewRule,
    campaignId: string
  ) => void;
  section: SectionType;
  highlight?: boolean;
};

const CampaignRules: React.FC<ComponentProps> = ({
  campaignId,
  campaignRules,
  selectedCampaign,
  selectCampaign,
  selectRuleCampaign,
  section,
  highlight = true,
}) => {
  const [expanded, setExpanded] = useState(false);

  const selectedRule = useSelector(getSelectedRule);
  const selectedSection = useSelector(getSelectedSection);

  if (!campaignRules || Object.keys(campaignRules).length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <button
        type="button"
        className={`h-6 px-6 flex flex-row items-center gap-1 border-t border-litlingo-gray-0.5 focus:outline-none hover:border hover:border-litlingo-secondary-100 ${
          selectedCampaign === campaignId && selectedSection === section && highlight
            ? 'bg-litlingo-focus-area-background'
            : 'bg-white'
        } `}
        data-testid={`campaign-button-${campaignId}`}
        onClick={(e): void => {
          setExpanded(!expanded);
          selectCampaign(e, campaignId);
        }}
      >
        <button
          type="button"
          onClick={(e): void => {
            e.stopPropagation();
            setExpanded(!expanded);
          }}
          className={`focus:outline-none ${expanded ? 'transform rotate-90' : ''}`}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.08961 9.91604C2.1425 9.96937 2.21437 9.99955 2.28948 9.99999C2.32675 10.0003 2.3637 9.99307 2.39807 9.97864C2.43244 9.9642 2.4635 9.9429 2.48934 9.91604L7.20219 5.20319C7.22866 5.17707 7.24968 5.14595 7.26402 5.11164C7.27837 5.07733 7.28575 5.04051 7.28575 5.00333C7.28575 4.96614 7.27837 4.92932 7.26402 4.89501C7.24968 4.8607 7.22866 4.82958 7.20219 4.80346L2.48934 0.0896109C2.46343 0.0619091 2.43221 0.0397035 2.39754 0.0243155C2.36287 0.00892748 2.32545 0.000671647 2.28753 3.93014e-05C2.2496 -0.000593044 2.21193 0.00641102 2.17677 0.0206347C2.1416 0.0348583 2.10966 0.0560109 2.08283 0.0828334C2.05601 0.109656 2.03486 0.1416 2.02063 0.176766C2.00641 0.211931 1.99941 0.249598 2.00004 0.287526C2.00067 0.325453 2.00893 0.362866 2.02432 0.397537C2.0397 0.432209 2.06191 0.46343 2.08961 0.489344L6.6026 5.00333L2.08961 9.51631C2.06326 9.5425 2.04235 9.57364 2.02809 9.60794C2.01382 9.64224 2.00647 9.67903 2.00647 9.71618C2.00647 9.75333 2.01382 9.79011 2.02809 9.82441C2.04235 9.85871 2.06326 9.88985 2.08961 9.91604Z"
              fill="#333333"
            />
          </svg>
        </button>
        <span className="text-left">{campaignRules.campaignName}</span>
      </button>

      {expanded && (
        <div className="flex flex-col">
          {campaignRules.rules.sort().map((r) => (
            <button
              key={r.uuid}
              type="button"
              data-testid={`rule-button-${r.uuid}`}
              onClick={(e): void => selectRuleCampaign(e, r, campaignId)}
              className={`h-6 pl-10 pr-6 flex flex-row items-center gap-1 border-t border-litlingo-gray-0.5 focus:outline-none hover:border hover:border-litlingo-secondary-100 ${
                selectedCampaign === campaignId &&
                selectedRule?.uuid === r.uuid &&
                selectedSection === section &&
                highlight
                  ? 'bg-litlingo-focus-area-background'
                  : 'bg-white'
              }`}
            >
              <span className="text-litlingo-gray-5">{INDENTATION_ICON}</span>
              {r.attachmentHit && (
                <span className="text-litlingo-gray-5 w-3">{ATTACHMENT_ICON}</span>
              )}
              {r.messageHit && <span className="text-litlingo-gray-5 w-3">{MESSAGE_ICON}</span>}

              <span className="w-full text-left">{r.name}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CampaignRules;
