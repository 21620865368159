import { fetchCommonWords, fetchSentimentOverTime } from 'actions';
import DashboardCommonWords from 'components/Analytics/DashboardCommonWords';
import Navbar from 'components/Navbar';
import Permissions from 'components/Permissions';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import EventsFilters from '../Filters/EventsFilters';
import DashboardSentiment from './DashboardSentiment';

const Analytics: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSentimentOverTime());
    dispatch(fetchCommonWords());
  }, [dispatch]);

  const handleMakeQuery = (): void => {
    dispatch(fetchSentimentOverTime());
    dispatch(fetchCommonWords());
  };

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />
      <div className="py-10">
        <header>
          <div className="max-w-7xl mx-auto sm:px-8 lg:px-10">
            <div className="flex flex-row justify-between items-center">
              <h1 className="title">Analytics</h1>
            </div>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto sm:px-8 lg:px-8">
            <span className="litlingo-gray-line flex my-4" />
            <EventsFilters resource={resourceQueryParamName.analytics} />
            <div className="flex justify-end">
              <button
                data-testid="analytics-make-query-button"
                type="button"
                onClick={handleMakeQuery}
                className="button button--primary"
              >
                Make query
              </button>
            </div>
            <div>
              <Permissions action="analytics.sentiment">
                <DashboardSentiment />
                <DashboardCommonWords />
              </Permissions>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Analytics;
