import React from 'react';
import { getTriggeredNodes } from 'selectors/communications';
import { useSelector } from 'store';
import type { MRuleConfigNode, RelationshipArrayItem } from 'types';
import {
  RELATIONSHIP_BOX_HEIGHT,
  RELATIONSHIP_BOX_WIDTH,
  RELATIONSHIP_MODAL_BODY_WIDTH,
  RELATIONSHIP_PADDING_RIGHT,
} from 'utils/dimensions';

type ComponentProps = {
  relationship: RelationshipArrayItem | null;
  annotators: MRuleConfigNode[];
};

const RenderRelationshipLines: React.FC<ComponentProps> = ({ relationship, annotators }) => {
  const triggeredNodes = useSelector(getTriggeredNodes);

  if (relationship == null) {
    return null;
  }

  const mapAnnotatorPosition =
    annotators != null
      ? annotators.reduce(
          (cur, item, ind) => ({ ...cur, [item.name]: ind }),
          {} as { [name: string]: number }
        )
      : {};

  const initPointY = RELATIONSHIP_BOX_HEIGHT / 2;
  const from = mapAnnotatorPosition[relationship.a.name as string];
  const to = mapAnnotatorPosition[relationship.b.name as string];

  const posFromX = 0;
  const posFromY = RELATIONSHIP_BOX_HEIGHT * from + initPointY;

  const posToX =
    RELATIONSHIP_MODAL_BODY_WIDTH - RELATIONSHIP_PADDING_RIGHT - 2 * RELATIONSHIP_BOX_WIDTH;
  const posToY = RELATIONSHIP_BOX_HEIGHT * to + initPointY;

  return (
    <line
      x1={posFromX}
      y1={posFromY}
      x2={posToX}
      y2={posToY}
      stroke={triggeredNodes.includes(relationship.id) ? '#718096' : 'currentColor'}
      strokeWidth={triggeredNodes.includes(relationship.id) ? '5' : '2'}
    />
  );
};

export default RenderRelationshipLines;
