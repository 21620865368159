/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type FieldTextProps = {
  name: string;
  isRequired: boolean;
  inputKey: string;
  customElement: string;
  value?: string;
};

export const FieldText: React.FC<FieldTextProps> = ({
  name,
  isRequired,
  inputKey,
  customElement,
  value,
}) => {
  const { watch, register } = useFormContext();

  if (customElement === 'textarea') {
    return (
      <textarea
        id={name}
        key={inputKey}
        name={name}
        defaultValue={watch(name)}
        rows={1}
        ref={register({
          required: isRequired ? 'Required' : false,
        })}
        className="rounded-none rounded-l-md sm:text-sm sm:leading-5 form-input block w-full py-2 px-3 border border-gray-300 shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        value={value}
      />
    );
  }

  return (
    <input
      id={name}
      key={inputKey}
      name={name}
      defaultValue={watch(name)}
      ref={register({
        required: isRequired ? 'Required' : false,
      })}
      className="rounded-none rounded-l-md sm:text-sm sm:leading-5 form-input block w-full py-2 px-3 border border-gray-300 shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
      value={value}
    />
  );
};

type FieldFloatProps = {
  name: string;
  isRequired: boolean;
  inputKey: string;
};

export const FieldFloat: React.FC<FieldFloatProps> = ({ name, isRequired, inputKey }) => {
  const { watch, register } = useFormContext();

  return (
    <input
      id={name}
      key={inputKey}
      name={name}
      defaultValue={watch(name)}
      ref={register({
        required: isRequired ? 'Required' : false,
        validate: {
          isFloat: (value): string | boolean => {
            if (/^-?[0-9]*([,.][0-9]+)?$/.test(value)) return true;
            return 'Only float values';
          },
        },
      })}
      className="rounded-none rounded-l-md sm:text-sm sm:leading-5 form-input block w-full py-2 px-3 border border-gray-300 shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
    />
  );
};

type FieldBooleanProps = {
  name: string;
  inputKey: string;
};

export const FieldBoolean: React.FC<FieldBooleanProps> = ({ name, inputKey }) => {
  const { setValue, watch, register } = useFormContext();
  const defaultValue = watch(name);

  useEffect(() => {
    setValue(name, Array.isArray(defaultValue) ? defaultValue[0] : defaultValue);
  }, [defaultValue, name, setValue]);

  return (
    <label className="switch relative inline-block" htmlFor={name}>
      <input
        id={name}
        key={inputKey}
        name={name}
        ref={register()}
        type="checkbox"
        className="litlingo-checkbox-input w-0 h-0 opacity-0"
      />
      <span className="slider round absolute inset-0 duration-500 rounded-full py-2 px-4 cursor-pointer" />
    </label>
  );
};

const UnsupportedField: React.FC = () => (
  <input
    className="rounded-none rounded-l-md sm:text-sm sm:leading-5 form-input block w-full py-2 px-3 border border-gray-300 shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
    disabled
    placeholder="Field not provided or supported"
  />
);

const FIELDS = {
  str: {
    Component: FieldText,
  },
  float: {
    Component: FieldFloat,
  },
  bool: {
    Component: FieldBoolean,
  },
};

type ValueFieldProps = {
  name: string;
  fieldType: 'str' | 'float' | 'bool';
  customElement?: string;
  isRequired: boolean;
  remove: (index?: number | number[] | undefined) => void;
  isList: boolean;
  index: number;
  inputKey: string | undefined;
  errors: { [key: string]: { message: string } };
  value?: string;
};

const ValueField: React.FC<ValueFieldProps> = ({
  name,
  fieldType,
  isRequired,
  remove,
  isList,
  index,
  inputKey,
  customElement,
  errors,
  ...rest
}) => {
  if (!FIELDS[fieldType]) return <UnsupportedField />;

  const { Component } = FIELDS[fieldType];

  return (
    <>
      <div className="flex mt-2">
        <Component
          name={name}
          isRequired={isRequired}
          inputKey={inputKey || '1'}
          customElement={customElement || ''}
          {...rest}
        />
        {isList && index !== 0 ? (
          <button
            type="button"
            className="mx-1 focus:outline-none"
            onClick={(): void => {
              remove(index);
            }}
          >
            <svg
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              className="h-5 w-5 text-gray-400"
              stroke="currentColor"
            >
              <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </button>
        ) : null}
      </div>
      <span>{errors[name] && errors[name].message}</span>
    </>
  );
};

export default ValueField;
