import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import type { UpdateCampaignRuleOutcomePayload } from 'actions/campaignRuleOutcomes';
import { updateCampaignRuleOutcome } from 'actions/campaignRuleOutcomes';
import {
  createCampaignFailure,
  createCampaignRequest,
  createCampaignSuccess,
  deleteCampaignFailure,
  deleteCampaignRequest,
  deleteCampaignRule,
  deleteCampaignSuccess,
  fetchActiveIntegrationsFailure,
  fetchActiveIntegrationsRequest,
  fetchActiveIntegrationsSuccess,
  fetchAllCampaignsFailure,
  fetchAllCampaignsRequest,
  fetchAllCampaignsSuccess,
  FetchAllCampaignsSuccessPayload,
  fetchCampaignsForFilterPillsFailure,
  fetchCampaignsForFilterPillsRequest,
  fetchCampaignsForFilterPillsSuccess,
  fetchSingleCampaignFailure,
  fetchSingleCampaignRequest,
  fetchSingleCampaignSuccess,
  PayloadDeleteCampaignRule,
  upsertCampaignFailure,
  upsertCampaignFulfill,
  upsertCampaignRequest,
  upsertCampaignSuccess,
  upsertCampaignWithChan,
} from 'actions/campaigns';
import type { API, Campaign, ErrorObject, MCampaign, NormalizedResource, UUID } from 'types';

export type CampaignState = {
  campaigns: NormalizedResource<MCampaign>;
  filterItems: NormalizedResource<MCampaign>;
  activeCampaign: UUID;
  error: ErrorObject | null;
  loading: string[];
  count: number;
  activeIntegrations: string[];
};
type CampaignReducer<P = void> = (state: CampaignState, action: PayloadAction<P>) => CampaignState;

const defaultState: CampaignState = {
  campaigns: {},
  activeCampaign: '',
  filterItems: {},
  error: null,
  loading: [],
  count: 0,
  activeIntegrations: [],
};

const handleFetchAllCampaignsRequest: CampaignReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAllCampaignsRequest.toString()],
});

const handleFetchAllCampaignsSuccess: CampaignReducer<FetchAllCampaignsSuccessPayload> = (
  state,
  { payload }
) => {
  const campaigns: NormalizedResource<Campaign> = {};
  payload.records.forEach((newCampaign) => {
    const { uuid } = newCampaign;
    const campaign = state.campaigns[uuid] || {};

    campaigns[uuid] = {
      ...campaign,
      ...newCampaign,
    };
  });

  return {
    ...state,
    campaigns: {
      ...campaigns,
    },
    count: payload.count,
    loading: state.loading.filter((s) => s !== fetchAllCampaignsRequest.toString()),
  };
};

const handleFetchAllCampaignsFailure: CampaignReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchAllCampaignsRequest.toString()),
});

const handleFetchCampaignsForFilterPillsRequest: CampaignReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchCampaignsForFilterPillsRequest.toString()],
});

const handleFetchCampaignsForFilterPillsFailure: CampaignReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchCampaignsForFilterPillsRequest.toString()),
});

const handleFetchCampaignsForFilterPillsSuccess: CampaignReducer<
  API.WrappedAPIResponse<Campaign>
> = (state, { payload }) => {
  const normalizedCampaigns: NormalizedResource<Campaign> = {};
  payload.records.forEach((campaign) => {
    normalizedCampaigns[campaign.uuid] = campaign;
  });
  return {
    ...state,
    filterItems: { ...state.filterItems, ...normalizedCampaigns },
    loading: state.loading.filter((s) => s !== fetchCampaignsForFilterPillsRequest.toString()),
    count: payload.count,
  };
};

const handleFetchSingleCampaignRequest: CampaignReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchSingleCampaignRequest.toString()],
});

const handleFetchSingleCampaignSuccess: CampaignReducer<Campaign> = (state, { payload }) => {
  const item = payload;
  const campaign = state.campaigns[item.uuid] || {};

  return {
    ...state,
    campaigns: {
      ...state.campaigns,
      [payload.uuid]: { ...campaign, ...item },
    },
    loading: state.loading.filter((s) => s !== fetchSingleCampaignRequest.toString()),
  };
};

const handleFetchSingleCampaignFailure: CampaignReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchSingleCampaignRequest.toString()),
});

const handleCreateCampaignRequest: CampaignReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, createCampaignRequest.toString()],
});

const handleCreateCampaignSuccess: CampaignReducer<Campaign> = (state, { payload }) => ({
  ...state,
  campaigns: {
    ...state.campaigns,
    [payload.uuid]: { ...payload, isNew: true },
  },
  loading: state.loading.filter((s) => s !== createCampaignRequest.toString()),
});

const handleFetchActiveIntegrationsFailure: CampaignReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchActiveIntegrationsRequest.toString()),
});

const handleFetchActiveIntegrationsRequest: CampaignReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchActiveIntegrationsRequest.toString()],
});

const handleFetchActiveIntegrationsSuccess: CampaignReducer<string[]> = (state, { payload }) => ({
  ...state,
  activeIntegrations: payload,
  loading: state.loading.filter((s) => s !== fetchActiveIntegrationsRequest.toString()),
});

const handleCreateCampaignFailure: CampaignReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== createCampaignRequest.toString()),
});

const handleDeleteCampaignRequest: CampaignReducer<string> = (state, { payload: id }) => ({
  ...state,
  campaigns: {
    ...state.campaigns,
    [id]: { ...state.campaigns[id], unSaved: true },
  },
  error: null,
  loading: [...state.loading, deleteCampaignRequest.toString()],
});

const handleDeleteCampaignSuccess: CampaignReducer<string> = (state, { payload: id }) => {
  const campaigns = { ...state.campaigns };
  delete campaigns[id];

  return {
    ...state,
    campaigns,
    loading: state.loading.filter((s) => s !== deleteCampaignRequest.toString()),
  };
};

const handleDeleteCampaignFailure: CampaignReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== deleteCampaignRequest.toString()),
});

const handleUpsertCampaignRequest: CampaignReducer<MCampaign> = (state, { payload }) => ({
  ...state,
  campaigns: {
    ...state.campaigns,
    [payload.uuid]: {
      ...state.campaigns[payload.uuid],
      ...payload,
    },
  },
  loading: [...state.loading, upsertCampaignRequest.toString()],
});

const handleUpsertCampaignSuccess: CampaignReducer<Campaign> = (state, { payload }) => ({
  ...state,
  campaigns: {
    ...state.campaigns,
    [payload.uuid]: {
      ...state.campaigns[payload.uuid],
      ...payload,
    },
  },
});

const handleUpsertCampaignFailure: CampaignReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleUpsertCampaignWithChan: CampaignReducer = (state) => ({
  ...state,
  loading: [...state.loading, upsertCampaignRequest.toString()],
});

const handleUpsertCampaignFulfill: CampaignReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== upsertCampaignRequest.toString()),
});

const handleUpdateCampaignRuleOutcome: CampaignReducer<UpdateCampaignRuleOutcomePayload> = (
  state,
  { payload }
) => {
  const { ruleId, campaignId } = payload;

  return {
    ...state,
    campaigns: {
      ...state.campaigns,
      [campaignId]: {
        ...state.campaigns[campaignId],
        rules: [...(state.campaigns[campaignId].rules ?? []), ruleId],
      },
    },
  };
};

const handleDeleteCampaignRuleOutcome: CampaignReducer<PayloadDeleteCampaignRule> = (
  state,
  { payload }
) => {
  const { ruleId, campaignId } = payload;

  return {
    ...state,
    campaigns: {
      ...state.campaigns,
      [campaignId]: {
        ...state.campaigns[campaignId],
        rules: state.campaigns[campaignId].rules?.filter((r) => r !== ruleId),
      },
    },
  };
};

const handlers = {
  [fetchAllCampaignsRequest.toString()]: handleFetchAllCampaignsRequest,
  [fetchAllCampaignsSuccess.toString()]: handleFetchAllCampaignsSuccess,
  [fetchAllCampaignsFailure.toString()]: handleFetchAllCampaignsFailure,
  [fetchSingleCampaignRequest.toString()]: handleFetchSingleCampaignRequest,
  [fetchSingleCampaignSuccess.toString()]: handleFetchSingleCampaignSuccess,
  [fetchSingleCampaignFailure.toString()]: handleFetchSingleCampaignFailure,
  [fetchCampaignsForFilterPillsRequest.toString()]: handleFetchCampaignsForFilterPillsRequest,
  [fetchCampaignsForFilterPillsSuccess.toString()]: handleFetchCampaignsForFilterPillsSuccess,
  [fetchCampaignsForFilterPillsFailure.toString()]: handleFetchCampaignsForFilterPillsFailure,
  [createCampaignRequest.toString()]: handleCreateCampaignRequest,
  [createCampaignSuccess.toString()]: handleCreateCampaignSuccess,
  [createCampaignFailure.toString()]: handleCreateCampaignFailure,
  [deleteCampaignRequest.toString()]: handleDeleteCampaignRequest,
  [deleteCampaignSuccess.toString()]: handleDeleteCampaignSuccess,
  [deleteCampaignFailure.toString()]: handleDeleteCampaignFailure,
  [upsertCampaignRequest.toString()]: handleUpsertCampaignRequest,
  [upsertCampaignSuccess.toString()]: handleUpsertCampaignSuccess,
  [upsertCampaignFailure.toString()]: handleUpsertCampaignFailure,
  [upsertCampaignFulfill.toString()]: handleUpsertCampaignFulfill,
  [upsertCampaignWithChan.toString()]: handleUpsertCampaignWithChan,
  [updateCampaignRuleOutcome.toString()]: handleUpdateCampaignRuleOutcome,
  [deleteCampaignRule.toString()]: handleDeleteCampaignRuleOutcome,
  [fetchActiveIntegrationsRequest.toString()]: handleFetchActiveIntegrationsRequest,
  [fetchActiveIntegrationsSuccess.toString()]: handleFetchActiveIntegrationsSuccess,
  [fetchActiveIntegrationsFailure.toString()]: handleFetchActiveIntegrationsFailure,
};

const campaignsReducer = createReducer(defaultState, handlers);

export default campaignsReducer;
