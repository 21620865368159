import { TestCase } from '@litlingo/client';
import { clearLastTestCases } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getLastTestCases, getTestCasesLoading } from 'selectors/testCases';
import { useSelector } from 'store';

interface RuleManagerTestSuiteSidebarListProps {
  selected: string[];
  setSelected: (selected: string[]) => void;
  handleUnlink: () => void;
  selectedNode: number | null;
  handleChangeRowMatch: (value: boolean) => void;
  testCases: TestCase[];
  handleChangeSelections: (id: string) => void;
  removeBranchesLoadingState: boolean;
  allowUnlink?: boolean;
  allowFlag?: boolean;
}

const RuleManagerTestSuiteSidebarList: React.FC<RuleManagerTestSuiteSidebarListProps> = ({
  selected,
  setSelected,
  handleUnlink,
  selectedNode,
  handleChangeRowMatch,
  testCases,
  handleChangeSelections,
  removeBranchesLoadingState,
  allowUnlink = true,
  allowFlag = true,
}) => {
  const dispatch = useDispatch();

  const fetchTestCasesLoadingState = useSelector(getTestCasesLoading);
  const lastTestCases = useSelector(getLastTestCases);

  useEffect(
    () => () => {
      dispatch(clearLastTestCases());
    },
    [dispatch]
  );

  const renderFlag = (testCase: TestCase): JSX.Element | null => {
    if (!testCase.most_recent_run_passed) {
      return (
        <div className="absolute right-0 top-0">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0H20V20L10 10L0 0Z" fill="#F8351A" />
          </svg>
        </div>
      );
    }

    if (testCase.should_match) {
      return (
        <div className="absolute right-0 top-0">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0H20V20L10 10L0 0Z" fill="#FFC945" />
          </svg>
        </div>
      );
    }

    return null;
  };

  return (fetchTestCasesLoadingState || removeBranchesLoadingState) && testCases.length === 0 ? (
    <div className="p-8 flex items-center justify-center">
      <LoadingIndicator size="10" className="self-center" />
    </div>
  ) : (
    <div className="flex flex-col">
      <div className="px-2.5 py-1 flex flex-col gap-1 justify-between bg-litlingo-gray-1">
        {selected.length > 0 && selectedNode && selectedNode > 0 && allowUnlink ? (
          <button
            className="flex justify-items-end outline-none self-end underline text-litlingo-alert"
            type="button"
            onClick={handleUnlink}
          >
            Unlink
          </button>
        ) : null}
        <div className="flex flex-row justify-between h-7">
          <label htmlFor="option-all" className="flex flex-row gap-3 items-center gap-1">
            <input
              type="checkbox"
              id="option-all"
              data-testid="button-all"
              onChange={(): void => {
                if (selected.length === testCases.length) {
                  setSelected([]);
                } else {
                  setSelected(testCases.map((testCase) => testCase.uuid));
                }
              }}
              className="form-checkbox litlingo-checkbox"
              checked={selected.length === testCases.length}
            />
            <div className="text-body select-none break-all">{selected.length} selected</div>
          </label>
          {selected.length > 0 && (
            <div className="text-body flex flex-row gap-1">
              <button
                className="py-1 px-3 bg-white rounded font-bold focus:outline-none hover:text-litlingo-gray-5"
                type="button"
                onClick={(): void => handleChangeRowMatch(true)}
                style={{ boxShadow: '3px 1px 8px 0px rgba(0, 0, 0, 0.10)' }}
              >
                Match
              </button>
              <button
                className="py-1 px-3 bg-white rounded font-bold focus:outline-none hover:text-litlingo-gray-5"
                type="button"
                onClick={(): void => handleChangeRowMatch(false)}
                style={{ boxShadow: '3px 1px 8px 0px rgba(0, 0, 0, 0.10)' }}
              >
                No Match
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        {testCases
          .sort((a, b) => {
            if (lastTestCases.includes(a.uuid) && !lastTestCases.includes(b.uuid)) return -1;
            if (!lastTestCases.includes(a.uuid) && lastTestCases.includes(b.uuid)) return 1;
            return 0;
          })
          .map((testCase, idx) => (
            <label
              htmlFor={`option-${testCase.uuid}`}
              className={`relative flex flex-row items-start gap-3 px-2.5 py-2 border-b border-litlingo-gray-1 ${
                lastTestCases.includes(testCase.uuid) ? 'bg-litlingo-focus-area-background' : ''
              }`}
              key={testCase.uuid}
            >
              <input
                type="checkbox"
                id={`option-${testCase.uuid}`}
                data-testid={`button-${idx}`}
                onChange={(): void => handleChangeSelections(testCase.uuid)}
                className="mt-0.5 form-checkbox litlingo-checkbox"
                checked={selected.includes(testCase.uuid)}
              />

              <div className="text-body select-none break-word">{testCase.test_string}</div>
              {allowFlag && renderFlag(testCase)}
            </label>
          ))}
      </div>
    </div>
  );
};

export default RuleManagerTestSuiteSidebarList;
