/* eslint-disable react/jsx-indent */
import { AnnotatedGraph, HighlightedSentence } from '@litlingo/react';
import React from 'react';
import { getUser } from 'selectors/auth';
import { getEnvelopeToPreview } from 'selectors/envelopeListView';
import { getModelTransparencyMode } from 'selectors/envelopeReview';
import { getCommsFullMessageHighlighted, getEvent, getEventDocCommLoading } from 'selectors/events';
import { getPermissionsPolicy } from 'selectors/permissions';
import { getUserShowHighlights } from 'selectors/users';
import { useSelector } from 'store';
import type { UUID } from 'types';
import { getFieldAuthorization } from 'utils/permissions';
import EnvelopeSkeleton from './EnvelopeSkeleton';

type ComponentProps = {
  eventId: UUID;
  commUuid: UUID | null;
  onlySubject?: boolean;
  borderSeparation?: string;
  highRiskOnly?: boolean;
  underlineOnly?: boolean;
  hideHighlight?: boolean;
  handleSentenceClick?: (line: AnnotatedGraph) => void;
};

const WrapperHighlightedSentenceComponent: React.FC<ComponentProps> = ({
  eventId,
  commUuid,
  onlySubject,
  borderSeparation,
  highRiskOnly = false,
  underlineOnly = false,
  hideHighlight = false,
  handleSentenceClick,
}) => {
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const entityFieldLevel = getFieldAuthorization('communications.entity', user.roles, policy);

  const res = useSelector((state) =>
    getCommsFullMessageHighlighted(
      state,
      commUuid || '',
      highRiskOnly.toString(),
      underlineOnly.toString()
    )
  );

  const loading = useSelector(getEventDocCommLoading);

  const event = useSelector((state) => getEvent(state, eventId));
  const userMetadata = useSelector(getUserShowHighlights);

  const envelopeToPreview = useSelector(getEnvelopeToPreview);
  const modelTransparencyMode = useSelector(getModelTransparencyMode);

  const handleBlockHighlight = (): boolean => {
    if (userMetadata === null) {
      return true;
    }

    if (
      userMetadata.data.showHighlights === undefined ||
      userMetadata.data.showHighlights === null
    ) {
      return true;
    }

    return userMetadata.data.showHighlights;
  };

  if (loading || !res) return <EnvelopeSkeleton />;

  const handleClick = (line: AnnotatedGraph): void => {
    if (handleSentenceClick) {
      handleSentenceClick(line);
    }
  };

  if (onlySubject) {
    return (
      <div>
        {res
          .filter((line) => line.type === 'subject')
          .map((line, idx) => (
            <div key={line.index} className={idx !== 0 ? 'mt-2' : ''}>
              <HighlightedSentence
                nodes={line.nodes}
                useHover={user.roles.includes('super-admin')}
                protectedFields={{
                  entity: entityFieldLevel,
                }}
                portal="tooltip-portal"
                ruleId={event?.rule_uuid}
                hideHighlight={hideHighlight}
              />
            </div>
          ))}
      </div>
    );
  }

  const renderContent = (): JSX.Element[] =>
    res
      .filter((line) => line.type === 'line')
      .map((line, idx) => (
        <div
          key={line.index}
          className={`${idx !== 0 ? 'mt-2' : ''} ${
            handleBlockHighlight() && !envelopeToPreview ? '-ml-1' : '-ml-1'
          }`}
        >
          <HighlightedSentence
            nodes={line.nodes}
            useHover={user.roles.includes('super-admin')}
            protectedFields={{
              entity: entityFieldLevel,
            }}
            portal="tooltip-portal"
            ruleId={event?.rule_uuid}
            blockHighlight={handleBlockHighlight()}
            borderSeparation={borderSeparation}
            hideHighlight={hideHighlight}
            highlightSentenceOnCLickHandle={(): void => handleClick(line)}
            modelTransparencyMode={modelTransparencyMode}
          />
        </div>
      ));

  return <>{renderContent()}</>;
};

export default WrapperHighlightedSentenceComponent;
