import { omit, pick } from 'lodash';

import Storage from './base';

class LocalStorage<T> extends Storage<T> {
  protected _key: string;

  constructor(key: string) {
    super();

    this._key = key;
  }

  protected _getKey(): Promise<string> | string {
    return this._key;
  }

  async get<K extends keyof T = keyof T>(
    keys?: K[]
  ): Promise<Pick<Partial<T>, K>> {
    const key = await this._getKey();

    const items = localStorage.getItem(key);
    if (items == null) {
      return {} as Partial<T>;
    }

    const obj: Partial<T> = JSON.parse(items);
    if (keys != null) {
      return pick(obj, keys);
    }

    return obj;
  }

  async set(items: Partial<T>): Promise<void> {
    const key = await this._getKey();

    const current = await this.get();
    localStorage.setItem(key, JSON.stringify({ ...current, ...items }));
  }

  async remove(keys?: (keyof T)[]): Promise<void> {
    const key = await this._getKey();

    if (keys == null) {
      localStorage.removeItem(key);
      return;
    }

    const current = await this.get();
    const updated = omit(current, keys);
    localStorage.setItem(key, JSON.stringify(updated));
  }
}

export default LocalStorage;
