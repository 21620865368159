import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetError from 'decorators/widget/WidgetError';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import React from 'react';
import { DefaultWidgetComponentProps } from 'types';
import { buildSimpleDataFromMetrics } from 'utils/dashboard';

const ReviewValueWidget: React.FC<DefaultWidgetComponentProps> = (props) => {
  const { widgetData, queryData } = props;

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  const processedData = buildSimpleDataFromMetrics(widgetData, queryData);
  if ('error' in processedData && processedData.error != null) {
    return <WidgetError msg={processedData.error} />;
  }

  if ('data' in processedData && processedData.data.length === 0) {
    return <WidgetEmptyState />;
  }

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-row text-body mb-2">
        <span className="font-bold text-litlingo-gray-5">Review Stream</span>
        <span className="ml-1">(on average)</span>
      </div>
      <div className="grid grid-cols-3 auto-rows-auto w-full h-full gap-3">
        {'data' in processedData &&
          processedData.data.map(
            (data, idx) =>
              queryData.queries[idx]?.id && (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${queryData.queries[idx]?.id}--${idx}`}
                  className="flex flex-col justify-center items-center h-full w-full bg-white rounded"
                  style={{ boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)' }}
                >
                  <div className="text-body">{data.label}</div>
                  <span className="text-heading-3">{data.count} %</span>
                </div>
              )
          )}
      </div>
    </div>
  );
};

export default withWidget({
  fetchOnePeriod: true,
})(ReviewValueWidget);
