import { fetchAllAnnotators } from 'actions';
import EnvelopeCollapsableSection from 'components/CollapsableSection/EnvelopeCollapsableSection';
import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import RenderAnnotator from 'components/RuleManager/RenderAnnotator';
import SearchInput from 'components/SearchInput';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAnnotators, getFetchAllAnnotatorsLoading } from 'selectors/annotator';
import { getNavParamsByResource } from 'selectors/nav';
import type { MRule, UUID } from 'types';
import MakeAnnotatorDraggable from './MakeAnnotatorDraggable';
import TestPhraseSidebarSection from './TestPhraseSidebarSection';

type ComponentProps = {
  rule: MRule;
};

type RouteParams = {
  ruleId: UUID;
  campaignId: UUID;
};

const RuleManagerSidebar: React.FC<ComponentProps> = ({ rule }) => {
  const dispatch = useDispatch();
  const { ruleId, campaignId } = useParams<RouteParams>();
  const annotators = useSelector(getAnnotators);
  // eslint-disable-next-line camelcase
  const { broad_search } = useSelector(getNavParamsByResource(resourceQueryParamName.annotator));
  const loading = useSelector(getFetchAllAnnotatorsLoading);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    dispatch(fetchAllAnnotators({}));
    // eslint-disable-next-line camelcase
  }, [dispatch, broad_search]);

  const buttonMainClass = 'button button--secondary font-bold';
  const renderItems = (): JSX.Element | JSX.Element[] => {
    if (loading) {
      return <LoadingIndicator size="10" data-testid="loading-indicator" />;
    }
    if (!loading && annotators.length === 0) {
      return <div data-testid="no-data-message">No identifiers match this search</div>;
    }
    return annotators.map((item) => (
      <>
        <MakeAnnotatorDraggable
          Component={RenderAnnotator}
          annotator={item}
          key={item.uuid}
          rule={rule}
          campaignId={campaignId}
          searchValue={searchValue}
        />
        {/* {true && <TermsSerchMatch annotatorId={item.uuid} searchValue={searchValue} />} */}
      </>
    ));
  };

  const handleSearchChange = (value: string): void => {
    setSearchValue(value);
    dispatch(fetchAllAnnotators({ searchValue: value }));
  };

  return (
    <>
      <EnvelopeCollapsableSection
        headerTitle="Build & Tune"
        isDefaultClosed
        dataTestid="build-tune-section"
      >
        <div className="flex flex-col gap-2 h-full pt-6 bg-litlingo-gray-1 overflow-hidden">
          <div className="flex flex-row justify-between items-center px-6">
            <div className="flex text-left">
              <p className="text-heading-3">Identifiers</p>
            </div>
            <div className="flex h-6">
              <LinkLookup
                routeName="annotator-choose-type"
                routeParams={{ campaignId, ruleId }}
                type="button"
                className={`${buttonMainClass}`}
              >
                Add New
              </LinkLookup>
            </div>
          </div>

          <div className="flex flex-col rounded-md px-6">
            <SearchInput
              className="w-full h-8"
              placeholder="Search identifiers"
              handleSearch={handleSearchChange}
            />
          </div>

          <div
            className="flex flex-col items-center pb-6 px-6 h-full w-full overflow-auto custom-scrollbar"
            data-testid="annotators-list-rule-page"
          >
            {renderItems()}
          </div>
        </div>
      </EnvelopeCollapsableSection>

      <EnvelopeCollapsableSection headerTitle="Test">
        <TestPhraseSidebarSection />
      </EnvelopeCollapsableSection>
    </>
  );
};

export default RuleManagerSidebar;
