abstract class Storage<T> {
  abstract get<K extends keyof T = keyof T>(
    keys?: K[]
  ): Promise<Pick<Partial<T>, K>>;

  abstract set(items: Partial<T>): Promise<void>;

  abstract remove(keys?: (keyof T)[]): Promise<void>;
}

export default Storage;
