import { UUID } from '@litlingo/client';
import { TestCaseEntity } from 'constants/testCases';
import React from 'react';
import EvaluateButton from './EvaluateButton';
import ExportButton from './ExportButton';
import ImportButton from './ImportButton';

type TestCasesActionsProps = {
  entity?: TestCaseEntity;
  entityId?: UUID;
};

const TestCasesActions: React.FC<TestCasesActionsProps> = (props) => {
  const { entity, entityId } = props;

  return (
    <div className="flex flex-row flex-none gap-2">
      <ExportButton entity={entity} entityId={entityId} />
      <ImportButton entity={entity} entityId={entityId} />
      <EvaluateButton entity={entity} entityId={entityId} />
    </div>
  );
};

export default TestCasesActions;
