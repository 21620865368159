/* eslint-disable camelcase */
import { setURLParams } from 'actions';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { getTestCaseSummary } from 'selectors/testCases';
import { useSelector } from 'store';

type ComponentProps = {
  className?: string;
  resource: string;
};

const TestCaseStateFilter: React.FC<ComponentProps> = ({ className, resource }) => {
  const dispatch = useDispatch();
  const { false_negatives, false_positives, true_negatives, true_positives } =
    useSelector(getTestCaseSummary);

  const urlParams = useSelector(getNavParamsByResource(resource));
  const matchCriteriasParams = (urlParams.match_criterias ?? []) as string[];

  const isSelected = (name: string): boolean => {
    if (name === 'truePositives') {
      if (matchCriteriasParams.includes('true_positives')) {
        return true;
      }
    }
    if (name === 'trueNegatives') {
      if (matchCriteriasParams.includes('true_negatives')) {
        return true;
      }
    }

    if (name === 'falsePositives') {
      if (matchCriteriasParams.includes('false_positives')) {
        return true;
      }
    }

    if (name === 'falseNegatives') {
      if (matchCriteriasParams.includes('false_negatives')) {
        return true;
      }
    }
    return false;
  };

  const buttonType = (name: string): string => {
    if (isSelected(name)) {
      return 'litlingo-test-case-button-regular';
    }
    return 'litlingo-test-case-button-outline';
  };

  return (
    <div className={className || 'flex-1 flex'}>
      <div className="flex flex-row items-end self-end justify-between text-body">
        <div className="mr-2.5 pb-1.5 flex flex-col gap-3 justify-end items-end">
          <span className="text-litlingo-gray-5 font-bold">Match</span>
          <span className="flex-none text-litlingo-gray-5 font-bold whitespace-no-wrap truncate">
            No match
          </span>
        </div>
        <div className="flex flex-col items-center">
          <span className="mb-0.5 font-bold text-litlingo-gray-5">Pass</span>
          <button
            type="button"
            data-testid="truePositives-button"
            className={`litlingo-test-case-button litlingo-test-case-button-first focus:outline-none ${buttonType(
              'truePositives'
            )}`}
            onClick={(): void => {
              if (isSelected('truePositives')) {
                dispatch(
                  setURLParams({
                    [`${resource}__match_criterias`]: matchCriteriasParams.filter(
                      (criteria) => criteria !== 'true_positives'
                    ),
                    [`${resource}__offset`]: '0',
                  })
                );
              } else {
                dispatch(
                  setURLParams({
                    [`${resource}__match_criterias`]: [...matchCriteriasParams, 'true_positives'],
                    [`${resource}__offset`]: '0',
                  })
                );
              }
            }}
          >
            {true_positives}
          </button>
          <button
            type="button"
            data-testid="trueNegatives-button"
            className={`litlingo-test-case-button litlingo-test-case-button-third focus:outline-none ${buttonType(
              'trueNegatives'
            )}`}
            onClick={(): void => {
              if (isSelected('trueNegatives')) {
                dispatch(
                  setURLParams({
                    [`${resource}__match_criterias`]: matchCriteriasParams.filter(
                      (criteria) => criteria !== 'true_negatives'
                    ),
                    [`${resource}__offset`]: '0',
                  })
                );
              } else {
                dispatch(
                  setURLParams({
                    [`${resource}__match_criterias`]: [...matchCriteriasParams, 'true_negatives'],
                    [`${resource}__offset`]: '0',
                  })
                );
              }
            }}
          >
            {true_negatives}
          </button>
        </div>
        <div className="flex flex-col items-center">
          <span className="mb-0.5 font-bold text-litlingo-gray-5">Fail</span>
          <button
            type="button"
            data-testid="falseNegatives-button"
            className={`litlingo-test-case-button litlingo-test-case-button-second focus:outline-none ${buttonType(
              'falseNegatives'
            )}`}
            onClick={(): void => {
              if (isSelected('falseNegatives')) {
                dispatch(
                  setURLParams({
                    [`${resource}__match_criterias`]: matchCriteriasParams.filter(
                      (criteria) => criteria !== 'false_negatives'
                    ),
                    [`${resource}__offset`]: '0',
                  })
                );
              } else {
                dispatch(
                  setURLParams({
                    [`${resource}__match_criterias`]: [...matchCriteriasParams, 'false_negatives'],
                    [`${resource}__offset`]: '0',
                  })
                );
              }
            }}
          >
            {false_negatives}
          </button>
          <button
            type="button"
            data-testid="falsePositives-button"
            className={`litlingo-test-case-button litlingo-test-case-button-fourth litlingo-test-case-button-last focus:outline-none ${buttonType(
              'falsePositives'
            )}`}
            onClick={(): void => {
              if (isSelected('falsePositives')) {
                dispatch(
                  setURLParams({
                    [`${resource}__match_criterias`]: matchCriteriasParams.filter(
                      (criteria) => criteria !== 'false_positives'
                    ),
                    [`${resource}__offset`]: '0',
                  })
                );
              } else {
                dispatch(
                  setURLParams({
                    [`${resource}__match_criterias`]: [...matchCriteriasParams, 'false_positives'],
                    [`${resource}__offset`]: '0',
                  })
                );
              }
            }}
          >
            {false_positives}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestCaseStateFilter;
