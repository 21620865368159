/* eslint-disable camelcase */

import { addAndRemoveValuesToTree, showSuccessAlert } from 'actions';
import Modal from 'components/Modal';
import { advanceSearchOptions } from 'constants/envelope';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getNavParamsFromTree } from 'selectors/nav';
import { useSelector } from 'store';
import OptionBadgeFilter from './OptionBadgeFilter';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
};

const AdvancedSearchModal: React.FC<ComponentProps> = ({ isModalOpen, toggleModalOpen }) => {
  const dispatch = useDispatch();

  const [selectedFilters, setSelectedFilters] = React.useState<string[]>([
    'body',
    'subject',
    'attachment_text',
  ]);

  const { broad_search, subject } = useSelector(getNavParamsFromTree) as {
    broad_search: string;
    subject: string;
  };

  const { register, getValues, handleSubmit } = useForm<{
    search: string;
  }>({
    defaultValues: {
      search: broad_search || subject || '',
    },
  });

  const onSubmit = (): void => {
    const { search } = getValues();

    if (search.trim()) {
      if (selectedFilters.length) {
        const valuesToAdd: {
          field: string;
          value: string;
          label?: string;
          isSingleValue?: boolean;
        }[] = [];
        selectedFilters.forEach((field) => {
          valuesToAdd.push({
            field,
            value: search.trim(),
            label: search.trim(),
            isSingleValue: true,
          });
        });

        dispatch(
          addAndRemoveValuesToTree({
            valuesToAdd,
            fieldsToRemove: advanceSearchOptions
              .map((option) => option.id)
              .filter((field) => !selectedFilters.includes(field)),
          })
        );
      } else {
        dispatch(
          addAndRemoveValuesToTree({
            valuesToAdd: [
              {
                field: 'broad_search',
                value: search.trim(),
                label: search.trim(),
                isSingleValue: true,
              },
            ],
            fieldsToRemove: ['subject', 'attachment_text', 'uuid', 'body'],
          })
        );
      }
      toggleModalOpen();
    }
  };

  const handleSearchButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const handleOpenSyntaxResource = (): void => {
    window.open(
      'https://lit-public-static-content.s3.us-east-2.amazonaws.com/LitLingo+Search+Syntax+(1).pdf',
      '_blank'
    );
  };

  const handleCopy = async (): Promise<void> => {
    const regex = /(?<=")([A-Za-z0-9]+)(?=")/g;
    const text = getValues()
      .search.match(regex)
      ?.reduce((acc, cur, idx) => {
        if (idx === 0) return cur;
        return `${acc}\n${cur}`;
      }, '');

    if (text) {
      await navigator.clipboard.writeText(text);
      dispatch(showSuccessAlert('Terms copied to your clipboard'));
    }
  };

  const renderAdvancedSearch = (
    <div className="pt-2 pb-4 flex flex-col gap-6">
      <div className="flex flex-col items-start gap-1 text-body">
        <span>
          This advanced query allows you to create more complex searches through your data using
          elastic search.
        </span>
        <button
          type="button"
          className="italic text-litlingo-primary underline focus:outline-none"
          onClick={handleOpenSyntaxResource}
        >
          What types of queries are there?
        </button>
      </div>

      <form>
        <textarea
          id="search"
          ref={register()}
          name="search"
          rows={12}
          placeholder="Type your advanced query here."
          className="form-input w-full p-2 text-body rounded-md resize-none placeholder-italic placeholder-litlingo-gray-6 border-litlingo-gray-2"
        />
        <div className="flex flex-row gap-1 justify-between">
          <div className="flex flex-row gap-12">
            <div className="flex flex-row gap-1 h-5">
              <span className="text-body">Search in:</span>
              {advanceSearchOptions.map((field) => (
                <OptionBadgeFilter
                  key={field.id}
                  option={field}
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                />
              ))}
            </div>
            <button
              type="button"
              className={`text-litlingo-primary-120 underline focus:outline-none ${
                !selectedFilters.length ? 'text-litlingo-gray-3' : 'text-litlingo-primary-120'
              }`}
              onClick={(): void => setSelectedFilters([])}
              disabled={!selectedFilters.length}
            >
              Clear
            </button>
          </div>
          <button type="button" onClick={handleCopy} className="text-small">
            <span className="text-litlingo-primary-120 underline focus:outline-none">
              Copy for Identifier Terms
            </span>
          </button>
        </div>
      </form>
    </div>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderAdvancedSearch}
          title="Advanced Query"
          okButton
          okButtonText="Search"
          okButtonOnClick={handleSearchButton}
          toggleShowModal={toggleModalOpen}
          style={{ width: '900px' }}
        />
      )}
    </>
  );
};

export default AdvancedSearchModal;
