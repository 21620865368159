import { saveCustomer } from 'actions';
import WarningMsg from 'components/WarningMsg';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSaveCustomerLoading } from 'selectors/auth';
import UserNotificationMsgs from 'utils/userNotificationMsgs';

type ComponentProps = {
  unsaved: boolean;
  isSaved: boolean;
  handleSave?: (showConfirm?: boolean) => Promise<void>;
  className?: string;
};

const CustomerSaveButton: React.FC<ComponentProps> = ({
  unsaved,
  isSaved,
  handleSave,
  className,
}) => {
  const saveLoading = useSelector(getSaveCustomerLoading);
  const defaultClass = 'max-w-7xl mr-40 pr-2';
  const dispatch = useDispatch();

  const handleSaveFunc = async (): Promise<void> => {
    dispatch(saveCustomer({}));
  };

  return (
    <div className={`${className || defaultClass} text-left flex flex-row items-center`}>
      <WarningMsg
        message={UserNotificationMsgs.unsavedChanges}
        showWarning={unsaved}
        showLoading={saveLoading}
        showSavedMsg={isSaved}
      />

      <span className="shadow-sm rounded-md ml-3">
        <button
          onClick={(): Promise<void> => (handleSave ? handleSave() : handleSaveFunc())}
          type="button"
          data-testid="customer-save-button"
          className="button button--primary"
        >
          Save
        </button>
      </span>
    </div>
  );
};

export default CustomerSaveButton;
