import { setURLParams } from 'actions';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';

export type QueryNavbarOption = { label: string; value: string; route: string };

type ComponentProps = {
  options: QueryNavbarOption[];
};

const QueryNavbar: React.FC<ComponentProps> = ({ options }) => {
  const dispatch = useDispatch();

  const { kind } = useSelector(getNavParamsByResource(resourceQueryParamName.model));

  const handleChangeQuery = (option: QueryNavbarOption): void => {
    dispatch(setURLParams({ [`${resourceQueryParamName.model}__kind`]: option.value }));
  };

  const renderLinkOption = (option: QueryNavbarOption): JSX.Element => (
    <button
      type="button"
      className="font-header text-xss leading-4 font-bold text-litlingo-gray-5 border-b-4 border-transparent cursor-pointer pb-2 focus:outline-none"
      onClick={(): void => handleChangeQuery(option)}
    >
      {option.label}
    </button>
  );

  const renderSelectedOption = (option: QueryNavbarOption): JSX.Element => (
    <div className="font-header text-xss leading-4 font-bold text-litlingo-gray-6 border-b-4 border-litlingo-orange pb-2">
      {option.label}
    </div>
  );

  return (
    <div className="flex items-center gap-8">
      {options.map((option) => {
        if (option.value === kind) {
          return renderSelectedOption(option);
        }
        return renderLinkOption(option);
      })}
    </div>
  );
};

export default QueryNavbar;
