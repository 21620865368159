import LoadingIndicator from 'components/LoadingIndicator';
import React from 'react';
import type { TagGroup } from 'types';
import TagGroupListItem from './TagGroupListItem';

interface Props {
  tagGroups: TagGroup[];
  loading: boolean;
}

const TagGroupList: React.VFC<Props> = ({ tagGroups, loading }) => (
  <div className="flex flex-col gap-2 mt-4">
    {loading ? (
      <LoadingIndicator className="m-auto mt-20" size="20" />
    ) : (
      tagGroups.map((tagGroup) => <TagGroupListItem key={tagGroup.uuid} tagGroup={tagGroup} />)
    )}
  </div>
);

export default TagGroupList;
