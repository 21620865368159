/* eslint-disable max-lines */
/* eslint-disable camelcase */
/* eslint-disable react/require-default-props */
import { Tag } from '@litlingo/client';
import { addTagToFilterItems, fetchAllTagGroups, fetchTagsForFilter } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import useClickOutside from 'components/utils/useClickOutside';
import { DOWN_ARROW_ICON, SEARCH_ICON } from 'constants/filterIcons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { useDispatch, useSelector } from 'react-redux';
import { getFetchAllTagsLoading, getTagsForFilters } from 'selectors/tags';
import logEvent from 'utils/analytics';

type ResourceFilterProps = {
  className?: string;
  dataTestid?: string;
  isOpening?: boolean;
  setTagsSelected: React.Dispatch<React.SetStateAction<string[]>>;
  tagsSelected: string[];
  setTagsUnselected: React.Dispatch<React.SetStateAction<string[]>>;
  tagsUnselected: string[];
};

const TagsDropdown: React.FC<ResourceFilterProps> = (props) => {
  const {
    className,
    dataTestid,
    isOpening,
    setTagsSelected,
    tagsUnselected,
    tagsSelected,
    setTagsUnselected,
  } = props;

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
  const [firstLoad, setFirstLoad] = useState(true);

  const inputRef = useRef<HTMLInputElement>(null);
  const allTags = useSelector(getTagsForFilters);

  const loadingTags = useSelector(getFetchAllTagsLoading);

  const loading = loadingTags;

  const filterTagsItems = [...tagsSelected];
  const notFilterTagsItems = [...tagsUnselected];

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useClickOutside(wrapperRef, handleClickOutside);

  useEffect(() => {
    if (isOpen && firstLoad) {
      setFirstLoad(false);
      dispatch(
        fetchAllTagGroups({
          limit: 25,
          broad_search: debouncedSearchValue,
        })
      );
      dispatch(
        fetchTagsForFilter({
          searchValue: debouncedSearchValue,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen]);

  useEffect(() => {
    if (isOpen) {
      dispatch(
        fetchAllTagGroups({
          limit: 25,
          broad_search: debouncedSearchValue,
        })
      );
      dispatch(
        fetchTagsForFilter({
          searchValue: debouncedSearchValue,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, debouncedSearchValue]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedSearchValue(searchValue), 300);
    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  useEffect(() => {
    if (isOpening) {
      inputRef.current?.focus();
    }
  }, [inputRef, isOpening]);

  useEffect(() => {
    const scrollHandler = (e: WheelEvent): void => {
      e.stopPropagation();
    };

    const ref = wrapperRef.current;

    ref?.addEventListener('wheel', scrollHandler, { passive: false });
    return () => ref?.removeEventListener('wheel', scrollHandler);
  }, []);

  const handleButtonClick = (): void => {
    if (isOpen) return;
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    logEvent('envelopes-list-filter-models');

    const { shiftKey: exclude } = event.nativeEvent;
    const { id: state } = event.currentTarget;

    if (exclude && filterTagsItems.includes(state)) {
      return;
    }

    const item = allTags.find((t) => t.uuid === state);

    event.currentTarget.dataset.checked =
      event.currentTarget.dataset.checked === 'true' ? 'false' : 'true';

    if (event.currentTarget.dataset.checked === 'true') {
      dispatch(addTagToFilterItems(item as Tag));
      if (exclude) {
        const newTags: string[] = [...tagsUnselected, item?.uuid as string];
        setTagsUnselected(newTags);
      } else {
        const newTags: string[] = [...tagsSelected, item?.uuid as string];
        setTagsSelected(newTags);
      }
    } else if (tagsSelected.includes(item?.uuid as string)) {
      const newTags: string[] = tagsSelected.filter((t) => t !== item?.uuid);
      setTagsSelected(newTags);
    } else {
      const newTags: string[] = tagsUnselected.filter((t) => t !== item?.uuid);
      setTagsUnselected(newTags);
    }
    event.stopPropagation();
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e?.target.value.toLowerCase();
    setSearchValue(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();

      // If allowNew is true and the input value is not already a tag, add it as a new tag
      if (searchValue.trim() && !tagsSelected.find((tag) => tag === searchValue)) {
        setTagsSelected([...tagsSelected, searchValue]);
        setSearchValue('');
      }
    }
  };

  return (
    <>
      <div ref={wrapperRef} className={`flex flex-col w-44 ${className || ''}`}>
        <div className="relative flex flex-row justify-start items-center w-full min-h-8 rounded px-2  bg-litlingo-white envelope-list-tags">
          <div className="w-6">{SEARCH_ICON()}</div>
          <input
            ref={inputRef}
            id="search"
            name="search"
            className=" react-tags__search-input max-h-5 w-full envelope-list-tags ml-1 font-normal text-base"
            placeholder="Search Tags"
            data-testid={dataTestid}
            type="text"
            onFocus={handleButtonClick}
            onChange={handleSearchChange}
            value={searchValue}
            autoComplete="off"
            onKeyDown={handleKeyDown}
          />
          <button
            type="button"
            className="focus:outline-none"
            onClick={(): void => setIsOpen(!isOpen)}
          >
            <span>{DOWN_ARROW_ICON}</span>
          </button>
        </div>

        {isOpen && (
          <>
            <LoadingOverlayWrapper active={loading} spinner={<LoadingIndicator />} fadeSpeed={0}>
              <div className="w-full max-h-filter z-100 mt-0.75 overflow-auto rounded-t bg-litlingo-white custom-scrollbar shadow-lg">
                <fieldset className="m-2 min-h-5">
                  {allTags.length ? (
                    [...allTags].map((item: Tag, idx) => (
                      <div key={item.uuid} className={idx !== 0 ? 'mt-2' : ''}>
                        <div
                          id={item.uuid}
                          role="button"
                          aria-hidden
                          className="relative flex items-center cursor-pointer"
                          data-checked={[...filterTagsItems, ...notFilterTagsItems].includes(
                            item.uuid
                          )}
                          onClick={(e): void => handleInputChange(e)}
                        >
                          <div className="text-sm leading-5 flex flex-col items-start justify-center cursor-pointer">
                            <div className="flex flex-row items-center cursor-pointer">
                              <div className="relative flex justify-start items-start">
                                <div className="flex items-center h-5 mr-1">
                                  <input
                                    id={item.uuid}
                                    data-testid={`${item.uuid}-checkbox`}
                                    type="checkbox"
                                    className={`form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out cursor-pointer ${
                                      notFilterTagsItems.includes(item.uuid)
                                        ? 'litlingo-checkbox-negated'
                                        : 'litlingo-checkbox'
                                    }`}
                                    checked={
                                      filterTagsItems.includes(item.uuid) ||
                                      notFilterTagsItems.includes(item.uuid)
                                    }
                                  />
                                </div>

                                <div className="flex flex-col text-sm leading-5">
                                  <span className="text-litlingo-gray-6 text-xxs text-left font-normal select-none break-word">
                                    {item.value}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-small">No tags found</div>
                  )}
                </fieldset>
              </div>
            </LoadingOverlayWrapper>

            <div className="flex self-end z-100 w-full -mb-2 bg-litlingo-white rounded-b shadow-lg">
              <span className="text-litlingo-gray-5 w-full font-normal text-xss italic text-right p-1">
                Shift + click to exclude
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TagsDropdown;
