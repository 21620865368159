import { SavedSearch, TagGroup, User } from '@litlingo/client';
import { reviewSetCreated } from 'actions/envelopeListView';
import {
  addUserToSavedSearch,
  removeUserFromSavedSearch,
  upsertSavedSearch,
} from 'actions/savedSearches';
import Modal from 'components/Modal';
import { TagGroups } from 'components/ReviewSetsManagerView/Sidebar/EditSidebar';

import UserLabels from 'components/User/UserLabels';
import UserSelect from 'components/User/UserSelect';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

type ComponentProps = {
  reviewSet: SavedSearch | null;
  isModalOpen: boolean;
  toggleModalOpen: () => void;
};

const AdditionalDetailsModal: React.FC<ComponentProps> = ({
  reviewSet,
  isModalOpen,
  toggleModalOpen,
}) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState(reviewSet?.users as User[]);
  const [tagGroups, setTagGroups] = useState([] as TagGroup[]);

  const handleDeleteUser = (user: User): void => {
    setUsers(users.filter((u) => u.uuid !== user.uuid));
    if (reviewSet) {
      dispatch(removeUserFromSavedSearch({ user, savedSearchId: reviewSet.uuid }));
    }
  };
  const handleSelectUser = (user: User, checked: boolean): void => {
    if (checked) {
      setUsers((userList) => [...userList, user]);
      if (reviewSet) {
        dispatch(addUserToSavedSearch({ user, savedSearchId: reviewSet.uuid }));
      }
    } else {
      handleDeleteUser(user);
    }
  };

  const handleDeleteTagGroup = (group: TagGroup): void => {
    setTagGroups(tagGroups.filter((g) => g.uuid !== group.uuid));
  };

  const handleSelectTagGroup = (group: TagGroup, checked: boolean): void => {
    if (checked) {
      setTagGroups([...tagGroups, group]);
    } else {
      handleDeleteTagGroup(group);
    }
  };

  const handleSave = (): void => {
    if (reviewSet?.config) {
      dispatch(
        upsertSavedSearch({
          ...reviewSet,
          config: {
            ...reviewSet?.config,
            tag_values_groups_uuids: tagGroups.map((g) => g.uuid),
          },
        })
      );
    }
    toggleModalOpen();
    dispatch(reviewSetCreated(false));
  };

  const handleSkip = (): void => {
    toggleModalOpen();
    dispatch(reviewSetCreated(false));
  };

  const renderAdditionalDetails = (
    <div className="flex flex-col gap-y-2">
      <div className="mt-4">
        <div>
          <div className="">{reviewSet?.name}</div>
          {reviewSet?.description && <div className="">{reviewSet.description}</div>}
        </div>
        <div className="mt-6">
          {reviewSet?.sample_size && (
            <div>{`Sample - ${reviewSet.sample_size}  of all, one time`}</div>
          )}
          <div>{reviewSet?.config?.default_count === -1 ? 'Shared Inbox' : 'Assignment'}</div>
          <div>{`${capitalize(reviewSet?.priority ?? '')} Priority`}</div>
        </div>
      </div>
      <div className="flex flex-col gap-1 mt-4 w-full">
        <span className="text-body">Assigned Reviewers</span>
        <div className="flex flex-row items-start w-full gap-5">
          <div className="w-44">
            <UserSelect
              selectUser={handleSelectUser}
              selectedUsers={users}
              className="min-w-44"
              roles={['reviewer', 'super-reviewer']}
            />
          </div>
          <UserLabels users={users} handleDelete={handleDeleteUser} className="flex-wrap" />
        </div>
      </div>

      <TagGroups
        tagGroups={tagGroups}
        handleSelectTagGroup={handleSelectTagGroup}
        handleDeleteTagGroup={handleDeleteTagGroup}
        setTagGroups={setTagGroups}
      />

      <div className="my-6">
        <span>{`*You’ll be able to find all your reviews in Messages > Review Management. There you can assign reviewers to the stream and add tag groups.`}</span>
      </div>
    </div>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          title="Add Additional Details?"
          body={renderAdditionalDetails}
          cancelButton
          cancelButtonText="Skip for Now"
          cancelButtonOnclick={handleSkip}
          okButton
          okButtonText="Save"
          okButtonOnClick={handleSave}
          toggleShowModal={toggleModalOpen}
          style={{
            width: '50rem',
          }}
        />
      )}
    </>
  );
};

export default AdditionalDetailsModal;
