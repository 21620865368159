import type { Policy, PolicyAction } from 'types';

const BASE_ACTIONS: PolicyAction[] = ['list', 'retrieve', 'create', 'update', 'delete'];
export const FIELD_IS_HIDDEN = 'FIELD_IS_HIDDEN';
export const FIELD_IS_PROTECTED = 'FIELD_IS_PROTECTED';
export const FIELD_IS_UNPROTECTED = 'FIELD_IS_UNPROTECTED';

export const meetsRolesReqs = (userRoles: string[], requiredRoles: string[]): boolean =>
  requiredRoles.some((item) => {
    if (Array.isArray(item)) {
      return item.every((role) => userRoles?.includes(role));
    }

    return userRoles?.includes(item);
  });

export const isActionAuthorized = (path: string, userRoles: string[], policy: Policy): boolean => {
  const [resource, action] = path.split('.');

  const actionError = new Error(`Action '${path}' is not present on policy`);
  if (policy[resource] == null) {
    throw actionError;
  }

  let actionRoles: string[] = [];
  const castedAction = action as PolicyAction;
  if (BASE_ACTIONS.includes(castedAction)) {
    const tempActionRoles = policy[resource][castedAction];
    if (tempActionRoles == null) {
      throw actionError;
    }
    actionRoles = tempActionRoles;
  } else {
    const { actions } = policy[resource];
    if (actions == null || actions[action] == null) {
      throw actionError;
    }

    actionRoles = actions[action];
  }

  return meetsRolesReqs(userRoles, actionRoles);
};

export const getFieldAuthorization = (
  path: string,
  userRoles: string[],
  policy: Policy
): 'FIELD_IS_HIDDEN' | 'FIELD_IS_PROTECTED' | 'FIELD_IS_UNPROTECTED' => {
  const [resource, field] = path.split('.');

  if (!policy || !policy[resource]) return FIELD_IS_UNPROTECTED;

  const { protectedFields } = policy[resource];
  if (policy[resource] == null || !protectedFields || protectedFields[field] == null) {
    return FIELD_IS_UNPROTECTED;
  }

  const fieldSpec = protectedFields[field];

  if (fieldSpec.unprotected != null && meetsRolesReqs(userRoles, fieldSpec.unprotected)) {
    return FIELD_IS_UNPROTECTED;
  }

  if (fieldSpec.protected != null && meetsRolesReqs(userRoles, fieldSpec.protected)) {
    return FIELD_IS_PROTECTED;
  }

  return FIELD_IS_HIDDEN;
};

export const isFieldUnprotected = (path: string, userRoles: string[], policy: Policy): boolean =>
  getFieldAuthorization(path, userRoles, policy) === FIELD_IS_UNPROTECTED;
