/* eslint-disable jsx-a11y/label-has-associated-control */
import { PLUS_ICON } from 'constants/annotator';
import { FAIL_UPLOAD, SUCCESS_UPLOADED } from 'constants/customerIcons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { GlobalState } from 'reducers';
import { uploadFileS3 } from 'reducers/fileUploads';
import {
  getFileUploadsActualProvider,
  getFileUploadsError,
  getFileUploadsProgress,
  getFileUploadsSuccess,
} from 'selectors/fileUploads';

type ComponentProps = {
  provider: 'o365' | 'zendesk';
};

const CustomerEditUploadS3: React.FC<ComponentProps> = ({ provider }) => {
  const dispatch = useDispatch();

  const uploading = useSelector((state: GlobalState) => state.fileUploads.uploading);
  const progress = useSelector((state: GlobalState) => getFileUploadsProgress(state));
  const success = useSelector((state: GlobalState) => getFileUploadsSuccess(state));
  const error = useSelector((state: GlobalState) => getFileUploadsError(state));
  const actualProvider = useSelector((state: GlobalState) => getFileUploadsActualProvider(state));

  const [file, setFile] = useState<File | null>(null);
  const [countFiles, setCountFiles] = useState(0);

  const formatProgress = (percent: number): string => `${Math.trunc(percent)} %`;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files != null) {
      setFile(event.target.files[0]);
      setCountFiles(countFiles + 1);
    }
  };

  useEffect(() => {
    if (file != null) {
      dispatch(uploadFileS3({ file, provider }));
    }
  }, [file, provider, dispatch]);

  const disabled = uploading || countFiles >= 2;

  const successMsg = (
    <>
      {success && (
        <div className="flex flex-row items-center">
          {SUCCESS_UPLOADED}
          <span className="litlingo-green-color text-xs font-bold pl-4">Upload successful</span>
        </div>
      )}
    </>
  );

  return (
    <div className="px-5 py-4">
      <div className="flex justify-between">
        <div className="text-sm overflow-auto w-1/2">
          <p>Secure File Upload</p>
          <p>File should be .zip or .json</p>
        </div>

        <div className="flex flex-col">
          {successMsg}
          {countFiles > 1 && successMsg}
          <div className="flex flex-row">
            {uploading && progress != null && (
              <span className="text-litlingo-primary mr-3">{formatProgress(progress)}</span>
            )}
            {uploading && (
              <p className="litlingo-red-color text-xs font-bold">
                Uploading... Do not close this page
              </p>
            )}
          </div>

          {error != null && actualProvider === provider && (
            <div className="flex flex-row items-center">
              {FAIL_UPLOAD}
              <span className="litlingo-red-color text-xs font-bold pl-4">Upload error</span>
            </div>
          )}
          {!disabled && (
            <div className="button button--secondary text litlingo-blue-border mt-2">
              <label className="flex flex-row items-center cursor-pointer">
                {PLUS_ICON}
                <span className="ml-2 text text--primary">
                  Select {`${countFiles === 1 ? 'another' : ''}`} file
                </span>
                <input
                  data-testid="zendesk-upload-file-button"
                  type="file"
                  className="hidden"
                  onChange={handleInputChange}
                />
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerEditUploadS3;
