import { deleteURLParams, setURLParams } from 'actions';
import Select from 'components/Select';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNavWidgetFilters } from 'selectors/nav';
import { getFilterDropdownOptions } from 'utils/dashboard';
import { v4 as uuidv4 } from 'uuid';

type WidgetConfigFilterProps = {
  widgetId: string;
  allLabel: string;
  dimension: string;
  defaultValue?: string;
  options?: { [key: string]: string }[];
};

const WidgetConfigFilter: React.FC<WidgetConfigFilterProps> = (props) => {
  const { widgetId, dimension, defaultValue, options: filterOptions, allLabel } = props;

  const dispatch = useDispatch();
  const filters = useSelector(getNavWidgetFilters(widgetId));

  useEffect(() => {
    if (filters[dimension] == null && defaultValue != null) {
      const param = `${resourceQueryParamName.widgets}__${widgetId}__${dimension}`;
      dispatch(setURLParams({ [param]: defaultValue }));
    }
    // we want this hook to run only when the component is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value: string): void => {
    const param = `${resourceQueryParamName.widgets}__${widgetId}__${dimension}`;

    if (value === 'all_data') {
      dispatch(deleteURLParams([param]));
    } else {
      dispatch(setURLParams({ [param]: value }));
    }
  };

  const options = getFilterDropdownOptions(filterOptions ?? null, allLabel);

  const param = filters[dimension];
  const value = Array.isArray(param) ? param[0] : param;

  return (
    <>
      {options.map((option) => (
        <div className="ml-1" key={uuidv4()}>
          <Select
            onChange={handleChange}
            options={option}
            className="max-h-72"
            value={value ?? 'all_data'}
          />
        </div>
      ))}
    </>
  );
};

type WidgetConfigFilterWrapperProps = {
  widgetId: string;
  dimension: string;
  allLabel?: string;
  defaultValue?: string;
  options?: { [key: string]: string }[];
};

const WidgetConfigFilterWrapper: React.FC<WidgetConfigFilterWrapperProps> = (props) => {
  const { widgetId, dimension, allLabel, defaultValue, options } = props;

  return (
    <WidgetConfigFilter
      widgetId={widgetId}
      dimension={dimension}
      allLabel={allLabel ?? 'Group by'}
      defaultValue={defaultValue}
      options={options}
    />
  );
};

export default WidgetConfigFilterWrapper;
