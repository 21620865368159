import {
  fetchAllAnnotators,
  fetchAllCampaignRuleOutcomes,
  fetchAllCampaigns,
  fetchAllRules,
} from 'actions';
import useClickOutside from 'components/utils/useClickOutside';
import keyMap from 'constants/configHotKeys';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useDispatch, useSelector } from 'react-redux';
import { getAnnotators } from 'selectors/annotator';
import { getCampaignRuleOutcomesList } from 'selectors/campaignRuleOutcomes';
import { getCampaignsList } from 'selectors/campaigns';
import { getRulesList } from 'selectors/rules';
import type { Annotator, Campaign, MCampaign, MRule, NormalizedAnnotator, Rule } from 'types';

type ComponentProps = {
  resource: string;
  setSelectedId: React.Dispatch<React.SetStateAction<string | null>>;
  entity?: Campaign | Rule | Annotator;
  cleanSearchValue?: boolean;
  maxHeight?: number;
};

const SearchInputByResource: React.FC<ComponentProps> = ({
  resource,
  setSelectedId,
  entity,
  cleanSearchValue,
  maxHeight = 120,
}) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [currentItem, setCurrentItem] = useState('');
  const campaigns = useSelector(getCampaignsList);
  const rules = useSelector(getRulesList);
  const annotators = useSelector(getAnnotators);
  const outcomes = useSelector(getCampaignRuleOutcomesList);
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, handleClickOutside);

  useEffect(() => {
    if (resource === 'Use Case') {
      dispatch(fetchAllCampaigns({ limit: '-1' }));
    }
    if (resource === 'Model') {
      dispatch(fetchAllRules({ limit: '-1' }));
      dispatch(fetchAllCampaignRuleOutcomes({ limit: -1 }));
    }
    if (resource === 'Identifier') {
      dispatch(fetchAllAnnotators({ limit: '-1' }));
    }
  }, [dispatch, resource]);

  useEffect(() => {
    if (cleanSearchValue) setSearchValue('');
  }, [resource, cleanSearchValue]);

  useEffect(() => {
    if (entity) {
      setSelectedId(entity.uuid);
      setSearchValue(entity.name);
    }
  }, [entity, setSelectedId, setSearchValue]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
    setIsOpen(true);
    setSelectedId(null);
    setCurrentItem('');
  };

  const handleChange = (item: Campaign | Rule | NormalizedAnnotator): void => {
    const { uuid, name } = item;
    setSelectedId(uuid);
    setIsOpen(false);
    setSearchValue(name);
  };

  const getData = (): NormalizedAnnotator[] | MCampaign[] | MRule[] => {
    if (resource === 'Use Case') {
      return (
        campaigns.filter(({ name }) => name.toLowerCase().includes(searchValue.toLowerCase())) || []
      );
    }
    if (resource === 'Model') {
      return (
        rules.filter(
          ({ name, uuid }) =>
            name.toLowerCase().includes(searchValue.toLowerCase()) &&
            outcomes.find((outcome) => outcome.rule_uuid === uuid)
        ) || []
      );
    }
    return (
      annotators.filter(({ name }) => name.toLowerCase().includes(searchValue.toLowerCase())) || []
    );
  };

  const getFirstItem = (): HTMLElement => {
    const table = document.getElementById('search-input-by-resource-table') as HTMLTableElement;
    return table?.children[0] as HTMLElement;
  };

  const getCurrenItem = (): HTMLElement => document.getElementById(currentItem) as HTMLElement;

  const handlers = {
    PRESS_KEY_DOWN: (): void => {
      if (currentItem === '') {
        const firstItem = getFirstItem();
        if (firstItem) {
          setCurrentItem(firstItem.id);
        }
      } else {
        let curItem = getCurrenItem();
        if (!curItem) {
          setCurrentItem('');
          curItem = getFirstItem();
        }
        const nextItem = curItem?.nextElementSibling;
        if (nextItem) {
          setCurrentItem(nextItem.id);
          nextItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },
    PRESS_KEY_UP: (): void => {
      if (currentItem === '') return;
      const curItem = getCurrenItem();
      const prevItem = curItem?.previousElementSibling;
      if (prevItem) {
        setCurrentItem(prevItem.id);
        prevItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    PRESS_KEY_ENTER: (): void => {
      if (currentItem === '') return;
      // @ts-ignore
      const item = getData().filter(
        (cur: Campaign | Rule | NormalizedAnnotator) => cur.uuid === currentItem
      );
      handleChange(item[0]);
    },
  };

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges />
      <div ref={wrapperRef} className="relative inline-block text-left">
        <div className="origin-top-left left-0 w-64 rounded-md">
          <div className="rounded-md bg-white">
            <div
              className="px-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg className="h-4 w-4 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M12.9 14.32a8 8 0 111.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 108 2a6 6 0 000 12z" />
                  </svg>
                </div>
                <input
                  id="search"
                  name="search"
                  className="form-input block w-full pl-10 sm:text-sm sm:leading-5"
                  onChange={handleSearchChange}
                  autoComplete="off"
                  value={searchValue}
                />
              </div>
              {isOpen && (
                <div
                  style={{ maxHeight: `${maxHeight}px` }}
                  className="overflow-y-scroll origin-top-left absolute left-0 w-64 rounded-md shadow-lg z-auto"
                >
                  <table className="min-w-full">
                    <thead>
                      <tr />
                    </thead>
                    <tbody className="bg-white text-left" id="search-input-by-resource-table">
                      {getData().length > 0 &&
                        // @ts-ignore
                        getData()
                          .slice(0, 25)
                          // @ts-ignore
                          .map((item: Campaign | Rule | NormalizedAnnotator) => {
                            const ruleOutcome = outcomes.find(
                              (outcome) => outcome.rule_uuid === item.uuid
                            );

                            return (
                              <tr
                                id={item.uuid}
                                key={item.uuid}
                                onClick={(): void => handleChange(item)}
                                className={`order-t border-gray-200 hover:bg-gray-200 table-row ${
                                  currentItem === item.uuid ? 'bg-gray-200' : ''
                                }`}
                              >
                                <td className="px-4 py-2 border-b border-gray-200">
                                  <div className="flex flex-col justify-center">
                                    <div className="text-xs leading-5 font-medium text-gray-900">
                                      {item.name}
                                    </div>
                                    {ruleOutcome && (
                                      <div className="text-xs text-gray-500 select-none break-all">
                                        {ruleOutcome.campaign?.name}
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchInputByResource;
