import { fetchSingleCustomer } from 'actions';
import Card from 'components/Card';
import CustomerEditSecretModal from 'components/Customer/CustomerEditSecretModal';
import LinkLookup from 'components/LinkLookup';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import { LEFT_ARROW_ICON, OK_ICON, WRONG_ICON } from 'constants/envelopeIcons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCustomer } from 'selectors/auth';
import { useSelector } from 'store';

const CustomerEditSlack: React.FC = () => {
  const dispatch = useDispatch();

  const [isModalOpen, setModalOpen] = useState(false);

  const customer = useSelector(getCustomer);
  const apiKeyName = 'slack_bot_token';
  const slackToken = customer?.secrets_summary?.[apiKeyName];

  useEffect(() => {
    if (!customer.name) {
      dispatch(fetchSingleCustomer);
    }
  }, [customer, dispatch]);

  const SLACK_ICON = (
    <svg
      className="h-6 w-6 text font-bold inline-flex"
      fill="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      stroke="currentColor"
      focusable="false"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 1024 1024"
    >
      <path
        fill="#fff"
        d="M409.4 128c-42.4 0-76.7 34.4-76.7 76.8c0 20.3 8.1 39.9 22.4 54.3c14.4 14.4 33.9 22.5 54.3 22.5h76.7v-76.8c0-42.3-34.3-76.7-76.7-76.8zm0 204.8H204.7c-42.4 0-76.7 34.4-76.7 76.8s34.4 76.8 76.7 76.8h204.6c42.4 0 76.7-34.4 76.7-76.8c.1-42.4-34.3-76.8-76.6-76.8zM614 486.4c42.4 0 76.8-34.4 76.7-76.8V204.8c0-42.4-34.3-76.8-76.7-76.8c-42.4 0-76.7 34.4-76.7 76.8v204.8c0 42.5 34.3 76.8 76.7 76.8zm281.4-76.8c0-42.4-34.4-76.8-76.7-76.8S742 367.2 742 409.6v76.8h76.7c42.3 0 76.7-34.4 76.7-76.8zm-76.8 128H614c-42.4 0-76.7 34.4-76.7 76.8c0 20.3 8.1 39.9 22.4 54.3c14.4 14.4 33.9 22.5 54.3 22.5h204.6c42.4 0 76.7-34.4 76.7-76.8c.1-42.4-34.3-76.7-76.7-76.8zM614 742.4h-76.7v76.8c0 42.4 34.4 76.8 76.7 76.8c42.4 0 76.8-34.4 76.7-76.8c.1-42.4-34.3-76.7-76.7-76.8zM409.4 537.6c-42.4 0-76.7 34.4-76.7 76.8v204.8c0 42.4 34.4 76.8 76.7 76.8c42.4 0 76.8-34.4 76.7-76.8V614.4c0-20.3-8.1-39.9-22.4-54.3c-14.4-14.4-34-22.5-54.3-22.5zM128 614.4c0 20.3 8.1 39.9 22.4 54.3c14.4 14.4 33.9 22.5 54.3 22.5c42.4 0 76.8-34.4 76.7-76.8v-76.8h-76.7c-42.3 0-76.7 34.4-76.7 76.8z"
      />
    </svg>
  );

  const header = (
    <div className="flex justify-between">
      <div className="pt-5 pb-12 flex items-center">
        <LinkLookup
          data-testid="customer-edit-slack-left-button"
          routeName="customer"
          className="mx-6 focus:outline-none"
        >
          {LEFT_ARROW_ICON}
        </LinkLookup>
        <span className="text text--large text--bold">Slack</span>
      </div>
    </div>
  );

  const apiKey = (
    <div className="px-5 py-4">
      <div className="flex justify-between items-center">
        <div>Bot token</div>
        <div className="flex items-center">
          <span>******</span>
          <div className="mx-8">{slackToken ? OK_ICON : WRONG_ICON}</div>
          <button
            data-testid="slack-bot-token-edit-button"
            type="button"
            onClick={(): void => setModalOpen(true)}
            className="button button--primary button--blue-textfocus:outline-none"
          >
            {slackToken ? 'Replace' : 'Setup'}
          </button>
        </div>
      </div>
    </div>
  );

  const addLitlingo = (
    <div className="px-5 py-4">
      <div className="flex justify-between items-center">
        <div>Add Litlingo to Slack</div>
        <div className="flex">
          <a
            data-testid="slack-add-litlingo-button"
            className="button button--primary button--blue-textfocus:outline-none"
            target="_blank"
            rel="noopener noreferrer"
            // @ts-expect-error FIXME: add type to client after v6
            href={customer.slack_install_link}
          >
            <div className="mr-2">{SLACK_ICON}</div>
            Add
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <div className="litlingo-gray-bg-color-4">
      <Navbar />
      <div
        className="flex flex-row overflow-auto"
        style={{
          minHeight: 'calc(100vh - 4rem)',
          height: 'calc(100vh - 4rem)',
        }}
      >
        <SettingSidebar />
        <div className="mt-0 mx-auto min-w-2xl w-8/12 max-w-6xl text-left">
          <Card items={[header, apiKey, addLitlingo]} />
          {isModalOpen && (
            <CustomerEditSecretModal
              secretKeyName={apiKeyName}
              toggleShowModal={(): void => setModalOpen(!isModalOpen)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerEditSlack;
