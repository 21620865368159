import React from 'react';

type ComponentProps = {
  type: 'low' | 'medium' | 'high';
};

const prioritiesMap = {
  low: {
    color: 'litlingo-primary-60',
    text: 'Low',
    textColor: 'litlingo-gray-6',
  },
  medium: {
    color: 'litlingo-secondary-100',
    text: 'Medium',
    textColor: 'litlingo-gray-6',
  },
  high: {
    color: 'litlingo-alert',
    text: 'High',
    textColor: 'white',
  },
};

const PriorityTags: React.FC<ComponentProps> = ({ type }) => {
  const priority = prioritiesMap[type];

  return (
    <div className={`py-0.5 px-2 rounded text-small bg-${priority.color}`}>
      <span className={`text-${priority.textColor}`}>{priority.text}</span>
    </div>
  );
};

export default PriorityTags;
