export const DOMAINS = ['litlingo.com', 'meltstudio.co'];
export const CUSTOMER_UUIDS_WHITELIST = [
  'a659b252-ec51-4008-bb1f-2ae3779f3a82',
  'bc88d774-b2fe-4264-8fea-eb79290a4750',
  'd2432204-b146-4093-a67a-87b6ca3f2e1b',
  '7c165096-f37d-449c-83ec-2575df6a244f',
  '57579a22-b734-4bc7-96c3-3e5b5713b79f',
];
export const MICROSOFT_TENANT_IDS_WHITELIST = [
  '4f5d8c00-2c4b-4a71-8d10-976089aefe63',
];

export const isCustomerAccount = (
  customerId: string,
  userEmail: string
): boolean => {
  const domain = userEmail.split('@')[1];

  return (
    DOMAINS.includes(domain) && !CUSTOMER_UUIDS_WHITELIST.includes(customerId)
  );
};
