import { undeleteResource } from 'actions';
import AlertsContext from 'contexts/AlertsContext';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import Transition from 'styles/Transition';
import AdvancedQueryAlert from './AdvancedQueryAlert';

const AlertWithHooks: React.FC = () => {
  const dispatch = useDispatch();
  const { alert, dismissAlert } = useContext(AlertsContext);

  if (alert == null) {
    return null;
  }

  const { message, type, id, resource } = alert;

  const handleCloseAlert = (): void => {
    dismissAlert();
  };

  const handleUndeleteResource = (): void => {
    if (resource && id) {
      // REDUX
      dispatch(undeleteResource({ resource, id }));
    }
  };

  const color = type === 'error' ? 'red' : 'green';

  if (type === 'query') return <AdvancedQueryAlert />;

  return (
    <div className="text-center z-alert fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
      <Transition
        show
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto">
          <div className={`rounded-md bg-${color}-50 p-4`}>
            <div className="flex justify-between">
              <div className="flex">
                <div className="flex-shrink-0">
                  {type === 'error' && (
                    <svg className="h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                  {(type === 'success' || type === 'undelete') && (
                    <svg className="h-5 w-5 text-green-400" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </div>
                <div className={`ml-3 text text-${color}-800`}>
                  {message}{' '}
                  {type === 'undelete' && (
                    <span>
                      ,{' '}
                      <span
                        className="hover:underline"
                        role="button"
                        aria-hidden="true"
                        onClick={handleUndeleteResource}
                      >
                        click here to undelete
                      </span>
                    </span>
                  )}
                </div>
              </div>
              <div
                className="flex cursor-pointer h-5"
                role="button"
                aria-hidden="true"
                onClick={handleCloseAlert}
              >
                <svg className="w-3" viewBox="0 0 409.806 409.806">
                  <path d="M228.929 205.01L404.596 29.343c6.78-6.548 6.968-17.352.42-24.132-6.548-6.78-17.352-6.968-24.132-.42-.142.137-.282.277-.42.42L204.796 180.878 29.129 5.21c-6.78-6.548-17.584-6.36-24.132.42-6.388 6.614-6.388 17.099 0 23.713L180.664 205.01 4.997 380.677c-6.663 6.664-6.663 17.468 0 24.132 6.664 6.662 17.468 6.662 24.132 0l175.667-175.667 175.667 175.667c6.78 6.548 17.584 6.36 24.132-.42 6.387-6.614 6.387-17.099 0-23.712L228.929 205.01z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default AlertWithHooks;
