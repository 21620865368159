import MATCHER_TYPE, { ERROR_ICON, PLUS_ICON, WARNING_UNSAVED_ICON } from 'constants/annotator';
import React from 'react';
import type { MLanguageMatcher, UUID } from 'types';

type ComponentProps = {
  languageMatchers: MLanguageMatcher[];
  languageMatcherFilter: string;
  highlightedLanguageMatcher: string | undefined;
  setLanguageMatcherFilter: React.Dispatch<React.SetStateAction<string>>;
  addLocalLanguageMatcher: () => void;
  handleLanguageMatcherClick: (languageMatcherId: UUID) => void;
};

const LanguageMatcherSidebarList: React.FC<ComponentProps> = ({
  languageMatchers,
  languageMatcherFilter,
  highlightedLanguageMatcher,
  setLanguageMatcherFilter,
  addLocalLanguageMatcher,
  handleLanguageMatcherClick,
}) => {
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setLanguageMatcherFilter(e.target.value);
  };

  const renderStatus = (status: keyof typeof MATCHER_TYPE): JSX.Element => {
    let icon;
    let text;
    if (status in MATCHER_TYPE) {
      icon = MATCHER_TYPE[status].icon;
      text = MATCHER_TYPE[status].name;
    }
    return (
      <div className="flex flex-row items-center">
        {icon}
        <span className="pl-1 text-xs  text">{text}</span>
      </div>
    );
  };

  return (
    <div className="w-1/4 flex flex-col border-r relative">
      <div className="flex flex-col border-b">
        <div className="px-5 my-5 w-full">
          <input
            className="border w-full p-2 rounded-lg text form-input"
            placeholder="Filter by term usage"
            onChange={handleFilterChange}
            value={languageMatcherFilter}
          />
        </div>
      </div>
      <div className="flex flex-col w-full overflow-y-auto" style={{ height: '75%' }}>
        {languageMatchers.length > 0 ? (
          languageMatchers.map((languageMatcher) => (
            <div
              key={languageMatcher.uuid}
              className={`flex flex-row border-b ${
                languageMatcher.uuid === highlightedLanguageMatcher
                  ? 'litlingo-annotator-selected-matcher-bg-color'
                  : ''
              }`}
            >
              <div
                className={`w-2 h-full bg-litlingo-primary ${
                  languageMatcher.uuid !== highlightedLanguageMatcher
                    ? 'litlingo-gray-bg-color'
                    : ''
                }`}
              />
              <div
                key={languageMatcher.uuid}
                aria-hidden="true"
                className="flex flex-col pl-6 pr-3 py-3 cursor-pointer w-full overflow-hidden"
                onClick={(): void => handleLanguageMatcherClick(languageMatcher.uuid)}
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text text-sm truncate">
                    {languageMatcher.name}
                  </span>
                  <div className="flex flex-row">
                    <div className="mx-2">{languageMatcher.localError && ERROR_ICON}</div>
                    {!languageMatcher.localError && !languageMatcher.saved && WARNING_UNSAVED_ICON}
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="text text--extra-small">{languageMatcher.type}</span>
                  {renderStatus(languageMatcher.status)}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="flex justify-center mt-6">
            <span className="text-sm font-semibold">No language matchers to show</span>
          </div>
        )}
      </div>
      <div className="border-t min-h-12 w-full justify-center relative flex items-center">
        <button
          type="button"
          onClick={addLocalLanguageMatcher}
          className="p-2 focus:outline-none flex flex-row items-center litlingo-annotator-add-matcher-button"
          data-testid="add-matcher-button"
        >
          {PLUS_ICON}
          <span className="ml-1 text text--extra-small text--primary">Add Matcher</span>
        </button>
      </div>
    </div>
  );
};

export default LanguageMatcherSidebarList;
