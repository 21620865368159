import { discardChanges } from 'actions';
import React from 'react';
import { useDispatch } from 'react-redux';

const RuleManagerDiscardChangesButton: React.FC = () => {
  const dispatch = useDispatch();

  const handleUndoClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    evt.stopPropagation();
    dispatch(discardChanges());
  };

  return (
    <button
      type="button"
      onClick={(evt): void => handleUndoClick(evt)}
      className="button button--secondary h-8 font-bold"
      data-testid="rule-mananger-discard"
    >
      Cancel
    </button>
  );
};

export default RuleManagerDiscardChangesButton;
