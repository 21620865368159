import { BarExtendedDatum, BarMouseEventHandler, ResponsiveBar } from '@nivo/bar';
import { allowedReadOnlyDimensions, metricsLinksMap } from 'constants/dashboard';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getNavParamsByResource, getNavWidgetFilters } from 'selectors/nav';
import { getPermissionsPolicy } from 'selectors/permissions';
import { DefaultWidgetComponentProps } from 'types';
import logEvent from 'utils/analytics';
import { buildHistogramDataFromMetrics } from 'utils/dashboard';
import { isActionAuthorized } from 'utils/permissions';
import { useHistory } from 'utils/urls';

const HistogramSingleMetricWidget: React.FC<DefaultWidgetComponentProps> = (props) => {
  const { widgetData, widgetId, order = 'reversed', queryData, nivoProps } = props;
  const history = useHistory();
  const user = useSelector(getUser);
  const widgetFilters = useSelector(getNavWidgetFilters(widgetId));
  const customerDomain = useSelector(getCustomerDomain);
  const filters = useSelector(getNavParamsByResource(resourceQueryParamName.metrics));
  const policy = useSelector(getPermissionsPolicy);

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  const processedData = buildHistogramDataFromMetrics(widgetData);

  const handleClick = (datum: BarExtendedDatum): void => {
    const [id, type] = widgetId.split('--');
    logEvent(`Dashboard-click-${id}-${type}`);

    // this widget only ever has a single metric config
    // so we pluck the first query config
    const query = queryData.queries[0];
    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info == null || !isActionAuthorized(info.action, user.roles, policy)) {
      return;
    }

    const series = widgetData.results[0];

    if (series == null) {
      return;
    }

    const start = moment.utc(queryData.start_time * 1000).format(FULL_DATE_FORMAT);
    const end = moment.utc(queryData.end_time * 1000).format(FULL_DATE_FORMAT);

    const dimensions: Record<string, string | string[]> = {};
    if (info.dimensions != null) {
      // eslint-disable-next-line no-debugger

      Object.entries(info.dimensions).forEach(([dimension, param]) => {
        const allowed = allowedReadOnlyDimensions[query.metric];

        // if allowedDimension is not defined or the dimension is not included,
        // just ignore it
        if (allowed == null || !allowed.includes(dimension)) {
          return;
        }

        let value: string | string[] = '';

        if (filters[dimension] != null) {
          const topValue = filters[dimension];
          value = topValue;
        }

        if (widgetFilters[dimension] != null) {
          const widgetValue = filters[dimension];
          value = widgetValue;
        }

        if (query.group_by === dimension) {
          // @ts-expect-error Value type is not an string, we turn this
          // into an object in order get the id of the resource
          value = datum.data.category.value;
        }

        dimensions[`${info.resource}__${param}`] = value;
      });
    }

    history.pushLookup({
      customerDomain,
      routeName: info.route,
      queryParams: {
        ...dimensions,
        [`${info.resource}__created_after`]: start,
        [`${info.resource}__created_before`]: end,
        ...dimensions,
      },
    });
  };

  const handleMouseEnter = (
    _datum: BarExtendedDatum,
    event: React.MouseEvent<SVGRectElement, MouseEvent>
  ): void => {
    const [id, type] = widgetId.split('--');
    logEvent(`Dashboard-hover-${id}-${type}`);

    const query = queryData.queries[0];
    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info != null) {
      event.currentTarget.style.cursor = 'pointer';
    }
  };

  if (processedData.data.length === 0) {
    return <WidgetEmptyState />;
  }

  if (order === 'reversed') {
    processedData.data.sort(
      // @ts-expect-error
      (a, b) => a[widgetData.results[0].id] - b[widgetData.results[0].id]
    );
  } else {
    processedData.data.sort(
      // @ts-expect-error
      (a, b) => b[widgetData.results[0].id] - a[widgetData.results[0].id]
    );
  }

  return (
    <div className="h-dashboard-events w-full">
      <ResponsiveBar
        data={processedData.data}
        keys={processedData.keys}
        // @ts-expect-error Value type is not an string, we turn this
        // into an object in order get the id of the resource
        indexBy={(item): string => item.category.label}
        margin={{ top: 0, right: 10, bottom: 0, left: 70 }}
        padding={0.2}
        innerPadding={2}
        enableGridY={false}
        layout="horizontal"
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisLeft={{
          format: (v): string =>
            v.toString().length > 10 ? `${v.toString().slice(0, 7)}...` : v.toString(),
        }}
        labelTextColor="#65A782"
        theme={{
          labels: {
            text: {
              fontWeight: 600,
            },
          },
          tooltip: {
            container: {
              background: 'none',
              boxShadow: 'none',
            },
          },
        }}
        tooltip={(d): JSX.Element => (
          <div className="bg-litlingo-off-white p-1 rounded flex items-center shadow-md">
            <div
              style={{
                background: d.color,
              }}
              className="h-3 w-3 mr-2"
            />
            <span className="text">
              {/* @ts-expect-error Value type is not an string, we turn this into an object in order get the id of the resource */}
              {d.data.category.label}: <b>{d.value}</b>
            </span>
          </div>
        )}
        animate
        colors={['#B6DDC6']}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter as BarMouseEventHandler<SVGElement>}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...nivoProps}
      />
    </div>
  );
};

export default withWidget({
  title: 'Users',
  minWidth: 3,
  minHeight: 6,
})(HistogramSingleMetricWidget);
