import { Campaign, Rule, UUID } from '@litlingo/client';
import { fetchAllCampaigns } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import SearchInput from 'components/SearchInput';
import { MODAL_BACKGROUND_REDESIGN } from 'constants/common';
import { CLOSE_MODAL_ICON } from 'constants/commonIcons';
import { ARROW_CLOSE_ENVELOPE } from 'constants/envelopeIcons';
import React, { useEffect, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerName } from 'selectors/auth';
import { getCampaignsList, getFetchAllCampaignsLoading } from 'selectors/campaigns';

type ComponentProps = {
  toggleModalOpen: () => void;
  onSelect: (uuid: string) => void;
  customerName?: string;
  customerId?: string;
  onBackButtonClick?: () => void;
};

type CampaignsListComponentProps = {
  campaign: Campaign;
  onSelect: (uuid: string) => void;
};

const CampaignsListComponent: React.FC<CampaignsListComponentProps> = ({ campaign, onSelect }) => {
  const [expanded, setExpanded] = useState(false);

  let rules: Rule[] = [];
  if (campaign.rule_outcomes) {
    rules = campaign.rule_outcomes?.reduce<Rule[]>((acc, cur) => {
      if (acc.find((i) => i.uuid === cur.rule?.uuid)) {
        return acc;
      }
      if (cur.rule) {
        return [...acc, cur.rule];
      }
      return acc;
    }, []);
  }

  const handleSelectRule = (ruleId: UUID): void => {
    onSelect(ruleId);
  };

  if (rules.length === 0) return null;

  const ruleItem = (rule: Rule): JSX.Element => (
    <button
      key={rule.uuid}
      type="button"
      onClick={(e): void => {
        e.preventDefault();
        handleSelectRule(rule.uuid);
      }}
      className="flex flex-row justify-between items-stretch w-full pl-8 pr-4 text-body text-litlingo-gray-3 border-b border-litlingo-gray-1 focus:outline-none bg-litlingo-gray-0.5 hover:bg-litlingo-gray-1"
    >
      <span className="py-1.5 font-bold text-litlingo-gray-5">{rule.name}</span>
    </button>
  );

  return (
    <div className="flex flex-col w-full">
      <button
        type="button"
        onClick={(e): void => {
          e.preventDefault();
          setExpanded(!expanded);
        }}
        className="flex flex-row justify-between items-stretch w-full pl-6 pr-4 text-body text-litlingo-gray-3 border-b  border-litlingo-gray-1  focus:outline-none bg-white hover:bg-litlingo-gray-1"
      >
        <span className="py-1.5 font-bold text-litlingo-gray-5">{campaign.name}</span>
        {rules.length > 0 && (
          <div className="flex flex-row justify-center items-center">
            <span
              className={`py-1.5  focus:outline-none transition-transform duration-300 ${
                expanded && 'transform rotate-90'
              }`}
            >
              {ARROW_CLOSE_ENVELOPE()}
            </span>
          </div>
        )}
      </button>

      {expanded && (
        <div className="flex flex-col w-full">{rules.map((rule) => ruleItem(rule))}</div>
      )}
    </div>
  );
};

const SelectUseCaseModal: React.FC<ComponentProps> = ({
  toggleModalOpen,
  onSelect,
  customerId,
  customerName,
  onBackButtonClick,
}) => {
  const dispatch = useDispatch();
  const campaigns = useSelector(getCampaignsList);
  const loading = useSelector(getFetchAllCampaignsLoading);
  const cName = useSelector(getCustomerName);
  const [search, setSearch] = useState('');

  const backButton = !!onBackButtonClick;

  useEffect(() => {
    dispatch(
      fetchAllCampaigns({
        name: search,
        limit: '25',
        relationships: [],
        selectable_fields: ['name', 'uuid', 'rule_outcomes.rule.[name, uuid]'],
        ...(customerId ? { customer_uuid: customerId } : {}),
      })
    );
  }, [dispatch, customerId, search]);

  const handleSearchChange = (value: string): void => {
    setSearch(value);
  };

  const campaignsEmptyListComponent = (): JSX.Element => (
    <div className="flex flex-col items-center text-center gap-2 pt-10 h-48">
      <h2 className="text-heading-3">Use Case not found</h2>
      <span>
        Suggestions: Make sure all words are spelled correctly or try a more general search.
      </span>
    </div>
  );

  const campaignsListBody = (
    <>
      <div className="absolute top-0 left-0 right-0 h-22 z-0">{MODAL_BACKGROUND_REDESIGN}</div>
      <div className="flex flex-row justify-end pr-6 pt-6">
        <button
          type="button"
          data-testid="close-button"
          className="w-6 z-10 focus:outline-none "
          aria-label="Close"
          onClick={toggleModalOpen}
        >
          {CLOSE_MODAL_ICON}
        </button>
      </div>

      <div className="flex flex-col mt-10 overflow-hidden transition-all duration-300 border-b border-litlingo-gray-1">
        <>
          <div className="px-6">
            <div className="flex flex-row gap-2 items-end">
              <h2 className="text-heading-1">Select a model</h2>
              <span className="text-body">from {customerName || cName}</span>
            </div>
            <div className="pt-4">
              <SearchInput
                placeholder="Search workspace"
                className="w-full"
                handleSearch={handleSearchChange}
                debounceDelay={10}
              />
            </div>
          </div>

          <div className="border-b border-litlingo-gray-2 mt-2" />
          <LoadingOverlayWrapper active={loading} spinner={<LoadingIndicator />} fadeSpeed={0}>
            {campaigns.length ? (
              <div className="flex flex-col w-full custom-scrollbar overflow-auto h-48">
                {campaigns.map((campaign) => (
                  <CampaignsListComponent
                    key={campaign.uuid}
                    campaign={campaign}
                    onSelect={onSelect}
                  />
                ))}
              </div>
            ) : (
              campaignsEmptyListComponent()
            )}
          </LoadingOverlayWrapper>
        </>
      </div>
    </>
  );

  return (
    <Modal
      body={campaignsListBody}
      title=" "
      cancelButton={false}
      toggleShowModal={toggleModalOpen}
      xButton={false}
      style={{
        width: '546px',
        minHeight: '405px',
        maxHeight: '405px',
        padding: '0px',
      }}
      secondaryButton={backButton}
      secondaryButtonText="< Back"
      secondaryButtonStyle="text-body pt-1 pl-6 underline focus:outline-none"
      secondaryButtonOnclick={onBackButtonClick}
    />
  );
};

export default SelectUseCaseModal;
