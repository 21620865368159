import { UUID } from '@litlingo/client';
import { replaceAnnotator } from 'actions';
import Modal from 'components/Modal';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import AnnotatorCompareRow from './AnnotatorCompareRow';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
  selectedAnnotators: UUID[];
  setSelectedAnnotators: React.Dispatch<React.SetStateAction<string[]>>;
};

const ReplaceAnnotatorModal: React.FC<ComponentProps> = ({
  isModalOpen,
  toggleModalOpen,
  selectedAnnotators,
  setSelectedAnnotators,
}) => {
  const dispatch = useDispatch();
  const [annotatorSource, setAnnotatorSource] = useState<UUID>();

  const body = (
    <div className=" flex flex-col mb-4 h-full">
      <span className="mt-2">
        Identify the source identifier and use it in place of the other identifiers below. You’ll
        see the changes take place in the models and use cases they’re connected to.
      </span>

      <div className=" flex flex-col border-t-2 border-litlingo-gray-3 min-h-56 mt-4">
        {selectedAnnotators.map((annotatorId) => (
          <AnnotatorCompareRow
            key={annotatorId}
            annotatorId={annotatorId}
            annotatorSource={annotatorSource}
            setAnnotatorSource={setAnnotatorSource}
          />
        ))}
      </div>
    </div>
  );

  const handleSaveButton = (): void => {
    if (annotatorSource) {
      dispatch(
        replaceAnnotator({
          source: annotatorSource,
          targets: selectedAnnotators.filter((ann) => ann !== annotatorSource),
        })
      );
    }
    setSelectedAnnotators([]);
    toggleModalOpen();
  };

  const handleCancelReplace = (): void => {
    setSelectedAnnotators([]);
    toggleModalOpen();
  };

  return (
    <>
      {isModalOpen && (
        <Modal
          title="From your selections"
          body={body}
          toggleShowModal={toggleModalOpen}
          style={{ width: '37.5rem' }}
          okButton
          okButtonText="Use as Source"
          okButtonOnClick={handleSaveButton}
          okButtonDisabled={!annotatorSource}
          cancelButton
          cancelButtonOnclick={handleCancelReplace}
          xButton
        />
      )}
    </>
  );
};

export default ReplaceAnnotatorModal;
