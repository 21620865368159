import { Identifier, UUID } from '@litlingo/client';
import { replaceIdentifier } from 'actions/identifier';
import Modal from 'components/Modal';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import IdentifierCompareRow from './IdentifierCompareRow';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
  selectedIdentifiers: UUID[];
  setSelectedIdentifiers: React.Dispatch<React.SetStateAction<string[]>>;
  identifiers: Identifier[];
};

const ReplaceIdentifierModal: React.FC<ComponentProps> = ({
  isModalOpen,
  toggleModalOpen,
  selectedIdentifiers,
  setSelectedIdentifiers,
  identifiers,
}) => {
  const dispatch = useDispatch();
  const [identifierSource, setIdentifierSource] = useState<UUID>();

  const body = (
    <div className=" flex flex-col mb-4 h-full">
      <span className="mt-2">
        Identify the source identifier and use it in place of the other identifiers below. You’ll
        see the changes take place in the models and use cases they’re connected to.
      </span>

      <div className=" flex flex-col border-t-2 border-litlingo-gray-3 min-h-56 mt-4">
        {selectedIdentifiers.map((identifierId) => (
          <IdentifierCompareRow
            key={identifierId}
            identifierId={identifierId}
            identifierSource={identifierSource}
            setIdentifierSource={setIdentifierSource}
            identifiers={identifiers}
          />
        ))}
      </div>
    </div>
  );

  const handleSaveButton = (): void => {
    if (identifierSource) {
      dispatch(
        replaceIdentifier({
          source: identifiers.find((i) => i.uuid === identifierSource),
          targets: selectedIdentifiers.filter((ann) => ann !== identifierSource),
        })
      );
    }
    setSelectedIdentifiers([]);
    toggleModalOpen();
  };

  const handleCancelReplace = (): void => {
    setSelectedIdentifiers([]);
    toggleModalOpen();
  };

  return (
    <>
      {isModalOpen && (
        <Modal
          title="From your selections"
          body={body}
          toggleShowModal={toggleModalOpen}
          style={{ width: '37.5rem' }}
          okButton
          okButtonText="Use as Source"
          okButtonOnClick={handleSaveButton}
          okButtonDisabled={!identifierSource}
          cancelButton
          cancelButtonOnclick={handleCancelReplace}
          xButton
        />
      )}
    </>
  );
};

export default ReplaceIdentifierModal;
