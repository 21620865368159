import LinkLookup from 'components/LinkLookup';
import daysAgo from 'components/utils/daysAgo';
import platform from 'constants/platform';
import React from 'react';
import type { MCampaign, UUID } from 'types';
import logEvent from 'utils/analytics';
import { printStringArray } from 'utils/strings';

type ComponentProps = {
  campaign: MCampaign;
  selected: boolean;
  handleCampaignSelect: (checked: boolean, uuid: UUID) => void;
  showModels: boolean;
};

const CampaignListItem: React.FC<ComponentProps> = ({
  campaign,
  selected,
  handleCampaignSelect,
  showModels,
}) => {
  const handleRowCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();

    handleCampaignSelect(e.target.checked, campaign.uuid);
    const element = document.activeElement as HTMLInputElement;
    if (element) {
      element.blur();
    }
  };

  return (
    <>
      <LinkLookup
        routeName={campaign.unSaved ? 'campaign-list' : 'campaign-detail'}
        routeParams={campaign.unSaved ? {} : { campaignId: campaign.uuid }}
        className={`${campaign.unSaved ? 'opacity-25' : ''} ${
          !campaign.active ? 'bg-litlingo-gray-1' : ''
        } border-t border-gray-200 hover:bg-gray-100 table-row`}
        onClick={(): Promise<void> => logEvent('single-use-case')}
        as="tr"
      >
        <td className="table-wrapper__new-td flex-col items-center justify-center ">
          <div className="flex flex-col items-center justify-center  min-h-8 ">
            <input
              id={`checkbox-user-${campaign.uuid}`}
              data-testid="select-button"
              type="checkbox"
              className="cursor-pointer form-checkbox litlingo-checkbox w-5 h-5"
              onClick={(e): void => e.stopPropagation()}
              onChange={(e): void => {
                handleRowCheckboxChange(e);
              }}
              checked={selected}
            />
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center">
            <div className="overflow-hidden">
              <div className="text-base text--truncate font-bold">{campaign.name}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td pr-1">
          <div className="text-body">
            {campaign.active_platforms &&
              printStringArray(
                Object.entries(platform).reduce<string[]>((acc, [key, value]) => {
                  if (campaign[key as keyof typeof campaign]) {
                    return [...acc, value];
                  }
                  return acc;
                }, [])
              )}
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="text-body">{campaign.active_inbound ? 'Yes' : ' No'}</div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="text-body">{campaign.active_outbound ? 'Yes' : 'No'}</div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="text-body">{campaign.active ? 'Yes' : 'No'}</div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="text-body">
            {campaign.updated_at != null && daysAgo(campaign.updated_at)}
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="text-body">{campaign.updated_by && `By ${campaign.updated_by.name}`}</div>
        </td>
      </LinkLookup>
      {selected && showModels && (
        <>
          {campaign.rule_outcomes
            ?.filter(
              (c, index, self) =>
                self.findIndex((item) => item.rule?.uuid === c.rule?.uuid) === index
            )
            .map((r) => (
              <tr
                key={r.uuid}
                className="border-t border-gray-200 bg-litlingo-gray-0.5 table-row h-8"
              >
                <td className="table-wrapper__new-td pl-15 text-body" colSpan={2}>
                  {`${r.rule?.name} (v${r.rule?.current_version})`}
                </td>
                <td colSpan={6} className="pl-8">
                  <div className="flex flex-col ">
                    {campaign.rule_outcomes
                      ?.filter((ruleOutcome) => ruleOutcome.rule_uuid === r.rule_uuid)

                      .map((rOutcome) => (
                        <div key={rOutcome.outcome?.uuid}>{rOutcome.outcome?.name}</div>
                      ))}
                  </div>
                </td>
              </tr>
            ))}
        </>
      )}
    </>
  );
};

export default CampaignListItem;
