/* eslint-disable max-lines */
import {
  deleteCampaign,
  evaluateAsyncResults,
  fetchAllTestCases,
  fetchTestCaseSummary,
} from 'actions';
import RenderRules from 'components/Campaign/RenderRules';
import CollapsableCard from 'components/CollapsableCard';
import ConfirmModal from 'components/ConfirmModal';
import Navbar from 'components/Navbar';
import Permissions from 'components/Permissions';
import RenderTestSuite from 'components/RenderTestSuite';
import TestVisualizer from 'components/TestVisualizer';
import keyMap from 'constants/configHotKeys';
import React, { useEffect, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCampaign } from 'selectors/campaigns';
import { getTestSentenceLoading } from 'selectors/communications';
import { getTestCasesResultsKey } from 'selectors/testCases';
import { useSelector } from 'store';
import type { UUID } from 'types';
import CampaignHeader from './CampaignHeader';

type RouteParams = {
  campaignId: UUID;
};

const Campaign: React.FC = () => {
  const { campaignId } = useParams<RouteParams>();
  const dispatch = useDispatch();
  const testRunLoading = useSelector(getTestSentenceLoading);

  const item = useSelector(getCampaign(campaignId)) || { active: false };
  const evaluateTestResultsKey = useSelector(getTestCasesResultsKey);
  const [isDeletingCampaign, setIsDeletingCampaign] = useState(false);

  useEffect(() => {
    // dispatch(fetchSingleCampaignWithRules({ id: campaignId }));
    dispatch(fetchAllTestCases({ campaign_uuids: campaignId, campaign_including_rules: true }));
    dispatch(fetchTestCaseSummary({ campaign_uuids: campaignId, campaign_including_rules: true }));
  }, [campaignId, dispatch]);

  const handleDelete = async (): Promise<void> => {
    dispatch(deleteCampaign({ id: campaignId }));
  };

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;
    if (evaluateTestResultsKey) {
      dispatch(evaluateAsyncResults(evaluateTestResultsKey));
      dispatch(
        fetchTestCaseSummary({ campaign_uuids: campaignId, campaign_including_rules: true })
      );
      intervalId = setInterval(() => {
        dispatch(evaluateAsyncResults(evaluateTestResultsKey));
        dispatch(
          fetchTestCaseSummary({ campaign_uuids: campaignId, campaign_including_rules: true })
        );
      }, 2000);
    }
    return (): void => clearInterval(intervalId);
  }, [dispatch, evaluateTestResultsKey, campaignId]);

  const toggleConfirmModal = (): void => setIsDeletingCampaign(false);

  const handlers = {
    SAVE_CAMPAIGN: (event: KeyboardEvent | undefined): void => {
      if (event) {
        event.preventDefault();
        const button = document.querySelector('#save-campaign-setup-button') as HTMLButtonElement;
        if (button) button.click();
      }
    },
  };

  const renderTest = (
    <CollapsableCard headerTitle="Test a Phrase" isDefaultClosed customLoading={testRunLoading}>
      <TestVisualizer />
    </CollapsableCard>
  );

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <div
        className="flex flex-col bg-white min-w-screen"
        style={{
          height: `calc(100vh - ${warningBarOffset}px)`,
        }}
      >
        <Navbar />
        <div className="flex flex-col gap-4 h-full pt-6 pb-4">
          <CampaignHeader />

          <main>
            <div className="flex flex-col gap-2 px-6">
              <RenderRules item={item} />

              <Permissions action="test_cases.list">
                <div>{renderTest}</div>
              </Permissions>
              <Permissions action="test_cases.list">
                <RenderTestSuite entity="campaign" entityId={campaignId} includingRules />
              </Permissions>
            </div>
          </main>
        </div>

        {isDeletingCampaign && (
          <ConfirmModal
            text="Are you sure you want to delete this use case?"
            okButtonText="Yes"
            cancelButtonText="No"
            okButtonOnClick={handleDelete}
            toggleShowModal={toggleConfirmModal}
          />
        )}
      </div>
    </>
  );
};
export default Campaign;
