import { useHistory as baseUseHistory } from 'react-router-dom';
import routes from 'routes';
import type {
  CustomerConfig,
  RouteDataNestedResourceSpec,
  RouteDataParamSpec,
  RouteParams,
  RouteSpec,
  SuperRouteSpec,
} from 'types';

export interface Params {
  [key: string]: string | string[] | null;
}

interface ParamsSpec {
  [paramName: string]: RouteDataParamSpec | RouteDataNestedResourceSpec;
}

export const buildQSFromQSObj = (obj: Params, qs = ''): string => {
  const params = new URLSearchParams(qs);
  Object.entries(obj).forEach(([key, value]) => {
    // remove existing values for the key
    params.delete(key);

    if (Array.isArray(value)) {
      value.forEach((v) => params.append(key, v));
    } else if (value != null && value !== '') {
      // remove empty strings
      params.append(key, value);
    }
  });

  return params.toString();
};

export const buildObjFromQSEntries = (entries: IterableIterator<[string, string]>): Params => {
  const result: Params = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

export const buildQSObjFromQS = (qs: string, paramsSpec: ParamsSpec): Params => {
  // convert qs to object, keys with multiple values are converted to an array
  const params: Params = {};
  if (qs !== '') {
    new URLSearchParams(qs).forEach((value, key) => {
      // check if key is a nested resource param
      if (key.includes('__')) {
        const [resource, filter] = key.split('__');

        // removes keys that are not present on the nested spec
        const nested = paramsSpec[resource];
        if (nested == null || !('nestedResource' in nested)) {
          return;
        }

        if (nested.allowAllParams) {
          const current = params[key];
          if (current != null) {
            params[key] = [...current, value];
          } else {
            params[key] = value;
          }

          return;
        }

        if (nested.params == null) {
          return;
        }

        // if there's no spec for the current param just ignore it
        const spec = nested.params[filter];
        if (spec == null) {
          return;
        }

        // if there are options for the current key and the value is not in them,
        // ignores it
        if (spec.options != null && !spec.options.includes(value)) {
          return;
        }

        const current = params[key];
        if (current != null) {
          params[key] = [...current, value];
          return;
        }

        params[key] = spec.list ? [value] : value;
      } else {
        // removes keys that are not present on the spec
        if (paramsSpec[key] == null) {
          return;
        }

        const spec = paramsSpec[key] as RouteDataParamSpec;

        // if there are options for the current key and the value is not in them,
        // ignores it
        if (spec.options != null && !spec.options.includes(value)) {
          return;
        }

        const current = params[key];
        if (current != null) {
          params[key] = [...current, value];
          return;
        }

        params[key] = spec.list ? [value] : value;
      }
    });
  }

  // add missing params with default values
  Object.entries(paramsSpec).forEach(([param, spec]) => {
    // add params in nested resource format
    if ('nestedResource' in spec) {
      if (spec.params != null) {
        Object.entries(spec.params).forEach(([nestedParam, nestedSpec]) => {
          if (nestedSpec.defaultValue != null && params[`${param}__${nestedParam}`] == null) {
            if (!(nestedParam === 'has_events' && 'envelopes__sample_uuid' in params)) {
              params[`${param}__${nestedParam}`] = nestedSpec.defaultValue;
            }
          }
        });
      }
    } else if (spec.defaultValue != null && params[param] == null) {
      if (Array.isArray(spec.defaultValue)) {
        params[param] = spec.defaultValue;
      } else {
        params[param] = spec.defaultValue.toString();
      }
    }
  });

  return params;
};

export type LookupProps = {
  routeName?: string;
  routeParams?: { [param: string]: string };
  queryParams?: Params | string;
  customerDomain?: string | null;
  state?: { [key: string]: string } | string;
};

export const reverse = (props: LookupProps): string => {
  const { routeName = '', routeParams = {}, queryParams = {}, customerDomain = 'r' } = props;

  const route = routes.find((r) => r.name === routeName);
  if (!route) {
    throw new Error(`Route '${routeName}' not found!`);
  }

  let queryString = '';
  if (typeof queryParams !== 'string') {
    queryString = buildQSFromQSObj(queryParams);
  } else {
    queryString = queryParams;
  }

  if (queryString.startsWith('?')) {
    queryString = queryString.slice(1);
  }

  const routeParamsDomain: { [param: string]: string } = {
    ...routeParams,
    customerDomain: customerDomain ?? 'r',
  };

  const path = route.path
    .split('/')
    .map((part) => {
      if (!part.startsWith(':')) return part;
      const paramName = part.replace(':', '').replace('?', '');
      const value = routeParamsDomain[paramName];
      if (value) return value;

      if (part.endsWith('?')) {
        return null;
      }
      throw new Error(`Route param '${paramName}' is required in:\n${routeName} -> ${route.path}`);
    })
    .filter(Boolean)
    .join('/');

  if (queryString) return `/${path}?${queryString}`;
  return `/${path}`;
};

export const useHistory = (): ReturnType<typeof baseUseHistory> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pushLookup: (props: LookupProps) => void;
} => {
  const history = {
    ...baseUseHistory(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pushLookup: (props: LookupProps): void => {
      const path = reverse(props);
      return history.push(path, props.state);
    },
  };
  return history;
};

export const getRoute = (
  spec?: RouteSpec | SuperRouteSpec,
  config?: CustomerConfig
): RouteSpec | null => {
  let routeSpec: RouteSpec | null = null;

  if (spec) {
    if (!('routes' in spec)) {
      return spec;
    }

    if (spec.customerConfig && config) {
      const property = config[spec.customerConfig];
      routeSpec = property ? spec.routes[0] : spec.routes[1];
    }
  }

  return routeSpec;
};

export const getParamsFromUrl = (
  url: string,
  resource: string,
  routeName: string,
  config: CustomerConfig
): RouteParams => {
  let params = {};
  const spec = routes.find((route) => route.name === routeName);
  const routeSpec = getRoute(spec, config);

  if (routeSpec !== null && routeSpec.data != null && routeSpec.data.params != null) {
    params = buildQSObjFromQS(url, routeSpec.data.params);
  }

  const response: RouteParams = {};

  Object.entries(params).forEach(([key, value]) => {
    const [paramResource, ...field] = key.split('__');
    if (paramResource === resource) {
      // @ts-expect-error
      response[field.join('__')] = value;
    }
  });

  return response;
};
