/* eslint-disable max-lines */
import {
  addFieldsToGroup,
  addValuesToGroup,
  clearSelectedFields,
  clearSelectedFilters,
  removeFieldsFromGroup,
  removeValuesFromGroup,
} from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import Permissions from 'components/Permissions';
import useClickOutside from 'components/utils/useClickOutside';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSelectedFields, getSelectedFilters } from 'selectors/envelopes';
import { useSelector } from 'store';

type ComponentProps = {
  primaryLabel: string;
  options: Option[];
  dropdownDirection?: 'down' | 'up';
  loading?: boolean;
  isReviewSetEdit?: boolean;
  canSaveSample: boolean;
  handleCancelReviewSet: () => void;
  handleEditReviewSet: () => void;
  handleSaveNewSample: () => void;
};
type Option = {
  id: string;
  label: string;
  action: () => void;
  permissions?: string;
};

const ButtonMenu: React.FC<ComponentProps> = (props) => {
  const {
    primaryLabel,
    options,
    dropdownDirection = 'down',
    loading = false,
    isReviewSetEdit,
    canSaveSample,
    handleEditReviewSet,
    handleCancelReviewSet,
    handleSaveNewSample,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const selectedValues = useSelector(getSelectedFilters);
  const selectedFields = useSelector(getSelectedFields);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleOptionClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    action: () => void
  ): void => {
    setIsOpen(false);
    e.stopPropagation();
    action();
  };

  const handleAddToGroups = (): void => {
    if (selectedValues.length <= 1) return;
    dispatch(
      addValuesToGroup({
        nodes: selectedValues,
      })
    );
    setIsOpen(false);
  };

  const handleRemoveFromGroups = (): void => {
    dispatch(
      removeValuesFromGroup({
        nodes: selectedValues,
      })
    );
    setIsOpen(false);
  };

  const handleGroupFields = (): void => {
    if (selectedFields.length <= 1) return;
    dispatch(
      addFieldsToGroup({
        nodes: selectedFields,
      })
    );
    setIsOpen(false);
  };

  const handleRemoveFieldsFromGroups = (): void => {
    dispatch(
      removeFieldsFromGroup({
        nodes: selectedFields,
      })
    );
    setIsOpen(false);
  };

  const handleUnselect = (): void => {
    dispatch(clearSelectedFilters());
    dispatch(clearSelectedFields());
    setIsOpen(false);
  };

  const isOnGroup = useMemo(
    (): boolean => selectedValues.some((pill) => pill.group),
    [selectedValues]
  );

  const isFieldInGroup = useMemo(
    (): boolean => selectedFields.some((pill) => pill.group),
    [selectedFields]
  );

  const renderOption = (o: Option): JSX.Element => {
    if (o.permissions) {
      return (
        <Permissions action={o.permissions} key={o.id}>
          <button
            data-testid={`menu-option-${o.id}`}
            type="button"
            onClick={(e): void => handleOptionClick(e, o.action)}
            className="text-body w-full border-litlingo-gray-2 p-2 pr-6 focus:outline-none hover:bg-litlingo-gray-1"
          >
            <div className="flex">
              <span className="whitespace-no-wrap">{o.label}</span>
            </div>
          </button>
        </Permissions>
      );
    }
    return (
      <button
        key={o.label}
        type="button"
        onClick={(e): void => handleOptionClick(e, o.action)}
        className="text-body w-full border-litlingo-gray-2 p-2 pr-6 focus:outline-none hover:bg-litlingo-gray-1"
      >
        <div className="flex">
          <span className="whitespace-no-wrap">{o.label}</span>
        </div>
      </button>
    );
  };

  const renderButton = (): JSX.Element => {
    if (selectedValues.length <= 1 && !isOnGroup) {
      return (
        <div className="w-full flex flex-row items-center bg-litlingo-primary rounded">
          <button
            type="button"
            className="button button--primary h-full flex gap-1 justify-center py-0.5 px-2 text-body w-full border-0 focus:outline-none"
            onClick={(): void => setIsOpen(!isOpen)}
          >
            {loading ? (
              <LoadingIndicator size="5" />
            ) : (
              <span className="font-bold text-white whitespace-no-wrap">
                {isReviewSetEdit ? 'Save' : primaryLabel}
              </span>
            )}
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.49026 8.44392L1.83398 3L0 4.91123L7.49035 12.0926L15 4.91123L13.166 3L7.49026 8.44392Z"
                fill="#ffffff"
              />
            </svg>
          </button>
        </div>
      );
    }
    return (
      <div className="w-full flex flex-row items-center bg-litlingo-primary rounded">
        <button
          type="button"
          className="button button--primary h-full flex gap-1 justify-center py-0.5 px-2 text-body w-full focus:outline-none"
          onClick={handleAddToGroups}
          disabled={selectedValues.length <= 1}
        >
          {loading ? (
            <LoadingIndicator size="5" />
          ) : (
            <span className="font-bold text-white whitespace-no-wrap">Group</span>
          )}
        </button>
        <span className="text-white text-center  w-2 h-6">&#x7C;</span>
        <button
          type="button"
          className="button button--primary h-full flex gap-1 justify-center py-0.5 px-2 text-body w-full border-0 focus:outline-none"
          onClick={(): void => setIsOpen(!isOpen)}
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.49026 8.44392L1.83398 3L0 4.91123L7.49035 12.0926L15 4.91123L13.166 3L7.49026 8.44392Z"
              fill="#ffffff"
            />
          </svg>
        </button>
      </div>
    );
  };

  const renderMenu = (): JSX.Element | undefined => {
    if (
      selectedValues.length === 0 &&
      !isOnGroup &&
      selectedFields.length === 0 &&
      !isFieldInGroup
    ) {
      return (
        <>
          {isOpen && isReviewSetEdit && (
            <div
              data-testid="dropdown-menu"
              className={`origin-bottom-right right-0 absolute rounded z-50 border border-litlingo-gray-2 ${
                dropdownDirection === 'down'
                  ? 'top-full mt-1'
                  : 'top-0 -mt-1 transform -translate-y-full'
              }`}
            >
              <div
                className="rounded bg-white shadow-xs overflow-hidden"
                style={{ boxShadow: '3px 1px 8px rgba(0, 0, 0, 0.35)' }}
              >
                <div
                  role="menu"
                  data-testid="options-container"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <button
                    data-testid="menu-option-edit-review-set"
                    type="button"
                    onClick={(): void => handleEditReviewSet()}
                    className="text-body w-full border-litlingo-gray-2 p-2 pr-6 focus:outline-none hover:bg-litlingo-gray-1"
                  >
                    <div className="flex">
                      <span className="whitespace-no-wrap">Save</span>
                    </div>
                  </button>

                  {canSaveSample && (
                    <button
                      data-testid="menu-option-edit-review-set"
                      type="button"
                      onClick={(): void => handleSaveNewSample()}
                      className="text-body w-full border-litlingo-gray-2 p-2 pr-6 focus:outline-none hover:bg-litlingo-gray-1"
                    >
                      <div className="flex">
                        <span className="whitespace-no-wrap">Create new sample</span>
                      </div>
                    </button>
                  )}

                  <button
                    data-testid="menu-option-edit-review-set"
                    type="button"
                    onClick={(): void => handleCancelReviewSet()}
                    className="text-body w-full border-litlingo-gray-2 p-2 pr-6 focus:outline-none hover:bg-litlingo-gray-1"
                  >
                    <div className="flex">
                      <span className="whitespace-no-wrap">Cancel</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          )}
          {isOpen && !isReviewSetEdit && options.length > 0 && (
            <div
              data-testid="dropdown-menu"
              className={`origin-bottom-right right-0 absolute rounded z-50 border border-litlingo-gray-2 ${
                dropdownDirection === 'down'
                  ? 'top-full mt-1'
                  : 'top-0 -mt-1 transform -translate-y-full'
              }`}
            >
              <div
                className="rounded bg-white shadow-xs overflow-hidden"
                style={{ boxShadow: '3px 1px 8px rgba(0, 0, 0, 0.35)' }}
              >
                <div
                  role="menu"
                  data-testid="options-container"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  {options.map((o) => renderOption(o))}
                </div>
              </div>
            </div>
          )}
        </>
      );
    }

    return isOpen ? (
      <>
        <div
          data-testid="dropdown-menu"
          className={`origin-bottom-right right-0 absolute rounded z-50 border border-litlingo-gray-2 ${
            dropdownDirection === 'down'
              ? 'top-full mt-1'
              : 'top-0 -mt-1 transform -translate-y-full'
          }`}
        >
          <div
            className="rounded bg-white shadow-xs overflow-hidden"
            style={{ boxShadow: '3px 1px 8px rgba(0, 0, 0, 0.35)' }}
          >
            <div
              role="menu"
              data-testid="options-container"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {selectedFields.length > 1 && (
                <button
                  data-testid="menu-option-edit-review-set"
                  type="button"
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onClick={handleGroupFields}
                  className="text-body w-full border-litlingo-gray-2 p-2 pr-6 focus:outline-none hover:bg-litlingo-gray-1"
                >
                  <div className="flex">
                    <span className="whitespace-no-wrap">Group Fields</span>
                  </div>
                </button>
              )}

              {isFieldInGroup && (
                <button
                  data-testid="menu-option-edit-review-set"
                  type="button"
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onClick={handleRemoveFieldsFromGroups}
                  className="text-body w-full border-litlingo-gray-2 p-2 pr-6 focus:outline-none hover:bg-litlingo-gray-1"
                >
                  <div className="flex">
                    <span className="whitespace-no-wrap">Ungroup Fields</span>
                  </div>
                </button>
              )}

              {isOnGroup && (
                <button
                  data-testid="menu-option-edit-review-set"
                  type="button"
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onClick={handleRemoveFromGroups}
                  className="text-body w-full border-litlingo-gray-2 p-2 pr-6 focus:outline-none hover:bg-litlingo-gray-1"
                >
                  <div className="flex">
                    <span className="whitespace-no-wrap">Ungroup</span>
                  </div>
                </button>
              )}
              <button
                data-testid="menu-option-edit-review-set"
                type="button"
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={handleUnselect}
                className="text-body w-full border-litlingo-gray-2 p-2 pr-6 focus:outline-none hover:bg-litlingo-gray-1"
              >
                <div className="flex">
                  <span className="whitespace-no-wrap">Unselect All</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </>
    ) : undefined;
  };
  return (
    <div ref={wrapperRef} className="relative w-full">
      {renderButton()}
      {renderMenu()}
    </div>
  );
};

export default ButtonMenu;
