import { createAction } from '@reduxjs/toolkit';
import { API, Entity, ErrorObject, Prompt, UUID } from 'types';

export const fetchEntities = createAction('ENVELOPES/FETCH_ENTITIES');
export const fetchEntitiesRequest = createAction('ENVELOPES/FETCH_ENTITIES_REQUEST');
export const fetchEntitiesFailure = createAction<ErrorObject>('ENVELOPES/FETCH_ENTITIES_FAILURE');
export const fetchEntitiesSuccess = createAction<API.WrappedAPIResponse<Entity>>(
  'ENVELOPES/FETCH_ENTITIES_SUCCESS'
);
export const fetchEntitiesFulfill = createAction('ENVELOPES/FETCH_ENTITIES_FULFILL');

type MergeEntitiesPayload = {
  source: UUID;
  destination: UUID;
};

export const mergeEntities = createAction<MergeEntitiesPayload>('ENVELOPES/MERGE_ENTITIES');
export const mergeEntitiesRequest = createAction('ENVELOPES/MERGE_ENTITIES_REQUEST');
export const mergeEntitiesFailure = createAction<ErrorObject>('ENVELOPES/MERGE_ENTITIES_FAILURE');
export const mergeEntitiesSuccess = createAction<UUID>('ENVELOPES/MERGE_ENTITIES_SUCCESS');

export const fetchPrompts = createAction<{ kind?: string } | undefined>('ENVELOPES/FETCH_PROMPTS');
export const fetchPromptsRequest = createAction('ENVELOPES/FETCH_PROMPTS_REQUEST');
export const fetchPromptsFailure = createAction<ErrorObject>('ENVELOPES/FETCH_PROMPTS_FAILURE');
export const fetchPromptsSuccess = createAction<API.WrappedAPIResponse<Prompt>>(
  'ENVELOPES/FETCH_PROMPTS_SUCCESS'
);
export const fetchPromptsFulfill = createAction('ENVELOPES/FETCH_PROMPTS_FULFILL');

type UpsetPromptPayload = {
  uuid?: string;
  kind: string;
  deployment: string;
  content?: string;
  source?: string;
  name: string;
  description?: string;
};

export const upsertPrompt = createAction<UpsetPromptPayload>('ENVELOPES/UPSERT_PROMPT');
export const upsertPromptRequest = createAction('ENVELOPES/UPSERT_PROMPT_REQUEST');
export const upsertPromptFailure = createAction<ErrorObject>('ENVELOPES/UPSERT_PROMPT_FAILURE');
export const upsertPromptSuccess = createAction<Prompt>('ENVELOPES/UPSERT_PROMPT_SUCCESS');

export const deletePrompt = createAction<{ uuid: UUID }>('ENVELOPES/DELETE_PROMPT');
export const deletePromptRequest = createAction('ENVELOPES/DELETE_PROMPT_REQUEST');
export const deletePromptFailure = createAction<ErrorObject>('ENVELOPES/DELETE_PROMPT_FAILURE');
export const deletePromptSuccess = createAction<{ uuid: UUID }>('ENVELOPES/DELETE_PROMPT_SUCCESS');

export const fetchSinglePrompt = createAction<{ promptId: UUID }>('ENVELOPES/FETCH_SINGLE_PROMPT');
export const fetchSinglePromptRequest = createAction('ENVELOPES/FETCH_SINGLE_PROMPT_REQUEST');
export const fetchSinglePromptFailure = createAction<ErrorObject>(
  'ENVELOPES/FETCH_SINGLE_PROMPT_FAILURE'
);
export const fetchSinglePromptSuccess = createAction<Prompt>(
  'ENVELOPES/FETCH_SINGLE_PROMPT_SUCCESS'
);
