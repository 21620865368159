import { LitlingoAxiosError } from '@errors';
import * as Sentry from '@sentry/browser';
import type { ClientResponseP, ErrorData, ErrorResponse } from '@types';
import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import axios from 'axios';

type ClientConfig = {
  logs: boolean;
  sentry: boolean;
};

abstract class Client<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  H extends Record<string, (...args: any[]) => any> = Record<string, never>
> {
  protected _client: AxiosInstance;

  protected _handlers: Partial<H>;

  protected _logs: boolean;

  protected _sentry: boolean;

  constructor(
    axiosConfig: AxiosRequestConfig = {},
    config: Partial<ClientConfig> = {}
  ) {
    this._client = axios.create(axiosConfig);
    this._handlers = {};
    this._logs = config.logs ?? false;
    this._sentry = config.sentry ?? false;

    this._client.interceptors.request.use(this._logRequest.bind(this));
    this._client.interceptors.response.use(
      this._logResponse.bind(this),
      this._logResponseError.bind(this)
    );
  }

  private async _logRequest(
    request: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> {
    if (this._logs) {
      // eslint-disable-next-line no-console
      console.log('%c Outgoing Network Request:', 'font-weight: bold', request);
    }

    return request;
  }

  private async _logResponse(
    response: AxiosResponse<unknown>
  ): Promise<AxiosResponse<unknown>> {
    if (this._logs) {
      // eslint-disable-next-line no-console
      console.log(
        '%c Request Success:',
        'color: #4CAF50; font-weight: bold',
        response
      );
    }

    return response;
  }

  private _logResponseError(
    error: Error | AxiosError | LitlingoAxiosError
  ): Promise<Error | AxiosError | LitlingoAxiosError> {
    if (this._logs) {
      // eslint-disable-next-line no-console
      console.log(
        '%c Request Error:',
        'color: #EC6060; font-weight: bold',
        error
      );
    }

    this._report(error);

    return Promise.reject(error);
  }

  private _report(error: Error | AxiosError | LitlingoAxiosError): void {
    // if sentry is disabled, skip it
    if (!this._sentry) {
      return;
    }

    // if there's a response, but the status is not 5xx, skip it
    if (
      'response' in error &&
      error.response != null &&
      error.response.status < 500
    ) {
      return;
    }

    Sentry.captureException(error);
  }

  // eslint-disable-next-line class-methods-use-this
  private _error(error: ErrorData): ErrorResponse {
    return { data: null, error };
  }

  protected _handle<K extends keyof H>(
    event: K,
    ...args: Parameters<H[K]>
  ): ReturnType<H[K]> | null {
    const handler = this._handlers[event];
    if (handler != null) {
      return handler(...args);
    }

    return null;
  }

  register<K extends keyof H>(event: K, handler: H[K]): void {
    this._handlers[event] = handler;
  }

  async request<D>(config: AxiosRequestConfig): ClientResponseP<D> {
    try {
      const response = await this._client.request<D>(config);
      if (response.status >= 400 && response.status <= 500) {
        throw new LitlingoAxiosError({
          message: 'Request failed',
          response,
          isAxiosError: true,
        });
      }

      return { data: response.data, error: null };
    } catch (error) {
      const err = error as AxiosError;
      // see: https://github.com/axios/axios#handling-errors
      // and: https://github.com/axios/axios/blob/master/lib/core/enhanceError.js#L21
      if (err.isAxiosError) {
        if (err.response != null) {
          // any error on the 5xx range is a server error
          if (err.response.status >= 500 && err.response.status < 600) {
            return this._error({
              message: 'Something unexpected happened.',
              status: err.response.status,
            });
          }

          return this._error({
            ...err.response.data,
            status: err.response.status,
          });
        }

        if (err.request != null) {
          return { data: null, error: err.toJSON() as ErrorData };
        }
      }

      throw error;
    }
  }
}

export default Client;
