import {
  cloneCampaign,
  cloneCampaignFailure,
  cloneCampaignRequest,
  cloneCampaignSuccess,
  createCampaign,
  createCampaignFailure,
  createCampaignRequest,
  createCampaignSuccess,
  deleteCampaign,
  deleteCampaignFailure,
  deleteCampaignRequest,
  deleteCampaignSuccess,
  fetchActiveIntegrations,
  fetchActiveIntegrationsFailure,
  fetchActiveIntegrationsRequest,
  fetchActiveIntegrationsSuccess,
  fetchAllCampaigns,
  fetchAllCampaignsDebounced,
  fetchAllCampaignsFailure,
  fetchAllCampaignsForFilter,
  fetchAllCampaignsRequest,
  fetchAllCampaignsSuccess,
  fetchCampaignsForFilterPills,
  fetchCampaignsForFilterPillsFailure,
  fetchCampaignsForFilterPillsRequest,
  fetchCampaignsForFilterPillsSuccess,
  fetchFilterCampaigns,
  fetchSingleCampaign,
  fetchSingleCampaignFailure,
  fetchSingleCampaignRequest,
  fetchSingleCampaignSuccess,
  fetchSingleCampaignWithRules,
  fetchSingleSimpleCampaign,
  prepareUndeleteAlert,
  showSuccessAlert,
  upsertCampaign,
  upsertCampaignFailure,
  upsertCampaignFulfill,
  upsertCampaignRequest,
  upsertCampaignSuccess,
  upsertCampaignWithChan,
} from 'actions';
import { receiveRuleOutcomes } from 'actions/campaignRuleOutcomes';
import { cleanOutcomes } from 'actions/outcome';
import { apiClient as LitLingoClient } from 'client';
import { push } from 'connected-react-router';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import type { Channel } from 'redux-saga';
import { buffers } from 'redux-saga';
import {
  actionChannel,
  call,
  debounce,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { getCustomerDomain } from 'selectors/auth';
import { getNavParams, getNavParamsByResource } from 'selectors/nav';
import type { API, MCampaign, RouteParams, SagaReturn } from 'types';
import { reverse } from 'utils/urls';
import { v4 as uuidv4 } from 'uuid';

function* fetchSingleSimpleCampaignSaga(
  action: ReturnType<typeof fetchSingleCampaign>
): SagaReturn {
  const { payload } = action;

  const response = (yield call(
    [LitLingoClient.resources.campaigns, 'retrieve'],
    payload.campaignId,
    {
      params: {
        include_pii: 'true',
      },
    }
  )) as API.Response<API.Campaigns.Retrieve>;
  if (response.error != null) {
    yield put(fetchSingleCampaignFailure(response.error));
  } else {
    yield put(fetchSingleCampaignSuccess(response.data));
  }
}

function* fetchSingleCampaignSaga(action: ReturnType<typeof fetchSingleCampaign>): SagaReturn {
  const { payload } = action;
  yield put(cleanOutcomes());
  yield put(fetchSingleCampaignRequest());
  const response = (yield call(
    [LitLingoClient.resources.campaigns, 'retrieve'],
    payload.campaignId,
    {
      params: {
        relationships: [
          'rule_outcomes.rule',
          'rule_outcomes.outcome',
          'rule_outcomes.created_by',
          'rule_outcomes.rule.updated_by',
          'rule_outcomes.rule.created_by',
          'rule.annotator',
        ],
        include_pii: 'true',
      },
    }
  )) as API.Response<API.Campaigns.Retrieve>;
  if (response.error != null) {
    yield put(fetchSingleCampaignFailure(response.error));
  } else {
    // Strip rule Outcomes out of the campaign - just leave an array of ID's
    const { rule_outcomes: ruleOutcomes, ...singleCampaign } = response.data;
    const campaign: MCampaign = { ...singleCampaign };
    if (ruleOutcomes != null) {
      campaign.rules = ruleOutcomes.map((ruleOutcome) => ruleOutcome.rule_uuid);
      yield put(receiveRuleOutcomes(ruleOutcomes));
    }

    yield put(fetchSingleCampaignSuccess(campaign));
  }
}

function* fetchSingleCampaignWithRulesSaga(
  action: ReturnType<typeof fetchSingleCampaignWithRules>
): SagaReturn {
  const { payload } = action;
  yield put(fetchSingleCampaignRequest());
  const response = (yield call(
    [LitLingoClient.resources.campaigns, 'retrieve'],
    payload.campaignId,
    {
      params: {
        relationships: [
          'rule_outcomes.rule',
          'rule_outcomes.outcome',
          'rule_outcomes.created_by',
          'rule_outcomes.rule.updated_by',
          'rule_outcomes.rule.created_by',
        ],
        include_pii: 'true',
      },
    }
  )) as API.Response<API.Campaigns.Retrieve>;
  if (response.error != null) {
    yield put(fetchSingleCampaignFailure(response.error));
  } else {
    // Strip rule Outcomes out of the campaign - just leave an array of ID's
    const { rule_outcomes: ruleOutcomes, ...singleCampaign } = response.data;
    const campaign: MCampaign = { ...singleCampaign };
    if (ruleOutcomes != null) {
      campaign.rules = ruleOutcomes.map((ruleOutcome) => ruleOutcome.rule_uuid);
      yield put(receiveRuleOutcomes(ruleOutcomes));
    }

    yield put(fetchSingleCampaignSuccess(campaign));
  }
}

function* fetchCampaignsList(paramsArg: Partial<RouteParams>): SagaReturn {
  yield put(fetchAllCampaignsRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.campaign)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = { ...paramsArg, include_count: true, ...resourceParams };

  const response = (yield call([LitLingoClient.resources.campaigns, 'list'], {
    params,
  })) as API.Response<API.Campaigns.List>;
  if (response.error != null) {
    yield put(fetchAllCampaignsFailure(response.error));
  } else {
    yield put(fetchAllCampaignsSuccess(response.data));
  }
}

function* fetchCampaignsForFilterPillsSaga(
  action: ReturnType<typeof fetchCampaignsForFilterPills>
): SagaReturn {
  const { campaignIds } = action.payload;

  const params = { uuids: campaignIds, include_pii: true };
  yield put(fetchCampaignsForFilterPillsRequest());

  const response = (yield call([LitLingoClient.resources.campaigns, 'list'], {
    params,
  })) as API.Response<API.Campaigns.List>;

  if (response.error != null) {
    yield put(fetchCampaignsForFilterPillsFailure(response.error));
  } else {
    yield put(fetchCampaignsForFilterPillsSuccess(response.data));
  }
}

function* fetchCampaignsForFilterSaga(): SagaReturn {
  const params = {};
  yield call(fetchCampaignsList, params);
}

function* fetchAllCampaignsSaga({ payload }: ReturnType<typeof fetchAllCampaigns>): SagaReturn {
  const navParams = (yield select(getNavParams)) as RouteParams;
  const params = {
    ...navParams,
    include_pii: 'true',
    relationships: [
      'rule_outcomes.rule',
      'rule_outcomes.rule.campaign_rule_outcomes',
      'rule_outcomes.rule.campaign_rule_outcomes.outcome',
      'updated_by',
    ],
    ...payload,
  };

  yield call(fetchCampaignsList, params);
}

function* fetchFilterCampaignsSaga({
  payload,
}: ReturnType<typeof fetchFilterCampaigns>): SagaReturn {
  const navParams = (yield select(getNavParams)) as RouteParams;
  const params = {
    ...navParams,
    include_pii: 'true',
    ...payload,
    selectable_fields: ['uuid', 'name'],
    active: 'true',
  };
  yield call(fetchCampaignsList, params);
}

function* createCampaignSaga(): SagaReturn {
  const campaignData = {
    name: 'Use Case Name',
    description: '',
    id: uuidv4(),
    active: false,
  };
  yield put(createCampaignRequest());
  const response = (yield call([LitLingoClient.resources.campaigns, 'upsert'], {
    data: campaignData,
  })) as API.Response<API.Campaigns.Upsert>;

  if (response.error != null) {
    yield put(createCampaignFailure(response.error));
  } else {
    yield put(createCampaignSuccess(response.data));
    const customerDomain = (yield select(getCustomerDomain)) as string;
    const path = reverse({
      routeName: 'campaign-detail',
      routeParams: { campaignId: response.data.uuid },
      customerDomain,
    });
    yield put(push(path));
  }
}

function* deleteCampaignSaga(action: ReturnType<typeof deleteCampaign>): SagaReturn {
  const { payload } = action;
  const { id } = payload;
  yield put(deleteCampaignRequest(id));
  const response = (yield call(
    [LitLingoClient.resources.campaigns, 'delete'],
    id
  )) as API.Response<API.Campaigns.Delete>;
  if (response.error != null) {
    yield put(deleteCampaignFailure(response.error));
  } else {
    yield put(deleteCampaignSuccess(id));
    const customerDomain = (yield select(getCustomerDomain)) as string;
    const path = reverse({ routeName: 'campaign-list', customerDomain });
    yield put(push(path));
    yield put(prepareUndeleteAlert({ resource: 'campaigns', id, fetchAction: fetchAllCampaigns }));
  }
}

function* cloneCampaignSaga(action: ReturnType<typeof cloneCampaign>): SagaReturn {
  const { payload } = action;
  yield put(cloneCampaignRequest());
  const { resourceId: campaignId, customerId } = payload;
  const response = (yield call([LitLingoClient.resources.campaigns.extras, 'cloneCampaign'], {
    urlParams: { campaignId, customerId },
  })) as API.Response<API.Campaigns.CloneCampaign>;
  if (response.error != null) {
    yield put(cloneCampaignFailure(response.error));
  } else {
    yield put(showSuccessAlert('Use Case Duplicated'));
    yield put(cloneCampaignSuccess(response.data));
  }
}

function* fetchActiveIntegrationsSaga(
  action: ReturnType<typeof fetchActiveIntegrations>
): SagaReturn {
  const { payload } = action;
  yield put(fetchActiveIntegrationsRequest());

  const response = (yield call(
    [LitLingoClient.resources.campaigns.extras, 'getActiveIntegrations'],
    { params: { broad_search: payload.searchValue } }
  )) as API.Response<API.Campaigns.getActiveIntegrations>;
  if (response.error != null) {
    yield put(fetchActiveIntegrationsFailure(response.error));
  } else {
    yield put(fetchActiveIntegrationsSuccess(response.data));
  }
}

function* upsertCampaignSaga(action: ReturnType<typeof upsertCampaign>): SagaReturn {
  const { payload } = action;

  yield put(upsertCampaignRequest);

  const response = (yield call([LitLingoClient.resources.campaigns, 'upsert'], {
    data: payload,
  })) as API.Response<API.Campaigns.Upsert>;
  if (response.error != null) {
    yield put(upsertCampaignFailure(response.error));
  } else {
    yield put(showSuccessAlert('Saved Use Case'));
    yield put(upsertCampaignSuccess(response.data));

    if (payload.redirect) {
      const customerDomain = (yield select(getCustomerDomain)) as string;
      const path = reverse({
        routeName: 'campaign-detail',
        routeParams: { campaignId: response.data.uuid },
        customerDomain,
      });
      yield put(push(path));
    }
  }

  if (action.type === upsertCampaignWithChan.toString()) yield delay(500);
  yield put(upsertCampaignFulfill());
}

function* channels(): SagaReturn {
  const upserCampaignChan = (yield actionChannel(
    upsertCampaignWithChan.toString(),
    buffers.sliding(1)
  )) as Channel<ReturnType<typeof upsertCampaignWithChan>>;

  const fetchAllCampaignsChan = (yield actionChannel(
    fetchAllCampaignsDebounced.toString(),
    buffers.sliding(1)
  )) as Channel<ReturnType<typeof fetchAllCampaignsDebounced>>;

  yield debounce(400, fetchAllCampaignsChan, fetchAllCampaignsSaga);
  yield debounce(400, upserCampaignChan, upsertCampaignSaga);
}

function* campaignsSaga(): SagaReturn {
  yield takeLatest(fetchAllCampaignsForFilter.toString(), fetchCampaignsForFilterSaga);
  yield takeLatest(fetchAllCampaigns.toString(), fetchAllCampaignsSaga);
  yield takeEvery(fetchCampaignsForFilterPills.toString(), fetchCampaignsForFilterPillsSaga);

  yield takeLatest(upsertCampaign.toString(), upsertCampaignSaga);
  yield takeLatest(fetchSingleSimpleCampaign.toString(), fetchSingleSimpleCampaignSaga);
  yield takeLatest(fetchSingleCampaign.toString(), fetchSingleCampaignSaga);
  yield takeLatest(fetchSingleCampaignWithRules.toString(), fetchSingleCampaignWithRulesSaga);
  yield takeLatest(createCampaign.toString(), createCampaignSaga);
  yield takeLatest(deleteCampaign.toString(), deleteCampaignSaga);
  yield takeLatest(cloneCampaign.toString(), cloneCampaignSaga);
  yield takeLatest(fetchActiveIntegrations.toString(), fetchActiveIntegrationsSaga);
  yield takeLatest(fetchFilterCampaigns.toString(), fetchFilterCampaignsSaga);
  // @ts-expect-error check types
  yield channels();
}

export default campaignsSaga;
