/* eslint-disable camelcase */
/* eslint-disable max-lines */
import React from 'react';
import { ArrowChange } from 'selectors/rulesCompare';

type ComponentProps = {
  arrows: ArrowChange[];
};

const RulesCompareArrows: React.FC<ComponentProps> = ({ arrows }) => {
  const width = 100;

  return (
    <>
      <div className="py-4 relative" style={{ minWidth: width, width }}>
        <div
          className="absolute border-r border-litlingo-gray-2"
          style={{ top: 0, bottom: 0, right: '50%' }}
        />

        <svg height="100%" width="100%">
          <defs>
            <marker
              id="add-arrowhead"
              viewBox="0 0 10 10"
              refX="8"
              refY="5"
              markerWidth="6"
              markerHeight="6"
              orient="auto-start-reverse"
            >
              <path d="M 0 0 L 10 5 L 0 10 z" className="fill-current text-litlingo-success" />
            </marker>
            <marker
              id="delete-arrowhead"
              viewBox="0 0 10 10"
              refX="8"
              refY="5"
              markerWidth="6"
              markerHeight="6"
              orient="auto-start-reverse"
            >
              <path d="M 0 0 L 10 5 L 0 10 z" className="fill-current text-litlingo-alert" />
            </marker>
            <linearGradient id="grad-add" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop
                className="text-litlingo-gray-0.5"
                offset="0%"
                stopColor="currentColor"
                stopOpacity={1}
              />
              <stop
                className="text-litlingo-success-light"
                offset="100%"
                stopColor="currentColor"
                stopOpacity={1}
              />
            </linearGradient>
            <linearGradient id="grad-delete" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop
                className="text-litlingo-alert-light"
                offset="0%"
                stopColor="currentColor"
                stopOpacity={1}
              />
              <stop
                className="text-litlingo-gray-0.5"
                offset="100%"
                stopColor="currentColor"
                stopOpacity={1}
              />
            </linearGradient>
          </defs>

          <g>
            {arrows.map((arrow) => (
              <g key={arrow.id}>
                {arrow.type === 'add' ? (
                  <>
                    <polygon
                      fill="url(#grad-add)"
                      strokeWidth={1}
                      points={`0,${arrow.leftIndex * 24} ${width},${
                        arrow.rightIndex * 24
                      } ${width},${arrow.rightIndex * 24 + 24} 0,${arrow.leftIndex * 24 + 24}`}
                    />
                  </>
                ) : (
                  <>
                    <polygon
                      fill="url(#grad-delete)"
                      strokeWidth={1}
                      points={`0,${arrow.leftIndex * 24} ${width},${
                        arrow.rightIndex * 24
                      } ${width},${arrow.rightIndex * 24 + 24} 0,${arrow.leftIndex * 24 + 24}`}
                    />
                  </>
                )}
              </g>
            ))}
          </g>
        </svg>
      </div>
    </>
  );
};

export default RulesCompareArrows;
