import { deleteURLParams, setURLParams } from 'actions';
import Select from 'components/Select';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { WidgetDecoratorFilterConfig } from 'types';

const WidgetDecoratorFilter: React.FC<WidgetDecoratorFilterConfig> = (props) => {
  const { prefix, name, allLabel = 'All data', fetchDataAction, getDataSelector } = props;

  const dispatch = useDispatch();
  const data = useSelector(getDataSelector);
  const params = useSelector(getNavParamsByResource(prefix));

  useEffect(() => {
    dispatch(fetchDataAction());
  }, [dispatch, fetchDataAction]);

  const handleChange = (value: string): void => {
    if (value === 'all_data') {
      dispatch(deleteURLParams([`${prefix}__${[name]}`]));
    } else {
      dispatch(setURLParams({ [`${prefix}__${[name]}`]: value }));
    }
  };

  const options = [
    { label: allLabel, value: 'all_data' },
    ...data.map((item) => ({ label: item.name, value: item.uuid })),
  ];

  const param = params[name];
  const value = Array.isArray(param) ? param[0] : param;

  return (
    <div className="ml-1">
      <Select
        onChange={handleChange}
        options={options}
        className="max-h-72"
        value={value ?? 'all_data'}
      />
    </div>
  );
};

export default WidgetDecoratorFilter;
