import { createRelationship, patchItemsToRelationship, removeRelationship } from 'actions';
import { SWITCH_HORIZONTAL_ICON } from 'constants/commonIcons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getFetchRelationshipTypesLoading } from 'selectors/annotator';
import { getAnnotatorsFromRules } from 'selectors/rule';
import { useSelector } from 'store';
import type {
  MRuleConfigNode,
  NormalizedRelationshipType,
  RelationshipArrayItem,
  RuleAnnotator,
  UUID,
} from 'types';

type ComponentProps = {
  relationship: RelationshipArrayItem | null;
  annotators: MRuleConfigNode[];
  relationshipTypes: NormalizedRelationshipType[];
  configId: string;
  ruleId: UUID;
};

const RenderEditRelationship: React.FC<ComponentProps> = ({
  relationship,
  annotators,
  relationshipTypes,
  configId,
  ruleId,
}) => {
  const dispatch = useDispatch();

  const relationshipTypesLoading = useSelector(getFetchRelationshipTypesLoading);
  const ruleAnnotators = useSelector((state) => getAnnotatorsFromRules(state, ruleId));

  if (relationship == null) {
    return null;
  }

  if (relationshipTypesLoading) {
    return <span>Loading...</span>;
  }

  const createArrowIcon = (): JSX.Element => (
    <svg
      className="h-8 w-5 mx-1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      stroke="currentColor"
    >
      <path d="M17 8l4 4m0 0l-4 4m4-4H3" />
    </svg>
  );

  const getAnnotatorRealName = (annotatorId: UUID | undefined): RuleAnnotator | undefined => {
    if (ruleAnnotators != null) {
      return ruleAnnotators.find((element) => element.annotator_uuid === annotatorId);
    }

    return undefined;
  };

  const handleDelete = (id: string): void => {
    dispatch(removeRelationship({ configId, relationshipId: id }));
  };

  const handleSwitch = (): void => {
    if (relationship.a.id && relationship.b.id && relationship.type)
      dispatch(
        patchItemsToRelationship({
          relationshipId: relationship.id,
          payload: {
            configId,
            type: relationship.type,
            id: relationship.id,
            annotation_a: { id: relationship.b.id },
            annotation_b: { id: relationship.a.id },
          },
        })
      );
  };

  const createDeleteIcon = (relationshipId: string): JSX.Element => (
    <button type="button" onClick={(): void => handleDelete(relationshipId)}>
      <svg
        className="h-8 w-5 ml-2 filter-color"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        stroke="currentColor"
      >
        <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
      </svg>
    </button>
  );

  const createSwitchIcon = (): JSX.Element => (
    <button type="button" onClick={(): void => handleSwitch()}>
      {SWITCH_HORIZONTAL_ICON}
    </button>
  );

  const handleSelect = (
    key: 'annotation_a' | 'annotation_b' | 'type',
    value: string,
    relationshipId: string
  ): void => {
    const data = key === 'type' ? value : { id: value };
    const createRelationshipPayload = {
      configId,
      id: relationshipId,
      [key]: data,
    };
    dispatch(createRelationship(createRelationshipPayload));
  };

  const createSelect = (
    selectedValue: string | null,
    optionsData: MRuleConfigNode[] | NormalizedRelationshipType[],
    key: 'annotation_a' | 'annotation_b' | 'type',
    relationshipId: string
  ): JSX.Element => (
    <div className="inline-block relative">
      <select
        defaultValue={selectedValue ?? undefined}
        className="truncate form-select-relationship w-full"
        onChange={(e): void => handleSelect(key, e.target.value, relationshipId)}
      >
        {/* @ts-ignore */}
        {optionsData.map((node: RuleConfigNode | NormalizedRelationshipType) => (
          <option key={node.id} value={node.id}>
            {getAnnotatorRealName('annotatorId' in node ? node.annotatorId : '')?.annotator?.name ||
              node.name}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center text-gray-700">
        <svg
          className="fill-current h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          stroke="currentColor"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );

  return (
    <div className="flex flex-row justify-between my-2">
      {createSelect(relationship.a.id, annotators, 'annotation_a', relationship.id)}
      {createArrowIcon()}
      {createSelect(relationship.type, relationshipTypes, 'type', relationship.id)}
      {createArrowIcon()}
      {createSelect(relationship.b.id, annotators, 'annotation_b', relationship.id)}
      {createSwitchIcon()}
      {createDeleteIcon(relationship.id)}
    </div>
  );
};

export default RenderEditRelationship;
