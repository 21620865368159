import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { TagGroup } from 'types';
import { upsertTagGroup } from '../../actions';

type TagGroupData = Pick<TagGroup, 'name' | 'description'> & { uuid?: TagGroup['uuid'] };

interface Props {
  toggleForm: boolean;
  setToggleForm: React.Dispatch<React.SetStateAction<boolean>>;
  initialValues?: TagGroupData;
}

const TagGroupsForm: React.VFC<Props> = ({
  toggleForm,
  setToggleForm,
  initialValues = { name: '', description: '' },
}) => {
  const dispatch = useDispatch();
  const form = useForm({
    defaultValues: initialValues,
    reValidateMode: 'onChange',
  });

  const handleSubmit = (data: { name: string; description: string }): void => {
    const upsertData: TagGroupData = { ...data };
    if (initialValues.uuid) {
      upsertData.uuid = initialValues.uuid;
    }
    dispatch(
      // @ts-ignore
      upsertTagGroup({
        ...upsertData,
      })
    );
    setToggleForm((prevState) => !prevState);
  };

  return (
    <div
      className={`overflow-hidden transition-all duration-500 ease-in-out shadow ${
        toggleForm ? 'max-h-96 p-4 mt-4 border rounded-md' : 'max-h-0 p-0'
      }`}
      style={{ boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)' }}
    >
      <h2 className="text-heading-2">
        {initialValues.uuid ? 'Edit Tag Group' : 'Create New Tag Group'}
      </h2>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <div className="flex flex-col gap-2 mt-2">
          <label htmlFor="value" className="block text-small">
            {'Name '}
            <span className="litlingo-red-color">*</span>
            <div className="mt-1">
              <input
                autoComplete="off"
                id="name"
                placeholder="New Tag Group"
                name="name"
                ref={form.register({
                  required: 'Add a tag group name to make a new tag',
                })}
                className="form-input text-body py-2 w-68 transition duration-150 ease-in-out rounded-md placeholder-italic placeholder-litlingo-gray-6"
              />
            </div>
            {form.errors.name && (
              <span className="text-litlingo-red-bg text-sm font-normal">
                {form.errors.name.message}
              </span>
            )}
          </label>

          <label htmlFor="description" className="block text-small">
            Description
            <div className="mt-1 rounded">
              <textarea
                id="description"
                name="description"
                placeholder="A short description that can help others understand the purpose of this tag group."
                rows={3}
                className="form-input block w-112 resize-none transition duration-150 ease-in-out placeholder-italic placeholder-litlingo-gray-6"
              />
            </div>
          </label>
        </div>

        <div className="flex justify-end gap-2 mt-4">
          <button
            onClick={(): void => setToggleForm((prevState) => !prevState)}
            type="button"
            className="button button--secondary flex justify-center text-base leading-5 font-bold text-litlingo-gray-5 bg-white border-litlingo-gray-3 w-20 h-8 text-center"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="button button--primary flex justify-center text-base leading-5 font-bold text-white w-20 h-8"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default TagGroupsForm;
