/* eslint-disable max-lines */

import { deleteUser } from 'actions';
import { removeUsers, selectUsers } from 'actions/userPermissionsPage';
import ConfirmModal from 'components/ConfirmModal';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import type { User } from 'types';

type ComponentProps = {
  users: User[];
  shouldDeleteUser?: boolean;
};

const UsersInformationSection: React.FC<ComponentProps> = ({ users, shouldDeleteUser = false }) => {
  const dispatch = useDispatch();

  const [isDeletingUsers, setIsDeletingUsers] = useState(false);

  const toggleConfirmModal = (): void => setIsDeletingUsers(false);

  const handleDeleteUser = (): void => {
    dispatch(deleteUser({ uuids: users.map((user) => user.uuid) }));
    dispatch(selectUsers([]));
    toggleConfirmModal();
  };

  const handleDelete = (): void => {
    if (shouldDeleteUser) {
      setIsDeletingUsers(true);
    } else {
      dispatch(removeUsers([...users]));
      dispatch(selectUsers([]));
    }
  };

  return (
    <div className="px-3 pt-4 flex flex-row gap-1 w-full">
      <div className="flex flex-row justify-end w-full">
        <button
          type="button"
          className="self-end text-body focus:outline-none"
          onClick={handleDelete}
        >
          <span className="text-litlingo-alert underline">Delete</span>
        </button>
      </div>

      {isDeletingUsers && (
        <ConfirmModal
          text="Are you sure you want to delete these users?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDeleteUser}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </div>
  );
};

export default UsersInformationSection;
