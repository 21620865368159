import PermissionsLink from 'components/PermissionsLink';
import React from 'react';
import type { RuleResultsV2, RuleRevision } from 'types';

type RulesViolatedFrom = 'identifiers' | 'models';

type ComponentProps = {
  rulesViolated: RuleResultsV2[];
  classes?: string;
  rulesViolatedFrom?: RulesViolatedFrom;
  showV2?: boolean;
};

const SentencesViolatedSectionGlobal: React.FC<ComponentProps> = ({
  rulesViolated,
  classes,
  rulesViolatedFrom,
  showV2 = false,
}) => {
  if (rulesViolated && rulesViolated.length !== 0) {
    return (
      <div
        className={`flex flex-col items-start w-1/3 ${classes} ${
          showV2 ? 'bg-litlingo-highlight' : 'litlingo-pink-color'
        } rounded`}
      >
        <div className="flex inline-flex self py-4 px-4">
          <svg
            className="h-5 w-5 mr-2 text font-bold"
            fill="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zm12.73-1.41A8 8 0 104.34 4.34a8 8 0 0011.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z" />
          </svg>
          <span className="text-xl text font-bold" data-testid="matched-title">
            {`Matched ${rulesViolated.length} ${rulesViolated.length > 1 ? 'rules' : 'rule'}`}
          </span>
        </div>
        <div className="h-32 overflow-auto">
          <ol className="list-decimal ml-10 pl-4 text-sm text whitespace-no-wrap">
            {rulesViolated.map((ruleItem) => (
              <li key={ruleItem.rule.uuid} className="w-full">
                {(ruleItem?.rule as RuleRevision)?.rule_group_uuid && (
                  <PermissionsLink
                    routeName="global-rule-group-manager"
                    routeParams={{
                      ruleId: (ruleItem.rule as RuleRevision).rule_group_uuid,
                    }}
                    className="text text--primary hover:underline"
                    action="rules.retrieve"
                  >
                    {ruleItem.rule.name}
                  </PermissionsLink>
                )}
              </li>
            ))}
          </ol>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`w-1/3 flex flex-col items-start ${classes}  ${
        showV2 ? 'bg-litlingo-gray-6' : 'litlingo-gray-bg-color-3'
      } rounded`}
    >
      <div className="flex inline-flex pt-2 pb-4 px-4">
        <svg
          className="h-5 w-5 mr-2 text text--bold"
          fill="#FCFCFC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          viewBox="-0.5 0 22 20"
          stroke="#FCFCFC"
        >
          <path d="M0 10a10 10 0 1120 0 10 10 0 01-20 0zm16.32-4.9L5.09 16.31A8 8 0 0016.32 5.09zm-1.41-1.42A8 8 0 003.68 14.91L14.91 3.68z" />
        </svg>
        <span className="text-xl text text--bold text--white">
          {`No ${rulesViolatedFrom === 'models' ? 'model' : 'identifier'} matched`}
        </span>
      </div>
    </div>
  );
};

export default SentencesViolatedSectionGlobal;
