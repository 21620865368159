import { fetchAllTags } from 'actions/tags';
import { SEARCH_ICON } from 'constants/filterIcons';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTags from 'react-tag-autocomplete';
import { getFetchAllTagsLoading, getTagsForFilters } from 'selectors/tags';
import { addTestCaseTagLoading, removeTestCaseTagLoading } from 'selectors/testCases';
import 'styles/labelsStyle.css';

type EnvelopeTagsProps = {
  tagsSelected: string[];
  setTagsSelected: React.Dispatch<React.SetStateAction<string[]>>;
};

const TestCaseMultiTags: React.FC<EnvelopeTagsProps> = (props) => {
  const { tagsSelected, setTagsSelected } = props;

  const dispatch = useDispatch();
  const allTags = useSelector(getTagsForFilters);
  const tagsLoading = useSelector(getFetchAllTagsLoading);
  const addLoading = useSelector(addTestCaseTagLoading);
  const removeLoading = useSelector(removeTestCaseTagLoading);

  const suggestions = allTags.map((tag) => ({ id: tag.uuid, name: tag.value }));

  useEffect(() => {
    dispatch(fetchAllTags({ limit: -1 }));
  }, [dispatch]);

  const handleAddition = (tag: { name: string }): void => {
    setTagsSelected([...tagsSelected.filter((t) => t !== tag.name), tag.name]);
  };

  return (
    <div className="flex flex-row text-body items-center gap-1 py-1.5 px-1 h-8 w-44 envelope-list-tags bg-white border border-litlingo-gray-2 rounded overflow-x">
      <div className="flex items-center w-5 h-full">{SEARCH_ICON()}</div>
      <div className="flex h-full w-4/5 z-100">
        <ReactTags
          allowNew
          // placeholderText is not present on the types library but it is on the library docs
          // @ts-ignore
          placeholderText="Add tag"
          suggestions={suggestions}
          onAddition={handleAddition}
          onDelete={(): Record<string, never> => ({})}
          autofocus
          maxSuggestionsLength={25}
          minQueryLength={0}
        />
      </div>
      {(tagsLoading || addLoading || removeLoading) && (
        <div className="flex flex-row">
          <div
            className="ml-3 loader ease-linear rounded-full border-2 border-gray-200 h-4 w-4 self-center"
            data-testid="loader"
          />
        </div>
      )}
    </div>
  );
};

export default TestCaseMultiTags;
