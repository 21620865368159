import React from 'react';
import 'styles/labelsStyle.css';
import TagsDropdown from './EnvelopeBulkTagActionDropdown';

type EnvelopeTagsProps = {
  setTagsSelected: React.Dispatch<React.SetStateAction<string[]>>;
  tagsSelected: string[];
  setTagsUnselected: React.Dispatch<React.SetStateAction<string[]>>;
  tagsUnselected: string[];
};

const EnvelopeMultiTags: React.FC<EnvelopeTagsProps> = (props) => {
  const { setTagsSelected, tagsSelected, tagsUnselected, setTagsUnselected } = props;

  return (
    <TagsDropdown
      setTagsSelected={setTagsSelected}
      tagsSelected={tagsSelected}
      tagsUnselected={tagsUnselected}
      setTagsUnselected={setTagsUnselected}
    />
  );
};

export default EnvelopeMultiTags;
