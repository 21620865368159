/* eslint-disable max-lines */
import { copyItem, createNewGroup, pasteItem } from 'actions';

import SelectRedesign from 'components/Select/SelectRedesign';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import type { MRuleConfig } from 'types';
import { operatorAcceptsOperatorChildren, operatorName } from './RuleManagerUtils';

type ComponentProps = {
  selectedId: string;
  idToMove: string | null;
  arrayTree: MRuleConfig[];
  setIsCollapsedAll: (value: boolean) => void;
};

const RuleManagerControls: React.FC<ComponentProps> = ({
  arrayTree,
  selectedId,
  idToMove,
  setIsCollapsedAll,
}) => {
  const dispatch = useDispatch();
  const node = useSelector((state) => state.config.items[selectedId]);

  const buttonMainClass = 'button button--secondary font-bold h-8 whitespace-no-wrap';

  const isRoot = node.parent == null;

  useEffect(() => {
    if (isRoot && node.typeOfConfig !== 'ANNOTATION_MATCH' && node.groups.length > 5) {
      setIsCollapsedAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  const handleCreateNewGroup = (position: string): void => {
    const nodeId = position === 'sibling' ? node?.parent || '' : node.id;

    const pos = arrayTree.reduce((acc, val, idx) => {
      if (val.id === nodeId || val.parent.includes(nodeId)) {
        return idx;
      }
      return acc;
    }, -1);

    let parentId = selectedId;
    if (position === 'sibling' && node.parent) {
      parentId = node.parent;
    }
    if (isRoot && position === 'sibling') return;
    dispatch(createNewGroup({ parentId, futureNodeIndex: pos + 1 }));
  };

  const handleCopyItem = (): void => {
    dispatch(copyItem({ idToCopy: selectedId }));
  };

  const handlePasteItem = (): void => {
    dispatch(pasteItem({ targetNodeId: selectedId, useIdentifierId: true }));
  };

  const renderSelectButton = (): JSX.Element | null => {
    const casted = node.name as keyof typeof operatorAcceptsOperatorChildren;
    if (operatorAcceptsOperatorChildren[casted] == null) {
      return null;
    }

    let options = [
      {
        value: 'child',
        label: 'As child',
      },
      {
        value: 'sibling',
        label: 'As sibling',
      },
    ];

    if (isRoot) {
      options = options.filter((option) => option.value !== 'sibling');
    }

    return (
      <div className="w-30">
        <SelectRedesign
          onChange={(value): void => {
            handleCreateNewGroup(value);
          }}
          options={options}
          value=""
          placeholder="Insert group"
        />
      </div>
    );
  };

  const renderMoveDeleteButtons = (): JSX.Element => {
    if (isRoot) {
      return (
        <div className="flex flex-row gap-1">
          <button type="button" onClick={handleCopyItem} className={buttonMainClass}>
            Copy
          </button>
          {operatorAcceptsOperatorChildren[
            node.name as keyof typeof operatorAcceptsOperatorChildren
          ] != null && (
            <button type="button" onClick={handlePasteItem} className={buttonMainClass}>
              Paste
            </button>
          )}
        </div>
      );
    }

    return (
      <div className="flex flex-row gap-1">
        {operatorName[node.name as keyof typeof operatorName] != null && (
          <>
            <button type="button" onClick={handleCopyItem} className={buttonMainClass}>
              Copy
            </button>
            {operatorAcceptsOperatorChildren[
              node.name as keyof typeof operatorAcceptsOperatorChildren
            ] != null && (
              <>
                <button type="button" onClick={handlePasteItem} className={buttonMainClass}>
                  Paste
                </button>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const renderNotMoveMode = (): JSX.Element | null => {
    if (idToMove != null) {
      return null;
    }

    return (
      <div className="pl-1 flex flex-row gap-2 w-full">
        <div className="flex flex-row gap-1">{renderSelectButton()}</div>
        <div className="flex flex-row gap-1">{renderMoveDeleteButtons()}</div>
      </div>
    );
  };

  return (
    <div className="z-10 flex flex-row justify-between flex-1 bg-white">
      <div className="flex flex-row gap-4 w-full">{renderNotMoveMode()}</div>
    </div>
  );
};

export default RuleManagerControls;
