import { deleteURLParams, setURLParams } from 'actions';
import MultiselectDropdownRedesign, {
  MultiSelectOption,
} from 'components/MultiSelectDropdown/MultiSelectDropdownRedesign';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { getUserIntegrationsCount } from 'selectors/users';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';

type ComponentProps = {
  className?: string;
  resource: string;
};

const UserIntegrationFilter: React.FC<ComponentProps> = (props) => {
  const { className, resource } = props;

  const dispatch = useDispatch();
  const filters = useSelector(getNavParamsByResource(resource));
  const userIntegrationsCount = useSelector(getUserIntegrationsCount);

  const options = [
    { value: 'insight_active_chat', label: 'Insight: Chat' },
    { value: 'insight_active_email', label: 'Insight: Email' },
    { value: 'prevent_active_chat', label: 'Prevent: Chat' },
    { value: 'prevent_active_email', label: 'Prevent: Email' },
  ];

  const handleChange = (option: MultiSelectOption): void => {
    logEvent(`use-cases-enabled-filter-${option}`);

    if (Object.keys(filters).includes(option.value)) {
      dispatch(deleteURLParams([`${resource}__${option.value}`]));
      return;
    }
    if (option.value === 'insight_active_chat') {
      dispatch(
        setURLParams({
          [`${resource}__insight_active_chat`]: 'true',
        })
      );
    }
    if (option.value === 'insight_active_email') {
      dispatch(
        setURLParams({
          [`${resource}__insight_active_email`]: 'true',
        })
      );
    }
    if (option.value === 'prevent_active_chat') {
      dispatch(
        setURLParams({
          [`${resource}__prevent_active_chat`]: 'true',
        })
      );
    }
    if (option.value === 'prevent_active_email') {
      dispatch(
        setURLParams({
          [`${resource}__prevent_active_email`]: 'true',
        })
      );
    }
  };

  const selectedItems = [];
  if (Object.keys(filters).includes('insight_active_chat')) {
    selectedItems.push({ label: 'Insight: Chat', value: 'insight_active_chat' });
  }
  if (Object.keys(filters).includes('insight_active_email')) {
    selectedItems.push({ label: 'Insight: Email', value: 'insight_active_email' });
  }
  if (Object.keys(filters).includes('prevent_active_chat')) {
    selectedItems.push({ label: 'Prevent: Chat', value: 'prevent_active_chat' });
  }
  if (Object.keys(filters).includes('prevent_active_email')) {
    selectedItems.push({ label: 'Prevent: Email', value: 'prevent_active_email' });
  }

  return (
    <div className="w-35">
      <MultiselectDropdownRedesign
        className={className || ''}
        placeholder="Integrations"
        onChange={handleChange}
        options={options}
        selectedItems={selectedItems}
        dataTestid="active"
        counts={userIntegrationsCount}
      />
    </div>
  );
};

export default UserIntegrationFilter;
