import { deleteTag } from 'actions';
import Badge from 'components/Badge';
import ConfirmModal from 'components/ConfirmModal';
import LinkLookup from 'components/LinkLookup';
import { EDIT_ICON, TRASH_ICON } from 'constants/commonIcons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import type { Tag } from 'types';

type ComponentProps = {
  tag: Tag;
  handleChangeTag: (tag: Tag) => void;
};

const TagListItem: React.FC<ComponentProps> = ({ tag, handleChangeTag }) => {
  const dispatch = useDispatch();
  const [isDeletingTag, setIsDeletingTag] = useState(false);

  const toggleConfirmModal = (): void => {
    setIsDeletingTag(!isDeletingTag);
  };

  const handleRemoveTag = (): void => {
    dispatch(deleteTag({ id: tag.uuid }));
    toggleConfirmModal();
  };

  return (
    <tr className="border-t border-gray-200 hover:bg-gray-100 table-row">
      <td className="table-wrapper__td">
        <div className="flex items-center">
          <div className="overflow-hidden">
            <div className="text-sm leading-5 font-medium litlingo-flex-break-words">
              <LinkLookup
                routeName="envelope-list"
                queryParams={{ envelopes__tags: tag.uuid, envelopes__offset: '0' }}
                className="inline-flex items-center"
              >
                <Badge title={tag.value} colorClass={tag.color} />
              </LinkLookup>
            </div>
          </div>
        </div>
      </td>
      <td className="table-wrapper__td">
        <div className="text-sm leading-5 litlingo-flex-break-words text-gray-800">
          {tag.description}
        </div>
      </td>
      <td className="table-wrapper__td">
        <div className="text-sm leading-5  litlingo-flex-break-words">{tag?.entity_count}</div>
      </td>
      <td className="table-wrapper__td">
        <button type="button" className="mr-4" onClick={(): void => handleChangeTag(tag)}>
          {EDIT_ICON()}
        </button>
        <button type="button" className="mr-4 w-5 h-5" onClick={toggleConfirmModal}>
          {TRASH_ICON}
        </button>
      </td>
      {isDeletingTag && (
        <ConfirmModal
          text="Are you sure you want to delete this tag?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleRemoveTag}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </tr>
  );
};

export default TagListItem;
