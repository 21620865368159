import { StorageError } from '@errors';
import { omit, pick } from 'lodash';

import Storage from './base';

class ChromeStorage<T> extends Storage<T> {
  protected _key: string;

  protected _area: chrome.storage.AreaName;

  constructor(key: string, area: chrome.storage.AreaName = 'local') {
    super();

    if (window.chrome == null) {
      throw new StorageError(
        'ChromeStorage class can only be used in chrome extensions'
      );
    }

    this._key = key;
    this._area = area;
  }

  async get<K extends keyof T = keyof T>(
    keys?: K[]
  ): Promise<Pick<Partial<T>, K>> {
    return new Promise((resolve, reject) => {
      window.chrome.storage[this._area].get(this._key, (items) => {
        if (window.chrome.runtime.lastError != null) {
          reject(window.chrome.runtime.lastError);
          return;
        }

        const obj = items[this._key];
        if (obj == null) {
          resolve({} as Partial<T>);
          return;
        }

        if (keys != null) {
          resolve(pick(obj, keys));
        }

        resolve(obj);
      });
    });
  }

  async set(items: Partial<T>): Promise<void> {
    const current = await this.get();
    const updated = { [this._key]: { ...current, ...items } };

    return new Promise((resolve, reject) => {
      window.chrome.storage[this._area].set(updated, () => {
        if (window.chrome.runtime.lastError != null) {
          reject(window.chrome.runtime.lastError);
          return;
        }

        resolve();
      });
    });
  }

  async remove(keys?: (keyof T)[]): Promise<void> {
    if (keys == null) {
      return new Promise((resolve, reject) => {
        window.chrome.storage[this._area].remove(this._key, () => {
          if (window.chrome.runtime.lastError != null) {
            reject(window.chrome.runtime.lastError);
            return;
          }

          resolve();
        });
      });
    }

    const current = await this.get();
    const updated = { [this._key]: omit(current, keys) };

    return new Promise((resolve, reject) => {
      window.chrome.storage[this._area].set(updated, () => {
        if (window.chrome.runtime.lastError != null) {
          reject(window.chrome.runtime.lastError);
          return;
        }

        resolve();
      });
    });
  }
}

export default ChromeStorage;
