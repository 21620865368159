/* eslint-disable max-lines */
/* eslint-disable camelcase */
import LoadingIndicator from 'components/LoadingIndicator';
import SummarySection from 'components/SummarySection';
import { INDENTATION_ICON, NEW_EDIT_ICON } from 'constants/commonIcons';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getModel, getUpsertModelLoading } from 'selectors/models';
import { getTestCaseSummary } from 'selectors/testCases';
import { useSelector } from 'store';
import EditModelModal from './EditModelModal';

type RouterParams = { ruleId: string; modelId: string };

const ModelHeader: React.FC = () => {
  const { modelId } = useParams<RouterParams>();
  const model = useSelector(getModel(modelId));
  const loading = useSelector(getUpsertModelLoading);
  const summary = useSelector(getTestCaseSummary);

  const [isFullDescription, setIsFullDescription] = useState(false);
  const [clampedDescription, setClampedDescription] = useState(false);

  const [isEditingModel, setIsEditingModel] = useState(false);

  const descriptionRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        const newC = node.scrollHeight > node.clientHeight + 2;
        setClampedDescription(newC);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [model, isFullDescription]
  );

  const handleClinkOnEdit = (): void => {
    setIsEditingModel(true);
  };
  const toggleEditModal = (): void => setIsEditingModel(false);

  const renderSummary = (): JSX.Element => <SummarySection summary={summary} renderTotalTests />;

  if (!model) return null;

  return (
    <>
      <header>
        <div className="px-6">
          <div className="flex flex-col gap-1">
            <div className="flex justify-between w-full">
              <div className="flex items-center w-3/5">
                <div className="flex flex-row items-baseline gap-2">
                  <span className="text-heading-2 break-words">{model.name}</span>
                  <button
                    type="button"
                    className="flex-none w-5 self-baseline focus:outline-none"
                    onClick={handleClinkOnEdit}
                  >
                    {NEW_EDIT_ICON('text-litlingo-primary-120')}
                  </button>
                  {loading && <LoadingIndicator />}
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between items-stretch mt-1">
              <div className="flex flex-col h-full justify-start gap-2 w-3/5">
                {model && model.description && (
                  <div
                    className={`${
                      isFullDescription ? 'break-words' : 'flex flex-row break-all'
                    } text-body w-80ch`}
                  >
                    <span
                      ref={descriptionRef}
                      className={`description ${isFullDescription ? '' : 'clamped-description'}`}
                    >
                      {model.description}
                    </span>
                    {(clampedDescription || isFullDescription) && (
                      <button
                        className={`text-body focus:outline-none ${
                          isFullDescription ? 'ml-2' : ''
                        }`}
                        type="button"
                        onClick={(): void => setIsFullDescription((v) => !v)}
                      >
                        <span className="underline text-litlingo-primary whitespace-no-wrap">
                          {isFullDescription ? 'Less' : 'More'}
                        </span>
                      </button>
                    )}
                  </div>
                )}
                {model.categories && (
                  <div className="flex flex-row gap-2 pl-6 text-small">
                    {INDENTATION_ICON}
                    <span className="flex gap-1">Has {model.categories.length} Models</span>
                  </div>
                )}
                <div className="flex flex-row gap-2 text-small">
                  <span className="flex gap-1">Priority: {model.priority}</span>
                </div>
              </div>
              <div className="self-end">{renderSummary()}</div>
            </div>
          </div>
        </div>
      </header>

      {isEditingModel && (
        <EditModelModal
          isModalOpen={isEditingModel}
          toggleModalOpen={toggleEditModal}
          modelToEdit={model}
        />
      )}
    </>
  );
};

export default ModelHeader;
