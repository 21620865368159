import { deleteLanguageMatcher } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import { DELETE_ICON } from 'constants/annotator';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import type { MLanguageMatcher, UUID } from 'types';

type ComponentProps = {
  setHighlightedLanguageMatcher: React.Dispatch<React.SetStateAction<string | undefined>>;
  activeLanguageMatcher: MLanguageMatcher;
  setValue: (field: string, value: string) => void;
};

const DeleteMatcherButton: React.FC<ComponentProps> = ({
  setValue,
  setHighlightedLanguageMatcher,
  activeLanguageMatcher,
}) => {
  const { annotatorId } = useParams<{ annotatorId: string }>();

  const dispatch = useDispatch();
  const [isDeletingLanguageMatcher, setIsDeletingLanguageMatcher] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<null | UUID>(null);

  const toggleConfirmModal = (): void => setIsDeletingLanguageMatcher(false);

  const handleDelete = (): void => {
    if (idToBeDeleted != null) {
      dispatch(deleteLanguageMatcher({ languageMatcherId: idToBeDeleted, annotatorId }));
      toggleConfirmModal();
      setValue('name', '');
      setValue('description', '');
      setHighlightedLanguageMatcher('');
    }
  };

  const handleDeleteClick = (languageMatcherId: UUID): void => {
    setIsDeletingLanguageMatcher(true);
    setIdToBeDeleted(languageMatcherId);
  };

  return (
    <div className="flex justify-end items-center min-h-12 border-t">
      <span className="mr-10 shadow-sm rounded-md">
        <button
          onClick={(): void => handleDeleteClick(activeLanguageMatcher.uuid)}
          type="button"
          className="inline-flex items-center px-3 litlingo-annotator-delete-matcher focus:outline-none"
        >
          {DELETE_ICON}
          <span className="pl-1">Delete matcher</span>
        </button>
      </span>
      {isDeletingLanguageMatcher && idToBeDeleted && (
        <ConfirmModal
          text="Are you sure you want to delete this language matcher?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDelete}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </div>
  );
};

export default DeleteMatcherButton;
