import type { ErrorData } from '@litlingo/client';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  deleteTemplateFailure,
  deleteTemplateRequest,
  deleteTemplateSuccess,
  fetchSingleTemplateFailure,
  fetchSingleTemplateRequest,
  fetchSingleTemplateSuccess,
  fetchTemplatesFailure,
  fetchTemplatesRequest,
  fetchTemplatesSuccess,
  upsertTemplateFailure,
  upsertTemplateRequest,
  upsertTemplateSuccess,
} from 'actions';
import type { API, ErrorObject, NormalizedResource, Template, UUID } from 'types';

export type TemplatesState = {
  selectedTemplate: Template;
  items: NormalizedResource<Template>;
  listIds: UUID[];
  count: number;
  loading: string[];
  error: ErrorData | null;
};

type TemplatesReducer<P = void> = (state: TemplatesState, action: PayloadAction<P>) => void;

const initialState: TemplatesState = {
  selectedTemplate: {} as Template,
  error: null,
  loading: [],
  items: {},
  listIds: [],
  count: 0,
};

const handleFetchTemplatesRequest: TemplatesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchTemplatesRequest.toString()],
});

const handleFetchTemplatesFailure: TemplatesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchTemplatesRequest.toString()),
});

const handleFetchTemplatesSuccess: TemplatesReducer<API.WrappedAPIResponse<Template>> = (
  state,
  { payload }
) => {
  const templates: NormalizedResource<Template> = {};
  const ids: UUID[] = [];

  payload.records.forEach((template) => {
    templates[template.uuid] = template;
    ids.push(template.uuid);
  });

  return {
    ...state,
    items: templates,
    listIds: ids,
    count: payload.count,
    loading: state.loading.filter((s) => s !== fetchTemplatesRequest.toString()),
  };
};

const handleFetchSingleTemplateRequest: TemplatesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchSingleTemplateRequest.toString()],
});

const handleFetchSingleTemplateFailure: TemplatesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchSingleTemplateRequest.toString()),
});

const handleFetchSingleTemplateSuccess: TemplatesReducer<Template> = (state, { payload }) => ({
  ...state,
  selectedTemplate: payload,
  loading: state.loading.filter((s) => s !== fetchSingleTemplateRequest.toString()),
});

const handleUpsertTemplateRequest: TemplatesReducer<Template> = (state) => ({
  ...state,
  loading: [...state.loading, upsertTemplateRequest.toString()],
});

const handleUpsertTemplateSuccess: TemplatesReducer<Template> = (state, { payload }) => ({
  ...state,
  items: {
    ...state.items,
    [payload.uuid]: {
      ...state.items[payload.uuid],
      ...payload,
    },
  },
  loading: state.loading.filter((s) => s !== upsertTemplateRequest.toString()),
});

const handleUpsertTemplateFailure: TemplatesReducer<Template> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== upsertTemplateRequest.toString()),
});

const handleDeleteTemplateRequest: TemplatesReducer<string> = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, deleteTemplateRequest.toString()],
});

const handleDeleteTemplateSuccess: TemplatesReducer<string> = (state, { payload: id }) => {
  const items = { ...state.items };
  delete items[id];

  return {
    ...state,
    items,
    loading: state.loading.filter((s) => s !== deleteTemplateRequest.toString()),
  };
};

const handleDeleteTemplateFailure: TemplatesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== deleteTemplateRequest.toString()),
});

const handlers = {
  [fetchTemplatesSuccess.toString()]: handleFetchTemplatesSuccess,
  [fetchTemplatesFailure.toString()]: handleFetchTemplatesFailure,
  [fetchTemplatesRequest.toString()]: handleFetchTemplatesRequest,
  [fetchSingleTemplateSuccess.toString()]: handleFetchSingleTemplateSuccess,
  [fetchSingleTemplateFailure.toString()]: handleFetchSingleTemplateFailure,
  [fetchSingleTemplateRequest.toString()]: handleFetchSingleTemplateRequest,
  [upsertTemplateSuccess.toString()]: handleUpsertTemplateSuccess,
  [upsertTemplateFailure.toString()]: handleUpsertTemplateFailure,
  [upsertTemplateRequest.toString()]: handleUpsertTemplateRequest,
  [deleteTemplateSuccess.toString()]: handleDeleteTemplateSuccess,
  [deleteTemplateFailure.toString()]: handleDeleteTemplateFailure,
  [deleteTemplateRequest.toString()]: handleDeleteTemplateRequest,
};

const TemplatesReducer = createReducer(initialState, handlers);

export default TemplatesReducer;
