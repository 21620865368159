/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { clearTree, requestTreeFiltersToogle } from 'actions';
import {
  allowedReadOnlyDimensions,
  metricsLinksMap,
  thWidthReviewSetMap,
} from 'constants/dashboard';
import { ARROW_CLOSE_ENVELOPE, ARROW_OPEN_ENVELOPE } from 'constants/envelopeIcons';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import moment from 'moment';
import React, { Key, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDomain } from 'selectors/auth';
import { getUser } from 'selectors/dashboard';
import { getNavParamsByResource, getNavWidgetFilters } from 'selectors/nav';
import { getPermissionsPolicy } from 'selectors/permissions';
import {
  DefaultWidgetComponentProps,
  MetricsData,
  MetricsDataV2,
  NivoBarData,
  QueryMetricObject,
} from 'types';
import { buildCollapseTableDataFromMetricsV2 } from 'utils/dashboard';
import { getValuesFromDimensions, transformToString } from 'utils/parserTree';
import { isActionAuthorized } from 'utils/permissions';
import { useTimeRange } from 'utils/timeRanges';
import { useHistory } from 'utils/urls';

type ComponentProps = {
  rows: string[];
  uncollapseData: {
    [key: string]: {
      [key: string]: number;
    };
  };
  labelsMap: {
    [key: string]: string;
  };
  rowData: NivoBarData[];
  keys: string[];
  uncollapsedItem: string | null;
  setUncollapsedItem: React.Dispatch<React.SetStateAction<string | null>>;
  queryData: QueryMetricObject;
  widgetData: MetricsData & MetricsDataV2;
  widgetId: string;
};
const TableRow: React.FC<ComponentProps> = ({
  rows,
  uncollapseData,
  labelsMap,
  rowData,
  keys,
  uncollapsedItem,
  setUncollapsedItem,
  queryData,
  widgetData,
  widgetId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const range = useTimeRange();

  const customerDomain = useSelector(getCustomerDomain);
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const filters = useSelector(getNavParamsByResource(resourceQueryParamName.metrics));
  const widgetFilters = useSelector(getNavWidgetFilters(widgetId));

  const metricLabels = keys.filter((key) => Object.values(labelsMap).includes(key));

  const handleCollapse = (id: string): void => {
    if (uncollapsedItem === id) {
      setUncollapsedItem('');
    } else {
      setUncollapsedItem(id);
    }
  };

  const hasAny = (metrics: { [key: string]: number }): boolean =>
    metricLabels.some((key) => {
      const val = metrics[key];
      if (Array.isArray(val) && (val.find((inner) => inner[0] === true) || val[0] === true)) {
        const value = Array.isArray(val[0])
          ? val.find((inner) => inner[0] === true)
          : val[0] === true
          ? val[1]
          : null;
        if (value) {
          return true;
        }
        return false;
      }
      return false;
    });

  const totalPendings = rowData.reduce((acc, data) => {
    if (rows.includes(data.user as string)) {
      const val = data['Pending'] as number;
      if (val) {
        return acc + val;
      }
      return acc + 0;
    }
    return acc + 0;
  }, 0);

  const totalReviewed = rowData.reduce((acc, data) => {
    if (rows.includes(data.user as string)) {
      const val = data['Total Reviewed'] as number;
      if (val) {
        return acc + val;
      }
      return acc + 0;
    }
    return acc + 0;
  }, 0);

  const rowForUser: (string | number)[] = [...rows];

  const pendings = Object.values(uncollapseData).filter((data) => {
    const val = data['Pending'];
    if (Array.isArray(val) && (val.find((inner) => inner[0] === true) || val[0] === true)) {
      const value = Array.isArray(val[0])
        ? val.find((inner) => inner[0] === true)
        : val[0] === true
        ? val[1]
        : null;

      if (value) {
        return true;
      }
      return false;
    }
    return false;
  });

  const starteds = Object.values(uncollapseData).filter((data) => {
    const val = data['Pending'];
    if (Array.isArray(val) && (val.find((inner) => inner[0] === true) || val[0] === true)) {
      const value = Array.isArray(val[0])
        ? val.find((inner) => inner[0] === true)
        : val[0] === true
        ? val[1]
        : null;

      if (value && value[1] > 0) {
        return true;
      }
      return false;
    }
    return false;
  });

  rowForUser.push(`${starteds.length}/${pendings.length}`);
  rowForUser.push(totalPendings);
  rowForUser.push(totalReviewed);

  const sortByPending = (
    a: {
      [key: string]: number;
    },
    b: {
      [key: string]: number;
    }
  ): number => {
    let aValue = 0;
    let bValue = 0;
    const aPendings = a['Pending'];
    const bPendings = b['Pending'];
    if (
      Array.isArray(aPendings) &&
      (aPendings.find((inner) => inner[0] === true) || aPendings[0] === true)
    ) {
      const [, value] = Array.isArray(aPendings[0])
        ? aPendings.find((inner) => inner[0] === true)
        : aPendings[0] === true
        ? aPendings[1]
        : null;
      if (value) {
        aValue = value;
      }
    }
    if (
      Array.isArray(bPendings) &&
      (bPendings.find((inner) => inner[0] === true) || bPendings[0] === true)
    ) {
      const [, value] = Array.isArray(bPendings[0])
        ? bPendings.find((inner) => inner[0] === true)
        : bPendings[0] === true
        ? bPendings[1]
        : null;
      if (value) {
        bValue = value;
      }
    }

    if (aValue > bValue) {
      return -1;
    }
    if (aValue < bValue) {
      return 1;
    }
    return 0;
  };

  const handleClick = (rowId: string, blockNavigation: boolean): void => {
    if (blockNavigation) return;

    const query = queryData.queries.find((q) => q.id === 'review-sets-total');

    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];

    if (info == null || !isActionAuthorized(info.action, user.roles, policy)) {
      return;
    }

    const series = widgetData.results.find((r) => r.id === 'review-sets-total');

    if (series == null) {
      return;
    }

    const start = range.created_after != null ? range.created_after : null;
    const end = range.created_before != null ? range.created_before : null;

    const dimensions: Record<string, string | string[]> = {};
    const infoDimension = info.dimensions;

    if (infoDimension != null) {
      Object.entries(infoDimension).forEach(([dimension, param]) => {
        const allowed = allowedReadOnlyDimensions[query.metric];

        if (allowed == null || !allowed.includes(dimension)) {
          return;
        }

        let value: string | string[] = '';

        if (query?.dimensions !== null) {
          const dm = query?.dimensions?.filter((dim) => dim.name === dimension);
          if (dm) {
            value = dm?.map((el) => el.value);
          }
        }

        if (filters[dimension] != null) {
          const topValue = filters[dimension];
          value = topValue;
        }

        if (widgetFilters[dimension] != null) {
          const widgetValue = widgetFilters[dimension];
          value = widgetValue;
        }

        if (query.group_by === dimension) {
          value = Object.entries(labelsMap).find(([, v]) => v === rowId)?.[0] ?? '';
        }

        dimensions[`${info.resource}__${param}`] = value;
      });
    }

    dimensions[`${info.resource}__review_values`] = [
      'escalated',
      'in_policy',
      'out_of_policy',
      'reviewed',
    ];

    const tree = getValuesFromDimensions(dimensions);
    dispatch(requestTreeFiltersToogle({ value: true }));
    dispatch(clearTree());

    history.pushLookup({
      customerDomain,
      routeName: info.route,
      queryParams: {
        [`${info.resource}__created_after`]: moment(start).format(FULL_DATE_FORMAT),
        [`${info.resource}__created_before`]: moment(end).format(FULL_DATE_FORMAT),
        [`${info.resource}__filters_search`]: transformToString(tree),
        ...dimensions,
      },
    });
  };

  return (
    <>
      <tr className="border border-litlingo-gray-2">
        {rowForUser.map((data, index) => (
          <td
            key={`${data}-${index}` as Key}
            className={`border border-litlingo-gray-2 px-4 ${
              index === 0
                ? `h-8 px-4 text-left text font-bold text-base w-32`
                : `text-right ${
                    rowForUser.length - 1 === index ? 'hover:underline cursor-pointer' : ''
                  }`
            } `}
            onClick={(): void => handleClick(rows[0], rowForUser.length - 1 !== index)}
            role="presentation"
          >
            {index === 0 && uncollapseData ? (
              <div className="flex flex-row gap-x-2 ">
                <button
                  type="button"
                  className="text-left text font-bold text-base focus:outline-none"
                  onClick={(): void => handleCollapse(data as string)}
                >
                  {uncollapsedItem === data ? ARROW_OPEN_ENVELOPE() : ARROW_CLOSE_ENVELOPE()}
                </button>
                <span className="truncate">{data}</span>
              </div>
            ) : (
              data?.toLocaleString()
            )}
          </td>
        ))}
      </tr>
      {uncollapsedItem === rows[0] && (
        <>
          {Object.entries(uncollapseData)
            .sort(([, a], [, b]) => sortByPending(a, b))
            .map(([search, metrics]) => (
              <>
                {hasAny(metrics) ? (
                  <tr
                    key={search}
                    className="border border-litlingo-gray-2 h-8 bg-litlingo-gray-0.5"
                  >
                    <td className="border border-litlingo-gray-2" />
                    <td className="border border-litlingo-gray-2 truncate px-2">{search}</td>
                    {metricLabels.map((key) => {
                      const val = metrics[key];
                      if (
                        Array.isArray(val) &&
                        (val.find((inner) => inner[0] === true) || val[0] === true)
                      ) {
                        const value = Array.isArray(val[0])
                          ? val.find((inner) => inner[0] === true)
                          : val[0] === true
                          ? val[1]
                          : null;
                        return (
                          <>
                            <td key={key} className="border border-litlingo-gray-2 px-4 text-right">
                              {value ?? '-'}
                            </td>
                          </>
                        );
                      }
                      return null;
                    })}
                  </tr>
                ) : null}
              </>
            ))}
        </>
      )}
    </>
  );
};

const TableCollapsableWidget: React.FC<
  DefaultWidgetComponentProps & { widgetData: MetricsDataV2 }
> = (props) => {
  const { widgetData, queryData, widgetId } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [uncollapsedItem, setUncollapsedItem] = useState<string | null>(null);

  useEffect(() => {
    const scrollHandler = (e: WheelEvent): void => {
      e.stopPropagation();
    };

    const ref = wrapperRef.current;

    ref?.addEventListener('wheel', scrollHandler, { passive: false });
    return () => ref?.removeEventListener('wheel', scrollHandler);
  }, []);

  const processedData = useMemo(
    () => widgetData && queryData && buildCollapseTableDataFromMetricsV2(widgetData, queryData),
    [widgetData, queryData]
  );

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  if (Object.keys(processedData?.data ?? {}).length === 0) {
    return <WidgetEmptyState />;
  }

  const sortByPending = (a: NivoBarData | undefined, b: NivoBarData | undefined): number => {
    const totalPendingsA: number = a ? (a['Pending'] as number) : 0;
    const totalPendingsB: number = b ? (b['Pending'] as number) : 0;

    if (totalPendingsA > totalPendingsB) {
      return -1;
    }
    if (totalPendingsA < totalPendingsB) {
      return 1;
    }
    return 0;
  };

  return (
    <div
      className="overflow-y-auto custom-scrollbar border-t border-b border-litlingo-gray-2"
      ref={wrapperRef}
    >
      <table className="table-fixed w-full h-full">
        <thead className="sticky top-0">
          <tr className="h-10">
            {processedData?.keys.map((header, idx) => (
              <th
                key={header}
                className={`border-l border-b border-litlingo-gray-2 bg-litlingo-gray-0.5 ${
                  processedData.keys.length - 1 === idx ? 'border-r' : ''
                } ${idx === 0 ? 'text-left pl-4' : ''} ${
                  Object.keys(thWidthReviewSetMap).includes(header)
                    ? thWidthReviewSetMap[header as keyof typeof thWidthReviewSetMap]
                    : ''
                }`}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <>
            {processedData &&
              Object.entries(processedData.data)
                .sort(([a], [b]) =>
                  sortByPending(
                    processedData.rowData.find((r) => r['user'] === a),
                    processedData.rowData.find((r) => r['user'] === b)
                  )
                )
                .map(([user, searches]) => (
                  <TableRow
                    rows={[user]}
                    uncollapseData={searches}
                    labelsMap={processedData.labelsMap}
                    rowData={processedData.rowData}
                    keys={processedData.keys}
                    setUncollapsedItem={setUncollapsedItem}
                    uncollapsedItem={uncollapsedItem}
                    queryData={queryData}
                    widgetData={widgetData}
                    widgetId={widgetId}
                    key={user}
                  />
                ))}
          </>
        </tbody>
      </table>
    </div>
  );
};

export default withWidget({})(
  TableCollapsableWidget as unknown as React.FC<DefaultWidgetComponentProps>
);
