import EventContent from 'components/EventContent';
import EventLabels from 'components/EventLabels';
import EventsSidebarLabelDropdown from 'components/EventsSidebarLabelDropdown';
import EventsVisualizer from 'components/EventView/EventsVisualizer';
import ThreeDotsDropdown from 'components/EventView/ThreeDotsDropdown';
import LinkLookup from 'components/LinkLookup';
import LogsList from 'components/LogsList';
import ModalAddTestSuitFromEvent from 'components/ModalAddTestSuiteFromEvent';
import Navbar from 'components/Navbar';
import Permissions from 'components/Permissions';
import PermissionsLink from 'components/PermissionsLink';
import Redacted from 'components/Redacted';
import moment from 'moment';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getEventCommUuid,
  getEventSummaryLoading,
  getSingleEvent,
  getSingleEventActions,
} from 'selectors/events';
import { useSelector } from 'store';
import { Communication, UUID } from 'types';

type RouteParams = {
  eventId: UUID;
};

const EventView: React.FC = () => {
  const { eventId } = useParams<RouteParams>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showVisualizer, setShowVisualizer] = useState(false);
  const event = useSelector((state) => getSingleEvent(state, eventId));
  const actions = useSelector((state) => getSingleEventActions(state, eventId));
  const commUuid = useSelector(getEventCommUuid(eventId));
  const loading = useSelector(getEventSummaryLoading);

  const handleVisualizerCloseClick = (): void => setShowVisualizer(false);

  const toggleModal = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  if (!event) return null;
  const createRecipients = (communication?: Communication): string => {
    let res = '';
    if (!communication || !communication.recipients) return res;
    if (communication.platform === 'slack') return res;

    communication.recipients.forEach((item, index) => {
      if (index === 0) {
        res += item.display_name;
      } else {
        res += `, ${item.display_name}`;
      }
    });
    if (res.length > 73) {
      res = `${res.substr(0, 70)}...`;
    }
    return res;
  };

  const recipients = createRecipients(event.communication);

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />
      <div className="flex flex-col min-h-wo-navbar h-wo-navbar px-8">
        <div className="pb-2">
          <div className="mt-4 flex-shrink-0 flex float-right">
            {loading && (
              <div className="mr-3 flex float-left loader ease-linear rounded-full border-2 border-gray-200 h-4 w-4 self-center" />
            )}
            <EventsSidebarLabelDropdown className="mr-3" eventId={eventId} />
            <Permissions action="test_cases.create">
              <ThreeDotsDropdown
                className="ml-5 mr-3"
                handleModalAddTestSuitFromEvent={toggleModal}
              />
            </Permissions>
          </div>
        </div>
        <div className="h-events-sidebar-content">
          <div className="ml-4 mt-4 flex flex-col text-left float-left">
            <table>
              <tbody>
                <tr>
                  <td>
                    <p className="litlingo-gray-color-2 text-sm pr-2">Triggered by:</p>
                  </td>
                  <td>
                    <div className="flex flex-row">
                      {event.campaign && (
                        <LinkLookup
                          routeName="campaign-detail"
                          routeParams={{ campaignId: event.campaign.uuid }}
                          className="group flex items-center text text-blue-800 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150"
                        >
                          <span className="truncate">{event.campaign && event.campaign.name}</span>
                        </LinkLookup>
                      )}
                      <span className="self-center text-sm px-1">/</span>
                      {event.rule && event.campaign && (
                        <PermissionsLink
                          className="text-blue-800 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150"
                          action="rules.retrieve"
                          routeName="rule-manager-campaign"
                          routeParams={{
                            ruleId: event.rule.uuid,
                            campaignId: event.campaign.uuid,
                          }}
                        >
                          <span className="truncate text-sm group flex items-center ">{`${event.rule.name}`}</span>
                        </PermissionsLink>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="litlingo-gray-color-2 text-sm">From:</p>
                  </td>
                  <td>
                    {!loading && (
                      <Redacted field="events.created_by">
                        <p className="litlingo-black-color text-sm">
                          {event.created_by && event.created_by.name}
                        </p>
                      </Redacted>
                    )}
                  </td>
                </tr>
                {recipients && recipients.length && (
                  <tr>
                    <td>
                      <p className="litlingo-gray-color-2 text-sm">To:</p>
                    </td>
                    <td>
                      {!loading && (
                        <Redacted field="communications.recipients">
                          <p className="litlingo-black-color text-sm">{recipients}</p>
                        </Redacted>
                      )}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <p className="litlingo-gray-color-2 text-sm">Date:</p>
                  </td>
                  <td className="litlingo-black-color text-sm">
                    {event.created_at != null && moment(event.created_at).format('llll')}
                    {' via '}
                    {event.platform}
                  </td>
                </tr>
                {event.communication && event.communication.subject && (
                  <tr>
                    <td>
                      <p className="litlingo-gray-color-2 text-sm">Subject:</p>
                    </td>
                    <td className="litlingo-black-color text-sm">{event.communication.subject}</td>
                  </tr>
                )}
                <tr>
                  <td>
                    <p className="litlingo-gray-color-2 text-sm">Label:</p>
                  </td>
                  <td>
                    <EventLabels eventId={eventId} />
                  </td>
                </tr>
              </tbody>
            </table>
            {event.communication && event.communication.subject && (
              <div className="">
                <p className="text-lg font-bold litlingo-black-color pt-2">
                  {event.communication.subject}
                </p>
              </div>
            )}
          </div>
          <EventContent eventId={eventId} commUuid={commUuid} />
          <EventsVisualizer
            show={showVisualizer}
            eventId={eventId}
            onCloseClick={handleVisualizerCloseClick}
          />
          <Permissions action="events.viewLogs">
            <LogsList logs={actions} />
          </Permissions>
        </div>
      </div>
      {isModalOpen && <ModalAddTestSuitFromEvent eventId={eventId} toggleModal={toggleModal} />}
    </div>
  );
};

export default EventView;
