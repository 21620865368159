/* eslint-disable max-lines */

import { selectReviewSet } from 'actions';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedReviewSet } from 'selectors/savedSearches';
import type { SavedSearch } from 'types';

type ComponentProps = {
  reviewSet: SavedSearch;
};

const SavedSearchListItem: React.FC<ComponentProps> = ({ reviewSet }) => {
  const dispatch = useDispatch();
  const selectedReviewSet = useSelector(getSelectedReviewSet);

  const handleSelectReviewSet = (): void => {
    dispatch(selectReviewSet({ reviewSet }));
  };

  return (
    <tr
      className={`table-row border-t border-gray-200  align-middle ${
        selectedReviewSet && reviewSet.uuid === selectedReviewSet.uuid
          ? 'bg-litlingo-success-light'
          : 'hover:bg-litlingo-gray-0'
      }`}
      role="button"
      aria-hidden
      data-testid="review-set"
      onClick={handleSelectReviewSet}
    >
      <td className="table-wrapper__new-td pl-4">
        <div className="table-wrapper__new-cell-content h-9 m-0 pl-0 text-body truncate">
          {reviewSet.name}
        </div>
      </td>
      {/* FRO-1090 <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-auto flex text-body">
          {reviewSet.priority
            ? `${reviewSet.priority?.at(0)?.toUpperCase()}${reviewSet.priority?.slice(1)}`
            : ''}
        </div>
      </td> */}

      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-auto flex text-body">
          {reviewSet.record_count}
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-auto flex text-body">
          {reviewSet.created_by?.name}
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-auto flex text-body">
          {moment(reviewSet.created_at).format('MM/DD/YYYY')}
        </div>
      </td>
    </tr>
  );
};

export default SavedSearchListItem;
