import type { AnnotatedGraph } from '@litlingo/react';
import { fetchCommThreadContext } from 'actions';
import moment from 'moment';
import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { getCommunicationContext, getSingleCommunication } from 'selectors/communications';
import { getRawThreadContext } from 'selectors/events';
import { useSelector } from 'store';
import { ThreadContext, UUID } from 'types';
import parseBody from 'utils/strings';
import HighlightedSentenceComponent from './HighlightedSentence';

const isInHighlighted: (
  items: ThreadContext[],
  index: number,
  commPlatformUuid: string,
  direction?: 1 | -1
) => boolean = (items, index, commPlatformUuid, direction = 1) => {
  const isHighlighted = `${commPlatformUuid}`.includes(items[index].id);

  if (isHighlighted) {
    return true;
  }

  if (
    items[index + direction] &&
    items[index].from?.user?.displayName === items[index + direction]?.from?.user?.displayName &&
    Math.abs(
      moment(items[index]?.createdDateTime).diff(
        moment(items[index + direction]?.createdDateTime),
        'minutes'
      )
    ) <= 30
  ) {
    return isInHighlighted(items, index + direction, commPlatformUuid, direction);
  }

  return false;
};

type ComponentProps = {
  eventId: UUID;
  commUuid: UUID;
  commPlatformUuid: string;
  contextPadding?: number;
  authorOnly?: boolean;
  highRiskOnly?: boolean;
  hideHighlight?: boolean;
  handleSentenceClick?: (line: AnnotatedGraph) => void;
};

const RenderThread: React.FC<ComponentProps> = ({
  eventId,
  commUuid,
  commPlatformUuid,
  contextPadding = 5,
  authorOnly = true,
  highRiskOnly = false,
  hideHighlight = false,
  handleSentenceClick,
}) => {
  const dispatch = useDispatch();

  const communicationSummary = useSelector((state) => getSingleCommunication(state, commUuid));
  const commThreadContext = useSelector((state) => getCommunicationContext(state, commUuid));
  const eventThreadContext = useSelector(getRawThreadContext(eventId));

  const rawCorrespondence = eventThreadContext || commThreadContext;

  useEffect(() => {
    if (eventId !== null && commUuid && !rawCorrespondence) {
      dispatch(fetchCommThreadContext({ eventId, commUuid }));
    }
  }, [commUuid, dispatch, eventId, rawCorrespondence]);

  const foundIndex =
    rawCorrespondence?.findIndex((item) => `${commPlatformUuid}`.includes(item.id)) || 0;

  const _rawCorrespondence =
    rawCorrespondence?.slice(
      Math.max(0, foundIndex - contextPadding),
      foundIndex + contextPadding + 1
    ) || [];

  const filteredCorrespondence = authorOnly
    ? _rawCorrespondence
        .filter(
          (_item, index, array) =>
            isInHighlighted(array, index, commPlatformUuid, -1) ||
            isInHighlighted(array, index, commPlatformUuid, 1)
        )
        .reverse()
    : [..._rawCorrespondence].reverse();

  const renderContent = (): JSX.Element[] | JSX.Element => {
    if (filteredCorrespondence.length === 0 && (eventId || commUuid)) {
      return (
        <HighlightedSentenceComponent
          eventId={eventId}
          commUuid={commUuid}
          fullMessageOptions="HIGHLIGHTED"
          highRiskOnly={highRiskOnly}
          hideHighlight={hideHighlight}
          handleSentenceClick={handleSentenceClick}
        />
      );
    }

    return filteredCorrespondence.map((item, index) => (
      <div
        key={`${item.from?.user?.displayName}-${item.createdDateTime}`}
        className={`flex flex-col h-full w-4/5 text-body focus:outline-none${
          item.from?.user?.displayName !==
          filteredCorrespondence[index + 1]?.from?.user?.displayName
            ? ' pb-1'
            : ''
        }`}
      >
        <div
          className={`${
            `${commPlatformUuid}`.includes(item.id) ? 'text-black' : ' text-litlingo-gray-5'
          }`}
        >
          {((item.from?.user?.displayName &&
            item.from?.user?.displayName !==
              filteredCorrespondence[index - 1]?.from?.user?.displayName) ||
            moment(item?.createdDateTime).diff(
              moment(filteredCorrespondence[index - 1]?.createdDateTime),
              'minutes'
            ) >= 30) && (
            <div
              className={`flex flex-row gap-1 pb-1${
                isInHighlighted(filteredCorrespondence, index, commPlatformUuid, 1)
                  ? ' text-black'
                  : ''
              }`}
            >
              <span className="font-bold">{item.from?.user?.displayName}</span>
              <span>•</span>
              <span>{moment(item.createdDateTime).format('MMM DD hh:mm A')}</span>
            </div>
          )}
          {!item.deletedDateTime && item.messageType === 'message' && (
            <div className="pl-4">
              <div className="py-1 text-left pl-6 border-l border-litlingo-gray-2">
                {commPlatformUuid.includes(item.id) ? (
                  <>
                    {eventId ? (
                      <HighlightedSentenceComponent
                        eventId={eventId}
                        commUuid={commUuid}
                        fullMessageOptions="HIGHLIGHTED"
                        highRiskOnly={highRiskOnly}
                        hideHighlight={hideHighlight}
                        handleSentenceClick={handleSentenceClick}
                      />
                    ) : (
                      <ReactMarkdown renderers={{ link: () => <></> }}>
                        {communicationSummary?.body
                          ? parseBody(
                              communicationSummary.body.substring(
                                0,
                                communicationSummary.body.length
                              )
                            )
                          : ''}
                      </ReactMarkdown>
                    )}
                  </>
                ) : (
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: item.body.content || '<span>BLOCKED</span>',
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    ));
  };

  return <div className="flex flex-col gap-x-4 pb-6">{renderContent()}</div>;
};

export default RenderThread;
