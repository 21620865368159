import { upsertAnnotator } from 'actions';
import AnnotatorTypeListTable from 'components/AnnotatorType/AnnotatorTypeListTable';
import Navbar from 'components/Navbar';
import PageHeader from 'components/PageHeader';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

type RouterParams = { ruleId?: string; campaignId?: string };

const AnnotatorType: React.FC = () => {
  const dispatch = useDispatch();
  const { campaignId, ruleId } = useParams<RouterParams>();

  const handleCreateNew = async (): Promise<void> => {
    dispatch(
      upsertAnnotator({
        name: 'New identifier',
        description: 'New description',
        type: 'custom',
        options_config: {},
        redirect: true,
        always_save: false,
        campaignId,
        ruleId,
      })
    );
  };

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />
      <div className="py-10">
        <header>
          <div className="max-w-7xl mx-auto sm:px-10 lg:px-14">
            <div>
              <PageHeader />
              <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                  <h2 className="title">Select matcher template</h2>
                  <span className="text">
                    Descriptive sentence about what a matcher (identifier) actually is
                  </span>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main className="mt-8 px-10 flex flex-col">
          <button
            type="button"
            onClick={handleCreateNew}
            className="flex flex-col litlingo-annotator-customer-matcher-button focus:outline-none"
            data-testid="custom-matcher-button"
          >
            <div className="my-4 mx-5 text-left">
              <h4 className="text text--large text--semibold">Custom matcher</h4>
              <span className="text text--lighter-3">
                Build a new language matcher from scratch
              </span>
            </div>
          </button>
          <div className="pt-8">
            <AnnotatorTypeListTable />
          </div>
        </main>
      </div>
    </div>
  );
};

export default AnnotatorType;
