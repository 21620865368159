/* eslint-disable max-lines */
import { platformIcon } from 'constants/platformIcons';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  getContextModels,
  getCustodians,
  getEnvelope,
  getEnvelopeMetadata,
} from 'selectors/envelopes';
import { useSelector } from 'store';

const EnvelopeContextInfo: React.FC = () => {
  const { envelopeId } = useParams<{ envelopeId: string }>();
  const envelope = useSelector((state) => getEnvelope(state, envelopeId));

  const custodians = useSelector(getCustodians);
  const metadata: string[] = useSelector((state) => getEnvelopeMetadata(state, envelopeId));
  const contextModels: string[] = useSelector(getContextModels);

  return (
    <div className="envelope-info-container text-small gap-4">
      {envelope && (
        <>
          {envelope.platform !== 'o365_teams' && (
            <div className="flex flex-col gap-2">
              <div className="flex flex-row justify-between">
                <span className="font-bold">Message Metadata</span>
                {platformIcon(envelope.platform, envelope.integration_type, true)}
              </div>
              <div>
                <ul className="list-disc list-outside pl-6">
                  {custodians.length > 0 &&
                    custodians.map((custodian) => (
                      <>
                        <li key={custodian.name}>{custodian.name}</li>
                        <ul className="list-disc list-outside pl-6">
                          {custodian.folders.map((folder) => (
                            <li key={`${custodian.name}-${folder}`}>{folder}</li>
                          ))}
                        </ul>
                      </>
                    ))}
                  {metadata.map((c) => (
                    <li key={c}>{c}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {contextModels.length > 0 && (
            <div className="flex flex-col gap-2">
              <div className="flex flex-row justify-between">
                <span className="font-bold">Context Models</span>
                {envelope.platform === 'o365_teams' &&
                  platformIcon(envelope.platform, envelope.integration_type, true)}
              </div>
              <div>
                <ul className="list-disc list-outside pl-6">
                  {contextModels.map((c) => (
                    <li key={c}>{c}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EnvelopeContextInfo;
