/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { fetchIdentities, upsertInvitation } from 'actions';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import type { Invitation } from 'types';

function removeEmptyFields(data: Invitation): void {
  Object.keys(data).forEach((key) => {
    if (data[key as keyof Invitation] === '' || data[key as keyof Invitation] == null) {
      delete data[key as keyof Invitation];
    }
  });
}

type ComponentProps = {
  editInvitation: Invitation;
  setIsCreateNewInvitationOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateInvitation: React.FC<ComponentProps> = ({
  editInvitation,
  setIsCreateNewInvitationOpen,
}) => {
  const dispatch = useDispatch();
  const { register, getValues, setValue } = useForm<Invitation>({
    defaultValues: editInvitation || {
      expires_in: 168,
      next_page: null,
      oauth2_client_uuid: null,
    },
  });

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const values = getValues() as unknown as Invitation;
    removeEmptyFields(values);

    const newInvitationData = {
      ...values,
    };
    const dataToSave = editInvitation
      ? { ...editInvitation, ...newInvitationData }
      : { ...newInvitationData, used_on: null };

    dispatch(upsertInvitation(dataToSave));
    setIsCreateNewInvitationOpen(false);
  };

  useEffect(() => {
    dispatch(fetchIdentities({ limit: -1 }));
  }, [dispatch]);

  useEffect(() => {
    if (editInvitation) {
      setValue('oauth2_identity_uuid', editInvitation.oauth2_identity_uuid);
    }
  }, [editInvitation, setValue]);

  return (
    <div className="flex mt-10 text-left">
      <form className="w-7/12 mr-20" onSubmit={onSubmit}>
        <div>
          <div className="col-span-6 sm:col-span-4 mt-6">
            <label
              htmlFor="oauth2_identity_uuid"
              className="block mb-1 text-sm font-medium leading-5 text-gray-700"
            >
              Identity
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="oauth2_identity_uuid"
                name="oauth2_identity_uuid"
                ref={register({ required: true })}
                className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
          <div className="sm:col-span-6 mt-6">
            <label
              htmlFor="expires_in"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Expires in
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="expires_in"
                  name="expires_in"
                  ref={register({
                    required: true,
                  })}
                  className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </label>
          </div>

          <div className="sm:col-span-6 mt-6">
            <label
              htmlFor="provider_hints"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Provider hints
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="provider_hints"
                  name="provider_hints"
                  ref={register({ required: true })}
                  className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </label>
          </div>

          <div className="sm:col-span-6 mt-6">
            <label
              htmlFor="oauth2_client_uuid"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Client
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="oauth2_client_uuid"
                  name="oauth2_client_uuid"
                  ref={register()}
                  className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </label>
          </div>

          <div className="sm:col-span-6 mt-6">
            <label
              htmlFor="next_page"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Next page
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="next_page"
                  name="next_page"
                  ref={register()}
                  className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </label>
          </div>
        </div>
        <div className="flex justify-start items-center">
          <button
            data-testid="create-user-button"
            type="submit"
            className="mt-5 -ml-px button button--primary"
          >
            <svg
              className="h-5 w-5 text-white"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M12 4v16m8-8H4" />
            </svg>
            <span className="ml-2">Save</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateInvitation;
