import { addTagsToTagGroup, deleteTagGroup, removeTagsFromTagGroup } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import TagGroupsForm from 'components/TagGroups/TagGroupsForm';
import { NEW_EDIT_ICON } from 'constants/commonIcons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAddTagsToTagGroupLoading,
  getDeleteTagGroupLoading,
  getFetchSingleTagGroupLoading,
  getRemoveTagsFromTagGroupLoading,
  getSelectedTagGroup,
  getUpsertTagGroupLoading,
} from 'selectors/tagGroup';
import { Tag } from 'types';
import logEvent from 'utils/analytics';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import TagsLabels from './TagsLabels';
import TagsSelect from './TagsSelect';

const TagGroup: React.VFC = () => {
  const dispatch = useDispatch();
  const tagGroup = useSelector(getSelectedTagGroup);

  const [tags, setTags] = useState(tagGroup?.tag_values ?? ([] as Tag[]));
  const [toggleForm, setToggleForm] = useState(false);
  const [isDeletingTagGroup, setIsDeletingTagGroup] = useState(false);
  const loading = useSelector(getFetchSingleTagGroupLoading);
  const upsertLoading = useSelector(getUpsertTagGroupLoading);
  const deleteLoading = useSelector(getDeleteTagGroupLoading);
  const addTagsLoading = useSelector(getAddTagsToTagGroupLoading);
  const removeTagsLoading = useSelector(getRemoveTagsFromTagGroupLoading);
  const isTagValueLoading = addTagsLoading || removeTagsLoading;

  useEffect(() => {
    if (tagGroup) {
      setTags(tagGroup.tag_values);
    }
  }, [tagGroup]);

  const handleDeleteTag = (tag: Tag): void => {
    setTags(tags.filter((g) => g.uuid !== tag.uuid));
    logEvent('tag-group-view-remove-tag');
  };

  const handleSelectTag = (tag: Tag, checked: boolean): void => {
    if (checked) {
      setTags([...tags, tag]);
      logEvent('tag-group-view-add-tag');
    } else {
      handleDeleteTag(tag);
    }
  };

  const toggleConfirmModal = (): void => setIsDeletingTagGroup(false);

  const handleDeleteClick = (): void => {
    if (tagGroup) {
      dispatch(deleteTagGroup({ id: tagGroup.uuid }));
      toggleConfirmModal();
      setToggleForm((prevState) => !prevState);
    }
  };

  const tagUuids = tagGroup?.tag_values.map(({ uuid }) => uuid) || [];
  const selectedTagsUuids = tags.map(({ uuid }) => uuid);

  const updateSelectedTags = (): void => {
    const removedTagsUuids = tagUuids.filter((uuid) => !selectedTagsUuids.includes(uuid));
    const addedTagsUuids = selectedTagsUuids.filter((uuid) => !tagUuids.includes(uuid));

    if (addedTagsUuids.length) {
      dispatch(
        addTagsToTagGroup({
          uuid: tagGroup?.uuid || '',
          tags: addedTagsUuids,
        })
      );
    }
    if (removedTagsUuids.length) {
      dispatch(
        removeTagsFromTagGroup({
          uuid: tagGroup?.uuid || '',
          tags: removedTagsUuids,
        })
      );
    }
  };

  const saveIsEnabled =
    tagGroup?.tag_values.length !== tags.length ||
    (tags.some(({ uuid }) => !tagUuids.includes(uuid)) && !isTagValueLoading);

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <div
      className="min-w-screen bg-white"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />
      <div
        className="flex flex-row w-full"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <SettingSidebar />

        <div className="w-full overflow-auto">
          <div className="pl-6 py-4 w-158">
            <div>
              <header>
                <div className="max-w-7xl">
                  <div className="flex items-center gap-2">
                    <LinkLookup
                      routeName="tags"
                      className="border-b-4 border-transparent text-menu-item cursor-pointer pb-1 px-2"
                    >
                      All Tags
                    </LinkLookup>
                    <div
                      role="button"
                      tabIndex={0}
                      className="border-b-4 border-litlingo-orange text-menu-item cursor-pointer pb-1 px-2"
                    >
                      Tag Groups
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-4">
                    <LinkLookup routeName="tag-groups">
                      <div className="text-body">
                        <p className="text-litlingo-info underline">{'<'} Back to All Tag Groups</p>
                      </div>
                    </LinkLookup>
                    {toggleForm && (
                      <span className="flex rounded">
                        <button
                          type="button"
                          className="button button--error flex justify-center text-base leading-5 font-bold px-1 bg-white w-40 h-8 text-center"
                          onClick={(): void => setIsDeletingTagGroup(true)}
                        >
                          Delete Tag Group
                        </button>
                      </span>
                    )}
                  </div>
                </div>
              </header>
            </div>
            {loading ? (
              <LoadingIndicator className="m-auto mt-20" size="20" />
            ) : (
              <main>
                <div className="mt-6">
                  {!toggleForm && (
                    <>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <h2 className="text-title">{tagGroup?.name}</h2>
                          {(isTagValueLoading || upsertLoading || deleteLoading) && (
                            <LoadingIndicator className="ml-2" size="5" />
                          )}
                        </div>

                        <button
                          type="button"
                          tabIndex={0}
                          className="flex items-center text-left text-litlingo-primary underline focus:outline-none"
                          onClick={(): void => {
                            setToggleForm((prevState) => !prevState);
                          }}
                        >
                          <div className="w-5 mr-1">{NEW_EDIT_ICON('text-litlingo-primary')}</div>
                          <span>Edit Tag Group</span>
                        </button>
                      </div>
                      <p className="text-body mt-1">{tagGroup?.description}</p>
                    </>
                  )}
                  <TagGroupsForm
                    toggleForm={toggleForm}
                    setToggleForm={setToggleForm}
                    initialValues={{
                      uuid: tagGroup?.uuid,
                      name: tagGroup?.name || '',
                      description: tagGroup?.description || '',
                    }}
                  />

                  <div>
                    <div className="mt-4 flex-shrink-0 flex items-center justify-between">
                      <div className="flex">
                        <TagsSelect
                          selectTag={handleSelectTag}
                          selectedTags={tags}
                          className="min-w-58"
                        />
                        <div className="ml-2">
                          <p className="text-litlingo-gray-6">Tags in this group: {tags.length}</p>
                          <TagsLabels
                            tags={tags}
                            handleDelete={handleDeleteTag}
                            className="flex-wrap"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-end gap-3 mt-6">
                    <span className="flex rounded">
                      <button
                        type="button"
                        disabled={!saveIsEnabled}
                        className={`button flex justify-center text-base leading-5 font-bold text-litlingo-gray-5 bg-white border-litlingo-gray-3 w-24 h-8 text-center ${
                          !saveIsEnabled ? 'cursor-default' : ''
                        }`}
                        onClick={(): void => {
                          setTags(tagGroup?.tag_values || []);
                        }}
                      >
                        Cancel
                      </button>
                    </span>
                    <span className="flex rounded">
                      <button
                        type="button"
                        disabled={!saveIsEnabled}
                        className={`button button--primary flex justify-center text-base leading-5 font-bold text-white w-44 h-8 ${
                          saveIsEnabled ? 'cursor-pointer' : 'opacity-75 cursor-default'
                        }`}
                        onClick={(): void => updateSelectedTags()}
                      >
                        Save Selected Tags
                      </button>
                    </span>
                  </div>
                </div>
                {isDeletingTagGroup && (
                  <ConfirmModal
                    data-testid="delete-modal"
                    text="Are you sure you want to delete this tag group?"
                    okButtonText="Yes"
                    cancelButtonText="No"
                    okButtonOnClick={handleDeleteClick}
                    toggleShowModal={toggleConfirmModal}
                  />
                )}
              </main>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagGroup;
