/* eslint-disable max-lines */

import LoadingIndicator from 'components/LoadingIndicator';
import LoadingOverlay from 'react-loading-overlay-ts';

import EmptyTableState from 'components/EmptyTableState';
import React from 'react';
import { useSelector } from 'react-redux';
import { getActiveTasks, getActiveTasksLoading } from 'selectors/tasks';
import TaskListItem from './TaskListItem';

const TasksList: React.FC = () => {
  const tasks = useSelector(getActiveTasks);
  const loading = useSelector(getActiveTasksLoading);

  const isEmpty = tasks.length === 0;

  return (
    <div className="flex overflow-hidden px-8 pb-8">
      <div className="table-wrapper__new no-scrollbar overflow-auto">
        <LoadingOverlay
          active={loading}
          spinner={<LoadingIndicator size="10" />}
          className="relative w-full h-full"
          fadeSpeed={0}
          styles={{
            content: (base) => ({
              ...base,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }),
            wrapper: () => ({}),
            spinner: () => ({}),
            overlay: (base) => ({
              ...base,
              zIndex: 10,
            }),
          }}
        >
          <table className="table-wrapper__table table-wrapper_table--fixed">
            <thead className="sticky top-0 z-10">
              <tr className="table-wrapper__header-tr h-10">
                <th className="table-wrapper__new-th pl-4">Job ID</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-6 p-0">Count</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-8 p-0">Status</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-6 p-0">Blocks</th>
                <th className="table-wrapper__new-th p-0">Customer</th>
                <th className="table-wrapper__new-th p-0">Created By</th>
              </tr>
            </thead>
            <tbody className="table-wrapper__tbody">
              {!loading && isEmpty ? (
                <EmptyTableState message="There are no active tasks to display" colCount="6" />
              ) : (
                tasks.map((task) => <TaskListItem key={task.job_uuid} task={task} />)
              )}
            </tbody>
          </table>
          {/* <ListFooter
                        resourceStateName={resourceQueryParamName.campaign}
                        resourceName="scope"
                        getResourceList={getCampaignsList}
                        getResourceTotalCount={getCampaignsTotalCount}
                        getResourceLoading={getFetchAllCampaignsLoading}
                      /> */}
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default TasksList;
