import EventsFiltersSidebar from 'components/Filters/EventsFiltersSidebar';
import GeneralSidebar from 'components/Sidebar/GeneralSidebar';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';

const EnvelopeListSidebar: React.FC = () => (
  <GeneralSidebar
    baseSidebar={<EventsFiltersSidebar resource={resourceQueryParamName.envelopes} />}
  />
);

export default EnvelopeListSidebar;
