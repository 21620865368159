/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { CampaignRuleOutcome } from '@litlingo/client';
import { cloneAnnotator } from 'actions';
import CustomerSimpleList from 'components/CustomerSimpleList';
import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import PageHeader from 'components/PageHeader';
import Permissions from 'components/Permissions';
import SummarySection from 'components/SummarySection';
import { INDENTATION_ICON, NEW_EDIT_ICON } from 'constants/commonIcons';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getAnnotator,
  getLanguageMatcherTermsSearchQuery,
  saveAnnotatorRequestLoading,
} from 'selectors/annotator';
import { getCampaignRuleOutcomesList } from 'selectors/campaignRuleOutcomes';

import { formatDistanceToNow } from 'date-fns';
import { getTestCaseSummary } from 'selectors/testCases';
import { useSelector } from 'store';
import EditAnnotatorModal from './EditAnnotatorModal';

type RouterParams = { annotatorId: string };

const AnnotatorHeader: React.FC = () => {
  const { annotatorId } = useParams<RouterParams>();

  const annotator = useSelector((state) => getAnnotator(state, annotatorId)) || {};
  const loading = useSelector(saveAnnotatorRequestLoading);
  const summary = useSelector(getTestCaseSummary);
  const campaignRuleOutcomes: CampaignRuleOutcome[] = useSelector(getCampaignRuleOutcomesList);
  const query = useSelector((state) => getLanguageMatcherTermsSearchQuery(state, annotatorId));

  const [isFullDescription, setIsFullDescription] = useState(false);
  const [clampedDescription, setClampedDescription] = useState(false);

  const [isEditingAnnotator, setIsEditingAnnotator] = useState(false);
  const [isCloneAnnotatorOpen, setIsCloneAnnotatorOpen] = useState(false);

  const descriptionRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        const newC = node.scrollHeight > node.clientHeight + 2;
        setClampedDescription(newC);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [annotator, isFullDescription]
  );

  const toggleIsCloneAnnotatorOpen = (): void => {
    setIsCloneAnnotatorOpen(!isCloneAnnotatorOpen);
  };

  const handleClinkOnEdit = (): void => {
    setIsEditingAnnotator(true);
  };

  const toggleEditModal = (): void => setIsEditingAnnotator(false);

  const renderSummary = (): JSX.Element => <SummarySection summary={summary} renderTotalTests />;

  const renderAnnotatorScreen = (
    <CustomerSimpleList
      toggleShowCloneResourceModalOpen={toggleIsCloneAnnotatorOpen}
      resourceId={annotatorId}
      cloneResource={cloneAnnotator}
    />
  );

  if (!annotator) return null;

  return (
    <>
      <header>
        <div className="px-6">
          <div className="flex flex-col gap-1">
            <div className="flex flex-row justify-between items-baseline">
              <div className="flex w-full">
                <PageHeader />
              </div>
              <div className="flex justify-end gap-2 w-full h-8">
                <Permissions action="annotators.clone">
                  <button
                    type="button"
                    onClick={toggleIsCloneAnnotatorOpen}
                    className="button button--secondary h-8"
                    data-testid="clone-annotator-button"
                  >
                    <span className="px-2 font-bold">Duplicate</span>
                  </button>
                </Permissions>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex items-center w-3/5">
                <div className="flex flex-row items-baseline gap-2">
                  <span className="break-words text-heading-2">{annotator.name}</span>
                  <button
                    type="button"
                    className="flex-none w-5 focus:outline-none"
                    onClick={handleClinkOnEdit}
                  >
                    {NEW_EDIT_ICON('text-litlingo-primary-120')}
                  </button>
                  <span className="flex-none text-small">
                    {`Identifier, instances are ${
                      annotator.always_save ? 'always' : 'never'
                    } saved`}
                  </span>
                  {loading && <LoadingIndicator data-testid="loading-indicator" />}
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between items-stretch mt-1">
              <div className="flex flex-col h-full justify-start gap-2 w-3/5">
                <div className="flex flex-row items-center ">
                  <div>{`${annotator.type}`}</div>
                  {annotator.description && (
                    <div className="flex-none border-b w-2 mx-1 border-litlingo-gray-6" />
                  )}
                  {annotator.description && (
                    <div
                      className={`${
                        isFullDescription ? 'break-words' : 'flex flex-row break-all'
                      } text-body w-80ch`}
                    >
                      <span
                        className={`description ${isFullDescription ? '' : 'clamped-description'}`}
                        ref={descriptionRef}
                      >
                        {`${annotator.description}`}
                      </span>
                      {(clampedDescription || isFullDescription) && (
                        <button
                          className={`text-body focus:outline-none ${
                            isFullDescription ? 'ml-2' : ''
                          }`}
                          type="button"
                          onClick={(): void => setIsFullDescription((v) => !v)}
                        >
                          <span className="underline text-litlingo-primary whitespace-no-wrap">
                            {isFullDescription ? 'Less' : 'More'}
                          </span>
                        </button>
                      )}
                    </div>
                  )}
                </div>

                {campaignRuleOutcomes && (
                  <div className="flex flex-col gap-2">
                    {campaignRuleOutcomes.map((outcome) => (
                      <div key={outcome.uuid} className="flex flex-row gap-2 pl-6 text-small">
                        {INDENTATION_ICON}
                        <div className="flex flex-row gap-1 items-center">
                          <LinkLookup
                            routeName={outcome.campaign?.uuid ? 'campaign-detail' : 'campaign-list'}
                            type="button"
                            className="self-end underline text-litlingo-primary-120 focus:outline-none"
                            routeParams={{ campaignId: outcome.campaign?.uuid || '' }}
                          >
                            <span>{outcome.campaign?.name}</span>
                          </LinkLookup>
                          <span className="text-litlingo-primary-120">{' → '}</span>
                          <LinkLookup
                            routeName={
                              outcome.rule?.uuid && outcome.campaign?.uuid
                                ? 'rule-manager-campaign'
                                : 'rule-list'
                            }
                            type="button"
                            className={`self-end underline ${
                              outcome.campaign?.active
                                ? 'text-litlingo-primary-120'
                                : 'text-litlingo-gray-4'
                            } focus:outline-none`}
                            routeParams={{
                              ruleId: outcome.rule?.uuid || '',
                              campaignId: outcome.campaign?.uuid || '',
                            }}
                          >
                            <span>{outcome.rule?.name}</span>
                          </LinkLookup>
                          {outcome.updated_at && (
                            <span className="text-litlingo-gray-4">
                              (
                              {`${formatDistanceToNow(new Date(outcome.updated_at), {
                                addSuffix: true,
                              })}`}
                              )
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <LinkLookup
                  className="text-small"
                  routeName="envelope-list"
                  queryParams={{
                    envelopes__broad_search: query,
                  }}
                >
                  <span className="text-litlingo-primary-120 underline">Search for Matchers</span>
                </LinkLookup>
              </div>
              <div className="self-end">{renderSummary()}</div>
            </div>
          </div>
        </div>
      </header>

      {isCloneAnnotatorOpen && (
        <Modal
          body={renderAnnotatorScreen}
          title="Choose a customer"
          okButton
          okButtonText="Done"
          okButtonOnClick={toggleIsCloneAnnotatorOpen}
          toggleShowModal={toggleIsCloneAnnotatorOpen}
        />
      )}
      {isEditingAnnotator && (
        <EditAnnotatorModal
          isModalOpen={isEditingAnnotator}
          toggleModalOpen={toggleEditModal}
          annotator={annotator}
        />
      )}
    </>
  );
};

export default AnnotatorHeader;
