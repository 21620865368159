/* eslint-disable camelcase */
/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { fetchSingleEnvelope } from 'actions';
import { setEnvelopePreview } from 'actions/envelopeListView';
import Tooltip from 'components/Tooltip';
import { platformTooltipParser } from 'constants/platform';
import { platformIcon } from 'constants/platformIcons';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getEnvelopeToPreview, getOrderByNewParam } from 'selectors/envelopeListView';
import { getNavParamsByResourceMemo } from 'selectors/nav';
import { useSelector } from 'store';
import type { CommunicationEnvelopeSummary, User } from 'types';
import logEvent from 'utils/analytics';
import EnvelopesListStatusDropdown from './EnvelopeListStatusDropdown';

import EnvelopeListCondensedCampaignsRules from './EnvelopeListCondensedCampaignsRules';
import RenderTags from './RenderTags';

type GetDate = (date: string, isRead: boolean) => JSX.Element;

const getDate: GetDate = (date) => {
  const fixedDate = date.charAt(date.length - 1) !== 'Z' ? date.concat('Z') : date;
  const eventDate = moment(fixedDate).utc().local();
  const dateToShow = eventDate.format('MMMM DD, YYYY');

  return (
    <div className="flex flex-row items-center text-body overflow-hidden">
      <span className="truncate">{dateToShow}</span>
    </div>
  );
};

type GetTeams = (user: User) => JSX.Element | null;

const getTeams: GetTeams = (user) => {
  const { teams } = user;

  if (!teams || teams.length === 0) return null;

  return (
    <div className="flex flex-row items-center text-body overflow-hidden">
      <div className="truncate">
        {teams.reduce(
          (acc, val, idx, arr) => `${acc} ${val.name}${idx === arr.length - 1 ? '' : ','}`,
          ''
        )}
      </div>
    </div>
  );
};

type ComponentProps = {
  data: CommunicationEnvelopeSummary;
  envelopeSelected: boolean;
  handleCheck: (e: React.ChangeEvent<HTMLInputElement>, item: CommunicationEnvelopeSummary) => void;
  envelopeIdx: number;
};

const EnvelopeListCondensedRow: React.FC<ComponentProps> = ({
  data,
  handleCheck,
  envelopeSelected,
  envelopeIdx,
}) => {
  const { envelope, events } = data;

  const dispatch = useDispatch();

  const navParams = useSelector((state) =>
    getNavParamsByResourceMemo(state, resourceQueryParamName.envelopes)
  );
  const envelopeToPreview = useSelector(getEnvelopeToPreview);
  const isOrderingByNewParam = useSelector(getOrderByNewParam);

  const isRead = envelope.is_read;
  const envelopeTags = envelope.tags?.filter((t) => t.tag_value !== null);

  const handleEnvelopePreview = (e: React.MouseEvent<HTMLButtonElement>): void => {
    dispatch(setEnvelopePreview({ envelopeId: envelope.uuid, envelopeIdx }));
    dispatch(fetchSingleEnvelope({ envelopeId: envelope.uuid, preview: true }));
    e.stopPropagation();
  };

  const getColValue = (): string | null | undefined => {
    if (navParams.order_by === 'created_at') {
      return moment(data.envelope.created_at).format('MMMM DD, YYYY');
    }
    if (navParams.order_by === 'reviewed_at') {
      return moment(data.envelope.reviewed_at).format('MMMM DD, YYYY');
    }
    if (navParams.order_by === 'review_value') {
      return data.envelope.review_value;
    }
    if (navParams.order_by === 'user_name') {
      return data.envelope.created_by?.name;
    }
    if (navParams.order_by === 'subject') {
      return events && events[events.length - 1]?.subject;
    }
    return '';
  };

  if (!data.envelope.uuid) return null;

  return (
    <>
      <td className="table-wrapper__new-td">
        <div
          className={`table-wrapper__new-cell-content-condensed flex flex-row justify-end pr-2 ${
            envelopeToPreview?.envelopeId === envelope.uuid
              ? 'border-l-5 border-litlingo-primary-120'
              : ''
          }  `}
          style={{
            paddingLeft: envelopeToPreview?.envelopeId === envelope.uuid ? '3px' : '',
          }}
        >
          <input
            id={`checkbox-envelope-${envelope.uuid}`}
            data-testid="select-button"
            type="checkbox"
            className="cursor-pointer form-checkbox litlingo-checkbox w-5 h-5"
            onClick={(e): void => e.stopPropagation()}
            onChange={(e): void => {
              handleCheck(e, data);
              logEvent('envelopes-list-select-one');
            }}
            checked={envelopeSelected}
          />
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="pt-2  text-base text-litlingo-gray-4">{envelopeIdx + 1}</div>
      </td>

      {isOrderingByNewParam && navParams.order_by !== 'random' && (
        <td className="table-wrapper__new-td bg-litlingo-focus-area-background px-1 py-2 text-base">
          {getColValue()}
        </td>
      )}

      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content-condensed flex flex-row items-center p-0 pr-2">
          <Tooltip
            tooltip={`platforms.${platformTooltipParser(
              envelope.platform,
              envelope.integration_type
            )}`}
            redesign
          >
            <div className="w-4">{platformIcon(envelope.platform, envelope.integration_type)}</div>
          </Tooltip>
        </div>
      </td>

      <td
        className={`table-wrapper__new-td ${
          isOrderingByNewParam && isOrderingByNewParam ? 'pl-1' : ''
        }`}
      >
        <div className="table-wrapper__new-cell-content-condensed">
          <div className="h-4">{data.sent_at != null && getDate(data.sent_at, isRead)}</div>
        </div>
      </td>

      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content-condensed">
          {data.envelope.created_by && getTeams(data.envelope.created_by)}
        </div>
      </td>

      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content-condensed flex flex-row gap-1">
          <EnvelopeListCondensedCampaignsRules events={data.events} />
        </div>
      </td>

      <td className="table-wrapper__new-td">
        <div
          className="table-wrapper__new-cell-content-condensed h-full flex flex-row gap-1 justify-between overflow-visible py-1.5"
          style={{ minHeight: '36px' }}
        >
          <div className="flex flex-row gap-2 justify-between">
            <div>{data.events && <EnvelopesListStatusDropdown data={data} />}</div>

            {envelopeTags && (
              <RenderTags
                tags={envelopeTags}
                expanded={false}
                envelope={envelope}
                dropdown={false}
              />
            )}
          </div>

          <div className="self-end table-wrapper__new-td--view-section">
            <button type="button" className="focus:outline-none" onClick={handleEnvelopePreview}>
              <span
                data-testid="show-all-button"
                className="self-end ml-2 whitespace-no-wrap underline text-litlingo-primary-120 text-base "
              >
                View
              </span>
            </button>
          </div>
        </div>
      </td>
    </>
  );
};

export const MemoizedEnvelopeListCondensedRow = React.memo(EnvelopeListCondensedRow);

export default EnvelopeListCondensedRow;
