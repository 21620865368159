import { RequestResponse } from 'hooks/types/client';
import useRequest from '../client/useRequest';

type UseCommunicationClone = (
  communicationUUID: string
) => RequestResponse<'communications.clones'>;

const useCommunicationClone: UseCommunicationClone = (communicationUUID: string) => {
  const params = {
    urlParams: {
      commUuid: communicationUUID,
    },
  };
  const result = useRequest('communications.clones', params, {
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log('error', error);
    },
    enabled: communicationUUID.length > 0,
  });

  return result;
};

export default useCommunicationClone;
