import { createAction } from '@reduxjs/toolkit';
import type { API, Campaign, ErrorObject, UUID } from 'types';

export const fetchAllCampaignsForFilter = createAction('CAMPAIGNS_FILTER/FETCH');

type FetchAllCampaignsPayload = {
  name?: string;
  limit?: string;
  relationships?: string[];
  selectable_fields?: string[];
};
export const fetchAllCampaignsDebounced = createAction<FetchAllCampaignsPayload>(
  'CAMPAIGNS/DEBOUNCED_FETCH'
);
export const fetchAllCampaigns = createAction<FetchAllCampaignsPayload>('CAMPAIGNS/FETCH');

export const fetchFilterCampaigns = createAction<FetchAllCampaignsPayload>(
  'CAMPAIGNS/FETCH_FILTER_REQUEST'
);

export const fetchAllCampaignsRequest = createAction('CAMPAIGNS/FETCH_REQUEST');

export type FetchAllCampaignsSuccessPayload = API.WrappedAPIResponse<Campaign>;
export const fetchAllCampaignsSuccess =
  createAction<API.WrappedAPIResponse<Campaign>>('CAMPAIGNS/FETCH_SUCCESS');
export const fetchAllCampaignsFailure = createAction<ErrorObject>('CAMPAIGNS/FETCH_FAILURE');

type FetchCampaignsForFilterPillsPayload = { campaignIds: string[] };

export const fetchCampaignsForFilterPills =
  createAction<FetchCampaignsForFilterPillsPayload>('CAMPAIGNS/FETCH_BY_ID');
export const fetchCampaignsForFilterPillsRequest = createAction('CAMPAIGNS/FETCH_BY_ID_REQUEST');
export const fetchCampaignsForFilterPillsSuccess = createAction<API.WrappedAPIResponse<Campaign>>(
  'CAMPAIGNS/FETCH_BY_ID_SUCCESS'
);
export const fetchCampaignsForFilterPillsFailure = createAction<ErrorObject>(
  'CAMPAIGNS/FETCH_BY_ID_FAILURE'
);

export const fetchSingleCampaignWithRules = createAction<{ campaignId: UUID }>(
  'CAMPAIGNS/FETCH_SINGLE_WITH_RULES'
);

export const fetchSingleSimpleCampaign = createAction<{ campaignId: UUID }>(
  'CAMPAIGNS/FETCH_SINGLE_SIMPLE'
);

export const fetchSingleCampaign = createAction<{ campaignId: UUID }>('CAMPAIGNS/FETCH_SINGLE');
export const fetchSingleCampaignRequest = createAction('CAMPAIGNS/FETCH_SINGLE_REQUEST');
export const fetchSingleCampaignSuccess = createAction<Campaign>('CAMPAIGNS/FETCH_SINGLE_SUCCESS');
export const fetchSingleCampaignFailure = createAction<ErrorObject>(
  'CAMPAIGNS/FETCH_SINGLE_FAILURE'
);

export const createCampaign = createAction('CAMPAIGNS/CREATE');
export const createCampaignRequest = createAction('CAMPAIGNS/CREATE_REQUEST');
export const createCampaignSuccess = createAction<Campaign>('CAMPAIGNS/CREATE_SUCCESS');
export const createCampaignFailure = createAction<ErrorObject>('CAMPAIGNS/CREATE_FAILURE');

export const deleteCampaign = createAction<{ id: UUID }>('CAMPAIGNS/DELETE');
export const deleteCampaignRequest = createAction<string>('CAMPAIGNS/DELETE_REQUEST');
export const deleteCampaignSuccess = createAction<string>('CAMPAIGNS/DELETE_SUCCESS');
export const deleteCampaignFailure = createAction<ErrorObject>('CAMPAIGNS/DELETE_FAILURE');

export type PayloadDeleteCampaignRule = {
  ruleId: UUID;
  campaignId: UUID;
};

export const deleteCampaignRule = createAction<PayloadDeleteCampaignRule>('CAMPAIGNS/DELETE_RULE');

export type UpsertCampaignPayload = {
  uuid?: string;
  name: string;
  description: string;
  active?: boolean;
  redirect?: boolean;
};

export type SearchActiveIntegrationsPayload = {
  searchValue: string;
};

export const upsertCampaign = createAction<UpsertCampaignPayload>('CAMPAIGNS/UPSERT');
export const upsertCampaignWithChan = createAction<UpsertCampaignPayload>(
  'CAMPAIGNS/UPSERT_CHANNEL'
);
export const upsertCampaignRequest = createAction<UpsertCampaignPayload>(
  'CAMPAIGNS/UPSERT_REQUEST'
);
export const upsertCampaignSuccess = createAction<Campaign>('CAMPAIGNS/UPSERT_SUCCESS');
export const upsertCampaignFailure = createAction<ErrorObject>('CAMPAIGNS/UPSERT_FAILURE');
export const upsertCampaignFulfill = createAction('CAMPAIGNS/UPSERT_FULFILL');

export const cloneCampaign = createAction<{ resourceId: UUID; customerId: UUID }>('CAMPAIGN/CLONE');
export const cloneCampaignRequest = createAction('CAMPAIGN/CLONE_REQUEST');
export const cloneCampaignFailure = createAction<ErrorObject>('CAMPAIGN/CLONE_FAILURE');
export const cloneCampaignSuccess = createAction<Campaign>('CAMPAIGN/CLONE_SUCCESS');

export const fetchActiveIntegrations = createAction<SearchActiveIntegrationsPayload>(
  'CAMPAIGN/FETCH_INTEGRATIONS'
);
export const fetchActiveIntegrationsRequest = createAction('CAMPAIGN/FETCH_INTEGRATIONS_REQUEST');
export const fetchActiveIntegrationsFailure = createAction<ErrorObject>(
  'CAMPAIGN/FETCH_INTEGRATIONS_FAILURE'
);
export const fetchActiveIntegrationsSuccess = createAction<string[]>(
  'CAMPAIGN/FETCH_INTEGRATIONS_SUCCESS'
);
