import { cloneRule, fetchAllCampaigns } from 'actions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignsList } from 'selectors/campaigns';
import type { UUID } from 'types';

type ComponentProps = {
  ruleOutcomeId: UUID;
  toggleShowCloneRuleModalOpen: () => void;
};

const CampaignSimpleList: React.FC<ComponentProps> = ({
  ruleOutcomeId,
  toggleShowCloneRuleModalOpen,
}) => {
  const dispatch = useDispatch();
  const campaigns = useSelector(getCampaignsList);

  useEffect(() => {
    dispatch(fetchAllCampaigns({ limit: '-1' }));
  }, [dispatch]);

  const handleClick = async (id: UUID): Promise<void> => {
    await dispatch(cloneRule({ campaignId: id, ruleOutcomeId }));
    toggleShowCloneRuleModalOpen();
  };

  return (
    <div style={{ maxHeight: '500px' }} className="overflow-scroll">
      <table className="min-w-full">
        <thead>
          <tr />
        </thead>
        <tbody className="bg-white text-left">
          {campaigns &&
            campaigns.map((campaign) => (
              <tr
                key={campaign.uuid}
                onClick={(): Promise<void> => handleClick(campaign.uuid)}
                data-testid={`campaign-${campaign.uuid}`}
                className="border-t border-gray-200 hover:bg-gray-100 table-row"
              >
                <td className="px-6 py-4 border-b border-gray-200">
                  <div className="flex items-center">
                    <div>
                      <div className="text">{campaign.name}</div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default CampaignSimpleList;
