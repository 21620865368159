import React from 'react';
import { RenderedAction } from 'types';
import LogItem from './LogItem';

type LogsListProps = {
  logs: RenderedAction[];
};

const LogsList: React.FC<LogsListProps> = (props) => {
  const { logs } = props;

  return (
    <div className="px-5 my-8">
      <h2 className="text-left my-4 text font-bold text-sm">Activity</h2>
      <div>
        {logs.map((log, index) => (
          <LogItem key={log.uuid} log={log} index={index} length={logs.length} />
        ))}
      </div>
    </div>
  );
};

export default LogsList;
