import { BarExtendedDatum, BarMouseEventHandler, ResponsiveBar } from '@nivo/bar';
import { fetchEventBuckets } from 'actions';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getEventBuckets, getEventBucketsLoading } from 'selectors/analytics';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getNavParamsByResource } from 'selectors/nav';
import { getPermissionsPolicy } from 'selectors/permissions';
import { useSelector } from 'store';
import { isActionAuthorized } from 'utils/permissions';
import { useTimeRange } from 'utils/timeRanges';
import { useHistory } from 'utils/urls';

const DashboardEvents: React.FC = () => {
  const dispatch = useDispatch();
  const eventBuckets = useSelector(getEventBuckets);
  const location = useLocation();
  const history = useHistory();
  const loading = useSelector(getEventBucketsLoading);
  const navParams = useSelector(getNavParamsByResource(resourceQueryParamName.metrics));

  const range = useTimeRange();
  const customerDomain = useSelector(getCustomerDomain);

  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);

  useEffect(() => {
    dispatch(fetchEventBuckets(range));
    // FIXME: location.search should not be here, it's better to use something
    // like the widget params
  }, [dispatch, range, location.search]);

  const colors = {
    0: '#4ACDAA',
  };
  const getColors = (): string => colors[0];
  let maxValue = 0;
  let sumHours = 0;

  const getLabelRange = (sum: number, date: string | number | Date): string => {
    const totalDays = sum / 24;
    const curDate = moment(date);
    if (totalDays < 1) {
      return curDate.add(30, 'minutes').startOf('hour').format('hh:mm A');
    }
    if (totalDays >= 1 && totalDays < 30) {
      return curDate.format('ddd DD');
    }
    if (totalDays >= 30) {
      return curDate.format('MMM DD');
    }
    return curDate.format('MMM DD');
  };

  const data = eventBuckets.map((event, ind) => {
    const curDate = moment(event.start_time).format('DD MMMM YYYY hh:mm A');
    const count = event.count || 0;
    if (ind > 0) {
      const a = moment(eventBuckets[ind - 1].start_time);
      const b = moment(eventBuckets[ind].start_time);
      sumHours += b.diff(a, 'hours');
    }

    maxValue = Math.max(maxValue, count);
    return {
      date: curDate,
      Events: count,
      startDate: event.start_time,
      endDate: event.end_time,
    };
  });

  const handleClick = (e: BarExtendedDatum): void => {
    const { campaign } = navParams;
    if (isActionAuthorized('events.list', user.roles, policy)) {
      const startOfDay = moment.utc(data[e.index].startDate).format(FULL_DATE_FORMAT);
      const endOfDay = moment.utc(data[e.index].endDate).format(FULL_DATE_FORMAT);
      history.pushLookup({
        routeName: 'envelope-list',
        queryParams: {
          envelopes__created_after: startOfDay,
          envelopes__created_before: endOfDay,
          ...(campaign
            ? { [`${resourceQueryParamName.envelopes}__campaign_uuids`]: campaign }
            : {}),
        },
        customerDomain,
      });
    }
  };

  if (loading) {
    return <WidgetLoading />;
  }

  if (data.length === 0) {
    return <WidgetEmptyState msg="No events" />;
  }

  return (
    <div className="h-dashboard-events">
      {/* make sure parent container have a defined height when using */}
      {/* responsive component, otherwise height will be 0 and */}
      <ResponsiveBar
        data={data}
        keys={['Events']}
        indexBy="date"
        margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
        padding={0.2}
        innerPadding={2}
        colors={getColors}
        maxValue={maxValue}
        enableLabel={false}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisBottom={{
          tickRotation: -49,
          legendOffset: 30,
          tickPadding: 5,
          format: (value): string => getLabelRange(sumHours, value),
        }}
        animate
        onClick={handleClick}
        onMouseEnter={
          ((_data, event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
            event.currentTarget.style.cursor = 'pointer';
          }) as BarMouseEventHandler<SVGElement>
        }
      />
    </div>
  );
};

export default withWidget({
  title: 'Events',
  subtitle: 'Issues found by your use case monitors',
  minWidth: 9,
  minHeight: 18,
  containerClassName: 'overflow-visible',
})(DashboardEvents);
