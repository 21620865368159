import { fetchAllTagGroups, fetchAllTags, fetchSingleTagGroup } from 'actions';
import TagGroup from 'components/TagGroup';
import TagGroups from 'components/TagGroups';
import TagsManager from 'components/TagsManager';
import { RouteSpec, SuperRouteSpec } from 'types';

const tagsRotues: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'tags',
    path: '/:customerDomain/tags',
    component: TagsManager,
    private: true,
    data: {
      actions: [fetchAllTags],
      params: {
        order_by: {
          defaultValue: 'created_at',
        },
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        tags: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            broad_search: {},
          },
        },
      },
    },
  },
  {
    name: 'tag-group-detail',
    path: '/:customerDomain/tag-groups/:tagGroupId',
    component: TagGroup,
    private: true,
    data: {
      idFromPath: true,
      actions: [fetchSingleTagGroup],
    },
  },
  {
    name: 'tag-groups',
    path: '/:customerDomain/tag-groups',
    component: TagGroups,
    private: true,
    data: {
      actions: [fetchAllTagGroups],
      params: {
        order_by: {
          defaultValue: 'created_at',
        },
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        tag_group: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            broad_search: {},
          },
        },
      },
    },
  },
];

export default tagsRotues;
