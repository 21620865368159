/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'store';

import LinkLookup from 'components/LinkLookup';
import {
  getConfigAsArrayFromRule,
  getEventRule,
  getEventsByRule,
  getRuleTracings,
  getSelectedText,
} from 'selectors/envelopeReview';
import EventSection from './EventSection';

type ComponentProps = {
  ruleId: string;
};

const RuleSection: React.FC<ComponentProps> = ({ ruleId }) => {
  const rule = useSelector((state) => getEventRule(state, ruleId));
  const events = useSelector((state) => getEventsByRule(state, ruleId));
  const tracings = useSelector((state) => getRuleTracings(state, ruleId));
  const arrayTree = useSelector((state) => getConfigAsArrayFromRule(state, ruleId));
  const selectedText = useSelector(getSelectedText);

  if (!rule) return null;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-1 text-body">
        <LinkLookup
          // @ts-ignore
          routeName={rule.rule_group_uuid ? 'global-rule-group-manager' : 'rule-manager'}
          // @ts-ignore
          routeParams={{ ruleId: rule.rule_group_uuid || rule.uuid }}
          queryParams={{ body: selectedText }}
          className="text-body"
        >
          <span className="font-bold hover:underline">{rule.name}</span>
        </LinkLookup>
        <span className="italic text-litlingo-gray-4">{events[0].campaign?.name || ''}</span>
      </div>
      <div className="flex flex-col gap-2">
        {tracings
          .filter((t) => t && t[0])
          .sort((a, b) => {
            const aIndex = arrayTree.findIndex((n) => n.id === a[0].id);
            const bIndex = arrayTree.findIndex((n) => n.id === b[0].id);

            if (!aIndex || !bIndex) return -1;

            if (aIndex > bIndex) return 1;
            if (aIndex < bIndex) return -1;

            return -1;
          })

          .map((t) => (
            <EventSection key={t[0].id} arrayTree={arrayTree} tracing={t} />
          ))}
      </div>
    </div>
  );
};
export default RuleSection;
