import { Client } from '@base';
import appConfig from '@config';
import { AuthError } from '@errors';
import * as Sentry from '@sentry/browser';
import type { Storage as StorageT } from '@storage';
import { LocalStorage } from '@storage';
import type {
  APIV2,
  AuthClientStorage,
  AuthorizationCodeResponse,
  AuthorizationRequestParams,
  AuthorizationResponse,
  AuthorizationTokenResponse,
  OIDCConfiguration,
  RestrictedCustomer,
  SelectCustomerResult,
  SignInConfig,
  SignInResult,
  SignOutConfig,
  SignOutRequestParams,
  SignOutResponse,
  TokenResponse,
  User,
} from '@types';
import type { JwtPayload } from 'jwt-decode';
import jwtDecode from 'jwt-decode';

export type StorageCls = new <T>(key: string) => StorageT<T>;

type AuthClientConfig = {
  storage: StorageCls;
  persist: boolean;
  sentry: boolean;
};

type HeadersConfig = {
  domain?: string | null;
  spoofedUser?: string | null;
  token?: string | null;
};

class AuthClient extends Client {
  private _clientId: string;

  private _config: OIDCConfiguration | null;

  private _domain: string | null;

  private _persist: boolean;

  private _spoofUser: string | null;

  private _storage: StorageT<AuthClientStorage>;

  constructor(clientId: string, config: Partial<AuthClientConfig> = {}) {
    super({}, { sentry: config.sentry ?? false });

    this._clientId = clientId;
    this._config = null;
    this._domain = null;
    this._persist = config.persist ?? false;
    this._spoofUser = null;

    const Storage = config.storage ?? LocalStorage;
    this._storage = new Storage<AuthClientStorage>(
      appConfig.auth.storageKey + clientId
    );
  }

  // eslint-disable-next-line class-methods-use-this
  private _headers(config: HeadersConfig): Record<string, string> {
    const headers: Record<string, string> = {
      'x-litlingo-auth-no-redirect': 'true',
      'block-overwrite': 'true',
      'cache-control': 'no-cache',
    };

    if (config.token != null) {
      headers.authorization = `Bearer ${config.token}`;
    }
    if (config.domain != null) {
      headers['x-litlingo-customer'] = config.domain;
    }
    if (config.spoofedUser != null) {
      headers['spoofed-user-uuid'] = config.spoofedUser;
    }

    return headers;
  }

  async isAuthenticated(): Promise<boolean> {
    const { token } = await this._storage.get(['token']);

    return token != null;
  }

  async getAPIClientHeaders(): Promise<Record<string, string>> {
    const headers: Record<string, string> = {};

    const token = await this.getToken();
    if (token != null) {
      headers.authorization = `Bearer ${token}`;
    }

    const domain = await this.getDomain();
    if (domain != null) {
      headers['x-litlingo-customer'] = domain;
    }

    if (
      this._sentry &&
      ((token == null && domain != null) || (token != null && domain == null))
    ) {
      Sentry.captureMessage('Invalid auth client state', {
        contexts: { data: { token, domain } },
      });
      await this._storage.remove(['token', 'domain']);
    }

    const spoofUser = this.getSpoofUser();
    if (spoofUser != null) {
      headers['spoofed-user-uuid'] = spoofUser;
    }

    return headers;
  }

  async getDomain(): Promise<string | null> {
    // if persist is disabled, the only source of truth is the instance property
    if (!this._persist) {
      return this._domain;
    }

    // if persist is enabled, the only source of truth should be the storage to
    // prevent issues in the chrome plugin where we have 2 instances of this auth
    // client
    const { domain } = await this._storage.get(['domain']);

    return domain ?? null;
  }

  getSpoofUser(): string | null {
    return this._spoofUser;
  }

  private async _setDomain(domain: string | null): Promise<void> {
    // if persist is disabled, the only source of truth is the instance property
    if (!this._persist) {
      this._domain = domain;
      return;
    }

    // if persist is enabled, the only source of truth should be the storage to
    // prevent issues in the chrome plugin where we have 2 instances of this auth
    // client
    if (domain != null) {
      await this._storage.set({ domain });
    } else {
      await this._storage.remove(['domain']);
    }
  }

  async config(): Promise<OIDCConfiguration> {
    if (this._config != null) {
      return this._config;
    }

    const response = await this.request<OIDCConfiguration>({
      method: 'get',
      url: appConfig.auth.config,
    });
    if (response.error != null) {
      throw new Error('Error fetching OIDC config');
    }

    this._config = response.data;

    return response.data;
  }

  private async _getState(): Promise<string> {
    const state = Array.from(
      window.crypto.getRandomValues(new Uint8Array(10)),
      (v) => v.toString(16)
    ).join('');

    await this._storage.set({ state });

    return state;
  }

  private static _getScope(scopes: string[] = []): string {
    const base = ['openid', 'email'].reduce<string[]>((curr, scope) => {
      if (!scopes.includes(scope)) {
        curr.push(scope);
      }

      return curr;
    }, []);

    return base.concat(scopes).join(' ');
  }

  private async _getSignInURL(signInConfig: SignInConfig): Promise<string> {
    const config = await this.config();

    const state = await this._getState();
    const params: AuthorizationRequestParams = {
      response_type: signInConfig.responseType ?? 'code',
      client_id: this._clientId,
      redirect_uri: signInConfig.redirectURI,
      scope: AuthClient._getScope(signInConfig.scopes),
      state,
    };
    if (signInConfig.providerHint != null) {
      params.provider_hint = signInConfig.providerHint;
    }

    this._storage.set({ redirectURI: signInConfig.redirectURI });
    const query = new URLSearchParams(params).toString();

    return `${config.authorization_endpoint}?${query}`;
  }

  private static _getURLParams<R>(url: string): R | null {
    // accounts.litlingo.com sometimes adds a weird # at the end, this replace
    // fixes that
    const [left, right] = url.replace(/#$/, '').split('#');

    let paramsString = right == null ? left : right;
    if (paramsString.indexOf('?') !== -1) {
      [, paramsString] = paramsString.split('?');
    }

    if (paramsString == null || paramsString.trim() === '') {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {};
    const regex = /([^&=]+)=([^&]*)/g;
    let match = regex.exec(paramsString);

    while (match !== null) {
      const [, name, value] = match;
      params[decodeURIComponent(name)] = decodeURIComponent(value);

      match = regex.exec(paramsString);
    }

    return params;
  }

  async _checkState(params: { state?: string }): Promise<void> {
    const { state } = await this._storage.get(['state']);
    if (state == null && params.state == null) return;

    if (state == null) {
      throw new AuthError('Stored auth state cannot be null');
    }

    this._storage.remove(['state']);
    if (params.state !== state) {
      throw new AuthError(
        `Invalid auth state, expected '${state}' and got '${params.state}'`
      );
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async _handleTokenFlow(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _params: AuthorizationTokenResponse
  ): Promise<SignInResult> {
    throw new AuthError('Auth token flow is not supported');
  }

  async _handleCodeFlow(
    params: AuthorizationCodeResponse
  ): Promise<SignInResult> {
    const { redirectURI } = await this._storage.get(['redirectURI']);
    await this._storage.remove(['redirectURI']);

    const config = await this.config();
    const response = await this.request<TokenResponse>({
      method: 'post',
      url: config.token_endpoint,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: new URLSearchParams({
        grant_type: 'authorization_code',
        code: params.code,
        client_id: this._clientId,
        // For redirect from registration flow
        redirect_uri: redirectURI || window.location.href.split('?')[0],
      }),
    });

    if (response.error != null) {
      throw new AuthError('There was an error changing the code for a token');
    }

    const customers = await this.request<APIV2.Users.Customers>({
      method: 'get',
      url: appConfig.api.customers,
      headers: this._headers({ token: response.data.access_token }),
    });

    if (customers.error != null) {
      throw new AuthError('There was an error getting the list of customers');
    }

    // This always need to be the last thing to do in this function to make sure
    // the token is not stored if something fails
    await this._storage.set({
      token: response.data.access_token,
      refreshToken: response.data.refresh_token,
    });

    return { customers: customers.data };
  }

  async signInWithRedirect(config: SignInConfig): Promise<void> {
    const url = await this._getSignInURL(config);
    window.location.assign(url);
  }

  async getRedirectResult(): Promise<SignInResult | null> {
    const params = AuthClient._getURLParams<AuthorizationResponse>(
      window.location.href
    );
    if (params == null || Object.keys(params).length === 0) {
      return null;
    }

    await this._checkState(params);

    if ('error' in params) {
      throw new AuthError(
        `There was an error signing the user in, code: '${params.error}'`
      );
    }

    if ('access_token' in params) {
      return this._handleTokenFlow(params);
    }

    if ('code' in params) {
      return this._handleCodeFlow(params);
    }

    return null;
  }

  async signInWithChromePopup(
    config: Omit<SignInConfig, 'redirectURI'> = {}
  ): Promise<SignInResult> {
    if (window.chrome == null) {
      throw new AuthError(
        "'signInWithChromePopup' can only be used in chrome extensions"
      );
    }

    const url = await this._getSignInURL({
      ...config,
      redirectURI: window.chrome.identity.getRedirectURL('login'),
    });

    return new Promise((resolve, reject) => {
      const cb = (winResponse?: chrome.windows.Window): void => {
        if (window.chrome.runtime.lastError != null) {
          // TODO: catch here the error message for closed window
          // "The user did not approve access."
          reject(
            new AuthError(
              window.chrome.runtime.lastError.message ??
                'Unknown error while signing in with chrome popup'
            )
          );
          return;
        }

        if (winResponse == null) {
          reject(new AuthError('Response URL cannot be null or undefined'));
          return;
        }

        window.chrome.tabs.onUpdated.addListener(
          async (tabId, changeInfo, tab) => {
            if (tab.windowId === winResponse.id) {
              const redirectURL = window.chrome.identity.getRedirectURL(
                'login'
              );
              if (
                'url' in changeInfo &&
                changeInfo.url != null &&
                changeInfo.url.includes(redirectURL)
              ) {
                const params = AuthClient._getURLParams<AuthorizationResponse>(
                  changeInfo.url
                );

                window.chrome.tabs.remove(tabId);

                if (params == null || Object.keys(params).length === 0) {
                  reject(new AuthError('URL params cannot be empty'));
                  return;
                }

                try {
                  await this._checkState(params);

                  if ('error' in params) {
                    reject(
                      new AuthError(
                        `There was an error signing the user in, code: '${params.error}'`
                      )
                    );
                    return;
                  }

                  if ('access_token' in params) {
                    const result = await this._handleTokenFlow(params);
                    resolve(result);
                    return;
                  }

                  const result = await this._handleCodeFlow(params);
                  resolve(result);
                } catch (e) {
                  reject(e);
                }
              }
            }
          }
        );
      };

      window.chrome.windows.create(
        {
          focused: true,
          height: 800,
          width: 800,
          url,
          type: 'popup',
        },
        cb
      );
    });
  }

  async selectCustomer(domain: string): Promise<SelectCustomerResult> {
    const token = await this.getToken();
    if (token == null) {
      throw new AuthError(
        "User needs to be signed in before calling 'selectCustomer'"
      );
    }

    const user = await this._getUser(token, domain);
    await this._setDomain(domain);

    return { user };
  }

  selectSpoofUser(spoofUser: string): void {
    this._spoofUser = spoofUser;
  }

  private async _getUser(token: string, domain: string): Promise<User> {
    const response = await this.request<APIV2.Users.Me>({
      method: 'get',
      url: appConfig.api.user,
      headers: this._headers({
        token,
        domain,
        spoofedUser: this._spoofUser,
      }),
    });

    if (response.error != null) {
      throw new AuthError('There was an error getting the user');
    }

    return response.data;
  }

  async getUser(): Promise<User> {
    const token = await this.getToken();
    if (token == null) {
      throw new AuthError(
        "User needs to be signed in before calling 'getUser'"
      );
    }

    const domain = await this.getDomain();
    if (domain == null) {
      throw new AuthError(
        "Customer needs to be selected before calling 'getUser'"
      );
    }

    return this._getUser(token, domain);
  }

  async getCustomers(): Promise<RestrictedCustomer[]> {
    const token = await this.getToken();
    if (token == null) {
      throw new AuthError(
        "User needs to be signed in before calling 'getCustomers'"
      );
    }

    const response = await this.request<APIV2.Users.Customers>({
      method: 'get',
      url: appConfig.api.customers,
      headers: this._headers({ token }),
    });

    if (response.error != null) {
      throw new AuthError('There was an error getting the list of customers');
    }

    return response.data;
  }

  async getToken(): Promise<string | null> {
    const { token, refreshToken } = await this._storage.get([
      'token',
      'refreshToken',
    ]);

    if (token == null) {
      return null;
    }

    const payload = jwtDecode<JwtPayload>(token);

    // only return the token if it expires more than EXPIRATION_LEEWAY in the
    // future
    const expiration = Date.now() / 1000 + appConfig.auth.expirationLeeway;
    if (payload.exp != null && payload.exp > expiration) {
      return token;
    }

    if (refreshToken == null) {
      Sentry.captureMessage('Failed to get refresh token from storage', {
        contexts: { data: { token, refreshToken } },
      });
      return null;
    }

    const config = await this.config();
    const response = await this.request<TokenResponse>({
      method: 'post',
      url: config.token_endpoint,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: this._clientId,
      }),
    });
    if (response.error != null) {
      return null;
    }

    await this._storage.set({
      token: response.data.access_token,
      refreshToken: response.data.refresh_token,
    });

    return response.data.access_token;
  }

  private async _getSignOutURL(config: SignOutConfig): Promise<string | null> {
    const token = await this.getToken();
    if (token == null) {
      return null;
    }

    const state = await this._getState();
    const params: SignOutRequestParams = {
      client_id: this._clientId,
      token,
      post_logout_redirect_uri: config.redirectURI,
      state,
    };

    const query = new URLSearchParams(params).toString();

    return `${appConfig.auth.logout}?${query}`;
  }

  private async _signOut(): Promise<void> {
    this._domain = null;
    this._spoofUser = null;
    await this._storage.remove([
      'token',
      'refreshToken',
      'domain',
      'redirectURI',
    ]);
  }

  async signOutWithChromePopup(): Promise<void> {
    if (window.chrome == null) {
      throw new AuthError(
        "'signOutWithChromePopup' can only be used in chrome extensions"
      );
    }

    const url = await this._getSignOutURL({
      redirectURI: window.chrome.identity.getRedirectURL('logout'),
    });

    // clean the stored auth state before calling logout url
    await this._signOut();

    // ignore url redirect for users without a stored token
    if (url == null) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      const cb = (winResponse?: chrome.windows.Window): void => {
        if (window.chrome.runtime.lastError != null) {
          reject(
            new AuthError(
              window.chrome.runtime.lastError.message ??
                'Unknown error while signing out with chrome popup'
            )
          );
          return;
        }

        if (winResponse == null) {
          reject(new AuthError('Response URL cannot be null or undefined'));
          return;
        }

        window.chrome.tabs.onUpdated.addListener(
          async (tabId, changeInfo, tab) => {
            if (tab.windowId === winResponse.id) {
              if ('url' in changeInfo && changeInfo.url != null) {
                const params = AuthClient._getURLParams<SignOutResponse>(
                  changeInfo.url
                );

                window.chrome.tabs.remove(tabId);

                if (params == null || Object.keys(params).length === 0) {
                  reject(new AuthError('URL params cannot be empty'));
                  return;
                }

                try {
                  await this._checkState(params);
                } catch (e) {
                  reject(e);
                }

                if ('error' in params) {
                  reject(
                    new AuthError(
                      `There was an error signing the user out, code: '${params.error}'`
                    )
                  );
                }

                resolve();
              }
            }
          }
        );
      };

      window.chrome.windows.create(
        {
          focused: true,
          height: 800,
          width: 800,
          url,
          type: 'popup',
        },
        cb
      );
    });
  }

  // FIXME: this does not check the state param
  async signOutWithRedirect(config: SignOutConfig): Promise<void> {
    const url = await this._getSignOutURL(config);

    // clean the stored auth state before calling logout url
    await this._signOut();

    if (url != null) {
      window.location.assign(url);
      return;
    }

    // ignore url redirect for users without a stored token and just redirect to
    // redirectURI if it's not already there
    if (window.location.href !== config.redirectURI) {
      window.location.assign(config.redirectURI);
    }
  }
}

export default AuthClient;
