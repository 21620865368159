import React from 'react';

type ComponentProps = {
  className?: string;
  onChange: (keyword: string) => void;
  options: { [key: string]: string };
  selectedItems: string | string[];
  notSelectedItems?: string | string[];
  labelClasses?: string;
  prefix?: string;
};

const capitalize = (label: string): string => {
  if (label.length > 0) {
    return label[0].toUpperCase() + label.slice(1);
  }
  return label;
};

const MultiSelectDropdown: React.FC<ComponentProps> = (props) => {
  const {
    className,
    onChange,
    options,
    selectedItems,
    notSelectedItems = [],
    labelClasses,
    prefix = 'default',
  } = props;

  const labelClass = labelClasses || 'text-litlingo-gray-600 text-sm';

  return (
    <div className={className || ''}>
      {Object.entries(options).map(([key, label], idx) => (
        <div key={key} className={idx !== 0 ? 'mt-1' : ''}>
          <div className="relative flex items-start">
            <div className="flex items-center h-5 mr-2">
              <input
                id={`${prefix}-${key}`}
                data-testid={`${key}-checkbox`}
                type="checkbox"
                value={key}
                className={`form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out cursor-pointer ${
                  notSelectedItems.includes(key) ? 'litlingo-checkbox-negated' : ''
                }`}
                onChange={(e): void => onChange(e.target.value)}
                checked={[...selectedItems, ...notSelectedItems].includes(key)}
              />
            </div>
            <div className="text-sm leading-5">
              <label htmlFor={`${prefix}-${key}`} className={labelClass}>
                {capitalize(label)}
              </label>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MultiSelectDropdown;
