/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { fetchAllCampaignRuleOutcomes } from 'actions';
import { setURLParams } from 'actions/nav';
import LoadingIndicator from 'components/LoadingIndicator';
import useClickOutside from 'components/utils/useClickOutside';
import { SEARCH_ICON } from 'constants/filterIcons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignRuleOutcomesList } from 'selectors/campaignRuleOutcomes';
import { getNavParamsByResource } from 'selectors/nav';
import { Selector } from 'types';
import logEvent from 'utils/analytics';
import ShowLabelsDashboard from './ShowLabelsDashboard';

type MinimumResource = {
  uuid: string;
  color?: string | null;
  envelopes_count?: number;
};

type ResourceFilterProps<T extends MinimumResource, A> = {
  resource: string;
  className?: string;
  filterKey: string;
  fetchAction?: A;
  getResourceList: Selector<T[]>;
  getResourceCount: Selector<number>;
  getLoading: Selector<boolean>;
  fetchLabelsResourceList?: A;
  getLabelsResourceList: Selector<T[]>;
  title?: string;
  nameKey: keyof T;
  secondNameKey?: keyof T;
  itemField?: keyof T;
  allowExclude?: boolean;
  dataTestid?: string;
  isRule?: boolean;
  colorChip?: boolean;
  isOpening?: boolean;
  showLabelKey?: keyof T;
};

const BackendResourceFilterDashboard = <
  T extends MinimumResource,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  A extends ActionCreatorWithOptionalPayload<any, string>
>(
  props: ResourceFilterProps<T, A>
): ReturnType<React.FC> => {
  const {
    className,
    resource,
    fetchAction,
    filterKey,
    itemField = 'uuid',
    getResourceList,
    getLoading,
    fetchLabelsResourceList,
    getLabelsResourceList,
    title = '',
    nameKey,
    secondNameKey,
    allowExclude = true,
    dataTestid,
    isRule = false,
    colorChip = false,
    isOpening,
    getResourceCount,
    showLabelKey,
  } = props;

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
  const [offset, setOffset] = useState(0);
  const [checkedStatus, setCheckedStatus] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const params = useSelector(getNavParamsByResource(resource));
  const allData = useSelector(getResourceList);
  const outcomes = useSelector(getCampaignRuleOutcomesList);
  const totalCount = useSelector(getResourceCount);

  const loading = useSelector(getLoading);

  const notFilterData = params[`not_${filterKey}`] ?? [];
  const filterData = params[filterKey] ?? [];

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  useEffect(() => {
    if (fetchLabelsResourceList && (filterData.length > 0 || notFilterData.length > 0)) {
      dispatch(fetchLabelsResourceList({ uuids: [...filterData, ...notFilterData] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filterData.length, notFilterData.length]);

  useEffect(() => {
    if (isOpen && fetchAction != null) {
      dispatch(
        fetchAction({
          limit: 25,
          include_envelopes_count: false,
          searchValue: debouncedSearchValue,
          offset: 0,
        })
      );
      setOffset(0);
      if (isRule) {
        dispatch(fetchAllCampaignRuleOutcomes({ limit: -1 }));
      }
    }
  }, [dispatch, isOpen, fetchAction, isRule, debouncedSearchValue]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedSearchValue(searchValue), 300);
    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  useEffect(() => {
    if (isOpening) {
      inputRef.current?.focus();
    }
  }, [inputRef, isOpening]);

  useEffect(() => {
    if (notFilterData.length && filterData.length) {
      setCheckedStatus(true);
    }
  }, [filterData.length, notFilterData.length]);

  const handleButtonClick = (): void => {
    setIsOpen(true);
  };

  const handleDelete = (labelId: string, exclude: boolean): void => {
    if (exclude) {
      dispatch(
        setURLParams({
          [`${resource}__not_${filterKey}`]: [...notFilterData].filter((s) => s !== labelId),
          [`${resource}__offset`]: '0',
        })
      );
    } else {
      dispatch(
        setURLParams({
          [`${resource}__${filterKey}`]: [...filterData].filter((s) => s !== labelId),
          [`${resource}__offset`]: '0',
        })
      );
    }
  };

  const handleInputChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: T
  ): void => {
    logEvent(`envelopes-list-filter-${title.toLowerCase()}`);

    const id = item.uuid;
    const value = item[itemField];
    const tagName = typeof value === 'string' ? value : id;

    const { shiftKey } = event.nativeEvent;

    let exclude = shiftKey;
    if (allowExclude === false) exclude = false;

    if (exclude && filterData.includes(tagName)) return;
    if (!exclude && notFilterData.includes(tagName)) return;

    let tagsNames = !exclude ? [...filterData] : [...notFilterData];

    event.currentTarget.dataset.checked =
      event.currentTarget.dataset.checked === 'true' ? 'false' : 'true';

    if (event.currentTarget.dataset.checked === 'true') {
      tagsNames.push(tagName);
      dispatch(
        setURLParams({
          [`${resource}__${!exclude ? filterKey : `not_${filterKey}`}`]: tagsNames,
          [`${resource}__offset`]: '0',
        })
      );
    } else {
      tagsNames = tagsNames.filter((s) => s !== tagName);
      handleDelete(tagName, exclude);
    }

    event.stopPropagation();
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e?.target.value.toLowerCase();
    setSearchValue(value);
  };

  const handleLoadMore = (): void => {
    if (isOpen && fetchAction != null) {
      dispatch(
        fetchAction({
          limit: 25,
          include_envelopes_count: false,
          searchValue: debouncedSearchValue,
          offset: offset + 25,
        })
      );
      setOffset((o) => o + 25);
    }
  };

  const handleInputChangeAll = (event: React.ChangeEvent<HTMLInputElement>): void => {
    logEvent(`envelopes-filter-all-${resource}`);

    const data = [...allData];
    const queryIds = data.reduce((acc, item) => [...acc, item.uuid] as never, []);

    if (event.target.checked) {
      if (filterData.length && !notFilterData.length) {
        dispatch(
          setURLParams({
            [`${resource}__${filterKey}`]: queryIds as string[],
            [`${resource}__offset`]: '0',
          })
        );
      } else if (notFilterData.length && !filterData.length) {
        dispatch(
          setURLParams({
            [`${resource}__not_${filterKey}`]: queryIds as string[],
            [`${resource}__offset`]: '0',
          })
        );
      }

      setCheckedStatus(true);
    } else {
      if (!filterData.length && !notFilterData.length) {
        dispatch(
          setURLParams({
            [`${resource}__${filterKey}`]: [],
            [`${resource}__offset`]: '0',
          })
        );
      } else if (notFilterData.length && !filterData.length) {
        dispatch(
          setURLParams({
            [`${resource}__not_${filterKey}`]: [],
            [`${resource}__offset`]: '0',
          })
        );
      } else {
        dispatch(
          setURLParams({
            [`${resource}__${filterKey}`]: [],
            [`${resource}__offset`]: '0',
          })
        );
        dispatch(
          setURLParams({
            [`${resource}__not_${filterKey}`]: [],
            [`${resource}__offset`]: '0',
          })
        );
      }
      setCheckedStatus(false);
    }
  };

  const getSelectAllStyle = (): string => {
    if (notFilterData.length && filterData.length) {
      return 'checkbox-select-all-filter';
    }
    if (notFilterData.length && !filterData.length) {
      return 'litlingo-checkbox-negated';
    }
    return '';
  };

  return (
    <>
      <div ref={wrapperRef} className={`flex flex-col ${className || ''}"`}>
        <div className="relative flex flex-row justify-start items-center w-full min-h-8 rounded p-1.5 bg-litlingo-success-light bg-opacity-10">
          {filterData.length || notFilterData.length ? (
            <div className="flex items-center h-5 ml-0.5">
              <input
                data-testid="select-all-checkbox"
                type="checkbox"
                className={`form-checkbox litlingo-checkbox  h-4 w-4 transition duration-150 ease-in-out cursor-pointer ${getSelectAllStyle()}`}
                onChange={handleInputChangeAll}
                checked={checkedStatus}
              />
            </div>
          ) : (
            <div className="w-5">{SEARCH_ICON('white')}</div>
          )}
          <input
            id="search"
            name="search"
            ref={inputRef}
            className="max-h-5 w-full text-white ml-1 font-normal text-base bg-transparent placeholder-white placeholder-italic"
            placeholder={`Search ${title}`}
            data-testid={dataTestid}
            type="text"
            onFocus={handleButtonClick}
            onChange={handleSearchChange}
            autoComplete="off"
          />
        </div>
        {isOpen && (
          <>
            <LoadingOverlayWrapper active={loading} spinner={<LoadingIndicator />} fadeSpeed={0}>
              <div className="w-full max-h-filter z-10 overflow-auto bg-litlingo-gray-1 custom-scrollbar">
                <fieldset className="m-2 min-h-5">
                  {allData.map((item, idx) => {
                    const ruleOutcome = outcomes.find((outcome) => outcome.rule_uuid === item.uuid);

                    if (
                      isRule &&
                      (!ruleOutcome || (ruleOutcome && ruleOutcome.campaign?.deleted_at))
                    )
                      return null;
                    const value = item[itemField];
                    const tagName = typeof value === 'string' ? value : item.uuid;

                    return (
                      <div key={item.uuid} className={idx !== 0 ? 'mt-2' : ''}>
                        <div
                          id={`${filterKey}-${item.uuid}`}
                          role="button"
                          aria-hidden
                          className="relative flex items-center cursor-pointer"
                          data-checked={[...filterData, ...notFilterData].includes(tagName)}
                          onClick={(event): void => handleInputChange(event, item)}
                        >
                          <div className="text-sm leading-5 flex flex-col items-start justify-center cursor-pointer">
                            <div className="flex flex-row items-center cursor-pointer">
                              <div
                                className={`relative flex justify-start ${
                                  secondNameKey ? 'items-start' : 'items-center'
                                }`}
                              >
                                <div className="flex items-center h-5 mr-1">
                                  <input
                                    id={`${filterKey}-${item.uuid}`}
                                    data-testid={`${item.uuid}-checkbox`}
                                    type="checkbox"
                                    className={`form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out cursor-pointer ${
                                      notFilterData.includes(tagName)
                                        ? 'litlingo-checkbox-negated'
                                        : ''
                                    }`}
                                    checked={
                                      filterData.includes(tagName) ||
                                      notFilterData.includes(tagName)
                                    }
                                  />
                                </div>
                                {colorChip && (
                                  <div
                                    className="rounded-full min-w-3 min-h-3 mr-2"
                                    style={{ backgroundColor: item.color || '' }}
                                  />
                                )}
                                <div className=" flex flex-col text-sm leading-5">
                                  <label
                                    htmlFor={`${filterKey}-${item.uuid}`}
                                    className="cursor-pointer"
                                  >
                                    <span className="text-litlingo-gray-6 text-xxs font-normal select-none break-all">
                                      {item[nameKey]}{' '}
                                      {/* {item.envelopes_count != null
                                      ? `(${item.envelopes_count})`
                                      : ''} Note: I don't see this in use so exempted to hide 0 counts */}
                                    </span>
                                  </label>
                                  {secondNameKey && (
                                    <span className="text-litlingo-gray-5 text-xxs font-normal select-none break-all">
                                      {item[secondNameKey]}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            {ruleOutcome && (
                              <span className="text-xs text-gray-500 select-none break-all">
                                {ruleOutcome.campaign?.name}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </fieldset>
                {offset + 25 < totalCount - 1 && (
                  <button
                    type="button"
                    className="w-full py-1 text-small border-t border-litlingo-gray-2 focus:outline-none"
                    onClick={handleLoadMore}
                  >
                    <span className="text-litlingo-primary">Load More</span>
                  </button>
                )}
              </div>
            </LoadingOverlayWrapper>
            {allowExclude && isOpen && (
              <div className="flex self-end mt-2 -mb-2">
                <span className="text-white font-normal text-xss">Shift + click to exclude</span>
              </div>
            )}
          </>
        )}
        {!isOpen && (
          <ShowLabelsDashboard
            handleDelete={handleDelete}
            getResourceList={getLabelsResourceList}
            filterData={filterData as string[]}
            notFilterData={notFilterData as string[]}
            itemField={itemField !== 'uuid' ? itemField : undefined}
            nameKey={nameKey}
            showLabelKey={showLabelKey}
          />
        )}
      </div>
    </>
  );
};

export default BackendResourceFilterDashboard;
