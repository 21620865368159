import React from 'react';
import FULL_LOGO, {
  SIMPLE_WHITE_LOGO,
  SIMPLE_BLUE_LOGO,
  LOGO_BLACK,
  ICON_LOGO,
} from '../constants/logos';

const FullLogo: React.FC = () => {
  return <>{FULL_LOGO}</>;
};

export const IconLogo: React.FC = () => {
  return <>{ICON_LOGO}</>;
};

export const SimpleBlackLogo: React.FC = () => {
  return <>{LOGO_BLACK}</>;
};

export const SimpleWhiteLogo: React.FC = () => {
  return <>{SIMPLE_WHITE_LOGO}</>;
};

export const SimpleBlueLogo: React.FC = () => {
  return <>{SIMPLE_BLUE_LOGO}</>;
};

export default FullLogo;
