import { fetchUsersRequest } from 'actions';
import type { Role, Selector, Team, User } from 'types';
import { userRolesToTypes } from 'utils/userRoles';

export const getUsersLoading: Selector<boolean> = (state) =>
  state.users.loading.includes(fetchUsersRequest.toString());

const getUsers: Selector<User[]> = (state) => state.ui.userPermissionsPage.users;

export const getSelectedUserName: Selector<string> = (state) => state.ui.userPermissionsPage.name;

export const getSelectedUserEmail: Selector<string> = (state) => state.ui.userPermissionsPage.email;

export const getSelectedUsers: Selector<User[]> = (state) =>
  state.ui.userPermissionsPage.selectedUsers;

export const getIsSelectAll: Selector<boolean> = (state) =>
  state.ui.userPermissionsPage.selectAllUsers;

export const getIncludedTeams: Selector<Team[]> = (state) =>
  state.ui.userPermissionsPage.includedTeams;
export const getExcludedTeams: Selector<Team[]> = (state) =>
  state.ui.userPermissionsPage.excludedTeams;

export const getIncludedRoles: Selector<string[]> = (state) =>
  state.ui.userPermissionsPage.includedRoles;

export const getIncludedUserTypes: Selector<string[]> = (state) =>
  userRolesToTypes(state.ui.userPermissionsPage.includedRoles as Role[]).map((t) => t.label);

export const getExcludedRoles: Selector<string[]> = (state) =>
  state.ui.userPermissionsPage.excludedRoles;

export const getIncludedInsight: Selector<string[]> = (state) =>
  state.ui.userPermissionsPage.includedInsight;

export const getExcludedInsight: Selector<string[]> = (state) =>
  state.ui.userPermissionsPage.excludedInsight;

export const getIncludedPrevent: Selector<string[]> = (state) =>
  state.ui.userPermissionsPage.includedPrevent;
export const getExcludedPrevent: Selector<string[]> = (state) =>
  state.ui.userPermissionsPage.excludedPrevent;

export const getPrivacyLevel: Selector<string | null> = (state) =>
  state.ui.userPermissionsPage.privacyLevel;

export const getSendInvitation: Selector<boolean> = (state) =>
  state.ui.userPermissionsPage.sendInvitation;

export const getInvitationState: Selector<string | null> = (state) =>
  state.ui.userPermissionsPage.invitationState;

export const getUnsavedChanges: Selector<boolean> = (state) =>
  state.ui.userPermissionsPage.unsavedChanges;

type Counts = {
  roles: Record<string, number>;
  insight: Record<string, number>;
  prevent: Record<string, number>;
  divisions: Record<string, number>;
};

export const getPermissionsCounts: Selector<Counts> = (state) => {
  const counts: Counts = {
    roles: { 'No role assigned': 0 },
    divisions: { 'No division assigned': 0 },
    insight: { 'No integrations assigned': 0 },
    prevent: { 'No integrations assigned': 0 },
  };

  const { selectedUsers: users } = state.ui.userPermissionsPage;

  users.forEach((user) => {
    if (user.roles.length > 0) {
      user.roles.forEach((r) => {
        if (counts.roles[r]) {
          counts.roles[r] += 1;
        } else {
          counts.roles[r] = 1;
        }
      });
    } else {
      counts.roles['No role assigned'] += 1;
    }

    if (user.insight_active_email || user.insight_active_chat) {
      if (user.insight_active_email) {
        if (counts.insight.email) {
          counts.insight.email += 1;
        } else {
          counts.insight.email = 1;
        }
      }
      if (user.insight_active_chat) {
        if (counts.insight.chat) {
          counts.insight.chat += 1;
        } else {
          counts.insight.chat = 1;
        }
      }
    } else {
      counts.insight['No integrations assigned'] += 1;
    }
    if (user.prevent_active_email || user.prevent_active_chat) {
      if (user.prevent_active_email) {
        if (counts.prevent.email) {
          counts.prevent.email += 1;
        } else {
          counts.prevent.email = 1;
        }
      } else if (user.prevent_active_chat) {
        if (counts.prevent.chat) {
          counts.prevent.chat += 1;
        } else {
          counts.prevent.chat = 1;
        }
      }
    } else {
      counts.prevent['No integrations assigned'] += 1;
    }

    if (user.teams && user.teams.length > 0) {
      user.teams?.forEach((t) => {
        if (counts.divisions[t.name]) {
          counts.divisions[t.name] += 1;
        } else {
          counts.divisions[t.name] = 1;
        }
      });
    } else {
      counts.divisions['No division assigned'] += 1;
    }
  });

  if (counts.roles['No role assigned'] === 0) {
    delete counts.roles['No role assigned'];
  }

  if (counts.insight['No integrations assigned'] === 0) {
    delete counts.insight['No integrations assigned'];
  }

  if (counts.prevent['No integrations assigned'] === 0) {
    delete counts.prevent['No integrations assigned'];
  }

  if (counts.divisions['No division assigned'] === 0) {
    delete counts.divisions['No division assigned'];
  }

  return counts;
};

type UsersResultCounts = {
  new: number;
  updated: number;
  total: number;
};

export const getUsersResultCounts: Selector<UsersResultCounts> = (state) => {
  const counts: UsersResultCounts = {
    new: 0,
    updated: 0,
    total: 0,
  };

  const { users } = state.ui.userPermissionsPage;

  users.forEach((user) => {
    if (!user.created_at) {
      counts.new += 1;
    } else if (user.modified) {
      counts.updated += 1;
    }
  });

  counts.total = users.length;

  return counts;
};

export const canSave: Selector<boolean> = (state) => {
  const counts = getUsersResultCounts(state);

  return counts.new > 0 || counts.updated > 0;
};

export const getCanApply: Selector<boolean> = (state) => {
  const teams = [...getIncludedTeams(state), ...getExcludedTeams(state)];

  const others = [
    ...getIncludedRoles(state),
    ...getExcludedRoles(state),
    ...getIncludedInsight(state),
    ...getExcludedInsight(state),
    ...getIncludedPrevent(state),
    ...getExcludedPrevent(state),
  ];

  return teams.length > 0 || others.length > 0;
};

export const getCanApplySaveSingleUser: Selector<boolean | null, string[]> = (
  state,
  saveInApply
) => {
  let user: User;

  if (saveInApply === 'true') {
    // eslint-disable-next-line prefer-destructuring
    user = getSelectedUsers(state)[0];
  } else {
    // eslint-disable-next-line prefer-destructuring
    user = getUsers(state)[0];
  }

  if (!user) return null;

  const name = getSelectedUserName(state);
  const email = getSelectedUserEmail(state);
  const privacy = getPrivacyLevel(state);
  const teams = getIncludedTeams(state);
  const roles = getIncludedRoles(state);
  const insight = getIncludedInsight(state);
  const prevent = getIncludedPrevent(state);

  if (user.name !== name) return true;
  if (user.email !== email) return true;
  if (user.privacy_level !== privacy) return true;

  if (teams.length !== user.teams?.length) return true;
  if (teams.some((team) => user.teams?.every((t) => team.uuid !== t.uuid))) return true;
  if (user.teams?.filter((t) => !teams.find((tA) => tA.uuid === t.uuid)).length) return true;

  const userTypes = userRolesToTypes(user.roles as Role[]).map((t) => t.label);
  if (saveInApply !== 'true') {
    if (roles.length !== userTypes?.length) return true;
    if (roles.some((role) => userTypes?.every((r) => role !== r))) return true;
    if (userTypes.some((role) => roles.every((r) => role !== r))) return true;
  } else {
    if (roles.length !== userTypes?.length) return true;
    if (roles.some((role) => userTypes?.every((r) => role !== r))) return true;
    if (userTypes.some((role) => roles.every((r) => role !== r))) return true;
  }

  if (insight.includes('email') && !user.insight_active_email) {
    return true;
  }
  if (insight.includes('chat') && !user.insight_active_chat) {
    return true;
  }
  if (!insight.includes('email') && user.insight_active_email) {
    return true;
  }
  if (!insight.includes('chat') && user.insight_active_chat) {
    return true;
  }

  if (prevent.includes('email') && !user.prevent_active_email) {
    return true;
  }
  if (prevent.includes('chat') && !user.prevent_active_chat) {
    return true;
  }
  if (!prevent.includes('email') && user.prevent_active_email) {
    return true;
  }
  if (!prevent.includes('chat') && user.prevent_active_chat) {
    return true;
  }

  return false;
};

export default getUsers;
