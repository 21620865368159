import { showSuccessAlert, updateCampaignRuleOutcomeConfig } from 'actions';
import Modal from 'components/Modal';
import React from 'react';
import ReactJson from 'react-json-view';
import { useDispatch } from 'react-redux';
import type { CampaignRuleOutcome } from 'types';

type ComponentProps = {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentConfig: React.Dispatch<React.SetStateAction<CampaignRuleOutcome['config'] | null>>;
  currentConfig: CampaignRuleOutcome['config'] | null;
  currentId: string | null;
};

const RenderJsonEditorModal: React.FC<ComponentProps> = ({
  setOpenModal,
  setCurrentConfig,
  currentConfig,
  currentId,
}) => {
  const dispatch = useDispatch();
  const handleEdit = (newConfig: CampaignRuleOutcome['config']): void => {
    if (currentConfig != null) setCurrentConfig({ ...currentConfig, ...newConfig });
  };

  const handleSaveAdvanced = (): void => {
    if (currentConfig != null && currentId != null) {
      dispatch(updateCampaignRuleOutcomeConfig({ uuid: currentId, config: currentConfig }));
      setOpenModal(false);
      dispatch(showSuccessAlert('Saved changes'));
    }
  };

  const renderJsonEditor = (
    <div className="px-5 py-4">
      <div className="flex items-center pb-2">
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.25685 1.09812C7.02185 -0.261875 8.97885 -0.261875 9.74285 1.09812L15.3228 11.0181C16.0728 12.3521 15.1098 13.9981 13.5808 13.9981H2.41985C0.889848 13.9981 -0.0731517 12.3521 0.676848 11.0181L6.25685 1.09812ZM8.99985 10.9991C8.99985 11.2643 8.89449 11.5187 8.70695 11.7062C8.51942 11.8938 8.26506 11.9991 7.99985 11.9991C7.73463 11.9991 7.48028 11.8938 7.29274 11.7062C7.10521 11.5187 6.99985 11.2643 6.99985 10.9991C6.99985 10.7339 7.10521 10.4796 7.29274 10.292C7.48028 10.1045 7.73463 9.99913 7.99985 9.99913C8.26506 9.99913 8.51942 10.1045 8.70695 10.292C8.89449 10.4796 8.99985 10.7339 8.99985 10.9991ZM7.99985 2.99913C7.73463 2.99913 7.48028 3.10448 7.29274 3.29202C7.10521 3.47955 6.99985 3.73391 6.99985 3.99913V6.99913C6.99985 7.26434 7.10521 7.5187 7.29274 7.70623C7.48028 7.89377 7.73463 7.99913 7.99985 7.99913C8.26506 7.99913 8.51942 7.89377 8.70695 7.70623C8.89449 7.5187 8.99985 7.26434 8.99985 6.99913V3.99913C8.99985 3.73391 8.89449 3.47955 8.70695 3.29202C8.51942 3.10448 8.26506 2.99913 7.99985 2.99913Z"
            fill="#F2994A"
          />
        </svg>

        <span className="text-sm ml-3">
          Caution: don’t edit these settings unless you know what you’re doing
        </span>
      </div>
      <ReactJson
        src={currentConfig ?? {}}
        theme="chalk"
        onEdit={(event): void => handleEdit(event.updated_src as CampaignRuleOutcome['config'])}
        style={{ paddingTop: '10px', paddingBottom: '25px' }}
      />
    </div>
  );

  return (
    <Modal
      title="Advanced View"
      toggleShowModal={(): void => setOpenModal(false)}
      body={renderJsonEditor}
      okButton
      okButtonText="Save"
      okButtonOnClick={handleSaveAdvanced}
    />
  );
};

export default RenderJsonEditorModal;
