import { addEventLabel, fetchEventLabels, removeEventLabel } from 'actions/events';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTags from 'react-tag-autocomplete';
import {
  addEventLabelLoading,
  getEventsLabels,
  getEventsLabelsLoading,
  getSingleEventLabels,
  removeEventLabelLoading,
} from 'selectors/events';
import 'styles/labelsStyle.css';
import type { UUID } from 'types';

type ComponentProps = {
  eventId: UUID;
};

const EventLabels: React.FC<ComponentProps> = ({ eventId }) => {
  const eventLabels = useSelector(getSingleEventLabels(eventId));
  const availableLabels = useSelector(getEventsLabels);
  const loadingFetch = useSelector(getEventsLabelsLoading);
  const loadingAdd = useSelector(addEventLabelLoading);
  const loadingRemove = useSelector(removeEventLabelLoading);
  const dispatch = useDispatch();

  const tags = eventLabels.map((label) => ({ id: label.value || '', name: label.value || '' }));
  const suggestions = availableLabels
    .filter((label) => eventLabels.every((eventLabel) => eventLabel.value !== label))
    .map((label) => ({ id: label, name: label }));

  useEffect(() => {
    dispatch(fetchEventLabels());
  }, [dispatch]);

  const handleDelete = (idx: number): void => {
    if (eventLabels[idx]) {
      dispatch(removeEventLabel({ eventId, label: eventLabels[idx].value as string }));
    }
  };

  const handleAddition = (tag: { name: string }): void => {
    dispatch(addEventLabel({ eventId, label: tag.name }));
  };

  const loadingView = (msg?: string): JSX.Element => (
    <div className="flex flex-row" data-testid="loader-container">
      {msg && <span className="text-xs p-1 self-center">{msg}</span>}
      <div className=" ml-3 loader ease-linear rounded-full border-2 border-gray-200 h-4 w-4 self-center" />
    </div>
  );

  return (
    <div className="flex flex-row">
      <ReactTags
        allowNew
        // placeholderText is not present on the types library but it is on the library docs
        // @ts-ignore
        placeholderText="Add new label"
        tags={tags}
        suggestions={suggestions}
        onDelete={handleDelete}
        onAddition={handleAddition}
        maxSuggestionsLength={25}
        minQueryLength={0}
      />
      {(loadingFetch || loadingAdd || loadingRemove) && loadingView()}
    </div>
  );
};

export default EventLabels;
