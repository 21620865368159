import { MutationFunction, QueryFunction, QueryKey } from '@tanstack/react-query';
import requestEndpoints from 'hooks/constants/client';
import type {
  FetcherParamsType,
  MutationVariables,
  RequestTypes,
  ResponseType,
} from 'hooks/types/client';

// any necessary here because we don't get the typing for the fetcher
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetcher: QueryFunction<any, QueryKey> = async ({
  queryKey,
}): Promise<ResponseType<keyof RequestTypes>> => {
  const [key, params] = queryKey as [keyof RequestTypes, FetcherParamsType];

  const { urlParams, queryParams, data } = params;

  const { id, ...otherUrlParams } = urlParams;

  let args = [];

  if (id) {
    args = [id, { urlParams: otherUrlParams, params: queryParams }];
  } else {
    args = [{ urlParams: otherUrlParams, params: queryParams, data }];
  }

  const [context, fnName] = requestEndpoints[key];

  const response = await context[fnName](...args);

  if (response.error) {
    throw response.error;
  } else {
    return response.data;
  }
};

// any necessary here because we don't get the typing for the fetcher
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mutationFetcher: MutationFunction<any, any> = async ({
  endpoint: key,
  params,
}: MutationVariables): Promise<ResponseType<keyof RequestTypes>> => {
  const { urlParams, queryParams, data } = params;

  const { id, ...otherUrlParams } = urlParams;

  let args = [];

  if (id) {
    args = [id, { urlParams: otherUrlParams, params: queryParams }];
  } else {
    args = [{ urlParams: otherUrlParams, params: queryParams, data }];
  }

  const [context, fnName] = requestEndpoints[key];

  const response = await context[fnName](...args);

  if (response.error) {
    throw response.error;
  } else {
    return response.data;
  }
};
