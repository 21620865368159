import daysAgo from 'components/utils/daysAgo';
import platform from 'constants/platform';
import React from 'react';
import type { Category } from 'types';
import { printStringArray } from 'utils/strings';

type ComponentProps = {
  category: Category;
};

const IdentifierCategoryItem: React.FC<ComponentProps> = ({ category }) => (
  <>
    <tr aria-hidden className="border-t border-gray-200 hover:bg-gray-100 table-row">
      <td className="table-wrapper__new-td">
        <div className="flex items-center text-body px-4">
          <div className="overflow-hidden">
            <div className="truncate font-bold">{category.rule?.name}</div>
          </div>
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="flex items-center text-body">
          <div className="overflow-hidden">
            <div className="truncate">{category.customer?.name}</div>
          </div>
        </div>
      </td>
      <td className="table-wrapper__new-td pr-1 py-1">
        <div className="text-body">
          {category.active_platforms &&
            printStringArray(
              Object.entries(platform).reduce<string[]>((acc, [key, value]) => {
                if (category[key as keyof typeof category]) {
                  return [...acc, value];
                }
                return acc;
              }, [])
            )}
        </div>
      </td>

      <td className="table-wrapper__new-td">
        <div className="text-body">{category.active_inbound ? 'Yes' : ' No'}</div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="text-body">{category.active_outbound ? 'Yes' : 'No'}</div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="text-body">{category.active_inbound ? 'Yes' : 'No'}</div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="text-body">
          {category.updated_at != null && daysAgo(category.updated_at)}
        </div>
      </td>

      <td className="table-wrapper__new-td">
        <div className="text-body">{category.updated_by && `By ${category.updated_by.name}`}</div>
      </td>
    </tr>
  </>
);

export default IdentifierCategoryItem;
