import envelopeReviewLabelOptions from 'components/utils/EventOptions';
import { createSelector } from 'reselect';
import type { ReviewValueConfigType, Selector } from 'types';
import { getReviewValueLabels } from './auth';

type NonNullReviewValueConfigType = {
  [P in keyof ReviewValueConfigType]-?: NonNullable<ReviewValueConfigType[P]>;
};

const getEnvelopeReviewLabelOptions: Selector<Partial<NonNullReviewValueConfigType>> =
  createSelector(
    [(state): ReviewValueConfigType | null => getReviewValueLabels(state)],
    (reviewValues) => {
      if (reviewValues) {
        return Object.fromEntries(Object.entries(reviewValues).filter(([, v]) => v != null));
      }

      return Object.fromEntries(
        Object.entries(envelopeReviewLabelOptions).filter(([, v]) => v != null)
      );
    }
  );

export const getEnvelopeReviewLabelOptionsMemoized: Selector<
  Partial<NonNullReviewValueConfigType>
> = createSelector(getReviewValueLabels, (reviewValues) => {
  if (reviewValues) {
    return Object.fromEntries(Object.entries(reviewValues).filter(([, v]) => v != null));
  }

  return Object.fromEntries(
    Object.entries(envelopeReviewLabelOptions).filter(([, v]) => v != null)
  );
});

export const getReviewLabelOptionsForDashboard: Selector<{ uuid: string; name: string }[]> =
  createSelector([getEnvelopeReviewLabelOptions], (reviewValues) =>
    Object.entries(reviewValues).map(([k, v]) => ({ uuid: k, name: v }))
  );

export const getHasOneReviewOption: Selector<boolean> = (state) => {
  const reviewValues = getReviewValueLabels(state);
  let options: Partial<ReviewValueConfigType>;

  if (reviewValues) {
    options = Object.fromEntries(Object.entries(reviewValues).filter(([, v]) => v != null));
  } else {
    options = envelopeReviewLabelOptions;
  }

  const statusOptions = Object.entries(options)
    .filter(([key]) => key !== 'pending' && key !== 'skipped')
    .map(([key, value]) => ({ label: value, value: key }));

  return statusOptions.length === 1;
};

export default getEnvelopeReviewLabelOptions;
