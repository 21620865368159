/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { cloneCampaign } from 'actions';
import CustomerSimpleList from 'components/CustomerSimpleList';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import PageHeader from 'components/PageHeader';
import Permissions from 'components/Permissions';
import SummarySection from 'components/SummarySection';
import { INDENTATION_ICON, NEW_EDIT_ICON } from 'constants/commonIcons';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getCampaign, getUpsertCampaignLoading } from 'selectors/campaigns';
import { getTestCaseSummary } from 'selectors/testCases';
import { useSelector } from 'store';
import EditCampaignModal from './EditCampaignModal';

type RouterParams = { ruleId: string; campaignId: string };

const CampaignHeader: React.FC = () => {
  const { campaignId } = useParams<RouterParams>();
  const campaign = useSelector(getCampaign(campaignId));
  const loading = useSelector(getUpsertCampaignLoading);
  const summary = useSelector(getTestCaseSummary);

  const [isFullDescription, setIsFullDescription] = useState(false);
  const [clampedDescription, setClampedDescription] = useState(false);

  const [isEditingCampaign, setIsEditingCampaign] = useState(false);
  const [isCloneCampaignOpen, setIsCloneCampaignOpen] = useState(false);

  const descriptionRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        const newC = node.scrollHeight > node.clientHeight + 2;
        setClampedDescription(newC);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [campaign, isFullDescription]
  );

  const toggleIsCloneCampaignOpen = (): void => {
    setIsCloneCampaignOpen(!isCloneCampaignOpen);
  };

  const handleClinkOnEdit = (): void => {
    setIsEditingCampaign(true);
  };
  const toggleEditModal = (): void => setIsEditingCampaign(false);

  const renderSummary = (): JSX.Element => <SummarySection summary={summary} renderTotalTests />;

  const renderCampaignScreen = (
    <CustomerSimpleList
      toggleShowCloneResourceModalOpen={toggleIsCloneCampaignOpen}
      resourceId={campaignId}
      cloneResource={cloneCampaign}
    />
  );

  if (!campaign) return null;

  return (
    <>
      <header>
        <div className="px-6">
          <div className="flex flex-col gap-1">
            <div className="flex flex-row justify-between items-baseline">
              <div className="flex w-full">
                <PageHeader />
              </div>
              <div className="flex justify-end gap-2 w-full  h-8">
                <Permissions action="campaigns.clone">
                  <button
                    type="button"
                    onClick={toggleIsCloneCampaignOpen}
                    className="button button--secondary h-8"
                    data-testid="clone-campaign-button"
                  >
                    <span className="px-2 font-bold">Duplicate</span>
                  </button>
                </Permissions>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex items-center w-3/5">
                <div className="flex flex-row items-baseline gap-2">
                  <span className="text-heading-2 break-words">{campaign.name}</span>
                  <button
                    type="button"
                    className="flex-none w-5 self-baseline focus:outline-none"
                    onClick={handleClinkOnEdit}
                  >
                    {NEW_EDIT_ICON('text-litlingo-primary-120')}
                  </button>
                  <span className="flex-none text-small">
                    Use Case:{' '}
                    {campaign.active ? (
                      <span className="text-litlingo-success">Active</span>
                    ) : (
                      <span className="text-litlingo-gray-5">Inactive</span>
                    )}
                  </span>
                  {loading && <LoadingIndicator />}
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between items-stretch mt-1">
              <div className="flex flex-col h-full justify-start gap-2 w-3/5">
                {campaign && campaign.description && (
                  <div
                    className={`${
                      isFullDescription ? 'break-words' : 'flex flex-row break-all'
                    } text-body w-80ch`}
                  >
                    <span
                      ref={descriptionRef}
                      className={`description ${isFullDescription ? '' : 'clamped-description'}`}
                    >
                      {campaign.description}
                    </span>
                    {(clampedDescription || isFullDescription) && (
                      <button
                        className={`text-body focus:outline-none ${
                          isFullDescription ? 'ml-2' : ''
                        }`}
                        type="button"
                        onClick={(): void => setIsFullDescription((v) => !v)}
                      >
                        <span className="underline text-litlingo-primary whitespace-no-wrap">
                          {isFullDescription ? 'Less' : 'More'}
                        </span>
                      </button>
                    )}
                  </div>
                )}
                {campaign.rules && (
                  <div className="flex flex-row gap-2 pl-6 text-small">
                    {INDENTATION_ICON}
                    <span className="flex gap-1">Uses {campaign.rules.length} Models</span>
                  </div>
                )}
              </div>
              <div className="self-end">{renderSummary()}</div>
            </div>
          </div>
        </div>
      </header>

      {isCloneCampaignOpen && (
        <Modal
          body={renderCampaignScreen}
          title="Choose a workspace"
          okButton
          okButtonText="Done"
          okButtonOnClick={toggleIsCloneCampaignOpen}
          toggleShowModal={toggleIsCloneCampaignOpen}
        />
      )}

      {isEditingCampaign && (
        <EditCampaignModal
          isModalOpen={isEditingCampaign}
          toggleModalOpen={toggleEditModal}
          campaignToEdit={campaign}
        />
      )}
    </>
  );
};

export default CampaignHeader;
