import { AnnotatedGraph, HighlightedSentence } from '@litlingo/react';
import { INDENTATION_ICON } from 'constants/commonIcons';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { CampaignRulesViolated } from 'selectors/communications';
import { getPermissionsPolicy } from 'selectors/permissions';
import { getFieldAuthorization } from 'utils/permissions';
import { useHistory } from 'utils/urls';

type ComponentProps = {
  resultLines: AnnotatedGraph[];
  classes?: string;
  campaignRulesViolated: Record<string, CampaignRulesViolated>;
  noLink?: boolean;
};

const SentencesViolatedSidebarSection: React.FC<ComponentProps> = ({
  classes,
  resultLines,
  campaignRulesViolated,
  noLink = false,
}) => {
  const history = useHistory();
  const customerDomain = useSelector(getCustomerDomain);

  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const entityFieldLevel = getFieldAuthorization('communications.entity', user.roles, policy);

  const selectCampaign = (campaignId: string): void => {
    if (noLink) return;
    history.pushLookup({
      routeName: 'campaign-detail',
      routeParams: { campaignId },
      customerDomain,
    });
  };

  const selectRuleCampaign = (ruleId: string, campaignId: string): void => {
    if (noLink) return;
    history.pushLookup({
      routeName: 'rule-manager-campaign',
      routeParams: { ruleId, campaignId },
      customerDomain,
    });
  };

  const renderResults = (): JSX.Element => {
    if (campaignRulesViolated && Object.keys(campaignRulesViolated).length > 0) {
      return (
        <div>
          {Object.values(campaignRulesViolated)
            .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
            .map((campaign) => (
              <div className="flex flex-col gap-1" key={campaign.id}>
                <button
                  type="button"
                  className="flex flex-col focus:outline-none hover:underline"
                  data-testid={`campaign-button-${campaign.id}`}
                  onClick={(): void => selectCampaign(campaign.id)}
                >
                  <span className="text-left">{campaign.label}</span>
                </button>

                <div className="flex flex-col">
                  {campaign.rules.sort().map((rule) => (
                    <div key={rule.id} className="flex flex-row items-center gap-2 mb-1 pl-2">
                      <div className="self-start">{INDENTATION_ICON}</div>

                      <button
                        type="button"
                        className="flex focus:outline-none hover:underline"
                        data-testid={`rule-button-${rule.id}`}
                        onClick={(): void => selectRuleCampaign(rule.id, campaign.id)}
                      >
                        <span className="w-full text-left">{rule.label}</span>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      );
    }

    return (
      <div className={`w-full w-full flex flex-row items-center gap-2 ${classes}`}>
        <svg
          className="h-5 w-5 text text--bold"
          fill="#333333"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          viewBox="-0.5 0 22 20"
          stroke="#333333"
        >
          <path d="M0 10a10 10 0 1120 0 10 10 0 01-20 0zm16.32-4.9L5.09 16.31A8 8 0 0016.32 5.09zm-1.41-1.42A8 8 0 003.68 14.91L14.91 3.68z" />
        </svg>
        <span className="text-heading-2">No models matched</span>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      {resultLines.map((line, idx) => (
        <div key={line.index} className={idx !== 0 ? 'mt-2' : ''}>
          <HighlightedSentence
            nodes={line.nodes}
            useHover={user.roles.includes('super-admin')}
            protectedFields={{
              entity: entityFieldLevel,
            }}
            portal="tooltip-portal"
          />
        </div>
      ))}
      <div className="w-full flex flex-col p-4 bg-litlingo-gray-0 overflow-hidden text-small gap-2">
        {renderResults()}
      </div>
    </div>
  );
};

export default SentencesViolatedSidebarSection;
