/* eslint-disable camelcase */
/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { continueAssignment, openSelectedSkippedAssigment } from 'actions/assignments';
import { setEnvelopePreview } from 'actions/envelopeListView';
import LinkLookup from 'components/LinkLookup';
import { concatFromQueryParam } from 'constants/eventsUtils';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TableComponents } from 'react-virtuoso';
import { getShowSkippedEnvelopes, getUsersSingleAssigment } from 'selectors/assignments';
import {
  envelopeListContentState,
  getStateFromAssignments,
  getTaggingEnvelope,
} from 'selectors/envelopeListView';
import { useSelector } from 'store';
import type { CommunicationEnvelopeSummary } from 'types';
import logEvent from 'utils/analytics';

const EnvelopeListCondensedRowWrapper: TableComponents<CommunicationEnvelopeSummary>['TableRow'] =
  ({ item, children, style, ...rest }) => {
    const location = useLocation();
    const dispatch = useDispatch();

    const comingFromAssignments = useSelector(getStateFromAssignments);
    const showSkippedEnvelopes = useSelector(getShowSkippedEnvelopes);
    const assignment = useSelector(getUsersSingleAssigment);
    const { loadingAll } = useSelector(envelopeListContentState);
    const isTaggingEnvelope = useSelector(getTaggingEnvelope);

    const { envelope } = item;

    const handleFromAssignment = (): void => {
      if (comingFromAssignments?.assignmentUuid) {
        dispatch(continueAssignment({ uuid: comingFromAssignments?.assignmentUuid }));
      }
    };

    const qp = new URLSearchParams(location.search);

    if (item.page_id) {
      qp.set('envelopes__page_id', item.page_id);
      qp.set('threads__entry_point', envelope.uuid);
    }

    const queryParams = concatFromQueryParam(
      qp.toString(),
      location.pathname,
      resourceQueryParamName.envelopes
    );

    const handleSkippedEnvelopeClick = (): void => {
      if (assignment) {
        dispatch(
          openSelectedSkippedAssigment({
            assignmentId: assignment?.uuid,
            envelopeId: envelope.uuid,
          })
        );
      }
    };

    return (
      <LinkLookup
        as="tr"
        routeName="envelope-detail"
        routeParams={{ envelopeId: item.envelope.uuid }}
        queryParams={`${queryParams}`}
        id={`envelope-${envelope.uuid}`}
        className="table-wrapper__new-tr table-row envelope-id-class align-top"
        style={{ ...style, height: '36px', cursor: loadingAll ? 'default' : 'pointer' }}
        item-testid="envelope-row"
        preventNavigation={(): boolean => window.getSelection()?.type === 'Range' || loadingAll}
        onClick={(): void => {
          logEvent('envelopes-list-envelope-details');
          dispatch(setEnvelopePreview(null));
        }}
        alternativeNavigation={handleFromAssignment}
        alternativeEvaluation={comingFromAssignments?.assignmentUuid}
        skippedEnvelopes={showSkippedEnvelopes}
        isTaggingEnvelope={isTaggingEnvelope}
        skippedEnvelopeNavigation={handleSkippedEnvelopeClick}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {children}
      </LinkLookup>
    );
  };

export const MemoizedEnvelopeListCondensedRowWrapper = React.memo(EnvelopeListCondensedRowWrapper);

export default EnvelopeListCondensedRowWrapper;
