/* eslint-disable no-case-declarations */
import { setURLParams } from 'actions';
import MultiSelect from 'components/MultiSelect';
import { FULL_DATE_FORMAT } from 'constants/formats';
import moment from 'moment';
import mz from 'moment-timezone';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getActiveUuid, getDashboardState, getUser } from 'selectors/dashboard';
import { useSelector } from 'store';
import { TIME_RANGE_FIELD } from 'utils/timeRanges';

const OutOfRetentionFilter: React.FC = () => {
  const options = {
    '1': 'Tomorrow',
    '7': 'This week',
    '30': 'This month',
  };

  const dispatch = useDispatch();

  const [retention, setRetention] = useState('');

  const activeUuid = useSelector(getActiveUuid) || '';
  const dashboardState = useSelector(getDashboardState(activeUuid));
  const user = useSelector(getUser);

  const handleChange = (key: string): void => {
    if (key === retention) {
      dispatch(setURLParams({ [TIME_RANGE_FIELD]: '' }));
      setRetention('');
      return;
    }

    setRetention(key);
    const retentionDays = user.customer?.config.communication_retention_days || 60;

    const timeOor = Number(key);

    const retentionStart = moment().subtract(retentionDays, 'days');

    const afterDate = retentionStart.toDate();

    // @ts-ignore
    const tz = mz.tz(dashboardState.primary_timezone);
    const marker = tz.local().startOf('day');

    let beforeDate;
    switch (timeOor) {
      case 1: // Tomorrow
        beforeDate = moment(marker).add(2, 'days').subtract(retentionDays, 'days').toDate();
        break;
      case 7: // This week
        beforeDate = moment(marker)
          .startOf('week')
          .add(1, 'weeks')
          .subtract(retentionDays, 'days')
          .toDate();
        break;
      case 30: // This month
        const monthsBeforeDate = moment(marker)
          .startOf('month')
          .add(1, 'months')
          .subtract(retentionDays, 'days')
          .toDate();
        const weeksBeforeDate = moment(marker)
          .startOf('week')
          .add(1, 'weeks')
          .subtract(retentionDays, 'days')
          .toDate();
        beforeDate =
          weeksBeforeDate >= monthsBeforeDate
            ? moment(marker)
                .startOf('month')
                .add(2, 'months')
                .subtract(retentionDays, 'days')
                .toDate()
            : monthsBeforeDate;
        break;
      default:
        beforeDate = moment(retentionStart).add(timeOor, 'days').toDate();
    }

    dispatch(
      setURLParams({
        [TIME_RANGE_FIELD]: `${moment(afterDate).format(FULL_DATE_FORMAT)};${moment(
          beforeDate
        ).format(FULL_DATE_FORMAT)}`,
      })
    );
  };

  return (
    <div className="mt-1">
      <MultiSelect
        options={options}
        onChange={handleChange}
        selectedItems={[retention]}
        notSelectedItems={[]}
        labelClasses="text-white font-normal text-xss"
      />
    </div>
  );
};

export default OutOfRetentionFilter;
