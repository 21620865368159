import type { PartialConfig } from './base';

const API_BASE = 'https://dev.litlingo.com';

const development: PartialConfig = {
  api: {
    base: API_BASE,
    v1: `${API_BASE}/api/v1`,
    v2: `${API_BASE}/api/v2`,
    integrations: `${API_BASE}/api/v1/integrations`,
    customers: `${API_BASE}/api/v2/users/me/customers`,
    user: `${API_BASE}/api/v2/users/me`,
  },
};

export default development;
