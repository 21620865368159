import { createRuleGroup } from 'actions/ruleGroup';
import Modal from 'components/Modal';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
};

const CreateRuleGroupModal: React.FC<ComponentProps> = ({ isModalOpen, toggleModalOpen }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');

  const body = (
    <div className=" flex flex-col mb-4 h-full text-body">
      <label htmlFor="name">
        <span>
          Name<span className="text-litlingo-alert">*</span>
        </span>
        <input
          id="name"
          type="text"
          className="form-input h-8 w-full text-litlingo-gray-6 text-body"
          onChange={(e): void => setName(e.target.value)}
          value={name}
        />
      </label>
    </div>
  );

  const handleCreateButton = (): void => {
    dispatch(createRuleGroup({ name }));
    toggleModalOpen();
  };

  const handleCancelReplace = (): void => {
    toggleModalOpen();
  };

  return (
    <>
      {isModalOpen && (
        <Modal
          title="Create Model"
          body={body}
          toggleShowModal={toggleModalOpen}
          style={{ width: '37.5rem' }}
          okButton
          okButtonText="Create"
          okButtonDisabled={!name}
          okButtonOnClick={handleCreateButton}
          cancelButton
          cancelButtonOnclick={handleCancelReplace}
          xButton
        />
      )}
    </>
  );
};

export default CreateRuleGroupModal;
