/* eslint-disable import/prefer-default-export */
import { SavedSearch } from '@litlingo/client';

const priorityValues = {
  high: 3,
  medium: 2,
  low: 1,
};

const typeValues = {
  inbox_0: 2,
  assignment: 1,
};

export const recordCountSort = (a: SavedSearch, b: SavedSearch): number => {
  if (a.record_count > 0 && b.record_count <= 0) return -1;
  if (b.record_count > 0 && a.record_count <= 0) return 1;
  return 0;
};

export const prioritySort = (a: SavedSearch, b: SavedSearch): number => {
  if (!a.config || !a.priority) return 1;
  if (!b.config || !b.priority) return -1;

  const prioA = priorityValues[a.priority];
  const prioB = priorityValues[b.priority];

  if (prioA > prioB) return -1;
  if (prioA < prioB) return 1;

  return 0;
};

export const typeSort = (a: SavedSearch, b: SavedSearch): number => {
  if (!a.config) return -1;
  if (!b.config) return 1;

  const typeA = typeValues[a.config.default_count === -1 ? 'inbox_0' : 'assignment'];
  const typeB = typeValues[b.config.default_count === -1 ? 'inbox_0' : 'assignment'];

  if (typeA > typeB) return -1;
  if (typeA < typeB) return 1;
  return 0;
};

export const savedSearchSort = (a: SavedSearch, b: SavedSearch): number => {
  const recordCountResult = recordCountSort(a, b);
  if (recordCountResult !== 0) return recordCountResult;

  const priorityResult = prioritySort(a, b);
  if (priorityResult !== 0) return priorityResult;

  return typeSort(a, b);
};
