import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import SearchInput from 'components/SearchInput';
import TagsList from 'components/TagsList';
import TagCount from 'components/TagsList/TagCount';
import TagForm from 'components/TagsManager/TagForm';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getDeleteTagLoading, getFetchAllTagsLoading, getUpsertTagLoading } from 'selectors/tags';
import type { Tag } from 'types';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';

const TagsManager: React.FC = () => {
  const [selectedTag, setSelectedTag] = useState<Tag>({} as Tag);
  const [toggleForm, setToggleForm] = useState<boolean>(false);

  const upsertLoading = useSelector(getUpsertTagLoading);
  const deleteLoading = useSelector(getDeleteTagLoading);
  const fetchLoading = useSelector(getFetchAllTagsLoading);

  const handleChangeTag = (tag: Tag): void => {
    setSelectedTag(tag);
    setToggleForm(true);
  };

  const handleNewTag = (): void => {
    handleChangeTag({
      value: '',
      description: '',
      color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    } as Tag);
  };

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <div
      className="min-w-screen bg-white"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />
      <div
        className="flex flex-row"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <SettingSidebar />
        <div className="px-6 py-4 overflow-auto">
          <div>
            <header>
              <div className="max-w-7xl">
                <div className="flex items-center gap-2">
                  <div
                    role="button"
                    tabIndex={0}
                    className="border-b-4 border-litlingo-orange text-menu-item cursor-pointer pb-1 px-2"
                  >
                    All Tags
                  </div>
                  <LinkLookup
                    routeName="tag-groups"
                    className="border-b-4 border-transparent text-menu-item cursor-pointer pb-1 px-2"
                  >
                    Tag Groups
                  </LinkLookup>
                </div>
                <div>
                  <div className="flex flex-row items-center mt-6">
                    <h2 className="text-title">All Tags</h2>
                    {(upsertLoading || deleteLoading || fetchLoading) && (
                      <LoadingIndicator className="ml-2" size="5" />
                    )}
                  </div>

                  <div className="flex justify-between mt-2 h-8">
                    <div className="flex gap-4">
                      <SearchInput
                        resource={resourceQueryParamName.tags}
                        field="broad_search"
                        placeholder="Search tags"
                      />

                      <button
                        onClick={handleNewTag}
                        type="button"
                        className="button button--primary text-button rounded whitespace-no-wrap"
                      >
                        Create New Tag
                      </button>
                    </div>
                    <div className="self-end">
                      <TagCount />
                    </div>
                  </div>

                  <div className={`${toggleForm ? 'mt-4' : ''}`}>
                    <TagForm
                      tag={selectedTag}
                      toggleForm={toggleForm}
                      setToggleForm={setToggleForm}
                    />
                  </div>
                </div>
              </div>
            </header>
          </div>
          <main>
            <div className="mt-4 mb-10 max-w-7xl">
              <TagsList handleChangeTag={handleChangeTag} />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default TagsManager;
