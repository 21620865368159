import { fetchSavedSearches } from 'actions';
import LinkLookup from 'components/LinkLookup';
import withWidget from 'decorators/widget';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSavedSearchesList, getSavedSearchesLoading } from 'selectors/savedSearches';
import logEvent from 'utils/analytics';

const SavedSearches: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getSavedSearchesLoading);
  const savedSearches = useSelector(getSavedSearchesList);

  useEffect(() => {
    dispatch(fetchSavedSearches({ limit: 5, is_starred: true }));
  }, [dispatch]);

  if (loading) {
    return <WidgetLoading />;
  }

  return (
    <div>
      <div className="flow-root">
        <ul className="-my-5 divide-y divide-gray-200">
          {savedSearches.slice(0, 3).map((savedSearch) => (
            // eslint-disable-next-line react/jsx-indent
            <li key={savedSearch.uuid} className="py-5">
              <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                <h3 className="text-sm font-semibold text-gray-800">
                  <LinkLookup
                    routeName="envelope-list"
                    queryParams={savedSearch.url}
                    className="hover:underline focus:outline-none text--primary"
                    onClick={(): void => {
                      logEvent(`Dashboard-saved-search-${savedSearch.name}`);
                    }}
                  >
                    {/* Extend touch target to entire panel */}
                    <span className="absolute inset-0" aria-hidden="true" />
                    {savedSearch.name} (~{savedSearch.record_count})
                  </LinkLookup>
                </h3>
                <p className="mt-1 text-sm text-gray-600 line-clamp-2">{savedSearch.description}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-6">
        <LinkLookup
          className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          routeName="saved-searches"
          onClick={(): Promise<void> => logEvent('Dashboard-saved-search-view-all')}
        >
          View all
        </LinkLookup>
      </div>
    </div>
  );
};

export default withWidget({
  title: 'Saved Searches',
  subtitle: 'Predefined searches to help you navigate the events better',
  minWidth: 3,
  minHeight: 10,
})(SavedSearches);
