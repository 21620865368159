import React from 'react';

export const operatorName = {
  AND: 'AND',
  OR: 'OR',
  RELATIONSHIP_MATCH: 'Same Sentence',
  NOT_AND: 'NOT ALL',
  NOT_OR: 'NOT ANY',
  NOT_RELATIONSHIP_MATCH: 'NOT Same Sentence',
};

export const operatorAcceptsOperatorChildren = {
  AND: 'AND',
  OR: 'OR',
  NOT_AND: 'NOT ALL',
  NOT_OR: 'NOT ANY',
};

export const operatorNameNot = {
  AND: 'NOT_AND',
  OR: 'NOT_OR',
  RELATIONSHIP_MATCH: 'NOT_RELATIONSHIP_MATCH',
} as const;

export const operatorNameButtons = {
  AND: 'all',
  OR: 'any',
  RELATIONSHIP_MATCH: 'ss',
};

export const ItemTypes = {
  OPERATOR: 'operator',
  ANNOTATOR: 'annotator',
};

export const modifiedIcon = (
  <div className="h-5 w-5 warning-color">
    <svg fill="currentColor" strokeWidth="2" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="12" />
    </svg>
  </div>
);

export const relationshipIcon = (disabled: boolean, noneUtilization = false): JSX.Element => {
  const getTextColor = (): string => {
    if (noneUtilization && disabled) return 'text-litlingo-gray-4';
    if (disabled) return 'text-litlingo-gray-3';
    return 'text-litlingo-gray-6';
  };
  return (
    <div className={`h-5 w-6 pr-2 `}>
      <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        viewBox="-0.5 -0.5 34 24"
        stroke="currentColor"
        className={`${getTextColor()}`}
      >
        <path d="M31 21H6q-5 0-5-5V1" />
      </svg>
    </div>
  );
};

export const indentation =
  '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0';

const getAuxText = (operator: keyof typeof operatorName): string => {
  if (operator === 'AND') return ' all of:';
  if (operator === 'OR') return ' any of:';

  return ' of:';
};

export const formattedName = (
  name: string,
  disabled?: boolean,
  highlightedIdentifier = false,
  noneUtilization = false
): JSX.Element | string => {
  const casted = name as keyof typeof operatorName;
  const textColor = (): string => {
    if (highlightedIdentifier) return 'text-litlingo-white';
    if (noneUtilization && disabled) return 'text-litlingo-gray-4';
    if (disabled) return 'text-litlingo-gray-3';
    return 'text-litlingo-gray-6';
  };

  if (operatorName[casted]) {
    return (
      <span className={`${textColor()}`}>
        <b>{operatorName[casted]}</b>
        {getAuxText(casted)}
      </span>
    );
  }

  return <span className={`${textColor()}`}>{name}</span>;
};

export const previewImage = (text: string): HTMLImageElement => {
  // @ts-ignore
  const elem: HTMLCanvasElement = document.createElementNS(
    'http://www.w3.org/1999/xhtml',
    'canvas'
  );
  elem.height = 30;
  const ctx = elem.getContext('2d');
  if (ctx != null) {
    ctx.font = '20px Liberation Sans';
    ctx.textAlign = 'center';
    ctx.fillText(text, elem.width / 2, elem.height / 2 + 5);
    ctx.strokeRect(0, 0, elem.width, elem.height);
  }
  const canvasImg = new Image();
  canvasImg.src = elem.toDataURL();

  return canvasImg;
};
