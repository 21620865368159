import { NEW_EDIT_ICON } from 'constants/commonIcons';
import React from 'react';
import { useSelector } from 'react-redux';
import { getSelectedUser } from 'selectors/userProfilePage';
import { printStringArray } from 'utils/strings';
import { userRolesToTypes } from 'utils/userRoles';
import renderInsight, { renderPrevent } from 'utils/userStrings';

type ComponentProps = {
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

const UserProfileCard: React.FC<ComponentProps> = ({ setIsEditing }) => {
  const user = useSelector(getSelectedUser);

  if (!user) return null;

  const handleClickOnEdit = (): void => {
    setIsEditing(true);
  };

  return (
    <div className="flex flex-col gap-8 p-5 border border-litlingo-gray-2 rounded w-112">
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-row gap-2.5 w-9/10">
          {user.name && (
            <span className="flex h-12 min-w-12 justify-center items-center rounded-full text-heading-1 bg-litlingo-warning-light">
              {user.name[0].toUpperCase()}
            </span>
          )}
          <div className="flex flex-col gap-1 w-full overflow-hidden">
            <span className="text-heading-2 truncate">{user.name}</span>
            <span className="truncate">{user.email}</span>
          </div>
        </div>
        <button
          type="button"
          className="flex-none w-5 self-start focus:outline-none"
          onClick={handleClickOnEdit}
        >
          {NEW_EDIT_ICON('text-litlingo-primary-120')}
        </button>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 text-body">
          <span className="font-bold">Divisions</span>
          <span className="pl-4 ">
            {user.teams && user.teams.length > 0
              ? printStringArray(
                  user.teams?.map((t) => t.name),
                  true
                )
              : 'No division assigned'}
          </span>
        </div>
        <div className="flex flex-col gap-2 text-body">
          <span className="font-bold">Role</span>
          <span className="pl-4">
            {userRolesToTypes(user.roles).length > 0
              ? printStringArray(
                  userRolesToTypes(user.roles).map((t) => t.label),
                  true
                )
              : 'No role assigned'}
          </span>
        </div>
        <div className="flex flex-col gap-2 text-body">
          <span className="font-bold">Insight Integrations</span>
          <span className="pl-4">{renderInsight(user, 'No integrations found')}</span>
        </div>
        <div className="flex flex-col gap-2 text-body">
          <span className="font-bold">Prevent Integrations</span>
          <span className="pl-4">{renderPrevent(user, 'No integrations found')}</span>
        </div>
      </div>
    </div>
  );
};

export default UserProfileCard;
