import type { PayloadAction } from '@reduxjs/toolkit';
import {
  authError,
  authGmail,
  authRequest,
  authSuccess,
  checkLoginRedirect,
  checkLoginRedirectFailure,
  checkLoginRedirectRequest,
  checkLoginRedirectSuccess,
  createCustomer,
  createCustomerFailure,
  createCustomerRequest,
  createCustomerSuccess,
  createNewSandbox,
  createNewSandboxFailure,
  createNewSandboxRequest,
  createNewSandboxSuccess,
  downloadCustomerDocument,
  downloadCustomerDocumentFailure,
  downloadCustomerDocumentFulfill,
  downloadCustomerDocumentSuccess,
  exportUsers,
  exportUsersFailure,
  exportUsersFulfill,
  exportUsersRequest,
  exportUsersSuccess,
  fetchAllCustomers,
  fetchAllSandboxes,
  fetchAllSandboxesFailure,
  fetchAllSandboxesRequest,
  fetchAllSandboxesSuccess,
  fetchCustomerChangelog,
  fetchCustomersFailure,
  fetchCustomersMe,
  fetchCustomersMeFailure,
  fetchCustomersMeFulfill,
  fetchCustomersMeRequest,
  fetchCustomersMeSuccess,
  fetchCustomersRequest,
  fetchCustomersSuccess,
  fetchIndexesComms,
  fetchIndexesCommsFailure,
  fetchIndexesCommsRequest,
  fetchIndexesCommsSuccess,
  fetchIndexingStatus,
  fetchIndexingStatusFailure,
  fetchIndexingStatusRequest,
  fetchIndexingStatusSuccess,
  fetchIProcessingStatusFailure,
  fetchLogMetrics,
  fetchLogMetricsFailure,
  fetchLogMetricsRequest,
  fetchLogMetricsSuccess,
  fetchProcessingStatus,
  fetchProcessingStatusRequest,
  fetchProcessingStatusSuccess,
  fetchReindexCommsRequest,
  fetchReprocessCommsRequest,
  fetchSingleCustomer,
  fetchSingleCustomerFailure,
  fetchSingleCustomerRequest,
  fetchSingleCustomerSuccess,
  fetchUserMetadata,
  fetchZendesk,
  fetchZendeskFailure,
  fetchZendeskRequest,
  fetchZendeskSuccess,
  gmailVerifyCredentials,
  gmailVerifyCredentialsFailure,
  gmailVerifyCredentialsRequest,
  gmailVerifyCredentialsSuccess,
  logoutUser,
  logoutUserFulfill,
  logoutUserRequest,
  navigateToOriginalComm,
  o365VerifyCredentials,
  o365VerifyCredentialsFailure,
  o365VerifyCredentialsRequest,
  o365VerifyCredentialsSuccess,
  redirectToLitlingoLogin,
  redirectToLitlingoLoginFailure,
  redirectToLitlingoLoginRequest,
  reindexComms,
  reprocessComms,
  saveCustomer,
  saveCustomerFailure,
  saveCustomerRequest,
  saveCustomerSuccess,
  selectCustomer,
  selectCustomerAndRedirect,
  selectCustomerCloneComm,
  selectCustomerCloneCommFailure,
  selectCustomerCloneCommRequest,
  selectCustomerCloneCommSuccess,
  selectCustomerFailure,
  selectCustomerFulfill,
  selectCustomerRequest,
  selectCustomerSuccess,
  setUserAmplitude,
  showErrorAlert,
  showSuccessAlert,
  spoofRoles,
  spoofRolesFailure,
  spoofRolesRequest,
  spoofRolesSuccess,
  verifyAuth,
  verifyFailure,
  verifyRequest,
  verifySuccess,
  zendeskVerifyCredentials,
  zendeskVerifyCredentialsFailure,
  zendeskVerifyCredentialsRequest,
  zendeskVerifyCredentialsSuccess,
} from 'actions';
import { updatePermissionsPolicy } from 'actions/permissions';
import { baseClient, authClient as LitlingoAuthClient, apiClient as LitLingoClient } from 'client';
import config from 'config';
import { getLocation, push } from 'connected-react-router';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import type { Location } from 'history';
import type { GlobalState } from 'reducers';
import {
  fetchChangelogFailure,
  fetchChangelogFulfill,
  fetchChangelogRequest,
  fetchChangelogSuccess,
} from 'reducers/auditLogs';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { getCustomerId, getUser } from 'selectors/auth';
import { getNavParams, getNavParamsByResource } from 'selectors/nav';
import type { API, Customer, ErrorObject, SagaReturn, User } from 'types';
import { setUser } from 'utils/reports';
import { reverse } from 'utils/urls';

function* spoofRolesSaga(action: ReturnType<typeof spoofRoles>): SagaReturn {
  try {
    yield put(spoofRolesRequest());
    const { payload } = action;
    const { userUuid } = payload;
    yield call([LitlingoAuthClient, 'selectSpoofUser'], userUuid as string);
    yield put(spoofRolesSuccess());
    const user = (yield select(getUser)) as ReturnType<typeof getUser>;
    if (user.customer?.domain) {
      yield put(verifyAuth({ domain: user.customer?.domain }));
      yield put(push(`/${user.customer?.domain}`));
    }
  } catch (e) {
    const err = e as ErrorObject;
    yield put(spoofRolesFailure({ message: err.message }));
  }
}

function* selectCustomerSaga(action: ReturnType<typeof selectCustomer>): SagaReturn {
  try {
    yield put(selectCustomerRequest());
    const { payload } = action;
    const { domain } = payload;
    const response = (yield call([LitlingoAuthClient, 'selectCustomer'], domain)) as { user: User };
    yield put(push(`/${response.user.customer?.domain}`));
    yield put(selectCustomerSuccess(response.user));
  } catch (e) {
    const err = e as ErrorObject;
    yield put(selectCustomerFailure({ message: err.message }));
  } finally {
    yield put(selectCustomerFulfill());
  }
}

function* selectCustomerRedirectSaga(
  action: ReturnType<typeof selectCustomerAndRedirect>
): SagaReturn {
  try {
    yield put(selectCustomerRequest());
    const { payload } = action;
    const { domain, redirect } = payload;
    const response = (yield call([LitlingoAuthClient, 'selectCustomer'], domain)) as { user: User };
    yield put(selectCustomerSuccess(response.user));

    const path = reverse(redirect);

    yield put(push(path));
  } catch (e) {
    const err = e as ErrorObject;
    yield put(selectCustomerFailure({ message: err.message }));
  } finally {
    yield put(selectCustomerFulfill());
  }
}

function* selectCustomerCloneCommSaga(
  action: ReturnType<typeof selectCustomerCloneComm>
): SagaReturn {
  yield put(selectCustomerCloneCommRequest());
  try {
    const { payload } = action;
    const { domain, newEnvelopeId, prevEnvelopeId } = payload;

    const search = window.location.search.replaceAll(prevEnvelopeId, newEnvelopeId);
    yield put(selectCustomerCloneCommSuccess());
    window.open(`/${domain}/communication-envelopes/${newEnvelopeId}${search}`, '_blank');
  } catch (e) {
    yield put(showErrorAlert("Couldn't go to  clone communication"));
    yield put(selectCustomerCloneCommFailure(e as ErrorObject));
  }
}

function* navigateToOriginalCommSaga(
  action: ReturnType<typeof navigateToOriginalComm>
): SagaReturn {
  yield put(selectCustomerCloneCommRequest());
  try {
    const { payload } = action;
    const { platformGuid, customerUuid, prevEnvelopeId } = payload;
    const customers = (yield call([LitlingoAuthClient, 'getCustomers'])) as Customer[];
    const customer = customers.find((c) => c.uuid === customerUuid);
    if (!customer) {
      throw new Error('Customer not found');
    }
    const envelopeResponse = (yield call([LitLingoClient.resources.envelopes.extras, 'summary'], {
      params: {
        platform_guid: platformGuid,
        customer_uuid: customer.uuid,
      },
    })) as API.Response<API.Envelopes.Summary>;
    if (!envelopeResponse.data) {
      throw new Error('Communication not found');
    }
    const envelopeSummary = envelopeResponse.data.records.filter(
      (e) => e.envelope.platform_guid === platformGuid
    )[0];
    if (!envelopeSummary) {
      throw new Error('Communication not found');
    }
    if (envelopeSummary && customer.domain) {
      const search = window.location.search.replaceAll(
        prevEnvelopeId,
        envelopeSummary.envelope.uuid
      );
      yield put(selectCustomerCloneCommSuccess());
      window.open(
        `/${customer.domain}/communication-envelopes/${envelopeSummary.envelope.uuid}${search}`,
        '_blank'
      );
    }
  } catch (e) {
    yield put(showErrorAlert("Couldn't go to  original communication"));
    yield put(selectCustomerCloneCommFailure(e as ErrorObject));
  }
}

function* fetchCustomersMeSaga(): SagaReturn {
  try {
    yield put(fetchCustomersMeRequest());
    const customers = (yield call([LitlingoAuthClient, 'getCustomers'])) as Customer[];
    yield put(fetchCustomersMeSuccess(customers));
  } catch (e) {
    const err = e as ErrorObject;
    yield put(fetchCustomersMeFailure({ message: err.message }));
    yield put(logoutUser());
  } finally {
    yield put(fetchCustomersMeFulfill());
  }
}

function* verifyAuthSaga(action: ReturnType<typeof verifyAuth>): SagaReturn {
  const { payload } = action;
  const { domain } = payload;

  try {
    yield put(verifyRequest());
    const response = (yield call([LitlingoAuthClient, 'selectCustomer'], domain)) as { user: User };

    if (response.user.email != null) {
      yield call(setUser, { email: response.user.email });
    }
    yield call(setUserAmplitude, response.user.uuid);
    if (response.user.customer) {
      yield put(
        updatePermissionsPolicy({
          privacyConfig: response.user.customer.config.privacy_config,
          roleAccessConfig: response.user.customer.config.role_access_config,
        })
      );
    }
    yield put(verifySuccess({ user: response.user }));
    yield put(fetchUserMetadata({ userId: response.user.uuid }));
  } catch (e) {
    const err = e as ErrorObject;

    const { pathname, search } = (yield select(getLocation)) as Location;
    window.localStorage.setItem('redirectUrl', `${pathname}${search}`);
    if (pathname.includes('login')) {
      window.localStorage.removeItem('redirectUrl');
    }

    yield put(verifyFailure({ message: err.message }));
    yield put(logoutUser());
  }
}

function* createCustomerSaga(action: ReturnType<typeof createCustomer>): SagaReturn {
  yield put(createCustomerRequest());

  const user = (yield select(getUser)) as ReturnType<typeof getUser>;

  if (user.customer != null) {
    const response = (yield call([LitLingoClient.resources.customers.extras, 'create'], {
      data: { ...action.payload, provider_id: user.customer.provider_id },
    })) as API.Response<API.Customers.Create>;
    if (response.error != null) {
      yield put(createCustomerFailure(response.error));
    } else {
      yield put(createCustomerSuccess());
      yield put(push(`/${response.data.customer.domain}/`));
    }
  }
}

function* authGmailSaga(action: ReturnType<typeof authGmail>): SagaReturn {
  const { payload } = action;
  yield put(authRequest());
  const response = (yield call([LitLingoClient.resources.login.extras, 'gmail'], {
    data: { code: payload },
  })) as API.Response<API.Login.Gmail>;
  if (response.error) {
    yield put(authError(response.error));
  } else {
    yield put(authSuccess({ userCustomers: response.data }));
  }
}

function* redirectToLitlingoLoginSaga(
  action: ReturnType<typeof redirectToLitlingoLogin>
): SagaReturn {
  const { payload } = action;
  yield put(redirectToLitlingoLoginRequest());

  try {
    yield call([LitlingoAuthClient, 'signInWithRedirect'], {
      responseType: config.litlingo.responseType,
      redirectURI: config.litlingo.redirectURI,
      ...(payload.providerHint != null && { providerHint: payload.providerHint }),
    });
  } catch (e) {
    yield put(
      redirectToLitlingoLoginFailure({
        message: 'There was an error redirecting you to Litlingo',
        code: 'login',
      })
    );
  }
}

function* checkLoginRedirectSaga(): SagaReturn {
  try {
    yield put(checkLoginRedirectRequest());
    const response = (yield call([LitlingoAuthClient, 'getRedirectResult'])) as {
      customers: Customer[];
    } | null;
    if (response != null) {
      yield put(checkLoginRedirectSuccess(response.customers));

      const savedRedirect = window.localStorage.getItem('redirectUrl');
      const fullPath = savedRedirect?.slice(1);
      if (savedRedirect && fullPath) {
        let domain = '';
        if (!fullPath.split('/')[1]) {
          [domain] = fullPath.split('?');
        } else {
          [domain] = fullPath.split('/');
        }
        if (domain === 'login') {
          window.localStorage.removeItem('redirectUrl');
          return;
        }

        const domainIsValid = response.customers.some((customer) => customer.domain === domain);

        if (domainIsValid) {
          yield put(verifyAuth({ domain }));
          yield take(verifySuccess.toString());
          yield put(push(savedRedirect));
        } else {
          window.localStorage.removeItem('__customer__');
          window.localStorage.removeItem('redirectUrl');
          yield put(push('/select-customer'));
          yield put(showErrorAlert('Your previous customer does not exist.'));
        }
      } else {
        const path = reverse({
          routeName: 'home',
        });
        yield put(push(path));
      }
    } else {
      yield put(logoutUser());
    }
  } catch (e) {
    const error = e as ErrorObject;
    yield put(
      checkLoginRedirectFailure({
        message: error.message,
        code: 'login',
      })
    );
  }
}

function* logoutUserSaga(): SagaReturn {
  yield put(logoutUserRequest());
  yield call([LitlingoAuthClient, 'signOutWithRedirect'], {
    redirectURI: config.litlingo.redirectURI,
  });
  yield put(logoutUserFulfill());
  yield put(push('/login'));
}

function* fetchSingleCustomerSaga(): SagaReturn {
  const id = (yield select(getCustomerId)) as ReturnType<typeof getCustomerId>;
  if (id == null) {
    return;
  }

  yield put(fetchSingleCustomerRequest());

  const response = (yield call([LitLingoClient.resources.customers, 'retrieve'], id, {
    params: { relationships: ['secrets_summary', 'slack_install_link'] },
  })) as API.Response<API.Customers.Retrieve>;
  if (response.error != null) {
    yield put(fetchSingleCustomerFailure(response.error));
  } else {
    yield put(fetchSingleCustomerSuccess(response.data));
  }
}

function* reprocessCommsSaga(action: ReturnType<typeof reprocessComms>): SagaReturn {
  const { payload } = action;

  yield put(fetchReprocessCommsRequest());
  const response = (yield call([LitLingoClient.resources.customers.extras, 'reprocessComms'], {
    data: payload,
  })) as API.Response<API.Customers.ReprocessComms>;
  if (response.error != null) {
    yield put(saveCustomerFailure(response.error));
  } else {
    yield put(saveCustomerSuccess(response.data));
    yield put(showSuccessAlert('Communications Reprocessing'));
  }
}

function* reindexCommsSaga(): SagaReturn {
  yield put(fetchReindexCommsRequest());
  const response = (yield call([
    LitLingoClient.resources.customers.extras,
    'reindexComms',
  ])) as API.Response<API.Customers.ReindexComms>;
  if (response.error != null) {
    yield put(saveCustomerFailure(response.error));
  } else {
    yield put(saveCustomerSuccess(response.data));
    yield put(showSuccessAlert('Communications Reindexing'));
  }
}

function* fetchIndexesCommsSaga(): SagaReturn {
  yield put(fetchIndexesCommsRequest());
  const response = (yield call([
    LitLingoClient.resources.customers.extras,
    'indexesComms',
  ])) as API.Response<API.Customers.Indexes>;

  if (response.error != null) {
    yield put(fetchIndexesCommsFailure(response.error));
  } else {
    yield put(fetchIndexesCommsSuccess(response.data));
  }
}

function* fetchLogMetricsSaga(): SagaReturn {
  yield put(fetchLogMetricsRequest());

  // through modified client
  const response = (yield call([
    baseClient.resources.logMetrics.extras,
    'logMetrics',
  ])) as API.Response<API.Customers.LogMetrics>;
  if (response.error != null) {
    yield put(fetchLogMetricsFailure(response.error));
  } else {
    yield put(fetchLogMetricsSuccess(response.data));
  }
}

function* fetchIndexingStatusSaga(): SagaReturn {
  yield put(fetchIndexingStatusRequest());

  const response = (yield call([
    LitLingoClient.resources.customers.extras,
    'indexingStatus',
  ])) as API.Response<API.Customers.IndexingStatus>;
  if (response.error != null) {
    yield put(fetchIndexingStatusFailure(response.error));
  } else {
    yield put(fetchIndexingStatusSuccess(response.data));
  }
}

function* fetchProcessingStatusSaga(): SagaReturn {
  yield put(fetchProcessingStatusRequest());

  const response = (yield call([
    LitLingoClient.resources.customers.extras,
    'processingStatus',
  ])) as API.Response<API.Customers.ProcessingStatus>;
  if (response.error != null) {
    yield put(fetchIProcessingStatusFailure(response.error));
  } else {
    yield put(fetchProcessingStatusSuccess(response.data));
  }
}

function* fetchZendeskSaga(): SagaReturn {
  yield put(fetchZendeskRequest());
  const response = (yield call([
    LitLingoClient.resources.customers.extras,
    'getZendeskSecret',
  ])) as API.Response<API.Customers.GetZendeskSecret>;
  if (response.error != null) {
    yield put(fetchZendeskFailure(response.error));
  } else {
    yield put(fetchZendeskSuccess(response.data));
  }
}

function* saveCustomerSaga(action: ReturnType<typeof saveCustomer>): SagaReturn {
  const { payload = {} } = action;

  const { customer } = ((yield select()) as GlobalState).auth;
  const customerToSave = { ...customer, ...payload };

  yield put(saveCustomerRequest());

  const response = (yield call([LitLingoClient.resources.customers, 'upsert'], {
    data: customerToSave,
    params: { relationships: ['secrets_summary'] },
  })) as API.Response<API.Customers.Upsert>;
  if (response.error != null) {
    yield put(saveCustomerFailure(response.error));
  } else {
    yield put(showSuccessAlert('Updated Customer'));
    yield put(saveCustomerSuccess(response.data));
  }
}

function* fetchAllCustomersSaga(): SagaReturn {
  try {
    yield put(fetchCustomersRequest());
    const customers = (yield call([LitlingoAuthClient, 'getCustomers'])) as Customer[];
    yield put(fetchCustomersSuccess(customers));
  } catch (error) {
    const errorData = error as ErrorObject;
    yield put(fetchCustomersFailure(errorData));
  }
}

function* zendeskVerifyCredentialsSaga(): SagaReturn {
  yield put(zendeskVerifyCredentialsRequest());
  const response = (yield call([
    LitLingoClient.resources.customers.extras,
    'verifyZendeskCredentials',
  ])) as API.Response<API.Customers.VerifyZendeskCredentials>;
  if (response.error != null) {
    yield put(zendeskVerifyCredentialsFailure(response.error));
  } else {
    yield put(zendeskVerifyCredentialsSuccess(response.data));
  }
}

function* gmailVerifyCredentialsSaga(): SagaReturn {
  yield put(gmailVerifyCredentialsRequest());
  const response = (yield call([
    LitLingoClient.resources.customers.extras,
    'verifyGmailCredentials',
  ])) as API.Response<API.Customers.VerifyGmailCredentials>;
  if (response.error != null) {
    yield put(gmailVerifyCredentialsFailure(response.error));
  } else {
    yield put(gmailVerifyCredentialsSuccess(response.data));
  }
}

function* o365VerifyCredentialsSaga(): SagaReturn {
  yield put(o365VerifyCredentialsRequest());
  const response = (yield call([
    LitLingoClient.resources.customers.extras,
    'verifyO365Credentials',
  ])) as API.Response<API.Customers.VerifyO365Credentials>;
  if (response.error != null) {
    yield put(o365VerifyCredentialsFailure(response.error));
  } else {
    yield put(o365VerifyCredentialsSuccess(response.data));
  }
}

function* downloadCustomerDocumentSaga(
  action: ReturnType<typeof downloadCustomerDocument>
): SagaReturn {
  const response = (yield call([LitLingoClient.resources.customers.extras, 'documentURL'], {
    urlParams: { documentId: action.payload.documentId },
  })) as API.Response<API.Customers.DocumentURL>;

  if (response.error != null) {
    yield put(downloadCustomerDocumentFailure(response.error));
  } else {
    yield put(downloadCustomerDocumentSuccess());
    window.location.replace(response.data.payload);
  }

  yield put(downloadCustomerDocumentFulfill());
}

function* fetchAllSandboxesSaga(): SagaReturn {
  yield put(fetchAllSandboxesRequest());

  const params = {
    include_count: true,
    limit: -1,
  };

  const response = (yield call([LitLingoClient.resources.customers.extras, 'getSandboxes'], {
    params,
  })) as API.Response<API.Customers.getSandboxes>;

  if (response.error != null) {
    yield put(fetchAllSandboxesFailure(response.error));
  } else {
    yield put(fetchAllSandboxesSuccess(response.data));
  }
}

function* createSandboxSaga(action: ReturnType<typeof createNewSandbox>): SagaReturn {
  yield put(createNewSandboxRequest());

  const { payload } = action;

  const response = (yield call([LitLingoClient.resources.customers.extras, 'newSandbox'], {
    data: { ...payload },
  })) as API.Response<API.Customers.newSandbox>;

  if (response.error != null) {
    yield put(createNewSandboxFailure(response.error));
  } else {
    yield put(createNewSandboxSuccess(response.data));
    yield put(showSuccessAlert('Sandbox created successfully'));
  }
}

function* fetchCustomerChangelogSaga(
  action: ReturnType<typeof fetchCustomerChangelog>
): SagaReturn {
  yield put(fetchChangelogRequest());

  const { version, auditlogUuid } = action.payload;

  const response = (yield call([LitLingoClient.resources.customers.extras, 'changelog'], {
    urlParams: { version },
  })) as API.Response<API.Customers.Changelog>;

  if (response.error != null) {
    yield put(
      fetchChangelogFailure({ ...response.error, message: response.error.message, auditlogUuid })
    );
  } else {
    yield put(fetchChangelogSuccess({ auditlogUuid, changelog: response.data.payload }));
  }
  yield put(fetchChangelogFulfill());
}

function* exportUsersSaga(): SagaReturn {
  yield put(exportUsersRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.user)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const navParams = (yield select(getNavParams)) as ReturnType<typeof getNavParams>;

  const params = {
    ...resourceParams,
    ...navParams,
    true_users: true,
  };

  const response = (yield call([LitLingoClient.resources.customers.extras, 'exportQueryUsers'], {
    params,
  })) as API.Response<API.Customers.ExportUsers>;

  if (response.error != null) {
    yield put(exportUsersFailure({ ...response.error, message: response.error.message }));
    yield put(showErrorAlert('Something went wrong.'));
  } else {
    yield put(exportUsersSuccess());
    yield put(showSuccessAlert('Exporting users.'));
  }
  yield put(exportUsersFulfill());
}

function* logoutOn403Saga(action: PayloadAction<string | ErrorObject>): SagaReturn {
  const { payload, type } = action;
  if (!type || !type.includes('FAILURE')) return;
  if (typeof payload !== 'string' && payload.status === 401) {
    yield put(logoutUser());
  }
}

function* authSaga(): SagaReturn {
  yield takeLatest(createCustomer.toString(), createCustomerSaga);
  yield takeLatest(checkLoginRedirect.toString(), checkLoginRedirectSaga);
  yield takeLatest(selectCustomer.toString(), selectCustomerSaga);
  yield takeLatest(selectCustomerAndRedirect.toString(), selectCustomerRedirectSaga);
  yield takeLatest(spoofRoles.toString(), spoofRolesSaga);
  yield takeLatest(fetchCustomersMe.toString(), fetchCustomersMeSaga);
  yield takeLatest(verifyAuth.toString(), verifyAuthSaga);
  yield takeLatest(authGmail.toString(), authGmailSaga);
  yield takeLatest(redirectToLitlingoLogin.toString(), redirectToLitlingoLoginSaga);
  yield takeLatest(logoutUser.toString(), logoutUserSaga);
  yield takeLatest(fetchSingleCustomer.toString(), fetchSingleCustomerSaga);
  yield takeLatest(reprocessComms.toString(), reprocessCommsSaga);
  yield takeLatest(reindexComms.toString(), reindexCommsSaga);
  yield takeLatest(fetchZendesk.toString(), fetchZendeskSaga);
  yield takeLatest(saveCustomer.toString(), saveCustomerSaga);
  yield takeLatest(fetchAllCustomers.toString(), fetchAllCustomersSaga);
  yield takeLatest(zendeskVerifyCredentials.toString(), zendeskVerifyCredentialsSaga);
  yield takeLatest(gmailVerifyCredentials.toString(), gmailVerifyCredentialsSaga);
  yield takeLatest(o365VerifyCredentials.toString(), o365VerifyCredentialsSaga);
  yield takeLatest(fetchIndexesComms.toString(), fetchIndexesCommsSaga);
  yield takeLatest(fetchLogMetrics.toString(), fetchLogMetricsSaga);
  yield takeLatest(fetchIndexingStatus.toString(), fetchIndexingStatusSaga);
  yield takeLatest(fetchProcessingStatus.toString(), fetchProcessingStatusSaga);
  yield takeLatest(downloadCustomerDocument.toString(), downloadCustomerDocumentSaga);
  yield takeLatest(fetchAllSandboxes.toString(), fetchAllSandboxesSaga);
  yield takeLatest(createNewSandbox.toString(), createSandboxSaga);
  yield takeLatest(fetchCustomerChangelog.toString(), fetchCustomerChangelogSaga);
  yield takeLatest(exportUsers.toString(), exportUsersSaga);
  yield takeLatest(selectCustomerCloneComm.toString(), selectCustomerCloneCommSaga);
  yield takeLatest(navigateToOriginalComm.toString(), navigateToOriginalCommSaga);

  yield takeLatest('*', logoutOn403Saga);
}

export default authSaga;
