import type { UpsertIdentifierPayload } from 'actions/identifier';
import {
  addNewLanguageMatcher,
  clearIdentifier,
  discardIdentifierChanges,
  editLanguageMatcher,
  fetchLanguageMatcherTypes,
  setActiveLanguageMatcherId,
  setHasChanges,
  upsertIdentifier,
} from 'actions/identifier';
import CollapsableMainSection from 'components/CollapsableCard/CollapsableMainSection';
import EmptyLanguageMatcherState from 'components/EmptyLanguageMatcherState';
import LeavePageModal from 'components/LeavePageModal';
import WarningMsg from 'components/WarningMsg';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  getActiveLanguageMatcher,
  getActiveLanguageMatcherId,
  getLanguageMatcherFromSelectedIdentifier,
  getLanguageMatcherTypes,
  getSelectedIdentifierRevision,
  saveIdentifierRequestLoading,
  unsavedChanges,
} from 'selectors/identifier';
import { useSelector } from 'store';
import type { MLanguageMatcher, UUID } from 'types';
import UserNotificationMsgs from 'utils/userNotificationMsgs';
import { v4 as uuidv4 } from 'uuid';
import ChooseCombinationType from './ChooseCombinationType';
import DeleteMatcherButton from './DeleteMatcherButton';

type LanguageMatcherType = {
  identifierData: UpsertIdentifierPayload;
};

const LanguageMatcherRedesign: React.FC<LanguageMatcherType> = ({ identifierData }) => {
  const dispatch = useDispatch();

  const [isSaved, setIsSaved] = useState(false);

  const identifierRevision = useSelector(getSelectedIdentifierRevision);
  const unsaved = useSelector(unsavedChanges);
  const activeLanguageMatcher = useSelector(getActiveLanguageMatcher);
  const activeLanguageMatcherId = useSelector(getActiveLanguageMatcherId);
  const languageMatcherTypes = useSelector(getLanguageMatcherTypes);
  const isLoadingSaveIdentifier = useSelector(saveIdentifierRequestLoading);
  const languageMatchers = useSelector((state) =>
    getLanguageMatcherFromSelectedIdentifier(state, '')
  );

  const { register, setValue, handleSubmit } = useForm<MLanguageMatcher>();

  useEffect(() => {
    if (activeLanguageMatcher && activeLanguageMatcher.isNew) {
      const input = document.getElementById('name');
      input?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLanguageMatcherId]);

  useEffect(() => {
    dispatch(fetchLanguageMatcherTypes());
  }, [dispatch]);

  useEffect(() => {
    if (activeLanguageMatcher && activeLanguageMatcher.name) {
      setValue('name', activeLanguageMatcher.name);
      setValue('description', activeLanguageMatcher.description);
    }
  }, [activeLanguageMatcher, setValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLanguageMatcherClick = (languageMatcherId: UUID): void => {
    dispatch(setActiveLanguageMatcherId(languageMatcherId));
  };

  const onEditLanguageMatcher = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!activeLanguageMatcher.uuid || e.target.value === null) return;
    dispatch(
      editLanguageMatcher({
        languageMatcherId: activeLanguageMatcher.uuid,
        data: { [e.target.name]: e.target.value },
      })
    );
  };

  const onSubmit = (): void => {
    if (!identifierRevision) return;

    dispatch(
      upsertIdentifier({
        ...identifierData,
        identifierId: identifierRevision.uuid,
        options_config: {},
      })
    );
    setIsSaved(true);
  };

  const addLocalLanguageMatcher = (): void => {
    if (!identifierRevision) return;
    const newMatcherId = uuidv4();

    const defaultValues: { [key: string]: string | number } = {};
    Object.entries(languageMatcherTypes.adjacent_tokens).forEach(([key, field]) => {
      if (field != null && field.default) {
        defaultValues[key] = field.default;
      }
    });
    const matcherBody = {
      uuid: newMatcherId,
      name: `New ${languageMatchers.length + 1}`,
      saved: false,
      type: 'token',
      isNew: true,
      ...defaultValues,
    };

    dispatch(
      addNewLanguageMatcher({
        identifierId: identifierRevision.uuid,
        languageMatcherId: newMatcherId,
        matcherBody,
      })
    );
    handleLanguageMatcherClick(newMatcherId);
  };

  const saveButton = (
    <div className="h-8 flex flex-row items-end gap-2">
      {activeLanguageMatcher && (
        <DeleteMatcherButton
          setValue={setValue}
          setHighlightedLanguageMatcher={(): void => {
            // moot
          }}
          activeLanguageMatcher={activeLanguageMatcher}
        />
      )}
      {activeLanguageMatcher && unsaved && (
        <button
          data-testid="save-matchers-button"
          type="button"
          className="button button--secondary h-full w-22 justify-center"
          onClick={(e): void => {
            dispatch(discardIdentifierChanges());
            e.stopPropagation();
          }}
        >
          <span className="font-bold">Discard</span>
        </button>
      )}
      <button
        data-testid="save-matchers-button"
        type="button"
        className="button button--primary h-full w-22 justify-center text-body"
        onClick={(e): void => {
          handleSubmit(onSubmit)();
          e.stopPropagation();
        }}
      >
        <span className="font-bold text-white">Save</span>
      </button>
    </div>
  );

  return (
    <CollapsableMainSection
      headerTitle="Matchers"
      customAction={saveButton}
      containerClasses="h-full"
      staticHeader
      stickyHeader
    >
      <div className="w-full min-h-104 h-full flex flex-col justify-center">
        <div
          className={`flex flex-col flex-1 overflow-y-auto pb-3 ${
            !activeLanguageMatcher && 'justify-center'
          }`}
        >
          {!activeLanguageMatcher ? (
            <EmptyLanguageMatcherState addNewLanguageMatcher={addLocalLanguageMatcher} />
          ) : (
            <>
              <div className="pt-4 px-6 flex flex-row justify-between">
                <div />
                <WarningMsg
                  message={UserNotificationMsgs.unsavedChanges}
                  showLoading={isLoadingSaveIdentifier}
                  showWarning={unsaved}
                  showSavedMsg={isSaved}
                />
              </div>
              <div className="grid gap-x-4 grid-cols-2 px-6 pt-6">
                <label
                  htmlFor="name"
                  className="block litlingo-identifier-label-name text-sm font-bold"
                >
                  Name
                  <input
                    ref={register({ required: true, pattern: /\S/i })}
                    name="name"
                    id="name"
                    data-testid="matcher-name-input"
                    defaultValue={activeLanguageMatcher && activeLanguageMatcher.name}
                    className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    onChange={onEditLanguageMatcher}
                  />
                </label>
                <label
                  htmlFor="description"
                  className="block litlingo-identifier-label-name text-sm font-bold"
                >
                  {'Description '}
                  <div className="mt-1 rounded-md shadow-sm">
                    <input
                      data-testid="matcher-description-input"
                      ref={register}
                      defaultValue={activeLanguageMatcher && activeLanguageMatcher.description}
                      id="description"
                      name="description"
                      className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      onChange={onEditLanguageMatcher}
                    />
                  </div>
                </label>
              </div>
              <div className="flex px-6 justify-between">
                <div className="w-full">
                  <ChooseCombinationType activeLanguageMatcher={activeLanguageMatcher} />
                </div>
              </div>
            </>
          )}
        </div>

        <LeavePageModal
          shouldBlockNavigation={unsaved}
          dicardAction={(): void => {
            dispatch(setHasChanges(false));
            dispatch(clearIdentifier());
          }}
        />
      </div>
    </CollapsableMainSection>
  );
};

export default LanguageMatcherRedesign;
