import { scheduleCompare } from 'actions';
import CustomerList from 'components/CompareRules/CustomerList';
import RuleList from 'components/CompareRules/RuleList';
import SelectSavedSearch from 'components/CompareRules/SelectSavedSearch';
import Modal from 'components/Modal';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UUID } from 'types';

type BasicResource = { uuid: UUID; name: string };

const CompareRules = forwardRef((props: { toggleIsCompareRulesOpen: () => void }, ref) => {
  const { toggleIsCompareRulesOpen } = props;
  const dispatch = useDispatch();

  const [rows, setRows] = useState<{ customer: BasicResource; rule: BasicResource }[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [currentCustomer, setCurrentCustomer] = useState<BasicResource>({} as BasicResource);
  const [isCustomerListOpen, setIsCustomerListOpen] = useState(false);
  const [isRuleListOpen, setIsRuleListOpen] = useState(false);
  const [selectedSavedSearch, setSelectedSavedSearch] = useState<UUID | null>(null);

  const toggleIsCustomerListOpen = (): void => {
    setIsCustomerListOpen(!isCustomerListOpen);
  };

  const toggleIsRuleListOpen = (): void => {
    setIsRuleListOpen(!isRuleListOpen);
  };

  const handleAddRow = (): void => {
    toggleIsCustomerListOpen();
  };

  const handleAddCustomer = (uuid: UUID, name: string): void => {
    setCurrentCustomer({ uuid, name });
    toggleIsRuleListOpen();
    toggleIsCustomerListOpen();
  };

  const handleAddRule = (uuid: UUID, name: string): void => {
    setRows(rows.concat({ customer: currentCustomer, rule: { uuid, name } }));
    setCurrentCustomer({} as BasicResource);
    toggleIsRuleListOpen();
  };

  useImperativeHandle(ref, () => ({
    compareRules(): void {
      if (rows.length === 1) {
        setError('Please select at least two models to compare');
        return;
      }

      if (selectedSavedSearch == null) {
        setError('Please select a saved search');
        return;
      }

      const ruleUuids = rows.map((row) => row.rule.uuid);

      dispatch(
        scheduleCompare({
          ruleUuids,
          savedSearchId: selectedSavedSearch || '',
        })
      );
      toggleIsCompareRulesOpen();
    },
  }));

  return (
    <div className="mt-4 mb-4 flex justify-center items-center flex-col">
      <div className="table-wrapper">
        <table className="table-wrapper__table">
          <thead>
            <tr>
              <th className="table-wrapper__th">Customer</th>
              <th className="table-wrapper__th">Model</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={JSON.stringify(row)}>
                <td className="table-wrapper__td">{row.customer.name}</td>
                <td className="table-wrapper__td">{row.rule.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button type="button" className="button button--primary mt-4 mb-2" onClick={handleAddRow}>
        Add a row
      </button>

      <div className="mt-4">
        <SelectSavedSearch
          setSelectedSavedSearch={setSelectedSavedSearch}
          selectedSavedSearch={selectedSavedSearch}
        />
      </div>

      {isCustomerListOpen && (
        <Modal
          body={<CustomerList handleClick={handleAddCustomer} />}
          title="Choose a customer"
          toggleShowModal={toggleIsCustomerListOpen}
        />
      )}

      {isRuleListOpen && (
        <Modal
          body={<RuleList handleClick={handleAddRule} customerId={currentCustomer?.uuid} />}
          title="Choose a model"
          toggleShowModal={toggleIsRuleListOpen}
        />
      )}

      {error != null && <span className="mt-2 text--extra-small  litlingo-red-color">{error}</span>}
    </div>
  );
});

CompareRules.displayName = 'CompareRulesComponent';

export default CompareRules;
