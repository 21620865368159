/* eslint-disable max-lines */
/* eslint-disable camelcase */
/* eslint-disable react/require-default-props */
import {
  addValueToTree,
  fetchFilterCampaignRuleOutcomes,
  fetchFilterCampaigns,
  fetchFilterRules,
  removeValueFromTree,
} from 'actions';
import ShowLabels from 'components/Filters/BackendResourceFilter/ShowLabels';
import LoadingIndicator from 'components/LoadingIndicator';
import useClickOutside from 'components/utils/useClickOutside';
import { SEARCH_ICON } from 'constants/filterIcons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import {
  getCampaignRuleOutcomesList,
  getFetchAllCampaignRuleOutcomesLoading,
} from 'selectors/campaignRuleOutcomes';
import { getCampaignsList, getFetchAllCampaignsLoading } from 'selectors/campaigns';
import { getNavParamsFromTree } from 'selectors/nav';
import { getFetchAllRulesLoading, getRulesList } from 'selectors/rules';
import { useSelector } from 'store';
import { MCampaign, MRule } from 'types';
import logEvent from 'utils/analytics';

type ResourceFilterProps = {
  className?: string;
  title?: string;
  dataTestid?: string;
  hasSelectedParams?: boolean;
  campaignsKey: string;
  rulesKey: string;
  isAdvanceFilterView?: boolean;
  isOpening?: boolean;
};

const CategoriesFilter: React.FC<ResourceFilterProps> = (props) => {
  const {
    className,
    title = '',
    hasSelectedParams,
    dataTestid,
    campaignsKey,
    rulesKey,
    isAdvanceFilterView,
    isOpening,
  } = props;

  const campaignsKeys = campaignsKey;
  const rulesKeys = rulesKey;

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [firstLoad, setFirstLoad] = useState(true);

  const inputRef = useRef<HTMLInputElement>(null);

  const params = useSelector(getNavParamsFromTree);
  const allCampaigns = useSelector(getCampaignsList);
  let allRules = useSelector(getRulesList);
  if (isAdvanceFilterView) {
    allRules = [];
  }

  const outcomes = useSelector(getCampaignRuleOutcomesList);

  const loadingCampaigns = useSelector(getFetchAllCampaignsLoading);
  const loadingRules = useSelector(getFetchAllRulesLoading);
  const loadingCampaignRuleOutcomes = useSelector(getFetchAllCampaignRuleOutcomesLoading);
  const loading = loadingCampaigns || loadingRules || loadingCampaignRuleOutcomes;

  const filterCampaigns = (params[campaignsKeys] ?? []) as string[];
  const notFilterCampaigns = (params[`not_${campaignsKeys}`] ?? []) as string[];
  const filterRules = (params[rulesKeys] ?? []) as string[];
  const notFilterRules = (params[`not_${rulesKeys}`] ?? []) as string[];

  const allFilterItems = [
    ...filterCampaigns,
    ...filterRules,
    ...notFilterCampaigns,
    ...notFilterRules,
  ];

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  useEffect(() => {
    if ((isOpen && firstLoad) || (hasSelectedParams && firstLoad)) {
      setFirstLoad(false);
      dispatch(
        fetchFilterCampaigns({
          limit: '-1',
          name: searchValue,
        })
      );
      dispatch(
        fetchFilterRules({
          limit: '-1',
          name: searchValue,
        })
      );
      dispatch(fetchFilterCampaignRuleOutcomes({ limit: -1 }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen]);

  useEffect(() => {
    if (isOpening) {
      inputRef.current?.focus();
    }
  }, [inputRef, isOpening]);

  const handleButtonClick = (): void => {
    if (isOpen) return;
    setIsOpen(!isOpen);
  };

  const handleDelete = (labelId: string, exclude: boolean): void => {
    if (exclude) {
      if (notFilterRules.includes(labelId)) {
        dispatch(
          removeValueFromTree({ value: labelId, field: `not_${rulesKeys.replace('_and', '')}` })
        );
      } else {
        dispatch(
          removeValueFromTree({ value: labelId, field: `not_${campaignsKeys.replace('_and', '')}` })
        );
      }
    } else if (filterRules.includes(labelId)) {
      dispatch(removeValueFromTree({ value: labelId, field: rulesKeys.replace('_and', '') }));
    } else {
      dispatch(removeValueFromTree({ value: labelId, field: campaignsKeys.replace('_and', '') }));
    }
  };

  const handleInputChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    isRule: boolean
  ): void => {
    logEvent('envelopes-list-filter-models');

    const { shiftKey: exclude } = event.nativeEvent;
    const { id: state } = event.currentTarget;

    if (
      exclude &&
      ((isRule && filterRules.includes(state)) || (!isRule && filterCampaigns.includes(state)))
    ) {
      return;
    }

    event.currentTarget.dataset.checked =
      event.currentTarget.dataset.checked === 'true' ? 'false' : 'true';

    const item =
      allCampaigns.find((c) => c.uuid === state) || allRules.find((r) => r.uuid === state);

    if (event.currentTarget.dataset.checked === 'true') {
      let key = '';
      if (isRule) {
        key = rulesKeys;
      } else {
        key = campaignsKeys;
      }

      if (exclude) {
        dispatch(
          addValueToTree({
            value: state,
            field: `not_${key}`.replace('and_', ''),
            label: item?.name,
          })
        );
      } else {
        dispatch(
          addValueToTree({ value: state, field: key.replace('_and', ''), label: item?.name })
        );
      }
    } else {
      handleDelete(state, notFilterRules.includes(state) || notFilterCampaigns.includes(state));
    }

    event.stopPropagation();
  };

  const getData = (): (MCampaign | MRule)[] =>
    [...allCampaigns, ...allRules].filter(
      ({ name }) => name && name.toLowerCase().includes(searchValue.toLowerCase())
    ) || [];

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e?.target.value.toLowerCase();
    setSearchValue(value);
  };

  return (
    <>
      <div ref={wrapperRef} className={`flex flex-col ${className || ''}"`}>
        <div className="relative flex flex-row justify-start items-center w-full min-h-8 rounded p-1.5 bg-litlingo-success-light bg-opacity-10">
          <div className="w-5">{SEARCH_ICON('white')}</div>
          <input
            id="search"
            name="search"
            ref={inputRef}
            className="max-h-5 w-full text-white ml-1 font-normal text-base bg-transparent placeholder-white placeholder-italic"
            placeholder={`Search ${title}`}
            data-testid={dataTestid}
            type="text"
            onFocus={handleButtonClick}
            onChange={handleSearchChange}
            value={searchValue}
            autoComplete="off"
          />
        </div>

        {isOpen && (
          <>
            <LoadingOverlayWrapper active={loading} spinner={<LoadingIndicator />} fadeSpeed={0}>
              <div className="w-full max-h-filter z-10 overflow-auto bg-litlingo-gray-1 custom-scrollbar">
                <fieldset className="m-2 min-h-5">
                  {getData().map((item, idx) => {
                    const ruleOutcome = outcomes.find(
                      (outcome) => outcome.rule_uuid === item.uuid && !outcome.campaign?.deleted_at
                    );
                    const isRule = 'rule_uuid' in item;

                    if (isRule && !ruleOutcome) {
                      return null;
                    }

                    const negated =
                      notFilterCampaigns.includes(item.uuid) || notFilterRules.includes(item.uuid);

                    return (
                      <div key={item.uuid} className={idx !== 0 ? 'mt-2' : ''}>
                        <div
                          id={item.uuid}
                          role="button"
                          aria-hidden
                          className="relative flex items-center cursor-pointer"
                          data-checked={allFilterItems.includes(item.uuid)}
                          onClick={(e): void => handleInputChange(e, isRule)}
                        >
                          <div className="text-sm leading-5 flex flex-col items-start justify-center cursor-pointer">
                            <div className="flex flex-row items-center cursor-pointer">
                              <div className="relative flex justify-start items-start">
                                <div className="flex items-center h-5 mr-1">
                                  <input
                                    id={item.uuid}
                                    data-testid={`${item.uuid}-checkbox`}
                                    type="checkbox"
                                    className={`form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out cursor-pointer ${
                                      negated ? 'litlingo-checkbox-negated' : 'litlingo-checkbox'
                                    }`}
                                    checked={allFilterItems.includes(item.uuid)}
                                  />
                                </div>

                                {!isRule && (
                                  <div className="h-5 flex items-center">
                                    <span className="mr-1">
                                      <svg
                                        width="3"
                                        height="16"
                                        viewBox="0 0 3 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect width="3" height="16" fill="#F8A01A" />
                                      </svg>
                                    </span>
                                  </div>
                                )}

                                <div className="flex flex-col text-sm leading-5">
                                  <label htmlFor={item.uuid} className="cursor-pointer">
                                    <span className="text-litlingo-gray-6 text-xxs font-normal select-none break-word">
                                      {item.name}
                                    </span>
                                  </label>
                                  {ruleOutcome && (
                                    <span className="text-litlingo-gray-5 text-xxs font-normal select-none break-word">
                                      {ruleOutcome.campaign?.name}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </fieldset>
              </div>
            </LoadingOverlayWrapper>

            <div className="flex self-end mt-2 -mb-2">
              <span className="text-white font-normal text-xss">Shift + click to exclude</span>
            </div>
          </>
        )}

        {!isOpen && (
          <>
            <ShowLabels
              handleDelete={handleDelete}
              filterData={filterCampaigns}
              notFilterData={notFilterCampaigns}
              getResourceKey={campaignsKey}
            />
            <ShowLabels
              handleDelete={handleDelete}
              filterData={filterRules}
              notFilterData={notFilterRules}
              getResourceKey={rulesKey}
            />
          </>
        )}
      </div>
    </>
  );
};

export default CategoriesFilter;
