import { BarDatum, BarExtendedDatum, ResponsiveBar } from '@nivo/bar';
import { fetchCampaignsPerformance } from 'actions';
import PermissionLink from 'components/PermissionsLink';
import { newToOldLabel } from 'components/utils/EventOptions';
import withWidget from 'decorators/widget';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCampaignsPerformance, getCampaignsPerformanceLoading } from 'selectors/analytics';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getPermissionsPolicy } from 'selectors/permissions';
import { useSelector } from 'store';
import { isActionAuthorized } from 'utils/permissions';
import { useTimeRange } from 'utils/timeRanges';
import { useHistory } from 'utils/urls';

type ItemType = {
  uuid: string;
  label: string;
  violation: number;
  pending: number;
  acceptable: number;
};

const ModelPerformance: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const campaignsPerformance = useSelector(getCampaignsPerformance);
  const loading = useSelector(getCampaignsPerformanceLoading);

  const range = useTimeRange();
  const customerDomain = useSelector(getCustomerDomain);

  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);

  const titles: string[] = [];
  const ids: string[] = [];

  useEffect(() => {
    dispatch(fetchCampaignsPerformance({ limit: 6, ...range }));
  }, [dispatch, range]);

  const handleClick = (event: BarExtendedDatum, id: string): void => {
    if (isActionAuthorized('events.list', user.roles, policy)) {
      history.pushLookup({
        routeName: 'envelope-list',
        queryParams: {
          envelopes__states: newToOldLabel[event.id as keyof typeof newToOldLabel],
          envelopes__campaign_uuids: id,
        },
        customerDomain,
      });
    }
  };

  const createChart = (curItem: ItemType[], id: string): JSX.Element => (
    <ResponsiveBar
      data={curItem}
      keys={['violation', 'pending', 'acceptable']}
      indexBy="label"
      margin={{ top: 0, right: 0, bottom: 0, left: 100 }}
      padding={0.52}
      innerPadding={0}
      maxValue={100}
      layout="horizontal"
      axisLeft={{
        tickSize: 0,
        tickPadding: 15,
        tickRotation: 0,
        legendOffset: -40,
      }}
      enableGridY={false}
      enableLabel={false}
      animate
      // @ts-ignore
      tooltip={(value: BarTooltipProps<BarDatum>): JSX.Element => <>{`${value}%`}</>}
      onClick={(event): void => handleClick(event, id)}
    />
  );

  const formatTitle = (title: string): string => {
    if (title.length > 34) {
      return `${title.slice(0, 31)}...`;
    }
    return title;
  };

  const formatValue = (value: number, maxValue: number): number =>
    Math.round((value * 100) / maxValue);

  if (loading) {
    return <WidgetLoading />;
  }

  return (
    <>
      <div className="text-left">
        <div className="inline-flex items-center pr-24">
          <div className="h-3 w-3 litlingo-red-bg-color" />
          <p className="text pl-2">Out of Policy</p>
        </div>
        <div className="inline-flex items-center pr-24">
          <div className="h-3 w-3 bg-litlingo-gray-100" />
          <p className="text pl-2">Pending review</p>
        </div>
        <div className="inline-flex items-center">
          <div className="h-3 w-3 bg-litlingo-old-blue" />
          <p className="text pl-2">Acceptable</p>
        </div>
      </div>
      <div className="text-left pt-4">
        <table className="text-lg text-left leading-5 w-full table-fixed">
          <tbody>
            {campaignsPerformance
              .map((campaign) => {
                const pending = campaign.metrics.NEW || 0;
                const violation = campaign.metrics.contains_issues || 0;
                const acceptable = campaign.metrics.no_issue || 0;
                const label = `${violation}/${pending}/${acceptable}`;
                titles.push(campaign.campaign.name);
                ids.push(campaign.campaign.uuid);
                const maxValue = pending + violation + acceptable;
                return {
                  uuid: campaign.campaign.uuid,
                  label,
                  violation: formatValue(violation, maxValue),
                  pending: formatValue(pending, maxValue),
                  acceptable: formatValue(acceptable, maxValue),
                };
              })
              .map((item, ind) => (
                <tr key={item.uuid}>
                  <PermissionLink
                    action="events.list"
                    className="hover:underline text-black"
                    routeName="envelope-list"
                    queryParams={{ envelopes__campaign_uuids: ids[ind] }}
                  >
                    <td className="w-1/3 truncate">{formatTitle(titles[ind])}</td>
                  </PermissionLink>
                  <td className="h-10 w-2/3">{createChart([item], ids[ind])}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default withWidget({
  title: 'Use Case Performance',
  subtitle: "Shows the ratio of confirmed out of policy's over all matched events",
  minWidth: 9,
  minHeight: 15,
})(ModelPerformance);
