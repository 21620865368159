import ExperimentsList from 'components/ExperimentsList';
import { RouteSpec, SuperRouteSpec } from 'types';

const experimentsRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'experiment-list',
    path: '/:customerDomain/experiments',
    component: ExperimentsList,
    private: true,
  },
];

export default experimentsRoutes;
