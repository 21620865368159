import { fetchAllEventsCount, fetchCommsByPlatform } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import PermissionLink from 'components/PermissionsLink';
import withWidget from 'decorators/widget';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getAllEventsCount,
  getAllEventsLoading,
  getCommsByPlatLoading,
  getTotalComms,
  getViolatedEventsCount,
} from 'selectors/analytics';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';
import { useTimeRange } from 'utils/timeRanges';

const Activity: React.FC = () => {
  const dispatch = useDispatch();

  const commsByPlatLoading = useSelector(getCommsByPlatLoading);
  const totalCommsCount = useSelector(getTotalComms);

  const totalCount = useSelector(getAllEventsCount);
  const totalLoading = useSelector(getAllEventsLoading);

  const violatedCount = useSelector(getViolatedEventsCount);

  const range = useTimeRange();

  useEffect(() => {
    dispatch(fetchAllEventsCount(range));
    dispatch(fetchCommsByPlatform(range));
  }, [dispatch, range]);

  const spinner = (
    <div className="h-8">
      <LoadingIndicator className="ml-2" size="5" />
    </div>
  );

  return (
    <div className="grid grid-cols-3">
      <div className="flex items-center">
        <dl>
          <dd className="items-baseline">
            <div className="flex items-baseline text-2xl leading-8 text font-bold">
              {commsByPlatLoading ? spinner : totalCommsCount}
            </div>
          </dd>
          <dt className="text text--left text--label">Messages</dt>
        </dl>
      </div>

      <div className="flex items-center">
        <dl>
          <dd className="items-baseline">
            <PermissionLink
              action="events.list"
              routeName="envelope-list"
              className="hover:underline text"
              queryParams={{
                envelopes__created_before: range.created_before || null,
                envelopes__created_after: range.created_after || null,
              }}
              onClick={(): Promise<void> => logEvent('Dashboard-events')}
            >
              <div className="flex items-baseline text-2xl leading-8 text font-bold">
                {totalLoading ? spinner : totalCount}
              </div>
            </PermissionLink>
          </dd>
          <dt className="text text--left text--label">Events</dt>
        </dl>
      </div>

      <div className="flex items-center">
        <dl>
          <dd className="items-baseline">
            <PermissionLink
              action="events.list"
              routeName="envelope-list"
              queryParams={{
                envelopes__states: 'contains_issues',
                envelopes__created_before: range.created_before || null,
                envelopes__created_after: range.created_after || null,
              }}
              className="hover:underline text"
              onClick={(): Promise<void> => logEvent('Dashboard-out-of-policy')}
            >
              <div className="flex items-baseline text-2xl leading-8 text font-bold">
                {totalLoading ? spinner : violatedCount}
              </div>
            </PermissionLink>
          </dd>
          <dt className="text text--left text--label">Out of Policy</dt>
        </dl>
      </div>
    </div>
  );
};

export default withWidget({
  title: 'Activity',
  minWidth: 9,
  minHeight: 6,
})(Activity);
