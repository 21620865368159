import RawEmail from 'components/HighlightedSentenceComponent/RawEmail';
import WrapperHighlightedSentence from 'components/HighlightedSentenceComponent/WrapperHighlightedSentence';
import React from 'react';
import type { UUID } from 'types';

type ComponentProps = {
  eventId: UUID;
  commUuid: UUID | null;
  fullMessageOptions: string;
  onlySubject?: boolean;
};

const HighlightedSentenceComponent: React.FC<ComponentProps> = ({
  eventId,
  commUuid,
  fullMessageOptions,
  onlySubject,
}) => {
  const FULL_OPTIONS = {
    HIGHLIGHTED: 'HIGHLIGHTED',
    PLAIN: 'PLAIN',
  };

  const renderContent = (): JSX.Element | null => {
    if (fullMessageOptions === FULL_OPTIONS.HIGHLIGHTED) {
      return (
        <WrapperHighlightedSentence
          eventId={eventId}
          commUuid={commUuid}
          onlySubject={onlySubject || false}
        />
      );
    }
    if (fullMessageOptions === FULL_OPTIONS.PLAIN) {
      return <RawEmail eventId={eventId} commUuid={commUuid} />;
    }
    return null;
  };

  return <>{renderContent()}</>;
};

export default HighlightedSentenceComponent;
