import Analytics from 'components/Analytics';
import { RouteSpec, SuperRouteSpec } from 'types';

const analyticsRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'analytics',
    path: '/:customerDomain/analytics',
    component: Analytics,
    permissions: 'analytics.seeAnalyticsPage',
    private: true,
    data: {
      params: {
        include_count: {
          defaultValue: '1',
        },
        analytics: {
          nestedResource: true,
          params: {
            campaign_uuids: {
              list: true,
            },
            not_campaign_uuids: {
              list: true,
            },
            rule_uuids: {
              list: true,
            },
            not_rule_uuids: {
              list: true,
            },
            annotator_uuids: {
              list: true,
            },
            not_annotator_uuids: {
              list: true,
            },
            platforms: {
              list: true,
            },
            states: {
              list: true,
            },
            review_values: {
              list: true,
            },
            is_starred: {},
            same_sentence: {},
            is_read: {},
            has_events: {},
            created_after: {},
            created_before: {},
            created_by: {
              list: true,
            },
            inbound: {},
            tags: {
              list: true,
            },
            not_tags: {
              list: true,
            },
            tags_and: {
              list: true,
            },
            not_tags_and: {
              list: true,
            },
            broad_search: {},
            sentiment_less_than: {},
          },
        },
      },
    },
  },
];

export default analyticsRoutes;
