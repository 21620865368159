import amplitude from 'amplitude-js';
import LoadingIndicator from 'components/LoadingIndicator';
import RedirectToHome from 'components/RedirectToHome';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, RouteComponentProps, useLocation } from 'react-router-dom';
import {
  getIsAuthenticated,
  getIsSigningOut,
  getIsVerifying,
  getSelectCustomerList,
  getUser,
} from 'selectors/auth';
import { getPermissionsPolicy } from 'selectors/permissions';
import type { RouteSpec, SuperRouteSpec } from 'types';
import { isActionAuthorized } from 'utils/permissions';
import { getRoute } from 'utils/urls';

type PrivateComponentProps = RouteComponentProps<{
  [paramName: string]: string | undefined;
}>;

const PrivateRoute = (route: RouteSpec | SuperRouteSpec): React.FC<PrivateComponentProps> => {
  const PrivateComponent: React.FC<PrivateComponentProps> = (props) => {
    const location = useLocation();

    const isAuthenticated = useSelector(getIsAuthenticated);
    const isVerifying = useSelector(getIsVerifying);
    const isSigningOut = useSelector(getIsSigningOut);
    const customerList = useSelector(getSelectCustomerList);

    const policy = useSelector(getPermissionsPolicy);

    const savedCustomer = window.localStorage.getItem('__customer__');

    const isMultipleCustomers = customerList.length > 1;
    const redirectUrl = window.localStorage.getItem('redirectUrl');
    const { pathname, search } = location;

    const user = useSelector(getUser);
    const config = user.customer?.config;
    const routeSpec = getRoute(route, config);

    if (!routeSpec) return null;

    const { component: Component, permissions } = routeSpec;

    amplitude.getInstance().logEvent(`PAGE_VIEW`);

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
      user.email?.includes('litlingo')
    ) {
      return (
        <div className="m-4 text-lg font-bold">Litlingo has been temporarily deactivated.</div>
      );
    }

    if (isVerifying) {
      return <LoadingIndicator className="m-auto mt-20" size="20" />;
    }

    if (isSigningOut) {
      return <LoadingIndicator className="m-auto mt-20" size="20" />;
    }

    if (!isAuthenticated) {
      const { location: _location } = props;
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: _location },
          }}
        />
      );
    }

    if (redirectUrl) {
      if (`${pathname}${search}` === redirectUrl) {
        window.localStorage.removeItem('redirectUrl');
      }
    }

    if (isMultipleCustomers && !savedCustomer) {
      return (
        <Redirect
          to={{
            pathname: '/select-customer',
          }}
        />
      );
    }

    if (permissions && !isActionAuthorized(permissions, user.roles, policy)) {
      return <RedirectToHome roles={user.roles} />;
    }

    if (routeSpec.customerConfig && config && !config[routeSpec.customerConfig]) {
      return <RedirectToHome roles={user.roles} />;
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} />;
  };

  PrivateComponent.displayName = `Private(${PrivateComponent.name})`;

  return PrivateComponent;
};

export default PrivateRoute;
