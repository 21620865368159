import { fetchUsersForFilter } from 'actions';
import { addUsers } from 'actions/userPermissionsPage';
import useClickOutside from 'components/utils/useClickOutside';
import { SEARCH_ICON } from 'constants/filterIcons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { useDispatch, useSelector } from 'react-redux';
import getUsers from 'selectors/userPermissionsPage';
import { getUsersList, getUsersLoading } from 'selectors/users';
import 'styles/labelsStyle.css';
import type { User } from 'types';

type UserPermissionsSelectFilterProps = {
  className?: string;
};

const UserPermissionsSelectFilter: React.FC<UserPermissionsSelectFilterProps> = (props) => {
  const { className = '' } = props;

  const dispatch = useDispatch();
  const users = useSelector(getUsersList);
  const usersInTable = useSelector(getUsers);
  const usersLoading = useSelector(getUsersLoading);
  const [isOpen, setIsOpen] = useState(false);

  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  useEffect(() => {
    const t = setTimeout(() => setDebouncedSearch(search), 500);
    return (): void => {
      clearTimeout(t);
    };
  }, [search]);

  useEffect(() => {
    dispatch(
      fetchUsersForFilter({ searchValue: debouncedSearch, limit: debouncedSearch ? -1 : 25 })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, dispatch]);

  const handleClickOutside = useCallback(() => {
    setSearch('');
    setIsOpen(false);
  }, [setIsOpen]);

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleButtonClick = (): void => {
    setIsOpen(true);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e?.target.value.toLowerCase();
    setSearch(value);
  };

  const handleAddToList = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    user: User
  ): void => {
    dispatch(addUsers([user]));

    e.stopPropagation();
  };

  const usersToRender = users.filter((u) => !usersInTable.some((uT) => uT.uuid === u.uuid));

  const renderOption = (user: User): JSX.Element => (
    <button
      key={user.uuid}
      type="button"
      data-testid={`user-select-button-${user.uuid}`}
      className="w-full flex flex-col text-left border-litlingo-gray-2 p-2 focus:outline-none hover:bg-litlingo-gray-1"
      onClick={(e): void => handleAddToList(e, user)}
    >
      <div className="w-full flex flex-row gap-1 flex-no-wrap text-body">
        <span className="whitespace-no-wrap">{user.name}</span>
        <span className="text-litlingo-gray-4 whitespace-no-wrap truncate">{`(${user.email})`}</span>
      </div>
    </button>
  );

  return (
    <div ref={wrapperRef} className={`relative inline-block text-left w-full ${className || ''}`}>
      <div className="rounded-md shadow-sm">
        <div className="flex flex-row items-center w-full h-8 bg-white">
          <div className="absolute left-0 ml-1.5 w-5">{SEARCH_ICON()}</div>
          <input
            id="search"
            name="search"
            data-testid="user-select-button"
            className="form-input h-full w-full text-litlingo-gray-6 pl-8 text-body placeholder-italic placeholder-litlingo-gray-6"
            value={search}
            placeholder="Search users by name or email"
            onChange={handleSearchChange}
            onFocus={handleButtonClick}
            autoComplete="off"
          />
        </div>
      </div>

      {isOpen && (
        <div
          data-testid="dropdown-menu"
          className="origin-bottom-left absolute mt-1 w-full rounded shadow-lg z-50 border border-litlingo-gray-2 max-h-46"
        >
          <LoadingOverlayWrapper active={usersLoading} fadeSpeed={0}>
            <div
              className="flex flex-col w-full rounded bg-white shadow-xs overflow-y-auto overflow-x-hidden max-h-46 custom-scrollbar"
              style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}
            >
              {search && (
                <div className="w-full flex flex-col text-left border-b border-litlingo-gray-1 p-2">
                  <span>{search}</span>
                </div>
              )}
              <div className="mt-1 pr-2 text-small self-end">
                <span className="text-litlingo-primary">Add existing user to table</span>
              </div>

              {usersToRender.length > 0 ? (
                usersToRender.map((user) => renderOption(user))
              ) : (
                <div className="p-2">No users found</div>
              )}
            </div>
          </LoadingOverlayWrapper>
        </div>
      )}
    </div>
  );
};

export default UserPermissionsSelectFilter;
