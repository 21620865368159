import { setURLParams } from 'actions';
import useClickOutside from 'components/utils/useClickOutside';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';

type ComponentProps = {
  className?: string;
  resource: string;
};

const ActiveCampaignsFilter: React.FC<ComponentProps> = (props) => {
  const { className, resource } = props;

  const dispatch = useDispatch();
  const filters = useSelector(getNavParamsByResource(resource)).active || [];
  const selectedItems = filters as string[];

  const activeOptions = { Active: 'true', Inactive: 'false' };

  const options = Object.entries(activeOptions).map(([key, value]) => ({
    value,
    label: key,
  }));

  const [isOpen, setIsOpen] = useState(false);

  // click outside functionality
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleButtonClick = (): void => setIsOpen(!isOpen);

  const handleChange = (value: string): void => {
    logEvent(`use-cases-enabled-filter-${value}`);

    const idx = filters.indexOf(value);
    if (idx === -1) {
      dispatch(
        setURLParams({
          [`${resource}__active`]: [value],
          [`${resource}__offset`]: '0',
        })
      );
    } else {
      dispatch(
        setURLParams({
          [`${resource}__active`]: [...filters.slice(0, idx), ...filters.slice(idx + 1)],
          [`${resource}__offset`]: '0',
        })
      );
    }
  };

  return (
    <div ref={wrapperRef} className={`relative inline-block text-left ${className || ''}`}>
      <div className="rounded-md shadow-sm">
        <button
          type="button"
          data-testid="active-button"
          // hover:font-bold
          className={`flex flex-row justify-between items-center rounded border p-2 bg-white text text-body text--lighter-4 hover:text-gray-500 focus:outline-none focus:border-litlingo-success focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 h-8 w-30 italic ${
            selectedItems && selectedItems.length !== 0 ? 'border-gray-700' : 'border-gray-300'
          }`}
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={handleButtonClick}
        >
          <span>Status</span>
          <svg className="-mr-1 ml-2 h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute left-0 mt-2 origin-top-left rounded-md shadow-lg w-64 z-20"
          id="multi-select-dropdown-content"
        >
          <div className="rounded-md bg-white shadow-xs">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="m-2 max-h-filter overflow-auto">
                <fieldset data-testid="status-options">
                  {options.map((option, idx) => (
                    <div key={option.value} className={idx !== 0 ? 'mt-4' : ''}>
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5 mr-2">
                          <input
                            id={option.value}
                            data-testid={`${option.value}-checkbox`}
                            type="checkbox"
                            value={option.value}
                            className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out"
                            onChange={(e): void => handleChange(e.target.value)}
                            checked={selectedItems && selectedItems.includes(option.value)}
                          />
                        </div>
                        <div className="text-sm leading-5">
                          <label htmlFor={option.value} className="text-gray-700">
                            {option.label}
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                  {options.length === 0 && (
                    <div className="text-center">
                      <span className="text-sm text-gray-700">No items</span>
                    </div>
                  )}
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActiveCampaignsFilter;
