import { FILTER_SELECTED_ICON, MINUS_ICON, PLUS_ICON } from 'constants/filterIcons';
import React, { useEffect, useRef, useState } from 'react';

type ComponentProps = {
  headerTitle: string;
  children: JSX.Element;
  customLoading?: boolean;
  dataTestid?: string;
  isDefaultClosed?: boolean;
  selectedFilters?: string | string[] | undefined;
  border?: boolean;
  isOpen?: boolean;
};

const SidebarCollapsableSection: React.FC<ComponentProps> = (props) => {
  const {
    headerTitle,
    children,
    isDefaultClosed,
    isOpen,
    dataTestid,
    customLoading,
    selectedFilters,
    border = true,
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(!!isDefaultClosed);
  const [isOpening, setIsOpening] = useState(false);

  const prevIsCollapsed = useRef<boolean>(!!isDefaultClosed);

  const headerClasses = 'h-5 px-0';
  const containerClasses = `pt-2 pb-2.5 ${border && 'border-b border-litlingo-white'}`;
  const titleClasses = ' text-base font-bold text-white leading-5';
  const contentClasses = 'px-0 mt-2';

  const handleCollapse = (): void => {
    setIsCollapsed(!isCollapsed);
  };

  // allows us to inject open state
  useEffect(() => {
    if (isOpen) {
      setIsCollapsed(!isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isCollapsed && isCollapsed !== prevIsCollapsed.current) {
      setIsOpening(true);
    } else {
      setIsOpening(false);
    }
  }, [isCollapsed, prevIsCollapsed]);

  useEffect(() => {
    prevIsCollapsed.current = isCollapsed;
  }, [isCollapsed]);

  const isSelectedFilter = selectedFilters && selectedFilters.length > 0;

  return (
    <div data-testid={dataTestid || 'section'} className={containerClasses}>
      <div
        className={headerClasses}
        role="button"
        onClick={handleCollapse}
        aria-hidden="true"
        data-testid="collapse-button"
      >
        <div className="flex flex-row justify-between items-center text-white">
          <div className="flex items-center">
            <h1 className={titleClasses}>{headerTitle}</h1>
            {customLoading && (
              <div className="ml-4 flex float-left loader ease-linear rounded-full border-2 border-gray-200 h-4 w-4 self-center" />
            )}
            {isSelectedFilter && <span className="ml-2">{FILTER_SELECTED_ICON()}</span>}
          </div>
          {isCollapsed ? PLUS_ICON('white') : MINUS_ICON('white')}
        </div>
      </div>
      {!isCollapsed && (
        <div className={contentClasses} data-testid="collapsable-content">
          <div
            className={`flex flex-col ${
              isCollapsed ? 'litlingo-collapsed opacity-0' : 'opacity-100'
            }`}
          >
            {React.createElement(children.type, { ...children.props, isOpening })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarCollapsableSection;
