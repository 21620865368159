const frequencyOptions = [
  {
    value: 'daily',
    label: 'Daily',
    text: '',
  },
  {
    value: 'weekly',
    label: 'Weekly',
    text: '',
  },
];
export default frequencyOptions;
