import { deleteTemplate } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import SimpleModal from 'components/SimpleModal';
import { EDIT_ICON, SEE_EMAIL, TRASH_ICON } from 'constants/commonIcons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import type { Template } from 'types';

type ComponentProps = {
  template: Template;
  handleChangeTemplate: (template: Template) => void;
};

const TemplateListItem: React.FC<ComponentProps> = ({ template, handleChangeTemplate }) => {
  const dispatch = useDispatch();
  const [isDeletingTemplate, setIsDeletingTemplate] = useState(false);
  const [isSeeingTemplate, setIsSeeingTemplate] = useState(false);

  const toggleConfirmModal = (): void => {
    setIsDeletingTemplate(!isDeletingTemplate);
  };

  const handleRemoveTemplate = (): void => {
    dispatch(deleteTemplate({ id: template.uuid }));
    toggleConfirmModal();
  };

  const toggleEmailModal = (): void => {
    setIsSeeingTemplate(!isSeeingTemplate);
  };

  return (
    <>
      <tr className="inline-flex items-cente44r table-row">
        <td className="table-wrapper__td">
          <div className="flex items-center">
            <div className="overflow-hidden">
              <div className="text-sm leading-5 font-medium litlingo-flex-break-words">
                {template.name}
              </div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__td">
          <div className="flex items-center">
            <div className="overflow-hidden">
              <div className="text-sm leading-5 font-medium truncate">{template.value}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__td">
          <button
            type="button"
            className="mr-4 focus:outline-none"
            data-testid="edit-template-button"
            onClick={(): void => handleChangeTemplate(template)}
          >
            {EDIT_ICON()}
          </button>
          <button
            type="button"
            className="mr-4 focus:outline-none"
            data-testid="see-template-button"
            onClick={(): void => setIsSeeingTemplate(true)}
          >
            {SEE_EMAIL}
          </button>
          <button
            type="button"
            className="mr-4 focus:outline-none w-5 h-5"
            data-testid="delete-template-button"
            onClick={toggleConfirmModal}
          >
            {TRASH_ICON}
          </button>
        </td>
      </tr>
      {isDeletingTemplate && (
        <ConfirmModal
          text="Are you sure you want to delete this template?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleRemoveTemplate}
          toggleShowModal={toggleConfirmModal}
        />
      )}
      {isSeeingTemplate && (
        <SimpleModal title="Body" toggle={toggleEmailModal}>
          <div className="m-4 text-sm leading-5 font-medium litlingo-flex-break-words">
            {template.value}
          </div>
        </SimpleModal>
      )}
    </>
  );
};

export default TemplateListItem;
