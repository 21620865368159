import { createAction } from '@reduxjs/toolkit';
import { history } from 'store';

export {
  addFilterToURL,
  clearURLParams,
  deleteURLParams,
  replaceURLParams,
  setParamsFromURL,
  setURLParams,
} from 'reducers/nav';

export const manualLocationChange = createAction<{
  location: typeof history.location;
  isFirstRendering: boolean;
}>('NAV/MANUAL_LOCATION_CHANGE');
