import AuditLogsTable from 'components/AuditLogsTable';
import CollapsableMainSection from 'components/CollapsableCard/CollapsableMainSection';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAnnotatorsAuditLogs } from 'reducers/annotatorsAuditLogs';
import {
  getAnnotatorsAuditLogsCount,
  getAnnotatorsAuditLogsList,
  getFetchAnnotatorsAuditLogsLoading,
} from 'selectors/annotatorsAuditLogs';
import { getNavParamsByResource } from 'selectors/nav';
import type { UUID } from 'types';
import { objToString } from 'utils/objToString';

type IdentifierAuditLogsTableProps = {
  identifierId: UUID;
};

const IdentifierAuditLogsTable: React.FC<IdentifierAuditLogsTableProps> = (props) => {
  const { identifierId } = props;

  const dispatch = useDispatch();
  const params = useSelector(getNavParamsByResource(resourceQueryParamName.auditLogs));
  const formattedParams = objToString(params);

  useEffect(() => {
    dispatch(fetchAnnotatorsAuditLogs({ identifier_uuids: [identifierId] }));
  }, [dispatch, identifierId, formattedParams]);

  return (
    <CollapsableMainSection headerTitle="Audit logs" isDefaultClosed>
      <div className="p-4">
        <AuditLogsTable
          getList={getAnnotatorsAuditLogsList}
          getCount={getAnnotatorsAuditLogsCount}
          getLoading={getFetchAnnotatorsAuditLogsLoading}
        />
      </div>
    </CollapsableMainSection>
  );
};

export default IdentifierAuditLogsTable;
