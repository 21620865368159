import { createAction } from '@reduxjs/toolkit';
import type { API, ErrorObject, RouteParams, SavedSearch, UUID, User } from 'types';
import { Tree } from 'utils/parserTree';

type FetchSavedSearchesPayload = {
  limit?: number;
  // eslint-disable-next-line camelcase
  is_starred?: boolean;
  name?: string;
  is_assignable?: boolean;
  broad_search?: string;
  offset?: number;
};
export const fetchSavedSearches = createAction<FetchSavedSearchesPayload>('SAVED_SEARCHES/FETCH');
export const fetchSavedSearchesRequest = createAction('SAVED_SEARCHES/FETCH_REQUEST');
export const fetchSavedSearchesSuccess = createAction<API.WrappedAPIResponse<SavedSearch>>(
  'SAVED_SEARCHES/FETCH_SUCCESS'
);
export const fetchSavedSearchesFailure = createAction<ErrorObject>('SAVED_SEARCHES/FETCH_FAILURE');
export const fetchSavedSearchesFulfill = createAction('SAVED_SEARCHES/FETCH_FULFILL');

export const fetchSingleSavedSearch = createAction<{ uuid: UUID; selectReviewSet?: boolean }>(
  'SAVED_SEARCHES/FETCH_SINGLE'
);
export const fetchSingleSavedSearchRequest = createAction('SAVED_SEARCHES/FETCH_SINGLE_REQUEST');
export const fetchSingleSavedSearchSuccess = createAction<SavedSearch>(
  'SAVED_SEARCHES/FETCH_SINGLE_SUCCESS'
);
export const fetchSingleSavedSearchFailure = createAction<ErrorObject>(
  'SAVED_SEARCHES/FETCH_SINGLE_FAILURE'
);

export const fetchSavedSearchesByRole = createAction<FetchSavedSearchesPayload>(
  'SAVED_SEARCHES/FETCH_BY_ROLE'
);

export const fetchOwnSavedSearches = createAction<FetchSavedSearchesPayload>(
  'SAVED_SEARCHES/FETCH_OWN'
);
export const fetchOwnSavedSearchesSuccess = createAction<API.WrappedAPIResponse<SavedSearch>>(
  'SAVED_SEARCHES/FETCH_OWN_SUCCESS'
);

type UpsertSavedSearchPayload = Pick<
  SavedSearch,
  | 'name'
  | 'description'
  | 'url'
  | 'private'
  | 'priority'
  | 'is_starred'
  | 'param_count'
  | 'is_assignable'
  | 'config'
  | 'users'
  | 'display_in_assignments'
  | 'viewers'
  | 'sample_size'
> & {
  redirect?: string;
  isReviewSet?: boolean;
  scope?: string;
  based_on?: string;
  cadence?: string;
};

export const createSavedSearch = createAction<UpsertSavedSearchPayload>('SAVED_SEARCHES/CREATE');
export const upsertSavedSearch = createAction<UpsertSavedSearchPayload>('SAVED_SEARCHES/UPSERT');
export const upsertSavedSearchRequest = createAction('SAVED_SEARCHES/UPSERT_REQUEST');
export const upsertSavedSearchSuccess = createAction<SavedSearch>('SAVED_SEARCHES/UPSERT_SUCCESS');
export const upsertSavedSearchFailure = createAction<ErrorObject>('SAVED_SEARCHES/UPSERT_FAILURE');
export const upsertSavedSearchFulfill = createAction('SAVED_SEARCHES/UPSERT_FULFILL');

export const upsertSavedSearchParams = createAction<{
  uuid: string;
  url: string;
  redirect: string;
}>('SAVED_SEARCHES/UPSERT_PARAMS');
export const upsertSavedSearchParamsRequest = createAction('SAVED_SEARCHES/UPSERT_PARAMS_REQUEST');
export const upsertSavedSearchParamsSuccess = createAction<SavedSearch>(
  'SAVED_SEARCHES/UPSERT_PARAMS_SUCCESS'
);
export const upsertSavedSearchParamsFailure = createAction<ErrorObject>(
  'SAVED_SEARCHES/UPSERT_PARAMS_FAILURE'
);

export const deleteSavedSearch = createAction<{ uuid: UUID }>('SAVED_SEARCHES/DELETE');
export const deleteSavedSearchRequest = createAction('SAVED_SEARCHES/DELETE_REQUEST');
export const deleteSavedSearchSuccess = createAction<UUID>('SAVED_SEARCHES/DELETE_SUCCESS');
export const deleteSavedSearchFailure = createAction<ErrorObject>('SAVED_SEARCHES/DELETE_FAILURE');
export const deleteSavedSearchFulfill = createAction('SAVED_SEARCHES/DELETE_FULFILL');

export type addUserToSavedSearchPayload = {
  user: User;
  savedSearchId: string;
};
export type addUserToSavedSearchSuccessPayload = {
  user: User;
  savedSearch: SavedSearch;
};

export const fetchOwnSavedSearchesViewers = createAction<FetchSavedSearchesPayload>(
  'SAVED_SEARCHES/FETCH_OWN_VIEWERS'
);
export const fetchOwnSavedSearchesViewersRequest = createAction(
  'SAVED_SEARCHES/FETCH_OWN_VIEWERS_REQUEST'
);
export const fetchOwnSavedSearchesViewersFailure = createAction<ErrorObject>(
  'SAVED_SEARCHES/FETCH_OWN_VIEWERS_FAILURE'
);

export const fetchOwnSavedSearchesViewersFulfill = createAction(
  'SAVED_SEARCHES/FETCH_OWN_VIEWERS_FULFILL'
);
export const fetchOwnSavedSearchesViewersSuccess = createAction<
  API.WrappedAPIResponse<SavedSearch>
>('SAVED_SEARCHES/FETCH_OWN_VIEWERS_SUCCESS');

export const addUserToSavedSearch =
  createAction<addUserToSavedSearchPayload>('SAVED_SEARCHES/ADD_USER');
export const addUserToSavedSearchRequest = createAction('SAVED_SEARCHES/ADD_USER_REQUEST');
export const addUserToSavedSearchFailure = createAction<ErrorObject>(
  'SAVED_SEARCHES/ADD_USER_FAILURE'
);
export const addUserToSavedSearchSuccess = createAction<addUserToSavedSearchSuccessPayload>(
  'SAVED_SEARCHES/ADD_USER_SUCCESS'
);

export type addUsersToReviewersPayload = {
  users: User[];
  savedSearchId: string;
};
export type addUserToViewersPayload = {
  user: User;
  savedSearchId: string;
};

export type addUsersToViewersPayload = {
  users: User[];
  savedSearchId: string;
};

export const addUsersToReviewers = createAction<addUsersToReviewersPayload>(
  'SAVED_SEARCHES/ADD_USERS'
);
export const addUsersToReviewersRequest = createAction('SAVED_SEARCHES/ADD_USERS_REQUEST');
export const addUsersToReviewersFailure = createAction<ErrorObject>(
  'SAVED_SEARCHES/ADD_USERS_FAILURE'
);
export const addUsersToReviewersSuccess = createAction<addUsersToReviewersPayload>(
  'SAVED_SEARCHES/ADD_USERS_SUCCESS'
);

export const addUserToViewers = createAction<addUserToViewersPayload>(
  'SAVED_SEARCHES/ADD_USER_VIEWERS'
);

export const addUsersToViewers = createAction<addUsersToViewersPayload>(
  'SAVED_SEARCHES/ADD_USERS_VIEWERS'
);

export const addUsersToViewersFailure = createAction<ErrorObject>(
  'SAVED_SEARCHES/ADD_USERS_VIEWERS_FAILURE'
);
export const addUsersToViewersSuccess = createAction<addUsersToViewersPayload>(
  'SAVED_SEARCHES/ADD_USERS_VIEWERS_SUCCESS'
);

export const addUserToViewersRequest = createAction('SAVED_SEARCHES/ADD_USER_VIEWERS_REQUEST');
export const addUserToViewersFailure = createAction<ErrorObject>(
  'SAVED_SEARCHES/ADD_USER_VIEWERS_FAILURE'
);
export const addUserToViewersSuccess = createAction<addUserToViewersPayload>(
  'SAVED_SEARCHES/ADD_USER_VIEWERS_SUCCESS'
);

export type removeUserFromSavedSearchPayload = {
  user: User;
  savedSearchId: string;
};

export type removeUserFromSavedSearchSuccessPayload = {
  user: User;
  savedSearch: SavedSearch;
};

export type removeUserFromSavedSearchViewersPayload = {
  user: User;
  savedSearchId: string;
};

export const removeUserFromSavedSearch = createAction<removeUserFromSavedSearchPayload>(
  'SAVED_SEARCHES/REMOVE_USER'
);
export const removeUserFromSavedSearchRequest = createAction('SAVED_SEARCHES/REMOVE_USER_REQUEST');
export const removeUserFromSavedSearchFailure = createAction<ErrorObject>(
  'SAVED_SEARCHES/REMOVE_USER_FAILURE'
);
export const removeUserFromSavedSearchSuccess =
  createAction<removeUserFromSavedSearchSuccessPayload>('SAVED_SEARCHES/REMOVE_USER_SUCCESS');

export const removeUserFromViewers = createAction<removeUserFromSavedSearchViewersPayload>(
  'SAVED_SEARCHES/REMOVE_USER_VIEWERS'
);

export const removeUserFromViewersRequest = createAction(
  'SAVED_SEARCHES/REMOVE_USER_VIEWERS_REQUEST'
);
export const removeUserFromViewersFailure = createAction<ErrorObject>(
  'SAVED_SEARCHES/REMOVE_USER_VIEWERS_FAILURE'
);
export const removeUserFromViewersSuccess = createAction<removeUserFromSavedSearchViewersPayload>(
  'SAVED_SEARCHES/REMOVE_VIEWERS_USER_SUCCESS'
);

export type SelectReviewSetType = {
  reviewSet: SavedSearch | null;
};

export const selectReviewSet = createAction<SelectReviewSetType>(
  'SAVED_SEARCHES/SELECT_REVIEW_SET'
);

type AppendSampleType = {
  name: string;
  description?: string;
  sample_size: number;
  dateRange: {
    createdAfter: string;
    createdBefore: string;
  };
  params: RouteParams;
  tree: Tree;
  overrideDateRange?: boolean;
  newDateRange?: {
    createdAfter: string;
    createdBefore: string;
  };
  prevDateRange?: boolean;
};

export const appendSample = createAction<AppendSampleType>('SAVED_SEARCHES/APPEND_SAMPLE');
export const appendSampleRequest = createAction('SAVED_SEARCHES/APPEND_SAMPLE_REQUEST');
export const appendSampleFailure = createAction<ErrorObject>(
  'SAVED_SEARCHES/APPEND_SAMPLE_FAILURE'
);
export const appendSampleSuccess = createAction<{ savedSearchUuid: string; newUrl: string }>(
  'SAVED_SEARCHES/APPEND_SAMPLE_SUCCESS'
);

export const openSubsamplesModal = createAction('SAVED_SEARCHES/OPEN_SUBSAMPLES_MODAL');
export const openMessagesModal = createAction('SAVED_SEARCHES/OPEN_MESSAGES_MODAL');

type SmartSampleType = {
  sample_size: number;
  tree: Tree;
  overrideDateRange?: boolean;
  dateRange?: {
    createdAfter: string;
    createdBefore: string;
  };
  newDateRange?: {
    createdAfter: string;
    createdBefore: string;
  };
  prevDateRange?: boolean;
  prompt_uuid: string;
};

export const smartSample = createAction<SmartSampleType>('SAVED_SEARCHES/SMART_SAMPLE');
export const smartSampleRequest = createAction('SAVED_SEARCHES/SMART_SAMPLE_REQUEST');
export const smartSampleFailure = createAction<ErrorObject>('SAVED_SEARCHES/SMART_SAMPLE_FAILURE');
export const smartSampleSuccess = createAction('SAVED_SEARCHES/SMART_SAMPLE_SUCCESS');
